import { IconProps } from '@abstractTypes/icons'

export const FaceShapeSquareWomanIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61850)">
        <path
          d="M47.707 36.543C45.736 34.572 45.001 26.117 45 21.25C45 9.67 35.58 0.25 24 0.25C12.42 0.25 3 9.67 3 21.25C2.999 26.117 2.264 34.572 0.293 36.543L0 36.836V37.25C0 43.315 4.935 48.25 11 48.25C13.365 48.25 15.553 47.492 17.349 46.217C17.565 46.23 17.78 46.25 18 46.25H30C30.22 46.25 30.435 46.23 30.651 46.217C32.447 47.492 34.635 48.25 37 48.25C43.065 48.25 48 43.315 48 37.25V36.836L47.707 36.543ZM18 44.25C13.038 44.25 9 40.212 9 35.25V20.213C14.746 19.792 18.918 15.788 20.405 12.059C22.412 14.534 27.276 18.119 39 20.1V35.25C39 40.212 34.962 44.25 30 44.25H18ZM37 46.25C35.825 46.25 34.705 46.017 33.674 45.605C37.936 44.088 41 40.025 41 35.25V19.25L40.156 18.262C22.377 15.455 20.999 9.151 20.99 9.108L19 9.25C19 12.79 14.276 18.25 8 18.25L7 19.25V35.25C7 40.025 10.064 44.088 14.326 45.605C13.295 46.017 12.175 46.25 11 46.25C6.161 46.25 2.202 42.412 2.007 37.62C4.914 33.984 5 22.579 5 21.25C5 10.773 13.523 2.25 24 2.25C34.477 2.25 43 10.773 43 21.25C43 22.579 43.086 33.984 45.993 37.62C45.798 42.412 41.839 46.25 37 46.25Z"
          fill="#333333"
        />
        <path
          d="M26.4324 23.3066H24.4324V31.2496H21.9844V33.2496H26.4324V23.3066Z"
          fill="#333333"
        />
        <path
          d="M27.3115 37.178L23.9995 36.208L20.6875 37.178L21.8785 38.37C22.4445 38.938 23.1975 39.25 23.9995 39.25C24.8015 39.25 25.5545 38.938 26.1215 38.371L27.3115 37.178Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61850">
          <rect width={48} height={48} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
