import { AppConfigOverride, BannerRegion } from '@abstractTypes/envs'

const getRbEnvVariables = (region: BannerRegion) => {
  const rbEnvVariables: AppConfigOverride = {
    shopperSlug: 'rayban',
    shopperName: 'RAYBAN',
    storeID: '10480',
    storeLang: 'it-IT',
    vmStore: 'rb',
    vmStyle: 'rb',
    productTileShape: 'SQUARED',
    showExtraPdpDetails: region === 'anz',
    pictureConfig: {
      mainAngle: 'FRONT',
    },
    isMonoBrand: true,
  }

  return rbEnvVariables
}

export default getRbEnvVariables
