import qs from 'qs'

export interface GetCustomProductRouteOptions {
  basePath: string
  brand: string
  model: string
  recipeId?: string
  UPC?: string
}

export const getCustomProductRoute = ({
  basePath,
  brand,
  model,
  recipeId,
  UPC,
}: GetCustomProductRouteOptions): string => {
  const recipeSegment = recipeId ? `/${recipeId}` : ''
  const upcParams = UPC
    ? qs.stringify({ UPC, skipOnMissingUpc: true }, { addQueryPrefix: true })
    : ''

  return `${basePath}/custom/${brand}/${model}CP${recipeSegment}${upcParams}`
}
