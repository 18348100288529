import { IconProps } from '@abstractTypes/icons'

export const PolarizedIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fill="#1f1f24"
        fillRule="evenodd"
        d="M17.0154353,5.20283103 C14.8193054,4.63818778 11.316593,5.11377656 7.38919344,6.35674964 C6.82581343,6.53485859 6.52068911,7.19139848 6.43269087,7.52677384 C6.34373614,7.86309659 6.21556479,8.26857866 6.13617508,8.56795327 C6.05774187,8.86638049 6.22417331,8.84932751 6.41451733,8.73848311 C6.60581784,8.6285861 7.60249351,8.15204993 8.00326809,8.01088912 C9.77184133,7.38466564 14.5620062,5.68884109 17.0039572,6.31601195 C18.0073284,6.57370149 18.5448829,7.45003541 18.2483671,8.56984805 C17.7347252,10.5119935 15.8848492,11.3921169 14.0110607,12.2068706 C13.6648068,12.3584527 13.5988081,12.1604486 13.6581112,11.9359177 L14.9264337,7.13739736 C15.0106059,6.82096977 14.7638282,6.75749477 14.4644429,6.82002238 L13.7116754,7.06444849 C13.5366354,7.11939699 13.4878538,7.22645184 13.4438547,7.3950869 L12.0530999,12.7099337 C11.6724119,12.7942512 11.2295512,12.8880426 10.7149529,12.9932027 C10.5351304,13.0292034 10.4375671,13.8032195 10.7962556,13.7871139 C10.9932951,13.77764 11.3682441,13.7179545 11.8139743,13.6241631 C11.8139743,13.6241631 10.7226049,17.8419346 10.6537367,18.1034137 C10.583912,18.3648928 10.7455609,18.3667875 10.9167749,18.3307868 C11.0879889,18.294786 11.4208517,18.1810995 11.706846,18.0948872 C11.9928403,18.0096222 12.1324897,17.7850913 12.1917928,17.5615077 L13.2334241,13.5426877 C13.2487282,13.4858445 13.3032488,13.2802613 13.4648977,13.2025754 C16.8681339,11.8042307 18.7897475,10.4238864 19.3081719,8.46374059 C19.5846011,7.41782422 19.2115651,5.76747429 17.0154353,5.20283103 M22.8300143,13.2395236 C22.1853316,18.4312099 17.0766514,23.4334186 10.2414841,19.7073416 C3.25040684,15.8959996 -7.83354488,2.70930577 13.1846425,3.00489084 C21.2728282,3.11763001 23.7099967,6.14832427 22.8300143,13.2395236"
      />
    </svg>
  )
}
