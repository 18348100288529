import { EngravingSelectedOptions } from '@abstractTypes/engraving'
import { Product } from '@abstractTypes/product'
import { ProductDescription } from '@components/CardProductBase/ProductCard/ProductDescription'
import ConditionalRender from '@components/ConditionalRender'
import { DiscountedPrice } from '@components/Price'
import { Heading5, Label, Text } from '@components/core/Typography'
import config from '@config/index'
import { isOOBrand } from '@libs/custom'
import { pxToRem } from '@libs/styled'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'

const CartItemDetailsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

export const CartDetailList = styled.dl`
  display: table;
  margin-block-end: 0;

  dt,
  dd {
    display: table-cell;
    inline-size: 50%;
  }

  dt {
    padding-inline-end: ${pxToRem(6)};
    inline-size: 50%;
  }
`

export const CartDetailHeader = styled.header<{ displayPriceUnderTitle?: boolean }>`
  display: flex;
  flex-direction: ${({ displayPriceUnderTitle }) => (displayPriceUnderTitle ? 'column' : 'row')};
  justify-content: space-between;
  margin-block-end: ${pxToRem(32)};
`
export const CartDetailHeaderTitle = styled.div`
  inline-size: 100%;
`

const StyledPargraph = styled.div`
  display: flex;
`

export interface CartItemDetailsProps {
  product: Product
  id?: string
  className?: string
  isCustomProduct?: boolean
  CustomIcon?: React.FC
  displayPriceUnderTitle?: boolean
  VAT?: string
  index: number
  engraving?: EngravingSelectedOptions
  withProductDescription?: boolean
}

export const CartItemDetails: React.FC<CartItemDetailsProps> = ({
  className,
  id,
  product,
  CustomIcon,
  isCustomProduct = false,
  index,
  displayPriceUnderTitle = false,
  VAT,
  engraving,
  withProductDescription = false,
}) => {
  const { brand, name } = product
  const isOakleyProduct = isOOBrand(product.brand.id)
  const isOakleyCustomProduct = isOakleyProduct && isCustomProduct
  const { isMonoBrand } = config
  const hasAlreadyRenderedName = useRef(false)

  const renderTileTitle = () => {
    // the title can be the brand name or the product's name
    if (isOakleyCustomProduct) return null

    if (isMonoBrand) {
      hasAlreadyRenderedName.current = true
      return (
        <Heading5 data-test={`mocobox-${index}_ProductName`} weight="bold">
          {name}
        </Heading5>
      )
    }

    return <Heading5 weight="bold">{brand.name}</Heading5>
  }

  const renderDescription = useCallback(
    () => <ProductDescription product={product} engraving={engraving} />,
    [product, engraving]
  )

  return (
    <CartItemDetailsWrapper className={className} id={id}>
      <CartDetailHeader displayPriceUnderTitle={displayPriceUnderTitle}>
        <CartDetailHeaderTitle>
          <StyledPargraph>
            {CustomIcon && isCustomProduct && <CustomIcon />}
            {renderTileTitle()}
          </StyledPargraph>
          {!hasAlreadyRenderedName.current && (
            <Text data-test={`mocobox-${index}_ProductName`}>{name}</Text>
          )}
        </CartDetailHeaderTitle>
        <div>
          <DiscountedPrice
            current={product.price.current}
            previous={product.price.previous}
            renderCurrentAs={Heading5}
          />
          {VAT && <Label>{VAT}</Label>}
        </div>
      </CartDetailHeader>
      <ConditionalRender condition={withProductDescription} render={renderDescription} />
    </CartItemDetailsWrapper>
  )
}
