import { Device } from '@abstractTypes/utils'
import { ArrowUpIcon } from '@components/core/Icons'
import { Label } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useThemeContext } from '@hooks/useThemeContext'
import { altNavigation, landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ScrollToTopButtonProps } from './types'

export const ScrollTopButton = styled.button`
  z-index: 1;
  position: fixed;
  inset-block-end: ${pxToRem(20)};
  inset-inline-end: ${pxToRem(8)};
  text-transform: uppercase;
  line-height: 0;
  inline-size: ${pxToRem(65)};
  block-size: ${pxToRem(65)};
  ${tablet(`
    inline-size: ${pxToRem(48)};
    block-size: ${pxToRem(48)};
    inset-inline-end: ${clampValues(10, 14)};
    padding: 0;
  `)}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${pxToRem(48)};
    block-size: ${pxToRem(48)};
    inset-inline-end: ${clampValuesLandscape(12, 20)};
    padding: 0;
  `
  )}
  ${altNavigation(`
    inset-inline-end: ${pxToRem(12)};
  `)}
  ${zoomPortrait(
    [150, 175, 200],
    `
    inline-size: ${pxToRem(98)};
    block-size: ${pxToRem(98)};
    inset-block-end: ${pxToRem(30)};
    inset-inline-end: ${pxToRem(20)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(130)};
    block-size: ${pxToRem(130)};
  `
  )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    inset-inline-end: ${pxToRem(10)};
  `
  )}

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.primaryDark};
    border-radius: ${pxToRem(64)};
    inline-size: 100%;
    block-size: 100%;
  }
`

export const ScrollToTopButton = ({ onClick }: ScrollToTopButtonProps) => {
  const { zoomLevel } = useNavigationContext()
  const theme = useThemeContext()
  const { t } = useTranslation()

  return (
    <ScrollTopButton
      onClick={onClick}
      id="Playlist_BackToTop"
      aria-label={t('FrameAdvisor.ctaBackToTop')}
      aria-description={t('FrameAdvisor.ctaBackToTop')}
    >
      <div>
        <ArrowUpIcon
          width={pxToRem(28, zoomLevel / 100)}
          height={pxToRem(15, zoomLevel / 100)}
          fill={theme.colors.primaryLight}
        />
        <Label color={theme.colors.primaryLight}>{t('FrameAdvisor.ctaBackToTop')}</Label>
      </div>
    </ScrollTopButton>
  )
}
