import {
  ActiveFilters,
  AnalyticsConfig,
  AnalyticsEventPayload,
  AnalyticsFAProduct,
  CommonDataLayer,
  ErrorData,
  ErrorForAnalytics,
  IsVmSupportedLayer,
  ProductForAnalytics,
  ProductForAnalyticsFields,
  UtagData,
} from '@abstractTypes/analytics'
import { CartItem } from '@abstractTypes/cart'
import { BOMComponent } from '@abstractTypes/customRecipe'
import { EngravingSelectedOptions, EngravingSelectedOptionsList } from '@abstractTypes/engraving'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FrameAdvisorUserProfile, MoodEnum } from '@abstractTypes/frameAdvisor'
import { Store } from '@abstractTypes/graphqlTypes'
import { Product, StandardProduct } from '@abstractTypes/product'
import { isBestMatch } from '@libs/frameAdvisor'
import {
  getCustomModelFromPathname,
  getProductNameFromPathname,
  isGraphQLError,
  isIPadView,
} from '@libs/utils'
import { FrameAdvisorState } from '@reducers/frameAdvisor'
import { Maybe } from 'graphql/jsutils/Maybe'
import { upperFirst } from 'lodash'
import { toUpperCase } from './caseInsensitive'
import {
  convertToCamelCaseAndStripSpecials,
  getPageType,
  mapAnalyticsShopperSlugs,
  stripSpecialCharacters,
} from './formatters'
import { SearchResultTypeView } from '@hooks/analytics/useSearchAnalyticsData'

export const PAGE_PLATFORM = 'SS20'

export const EVENTS_ID = {
  vpw: 'VirtualPage-View',
  event: 'Event',
  click: 'Click',
  impression: 'Impression',
}

export const PAGES: { [key: string]: string } = {
  home: 'home',
  products: 'products',
  cart: 'cart',
  wishlist: 'wishlist',
  pdp: 'pdp',
  'thank-you': 'thank-you',
  checkout: 'checkout',
  rb: 'rb',
  oo: 'oo',
  rbpcp: 'rbpcp',
  oopcp: 'oopcp',
  playlist: 'playlist',
  fsa: 'fsa',

  supernova: 'supernova',
  brands: 'brands',
  electronics: 'electronics',
  handoff: 'retrieve-session',
  nuance: 'nuance',
}

export const PAGES_CUSTOM: { [key: string]: string } = {
  rb: 'rb',
  oo: 'oo',
}

// Based on doc https://docs.google.com/document/d/1AFpHgZKjf2YMP1UmqlVqM1bfPaUD5go8MAqf9dTblnE/edit#
export const PAGE_TYPES: { [key: string]: string } = {
  [PAGES.home]: 'Home',
  [PAGES.products]: 'Plp',
  [PAGES.cart]: 'CartPage',
  [PAGES.wishlist]: 'Wishlist',
  [PAGES.pdp]: 'Pdp',
  [PAGES['thank-you']]: 'Thankyou',
  [PAGES.checkout]: 'Delivery',
  [PAGES.rb]: 'Custompage',
  [PAGES.oo]: 'Custompage',
  [PAGES.rbpcp]: 'Pcp',
  [PAGES.oopcp]: 'Pcp',
  [PAGES.playlist]: 'Playlist',
  [PAGES.fsa]: 'FrameAdvisor',
  [PAGES.electronics]: 'Landing',
  [PAGES.brands]: 'Landing',
  [PAGES.customerOrder]: 'Static',
  [PAGES.handoff]: 'Static',
  [PAGES.nuance]: 'Landing',
}

export const PAGE_SECTION_1: { [key: string]: string } = {
  [PAGES.home]: 'Landings',
  [PAGES.products]: 'Catalog',
  [PAGES.pdp]: 'Catalog',
  [PAGES.wishlist]: 'FittingRoom',
  [PAGES.cart]: 'CartPage',
  [PAGES['thank-you']]: 'Checkout',
  [PAGES.checkout]: 'Checkout',
  [PAGES.rb]: 'Landings',
  [PAGES.oo]: 'Landings',
  [PAGES.rbpcp]: 'Custom',
  [PAGES.oopcp]: 'Custom',
  [PAGES.electronics]: 'Landings',
  [PAGES.nuance]: 'Landings',
  [PAGES.brands]: 'Landings',
  [PAGES.customerOrder]: 'Other',
  [PAGES.handoff]: 'Other',
}

export const PAGE_SECTION_2: { [key: string]: string } = {
  [PAGES['thank-you']]: 'Standard',
  [PAGES.checkout]: 'Standard',
  [PAGES.pdp]: getProductNameFromPathname(),
  [PAGES.rb]: 'Custom',
  [PAGES.oo]: 'Custom',
  [PAGES.rbpcp]: getCustomModelFromPathname(),
  [PAGES.oopcp]: getCustomModelFromPathname(),

  [PAGES.supernova]: 'supernova',
  [PAGES.nuance]: 'Nuance',
  [PAGES.brands]: 'brands',
  [PAGES.customerOrder]: 'BacktoCustomerOrder',
  [PAGES.handoff]: 'HandOff',
}

export const ERROR_SOURCE: { [key: string]: string } = {
  'Solr Error': 'Product',
  'Network error': 'Server',
  '404': '404',
  '500': 'Client',
  'CMS Error': 'Server',
  'WCS Error': 'Server',
  'Magento Error': 'Server',
  'Catalog API Error': 'Server',
}

export const isFrameAdvisorPage = (pageType: string) => {
  return [[PAGES['fsa']], PAGES.playlist].includes(pageType)
}

export const isConfiguratorPage = (pageType: string) => {
  return [PAGES.oo, PAGES.rb, PAGES.oopcp, PAGES.rbpcp].includes(pageType)
}

export const formatPageType = (
  pageType: string | undefined,
  subPageType: string | undefined,
  extraPageData: string | undefined
): string => {
  if (subPageType && PAGES[subPageType] && PAGES_CUSTOM[subPageType] && extraPageData) {
    return `${PAGES[subPageType]}pcp`
  }
  if (subPageType) {
    return PAGES[subPageType] ?? PAGES.pdp
  }

  return pageType ? PAGES[pageType] : PAGES.home
}

export const isPageWithCatalogProducts = (pageType: string) => {
  return [PAGES.products, PAGES.pdp].includes(pageType)
}

export const formatFilters = (activeFilters: ActiveFilters[]): string => {
  const formattedFilters = activeFilters
    .map(elem => {
      if (Array.isArray(elem.value)) {
        return elem.value.map((val: string) => `${elem.kind}=${val}`).join('|')
      } else {
        return `${elem.kind}=${elem.value}`
      }
    })
    .join('|')
  return stripSpecialCharacters(formattedFilters)
}

export const formatActiveFilters = (activeFilters: ActiveFilters[] | undefined): string => {
  let formattedFilters = ''
  if (activeFilters) {
    formattedFilters = formatFilters(activeFilters)
  }
  return stripSpecialCharacters(formattedFilters)
}

export const formatActiveSort = (activeSort: ActiveFilters[] | undefined): string => {
  const defaultValue = 'sort=price:relevance'
  if (activeSort) {
    if (activeSort[0] && activeSort[0].value) {
      return formatFilters(activeSort)
    }
    return defaultValue
  }
  return defaultValue
}

export const formatFrameAdvisorPageData = (
  frameAdvisorState: FrameAdvisorState,
  frameAdvisorUserProfile?: FrameAdvisorUserProfile
) => {
  const { userSearchInformation, userInformation } = frameAdvisorUserProfile || {}
  const userProductType = userInformation?.glassesType
  const SUNGLASSES_LABELS = ['SUN', 'Sunglasses']
  const gender = userSearchInformation?.gender || frameAdvisorState.survey.gender || ''
  const faceShape = userSearchInformation?.faceShape || frameAdvisorState.survey.face_shape || ''
  const faceLength = userSearchInformation?.faceLength || frameAdvisorState.survey.face_length || ''
  const playList = frameAdvisorState.selectedPlaylist?.analyticsData?.playlistTitleID || ''
  const shadesFor = frameAdvisorState.shadesFor || 'MySelf'
  const frameCat = frameAdvisorState.survey.playlistType
    ? SUNGLASSES_LABELS.includes(frameAdvisorState.survey.playlistType)
      ? 'Sunglasses'
      : 'Eyeglasses'
    : ''

  const mood =
    userProductType?.toLowerCase() === 'sunglasses'
      ? userSearchInformation?.sunMood
      : userSearchInformation?.eyeMood

  const userFaceData = {
    FaceShape: faceShape,
    FaceLength: faceLength,
    Age: userSearchInformation?.age,
    HairColor: userSearchInformation?.hairColor,
    EyeColor: userSearchInformation?.eyeColor,
  }

  const userStyleData = {
    For: shadesFor,
    Gender: gender,
    FrameCat: frameCat,
    Style: convertToCamelCaseAndStripSpecials(playList),
    Highlighting:
      userProductType && mood && (mood.toLowerCase() === MoodEnum.harmony ? 'Balance' : 'Contrast'),
  }

  return {
    Page_UserFace: createStringPayloadFromRecord(userFaceData),
    Page_UserStyle: createStringPayloadFromRecord(userStyleData),
  }
}

export const formatError = (error: ErrorData): ErrorForAnalytics => {
  const errorData = error.errors[0]
  return {
    id: 'Error',
    Error_Source: ERROR_SOURCE[error.type] || 'Anomaly',
    Error_Code: isGraphQLError(errorData) ? errorData.extensions?.code : '500',
    Error_Details: errorData.stack || errorData.message,
    Error_Message: errorData.message,
  }
}

export const formatEngraving = (engravedItem: EngravingSelectedOptions | undefined): string => {
  let formattedEngraving = ''
  if (engravedItem) {
    formattedEngraving = engravedItem.sides.map(side => side.text).join(',')
  }
  return formattedEngraving
}

const mapCategory = (category: string) => {
  switch (category.toLowerCase()) {
    case 'sunglasses':
    case 'eyeglasses':
    case 'goggles':
    case 'replacementlenses':
      return 'EYEWEAR'
    default:
      return 'AFA'
  }
}

interface CPComponentsTypes {
  case: string
  engraving: string
}

const OO_CP_COMPONENTS_TYPES: CPComponentsTypes = {
  case: 'ocp_microbag',
  engraving: 'ocp_etching',
}

const RB_CP_COMPONENTS_TYPES: CPComponentsTypes = {
  case: 'C',
  engraving: 'S',
}

const getCPComponentValue = (item: Product, componentType: keyof CPComponentsTypes) => {
  const brandTypes =
    item.originalBrandCode === 'OO' ? OO_CP_COMPONENTS_TYPES : RB_CP_COMPONENTS_TYPES
  const components = item.customItem?.custom.bom.components.filter(
    (c: BOMComponent) => c.componentType === brandTypes[componentType]
  )
  return components?.map((el: BOMComponent) => el.componentCode).join(' | ') || ''
}

const getCPSize = (item: Product) => {
  return item.customItem?.custom.bom.modelGrid?.slice(-2) || ''
}

export interface FormatProductParams {
  item: Product
  mySelection?: FrameAdvisorState['mySelection']
  engravedItem?: EngravingSelectedOptions
  quantity?: number
  isEventWithCatalogProducts?: boolean
  isExtendedProductTypes?: boolean
  hasItemsInStock?: boolean
}

export const formatProduct = ({
  item,
  mySelection = [],
  engravedItem,
  quantity,
  isEventWithCatalogProducts = false,
  isExtendedProductTypes = false,
  hasItemsInStock,
}: FormatProductParams): ProductForAnalyticsFields => {
  const engraving = formatEngraving(engravedItem)
  const pageType = getPageType()
  const isCustomized = !!item.customItem

  let productCategory =
    (item.productType == ProductTypeValues.OPTICAL ? 'OPTICS' : item.productType) || 'SUN'

  if (isExtendedProductTypes) {
    productCategory = mapCategory(item.productType || '')
  }

  let isAvailableInStock = item.availability?.available

  if (hasItemsInStock !== undefined) {
    isAvailableInStock = hasItemsInStock
  }

  const faData =
    item.isMySelection || mySelection.some(selection => selection.moco === item.moco)
      ? {
          Badges: 'FrameAdv',
          Fg_BestMatch: isBestMatch(item) ? '1' : '0',
        }
      : {
          Badges: '',
          Fg_BestMatch: '',
        }

  const productContext: ProductForAnalyticsFields = {
    Status: isAvailableInStock ? 'Available' : 'Out-of-stock',
    OosOptions: '',
    Category: productCategory,
    Type: isCustomized ? 'CP' : 'STD',
    Engraving: isCustomized ? getCPComponentValue(item, 'engraving') : engraving,
    // TODO: this is a workaround. Remove it once the Product input is aligned between standard and custom products from VM
    Price: item.price ? (Math.round(item.price.current * 100) / 100).toFixed(2) : '',
    PriceFull: item.price ? (Math.round(item.price.current * 100) / 100).toFixed(2) : '',
    Brand: item.brand.name,
    Size: isCustomized ? getCPSize(item) : `${item.size}`,
    Sku: item.sku ? item.sku : '',
    ModelName: item.name,
    MoCo: item.moco,
    LensColor: item.lensColorLabel || item.lensColor || '',
    LensTechnology: '',
    FrameColor: item.frameColorLabel ? item.frameColorLabel : item.frameColor || '',
    FrameTechnology: '',
    Shape: item.frameShape ? item.frameShape[0] : '',
    LensUPC: '',
    ModelCode: item.isCustomizable ? item.model : '',
    Lens: item.isCustomizable && item.lensColorLabel ? item.lensColorLabel : '',
    Frame: item.isCustomizable && item.frameColorLabel ? item.frameColorLabel : '',
    Case: item.isCustomizable ? getCPComponentValue(item, 'case') : '',
    Cloth: '',
    Units: item.isCustomizable ? '1' : quantity ? quantity.toString() : '',
    PerkCode: '',
    InsuranceAmount: '',
    InsuranceCode: '',
    Warranty: '',
    TaxRate: '',
    CancelledUnits: '',
    CancelledAmount: '',
    FrameType: 'STD',
    LensType: item.completePair ? 'RX' : 'PLANO',
    ...faData,
  }

  if (item.customizable) {
    productContext.Stock = `${item.availability?.ecommStock}`
    productContext.Image = `/${item.originalBrandCode}/${item.model}/${item.moco}_000A.png`
    productContext.Url = `${item.url}`
  }

  if (item.completePair) {
    productContext.Type = 'RX'
  }

  if (
    isPageWithCatalogProducts(pageType) ||
    isEventWithCatalogProducts ||
    isConfiguratorPage(pageType)
  ) {
    productContext.Vm_IsUpcSupported = item.vto ? '1' : '0'
    productContext.Rtr_IsUpcSupported = item.rtr ? '1' : '0'
    productContext.Conf_IsUpcSupported = item.customizable ? '1' : '0'
  }

  return productContext
}

interface OrderData {
  address?: string
  countryId: string
  subtot?: number
  currency?: string
  posReceiptId?: string
  shipping?: string
  shippingType?: string
}

export const formatOrderData = ({
  address,
  countryId,
  subtot,
  currency,
  posReceiptId,
  shipping,
  shippingType,
}: OrderData) => {
  const shippingMode = shipping === 'home' ? 'Delivery-at-home' : 'Ship-to-store'

  return {
    Order_CartId: '',
    Order_Id: posReceiptId ? `${posReceiptId}` : '',
    Order_Currency: currency ? currency : '',
    Order_GiftCardCode: '',
    Order_DiscountCode: '',
    Order_InsuranceCode: '',
    Order_Insured: '',
    Order_ProductsAmount: subtot ? (Math.round(subtot * 100) / 100).toFixed(2) : '',
    Order_DiscountAmount: '',
    Order_ShippingAmount: '0.00',
    Order_ShippingDiscount: '0.00',
    Order_ShippingTaxRate: '',
    Order_InsuranceAmount: '',
    Order_TaxAmount: '0.00',
    Order_ShippingMode: `${shippingMode}:${shippingType}`,
    Order_ShippingType: shipping,
    Order_ShippingAddress: address,
    Order_ShippingDeliveryDate: '0000-00-00',
    Order_State: countryId,
    Order_ZipCode: '',
    Order_PaymentType: 'POS',
    Order_PaymentInstallments: '',
    Order_Type: '',
    Order_SubscriptionFrequency: '',
    Order_PrescriptionMethod: '',
    Order_CancellationId: '',
    ShippingTo: posReceiptId ? posReceiptId : '',
  }
}

export const formatCartItems = (
  items: CartItem[],
  mySelection: FrameAdvisorState['mySelection'],
  engravingItems: EngravingSelectedOptionsList,
  isEventWithCatalogProducts = false,
  isExtendedProductTypes = false
): ProductForAnalytics => {
  return items.reduce((acc: ProductForAnalytics, item) => {
    const engraving = engravingItems[item.id]
    const UPC = item.product.isCustomizable ? item.product.model : item.product.UPC
    acc[UPC] = formatProduct({
      item: item.product,
      mySelection,
      engravedItem: engraving,
      quantity: item.quantity,
      isEventWithCatalogProducts,
      isExtendedProductTypes,
    })
    return acc
  }, {})
}

export const formatProducts = (
  products: StandardProduct[],
  mySelection: FrameAdvisorState['mySelection'],
  isExtendedProductTypes = false
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, product) => {
    // there is no cart ID or quantity information in CustomProduct type
    acc[product.UPC] = formatProduct({
      item: product,
      mySelection,
      engravedItem: undefined,
      quantity: undefined,
      isEventWithCatalogProducts: undefined,
      isExtendedProductTypes,
    })
    return acc
  }, {})
}

export const formatFrameAdvisorBaseData = (frameAdvisorState: FrameAdvisorState) => {
  const isFaceScanAppConnected = !!frameAdvisorState.fsaVideoId

  return {
    Fs_IsConnected: isFaceScanAppConnected ? '1' : '0',
  }
}

export const formatSessionId = (sessionId: string) => ({
  Session_Id: sessionId,
})

export const formatSearch = (searchValue: string) => ({
  Page_Section1: 'Search',
  Search_Keyword: searchValue,
  Search_View: SearchResultTypeView.serp,
  Search_Type: 'text',
  Events_SearchRun: '1',
})

export const formatCommonData = (
  analyticsConfig: AnalyticsConfig,
  store: Store,
  version = '',
  isEventWithCatalogProducts = false,
  payload?: AnalyticsEventPayload | undefined
): CommonDataLayer | IsVmSupportedLayer => {
  const deviceType = isIPadView() ? 'iPAD' : 'WALL43'
  const pageType = getPageType()
  const lang = store.langCode.split('-')[0].toUpperCase()

  // used the fallbacks on empty string because the analytics provider wraps service and config-override routes too
  const urlSlugs = window.location.pathname.split('/')
  const storeId = urlSlugs[2] || ''
  const langCode = urlSlugs[3] || ''
  const langCodeChuncks = langCode && langCode.split('-')
  const langCodeFromUrl = langCodeChuncks[0] || ''
  const countryIdFromUrl = langCodeChuncks[1] || ''

  const defaultLang = langCodeFromUrl.toUpperCase()
  const defaultCountyId = countryIdFromUrl

  const pageCountryMap: Record<string, string> = {
    GB: 'UK',
  }

  const getPageCountry = (countryId: string) => {
    countryId = countryId.toUpperCase()
    return pageCountryMap[countryId] || countryId
  }

  const commonDataContext: CommonDataLayer = {
    Page_Brand: mapAnalyticsShopperSlugs(analyticsConfig.shopperSlug) || '',
    Store_Id: store.globalStoreId || storeId,
    Page_Design: '',
    Page_Country: getPageCountry(store.countryId || defaultCountyId),
    Page_Language: lang || defaultLang,
    Page_DeviceType: deviceType,
    Page_Platform: analyticsConfig.pagePlatform || PAGE_PLATFORM,
    Page_PlatformRelease: version || '',
    Page_Type: PAGE_TYPES[pageType],
    Page_Section1: PAGE_SECTION_1[pageType] || '',
    Page_Section2: PAGE_SECTION_2[pageType] || '',
    Order_Currency: toUpperCase(store.currency.id),
    Store_Segment: store.storeSegment.toUpperCase() || '',
  }

  //Overwriting of common properties if the same were found in payload
  if (payload) {
    for (const [key, value] of Object.entries(commonDataContext)) {
      commonDataContext[key as keyof typeof commonDataContext] = payload[key] ?? value
    }
  }

  if (
    !isFrameAdvisorPage(pageType) &&
    (isPageWithCatalogProducts(pageType) || isEventWithCatalogProducts)
  ) {
    commonDataContext.Vm_IsBrowserSupported = store.vtoEnabled ? '1' : '0'
  }

  return commonDataContext
}

declare global {
  interface Window {
    gtag(eventKey: string, eventValue: string, utagData: UtagData): void
  }
}

export const analyticsFormatFASuggestions = (
  items: Product[]
): Record<string, AnalyticsFAProduct> => {
  return items.reduce((acc: Record<string, AnalyticsFAProduct>, item: Product) => {
    const { Category, Price, PriceFull, Type, Status } = formatProduct({ item })
    const UPC = item.customizable ? item.model : item.UPC
    acc[UPC] = {
      Category,
      Price,
      PriceFull,
      Type,
      Status,
      FrameType: item.frameAdvisorEvidences?.frameShape || '',
      Fg_BestMatch: isBestMatch(item) ? '1' : '0',
      LensType: item.frameAdvisorEvidences?.lensColorTreatment || '',
    }
    return acc
  }, {})
}

const createStringPayloadFromRecord = (record: Record<string, Maybe<string>>) =>
  Object.entries(record)
    .filter(([, v]) => !!v)
    .map(([k, v]) => `${k}=${upperFirst(v!)}`)
    .join(',')
