import { IconProps } from '@abstractTypes/icons'

export const MinusIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path d="M18 8.875H0V9.625H18V8.875Z" fill="#333333" />
    </svg>
  )
}
