import ConditionalRender from '@components/ConditionalRender'
import { LinkTypography } from '@components/core/Typography'
import useStripeBannerVisible from '@hooks/useStripeBannerVisible'
import { useThemeContext } from '@hooks/useThemeContext'
import { BannerContent, BannerText, BannerWrapper } from './styles'
import { StripeBannerProps } from './types'

const StripeBanner: React.FC<StripeBannerProps> = ({
  title,
  className,
  takeSpaceWhenEmpty,
  backgroundColor,
  icon,
  isVisible,
  isSticky,
}) => {
  useStripeBannerVisible(isVisible)

  return (
    <StripeBannerContent
      title={title}
      className={className}
      takeSpaceWhenEmpty={takeSpaceWhenEmpty}
      isVisible={isVisible}
      backgroundColor={backgroundColor}
      icon={icon}
      isSticky={isSticky}
    />
  )
}

interface StripeBannerContentProps extends StripeBannerProps {
  isVisible: boolean
  isSticky?: boolean
}

export const StripeBannerContent: React.FC<StripeBannerContentProps> = ({
  isVisible,
  takeSpaceWhenEmpty,
  className,
  backgroundColor,
  title,
  icon,
  isSticky,
}) => {
  const theme = useThemeContext()
  const bannerIconComponent = icon && <>{icon}</>
  const showBanner = isVisible || takeSpaceWhenEmpty

  return (
    <ConditionalRender
      condition={showBanner}
      render={() => (
        <BannerWrapper
          id="stripe-banner"
          className={className}
          isSticky={isSticky}
          asPlaceholder={!isVisible && !!takeSpaceWhenEmpty}
        >
          <ConditionalRender
            condition={isVisible}
            render={() => (
              <BannerContent bgColor={backgroundColor || theme.colors.highlight01}>
                {bannerIconComponent}
                <LinkTypography href="" aria-labelledby="stripe-banner" />
                <BannerText color="primaryLight">{title}</BannerText>
              </BannerContent>
            )}
          />
        </BannerWrapper>
      )}
    />
  )
}

export default StripeBanner
