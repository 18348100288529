import { Device } from '@abstractTypes/utils'
import { altNavigation, altNavigationZoom, landscape, tablet } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'
import {
  animationDuration,
  landscapeTabletSliderWidth,
  landscapeTowerSliderWidth,
} from '../styleConsts'

import PrivacyPolicyTooltip from '@components/PrivacyPolicyTooltip'
import { PrivacyPolicyButton } from '@components/PrivacyPolicyTooltip/styles'

export const NuanceHeaderStyled = styled.div<{ isSticky?: boolean }>`
  z-index: 3;
  position: absolute;
  inset-inline: 0 0;
  inset-block-start: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: width ${animationDuration} linear;
  margin: auto;
  ${landscape(`
    background-color: transparent;
    inline-size: ${landscapeTowerSliderWidth};
    inset-inline: unset;
  `)}
  ${tablet(
    `
    inset-block-start: 0};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${landscapeTabletSliderWidth};
  `
  )}
  ${({ theme }) => `
    ${altNavigation(`
      position: sticky;
      inset-block-start: 0;
      min-block-size: ${pxToRem(104)};
      padding-block: ${pxToRem(24)} ;
      padding-inline:  ${pxToRem(48)};
      background-color: ${theme.colors.primaryLight};
    `)}
  `}

  ${altNavigationZoom(
    [150, 175, 200],
    `
      position: relative;
    `
  )}
`

export const PrivacyPolicyTooltipStyled = styled(PrivacyPolicyTooltip)`
  inset-block-start: unset;
  min-block-size: unset;
  align-self: center;
  ${tablet(`
    inset-block-start: unset;
  `)}
  ${landscape(
    Device.tablet,
    `
    inset-block-start: unset;
  `
  )}

  ${PrivacyPolicyButton} {
    margin-block-start: 0;
  }
`

export const LogoStyled = styled.div`
  margin-inline: auto;
`
