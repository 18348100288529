import { getSiteAbsolutePath, getSiteOrigin } from '@libs/url'
import { isIPadView } from '@libs/utils'
import { get } from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export interface UseQRCodeScanner {
  assetsLogoSrc: string
  timeout: number
  topLabel?: string
  bottomLabel?: string
}

declare global {
  interface Window {
    webkit?: {
      messageHandlers?: {
        iosClickListener?: {
          postMessage: (args: unknown) => void
        }
      }
    }
  }
}

export const isQRCodeScannerAvailable = () =>
  isIPadView() && !!window.webkit && get(window, 'webkit.messageHandlers.iosClickListener')

export const useQRCodeScanner = ({
  timeout,
  assetsLogoSrc,
  topLabel,
  bottomLabel,
}: UseQRCodeScanner) => {
  const { t } = useTranslation()
  const basePath = getSiteAbsolutePath()
  const origin = getSiteOrigin()
  const topLabelTranslation = topLabel ? topLabel : 'Barcode.topLabel'
  const bottomLabelTranslation = bottomLabel ? bottomLabel : 'Barcode.bottomLabel'

  return useCallback(() => {
    const iosClickListener = get(window, 'webkit.messageHandlers.iosClickListener')
    iosClickListener &&
      iosClickListener.postMessage({
        action: 'open_scanner',
        logo: assetsLogoSrc,
        topLabel: t(topLabelTranslation),
        bottomLabel: t(bottomLabelTranslation),
        returnUrl: `${origin}${basePath}/retrieve-session`,
        timeout: timeout.toString(),
      })
  }, [t, timeout, origin, basePath, assetsLogoSrc])
}
