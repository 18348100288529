import React from 'react'
import { FormInput } from '@components/core/FormInput'
import { Select, SelectOption } from '@components/core/Select'
import { Spin } from '@components/Loading'
import { FormInputSuggesterContainer, LoadingOverlay } from '@components/Checkout/styles'
import { FormInputSuggesterProps } from './types'

export const FormInputSuggester: React.FC<FormInputSuggesterProps> = ({
  name,
  value,
  placeholder,
  type = 'text',
  label,
  fieldDescription,
  required,
  hasFeedback,
  validateStatus,
  help,
  suggestions,
  getInputProps,
  getSuggestionItemProps,
  loading,
  className,
}) => {
  const props = getInputProps({ options: { value, type, name } })

  const children =
    suggestions &&
    suggestions.map(suggestion => {
      const remappedSuggestion = {
        ...suggestion,
        id: suggestion.placeId || Date.now().toString(),
      }
      return (
        <React.Fragment key={remappedSuggestion.id}>
          <SelectOption
            value={remappedSuggestion.description}
            {...getSuggestionItemProps(remappedSuggestion, {
              onTouchStart: (evt: React.TouchEvent) => {
                const clickEvt = new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: true,
                })
                evt.target.dispatchEvent(clickEvt)
              },
            })}
          >
            {remappedSuggestion.description}
          </SelectOption>
        </React.Fragment>
      )
    })
  return (
    <FormInputSuggesterContainer>
      {loading && (
        <LoadingOverlay>
          <Spin />
        </LoadingOverlay>
      )}
      <FormInput
        name={name}
        value={value}
        onChange={value => props.onChange({ target: { value: value } })}
        label={label}
        type={type}
        placeholder={placeholder}
        required={required}
        hasFeedback={hasFeedback}
        validateStatus={validateStatus}
        fieldDescription={fieldDescription}
        help={help}
        className={className}
      />
      <Select forceVisible={!!children?.length}>{children}</Select>
    </FormInputSuggesterContainer>
  )
}
