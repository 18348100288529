import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const ModalContent = styled.div`
  display: block;
`

export const ModalContentContainer = styled.div`
  text-align: center;
  margin-block-end: ${pxToRem(30)};
`

export const ModalContentCTAContainer = styled.div`
  text-align: center;

  button {
    min-inline-size: ${pxToRem(198)};
    margin-block: 0;
    margin-inline: ${pxToRem(10)};
  }
`
