import { Heading1, Heading2, Text } from '@components/core/Typography'
import config from '@config/index'
import { getConfigFromSessionStorage } from '@libs/configOverride'
import { forEach, isArray, isObject } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { ServiceApi } from './ServiceApi'

export const ServiceContent = styled.div`
  block-size: 100%;
  overflow: scroll;
  padding: 3rem;
  color: #000;

  h1 {
    font-size: 3rem;
  }
`

export const TableDataContent = styled.table`
  inline-size: 100%;
  font-family: monospace;
  border-collapse: collapse;

  tr {
    border-block-end: 1px solid #ccc;

    &:hover {
      background-color: papayawhip;
    }
  }

  td {
    padding: 0.3rem;
  }
`

export const Notes = styled(Text)`
  padding: 0.3rem;
  text-align: end;
`
const Title = styled(Heading1)`
  margin-block-end: 0;
  padding-block-end: 0;
`
const OverrideTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.functional01};
`

interface FlatData {
  [k: string]: string
}

function copyTextToClipboard(text: string) {
  if (!window.navigator.clipboard) {
    return
  }
  navigator.clipboard.writeText(text)
}

const flattenObject = (
  obj: Record<string, any>,
  parent: string = '',
  res: Record<string, any> = {}
): Record<string, any> => {
  forEach(obj, (value, key) => {
    const propName = parent ? `${parent}.${key}` : key
    if (isObject(value) && !isArray(value)) {
      flattenObject(value, propName, res)
    } else {
      res[propName] = value
    }
  })
  return res
}

const TableData = ({ data }: { data: Record<string, any> }) => {
  const flatData: FlatData = flattenObject(data)

  return (
    <React.Fragment>
      <TableDataContent>
        <tbody>
          {Object.keys(flatData).map((r, i) => (
            <tr key={i}>
              <td>
                <Text weight="bold">{r}</Text>
              </td>

              <td onDoubleClick={() => copyTextToClipboard(flatData[r])}>{String(flatData[r])}</td>
            </tr>
          ))}
        </tbody>
      </TableDataContent>
      <Notes>Double click on value to coNotesy data to clipboard</Notes>
    </React.Fragment>
  )
}

const RawJson = ({ json }: { json: unknown }) => (
  <details style={{ padding: '.5rem', marginBottom: '2rem' }}>
    <summary>JSON</summary>
    <pre>{JSON.stringify(json, undefined, 4)}</pre>
  </details>
)

function getBaseDomain(url: string) {
  return typeof url === 'string'
    ? url.split('/').reduce((url, seg, i) => (i < 3 ? (url += seg + '/') : url), '') + 'service'
    : ''
}

const Service: React.FunctionComponent = () => {
  const configOverrideEnabled = getConfigFromSessionStorage() !== null

  return (
    <ServiceContent>
      <Title>Service Info</Title>
      {configOverrideEnabled && (
        <OverrideTitle>
          Some local configurations override are enabled on your local environment
        </OverrideTitle>
      )}

      <Heading2>UI</Heading2>

      <TableData data={config} />
      <RawJson json={config} />

      <Heading2>API</Heading2>

      <ServiceApi apiEndpoint={getBaseDomain(config.logServerUrl)}>
        {({ loading, error, json }) => (
          <React.Fragment>
            {loading && <Text>Loading...</Text>}
            {json && (
              <React.Fragment>
                <TableData data={json} />
                <RawJson json={json} />
              </React.Fragment>
            )}

            {error && <pre>{JSON.stringify(error, undefined, 4)}</pre>}
          </React.Fragment>
        )}
      </ServiceApi>
    </ServiceContent>
  )
}

export default Service
