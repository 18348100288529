import { Device } from '@abstractTypes/utils'
import { Text } from '@components/core/Typography'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const HeaderContainer = styled.div<{
  isAlternativeNavigation?: boolean
  staticPosition?: boolean
  isSticky?: boolean
}>`
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
  z-index: 2;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  min-block-size: ${pxToRem(134)};
  ${tablet(
    `min-block-size: ${clampValues(90, 110)}; max-block-size: ${clampValues(
      90,
      110
    )}; block-size: inherit;`
  )}
  ${landscape(Device.tablet, `min-block-size: ${clampValuesLandscape(90, 110)};`)}

  a {
    &[data-element-id='MainNav_Logo'] {
      img {
        margin-block: 0;
        margin-inline: auto;
        object-fit: initial;
        display: block;
        block-size: ${pxToRem(134)};
        inline-size: ${pxToRem(280)};

        ${tablet(`
          block-size: ${clampValues(90, 110)};
          inline-size: ${clampValues(188, 230)};
        `)}

        ${landscape(
          Device.tablet,
          `
          block-size: ${clampValuesLandscape(90, 110)};
          inline-size: ${clampValuesLandscape(188, 230)};
          `
        )}
      }
    }
    block-size: 100%;
  }

  ${({ isSticky, theme }) =>
    isSticky &&
    `
    background-color: ${theme.colors.primaryLight};
  `}
`

export const ProfileAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset-inline-end: ${pxToRem(41)};
`

export const ProfileAvatarLabel = styled(Text)`
  max-inline-size: ${pxToRem(80)};
  text-align: end;
`

export const ProfileAvatarContainer = styled.button`
  margin-inline-start: ${pxToRem(16)};
`
