import { IconProps } from '@abstractTypes/icons'


export const IPadIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 287 394"
      stroke={stroke}
    >
      <defs>
        <style>{'.cls-1,.cls-2{fill:#fff;}.cls-2{opacity:0.5;}.cls-3{fill:#000101;}'}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M249.3,0H37.7A37.7,37.7,0,0,0,0,37.7V356.3A37.7,37.7,0,0,0,37.7,394H249.3A37.7,37.7,0,0,0,287,356.3V37.7A37.7,37.7,0,0,0,249.3,0ZM275,358.24a25.39,25.39,0,0,1-25.39,25.39H38.39A25.39,25.39,0,0,1,13,358.24V36.33A25.39,25.39,0,0,1,38.39,10.94H249.61A25.39,25.39,0,0,1,275,36.33Z"
          />
          <rect className="cls-1" x={94} y={354} width={98} height={12} rx={6} />
          <path
            className="cls-1"
            d="M193.93,184.66H157.28c-.61-.57-.39-1.27-.39-1.91q0-11.73,0-23.47c0-2.58,1.11-3.78,3.66-3.79,10-.05,20,0,29.93,0,1.92,0,3.34,1.18,3.37,2.77"
          />
          <path
            className="cls-2"
            d="M112.67,250.05c-4.73,0-9.47,0-14.2,0-3,0-4-1.07-4-4.12,0-9.23,0-18.46,0-27.69,0-2.14,1-3.4,3.24-3.41,10.13,0,20.27,0,30.4.06a3.36,3.36,0,0,1,3.57,3.67c0,9.15.14,18.3.15,27.45,0,2.74-1.44,4.1-4.2,4.11-5,0-10,0-15,0Z"
          />
          <path className="cls-3" d="M82.49,190.57H206.06" />
          <path className="cls-3" d="M206.06,198H82.49" />
          <path
            className="cls-2"
            d="M194,218.16a3.08,3.08,0,0,0-3.32-3.27c-10.13,0-20.26,0-30.4,0a3.24,3.24,0,0,0-3.38,3.46q0,14.09,0,28.17a3.32,3.32,0,0,0,3.6,3.53c5,0,10,0,14.95,0s10,0,15,0c2.28,0,3.69-1.29,3.7-3.47Zm-10.37,24.7h-8.83c-2.95,0-5.89,0-8.84,0a2,2,0,0,1-2.13-2.08q0-8.33,0-16.64a1.92,1.92,0,0,1,2-2q9,0,18,0a1.83,1.83,0,0,1,2,1.94l0,16.79A2,2,0,0,1,183.61,242.86Z"
          />
          <path
            className="cls-1"
            d="M94.56,184.54c0-8.87-.06-17.59.06-26.3a2.81,2.81,0,0,1,2.85-2.68c10.22-.08,20.43,0,30.65-.11,1.65,0,1.61,2.31,3.37,1.56v27.53Zm9.6-21.44c.72,1.87-1.94,1.64-1.8,3.56.3,4.22.08,8.48.11,12.72,0,.59-.24,1.22.54,1.73a8.31,8.31,0,0,0,5.28,1.6c3.56-.23,7.14-.06,10.72-.07,3.16,0,4.22-1,4.23-4.11,0-4.24-.1-8.49,0-12.73.08-2.12-.76-2.75-2.72-2.72C115.24,163.14,109.92,163.1,104.16,163.1Z"
          />
          <path
            className="cls-2"
            d="M192.64,204.63a3.77,3.77,0,0,1-2.43,2.75c-3.8.31-7.63.11-11.46.11-.84,0-.64-.84-1.23-1.45-.12,1.52-1,1.5-2,1.5-3.49,0-7-.38-10.45.16-3.17.51-5.06-2-7.75-2.3-.16,0-.34-.52-.37-.81-.05-.49.28-.75.75-.79.91-.06,1.82-.16,2.74-.16,9.8,0,19.61,0,29.42,0,1,0,2.81-.78,2.76.95"
          />
          <path
            className="cls-2"
            d="M147.74,203.88a4.32,4.32,0,0,1,.57,2.62c0,6.81.05,13.62,0,20.44,0,3.34-1.37,4.69-4.43,4.56a3.39,3.39,0,0,1-3.56-3.53c-.12-5.64-.06-11.28-.07-16.92,0-4-1.13-5.13-5.11-5.13-4.15,0-8.3,0-12.45,0a26.56,26.56,0,0,1-3-.3c-.63-.06-1.18-.21-1.08-1s.63-.69,1.11-.69Z"
          />
          <path
            className="cls-2"
            d="M140.45,237.05c2.6-2,4.94-2.56,7.53.07a34.61,34.61,0,0,1,.17,9.76c-.26,2.49-1.51,3.25-4.54,3.11a3.2,3.2,0,0,1-3.33-3.34A55.38,55.38,0,0,1,140.45,237.05Z"
          />
          <path
            className="cls-1"
            d="M148.14,162.84c-.5,1.56.87,3.79-1.25,5.54-1.54,1.28-3.17.86-4.58.52a2.8,2.8,0,0,1-2-2.92c.13-2.23,0-4.48,0-6.72,0-2.7,1.14-3.77,3.76-3.79,2.8,0,4,1,4,3.74C148.16,160.29,148.14,161.37,148.14,162.84Z"
          />
          <path
            className="cls-1"
            d="M140.41,184.56a64.63,64.63,0,0,1,.1-8.49c.23-1.68,1.7-2.67,3.72-2.67s3.5,1,3.73,2.67a64.63,64.63,0,0,1,.1,8.49Z"
          />
          <path
            className="cls-2"
            d="M103.05,203.88c1.9,0,3.81,0,5.71,0,.55,0,1.23-.06,1.24.81s-.65.78-1.22.86a50,50,0,0,1-11.6.14c-.62,0-1.46,0-1.47-.92,0-1.21,1-.87,1.64-.89,1.9,0,3.8,0,5.7,0Z"
          />
          <rect className="cls-1" x={82.49} y={190.51} width={124} height={9} />
        </g>
      </g>
    </svg>
  )
}
