import { UpcItem } from '@abstractTypes/graphqlTypes'
import { PDPProduct, StandardProduct } from '@abstractTypes/product'
import { StockItem } from '@abstractTypes/realTimeCheck'
import { addAvailability, updateAvailability } from '@actions/cart'
import config from '@config/index'
import { checkAvailabilityPDP } from '@data'
import { useStoreContext } from '@hooks/useStoreContext'
import { isCurrentProductInStock } from '@libs/utils'
import { useEffect, useMemo, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface CheckAvailabilityState {
  product: PDPProduct
  stockItems: StockItem[]
  realStockItems: StockItem[]
  hasStandardStock: boolean
  hasPrescriptionStock: boolean
  called: boolean
  loading: boolean
}

const initialState = (product: PDPProduct): CheckAvailabilityState => ({
  product,
  stockItems: [],
  realStockItems: [],
  hasStandardStock: false,
  hasPrescriptionStock: false,
  called: false,
  loading: false,
})

export interface CheckAvailabilityAction {
  type: string
  payload?: StockItem[]
}

const reducer = (
  state: CheckAvailabilityState,
  action: CheckAvailabilityAction
): CheckAvailabilityState => {
  switch (action.type) {
    case 'CHECK_AVAILABILITY_TOGGLE_LOADING':
      return {
        ...state,
        loading: !state.loading,
      }
    case 'CHECK_AVAILABILITY_SUCCESS':
      const stockItems = action.payload || []
      const hasPrescriptionStock = isCurrentProductInStock(
        stockItems.filter(item => item.prescriptionApplied),
        state.product.UPC,
        false
      )
      const hasStandardStock = isCurrentProductInStock(
        stockItems.filter(item => !item.prescriptionApplied),
        state.product.UPC,
        false
      )
      return {
        ...state,
        stockItems: action.payload || [],
        realStockItems: action.payload || [],
        hasPrescriptionStock,
        hasStandardStock,
        called: true,
      }
    case 'CHECK_AVAILABILITY_ERROR':
      return {
        ...state,
        stockItems: action.payload || [],
        realStockItems: action.payload || [],
        hasPrescriptionStock: true,
        hasStandardStock: true,
        called: true,
        loading: false,
      }
    default:
      return state
  }
}

export const useCheckWishlistProductInStock = () => {
  const items = useSelector(s => s.wishlist.items)
  const dispatch = useDispatch()
  const store = useStoreContext()
  const sessionToken = useSelector(state => state.session.token)
  const { storeId, prescriptionEnabled, pdpRealtimeCheckEnabled } = store

  const payload = useMemo<UpcItem[]>(() => {
    const p = items.reduce<UpcItem[]>((acc, w) => {
      if (w.roxable && prescriptionEnabled) {
        acc.push({
          upc: w.UPC,
          prescriptionApplied: true,
        })
      }
      acc.push({
        upc: w.UPC,
        prescriptionApplied: false,
      })
      return acc
    }, [])

    return p
  }, [items, prescriptionEnabled])

  useEffect(() => {
    if (!pdpRealtimeCheckEnabled) return
    const fetchData = async () => {
      try {
        dispatch(updateAvailability(true))
        const data = await checkAvailabilityPDP(
          {
            data: {
              storeId: storeId,
              upcs: payload,
            },
          },
          config.availabilityTimeout
        )
        const stockItems = data.data?.availability.stockItems
        if (stockItems) {
          dispatch(addAvailability(stockItems))
        }
      } catch (e) {
        dispatch(addAvailability(payload.map(i => ({ ...i, qty: 1 }))))
      } finally {
        dispatch(updateAvailability(false))
      }
    }

    fetchData()
  }, [payload, sessionToken, storeId, dispatch, pdpRealtimeCheckEnabled])
}

export const useCheckProductInStock = (product: PDPProduct, checkProductVariants?: boolean) => {
  const store = useStoreContext()
  const { storeId, pdpRealtimeCheckEnabled, prescriptionEnabled } = store
  const [checkAvailabilityState, dispatch] = useReducer(reducer, initialState(product))

  const stockItemPayload: UpcItem[] = useMemo(() => {
    let payload: UpcItem[] = []
    const isPrescriptionApplied = !!(product.roxable && prescriptionEnabled)

    const currentProduct = {
      upc: product.UPC,
      prescriptionApplied: false,
    }

    if (checkProductVariants) {
      const otherVariants: UpcItem[] =
        product.variants?.map((variant: StandardProduct) => ({
          upc: variant.UPC,
          prescriptionApplied: false,
        })) || []

      payload = [currentProduct, ...otherVariants]
    } else {
      const otherSizes: UpcItem[] =
        product.sizes?.map((size: StandardProduct) => ({
          upc: size.UPC,
          prescriptionApplied: false,
        })) || []
      payload = [currentProduct, ...otherSizes]
    }

    if (isPrescriptionApplied) {
      payload.push(
        ...payload.map(item => ({
          upc: item.upc,
          prescriptionApplied: true,
        }))
      )
    }

    return payload
  }, [prescriptionEnabled, product])

  useEffect(() => {
    if (pdpRealtimeCheckEnabled) {
      dispatch({ type: 'CHECK_AVAILABILITY_TOGGLE_LOADING' })
      checkAvailabilityPDP(
        {
          data: {
            storeId: storeId,
            upcs: stockItemPayload,
          },
        },
        config.availabilityTimeout
      )
        .then(res => {
          const stockItems = res?.data?.availability.stockItems || []
          dispatch({
            type: 'CHECK_AVAILABILITY_SUCCESS',
            payload: stockItems,
          })
          dispatch({ type: 'CHECK_AVAILABILITY_TOGGLE_LOADING' })
        })
        .catch(() => {
          dispatch({
            type: 'CHECK_AVAILABILITY_ERROR',
            payload: stockItemPayload.map(i => ({ ...i, qty: 1 })),
          })
        })
    }
  }, [product])

  return checkAvailabilityState
}
