import PurchaseConfigureModal from '@components/PurchaseConfigure/PurchaseConfigureModal'
import usePurchaseOptions from '@components/PurchaseConfigure/hooks/usePurchaseOptions'
import useShowPurchaseModal from '@components/PurchaseConfigure/hooks/useShowPurchaseModal'
import ButtonWithLoader from '@components/core/ButtonWithLoader'
import { Heading5, Text } from '@components/core/Typography'
import { useIsCurrentProductInStock } from '@hooks/useIsCurrentProductInStock'
import { landscape } from '@libs/media'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AddToCartProps } from './types'

const NoStockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${landscape(
    `
    text-align: start;
  `
  )}
`

const AddToCart: React.FC<AddToCartProps> = ({
  product,
  handleClickToCart,
  stockItems,
  analyticsDataDescription,
  loading,
  hasStock,
  customLabel,
  disabled,
}) => {
  const { t } = useTranslation()
  const [isPurchaseConfigModalVisible, setIsPurchaseConfigModalVisible] = useState<boolean>(false)
  const { UPC } = product
  const hasNonPrescriptionStock = useIsCurrentProductInStock(
    stockItems.filter(item => !item.prescriptionApplied),
    UPC
  )
  const hasPrescriptionStock = useIsCurrentProductInStock(
    stockItems.filter(item => item.prescriptionApplied),
    UPC
  )

  const { showPrescriptionSwitch, showMixMatchSwitch } = usePurchaseOptions(product)
  const showPurchaseModal = useShowPurchaseModal(product)

  const isCustomProduct = product.customizable

  const onClick = () => {
    if (showPurchaseModal) {
      setIsPurchaseConfigModalVisible(true)
    } else {
      handleClickToCart(false, false)
    }
  }

  const handleModalClickToCart = useCallback(
    (addPrescription = false, addMixMatch = false) => {
      setIsPurchaseConfigModalVisible(false)
      handleClickToCart(addPrescription, addMixMatch)
    },
    [handleClickToCart]
  )

  const renderPurchaseConfigModal = () => (
    <PurchaseConfigureModal
      visible={isPurchaseConfigModalVisible}
      showPrescriptionSwitch={showPrescriptionSwitch}
      showMixMatchSwitch={showMixMatchSwitch}
      hasNonPrescriptionStock={hasNonPrescriptionStock}
      hasPrescriptionStock={hasPrescriptionStock}
      onSubmit={handleModalClickToCart}
      onClose={() => setIsPurchaseConfigModalVisible(false)}
      product={product}
    />
  )

  const renderNoStock = () => (
    <NoStockContainer>
      <Heading5 weight="bold">{t('PDP.backSoon')}</Heading5>
      {!isCustomProduct && <Text>{t('PDP.checkOtherColorsSizes')}</Text>}
    </NoStockContainer>
  )

  return (
    <>
      {!hasStock && !loading ? (
        renderNoStock()
      ) : (
        <ButtonWithLoader
          loading={loading}
          fullWidth
          textUppercase
          dataAttrs={{
            id: 'Prod_AddCart_RXModal',
            description: analyticsDataDescription,
          }}
          onClick={onClick}
          disabled={disabled}
        >
          {customLabel ?? t('Core.addToBag')}
        </ButtonWithLoader>
      )}
      {renderPurchaseConfigModal()}
    </>
  )
}

export default AddToCart
