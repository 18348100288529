export const toUpperCase = (str = '') => String(str).toUpperCase()
export const toLowerCase = (str = '') => String(str).toLowerCase()

export const equals = (str1 = '', str2 = '') => toLowerCase(str1) === toLowerCase(str2)

export const arrayIncludes = (arr: ReadonlyArray<string>, value: string) =>
  Boolean(arr.find(v => equals(v, value)))

export const findElementById = <T extends { id: string }>(arr: Array<T> = [], id: string) => {
  return arr.find(e => equals(e.id, id))
}

export const arrayExclude = (arr: string[] = [], value: string) =>
  arr.filter(v => !equals(v, value))

export const findParameterCaseInsentitive = <T extends object>(obj: T, key?: string) =>
  Object.entries(obj).find(([objectKey]) => equals(objectKey, key))?.[1]
