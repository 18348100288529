import { AccordionSingleProps } from '@abstractTypes/accordion'
import { CaretDownThinIcon } from '@components/core/Icons'
import { pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { uniqueId } from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import {
  AccordionContent,
  AccordionIcon,
  AccordionLayoutWrapper,
  AccordionSubtitle,
  AccordionTitle,
} from './styles'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { Heading4, Heading5 } from '../Typography'
import { useAriaFocusTrap } from '@hooks/useAriaFocusTrap'
import { useCombinedRefs } from '@hooks/useCombinedRefs'

export const AccordionLayout: React.FC<AccordionSingleProps> = ({
  children,
  className,
  title,
  subtitle,
  renderAccordionIcon,
  dataAttr,
  isOpened = false,
  onClick,
  onTitleClick,
  useFocusTrap,
  ...props
}) => {
  const { id: dataId, title: dataTitle } = dataAttr || {}
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const accordionContentId = useMemo(uniqueId, [])
  const { zoomLevel, isAlternativeNavigation } = useNavigationContext()

  const onElementClick = useCallback(() => {
    onClick && wrapperRef.current && onClick(wrapperRef.current)
  }, [onClick])

  const handleTitleClick = useCallback<React.MouseEventHandler<HTMLElement>>(
    e => {
      onTitleClick && onTitleClick(e)
    },
    [onTitleClick]
  )
  const { setRef } = useAriaFocusTrap(!!useFocusTrap && isOpened)
  const combinedRef = useCombinedRefs<HTMLDivElement>(wrapperRef, setRef)

  return (
    <AccordionLayoutWrapper
      className={className}
      ref={combinedRef}
      onClick={onElementClick}
      aria-expanded={isOpened}
      aria-controls={accordionContentId}
      {...(dataAttr && dataId && { 'data-element-id': dataId, 'data-test': dataId })}
      {...(isOpened && dataAttr && { 'data-analytics_available_call': '0' })}
      {...props}
    >
      <AccordionTitle
        onClick={handleTitleClick}
        data-test={dataTitle}
        role="tab"
        aria-selected={isOpened}
        aria-labelledby={isOpened ? `accordion-tabpanel-${accordionContentId}` : ''}
      >
        {zoomLevel > 150 && isAlternativeNavigation ? (
          <Heading5 weight="bold">{title}</Heading5>
        ) : (
          <Heading4 weight="bold">{title}</Heading4>
        )}
        {subtitle && <AccordionSubtitle>{subtitle}</AccordionSubtitle>}
        <AccordionIcon isOpen={isOpened}>
          {renderAccordionIcon ? (
            renderAccordionIcon()
          ) : (
            <CaretDownThinIcon
              height={pxToRem(isIPadView() ? 13 : 17, zoomLevel / 100)}
              width={pxToRem(isIPadView() ? 24 : 32, zoomLevel / 100)}
            />
          )}
        </AccordionIcon>
      </AccordionTitle>

      <AccordionContent
        id={`accordion-tabpanel-${accordionContentId}`}
        isOpen={isOpened}
        role="tabpanel"
        aria-disabled={!isOpened}
        aria-hidden={!isOpened}
      >
        {children}
      </AccordionContent>
    </AccordionLayoutWrapper>
  )
}
