import {
  FlowsActionTypes,
  SET_BARCODE_FLOW,
  SET_CUSTOMER_ORDER_FLOW,
  SET_FRAME_ADVISOR_FLOW,
} from '@actions/flows'

export interface FlowsState {
  barcodeFlow: boolean
  customerOrderFlow: boolean
  frameAdvisorFlow: boolean
}

const initialState: FlowsState = {
  barcodeFlow: false,
  customerOrderFlow: false,
  frameAdvisorFlow: false,
}

export default (state = initialState, action: FlowsActionTypes) => {
  switch (action.type) {
    case SET_BARCODE_FLOW:
      return { ...state, barcodeFlow: action.payload.show }
    case SET_CUSTOMER_ORDER_FLOW:
      return { ...state, customerOrderFlow: action.payload.show }
    case SET_FRAME_ADVISOR_FLOW:
      return { ...state, frameAdvisorFlow: action.payload.show }

    default:
      return state
  }
}
