import { landscape, zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'

import styled from 'styled-components'

export const ICON_SIZE = '2rem'
export const IMAGE_RATIO = '62.4%'

export const IconContainer = styled.div<{ row?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  ${zoomPortrait(
    [110],
    `
    inline-size: ${pxToRem(36)};
  `
  )}
  ${zoomPortrait(
    [125],
    `
    inline-size: ${pxToRem(40)};
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(56)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(64)};
  `
  )}

  img {
    object-fit: scale-down;
    inline-size: calc(${ICON_SIZE} * 0.6);
    block-size: calc(${ICON_SIZE} * 0.6);
  }
`

export const PolarizedIconContainer = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 1.5rem;
  ${landscape(`
    inset-block-start: 11rem;
    inset-inline-start: 0.909rem;
  `)}
`
