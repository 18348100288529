import { ProductSubdivisions } from '@abstractTypes/filter'
import { pushToDataTrack } from '@providers/analyticsProvider'

export const usePushToDataTrack = () => (p: ProductSubdivisions, i: number) => {
  pushToDataTrack({
    id: 'Click',
    data_element_id: `MainNav_Results_Tab${i}`,
    data_description: p,
  })
}
