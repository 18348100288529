import { isIosVersionFrom, isIPadView } from '@libs/utils'
import { useMemo } from 'react'
import { useStoreContext } from './useStoreContext'

export const useVMVisibility = () => {
  const store = useStoreContext()

  return useMemo(() => {
    const isIpad = isIPadView()
    const ipadEnabled = isIpad && store.vtoIPadEnabled && isIosVersionFrom('11.4.0')
    const monitorEnabled = !isIpad && store.vtoMonitorEnabled

    return {
      isVMEnabled: store.vtoEnabled && (ipadEnabled || monitorEnabled),
      isVMIpadEnabled: store.vtoEnabled && ipadEnabled,
      isVMMonitorEnabled: store.vtoEnabled && monitorEnabled,
    }
  }, [store.vtoEnabled, store.vtoIPadEnabled, store.vtoMonitorEnabled])
}
