import devConfig from './config.dev'
import productionConfig from './config.production'

let masterConfig = devConfig

if (process.env.NODE_ENV === 'production') {
  masterConfig = productionConfig
}

export default masterConfig
