import config from '@config/config.base'
import { useTranslateFilterValue } from '@hooks/useTranslateFilterValue'
import { isChanelBrand, removeSpaces } from '@libs/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DetailListContainer } from '../styles'
import DetailsRow from './DetailsRow'
import { DetailsProps } from './types'

export const EyeglassesDetailsList: React.FC<DetailsProps> = ({ product, className = '' }) => {
  const { t } = useTranslation()
  const translateFilterValue = useTranslateFilterValue()
  const shouldDisplayFitInfo = !isChanelBrand(product.brand)
  return (
    <DetailListContainer className={className}>
      <DetailsRow label={t('ProductDescription.brand')} value={product.brand.name} />
      <DetailsRow label={t('ProductDescription.name')} value={product.name} />
      <DetailsRow label={t('ProductDescription.productCode')} value={product.UPC} />
      <DetailsRow
        label={t('ProductDescription.modelColor')}
        value={`${product.model} ${product.colorCode}`}
      />

      <DetailsRow
        label={t('ProductDescription.frameShape')}
        value={translateFilterValue(product.frameShape, 'frameShape')}
      />
      <DetailsRow
        label={t('ProductDescription.frameMaterial')}
        value={translateFilterValue(product.frameMaterial, 'frameMaterial')}
      />
      <DetailsRow
        label={t('ProductDescription.frameColor')}
        value={translateFilterValue(product.frameColorLabel, 'frameColorLabel')}
      />
      <DetailsRow
        label={t('ProductDescription.roxable')}
        value={product.roxable ? t('Core.yes') : t('Core.no')}
      />
      <DetailsRow
        label={t('ProductDescription.progressiveFriendly')}
        value={product.progressiveFriendly ? t('Core.yes') : t('Core.no')}
      />
      {!!product.lensHeight && (
        <DetailsRow
          label={t('ProductDescription.lensHeight')}
          value={translateFilterValue(product.lensHeight, 'lensHeight')}
        />
      )}
      {!!product.lensMaterial && (
        <DetailsRow
          label={t('ProductDescription.lensMaterial')}
          value={translateFilterValue(product.lensMaterial, 'lensMaterial')}
        />
      )}

      <DetailsRow
        label={t('ProductDescription.lookBest')}
        value={translateFilterValue(product.faceShape, 'faceShape')}
      />
      <DetailsRow label={t('ProductDescription.size')} value={product.sizeFull || product.size} />
      <DetailsRow
        label={t('sizeAndFit.sizeContent.hingeToHingeMeasureLabel')}
        value={`${product.hingeDistance}mm`}
      />
      {shouldDisplayFitInfo && (
        <>
          <DetailsRow
            label={t('ProductDescription.fit')}
            value={
              product.frameFitting &&
              t(`ProductDescription.frameFittingDescription.${product.frameFitting?.toLowerCase()}`)
            }
          />
          <DetailsRow
            label={t('ProductDescription.frameFitting')}
            value={
              product.geoFit &&
              t(
                `ProductDescription.geoFitDescription.${removeSpaces(product.geoFit).toLowerCase()}`
              )
            }
          />
          {config.showExtraPdpDetails && (
            <>
              <DetailsRow
                label={t('ProductDescription.lensCategory')}
                value={product.advisoryNotes}
              />
              <DetailsRow
                label={t('ProductDescription.suitableForDriving')}
                value={product.drivingAsnzs ? t('Core.yes') : t('Core.no')}
              />
            </>
          )}
        </>
      )}
    </DetailListContainer>
  )
}

export const Details = EyeglassesDetailsList
