import { FrameAdvisorActionTypes } from '@actions/frameAdvisor'
import { StoreActionTypes } from '@actions/store'
import { WishlistActionTypes } from '@actions/wishlist'
import { connectRouter, RouterActionType } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import accessibility from './accessibility'
import barcode from './barcode'
import cart from './cart'
import checkout from './checkout'
import { sessionReducer } from './core'
import customerOrder from './customerOrder'
import engraving from './engraving'
import filters from './filters'
import flows from './flows'
import frameAdvisor from './frameAdvisor'
import keyboard from './keyboard'
import order from './order'
import rtr from './rtr'
import ui from './ui'
import version from './version'
import wishlist from './wishlist'

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    cart,
    barcode,
    checkout,
    order,
    wishlist,
    engraving,
    session: sessionReducer,
    keyboard,
    ui,
    frameAdvisor,
    customerOrder,
    accessibility,
    flows,
    filters,
    rtr,
    version,
  })

export default createRootReducer

export type StoreActionsTypes =
  | StoreActionTypes
  | RouterActionType
  | FrameAdvisorActionTypes
  | WishlistActionTypes
