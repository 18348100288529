import { useSendAnalyticsEvent } from '@hooks/analyticsHooks'
import { useEffect } from 'react'

export const useSendBarcodeAvailableScannedCustomAnalyticsEvent = (
  shouldSendEvent: boolean,
  isOakley: boolean
) => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: isOakley ? 'Barcode:CustomOO' : 'Barcode:CustomRB',
  })

  const sendCloseModalEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: isOakley ? 'BarcodeResultOOCustom_Close' : 'BarcodeResultRBCustom_Close',
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])

  return { sendCloseModalEvent }
}
