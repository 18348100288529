import { IconProps } from '@abstractTypes/icons'

export const PilotIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.187 0.288C44.342 0.764 46.665 3.036 47.58 6.523C48.758 11.019 47.341 16.209 44.282 18.597C43.093 19.524 41.656 19.995 40.086 19.995C39.15 19.995 38.168 19.827 37.162 19.49C32.56 17.946 28.173 13.137 26.421 7.837C25.025 6.781 22.971 6.782 21.576 7.838C19.812 13.152 15.432 17.948 10.837 19.49C9.83099 19.828 8.84899 19.995 7.91299 19.995C6.34299 19.995 4.90599 19.525 3.71699 18.597C0.658989 16.21 -0.759011 11.019 0.419989 6.524C1.33499 3.037 3.65799 0.765 6.79399 0.291C8.10799 0.117 9.54699 0.014 11 0.007V0H37V0.007C38.447 0.013 39.881 0.116 41.187 0.288ZM10.202 17.595C14.489 16.156 18.582 11.433 19.935 6.367C20.14 5.493 19.978 4.784 19.419 4.165C18.194 2.81 15.149 2.034 11.417 2H11.001V1.998C9.74999 2.005 8.42899 2.093 7.07599 2.271C3.93299 2.746 2.76899 5.457 2.35599 7.031C1.38399 10.739 2.52299 15.126 4.94899 17.021C6.68199 18.37 8.76099 18.078 10.202 17.595ZM21.996 5.354C21.926 4.413 21.562 3.554 20.901 2.824C20.625 2.519 20.297 2.25 19.939 2.001H28.059C27.702 2.25 27.373 2.519 27.098 2.824C26.44 3.552 26.076 4.409 26.006 5.355C25.367 5.127 24.69 5 24 5C23.31 5 22.635 5.127 21.996 5.354ZM37.799 17.595C39.239 18.079 41.319 18.371 43.052 17.021C45.478 15.126 46.617 10.738 45.645 7.031C45.232 5.457 44.068 2.746 40.907 2.268C39.561 2.09 38.247 2.006 37 1.999V2H36.59C32.86 2.033 29.809 2.809 28.582 4.165C28.022 4.784 27.86 5.493 28.072 6.396C29.418 11.434 33.512 16.156 37.799 17.595Z"
        fill={fill}
      />
    </svg>
  )
}
