import { Device } from '@abstractTypes/utils'
import { Heading4 } from '@components/core/Typography'
import { landscape } from '@libs/media'
import styled from 'styled-components'

export const PanelHeaderCloseButton = styled.button``

export const PanelHeaderBackButton = styled.button`
  position: absolute;
  inset-inline-start: 0;
`

export const PanelHeaderTitle = styled(Heading4)`
  ${landscape(`
    display: flex;
  `)}
  ${landscape(
    Device.tablet,
    `
      margin-block-start: 0;
  `
  )}
`
export const PanelHeaderTitleCount = styled(Heading4)`
  opacity: 0.49;
`
