import { ShippingEstimationPeriod, ShippingMethod } from '@abstractTypes/graphqlTypes'
import { ShippingMethodCode } from '@abstractTypes/shipping'
import { ShippingPanelContainer } from '@components/Checkout/Panels/styles'
import { GreenDiscoverIcon } from '@components/core/Icons/GreenDiscover'
import { GreenShipmentIcon } from '@components/core/Icons/GreenShipment'
import { ButtonTypography, Label, Text } from '@components/core/Typography'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { usePrescriptionEnabled } from '@hooks/usePrescriptionEnabled'
import { pxToRem } from '@libs/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CheckoutCheckbox } from '../CheckoutCheckbox'
import { ShippingTypePanelProps } from './types'

const CheckoutCheckboxGreen = styled(CheckoutCheckbox)<{ checked: boolean }>`
  ${({ checked }) =>
    checked &&
    `
      background-color: rgba(209, 223, 197, 0.5);
      border-color: rgba(209, 223, 197, 0.5);
  `}
`

const CheckboxInfo = styled(Label)`
  display: block;
  margin-block-start: ${pxToRem(24)};
`

const GreenShipmentTitle = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-inline-start: ${pxToRem(16)};
    margin-block-start: ${pxToRem(-7)};
  }
`

const Subtitle = styled(Text)`
  margin-block-start: ${pxToRem(16)};
`

const DiscoverButton = styled(ButtonTypography)`
  margin-block-start: ${pxToRem(16)};
  display: flex;
  align-items: center;

  svg {
    margin-inline-start: ${pxToRem(16)};
  }
`

export const ShippingTypePanel: React.FC<ShippingTypePanelProps> = ({
  data,
  setData,
  shippingMethods,
  isMixedGreenCart,
}) => {
  const { t } = useTranslation()
  const { setVisibleDrawer } = useDrawerHandler()
  const showDrawer = () => setVisibleDrawer('greenShipment')
  const greenColor = '#709848'
  const prescriptionEnabled = usePrescriptionEnabled()

  const getShippingEstimationDescription = (shipmentTypeEstimation?: ShippingEstimationPeriod) => {
    const { min, max } = shipmentTypeEstimation || {}
    if (!min || !max) {
      return undefined
    }
    const period = min === max ? min : `${min}-${max}`

    return <Subtitle>{t('Checkout.shippingEstimation', { period })}</Subtitle>
  }

  const renderShippingMethod = (method: ShippingMethod) => {
    const { code, translationKey, estimations } = method

    if (code === ShippingMethodCode.GREEN) {
      return (
        <CheckoutCheckboxGreen
          key={code}
          name="shippingType"
          elementKey="option-shippingTypeGreen"
          aria-label={t(`Checkout.${translationKey}`)}
          onChange={() => {
            setData('shippingType', code)
          }}
          checked={data.shippingType === code}
          dataAttr={{ test: 'Checkout_Deliver-at-green' }}
          color={greenColor}
          description={
            isMixedGreenCart && prescriptionEnabled ? (
              <CheckboxInfo>{t('Checkout.greenAlert')}</CheckboxInfo>
            ) : (
              <>
                {getShippingEstimationDescription(estimations)}
                <Subtitle color={greenColor} weight="bold">
                  {t('Checkout.greenSubtitle1')}
                </Subtitle>
                <Subtitle>{t('Checkout.greenSubtitle2')}</Subtitle>
                <DiscoverButton
                  color={greenColor}
                  onClick={showDrawer}
                  data-element-id="GreenShipping_MoreInfo"
                >
                  {t('Checkout.greenDiscover')}
                  <GreenDiscoverIcon height={pxToRem(24)} width={pxToRem(24)} fill={greenColor} />
                </DiscoverButton>
              </>
            )
          }
        >
          <GreenShipmentTitle>
            {t(`Checkout.${translationKey}`)}
            <GreenShipmentIcon height={pxToRem(23)} width={pxToRem(23)} fill={greenColor} />
          </GreenShipmentTitle>
        </CheckoutCheckboxGreen>
      )
    }

    return (
      <CheckoutCheckbox
        aria-label={t(`Checkout.${translationKey}`)}
        key={code}
        name="shippingType"
        elementKey={`option-shippingType${code}`}
        onClick={() => {
          setData('shippingType', code)
        }}
        checked={data.shippingType === code}
        dataAttr={{ test: `Checkout_Ship-to-${code}` }}
        description={getShippingEstimationDescription(estimations)}
      >
        {t(`Checkout.${translationKey}`)}
      </CheckoutCheckbox>
    )
  }
  return (
    <ShippingPanelContainer role="group" aria-labelledby="shippingTypePanel">
      {shippingMethods.map(method => renderShippingMethod(method))}
    </ShippingPanelContainer>
  )
}
