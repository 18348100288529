import { EngravingConstraints, EngravingSelectedOptions } from '@abstractTypes/engraving'

export const ADD_ENGRAVING = 'engraving/ADD_ENGRAVING'
export const EDIT_ENGRAVING = 'engraving/EDIT_ENGRAVING'
export const DELETE_ENGRAVING = 'engraving/DELETE_ENGRAVING'

export interface AddEngravingAction {
  type: typeof ADD_ENGRAVING
  payload: {
    itemId: string
    details: EngravingSelectedOptions
    engravingConstraints?: EngravingConstraints
  }
}
export const addEngraving = (
  itemId: string,
  details: EngravingSelectedOptions,
  engravingConstraints?: EngravingConstraints
): AddEngravingAction => ({
  type: ADD_ENGRAVING,
  payload: {
    itemId,
    details,
    engravingConstraints,
  },
})

export interface EditEngravingAction {
  type: typeof EDIT_ENGRAVING
  payload: {
    itemId: string
    details: EngravingSelectedOptions
  }
}
export const editEngraving = (
  itemId: string,
  details: EngravingSelectedOptions
): EditEngravingAction => ({
  type: EDIT_ENGRAVING,
  payload: {
    itemId,
    details,
  },
})

export interface DeleteEngravingAction {
  type: typeof DELETE_ENGRAVING
  payload: {
    itemId: string | null
  }
}
export const deleteEngraving = (itemId: string | null): DeleteEngravingAction => ({
  type: DELETE_ENGRAVING,
  payload: {
    itemId,
  },
})

export type EngravingAction = AddEngravingAction | EditEngravingAction | DeleteEngravingAction
