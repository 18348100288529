import { DrawerKey } from '@abstractTypes/utils'
import {
  setVisibleDrawer as setVisibleDrawerAction,
  closeVisibleDrawer as closeVisibleDrawerAction,
} from '@actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { useAction } from './useActions'
import { useCallback } from 'react'

export const useDrawerHandler = (drawerKey?: DrawerKey) => {
  const dispatch = useDispatch()
  const visibleDrawerKey = useSelector(s => s.ui.visibleDrawerKey)
  const setVisibleDrawer = useCallback(
    (drawerKey?: DrawerKey) => dispatch(setVisibleDrawerAction(drawerKey)),
    [dispatch]
  )
  const closeDrawer = useCallback(
    (drawerKey?: DrawerKey) => dispatch(closeVisibleDrawerAction(drawerKey)),
    [dispatch]
  )
  const closeAnyDrawer = useAction(setVisibleDrawerAction(undefined))
  const isVisible = drawerKey === visibleDrawerKey || false
  return { visibleDrawerKey, isVisible, closeAnyDrawer, setVisibleDrawer, closeDrawer }
}
