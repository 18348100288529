import { ListItem, ListItemAction } from '@components/core/List'
import { Label, Text } from '@components/core/Typography'
import { tablet, zoomPortrait } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import React from 'react'
import styled, { css } from 'styled-components'

export const SwitcherWithLableStyled = styled(ListItem)`
  padding-block: 0;
  padding-inline: ${pxToRem(64)} ${pxToRem(17)};
  margin-block-start: ${pxToRem(36)};
  ${tablet(`
    margin-block-start: ${clampValues(38, 48)};
    padding-inline-start: ${clampValues(40, 64)}; 
  `)}
`
export const LabelWrapper = styled.div`
  position: relative;
  margin-inline-start: ${pxToRem(22)};
`

export const Switcher = styled.div<{ checked: boolean; disabled: boolean }>`
  position: absolute;
  inset-block-start: ${pxToRem(2)};
  inset-inline-end: ${({ checked }) => (checked ? pxToRem(2) : pxToRem(46))};
  inline-size: ${pxToRem(31)};
  block-size: ${pxToRem(31)};
  ${({ checked }) => css`
    ${zoomPortrait(
      [150, 175],
      `
        block-size: ${pxToRem(47)};
        inline-size: ${pxToRem(47)};
        inset-block-start: ${pxToRem(3)};
        inset-inline-end: ${checked ? pxToRem(3) : pxToRem(69)};
      `
    )}
    ${zoomPortrait(
      [200],
      `
        block-size: ${pxToRem(62)};
        inline-size: ${pxToRem(62)};
        inset-block-start: ${pxToRem(4)};
        inset-inline-end: ${checked ? pxToRem(4) : pxToRem(93)};
      `
    )}
  `}
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  ${({ checked }) =>
    tablet(
      `
      inset-block-start: ${clampValues(1, 2)};
      block-size: ${clampValues(24, 31)};
      inline-size: ${clampValues(24, 31)};
      inset-inline-end: ${checked ? clampValues(1, 2) : clampValues(42, 45)};
  `
    )}
`

export const LabelStyled = styled(Label)<{ checked: boolean; disabled: boolean }>`
  display: flex;
  flex-direction: ${({ checked }) => (checked ? 'row' : 'row-reverse')};
  justify-content: space-between;
  align-items: center;
  inline-size: ${pxToRem(80)};
  block-size: ${pxToRem(35)};
  border-radius: 4.545rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryLight};
  background-color: ${({ theme, checked, disabled }) =>
    disabled ? theme.colors.inactive : checked ? theme.colors.highlight02 : theme.colors.inactive};
  padding-block: 0;
  padding-inline: ${pxToRem(12)} ${pxToRem(7)};
  ${zoomPortrait(
    [150, 175],
    `
      inline-size: ${pxToRem(119)};
      block-size: ${pxToRem(53)};
      padding-inline:  ${pxToRem(18)} ${pxToRem(11)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
      inline-size: ${pxToRem(159)};
      block-size: ${pxToRem(70)};
      padding-inline:  ${pxToRem(24)} ${pxToRem(14)};
    `
  )}

  & input {
    opacity: 0;
  }
  ${tablet(`
    inline-size: ${clampValues(67, 78)};
    block-size: ${clampValues(26, 35)};
    padding-block: 0 ;
    padding-inline: ${clampValues(8, 12)};
  `)}
`
export const CustomToggleText = styled(Text)`
  display: flex;
  align-items: center;
`

type SwitcherWithLabelProps = React.ComponentProps<typeof Switcher> & {
  className?: string
  disabled?: boolean
  checked: boolean
  label: React.ReactNode
  showOnOff?: boolean
  dataAttr?: {
    test: string
  }
}

export const SwitcherWithLabel: React.FC<SwitcherWithLabelProps> = ({
  disabled = false,
  checked,
  label,
  showOnOff = true,
  className,
  dataAttr,
  ...props
}) => {
  return (
    <SwitcherWithLableStyled className={className}>
      <Text color="secondaryMedium">{label}</Text>
      <ListItemAction>
        <LabelWrapper tabIndex={0}>
          <LabelStyled
            weight="bold"
            checked={checked}
            data-test={dataAttr?.test}
            // TODO: once we migrate all the UIs, we need to check if PlpFilters.on and PlpFilters.off are used anywhere, otherwise we can remove them from the assets folder
            disabled={disabled}
          >
            {showOnOff && (
              <CustomToggleText color="primaryLight">{checked ? 'ON' : 'OFF'}</CustomToggleText>
            )}
            <input type="checkbox" {...props} disabled={disabled} checked={checked} tabIndex={-1} />
            <Switcher checked={checked} disabled={disabled} />
          </LabelStyled>
        </LabelWrapper>
      </ListItemAction>
    </SwitcherWithLableStyled>
  )
}
