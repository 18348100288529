import { useCallback, useRef, useState } from 'react'

const useOnScreen = (root?: Element | Document | null, threshold?: number | number[]) => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [isOnScreen, setIsOnScreen] = useState(true)

  const refInternal = useRef<HTMLElement | null>(null)

  const setObserver = useCallback(() => {
    const options = {
      root: root || null,
      threshold: threshold || 1,
    }
    observerRef.current = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting)
    }, options)
  }, [root, threshold])

  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (refInternal.current) {
        observerRef.current?.disconnect()
      }
      if (!node) return
      setObserver()
      observerRef.current?.observe(node)

      refInternal.current = node
    },
    [setObserver]
  )

  return { isOnScreen, setRef }
}

export default useOnScreen
