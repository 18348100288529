import { Device } from '@abstractTypes/utils'
import { CardProductContainerStyled } from '@components/CardProductBase/ProductCard/ProductCard.style'
import { PrimaryButton } from '@components/core/Button'
import { Panel, PanelHeaderStyled } from '@components/core/Collapse'
import { InputStyled } from '@components/core/FormInput/styles'
import { FormItemWrapper as FormItem } from '@components/core/FormItem/styles'
import { Row } from '@components/core/Grid'
import {
  SelectDropdown,
  SelectInnerStyled,
  SelectStyled,
  SelectWrapperStyled,
} from '@components/core/Select/styles'
import { Heading5, Text } from '@components/core/Typography'
import { StickyTitle } from '@components/plp/PLPTitle'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const MiniCartItemsContainer = styled.div`
  block-size: auto;

  ${CardProductContainerStyled} {
    margin-block-end: ${pxToRem(48)};
    ${landscape(`inline-size: ${pxToRem(416)};`)}
    ${tablet(`margin-block-end: ${clampValues(54, 72)};`)}
    ${landscape(
      Device.tablet,
      `
      inline-size: ${clampValuesLandscape(303, 404)};
      margin-block-end: ${clampValuesLandscape(54, 72)};
    `
    )}
    ${zoomLandscape(
      [150, 175],
      `
        inline-size: ${pxToRem(624)};
      `
    )}
    ${altNavigationZoom(
      [150, 175],
      `
        inline-size: ${pxToRem(606)};
      `
    )}
    ${zoomLandscape(
      [200],
      `
        inline-size: ${pxToRem(832)};
      `
    )}
  }
`

export const FormInputSuggesterContainer = styled.div`
  position: relative;

  ${SelectStyled} {
    position: absolute;
    inset-block-start: ${pxToRem(70)};
  }

  ${SelectInnerStyled} {
    block-size: 0;
    border: none;
  }

  ${SelectWrapperStyled} {
    display: none;
  }

  ${SelectDropdown} {
    position: absolute;
    border: ${({ theme }) => `1px solid ${theme.colors.outline}`};
  }
`

export const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  ${altNavigation(`
    inset-block-start: ${pxToRem(1076)};
  `)};
`

export const CheckoutRecapItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > * {
    min-block-size: unset;
  }
`

export const CheckoutRecapContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CheckoutFormContainer = styled.div`
  ${FormItem} {
    margin-block-end: ${pxToRem(32)};
    ${tablet(`margin-block-end: ${clampValues(24, 32)};`)};

    &:last-child {
      margin-block-end: 0;
    }

    label {
      text-transform: capitalize;
      display: flex;
      align-items: center;
    }

    input,
    ${SelectInnerStyled} {
      border-radius: 0;
      block-size: ${pxToRem(48)};
      border: ${({ theme }) => `1px solid ${theme.colors.outline}`};
      ${tablet(`block-size: ${clampValues(36, 48)};`)}
    }

    ${InputStyled} {
      ${tablet(`line-block-size: ${clampValues(36, 48)};`)}
    }
  }
`

export const DeliverToStoreMessageContainer = styled.div`
  hr {
    background: ${({ theme }) => theme.colors.primaryDark};
    margin-block: ${pxToRem(24)} ${pxToRem(22)};
    margin-inline: 0;
    block-size: 1px;
    border: none;
  }
`

export const DeliverToStoreText = styled(Text)`
  margin-block-start: ${pxToRem(32)};
`

export const DeliveryPanelWrapper = styled.div`
  max-inline-size: 90%;
`

export const CartSummaryContainer = styled.div`
  inline-size: 100%;
`

export const SubtotalSections = styled.div`
  margin-block: ${pxToRem(32)} ${pxToRem(24)};
  margin-inline: 0;
  ${landscape(`margin-block: ${pxToRem(32)}`)};
  ${tablet(`margin-block: ${clampValues(18, 24)}`)};
  ${landscape(Device.tablet, `margin-block: ${clampValuesLandscape(18, 24)}`)};
  ${({ theme }) =>
    zoomPortrait(
      [150, 175, 200],
      `
      border-block-end: 1px solid ${theme.colors.outline};
  `
    )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    margin: 0;
  `
  )}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    margin: 0;
    padding-block-end: 0;
  `
  )}
  ${zoomPortrait(
    [150, 175, 200],
    `
    padding-block-end: ${pxToRem(10)}
  `
  )}
`

export const CartSummaryTotal = styled(Row)`
  margin-block-end: ${pxToRem(24)};
  ${tablet(`margin-block-end: ${clampValues(18, 24)};`)};
  ${landscape(`margin-block-end: ${pxToRem(22)};`)};
  ${zoomLandscape(
    [150, 175, 200],
    `
  margin-block-end: 0;
  margin-block-start: ${pxToRem(21)};
  `
  )}
  ${altNavigationZoom(
    [150, 175, 200],
    `
  margin-block-end: 0;
  margin-block-start: ${pxToRem(10)};
  `
  )}
  ${landscape(Device.tablet, `margin-block-end: ${clampValuesLandscape(18, 22)};`)};
`

export const StickyTitleStyled = styled(StickyTitle)`
  padding-block: 0 ${pxToRem(32)};
  padding-inline: 0;
  inset-block-start: -1px;
  ${landscape(`padding-block:  0 ${pxToRem(24)};`)};
  ${tablet(`padding-block:  0 ${clampValues(18, 24)};`)};
  ${landscape(Device.tablet, `padding-block:  0 ${clampValuesLandscape(18, 24)};`)};
  ${zoomPortrait(
    [150, 175, 200],
    `
    inset-block-start: auto;
    inset-block-end: 0;
    inset-inline-start: 0;
    position: fixed;
    padding-block: ${pxToRem(50)} ;
    padding-inline:  ${pxToRem(65)};
    display: grid;
    grid-template-columns: ${pxToRem(450)} auto;
    grid-column-gap: ${pxToRem(25)};
  `
  )}
  ${zoomPortrait(
    [175, 200],
    `
    padding-block: ${pxToRem(50)} ;
    padding-inline: ${pxToRem(10)};
    grid-template-columns: ${pxToRem(500)} auto;
    grid-column-gap: ${pxToRem(15)};
  `
  )}
  ${zoomPortrait(
    [200],
    `   
    padding-block: ${pxToRem(25)} ;
  `
  )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    position: fixed;
    padding-block: ${pxToRem(20)}  ${pxToRem(20)};
    padding-inline: ${pxToRem(400)} ${pxToRem(190)};
    display: grid;
    grid-template-columns: ${pxToRem(459)} auto;
    grid-column-gap: ${pxToRem(130)};
  `
  )}
  ${zoomLandscape(
    [200],
    `
    padding-block: ${pxToRem(16)} ;
    padding-inline:  ${pxToRem(150)};
    grid-template-columns: ${pxToRem(594)} auto;
  `
  )}
    ${altNavigationZoom(
    [150, 175, 200],
    `
    position: fixed;
    padding-block: ${pxToRem(18)} ;
    padding-inline:  ${pxToRem(46)};
    grid-template-columns: 0.8fr 1fr;
    grid-column-gap: ${pxToRem(35)};
  `
  )}
  ${altNavigationZoom(
    [175, 200],
    `
    padding-block: ${pxToRem(10)} 0;
    padding-inline:  ${pxToRem(10)} ;
    grid-template-columns: ${pxToRem(500)} auto;
    grid-column-gap: ${pxToRem(15)};
  `
  )}
    ${altNavigationZoom(
    [200],
    `
    padding: ${pxToRem(10)};
    grid-template-columns: ${pxToRem(450)} auto;
  `
  )}
  ${({ theme }) =>
    zoomPortrait(
      [150, 175, 200],
      `
      border-block-start: 1px solid ${theme.colors.outline};
  `
    )}
  ${zoomLandscape(
    [200],
    `
    flex-direction: column;
    `
  )}
`

export const PriceText = styled(Text)`
  text-align: end;
  overflow-wrap: break-word;
`

export const PanelStyled = styled(Panel)`
  &:first-child {
    ${PanelHeaderStyled} {
      padding-block-start: 0;
    }
  }

  &:last-child {
    ${landscape(
      `
        margin-block-end: 0;
      `
    )}
  }
`

export const GoToBagButton = styled(PrimaryButton)`
  box-shadow: ${({ theme }) => `
    ${theme.colors.primaryLight} 0 ${pxToRem(2)} ${pxToRem(1)}, 
    ${theme.colors.primaryLight} 0 ${pxToRem(4)} ${pxToRem(2)},
    ${theme.colors.primaryLight} 0 ${pxToRem(8)} ${pxToRem(4)}, 
    ${theme.colors.primaryLight} 0 ${pxToRem(16)} ${pxToRem(8)},
    ${theme.colors.primaryLight} 0 ${pxToRem(32)} ${pxToRem(16)}
  `};
  z-index: 1;
  position: relative;
  inline-size: 90%;
  inset-inline-start: ${pxToRem(32)};
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(908)};
    `
  )}
  ${zoomLandscape(
    [150, 175],
    `
    position: fixed;
    inset-block-start: ${pxToRem(962)};
    z-index: 1;
    `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(1016)};
    `
  )}
  ${zoomLandscape(
    [200],
    `
    position: absolute;
    inset-block-start: ${pxToRem(949)};
    inline-size: ${pxToRem(1232)};
    z-index: 1;
    `
  )}
  ${altNavigationZoom(
    [100, 110, 125],
    `
    position: absolute;
    inset-block-start: ${pxToRem(738)};
    z-index: 1;
    `
  )}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    position: fixed;
    inset-block-start: ${pxToRem(738)};
    `
  )}
`
export const HeadingWithPrice = styled(Heading5)`
  overflow-wrap: break-word;
  ${tablet(`
    max-inline-size: ${pxToRem(140)};
  `)}
`
