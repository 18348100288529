import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getShEnvVariables = () => {
  const shEnvVariables: AppConfigOverride = {
    shopperSlug: 'sh',
    shopperName: 'SPECTACLE HUT',
    storeID: '12161',
    storeLang: 'en-GB',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    vmStore: 'sh',
    vmStyle: 'sh',
  }

  return shEnvVariables
}

export default getShEnvVariables
