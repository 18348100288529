import {
  DRESSED_ON_IMAGE_RATIO,
  IMAGE_RATIO,
} from '@components/CardProductBase/ProductCard/ProductCard.style'
import styled from 'styled-components'

export const PreviewContainer = styled.div<{
  isSquared: boolean
  isDressedOnView: boolean
}>`
  position: relative;
  inline-size: 100%;
  padding-block-start: ${({ isSquared, isDressedOnView }) => {
    const imageRatio = isDressedOnView ? DRESSED_ON_IMAGE_RATIO : IMAGE_RATIO
    return isSquared && !isDressedOnView ? '100%' : imageRatio
  }};

  overflow: hidden;
`

export const PreviewStyled = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.productCard.backgroundColor};
`
