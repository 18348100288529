import { IconProps } from '@abstractTypes/icons'
export const FsaOnboardingHelpIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 48"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path d="M18 44H14V48H18V44Z" fill="black" />
      <path
        d="M16 0C7.178 0 0 7.178 0 16H2C2 8.28 8.28 2 16 2C23.72 2 30 8.28 30 16C30 21.773 26.534 26.882 21.17 29.015C17.422 30.505 15 34.032 15 38H17C17 34.856 18.927 32.059 21.909 30.873C28.039 28.436 32 22.598 32 16C32 7.178 24.822 0 16 0Z"
        fill="black"
      />
    </svg>
  )
}
