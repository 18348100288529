import { useEffect, useRef, useState, useCallback } from 'react'
import { throttle } from 'lodash'
import { useDeviceType } from './useDeviceType'
import { isHTMLElement } from '@libs/utils'

const SCROLL_THROTTLE_INTERVAL = 200

interface UseScrollUpOptions {
  block?: boolean
  returnFalseIfBlock?: boolean
  initialValue?: boolean
  startListeningFrom?: number
}

export const useScrollUp = ({
  block = false,
  returnFalseIfBlock = false,
  initialValue = true,
  startListeningFrom = 0,
}: UseScrollUpOptions) => {
  const refInternal = useRef<HTMLElement | null>(null)
  const [isScrollUp, setIsScrollUp] = useState(initialValue)

  const prevScrollTop = useRef(0)

  const deltaY = 20

  useEffect(() => {
    if (!block) return
    setIsScrollUp(!returnFalseIfBlock)
  }, [block, returnFalseIfBlock])

  const handleScroll = useCallback(() => {
    const currentElement = refInternal.current
    if (!isHTMLElement(currentElement)) return
    const { scrollTop } = currentElement
    if (scrollTop <= startListeningFrom) {
      setIsScrollUp(initialValue)
    } else {
      if (scrollTop - deltaY > prevScrollTop.current) {
        setIsScrollUp(false)
      } else if (scrollTop + deltaY < prevScrollTop.current) {
        setIsScrollUp(true)
      }
    }

    prevScrollTop.current = scrollTop
  }, [startListeningFrom, initialValue])

  const onScroll = useCallback(
    () => throttle(handleScroll, SCROLL_THROTTLE_INTERVAL)(),
    [handleScroll]
  )

  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (block) return
      if (refInternal.current) {
        // Make sure to cleanup any events/references added to the last instance
        refInternal.current.removeEventListener('scroll', onScroll)
      }
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      if (!node) return
      node.addEventListener('scroll', onScroll)
      refInternal.current = node
    },
    [onScroll, block]
  )

  return { isScrollUp, setRef }
}

export const useScrollUpWithBaseContainer = ({
  block = false,
  returnFalseIfBlock = false,
  initialValue = true,
  startListeningFrom = 0,
}: UseScrollUpOptions) => {
  const { isTowerPortrait } = useDeviceType()
  const scrollableTarget = isTowerPortrait ? 'main' : 'page'
  const baseContainer = document.getElementById(scrollableTarget)

  const { isScrollUp, setRef } = useScrollUp({
    block,
    returnFalseIfBlock,
    initialValue,
    startListeningFrom,
  })

  useEffect(() => {
    setRef(baseContainer)
  }, [baseContainer, setRef])

  return { isScrollUp }
}
