import { Device } from '@abstractTypes/utils'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css, keyframes } from 'styled-components'

const growIconBadge = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`

export const MenuIconBadge = styled.span<{ isAnimation?: boolean; transparentBg?: boolean }>`
  position: absolute;
  inset-block-start: ${pxToRem(18)};
  inset-inline-end: ${pxToRem(18)};
  display: block;
  inline-size: ${pxToRem(10)};
  block-size: ${pxToRem(10)};
  border-radius: 100%;
  background-color: ${({ theme, transparentBg }) =>
    transparentBg ? 'transparent' : theme.colors.highlight01};
  animation: ${({ isAnimation }) =>
    isAnimation &&
    css`
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-name: ${growIconBadge};
    `};

  ${tablet(
    `
    inset-block-start: ${clampValues(10, 12)};
    inset-inline-end: ${clampValues(10, 12)};
    inline-size: ${clampValues(8, 10)};
    block-size: ${clampValues(8, 10)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    inset-block-start: ${clampValuesLandscape(7, 12)};
    inset-inline-end: ${clampValuesLandscape(7, 12)};
    inline-size: ${clampValuesLandscape(8, 10)};
    block-size: ${clampValuesLandscape(8, 10)};
  `
  )}
  ${altNavigation(`
    inline-size: ${pxToRem(8)};
    block-size: ${pxToRem(8)};
    inset-block-start: ${pxToRem(12)};
    inset-inline-end: ${pxToRem(12)};
  `)}
  ${zoomPortrait(
    [150, 175, 200],
    `
    inline-size: ${pxToRem(15)};
    block-size: ${pxToRem(15)};
    inset-block-start: ${pxToRem(13)};
    inset-inline-end: ${pxToRem(13)};
  `
  )}
  ${altNavigationZoom(
    [150, 175],
    `
    inline-size: ${pxToRem(12)};
    block-size: ${pxToRem(12)};
    inset-block-start: ${pxToRem(8)};
    inset-inline-end: ${pxToRem(8)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    inline-size: ${pxToRem(16)};
    block-size: ${pxToRem(16)};
    inset-block-start: ${pxToRem(4)};
    inset-inline-end: ${pxToRem(4)};
  `
  )}
`

export const floatingButtonWidth = pxToRem(88)
export const floatingButtonWidthZoom = pxToRem(104)
export const floatingButtonWidthAltNav = pxToRem(64)
export const floatingButtonWidthAltNavZoom = pxToRem(76)
export const floatingButtonTabletPortrait = clampValues(56, 64)
export const floatingButtonTabletLandscape = clampValuesLandscape(48, 64)
export const FloatingButton = styled.button`
  inline-size: ${floatingButtonWidth};
  block-size: ${pxToRem(88)};
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-inline-end: 1px solid ${({ theme }) => theme.colors.outline};
  border-block-end: 1px solid ${({ theme }) => theme.colors.outline};
  background-color: ${({ theme }) => theme.colors.primaryLight};

  ${tablet(`
    inline-size: ${floatingButtonTabletPortrait};
    block-size: ${floatingButtonTabletPortrait};
  `)}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${floatingButtonTabletLandscape};
    block-size: ${floatingButtonTabletLandscape};
  `
  )}
  ${zoomPortrait(
    [150, 175, 200],
    `
    inline-size: ${floatingButtonWidthZoom};
    block-size: ${pxToRem(104)};
  `
  )}
  ${altNavigation(`
    inline-size: ${floatingButtonWidthAltNav};
    block-size: ${pxToRem(64)};
  `)}
  ${altNavigationZoom(
    [150, 175],
    `
    inline-size: ${floatingButtonWidthAltNavZoom};
    block-size: ${pxToRem(76)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    inline-size: ${floatingButtonWidth};
    block-size: ${pxToRem(88)};
  `
  )}
`

export const FloatingContainer = styled.div<{
  higher?: boolean
}>`
  z-index: 1;
  position: ${({ higher }) => (higher ? 'absolute' : 'fixed')};
  inset-inline-start: 0;
  inset-block-start: ${({ higher }) => (higher ? pxToRem(348) : pxToRem(260))};

  ${altNavigation(`
		inset-block-start:  ${pxToRem(1250)};
		position: fixed;
	`)};
  > ${FloatingButton}:first-child {
    border-block-start: 1px solid ${({ theme }) => theme.colors.outline};
  }

  ${({ higher }) =>
    tablet(`
    ${
      higher
        ? `inset-block-start: ${clampValues(232, 309)}`
        : `inset-block-start: ${clampValues(184.5, 246)}`
    };`)}

  ${({ higher }) =>
    landscape(
      `${higher ? `inset-block-start: ${pxToRem(342)}` : `inset-block-start: ${pxToRem(253)}`};`
    )}
  ${({ higher }) =>
    landscape(
      Device.tower,
      `${higher ? `inset-block-start: ${pxToRem(329)}` : `inset-block-start: ${pxToRem(241)}`};`
    )}
  ${({ higher }) =>
    zoomLandscape(
      [150, 175],
      `${higher ? `inset-block-start: ${pxToRem(285)}` : `inset-block-start: ${pxToRem(241)}`};`
    )}
  ${({ higher }) =>
    zoomLandscape(
      [200],
      `${higher ? `inset-block-start: ${pxToRem(360)}` : `inset-block-start: ${pxToRem(220)}`};`
    )}

  ${({ higher }) =>
    landscape(
      Device.tablet,
      `${
        higher
          ? `inset-block-start: ${clampValuesLandscape(198, 264)}`
          : `inset-block-start: ${clampValuesLandscape(150, 201)}`
      }; `
    )}
`
