import { IconProps } from '@abstractTypes/icons'
export const SkinUndertone1Icon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 78 78"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      stroke={stroke}
      className={className}
    >
      <mask
        id="mask0_3992_97264"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={4}
        y={4}
        width={70}
        height={70}
      >
        <circle cx={39} cy={39} r={34.8511} fill="#C4C4C4" />
        <circle cx={39} cy={39} r={34.8511} fill="url(#pattern0)" />
        <circle cx={39} cy={39} r={34.8511} fill="url(#pattern1)" />
      </mask>
      <g mask="url(#mask0_3992_97264)">
        <rect x={2.48926} y={-15.766} width={72.1915} height={109.532} fill="url(#pattern2)" />
        <rect
          x={39.1455}
          y={-11.6171}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 39.1455 -11.6171)"
          fill="#FFE3CB"
        />
        <rect
          x={49.1199}
          y={-1.64221}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 49.1199 -1.64221)"
          fill="#E5C6AB"
        />
        <rect
          x={59.0947}
          y={8.33228}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 59.0947 8.33228)"
          fill="#BF9C7B"
        />
        <rect
          x={69.0698}
          y={18.3071}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 69.0698 18.3071)"
          fill="#A27A56"
        />
        <rect
          x={79.0439}
          y={28.282}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 79.0439 28.282)"
          fill="#805A39"
        />
      </g>
      <circle cx={39} cy={39} r={38.5} stroke="#D6D6D6" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
          <use xlinkHref="#image0_3992_97264" transform="translate(-0.27193) scale(0.00438596)" />
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width={1} height={1}>
          <use xlinkHref="#image0_3992_97264" transform="translate(-0.27193) scale(0.00438596)" />
        </pattern>
        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width={1} height={1}>
          <use xlinkHref="#image1_3992_97264" transform="scale(0.0035461 0.0023372)" />
        </pattern>
      </defs>
    </svg>
  )
}
