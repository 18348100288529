import {
  ProductHierarchies,
  ProductSubdivisions,
  ProductTypeValues,
  TogglableProductTypeValues,
} from '@abstractTypes/filter'
import { StandardProduct } from '@abstractTypes/product'
import config from '@config/index'
import { PRODUCT_TYPE } from '@constants/product'
import { equals } from './caseInsensitive'

export const isEyeglassByProductSubdivision = (productSubdivision?: ProductSubdivisions) => {
  switch (productSubdivision) {
    case ProductHierarchies.EYEGLASSES:
    case ProductTypeValues.OPTICAL:
      return true

    default:
      return false
  }
}

export const isSunglassByProductSubdivision = (productSubdivision?: ProductSubdivisions) => {
  switch (productSubdivision) {
    case ProductTypeValues.SUN:
    case ProductHierarchies.SUNGLASSES:
      return true
    default:
      return false
  }
}

export const isGogglesByProductSubdivision = (productSubdivision?: ProductSubdivisions) => {
  switch (productSubdivision) {
    case ProductHierarchies.GOGGLES:
    case ProductTypeValues.SNOWGOGGLES:
      return true

    default:
      return false
  }
}

const mapProductTypeToHierarchy: Record<TogglableProductTypeValues, ProductHierarchies> = {
  [ProductTypeValues.OPTICAL]: ProductHierarchies.EYEGLASSES,
  [ProductTypeValues.SUN]: ProductHierarchies.SUNGLASSES,
}

export const isProductTypeSubdivision = () => {
  const productSubdivision = config.default.productSubdivision
  return productSubdivision === PRODUCT_TYPE
}

export const getProductSubdivisionFromCaseInsensitiveOne = (
  productSubdivision?: string
): ProductSubdivisions | undefined =>
  Object.values(ProductTypeValues).find(item => equals(item, productSubdivision)) ??
  Object.values(ProductHierarchies).find(item => equals(item, productSubdivision))

export const getProductSubdivisionFromProduct = (
  product: StandardProduct
): ProductSubdivisions | undefined => {
  if (isProductTypeSubdivision()) {
    return getProductSubdivisionFromCaseInsensitiveOne(product.productType)
  }

  return getProductSubdivisionFromCaseInsensitiveOne(product.product_hierarchy1)
}

export const isSunglassProduct = (product: StandardProduct) => {
  const productSubdivision = getProductSubdivisionFromProduct(product)
  return isSunglassByProductSubdivision(productSubdivision)
}

export const isEyeglassProduct = (product: StandardProduct) => {
  const productSubdivision = getProductSubdivisionFromProduct(product)
  return isEyeglassByProductSubdivision(productSubdivision)
}

export const isGogglesProduct = (product: StandardProduct) => {
  const productSubdivision = getProductSubdivisionFromProduct(product)
  return isGogglesByProductSubdivision(productSubdivision)
}

export const getProductSubdivisionFromProductType = (
  productType: ProductTypeValues
): ProductSubdivisions => {
  if (isProductTypeSubdivision()) {
    return productType
  }

  return mapProductTypeToHierarchy[productType as TogglableProductTypeValues]
}

export const isEyewearByProductSubdivision = (productSubdivision?: ProductSubdivisions) => {
  const productSubdivisionInsensitive =
    getProductSubdivisionFromCaseInsensitiveOne(productSubdivision)
  return (
    isEyeglassByProductSubdivision(productSubdivisionInsensitive) ||
    isSunglassByProductSubdivision(productSubdivisionInsensitive) ||
    isGogglesByProductSubdivision(productSubdivisionInsensitive)
  )
}
