import { useRef, useEffect } from 'react'
import { isEmpty } from 'lodash'

/**
 * A modification of usePrevious, this hook only keeps track of the last
 *  non-nullish value
 */
export const usePreviousNonNullish = <T>(value: T): T => {
  const ref = useRef<T>(value)
  useEffect(() => {
    if (value !== null && value !== undefined && !isEmpty(value)) {
      ref.current = value
    }
  })
  return ref.current
}
