import { Device } from '@abstractTypes/utils'
import { ButtonTypography } from '@components/core/Typography'
import { DisclaimerWrapper } from '@components/Disclaimer'
import { altNavigation, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const PrivacyPolicyTooltipContainer = styled.div<{ isFixed?: boolean }>`
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'absolute')};
  inset-block-start: ${pxToRem(25)};
  inset-inline-start: ${pxToRem(16)};
  z-index: 4;
  display: flex;
  align-self: start;
  align-items: start;
  inline-size: auto;
  min-block-size: ${pxToRem(100)};
  ${tablet(`
    inset-block-start: ${clampValues(8, 18)};
    inset-inline-start: ${clampValues(10, 16)};
  `)}
  ${landscape(
    Device.tablet,
    `
    inset-block-start: ${clampValuesLandscape(8, 15)};
    inset-inline-start: ${clampValuesLandscape(8, 15)};
  `
  )}
  ${({ isFixed }) =>
    isFixed &&
    `
    ${altNavigation(`
      inset-block-start: calc(56vh + ${pxToRem(15)});
    `)}
  `};
`

export const PrivacyPolicyButton = styled.button`
  margin-block-start: ${pxToRem(20)};
`

export const PrivacyPolicyTooltipWrapper = styled('div')<{ visible: boolean }>`
  inline-size: calc(100% - ${pxToRem(72)} - ${pxToRem(50)});
  max-inline-size: ${pxToRem(656)};
  margin-inline-start: 30px;
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  border-radius: 0.45rem;

  &:after {
    content: '';
    position: absolute;
    display: block;
    inline-size: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent ${({ theme }) => theme.colors.primaryLight};
    border-inline-size: ${pxToRem(13)} ${pxToRem(19)} ${pxToRem(13)} 0;
    inset-block-start: 32%;
    ${zoomPortrait(
      [110, 125],
      `
      inset-block-start: 26%;
    `
    )}
    ${zoomPortrait(
      [150],
      `
      inset-block-start: 22%;
    `
    )}
    ${zoomPortrait(
      [175, 200],
      `
      inset-block-start: 17%;
    `
    )}
    inset-inline-start: ${pxToRem(44)};
    ${zoomPortrait(
      [110],
      `
      inset-inline-start: ${pxToRem(47)};
    `
    )}
    ${zoomPortrait(
      [125],
      `
      inset-inline-start: ${pxToRem(52)};
    `
    )}
    ${zoomPortrait(
      [150],
      `
    inset-inline-start: ${pxToRem(60)};
    `
    )}
    ${zoomPortrait(
      [175],
      `
      inset-inline-start: ${pxToRem(68)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inset-inline-start: ${pxToRem(76)};
    `
    )}
    margin-block-start: ${pxToRem(-10)};
  }

  ${DisclaimerWrapper} {
    padding-block-end: ${pxToRem(20)};
    inline-size: 100%;
    inline-size: fit-content;
    max-inline-size: ${pxToRem(1050)};
    position: static;
    border-radius: 0.45rem;
    text-align: start;
    ${tablet(Device.ipadMini, `padding: ${pxToRem(10)};`)}
    ${landscape(`
      padding-inline-start: ${pxToRem(50)};
      padding-inline-end: ${pxToRem(50)}
    `)}

    ${ButtonTypography} {
      line-height: 1;
      text-decoration: underline;
      font-weight: 400;
    }
  }
`
