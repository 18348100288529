import { Device } from '@abstractTypes/utils'
import { altNavigation, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const SearchProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 0;
  padding-inline: ${pxToRem(112)};
  inline-size: 100%;
  block-size: calc(100% - ${pxToRem(520 + 112)});
  overflow-y: auto;
  ${zoomPortrait(
    [175, 200],
    `
    padding-block-end: ${pxToRem(128)};
    block-size: calc(100% - ${pxToRem(520 + 128)});
  `
  )}
  ${landscape(`
    padding-block: 0 ${pxToRem(80)};
    padding-inline:  ${pxToRem(112)} ;
    block-size: auto;
  `)}
  ${altNavigation(`
    padding-block: 0 ${pxToRem(80)};
    padding-inline:  ${pxToRem(112)} ;
    block-size: auto;
  `)}
  ${tablet(`
    padding-block: 0  ${clampValues(64, 80)};
    padding-inline:  ${clampValues(72, 96)} ;
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-block: 0  ${clampValuesLandscape(64, 80)};
    padding-inline:  ${clampValuesLandscape(72, 96)} ;
    `
  )}
  ${tablet(
    Device.tablet,
    `
    block-size: calc(100% - ${pxToRem(370)});
  `
  )}
  ${tablet(`margin-block-start: ${clampValues(34, 64)};`)}
  ${landscape(Device.tablet, `margin-block-start: ${clampValuesLandscape(37, 63)};`)}
`
