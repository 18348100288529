import { FrameAdvisorOption, FrameAdvisorStep } from '@abstractTypes/frameAdvisor'
import {
  setCompleteSurvey,
  setLastSurveyFaceStep,
  setSelectedOptionId,
} from '@actions/frameAdvisor'
import {
  CheckmarkIcon,
  FaFrameShapeCatIcon,
  FaFrameShapePilotIcon,
  FaFrameShapeSquareIcon,
  FaceShapeCircularIcon,
  FaceShapeOvalIcon,
  FaceShapeRectangularIcon,
  FaceShapeSquaredIcon,
  FaceShapeTriangleIcon,
} from '@components/core/Icons'
import { Text } from '@components/core/Typography'
import { useNextStepUrl } from '@hooks/useNextStepUrl'
import { useSaveOptionSelection } from '@hooks/useSaveOptionSelection'
import { useThemeContext } from '@hooks/useThemeContext'
import { useTryCompleteSurvey } from '@hooks/useTryCompleteSurvey'
import { altNavigation, altNavigationZoom, tablet, zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ColorSwatch } from '../ColorElements/ColorSwatch'
import { getColorIcon } from '../ColorElements/consts'

const getGenderIconMap = (color: string): Record<string, React.ReactElement> => ({
  woman: <FaFrameShapeCatIcon width={pxToRem(48)} height={pxToRem(16)} fill={color} />,
  man: <FaFrameShapeSquareIcon width={pxToRem(48)} height={pxToRem(22)} fill={color} />,
  skip: <FaFrameShapePilotIcon width={pxToRem(48)} height={pxToRem(20)} fill={color} />,
})

const getFaceShapeIconMap = (color: string): Record<string, React.ReactElement> => ({
  rounded: <FaceShapeOvalIcon width={pxToRem(32)} height={pxToRem(48)} fill={color} cut />,
  angular: <FaceShapeRectangularIcon width={pxToRem(34)} height={pxToRem(48)} fill={color} cut />,
})

const getFaceLengthIconMap = (color: string): Record<string, React.ReactElement> => ({
  rectangular: <FaceShapeRectangularIcon width={pxToRem(32)} height={pxToRem(48)} fill={color} />,
  squared: <FaceShapeSquaredIcon width={pxToRem(40)} height={pxToRem(40)} fill={color} />,
  triangle: <FaceShapeTriangleIcon width={pxToRem(40)} height={pxToRem(40)} fill={color} />,
  oval: <FaceShapeOvalIcon width={pxToRem(32)} height={pxToRem(48)} fill={color} />,
  circular: <FaceShapeCircularIcon width={pxToRem(40)} height={pxToRem(40)} fill={color} />,
})

const getSurveyStepIcon = ({ step = '', option = '', color = '' }) => {
  const stepOption = option.toLowerCase()
  switch (step.toLowerCase()) {
    case 'gender':
      return getGenderIconMap(color)[stepOption]
    case 'faceshape':
      return getFaceShapeIconMap(color)[stepOption]
    case 'facelength':
      return getFaceLengthIconMap(color)[stepOption]
    case 'eyecolor':
      return <ColorSwatch>{getColorIcon('eye', stepOption)}</ColorSwatch>
    case 'haircolor':
      return <ColorSwatch>{getColorIcon('hair', stepOption)}</ColorSwatch>
    default:
      return <div />
  }
}

export const StepWrapper = styled.button<{ disablePointerEvents?: boolean }>`
  position: relative;
  inline-size: ${pxToRem(200)};
  block-size: ${pxToRem(200)};
  padding-block: ${pxToRem(32)} ${pxToRem(50)};
  padding-inline: 0;
  background: ${({ theme }) => theme.colors.primaryLight};
  border-radius: ${pxToRem(15)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${({ disablePointerEvents }) => disablePointerEvents && 'pointer-events: none'}
  color: white !important;
  margin-block-start: ${pxToRem(32)};
  margin-inline-end: ${pxToRem(24)};
  ${tablet(`
    inline-size: ${pxToRem(150)};
    block-size: ${pxToRem(150)};
    padding-block: ${pxToRem(29)} ;
    padding-inline:  0;
  `)}
  ${altNavigation(`
    inline-size: ${pxToRem(150)};
    block-size: ${pxToRem(150)};
    padding-block: ${pxToRem(29)} ;
    padding-inline:  0;
  `)}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(300)};
    block-size: ${pxToRem(300)};
    padding-block: ${pxToRem(48)} ${pxToRem(80)};
    padding-inline:  0 ;
    `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(400)};
    block-size: ${pxToRem(400)};
    padding-block: ${pxToRem(64)} ${pxToRem(100)};
    padding-inline:  0 ;
  `
  )}
  ${altNavigationZoom(
    [150, 175],
    `
    inline-size: ${pxToRem(225)};
    block-size: ${pxToRem(225)};
    padding-block: ${pxToRem(43)} ;
    padding-inline:  0;
  `
  )};
  ${altNavigationZoom(
    [200],
    `
    inline-size: ${pxToRem(300)};
    block-size: ${pxToRem(300)};
    padding-block: ${pxToRem(58)} ;
    padding-inline:  0;
  `
  )};
`

const CheckboxContainer = styled.div<{ active: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  inset-block-start: ${pxToRem(11)};
  inset-inline-end: ${pxToRem(11)};
  inline-size: ${pxToRem(24)};
  block-size: ${pxToRem(24)};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.outline};
  padding-block: ${pxToRem(5)};
  padding-inline: ${pxToRem(3)};
  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.colors.highlight04};
    border: none;
  `};
  ${tablet(`
    inline-size: ${pxToRem(16)};
    block-size: ${pxToRem(16)};
  `)};
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(36)};
    block-size: ${pxToRem(36)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(48)};
    block-size: ${pxToRem(48)};
  `
  )}

  > svg {
    ${zoomPortrait(
      [150, 175],
      `
      inline-size: ${pxToRem(26)};
      block-size: ${pxToRem(17)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: ${pxToRem(34)};
      block-size: ${pxToRem(22)};
    `
    )}
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: ${pxToRem(21)};
  padding-inline: 0;

  ${tablet(`
    padding-block: ${pxToRem(10)};
  `)};
  ${altNavigation(`
    padding-block: ${pxToRem(10)};
  `)};
  ${zoomPortrait(
    [150, 175],
    `
    padding-block: ${pxToRem(32)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    padding-block: ${pxToRem(42)};
  `
  )}
  ${altNavigationZoom(
    [150, 175],
    `
    padding-block: ${pxToRem(15)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    padding-block: ${pxToRem(20)};
  `
  )}
  > svg {
    block-size: ${pxToRem(64)};
    inline-size: ${pxToRem(64)};
    ${tablet(`
      block-size: ${pxToRem(48)};
      inline-size: ${pxToRem(48)};
    `)};
    ${altNavigation(`
      block-size: ${pxToRem(48)};
      inline-size: ${pxToRem(48)};
    `)};
    ${zoomPortrait(
      [150, 175],
      `
      block-size: ${pxToRem(96)};
      inline-size: ${pxToRem(96)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      block-size: ${pxToRem(128)};
      inline-size: ${pxToRem(128)};
    `
    )}
    ${altNavigationZoom(
      [150, 175],
      `
      block-size: ${pxToRem(72)};
      inline-size: ${pxToRem(72)};
    `
    )}
    ${altNavigationZoom(
      [200],
      `
      block-size: ${pxToRem(96)};
      inline-size: ${pxToRem(96)};
    `
    )}
  }
`

interface FrameAdvisorOptionProps {
  option: FrameAdvisorOption
  step: FrameAdvisorStep
  slug?: string
}

export const FrameAdvisorOptionContent: React.FC<FrameAdvisorOptionProps> = ({
  option,
  step,
  slug,
}) => {
  const theme = useThemeContext()
  const dispatch = useDispatch()
  const history = useHistory()
  const { id, tags, title, nextStep, analyticsData } = option
  const selectedOptionIds = useSelector(state => state.frameAdvisor.selectedOptionIds)
  const { dataElementId, dataDescription } = analyticsData || {}
  const saveOptionSelection = useSaveOptionSelection(tags)
  const tryCompleteSurvey = useTryCompleteSurvey(nextStep)
  const nextStepUrl = useNextStepUrl(nextStep)
  const isSelected = selectedOptionIds[step.id] === id
  const activeColor = theme.colors.highlight04

  const handleNextStep = useCallback(() => {
    saveOptionSelection()
    if (!nextStep) {
      dispatch(setLastSurveyFaceStep(slug || ''))
      dispatch(setCompleteSurvey(true))
      history.push(nextStepUrl)
      return
    }
    dispatch(setSelectedOptionId(id, step.id))
    tryCompleteSurvey()
    history.replace(nextStepUrl)
  }, [
    nextStep,
    dispatch,
    id,
    step.id,
    saveOptionSelection,
    tryCompleteSurvey,
    history,
    nextStepUrl,
    slug,
  ])

  return (
    <>
      <StepWrapper
        onClick={isSelected ? () => false : handleNextStep}
        data-element-id={dataElementId}
        data-test={dataElementId}
        data-description={dataDescription}
        data-analytics_available_call="0"
        disablePointerEvents={isSelected}
        aria-label={`${step.title}. ${option.title}`}
      >
        <IconWrapper>
          {getSurveyStepIcon({
            step: step?.key,
            option: option.analyticsData?.dataDescription || '',
            color: isSelected ? activeColor : theme.colors.primaryDark,
          })}
        </IconWrapper>
        <Text weight="bold" color={isSelected ? activeColor : theme.colors.primaryDark}>
          {title}
        </Text>
        <CheckboxContainer active={isSelected}>
          <CheckmarkIcon stroke={theme.colors.primaryLight} fill={theme.colors.primaryLight} />
        </CheckboxContainer>
      </StepWrapper>
    </>
  )
}
