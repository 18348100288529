import { Store, Vm } from '@abstractTypes/graphqlTypes'
import {
  VMMV_PICTURE_VIDEO_LOCALSTORAGE_KEY,
  VMMV_POLICY_COOKIE_KEY,
  VMMV_POLICY_COOKIE_KEY2,
  VMMV_SAVED_STATE_KEY,
} from '@components/VirtualMirror/constants'
import config from '@config/index'
import QRCodeBase from 'qrcode.react'
import ReactDOMServer from 'react-dom/server'
import { PAGE_PLATFORM } from './analytics'
import { arrayIncludes } from './caseInsensitive'
import { isRBBrand } from './custom'

export const formatVMDataCustom = (
  token: string,
  localizedName: string,
  urlThumbnail: string,
  store: Store,
  brand: 'rb' | 'oo'
): Vm => {
  const OoBrand = {
    logoUrl: `${config.assets.URL}/brands/oakley.svg`,
    name: 'Oakley',
  }
  const RbBrand = {
    logoUrl: `${config.assets.URL}/brands/ray-ban.svg`,
    name: 'Ray-Ban',
  }

  const productsArray = [
    {
      upc: token || '',
      code: localizedName || '',
      name: localizedName || '',
      colorCode: '',
      thumbnailUrl: urlThumbnail || '',
      brand: isRBBrand(brand) ? RbBrand : OoBrand,
    },
  ]

  return {
    store: {
      storeId: store.storeId,
      id: store.id,
      type: store.storeType,
      name: store.name,
      address: store.address,
      globalStoreId: store.globalStoreId,
      region: getRegionForVMMV(store.region),
      countryId: store.countryId,
    },
    analytics: {
      adobeSessionId: window.utag_data?.Adobe_SessionId,
      pagePlatform: PAGE_PLATFORM,
      pageBrand: window.utag_data?.Page_Brand,
      storeId: store.storeId,
      sourcePosition: 'PCP',
      pageEnvironment: window.utag_data?.Page_Environment,
      storeRegion: store.region.toUpperCase(),
      storeCompany: config.vmStore,
      storeGlobalId: store?.globalStoreId,
      source: 'QRCODE',
    },
    style: config.vmStyle,
    addFrameLensColor: false,
    glassEnv: config.vmEnv.toUpperCase(),
    products: productsArray,
  }
}

export const generateBase64Qrcode = (value: string, size = 100) => {
  const svg = ReactDOMServer.renderToString(
    <QRCodeBase xmlns="http://www.w3.org/2000/svg" size={size} value={value} renderAs={'svg'} />
  )
  return btoa(svg)
}

// TODO: This is garbage and it was done as a quick fix was needed while still releasing R11-22
// TODO: We need to remove this and do a better check, or, better, have the VMMV handle the regions and clean the field before submitting it to VM
export const getRegionForVMMV = (region: string) => {
  const usRegions = ['na', 'us']
  const anzRegions = ['au', 'nz']

  if (arrayIncludes(usRegions, region)) return 'US'
  if (arrayIncludes(anzRegions, region)) return 'AU'

  // TODO: china - is it fine sending EU for china too??
  return 'EU'
}

const cleanupVMMVPictureVideo = () => {
  localStorage.removeItem(VMMV_PICTURE_VIDEO_LOCALSTORAGE_KEY)
}

const setCookieExpiredForAllPaths = (
  name: string,
  cookieString?: string,
  count = -1,
  paths?: string[]
): boolean => {
  if (paths && count > paths.length - 1) {
    return false
  } else if (paths) {
    cookieString = `${cookieString}/${paths[count]}`.replace('//', '/')
    document.cookie = cookieString
    document.cookie = `${cookieString}/`
  } else {
    cookieString = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    document.cookie = cookieString
    cookieString = `${cookieString}path=`
  }

  if (document.cookie.indexOf(name) !== -1) {
    setCookieExpiredForAllPaths(
      name,
      cookieString,
      count + 1,
      paths || location.pathname.split('/')
    )
  }

  return true
}

const cleanupVMMVPolicyAcceptance = () => {
  setCookieExpiredForAllPaths(VMMV_POLICY_COOKIE_KEY)
  setCookieExpiredForAllPaths(VMMV_POLICY_COOKIE_KEY2)
  localStorage.removeItem(VMMV_SAVED_STATE_KEY)
}

export const cleanupVMMVData = () => {
  cleanupVMMVPictureVideo()
  cleanupVMMVPolicyAcceptance()
}
