import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const sghTheme: ThemeOverride = {
  colors: {
    primaryDark: '#222222',
    primaryDarkPressed: '#555555',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#F7F7F7',
    inactive: '#C5C5C5',
    outline: '#E6E6E6',
    background: '#F7F7F7',
    highlight01: '#222222',
    highlight01Pressed: '#555555',
    highlight02: '#FF7714',
    highlight02Pressed: '#F2B140',
    highlight03: '#222222',
    highlight04: '#504BD6',
    functional01: '#990000',
    functional02: '#417505',
    functional03: '#F2B140',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 300,
      },
      fontSize: {
        tower: 100,
        tablet: 75,
        tabletMini: 57,
      },
      lineHeight: {
        tower: 115,
        tablet: 80,
        tabletMini: 72,
      },
    },
    heading1: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 300,
      },
      fontSize: {
        tower: 60,
        tablet: 56,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 75,
        tablet: 62,
        tabletMini: 60,
      },
    },
    heading2: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 300,
      },
      fontSize: {
        tower: 40,
        tablet: 40,
        tabletMini: 34,
      },
      lineHeight: {
        tower: 48,
        tablet: 48,
        tabletMini: 46,
      },
    },
    heading3: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 34,
        tablet: 34,
        tabletMini: 26,
      },
      lineHeight: {
        tower: 44,
        tablet: 42,
        tabletMini: 36,
      },
    },
    heading4: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 300,
        bold: 700,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 42,
        tablet: 34,
        tabletMini: 31,
      },
    },
    heading5: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 26,
        tablet: 30,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 14,
      },
      lineHeight: {
        tower: 20,
        tablet: 20,
        tabletMini: 20,
      },
    },
    cta: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
        bold: 500,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    link: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 30,
        tablet: 34,
        tabletMini: 31,
      },
    },
    caption: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 16,
        tablet: 16,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'rounded',
      borderRadius: 64,
    },
    rangeSlider: {
      background: '#C5C5C5', //inactive
      foreground: '#222222', //highlight01
      thumb: '#F7F7F7', //background
      thumbBorder: '#C5C5C5', //inactive
    },
  },
}
