import { IconProps } from '@abstractTypes/icons'

export const HomeIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.45 11.218L16.7827 0.568662C16.5493 0.399329 16.274 0.313995 15.9993 0.313995C15.7247 0.313995 15.45 0.398662 15.2167 0.567995L0.550667 11.204C0.204667 11.4547 0 11.856 0 12.2833V30.6667C0 31.4033 0.596667 32 1.33333 32H12H20H30.6667C31.4033 32 32 31.4033 32 30.6667V12.2967C32 11.87 31.7953 11.4687 31.45 11.218ZM12 30.6667V21.3333H20V30.6667H12ZM30.6667 30.6667H21.3333V21.3333C21.3333 20.5967 20.7367 20 20 20H12C11.2633 20 10.6667 20.5967 10.6667 21.3333V30.6667H1.33333V12.2833L15.9993 1.64733H16L30.6667 12.2967V30.6667Z"
        fill={fill}
      />
    </svg>
  )
}
