import { DataAttrs, Product } from '@abstractTypes/product'
import { removeItemFromFASelection } from '@actions/frameAdvisor'
import { ThumbUpIcon } from '@components/core/Icons'
import { ThumbBtn } from '@components/core/ThumbBtn/style'
import { useSendAnalyticsProductsEvent } from '@hooks/analyticsHooks'
import { useActions } from '@hooks/useActions'
import { useThemeContext } from '@hooks/useThemeContext'
import { useWishList } from '@hooks/useWishlist'
import { clampValues, pxToRem } from '@libs/styled'
import { useNavigationContext } from '@hooks/useNavigationContext'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isIPadView } from '@libs/utils'
import { useAccessibilityContext } from '@providers/accessibilityProvider'
import { EVENTS_ID } from '@libs/analytics'

export interface WishListButtonProps {
  product: Product
  dataAttrs?: DataAttrs
  buttonSize?: 'large' | 'standard'
}

export const ProductWishListActionButton: React.FC<WishListButtonProps> = ({
  product,
  dataAttrs,
  buttonSize: propButtonSize,
}) => {
  const theme = useThemeContext()
  const { t } = useTranslation()
  const { sendNotification } = useAccessibilityContext()
  const { wishlisted, toggleItemToWishlist } = useWishList(product.UPC)
  const { zoomLevel } = useNavigationContext()

  const sendAddToWishlistEvent = useSendAnalyticsProductsEvent({
    id: EVENTS_ID.event,
    Events_ProdFavAdd: '1',
  })
  const actions = useActions({
    removeItemFromFASelection,
  })

  const handleToggleWishlist = useCallback(
    (product: Product) => {
      toggleItemToWishlist(product)
      if (wishlisted) {
        actions.removeItemFromFASelection(product)
      }
      if (!wishlisted) {
        sendAddToWishlistEvent && sendAddToWishlistEvent(product)
      }
    },
    [toggleItemToWishlist, wishlisted, actions, sendAddToWishlistEvent]
  )

  const accessibleToggleItemToWishlist = useCallback(
    product => {
      if (!sendNotification) {
        return handleToggleWishlist(product)
      }
      if (wishlisted) {
        sendNotification(
          t('AccessibilityLabel.productRemovedFromWishlist', { name: product.name }),
          'assertive'
        )
      } else {
        sendNotification(
          t('AccessibilityLabel.productAddedToWishlist', { name: product.name }),
          'assertive'
        )
      }
      handleToggleWishlist(product)
    },
    [wishlisted, handleToggleWishlist, sendNotification, t]
  )

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    accessibleToggleItemToWishlist(product)
  }

  const buttonSize = zoomLevel > 150 ? 'large' : propButtonSize || 'standard'

  const sizes =
    buttonSize === 'large'
      ? isIPadView()
        ? clampValues(18, 22)
        : pxToRem(22, zoomLevel / 100)
      : isIPadView()
        ? clampValues(14, 20)
        : pxToRem(20, zoomLevel / 100)

  return (
    <>
      <ThumbBtn
        isToggleButton={true}
        onClick={handleClick}
        wishlisted={wishlisted}
        size={buttonSize}
        data-test={dataAttrs && dataAttrs.wishlist}
        data-element-id={dataAttrs && dataAttrs.wishlist}
        data-description={dataAttrs && dataAttrs.description}
        aria-label={t('ARIA.likeIcon.label')}
      >
        <ThumbUpIcon
          stroke={wishlisted ? theme.colors.primaryLight : theme.colors.primaryDark}
          fill={wishlisted ? theme.colors.primaryLight : theme.colors.primaryDark}
          height={sizes}
          width={sizes}
        />
      </ThumbBtn>
    </>
  )
}
