import { CheckboxWithLabel } from '@components/core/Checkbox'
import { Checkbox as CheckboxLib, CheckboxWithLabelWrapper } from '@components/core/Checkbox/styles'
import { FormItemWrapper, HelpStyled } from '@components/core/FormItem/styles'
import { pxToRem, theme } from '@libs/styled'
import styled from 'styled-components'

export const SubscriptionsCheckbox = styled(CheckboxWithLabel)`
  margin: 0;

  &:first-child {
    margin-inline-end: ${pxToRem(20)};
  }

  ${CheckboxLib} {
    &:not(:checked) + label,
    :checked + label {
      padding-inline-start: ${pxToRem(35)};
    }
  }
`

export const SubscriptionsCheckboxWrapper = styled.div`
  margin-inline-start: 0;
  padding: ${pxToRem(13)};
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: 8px;
  background-color: ${theme('checkbox_bg_color')};

  &:nth-child(2) {
    margin-block-start: ${pxToRem(20)};
  }

  ${FormItemWrapper} {
    margin-block-end: 0;
  }

  ${HelpStyled} {
    block-size: ${pxToRem(14)};
  }
`

export const CheckoutCheckboxWrapper = styled.div<{ checked?: boolean }>`
  inline-size: 100%;
  padding-block: ${pxToRem(24)} ${pxToRem(24)};
  padding-inline: ${pxToRem(15)} ${pxToRem(10)};
  border: ${({ checked, theme }) =>
    `1px solid ${checked ? theme.checkout.checkbox_checked_border_color : theme.colors.inactive}`};
  margin-block: ${pxToRem(20)} 0;
  margin-inline: 0;

  ${CheckboxWithLabelWrapper} {
    label {
      padding-inline-start: ${pxToRem(35)};
    }
  }

  &:first-child {
    margin-block-start: 0;
  }
`

export const SubscriptionsPanelContainer = styled.div``

export const ShippingPanelContainer = styled.div``
