import { resetBarcodeModalInfo } from '@actions/barcode'
import {
  BarcodeButtonContent,
  BarcodeButtonTitle,
  BarcodeScannerButton,
  DiscountBanner,
  SearchContainer,
  SearchDrawerStyled,
  SearchFloatingButton,
  SearchHeader,
  SearchIconWrapper,
  SearchInput,
  SearchInputContent,
  SearchInputWrapper,
  SearchWrapper,
} from '@components/SearchDrawer/styles'
import { BarcodeIcon, CloseIcon, SearchIcon } from '@components/core/Icons'
import config from '@config/index'
import { isBarcodeScannerAvailable, useBarcodeScanner } from '@hooks/useBarcodeScanner'
import { useDebounce } from '@hooks/useDebounce'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useNavigationFlowHandler } from '@hooks/useNavigationFlowHandler'
import { useScrollUp } from '@hooks/useScrollUp'
import { useStoreContext } from '@hooks/useStoreContext'
import { useThemeContext } from '@hooks/useThemeContext'
import { clampValues, pxToRem } from '@libs/styled'
import { getSearchValueFromQs } from '@libs/url'
import { isIPadView } from '@libs/utils'
import { default as React, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SearchProductsWrapper from './SearchProducts'
import { SearchSuggestions } from './SearchSuggestions'

const SEARCH_DEBOUNCE_INTERVAL = 1000
const SUGGESTIONS_DEBOUNCE_INTERVAL = 300

export const SearchDrawer: React.FC = () => {
  const { zoomLevel, isAlternativeNavigation, landscapeNavigation } = useNavigationContext()
  const theme = useThemeContext()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const initialQuery = getSearchValueFromQs(history.location)
  const [fromSuggestions, setFromSuggestions] = useState(false)
  const { barcodeFlow } = useNavigationFlowHandler()
  const [query, setQuery] = useState(initialQuery)
  const [previousQuery, setPreviousQuery] = useState('')

  const { closeAnyDrawer, isVisible } = useDrawerHandler('search')
  const debouncedQuery = useDebounce(query, SEARCH_DEBOUNCE_INTERVAL)
  const suggestionsDebouncedQuery = useDebounce(query, SUGGESTIONS_DEBOUNCE_INTERVAL)
  const store = useStoreContext()

  const openBarcodeScanner = useBarcodeScanner({
    timeout: config.resetAppTimeOut,
    assetsLogoSrc: `${config.assets.URL}/logos/${config.shopperSlug}/logo-for-barcode-page.png`,
  })

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery)
    }
  }, [initialQuery])

  const handleCloseSearchDrawer = () => {
    closeAnyDrawer()
    if (barcodeFlow) dispatch(resetBarcodeModalInfo()) // TODO: this is for barcode scenario 8, in which similar styles rendered are handled on redux and needs to be cleaned.
  }

  const updateQuery = (suggestionValue: string, isFromSuggestions = false) => {
    setPreviousQuery(query)
    setQuery(suggestionValue)
    if (isFromSuggestions !== fromSuggestions) {
      setFromSuggestions(isFromSuggestions)
    }
    if (barcodeFlow) {
      history.replace(history.location.pathname)
      dispatch(resetBarcodeModalInfo())
    }
  }

  const isBarcodeScannerEnabled = store.barcodeReaderEnabled
  const isIPad = isIPadView()
  const blockScroll = !(landscapeNavigation && zoomLevel < 150) || isIPad

  const { isScrollUp, setRef } = useScrollUp({
    block: blockScroll,
    returnFalseIfBlock: true,
  })

  const isDiscountBannerSticky = isScrollUp || (isAlternativeNavigation && zoomLevel < 110)

  return (
    <SearchDrawerStyled
      position="left"
      isFullscreen
      visible={isVisible}
      unmountOnExit={false}
      showOverlay={false}
    >
      <SearchFloatingButton
        type="button"
        onClick={handleCloseSearchDrawer}
        aria-label={t('ARIA.closeIcon.label')}
      >
        <CloseIcon
          width={isIPad ? pxToRem(20) : pxToRem(34, zoomLevel / 100)}
          height={isIPad ? pxToRem(20) : pxToRem(34, zoomLevel / 100)}
          stroke={theme.colors.primaryDark}
          fill={theme.colors.primaryDark}
        />
      </SearchFloatingButton>
      <SearchWrapper ref={setRef}>
        <SearchHeader showPrivacyPolicyTooltip={true} isSticky={isScrollUp} />
        <DiscountBanner takeSpaceWhenEmpty={true} isSticky={isDiscountBannerSticky} />
        <SearchContainer isSticky={isScrollUp}>
          <SearchInputContent>
            <SearchInputWrapper>
              {isVisible && (
                <SearchInput
                  type="search"
                  onChange={e => {
                    updateQuery(e.target.value)
                  }}
                  value={query}
                  data-autofocus
                  placeholder={t('search.keywords')}
                  aria-label={t('search.keywords')}
                  data-test="Search_input"
                  name="Search_input"
                />
              )}
              <SearchIconWrapper>
                <SearchIcon
                  width={isIPadView() ? clampValues(24, 32) : pxToRem(32, zoomLevel / 100)}
                  height={isIPadView() ? clampValues(24, 32) : pxToRem(32, zoomLevel / 100)}
                />
              </SearchIconWrapper>
            </SearchInputWrapper>
            {isBarcodeScannerAvailable() && isBarcodeScannerEnabled && (
              <BarcodeScannerButton
                dataAttrs={{ id: 'MainNav_SearchBarcode' }}
                onClick={() => {
                  closeAnyDrawer()
                  openBarcodeScanner()
                }}
              >
                <BarcodeButtonContent>
                  <BarcodeIcon
                    stroke="#fff"
                    width={isIPadView() ? clampValues(24, 32) : pxToRem(32)}
                    height={isIPadView() ? clampValues(24, 32) : pxToRem(32)}
                    fill={theme.colors.primaryLight}
                  />
                  <BarcodeButtonTitle
                    aria-label={t('search.barcode')}
                    aria-description={t('search.barcode')}
                  >
                    {t('search.barcode')}
                  </BarcodeButtonTitle>
                </BarcodeButtonContent>
              </BarcodeScannerButton>
            )}
          </SearchInputContent>
          {suggestionsDebouncedQuery &&
            config.toggleFeature.searchSuggestions &&
            !fromSuggestions && (
              <SearchSuggestions
                query={suggestionsDebouncedQuery}
                updateQuery={v => {
                  updateQuery(v, true)
                }}
              />
            )}
        </SearchContainer>
        {debouncedQuery && (
          <SearchProductsWrapper
            query={debouncedQuery}
            fromSuggestions={fromSuggestions}
            previousQuery={previousQuery}
          />
        )}
      </SearchWrapper>
    </SearchDrawerStyled>
  )
}
