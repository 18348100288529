import { IconProps } from '@abstractTypes/icons'

export const HydrophobicIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 44"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={33}
        height={44}
      >
        <path d="M0 0h32.483v43.21H0V0z" fill={fill} />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.012 27.311c0-6.024-10.746-20.596-13.711-24.21C13.094 7.078 2.469 21.287 2.469 27.31c0 7.467 6.178 13.492 13.832 13.492 7.536 0 13.711-6.025 13.711-13.492zm2.472 0c0 8.792-7.288 15.9-16.183 15.9C7.288 43.21 0 36.102 0 27.31 0 20.204 11.732 4.667 15.312.451c.496-.601 1.482-.601 1.854 0 3.951 4.458 15.318 19.995 15.318 26.86z"
          fill={fill}
          strokeWidth={0}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.405 23.66c.451-1.398 2.601-.582 2.036.817-1.133 3.028-.568 5.942 1.582 8.504.906 1.165-.792 2.68-1.81 1.516-2.6-3.145-3.28-6.992-1.808-10.836z"
        fill={fill}
        strokeWidth={0}
      />
    </svg>
  )
}
