import { PrizmColors, PrizmFonts } from 'styled-components'

const colors: PrizmColors = {
  primary: '#ffffff',
}

const font = 'Avenir Next, Helvetica, Arial'

const fonts: PrizmFonts = {
  font: font,
  heading1: {
    fontFamily: font,
    weight: {
      regular: 900,
    },
    fontSize: {
      tower: 100,
      tablet: 68,
      tabletMini: 50,
    },
    lineHeight: {
      tower: 125,
      tablet: 85,
      tabletMini: 63,
    },
  },
  body: {
    fontFamily: font,
    weight: {
      regular: 700,
    },
    fontSize: {
      tower: 36,
      tablet: 36,
      tabletMini: 26,
    },
    lineHeight: {
      tower: 45,
      tablet: 45,
      tabletMini: 33,
    },
  },
  cta: {
    fontFamily: font,
    weight: {
      regular: 700,
    },
    fontSize: {
      tower: 30,
      tablet: 30,
      tabletMini: 26,
    },
    lineHeight: {
      tower: 45,
      tablet: 45,
      tabletMini: 33,
    },
  },
}

export default {
  colors,
  fonts,
}
