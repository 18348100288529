import { IconProps } from '@abstractTypes/icons'

export const CaretUpBoldIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2189 17.6642C32.9999 16.8832 32.9999 15.6168 32.2189 14.8358L18.2189 0.835787C17.4379 0.0547369 16.1715 0.0547369 15.3905 0.835787L1.39047 14.8358C0.609425 15.6168 0.609425 16.8832 1.39047 17.6642C2.17152 18.4453 3.43785 18.4453 4.2189 17.6642L16.8047 5.07843L29.3905 17.6642C30.1715 18.4453 31.4379 18.4453 32.2189 17.6642Z"
        fill="#333333"
      />
    </svg>
  )
}
