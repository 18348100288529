import { Service } from '@components/EnvironmentInfo'
import styled from 'styled-components'

const ServiceContainer = styled.div`
  overflow-x: scroll;
  block-size: 100vh;
`
const ServicePage = () => {
  return (
    <ServiceContainer>
      <Service />
    </ServiceContainer>
  )
}

export default ServicePage
