import { AppConfig, BannerRegion } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'
import getBannerInfoByDns from '@libs/envs/getBannerInfoByDns'
import isLocalhost from '@libs/envs/isLocalhost'
import packageJson from 'package.json'
import {
  ASSETS_PORT,
  BFF_PORT,
  ORIGIN_SUBDNS,
  RTR_JS_VERSION,
  VM_JS_VERSION,
} from '../default/const'
import googleApiSupportedLangs from '../default/googleApiSupportedLangs'

// TODO: use a better structure for this configuration object

interface CalculatedUrls {
  nodejsApi: string
  nodejsAssets: string
  courtesy: string
  courtesyHostUrl: string
}

export const getBaseLocalhostEnvVariables = () => {
  const calculatedUrls: CalculatedUrls = {
    nodejsApi: `http://localhost:${BFF_PORT}`,
    nodejsAssets: `http://localhost:${ASSETS_PORT}`,
    courtesy: 'https://courtesy.fc-stage.instore.oakley.com',
    courtesyHostUrl: 'https://www.courtesy-ss.instore.oakley.com/',
  }

  return getBaseEnvVariables('na', calculatedUrls)
}

export const getBaseRemoteEnvVariables = (
  region: BannerRegion,
  bffSlug: string,
  courtesySlug: string
) => {
  const calculatedUrls: CalculatedUrls = {
    nodejsApi: `https://api.${bffSlug}${ORIGIN_SUBDNS}`,
    nodejsAssets: `https://assets.${bffSlug}${ORIGIN_SUBDNS}`,
    courtesy: `https://courtesy.${courtesySlug}${ORIGIN_SUBDNS}`,
    courtesyHostUrl: `https://www.courtesy-ss.instore.oakley.com/`, // only one endpoint for everything
  }

  return getBaseEnvVariables(region, calculatedUrls)
}

const getShopperSlugByEnv = () =>
  (isLocalhost() ? process.env.REACT_APP_SHOPPER_SLUG : getBannerInfoByDns().shopperSlug) ??
  'master'

const getBaseEnvVariables = (region: BannerRegion, urls: CalculatedUrls): AppConfig => {
  const baseEnvVariables: AppConfig = {
    assets: { URL: `${urls.nodejsAssets}/assets` },
    availabilityTimeout: 5000,
    menuStyleVariant: 'ACCORDION',
    component: { showQRCodeDebugInfo: false },
    courtesyInterval: 60000,
    courtesyUrl: urls.courtesy,
    courtesyHostUrl: urls.courtesyHostUrl,
    publicUrl: '',
    default: {
      productType: ProductTypeValues.SUN,
      faPlaylistType: FaPlaylistType.SUN,
      productSubdivision: 'productType',
    },
    dressedOnImageService: 'https://test-gw-api-vtoimageheadless.luxdeepblue.com/',
    enablePaginationLimit: 15,
    enableServiceWorker: true,
    serviceWorkerCachePeriod: 1,
    env: 'development',
    googleApiSupportedLangs,
    googleMapsApiKey: 'AIzaSyB9n9xyauS721OlTgTJA_YCBOScm1bcIYE',
    itemsPerFrameAdvisor: 12,
    itemsPerPage: 36,
    log2api: false,
    logServerUrl: `${urls.nodejsApi}/trace-log`,
    frameAdvisorBestMatchCount: 3,
    middleware: { URL: `${urls.nodejsApi}/graphql` },
    ooCustomEndPoint: `${urls.nodejsApi}/oocustom`,
    ooCustomJsUrl:
      'https://cdn-prod.fluidconfigure.com/static/assets/prod/qa/customers/c1620/configureHtml/etc/assets/js/fluidConfigure-oak-retail.min.js',
    ooCustomOcId: '05de1c59-981b-4c16-9485-a9cebe374d1c',
    pictureConfig: {
      priority: ['Brand', 'PI21', 'PI20', 'EXT', 'Degree', 'Catalog image'],
      mainAngle: 'QUARTER', // TODO: let's move this into a configuration as we did for product info configs
    },
    productImageUrl: 'https://smartshopper-im2.luxottica.com',
    rbCustomEndPoint: `${urls.nodejsApi}/rbcustom`,
    rbCustomJsUrl:
      '//cdn-prod.fluidconfigure.com/static/assets/prod/qa/customers/c1589/configureHtml/etc/assets/js/fluidConfigure-rbn-retail.min.js',
    rbCustomOcId: '6287cecb-0a0c-42d4-8548-aad222870035',
    resetAppTimeOut: 420000, // TODO: create constants
    errorWidgetTimeOut: 60000, // TODO: create constants
    searchSuggestionsCount: 3,
    shopperName: 'MASTER',
    shopperSlug: 'master',
    showStoreAvailableFilter: false,
    showExtraPdpDetails: false,
    storeID: '0',
    storeLang: 'en-US',
    tealiumAnalytics: {
      profile: 'smartshopper2',
      shopperSlug: getShopperSlugByEnv(),
      gtagId: 'dw-gtag-tealium',
    },
    toggleFeature: {
      analytics: true,
      badgeNew: false,
      discountBanner: true,
      virtualKeyboard: true,
      availableInStore: true,
      courtesy: false,
      engraving: true,
      searchSuggestions: true,
      emailSuggestions: true,
      customerOrderError: false,
      handoff: true,
      pdpExtraDetails: false,
      newMerch: false,
    },
    vmEnv: 'test',
    fsaEnv: 'test',
    vmKey: '2dc7d7e3-14cd-4459-bdc3-cb557f47c821',
    vmSource: 'RETAIL_SMART_SHOPPER',
    vmURL: 'https://qa-virtualmirror.luxottica.com',
    vmJS: `https://vmmv-uat.luxottica.com/v/${VM_JS_VERSION}/index.umd.js`,
    vmStore: 'master',
    vmStyle: 'master',
    isMonoBrand: false,
    productTileShape: 'STANDARD', // TODO: let's move this into a configuration as we did for product info configs
    wishListProductTileShape: 'STANDARD',
    rtrJS: `https://uat-rtrmv.essilorluxottica.com/lib/v/${RTR_JS_VERSION}/main.umd.js`,
    shopperRegion: region === 'na' ? 'na' : 'emea',
    version: parseInt(packageJson.version),
  }

  return baseEnvVariables
}
