import { AppConfigOverride } from '@abstractTypes/envs'

const getSvEnvVariables = () => {
  const svEnvVariables: AppConfigOverride = {
    shopperSlug: 'salmoiraghi',
    storeID: '10131',
    storeLang: 'it-IT',
    shopperName: 'SALMOIRAGHI & VIGANO',
    vmStyle: 'sv',
    vmStore: 'sv',
  }

  return svEnvVariables
}

export default getSvEnvVariables
