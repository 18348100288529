import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const svTheme: ThemeOverride = {
  colors: {
    primaryDark: '#222222',
    primaryDarkPressed: '#747474',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#F9F9F9',
    inactive: '#D6D6D6',
    outline: '#D6D6D6',
    background: '#F9F9F9',
    highlight01: '#222222',
    highlight01Pressed: '#747474',
    highlight02: '#475872',
    highlight02Pressed: '#475872',
    highlight03: '#222222',
    highlight04: '#504BD6',
    functional01: '#ED4545',
    functional02: '#79AF2C',
    functional03: '#FABD66',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'Nunito',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 100,
        tablet: 75,
        tabletMini: 57,
      },
      lineHeight: {
        tower: 115,
        tablet: 97.5,
        tabletMini: 74.1,
      },
    },
    heading1: {
      fontFamily: 'Nunito',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 50,
        tablet: 40,
        tabletMini: 36,
      },
      lineHeight: {
        tower: 65,
        tablet: 52,
        tabletMini: 46.8,
      },
    },
    heading2: {
      fontFamily: 'Nunito',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 40,
        tablet: 36,
        tabletMini: 32,
      },
      lineHeight: {
        tower: 52,
        tablet: 46.8,
        tabletMini: 41.6,
      },
    },
    heading3: {
      fontFamily: 'Nunito',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 34,
        tablet: 30,
        tabletMini: 26,
      },
      lineHeight: {
        tower: 47.6,
        tablet: 39,
        tabletMini: 33.8,
      },
    },
    heading4: {
      fontFamily: 'Nunito',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 25,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 32.5,
        tablet: 31.2,
        tabletMini: 26,
      },
    },
    heading5: {
      fontFamily: 'Nunito',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 26,
        tablet: 26,
        tabletMini: 23.4,
      },
    },
    body: {
      fontFamily: 'Nunito',
      weight: {
        regular: 400,
        bold: 600,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 23.4,
        tablet: 20.8,
        tabletMini: 20.8,
      },
    },
    label: {
      fontFamily: 'Nunito',
      weight: {
        regular: 600,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 13,
      },
      lineHeight: {
        tower: 18.2,
        tablet: 18.2,
        tabletMini: 16.9,
      },
    },
    cta: {
      fontFamily: 'Nunito',
      weight: {
        regular: 600,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 23.4,
        tablet: 20.8,
        tabletMini: 20.8,
      },
    },
    link: {
      fontFamily: 'Nunito',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 25,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 32.5,
        tablet: 31.2,
        tabletMini: 26,
      },
    },
    caption: {
      fontFamily: 'Nunito',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 13,
        tablet: 13,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 16.9,
        tablet: 16.9,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'rounded',
      borderRadius: 64,
    },
    rangeSlider: {
      background: '#D6D6D6', //inactive
      foreground: '#222222', //highlight01
      thumb: '#F9F9F9', //background
      thumbBorder: '#D6D6D6', //inactive
    },
  },
}
