import { GreenShipmentColors, GreenShipmentFonts } from 'styled-components'

const colors: GreenShipmentColors = {
  primary: '#000000',
  primaryBlue: '#8EC5E2',
  secondaryBlue: '#DDEEF6',
  primaryLight: '#ffffff',
}

const font = 'Avenir Next, Helvetica, Arial'

const fonts: GreenShipmentFonts = {
  font: font,
  heading1: {
    fontFamily: font,
    weight: {
      regular: 350,
    },
    fontSize: {
      tower: 60,
      tablet: 34,
      tabletMini: 34,
    },
    lineHeight: {
      tower: 62,
      tablet: 46,
      tabletMini: 46,
    },
  },
  heading2: {
    fontFamily: font,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 32,
      tablet: 20,
      tabletMini: 20,
    },
    lineHeight: {
      tower: 48,
      tablet: 26,
      tabletMini: 26,
    },
  },
  heading3: {
    fontFamily: font,
    weight: {
      regular: 350,
    },
    fontSize: {
      tower: 30,
      tablet: 20,
      tabletMini: 20,
    },
    lineHeight: {
      tower: 36,
      tablet: 26,
      tabletMini: 26,
    },
  },
  body: {
    fontFamily: font,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 18,
      tablet: 15,
      tabletMini: 15,
    },
    lineHeight: {
      tower: 26,
      tablet: 22,
      tabletMini: 22,
    },
  },
}

export default {
  colors,
  fonts,
}
