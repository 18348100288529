import { IconProps } from '@abstractTypes/icons'

export const ColorPickerIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8082 29.8662C23.434 29.8662 29.6162 23.684 29.6162 16.058C29.6162 14.7041 29.4211 13.3952 29.0579 12.1588L24.5069 13.4877C24.7474 14.3025 24.8764 15.1653 24.8764 16.058C24.8764 21.0664 20.8163 25.1265 15.8082 25.1265C10.7998 25.1265 6.73996 21.0664 6.73996 16.058C6.73996 11.0497 10.7998 6.98976 15.8082 6.98976C16.1892 6.98976 16.5648 7.01328 16.9335 7.0589L17.5756 2.36195C16.9969 2.28815 16.4071 2.25 15.8082 2.25C8.18209 2.25 2 8.43211 2 16.058C2 23.684 8.18209 29.8662 15.8082 29.8662Z"
        fill="url(#paint0_angular_1874_61690)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8292 7.77068L22.6986 5.93137L14.07 16.3725L14.283 18.3741L16.2539 18.2118L24.8292 7.77068ZM23.3907 5.1198L25.5212 6.95912L26.7996 5.39026L24.669 3.55094L23.3907 5.1198ZM24.9349 2.36074L27.9177 4.90335C28.1307 5.11983 28.1839 5.44437 27.9709 5.66074L16.8923 19.0773C16.8388 19.1855 16.6792 19.2396 16.5727 19.2396L13.8027 19.5101C13.5366 19.5101 13.2703 19.2937 13.2703 19.0232L13.0039 16.21C13.0039 16.1018 13.0572 15.9396 13.1105 15.8314L24.1892 2.46893C24.4022 2.19847 24.7219 2.19847 24.9349 2.36074Z"
        fill="#5D6263"
      />
      <defs>
        <radialGradient
          id="paint0_angular_1874_61690"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.8081 16.0581) scale(13.8081)"
        >
          <stop offset={0.0679789} stopColor="#F29C1A" />
          <stop offset={0.184621} stopColor="#E32021" />
          <stop offset={0.277105} stopColor="#E61983" />
          <stop offset={0.371526} stopColor="#D51F84" />
          <stop offset={0.469699} stopColor="#155BA7" />
          <stop offset={0.56866} stopColor="#00ABE8" />
          <stop offset={0.67996} stopColor="#88BF52" />
          <stop offset={0.765651} stopColor="#DADB00" />
          <stop offset={0.966801} stopColor="#FFE810" />
        </radialGradient>
      </defs>
    </svg>
  )
}
