import { IconProps } from '@abstractTypes/icons'


export const HandoffIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = 'none',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 28 32"
      stroke={stroke}
    >
      <path
        d="M23.9998 0H3.99984C2.16184 0 0.666504 1.49533 0.666504 3.33333V28.6667C0.666504 30.5047 2.16184 32 3.99984 32H23.9998C25.8378 32 27.3332 30.5047 27.3332 28.6667V3.33333C27.3332 1.49533 25.8378 0 23.9998 0ZM25.9998 28.6667C25.9998 29.7693 25.1025 30.6667 23.9998 30.6667H3.99984C2.89717 30.6667 1.99984 29.7693 1.99984 28.6667V3.33333C1.99984 2.23067 2.89717 1.33333 3.99984 1.33333H23.9998C25.1025 1.33333 25.9998 2.23067 25.9998 3.33333V28.6667Z"
        fill={fill}
        stroke={stroke}
      />
      <path
        d="M13.9998 29.3333C14.7362 29.3333 15.3332 28.7364 15.3332 28C15.3332 27.2636 14.7362 26.6667 13.9998 26.6667C13.2635 26.6667 12.6665 27.2636 12.6665 28C12.6665 28.7364 13.2635 29.3333 13.9998 29.3333Z"
        fill={fill}
        stroke={stroke}
      />
      <path d="M16.6665 4H11.3332V5.33333H16.6665V4Z" fill={fill} stroke={stroke} />
    </svg>
  )
}
