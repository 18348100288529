import { IconProps } from '@abstractTypes/icons'

export const ArrowDownIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 34 34"
      stroke={stroke}
    >
      <path
        fill="#000"
        d="M33.666 17l-2.937-2.938-11.646 11.626V.332h-4.167v25.355L3.291 14.041.333 17 17 33.667 33.666 17z"
      />
    </svg>
  )
}
