import { tablet } from '@libs/media'
import { pxToRem } from '@libs/styled'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { PrimaryButton } from '../Button'
import { ButtonWithLoaderProps } from './types'

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`
const Loader = styled.div`
  border: 0.2rem solid ${({ theme }) => theme.colors.primaryLight};
  border-block-start: 0.2rem solid transparent;
  border-radius: 50%;
  inline-size: ${pxToRem(24)};
  block-size: ${pxToRem(24)};
  animation: ${spin} 0.6s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  ${tablet(
    `
    inline-size: ${pxToRem(26)};
    block-size: ${pxToRem(26)};
  `
  )}
`

const ContainerButton = styled(PrimaryButton)<ButtonWithLoaderProps>`
  background-color: ${({ loading, theme }) =>
    loading ? theme.colors.inactive : theme.colors.highlight01};
`

const ButtonWithLoader: React.FC<ButtonWithLoaderProps> = props => {
  const handleClick = (e?: React.MouseEvent) => {
    if (props.loading) {
      e?.preventDefault()
      e?.stopPropagation()
      return
    }

    props.onClick && props.onClick()
  }
  return (
    <ContainerButton {...props} onClick={handleClick}>
      {props.loading ? <Loader /> : props.children}
    </ContainerButton>
  )
}

export default ButtonWithLoader
