import { IconProps } from '@abstractTypes/icons'
export const UndertoneBlueIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_7243_62450"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="62"
        height="62"
      >
        <circle cx="31.0002" cy="31.0002" r="30.383" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_7243_62450)">
        <rect
          x="-3"
          y="-3"
          width="127.319"
          height="82.4681"
          fill="url(#paint0_linear_7243_62450)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7243_62450"
          x1="119.037"
          y1="62.2878"
          x2="74.1005"
          y2="-41.2461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E4A8B" />
          <stop offset="1" stopColor="#258AF8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
