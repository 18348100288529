import { useStoreContext } from '@hooks/useStoreContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps } from 'react-router-dom'

interface AutofillStoreIdentityParamsProps extends RouteComponentProps {
  defaultId: string
  defaultType: string
  defaultLang: string
}

export const AutofillStoreIdentityParams: React.FC<AutofillStoreIdentityParamsProps> = props => {
  const { i18n } = useTranslation()
  const { history, defaultId, defaultType, defaultLang } = props
  const store = useStoreContext()
  const [, storeType, storeId] = history.location.pathname.split('/')
  const { defaultLangCode: defaultLangCodeFromStore } = store

  const defaults = {
    langCode: defaultLangCodeFromStore || defaultLang || 'en-US',
    storeType: storeType || defaultType,
    storeId: storeId || defaultId,
  }

  if (defaults.langCode !== defaultLang) {
    i18n.changeLanguage(defaults.langCode)
  }

  return <Redirect to={`/${defaults.storeType}/${defaults.storeId}/${defaults.langCode}`} />
}
