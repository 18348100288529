import { DeviceImageRatio } from '@abstractTypes/image'
import { ContentImage } from '@components/Image'
import { NukaCarousel } from '@components/NukaCarousel'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import React, { useEffect, useMemo, useState } from 'react'
import ElectronicsVideoSlide from './ElectronicsVideoSlide'
import { ElectronicsSlide, ElectronicsSliderContainer, LoadingScreen } from './styles'
import { ElectronicsSliderProps } from './types'

const ElectronicsSlider: React.FC<ElectronicsSliderProps> = ({
  tabs = [],
  spiner,
  isTabsExpanded,
  updating = false,
  slideIndex,
  onSwipe,
}) => {
  const rtlDirection = useLanguageDirection()
  const [counter, setCounter] = useState(0)
  const [videosLoaded, setVideosLoaded] = useState(false)
  const [videoLoaded, setVideoLoaded] = useState(0)
  let numberOfVideos = 0

  const imageRatio: DeviceImageRatio = useMemo(
    () =>
      isTabsExpanded
        ? { tower: '_16x9', towerLandscape: '_1x1', ipad: '_16x9', ipadlandscape: '_1x1' }
        : { tower: '_3x4', towerLandscape: '_1x1', ipad: '_1x1', ipadlandscape: '_1x1' },
    [isTabsExpanded]
  )

  const slides = tabs.map((tab, index) => {
    const isVideo = Boolean(tab?.video?.length)
    if (isVideo) {
      numberOfVideos++
    }
    return (
      <ElectronicsSlide key={tab?.id}>
        {isVideo ? (
          <ElectronicsVideoSlide
            videoList={tab.video || []}
            poster={tab?.pictures?.[0]}
            index={rtlDirection ? tabs.length - 1 - index : index}
            slideIndex={slideIndex}
            setVideoLoaded={setVideoLoaded}
          />
        ) : (
          <ContentImage
            imageSource={tab.pictures?.[0]}
            aria-description={tab.pictures?.[0]?.altText || ''}
            aria-label={tab.pictures?.[0]?.altText || ''}
            ratio={imageRatio}
          />
        )}
      </ElectronicsSlide>
    )
  })

  useEffect(() => {
    setCounter(counter + 1)
  }, [videoLoaded])

  useEffect(() => {
    if (counter === numberOfVideos) {
      setVideosLoaded(true)
    }
  }, [counter])

  if (numberOfVideos === 0) {
    setVideosLoaded(true)
  }

  return (
    <ElectronicsSliderContainer isTabsExpanded={isTabsExpanded}>
      <NukaCarousel
        slideIndex={slideIndex}
        loop={false}
        disableEdgeSwiping={true}
        beforeSlide={(_i: number, n: number) => onSwipe(n)}
        autoplayReverse={false}
      >
        {slides}
      </NukaCarousel>
      {(updating || !videosLoaded) && <LoadingScreen>{spiner}</LoadingScreen>}
    </ElectronicsSliderContainer>
  )
}

export default ElectronicsSlider
