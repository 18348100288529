import { BarcodeScanModalBase } from '@components/BarCodeScanModal/Modals/BarcodeScanModalBase'
import { BarcodeScanModalWithRedirectProps } from '@components/BarCodeScanModal/types'
import { PrimaryButton, SecondaryButton } from '@components/core/Button'
import { getCustomizableBrand } from '@libs/custom'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSendBarcodePolirizedAnalyticsEvent } from './analytics'

export const BarcodeScanModalOtherPolarizedMoco = ({
  visible,
  onCancel,
  productInfo,
  onRedirect,
}: BarcodeScanModalWithRedirectProps) => {
  const { t } = useTranslation()
  const { originalBrandCode, thumbnails, url } = productInfo
  const formattedBrand = getCustomizableBrand(originalBrandCode)

  const title = t('BarCodeScanModal.TryPolarized')
  const description = t('BarCodeScanModal.UpgradeYourSelection')
  const { sendCloseModalEvent, sendDiscoverEvent } = useSendBarcodePolirizedAnalyticsEvent(visible)

  const handleOnCancel = useCallback(() => {
    sendCloseModalEvent()
    onCancel()
  }, [onCancel, sendCloseModalEvent])

  const handleOnDiscover = useCallback(() => {
    sendDiscoverEvent()
    onRedirect(url)
  }, [onRedirect, sendDiscoverEvent, url])

  return (
    <BarcodeScanModalBase
      visible={visible}
      title={title}
      titleWeight="bold"
      description={description}
      formattedBrand={formattedBrand}
      thumbnails={thumbnails}
      onCancel={handleOnCancel}
      footerContent={[
        <SecondaryButton fullWidth key="cancel" onClick={handleOnCancel}>
          {t('BarCodeScanModal.CancelBtn')}
        </SecondaryButton>,
        <PrimaryButton key="goToUPC" onClick={handleOnDiscover}>
          {t('BarCodeScanModal.DiscoverPolarized')}
        </PrimaryButton>,
      ]}
    />
  )
}
