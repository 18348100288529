import { useSendAnalyticsEvent } from '@hooks/analyticsHooks'
import { useEffect } from 'react'

export const useSendPolarizedSameSizeMocoAnalyticsEvent = (shouldSendEvent: boolean) => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
  })

  const sendCloseModalEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeResult_Close',
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])

  return { sendCloseModalEvent }
}
