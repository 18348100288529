import { IconProps } from '@abstractTypes/icons'

export const GlassShape2Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6043 0.25293C37.4543 0.25293 36.2813 0.34393 35.1373 0.56993C31.9533 1.19893 28.5973 2.87493 27.0063 5.07793C26.0983 4.54893 25.0723 4.24993 23.9993 4.24993C22.9193 4.24993 21.8893 4.53693 20.9843 5.06593C19.3903 2.86893 16.0403 1.19793 12.8623 0.57093C11.7183 0.34493 10.5453 0.25393 9.39534 0.25393C4.50134 0.25393 0.0273438 1.90993 0.0273438 1.90993C0.0273438 10.4429 4.74334 16.2479 12.5173 16.2479C20.2913 16.2479 22.5723 11.0909 21.8843 7.07593C21.8787 7.04119 21.8691 7.00678 21.8595 6.97233L21.8595 6.9723C21.8522 6.94625 21.8449 6.92018 21.8393 6.89393C22.4773 6.47993 23.2173 6.24993 23.9993 6.24993C24.7753 6.24993 25.5223 6.47293 26.1623 6.88493C26.1564 6.91215 26.149 6.939 26.1416 6.9658L26.1416 6.96583C26.1316 7.00204 26.1217 7.03815 26.1153 7.07493C25.4283 11.0899 27.7083 16.2469 35.4823 16.2469C43.2563 16.2469 47.9723 10.4419 47.9723 1.90893C47.9723 1.90893 43.4983 0.25293 38.6043 0.25293ZM18.7633 11.9749C17.5103 13.4609 15.3493 14.2469 12.5163 14.2469C6.46634 14.2469 2.55934 10.1139 2.07734 3.35593C3.66734 2.90293 6.45934 2.25293 9.39534 2.25293C10.4993 2.25293 11.5353 2.34693 12.4743 2.53193C16.2423 3.27593 19.5793 5.46493 19.9123 7.41193C20.2013 9.10293 19.7823 10.7659 18.7633 11.9749ZM29.2353 11.9749C30.4893 13.4609 32.6493 14.2469 35.4823 14.2469C41.5313 14.2469 45.4383 10.1149 45.9213 3.35793C44.3263 2.90493 41.5253 2.25293 38.6043 2.25293C37.5003 2.25293 36.4633 2.34693 35.5243 2.53193C31.7563 3.27593 28.4193 5.46493 28.0863 7.41193C27.7963 9.10293 28.2153 10.7659 29.2353 11.9749Z"
        fill="#333333"
      />
    </svg>
  )
}
