import { AppConfigOverride, DeploymentEnvWithFallback } from '@abstractTypes/envs'
import getDevEnvVariables from './dev-env'
import getLocalEnvVariables from './local-env'
import getProdEnvVariables from './prod-env'
import getStageEnvVariables from './stage-env'
import getTestEnvVariables from './test-env'
import getUatEnvVariables from './uat-env'

const getDeploymentEnvVariables = (deploymentEnv: DeploymentEnvWithFallback) => {
  const deploymentEnvVariablesMap: { [key in DeploymentEnvWithFallback]: () => AppConfigOverride } =
    {
      local: getLocalEnvVariables,
      dev: getDevEnvVariables,
      test: getTestEnvVariables,
      uat: getUatEnvVariables,
      stage: getStageEnvVariables,
      prod: getProdEnvVariables,
    }

  return deploymentEnvVariablesMap[deploymentEnv]
}

export default getDeploymentEnvVariables
