import { ProductSubdivisions } from '@abstractTypes/filter'
import { clearPreselectedFilters } from '@actions/ui'
import { Title } from '@components/Contents/NoDataContent/styles'
import { SecondaryButton } from '@components/core/Button'
import { SearchProductList } from '@components/ProductsList'
import { useSendSearchAnalyticsEvent } from '@hooks/analytics/useSearchAnalyticsData'
import { useProductGrid } from '@hooks/useProductGrid'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useGetViewAllLink from './hooks/useGetViewAllLink'
import { SearchTabsContainer } from './SearchProductsContent'
import { SettingsWrapper, ViewAllWrapper } from './SearchProductsContent/ResultContent/styled'
import { SimilarStylesProductsProps } from './types'

const SimilarStylesProducts: React.FC<SimilarStylesProductsProps> = ({
  products,
  query,
  renderSwitcher,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const shouldSendEvent = Boolean(products.length)
  const getViewAllLink = useGetViewAllLink()
  const dispatch = useDispatch()
  useSendSearchAnalyticsEvent(false, query, products, shouldSendEvent)
  const { numberOfPreviewItems } = useProductGrid()
  const productDisplayed = useMemo(
    () => products.slice(0, numberOfPreviewItems),
    [numberOfPreviewItems, products]
  )

  return (
    <>
      <SearchTabsContainer>
        <SettingsWrapper>
          <Title
            weight="bold"
            aria-label={t('ProductDetails.afaTitle')}
            aria-description={t('ProductDetails.afaTitle')}
          >
            {t('ProductDetails.afaTitle')}
          </Title>
          {renderSwitcher}
        </SettingsWrapper>
      </SearchTabsContainer>
      <SearchProductList items={productDisplayed} />
      <ViewAllWrapper>
        {products?.length > numberOfPreviewItems && (
          <SecondaryButton
            dataAttrs={{ id: 'Tiles_ViewAll', test: 'Search_View-All' }}
            onClick={() => {
              history.push(getViewAllLink(query, products[0].productType as ProductSubdivisions))
              dispatch(clearPreselectedFilters())
            }}
            ariaAttrs={{
              label: t('ButtonSearch.viewAll'),
              description: t('ButtonSearch.viewAll'),
            }}
          >
            {t('ButtonSearch.viewAll')}
          </SecondaryButton>
        )}
      </ViewAllWrapper>
    </>
  )
}

export default SimilarStylesProducts
