import { Device } from '@abstractTypes/utils'
import { ButtonTypography } from '@components/core/Typography'
import { landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const Toggler = styled.div`
  display: flex;
  min-block-size: ${pxToRem(48)};
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: 50vh;
  overflow: hidden;
  ${zoomPortrait(
    [150, 175, 200],
    `
  border-radius: ${pxToRem(56)};
  `
  )}
  background-color: ${({ theme }) => theme.colors.primaryLight};
  ${zoomPortrait(
    [150, 175],
    `
    block-size: ${pxToRem(72)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    block-size: ${pxToRem(96)};
  `
  )}

  ${tablet(
    `
    min-block-size: ${clampValues(36, 48)};
  `
  )}

  ${landscape(
    Device.tablet,
    `
    block-size: ${clampValuesLandscape(36, 48)};
  `
  )}
`

export const ButtonStyled = styled(ButtonTypography)<{ active?: boolean }>`
  padding-block: 0;
  padding-inline: ${pxToRem(24)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(24)};
  text-transform: uppercase;
  ${zoomPortrait(
    [150, 175, 200],
    `
  border-radius: ${pxToRem(56)};
  `
  )}
  min-inline-size: ${pxToRem(151)};
  ${zoomPortrait(
    [150, 175],
    `
  min-inline-size: ${pxToRem(227)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
  min-inline-size: ${pxToRem(302)};
  `
  )}
  color: ${({ theme, active }) => (active ? theme.colors.primaryLight : theme.colors.primaryDark)};
  line-height: 1;
  background-color: ${({ theme, active }) => (active ? theme.colors.primaryDark : 'inherit')};

  ${tablet(
    `
    min-inline-size: ${clampValues(112, 150)};
    padding-block: 0 ;
    padding-inline:  ${clampValues(16, 24)};
  `
  )}
`
