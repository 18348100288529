import { Heading4 } from '@components/core/Typography'
import { landscape } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'
export const MenuListWrapperStyled = styled.div`
  inline-size: 100%;
  ${landscape(`
      margin: auto;
  `)}
  > ${Heading4} {
    font-weight: 700;
    padding-block-end: ${pxToRem(16)};
  }

  button {
    padding-block: ${pxToRem(16)} ${pxToRem(16)};
    padding-inline: ${pxToRem(48)} 0;
  }
`
