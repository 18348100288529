import { OOCustomRecipe, RBCustomRecipe } from '@abstractTypes/customRecipe'
import { ProductThumbnailGroup } from '@abstractTypes/graphqlTypes'
import { getThumbnailUrlForCustom } from '@libs/custom'
import { getProductMainImage } from '@libs/image'
import React from 'react'
import { StyledCustomImg, StyledImg } from './ProductImage.style'

interface ProductImageProps {
  thumbnails: ProductThumbnailGroup
  customItem?: OOCustomRecipe | RBCustomRecipe
  recipeId?: string
  originalBrandCode: string
  dressedOnImageUrl?: string
  showShadImg: boolean
  imgWidth?: number
}

export const ProductImage: React.FC<ProductImageProps> = ({
  dressedOnImageUrl,
  thumbnails,
  showShadImg,
  imgWidth = 600,
  customItem,
  recipeId,
  originalBrandCode,
}) => {
  const defaultRenderImage = () => {
    const imageSrc = getProductMainImage(thumbnails, imgWidth, showShadImg)
    return <StyledImg imageSource={imageSrc} />
  }

  const renderDressedOnImage = (url: string) => <StyledImg imageSource={url} />

  if (customItem && recipeId) {
    const customURL = getThumbnailUrlForCustom(recipeId, originalBrandCode)
    return <StyledCustomImg imageSource={customURL} />
  }

  return dressedOnImageUrl ? renderDressedOnImage(dressedOnImageUrl) : defaultRenderImage()
}
