import { Device } from '@abstractTypes/utils'
import { BestMatchWrapper } from '@components/BadgeBestMatch/styles'
import { Link } from '@components/core/Link'
import { Text } from '@components/core/Typography'
import { landscape, tablet, tabletPortrait, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const BestMatchTextWrapperStyled = styled.div`
  margin-block-end: ${pxToRem(16)};
  ${tablet(`margin-block-end: ${clampValues(18, 24)};`)}
  ${landscape(Device.tablet, `margin-block-end: ${clampValuesLandscape(12, 24)};`)};
  ${({ theme }) =>
    `
    .show-more {
      font-weight: ${theme.fonts.body.weight.bold}
    }
  `}
`

export const ProductDescriptionStyled = styled(Text)`
  text-align: start;
  margin-block-end: 0;

  span {
    font-weight: ${({ theme }) => theme.fonts.body.weight.bold};
  }
`

export const DiscoverMoreLink = styled(Link)`
  text-align: start;
  flex-grow: 1;
`

export const BestMatchDetails = styled.div<{ isDressedOnView?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: center;
  grid-column: 1 / 3;
  ${zoomPortrait(
    [150, 175, 200],
    `
    inline-size: 100%;
    padding-block: 0  ${pxToRem(24)};
    padding-inline: ${pxToRem(24)};
    `
  )}

  ${BestMatchWrapper} {
    margin-block: ${pxToRem(16)};
    ${zoomPortrait(
      [150, 175, 200],
      `
        margin-block: ${pxToRem(32)};
      `
    )}
  }
  ${({ isDressedOnView, theme }) =>
    isDressedOnView &&
    `
    position: absolute;
    inset-block-end: 0;
    border-start-start-radius: ${pxToRem(16)};
    border-start-end-radius: ${pxToRem(16)};
    border-end-end-radius: 0;
    border-end-start-radius: 0px;
    background-color: ${theme.colors.primaryLight}CC;
    inline-size: 100%;
    padding-block: 0;
    padding-inline: ${pxToRem(16)}  ${pxToRem(144)};
    z-index: 2;
    border-block-end: 4px solid ${theme.colors.highlight04};
  `}
  ${({ isDressedOnView }) =>
    isDressedOnView &&
    tabletPortrait(
      `
      padding-block: 0;
      padding-inline: ${pxToRem(16)} ${clampValues(116, 130)};
    `
    )}
  ${({ isDressedOnView }) =>
    isDressedOnView &&
    landscape(
      Device.tablet,
      `
      padding-block: 0 ;
      padding-inline:  ${pxToRem(16)} ${clampValuesLandscape(116, 130)};
    `
    )}
  ${({ isDressedOnView }) =>
    isDressedOnView &&
    zoomPortrait(
      [150, 175],
      `
      padding-block: 0;
      padding-inline: ${pxToRem(32)} ${pxToRem(200)};
    `
    )}
  ${({ isDressedOnView }) =>
    isDressedOnView &&
    zoomLandscape(
      [150, 175],
      `
      padding-block: 0;
      padding-inline: ${pxToRem(32)} ${pxToRem(200)};
    `
    )}
  ${({ isDressedOnView }) =>
    isDressedOnView &&
    zoomPortrait(
      [200],
      `
      padding-block: 0;
      padding-inline: ${pxToRem(32)} ${pxToRem(235)};
    `
    )}
  ${({ isDressedOnView }) =>
    isDressedOnView &&
    zoomLandscape(
      [200],
      `
      padding-block: 0;
      padding-inline: ${pxToRem(32)} ${pxToRem(235)};
    `
    )}
`
