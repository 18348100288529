import { Device } from '@abstractTypes/utils'
import { VMMVClassName } from '@components/VirtualMirror/constants'
import { ADA_SCREEN_HEIGHT } from '@constants/accessibility'
import { altNavigation, landscape, tablet, transformTranslateX, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { scrollbar } from '@styles/common'
import styled from 'styled-components'

export const CloseIconContainer = styled.button`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  padding-block: ${pxToRem(26)};
  padding-inline: ${pxToRem(26)};
  display: block;
  inline-size: fit-content;
  text-align: end;
`
export const ModalWrapper = styled.div`
  ${scrollbar};
  max-block-size: 100%;
  block-size: 100%;
  overflow: auto;
  display: block;
  padding-block: 0;
  padding-inline: ${pxToRem(40)};
  ${tablet(`
    padding-inline:  ${clampValues(12, 20)};
  `)}
  ${zoomPortrait(
    [150, 175],
    `
    padding-inline:  ${pxToRem(48)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    padding-inline:  ${pxToRem(56)};
  `
  )}
`
export const ModalHeader = styled.div<{ centeredTitle?: boolean }>`
  display: flex;
  justify-content: ${({ centeredTitle }) => (centeredTitle ? 'center' : 'flex-start')};
  text-align: ${({ centeredTitle }) => (centeredTitle ? 'center' : 'start')};
  position: static;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  padding-block: 0 ${pxToRem(40)};
  padding-inline: ${pxToRem(40)};
  ${tablet(`
    padding-block: 0  ${clampValues(30, 40)};
    padding-inline:  ${clampValues(12, 20)} ;
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-block-end: ${clampValuesLandscape(30, 40)} ;
    `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    padding-block: 0  ${pxToRem(40)};
    padding-inline:  ${pxToRem(48)} ;
  `
  )}
  ${zoomPortrait(
    [200],
    `
    padding-block: 0  ${pxToRem(40)};
    padding-inline:  ${pxToRem(56)};
  `
  )}
`
export const ModalBodyWrapper = styled.div<{ centeredContent?: boolean }>`
  text-align: ${({ centeredContent }) => (centeredContent ? 'center' : 'start')};
  overflow: hidden;
  inline-size: 100%;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-block: ${pxToRem(48)} 0;
  padding-inline: ${pxToRem(103)};
  gap: ${pxToRem(24)};
  ${tablet(
    `
    padding-block: ${clampValues(42, 48)} 0;
    padding-inline:  ${clampValues(60, 104)};
    gap: ${clampValues(18, 24)}
  `
  )}
  ${landscape(
    Device.tablet,
    `
    padding-block: ${pxToRem(48)} 0;
    padding-inline: ${clampValuesLandscape(100, 104)} ;
  `
  )}
`

export const Root = styled.div`
  .css-modal-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  .css-modal-enter-active {
    opacity: 1;
    ${transformTranslateX('0')}
    transition: opacity 300ms, transform 300ms;
  }

  .css-modal-exit {
    opacity: 1;
  }

  .css-modal-active {
    opacity: 0;
    transform: scale(0.9);
    transition:
      opacity 300ms,
      transform 300ms;
  }
`

export const Mask = styled.div`
  position: fixed;
  inset-block: 0;
  inset-inline: 0;
  z-index: 1000;
  block-size: 100%;
  background-color: rgb(51 51 51 / 60%);

  .${VMMVClassName} & {
    z-index: 1001;
  }
`

export const Modal = styled.div<{ centered?: boolean }>`
  box-sizing: border-box;
  padding: 0;
  color: rgb(0 0 0 / 65%);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  inline-size: fit-content;
  margin-block: 0;
  margin-inline: auto;
  pointer-events: none;

  &.centered {
    display: inline-block;
    text-align: start;
    inset-block-start: 0;
    vertical-align: middle;
  }
`

export const ModalContent = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  background-clip: padding-box;
  border: 0;
  border-radius: ${pxToRem(4)};
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  pointer-events: auto;
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: ${pxToRem(104)};
  padding-inline: ${pxToRem(39)};
  inline-size: ${pxToRem(900)};
  block-size: 100%;
  ${tablet(
    `
    padding-block: ${clampValues(78, 104)} ;
    padding-inline:  ${pxToRem(37)};
    inline-size: ${clampValues(660, 832)};
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    padding-block: ${pxToRem(104)};
    padding-inline:  ${pxToRem(47)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    padding-block: ${pxToRem(104)} ;
    padding-inline:  ${pxToRem(55)};
  `
  )}
`

export const ModalBaseWrapper = styled.div<{ centered?: boolean }>`
  display: block;
  position: fixed;
  inset-block: 0;
  inset-inline: 0;
  z-index: 1001;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  text-align: ${({ centered }) => (centered ? 'center' : 'unset')};
  ${altNavigation(`
  inset-block-start: ${pxToRem(1076)};
  block-size: ${ADA_SCREEN_HEIGHT};
  &::before {
    block-size: 0;
  }
  `)}
  ${({ centered }) => {
    if (!centered) return
    return `&::before {
      content: "";
      display: inline-block;
      block-size: 100%;
      vertical-align: middle;
      inline-size: 0;
    }`
  }}
`
