import { CMSIconKey } from '@components/core/Icons/CMSIcon'
import { ListItemButton } from '@components/core/List'
import { Heading4 } from '@components/core/Typography'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconMenu } from './IconMenu'
import { MenuListItemProps } from './types'

export const MenuListItem: FC<MenuListItemProps> = ({
  menu,
  item,
  isHierarchySecondLevel = false,
  onSelectMenu,
  onSelect,
}) => {
  const dataElementId = item.analyticsData
    ? `${item.analyticsData?.dataElementId}${item.analyticsData?.dataDescription}`
    : undefined
  const { t } = useTranslation()

  const label = useMemo(
    () => (item.label.includes('Menu.') ? t(item.label) : item.label),
    [item, t]
  )

  return (
    <ListItemButton
      onClick={() => {
        if (item.items && item.items.length) {
          return onSelectMenu(item)
        }
        onSelect()
      }}
      dataAttrs={{ id: dataElementId }}
      ariaAttrs={{ label: `${menu.label} ${item.label}` }}
      navigateTo={item.href ? item.href : undefined}
    >
      {item.iconId && <IconMenu iconId={item.iconId as CMSIconKey} />}
      <Heading4 weight={isHierarchySecondLevel ? 'bold' : undefined}>{label}</Heading4>
    </ListItemButton>
  )
}
