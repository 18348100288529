import { ProductHierarchies, ProductTypeValues } from '@abstractTypes/filter'
import config from '@config/config.base'
import { useStoreContext } from '@hooks/useStoreContext'
import { useMemo } from 'react'

export const useProductSubdivisionDefault = () => {
  const productSubdivisionDefault =
    config.default.productSubdivision === 'productType'
      ? config.default.productType
      : config.default.productHierarchy
  const store = useStoreContext()

  return useMemo(() => {
    if (!store.eyeglassesEnabled && productSubdivisionDefault === ProductTypeValues.OPTICAL) {
      return ProductTypeValues.SUN
    }
    if (!store.eyeglassesEnabled && productSubdivisionDefault === ProductHierarchies.EYEGLASSES) {
      return ProductHierarchies.SUNGLASSES
    }
    return productSubdivisionDefault
  }, [store.eyeglassesEnabled])
}
