import {
  FilterKind,
  ProductHierarchies,
  ProductSubdivisions,
  ProductTypeValues,
  PRODUCT_HIERARCHY_1,
  PRODUCT_HIERARCHY_2,
  PRODUCT_HIERARCHY_3,
} from '@abstractTypes/filter'
import config from '@config/config.base'
import { PRODUCT_TYPE } from '@constants/product'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { getFacetValue } from './useFilters'

export type useGetProductTypeListFromUrlRet = () => { productType: ProductTypeValues | undefined }
export type useGetProductHierarchyListFromUrlRet = () => {
  hierarchy1: ProductHierarchies | undefined
  hierarchy2: ProductHierarchies | undefined
  hierarchy3: ProductHierarchies | undefined
}
type useGetProductSubdivisionListFromUrlRet =
  | useGetProductTypeListFromUrlRet
  | useGetProductHierarchyListFromUrlRet

const useGetProductTypeListFromUrl = (): useGetProductTypeListFromUrlRet => {
  const { location } = useHistory()

  const getProductTypeFromUrl = useCallback(() => {
    const value = getFacetValue(PRODUCT_TYPE, location)
    const productType = (value?.length && (value[0] as ProductTypeValues)) || undefined
    return { productType }
  }, [location])

  return getProductTypeFromUrl
}

export const useGetHierarchyValue = () => {
  const { location } = useHistory()

  return useCallback(
    (hierarchyField: FilterKind) => {
      const hierarchy1Filter = getFacetValue(hierarchyField, location)
      const hierarchy1FilterValue =
        (hierarchy1Filter?.length && (hierarchy1Filter[0] as ProductHierarchies)) || undefined
      return hierarchy1FilterValue
    },
    [location]
  )
}

const useGetProductHierarchyListFromUrl = (): useGetProductHierarchyListFromUrlRet => {
  const getHierarchyValue = useGetHierarchyValue()

  const getProductHierarchyFromUrl = useCallback(() => {
    const hierarchy1FilterValue = getHierarchyValue(PRODUCT_HIERARCHY_1)
    const hierarchy2FilterValue = getHierarchyValue(PRODUCT_HIERARCHY_2)
    const hierarchy3FilterValue = getHierarchyValue(PRODUCT_HIERARCHY_3)

    return {
      hierarchy1: hierarchy1FilterValue,
      hierarchy2: hierarchy2FilterValue,
      hierarchy3: hierarchy3FilterValue,
    }
  }, [getHierarchyValue])

  return getProductHierarchyFromUrl
}

export const useGetProductSubdivisionListFromUrl = (): useGetProductSubdivisionListFromUrlRet => {
  const getProductTypeListFromUrl = useGetProductTypeListFromUrl()
  const getProductHierarchyListFromUrl = useGetProductHierarchyListFromUrl()

  switch (config.default.productSubdivision) {
    case 'productHierarchy':
      return getProductHierarchyListFromUrl

    case 'productType':
    default:
      return getProductTypeListFromUrl
  }
}

export const useProductSubdivisionFromUrl = (): ProductSubdivisions | undefined => {
  const getProductSubdivisionListFromUrl = useGetProductSubdivisionListFromUrl()

  switch (config.default.productSubdivision) {
    case 'productHierarchy':
      const { hierarchy1 } = (
        getProductSubdivisionListFromUrl as useGetProductHierarchyListFromUrlRet
      )()
      return hierarchy1

    case 'productType':
    default:
      const { productType } = (
        getProductSubdivisionListFromUrl as useGetProductTypeListFromUrlRet
      )()
      return productType
  }
}
