import { CartItem } from '@abstractTypes/cart'
import { Product } from '@abstractTypes/product'
import { StockItem } from '@abstractTypes/realTimeCheck'
import { setVisibleDrawer } from '@actions/ui'
import { createCartItemId, stock } from '@libs/cart'
import { Dispatch } from 'redux'
import { ThunkAction } from '../store'
import { addEngraving, deleteEngraving } from './engraving'
import { removeItemFromWishlist } from './wishlist'

export const ADD_ITEM = 'cart/ADD_ITEM'
export const REMOVE_ITEM = 'cart/REMOVE_ITEM'
export const SET_ITEM_QUANTITY = 'cart/SET_ITEM_QUANTITY'
export const UPDATE_ITEM = 'cart/UPDATE_ITEM'
export const ADD_AVAILABILITY = 'cart/ADD_AVAILABILITY'
export const UPDATE_AVAILABILITY = 'cart/UPDATE_AVAILABILITY'
export const CLEAR_CART_ITEM_OVERLAY = 'cart/CLEAR_CART_ITEM_OVERLAY'

// eslint-disable-next-line @typescript-eslint/ban-types
interface DefaultAction<T = {}> {
  type: string
  payload?: T
}

export interface AddItemAction extends DefaultAction {
  type: typeof ADD_ITEM
  payload: CartItem
}

interface AddAvailabilityAction extends DefaultAction {
  type: typeof ADD_AVAILABILITY
  payload: StockItem[]
}

export const addAvailability = (items: StockItem[]): AddAvailabilityAction => {
  return {
    type: ADD_AVAILABILITY,
    payload: items,
  }
}

interface UpdateAvailabilityAction extends DefaultAction {
  type: typeof UPDATE_AVAILABILITY
  payload: boolean
}

export const updateAvailability = (updating: boolean): UpdateAvailabilityAction => {
  return {
    type: UPDATE_AVAILABILITY,
    payload: updating,
  }
}

interface ClearCartItemOverlayAction extends DefaultAction {
  type: typeof CLEAR_CART_ITEM_OVERLAY
}

export const clearCartItemOverlay = (): ClearCartItemOverlayAction => {
  return {
    type: CLEAR_CART_ITEM_OVERLAY,
  }
}

export const addToCart =
  (id: string, product: Product, quantity: number, storeId: string): ThunkAction =>
  (dispatch, getState) => {
    const { session } = getState()

    const payload = {
      id,
      product,
      quantity,
      storeId,
      session,
    }
    dispatch({
      type: ADD_ITEM,
      payload,
    })
  }
interface RemoveItemAction extends DefaultAction {
  type: typeof REMOVE_ITEM
  payload: {
    id: string
    openMinicart: boolean
  }
}
export const removeItemFromCart =
  (id: string, openMinicart: boolean) => (dispatch: Dispatch<RemoveItemAction>) => {
    dispatch({
      type: REMOVE_ITEM,
      payload: {
        id,
        openMinicart,
      },
    })
  }
interface SetItemQuantityAction extends DefaultAction {
  type: typeof SET_ITEM_QUANTITY
  payload: {
    id: string
    quantity: number
  }
}
export const setCartItemQuantity =
  (id: string, quantity: number) => (dispatch: Dispatch<SetItemQuantityAction>) => {
    dispatch({
      type: SET_ITEM_QUANTITY,
      payload: {
        id,
        quantity,
      },
    })
  }

export interface UpdateItemAction extends DefaultAction {
  type: typeof UPDATE_ITEM
  payload: {
    id: string
    product: Product
    quantity: number
    storeId: string
    session: string
  }
}

export const updateItemInCart =
  (id: string, product: Product, quantity: number, storeId: string, session: string) =>
  (dispatch: Dispatch<UpdateItemAction>) => {
    dispatch({
      type: UPDATE_ITEM,
      payload: {
        id,
        product,
        quantity,
        storeId,
        session,
      },
    })
  }

/** Before add an item to cart we have to check
 *  whether it is already in cart
 *  or it is and engraving has been added.
 *
 * This way, the same product can be added to cart
 * without engraving,
 * and with different engravings.
 **/

export const addItemToCart = (
  product: Product,
  storeId: string,
  quantity = 1,
  shouldToggleMinicart = true
): ThunkAction => {
  return function (dispatch, getState) {
    const { engraving } = getState()
    const upc = product.UPC
    const currentEngravingDetails = engraving.items[upc] && { ...engraving.items[upc] }
    const id = createCartItemId(product, engraving.items)

    // Delete current engraving data
    if (currentEngravingDetails) {
      dispatch(addEngraving(id, currentEngravingDetails))
      dispatch(deleteEngraving(upc))
    }
    dispatch(addToCart(id, product, quantity, storeId))

    if (shouldToggleMinicart) {
      dispatch(setVisibleDrawer('minicart'))
    }
  }
}

export const addWishlistItemsToCart = (products: Product[], storeId: string): ThunkAction => {
  return function (dispatch, getState) {
    const { cart } = getState()

    products.forEach(product => {
      dispatch(removeItemFromWishlist(product))
      if (stock(cart, product) !== 0) {
        dispatch(addItemToCart(product, storeId, 1, false))
      }
    })
    dispatch(setVisibleDrawer('minicart'))
  }
}

export type CartActions =
  | AddItemAction
  | RemoveItemAction
  | SetItemQuantityAction
  | UpdateItemAction
  | AddAvailabilityAction
  | UpdateAvailabilityAction
  | ClearCartItemOverlayAction
