import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getAlEnvVariables = () => {
  const alEnvVariables: AppConfigOverride = {
    shopperSlug: 'al',
    shopperName: 'ANTOINE LAOUN',
    storeID: 'E639',
    storeLang: 'en-US',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    vmStore: 'al',
    vmStyle: 'al',
  }

  return alEnvVariables
}

export default getAlEnvVariables
