import { pxToRem } from '@libs/styled'

export const SCROLL_RESTORATION_ID = 'page'

export const TOP_ANCHOR_ID = 'top-anchor'

export const ZOOM_LEVEL_PREFIX = 'zoom-level-'

export const zoomLevels = [100, 110, 125, 150, 175, 200] as const

export const ADA_SCREEN_HEIGHT = pxToRem(844)
