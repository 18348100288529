import { Point } from '@abstractTypes/keyboard'
import { decreaseZoomLevel, increaseZoomLevel } from '@actions/accessibility'
import { getSiteAbsolutePath } from '@libs/url'
import { History } from 'history'
import { Dispatch } from 'react'
import { matchPath } from 'react-router-dom'
import { AnyAction } from 'redux'
import { setVisibleDrawer, setVisibleModal } from './ui'

export const SET_POS = 'keyboard/SET_POS'

export interface StorePositionAction {
  type: typeof SET_POS
  payload: Point
}

// keyboard shortcut actions
export const openSearch = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setVisibleModal(undefined))
  dispatch(setVisibleDrawer('search'))
}

export const navigateToPlpAndOpenFilters =
  (history: History) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setVisibleModal(undefined))
    const basePath = getSiteAbsolutePath()

    const isPLPPage = !!matchPath(history.location.pathname, {
      path: [
        '/:store_type/:store_id/:lang/products',
        '/:store_type/:store_id/:lang/products/:contenId',
        '/:store_type/:store_id/:lang/frame-advisor/playlist/:playlistId',
      ],
    })
    if (isPLPPage) {
      dispatch(setVisibleDrawer('filters'))
      return
    }
    history.push(`${basePath}/products`)
  }

export const navigateToFavourites = (history: History) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setVisibleModal(undefined))
  const basepath = getSiteAbsolutePath()
  history.push(`${basepath}/wishlist`)
}

export const zoomIn = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(increaseZoomLevel)
}

export const zoomOut = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(decreaseZoomLevel)
}

export type KeyboardActionTypes = StorePositionAction
