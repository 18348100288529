import { IconProps } from '@abstractTypes/icons'
export const UndertoneCoolIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_7270_62676"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <circle cx="31.0002" cy="31.0002" r="30.383" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_7270_62676)">
        <rect
          x="30.6387"
          y="-12.8413"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 30.6387 -12.8413)"
          fill="#F7E4DD"
        />
        <rect
          x="39.334"
          y="-4.14551"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 39.334 -4.14551)"
          fill="#E0C8BE"
        />
        <rect
          x="48.0312"
          y="4.55029"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 48.0312 4.55029)"
          fill="#C0A497"
        />
        <rect
          x="56.7266"
          y="13.2466"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 56.7266 13.2466)"
          fill="#958076"
        />
        <rect
          x="65.4219"
          y="21.9424"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 65.4219 21.9424)"
          fill="#735D53"
        />
      </g>
    </svg>
  )
}
