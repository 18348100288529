import { CartItem } from '@abstractTypes/cart'
import {
  EngravingRadioFont,
  EngravingRadioFontOrderPlacement,
  EngravingRadioFontUI,
  EngravingReduxState,
  Side,
  SidesMap,
} from '@abstractTypes/engraving'

const sidesMap: SidesMap = {
  extL: { title: 'Engraving.el', flip: false },
  intL: { title: 'Engraving.il', flip: true },
  intR: { title: 'Engraving.ir', flip: false },
  extR: { title: 'Engraving.er', flip: true },
}

const sidesOrder = ['extL', 'intL', 'intR', 'extR'] as (keyof SidesMap)[]

export const engravedSummaryValues = (
  cartItems: CartItem[],
  engravingDetails: EngravingReduxState
) => {
  const engravedItems = cartItems.filter(
    el => engravingDetails.items && typeof engravingDetails.items[el.id] !== 'undefined'
  )
  const numberItems = engravedItems.reduce((tot: number, el) => tot + el.quantity, 0)
  const price = engravedItems.reduce(
    (tot: number, el) => tot + el.quantity * (el.product.engravingConstraints?.price || 0),
    0
  )

  return {
    price,
    numberItems,
  }
}

/**
 * this function creates sides data for engraving functionality.
 *
 * @param {array} storeSides - Array of available engravable sides.
 * @return {array} - Array of Object describing the each side.
 */
export const createSides = (storeSides: string[]): Side[] => {
  const sidesActive = sidesOrder.filter(el => storeSides.indexOf(el) !== -1)
  return sidesActive.map(side => {
    return {
      id: side,
      title: sidesMap[side].title,
      text: '',
      flip: sidesMap[side].flip,
      active: true,
    }
  })
}

export const formatEngravingFontForUI = (font: EngravingRadioFont): EngravingRadioFontUI => {
  const formattingMap: Record<EngravingRadioFont, EngravingRadioFontUI> = {
    latoRegular: 'regular',
    artelyInks: 'calligraphy',
    garamondItalic: 'italic',
    righteous: 'bold',
  }
  return formattingMap[font]
}

export const formatEngravingFontForOrderPlacement = (
  font: EngravingRadioFont
): EngravingRadioFontOrderPlacement => {
  const formattingMap: Record<EngravingRadioFont, EngravingRadioFontOrderPlacement> = {
    latoRegular: 'Lato',
    artelyInks: 'ArtelyInks',
    garamondItalic: 'Garamond',
    righteous: 'RighteousRegular',
  }
  return formattingMap[font]
}
