import { useSelector } from 'react-redux'

export const useNavigationFlowHandler = () => {
  const frameAdvisorFlow = useSelector(state => state.flows.frameAdvisorFlow)
  const barcodeFlow = useSelector(state => state.flows.barcodeFlow)
  const customerOrderFlow = useSelector(state => state.flows.customerOrderFlow)
  return {
    frameAdvisorFlow,
    barcodeFlow,
    customerOrderFlow,
  }
}
