import { Device } from '@abstractTypes/utils'
import { landscape, tablet } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import { setAutofocusWithDelay } from '@libs/utils'
import { ReactNode, useEffect, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ClearButton } from '../ClearButton'

const slideDown = keyframes`
  0% { 
    opacity: 0;
    transform: translateY(-60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const PanelStyled = styled.div`
  border-block-end: 1px solid ${({ theme }) => theme.colors.outline};

  &:last-child {
    ${landscape(
      Device.tablet,
      `
			margin-block-end: ${pxToRem(200)};
    `
    )}
  }
`

export const PanelHeaderStyled = styled(ClearButton)<{ removePadding?: boolean }>`
  inline-size: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: ${pxToRem(22)};
  padding-inline: ${pxToRem(0)};
  padding-block-end: ${({ removePadding }) => removePadding && 0};

  ${tablet(
    `
      padding-block: ${clampValues(18, 22)} ;
      padding-inline:  0;
    `
  )}

  ${({ removePadding }) =>
    tablet(
      Device.ipadMini,
      `
			padding-block-end: ${removePadding && 0};
			`
    )};
`

export const PanelContentStyled = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  padding-block: ${pxToRem(30)};
  padding-inline: 0;
  animation: ${({ visible }) =>
    visible &&
    css`
      animation-duration: 0.3s;
      animation-timing-function: linear;
      animation-name: ${slideDown};
    `};
  ${tablet(
    `
			padding-block: ${clampValues(24, 30)} ;
			padding-inline:  0;
    `
  )}
`

export interface PanelProps {
  isActive?: boolean
  header?: string | React.ReactNode
  className?: string
  style?: React.CSSProperties
  onClickItem?: (panelKey: string | number) => void
  expandIcon?: ((isActive?: boolean) => ReactNode) | undefined
  panelKey: string | number
  dataAttr?: { test?: string }
}

export const Panel: React.FC<PanelProps> = ({
  className,
  header,
  children,
  isActive,
  expandIcon,
  onClickItem,
  panelKey,
  dataAttr,
}) => {
  const panelContentRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const panelContent = panelContentRef?.current
    if (!panelContent) return
    isActive && setAutofocusWithDelay(panelContent)
  }, [isActive])

  return (
    <PanelStyled className={className}>
      <PanelHeaderStyled
        onClick={() => onClickItem?.(panelKey)}
        data-test={dataAttr?.test}
        removePadding={isActive}
        tabIndex={0}
        role="tab"
      >
        {header && header}
        {expandIcon && expandIcon(isActive)}
      </PanelHeaderStyled>
      <PanelContentStyled visible={isActive} role="tabpanel" ref={panelContentRef} tabIndex={0}>
        {children}
      </PanelContentStyled>
    </PanelStyled>
  )
}
