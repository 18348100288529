import { Device } from '@abstractTypes/utils'
import AvailableInStoreSwitch from '@components/AvailableInStoreSwitch'
import { Heading4, Heading5 } from '@components/core/Typography'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const NotFoundMessage = styled(Heading5)`
  z-index: 3;
  margin-block: ${pxToRem(64)} ${pxToRem(84)};
  margin-inline: 0;
  inline-size: 100%;
  text-align: center;
  ${tablet(`
  margin-block: ${clampValues(30, 76)} ${clampValues(28, 32)};
  `)}
  ${landscape(`
    margin-block: ${pxToRem(40)} ${pxToRem(66)};
    
    z-index: 1;
  `)}
  ${landscape(
    Device.tablet,
    `margin-block: ${clampValuesLandscape(37, 49)} ${clampValuesLandscape(48, 64)};`
  )}
`

export const Title = styled(Heading4)`
  inline-size: 100%;
  margin-block-end: ${pxToRem(24)};
  ${tablet(`margin-block-end: ${clampValues(18, 24)};`)}
`

export const Switcher = styled(AvailableInStoreSwitch)`
  margin-block-start: 0;
  ${tablet('margin-block-start: 0;')};
  position: absolute;
  inset-inline-end: 0;
`

export const ContinueShoppingBtn = styled.div`
  text-align: center;
`

export const NoDataContainer = styled.div`
  position: relative;
  min-block-size: ${pxToRem(100)};
`
