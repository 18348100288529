import { AppConfigOverride } from '@abstractTypes/envs'
import { RTR_JS_VERSION, VM_JS_VERSION } from '../default/const'

const getProdEnvVariables = (): AppConfigOverride => {
  const prodEnvVariables = {
    log2api: true,
    env: 'production',
    vmURL: 'https://virtualmirror-xp.luxottica.com',
    rbCustomJsUrl:
      'https://cdn-prod.fluidconfigure.com/static/assets/prod/prod/customers/c1589/configureHtml/etc/assets/js/fluidConfigure-rbn-retail.min.js',
    ooCustomJsUrl:
      'https://cdn-prod.fluidconfigure.com/static/assets/prod/prod/customers/c1620/configureHtml/etc/assets/js/fluidConfigure-oak-retail.min.js',
    dressedOnImageService: 'https://gw-api-vtoimageheadless.luxdeepblue.com/',
    vmEnv: 'prod',
    fsaEnv: 'prod',
    rbCustomOcId: '412f8ea0-502a-4b1c-b59d-970f139c781e',
    toggleFeature: {
      courtesy: true,
      emailSuggestions: true,
    },
    vmJS: `https://vmmv.luxottica.com/v/${VM_JS_VERSION}/index.umd.js`,
    rtrJS: `https://rtrmv.essilorluxottica.com/lib/v/${RTR_JS_VERSION}/main.umd.js`,
  }

  return prodEnvVariables
}

export default getProdEnvVariables
