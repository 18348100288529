import React from 'react'
import Loading from '@components/Loading'
import { useCourtesy } from '@hooks/useCourtesy'
import config from '@config/index'

interface CourtesyCheckerProps {
  children: React.ReactNode
}

const CourtesyChecker: React.FC<CourtesyCheckerProps> = ({ children }) => {
  const courtesyActive = useCourtesy()
  const { courtesyHostUrl, courtesyUrl } = config

  if (courtesyActive === undefined) return <Loading />

  if (courtesyActive) {
    const redirectURL = window.location.href
    const project = 'ss'
    const [, storeIdentity] = courtesyUrl.split('.')
    const [area, env] = storeIdentity.split('-')
    const url = `${courtesyHostUrl}?redirectUrl=${redirectURL}&area=${area}&env=${env}&project=${project}`
    window.location.replace(url)
  }

  return courtesyActive ? null : <>{children}</>
}

export default CourtesyChecker
