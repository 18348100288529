import { Product } from '@abstractTypes/product'
import { PrimaryButton } from '@components/core/Button/index'
import { useCustomerOrder } from '@hooks/useCustomOrder'
import { dataDescriptionForProduct } from '@libs/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerOrderModal } from './CustomerOrderModal'

interface CustomerOrder {
  product: Product
}

export const CustomerOrder: React.FC<CustomerOrder> = ({ product }) => {
  const { t } = useTranslation()
  const { isCustomerOrderModalVisible, handleToggleVisible } = useCustomerOrder()
  const analyticsDataDescription = dataDescriptionForProduct(product)

  return (
    <>
      <PrimaryButton
        onClick={handleToggleVisible}
        textUppercase={true}
        dataAttrs={{
          id: 'Prod_AddCart',
          description: analyticsDataDescription,
        }}
        ariaAttrs={{
          label: t('Core.buyNow'),
          description: t('Core.buyNow'),
        }}
      >
        {t('Core.buyNow')}
      </PrimaryButton>
      <CustomerOrderModal
        visible={isCustomerOrderModalVisible}
        handleToggleVisible={handleToggleVisible}
        product={product}
      />
    </>
  )
}
