import { ProductInfoConfig, ProductInfoConfigScenario } from '@abstractTypes/tiles'
import { DefaultTheme } from 'styled-components'
import { useProductInfoConfig } from './useProductInfoConfig'

const chanelProductInfoConfigPlp = (theme: DefaultTheme): ProductInfoConfig => ({
  style: {
    view: 'list',
    align: 'center',
    background: theme.chanel.colors.background,
  },
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
        transform: 'uppercase',
      },
    },
    {
      id: 'color',
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
  ],
})

const defaultProductInfoConfigPlp = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'brand',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'name',
    },
    {
      id: 'color',
    },
  ],
})

const monoBrandRBProductInfoConfigPlp = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'color',
    },
  ],
})

const monoBrandOOProductInfoConfigPlp = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'color',
    },
  ],
})

const productInfoConfigByScenarios: ProductInfoConfigScenario = {
  monobrand: {
    rb: monoBrandRBProductInfoConfigPlp,
    oo: monoBrandOOProductInfoConfigPlp,
  },
  multibrand: {
    ch: chanelProductInfoConfigPlp,
    default: defaultProductInfoConfigPlp,
  },
  default: defaultProductInfoConfigPlp,
}

export const useProductInfoConfigPlp = () => {
  const { getProductInfoConfig } = useProductInfoConfig(productInfoConfigByScenarios)
  return getProductInfoConfig
}
