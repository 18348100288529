import { PrimaryButton } from '@components/core/Button'
import ErrorWidget from '@components/ErrorWidget'
import { QRCodeBorder } from '@components/FrameAdvisor/FaceappConnector'
import { QRCode } from '@components/QRcode'
import {
  useSessionHandoffStateSaver,
  useSessionHandoffStatusChecker,
  useSessionHandoffToken,
} from '@data'
import { useFullscreenLoader } from '@hooks/useFullscreenLoader'
import { useResetSS } from '@hooks/useResetSS'
import { useCallback, useEffect, useState } from 'react'

export const SessionHandoffContent = ({ onScanCompleted }: { onScanCompleted: () => void }) => {
  const resetSS = useResetSS()
  const [qrcodeUrl, setQrcodeUrl] = useState<string | undefined>()
  const {
    data: TokenData,
    loading: TokenLoading,
    error: tokenError,
    refetch,
  } = useSessionHandoffToken()
  const [saveSession, { loading: createUserLoading, error: createUserError }] =
    useSessionHandoffStateSaver()
  const [checkHandoffStatus, { startPolling, stopPolling, data: scanningStatus }] =
    useSessionHandoffStatusChecker()

  useFullscreenLoader('loading-SessionHandoffContent', [TokenLoading, createUserLoading])

  const saveSessionState = useCallback(
    (token: string) =>
      saveSession({
        variables: {
          token: token,
        },
      })
        .then(res => {
          setQrcodeUrl(`${res.data?.sessionHandoffStateSaver.qrcodeUrl}`)
        })
        .catch(err => console.log(err)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!TokenData?.sessionHandoffToken.token) return
    if (!qrcodeUrl) {
      saveSessionState(TokenData?.sessionHandoffToken.token)
      checkHandoffStatus({
        variables: {
          token: TokenData?.sessionHandoffToken.token,
        },
      })
      startPolling(5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TokenData])

  useEffect(() => {
    if (scanningStatus?.sessionHandoffState.isScanned === 'true') {
      stopPolling()
      onScanCompleted()
      resetSS()
    }
  }, [onScanCompleted, resetSS, scanningStatus, stopPolling])

  if (tokenError || createUserError)
    return (
      <ErrorWidget
        type="Generic Error"
        errors={[tokenError as Error] || [createUserError as Error]}
        withWrapper
      />
    )

  // TODO: remove hardcoded translation
  return (
    <>
      <QRCodeBorder>
        <div>
          <QRCode
            url={qrcodeUrl || ''}
            size={300}
            data-token={TokenData?.sessionHandoffToken.token}
            data-post-url={qrcodeUrl}
            variant={'round'}
          />
          {tokenError && (
            <div>
              <PrimaryButton onClick={refetch}>Token is expired</PrimaryButton>
            </div>
          )}
        </div>
      </QRCodeBorder>
    </>
  )
}
