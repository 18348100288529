import { Device } from '@abstractTypes/utils'
import { altNavigation, landscape, zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const ImageWrapper = styled.div`
  margin-block: 0;
  margin-inline: auto;
  inline-size: ${pxToRem(124)};
  display: inline-block;
  padding-block: 0;

  ${landscape(`
    inline-size: 50%;
    block-size: ${pxToRem(215)};
    margin-block-end: 4vh;
  `)}
  ${landscape(
    Device.tablet,
    `
    block-size: ${pxToRem(105)};
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(180)};
    block-size: ${pxToRem(90)}; 
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(240)};
    block-size: ${pxToRem(120)}; 
  `
  )}
 
  img {
    inline-size: 100%;
    block-size: 100%;
    object-fit: contain;
  }
`

export const ImagesScrollableContainer = styled.div`
  inline-size: 100%;
  overflow: auto;
  white-space: nowrap;
  ${landscape(`
    inline-size: 100%;
    white-space: normal;
  `)}
  ${altNavigation(`
    inline-size: 100%;
  `)}
`

export const ColorsTabContainer = styled.div`
  display: flex;
  max-inline-size: 70%;
  margin-inline: auto;
  flex-wrap: wrap;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  justify-content: space-around;
`
