import styled, { keyframes } from 'styled-components'

const Loader = keyframes`
  0% {
    background-position-x: 100%;
  }

  100% {
    background-position-x: 0;
  }
`

const LoaderReversed = keyframes`
  0% {
    background-position-x: -100%;;
  }
  
  100% {
    background-position-x: 0;
  }
`

export const Progress = styled.div<{
  active?: boolean
  passed: boolean
  animationTime?: string
  isProgressPaused?: boolean
  activeColor?: string
  backgroundColor?: string
  isReversed?: boolean
}>`
  margin-block: 0;
  margin-inline: 0.25rem;
  display: flex;
  flex-grow: 1;
  block-size: 5px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: ${({ passed }) => (passed ? '0 0' : '100% 50%')};
  background-size: 200%;
  animation-play-state: ${({ isProgressPaused }) => isProgressPaused && 'paused'};
  animation-name: ${({ active, isReversed }) =>
    active ? (isReversed ? LoaderReversed : Loader) : ''};
  animation-duration: ${({ animationTime }) => animationTime};
  animation-timing-function: linear;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.primaryDark};
  background-image: ${({
    activeColor = 'rgba(255, 255, 255, 1)',
    backgroundColor = 'rgba(88, 89, 104, 1)',
    isReversed,
  }) =>
    isReversed
      ? `linear-gradient(to left,${backgroundColor} 0%, ${backgroundColor} 50%, ${activeColor} 50.001%, ${activeColor} 100%)`
      : `linear-gradient(to right,${activeColor} 0%, ${activeColor} 50%, ${backgroundColor} 50.001%, ${backgroundColor} 100%)`};
`
