import { Device } from '@abstractTypes/utils'
import { FaceShapesModal } from '@components/FrameAdvisor/Survey/FaceShapesModal'
import { FrameAdvisorOptionContent } from '@components/FrameAdvisor/Survey/FrameAdvisorOptionContent'
import { Heading2, Heading3 } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { altNavigation, landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValuesLandscape, pxToRem } from '@libs/styled'
import React from 'react'
import styled from 'styled-components'
import { FrameAdvisorStepProps } from './types'

export const FrameAdvisorOptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-inline-size: 90%;
  margin-block-start: ${pxToRem(42)};
  ${tablet(`
    margin-block-start: ${pxToRem(18)};
  `)}
  ${altNavigation(`
    max-inline-size: 80%;
  `)}
  ${zoomPortrait(
    [150, 175],
    `
    max-inline-size: 80%;
  `
  )}
  ${zoomLandscape(
    [150, 175],
    `
    max-inline-size: 90%;
  `
  )}
`

export const FrameAdvisorStepWrapper = styled.div<{ firstStep?: boolean }>`
  margin-block-start: ${({ firstStep }) => (firstStep ? '0' : pxToRem(80))};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${({ firstStep }) =>
    landscape(
      Device.tablet,
      `margin-block-start: ${firstStep ? '0' : clampValuesLandscape(30, 120)};}`
    )}
`

export const StepTitle = styled(Heading2)`
  max-inline-size: 60vw;
  text-align: center;
  ${zoomLandscape(
    [150, 175, 200],
    `
    max-inline-size: 80vw;
    `
  )}
`

export const AltNavStepTitle = styled(Heading3)`
  max-inline-size: 80vw;
  text-align: center;
`

export const FrameAdvisorStepContent = React.forwardRef<HTMLDivElement, FrameAdvisorStepProps>(
  ({ step, className, isFirstStep, onClick }, ref) => {
    const options = step.options
    const lastStep = step.options[0]?.nextStep === null
    const { isAlternativeNavigation } = useNavigationContext()

    return (
      <FrameAdvisorStepWrapper
        ref={ref}
        key={step.id}
        className={className}
        onClick={onClick}
        firstStep={isFirstStep}
      >
        {isAlternativeNavigation ? (
          <AltNavStepTitle color="primaryLight">{step.title}</AltNavStepTitle>
        ) : (
          <StepTitle color="primaryLight">{step.title}</StepTitle>
        )}
        <FrameAdvisorOptionsWrapper>
          {options.map(option => (
            <FrameAdvisorOptionContent
              option={option}
              key={option.id}
              step={step}
              slug={step.slug}
            />
          ))}
        </FrameAdvisorOptionsWrapper>
        {lastStep && <FaceShapesModal options={options} />}
      </FrameAdvisorStepWrapper>
    )
  }
)

FrameAdvisorStepContent.displayName = 'FrameAdvisorStepContent'
