export type Maybe<T> = T | null
export type InputMaybe<T> = T
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  CMSSDisabledCustomizers: {
    input: 'rbconfigurator' | 'ooconfigurator'
    output: 'rbconfigurator' | 'ooconfigurator'
  }
  ColorVariant: { input: 'dark' | 'light'; output: 'dark' | 'light' }
  ContentV2PageType: {
    input:
      | 'home'
      | 'plp'
      | 'plp_anchor'
      | 'pdp'
      | 'customplp'
      | 'custompdp'
      | 'frameadvisorfsa'
      | 'frameadvisorsurvey'
      | 'frameadvisoronboarding'
      | 'freelink'
      | 'supernovaLp'
      | 'prizmExperience'
      | 'editorialplp'
      | 'editorialLp'
      | 'nuance'
      | 'editorialLpNuance'
    output:
      | 'home'
      | 'plp'
      | 'plp_anchor'
      | 'pdp'
      | 'customplp'
      | 'custompdp'
      | 'frameadvisorfsa'
      | 'frameadvisorsurvey'
      | 'frameadvisoronboarding'
      | 'freelink'
      | 'supernovaLp'
      | 'prizmExperience'
      | 'editorialplp'
      | 'editorialLp'
      | 'nuance'
      | 'editorialLpNuance'
  }
  ContentV2ProductType: {
    input:
      | 'OPTICAL'
      | 'SUN'
      | 'SUNGLASSES'
      | 'EYEGLASSES'
      | 'APPAREL'
      | 'ACCESSORIES'
      | 'GOGGLES'
      | 'ELECTRONICS'
      | 'SNOW_GOGGLES'
    output:
      | 'OPTICAL'
      | 'SUN'
      | 'SUNGLASSES'
      | 'EYEGLASSES'
      | 'APPAREL'
      | 'ACCESSORIES'
      | 'GOGGLES'
      | 'ELECTRONICS'
      | 'SNOW_GOGGLES'
  }
  FitImageType: { input: 'narrow' | 'regular' | 'wide'; output: 'narrow' | 'regular' | 'wide' }
  Gender: { input: 'men' | 'women' | 'kids' | 'CHILD'; output: 'men' | 'women' | 'kids' | 'CHILD' }
  JSONObject: { input: any; output: any }
  MainPictureAngle: { input: 'QUARTER' | 'FRONT'; output: 'QUARTER' | 'FRONT' }
  MocoPreferenceStatus: { input: '0' | '1' | '2'; output: '0' | '1' | '2' }
  URI: { input: string; output: string }
}

export type AboutThisStyle = {
  __typename?: 'AboutThisStyle'
  advCampaign?: Maybe<Array<AdvCampaignItem>>
  lensTechnologies?: Maybe<Array<LensItem>>
  lensTreatment?: Maybe<Array<LensItem>>
}

export type Address = {
  address: Scalars['String']['input']
  address1?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  state: Scalars['String']['input']
  zip: Scalars['String']['input']
}

export type AdvCampaignImage = {
  __typename?: 'AdvCampaignImage'
  id: Scalars['ID']['output']
  src?: Maybe<Scalars['String']['output']>
}

export type AdvCampaignItem = {
  __typename?: 'AdvCampaignItem'
  ipadLandscape?: Maybe<AdvCampaignImage>
  ipadPortrait?: Maybe<AdvCampaignImage>
  towerLandscape?: Maybe<AdvCampaignImage>
  towerPortrait?: Maybe<AdvCampaignImage>
}

export type AdvisorInformation = {
  __typename?: 'AdvisorInformation'
  EYEGLASSES?: Maybe<AdvisorMoodSuggestions>
  SUNGLASSES?: Maybe<AdvisorMoodSuggestions>
}

export type AdvisorMoodSuggestions = {
  __typename?: 'AdvisorMoodSuggestions'
  CONTRAST?: Maybe<Scalars['JSONObject']['output']>
  HARMONY?: Maybe<Scalars['JSONObject']['output']>
}

export type Analytics = {
  adobeSessionId?: InputMaybe<Scalars['String']['input']>
  pageBrand?: InputMaybe<Scalars['String']['input']>
  pageEnvironment?: InputMaybe<Scalars['String']['input']>
  pagePlatform?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Scalars['String']['input']>
  sourcePosition?: InputMaybe<Scalars['String']['input']>
  storeCompany?: InputMaybe<Scalars['String']['input']>
  storeGlobalId?: InputMaybe<Scalars['String']['input']>
  storeId?: InputMaybe<Scalars['String']['input']>
  storeRegion?: InputMaybe<Scalars['String']['input']>
}

export type Availability = {
  storeId: Scalars['String']['input']
  upcs: Array<UpcItem>
}

export type AvailabilityItem = {
  __typename?: 'AvailabilityItem'
  prescriptionApplied: Scalars['Boolean']['output']
  qty: Scalars['Int']['output']
  upc: Scalars['String']['output']
}

export type AvailabilityResponse = {
  __typename?: 'AvailabilityResponse'
  stockItems: Array<Maybe<AvailabilityItem>>
}

export type Badges = {
  __typename?: 'Badges'
  pdp: Array<Scalars['String']['output']>
  tile: Array<Scalars['String']['output']>
}

export type BannerDetails = {
  __typename?: 'BannerDetails'
  detailsPicture?: Maybe<ContentV2Image>
  detailsText?: Maybe<Scalars['String']['output']>
  detailsTitle?: Maybe<Scalars['String']['output']>
}

export type BarcodeProducts = {
  __typename?: 'BarcodeProducts'
  items?: Maybe<Array<Product>>
  numFound: Scalars['Int']['output']
  oneCatalogInfo?: Maybe<OneCatalogInfo>
  upcCustomizable: Array<Scalars['String']['output']>
  upcPolarized: Array<Scalars['String']['output']>
  upcPolarizedRelated: Array<Scalars['String']['output']>
  upcPrizm: Array<Scalars['String']['output']>
  upcSameFrameColor: Array<Scalars['String']['output']>
  upcSameLensColor: Array<Scalars['String']['output']>
  upcSameModel: Array<Scalars['String']['output']>
  upcSameSize: Array<Scalars['String']['output']>
}

export type Brand = {
  __typename?: 'Brand'
  id: Scalars['ID']['output']
  logo: Image
  name: Scalars['String']['output']
  url: Scalars['URI']['output']
}

export type BrandBase = {
  __typename?: 'BrandBase'
  id: Scalars['ID']['output']
  logo: Image
  name: Scalars['String']['output']
}

export type CmsAnalyticsData = {
  __typename?: 'CMSAnalyticsData'
  dataDescription?: Maybe<Scalars['String']['output']>
  dataElementId?: Maybe<Scalars['String']['output']>
}

export type CartProduct = {
  brand?: InputMaybe<Scalars['String']['input']>
  moco?: InputMaybe<Scalars['String']['input']>
  model?: InputMaybe<Scalars['String']['input']>
  productType?: InputMaybe<Scalars['String']['input']>
  upc?: InputMaybe<Scalars['String']['input']>
}

export type CartRecommendedAccessoriesData = {
  bagType?: InputMaybe<Scalars['String']['input']>
  cartProducts: Array<CartProduct>
  filters: CartRecommendedAccessoriesFilters
  groupField?: InputMaybe<Scalars['String']['input']>
  rows?: InputMaybe<Scalars['Int']['input']>
  score_biz_field?: InputMaybe<Scalars['String']['input']>
  sortField?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type CartRecommendedAccessoriesFilters = {
  lang: Scalars['String']['input']
  store: Scalars['String']['input']
}

export type Checkout = {
  address?: InputMaybe<Address>
  cartTotal?: InputMaybe<CheckoutCartTotal>
  customer: Person
  delivery: Scalars['String']['input']
  items: Array<CheckoutItem>
  langCode: Scalars['String']['input']
  storeId: Scalars['String']['input']
  storeType: Scalars['String']['input']
}

export type CheckoutCartTotal = {
  previousSubtotal?: InputMaybe<Scalars['Float']['input']>
  subtotal: Scalars['Float']['input']
  total: Scalars['Float']['input']
}

export type CheckoutError = {
  __typename?: 'CheckoutError'
  message: Scalars['String']['output']
  status: Scalars['Int']['output']
  statusText: Scalars['String']['output']
}

export type CheckoutItem = {
  UPC: Scalars['String']['input']
  brandCode?: InputMaybe<Scalars['String']['input']>
  brandName?: InputMaybe<Scalars['String']['input']>
  complete_pair?: InputMaybe<Scalars['Boolean']['input']>
  customItem?: InputMaybe<Scalars['JSONObject']['input']>
  engraving?: InputMaybe<EngravingData>
  imageURL: Scalars['String']['input']
  isNuanceAccessory?: InputMaybe<Scalars['Boolean']['input']>
  isNuanceFrame?: InputMaybe<Scalars['Boolean']['input']>
  isSupernova?: InputMaybe<Scalars['Boolean']['input']>
  price: Scalars['Float']['input']
  productName: Scalars['String']['input']
  quantity: Scalars['Int']['input']
  shippingType: Scalars['String']['input']
}

export type CheckoutResponse = {
  __typename?: 'CheckoutResponse'
  errors?: Maybe<Array<CheckoutError>>
  orderNumber: Scalars['String']['output']
  posReceiptId: Scalars['String']['output']
}

export type Content =
  | ContentNuance
  | ContentV2Brand
  | ContentV2Brands
  | ContentV2ContentMedia
  | ContentV2ContentTapAndHoldMedia
  | ContentV2ContentTextTile
  | ContentV2Electronics
  | ContentV2FrameAdvisorSurvey
  | ContentV2Grid
  | ContentV2HeroSlideShow
  | ContentV2Link
  | ContentV2Prizm
  | ContentV2ProductModels
  | ContentV2ProductPackaging
  | ContentV2Slide
  | ContentV2SportCategories

export type ContentEditorialBanner = {
  __typename?: 'ContentEditorialBanner'
  id: Scalars['ID']['output']
  slides: Array<ContentEditorialBannerSlide>
}

export type ContentEditorialBannerSlide = {
  __typename?: 'ContentEditorialBannerSlide'
  cta?: Maybe<ContentV2Cta>
  id: Scalars['ID']['output']
  isRtr: Scalars['Boolean']['output']
  isTapAndHold: Scalars['Boolean']['output']
  layout: Scalars['String']['output']
  pretitle: Scalars['String']['output']
  rtrData?: Maybe<RtrData>
  standardMedia?: Maybe<ContentV2ContentMedia>
  subtitle: Scalars['String']['output']
  tapAndHoldMedia?: Maybe<ContentV2ContentTapAndHoldMedia>
  textColor?: Maybe<Scalars['ColorVariant']['output']>
  title: Scalars['String']['output']
}

export type ContentEditorialBenefits = {
  __typename?: 'ContentEditorialBenefits'
  content: EditorialBenefitsContent
  id: Scalars['ID']['output']
  layout: Scalars['String']['output']
}

export type ContentEditorialBoard = {
  __typename?: 'ContentEditorialBoard'
  cta?: Maybe<ContentV2Cta>
  id: Scalars['ID']['output']
  isRtr: Scalars['Boolean']['output']
  isTapAndHold: Scalars['Boolean']['output']
  isTransitionLenses: Scalars['Boolean']['output']
  layout: Scalars['String']['output']
  rtrData?: Maybe<RtrData>
  standardMedia?: Maybe<ContentV2ContentMedia>
  subtitle: Scalars['String']['output']
  tapAndHoldMedia?: Maybe<ContentV2ContentTapAndHoldMedia>
  text: Scalars['String']['output']
  title: Scalars['String']['output']
  transitionLensesMedia?: Maybe<Array<Maybe<ContentV2ContentMedia>>>
}

export type ContentEditorialBox = {
  __typename?: 'ContentEditorialBox'
  cta1?: Maybe<ContentV2Cta>
  cta2?: Maybe<ContentV2Cta>
  media1?: Maybe<ContentV2ContentMedia>
  media2?: Maybe<ContentV2ContentMedia>
}

export type ContentEditorialChooseLensTransition = {
  __typename?: 'ContentEditorialChooseLensTransition'
  id: Scalars['ID']['output']
  labelTextButton?: Maybe<Scalars['String']['output']>
  layout: Scalars['String']['output']
  title1: Scalars['String']['output']
  title2: Scalars['String']['output']
  transitionLensDataList: Array<TransitionLensDataList>
}

export type ContentEditorialComponent =
  | ContentEditorialBanner
  | ContentEditorialBenefits
  | ContentEditorialBoard
  | ContentEditorialBox
  | ContentEditorialChooseLensTransition
  | ContentEditorialDragLenses
  | ContentEditorialGrid
  | ContentEditorialImageMask
  | ContentEditorialLensColor
  | ContentEditorialNColumnProductGrid
  | ContentEditorialProductGrid
  | ContentEditorialProductTile
  | ContentEditorialSwitchRtr
  | ContentEditorialThreeColumnProductGrid
  | ContentEditorialTryThemOn
  | ContentEditorialTwoColumnProductGrid
  | ContentV2Html

export type ContentEditorialDragLenses = {
  __typename?: 'ContentEditorialDragLenses'
  id: Scalars['ID']['output']
  layout: Scalars['String']['output']
}

export type ContentEditorialGrid = {
  __typename?: 'ContentEditorialGrid'
  grid: ContentV2Grid
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
}

export type ContentEditorialImageMask = {
  __typename?: 'ContentEditorialImageMask'
  content: EditorialMaskContent
  id: Scalars['ID']['output']
  layout: Scalars['String']['output']
}

export type ContentEditorialLensColor = {
  __typename?: 'ContentEditorialLensColor'
  id: Scalars['ID']['output']
  labelTextButton?: Maybe<Scalars['String']['output']>
  layout: Scalars['String']['output']
  lensColorDataList: Array<LensColorDataList>
  title1: Scalars['String']['output']
}

export type ContentEditorialNColumnProductGrid = {
  __typename?: 'ContentEditorialNColumnProductGrid'
  mocos: Array<Scalars['String']['output']>
  products: Array<Product>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ContentEditorialProductGrid = {
  __typename?: 'ContentEditorialProductGrid'
  mocos: Array<Scalars['String']['output']>
  products: Array<Product>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ContentEditorialProductTile = {
  __typename?: 'ContentEditorialProductTile'
  id: Scalars['ID']['output']
  layout: Scalars['String']['output']
  media?: Maybe<ContentV2ContentMedia>
  mocos: Array<Scalars['String']['output']>
  product?: Maybe<Product>
  text: Scalars['String']['output']
}

export type ContentEditorialSwitchRtr = {
  __typename?: 'ContentEditorialSwitchRtr'
  cta?: Maybe<ContentV2Cta>
  id: Scalars['ID']['output']
  layout: Scalars['String']['output']
  rtrDataList: Array<RtrData>
}

export type ContentEditorialThreeColumnProductGrid = {
  __typename?: 'ContentEditorialThreeColumnProductGrid'
  mocos: Array<Scalars['String']['output']>
  products: Array<Product>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ContentEditorialTryThemOn = {
  __typename?: 'ContentEditorialTryThemOn'
  id: Scalars['ID']['output']
  layout: Scalars['String']['output']
  mocos?: Maybe<Array<Maybe<Product>>>
  qrCode?: Maybe<Scalars['String']['output']>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ContentEditorialTwoColumnProductGrid = {
  __typename?: 'ContentEditorialTwoColumnProductGrid'
  mocos: Array<Scalars['String']['output']>
  products: Array<Product>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ContentNuance = {
  __typename?: 'ContentNuance'
  contentNuance?: Maybe<Array<ContentNuanceTabs>>
}

export type ContentNuanceTabs = {
  __typename?: 'ContentNuanceTabs'
  hasShortMedia: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type ContentV2AnalyticsData = {
  __typename?: 'ContentV2AnalyticsData'
  playlistTitleDescription?: Maybe<Scalars['String']['output']>
  playlistTitleID?: Maybe<Scalars['String']['output']>
}

export type ContentV2Article = {
  __typename?: 'ContentV2Article'
  id: Scalars['ID']['output']
  text?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ContentV2Brand = {
  __typename?: 'ContentV2Brand'
  brand?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<ContentV2Image>
}

export type ContentV2Brands = {
  __typename?: 'ContentV2Brands'
  analyticsData?: Maybe<CmsAnalyticsData>
  brands?: Maybe<Array<Maybe<ContentV2Brand>>>
  id: Scalars['ID']['output']
}

export type ContentV2ContentMedia = {
  __typename?: 'ContentV2ContentMedia'
  classes?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<ContentV2Image>
  ipadImage?: Maybe<ContentV2Image>
  ipadVideo?: Maybe<ContentV2Video>
  links?: Maybe<Array<Maybe<ContentV2Cta>>>
  video?: Maybe<ContentV2Video>
}

export type ContentV2ContentTapAndHoldMedia = {
  __typename?: 'ContentV2ContentTapAndHoldMedia'
  media1?: Maybe<ContentV2ContentMedia>
  media2?: Maybe<ContentV2ContentMedia>
  video?: Maybe<ContentV2ContentMedia>
}

export type ContentV2ContentTextTile = {
  __typename?: 'ContentV2ContentTextTile'
  id: Scalars['ID']['output']
  text?: Maybe<Scalars['String']['output']>
}

export type ContentV2Cta = {
  __typename?: 'ContentV2Cta'
  ctaColor?: Maybe<Scalars['ColorVariant']['output']>
  ctaText?: Maybe<Scalars['String']['output']>
  disabled?: Maybe<Scalars['Boolean']['output']>
  link?: Maybe<ContentV2Link>
  position?: Maybe<Scalars['String']['output']>
}

export type ContentV2Electronics = {
  __typename?: 'ContentV2Electronics'
  id: Scalars['ID']['output']
  product?: Maybe<Scalars['String']['output']>
  tabs: Array<ContentV2ElectronicsTab>
  title: Scalars['String']['output']
}

export type ContentV2ElectronicsTab = {
  __typename?: 'ContentV2ElectronicsTab'
  analyticsData: Scalars['String']['output']
  content?: Maybe<Array<Maybe<ContentV2ElectronicsTabContent>>>
  id: Scalars['ID']['output']
  isDefaultTab?: Maybe<Scalars['Boolean']['output']>
  isShortMedia?: Maybe<Scalars['Boolean']['output']>
  pictures?: Maybe<Array<Maybe<ContentV2Image>>>
  tabName?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  video?: Maybe<Array<ContentV2Video>>
}

export type ContentV2ElectronicsTabContent =
  | ContentV2Brands
  | ContentV2Html
  | ContentV2Image
  | ContentV2Teaser
  | ContentV2Video
  | ElectronicsContentV2Article

export type ContentV2EnforcementMessagesTranslation = {
  __typename?: 'ContentV2EnforcementMessagesTranslation'
  key?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
}

export type ContentV2FrameAdvisorSurvey = {
  __typename?: 'ContentV2FrameAdvisorSurvey'
  survey?: Maybe<Array<Maybe<FrameAdvisorStep>>>
}

export type ContentV2Grid = {
  __typename?: 'ContentV2Grid'
  id: Scalars['ID']['output']
  tiles?: Maybe<Array<Maybe<ContentV2ContentMedia>>>
}

export type ContentV2Html = {
  __typename?: 'ContentV2HTML'
  data?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type ContentV2HeroSlideShow = {
  __typename?: 'ContentV2HeroSlideShow'
  id: Scalars['ID']['output']
  slides?: Maybe<Array<Maybe<ContentV2Slide>>>
}

export type ContentV2Image = {
  __typename?: 'ContentV2Image'
  altText: Scalars['String']['output']
  callToActionCustomText?: Maybe<Scalars['String']['output']>
  cropImage?: Maybe<CropImage>
  id: Scalars['ID']['output']
  mimeType?: Maybe<Scalars['String']['output']>
  src: Scalars['URI']['output']
}

export type ContentV2Link = {
  __typename?: 'ContentV2Link'
  analyticsData?: Maybe<CmsAnalyticsData>
  brand?: Maybe<Scalars['String']['output']>
  customizable?: Maybe<Scalars['Boolean']['output']>
  engravable?: Maybe<Scalars['Boolean']['output']>
  gender?: Maybe<Scalars['Gender']['output']>
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  model?: Maybe<Scalars['String']['output']>
  pageType?: Maybe<Scalars['ContentV2PageType']['output']>
  polarizedLens?: Maybe<Scalars['Boolean']['output']>
  productType?: Maybe<Scalars['ContentV2ProductType']['output']>
  roxable?: Maybe<Scalars['Boolean']['output']>
  rtr?: Maybe<Scalars['Boolean']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sports?: Maybe<Array<Scalars['String']['output']>>
  url: Scalars['String']['output']
  vto?: Maybe<Scalars['Boolean']['output']>
}

export type ContentV2Playlist = {
  __typename?: 'ContentV2Playlist'
  playlist?: Maybe<Array<Maybe<ContentV2PlaylistItem>>>
  title?: Maybe<Scalars['String']['output']>
}

export type ContentV2PlaylistItem = {
  __typename?: 'ContentV2PlaylistItem'
  analyticsData?: Maybe<ContentV2AnalyticsData>
  availableInStore: Scalars['Boolean']['output']
  carousel?: Maybe<Array<Maybe<ContentV2Image>>>
  carouselIpad?: Maybe<Array<Maybe<ContentV2Image>>>
  deboostTags?: Maybe<Array<Scalars['String']['output']>>
  description?: Maybe<Scalars['String']['output']>
  duration?: Maybe<Scalars['Int']['output']>
  elevationMoco?: Maybe<Array<Scalars['String']['output']>>
  exclusionMoco?: Maybe<Array<Scalars['String']['output']>>
  font?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<ContentV2Image>
  imageIpad?: Maybe<ContentV2Image>
  productType?: Maybe<Array<Scalars['String']['output']>>
  tags: Array<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  video?: Maybe<ContentV2Video>
}

export type ContentV2Prizm = {
  __typename?: 'ContentV2Prizm'
  id: Scalars['ID']['output']
  playlist?: Maybe<Array<Maybe<ContentV2PrizmItem>>>
  title?: Maybe<Scalars['String']['output']>
}

export type ContentV2PrizmItem = {
  __typename?: 'ContentV2PrizmItem'
  bannerDetails?: Maybe<BannerDetails>
  clen: Array<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<ContentV2Image>
  link: ContentV2Link
  title?: Maybe<Scalars['String']['output']>
  video: ContentV2Video
  videoLandscape?: Maybe<ContentV2Video>
}

export type ContentV2PrizmPlaylist = {
  __typename?: 'ContentV2PrizmPlaylist'
  playlist?: Maybe<Array<Maybe<ContentV2PrizmItem>>>
}

export type ContentV2ProductModel = {
  __typename?: 'ContentV2ProductModel'
  id: Scalars['ID']['output']
  imageUrl: Scalars['String']['output']
  productTitle: Scalars['String']['output']
  productUrl: Scalars['String']['output']
}

export type ContentV2ProductModels = {
  __typename?: 'ContentV2ProductModels'
  id: Scalars['ID']['output']
  models?: Maybe<Array<Maybe<ContentV2ProductModel>>>
  title: Scalars['String']['output']
}

export type ContentV2ProductPackaging = {
  __typename?: 'ContentV2ProductPackaging'
  PackageDescription: Scalars['URI']['output']
  PackageTitle: Scalars['String']['output']
  id: Scalars['ID']['output']
  image?: Maybe<ContentV2Image>
  imageIpad?: Maybe<ContentV2Image>
}

export type ContentV2Slide = {
  __typename?: 'ContentV2Slide'
  duration?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  isElectronicsSlide?: Maybe<Scalars['Boolean']['output']>
  isNuanceSlide?: Maybe<Scalars['Boolean']['output']>
  isSupernovaSlide?: Maybe<Scalars['Boolean']['output']>
  slotA: ContentV2SlideTile
  slotB?: Maybe<ContentV2SlideTile>
  textColor?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type ContentV2SlideTile = ContentV2ContentMedia | ContentV2ContentTextTile

export type ContentV2SportCategories = {
  __typename?: 'ContentV2SportCategories'
  categories?: Maybe<Array<Maybe<ContentV2ContentMedia>>>
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
}

export type ContentV2Teaser = {
  __typename?: 'ContentV2Teaser'
  analyticsData?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  pictures?: Maybe<Array<Maybe<ContentV2Image>>>
  tabName?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ContentV2Video = {
  __typename?: 'ContentV2Video'
  alt?: Maybe<Scalars['String']['output']>
  callToActionCustomText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  src: Scalars['URI']['output']
  title?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type CropImage = {
  __typename?: 'CropImage'
  _1x1: Scalars['URI']['output']
  _3x4: Scalars['URI']['output']
  _4x3: Scalars['URI']['output']
  _9x16: Scalars['URI']['output']
  _16x9: Scalars['URI']['output']
}

export type Currency = {
  __typename?: 'Currency'
  id: CurrencyCode
  label: Scalars['String']['output']
  symbol?: Maybe<Scalars['String']['output']>
}

/**
 * CurrencyCode -
 * codes that follows ISO 4217
 */
export enum CurrencyCode {
  /** United Arab Emirates Dirham (AED) */
  Aed = 'AED',
  /** Afghan Afghani (AFN) */
  Afn = 'AFN',
  /** Albanian Lek (ALL) */
  All = 'ALL',
  /** Armenian Dram (AMD) */
  Amd = 'AMD',
  /** Netherlands Antillean Guilder */
  Ang = 'ANG',
  /** Angolan Kwanza (AOA) */
  Aoa = 'AOA',
  /** Argentine Pesos (ARS) */
  Ars = 'ARS',
  /** Australian Dollars (AUD) */
  Aud = 'AUD',
  /** Aruban Florin (AWG) */
  Awg = 'AWG',
  /** Azerbaijani Manat (AZN) */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark (BAM) */
  Bam = 'BAM',
  /** Barbadian Dollar (BBD) */
  Bbd = 'BBD',
  /** Bangladesh Taka (BDT) */
  Bdt = 'BDT',
  /** Bulgarian Lev (BGN) */
  Bgn = 'BGN',
  /** Bahraini Dinar (BHD) */
  Bhd = 'BHD',
  /** Brunei Dollar (BND) */
  Bnd = 'BND',
  /** Bolivian Boliviano (BOB) */
  Bob = 'BOB',
  /** Brazilian Real (BRL) */
  Brl = 'BRL',
  /** Bahamian Dollar (BSD) */
  Bsd = 'BSD',
  /** Bhutanese Ngultrum (BTN) */
  Btn = 'BTN',
  /** Botswana Pula (BWP) */
  Bwp = 'BWP',
  /** Belarusian Ruble (BYR) */
  Byr = 'BYR',
  /** Belize Dollar (BZD) */
  Bzd = 'BZD',
  /** Canadian Dollars (CAD) */
  Cad = 'CAD',
  /** Congolese franc (CDF) */
  Cdf = 'CDF',
  /** Swiss Francs (CHF) */
  Chf = 'CHF',
  /** Chilean Peso (CLP) */
  Clp = 'CLP',
  /** Chinese Yuan Renminbi (CNY) */
  Cny = 'CNY',
  /** Colombian Peso (COP) */
  Cop = 'COP',
  /** Costa Rican Colones (CRC) */
  Crc = 'CRC',
  /** Cape Verdean escudo (CVE) */
  Cve = 'CVE',
  /** Czech Koruny (CZK) */
  Czk = 'CZK',
  /** Danish Kroner (DKK) */
  Dkk = 'DKK',
  /** Dominican Peso (DOP) */
  Dop = 'DOP',
  /** Algerian Dinar (DZD) */
  Dzd = 'DZD',
  /** Egyptian Pound (EGP) */
  Egp = 'EGP',
  /** Ethiopian Birr (ETB) */
  Etb = 'ETB',
  /** Euro (EUR) */
  Eur = 'EUR',
  /** Fijian Dollars (FJD) */
  Fjd = 'FJD',
  /** United Kingdom Pounds (GBP) */
  Gbp = 'GBP',
  /** Georgian Lari (GEL) */
  Gel = 'GEL',
  /** Ghanaian Cedi (GHS) */
  Ghs = 'GHS',
  /** Gambian Dalasi (GMD) */
  Gmd = 'GMD',
  /** Guatemalan Quetzal (GTQ) */
  Gtq = 'GTQ',
  /** Guyanese Dollar (GYD) */
  Gyd = 'GYD',
  /** Hong Kong Dollars (HKD) */
  Hkd = 'HKD',
  /** Honduran Lempira (HNL) */
  Hnl = 'HNL',
  /** Croatian Kuna (HRK) */
  Hrk = 'HRK',
  /** Haitian Gourde (HTG) */
  Htg = 'HTG',
  /** Hungarian Forint (HUF) */
  Huf = 'HUF',
  /** Indonesian Rupiah (IDR) */
  Idr = 'IDR',
  /** Israeli New Shekel (NIS) */
  Ils = 'ILS',
  /** Indian Rupees (INR) */
  Inr = 'INR',
  /** Icelandic Kronur (ISK) */
  Isk = 'ISK',
  /** Jersey Pound */
  Jep = 'JEP',
  /** Jamaican Dollars (JMD) */
  Jmd = 'JMD',
  /** Jordanian Dinar (JOD) */
  Jod = 'JOD',
  /** Japanese Yen (JPY) */
  Jpy = 'JPY',
  /** Kenyan Shilling (KES) */
  Kes = 'KES',
  /** Kyrgyzstani Som (KGS) */
  Kgs = 'KGS',
  /** Cambodian Riel */
  Khr = 'KHR',
  /** Comorian Franc (KMF) */
  Kmf = 'KMF',
  /** South Korean Won (KRW) */
  Krw = 'KRW',
  /** Kuwaiti Dinar (KWD) */
  Kwd = 'KWD',
  /** Cayman Dollars (KYD) */
  Kyd = 'KYD',
  /** Kazakhstani Tenge (KZT) */
  Kzt = 'KZT',
  /** Laotian Kip (LAK) */
  Lak = 'LAK',
  /** Lebanese Pounds (LBP) */
  Lbp = 'LBP',
  /** Sri Lankan Rupees (LKR) */
  Lkr = 'LKR',
  /** Liberian Dollar (LRD) */
  Lrd = 'LRD',
  /** Lesotho Loti (LSL) */
  Lsl = 'LSL',
  /** Lithuanian Litai (LTL) */
  Ltl = 'LTL',
  /** Latvian Lati (LVL) */
  Lvl = 'LVL',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu (MDL) */
  Mdl = 'MDL',
  /** Malagasy Ariary (MGA) */
  Mga = 'MGA',
  /** Macedonia Denar (MKD) */
  Mkd = 'MKD',
  /** Burmese Kyat (MMK) */
  Mmk = 'MMK',
  /** Mongolian Tugrik */
  Mnt = 'MNT',
  /** Macanese Pataca (MOP) */
  Mop = 'MOP',
  /** Mauritian Rupee (MUR) */
  Mur = 'MUR',
  /** Maldivian Rufiyaa (MVR) */
  Mvr = 'MVR',
  /** Malawian Kwacha (MWK) */
  Mwk = 'MWK',
  /** Mexican Pesos (MXN) */
  Mxn = 'MXN',
  /** Malaysian Ringgits (MYR) */
  Myr = 'MYR',
  /** Mozambican Metical */
  Mzn = 'MZN',
  /** Namibian Dollar */
  Nad = 'NAD',
  /** Nigerian Naira (NGN) */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba (NIO) */
  Nio = 'NIO',
  /** Norwegian Kroner (NOK) */
  Nok = 'NOK',
  /** Nepalese Rupee (NPR) */
  Npr = 'NPR',
  /** New Zealand Dollars (NZD) */
  Nzd = 'NZD',
  /** Omani Rial (OMR) */
  Omr = 'OMR',
  /** Peruvian Nuevo Sol (PEN) */
  Pen = 'PEN',
  /** Papua New Guinean Kina (PGK) */
  Pgk = 'PGK',
  /** Philippine Peso (PHP) */
  Php = 'PHP',
  /** Pakistani Rupee (PKR) */
  Pkr = 'PKR',
  /** Polish Zlotych (PLN) */
  Pln = 'PLN',
  /** Paraguayan Guarani (PYG) */
  Pyg = 'PYG',
  /** Qatari Rial (QAR) */
  Qar = 'QAR',
  /** Romanian Lei (RON) */
  Ron = 'RON',
  /** Serbian dinar (RSD) */
  Rsd = 'RSD',
  /** Russian Rubles (RUB) */
  Rub = 'RUB',
  /** Rwandan Franc (RWF) */
  Rwf = 'RWF',
  /** Saudi Riyal (SAR) */
  Sar = 'SAR',
  /** Solomon Islands Dollar (SBD) */
  Sbd = 'SBD',
  /** Seychellois Rupee (SCR) */
  Scr = 'SCR',
  /** Sudanese Pound (SDG) */
  Sdg = 'SDG',
  /** Swedish Kronor (SEK) */
  Sek = 'SEK',
  /** Singapore Dollars (SGD) */
  Sgd = 'SGD',
  /** Surinamese Dollar (SRD) */
  Srd = 'SRD',
  /** South Sudanese Pound (SSP) */
  Ssp = 'SSP',
  /** Sao Tome And Principe Dobra (STD) */
  Std = 'STD',
  /** Syrian Pound (SYP) */
  Syp = 'SYP',
  /** Swazi Lilangeni (SZL) */
  Szl = 'SZL',
  /** Thai baht (THB) */
  Thb = 'THB',
  /** Turkmenistani Manat (TMT) */
  Tmt = 'TMT',
  /** Tunisian Dinar (TND) */
  Tnd = 'TND',
  /** Turkish Lira (TRY) */
  Try = 'TRY',
  /** Trinidad and Tobago Dollars (TTD) */
  Ttd = 'TTD',
  /** Taiwan Dollars (TWD) */
  Twd = 'TWD',
  /** Tanzanian Shilling (TZS) */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia (UAH) */
  Uah = 'UAH',
  /** Ugandan Shilling (UGX) */
  Ugx = 'UGX',
  /** United States Dollars (USD) */
  Usd = 'USD',
  /** Uruguayan Pesos (UYU) */
  Uyu = 'UYU',
  /** Uzbekistan som (UZS) */
  Uzs = 'UZS',
  /** Venezuelan Bolivares (VEF) */
  Vef = 'VEF',
  /** Vietnamese đồng (VND) */
  Vnd = 'VND',
  /** Vanuatu Vatu (VUV) */
  Vuv = 'VUV',
  /** Samoan Tala (WST) */
  Wst = 'WST',
  /** Central African CFA Franc (XAF) */
  Xaf = 'XAF',
  /** East Caribbean Dollar (XCD) */
  Xcd = 'XCD',
  /** West African CFA franc (XOF) */
  Xof = 'XOF',
  /** CFP Franc (XPF) */
  Xpf = 'XPF',
  /** Yemeni Rial (YER) */
  Yer = 'YER',
  /** South African Rand (ZAR) */
  Zar = 'ZAR',
  /** Zambian Kwacha (ZMW) */
  Zmw = 'ZMW',
}

export type CustomerOrderCheckout = {
  email?: InputMaybe<Scalars['String']['input']>
  items: Array<CheckoutItem>
  langCode: Scalars['String']['input']
  salesOrderId?: InputMaybe<Scalars['String']['input']>
  storeId: Scalars['String']['input']
  storeType: Scalars['String']['input']
}

export type DataNuance = {
  __typename?: 'DataNuance'
  extraContent?: Maybe<ExtraContent>
  htmlContent?: Maybe<Scalars['String']['output']>
}

export type DestinationAvailability = {
  __typename?: 'DestinationAvailability'
  home: Scalars['Boolean']['output']
  store: Scalars['Boolean']['output']
}

export type DiscountBanner = {
  __typename?: 'DiscountBanner'
  backgroundColor?: Maybe<Scalars['String']['output']>
  teaserText?: Maybe<Scalars['String']['output']>
  teaserTitle?: Maybe<Scalars['String']['output']>
}

export type EditorialBenefitsContent = {
  __typename?: 'EditorialBenefitsContent'
  data: Scalars['String']['output']
  teaserTitle: Scalars['String']['output']
}

export type EditorialComponentList = {
  __typename?: 'EditorialComponentList'
  components: Array<ContentEditorialComponent>
  id: Scalars['String']['output']
}

export type EditorialContentBrandPlp = {
  __typename?: 'EditorialContentBrandPLP'
  bannerSlides?: Maybe<Array<Maybe<ContentV2ContentMedia>>>
}

export type EditorialLensItem = {
  __typename?: 'EditorialLensItem'
  iconId: Scalars['String']['output']
  label: Scalars['String']['output']
}

export type EditorialMaskContent = {
  __typename?: 'EditorialMaskContent'
  imgUrls: Array<Maybe<ContentV2Image>>
  lensItems: Array<EditorialLensItem>
}

export type ElectronicsContentV2Article = {
  __typename?: 'ElectronicsContentV2Article'
  colors?: Maybe<Array<Scalars['String']['output']>>
  id: Scalars['ID']['output']
  text?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type EnforcementMessage = {
  __typename?: 'EnforcementMessage'
  message?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
}

export type EngravingData = {
  items: Array<EngravingItem>
  price: Scalars['Float']['input']
}

export type EngravingItem = {
  color: Scalars['String']['input']
  font: Scalars['String']['input']
  side: Scalars['String']['input']
  text: Scalars['String']['input']
}

export type ExcludedFilters = {
  productType?: InputMaybe<Array<Scalars['String']['input']>>
  product_hierarchy1?: InputMaybe<Array<Scalars['String']['input']>>
}

export type Experiences = {
  __typename?: 'Experiences'
  experiences: Array<TransitionExperienceItem>
}

export type ExtraContent = {
  __typename?: 'ExtraContent'
  content?: Maybe<NuanceContent>
}

export type FaSuggestionsData = {
  enableScaling?: InputMaybe<Scalars['Boolean']['input']>
  features?: InputMaybe<SuggestionsSurvey>
  filters?: InputMaybe<SuggestionsFilters>
  loveHates?: InputMaybe<SuggestionsLoveHates>
  playlist?: InputMaybe<SuggestionsPlaylist>
  rows?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type FacetFilter = {
  __typename?: 'FacetFilter'
  count?: Maybe<Scalars['Int']['output']>
  kind?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export enum Fit {
  Asian = 'asian',
  Large = 'large',
  Small = 'small',
  Standard = 'standard',
}

export type FitImage = {
  __typename?: 'FitImage'
  fit?: Maybe<Scalars['FitImageType']['output']>
  image?: Maybe<ContentV2Image>
}

export type FitImages = {
  __typename?: 'FitImages'
  optical: Array<FitImage>
  sun: Array<FitImage>
}

export type FitSize = {
  __typename?: 'FitSize'
  fitImages: FitImages
}

export type FrameAdvisorFsa = {
  __typename?: 'FrameAdvisorFsa'
  frameAdvisorFsaToken: FrameAdvisorFsaToken
  videoBg: Array<ContentV2Video>
}

export type FrameAdvisorFsaConsentResponse = {
  __typename?: 'FrameAdvisorFsaConsentResponse'
  name: Scalars['String']['output']
  value: Scalars['Boolean']['output']
  videoId: Scalars['String']['output']
}

export type FrameAdvisorFsaToken = {
  __typename?: 'FrameAdvisorFsaToken'
  postURL: Scalars['String']['output']
  token: Scalars['String']['output']
}

export type FrameAdvisorFsaUserProfile = {
  __typename?: 'FrameAdvisorFsaUserProfile'
  advisorInformation?: Maybe<AdvisorInformation>
  pictureUuid: Scalars['String']['output']
  playlistId?: Maybe<Scalars['String']['output']>
  profilePictureUrl?: Maybe<Scalars['String']['output']>
  userInformation?: Maybe<ProfileUserInformation>
  userSearchInformation?: Maybe<ProfileUserSearchInformation>
  uuid: Scalars['String']['output']
  videoUuid?: Maybe<Scalars['String']['output']>
}

export type FrameAdvisorFsaVideoId = {
  __typename?: 'FrameAdvisorFsaVideoId'
  accessToken?: Maybe<Scalars['String']['output']>
  refreshToken?: Maybe<Scalars['String']['output']>
  videoId?: Maybe<Scalars['String']['output']>
}

export type FrameAdvisorOnboarding = {
  __typename?: 'FrameAdvisorOnboarding'
  slides: Array<OnboardingSlide>
}

export type FrameAdvisorOption = {
  __typename?: 'FrameAdvisorOption'
  analyticsData?: Maybe<CmsAnalyticsData>
  assetKey?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  image?: Maybe<ContentV2Image>
  ipadImage?: Maybe<ContentV2Image>
  learnMoreImage?: Maybe<ContentV2Image>
  learnMoreImageIpad?: Maybe<ContentV2Image>
  nextStep?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Maybe<FrameAdvisorTag>>>
  title?: Maybe<Scalars['String']['output']>
}

export type FrameAdvisorStep = {
  __typename?: 'FrameAdvisorStep'
  id: Scalars['String']['output']
  key: Scalars['String']['output']
  options: Array<Maybe<FrameAdvisorOption>>
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type FrameAdvisorTag = {
  __typename?: 'FrameAdvisorTag'
  suggestedKey?: Maybe<Scalars['String']['output']>
  suggestedTag?: Maybe<Scalars['String']['output']>
}

export type FsaCreateUser = {
  __typename?: 'FsaCreateUser'
  uuid: Scalars['String']['output']
}

export type GreenShipment = {
  __typename?: 'GreenShipment'
  backgroundColor?: Maybe<Scalars['String']['output']>
  teaserText?: Maybe<Scalars['String']['output']>
  teaserTitle?: Maybe<Scalars['String']['output']>
}

export type GreenShipmentBlocks = {
  __typename?: 'GreenShipmentBlocks'
  labelStyle?: Maybe<Scalars['String']['output']>
  labelText?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type GreenShipmentInfo = {
  __typename?: 'GreenShipmentInfo'
  footerText?: Maybe<Scalars['String']['output']>
  headerCaption?: Maybe<Scalars['String']['output']>
  headerImage?: Maybe<ContentV2Image>
  headerTitle?: Maybe<Scalars['String']['output']>
  mainText?: Maybe<Scalars['String']['output']>
  subText?: Maybe<Scalars['String']['output']>
  textGrid?: Maybe<Array<Maybe<GreenShipmentBlocks>>>
}

export type IPage = {
  id: Scalars['ID']['output']
  type: PageType
  url: Scalars['URI']['output']
}

export type Image = {
  __typename?: 'Image'
  accessibilitySrc?: Maybe<Scalars['URI']['output']>
  accessibilitySrcLandscape?: Maybe<Scalars['URI']['output']>
  altText: Scalars['String']['output']
  callToActionCustomText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  ipadSrc?: Maybe<Scalars['URI']['output']>
  mimeType?: Maybe<Scalars['String']['output']>
  src: Scalars['URI']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type Item = {
  __typename?: 'Item'
  subCollectionTitle: Scalars['String']['output']
  subItems: Array<SubItem>
}

export type Language = {
  __typename?: 'Language'
  id: Scalars['ID']['output']
  isoCode: Scalars['String']['output']
  label: Scalars['String']['output']
}

export type LensColorDataList = {
  __typename?: 'LensColorDataList'
  collectionSubTitle?: Maybe<Scalars['String']['output']>
  collectionTitle?: Maybe<Scalars['String']['output']>
  items: Array<LensItems>
}

export type LensItem = {
  __typename?: 'LensItem'
  description?: Maybe<Scalars['String']['output']>
  iconId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<ContentV2Image>
  imageIpad?: Maybe<ContentV2Image>
  isTapAndHold?: Maybe<Scalars['Boolean']['output']>
  tapAndHoldMedia?: Maybe<ContentV2ContentTapAndHoldMedia>
  title?: Maybe<Scalars['String']['output']>
}

export type LensItems = {
  __typename?: 'LensItems'
  bigImg: Scalars['String']['output']
  cta?: Maybe<ContentV2Cta>
  labelImg: Scalars['String']['output']
  thumbnail: Scalars['String']['output']
}

export type Link = {
  __typename?: 'Link'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  url: Scalars['URI']['output']
}

export type LoveHatesData = {
  moco?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['MocoPreferenceStatus']['input']>
  timestamp?: InputMaybe<Scalars['String']['input']>
}

export type MatchesWith = {
  __typename?: 'MatchesWith'
  numFound: Scalars['Int']['output']
  products: Array<Product>
  queryTime: Scalars['Int']['output']
}

export type MatchesWithSuggestionsData = {
  compatibleFor?: InputMaybe<Scalars['String']['input']>
  core?: InputMaybe<Scalars['String']['input']>
  excludeFilters?: InputMaybe<ExcludedFilters>
  lang?: InputMaybe<Scalars['String']['input']>
  moco?: InputMaybe<Scalars['String']['input']>
  rows?: InputMaybe<Scalars['Int']['input']>
  score_biz_field?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  store?: InputMaybe<Scalars['String']['input']>
}

export type Menu = {
  __typename?: 'Menu'
  analyticsData?: Maybe<CmsAnalyticsData>
  id: Scalars['ID']['output']
  items: Array<Maybe<MenuItem>>
}

export type MenuItem = {
  __typename?: 'MenuItem'
  analyticsData?: Maybe<CmsAnalyticsData>
  iconId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  link?: Maybe<ContentV2Link>
  submenu?: Maybe<SubMenu>
  text: Scalars['String']['output']
}

export type Meta = {
  __typename?: 'Meta'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  availability: AvailabilityResponse
  checkout: CheckoutResponse
  createVMHash?: Maybe<VmDataResponse>
  customerOrderCheckout: CheckoutResponse
  frameAdvisorFsaConsent: FrameAdvisorFsaConsentResponse
  frameAdvisorFsaEmail?: Maybe<Scalars['Boolean']['output']>
  fsaCreateUser: FsaCreateUser
  fsaUpdateProfileProperty: Scalars['Boolean']['output']
  saveUserPlaylist: SaveUserPlaylist
  sessionHandoffStateSaver: SessionHandoffStateSaver
}

export type MutationAvailabilityArgs = {
  data: Availability
}

export type MutationCheckoutArgs = {
  data: Checkout
}

export type MutationCreateVmHashArgs = {
  data: Vm
}

export type MutationCustomerOrderCheckoutArgs = {
  data: CustomerOrderCheckout
}

export type MutationFrameAdvisorFsaConsentArgs = {
  name: Scalars['String']['input']
  value: Scalars['Boolean']['input']
  videoId: Scalars['String']['input']
}

export type MutationFrameAdvisorFsaEmailArgs = {
  email: Scalars['String']['input']
  locale: Scalars['String']['input']
  videoId: Scalars['String']['input']
}

export type MutationFsaCreateUserArgs = {
  fsaAccessToken: Scalars['String']['input']
  fsaRefreshToken: Scalars['String']['input']
  url: Scalars['URI']['input']
  videoId: Scalars['String']['input']
}

export type MutationFsaUpdateProfilePropertyArgs = {
  propertyName: Scalars['String']['input']
  propertyValue: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationSaveUserPlaylistArgs = {
  fsaAccessToken: Scalars['String']['input']
  fsaRefreshToken: Scalars['String']['input']
  playlistId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationSessionHandoffStateSaverArgs = {
  isScanned?: InputMaybe<Scalars['Boolean']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  token: Scalars['String']['input']
}

export type NoResults = {
  __typename?: 'NoResults'
  id: Scalars['ID']['output']
  noResults?: Maybe<Array<Maybe<NoResultsCarousels>>>
}

export type NoResultsCarousels = {
  __typename?: 'NoResultsCarousels'
  id: Scalars['String']['output']
  products?: Maybe<Array<Maybe<Product>>>
}

export type NuanceContent = {
  __typename?: 'NuanceContent'
  cropImages?: Maybe<Array<ContentV2Image>>
  cta?: Maybe<ContentV2Cta>
  html?: Maybe<Scalars['String']['output']>
  qrcode?: Maybe<Scalars['String']['output']>
  video?: Maybe<Array<ContentV2Video>>
}

export type NuanceTabContent = {
  __typename?: 'NuanceTabContent'
  cta?: Maybe<ContentV2Cta>
  data?: Maybe<DataNuance>
  hero?: Maybe<ContentV2ContentMedia>
  id: Scalars['String']['output']
  nuanceType?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type OnboardingSlide = {
  __typename?: 'OnboardingSlide'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  title: Scalars['String']['output']
  video?: Maybe<ContentV2Video>
}

export type OneCatalogInfo = {
  __typename?: 'OneCatalogInfo'
  UPC: Scalars['String']['output']
  customizable?: Maybe<Scalars['Boolean']['output']>
  moco: Scalars['String']['output']
  model: Scalars['String']['output']
  originalBrandCode: Scalars['String']['output']
  stylenameNormalized: Scalars['String']['output']
  thumbnails: ProductThumbnailGroup
}

export type Page =
  | PageError
  | PageFrameAdvisorPlaylist
  | PageFrameAdvisorSurvey
  | PageHome
  | PageNuance
  | PagePdp
  | PagePrizm
  | PageSupernova

export type PageError = IPage & {
  __typename?: 'PageError'
  content?: Maybe<Array<Maybe<Content>>>
  id: Scalars['ID']['output']
  type: PageType
  url: Scalars['URI']['output']
}

export type PageFrameAdvisorPlaylist = IPage & {
  __typename?: 'PageFrameAdvisorPlaylist'
  id: Scalars['ID']['output']
  playlist?: Maybe<ContentV2Playlist>
  type: PageType
  url: Scalars['URI']['output']
}

export type PageFrameAdvisorSurvey = IPage & {
  __typename?: 'PageFrameAdvisorSurvey'
  id: Scalars['ID']['output']
  survey?: Maybe<ContentV2FrameAdvisorSurvey>
  type: PageType
  url: Scalars['URI']['output']
  videoBg?: Maybe<Array<ContentV2Video>>
}

export type PageHome = IPage & {
  __typename?: 'PageHome'
  content?: Maybe<Array<Maybe<Content>>>
  id: Scalars['ID']['output']
  type: PageType
  url: Scalars['URI']['output']
}

export type PageNuance = IPage & {
  __typename?: 'PageNuance'
  content?: Maybe<Array<Maybe<Content>>>
  id: Scalars['ID']['output']
  meta: Meta
  type: PageType
  url: Scalars['URI']['output']
}

export type PagePdp = IPage & {
  __typename?: 'PagePDP'
  alternatives?: Maybe<Array<Product>>
  content?: Maybe<Array<Maybe<Content>>>
  id: Scalars['ID']['output']
  product: Product
  relationship?: Maybe<ProductRelation>
  type: PageType
  url: Scalars['URI']['output']
}

export type PagePrizm = IPage & {
  __typename?: 'PagePrizm'
  id: Scalars['ID']['output']
  prizmPlaylist?: Maybe<ContentV2PrizmPlaylist>
  type: PageType
  url: Scalars['URI']['output']
}

export type PageSupernova = IPage & {
  __typename?: 'PageSupernova'
  content?: Maybe<Array<Maybe<Content>>>
  id: Scalars['ID']['output']
  title?: Maybe<Scalars['String']['output']>
  type: PageType
  url: Scalars['URI']['output']
}

export enum PageType {
  PageError = 'PageError',
  PageFrameAdvisorPlaylist = 'PageFrameAdvisorPlaylist',
  PageFrameAdvisorSurvey = 'PageFrameAdvisorSurvey',
  PageHome = 'PageHome',
  PageNuance = 'PageNuance',
  PagePdp = 'PagePDP',
  PagePrizm = 'PagePrizm',
  PageSupernova = 'PageSupernova',
}

export type Person = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  marketing?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  profiling?: InputMaybe<Scalars['String']['input']>
}

export type PictureConfig = {
  mainAngle?: InputMaybe<Scalars['MainPictureAngle']['input']>
  priority: Array<Scalars['String']['input']>
}

export type PriceRanges = {
  __typename?: 'PriceRanges'
  max: Scalars['String']['output']
  min: Scalars['String']['output']
}

export type Product = {
  __typename?: 'Product'
  UPC: Scalars['String']['output']
  advisoryNotes?: Maybe<Scalars['String']['output']>
  availability?: Maybe<ProductAvailability>
  badges: Badges
  brand: Brand
  clen?: Maybe<Scalars['String']['output']>
  color?: Maybe<Scalars['String']['output']>
  colorCode: Scalars['String']['output']
  colorLabel?: Maybe<Scalars['String']['output']>
  colorsAvailable?: Maybe<Scalars['Int']['output']>
  customizable?: Maybe<Scalars['Boolean']['output']>
  description?: Maybe<Scalars['String']['output']>
  drivingAsnzs?: Maybe<Scalars['String']['output']>
  enforcementBullets?: Maybe<Array<Maybe<EnforcementMessage>>>
  engravable: Scalars['Boolean']['output']
  engravingConstraints?: Maybe<EngravingConstraints>
  faceShape?: Maybe<Array<Scalars['String']['output']>>
  fit?: Maybe<Scalars['String']['output']>
  frameAdvisorEvidences?: Maybe<FrameAdvisorEvidences>
  frameColor?: Maybe<Scalars['String']['output']>
  frameColorLabel?: Maybe<Scalars['String']['output']>
  frameFitting?: Maybe<Scalars['String']['output']>
  frameMaterial?: Maybe<Scalars['String']['output']>
  frameMaterialLabel?: Maybe<Scalars['String']['output']>
  frameShape?: Maybe<Array<Scalars['String']['output']>>
  gender?: Maybe<Array<Maybe<Scalars['Gender']['output']>>>
  geoFit?: Maybe<Scalars['String']['output']>
  greenShippingAvailable: Scalars['Boolean']['output']
  hingeDistance?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  image360?: Maybe<Scalars['Boolean']['output']>
  isAfa?: Maybe<Scalars['Boolean']['output']>
  isEditorial: Scalars['Boolean']['output']
  isNuanceAccessories: Scalars['Boolean']['output']
  isNuanceFrame: Scalars['Boolean']['output']
  isPrizm?: Maybe<Scalars['Boolean']['output']>
  isSupernova: Scalars['Boolean']['output']
  isTransition?: Maybe<Scalars['Boolean']['output']>
  lensColor?: Maybe<Scalars['String']['output']>
  lensColorLabel?: Maybe<Scalars['String']['output']>
  lensHeight?: Maybe<Scalars['String']['output']>
  lensMaterial?: Maybe<Scalars['String']['output']>
  lensTreatment?: Maybe<Array<Scalars['String']['output']>>
  lensTreatmentLabel?: Maybe<Array<Scalars['String']['output']>>
  moco: Scalars['String']['output']
  model: Scalars['String']['output']
  name: Scalars['String']['output']
  numberOfVariants?: Maybe<Scalars['Int']['output']>
  originalBrandCode: Scalars['String']['output']
  packaging?: Maybe<ContentV2ProductPackaging>
  polarized?: Maybe<Scalars['Boolean']['output']>
  price: ProductPrice
  prizmCategory?: Maybe<Scalars['String']['output']>
  productType?: Maybe<Scalars['String']['output']>
  product_hierarchy1?: Maybe<Scalars['String']['output']>
  progressiveFriendly?: Maybe<Scalars['Boolean']['output']>
  roxable: Scalars['Boolean']['output']
  rtr: Scalars['Boolean']['output']
  size?: Maybe<Scalars['String']['output']>
  sizeFull?: Maybe<Scalars['String']['output']>
  sizes?: Maybe<Array<Maybe<Product>>>
  sku: Scalars['String']['output']
  sports?: Maybe<Array<Scalars['String']['output']>>
  styleName: Scalars['String']['output']
  thumbnails: ProductThumbnailGroup
  transitionLens?: Maybe<TransitionLens>
  tryLenses?: Maybe<TryLenses>
  url: Scalars['URI']['output']
  variants?: Maybe<Array<Maybe<Product>>>
  vto: Scalars['Boolean']['output']
}

export type ProductAvailability = {
  __typename?: 'ProductAvailability'
  available: Scalars['Boolean']['output']
  ecommStock: Scalars['Int']['output']
  id: Scalars['ID']['output']
  stock: Scalars['Int']['output']
}

export type ProductList = {
  __typename?: 'ProductList'
  filters: Array<FacetFilter>
  items?: Maybe<Array<Product>>
  matchedRules: Array<Scalars['String']['output']>
  numRows: Scalars['Int']['output']
}

export type ProductPrice = {
  __typename?: 'ProductPrice'
  currency?: Maybe<CurrencyCode>
  current: Scalars['Float']['output']
  id: Scalars['ID']['output']
  previous?: Maybe<Scalars['Float']['output']>
}

export type ProductRelation = {
  __typename?: 'ProductRelation'
  type: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ProductThumbnail = {
  __typename?: 'ProductThumbnail'
  gallery: Array<Scalars['String']['output']>
  main: Scalars['String']['output']
}

export type ProductThumbnailGroup = {
  __typename?: 'ProductThumbnailGroup'
  shad?: Maybe<ProductThumbnail>
  standard: ProductThumbnail
}

export enum ProductType {
  Bestseller = 'BESTSELLER',
  Exclusive = 'EXCLUSIVE',
  New = 'NEW',
  Promotion = 'PROMOTION',
}

export type ProfileUserInformation = {
  __typename?: 'ProfileUserInformation'
  faceBreadth?: Maybe<Scalars['Float']['output']>
  glassesType?: Maybe<Scalars['String']['output']>
}

export type ProfileUserSearchInformation = {
  __typename?: 'ProfileUserSearchInformation'
  age?: Maybe<Scalars['String']['output']>
  darkCircles?: Maybe<Scalars['String']['output']>
  eyeColor?: Maybe<Scalars['String']['output']>
  eyeMood?: Maybe<Scalars['String']['output']>
  eyebrowsThickness?: Maybe<Scalars['String']['output']>
  faceLength?: Maybe<Scalars['String']['output']>
  faceShape?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Scalars['String']['output']>
  hairColor?: Maybe<Scalars['String']['output']>
  skinUndertones?: Maybe<Scalars['String']['output']>
  sunMood?: Maybe<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  aboutThisStyle?: Maybe<AboutThisStyle>
  barcodeProducts?: Maybe<BarcodeProducts>
  cartRecommendedAccessories?: Maybe<RecommendedAccessories>
  discountBanner?: Maybe<DiscountBanner>
  editorialBrandPLPContent?: Maybe<EditorialContentBrandPlp>
  editorialComponentList?: Maybe<EditorialComponentList>
  fitSize?: Maybe<FitSize>
  frameAdvisorColorPalettes: Scalars['JSONObject']['output']
  frameAdvisorFsaToken: FrameAdvisorFsa
  frameAdvisorFsaUserProfile: FrameAdvisorFsaUserProfile
  frameAdvisorFsaVideoId: FrameAdvisorFsaVideoId
  frameAdvisorOnboarding: FrameAdvisorOnboarding
  frameAdvisorProfilePicture: Scalars['String']['output']
  frameAdvisorSuggestions: SuggestionsResponse
  greenShipment?: Maybe<GreenShipment>
  greenShipmentInfo?: Maybe<GreenShipmentInfo>
  inspiredProducts?: Maybe<Array<Scalars['String']['output']>>
  matchesWith?: Maybe<MatchesWith>
  noResults?: Maybe<NoResults>
  nuanceTabContent?: Maybe<NuanceTabContent>
  page?: Maybe<Page>
  privacyPolicy?: Maybe<Scalars['String']['output']>
  products?: Maybe<ProductList>
  searchSuggestions?: Maybe<Array<Scalars['String']['output']>>
  sessionHandoffState: SessionHandoffState
  sessionHandoffToken: SessionHandoffToken
  store?: Maybe<Store>
  transitionExperiences: TransitionExperiences
  translations?: Maybe<Scalars['JSONObject']['output']>
}

export type QueryAboutThisStyleArgs = {
  brand: Scalars['String']['input']
  model: Scalars['String']['input']
  url: Scalars['URI']['input']
}

export type QueryBarcodeProductsArgs = {
  data: SearchData
  pictureConfig: PictureConfig
  url: Scalars['URI']['input']
}

export type QueryCartRecommendedAccessoriesArgs = {
  data: CartRecommendedAccessoriesData
  pictureConfig?: InputMaybe<PictureConfig>
  url: Scalars['URI']['input']
}

export type QueryDiscountBannerArgs = {
  url: Scalars['URI']['input']
}

export type QueryEditorialBrandPlpContentArgs = {
  brand: Scalars['String']['input']
  productSubdivision: Scalars['String']['input']
  url: Scalars['URI']['input']
}

export type QueryEditorialComponentListArgs = {
  contentId: Scalars['String']['input']
  excludeFilters?: InputMaybe<Scalars['JSONObject']['input']>
  pictureConfig?: InputMaybe<PictureConfig>
  productSubdivision: Scalars['String']['input']
  url: Scalars['URI']['input']
}

export type QueryFitSizeArgs = {
  url: Scalars['URI']['input']
}

export type QueryFrameAdvisorFsaTokenArgs = {
  url: Scalars['URI']['input']
}

export type QueryFrameAdvisorFsaUserProfileArgs = {
  userId: Scalars['String']['input']
}

export type QueryFrameAdvisorFsaVideoIdArgs = {
  token: Scalars['String']['input']
}

export type QueryFrameAdvisorOnboardingArgs = {
  url: Scalars['URI']['input']
}

export type QueryFrameAdvisorProfilePictureArgs = {
  userPictureUUID: Scalars['String']['input']
}

export type QueryFrameAdvisorSuggestionsArgs = {
  data: FaSuggestionsData
  pictureConfig?: InputMaybe<PictureConfig>
  tags?: InputMaybe<Scalars['JSONObject']['input']>
  url: Scalars['URI']['input']
}

export type QueryGreenShipmentArgs = {
  url: Scalars['URI']['input']
}

export type QueryGreenShipmentInfoArgs = {
  url: Scalars['URI']['input']
}

export type QueryInspiredProductsArgs = {
  search: Scalars['String']['input']
}

export type QueryMatchesWithArgs = {
  data: MatchesWithSuggestionsData
  pictureConfig?: InputMaybe<PictureConfig>
  url: Scalars['URI']['input']
}

export type QueryNoResultsArgs = {
  data: SearchData
  pictureConfig: PictureConfig
  url: Scalars['URI']['input']
}

export type QueryNuanceTabContentArgs = {
  id: Scalars['String']['input']
  isNuanceAccessories?: InputMaybe<Scalars['Boolean']['input']>
  isNuanceFrame?: InputMaybe<Scalars['Boolean']['input']>
  url: Scalars['URI']['input']
}

export type QueryPageArgs = {
  disabledCustomizers: Array<Scalars['CMSSDisabledCustomizers']['input']>
  excludeFilters?: InputMaybe<Scalars['JSONObject']['input']>
  isCompletePair?: InputMaybe<Scalars['Boolean']['input']>
  pictureConfig?: InputMaybe<PictureConfig>
  productSubdivision: Scalars['String']['input']
  tags?: InputMaybe<Scalars['JSONObject']['input']>
  type: PageType
  url: Scalars['URI']['input']
}

export type QueryPrivacyPolicyArgs = {
  url: Scalars['URI']['input']
}

export type QueryProductsArgs = {
  data: SearchData
  pictureConfig: PictureConfig
  url: Scalars['URI']['input']
}

export type QuerySearchSuggestionsArgs = {
  count: Scalars['Int']['input']
  filters: SuggestionsFilters
  url: Scalars['URI']['input']
}

export type QuerySessionHandoffStateArgs = {
  token: Scalars['String']['input']
}

export type QueryStoreArgs = {
  disabledCustomizers: Array<Scalars['CMSSDisabledCustomizers']['input']>
  isCompletePair?: InputMaybe<Scalars['Boolean']['input']>
  productSubdivision: Scalars['String']['input']
  url: Scalars['URI']['input']
}

export type QueryTransitionExperiencesArgs = {
  url: Scalars['URI']['input']
}

export type QueryTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>
  ns?: InputMaybe<Scalars['String']['input']>
  region?: InputMaybe<Scalars['String']['input']>
}

export type RecommendedAccessories = {
  __typename?: 'RecommendedAccessories'
  numFound: Scalars['Int']['output']
  products: Array<Product>
}

export type ResponseError = {
  __typename?: 'ResponseError'
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['Int']['output']>
  statusText?: Maybe<Scalars['String']['output']>
}

export enum RtrCtaLayout {
  OverCopyLeft = 'OVER_COPY_LEFT',
  OverCopyRight = 'OVER_COPY_RIGHT',
  OverMediaLeft = 'OVER_MEDIA_LEFT',
  OverMediaRight = 'OVER_MEDIA_RIGHT',
}

export type RtrData = {
  __typename?: 'RtrData'
  cta?: Maybe<ContentV2Cta>
  ctaLayout?: Maybe<RtrCtaLayout>
  productMOCO: Scalars['String']['output']
  rtrType: Scalars['String']['output']
  thumbnail?: Maybe<Scalars['String']['output']>
}

export type SaveUserPlaylist = {
  __typename?: 'SaveUserPlaylist'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SearchData = {
  filters: SearchFilters
  groupField?: InputMaybe<Scalars['String']['input']>
  rows: Scalars['Int']['input']
  sortField?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<SortOrder>
  splitGroupWhenOnlyOneFound?: InputMaybe<Scalars['Boolean']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type SearchFilters = {
  brand?: InputMaybe<Array<Scalars['String']['input']>>
  category?: InputMaybe<Array<Scalars['String']['input']>>
  certifications?: InputMaybe<Array<Scalars['String']['input']>>
  childAgeRange?: InputMaybe<Array<Scalars['String']['input']>>
  childAgeRanges?: InputMaybe<Array<Scalars['Int']['input']>>
  color?: InputMaybe<Array<Scalars['String']['input']>>
  core?: InputMaybe<Scalars['String']['input']>
  engravable?: InputMaybe<Scalars['Boolean']['input']>
  excludeFilters?: InputMaybe<ExcludedFilters>
  faceShape?: InputMaybe<Array<Scalars['String']['input']>>
  frameColor?: InputMaybe<Array<Scalars['String']['input']>>
  frameFitting?: InputMaybe<Array<Scalars['String']['input']>>
  frameMaterial?: InputMaybe<Array<Scalars['String']['input']>>
  frameShape?: InputMaybe<Array<Scalars['String']['input']>>
  frameType?: InputMaybe<Array<Scalars['String']['input']>>
  gender?: InputMaybe<Array<Scalars['String']['input']>>
  geoFit?: InputMaybe<Array<Scalars['String']['input']>>
  isPhotochromic?: InputMaybe<Scalars['Boolean']['input']>
  isPrizm?: InputMaybe<Scalars['Boolean']['input']>
  isSupernova?: InputMaybe<Scalars['Boolean']['input']>
  lang?: InputMaybe<Scalars['String']['input']>
  lensColor?: InputMaybe<Array<Scalars['String']['input']>>
  lensColorLabel?: InputMaybe<Array<Scalars['String']['input']>>
  lensTechnology?: InputMaybe<Array<Scalars['String']['input']>>
  lensTreatment?: InputMaybe<Array<Scalars['String']['input']>>
  lensTreatmentLabel?: InputMaybe<Array<Scalars['String']['input']>>
  maxLensHeight?: InputMaybe<Scalars['Float']['input']>
  maxPrice?: InputMaybe<Scalars['Int']['input']>
  minLensHeight?: InputMaybe<Scalars['Float']['input']>
  minPrice?: InputMaybe<Scalars['Int']['input']>
  moco?: InputMaybe<Array<Scalars['String']['input']>>
  modelFamily?: InputMaybe<Array<Scalars['String']['input']>>
  polarizedLens?: InputMaybe<Scalars['Boolean']['input']>
  priceRanges?: InputMaybe<Array<Scalars['Int']['input']>>
  prizmCategory?: InputMaybe<Array<Scalars['String']['input']>>
  productType?: InputMaybe<Array<Scalars['String']['input']>>
  product_hierarchy1?: InputMaybe<Array<Scalars['String']['input']>>
  product_hierarchy2?: InputMaybe<Array<Scalars['String']['input']>>
  product_hierarchy3?: InputMaybe<Array<Scalars['String']['input']>>
  progressiveFriendly?: InputMaybe<Scalars['Boolean']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  release?: InputMaybe<Array<Scalars['String']['input']>>
  roxable?: InputMaybe<Scalars['Boolean']['input']>
  rtr?: InputMaybe<Scalars['Boolean']['input']>
  salesCategory?: InputMaybe<Array<Scalars['String']['input']>>
  size?: InputMaybe<Array<Scalars['String']['input']>>
  sizeOrdinal?: InputMaybe<Array<Scalars['String']['input']>>
  sports?: InputMaybe<Array<Scalars['String']['input']>>
  store?: InputMaybe<Scalars['String']['input']>
  storeAvailable?: InputMaybe<Scalars['Boolean']['input']>
  technologies?: InputMaybe<Array<Scalars['String']['input']>>
  vto?: InputMaybe<Scalars['Boolean']['input']>
  weatherConditions?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SessionHandoffState = {
  __typename?: 'SessionHandoffState'
  isScanned: Scalars['String']['output']
  state: Scalars['JSONObject']['output']
}

export type SessionHandoffStateSaver = {
  __typename?: 'SessionHandoffStateSaver'
  qrcodeUrl: Scalars['String']['output']
}

export type SessionHandoffToken = {
  __typename?: 'SessionHandoffToken'
  token: Scalars['String']['output']
}

export type ShippingEstimationPeriod = {
  __typename?: 'ShippingEstimationPeriod'
  max?: Maybe<Scalars['String']['output']>
  min?: Maybe<Scalars['String']['output']>
}

export type ShippingMethod = {
  __typename?: 'ShippingMethod'
  code: Scalars['String']['output']
  destinationAvailability: DestinationAvailability
  estimations: ShippingEstimationPeriod
  fee: Scalars['Float']['output']
  translationKey: Scalars['String']['output']
  upc: Scalars['String']['output']
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type State = {
  __typename?: 'State'
  code: Scalars['String']['output']
  countryId: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Store = {
  __typename?: 'Store'
  adaWcagEnabled: Scalars['Boolean']['output']
  address: Scalars['String']['output']
  addressGeocode: Scalars['Boolean']['output']
  barcodeReaderEnabled?: Maybe<Scalars['Boolean']['output']>
  brandEditorialList: Array<Scalars['String']['output']>
  brandSegregationList: Array<Scalars['String']['output']>
  brands: Array<BrandBase>
  checkoutFields: Array<Scalars['String']['output']>
  city: Scalars['String']['output']
  contentIntegrationEnabled?: Maybe<Scalars['Boolean']['output']>
  countryId: Scalars['String']['output']
  currency: Currency
  defaultAvailableInStoreOpt: Scalars['Boolean']['output']
  defaultAvailableInStoreSun: Scalars['Boolean']['output']
  defaultLangCode: Scalars['String']['output']
  digitalCatalog: Scalars['Boolean']['output']
  disabledCustomizers: Array<Scalars['CMSSDisabledCustomizers']['output']>
  editorialContentEnabled: Scalars['Boolean']['output']
  email?: Maybe<Scalars['String']['output']>
  engravingEnabled: Scalars['Boolean']['output']
  eyeglassesEnabled?: Maybe<Scalars['Boolean']['output']>
  faceScanAppEnabled?: Maybe<Scalars['Boolean']['output']>
  frameAdvisorEnabled?: Maybe<Scalars['Boolean']['output']>
  fsaArea?: Maybe<Scalars['String']['output']>
  globalStoreId: Scalars['String']['output']
  hasNPSDisclaimer?: Maybe<Scalars['Boolean']['output']>
  hideCheckoutEmail?: Maybe<Scalars['Boolean']['output']>
  hideCheckoutOrderNumber?: Maybe<Scalars['Boolean']['output']>
  hideCheckoutPostcode?: Maybe<Scalars['Boolean']['output']>
  hideHeaderLogo?: Maybe<Scalars['Boolean']['output']>
  hideHomeDelivery?: Maybe<Scalars['Boolean']['output']>
  hideStoreDelivery?: Maybe<Scalars['Boolean']['output']>
  hpCarouselAutoplayEnabled?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  isGoToCustomBannerModal?: Maybe<Scalars['Boolean']['output']>
  langCode: Scalars['String']['output']
  languages: Array<Scalars['String']['output']>
  menu?: Maybe<Menu>
  mixMatchEnabled: Scalars['Boolean']['output']
  mixMatchPrescriptionEnabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nuanceAccessoriesEnabled: Scalars['Boolean']['output']
  nuanceEnabled: Scalars['Boolean']['output']
  ooCustomEnabled: Scalars['Boolean']['output']
  ooOcHierarchy?: Maybe<Scalars['String']['output']>
  packagingEnabled: Scalars['Boolean']['output']
  pdpRealtimeCheckEnabled: Scalars['Boolean']['output']
  plpPictureModeEnabled?: Maybe<Scalars['Boolean']['output']>
  postalCode: Scalars['String']['output']
  prescriptionEnabled?: Maybe<Scalars['Boolean']['output']>
  priceRanges: Array<PriceRanges>
  rbCustomEnabled: Scalars['Boolean']['output']
  rbOcHierarchy?: Maybe<Scalars['String']['output']>
  region: Scalars['String']['output']
  requiredCheckoutPostcode: Scalars['Boolean']['output']
  rtrEnabled: Scalars['Boolean']['output']
  shippingMethods: Array<ShippingMethod>
  solrCore: Scalars['String']['output']
  stateId?: Maybe<Scalars['String']['output']>
  states: Array<State>
  storeId: Scalars['String']['output']
  storeSegment: Scalars['String']['output']
  storeType: Scalars['String']['output']
  subdivisionSearchTabOrder: Array<Scalars['String']['output']>
  subscriptionsEnabled: Scalars['Boolean']['output']
  supernovaEnabled: Scalars['Boolean']['output']
  supernovaPurchaseEnabled: Scalars['Boolean']['output']
  telephone: Scalars['String']['output']
  url: Scalars['URI']['output']
  vtoEnabled: Scalars['Boolean']['output']
  vtoIPadEnabled: Scalars['Boolean']['output']
  vtoMonitorEnabled: Scalars['Boolean']['output']
}

export type StoreIds = {
  langCode: Scalars['String']['input']
  storeId: Scalars['String']['input']
  storeType: Scalars['String']['input']
}

export type SubItem = {
  __typename?: 'SubItem'
  bigImage: ContentV2ContentMedia
  cta?: Maybe<ContentV2Cta>
  labelImg: Scalars['String']['output']
  thumbnail: Scalars['String']['output']
}

export type SubMenu = ContentV2Brands | Menu

export type SuggestionsFilters = {
  brand?: InputMaybe<Array<Scalars['String']['input']>>
  category?: InputMaybe<Array<Scalars['String']['input']>>
  certifications?: InputMaybe<Array<Scalars['String']['input']>>
  color?: InputMaybe<Array<Scalars['String']['input']>>
  core?: InputMaybe<Scalars['String']['input']>
  engravable?: InputMaybe<Scalars['Boolean']['input']>
  excludeFilters?: InputMaybe<ExcludedFilters>
  faceShape?: InputMaybe<Array<Scalars['String']['input']>>
  frameColor?: InputMaybe<Array<Scalars['String']['input']>>
  frameFitting?: InputMaybe<Array<Scalars['String']['input']>>
  frameMaterial?: InputMaybe<Array<Scalars['String']['input']>>
  frameShape?: InputMaybe<Array<Scalars['String']['input']>>
  frameType?: InputMaybe<Array<Scalars['String']['input']>>
  gender?: InputMaybe<Array<Scalars['String']['input']>>
  geoFit?: InputMaybe<Array<Scalars['String']['input']>>
  isPrizm?: InputMaybe<Scalars['Boolean']['input']>
  lang?: InputMaybe<Scalars['String']['input']>
  lensColor?: InputMaybe<Array<Scalars['String']['input']>>
  lensColorLabel?: InputMaybe<Array<Scalars['String']['input']>>
  lensTechnology?: InputMaybe<Array<Scalars['String']['input']>>
  lensTreatment?: InputMaybe<Array<Scalars['String']['input']>>
  lensTreatmentLabel?: InputMaybe<Array<Scalars['String']['input']>>
  maxLensHeight?: InputMaybe<Scalars['Float']['input']>
  maxPrice?: InputMaybe<Scalars['Int']['input']>
  minLensHeight?: InputMaybe<Scalars['Float']['input']>
  minPrice?: InputMaybe<Scalars['Int']['input']>
  modelFamily?: InputMaybe<Array<Scalars['String']['input']>>
  optimalHinge?: InputMaybe<Scalars['Float']['input']>
  polarizedLens?: InputMaybe<Scalars['Boolean']['input']>
  priceRanges?: InputMaybe<Array<Scalars['Int']['input']>>
  prizmCategory?: InputMaybe<Array<Scalars['String']['input']>>
  productType?: InputMaybe<Array<Scalars['String']['input']>>
  product_hierarchy1?: InputMaybe<Array<Scalars['String']['input']>>
  product_hierarchy2?: InputMaybe<Array<Scalars['String']['input']>>
  product_hierarchy3?: InputMaybe<Array<Scalars['String']['input']>>
  progressiveFriendly?: InputMaybe<Scalars['Boolean']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  release?: InputMaybe<Array<Scalars['String']['input']>>
  roxable?: InputMaybe<Scalars['Boolean']['input']>
  rtr?: InputMaybe<Scalars['Boolean']['input']>
  salesCategory?: InputMaybe<Array<Scalars['String']['input']>>
  size?: InputMaybe<Array<Scalars['String']['input']>>
  sizeOrdinal?: InputMaybe<Array<Scalars['String']['input']>>
  sports?: InputMaybe<Array<Scalars['String']['input']>>
  store?: InputMaybe<Scalars['String']['input']>
  storeAvailable?: InputMaybe<Scalars['Boolean']['input']>
  technologies?: InputMaybe<Array<Scalars['String']['input']>>
  vto?: InputMaybe<Scalars['Boolean']['input']>
  weatherConditions?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SuggestionsLoveHates = {
  activationThreshold?: InputMaybe<Scalars['Float']['input']>
  dislikedBoost?: InputMaybe<Scalars['Float']['input']>
  enableFilter?: InputMaybe<Scalars['Boolean']['input']>
  likedBoost?: InputMaybe<Scalars['Float']['input']>
  preferences?: InputMaybe<Array<LoveHatesData>>
}

export type SuggestionsPlaylist = {
  deboostTags?: InputMaybe<Array<Scalars['String']['input']>>
  elevate?: InputMaybe<Array<Scalars['String']['input']>>
  exclude?: InputMaybe<Array<Scalars['String']['input']>>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SuggestionsResponse = {
  __typename?: 'SuggestionsResponse'
  filters?: Maybe<Array<FacetFilter>>
  numFound?: Maybe<Scalars['Int']['output']>
  products?: Maybe<Array<Product>>
}

export type SuggestionsSurvey = {
  featureMap?: InputMaybe<Scalars['JSONObject']['input']>
}

export type TransitionExperienceCalloutsItem = {
  __typename?: 'TransitionExperienceCalloutsItem'
  coordinates: Array<TransitionExperienceCoordinates>
  description: Scalars['String']['output']
  id?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type TransitionExperienceColorRgbsItem = {
  __typename?: 'TransitionExperienceColorRgbsItem'
  level: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type TransitionExperienceColorsItem = {
  __typename?: 'TransitionExperienceColorsItem'
  colorName: Scalars['String']['output']
  rgbs: Array<TransitionExperienceColorRgbsItem>
}

export type TransitionExperienceCoordinates = {
  __typename?: 'TransitionExperienceCoordinates'
  resourceType: Scalars['String']['output']
  value: Scalars['Int']['output']
}

export type TransitionExperienceItem = {
  __typename?: 'TransitionExperienceItem'
  background: Scalars['String']['output']
  callouts: Array<TransitionExperienceCalloutsItem>
  colors: Array<TransitionExperienceColorsItem>
  experience: Scalars['String']['output']
  foreground?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type TransitionExperiences = {
  __typename?: 'TransitionExperiences'
  value: Experiences
}

export type TransitionLens = {
  __typename?: 'TransitionLens'
  availableColors: Array<Scalars['String']['output']>
  defaultAlphaLevel: Scalars['Float']['output']
  defaultColor: Scalars['String']['output']
  defaultType: Scalars['String']['output']
  isEvolve: Scalars['Boolean']['output']
}

export type TransitionLensDataList = {
  __typename?: 'TransitionLensDataList'
  collectionTitle: Scalars['String']['output']
  items: Array<Item>
}

export type Translations = {
  __typename?: 'Translations'
  data?: Maybe<Scalars['JSONObject']['output']>
  error?: Maybe<ResponseError>
}

export type TryLenses = TryLensesAll | TryLensesOo | TryLensesRb

export type TryLensesAll = {
  __typename?: 'TryLensesAll'
  baseImage: Scalars['URI']['output']
  maskImage: Scalars['URI']['output']
}

export type TryLensesOo = {
  __typename?: 'TryLensesOO'
  bannerDetails?: Maybe<BannerDetails>
  clen: Array<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<ContentV2Image>
  link: ContentV2Link
  title?: Maybe<Scalars['String']['output']>
  video: ContentV2Video
  videoLandscape?: Maybe<ContentV2Video>
}

export type TryLensesRb = {
  __typename?: 'TryLensesRB'
  baseImage: Scalars['URI']['output']
  description?: Maybe<Scalars['String']['output']>
  label: Scalars['String']['output']
  maskImage: Scalars['URI']['output']
  swatchImage: Scalars['URI']['output']
}

export enum TryLensesType {
  TryLensesAll = 'TryLensesAll',
  TryLensesOo = 'TryLensesOO',
  TryLensesRb = 'TryLensesRB',
}

export type UpcItem = {
  prescriptionApplied: Scalars['Boolean']['input']
  upc: Scalars['String']['input']
}

export type Vm = {
  addFrameLensColor?: InputMaybe<Scalars['Boolean']['input']>
  analytics?: InputMaybe<Analytics>
  glassEnv?: InputMaybe<Scalars['String']['input']>
  products: Array<VmProduct>
  store: VmStore
  style: Scalars['String']['input']
}

export type VmBrand = {
  logoUrl: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type VmDataResponse = {
  __typename?: 'VMDataResponse'
  errors?: Maybe<Array<Maybe<ResponseError>>>
  hash: Scalars['String']['output']
}

export type VmProduct = {
  brand: VmBrand
  code: Scalars['String']['input']
  colorCode?: InputMaybe<Scalars['String']['input']>
  frameColorLabel?: InputMaybe<Scalars['String']['input']>
  lensColorLabel?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  size?: InputMaybe<Scalars['String']['input']>
  styleName?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  thumbnailUrl: Scalars['String']['input']
  upc: Scalars['String']['input']
}

export type VmStore = {
  address?: InputMaybe<Scalars['String']['input']>
  countryId?: InputMaybe<Scalars['String']['input']>
  globalStoreId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
  region?: InputMaybe<Scalars['String']['input']>
  storeId: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type Video = {
  __typename?: 'Video'
  accessibilityFallbackSrc?: Maybe<Scalars['String']['output']>
  accessibilitySrc?: Maybe<Scalars['String']['output']>
  fallbackSrc: Scalars['String']['output']
  fallbackType: Scalars['String']['output']
  id: Scalars['ID']['output']
  ipadFallbackSrc?: Maybe<Scalars['String']['output']>
  ipadSrc?: Maybe<Scalars['URI']['output']>
  src: Scalars['URI']['output']
  type: Scalars['String']['output']
}

export type EngravingConstraints = {
  __typename?: 'engravingConstraints'
  colors?: Maybe<Array<Scalars['String']['output']>>
  engGlob?: Maybe<Array<Scalars['String']['output']>>
  fonts?: Maybe<Array<Scalars['String']['output']>>
  globSides?: Maybe<Array<Scalars['String']['output']>>
  maxChars?: Maybe<Scalars['Int']['output']>
  oneEng?: Maybe<Scalars['Boolean']['output']>
  price: Scalars['Float']['output']
  sides?: Maybe<Array<Scalars['String']['output']>>
}

export enum FaceShape {
  Heart = 'heart',
  Oval = 'oval',
  Round = 'round',
  Square = 'square',
}

export type FrameAdvisorEvidences = {
  __typename?: 'frameAdvisorEvidences'
  frameColor?: Maybe<Scalars['String']['output']>
  frameColorPalette?: Maybe<Scalars['String']['output']>
  frameShape?: Maybe<Scalars['String']['output']>
  frameTempleFitting?: Maybe<Scalars['String']['output']>
  frameTempleThickness?: Maybe<Scalars['String']['output']>
  glassGender?: Maybe<Scalars['String']['output']>
  lensColorTreatment?: Maybe<Scalars['String']['output']>
}
