import { IconProps } from '@abstractTypes/icons'

export const GlassShape5Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1882 0.538C44.3432 1.014 46.6662 3.286 47.5812 6.773C48.7592 11.269 47.3422 16.459 44.2832 18.847C43.0942 19.774 41.6572 20.245 40.0872 20.245C39.1512 20.245 38.1692 20.077 37.1632 19.74C32.5612 18.196 28.1742 13.387 26.4222 8.087C25.0262 7.031 22.9722 7.032 21.5772 8.088C19.8132 13.402 15.4332 18.198 10.8382 19.74C9.83221 20.078 8.85021 20.245 7.91421 20.245C6.34421 20.245 4.90721 19.775 3.71821 18.847C0.660209 16.46 -0.757791 11.269 0.421209 6.774C1.33621 3.287 3.65921 1.015 6.79521 0.541C8.10921 0.367 9.54821 0.264 11.0012 0.257V0.25H37.0012V0.257C38.4482 0.263 39.8822 0.366 41.1882 0.538ZM10.2032 17.845C14.4902 16.406 18.5832 11.683 19.9362 6.617C20.1412 5.743 19.9792 5.034 19.4202 4.415C18.1952 3.06 15.1502 2.284 11.4182 2.25H11.0022V2.248C9.75121 2.255 8.43021 2.343 7.07721 2.521C3.93421 2.996 2.77021 5.707 2.35721 7.281C1.38521 10.989 2.52421 15.376 4.95021 17.271C6.68321 18.62 8.76221 18.328 10.2032 17.845ZM21.9984 5.60398C21.9284 4.66298 21.5644 3.80398 20.9034 3.07398C20.6274 2.76898 20.2994 2.49998 19.9414 2.25098H28.0614C27.7044 2.49998 27.3754 2.76898 27.1004 3.07398C26.4424 3.80198 26.0784 4.65898 26.0084 5.60498C25.3694 5.37698 24.6924 5.24998 24.0024 5.24998C23.3124 5.24998 22.6374 5.37698 21.9984 5.60398ZM37.8002 17.845C39.2402 18.329 41.3202 18.621 43.0532 17.271C45.4792 15.376 46.6182 10.988 45.6462 7.281C45.2332 5.707 44.0692 2.996 40.9082 2.518C39.5622 2.34 38.2482 2.256 37.0012 2.249V2.25H36.5912C32.8612 2.283 29.8102 3.059 28.5832 4.415C28.0232 5.034 27.8612 5.743 28.0732 6.646C29.4192 11.684 33.5132 16.406 37.8002 17.845Z"
        fill="#333333"
      />
    </svg>
  )
}
