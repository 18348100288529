import { IconProps } from '@abstractTypes/icons'

export const ShoppingBagIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      strokeWidth={0}
    >
      <g clipPath="url(#clip0_1874_61696)">
        <path
          d="M27.383 8.80267C27.323 8.10733 26.7517 7.58333 26.0543 7.58333H21.9997V6.25C21.9997 2.942 19.3077 0.25 15.9997 0.25C12.6917 0.25 9.99966 2.942 9.99966 6.25V7.58333H5.94499C5.24766 7.58333 4.67632 8.10733 4.61632 8.80267L2.79232 30.0793C2.74499 30.636 2.93366 31.1913 3.31166 31.6027C3.68966 32.014 4.22699 32.25 4.78566 32.25H27.2143C27.773 32.25 28.3103 32.014 28.6877 31.6027C29.0657 31.1913 29.255 30.636 29.207 30.0793L27.383 8.80267ZM11.333 6.25C11.333 3.67667 13.4263 1.58333 15.9997 1.58333C18.573 1.58333 20.6663 3.67667 20.6663 6.25V7.58333H11.333V6.25ZM5.94499 8.91667H9.99966V12.25H11.333V8.91667H20.6663V12.25H21.9997V8.91667H26.0543L27.5397 26.25H4.45966L5.94499 8.91667ZM27.705 30.7007C27.577 30.84 27.4023 30.9167 27.2137 30.9167H4.78566C4.59699 30.9167 4.42232 30.84 4.29432 30.7007C4.16699 30.5613 4.10499 30.3813 4.12166 30.1927L4.34499 27.5833H27.6543L27.8783 30.1933C27.8943 30.3813 27.833 30.5613 27.705 30.7007Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61696">
          <rect width={32} height={32} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
