import React from 'react'
import styled from 'styled-components'
import { ListProvider } from '@providers/listProvider'
import { ListProps } from './types'

const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const List: React.FC<ListProps> = ({ className, children, as = 'ul' }) => {
  return (
    <ListProvider>
      <ListContainer as={as} className={className}>
        {children}
      </ListContainer>
    </ListProvider>
  )
}
