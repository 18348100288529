import { NuanceTabContent } from '@abstractTypes/graphqlTypes'
import { NuanceCTA } from '@components/NuanceComponents/NuanceContent/styles'
import { QRCode } from '@components/QRcode'
import SSTrans from '@components/core/Trans'
import {
  NuanceHeading0,
  NuanceHeading1,
  NuanceHeading2,
  NuanceHeading3,
  NuanceHeading4,
  NuanceHeading5,
  NuanceLabel,
  NuanceListTypography,
  NuanceText,
} from '@components/core/Typography/NuanceTypography'
import { useThemeContext } from '@hooks/useThemeContext'
import React, { useCallback, useState } from 'react'
import { GlassesImagesContent } from '../GlassesImagesContent'
import { VideoModal } from '../VideoModal'
import { CmsContentStyled, HtmlTabContent } from './styles'

export interface HtmlTabProps {
  data: NuanceTabContent['data']
}

const HtmlTab: React.FC<HtmlTabProps> = ({ data }) => {
  const theme = useThemeContext()
  const [showPlayAnimation, setShowPlayAnimation] = useState(true)
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const newLineRegex = /(?<!>)\n(?!<)/g
  const htmlContent = data?.htmlContent?.replaceAll(newLineRegex, '<br>')
  const { cta, cropImages, qrcode, html, video } = data?.extraContent?.content ?? {}
  const cmsContent = html?.replace(newLineRegex, '<br/>')
  const qrCodeSize = 160

  const toggleSeeVideo = useCallback(() => {
    if (isVideoVisible) {
      setShowPlayAnimation(false)
    }
    setIsVideoVisible(currentValue => !currentValue)
  }, [isVideoVisible])

  return (
    <>
      {htmlContent && (
        <HtmlTabContent as={'div'}>
          <SSTrans
            defaults={htmlContent}
            components={{
              h0: <NuanceHeading0 />,
              h1: <NuanceHeading1 />,
              h2: <NuanceHeading2 />,
              h3: <NuanceHeading3 weight="bold" />,
              h4: <NuanceHeading4 />,
              h5: <NuanceHeading5 />,
              li: <NuanceListTypography />,
              label: <NuanceLabel />,
              p: <NuanceText />,
              button: <NuanceCTA background={theme.nuance.colors.primaryDark} />,
              span: <span />,
              div: <div />,
            }}
          />
        </HtmlTabContent>
      )}
      {cmsContent && (
        <CmsContentStyled
          dangerouslySetInnerHTML={{
            __html: cmsContent,
          }}
        />
      )}
      {cta && (
        <NuanceCTA
          background={theme.nuance.colors.primaryDark}
          navigateTo={!video ? cta.link?.url : undefined}
          onClick={toggleSeeVideo}
          dataCalls="0"
          dataAttrs={{
            id: cta?.link?.analyticsData?.dataElementId ?? undefined,
          }}
        >
          {cta.ctaText}
        </NuanceCTA>
      )}
      {cropImages && <GlassesImagesContent images={cropImages} />}
      {qrcode && <QRCode url={qrcode || ''} size={qrCodeSize} variant="square" />}
      {/* we're using isVideoVisible in the condition because we want the modal component to totally re-render after it gets closed */}
      {video && isVideoVisible && (
        <VideoModal
          showPlayAnimation={showPlayAnimation}
          setShowPlayAnimation={setShowPlayAnimation}
          videos={video}
          visible={isVideoVisible}
          onClose={toggleSeeVideo}
        />
      )}
    </>
  )
}

export default HtmlTab
