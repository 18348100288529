import { Maybe } from '@abstractTypes/graphqlTypes'
import config from '@config/index'
import { shallowEqual, useSelector } from 'react-redux'

export const DEFAULT_STORETYPE = config.shopperSlug
export const DEFAULT_STORE = config.storeID
export const DEFAULT_LANG = config.storeLang

const getWithFallback = (value: Maybe<string>, fallback: string) => {
  if (!value || value === 'undefined') return fallback
  return value
}

export const useStoreIndentity = () => {
  return useSelector(state => {
    const [, urlStoreType, urlStoreId, urlLangCode] = state.router.location.pathname.split('/')

    const storeType = getWithFallback(urlStoreType, DEFAULT_STORETYPE)
    const storeId = getWithFallback(urlStoreId, DEFAULT_STORE)
    const langCode = getWithFallback(urlLangCode, DEFAULT_LANG)

    return {
      storeType,
      storeId,
      langCode,
      basePath: `/${storeType}/${storeId}/${langCode}`,
    }
  }, shallowEqual)
}
