import { useMemo } from 'react'
import { useDeviceType } from './useDeviceType'
import { useNavigationContext } from './useNavigationContext'
import { useThemeContext } from './useThemeContext'

const NUMBER_OF_PREVIEW_ROWS = 2

export const useProductGrid = () => {
  const { isIpad, isIpadlandscape } = useDeviceType()
  const { isAlternativeNavigation, zoomLevel, landscapeNavigation } = useNavigationContext()
  const theme = useThemeContext()

  const numberOfColumns = useMemo(() => {
    const productGridColumns = theme.productGridColumns
    if (isIpad) {
      return isIpadlandscape ? productGridColumns.ipadLandscape : productGridColumns.ipad
    }

    if (zoomLevel >= 150) {
      if (isAlternativeNavigation) {
        return productGridColumns.altNavZoom
      }

      return landscapeNavigation
        ? productGridColumns.towerLandscapeZoom
        : productGridColumns.towerZoom
    }

    return landscapeNavigation ? productGridColumns.towerLandscape : productGridColumns.tower
  }, [
    theme.productGridColumns,
    isIpad,
    zoomLevel,
    landscapeNavigation,
    isIpadlandscape,
    isAlternativeNavigation,
  ])

  return { numberOfPreviewItems: numberOfColumns * NUMBER_OF_PREVIEW_ROWS }
}
