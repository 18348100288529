import { useEffect, useRef, useState } from 'react'

const useIsScrollable = <T extends Element>() => {
  const [isScrollable, setIsScrollable] = useState(false)
  const ref = useRef<T>(null)

  useEffect(() => {
    let isRunning = true

    const updateScrollable = () => {
      const el = ref.current
      // this value is correct if there is no internal content margin collapse
      const currentScrollable = el !== null && el.scrollHeight > el.clientHeight
      if (currentScrollable !== isScrollable) setIsScrollable(currentScrollable)
      if (isRunning) window.requestAnimationFrame(updateScrollable)
    }

    window.requestAnimationFrame(updateScrollable)

    return () => {
      isRunning = false
    }
  }, [isScrollable, setIsScrollable])

  return {
    ref,
    isScrollable,
  }
}

export default useIsScrollable
