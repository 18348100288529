import ConditionalRender from '@components/ConditionalRender'
import { Price } from '@components/Price/Price'
import { Heading4 } from '@components/core/Typography'
import useShowPrice from '@hooks/useShowPrice'
import React from 'react'
import { CurrentPrice, DiscountedPriceContainer, PreviousPrice } from './styles'
import { PriceProps } from './types'

export const DiscountedPrice: React.FC<PriceProps> = ({
  className,
  engraving = 0,
  current,
  quantity = 1,
  previous,
  renderCurrentAs = Heading4,
  renderPreviousAs = Heading4,
  displayCurrentPriceFirst = false,
  highlightCurrent = true,
}) => {
  const showPrice = useShowPrice()

  return (
    <DiscountedPriceContainer className={className}>
      <ConditionalRender
        condition={showPrice && previous}
        render={previous =>
          previous ? (
            <PreviousPrice as={renderPreviousAs} color="secondaryLight">
              <Price price={quantity * (previous + engraving)} />
            </PreviousPrice>
          ) : (
            <></>
          )
        }
      />

      <ConditionalRender
        condition={showPrice}
        render={() => (
          <CurrentPrice
            as={renderCurrentAs}
            weight="bold"
            isFirst={displayCurrentPriceFirst}
            color={previous !== undefined && highlightCurrent ? 'highlightError' : undefined}
          >
            <Price price={quantity * (current + engraving)} />
          </CurrentPrice>
        )}
      />
    </DiscountedPriceContainer>
  )
}
