import { FaceappConnectorPropsV2 } from '@abstractTypes/frameAdvisor'
import ConditionalRender from '@components/ConditionalRender'
import { PrimaryButton } from '@components/core/Button/index'
import { Text } from '@components/core/Typography'
import { QRCode } from '@components/QRcode'
import config from '@config/index'
import { useStoreContext } from '@hooks/useStoreContext'
import { getLangFromUrl } from '@libs/url'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeWrapper, Border, BorderContainer, Container } from './styles'
import { RenderBarcodeProps } from './types'

export const QRCodeBorder: React.FC = ({ children }) => {
  return (
    <BorderContainer>
      <Border>{children}</Border>
    </BorderContainer>
  )
}

const RenderBarcode: React.FC<RenderBarcodeProps> = ({
  postURL,
  token,
  videoIdError,
  qrCodeLabels,
  frameAdvisorFsaTokenRefetch,
  assistanceTitleLabel,
  qrCodeVariant,
  qrCodeSize = 170,
}) => (
  <BarcodeWrapper>
    <ConditionalRender
      condition={assistanceTitleLabel}
      render={assistanceTitleLabel => <Text color="primaryLight">{assistanceTitleLabel}</Text>}
    />

    {postURL && (
      <QRCodeBorder>
        <div>
          <QRCode
            url={postURL || ''}
            size={qrCodeSize}
            data-token={token}
            data-post-url={postURL}
            variant={qrCodeVariant}
          />
          {videoIdError && (
            <div>
              <Text>{qrCodeLabels.qrcodeExpired}</Text>
              <PrimaryButton onClick={() => frameAdvisorFsaTokenRefetch()}>
                {qrCodeLabels.refreshQrcode}
              </PrimaryButton>
            </div>
          )}
        </div>
      </QRCodeBorder>
    )}
  </BarcodeWrapper>
)

const FaceappConnector: React.FC<FaceappConnectorPropsV2> = ({
  videoIdError,
  frameAdvisorFsaTokenData,
  frameAdvisorFsaTokenRefetch,
  qrCodeLabels,
  qrCodeVariant,
  className,
  withAssistanceLabel = true,
  qrCodeSize,
}) => {
  const { t } = useTranslation()
  const store = useStoreContext()
  const lang = getLangFromUrl()
  const token = frameAdvisorFsaTokenData.token || ''
  const postURL = frameAdvisorFsaTokenData.postURL || ''

  const urlParams = qs.stringify({
    id: token,
    env: config.fsaEnv,
    region: store.fsaArea,
    country: store.countryId,
    language: lang,
    banner: config.vmStore, // We cannot use shopperSLug because of this issue here: https://abstractsrl.atlassian.net/browse/SS-26244
  })

  const qrcodePostUrl = `${postURL}?${urlParams}`

  return (
    <Container className={className}>
      <RenderBarcode
        postURL={qrcodePostUrl}
        token={token}
        videoIdError={videoIdError}
        qrCodeLabels={qrCodeLabels}
        frameAdvisorFsaTokenRefetch={frameAdvisorFsaTokenRefetch}
        scanCTALabel={t('faceScanApp.scanCta')}
        assistanceTitleLabel={withAssistanceLabel ? t('faceScanApp.assistance') : ''}
        qrCodeVariant={qrCodeVariant}
        qrCodeSize={qrCodeSize}
      />
    </Container>
  )
}

export default FaceappConnector
