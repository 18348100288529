import { ShippingMethod } from '@abstractTypes/graphqlTypes'
import { ShippingMethodCode } from '@abstractTypes/shipping'
import { useMemo } from 'react'
import { filterShippingMethods } from '@libs/checkout'

const shippingMethodCodesSorted = [
  ShippingMethodCode.GREEN,
  ShippingMethodCode.STANDARD,
  ShippingMethodCode.EXPRESS,
  ShippingMethodCode.EXPEDITED,
  ShippingMethodCode.MILITARY,
  ShippingMethodCode.POBOX,
]

export const useFilteredSortedShippingMethods = (
  shippingMethods: ShippingMethod[],
  shipping: string
) => {
  return useMemo(() => {
    return [...filterShippingMethods(shippingMethods, shipping)].sort((a, b) => {
      const indexA = shippingMethodCodesSorted.indexOf(a.code as ShippingMethodCode)
      const indexB = shippingMethodCodesSorted.indexOf(b.code as ShippingMethodCode)
      if (indexA === -1 && indexB === -1) return 0
      if (indexA === -1) return 1
      if (indexB === -1) return -1
      return indexA - indexB
    })
  }, [shipping, shippingMethods])
}
