import {
  AccessibilityModalSection,
  SectionTitleRow,
} from '@components/Accessibility/sections/styles'
import styled from 'styled-components'

export const ScreenReaderSectionStyle = styled(AccessibilityModalSection)`
  border: none !important;
  grid-column: 1 / 3;
  padding: 0;

  ${SectionTitleRow} {
    min-block-size: 0;
  }
`
