import { IconProps } from '@abstractTypes/icons'

export const CatIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.605 0.00292969C37.455 0.00292969 36.282 0.0939297 35.138 0.31993C31.954 0.94893 28.598 2.62493 27.007 4.82793C26.099 4.29893 25.073 3.99993 24 3.99993C22.92 3.99993 21.89 4.28693 20.985 4.81593C19.391 2.61893 16.041 0.94793 12.863 0.32093C11.719 0.0949297 10.546 0.00392969 9.39602 0.00392969C4.50202 0.00392969 0.0280151 1.65993 0.0280151 1.65993C0.0280151 10.1929 4.74401 15.9979 12.518 15.9979C20.292 15.9979 22.573 10.8409 21.885 6.82593C21.8793 6.79119 21.8697 6.75678 21.8601 6.72233L21.8601 6.7223C21.8529 6.69625 21.8456 6.67018 21.84 6.64393C22.478 6.22993 23.218 5.99993 24 5.99993C24.776 5.99993 25.523 6.22293 26.163 6.63493C26.1571 6.66215 26.1497 6.689 26.1423 6.7158L26.1423 6.71583C26.1323 6.75204 26.1223 6.78815 26.116 6.82493C25.429 10.8399 27.709 15.9969 35.483 15.9969C43.257 15.9969 47.973 10.1919 47.973 1.65893C47.973 1.65893 43.499 0.00292969 38.605 0.00292969ZM18.764 11.7249C17.511 13.2109 15.35 13.9969 12.517 13.9969C6.46702 13.9969 2.56002 9.86393 2.07802 3.10593C3.66802 2.65293 6.46001 2.00293 9.39602 2.00293C10.5 2.00293 11.536 2.09693 12.475 2.28193C16.243 3.02593 19.58 5.21493 19.913 7.16193C20.202 8.85293 19.783 10.5159 18.764 11.7249ZM29.236 11.7249C30.49 13.2109 32.65 13.9969 35.483 13.9969C41.532 13.9969 45.439 9.86493 45.922 3.10793C44.327 2.65493 41.526 2.00293 38.605 2.00293C37.501 2.00293 36.464 2.09693 35.525 2.28193C31.757 3.02593 28.42 5.21493 28.087 7.16193C27.797 8.85293 28.216 10.5159 29.236 11.7249Z"
        fill={fill}
      />
    </svg>
  )
}
