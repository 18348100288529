import { Product } from '@abstractTypes/product'
import { useSendAnalyticsEvent } from '@hooks/analyticsHooks'
import { useCart } from '@hooks/useCart'
import { useStoreContext } from '@hooks/useStoreContext'
import { EVENTS_ID, formatCartItems, formatProduct } from '@libs/analytics'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useSendMinicartOverlayOpenEvent = (isVisible: boolean) => {
  const mySelection = useSelector(state => state.frameAdvisor.mySelection)
  const engravingDetails = useSelector(state => state.engraving)
  const { items } = useCart()
  const { vtoEnabled } = useStoreContext()

  const sendAnalyticsEvent = useSendAnalyticsEvent(
    {
      id: EVENTS_ID.impression,
      Page_Section2: 'MiniCart',
      Products: formatCartItems(items, mySelection, engravingDetails.items, true),
      Vm_IsBrowserSupported: vtoEnabled ? '1' : '0',
    },
    true,
    true
  )

  useEffect(() => {
    if (isVisible) {
      sendAnalyticsEvent()
    }
  }, [isVisible, sendAnalyticsEvent])
}

export const useSendRemoveItemFromCart = () => {
  const mySelection = useSelector(state => state.frameAdvisor.mySelection)

  const sendRemoveItemFromCart = useSendAnalyticsEvent((product: Product) => ({
    id: 'Prods-Delete',
    Products: {
      // there is no cart ID or quantity information in CustomProduct type
      [product.UPC]: formatProduct({ item: product, mySelection }),
    },
  }))

  return sendRemoveItemFromCart
}
