import { isNuanceProduct } from '@libs/nuance'
import { isEyeglassProduct, isGogglesProduct } from '@libs/productSubdivision'
import React from 'react'
import { EyeglassesDetailsList } from './EyeglassesDetails'
import { GogglesDetailsList } from './GogglesDetails'
import { NuanceDetailsList } from './NuanceDetails'
import { SunglassesDetailsList } from './SunglassesDetails'
import { DetailsProps } from './types'

export const DetailsList: React.FC<DetailsProps> = props => {
  const { product } = props

  if (isEyeglassProduct(product)) {
    return <EyeglassesDetailsList {...props} />
  }
  if (isGogglesProduct(product)) {
    return <GogglesDetailsList {...props} />
  }
  if (isNuanceProduct(product)) {
    return <NuanceDetailsList {...props} />
  }

  return <SunglassesDetailsList {...props} />
}

export const Details = DetailsList
