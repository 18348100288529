import { IconProps } from '@abstractTypes/icons'

export const FaceShapeTriangleIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        d="M43.5216 6.36C43.1487 5.72396 42.6174 5.19533 41.9795 4.82557C41.3416 4.4558 40.6189 4.25751 39.8816 4.25H8.3616C7.62433 4.25751 6.90157 4.4558 6.26369 4.82557C5.6258 5.19533 5.09453 5.72396 4.7216 6.36C4.28415 7.07782 4.03711 7.8954 4.00387 8.73535C3.97062 9.5753 4.15226 10.4098 4.5316 11.16L20.2916 42.05C20.6328 42.7777 21.1742 43.3932 21.8525 43.8243C22.5308 44.2555 23.3179 44.4845 24.1216 44.4845C24.9253 44.4845 25.7124 44.2555 26.3907 43.8243C27.069 43.3932 27.6104 42.7777 27.9516 42.05L43.7116 11.14C44.0873 10.3924 44.267 9.56155 44.2338 8.72547C44.2006 7.88939 43.9555 7.07545 43.5216 6.36ZM24.1216 42.36C23.718 42.352 23.3248 42.2305 22.987 42.0094C22.6492 41.7883 22.3805 41.4767 22.2116 41.11L6.4516 10.16C6.24095 9.73755 6.13972 9.26898 6.15719 8.79724C6.17467 8.32551 6.31028 7.86571 6.5516 7.46C6.73036 7.13405 6.9917 6.86083 7.30939 6.66776C7.62707 6.47469 7.98994 6.36856 8.3616 6.36H39.8816C40.2533 6.36856 40.6161 6.47469 40.9338 6.66776C41.2515 6.86083 41.5128 7.13405 41.6916 7.46C41.9329 7.86571 42.0685 8.32551 42.086 8.79724C42.1035 9.26898 42.0022 9.73755 41.7916 10.16L26.0316 41.08C25.867 41.4523 25.6002 41.7702 25.2621 41.9968C24.924 42.2234 24.5285 42.3493 24.1216 42.36Z"
        fill={stroke}
      />
    </svg>
  )
}
