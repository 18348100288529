import { Maybe } from 'graphql/jsutils/Maybe'
import { toArray } from '@libs/utils'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const useTranslateFilterValue = () => {
  const { t } = useTranslation()

  return useCallback(
    (filter: Maybe<Array<Maybe<string>> | string>, translationKey: string): string => {
      if (!filter) {
        return ''
      }

      return toArray(filter)
        .filter(v => !!v)
        .map(v => {
          return t(`PlpFilters${translationKey}.${v?.toLowerCase()}`, filter)
        })
        .join(', ')
    },
    [t]
  )
}
