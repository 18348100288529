import { IconProps } from '@abstractTypes/icons'


export const NflIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path
        d="M47.393 5.425A6.058 6.058 0 0 0 42.575.607 36.088 36.088 0 0 0 30.07.487 35.843 35.843 0 0 0 .487 30.07a36.112 36.112 0 0 0 .12 12.505 6.058 6.058 0 0 0 4.818 4.818C7.598 47.797 9.794 48 12 48c1.975 0 3.956-.162 5.93-.486a35.843 35.843 0 0 0 29.584-29.583c.686-4.18.646-8.389-.121-12.506zm-5.185-2.852a4.048 4.048 0 0 1 3.219 3.22 33.99 33.99 0 0 1 .479 8.7L33.51 2.095C34.34 2.036 35.17 2 36 2c2.083 0 4.158.191 6.208.573zM5.792 45.427a4.048 4.048 0 0 1-3.22-3.219 33.99 33.99 0 0 1-.478-8.7L14.49 45.905a33.979 33.979 0 0 1-8.7-.478zm11.814.113c-.19.031-.381.053-.571.081L2.379 30.965c.028-.19.05-.38.08-.57A33.845 33.845 0 0 1 30.395 2.46c.19-.03.38-.054.57-.082L45.62 17.034c-.028.19-.05.381-.081.571A33.842 33.842 0 0 1 17.606 45.54z"
        fill={fill}
      />
      <path
        d="m32 14.586-2 2-2-2L26.586 16l2 2L24 22.586l-2-2L20.586 22l2 2L18 28.586l-2-2L14.586 28l2 2-2 2L16 33.414l2-2 2 2L21.414 32l-2-2L24 25.414l2 2L27.414 26l-2-2L30 19.414l2 2L33.414 20l-2-2 2-2L32 14.586z"
        fill={fill}
      />
    </svg>
  )
}
