import { Dispatch } from 'react'

export const SET_VERSION = 'version/SET_VERSION'

export interface SetVersionAction {
  type: typeof SET_VERSION
  payload: {
    version: number
  }
}

export const setSSVersion = (version: number) => (dispatch: Dispatch<SetVersionAction>) => {
  dispatch({
    type: SET_VERSION,
    payload: {
      version,
    },
  })
}

export type VersionActionTypes = SetVersionAction
