import { IconProps } from '@abstractTypes/icons'

export const InfoIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <circle cx={16} cy={16.25} r={15} stroke={stroke} strokeWidth={2} />
      <path
        d="M16.4961 22.25H14.8701V12.7402H16.4961V22.25ZM14.7383 10.2178C14.7383 9.9541 14.8174 9.73145 14.9756 9.5498C15.1396 9.36816 15.3799 9.27734 15.6963 9.27734C16.0127 9.27734 16.2529 9.36816 16.417 9.5498C16.5811 9.73145 16.6631 9.9541 16.6631 10.2178C16.6631 10.4814 16.5811 10.7012 16.417 10.877C16.2529 11.0527 16.0127 11.1406 15.6963 11.1406C15.3799 11.1406 15.1396 11.0527 14.9756 10.877C14.8174 10.7012 14.7383 10.4814 14.7383 10.2178Z"
        fill={stroke}
        strokeWidth={0}
      />
    </svg>
  )
}
