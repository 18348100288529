import { IconProps } from '@abstractTypes/icons'


export const FrameAdvisorMenuIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_37943_1857" fill={fill}>
        <path d="M1.33333 2C1.33333 1.632 1.63267 1.33333 2 1.33333H6.66667V0H2C0.897333 0 0 0.897333 0 2V6.66667H1.33333V2Z" />
        <path d="M1.33333 30V25.3333H0V30C0 31.1027 0.897333 32 2 32H6.66667V30.6667H2C1.63267 30.6667 1.33333 30.368 1.33333 30Z" />
        <path d="M30.6667 30C30.6667 30.368 30.3673 30.6667 30 30.6667H25.3333V32H30C31.1027 32 32 31.1027 32 30V25.3333H30.6667V30Z" />
        <path d="M30 0H25.3333V1.33333H30C30.3673 1.33333 30.6667 1.632 30.6667 2V6.66667H32V2C32 0.897333 31.1027 0 30 0Z" />
        <path d="M25.3287 13.3273V13.246H25.3333C25.3333 13.2267 25.3293 13.21 25.3293 13.1907L25.3333 12.1453C25.3333 6.91867 21.1467 2.66667 16 2.66667C10.8533 2.66667 6.66667 6.91867 6.66667 12.1453V13.246L6.91933 15.3333H0V16.6667H32V15.3333H25.082L25.3287 13.3273ZM16 4C19.8967 4 23.1473 6.85267 23.8533 10.6133C22.5147 9.83 20.6307 9.826 19.7913 9.826C14.448 9.826 13.376 7.50667 13.37 7.49467L12.0827 7.73867C12.0827 10.17 10.7147 11.4193 8.03267 11.4687C8.37333 7.29333 11.8133 4 16 4ZM8 13.2053V12.808C11.538 12.764 12.7947 10.9173 13.2167 9.32667C14.2593 10.194 16.222 11.16 19.792 11.16C23.4813 11.16 23.9887 12.2507 23.9993 13.2147L23.738 15.3333H8.262L8 13.2053Z" />
        <path d="M9.13333 21.126C9.01333 20.7793 8.62667 20.5993 8.28 20.726C7.94 20.846 7.76 21.226 7.88 21.5727C7.98 21.846 8.23333 22.02 8.50667 22.02C8.58 22.02 8.66 22.006 8.73333 21.9793C9.08 21.8533 9.26 21.4727 9.14 21.126C9.13333 21.126 9.13333 21.126 9.13333 21.126Z" />
        <path d="M8.08667 19.3533C8.44667 19.3067 8.70667 18.9793 8.66667 18.6133C8.62 18.2467 8.28667 17.9867 7.92 18.0333C7.55333 18.0733 7.29333 18.4067 7.34 18.7733C7.38 19.1133 7.66667 19.36 8 19.36C8.02667 19.36 8.05333 19.36 8.08667 19.3533Z" />
        <path d="M24.0733 18.0533C23.7133 18.0067 23.38 18.266 23.3333 18.6333C23.2867 19 23.5467 19.3267 23.9133 19.3727C23.94 19.3793 23.9667 19.3793 24 19.3793C24.3267 19.3793 24.6133 19.1333 24.6533 18.7927C24.7 18.4267 24.44 18.0933 24.0733 18.0533Z" />
        <path d="M9.43333 23.1733C9.16 23.42 9.13333 23.84 9.38 24.12C9.50667 24.2667 9.69333 24.34 9.87333 24.34C10.0333 24.34 10.1933 24.2867 10.32 24.18C10.5933 23.9333 10.62 23.5133 10.3733 23.2333C10.1333 22.9593 9.71333 22.9327 9.43333 23.1733Z" />
        <path d="M23.7133 20.7393C23.3667 20.6193 22.98 20.8 22.86 21.146C22.7333 21.486 22.9133 21.8727 23.26 21.9993C23.3333 22.026 23.4067 22.0393 23.4867 22.0393C23.76 22.0393 24.0133 21.866 24.1133 21.5927V21.5993C24.24 21.2467 24.06 20.8667 23.7133 20.7393Z" />
        <path d="M18.0133 27.22C18.0133 27.2127 18.0133 27.2127 18.0133 27.2127C17.74 27.4667 17.72 27.8867 17.9667 28.16C18.1 28.3 18.28 28.3727 18.46 28.3727C18.62 28.3727 18.7867 28.3193 18.9133 28.1993C19.18 27.9527 19.2 27.5327 18.9533 27.2593C18.7067 26.986 18.2867 26.9667 18.0133 27.22Z" />
        <path d="M11.2467 25.1933C10.9733 25.44 10.9533 25.8667 11.2 26.14C11.3333 26.2867 11.5133 26.36 11.6933 26.36C11.8533 26.36 12.0133 26.3 12.14 26.1867C12.4133 25.94 12.4333 25.52 12.1867 25.2467C11.94 24.9733 11.52 24.9533 11.2467 25.1933Z" />
        <path d="M21.6133 23.2533C21.6133 23.2467 21.6133 23.2467 21.6133 23.2467C21.3667 23.5267 21.3933 23.9467 21.6667 24.1933C21.7933 24.3 21.9533 24.36 22.1067 24.36C22.2933 24.36 22.48 24.28 22.6067 24.1333C22.8533 23.86 22.8267 23.4327 22.5533 23.1933C22.2733 22.9467 21.8533 22.9733 21.6133 23.2533Z" />
        <path d="M19.8 25.26C19.5533 25.5333 19.5733 25.9533 19.8467 26.2C19.9733 26.32 20.1333 26.3733 20.2933 26.3733C20.4733 26.3733 20.6533 26.3007 20.7867 26.1533C21.0333 25.88 21.0133 25.46 20.74 25.2133C20.4667 24.9667 20.0467 24.986 19.8 25.26Z" />
        <path d="M13.0333 27.2467C12.78 27.5127 12.8 27.94 13.0733 28.1867C13.2 28.3067 13.36 28.36 13.52 28.36C13.7 28.36 13.88 28.2927 14.0133 28.1467C14.26 27.8733 14.2467 27.4533 13.9733 27.2067C13.7 26.9533 13.28 26.9733 13.0333 27.2467Z" />
        <path d="M15.9933 28C15.6267 28 15.3267 28.2927 15.32 28.6667C15.32 29.0333 15.62 29.3333 15.9867 29.3333C16.36 29.3333 16.6533 29.0333 16.66 28.6667C16.66 28.3 16.36 28 15.9933 28Z" />
      </mask>
      <path
        d="M1.33333 2C1.33333 1.632 1.63267 1.33333 2 1.33333H6.66667V0H2C0.897333 0 0 0.897333 0 2V6.66667H1.33333V2Z"
        fill="#333333"
      />
      <path
        d="M1.33333 30V25.3333H0V30C0 31.1027 0.897333 32 2 32H6.66667V30.6667H2C1.63267 30.6667 1.33333 30.368 1.33333 30Z"
        fill="#333333"
      />
      <path
        d="M30.6667 30C30.6667 30.368 30.3673 30.6667 30 30.6667H25.3333V32H30C31.1027 32 32 31.1027 32 30V25.3333H30.6667V30Z"
        fill="#333333"
      />
      <path
        d="M30 0H25.3333V1.33333H30C30.3673 1.33333 30.6667 1.632 30.6667 2V6.66667H32V2C32 0.897333 31.1027 0 30 0Z"
        fill="#333333"
      />
      <path
        d="M25.3287 13.3273V13.246H25.3333C25.3333 13.2267 25.3293 13.21 25.3293 13.1907L25.3333 12.1453C25.3333 6.91867 21.1467 2.66667 16 2.66667C10.8533 2.66667 6.66667 6.91867 6.66667 12.1453V13.246L6.91933 15.3333H0V16.6667H32V15.3333H25.082L25.3287 13.3273ZM16 4C19.8967 4 23.1473 6.85267 23.8533 10.6133C22.5147 9.83 20.6307 9.826 19.7913 9.826C14.448 9.826 13.376 7.50667 13.37 7.49467L12.0827 7.73867C12.0827 10.17 10.7147 11.4193 8.03267 11.4687C8.37333 7.29333 11.8133 4 16 4ZM8 13.2053V12.808C11.538 12.764 12.7947 10.9173 13.2167 9.32667C14.2593 10.194 16.222 11.16 19.792 11.16C23.4813 11.16 23.9887 12.2507 23.9993 13.2147L23.738 15.3333H8.262L8 13.2053Z"
        fill="#333333"
      />
      <path
        d="M9.13333 21.126C9.01333 20.7793 8.62667 20.5993 8.28 20.726C7.94 20.846 7.76 21.226 7.88 21.5727C7.98 21.846 8.23333 22.02 8.50667 22.02C8.58 22.02 8.66 22.006 8.73333 21.9793C9.08 21.8533 9.26 21.4727 9.14 21.126C9.13333 21.126 9.13333 21.126 9.13333 21.126Z"
        fill="#333333"
      />
      <path
        d="M8.08667 19.3533C8.44667 19.3067 8.70667 18.9793 8.66667 18.6133C8.62 18.2467 8.28667 17.9867 7.92 18.0333C7.55333 18.0733 7.29333 18.4067 7.34 18.7733C7.38 19.1133 7.66667 19.36 8 19.36C8.02667 19.36 8.05333 19.36 8.08667 19.3533Z"
        fill="#333333"
      />
      <path
        d="M24.0733 18.0533C23.7133 18.0067 23.38 18.266 23.3333 18.6333C23.2867 19 23.5467 19.3267 23.9133 19.3727C23.94 19.3793 23.9667 19.3793 24 19.3793C24.3267 19.3793 24.6133 19.1333 24.6533 18.7927C24.7 18.4267 24.44 18.0933 24.0733 18.0533Z"
        fill="#333333"
      />
      <path
        d="M9.43333 23.1733C9.16 23.42 9.13333 23.84 9.38 24.12C9.50667 24.2667 9.69333 24.34 9.87333 24.34C10.0333 24.34 10.1933 24.2867 10.32 24.18C10.5933 23.9333 10.62 23.5133 10.3733 23.2333C10.1333 22.9593 9.71333 22.9327 9.43333 23.1733Z"
        fill="#333333"
      />
      <path
        d="M23.7133 20.7393C23.3667 20.6193 22.98 20.8 22.86 21.146C22.7333 21.486 22.9133 21.8727 23.26 21.9993C23.3333 22.026 23.4067 22.0393 23.4867 22.0393C23.76 22.0393 24.0133 21.866 24.1133 21.5927V21.5993C24.24 21.2467 24.06 20.8667 23.7133 20.7393Z"
        fill="#333333"
      />
      <path
        d="M18.0133 27.22C18.0133 27.2127 18.0133 27.2127 18.0133 27.2127C17.74 27.4667 17.72 27.8867 17.9667 28.16C18.1 28.3 18.28 28.3727 18.46 28.3727C18.62 28.3727 18.7867 28.3193 18.9133 28.1993C19.18 27.9527 19.2 27.5327 18.9533 27.2593C18.7067 26.986 18.2867 26.9667 18.0133 27.22Z"
        fill="#333333"
      />
      <path
        d="M11.2467 25.1933C10.9733 25.44 10.9533 25.8667 11.2 26.14C11.3333 26.2867 11.5133 26.36 11.6933 26.36C11.8533 26.36 12.0133 26.3 12.14 26.1867C12.4133 25.94 12.4333 25.52 12.1867 25.2467C11.94 24.9733 11.52 24.9533 11.2467 25.1933Z"
        fill="#333333"
      />
      <path
        d="M21.6133 23.2533C21.6133 23.2467 21.6133 23.2467 21.6133 23.2467C21.3667 23.5267 21.3933 23.9467 21.6667 24.1933C21.7933 24.3 21.9533 24.36 22.1067 24.36C22.2933 24.36 22.48 24.28 22.6067 24.1333C22.8533 23.86 22.8267 23.4327 22.5533 23.1933C22.2733 22.9467 21.8533 22.9733 21.6133 23.2533Z"
        fill="#333333"
      />
      <path
        d="M19.8 25.26C19.5533 25.5333 19.5733 25.9533 19.8467 26.2C19.9733 26.32 20.1333 26.3733 20.2933 26.3733C20.4733 26.3733 20.6533 26.3007 20.7867 26.1533C21.0333 25.88 21.0133 25.46 20.74 25.2133C20.4667 24.9667 20.0467 24.986 19.8 25.26Z"
        fill="#333333"
      />
      <path
        d="M13.0333 27.2467C12.78 27.5127 12.8 27.94 13.0733 28.1867C13.2 28.3067 13.36 28.36 13.52 28.36C13.7 28.36 13.88 28.2927 14.0133 28.1467C14.26 27.8733 14.2467 27.4533 13.9733 27.2067C13.7 26.9533 13.28 26.9733 13.0333 27.2467Z"
        fill="#333333"
      />
      <path
        d="M15.9933 28C15.6267 28 15.3267 28.2927 15.32 28.6667C15.32 29.0333 15.62 29.3333 15.9867 29.3333C16.36 29.3333 16.6533 29.0333 16.66 28.6667C16.66 28.3 16.36 28 15.9933 28Z"
        fill="#333333"
      />
      <path
        d="M1.33333 2C1.33333 1.632 1.63267 1.33333 2 1.33333H6.66667V0H2C0.897333 0 0 0.897333 0 2V6.66667H1.33333V2Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M1.33333 30V25.3333H0V30C0 31.1027 0.897333 32 2 32H6.66667V30.6667H2C1.63267 30.6667 1.33333 30.368 1.33333 30Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M30.6667 30C30.6667 30.368 30.3673 30.6667 30 30.6667H25.3333V32H30C31.1027 32 32 31.1027 32 30V25.3333H30.6667V30Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M30 0H25.3333V1.33333H30C30.3673 1.33333 30.6667 1.632 30.6667 2V6.66667H32V2C32 0.897333 31.1027 0 30 0Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M25.3287 13.3273V13.246H25.3333C25.3333 13.2267 25.3293 13.21 25.3293 13.1907L25.3333 12.1453C25.3333 6.91867 21.1467 2.66667 16 2.66667C10.8533 2.66667 6.66667 6.91867 6.66667 12.1453V13.246L6.91933 15.3333H0V16.6667H32V15.3333H25.082L25.3287 13.3273ZM16 4C19.8967 4 23.1473 6.85267 23.8533 10.6133C22.5147 9.83 20.6307 9.826 19.7913 9.826C14.448 9.826 13.376 7.50667 13.37 7.49467L12.0827 7.73867C12.0827 10.17 10.7147 11.4193 8.03267 11.4687C8.37333 7.29333 11.8133 4 16 4ZM8 13.2053V12.808C11.538 12.764 12.7947 10.9173 13.2167 9.32667C14.2593 10.194 16.222 11.16 19.792 11.16C23.4813 11.16 23.9887 12.2507 23.9993 13.2147L23.738 15.3333H8.262L8 13.2053Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M9.13333 21.126C9.01333 20.7793 8.62667 20.5993 8.28 20.726C7.94 20.846 7.76 21.226 7.88 21.5727C7.98 21.846 8.23333 22.02 8.50667 22.02C8.58 22.02 8.66 22.006 8.73333 21.9793C9.08 21.8533 9.26 21.4727 9.14 21.126C9.13333 21.126 9.13333 21.126 9.13333 21.126Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M8.08667 19.3533C8.44667 19.3067 8.70667 18.9793 8.66667 18.6133C8.62 18.2467 8.28667 17.9867 7.92 18.0333C7.55333 18.0733 7.29333 18.4067 7.34 18.7733C7.38 19.1133 7.66667 19.36 8 19.36C8.02667 19.36 8.05333 19.36 8.08667 19.3533Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M24.0733 18.0533C23.7133 18.0067 23.38 18.266 23.3333 18.6333C23.2867 19 23.5467 19.3267 23.9133 19.3727C23.94 19.3793 23.9667 19.3793 24 19.3793C24.3267 19.3793 24.6133 19.1333 24.6533 18.7927C24.7 18.4267 24.44 18.0933 24.0733 18.0533Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M9.43333 23.1733C9.16 23.42 9.13333 23.84 9.38 24.12C9.50667 24.2667 9.69333 24.34 9.87333 24.34C10.0333 24.34 10.1933 24.2867 10.32 24.18C10.5933 23.9333 10.62 23.5133 10.3733 23.2333C10.1333 22.9593 9.71333 22.9327 9.43333 23.1733Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M23.7133 20.7393C23.3667 20.6193 22.98 20.8 22.86 21.146C22.7333 21.486 22.9133 21.8727 23.26 21.9993C23.3333 22.026 23.4067 22.0393 23.4867 22.0393C23.76 22.0393 24.0133 21.866 24.1133 21.5927V21.5993C24.24 21.2467 24.06 20.8667 23.7133 20.7393Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M18.0133 27.22C18.0133 27.2127 18.0133 27.2127 18.0133 27.2127C17.74 27.4667 17.72 27.8867 17.9667 28.16C18.1 28.3 18.28 28.3727 18.46 28.3727C18.62 28.3727 18.7867 28.3193 18.9133 28.1993C19.18 27.9527 19.2 27.5327 18.9533 27.2593C18.7067 26.986 18.2867 26.9667 18.0133 27.22Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M11.2467 25.1933C10.9733 25.44 10.9533 25.8667 11.2 26.14C11.3333 26.2867 11.5133 26.36 11.6933 26.36C11.8533 26.36 12.0133 26.3 12.14 26.1867C12.4133 25.94 12.4333 25.52 12.1867 25.2467C11.94 24.9733 11.52 24.9533 11.2467 25.1933Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M21.6133 23.2533C21.6133 23.2467 21.6133 23.2467 21.6133 23.2467C21.3667 23.5267 21.3933 23.9467 21.6667 24.1933C21.7933 24.3 21.9533 24.36 22.1067 24.36C22.2933 24.36 22.48 24.28 22.6067 24.1333C22.8533 23.86 22.8267 23.4327 22.5533 23.1933C22.2733 22.9467 21.8533 22.9733 21.6133 23.2533Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M19.8 25.26C19.5533 25.5333 19.5733 25.9533 19.8467 26.2C19.9733 26.32 20.1333 26.3733 20.2933 26.3733C20.4733 26.3733 20.6533 26.3007 20.7867 26.1533C21.0333 25.88 21.0133 25.46 20.74 25.2133C20.4667 24.9667 20.0467 24.986 19.8 25.26Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M13.0333 27.2467C12.78 27.5127 12.8 27.94 13.0733 28.1867C13.2 28.3067 13.36 28.36 13.52 28.36C13.7 28.36 13.88 28.2927 14.0133 28.1467C14.26 27.8733 14.2467 27.4533 13.9733 27.2067C13.7 26.9533 13.28 26.9733 13.0333 27.2467Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
      <path
        d="M15.9933 28C15.6267 28 15.3267 28.2927 15.32 28.6667C15.32 29.0333 15.62 29.3333 15.9867 29.3333C16.36 29.3333 16.6533 29.0333 16.66 28.6667C16.66 28.3 16.36 28 15.9933 28Z"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-1-inside-1_37943_1857)"
      />
    </svg>
  )
}
