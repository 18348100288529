import { toggleAlternativeNavigation } from '@actions/accessibility'
import {
  AccessibilityGridHeading,
  AccessibilityModalSection,
  AccessibilitySwitcher,
  SectionContent,
  SectionIcon,
  SectionTitleRow,
  SectionTitleRowLabel,
} from '@components/Accessibility/sections/styles'
import { AdjustableNavigationIcon } from '@components/core/Icons'
import { LinkTypography, Text } from '@components/core/Typography'
import { useAction } from '@hooks/useActions'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { pxToRem } from '@libs/styled'
import { pushToDataTrack } from '@providers/analyticsProvider'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const ADASection: React.FC = () => {
  const { t } = useTranslation()
  const toggleAltNavigation = useAction(toggleAlternativeNavigation)
  const { isAlternativeNavigation, zoomLevel, landscapeNavigation } = useNavigationContext()

  const handleNavigationView = useCallback(() => {
    toggleAltNavigation()
  }, [toggleAltNavigation])

  if (landscapeNavigation) {
    return null
  }
  return (
    <AccessibilityModalSection>
      <SectionTitleRow>
        <SectionTitleRowLabel>
          <LinkTypography href="" aria-labelledby="accessibility-alternative-navigation" />
          <SectionIcon>
            <AdjustableNavigationIcon
              height={pxToRem(32, zoomLevel / 100)}
              width={pxToRem(32, zoomLevel / 100)}
            />
          </SectionIcon>
          <AccessibilityGridHeading
            weight={'bold'}
            aria-label={t('AccessibilityLabel.modalAlternativeNavigation')}
          >
            {t('AccessibilityLabel.modalAlternativeNavigation')}
          </AccessibilityGridHeading>
        </SectionTitleRowLabel>
        <AccessibilitySwitcher
          data_element_id="AdaModal_NavViewAdjust"
          data_description={isAlternativeNavigation ? 'ON' : 'OFF'}
          checked={isAlternativeNavigation}
          onChange={() => {
            handleNavigationView()
            pushToDataTrack({
              id: 'Click',
              data_element_id: 'AdaModal_NavViewAdjust',
              data_description: isAlternativeNavigation ? 'OFF' : 'ON',
            })
          }}
        />
      </SectionTitleRow>
      <SectionContent>
        <Text>{t('AccessibilityLabel.modalAlternativeNavigationSubTitle')}</Text>
      </SectionContent>
    </AccessibilityModalSection>
  )
}
