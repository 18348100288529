import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getTvEnvVariables = () => {
  const tvEnvVariables: AppConfigOverride = {
    shopperSlug: 'tv',
    shopperName: 'TEAMVISION',
    storeID: '',
    storeLang: 'en-US',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    vmStore: 'tv',
    vmStyle: 'tv',
  }

  return {
    ...tvEnvVariables,
  }
}

export default getTvEnvVariables
