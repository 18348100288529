import { Product } from '@abstractTypes/product'
import { ContentImage } from '@components/Image'
import { NukaCarousel } from '@components/NukaCarousel'
import { useIsMocoInMySelection } from '@hooks/useIsMocoInMySelection'
import { usePriceFormatter } from '@hooks/usePriceFormatter'
import { readProductCaracteristics, readProductName } from '@libs/accessibility'
import { getThumbnailUrlForCustom } from '@libs/custom'
import { useGetProductUrl } from '@libs/formatters'
import { getCaseThumbnailUrlForCustom, getProductMainImage } from '@libs/image'
import { pxToRem } from '@libs/styled'
import { dataDescriptionForProduct } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const CartImg = styled(ContentImage)`
  object-fit: contain;
`

const ImageBackground = styled.div`
  inline-size: 100%;
  block-size: 100%;
  position: relative;
  display: flex;
  align-items: center;
`

const Dots = styled.div`
  margin-block: 0;
  margin-inline: 0.4545rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  inset-block-end: ${pxToRem(10)};
  position: relative;
`

const Dot = styled.span<{ active: boolean }>`
  inline-size: 2.2727rem;
  block-size: 0.1363rem;
  margin-block: 0;
  margin-inline: 0.2rem;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`

export interface CartImageProps {
  product: Product
  className?: string
  showShadImg?: boolean
  withPDPLink?: boolean
}

const CartLink: React.FC<{ product: Product }> = ({ product, children }) => {
  const isInMySelection = useIsMocoInMySelection(product.moco)
  const analyticsDataDescription = dataDescriptionForProduct(product)
  const { t } = useTranslation()
  const formatPrice = usePriceFormatter()
  const getProductUrl = useGetProductUrl()
  const productUrl = getProductUrl(product.url)
  return (
    <Link
      to={isInMySelection ? `${productUrl}?fromFrameAdvisor=true` : productUrl}
      data-element-id="Prods_ProdLink"
      data-test="Prods_ProdLink"
      data-description={analyticsDataDescription}
      aria-label={`
        ${readProductName(product)}.
        ${' '}
        ${formatPrice(product.price.current)}.
        ${' '}
        ${readProductCaracteristics(product, t)}.
      `}
    >
      {children}
    </Link>
  )
}

export const CartImage: React.FC<CartImageProps> = ({
  product,
  className,
  showShadImg = false,
  withPDPLink = true,
}) => {
  const { customItem, recipeId, originalBrandCode, thumbnails } = product

  const productImgSrc =
    customItem && recipeId && getThumbnailUrlForCustom
      ? getThumbnailUrlForCustom(recipeId, originalBrandCode)
      : getProductMainImage(thumbnails, 500, showShadImg)

  const caseProductImgSrc = recipeId && getCaseThumbnailUrlForCustom(recipeId, originalBrandCode)

  const content =
    caseProductImgSrc && customItem ? [productImgSrc, caseProductImgSrc] : [productImgSrc]

  const Image = (
    <ImageBackground className={className}>
      {content.length > 1 ? (
        <NukaCarousel
          withoutBullets={false}
          renderBottomCenterControls={({ currentSlide }) => (
            <Dots>
              {content.map((_c, index) => (
                <Dot active={currentSlide === index} key={`dot-${index}`} />
              ))}
            </Dots>
          )}
        >
          {content.map((src, ind) => (
            <CartImg key={ind} imageSource={src} />
          ))}
        </NukaCarousel>
      ) : (
        <CartImg imageSource={content[0]} />
      )}
    </ImageBackground>
  )

  return withPDPLink ? <CartLink product={product}>{Image}</CartLink> : Image
}
