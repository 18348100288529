import { IconProps } from '@abstractTypes/icons'

export const HamburgerMenuIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      strokeWidth={0}
    >
      <g clipPath="url(#clip0_1874_61695)">
        <path d="M32 6.91699H0V8.25033H32V6.91699Z" fill={fill} />
        <path d="M32 15.584H0V16.9173H32V15.584Z" fill={fill} />
        <path d="M32 24.25H0V25.5833H32V24.25Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_1874_61695">
          <rect width={32} height={32} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
