import { ContentImage } from '@components/Image'
import { ProgressBar } from '@components/ProgressBar'
import { PrimaryButton } from '@components/core/Button'
import { landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

const sliderWrapperStyle = css`
  padding-block: 0;
  padding-inline: ${pxToRem(108)};
  display: flex;
  inline-size: 100%;
  ${tablet(`
  
  padding-inline:  ${clampValues(72, 108)};
  `)};
  ${zoomPortrait([150, 175, 200], `padding-inline:  ${pxToRem(88)};`)};
  ${zoomLandscape(
    [200],
    `
  padding-block: ${pxToRem(100)};
  padding-inline:  ${pxToRem(88)}}
  `
  )}
`

export const NavigationBtn = styled(PrimaryButton)`
  position: absolute;
  inset-block-start: ${pxToRem(52)};
  inline-size: ${pxToRem(60)};
  block-size: ${pxToRem(60)};
  z-index: 100;
  padding: ${pxToRem(20)};
  opacity: 0.9;
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(80)};
    block-size: ${pxToRem(80)};
    `
  )}
`

export const PrevBtn = styled(NavigationBtn)`
  inset-inline-start: ${pxToRem(33)};
`

export const NextBtn = styled(NavigationBtn)`
  inset-inline-end: ${pxToRem(33)};
`

export const NuancePrevBtn = styled.button`
  position: absolute;
  inset-inline-start: ${pxToRem(64)};
  inset-block-end: ${pxToRem(135)};
  z-index: 100;
`

export const NuanceNextBtn = styled.button`
  position: absolute;
  inset-inline-end: ${pxToRem(64)};
  inset-block-end: ${pxToRem(135)};
  z-index: 100;
`

export const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
`

export const MultiSlidesWrapper = styled.div<{ isCentered?: boolean; gutter?: number }>`
  ${sliderWrapperStyle};
  ${({ isCentered }) =>
    landscape(`
      justify-content: ${isCentered ? 'center' : 'flex-start'}
      block-size: 100%;
  `)};
  overflow-x: auto;
  & > * + * {
    margin-inline-start: ${({ gutter = 40 }) => pxToRem(gutter)};
  }
`

export const SingleSlideWrapper = styled.div<{ isCentered?: boolean }>`
  ${sliderWrapperStyle};
  justify-content: ${({ isCentered = true }) => (isCentered ? 'center' : 'flex-start')};
`

export const SliderWrapper = styled.div`
  inline-size: 100%;
  block-size: 100%;
  position: relative;
`

const mediaElement = css`
  min-inline-size: 100%;
  min-block-size: 100%;
  inline-size: 100%;
  block-size: 100%;
  object-fit: cover;
`

export const VideoStyled = styled.video`
  ${mediaElement};
  object-position: 0 20%;
`

export const ContentImageStyled = styled(ContentImage)`
  ${mediaElement}
`
export const ContentProgressbar = styled(ProgressBar)`
  inline-size: 50vw;
  inset-block-end: ${pxToRem(33)};
  ${landscape(`
    inline-size: 37.73vw;
  `)}
`

export const SlideContent = styled.div`
  inline-size: 100%;
  block-size: 100%;
  position: relative;
`
