import { DisclaimerProps } from '@abstractTypes/components'
import { Device } from '@abstractTypes/utils'
import { Label } from '@components/core/Typography'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

const shadow = css`
  &::before {
    content: '';
    position: absolute;
    inset-inline: 0 0;
    inset-block-start: ${pxToRem(-32)};
    display: block;
    block-size: ${pxToRem(35)};
    background-image: linear-gradient(to top, #fff, rgb(255 255 255 / 0%));
  }
`

export const DisclaimerText = styled(Label)`
  display: block;
  color: inherit;
`

export const DisclaimerWrapper = styled.footer<DisclaimerProps>`
  flex-shrink: 0;
  z-index: 0;
  position: ${({ floatingDisclaimer }) => (floatingDisclaimer ? 'absolute' : 'relative')};
  inset-inline: 0 0;
  inset-block-end: 0;
  padding-block: ${pxToRem(20)} ${pxToRem(44)};
  padding-inline: ${pxToRem(24)};
  text-align: center;
  color: ${({ theme, disclaimerColor }) => theme.colors[disclaimerColor || 'primaryDark']};
  background: ${({ theme, floatingDisclaimer }) =>
    floatingDisclaimer ? 'transparent' : theme.colors.primaryLight};
  ${tablet(`
    padding-block: ${pxToRem(20)} ${pxToRem(30)};    
    padding-inline:  ${clampValues(18, 22)} ;    
    position: fixed;
  `)};
  ${({ withShadow, floatingDisclaimer }) => withShadow && !floatingDisclaimer && shadow}
`

export const DisclaimerNPSWrapper = styled(DisclaimerWrapper)<DisclaimerProps>`
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  position: ${({ floatingDisclaimer }) => (floatingDisclaimer ? 'fixed' : 'relative')};
  background: ${({ theme }) => theme.colors.primaryLight};
  ${({ withShadow }) => withShadow && shadow}
  ${landscape(
    Device.tablet,
    `
    padding-block-end: ${clampValuesLandscape(18, 28)};
  `
  )}
    ${landscape(`
      flex-direction: row;
      ${DisclaimerText}:first-child {
        margin-inline-end: ${pxToRem(6)};
      }
      
    `)}
`
