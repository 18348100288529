import { NuanceHeading3, NuanceHeading5 } from '@components/core/Typography/NuanceTypography'
import { altNavigation, landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { generateDefaultFontProperties } from '@libs/typography'
import { Device } from 'sniffr'
import styled, { css } from 'styled-components'

export const HtmlTabContent = styled.div`
  text-align: center;

  br:first-child {
    display: none;
  }

  .”accent”,
  .accent {
    color: ${({ theme }) => theme.nuance.colors.highlight04};
  }

  ${NuanceHeading3} {
    margin-block-end: ${pxToRem(24)};

    &:nth-child(n + 2) {
      margin-block: ${pxToRem(24)} ${pxToRem(16)};
    }
  }

  ${NuanceHeading5} {
    &:nth-child(odd) {
      margin-block-end: ${pxToRem(24)};
    }
  }
`

const cmsContentDetailsStyles = css`
  & .cms-content {
    text-transform: none;
    padding-block: 0;
    padding-inline: ${pxToRem(46)};
    ${altNavigation(`
      
      padding-inline:  0 ${pxToRem(24)};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-inline: 0 ${pxToRem(24)};
      margin-block-start: ${clampValuesLandscape(27, 37)};
    `
    )}
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline: ${pxToRem(62)};
      
    `
    )}
    ${zoomPortrait(
      [200],
      `
        padding-inline:  ${pxToRem(74)};
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      padding-inline: 0 ${pxToRem(24)};
    `
    )}
  }

  & .cms-content td {
    vertical-align: top;
    text-align: start;
    padding-block-end: ${pxToRem(12)};
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.nuance.fonts.heading5,
        { color: 'primaryDark' },
        'nuance',
        '.cms-content td'
      )}
  }

  & .cms-content tr {
    :nth-last-child(2) {
      block-size: ${pxToRem(36)};
    }
    ${landscape(
      Device.tablet,
      `
      block-size: unset;
      min-block-size: ${pxToRem(26)};
      margin-block-end: ${pxToRem(14)};
      display: flex;
      :nth-last-child(2) {
        margin-block-end: 0;
      }
    `
    )}
  }

  & .cms-content .cms-leftcol {
    padding-inline-end: ${pxToRem(24)};
    min-inline-size: 50%;
    inline-size: 50%;
    max-inline-size: 50%;
    font-weight: bold;
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline-end: ${pxToRem(40)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      padding-inline-end: ${pxToRem(30)};
    `
    )}
  }

  & .cms-content .cms-leftcol span {
    display: block;
    padding-block-start: ${pxToRem(14)};
    ${landscape(
      Device.tablet,
      `
      padding-block-start: ${clampValuesLandscape(10, 14)};
    `
    )}
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.nuance.fonts.label,
        { color: 'inactive' },
        'nuance',
        '.cms-content .cms-leftcol span'
      )};
  }

  & .cms-content .cms-rightcol {
    padding-inline-start: ${pxToRem(18)};
    ${landscape(
      Device.tablet,
      `
      padding-inline-start: ${clampValuesLandscape(14, 18)};
    `
    )}
  }

  & .cms-content img {
    inline-size: ${pxToRem(142)};
    block-size: ${pxToRem(142)};
    ${tablet(`
      inline-size: ${clampValues(103, 142)};
      block-size: ${clampValues(103, 142)};
    `)}
    ${zoomPortrait(
      [150, 175],
      `
        inline-size: ${pxToRem(213)};
        block-size: ${pxToRem(213)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
        inline-size: ${pxToRem(284)};
        block-size: ${pxToRem(284)};
    `
    )}
  }

  & .cms-content br {
    display: block;
    content: ' ';
    block-size: ${pxToRem(14)};
  }

  & .cms-content .cms-footer {
    position: relative;
    text-align: justify;
    padding-block-start: ${pxToRem(24)};
    ${tablet(`
      padding-block-start: ${clampValues(16, 24)};    
    `)};
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.nuance.fonts.label,
        { color: 'inactive' },
        'nuance',
        '.cms-content .cms-footer'
      )};
  }
`

export const CmsContent = styled.div`
  ${cmsContentDetailsStyles};
`

export const CmsContentStyled = styled.div`
  & .cms-content {
    text-transform: none;
    padding-block: 0;
    padding-inline: ${pxToRem(46)};
    ${altNavigation(`
      
      padding-inline:  0 ${pxToRem(24)};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-inline: 0 ${pxToRem(24)};
      margin-block-start: ${clampValuesLandscape(27, 37)};
    `
    )}
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline: ${pxToRem(62)};
      
    `
    )}
    ${zoomPortrait(
      [200],
      `
        padding-inline:  ${pxToRem(74)};
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      padding-inline: 0 ${pxToRem(24)};
    `
    )}
  }

  & .cms-content td {
    vertical-align: top;
    text-align: start;
    padding-block-end: ${pxToRem(12)};
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.nuance.fonts.heading5,
        { color: 'primaryDark' },
        'nuance',
        '.cms-content td'
      )}
  }

  & .cms-content tr {
    :nth-last-child(2) {
      block-size: ${pxToRem(36)};
    }
    ${landscape(
      Device.tablet,
      `
      block-size: unset;
      min-block-size: ${pxToRem(26)};
      margin-block-end: ${pxToRem(14)};
      display: flex;
      :nth-last-child(2) {
        margin-block-end: 0;
      }
    `
    )}
  }

  & .cms-content .cms-leftcol {
    padding-inline-end: ${pxToRem(24)};
    min-inline-size: 50%;
    inline-size: 50%;
    max-inline-size: 50%;
    font-weight: bold;
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline-end: ${pxToRem(40)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      padding-inline-end: ${pxToRem(30)};
    `
    )}
  }

  & .cms-content .cms-leftcol span {
    display: block;
    padding-block-start: ${pxToRem(14)};
    ${landscape(
      Device.tablet,
      `
      padding-block-start: ${clampValuesLandscape(10, 14)};
    `
    )}
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.nuance.fonts.label,
        { color: 'inactive' },
        'nuance',
        '.cms-content .cms-leftcol span'
      )};
  }

  & .cms-content .cms-rightcol {
    padding-inline-start: ${pxToRem(18)};
    ${landscape(
      Device.tablet,
      `
      padding-inline-start: ${clampValuesLandscape(14, 18)};
    `
    )}
  }

  & .cms-content img {
    inline-size: ${pxToRem(142)};
    block-size: ${pxToRem(142)};
    ${tablet(`
      inline-size: ${clampValues(103, 142)};
      block-size: ${clampValues(103, 142)};
    `)}
    ${zoomPortrait(
      [150, 175],
      `
        inline-size: ${pxToRem(213)};
        block-size: ${pxToRem(213)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
        inline-size: ${pxToRem(284)};
        block-size: ${pxToRem(284)};
    `
    )}
  }

  & .cms-content br {
    display: block;
    content: ' ';
    block-size: ${pxToRem(14)};
  }

  & .cms-content .cms-footer {
    position: relative;
    text-align: justify;
    padding-block-start: ${pxToRem(24)};
    ${tablet(`
      padding-block-start: ${clampValues(16, 24)};    
    `)};
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.nuance.fonts.label,
        { color: 'inactive' },
        'nuance',
        '.cms-content .cms-footer'
      )};
  }
`
