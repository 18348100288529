import { setSSVersion } from '@actions/version'
import config from '@config/index'
import { isIPadView } from '@libs/utils'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface VersionControllerProps {
  children: React.ReactNode
}

const VersionController: React.FC<VersionControllerProps> = ({ children }) => {
  const savedVersion = useSelector(s => s.version.version)
  const appVersion = config.version
  const dispatch = useDispatch()

  if (appVersion > savedVersion && !isIPadView() && caches) {
    caches &&
      caches.keys().then(function (names) {
        for (const name of names) caches.delete(name)
      })
    navigator.serviceWorker &&
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
    dispatch(setSSVersion(appVersion))
  }

  return <>{children}</>
}

export default VersionController
