import { ShippingMethod } from '@abstractTypes/graphqlTypes'
import { Device } from '@abstractTypes/utils'
import { CheckoutRecapContainer, StickyTitleStyled } from '@components/Checkout/styles'
import ConditionalRender from '@components/ConditionalRender'
import { PrivacyPolicyModalLink } from '@components/PrivacyPolicyModalLink'
import { PrimaryButton } from '@components/core/Button'
import { Heading5 } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { cartSummaryValues } from '@libs/cart'
import { engravedSummaryValues } from '@libs/engraving'
import { altNavigationZoom, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValuesLandscape, pxToRem } from '@libs/styled'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CartSummary } from './CartSummary'
import { CheckoutRecapItems } from './CheckoutRecapItems'
import { CheckoutRecapProps } from './types'

export const checkoutSummaryTitleId = 'checkout-summary-title'

const LinkToModalStyled = styled(PrivacyPolicyModalLink)`
  margin-block-start: ${pxToRem(22)};
  ${tablet(`margin-block-start: ${pxToRem(24)};`)};
  ${landscape(Device.tablet, `margin-block-start: ${clampValuesLandscape(18, 24)};`)};
  ${zoomPortrait(
    [150, 175, 200],
    `
    grid-column: 2;
    grid-row: 2;
  `
  )}
`

const CartSummaryStyled = styled(CartSummary)`
  ${zoomPortrait(
    [150, 175, 200],
    `
    grid-column: 1;
    grid-row: 1 / span 2;
  `
  )}
  ${altNavigationZoom(
    [150],
    `
    margin-block-start: ${pxToRem(110)};
    margin-inline-start: ${pxToRem(10)};
  `
  )}
  ${altNavigationZoom(
    [175],
    `
    margin-block-start: ${pxToRem(120)};
    margin-inline-start: ${pxToRem(5)};
  `
  )}
`

const PrimaryButtonStyled = styled(PrimaryButton)`
  ${zoomPortrait(
    [150, 175, 200],
    `
    grid-column: 2;
    grid-row: 1;
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    margin-block-start: ${pxToRem(25)};
  `
  )}
`

export const CheckoutRecap = ({
  className,
  engravingDetails,
  items,
  onConfirm,
  storeData,
  dataAttrs,
  shippingType,
}: CheckoutRecapProps) => {
  const { t } = useTranslation()
  const { subtot } = cartSummaryValues(items)
  const engravingInfo = engravedSummaryValues(items, engravingDetails)
  const selectedShipment = storeData.shippingMethods.find(
    method => method.code === shippingType
  ) as ShippingMethod
  const shippingPrice = selectedShipment ? selectedShipment.fee : 0

  const { zoomLevel, isAlternativeNavigation } = useNavigationContext()

  return (
    <CheckoutRecapContainer className={className}>
      <StickyTitleStyled isSticky tabIndex={0} id={checkoutSummaryTitleId}>
        <ConditionalRender
          condition={zoomLevel < 150}
          render={() => <Heading5>{t('Checkout.summaryTitle')}</Heading5>}
        />

        <CartSummaryStyled
          columnsLayout={[15, 9]}
          subtot={subtot}
          tot={subtot + engravingInfo.price + shippingPrice}
          hideCheckOutButton
          engravingInfo={engravingInfo}
          shippingPrice={shippingPrice}
        />
        <PrimaryButtonStyled
          dataAttrs={{
            id: dataAttrs && dataAttrs.proceedBtn,
            test: 'button-confirm',
          }}
          onClick={() => onConfirm()}
          fullWidth
        >
          {t('Checkout.confirm')}
        </PrimaryButtonStyled>
        <LinkToModalStyled
          linkText="Core.privacyPolicyTitle"
          linkClickableText="Core.privacyPolicyLabel"
          isAlternativeNavigation={isAlternativeNavigation}
        />
      </StickyTitleStyled>
      <CheckoutRecapItems />
    </CheckoutRecapContainer>
  )
}
