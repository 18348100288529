import { useSendAnalyticsEvent } from '@hooks/analyticsHooks'
import { useEffect } from 'react'

export const useSendBarcodeCustomMocoAvailableAnalyticsEvent = (
  shouldSendEvent: boolean,
  isOakley: boolean
) => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: isOakley ? 'Barcode:CustomOO:NOUpc' : 'Barcode:CustomRB:NOUpc',
  })

  const sendCancelModalEvent = useSendAnalyticsEvent(() => ({
    id: 'Click',
    data_element_id: isOakley ? 'BarcodeNoResultOOCustom_Close' : 'BarcodeNoResultRBCustom_Close',
  }))

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])

  return { sendCancelModalEvent }
}
