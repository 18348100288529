import { useLazyFaProfilePictureQuery } from '@data'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useFsaProfilePicture = (pictureUrl: string) => {
  const accessToken = useSelector(state => state.frameAdvisor.fsaAccessToken)
  const userPictureUUID = useSelector(state => state.frameAdvisor.fsaInfo?.pictureUuid)
  const [fileUrl, setFileUrl] = useState<string>()

  const [loadBase64ProfileImage, { data: userProfileBase64 }] = useLazyFaProfilePictureQuery(
    userPictureUUID || ''
  )

  useEffect(() => {
    const fetchImage = async () => {
      const url = await fetch(pictureUrl, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(r => r.blob())
        .then(r => URL.createObjectURL(r))
      setFileUrl(url)
    }

    if (pictureUrl) {
      if (accessToken) {
        fetchImage()
      } else {
        loadBase64ProfileImage()
        setFileUrl(`data:image/png;base64,${userProfileBase64?.frameAdvisorProfilePicture}`)
      }
    }
  }, [pictureUrl, accessToken, userProfileBase64, loadBase64ProfileImage])
  return fileUrl
}
