import { IconProps } from '@abstractTypes/icons'
export const CaretLeftThinIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M16.431 32.0404L17.3737 31.0978L2.51166 16.2358L17.3737 1.37376L16.431 0.431091L0.626319 16.2358L16.431 32.0404Z"
        fill="#333333"
      />
    </svg>
  )
}
