import { IconProps } from '@abstractTypes/icons'
export const FrameSizeIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0.2}
    >
      <path
        d="M30.6667 0.666992V2.00033H1.33333V0.666992H0V4.66699H1.33333V3.33366H30.6667V4.66699H32V0.666992H30.6667Z"
        fill={fill}
      />
      <path
        d="M27.458 7.52598C26.5873 7.41132 25.6313 7.34265 24.6667 7.33865V7.33398H7.33333V7.33865C6.36466 7.34332 5.40533 7.41198 4.52933 7.52798C2.43866 7.84398 0.889993 9.35865 0.279993 11.6833C-0.506008 14.68 0.439326 18.1407 2.47799 19.732C3.27066 20.3507 4.22866 20.664 5.27533 20.664C5.89933 20.664 6.55399 20.5527 7.22466 20.3273C10.288 19.2993 13.208 16.102 14.384 12.5593C15.314 11.8553 16.6833 11.8547 17.614 12.5587C18.782 16.092 21.7067 19.298 24.7747 20.3273C25.4453 20.552 26.1 20.664 26.724 20.664C27.7707 20.664 28.7287 20.35 29.5213 19.732C31.56 18.14 32.5053 14.68 31.7193 11.6833C31.11 9.35865 29.5613 7.84398 27.458 7.52598ZM13.29 11.5787C12.388 14.956 9.65933 18.1046 6.80133 19.064C5.84066 19.386 4.45466 19.5807 3.29933 18.6813C1.68199 17.418 0.922659 14.4933 1.57066 12.0213C1.84599 10.972 2.62199 9.16465 4.71733 8.84798C5.61933 8.72932 6.49999 8.67065 7.33399 8.66598V8.66732H7.63399C10.1113 8.69265 12.1313 9.21065 12.946 10.1107C13.3187 10.5233 13.4267 10.996 13.29 11.5787ZM14.664 10.9033C14.6173 10.276 14.3747 9.70332 13.934 9.21665C13.75 9.01332 13.5313 8.83398 13.2927 8.66798H18.706C18.468 8.83398 18.2487 9.01332 18.0647 9.21665C17.626 9.70198 17.3833 10.2733 17.3367 10.904C16.9113 10.752 16.46 10.6673 16 10.6673C15.54 10.6673 15.09 10.752 14.664 10.9033ZM28.7013 18.6813C27.546 19.582 26.1593 19.3866 25.1993 19.064C22.3413 18.1046 19.612 14.9567 18.7147 11.598C18.5733 10.996 18.6813 10.5233 19.0547 10.1107C19.8727 9.20665 21.9067 8.68932 24.3933 8.66732H24.6667V8.66665C25.498 8.67132 26.374 8.72732 27.2713 8.84598C29.3787 9.16465 30.1547 10.972 30.43 12.0213C31.078 14.4927 30.3187 17.418 28.7013 18.6813Z"
        fill={fill}
      />
    </svg>
  )
}
