import { IconProps } from '@abstractTypes/icons'

export const LeftIcon: React.FC<IconProps> = ({ width = '1rem', height = '1rem' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 43 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M3.47163 17.6066L20.0616 1.26329L18.7793 0L0 18.5L18.7793 37L20.0616 35.7367L3.47163 19.3934L43 19.3934V17.6066L3.47163 17.6066Z"
        fill="white"
      />
    </svg>
  )
}
