import { ProTipIcon } from '@components/core/Icons'
import SSTrans from '@components/core/Trans'
import { Heading5 } from '@components/core/Typography'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TipCardTitle, TipImageWrapper } from '../styles'

const ProTipText = styled(Heading5)`
  display: inline;
`

const translationKey = 'FrameAdvisor.tips.proTip'

const iconSize = isIPadView() ? clampValues(62, 84) : pxToRem(84)

export const ProTipContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <TipCardTitle>{t(`${translationKey}.title`)}</TipCardTitle>
      <TipImageWrapper>
        <ProTipIcon width={iconSize} height={iconSize} />
      </TipImageWrapper>
      <ProTipText>
        <SSTrans
          i18nKey={`${translationKey}.text`}
          components={{
            bold: <ProTipText weight="bold" />,
          }}
        />
      </ProTipText>
    </>
  )
}
