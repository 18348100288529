import { IconProps } from '@abstractTypes/icons'
export const GreenShipmentIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 46"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M28.95 32.464L29.767 31.647C31.402 32.952 33.387 33.608 35.374 33.608C37.679 33.608 39.983 32.731 41.738 30.976C47.648 25.066 47.009 14.39 46.978 13.939C46.945 13.439 46.547 13.041 46.047 13.008C45.597 12.976 34.921 12.338 29.01 18.248C25.738 21.52 25.52 26.704 28.35 30.235L27.535 31.05C26.515 32.07 25.666 33.213 24.978 34.437C24.834 29.666 23.516 25.098 21.16 21.065C23.019 19.182 24.044 16.692 24.044 14.043C24.044 11.372 23.004 8.86102 21.115 6.97202C14.51 0.369022 2.47503 0.994022 1.96503 1.02502C1.45903 1.05402 1.05503 1.45802 1.02603 1.96402C0.996031 2.47302 0.368032 14.509 6.97403 21.114C8.86303 23.003 11.374 24.043 14.045 24.043C16.052 24.043 17.968 23.456 19.598 22.363C21.813 26.271 23 30.7 23 35.313V41.019C11.406 41.226 2.03303 44.909 1.62803 45.071L2.37103 46.928C2.47003 46.889 12.328 43 24 43C35.654 43 45.53 46.889 45.629 46.929L46.372 45.072C45.968 44.911 36.62 41.237 25.048 41.021C25.279 37.788 26.636 34.779 28.95 32.464ZM30.425 19.662C34.696 15.391 42.168 14.979 44.888 14.979C44.929 14.979 44.968 14.979 45.006 14.979C45.018 17.595 44.658 25.227 40.324 29.561C37.837 32.048 33.935 32.261 31.196 30.217L34.707 26.706L33.293 25.292L29.78 28.805C27.724 26.066 27.934 22.153 30.425 19.662ZM8.38703 19.701C3.41003 14.724 2.99903 5.86602 3.00203 3.00202C3.02403 3.00202 3.04603 3.00202 3.06803 3.00202C6.00003 3.00202 14.752 3.44002 19.7 8.38802C22.695 11.383 22.806 16.178 20.048 19.317C19.068 17.892 17.955 16.543 16.706 15.294L15.292 16.708C16.514 17.93 17.599 19.252 18.544 20.654C15.433 22.774 11.146 22.46 8.38703 19.701Z"
        fill={fill}
      />
    </svg>
  )
}
