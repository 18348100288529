import { BannerInfo, BffSlug } from '@abstractTypes/envs'

const getBffSlugByBannerInfo = (bannerInfo: BannerInfo): BffSlug => {
  const { region, deploymentEnv } = bannerInfo
  switch (deploymentEnv) {
    case 'dev': {
      switch (region) {
        case 'na':
          return 'fc-dev'
        default:
          return 'fcemea-dev'
      }
    }
    case 'test': {
      switch (region) {
        case 'na':
          return 'fc-test'
        default:
          return 'fcemea-test'
      }
    }
    case 'uat': {
      switch (region) {
        case 'na':
          return 'fc-uat'
        default:
          return 'fcemea-uat'
      }
    }
    case 'prod': {
      switch (region) {
        case 'na':
          return 'fc-us'
        default:
          return 'fcemea'
      }
    }
    case 'stage': {
      switch (region) {
        case 'na':
          return 'fc-stage'
        default:
          return 'fcemea-stage'
      }
    }
  }
}

export default getBffSlugByBannerInfo
