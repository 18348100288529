import ConditionalRender from '@components/ConditionalRender'
import { ListTypography } from '@components/core/Typography'
import { landscape } from '@libs/media'
import { pxToRem } from '@libs/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BadgeChipsProps } from './types'

const Chips = styled.ul`
  list-style: none;
  margin: ${pxToRem(-4)};
  margin-block-start: ${pxToRem(16 - 4)};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${landscape(`
    margin-block-start: ${pxToRem(24)};
    justify-content: initial;
  `)}
`

export const BadgeChip = styled(ListTypography)`
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: ${pxToRem(100)};
  padding-block: ${pxToRem(4)};
  padding-inline: ${pxToRem(16)};
  margin: ${pxToRem(4)};
`

// badges filtering and sorting is on the bff side
export const BadgeChips: React.FC<BadgeChipsProps> = ({ badges = [], className }) => {
  const { t } = useTranslation()
  return (
    <ConditionalRender
      condition={badges.length}
      render={() => (
        <Chips className={className}>
          {badges.map(badge => (
            <BadgeChip key={badge}>{t(`CardImage.badges.${badge}`)}</BadgeChip>
          ))}
        </Chips>
      )}
    />
  )
}
