import {
  defaultNavContext,
  NavigationContext,
  NavigationContextData,
} from '@providers/navigationProvider'
import { useContext } from 'react'

export const useNavigationContext = (): NavigationContextData => {
  const context = useContext(NavigationContext)
  if (context === defaultNavContext) {
    throw new Error('useNavigationContext must be used within a NavigationContextProvider')
  }
  return context
}
