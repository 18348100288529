import { IconProps } from '@abstractTypes/icons'
export const CategoryPrizmIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 60 26"
      stroke={stroke}
      className={className}
    >
      <path
        fill="#FEFEFE"
        d="M29.852.397L55.96 22.532l-3.576-1.265-10.306-3.697-8.927-7.726-1.702 5.424L60 25.457l-55.984.008 5.108-1.35 14.334-3.763h6.393v-3.64L0 25.596l27.864-24.05v6.207l-.229.505-9.95 8.863 12.166-.855V.397zm10.735 19.597l2.501 1.732-19.442-.024-9.039 2.39 37.444-.006-11.464-4.092zM26.44 4.62L5.802 22.433l13.628-4.056-5.702.4L26.44 7.454V4.62zm4.986 10.72l-.261.835.644-.045-.695.206-1.26 4.016h8.81l-7.238-5.011zm-.15-11.907v7.647l1.16-3.7L42.8 16.414l6.499 2.298L31.276 3.433z"
      />
    </svg>
  )
}
