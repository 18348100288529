import { IconProps } from '@abstractTypes/icons'

export const CaretLeftIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2189 0.835786C17.4379 0.0547377 16.1715 0.0547376 15.3905 0.835786L1.39047 14.8358C0.609421 15.6168 0.609421 16.8832 1.39047 17.6642L15.3905 31.6642C16.1715 32.4453 17.4378 32.4453 18.2189 31.6642C18.9999 30.8832 18.9999 29.6168 18.2189 28.8358L5.63311 16.25L18.2189 3.66421C18.9999 2.88316 18.9999 1.61683 18.2189 0.835786Z"
        fill={fill}
      />
    </svg>
  )
}
