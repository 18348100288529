import { IconProps } from '@abstractTypes/icons'

export const FrameAdvisorIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 57"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1688 7.7168H40.3939C41.6384 7.7168 42.551 8.71235 42.551 9.87383V45.382C42.551 46.5435 41.5554 47.539 40.3939 47.539H16.1688C15.0073 47.539 14.0117 46.5435 14.0117 45.382V9.87383C14.0117 8.71235 14.9243 7.7168 16.1688 7.7168ZM40.394 9.37602H16.1688C15.8369 9.37602 15.671 9.62491 15.671 9.8738V39.4916H40.8917V9.8738C40.8917 9.62491 40.6429 9.37602 40.394 9.37602ZM40.394 45.8798H16.1688C15.9199 45.8798 15.671 45.6309 15.671 45.382V41.1509H40.9747V45.382C40.8918 45.6309 40.7258 45.8798 40.394 45.8798ZM27.7836 42.3953H29.5258C29.9406 42.3953 30.3554 42.7271 30.3554 43.2249C30.3554 43.7227 30.0236 44.0545 29.5258 44.0545H27.7836C27.2858 44.0545 26.9539 43.7227 26.9539 43.2249C26.9539 42.7271 27.2858 42.3953 27.7836 42.3953Z"
        fill="#333333"
      />
      <path
        d="M31.3037 21.0904C28.9538 18.8005 26.1463 17.5897 22.8532 17.5358C22.6345 17.5322 22.472 17.6083 22.4106 17.8244C22.354 18.0231 22.4433 18.1693 22.6165 18.27C22.6842 18.3102 22.7573 18.309 22.832 18.3099C25.2394 18.3396 27.4451 19.0271 29.3787 20.4542C32.3745 22.6653 33.9472 25.6556 34.1061 29.3838C34.1094 29.4578 34.1094 29.5322 34.113 29.6065C34.1253 29.8669 34.279 30.0285 34.5071 30.0222C34.7351 30.0159 34.885 29.8516 34.8862 29.5915C34.8889 29.1178 34.8591 28.6445 34.7972 28.1749C34.4449 25.5098 33.3386 23.2067 31.4781 21.2655C31.4199 21.2049 31.3615 21.1483 31.3037 21.0904Z"
        fill="#333333"
        stroke="#333333"
        strokeWidth={0.666667}
      />
      <path
        d="M29.1503 23.2753C27.4385 21.6234 25.4012 20.725 23.0099 20.6443C22.8838 20.6337 22.7568 20.6496 22.6371 20.6911C22.4576 20.7609 22.3425 20.8604 22.3734 21.0699C22.3967 21.2705 22.567 21.4215 22.769 21.4205C23.6003 21.4085 24.4068 21.5533 25.1998 21.7919C28.5884 22.8117 30.9853 26.0004 31.0222 29.5383C31.0255 29.8491 31.1688 30.0259 31.4163 30.0253C31.6638 30.0247 31.8125 29.8395 31.8026 29.5125C31.7397 27.0874 30.8421 25.0174 29.1503 23.2753Z"
        fill="#333333"
        stroke="#333333"
        strokeWidth={0.666667}
      />
      <path
        d="M26.9779 25.4681C25.8418 24.371 24.4904 23.7773 22.906 23.726C22.6959 23.7191 22.5185 23.7641 22.4247 23.9676C22.2955 24.2436 22.508 24.5022 22.8628 24.507C23.7154 24.5205 24.5264 24.7177 25.2631 25.1469C26.9584 26.1337 27.8572 27.6139 27.9381 29.5793C27.9471 29.7975 28.025 29.9557 28.2399 30.0153C28.4308 30.069 28.5708 29.98 28.6708 29.82C28.7206 29.7408 28.7209 29.65 28.7197 29.5613C28.6747 27.9694 28.0808 26.6105 26.9779 25.4681Z"
        fill="#333333"
        stroke="#333333"
        strokeWidth={0.666667}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8017 28.7646C25.9576 29.1554 25.6505 29.54 25.2298 29.54H25.0474H23.723C23.3548 29.54 23.0563 29.2416 23.0563 28.8734V27.3666C23.0563 26.9459 23.441 26.6387 23.8317 26.7946C24.2246 26.9513 24.6923 27.1938 25.0474 27.5489C25.4025 27.904 25.645 28.3717 25.8017 28.7646Z"
        fill="#333333"
      />
      <ellipse cx={42.0961} cy={9.21035} rx={5.6} ry={5.6} fill="#D0021B" />
    </svg>
  )
}
