import { IconProps } from '@abstractTypes/icons'
export const FaFaceShapeHeartUngenderedIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M43.3129 15.9871C43.1209 7.99508 36.1949 2.55008 29.8409 0.873082C23.8829 -0.701918 18.5629 0.755082 15.6849 4.67008C12.5179 3.02608 9.13595 3.09908 6.27795 4.90608C1.88595 7.68308 -0.442052 13.8791 0.341948 20.6291C0.357948 20.9371 0.785949 28.1931 4.70295 31.5771C5.36495 32.1491 6.08495 32.5771 6.85995 32.8631C7.43595 35.0431 8.51995 37.0481 10.0649 38.7341L16.2349 45.4651C17.7129 47.0761 19.8139 48.0001 21.9999 48.0001C24.1859 48.0001 26.2869 47.0761 27.7649 45.4641L33.9349 38.7331C35.6989 36.8081 36.8669 34.4701 37.3549 31.9351C37.7949 31.7711 43.6359 29.3331 43.3129 15.9871ZM21.9999 46.0001C20.3729 46.0001 18.8099 45.3121 17.7099 44.1131L11.5399 37.3821C9.84795 35.5361 8.78895 33.2461 8.47695 30.7511L6.99995 19.9321V19.0001C13.8209 19.0001 16.0429 15.4061 16.7219 12.4811C18.5219 14.0801 22.1159 16.0001 28.9999 16.0001C36.0079 16.0001 36.9779 18.0881 36.9999 19.9351L35.5219 30.7611C35.2109 33.2451 34.1529 35.5351 32.4599 37.3811L26.2899 44.1121C25.1899 45.3121 23.6269 46.0001 21.9999 46.0001ZM37.7429 29.2751L38.9909 20.1351L38.9999 20.0001C38.9999 14.0001 31.4729 14.0001 28.9999 14.0001C18.9479 14.0001 16.9419 9.65908 16.9289 9.62808L14.9999 10.0001C14.9999 13.1931 13.6119 17.0001 6.99995 17.0001H5.99995L4.99995 18.0001V20.0001L6.40295 30.3401C6.27395 30.2471 6.13795 30.1731 6.01395 30.0651C2.74195 27.2421 2.34395 20.5881 2.33395 20.4601C1.63295 14.4061 3.60095 8.96408 7.34695 6.59608C9.82295 5.03208 12.7059 5.12108 15.4699 6.84808L16.8569 6.51508C19.5309 2.05908 25.0529 1.67708 29.3299 2.80708C34.9839 4.30008 41.1459 9.07708 41.3139 16.0361C41.5159 24.3941 39.1829 27.9081 37.7429 29.2751Z"
        fill={fill}
      />
      <path d="M24.0129 36.0001H19.9859V38.0001H24.0129V36.0001Z" fill={fill} />
      <path d="M22.4339 31.0001H19.9859V33.0001H24.4339V23.0571H22.4339V31.0001Z" fill={fill} />
    </svg>
  )
}
