import { useListContext } from '@hooks/useListContext'
import { ListProvider } from '@providers/listProvider'
import React from 'react'
import styled from 'styled-components'
import { ListItemProps } from './types'

export const ListItemStyle = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`

const ListItemContainer: React.FC<ListItemProps> = ({ children, className, as }) => {
  const context = useListContext()
  const component = as || (context.parentElement === 'ul' ? 'li' : 'div')

  return (
    <ListItemStyle as={component} className={className}>
      {children}
    </ListItemStyle>
  )
}

export const ListItem: React.FC<ListItemProps> = props => {
  return (
    <ListProvider>
      <ListItemContainer {...props} />
    </ListProvider>
  )
}
