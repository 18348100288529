import { ElectronicsTypes } from '@abstractTypes/electronics'
import { IconProps } from '@abstractTypes/icons'
import { RayBanMetaLogo } from '@components/core/Icons'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useThemeContext } from '@hooks/useThemeContext'
import React from 'react'
import { ElectronicsLogoStyled } from './styles'

type ElectronicsLogosMapper = Record<ElectronicsTypes, React.FC<IconProps>>

const electronicsLogosMapper: ElectronicsLogosMapper = {
  supernova: RayBanMetaLogo,
}

export const ElectronicsLogo: React.FC<{ electronicsType?: ElectronicsTypes }> = ({
  electronicsType,
}) => {
  const { isAlternativeNavigation } = useNavigationContext()
  const theme = useThemeContext()

  if (!electronicsType) return null

  const color = isAlternativeNavigation
    ? theme.electronics.colors.primaryDark
    : theme.electronics.colors.primaryLight
  const Logo = electronicsLogosMapper[electronicsType]

  return (
    <ElectronicsLogoStyled>
      <Logo fill={color} />
    </ElectronicsLogoStyled>
  )
}
