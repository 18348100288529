import { IconProps } from '@abstractTypes/icons'


export const StoreIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.6667 10.6667C31.402 10.6667 32 10.0687 32 9.33333V6.66667C32 5.93133 31.402 5.33333 30.6667 5.33333H28.8L27.002 0.838C26.7993 0.332 26.3093 0 25.764 0H6.236C5.69067 0 5.20067 0.332 4.998 0.838L3.2 5.33333H1.33333C0.598 5.33333 0 5.93133 0 6.66667V9.33333C0 10.0687 0.598 10.6667 1.33333 10.6667H2.66667V30.6667H0V32H2.66667H4H6.66667H8H13.3333H14.6667H17.3333H18.6667H24H25.3333H28H29.3333H32V30.6667H29.3333V10.6667H30.6667ZM6.236 1.33333H25.764L27.364 5.33333H4.636L6.236 1.33333ZM1.33333 9.33333V6.66667H2.66667H29.3333H30.6667L30.6673 9.33333H29.3333H2.66667H1.33333ZM8 30.6667V21.3333H13.3333V30.6667H8ZM18.6667 30.6667V21.3333H24V30.6667H18.6667ZM28 30.6667H25.3333V21.3333C25.3333 20.598 24.7353 20 24 20H18.6667C17.9313 20 17.3333 20.598 17.3333 21.3333V30.6667H14.6667V21.3333C14.6667 20.598 14.0687 20 13.3333 20H8C7.26467 20 6.66667 20.598 6.66667 21.3333V30.6667H4V10.6667H28V30.6667Z"
        fill={fill}
      />
    </svg>
  )
}
