import { Label } from '@components/core/Typography'
import { useSearchSuggestionsData } from '@data'
import { useFormatSearchSuggestionsQueryPayload } from '@hooks/formatPayloadForSearchApi/useFormatSearchSuggestionsQueryPayload'
import { arrayExclude } from '@libs/caseInsensitive'
import React from 'react'
import { SuggestionButton, SuggestionsContainer } from './styles'
import { SearchSuggestionsProps } from './types'

export const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({ updateQuery, query }) => {
  const queryPayload = useFormatSearchSuggestionsQueryPayload(query)
  const { data } = useSearchSuggestionsData(queryPayload)

  return (
    <SuggestionsContainer>
      {arrayExclude(data?.searchSuggestions as string[] | undefined, query).map(
        (suggestion, index) => (
          <SuggestionButton color="primaryDark" key={index} onClick={() => updateQuery(suggestion)}>
            <Label>{suggestion}</Label>
          </SuggestionButton>
        )
      )}
    </SuggestionsContainer>
  )
}
