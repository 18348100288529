import { IconProps } from '@abstractTypes/icons'

export const GlassShape3Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.1128 1.25C42.7558 0.631 42.0957 0.25 41.3807 0.25H32.1427C31.4277 0.25 30.7677 0.631 30.4107 1.25L28.3678 4.789C27.1378 3.803 25.6058 3.25 23.9998 3.25C22.3937 3.25 20.8617 3.803 19.6318 4.789L17.5877 1.25C17.2307 0.631 16.5697 0.25 15.8558 0.25H6.61875C5.90375 0.25 5.24375 0.631 4.88675 1.25L0.26775 9.25C-0.08925 9.869 -0.08925 10.631 0.26775 11.25L4.88675 19.25C5.24375 19.869 5.90475 20.25 6.61875 20.25H15.8567C16.5717 20.25 17.2318 19.869 17.5888 19.25L22.2078 11.25C22.5648 10.631 22.5648 9.869 22.2078 9.25L20.6478 6.548C21.5608 5.719 22.7488 5.25 23.9998 5.25C25.2498 5.25 26.4377 5.719 27.3528 6.548L25.7927 9.25C25.4357 9.869 25.4357 10.631 25.7927 11.25L30.4118 19.25C30.7688 19.869 31.4297 20.25 32.1437 20.25H41.3817C42.0967 20.25 42.7568 19.869 43.1138 19.25L47.7327 11.25C48.0887 10.631 48.0887 9.869 47.7318 9.25L43.1128 1.25ZM15.8558 18.25H6.61875L1.99975 10.25L6.61875 2.25H15.8568L20.4757 10.25L15.8558 18.25ZM32.1427 18.25H41.3808L45.9997 10.25L41.3808 2.25H32.1427L27.5238 10.25L32.1427 18.25Z"
        fill="#333333"
      />
    </svg>
  )
}
