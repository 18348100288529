import { IconProps } from '@abstractTypes/icons'


export const SelectedIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.131 21.2754L0.347656 9.49208L1.29032 8.54941L12.1317 19.3914L31.035 0.528076L31.9777 1.47208L12.131 21.2754Z"
        fill={fill}
      />
    </svg>
  )
}
