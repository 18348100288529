import { IconProps } from '@abstractTypes/icons'

export const BarcodeIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.68 4.18668H18.3467V21.4533H19.68V4.18668Z" fill={fill} />
      <path d="M13.6534 4.18668H12.3201V21.4533H13.6534V4.18668Z" fill={fill} />
      <path d="M22.6933 4.18668H21.36V21.4533H22.6933V4.18668Z" fill={fill} />
      <path d="M28.72 4.18668H27.3867V21.4533H28.72V4.18668Z" fill={fill} />
      <path d="M10.64 4.18668H9.30667V21.4533H10.64V4.18668Z" fill={fill} />
      <path
        d="M26.0867 1.33333H30.6667V5.91333C30.6667 6.27999 30.9667 6.58 31.3333 6.58C31.7067 6.58 32 6.27999 32 5.91333V0.666667C32 0.293335 31.7067 0 31.3333 0H26.0867C25.72 0 25.42 0.293335 25.42 0.666667C25.42 1.03333 25.72 1.33333 26.0867 1.33333Z"
        fill={fill}
      />
      <path d="M7.63335 4.18668H6.30002V21.4533H7.63335V4.18668Z" fill={fill} />
      <path
        d="M0.666667 6.58C1.04 6.58 1.33333 6.27999 1.33333 5.91333V1.33333H5.92001C6.28666 1.33333 6.58667 1.03333 6.58667 0.666667C6.58667 0.293335 6.28666 0 5.92001 0H0.666667C0.300008 0 0 0.293335 0 0.666667V5.91333C0 6.27999 0.300008 6.58 0.666667 6.58Z"
        fill={fill}
      />
      <path d="M25.7067 4.18668H24.3733V21.4533H25.7067V4.18668Z" fill={fill} />
      <path d="M16.6667 4.18668H15.3334V21.4533H16.6667V4.18668Z" fill={fill} />
      <path
        d="M32 20.0801C32 19.7134 31.7067 19.4134 31.3333 19.4134C30.9667 19.4134 30.6667 19.7134 30.6667 20.0801V24.6668H26.0867C25.72 24.6668 25.42 24.9601 25.42 25.3334C25.42 25.7001 25.72 26.0001 26.0867 26.0001H31.3333C31.7067 26.0001 32 25.7001 32 25.3334V20.0801Z"
        fill={fill}
      />
      <path d="M4.62002 4.18668H3.28668V21.4533H4.62002V4.18668Z" fill={fill} />
      <path
        d="M0.666667 26.0001H5.92001C6.28666 26.0001 6.58667 25.7001 6.58667 25.3334C6.58667 24.9601 6.28666 24.6668 5.92001 24.6668H1.33333V20.0801C1.33333 19.7134 1.04 19.4134 0.666667 19.4134C0.300008 19.4134 0 19.7134 0 20.0801V25.3334C0 25.7001 0.300008 26.0001 0.666667 26.0001Z"
        fill={fill}
      />
    </svg>
  )
}
