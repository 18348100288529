import { ProductTypeValues } from '@abstractTypes/filter'
import { useTranslateFilterValue } from '@hooks/useTranslateFilterValue'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DetailListContainer } from '../styles'
import DetailsRow from './DetailsRow'
import { DetailsProps } from './types'

export const GogglesDetailsList: React.FC<DetailsProps> = ({ product, className = '' }) => {
  const { t } = useTranslation()
  const translateFilterValue = useTranslateFilterValue()
  return (
    <DetailListContainer className={className}>
      <DetailsRow label={t('ProductDescription.brand')} value={product.brand.name} />
      <DetailsRow label={t('ProductDescription.name')} value={product.name} />
      <DetailsRow label={t('ProductDescription.productCode')} value={product.UPC} />
      <DetailsRow
        label={t('ProductDescription.modelColor')}
        value={`${product.model} ${product.colorCode}`}
      />

      <DetailsRow
        label={t('ProductDescription.frameShape')}
        value={translateFilterValue(product.frameShape, 'frameShape')}
      />
      <DetailsRow
        label={t('ProductDescription.frameMaterial')}
        value={translateFilterValue(product.frameMaterial, 'frameMaterial')}
      />
      <DetailsRow
        label={t('ProductDescription.frameColor')}
        value={translateFilterValue(product.frameColorLabel, 'frameColorLabel')}
      />

      <DetailsRow
        label={t('ProductDescription.lensMaterial')}
        value={translateFilterValue(product.lensMaterial, 'lensMaterial')}
      />
      {product.productType !== ProductTypeValues.OPTICAL && (
        <DetailsRow
          label={t('ProductDescription.lensColor')}
          value={translateFilterValue(product.lensColorLabel, 'lensColorLabel')}
        />
      )}
      <DetailsRow label={t('ProductDescription.size')} value={product.sizeFull || product.size} />
    </DetailListContainer>
  )
}

export const Details = GogglesDetailsList
