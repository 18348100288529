import { Device } from '@abstractTypes/utils'
import { ButtonTypography } from '@components/core/Typography'
import { landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { TogglerProps } from './types'

export const TogglerContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border: 1px solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: ${pxToRem(33)};
  ${zoomPortrait(
    [150, 175],
    `
    border-radius: ${pxToRem(50)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    border-radius: ${pxToRem(66)};
  `
  )}
`

export const TogglerButton = styled(ButtonTypography)<{ active: boolean }>`
  z-index: ${({ active }) => (active ? 1 : 0)};
  min-inline-size: ${pxToRem(190)};
  border-radius: ${pxToRem(58)};
  margin: 0;
  display: flex;
  flex-direction: column; /* row direction doesn't center text vertically in KIOSK for some reason */
  justify-content: center;
  align-items: center;
  outline: 0;
  font-size: ${pxToRem(20)};
  font-weight: 600;
  color: ${({ active, theme }) => (active ? theme.colors.primaryLight : theme.colors.primaryDark)};
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.outline : theme.colors.primaryLight};
  padding: ${pxToRem(14)};
  ${tablet(
    `
    padding: ${clampValues(10, 14)};
    font-size: ${clampValues(14, 20)};

    `
  )}
  ${landscape(
    Device.tablet,
    `
    padding: ${clampValuesLandscape(10, 14)};
    font-size: ${clampValuesLandscape(14, 20)};
    min-inline-size: ${clampValuesLandscape(130, 170)}
    `
  )}
`

export const Toggler: React.FC<TogglerProps> = ({
  onChange,
  value,
  options,
  analitycsCallNumber = 0,
  focus = false,
  className,
}) => {
  const isSelected = useCallback(
    optionValue => {
      return value === optionValue
    },
    [value]
  )

  return (
    <TogglerContainer data-analytics_available_call={analitycsCallNumber} className={className}>
      {options?.map(option => (
        <TogglerButton
          key={option.value}
          data-element-id={option?.dataElementId}
          data-test={option?.dataElementId}
          data-description={option?.dataDescription}
          onClick={() => onChange(option?.value)}
          active={isSelected(option?.value)}
          autoFocus={focus && isSelected(option?.value)}
          aria-label={option?.label}
          aria-description={option?.label}
          className={isSelected(option?.value) ? 'active' : ''}
          textSizeTablet="labelTablet"
          textSizeTabletMini="labelTabletMini"
        >
          {option?.label}
        </TogglerButton>
      ))}
    </TogglerContainer>
  )
}
