import { EngravingRadioFonts } from '@abstractTypes/engraving'
import {
  ArtelyInksFontFamily,
  GaramondItalicFontFamily,
  LatoFontFamily,
  RighteousFontFamily,
} from '@styles/fonts'

const primaryFont = 'Roboto, sans-serif'
const secondaryFont = 'Trade-Gothic, Helvetica'

export const radioFonts: EngravingRadioFonts = {
  latoRegular: {
    fontName: `${LatoFontFamily}, Helvetica`,
    fontSize: '14px',
    fontStyle: 'normal',
  },
  righteous: {
    fontName: `${RighteousFontFamily}, Helvetica`,
    fontSize: '14px',
    fontStyle: 'normal',
  },
  artelyInks: {
    fontName: `${ArtelyInksFontFamily}, Helvetica`,
    fontSize: '14px',
    fontStyle: 'normal',
  },
  garamondItalic: {
    fontName: `${GaramondItalicFontFamily}, Helvetica`,
    fontSize: '18px',
    fontStyle: 'italic',
  },
}

export const engraving = {
  radioFonts,
  colors: {
    white: '#ffffff',
    emperor: '#555555',
    mineShaft: '#333333',
    iron: '#e2e2e5',
    alto: '#d9d9d9',
    stiletto: '#a63042',
    dustyGray: '#9b9b9b',
  },
  radioColors: {
    gold: '#d8af58',
    silver: '#c0c0c0',
    rose: '#ffe4e1',
    roseGold: '#ffe4e1',
  },
  fonts: {
    primary: primaryFont,
    secondary: secondaryFont,
    input: 'Helvetica',
  },
}

export default engraving
