import { BannerInfo, CourtesySlug } from '@abstractTypes/envs'

const getCourtesySlugByBannerInfo = (bannerInfo: BannerInfo): CourtesySlug => {
  const { region, deploymentEnv } = bannerInfo
  switch (deploymentEnv) {
    case 'prod': {
      switch (region) {
        case 'na':
          return 'na-prod'
        default:
          return 'emea-prod'
      }
    }

    default: {
      switch (region) {
        case 'na':
          return 'fc-stage'
        default:
          return 'emea-test'
      }
    }
  }
}

export default getCourtesySlugByBannerInfo
