import config from '@config/index'
import { useDiscountBanner } from '@data'
import React from 'react'
import StripeBanner from '.'
import { DiscountBannerProps } from './types'

const DiscountBanner: React.FC<DiscountBannerProps> = ({
  className,
  takeSpaceWhenEmpty = false,
  icon,
  isSticky,
}) => {
  const { data } = useDiscountBanner()
  const isBannerVisible = !!(data && data.discountBanner) && config.toggleFeature.discountBanner

  return (
    <StripeBanner
      className={className}
      takeSpaceWhenEmpty={takeSpaceWhenEmpty}
      backgroundColor={data?.discountBanner?.backgroundColor}
      title={data?.discountBanner?.teaserTitle || ''}
      icon={icon}
      isVisible={isBannerVisible}
      isSticky={isSticky}
    />
  )
}

export default DiscountBanner
