import { ProductInfoConfig, ProductInfoConfigScenario } from '@abstractTypes/tiles'
import { useProductInfoConfig } from './useProductInfoConfig'

const defaultProductInfoConfigFrameAdv = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'brand',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'name',
    },
    {
      id: 'color',
    },
    {
      id: 'bestMatch',
    },
  ],
})

const monoBrandRBProductInfoConfigFrameAdv = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'color',
    },
    {
      id: 'bestMatch',
    },
  ],
})

const monoBrandOOProductInfoConfigFrameAdv = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'color',
    },
    {
      id: 'bestMatch',
    },
  ],
})
const productInfoConfigByScenarios: ProductInfoConfigScenario = {
  monobrand: {
    rb: monoBrandRBProductInfoConfigFrameAdv,
    oo: monoBrandOOProductInfoConfigFrameAdv,
  },
  multibrand: {
    default: defaultProductInfoConfigFrameAdv,
  },
  default: defaultProductInfoConfigFrameAdv,
}

export const useProductInfoConfigFrameAdv = () => {
  const { getProductInfoConfig } = useProductInfoConfig(productInfoConfigByScenarios)
  return getProductInfoConfig
}
