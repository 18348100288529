import { IconProps } from '@abstractTypes/icons'

export const PlayButton: React.FC<IconProps> = ({ width = '1rem', height = '1rem' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="#333333" />
      <g clipPath="url(#clip0_4154_6760)">
        <path
          d="M30 12C20.0753 12 12 20.0753 12 30C12 39.9248 20.0753 48 30 48C39.9248 48 48 39.9248 48 30C48 20.0753 39.9248 12 30 12ZM30 46.5C20.9018 46.5 13.5 39.0983 13.5 30C13.5 20.9018 20.9018 13.5 30 13.5C39.0983 13.5 46.5 20.9018 46.5 30C46.5 39.0983 39.0983 46.5 30 46.5Z"
          fill="white"
        />
        <path
          d="M36.9645 28.728L26.295 22.059C26.0415 21.9008 25.7692 21.8287 25.503 21.8287C24.7215 21.8287 24 22.452 24 23.331V36.6682C24 37.5472 24.7222 38.1705 25.503 38.1705C25.7685 38.1705 26.0415 38.0985 26.295 37.9403L36.9645 31.2712C37.905 30.6847 37.905 29.3153 36.9645 28.728ZM25.5 36.6682L25.506 23.3348L36.1695 30L25.5 36.6682Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4154_6760">
          <rect width="36" height="36" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  )
}
