import { IconProps } from '@abstractTypes/icons'
export const FaTempleFittingIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 48"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M6.09958 36.2102C5.94958 35.6802 5.39958 35.3702 4.86958 35.5202C4.32958 35.6702 4.01958 36.2202 4.16958 36.7502C4.29958 37.1902 4.69958 37.4802 5.13958 37.4802C5.22958 37.4802 5.30958 37.4702 5.39958 37.4502C5.93958 37.3002 6.24958 36.7502 6.09958 36.2102Z"
        fill="black"
      />
      <path
        d="M3.77919 9.69049C3.93919 9.78049 4.11919 9.83049 4.28919 9.83049C4.62919 9.83049 4.96919 9.66049 5.14919 9.34049C5.43919 8.87049 5.27919 8.26049 4.80919 7.97049C4.33919 7.69049 3.71919 7.84049 3.43919 8.32049C3.14919 8.79049 3.30919 9.40049 3.77919 9.69049Z"
        fill="black"
      />
      <path
        d="M6.14998 39.3613C5.66998 39.6313 5.48998 40.2313 5.74998 40.7213C5.92998 41.0513 6.26998 41.2413 6.62998 41.2413C6.78998 41.2413 6.94998 41.2013 7.10998 41.1213C7.58998 40.8513 7.76998 40.2513 7.50998 39.7613C7.23998 39.2813 6.63998 39.1013 6.14998 39.3613Z"
        fill="black"
      />
      <path
        d="M1.90952 17.4995C1.91952 17.4995 1.93952 17.4995 1.95952 17.4995C2.47952 17.4995 2.92952 17.0795 2.95952 16.5495C2.97952 15.9995 2.55952 15.5295 2.00952 15.4995C1.44952 15.4695 0.979519 15.8995 0.959519 16.4495C0.929519 16.9995 1.34952 17.4695 1.90952 17.4995Z"
        fill="black"
      />
      <path
        d="M2.3592 13.4811C2.4592 13.5111 2.5592 13.5211 2.6492 13.5211C3.0792 13.5211 3.4792 13.2411 3.6092 12.8111C3.7692 12.2911 3.4692 11.7311 2.9492 11.5711C2.4192 11.4011 1.8592 11.7011 1.6992 12.2311C1.5392 12.7611 1.8292 13.3211 2.3592 13.4811Z"
        fill="black"
      />
      <path
        d="M8.35985 42.7504C7.97985 43.1504 7.99985 43.7804 8.39985 44.1604C8.58985 44.3504 8.83985 44.4404 9.07985 44.4404C9.34985 44.4404 9.60985 44.3304 9.80985 44.1304C10.1898 43.7304 10.1698 43.1004 9.76985 42.7104C9.36985 42.3304 8.73985 42.3504 8.35985 42.7504Z"
        fill="black"
      />
      <path
        d="M23.6194 44.6103C23.1394 44.8703 22.9594 45.4803 23.2294 45.9603C23.4094 46.3003 23.7494 46.4803 24.0994 46.4803C24.2694 46.4803 24.4294 46.4403 24.5794 46.3603H24.5894C25.0694 46.0903 25.2494 45.4803 24.9794 45.0003C24.7094 44.5203 24.1094 44.3403 23.6194 44.6103Z"
        fill="black"
      />
      <path
        d="M28.7294 6.22091C28.9994 6.22091 29.2594 6.12091 29.4594 5.91091C29.8394 5.50091 29.8194 4.87091 29.4094 4.49091C29.0094 4.12091 28.3794 4.14091 27.9994 4.54091C27.6194 4.94091 27.6394 5.58091 28.0494 5.96091C28.2394 6.13091 28.4894 6.22091 28.7294 6.22091Z"
        fill="black"
      />
      <path
        d="M12.8399 44.8297C12.3399 44.5897 11.7399 44.7997 11.4999 45.2997C11.2599 45.7897 11.4799 46.3897 11.9699 46.6297C12.1099 46.6997 12.2599 46.7297 12.3999 46.7297C12.7799 46.7297 13.1299 46.5197 13.3099 46.1597C13.5499 45.6597 13.3399 45.0597 12.8399 44.8297Z"
        fill="black"
      />
      <path
        d="M20.1291 45.8308C19.5891 45.9208 19.2191 46.4408 19.3191 46.9808C19.3991 47.4708 19.8191 47.8108 20.2991 47.8108C20.3591 47.8108 20.4091 47.8108 20.4691 47.8008C21.0191 47.7008 21.3791 47.1808 21.2891 46.6408C21.1891 46.1008 20.6691 45.7308 20.1291 45.8308Z"
        fill="black"
      />
      <path
        d="M16.3999 45.8911C15.8499 45.8211 15.35 46.2011 15.28 46.7511C15.2 47.3011 15.5899 47.8011 16.1299 47.8811C16.1799 47.8811 16.22 47.8811 16.27 47.8811C16.76 47.8811 17.1899 47.5211 17.2599 47.0211C17.3299 46.4711 16.9499 45.9711 16.3999 45.8911Z"
        fill="black"
      />
      <path
        d="M32.0696 36.2099C32.2096 35.6799 31.8896 35.1299 31.3496 34.9899C30.8196 34.8499 30.2696 35.1699 30.1296 35.6999C29.9896 36.2299 30.3096 36.7799 30.8396 36.9199C30.9296 36.9399 31.0096 36.9599 31.0996 36.9599C31.5396 36.9599 31.9496 36.6599 32.0696 36.2099Z"
        fill="black"
      />
      <path
        d="M21.4494 2.39963C21.5294 2.40963 21.6094 2.41963 21.6794 2.41963C22.1394 2.41963 22.5494 2.10963 22.6594 1.64963C22.7794 1.11963 22.4494 0.579634 21.9094 0.449634C21.3794 0.319634 20.8394 0.659634 20.7094 1.18963C20.5794 1.72963 20.9194 2.26963 21.4494 2.39963Z"
        fill="black"
      />
      <path
        d="M13.6697 2.5703C13.7597 2.5703 13.8497 2.5503 13.9397 2.5303C14.4797 2.3803 14.7897 1.8303 14.6397 1.2903C14.4797 0.760298 13.9297 0.450298 13.3997 0.600298C12.8697 0.750298 12.5597 1.3103 12.7097 1.8403C12.8397 2.2803 13.2397 2.5703 13.6697 2.5703Z"
        fill="black"
      />
      <path
        d="M24.9797 3.74015C25.1297 3.82015 25.2897 3.86015 25.4497 3.86015C25.8097 3.86015 26.1497 3.67015 26.3297 3.34015C26.5997 2.85015 26.4197 2.25015 25.9297 1.98015C25.4497 1.72015 24.8397 1.90015 24.5797 2.39015C24.3097 2.87015 24.4897 3.48015 24.9797 3.74015Z"
        fill="black"
      />
      <path
        d="M17.6691 1.9904H17.6791C18.2291 1.9804 18.6791 1.5204 18.6691 0.970402C18.6591 0.420402 18.2091 -0.0195985 17.6491 -0.00959849C17.0991 -0.00959849 16.6591 0.450402 16.6691 1.0004C16.6791 1.5504 17.1191 1.9904 17.6691 1.9904Z"
        fill="black"
      />
      <path
        d="M32.9398 16.0004C32.9798 16.5204 33.4198 16.9104 33.9298 16.9104C33.9598 16.9104 33.9898 16.9104 34.0198 16.9104C34.5698 16.8604 34.9798 16.3704 34.9298 15.8204C34.8798 15.2704 34.3898 14.8604 33.8398 14.9104C33.2898 14.9604 32.8898 15.4504 32.9398 16.0004Z"
        fill="black"
      />
      <path
        d="M32.1396 12.2897C32.2796 12.6997 32.6696 12.9597 33.0896 12.9597C33.1896 12.9597 33.2996 12.9397 33.4096 12.8997C33.9296 12.7197 34.2096 12.1597 34.0296 11.6297C33.8496 11.1097 33.2796 10.8297 32.7596 11.0097C32.2396 11.1897 31.9596 11.7597 32.1396 12.2897Z"
        fill="black"
      />
      <path
        d="M31.3194 9.32028C31.5094 9.32028 31.6894 9.27028 31.8594 9.17028C32.3294 8.86028 32.4594 8.25028 32.1594 7.78028C31.8594 7.32028 31.2394 7.19028 30.7794 7.48028C30.3094 7.78028 30.1794 8.40028 30.4794 8.87028C30.6694 9.16028 30.9894 9.32028 31.3194 9.32028Z"
        fill="black"
      />
      <path
        d="M9.95909 4.16056C10.1391 4.16056 10.3091 4.11056 10.4691 4.02056C10.9491 3.74056 11.0991 3.13056 10.8191 2.65056C10.5391 2.17056 9.92909 2.02056 9.44909 2.30056C8.97909 2.58056 8.81909 3.19056 9.09909 3.67056C9.28909 3.99056 9.61909 4.16056 9.95909 4.16056Z"
        fill="black"
      />
      <path
        d="M6.77984 6.64025C7.02984 6.64025 7.28984 6.55025 7.48984 6.35025C7.86984 5.96025 7.86984 5.33025 7.47984 4.93025C7.08984 4.55025 6.45984 4.55025 6.06984 4.94025C5.67984 5.33025 5.67984 5.96025 6.06984 6.35025C6.26984 6.55025 6.51984 6.64025 6.77984 6.64025Z"
        fill="black"
      />
      <path
        d="M1.93966 21.586C1.11866 24.691 2.11566 28.304 4.25866 29.991L4.26265 29.994C5.11665 30.66 6.14365 30.997 7.26265 30.997C7.92265 30.997 8.61366 30.88 9.32066 30.643C12.7247 29.5 15.8377 25.95 16.8977 21.979C17.1637 20.849 16.9137 19.819 16.1767 19H19.8237C19.3097 19.572 19.0277 20.251 18.9997 21V31H15.9857V33H20.9997V26.057C22.4737 28.212 24.5147 29.916 26.6797 30.643C27.3867 30.88 28.0777 30.997 28.7377 30.997C29.8567 30.997 30.8837 30.659 31.7377 29.994L31.7417 29.991C33.8847 28.304 34.8817 24.691 34.0607 21.586C33.6387 19.988 32.7847 18.761 31.6227 18C31.0017 17.593 30.2967 17.313 29.5137 17.195C28.6267 17.078 27.6527 17.009 26.6677 17.007V17H9.33266V17.007C8.34266 17.01 7.36266 17.079 6.46766 17.197C5.69166 17.314 4.99066 17.594 4.37266 18C3.21666 18.76 2.36166 19.988 1.93966 21.586ZM30.5047 28.418C29.4657 29.225 28.1967 29.043 27.3157 28.747C24.5557 27.821 21.9177 24.769 21.0497 21.521C20.9337 21.028 21.0147 20.663 21.3117 20.333C22.0717 19.493 24.0847 19.01 26.5097 19H26.6457C27.4727 19.003 28.3447 19.058 29.2317 19.175C31.1547 19.466 31.8707 21.131 32.1257 22.097C32.7447 24.438 32.0327 27.214 30.5047 28.418ZM6.74865 19.177C7.63465 19.06 8.50666 19.003 9.33266 18.999H9.38466C11.8607 18.996 13.9157 19.481 14.6867 20.332C14.9837 20.661 15.0647 21.027 14.9567 21.491C14.0817 24.768 11.4427 27.82 8.68366 28.746C7.80266 29.042 6.53366 29.226 5.49466 28.417C3.96666 27.213 3.25465 24.437 3.87365 22.096C4.12865 21.13 4.84465 19.466 6.74865 19.177Z"
        fill="black"
      />
      <path
        d="M30.899 42.626L25.707 37.435L24.293 38.849L30.897 45.454L41.706 34.668H41.707L41 33.96L40.293 33.252L30.899 42.626Z"
        fill="black"
      />
    </svg>
  )
}
