import { InitialisedRTR } from './rtr'
import { HTMLTransitionElement } from './transitionExperience'

export type DeepPartialEngr<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartialEngr<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartialEngr<U>>
      : DeepPartialEngr<T[P]>
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>
}

export enum Orientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export enum Device {
  tower = 'tower',
  tablet = 'tablet',
  ipad11 = 'ipad11',
  ipadMini = 'ipadMini',
}

export type ConfigItemValue = string | boolean | number | string[] | undefined

export type LogoColor = 'white' | 'default'

export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom'

export type DrawerKey =
  | 'minicart'
  | 'search'
  | 'menu'
  | 'filters'
  | 'details'
  | 'lenses'
  | 'sizes'
  | 'colors'
  | 'tryLenses'
  | 'alternatives'
  | 'electronics'
  | 'faProfileRecap'
  | 'greenShipment'
  | 'suggestions'
  | undefined

export type ModalKey = 'accessibility' | 'customizeModal' | 'transferSessionModal' | undefined

export type LogData = {
  level: string
  message: string
  source?: string
}

export type LogFunction = (data: LogData) => void

export type Comparator<T> = (a: T, b: T) => number

type ActionName = 'openKioskAppSettings' | 'webAppReset'
declare global {
  interface Window {
    rtrViewerMV: InitialisedRTR
    teViewer: unknown
    api?: {
      invoke: (name: ActionName) => void
    } // DO NOT REMOVE! It is needed for the kiosk app (tower - deprecated)
    ipc?: {
      send: (type: string, name: ActionName) => void
    } // DO NOT REMOVE! It is needed for the kiosk app (tower - >v3)
    toggleScreenReader?: (isActive: boolean) => void // DO NOT REMOVE! It is needed for the kiosk app (ipad)
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'transition-experience': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLTransitionElement>,
        HTMLTransitionElement
      >
    }
  }
}
