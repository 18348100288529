import { Product } from '@abstractTypes/product'
import { useSendAnalyticsEvent } from '@hooks/analyticsHooks'
import { formatProduct } from '@libs/analytics'
import { useEffect } from 'react'

export const useSendVirtualPageView = (product: Product, shouldSendEvent: boolean) => {
  const analyticsData = {
    id: 'VirtualPage-View',
    Page_Type: 'OrderSummary',
    Page_Section1: 'Checkout',
    Page_Section2: 'CustomerOrder',
    Products: {
      [product.UPC]: formatProduct({ item: product }),
    },
  }

  const sendAnalytics = useSendAnalyticsEvent(analyticsData, true)

  useEffect(() => {
    shouldSendEvent && sendAnalytics()
  }, [sendAnalytics, shouldSendEvent])
}

export const useSendAnalyticsProceedClose = () => {
  const sendCloseModalEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'Proceed_Close',
  })
  return sendCloseModalEvent
}
