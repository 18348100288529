import styled from 'styled-components'

export const ProductPropertiesStyled = styled.div`
  display: flex;
  inline-size: 100%;
`

export const ProductPropertyStyled = styled.div`
  margin-block: 0;
  margin-inline: 0.3em;
  flex: 1;
  font-weight: 600;
  color: #8c8c8d;
  text-transform: uppercase;
  background-color: #f1f1f1;
  margin-block-start: auto;
`
