import { Modal } from '@components/core/Modal'
import { ModalBody, ModalContent, ModalWrapper } from '@components/core/Modal/styles'
import { NuanceHeading2 } from '@components/core/Typography/NuanceTypography'
import { NuanceNextBtn, NuancePrevBtn } from '@components/Slider/styles'
import { ADA_SCREEN_HEIGHT } from '@constants/accessibility'
import { altNavigation, altNavigationZoom, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { Device } from 'sniffr'
import styled, { css, keyframes } from 'styled-components'

export const ModalStyled = styled(Modal)<{ animation?: boolean }>`
  ${({ animation }) => (animation ? '' : 'animation: none !important;')}

  ${ModalContent} {
    block-size: 100vh;
    inline-size: 100vw;
    background: ${({ theme }) => theme.nuance.colors.secondary};
    ${altNavigation(`
      block-size: ${ADA_SCREEN_HEIGHT};
    `)}
  }

  ${ModalBody} {
    block-size: 100%;
    inline-size: 100%;
    padding: 0;
    ${zoomPortrait([150, 175, 200], `padding: 0;`)}
  }

  ${ModalWrapper} {
    padding: 0;
    overflow: hidden;
    ${zoomPortrait([150, 175, 200], `padding: 0;`)}

    ${NuancePrevBtn}, ${NuanceNextBtn} {
      ${altNavigation('position: fixed;')}
      ${altNavigationZoom(
        [110, 125, 150, 175, 200],
        `
        position: fixed;
      `
      )}
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(10)};
  background-color: ${({ theme }) => theme.electronics.colors.primaryLight};
  z-index: 1;
  inset-inline-end: 0;
  inset-block-start: ${pxToRem(260)};
  ${tablet(
    `
      inset-block-start: ${clampValues(184.5, 246)};
      inline-size: ${clampValues(46.5, 62)};
      block-size: ${clampValues(46.5, 62)};
      > svg {
        inline-size: ${clampValues(18, 24)};
        block-size: ${clampValues(18, 24)};
      }
  `
  )}
  ${landscape(`
    inset-block-start: ${pxToRem(253)};
  `)}
  ${landscape(
    Device.tablet,
    `
    inset-block-start: ${clampValuesLandscape(150, 201)};
    inline-size: ${clampValuesLandscape(46.5, 62)};
    block-size: ${clampValuesLandscape(46.5, 62)};
    > svg {
      inline-size: ${clampValuesLandscape(18, 24)};
      block-size: ${clampValuesLandscape(18, 24)};
    }
  `
  )}
`

export const VideoStyled = styled.video`
  inline-size: 100vw;
  block-size: 100vh;
  object-fit: cover;
  ${altNavigation(`
    block-size: ${ADA_SCREEN_HEIGHT};
  `)}
`

export const StyledDiv = styled.div`
  inline-size: 100vw;
  block-size: 100vh;
`

export const Title = styled(NuanceHeading2)`
  position: absolute;
  text-transform: uppercase;
  padding-block-start: ${pxToRem(96)};
  padding-inline-start: ${pxToRem(96)};
  z-index: 2;
  inline-size: ${pxToRem(500)};
`

const pulseSmallerRing = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`

const pulseBiggerRing = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`

const animationSmallerRing = css`
  animation: linear ${pulseSmallerRing} 1.5s infinite;
`

const animationBiggerRing = css`
  animation: linear ${pulseBiggerRing} 1.5s infinite;
`

export const VideoModalSlideStyled = styled.div`
  inline-size: 100vw;
  block-size: 100vh;
  position: relative;
`
export const ControlButton = styled.div<{ playAnimation: boolean }>`
  position: absolute;
  z-index: 100;
  inset-block-start: ${pxToRem(30)};
  inset-inline-end: ${pxToRem(30)};
  inline-size: ${pxToRem(60)};
  block-size: ${pxToRem(60)};

  & > svg {
    position: relative;
    z-index: 100;
  }

  ::after {
    display: ${({ playAnimation }) => (playAnimation ? 'block' : 'none')};
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    inline-size: ${pxToRem(100)};
    block-size: ${pxToRem(100)};
    inset-block-start: ${pxToRem(-20)};
    inset-inline-start: ${pxToRem(-20)};
    z-index: 2;
    background-color: #00f3;
    ${({ playAnimation }) => playAnimation && animationBiggerRing}
  }

  ::before {
    display: ${({ playAnimation }) => (playAnimation ? 'block' : 'none')};
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    inline-size: ${pxToRem(80)};
    block-size: ${pxToRem(80)};
    inset-block-start: ${pxToRem(-10)};
    inset-inline-start: ${pxToRem(-10)};
    z-index: 2;
    background-color: #00f3;
    ${({ playAnimation }) => playAnimation && animationSmallerRing}
  }
`
