import { QueryNoResultsArgs } from '@abstractTypes/graphqlTypes'
import { useAvailableInStoreProductSubdivision } from '@hooks/useAvailableInStore'
import useEnrichExcludeFilters from '@hooks/useEnrichExcludeFilters'
import { useProductSubdivisionFilters } from '@hooks/useFilters'
import { useProductSubdivisionFromUrl } from '@hooks/useGetProductSubdivisionFromUrl'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useStoreContext } from '@hooks/useStoreContext'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useFormatPlpNoResultsQueryPayload = (): QueryNoResultsArgs['data'] => {
  const productSubdivision = useProductSubdivisionFromUrl()
  const { landscapeNavigation } = useNavigationContext()
  const NUMBER_OF_PREVIEW_ITEMS = landscapeNavigation ? 3 : 2

  const { storeId, langCode } = useStoreContext()

  const { availableInStore } = useAvailableInStoreProductSubdivision(productSubdivision)

  const customerOrderType = useSelector(s => s.customerOrder.orderType)
  const isCompletePair = customerOrderType === 'COMPLETE_PAIR'
  const enrichedExcludeFilters = useEnrichExcludeFilters()
  const productSubdivisionFilters = useProductSubdivisionFilters(productSubdivision)

  const queryPayload = useMemo(() => {
    const data: QueryNoResultsArgs['data'] = {
      filters: {
        ...productSubdivisionFilters,
        ...(isCompletePair && { roxable: true }),
        store: storeId,
        lang: langCode,
        excludeFilters: enrichedExcludeFilters,
        storeAvailable: availableInStore,
      },
      rows: NUMBER_OF_PREVIEW_ITEMS,
      start: 0,
      splitGroupWhenOnlyOneFound: false,
    }
    return data
  }, [
    productSubdivisionFilters,
    isCompletePair,
    storeId,
    langCode,
    enrichedExcludeFilters,
    availableInStore,
    NUMBER_OF_PREVIEW_ITEMS,
  ])

  return queryPayload
}

export default useFormatPlpNoResultsQueryPayload
