import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getLpEnvVariables = () => {
  const lpEnvVariables: AppConfigOverride = {
    shopperSlug: 'lp',
    shopperName: 'LAUBMAN PANK',
    storeLang: 'en-GB',
    storeID: '14',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    showExtraPdpDetails: true,
    vmStore: 'lp',
    vmStyle: 'lp',
  }

  return lpEnvVariables
}

export default getLpEnvVariables
