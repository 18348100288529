import { InfoIcon } from '@components/core/Icons/Info'
import React, { useCallback, useEffect } from 'react'
import { ToastNotificationContent, ToastNotificationWrapper } from './styles'
import { useSelector } from 'react-redux'
import { ToastType, ToastData } from '@abstractTypes/toast'
import { useActions } from '@hooks/useActions'
import { deleteToast } from '@actions/ui'

const IconMap: Record<ToastType, React.ReactElement> = {
  info: <InfoIcon stroke="blue" />,
  error: <InfoIcon stroke="red" />,
  warn: <InfoIcon stroke="yellow" />,
  success: <InfoIcon stroke="green" />,
}

export const Toast: React.FC<ToastData> = ({ id, message, duration = 3000, type = 'success' }) => {
  const actions = useActions({ deleteToast })
  const destroy = useCallback(() => actions.deleteToast(id), [id, actions])

  useEffect(() => {
    const timer = setTimeout(() => {
      destroy()
    }, duration)

    return () => clearTimeout(timer)
  }, [destroy, duration])

  return (
    <ToastNotificationContent onClick={destroy}>
      {IconMap[type]}
      {message}
    </ToastNotificationContent>
  )
}

export const ToastNotification = () => {
  const toasts = useSelector(state => state.ui.toasts)
  return (
    <ToastNotificationWrapper>
      {toasts.map(toast => (
        <Toast key={toast.id} {...toast} />
      ))}
    </ToastNotificationWrapper>
  )
}
