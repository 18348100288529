import Sniffr from 'sniffr'

const caseInsensitiveMatch = (str1: string, str2: string) => {
  const caseInsensitive = new RegExp(str1, 'i')
  return str2.match(caseInsensitive)
}

const getChromiumVersion = (): number => {
  const sniffr = new Sniffr()
  const sys = sniffr.sniff(navigator.userAgent)
  const isChromium = caseInsensitiveMatch(sys.browser.name, 'chrome')
  const version: string = sys.browser.versionString
  return isChromium ? parseInt(version.split('.')[0]) : 0
}

export default getChromiumVersion
