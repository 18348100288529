import { AccordionIcon } from '@components/core/Accordion/styles'
import { Heading4 } from '@components/core/Typography'
import { altNavigation, altNavigationZoom, tablet } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const LanguageSelectorWrapper = styled.div`
  ${AccordionIcon} {
    margin-inline-start: ${pxToRem(16)};
  }
`

export const SelectedLanguage = styled(Heading4)`
  margin-inline-start: ${pxToRem(7)};
`

export const LanguageSelectorList = styled.ul`
  position: absolute;
  inset-inline-start: 0;
  padding-inline-start: ${pxToRem(96)};
  inline-size: 100%;
  block-size: 100%;
  min-block-size: 90vh;
  overflow: scroll;
  background: ${({ theme }) => theme.colors.background};
  z-index: 2;
  ${tablet(`padding-inline-start: ${clampValues(72, 96)};`)}

  li:last-child {
    margin-block-end: ${pxToRem(46)};
  }
  ${altNavigation(`
    block-size: unset;
    min-block-size: 26vh;
  `)};
  ${altNavigationZoom(
    [150, 175],
    `
    min-block-size: 32vh;
  `
  )};
  ${altNavigationZoom(
    [200],
    `
    min-block-size: 28vh;
  `
  )};
`
export const LanguageSelectorListItem = styled(Heading4)`
  padding-block-start: ${pxToRem(70)};
  display: block;
  ${tablet(`padding-block-start: ${clampValues(36, 70)};`)}
`
