import { ElectronicsColors, ElectronicsFonts } from 'styled-components'

const colors: ElectronicsColors = {
  primary: '#000000',
  secondary: '#111111',
  primaryDark: '#1F1F24',
  primaryLight: '#ffffff',
  inactive: '#888888',
  // SS-37711: must be the same color as highlight01 of the rayban theme
  ctaHome: '#E80C00',
}

const primaryFont = 'Oswald,Gilmer,Roboto,Helvetica,Arial'
const secondaryFont = 'Lato,Gilmer,Roboto,Helvetica,Arial'

const fonts: ElectronicsFonts = {
  primary: primaryFont,
  secondary: secondaryFont,
  heading1: {
    fontFamily: primaryFont,
    weight: {
      regular: 500,
    },
    fontSize: {
      tower: 48,
      tablet: 48,
      tabletMini: 35,
    },
    lineHeight: {
      tower: 72,
      tablet: 72,
      tabletMini: 52,
    },
  },
  heading2: {
    fontFamily: primaryFont,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 48,
      tablet: 48,
      tabletMini: 34,
    },
    lineHeight: {
      tower: 62,
      tablet: 62,
      tabletMini: 46,
    },
  },
  heading4: {
    fontFamily: primaryFont,
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 32,
      tablet: 32,
      tabletMini: 23,
    },
    lineHeight: {
      tower: 48,
      tablet: 48,
      tabletMini: 34,
    },
  },
  heading5: {
    fontFamily: primaryFont,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 24,
      tablet: 24,
      tabletMini: 18,
    },
    lineHeight: {
      tower: 36,
      tablet: 36,
      tabletMini: 28,
    },
  },
  bodyLenses: {
    fontFamily: primaryFont,
    weight: {
      regular: 500,
      bold: 700,
    },
    fontSize: {
      tower: 32,
      tablet: 32,
      tabletMini: 23,
    },
    lineHeight: {
      tower: 48,
      tablet: 48,
      tabletMini: 34,
    },
  },
  body: {
    fontFamily: secondaryFont,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 18,
      tablet: 18,
      tabletMini: 15,
    },
    lineHeight: {
      tower: 26,
      tablet: 26,
      tabletMini: 22,
    },
  },
  label: {
    fontFamily: secondaryFont,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 14,
      tablet: 14,
      tabletMini: 14,
    },
    lineHeight: {
      tower: 20,
      tablet: 20,
      tabletMini: 18,
    },
  },
  cta: {
    fontFamily: primaryFont,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 32,
      tablet: 32,
      tabletMini: 23,
    },
    lineHeight: {
      tower: 48,
      tablet: 48,
      tabletMini: 34,
    },
  },
}

export default {
  colors,
  fonts,
}
