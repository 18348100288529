import { Heading5, Text } from '@components/core/Typography'
import { zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const Checkbox = styled.input.attrs({ type: 'checkbox' })<{
  color?: string
}>`
  :not(:checked),
  :checked {
    position: absolute;
    inset-inline-start: -9999px;
  }

  :not(:checked) + label,
  :checked + label {
    position: relative;
    padding-inline-start: ${pxToRem(48)};
    cursor: pointer;
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline-start: ${pxToRem(72)};
      `
    )}
    ${zoomPortrait(
      [200],
      `
      padding-inline-start: ${pxToRem(96)};
      `
    )}
  }

  :not(:checked) + label:before,
  :checked + label:before {
    content: '';
    position: absolute;
    inset-inline-start: 0;
    inline-size: ${pxToRem(24)};
    block-size: ${pxToRem(24)};
    background-color: #fff;
    border-radius: 1px;
    outline: none;
    inset-block-start: 50%;
    transform: translateY(-50%);
    border: 1px solid ${({ theme, color }) => color || theme.colors.primaryDark};
    ${zoomPortrait(
      [150, 175],
      `
      inline-size: ${pxToRem(36)};
      block-size: ${pxToRem(36)};
      `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: ${pxToRem(48)};
      block-size: ${pxToRem(48)};
      `
    )}
  }

  :checked + label:before {
    background-color: ${({ theme, color }) => color || theme.colors.primaryDark};
  }

  :not(:checked) + label:after,
  :checked + label:after {
    content: '';
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 0;
    inline-size: ${pxToRem(24)};
    block-size: ${pxToRem(24)};
    transition: all 0.2s;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=);
    ${zoomPortrait(
      [150, 175],
      `
      inline-size: ${pxToRem(36)};
      block-size: ${pxToRem(36)};
      `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: ${pxToRem(48)};
      block-size: ${pxToRem(48)};
      `
    )}
  }

  :not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  :checked + label:after {
    opacity: 1;
    transform: scale(1) translateY(-50%);
  }
`

export const CheckboxWithLabelWrapper = styled.p<{ disabled: boolean }>`
  display: inline-flex;
  align-items: center;

  > ${Checkbox} {
    margin-inline-end: ${pxToRem(24)};
    ${zoomPortrait(
      [150, 175],
      `
      margin-inline-end: ${pxToRem(36)};
      `
    )}
    ${zoomPortrait(
      [200],
      `
      margin-inline-end: ${pxToRem(48)};
      `
    )}
  }
`

export const CheckboxLabel = styled(Heading5)<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => disabled && '0.3'};
`

export const CheckboxDescription = styled(Text)<{ disabled: boolean }>`
  padding-inline-start: ${pxToRem(48)};
  margin-block-start: ${pxToRem(4)};
  text-transform: none;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  ${zoomPortrait(
    [150, 175],
    `
    padding-inline-start: ${pxToRem(72)};
    margin-block-start: ${pxToRem(6)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
    padding-inline-start: ${pxToRem(96)};
    margin-block-start: ${pxToRem(8)};
    `
  )}
`
