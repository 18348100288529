import MiniCartProductCard from '@components/CardProduct/MiniCartProductCard'
import { MiniCartItemsContainer } from '@components/Checkout/styles'
import { useCombinedRefs } from '@hooks/useCombinedRefs'
import useIsScrollable from '@hooks/useIsScrollable'
import React, { useEffect, useMemo } from 'react'
import { MiniCartItemsProps } from './types'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { usePrevious } from '@hooks/usePrevious'

export const MiniCartItems = React.forwardRef(
  (
    { items, removeItemFromCart, dataAttrs }: MiniCartItemsProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { ref: scrollableRef } = useIsScrollable<HTMLDivElement>()
    const combinedRef = useCombinedRefs(ref, scrollableRef)

    const { isVisible } = useDrawerHandler('minicart')

    const previousItems = usePrevious(items)

    const sortedItems = useMemo(() => {
      const accessories = items.filter(({ product }) => product.isNuanceAccessories)
      const others = items.filter(({ product }) => !product.isNuanceAccessories)
      return [
        ...accessories.reverse(), //Shows last accessory added on top
        ...others,
      ]
    }, [items])

    useEffect(() => {
      if (isVisible && previousItems.length < sortedItems.length) {
        setTimeout(() => {
          scrollableRef.current?.scrollIntoView({ behavior: 'smooth' })
        }, 1500)
      }
    }, [scrollableRef, isVisible, previousItems.length, sortedItems.length])

    return (
      <MiniCartItemsContainer ref={combinedRef}>
        {sortedItems.map(e => {
          const analyticsDataDescription = `${e.product.brand.name}_${e.product.name}_${e.product.UPC}`
          return (
            <MiniCartProductCard
              cartItem={e}
              key={e.id}
              dataAttrs={{ ...dataAttrs, description: analyticsDataDescription }}
              removeItemFromCart={removeItemFromCart}
              showRTRIcon
              showVTOIcon
            />
          )
        })}
      </MiniCartItemsContainer>
    )
  }
)
MiniCartItems.displayName = 'MiniCartItems'
