import { ProductHierarchies, ProductTypeValues } from '@abstractTypes/filter'
import {
  AvailableInStore,
  AvailableInStoreSearchPage,
  FiltersActionTypes,
  SET_FORCED_FILTERS_STATE,
  TOGGLE_AVAILABLE_IN_STORE,
  TOGGLE_AVAILABLE_IN_STORE_FORCED,
  TOGGLE_AVAILABLE_IN_STORE_SEARCH_PAGE,
} from '@actions/filters'
import config from '@config/index'
import { merge } from 'lodash'

export interface FiltersState {
  availableInStore: AvailableInStore
  availableInStoreSearchPage: AvailableInStoreSearchPage
  isForcedFiltersState: boolean
  forcedState: {
    availableInStore: AvailableInStore
  }
}

const initialAvailableInStoreProductType: AvailableInStore = {
  [ProductTypeValues.SUN]: undefined,
  [ProductTypeValues.OPTICAL]: undefined,
}

const initialAvailableInStoreProductHierarchies: AvailableInStore = {
  [ProductHierarchies.SUNGLASSES]: undefined,
  [ProductHierarchies.EYEGLASSES]: undefined,
}

const initialAvailableInStoreSearchPageTypes: AvailableInStoreSearchPage = {
  [ProductTypeValues.SNOWGOGGLES]: false,
}

const initialAvailableInStoreSearchPageHierarchies: AvailableInStoreSearchPage = {
  [ProductHierarchies.GOGGLES]: false,
  [ProductHierarchies.APPAREL]: false,
  [ProductHierarchies.REPLACEMENT_LENSES]: false,
  [ProductHierarchies.ACCESSORIES]: false,
  [ProductHierarchies.NFL]: false,
  [ProductHierarchies.HELMETS]: false,
  [ProductHierarchies.FOOTWEAR]: false,
}

let defaultAvailableInStore: AvailableInStore
let defaultAvailableInStoreSearchPage: AvailableInStoreSearchPage

switch (config.default.productSubdivision) {
  case 'productHierarchy': {
    defaultAvailableInStore = initialAvailableInStoreProductHierarchies
    defaultAvailableInStoreSearchPage = initialAvailableInStoreSearchPageHierarchies
    break
  }
  case 'productType':
  default: {
    defaultAvailableInStore = initialAvailableInStoreProductType
    defaultAvailableInStoreSearchPage = initialAvailableInStoreSearchPageTypes
    break
  }
}

const initialState: FiltersState = {
  availableInStore: defaultAvailableInStore,
  availableInStoreSearchPage: defaultAvailableInStoreSearchPage,
  isForcedFiltersState: false,
  forcedState: {
    availableInStore: defaultAvailableInStore,
  },
}

export default (state = initialState, action: FiltersActionTypes) => {
  switch (action.type) {
    case TOGGLE_AVAILABLE_IN_STORE: {
      const { productSubdivision, forcedValue } = action.payload
      return {
        ...state,
        availableInStore: {
          ...state.availableInStore,
          [productSubdivision]: forcedValue ?? !state.availableInStore[productSubdivision],
        },
      }
    }
    case TOGGLE_AVAILABLE_IN_STORE_SEARCH_PAGE: {
      const { productSubdivision } = action.payload
      return {
        ...state,
        availableInStoreSearchPage: {
          ...state.availableInStoreSearchPage,
          [productSubdivision]: !state.availableInStoreSearchPage[productSubdivision],
        },
      }
    }
    case TOGGLE_AVAILABLE_IN_STORE_FORCED: {
      const { productSubdivision } = action.payload
      return {
        ...state,
        forcedState: {
          availableInStore: {
            ...state.forcedState.availableInStore,
            [productSubdivision]: !state.forcedState.availableInStore[productSubdivision],
          },
        },
      }
    }
    case SET_FORCED_FILTERS_STATE:
      const { isForcedFiltersState, forcedState = {} } = action.payload
      return {
        ...state,
        isForcedFiltersState,
        forcedState: merge({}, state.forcedState, forcedState),
      }
    default:
      return state
  }
}
