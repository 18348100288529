import { Device } from '@abstractTypes/utils'
import { Heading2, Text } from '@components/core/Typography'
import { altNavigationZoom, landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const SliderContainer = styled.div`
  touch-action: none;
  z-index: -1;
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inline-size: 100%;
  overflow: hidden;

  & > div {
    inline-size: 100%;
    block-size: ${pxToRem(556)};
    background: black;

    ${tablet(`
      block-size: ${clampValues(384, 528)};
    `)}

    ${landscape(`block-size: ${pxToRem(535)};`)}

    ${landscape(
      Device.tablet,
      `
      block-size:  ${clampValuesLandscape(386, 532)};
    `
    )}
    img, video {
      opacity: 0.7;
    }
  }
`

export const PlayListTitle = styled(Heading2)`
  margin-block-end: ${pxToRem(24)};
  text-transform: uppercase;
`

export const PlayListDescription = styled(Text)`
  margin-block-end: ${pxToRem(24)};
  text-transform: uppercase;
  ${landscape(`
    text-transform: none;
  `)}
`

export const PlayListDescriptionContainer = styled.div`
  position: absolute;
  inset-block-start: ${pxToRem(230)};
  inset-inline-end: 0;
  inset-inline-start: 0;
  text-align: center;
  padding-block: 0;
  padding-inline: ${pxToRem(90)};

  ${tablet(`
      inset-block-start: ${clampValues(190, 230)};
      margin: auto;
      padding: 0;
      inline-size: ${pxToRem(688)};
  `)}

  ${landscape(
    Device.tablet,
    `
      inset-block-start: ${clampValuesLandscape(150, 230)};
  `
  )}
`

export const FrameAdvisorSuggestionPopup = styled.div`
  z-index: 1;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  margin-block-start: ${pxToRem(556)};
  padding-block-start: ${pxToRem(40)};
  block-size: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;

  ${tablet(`margin-block-start: ${clampValues(384, 528)};`)}
  ${landscape(`margin-block-start: ${pxToRem(532)};`)}
  ${landscape(Device.tablet, `margin-block-start: ${clampValuesLandscape(386, 532)};`)}
`
export const Btn = styled.button`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: ${pxToRem(66)};
  block-size: ${pxToRem(66)};
  border-radius: 50%;
  box-sizing: content-box;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  ${tablet(`
    inline-size: ${pxToRem(50)};
    block-size: ${pxToRem(50)};
  `)}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(99)};
    block-size: ${pxToRem(99)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(132)};
    block-size: ${pxToRem(132)};
  `
  )}
  ${zoomLandscape(
    [200],
    `
    inline-size: ${pxToRem(99)};
    block-size: ${pxToRem(99)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    inline-size: ${pxToRem(80)};
    block-size: ${pxToRem(80)};
  `
  )}

  & > svg {
    inline-size: ${pxToRem(20)};
    block-size: ${pxToRem(36)};
    ${tablet(`
      inline-size: ${pxToRem(15)};
      block-size: ${pxToRem(27)};
    `)}
    ${zoomPortrait(
      [150, 175],
      `
      inline-size: ${pxToRem(30)};
      block-size: ${pxToRem(54)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: ${pxToRem(40)};
      block-size: ${pxToRem(72)};
    `
    )}
    ${zoomLandscape(
      [200],
      `
      inline-size: ${pxToRem(30)};
      block-size: ${pxToRem(54)};
    `
    )}
    ${altNavigationZoom(
      [200],
      `
      inline-size: ${pxToRem(24)};
      block-size: ${pxToRem(44)};
    `
    )}
  }
`

export const NextBtn = styled(Btn)`
  z-index: 2;
  inset-block-start: auto;
  inset-block-end: ${pxToRem(-35)};
  inset-inline-end: ${pxToRem(-70)};
`

export const PrevBtn = styled(Btn)`
  z-index: 2;
  inset-block-start: auto;
  inset-block-end: ${pxToRem(-35)};
  inset-inline-start: ${pxToRem(-70)};
`
