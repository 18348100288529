import { IconProps } from '@abstractTypes/icons'

export const FaceShapeTriangleIconFA = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5216 2.11C39.1487 1.47396 38.6174 0.945329 37.9795 0.575567C37.3416 0.205804 36.6189 0.0075132 35.8816 0H4.3616C3.62433 0.0075132 2.90157 0.205804 2.26369 0.575567C1.6258 0.945329 1.09453 1.47396 0.7216 2.11C0.284152 2.82782 0.0371143 3.6454 0.00386616 4.48535C-0.029382 5.3253 0.152259 6.15985 0.5316 6.91L16.2916 37.8C16.6328 38.5277 17.1742 39.1432 17.8525 39.5743C18.5308 40.0055 19.3179 40.2345 20.1216 40.2345C20.9253 40.2345 21.7124 40.0055 22.3907 39.5743C23.069 39.1432 23.6104 38.5277 23.9516 37.8L39.7116 6.89C40.0873 6.14237 40.267 5.31155 40.2338 4.47547C40.2006 3.63939 39.9555 2.82545 39.5216 2.11ZM20.1216 38.11C19.718 38.102 19.3248 37.9805 18.987 37.7594C18.6492 37.5383 18.3805 37.2267 18.2116 36.86L2.4516 5.91C2.24095 5.48755 2.13972 5.01898 2.15719 4.54724C2.17467 4.07551 2.31028 3.61571 2.5516 3.21C2.73036 2.88405 2.9917 2.61083 3.30939 2.41776C3.62707 2.22469 3.98994 2.11856 4.3616 2.11H35.8816C36.2533 2.11856 36.6161 2.22469 36.9338 2.41776C37.2515 2.61083 37.5128 2.88405 37.6916 3.21C37.9329 3.61571 38.0685 4.07551 38.086 4.54724C38.1035 5.01898 38.0022 5.48755 37.7916 5.91L22.0316 36.83C21.867 37.2023 21.6002 37.5202 21.2621 37.7468C20.924 37.9734 20.5285 38.0993 20.1216 38.11Z"
        fill={fill}
      />
    </svg>
  )
}
