import { toggleVirtualKeyboard } from '@actions/accessibility'
import {
  AccessibilityModalSection,
  AccessibilitySwitcher,
  SectionContent,
  SectionIcon,
  SectionTitleRow,
  SectionTitleRowLabel,
} from '@components/Accessibility/sections/styles'
import { VirtualKeyboardIcon } from '@components/core/Icons'
import { Heading4, LinkTypography, Text } from '@components/core/Typography'
import { useAction } from '@hooks/useActions'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { pxToRem } from '@libs/styled'
import { pushToDataTrack } from '@providers/analyticsProvider'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const VKSection: React.FC = () => {
  const { t } = useTranslation()
  const { zoomLevel } = useNavigationContext()
  const isVKEnabled = useSelector(({ accessibility }) => accessibility.vkEnabled)
  const toggleVK = useAction(toggleVirtualKeyboard)

  const handleVirtualKeyboard = useCallback(() => {
    toggleVK()
  }, [toggleVK])

  return (
    <AccessibilityModalSection>
      <LinkTypography href="" aria-label={t('AccessibilityLabel.modalVK')} />
      <SectionTitleRow>
        <SectionTitleRowLabel>
          <SectionIcon>
            <VirtualKeyboardIcon
              height={pxToRem(22, zoomLevel / 100)}
              width={pxToRem(32, zoomLevel / 100)}
            />
          </SectionIcon>
          <Heading4 weight="bold" aria-label={t('AccessibilityLabel.modalVK')}>
            {t('AccessibilityLabel.modalVK')}
          </Heading4>
        </SectionTitleRowLabel>
        <AccessibilitySwitcher
          name="Virtual Keyboard"
          checked={isVKEnabled}
          onChange={() => {
            handleVirtualKeyboard()
            pushToDataTrack({
              id: 'Click',
              data_element_id: 'AdaModal_Keyboard',
              data_description: isVKEnabled ? 'OFF' : 'ON',
            })
          }}
          data-element-id="AdaModal_Keyboard"
          data-test="AdaModal_Keyboard"
          data-description={isVKEnabled ? 'ON' : 'OFF'}
        />
      </SectionTitleRow>
      <SectionContent>
        <Text>{t('AccessibilityLabel.modalVKDescription')}</Text>
      </SectionContent>
    </AccessibilityModalSection>
  )
}
