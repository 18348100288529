import { IconProps } from '@abstractTypes/icons'

export const FaceShapeOvalIconFA = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  className = '',
  cut = false,
}: IconProps & { cut?: boolean }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 ${cut ? 24 : 0} 32 48`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 48C7.18 48 0 37.23 0 24C0 10.77 7.18 0 16 0C24.82 0 32 10.77 32 24C32 37.23 24.82 48 16 48ZM16 2C8.28 2 2 11.87 2 24C2 36.13 8.28 46 16 46C23.72 46 30 36.13 30 24C30 11.87 23.72 2 16 2Z"
        fill={fill}
      />
    </svg>
  )
}
