import Loading from '@components/Loading'
import React, { Suspense } from 'react'
import App from './App'
const TranslationProvider = React.lazy(() => import('@providers/translationProvider'))

const AppWithTranslations = () => (
  <Suspense fallback={<Loading />}>
    <TranslationProvider>
      <App />
    </TranslationProvider>
  </Suspense>
)

export default AppWithTranslations
