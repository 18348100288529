import { Device } from '@abstractTypes/utils'
import { Modal } from '@components/core/Modal'
import {
  ModalBody,
  ModalBodyWrapper,
  ModalContent,
  ModalHeader,
  ModalWrapper,
} from '@components/core/Modal/styles'
import { ADA_SCREEN_HEIGHT } from '@constants/accessibility'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { pxToRem } from '@libs/styled'
import { hideScrollbar, scrollbar } from '@styles/common'
import styled from 'styled-components'
import { DeviceInfoType, accessibilityButtonInfoMap } from '../sections/styles'

export const AccessibilityModalContainer = styled.div`
  display: flex;
  inline-size: 100%;
  block-size: 100%;
`

export const AccessibilityModalContent = styled.div`
  inline-size: 100%;
  block-size: 100%;

  b {
    font-weight: ${({ theme }) => theme.fonts.body.weight.bold};
  }
`

export const AccessibilityModalSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
  margin-block-start: ${pxToRem(32)};
  ${tablet(
    `
    margin-block-start: ${pxToRem(24)};
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    grid-template-columns: repeat(1, 1fr);
    padding-inline: ${pxToRem(90)};
    margin-block-start: 0;
  `
  )}
  ${zoomLandscape(
    [150, 175],
    `
    grid-template-columns: repeat(2, 1fr);
    margin-block-start: ${pxToRem(32)};
    padding-inline: ${pxToRem(0)};
  `
  )}
  ${altNavigationZoom(
    [150, 175],
    `
    grid-template-columns: repeat(1, 1fr);
    margin-inline-start: ${pxToRem(18)};
    margin-inline-end: ${pxToRem(18)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    grid-template-columns: repeat(1, 1fr);
    margin-block-start: 0;
    padding-inline: ${pxToRem(36)};
  `
  )}
  ${zoomLandscape(
    [200],
    `
    grid-template-columns: repeat(2, 1fr);
    margin-block-start: ${pxToRem(32)};
    padding-inline: ${pxToRem(0)};
  `
  )}
`

const getBottomPosition = (device: DeviceInfoType, direction?: 'up' | 'down') => {
  const bottom =
    accessibilityButtonInfoMap[device].insetBlockEnd ??
    accessibilityButtonInfoMap['default'].insetBlockEnd
  const height =
    accessibilityButtonInfoMap[device].blockSize ?? accessibilityButtonInfoMap['default'].blockSize
  if (direction === 'down') {
    return `calc(${bottom} + ${height})`
  }

  return bottom
}

const getModalPosition = (device: DeviceInfoType, direction?: 'up' | 'down') => {
  const isTablet = device === 'tabletPortrait' || device === 'tabletLandscape'
  const shuoldNotTranslate = isTablet || direction === 'up'
  const cssRules = {
    'inset-block-end': isTablet ? 'auto' : getBottomPosition(device, direction),
    transform: shuoldNotTranslate ? 'none' : 'translateY(100%)',
    'inset-block-start': accessibilityButtonInfoMap[device].insetBlockStart ?? 'auto',
  }

  return Object.entries(cssRules)
    .map(([key, value]) => `${key}:${value}`)
    .join(';')
}

export const AccessibilityModalStyled = styled(Modal)`
  &.accessibility {
    position: absolute;
    inset-block-start: auto;
    padding-block-end: 0;
    inset-inline-end: ${pxToRem(64)};
    inset-inline-start: ${pxToRem(96)};
    ${getModalPosition('default', 'down')};
    ${tablet(`
      ${getModalPosition('tabletPortrait')};
      inset-inline-start: ${pxToRem(72)};
    `)}
    ${landscape(`
      inset-inline-end: ${pxToRem(904)};
      ${getModalPosition('towerLandscape', 'up')};
    `)}
    ${landscape(
      Device.tablet,
      `
      inset-inline-end: ${pxToRem(904)};
      inset-inline-start: ${pxToRem(72)};
      ${getModalPosition('tabletLandscape')};
    `
    )}
    ${altNavigation(`
      inset-block-start: ${pxToRem(180)};
      inset-inline-end: ${pxToRem(80)};
      inset-inline-start: ${pxToRem(80)};
    `)}
      ${altNavigationZoom(
      [150, 175, 200],
      `
        inset-block-start: ${pxToRem(180)};
        `
    )};

    ${zoomPortrait(
      [150, 175],
      `
      ${getModalPosition('zoom150', 'down')};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      ${getModalPosition('zoom200', 'down')};
    `
    )}
    ${zoomLandscape(
      [150, 175],
      `
      inset-inline-end: ${pxToRem(402)};
      inset-inline-start: ${pxToRem(138)};
      ${getModalPosition('zoomLandscape150', 'up')};
  `
    )}

    ${zoomLandscape(
      [200],
      `
      inset-inline-end: ${pxToRem(7)};
      inset-inline-start: ${pxToRem(113)};
      ${getModalPosition('zoomLandscape200', 'up')};
  `
    )}
  }

  ${ModalHeader} {
    padding: 0;
    ${landscape(`
      padding: 0;
    `)}
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding: 0;
  `
    )}
  }

  ${ModalContent} {
    padding: ${pxToRem(32)};
    overflow: hidden;
    ${tablet(
      `
      inline-size: ${pxToRem(600)}
    `
    )}
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline: ${pxToRem(72)};
    `
    )}
    ${zoomLandscape(
      [150, 175],
      `
      padding-inline: ${pxToRem(32)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      padding-inline: ${pxToRem(60)};
    `
    )}
    ${zoomLandscape(
      [200],
      `
      padding: ${pxToRem(40)};
    `
    )}
  }

  ${ModalBody} {
    padding: 0;
    inline-size: auto;
    max-block-size: ${getBottomPosition('default')}
      ${tablet(
        `
      max-block-size: ${getBottomPosition('tabletPortrait')}
    `
      )}
      ${zoomPortrait(
        [150, 175],
        `
      padding: 0;
      max-block-size: ${getBottomPosition('zoom150')}
    `
      )}
      ${zoomPortrait(
        [200],
        `
      padding: 0;
      max-block-size: ${getBottomPosition('zoom200')}
    `
      )}
      ${altNavigationZoom(
        [150, 175, 200],
        `
      max-block-size: calc(${ADA_SCREEN_HEIGHT} - ${pxToRem(250)});
    `
      )};
  }

  ${ModalWrapper} {
    ${hideScrollbar};
    padding: 0;
    ${zoomPortrait(
      [150, 175, 200],
      `
      ${scrollbar}
      padding: 0;
      ::-webkit-scrollbar {
        margin-block-start: 60px;
      }
      ::-webkit-scrollbar-track {
        
        margin-block-start: 60px;
      }
      ::-webkit-scrollbar-thumb {
        margin-block-start: 60px;
      }
    `
    )}
  }

  ${ModalBodyWrapper} {
    overflow: auto;
    margin-block-start: ${pxToRem(32)};
    ${tablet(
      `
      margin-block-start: ${pxToRem(24)};
    `
    )}
  }
`
export const AccessibilityModalHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryDark};
  gap: ${pxToRem(16)};
  ${zoomPortrait(
    [150, 175, 200],
    `
    gap: ${pxToRem(36)};
  `
  )}
`

export const TitleIcon = styled.div`
  display: flex;
  block-size: ${pxToRem(35)};
  inline-size: ${pxToRem(35)};
  ${zoomPortrait(
    [150, 175],
    `
    block-size: ${pxToRem(72)};
    inline-size: ${pxToRem(72)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    block-size: ${pxToRem(96)};
    inline-size: ${pxToRem(96)};
  `
  )}
`
