import { useStoreContext } from '@hooks/useStoreContext'
import { getLangFromUrl } from '@libs/url'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useFormatSearchSuggestionsQueryPayload = (query: string) => {
  const { storeId } = useStoreContext()
  const { availableInStore, availableInStoreSearchPage } = useSelector(s => s.filters)
  const customerOrderType = useSelector(s => s.customerOrder.orderType)
  const isCompletePair = customerOrderType === 'COMPLETE_PAIR'

  const isAvailableInStoreForAnyProductType =
    Object.values(availableInStore || {}).some(Boolean) ||
    Object.values(availableInStoreSearchPage || {}).some(Boolean)

  return useMemo(() => {
    const payload = {
      ...(isCompletePair && { roxable: true }),
      store: storeId,
      storeAvailable: isAvailableInStoreForAnyProductType,
      lang: getLangFromUrl(),
      query: query.toLowerCase(),
    }
    return payload
  }, [query, storeId, isCompletePair, isAvailableInStoreForAnyProductType])
}
