import { TooltipPosition } from '@abstractTypes/utils'
import { Text } from '@components/core/Typography'
import { zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

export const TooltipWrapper = styled.div`
  position: relative;
`

const getTooltipCoordinates = (position: TooltipPosition) => {
  switch (position) {
    case 'right': {
      return css`
        inset-block-start: 50%;
        inset-inline-start: 101%;
        transform: translate(0, -50%);
      `
    }
    default: {
      return null
    }
  }
}

export const LabelContainer = styled('div')<{ position: TooltipPosition }>`
  z-index: 4;
  position: absolute;
  display: flex;
  align-items: center;
  ${props => getTooltipCoordinates(props.position)}
`
export const LabelStyled = styled(Text)`
  padding-block: ${pxToRem(20)};
  padding-inline: ${pxToRem(18)};
  block-size: ${pxToRem(66)};
  min-inline-size: ${pxToRem(200)};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primaryLight};
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  ${zoomPortrait(
    [150, 175, 200],
    `
    block-size: ${pxToRem(82)};
  `
  )}

  ${zoomPortrait(
    [200],
    `
    block-size: ${pxToRem(98)};
  `
  )}
`

export const ArrowLeft = styled.div`
  margin-inline-end: -1px;
  inline-size: 0;
  block-size: 0;
  border-block-start: ${pxToRem(10)} solid transparent;
  border-block-end: ${pxToRem(10)} solid transparent;
  border-inline-end: ${pxToRem(12)} solid ${({ theme }) => theme.colors.primaryDark};
`
