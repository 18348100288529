import { Device } from '@abstractTypes/utils'
import { Heading4 } from '@components/core/Typography'
import { tablet } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const TipTile = styled.div`
  padding-block: ${pxToRem(32)};
  padding-inline: ${pxToRem(23)};
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  block-size: 100%;
  text-align: center;
  ${tablet(`
    padding-block: ${clampValues(23, 32)} ;
    padding-inline:  ${clampValues(14, 23)};
  `)}
`

export const TipCardTitle = styled(Heading4).attrs({ weight: 'bold' })`
  margin-block-end: ${pxToRem(20)};
  ${tablet(`
    margin-block-end: ${clampValues(16, 20)};
  `)}
`

export const TipImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  margin-block-end: ${pxToRem(20)};
  ${tablet(`
    margin-block-end: ${clampValues(16, 20)};
  `)}
`

export const MessageBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  inline-size: 95%;
  min-block-size: ${pxToRem(69)};
  margin-inline-start: auto;
  margin-block-end: ${pxToRem(30)};
  background-color: ${({ theme }) => theme.colors.primaryLight};
  padding-block: ${pxToRem(8)} ${pxToRem(9)};
  padding-inline: ${pxToRem(27)} ${pxToRem(16)};
  border-radius: ${pxToRem(48)};
  text-align: start;
  ${tablet(`
    padding-block: ${clampValues(6, 8)} ${clampValues(6, 9)};
    padding-inline:  ${clampValues(20, 27)}  ${clampValues(10, 16)};
    min-block-size: ${clampValues(51, 69)};
    border-radius: ${clampValues(36, 48)};
  `)}
  ::before {
    content: '';
    border: ${pxToRem(20)} solid transparent;
    border-bottom-inline-size: ${`calc(2 * ${pxToRem(20)} * 0.866)`};
    border-bottom-color: ${({ theme }) => theme.colors.primaryLight};
    position: absolute;
    inset-block-start: ${pxToRem(-20)};
    inset-inline-start: ${pxToRem(-20)};
    transform: rotate(-53.19deg);
    ${tablet(
      Device.ipadMini,
      `
      border-inline-size: ${pxToRem(10)};
      border-bottom-inline-size: ${`calc(2 * ${pxToRem(10)} * 0.866)`};
      inset-block-start: ${pxToRem(-10)};
      inset-inline-start: ${pxToRem(-10)};
    `
    )}
  }
`
