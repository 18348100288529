import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const ooTheme: ThemeOverride = {
  colors: {
    primaryDark: '#222222',
    primaryDarkPressed: '#22222280',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#FFFFFF75',
    inactive: '#B6B6B6',
    outline: '#D8D8D8',
    background: '#F6F6F6',
    highlight01: '#222222',
    highlight01Pressed: '#747474',
    highlight02: '#4294FD',
    highlight02Pressed: '#4294FD75',
    highlight03: '#222222',
    highlight04: '#504BD6',
    functional01: '#C83430',
    functional02: '#167D4A',
    functional03: '#F7B500',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 900,
      },
      fontSize: {
        tower: 100,
        tablet: 75,
        tabletMini: 57,
      },
      lineHeight: {
        tower: 115,
        tablet: 80,
        tabletMini: 72,
      },
    },
    heading1: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 900,
      },
      fontSize: {
        tower: 60,
        tablet: 56,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 70,
        tablet: 66,
        tabletMini: 58,
      },
    },
    heading2: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 800,
      },
      fontSize: {
        tower: 45,
        tablet: 40,
        tabletMini: 34,
      },
      lineHeight: {
        tower: 55,
        tablet: 50,
        tabletMini: 44,
      },
    },
    heading3: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 900,
      },
      fontSize: {
        tower: 38,
        tablet: 32,
        tabletMini: 28,
      },
      lineHeight: {
        tower: 52,
        tablet: 44,
        tabletMini: 40,
      },
    },
    heading4: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 500,
        bold: 700,
      },
      fontSize: {
        tower: 28,
        tablet: 26,
        tabletMini: 24,
      },
      lineHeight: {
        tower: 38,
        tablet: 36,
        tabletMini: 34,
      },
    },
    heading5: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 500,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 18,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 30,
        tablet: 28,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 16,
        tablet: 14,
        tabletMini: 14,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 600,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 14,
      },
      lineHeight: {
        tower: 24,
        tablet: 24,
        tabletMini: 24,
      },
    },
    cta: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 500,
        bold: 700,
      },
      fontSize: {
        tower: 16,
        tablet: 14,
        tabletMini: 14,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    link: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 28,
        tablet: 26,
        tabletMini: 24,
      },
      lineHeight: {
        tower: 38,
        tablet: 36,
        tabletMini: 34,
      },
    },
    caption: {
      fontFamily: 'Avenir Next',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 11,
      },
      lineHeight: {
        tower: 16,
        tablet: 16,
        tabletMini: 13,
      },
    },
  },
  components: {
    button: {
      style: 'rounded',
      borderRadius: 50,
      uppercase: true,
    },
    rangeSlider: {
      background: '#D8D8D8', //outline
      foreground: '#4294FD', //highlight02
      thumb: '#FFFFFF', //primaryLight
      thumbBorder: '#D8D8D8', //outline
    },
  },
  productGridColumns: {
    towerLandscape: 4,
  },
}
