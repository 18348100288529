import { IconProps } from '@abstractTypes/icons'

const IncreasesVisualClarity: React.FC<IconProps> = ({ width = '1rem', height = '1rem' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 75 74"
      fill="none"
    >
      <path
        d="M59.4791 37C59.4791 25.6194 50.5593 16.2908 39.302 15.4953V0H36.1978V74H39.302V58.5047C50.5593 57.7092 59.4791 48.3806 59.4791 37ZM39.302 27.8888C43.6991 28.6257 47.0624 32.4243 47.0624 37C47.0624 41.5757 43.6991 45.3728 39.302 46.1112V27.8888ZM39.302 55.4214V49.2254C45.4172 48.4623 50.1666 43.2761 50.1666 36.9985C50.1666 30.7208 45.4172 25.5346 39.302 24.7715V18.5755C48.8458 19.3633 56.3749 27.3168 56.3749 36.9969C56.3749 46.677 48.8458 54.6336 39.302 55.4214Z"
        fill="#222222"
      />
      <path
        d="M20.5063 47.5296C19.8078 48.0229 19.6526 48.9942 20.1648 49.6725C20.4597 50.0887 20.9409 50.32 21.422 50.32C21.7324 50.32 22.0584 50.2121 22.3222 50.0117C23.0206 49.5183 23.1759 48.5625 22.6792 47.8687C22.167 47.175 21.2047 47.0208 20.5063 47.5296Z"
        fill="#222222"
      />
      <path
        d="M18.085 32.2671C18.2402 32.3288 18.3954 32.3442 18.5506 32.3442C19.2025 32.3442 19.8234 31.9279 20.0406 31.2804C20.3045 30.4633 19.8544 29.6 19.0318 29.3379C18.2247 29.0758 17.34 29.5229 17.0762 30.3246C16.8123 31.1417 17.2624 32.005 18.085 32.2671Z"
        fill="#222222"
      />
      <path
        d="M20.0251 42.7196C19.7613 41.9025 18.8921 41.4554 18.0695 41.7175C17.2624 41.9796 16.8123 42.8583 17.0762 43.66C17.2935 44.3075 17.8988 44.7238 18.5506 44.7238C18.7059 44.7238 18.8766 44.7083 19.0318 44.6467C19.8389 44.3846 20.289 43.5212 20.0251 42.7196Z"
        fill="#222222"
      />
      <path
        d="M17.5728 38.5417C18.43 38.5417 19.1249 37.8514 19.1249 37C19.1249 36.1486 18.43 35.4583 17.5728 35.4583C16.7156 35.4583 16.0208 36.1486 16.0208 37C16.0208 37.8514 16.7156 38.5417 17.5728 38.5417Z"
        fill="#222222"
      />
      <path
        d="M24.9763 19.5483C24.2778 20.0417 24.1226 20.9975 24.6348 21.6912C24.9297 22.1075 25.4109 22.3233 25.892 22.3233C26.2024 22.3233 26.5284 22.2308 26.8077 22.0304C27.4907 21.5371 27.6459 20.5658 27.1492 19.8875C26.637 19.1937 25.6592 19.0396 24.9763 19.5483Z"
        fill="#222222"
      />
      <path
        d="M31.0294 16.465C30.2068 16.7271 29.7567 17.6058 30.0361 18.4075C30.2378 19.0704 30.8587 19.4867 31.5105 19.4867C31.6658 19.4867 31.821 19.4558 31.9917 19.4096C31.9917 19.3942 31.9917 19.3942 31.9917 19.3942C32.7988 19.1321 33.2489 18.2687 32.985 17.4671C32.7212 16.65 31.8365 16.2029 31.0294 16.465Z"
        fill="#222222"
      />
      <path
        d="M20.1648 24.3121C19.6681 25.0058 19.8234 25.9617 20.5063 26.4704C20.7856 26.6708 21.1116 26.7633 21.422 26.7633C21.9031 26.7633 22.3688 26.5475 22.6792 26.1313C23.1759 25.4375 23.0362 24.4817 22.3377 23.9729C21.6393 23.4796 20.677 23.6183 20.1648 24.3121Z"
        fill="#222222"
      />
      <path
        d="M31.9762 54.5904C31.1536 54.3283 30.2844 54.7754 30.0205 55.5771C29.7567 56.3942 30.1913 57.2575 31.0139 57.5196C31.1691 57.5812 31.3398 57.5967 31.495 57.5967C32.1469 57.5967 32.7522 57.1804 32.9695 56.5329C33.2334 55.7312 32.7833 54.8525 31.9762 54.5904Z"
        fill="#222222"
      />
      <path
        d="M26.7922 51.9696C26.0938 51.4608 25.1315 51.615 24.6193 52.2933C24.1226 52.9871 24.2778 53.9429 24.9608 54.4517C25.2401 54.6521 25.5661 54.7446 25.8765 54.7446C26.3576 54.7446 26.8233 54.5287 27.1337 54.1125C27.6303 53.4187 27.4906 52.4629 26.7922 51.9542C26.7922 51.9696 26.7922 51.9696 26.7922 51.9696Z"
        fill="#222222"
      />
    </svg>
  )
}

export default IncreasesVisualClarity
