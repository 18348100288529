import { UpcItem } from '@abstractTypes/graphqlTypes'
import { Product } from '@abstractTypes/product'
import { StockItem } from '@abstractTypes/realTimeCheck'
import { addItemToCart, removeItemFromCart } from '@actions/cart'
import { setOrderNumber } from '@actions/order'
import { PrimaryButton, SecondaryButton } from '@components/core/Button/index'
import { LoadingPage } from '@components/core/LoadingPage'
import { Modal } from '@components/core/Modal'
import { Heading3, Text } from '@components/core/Typography'
import { checkAvailabilityPDP, useDoCustomerOrderCheckout } from '@data'
import { useCart } from '@hooks/useCart'
import { useCustomerOrderCheckoutData } from '@hooks/useCustomerOrderCheckoutData'
import { useCustomerOrder } from '@hooks/useCustomOrder'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { useToastNotification } from '@hooks/useToastNotification'
import { useResetContext } from '@providers/resetProvider'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ModalContent, ModalContentContainer, ModalContentCTAContainer } from '../styles'
import { useSendAnalyticsProceedClose, useSendVirtualPageView } from './analytics'

interface CustomerOrderModal {
  product: Product
  visible: boolean
  handleToggleVisible: () => void
}

export const CustomerOrderModal: React.FC<CustomerOrderModal> = ({
  product,
  visible,
  handleToggleVisible,
}) => {
  const { t } = useTranslation()
  const [sendingCheckoutConfirm, setSendingCheckoutConfirm] = useState(false)
  const [doCustomerOrderCheckout] = useDoCustomerOrderCheckout()
  const { isCompletePairOrderType } = useCustomerOrder()

  const getCustomerOrderCheckoutData = useCustomerOrderCheckoutData()
  const { items } = useCart()
  const dispatch = useDispatch()
  const toaster = useToastNotification()

  const cartItem = items.find(item => item.product.id === product.id)
  const cartItemId = cartItem ? cartItem.id : null
  const { storeId } = useStoreIndentity()

  const sendAnalyticsProceedClose = useSendAnalyticsProceedClose()

  const { reset } = useResetContext()

  const handleConfirm = () => {
    sendAnalyticsProceedClose()
    dispatch(addItemToCart({ ...product, type: 'customerOrder' }, storeId, 1, false))
  }

  const sendOrder = useCallback(() => {
    const customerOrderCheckout = getCustomerOrderCheckoutData()

    doCustomerOrderCheckout({
      variables: {
        data: customerOrderCheckout,
      },
    })
      .then(({ data, errors: mutationErrors }) => {
        if (mutationErrors || !data) {
          return Promise.reject(!data ? { message: t('Checkout.errorMessage') } : mutationErrors)
        }

        const { orderNumber, posReceiptId, errors } = data.customerOrderCheckout

        if (orderNumber) {
          dispatch(setOrderNumber(orderNumber, posReceiptId as string))
          reset()
        } else {
          if (errors) {
            errors.forEach(e => {
              toaster.error(e?.message as string)
            })
          }
        }
      })
      .catch(err => {
        const { type, message: gqMessage } = (err.graphQLErrors && err.graphQLErrors[0]) || {}
        toaster.error((type && gqMessage && `${type}: ${gqMessage}`) || err.message)
        if (cartItemId) {
          dispatch(removeItemFromCart(cartItemId, false))
        }
      })
      .finally(() => {
        setSendingCheckoutConfirm(false)
      })
  }, [
    doCustomerOrderCheckout,
    cartItemId,
    t,
    getCustomerOrderCheckoutData,
    dispatch,
    reset,
    toaster,
  ])

  useSendVirtualPageView(product, visible)

  useEffect(() => {
    const customerOrderCheckout = getCustomerOrderCheckoutData()
    const isProductPresent = customerOrderCheckout.items.length > 0 && cartItemId
    const customerCheckoutItem = customerOrderCheckout.items[0]

    if (isProductPresent && customerCheckoutItem.customItem) {
      setSendingCheckoutConfirm(true)
      sendOrder()
      return
    }

    if (isProductPresent) {
      setSendingCheckoutConfirm(true)
      const realTimeCheckPayload: UpcItem = {
        upc: customerCheckoutItem.UPC,
        prescriptionApplied: !!customerCheckoutItem.complete_pair,
      }

      checkAvailabilityPDP({
        data: {
          storeId,
          upcs: [realTimeCheckPayload],
        },
      }).then(res => {
        const stockItem = res?.data?.availability.stockItems.find(
          (s: StockItem) =>
            s.upc === realTimeCheckPayload.upc &&
            s.prescriptionApplied === realTimeCheckPayload.prescriptionApplied
        )
        const itemAvailability = stockItem?.qty

        if (itemAvailability) {
          if (isCompletePairOrderType && !product.roxable && visible) {
            toaster.error(t('CustomerOrder.prescriptionUnavailable'))
            setSendingCheckoutConfirm(false)
          } else {
            sendOrder()
          }
        } else {
          toaster.error(t('CustomerOrder.stockAlertMessage'))
          setSendingCheckoutConfirm(false)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItemId, getCustomerOrderCheckoutData, storeId])

  return (
    <LoadingPage loading={sendingCheckoutConfirm} zIndex={1010}>
      <Modal
        visible={visible}
        onCancel={handleToggleVisible}
        title={
          <Heading3
            aria-label={t('CustomerOrder.modalTitle')}
            aria-description={t('CustomerOrder.modalTitle')}
          >
            {t('CustomerOrder.modalTitle')}
          </Heading3>
        }
      >
        <ModalContent>
          <ModalContentContainer>
            <Text
              aria-label={t('CustomerOrder.modalDescription')}
              aria-description={t('CustomerOrder.modalDescription')}
            >
              {t('CustomerOrder.modalDescription')}
            </Text>
          </ModalContentContainer>
          <ModalContentCTAContainer>
            <SecondaryButton
              onClick={handleToggleVisible}
              dataAttrs={{
                id: 'Proceed_Cancel',
              }}
              ariaAttrs={{
                label: t('CustomerOrder.modalCancel'),
                description: t('CustomerOrder.modalCancel'),
              }}
            >
              {t('CustomerOrder.modalCancel')}
            </SecondaryButton>
            <PrimaryButton
              onClick={handleConfirm}
              disabled={sendingCheckoutConfirm}
              dataAttrs={{
                id: 'Proceed_Confirm',
                reset: true,
              }}
              ariaAttrs={{
                label: t('CustomerOrder.modalConfirm'),
                description: t('CustomerOrder.modalConfirm'),
              }}
            >
              {t('CustomerOrder.modalConfirm')}
            </PrimaryButton>
          </ModalContentCTAContainer>
        </ModalContent>
      </Modal>
    </LoadingPage>
  )
}
