import { altNavigation } from '@libs/media'
import React from 'react'
import styled, { keyframes } from 'styled-components'

// from - twitter bootstrap with love.
// https://getbootstrap.com/docs/4.4/components/spinners/#growing-spinner
const spinnerGrow = keyframes`
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
`

export const Spin = styled.div`
  display: inline-block;
  inline-size: 2rem;
  block-size: 2rem;
  border-radius: 50%;
  opacity: 0;
  vertical-align: text-bottom;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  animation: ${spinnerGrow} 1.5s linear infinite;
`

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-block-size: calc(100vh - 320px);
  ${altNavigation(`
    position: absolute;
    inset: 0
    z-index: 1;
    min-block-size: auto;
  `)}
`

export const Loading: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <LoaderWrapper className={className}>
      <Spin />
    </LoaderWrapper>
  )
}

export const LoadingPLP = styled(Loading)`
  position: fixed;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  inset-inline: 0 0;
  inset-block: auto;
`

export default Loading
