import { AccessibilityRoles } from '@abstractTypes/accessibility'
import { StandardProduct } from '@abstractTypes/product'
import i18next, { TFunction } from 'i18next'

/**
 * The returned object includes the ARIA attributes required for accessibility
 * * @param {string} key
 * * @param {AccessibilityRoles} role
 */

export const getARIAAttribues = (key: string, role?: AccessibilityRoles) => {
  return {
    role,
    'aria-label': i18next.t(`ARIA.${key}.label`),
  }
}

export const disableScreenReaderForElement = (item: Element | HTMLElement) => {
  item.setAttribute('aria-disabled', 'true')
  item.setAttribute('aria-hidden', 'true')
  item.setAttribute('tabindex', '-1')
  item.setAttribute('role', 'presentation')
  item.removeAttribute('id')
  Array.from(item.children).forEach((child: Element) => disableScreenReaderForElement(child))
}

export const skipScreenReaderElement = () => ({
  'aria-disabled': true,
  'aria-hidden': true,
  tabindex: '-1',
  role: 'presentation',
})

export const handleSliderARIABehaviour = () => {
  const prevClonedElements = Array.from(document.getElementsByClassName('prev-cloned'))
  const nextClonedElements = Array.from(document.getElementsByClassName('next-cloned'))
  prevClonedElements.concat(nextClonedElements).forEach((item: Element) => {
    disableScreenReaderForElement(item)
  })
}

export const readProductName = (product: StandardProduct) => `${product.brand.name} ${product.name}`

export const readProductCaracteristics = (product: StandardProduct, t: TFunction) => {
  const frameColor = `${t('ProductDescription.frameColor')} ${product.frameColorLabel}`
  const lensColor = `${t('ProductDescription.lensColor')} ${product.lensColorLabel}`
  const size = `${t('ProductDescription.size')} ${product.sizeFull}`
  return `${frameColor}.${lensColor}.${size}`
}
