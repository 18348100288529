import { FiltersReq, ProductHierarchies, ProductTypeValues } from '@abstractTypes/filter'
import deepMerge from '@libs/deepMerge'
import { isProductTypeSubdivision } from '@libs/productSubdivision'
import { useMemo } from 'react'
import { defaultExcludeFilters } from './formatPayloadForSearchApi'
import { useStoreContext } from './useStoreContext'

const getExcludeFilter = (
  productTypes: ProductTypeValues[] = [],
  productHierarchies: ProductHierarchies[] = []
): FiltersReq => {
  const isProductType = isProductTypeSubdivision()
  if (isProductType && productTypes.length > 0) {
    return {
      productType: productTypes,
    }
  }
  if (!isProductType && productHierarchies.length) {
    return {
      product_hierarchy1: productHierarchies,
    }
  }

  return {}
}

const useEnrichExcludeFilters = (excludeFilters: FiltersReq = defaultExcludeFilters) => {
  const store = useStoreContext()
  return useMemo(() => {
    let excludeEyeglasses: FiltersReq = {}
    let excludeNuance: FiltersReq = {}
    let excludeNuanceAccessories: FiltersReq = {}
    if (!store.eyeglassesEnabled) {
      excludeEyeglasses = getExcludeFilter(
        [ProductTypeValues.OPTICAL],
        [ProductHierarchies.EYEGLASSES]
      )
    }
    if (!store.nuanceEnabled) {
      excludeNuance = getExcludeFilter([ProductTypeValues.NUANCE])
    }
    if (!store.nuanceAccessoriesEnabled) {
      excludeNuanceAccessories = getExcludeFilter([ProductTypeValues.ACCESSORIES])
    }
    return deepMerge<FiltersReq>(
      excludeFilters,
      excludeEyeglasses,
      excludeNuance,
      excludeNuanceAccessories
    )
  }, [excludeFilters, store.eyeglassesEnabled, store.nuanceAccessoriesEnabled, store.nuanceEnabled])
}

export default useEnrichExcludeFilters
