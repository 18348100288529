import emojiRegex from 'emoji-regex'
import React, { ChangeEvent } from 'react'
import { InputText } from './styles'
import { InputTextProps } from './types'

export const InputTextBase: React.FC<InputTextProps> = ({
  className,
  type,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  dataTest,
  name,
  ariaLabel,
  maxLength,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // removing emoji from input
    const { value } = event.target
    const regex = emojiRegex()
    const newValue = value.replace(regex, '')
    onChange(newValue)
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event.target.value)
    }
  }

  const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event.target.value)
    }
  }

  return (
    <>
      <InputText
        className={className}
        data-test={dataTest}
        aria-label={ariaLabel}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    </>
  )
}
