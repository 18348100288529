import React from 'react'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { ElectronicsTogglerModelProps } from './types'
import { ElectronicTabsWithAccordion, ElectronicsToggler } from './styles'

export const ElectronicsTogglerModel: React.FC<ElectronicsTogglerModelProps> = ({
  onModelChange,
  models,
  selectedModel,
}) => {
  const { isAlternativeNavigation, zoomLevel } = useNavigationContext()

  if (isAlternativeNavigation && zoomLevel >= 150) {
    return (
      <ElectronicTabsWithAccordion
        onTabClick={(_, tabId) => onModelChange(tabId)}
        tabs={models.map(m => ({
          id: m.id,
          tabName: m.title,
        }))}
        withFocus
      />
    )
  }

  return (
    <ElectronicsToggler
      options={models.map(m => ({
        value: m.id,
        label: m.title,
      }))}
      value={selectedModel.id}
      onChange={onModelChange}
      focus
    />
  )
}
