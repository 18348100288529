import { Product } from '@abstractTypes/product'
import useShowMixMatchSwitch from './useShowMixMatchSwitch'
import { useShowPrescriptionSwitch } from './useShowPrescriptionSwitch'

interface PurchaseOptionsOutput {
  showPrescriptionSwitch: boolean
  showMixMatchSwitch: boolean
}

const usePurchaseOptions = (product: Product): PurchaseOptionsOutput => {
  const showMixMatchSwitch = useShowMixMatchSwitch(product)
  const showPrescriptionSwitch = useShowPrescriptionSwitch(product)

  return { showMixMatchSwitch, showPrescriptionSwitch }
}

export default usePurchaseOptions
