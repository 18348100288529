import { useThemeContext } from '@hooks/useThemeContext'
import { isIPadView } from '@libs/utils'
import { get } from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

declare global {
  interface Window {
    webkit?: {
      messageHandlers?: {
        iosClickListener?: {
          postMessage: (args: unknown) => void
        }
      }
    }
  }
}

export const isDigitalCouvetteAvailable = () =>
  isIPadView() && !!window.webkit && get(window, 'webkit.messageHandlers.iosClickListener')

export const useDigitalCouvette = () => {
  const theme = useThemeContext()
  const { t } = useTranslation()
  const analytics = JSON.stringify(window.utag_data)

  return useCallback(() => {
    const iosClickListener = get(window, 'webkit.messageHandlers.iosClickListener')
    iosClickListener &&
      iosClickListener.postMessage({
        action: 'openCouvette',
        main_color: theme.colors.highlight04,
        toggle_color: theme.colors.highlight02,
        toggle_inactive_color: theme.colors.inactive,
        font_color: theme.colors.primaryDark,
        inactive_color: theme.colors.inactive,
        even_size_color: theme.colors.background,
        odd_size_color: theme.colors.outline,
        selected_size_color: '8',
        title_font: theme.fonts.heading1.fontFamily,
        body_font: theme.fonts.body.fontFamily,
        text_title: t('sizeAndFit.digitalCouvette.title'),
        text_subtitle: t('sizeAndFit.digitalCouvette.subtitle'),
        text_sizeSelector: t('sizeAndFit.digitalCouvette.sizeSelector'),
        text_howTo: t('sizeAndFit.digitalCouvette.howTo'),
        text_toggle: t('sizeAndFit.digitalCouvette.toggle'),
        text_box1: t('sizeAndFit.digitalCouvette.box1'),
        text_box2: t('sizeAndFit.digitalCouvette.box2'),
        text_box3: t('sizeAndFit.digitalCouvette.box3'),
        text_rotate: t('sizeAndFit.digitalCouvette.rotateDevice'),
        tealium_data: analytics,
      })
  }, [
    analytics,
    t,
    theme.colors.background,
    theme.colors.highlight02,
    theme.colors.highlight04,
    theme.colors.inactive,
    theme.colors.outline,
    theme.colors.primaryDark,
    theme.fonts.body.fontFamily,
    theme.fonts.heading1.fontFamily,
  ])
}
