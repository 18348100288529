import { IconProps } from '@abstractTypes/icons'


export const FootwearIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66 13.627a4.805 4.805 0 0 0-1.362-.104c-.598.04-1.18.218-1.702.522-.504.298-.937.71-1.266 1.204a4.083 4.083 0 0 0-.638 1.652l-1.4 9.548-.214 1.304a5.874 5.874 0 0 0 .53 3.56 6.442 6.442 0 0 0 2.353 2.638 6.146 6.146 0 0 0 3.346.95h46.12c.893-.001 1.769-.258 2.53-.741a4.949 4.949 0 0 0 1.782-1.999A12.432 12.432 0 0 0 59 26.722a5.587 5.587 0 0 0-.843-2.884 5.328 5.328 0 0 0-2.196-1.98c-1.702-.835-4.072-1.879-6.304-2.844a48.925 48.925 0 0 1-11.725-7.16L25.147 1.34a1.318 1.318 0 0 0-1.518-.095 1.38 1.38 0 0 0-.398.369L21.642 3.78a5.658 5.658 0 0 0-.96 2.134 5.757 5.757 0 0 0-.023 2.353l.403 2.074a4.042 4.042 0 0 1-.087 1.933 3.94 3.94 0 0 1-.972 1.654 8.181 8.181 0 0 1-5.837 2.478h-.278a6.63 6.63 0 0 1-4.06-1.487 5.63 5.63 0 0 0-2.168-1.292z"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M1.002 26.944h15.13c1.042 0 2.074.208 3.039.613l3.24 1.305a8.983 8.983 0 0 0 3.455.69H59M36 10l-5.368 6.34M39.5 12.5l-5.086 6.488M43.5 15l-5.303 6.636M20 14l2.436-1.312a6.287 6.287 0 0 1 2.71-.616c.937 0 1.786.21 2.636.616L31.5 14.5"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  )
}
