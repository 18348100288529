import { ShopperSlugWithFallback } from '@abstractTypes/envs'
import { ThemeOverride } from '@abstractTypes/theme'
import { alTheme } from '@smartshoppers/al/theme'
import { dcTheme } from '@smartshoppers/dc/theme'
import { goTheme } from '@smartshoppers/go/theme'
import { gviTheme } from '@smartshoppers/gvi/theme'
import { gvifeTheme } from '@smartshoppers/gvife/theme'
import { lcTheme } from '@smartshoppers/lc/theme'
import { lpTheme } from '@smartshoppers/lp/theme'
import { moTheme } from '@smartshoppers/mo/theme'
import { ocTheme } from '@smartshoppers/oc/theme'
import { ooTheme } from '@smartshoppers/oo/theme'
import { opsmTheme } from '@smartshoppers/opsm/theme'
import { pvTheme } from '@smartshoppers/pv/theme'
import { rbTheme } from '@smartshoppers/rb/theme'
import { sghTheme } from '@smartshoppers/sgh/theme'
import { sghofTheme } from '@smartshoppers/sghof/theme'
import { shTheme } from '@smartshoppers/sh/theme'
import { solTheme } from '@smartshoppers/sol/theme'
import { svTheme } from '@smartshoppers/sv/theme'
import { toTheme } from '@smartshoppers/to/theme'
import { tvTheme } from '@smartshoppers/tv/theme'
import { defaultTheme } from '@theme/index'
import { merge } from 'lodash'

const shopperThemeForBanner: { [key in ShopperSlugWithFallback]?: ThemeOverride } = {
  opsm: opsmTheme,
  sgh: sghTheme,
  dc: dcTheme,
  go: goTheme,
  gvi: gviTheme,
  gvife: gvifeTheme,
  lc: lcTheme,
  salmoiraghi: svTheme,
  sghof: sghofTheme,
  oc: ocTheme,
  pv: pvTheme,
  oakley: ooTheme,
  rayban: rbTheme,
  al: alTheme,
  lp: lpTheme,
  sol: solTheme,
  to: toTheme,
  sh: shTheme,
  tv: tvTheme,
  mo: moTheme,
  master: defaultTheme,
}

export const getTheme = (shopperSlug: ShopperSlugWithFallback) => {
  const theme = shopperThemeForBanner[shopperSlug]
  return merge({}, defaultTheme, theme)
}
