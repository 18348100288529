import { useCallback, useMemo, useReducer } from 'react'

export type Notification = {
  id: string
  message: string
  type: 'polite' | 'assertive'
}

export type NotificationState = Notification[]

const NOTIFICATION_TIMEOUT = 5000

const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

type NotificationAction = {
  payload: Notification
  type: typeof ADD_NOTIFICATION | typeof REMOVE_NOTIFICATION
}

export type SendNotificationAction = (message: string, type?: Notification['type']) => void

const reducer = (state: NotificationState, action: NotificationAction) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.payload]
    case REMOVE_NOTIFICATION:
      return state.filter(notification => notification.id !== action.payload.id)
    default:
      return state
  }
}

let uniqueId = 0
const createNotification = (message: string, type: Notification['type']): Notification => {
  const id = (++uniqueId).toString()
  return {
    message,
    type,
    id,
  }
}

const useNotifications = () => {
  const [notifications, dispatch] = useReducer(reducer, [])

  const sendNotification = useCallback<SendNotificationAction>(
    (message, type = 'polite') => {
      const notification = createNotification(message, type)
      dispatch({
        payload: notification,
        type: ADD_NOTIFICATION,
      })

      setTimeout(
        () =>
          dispatch({
            payload: notification,
            type: REMOVE_NOTIFICATION,
          }),
        NOTIFICATION_TIMEOUT
      )
    },
    [dispatch]
  )

  const politeNotifications = useMemo(
    () => notifications.filter(notification => notification.type === 'polite'),
    [notifications]
  )
  const assertiveNotifications = useMemo(
    () => notifications.filter(notification => notification.type === 'assertive'),
    [notifications]
  )

  return {
    notifications,
    politeNotifications,
    assertiveNotifications,
    sendNotification,
  }
}

export default useNotifications
