import ConditionalRender from '@components/ConditionalRender'
import { Price } from '@components/Price/Price'
import { PrimaryButton } from '@components/core/Button'
import { Col, Row } from '@components/core/Grid'
import { Heading5, Text } from '@components/core/Typography'
import useShowPrice from '@hooks/useShowPrice'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  CartSummaryContainer,
  CartSummaryTotal,
  GoToBagButton,
  HeadingWithPrice,
  PriceText,
  SubtotalSections,
} from './styles'
import { CartSummaryProps } from './types'

interface EngravingElementBase {
  price: number
}

export const EngravingCheckoutPrice = styled(Text)`
  text-align: end;
`

const EngravingElementBase = ({ price }: EngravingElementBase) => {
  const { t } = useTranslation()
  return (
    <Row className="engraving">
      <Col span={12}>
        <Text color="inactive" className="engraving-label">
          {t('Engraving.engravingFee')}
        </Text>
      </Col>
      <Col span={12}>
        <EngravingCheckoutPrice color="primaryDark" className="engraving-price">
          <Price price={price} />
        </EngravingCheckoutPrice>
      </Col>
    </Row>
  )
}

export const CartSummary = ({
  className,
  subtot,
  showBagButton = false,
  hideCheckOutButton,
  engravingInfo,
  tot,
  dataAttrs,
  hideSubtotalSection,
  hideShippingSection,
  columnsLayout = [12, 12],
  shippingPrice = 0,
}: CartSummaryProps) => {
  const { basePath } = useStoreIndentity()
  const { t } = useTranslation()
  const showPrice = useShowPrice()
  const showEngravingPrice = engravingInfo && engravingInfo.numberItems > 0

  const renderSubtotal = () => (
    <Row>
      <Col span={columnsLayout[0]}>
        <Text color="inactive">{t('SummaryCart.subtotal')}</Text>
      </Col>
      <Col span={columnsLayout[1]}>
        <PriceText>
          <Price price={subtot} />
        </PriceText>
      </Col>
    </Row>
  )

  const renderShippingSection = () => (
    <Row>
      <Col span={columnsLayout[0]}>
        <Text color="inactive">{t('SummaryCart.shipping')}</Text>
      </Col>
      <Col span={columnsLayout[1]}>
        <PriceText>
          {shippingPrice ? <Price price={shippingPrice} /> : t('SummaryCart.free')}
        </PriceText>
      </Col>
    </Row>
  )

  return (
    <CartSummaryContainer className={className}>
      <SubtotalSections>
        <ConditionalRender
          condition={showPrice}
          render={() => (
            <>
              <ConditionalRender condition={!hideSubtotalSection} render={() => renderSubtotal()} />
              <ConditionalRender
                condition={showEngravingPrice}
                render={() => <EngravingElementBase price={engravingInfo.price} />}
              />
              <ConditionalRender
                condition={!hideShippingSection}
                render={() => renderShippingSection()}
              />
            </>
          )}
        />
      </SubtotalSections>

      <ConditionalRender
        condition={showPrice}
        render={() => (
          <CartSummaryTotal justify={'space-between'} align={'center'}>
            <Heading5 color="primaryDark">{t('SummaryCart.total')}</Heading5>
            <HeadingWithPrice color="primaryDark">
              <Price price={tot} />
            </HeadingWithPrice>
          </CartSummaryTotal>
        )}
      />

      <ConditionalRender
        condition={!hideCheckOutButton}
        render={() => (
          <PrimaryButton navigateTo={`${basePath}/cart/checkout`} className="checkout">
            {t('SummaryCart.secureCheckout')}
          </PrimaryButton>
        )}
      />

      <ConditionalRender
        condition={showBagButton}
        render={() => (
          <GoToBagButton
            dataAttrs={{
              id: dataAttrs && dataAttrs.bagBtn,
              test: 'button-cart',
            }}
            id="go-to-bag"
            navigateTo={`${basePath}/cart`}
            ariaAttrs={{ label: `${t('SummaryCart.go')} ${tot}` }}
            fullWidth
          >
            {t('SummaryCart.go')}
          </GoToBagButton>
        )}
      />
    </CartSummaryContainer>
  )
}
