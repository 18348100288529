import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const lcTheme: ThemeOverride = {
  colors: {
    primaryDark: '#222222',
    primaryDarkPressed: '#444444',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#F6F6F6',
    inactive: '#999999',
    outline: '#D8D8D8',
    background: '#F6F6F6',
    highlight01: '#5C7B92',
    highlight01Pressed: '#192C50',
    highlight02: '#5C7B92',
    highlight02Pressed: '#192C50',
    highlight03: '#192C50',
    highlight04: '#557B92',
    functional01: '#D32113',
    functional02: '#247534',
    functional03: '#F0A63C',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'MinervaModern',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 100,
        tablet: 75,
        tabletMini: 57,
      },
      lineHeight: {
        tower: 115,
        tablet: 80,
        tabletMini: 72,
      },
    },
    heading1: {
      fontFamily: 'MinervaModern',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 64,
        tablet: 56,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 68,
        tablet: 62,
        tabletMini: 60,
      },
    },
    heading2: {
      fontFamily: 'MinervaModern',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 48,
        tablet: 40,
        tabletMini: 34,
      },
      lineHeight: {
        tower: 48,
        tablet: 48,
        tabletMini: 46,
      },
    },
    heading3: {
      fontFamily: 'MinervaModern',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 32,
        tablet: 32,
        tabletMini: 24,
      },
      lineHeight: {
        tower: 44,
        tablet: 44,
        tabletMini: 34,
      },
    },
    heading4: {
      fontFamily: 'SofiaPro',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 30,
        tablet: 32,
        tabletMini: 31,
      },
    },
    heading5: {
      fontFamily: 'SofiaPro',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 26,
        tablet: 30,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'SofiaPro',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 16,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'SofiaPro',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 24,
        tablet: 20,
        tabletMini: 18,
      },
    },
    cta: {
      fontFamily: 'SofiaPro',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 16,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 26,
        tabletMini: 26,
      },
    },
    link: {
      fontFamily: 'SofiaPro',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 36,
        tablet: 32,
        tabletMini: 31,
      },
    },
    caption: {
      fontFamily: 'SofiaPro',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 13,
        tablet: 13,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 16,
        tablet: 16,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'rounded',
      borderRadius: 64,
      uppercase: true,
    },
    rangeSlider: {
      background: '#999999', //inactive
      foreground: '#5C7B92', //highlight01
      thumb: '#F6F6F6', //background
      thumbBorder: '#999999', //inactive
    },
  },
}
