import { useCallback, useEffect, useRef } from 'react'
import { useCustomKeydownHandler } from './useCustomKeydownHandler'
import { useAriaFocusCbWithRestore } from './useAriaFocusWithReturnToPrev'
import { FOCUS_SELECTOR, setAutofocusWithDelay } from '@libs/utils'

const handleFocusTrapping = (node: HTMLElement, event: KeyboardEvent) => {
  const focusableChildren = node.querySelectorAll<HTMLElement>(FOCUS_SELECTOR)
  if (!focusableChildren.length) {
    event.preventDefault()
    return
  }

  const lastFocusableElement = focusableChildren[event.shiftKey ? 0 : focusableChildren.length - 1]
  const isLastActive = document.activeElement === lastFocusableElement
  if (!isLastActive) return

  event.preventDefault()
  const nextTarget = focusableChildren[event.shiftKey ? focusableChildren.length - 1 : 0]
  if (nextTarget) {
    nextTarget.focus()
  }
}

export const useAriaFocusTrap = (condition: boolean) => {
  const handleKeyDown = useCallback((e: KeyboardEvent, targetElement: HTMLElement) => {
    if (e.key === 'Tab') {
      handleFocusTrapping(targetElement, e)
    }
  }, [])
  const { setRef: setKeyboardHandlerRef } = useCustomKeydownHandler(handleKeyDown, !condition)
  const { focus, restoreFocus, setRef: setFocusCbRef } = useAriaFocusCbWithRestore()

  const refInternal = useRef<HTMLElement | null>(null)
  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (!node) return
      refInternal.current = node
      setFocusCbRef(node)
      setKeyboardHandlerRef(node)
    },
    [setFocusCbRef, setKeyboardHandlerRef]
  )

  useEffect(() => {
    const targetElement = refInternal.current
    if (!targetElement || !condition) return

    targetElement.setAttribute('tabindex', '0')
    focus()

    return () => {
      restoreFocus()
      targetElement?.removeAttribute('tabindex')
    }
  }, [condition, focus, restoreFocus])

  useEffect(() => {
    const targetElement = refInternal.current
    if (!targetElement || !condition) return

    const focusableElement = targetElement.querySelector<HTMLElement>('[data-autofocus]')
    if (!focusableElement) return

    /*
      For the screen reader:
      using data-autofocus instead of autofocus to make autofocus delayed,
      because when opening a modal, the focus should be on that modal and not on the autofocus child with.
    */
    setAutofocusWithDelay(focusableElement)
  }, [condition])

  return { setRef }
}
