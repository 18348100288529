import { AppConfigOverride } from '@abstractTypes/envs'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getOoEnvVariables = () => {
  const ooEnvVariables: AppConfigOverride = {
    shopperSlug: 'oakley',
    default: {
      faPlaylistType: FaPlaylistType.SUN,
      productSubdivision: 'productHierarchy',
    },
    storeID: 'B105',
    storeLang: 'en-US',
    shopperName: 'OAKLEY',
    productImageUrl: 'https://smartshopper-im.luxottica.com',
    menuStyleVariant: 'LIST',
    vmStore: 'oo',
    vmStyle: 'oo',
    pictureConfig: {
      priority: [
        'Tech_ProductImages_AFA',
        'Brand',
        'PI21',
        'PI20',
        'EXT',
        'Degree',
        'Catalog image',
      ],
    },
    productTileShape: 'SQUARED',
    isMonoBrand: true,
  }

  return ooEnvVariables
}

export default getOoEnvVariables
