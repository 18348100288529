import { pxToRem, theme } from '@libs/styled'
import { generateDefaultFontProperties } from '@libs/typography'
import styled from 'styled-components'
import { InputTextBase } from '../InputText/InputText'

export const InputStyled = styled(InputTextBase)`
  ${({ theme }) => generateDefaultFontProperties(theme, theme.fonts.body, {})}
  inline-size: 100%;
  padding-inline-start: 1em;
  border: 1px solid ${theme('input_border_color')};
  border-radius: ${pxToRem(8)};
  color: ${theme('input_color')};
  letter-spacing: 0.3px;
  line-block-size: ${pxToRem(48)};
  &::placeholder {
    color: ${theme('select_placeholder')} !important;
  }
`
