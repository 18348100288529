import { IconProps } from '@abstractTypes/icons'

export const ScreenReaderIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 39"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.5386 8.338L35.9996 4.523V2.799C35.9996 1.256 34.7446 0 33.2006 0H14.7986C13.2546 0 11.9996 1.256 11.9996 2.799V6.523L2.45963 10.338C0.94463 10.945 0.18863 12.679 0.77463 14.201L9.33863 36.468C9.62863 37.223 10.1966 37.817 10.9376 38.14C11.3226 38.308 11.7296 38.392 12.1376 38.392C12.5136 38.392 12.8916 38.321 13.2526 38.176L26.4996 32.878L34.7456 36.176C35.1066 36.321 35.4836 36.392 35.8606 36.392C36.2686 36.392 36.6746 36.308 37.0606 36.14C37.8016 35.817 38.3696 35.223 38.6606 34.468L47.2236 12.201C47.8106 10.679 47.0546 8.945 45.5386 8.338ZM36.2606 34.307C36.0126 34.413 35.7396 34.42 35.4896 34.319L29.6926 32H33.2006C34.7446 32 35.9996 30.744 35.9996 29.201V6.677L44.7946 10.195C45.3006 10.397 45.5526 10.975 45.3576 11.482L36.7936 33.749C36.6966 34.001 36.5076 34.199 36.2606 34.307ZM11.7386 36.307C11.4916 36.2 11.3026 36.001 11.2056 35.749L2.64163 13.482C2.44663 12.975 2.69863 12.397 3.20363 12.195L19.4896 5.681C19.6096 5.632 19.7356 5.609 19.8616 5.609C19.9976 5.609 20.1326 5.637 20.2616 5.693C20.5086 5.8 20.6976 5.999 20.7946 6.251L29.3586 28.518C29.5536 29.025 29.3016 29.603 28.7966 29.805L12.5096 36.318C12.2596 36.42 11.9846 36.413 11.7386 36.307ZM14.7986 2H33.2006C33.6416 2 33.9996 2.358 33.9996 2.799V29.201C33.9996 29.642 33.6416 30 33.2006 30H31.1936C31.4716 29.317 31.5066 28.534 31.2236 27.799L22.6596 5.532C22.3696 4.777 21.8016 4.183 21.0606 3.86C20.3206 3.537 19.4986 3.522 18.7456 3.824L13.9996 5.723V2.799C13.9996 2.358 14.3576 2 14.7986 2Z"
        fill={fill}
      />
    </svg>
  )
}
