import { AdditionalQueryStringAttributes } from '@abstractTypes/frameAdvisor'
import { ProductPriceType } from '@abstractTypes/price'
import { DataAttrs, Product } from '@abstractTypes/product'
import { ProductInfoConfig, ProductInfoConfigIds } from '@abstractTypes/tiles'
import { TypographyProps } from '@abstractTypes/typography'
import { BestMatchInfo } from '@components/CardProductBase/ProductCard/BestMatchInfo'
import { ProductAddBuyButton } from '@components/CardProductBase/ProductCard/ProductAddBuyButton'
import { ProductPreview } from '@components/CardProductBase/ProductCard/ProductPreview'
import ConditionalRender from '@components/ConditionalRender'
import { RoundCheckmarkIcon } from '@components/core/Icons/RoundCheckmark'
import config from '@config/index'
import { useCustomizableEnabled } from '@hooks/useCustomizableEnabled'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import { useRTRVisibility } from '@hooks/useRTRVisibility'
import useShowPrice from '@hooks/useShowPrice'
import { useTranslateFilterValue } from '@hooks/useTranslateFilterValue'
import { useVMVisibility } from '@hooks/useVMVisibility'
import { useGetProductUrl } from '@libs/formatters'
import { pxToRem } from '@libs/styled'
import { getQueryParamsOnlyDefined } from '@libs/url'
import { isSquaredProductTile } from '@libs/utils'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { LoveHateActionsBtns } from './LoveHateActionsBtns'
import {
  AddBuyWrapper,
  BrandStyled,
  CardBodyStyled,
  CardProductContainerStyled,
  CardProductLinkStyled,
  CheckedIcon,
  Chips,
  ColorTitle,
  IconStyled,
  IconsBottomLeft,
  IconsStyled,
  IconsTopRight,
  ModelStyled,
  NameStyled,
  ProductCardInfo,
  QuantityStyled,
  TrashButton,
  VisuallyHidden,
  ProductOverlay,
  ProductOverlayContent,
  ProductOverlayHeading,
} from './ProductCard.style'
import { ProductColors } from './ProductColors'
import { getProductCardIcons } from './ProductIcons'
import { ProductImage } from './ProductImage'
import { ProductPrice } from './ProductPrice'
import { ProductWishListActionButton } from './ProductWishListActionButton'

export type ImageSize = 'small' | 'standard' | 'large' | 'huge' | 'extended' | 'massive'
export type ImageSizeDressedOn = 'standard' | 'large' | 'extended'

export interface ProductCardProps {
  product: Product
  quantity?: number
  showWishlistBtn?: boolean
  showLoveHateBtns?: boolean
  showProductCode?: boolean
  dataAttrs?: DataAttrs
  showIcons?: boolean
  showEngravingIcon?: boolean
  showRTRIcon?: boolean
  showVTOIcon?: boolean
  showCheckedIcon?: boolean
  showOverlay?: boolean
  showCustomizableIcon: boolean
  priceStyle?: ProductPriceType
  size?: ImageSize
  className?: string
  /** whether model code should be hilighed as brand or model name */
  highlightModelCode?: boolean
  additionalQueryStringAttributes?: AdditionalQueryStringAttributes
  accessibilityData?: { [key: string]: string }
  dressedOnImageUrl?: string
  showAddBuyBtn?: boolean
  playlistId?: string
  index?: number
  onTrashIconClick?: () => void
  showTrashIcon?: boolean
  showShadImg?: boolean
  imgWidth?: number
  asLink?: boolean
  customImage?: React.ReactElement
  productInfoConfig: ProductInfoConfig
  isDressedOnView?: boolean
  renderPriceAs?: React.FC<TypographyProps>
  engravingPrice?: number
  isBestMatch?: boolean
  bestMatchInfo?: {
    dynamicSentence: string
  }
}

export const ProductWishListActionButtonStyled = styled(ProductWishListActionButton)`
  position: absolute;
  inset-inline-end: ${pxToRem(16)};
  inset-block-end: ${pxToRem(16)};
`

export const renderIcon = (
  iconComponentOrSrc: ((order?: number) => React.ReactNode) | string,
  alt: string,
  order?: number
) =>
  typeof iconComponentOrSrc === 'function' ? (
    iconComponentOrSrc(order)
  ) : (
    <IconStyled data-test={`img-${alt}`} alt="" src={iconComponentOrSrc} />
  )

const getProductCardLinkParams = (
  baseUrl: string,
  additionalQueryStringAttributes?: AdditionalQueryStringAttributes
) => {
  const paramsString = getQueryParamsOnlyDefined(additionalQueryStringAttributes)
  const linkParams = { pathname: baseUrl, search: paramsString }
  return linkParams
}

export const ProductPreviewWrapper = styled.div``

export const ProductCardBase: React.FC<ProductCardProps> = ({
  accessibilityData,
  additionalQueryStringAttributes,
  className,
  dataAttrs,
  dressedOnImageUrl,
  highlightModelCode = false,
  index = 0,
  size = 'standard',
  onTrashIconClick,
  playlistId,
  priceStyle,
  product,
  quantity,
  showAddBuyBtn = false,
  showCheckedIcon = false,
  showCustomizableIcon = false,
  showEngravingIcon = false,
  showIcons = false,
  showLoveHateBtns = false,
  showTrashIcon,
  showVTOIcon = false,
  showRTRIcon = false,
  showWishlistBtn = false,
  showShadImg = false,
  showOverlay = false,
  imgWidth,
  asLink = true,
  customImage,
  productInfoConfig,
  isDressedOnView = false,
  renderPriceAs,
  engravingPrice,
  bestMatchInfo,
  isBestMatch,
}) => {
  const isCustomizableEnabled = useCustomizableEnabled()
  const getProductUrl = useGetProductUrl()
  const url = getProductUrl(product.url)

  const {
    UPC,
    moco,
    customizable,
    polarized,
    vto,
    colorsAvailable,
    thumbnails,
    recipeId,
    customItem,
    originalBrandCode,
  } = product

  // TODO: check what do we need these params for?
  const linkParams = getProductCardLinkParams(url, additionalQueryStringAttributes)

  const history = useHistory()
  const onRedirect = useCallback(() => {
    linkParams && history.push(linkParams)
  }, [history, linkParams])

  const { t } = useTranslation()
  const showPriceEnabled = useShowPrice()
  const translateFilterValue = useTranslateFilterValue()
  const rtlDirection = useLanguageDirection()

  const availableColors = (product.variants && product.variants.length + 1) || 0

  const productCardId = `productCard_${UPC}`

  const modelName = product.model.startsWith('0') ? product.model.substring(1) : product.model

  const iconSet = getProductCardIcons(config.shopperSlug)
  const showBadges = !product.customItem && product.badges
  const { isRTREnabled } = useRTRVisibility()
  const { isVMEnabled } = useVMVisibility()
  const hasRTR = product.rtr
  const hasVTO = product.vto
  const shouldShowVtoIcon = !isRTREnabled && isVMEnabled && showVTOIcon && hasVTO
  const shouldShowRtrIcon = isRTREnabled && showRTRIcon && hasRTR

  const renderIcons = () => (
    <IconsStyled>
      {showBadges && <Chips badges={product.badges.tile} />}
      <IconsBottomLeft>
        {shouldShowVtoIcon && renderIcon(iconSet.vtoIcon.icon, 'vto', iconSet.vtoIcon.order)}
        {shouldShowRtrIcon && renderIcon(iconSet.rtrIcon.icon, 'rtr', iconSet.rtrIcon.order)}
        {showEngravingIcon &&
          renderIcon(iconSet.engravingIcon.icon, 'engravable', iconSet.engravingIcon.order)}
        {showCustomizableIcon &&
          customizable &&
          isCustomizableEnabled(product.originalBrandCode) &&
          renderIcon(
            iconSet.customIcon.icon,
            'oneConfigurator available',
            iconSet.customIcon.order
          )}
      </IconsBottomLeft>
      <IconsTopRight rtlDirection={rtlDirection}>
        {showCheckedIcon && (
          <CheckedIcon order={iconSet.chekedIcon.order}>
            {renderIcon(iconSet.chekedIcon.icon, 'checked')}
          </CheckedIcon>
        )}
        {showTrashIcon && (
          <TrashButton
            onClick={onTrashIconClick}
            order={iconSet.trashIcon.order}
            data-element-id={dataAttrs && dataAttrs.removeBtn}
            data-description={dataAttrs && dataAttrs.description}
            data-test={dataAttrs && dataAttrs.removeBtn}
            aria-label={t('Core.remove')}
          >
            {renderIcon(iconSet.trashIcon.icon, 'remove')}
          </TrashButton>
        )}
      </IconsTopRight>
    </IconsStyled>
  )

  const getInfoModuleById = (id: ProductInfoConfigIds) =>
    productInfoConfig.modules.find(info => info.id === id)

  const renderColorname = () => {
    const infoModule = getInfoModuleById('colorname')
    const showColorname = !!infoModule
    const { isAfa } = product

    const colorName = [
      `${translateFilterValue(product.frameColorLabel, 'frameColorLabel')}`,
      `${translateFilterValue(product.lensColorLabel, 'lensColorLabel')}`,
    ]
      .filter(Boolean)
      .join('/')

    const colorAfaName = `${translateFilterValue(product.colorLabel, 'colorLabel')}`

    const colorTitle = isAfa ? colorAfaName : colorName

    return (
      <ConditionalRender
        condition={showColorname}
        render={() => <ColorTitle weight={infoModule?.style?.weight}>{colorTitle}</ColorTitle>}
      />
    )
  }

  const renderColor = () => {
    const infoModule = getInfoModuleById('color')
    const showColor = !!infoModule

    return (
      <ConditionalRender
        condition={showColor}
        render={() => (
          <ProductColors availableColors={colorsAvailable ? colorsAvailable : availableColors} />
        )}
      />
    )
  }

  const renderBrand = () => {
    const infoModule = getInfoModuleById('brand')
    const showProductBrandName = !!infoModule

    return (
      <ConditionalRender
        condition={showProductBrandName}
        render={() => (
          <BrandStyled
            weight={infoModule?.style?.weight}
            data-test="text-brand"
            aria-label={product.brand.name}
            aria-description={product.brand.name}
          >
            {product.brand.name}
          </BrandStyled>
        )}
      />
    )
  }

  const renderQuantity = () => {
    const showQuantity = !!getInfoModuleById('quantity')
    return (
      <ConditionalRender
        condition={showQuantity}
        render={() => <QuantityStyled>{`${t('Core.quantity')} ${quantity}`}</QuantityStyled>}
      />
    )
  }

  const renderProductName = () => {
    const infoModule = getInfoModuleById('name')
    const showProductName = !!infoModule

    return (
      <ConditionalRender
        condition={showProductName}
        render={() => (
          <NameStyled
            weight={infoModule?.style?.weight}
            aria-label={product.name}
            aria-description={product.name}
            data-test={dataAttrs?.productName ? dataAttrs.productName : 'context-productName'}
            transform={infoModule?.style?.transform}
          >
            {product.name}
          </NameStyled>
        )}
      />
    )
  }

  const renderModelCode = () => {
    const showModelCode = !!getInfoModuleById('modelcode')
    const weight = highlightModelCode ? 'bold' : undefined

    return (
      <ConditionalRender
        condition={showModelCode}
        render={() => (
          <ModelStyled
            data-test="context-modelName"
            highlight={highlightModelCode}
            aria-label={modelName}
            aria-description={modelName}
            weight={weight}
          >
            {modelName}
          </ModelStyled>
        )}
      />
    )
  }

  const renderPrice = () => {
    const infoModule = getInfoModuleById('price')
    const showPrice = showPriceEnabled && !!infoModule

    return (
      <ConditionalRender
        condition={showPrice}
        render={() => (
          <ProductPrice
            product={product}
            priceStyle={priceStyle}
            quantity={quantity}
            dataAttr={dataAttrs}
            weight={infoModule?.style?.weight}
            renderPriceAs={renderPriceAs}
            engravingPrice={engravingPrice}
          />
        )}
      />
    )
  }

  const renderBestMatch = () => {
    const showBestMatch = !isDressedOnView && isBestMatch

    return (
      <ConditionalRender
        condition={showBestMatch}
        render={() => (
          <BestMatchInfo
            product={product}
            dataAttrs={dataAttrs}
            dynamicSentence={bestMatchInfo?.dynamicSentence}
          />
        )}
      />
    )
  }

  const renderProductInfo = () => {
    const productInfoConfigCallbacks: Record<ProductInfoConfigIds, () => React.ReactNode> = {
      brand: renderBrand,
      name: renderProductName,
      quantity: renderQuantity,
      color: renderColor,
      price: renderPrice,
      colorname: renderColorname,
      modelcode: renderModelCode,
      bestMatch: renderBestMatch,
    }

    const infoModules = React.Children.toArray(
      productInfoConfig.modules.map(info => {
        const { id } = info
        const cb = productInfoConfigCallbacks[id]
        return cb()
      })
    )

    return <>{infoModules}</>
  }

  return (
    <CardProductContainerStyled
      isDressedOn={isDressedOnView}
      size={size}
      className={className}
      data-test={`context-${moco}`}
    >
      {asLink && (
        <CardProductLinkStyled
          role="button"
          onClick={onRedirect}
          aria-labelledby={productCardId}
          data-element-id={dataAttrs && dataAttrs.link}
          data-test={dataAttrs && dataAttrs.link}
          data-description={dataAttrs && dataAttrs.description}
        />
      )}
      <ProductPreviewWrapper>
        <ProductPreview
          backgroundColor={productInfoConfig.style?.background}
          isSquared={isSquaredProductTile()}
          isDressedOnView={isDressedOnView}
        >
          {showOverlay && (
            <ProductOverlay>
              <ProductOverlayContent>
                <RoundCheckmarkIcon width={pxToRem(40)} height={pxToRem(40)} />
                <ProductOverlayHeading weight={'bold'}>
                  {t('ProductView.added')}
                </ProductOverlayHeading>
              </ProductOverlayContent>
            </ProductOverlay>
          )}
          {showIcons && renderIcons()}
          {customImage ? (
            customImage
          ) : (
            <ProductImage
              thumbnails={thumbnails}
              originalBrandCode={originalBrandCode}
              recipeId={recipeId}
              customItem={customItem}
              dressedOnImageUrl={dressedOnImageUrl}
              showShadImg={showShadImg}
              imgWidth={imgWidth}
            />
          )}
          {showWishlistBtn && (
            <ProductWishListActionButtonStyled
              product={product}
              dataAttrs={dataAttrs}
              buttonSize="large"
            />
          )}
          {showLoveHateBtns && !!playlistId && (
            <LoveHateActionsBtns
              product={product}
              playlistId={playlistId}
              dataAttrs={dataAttrs}
              index={index}
            />
          )}
          {isBestMatch && isDressedOnView && (
            <BestMatchInfo
              product={product}
              dataAttrs={dataAttrs}
              dynamicSentence={bestMatchInfo?.dynamicSentence}
              isDressedOnView={true}
            />
          )}
        </ProductPreview>
      </ProductPreviewWrapper>
      <CardBodyStyled>
        <ProductCardInfo
          aria-hidden
          id={productCardId}
          view={productInfoConfig.style?.view}
          align={productInfoConfig.style?.align}
        >
          {renderProductInfo()}
          <VisuallyHidden>{moco}</VisuallyHidden>
          {showVTOIcon && vto && accessibilityData && (
            <VisuallyHidden>{accessibilityData.vm}</VisuallyHidden>
          )}
          {polarized && accessibilityData && (
            <VisuallyHidden>{accessibilityData.polarized}</VisuallyHidden>
          )}
        </ProductCardInfo>
        {showAddBuyBtn && (
          <AddBuyWrapper>
            <ProductAddBuyButton product={product} index={index} />
          </AddBuyWrapper>
        )}
      </CardBodyStyled>
    </CardProductContainerStyled>
  )
}
