import { useAriaFocusTrap } from '@hooks/useAriaFocusTrap'
import { useCombinedRefs } from '@hooks/useCombinedRefs'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { usePortalDiv } from '@hooks/usePortalDiv'
import React, { useCallback, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { DrawerContainer, DrawerOverlay } from './styles'
import { DrawerProps } from './types'

export const DrawerBaseContainer: React.FC<DrawerProps> = ({
  children,
  position = 'left',
  visible = false,
  drawerKey,
  isFullscreen = false,
  className,
  unmountOnExit = true,
  showOverlay = true,
}) => {
  const { closeAnyDrawer } = useDrawerHandler()
  const [isTransitionEnd, setIsTransitionEnd] = useState(false)
  const { setRef } = useAriaFocusTrap(visible && isTransitionEnd)
  const drawerRef = useRef(null)
  const drawerOverlayRef = useRef(null)
  const combinedRefs = useCombinedRefs<HTMLDivElement>(setRef, drawerRef)

  const onExitHandler = useCallback(() => {
    setIsTransitionEnd(false)
  }, [])
  const onEnteredHandler = useCallback(() => {
    setIsTransitionEnd(true)
  }, [])

  return (
    <>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        classNames="visible"
        in={visible}
        appear={visible}
        timeout={300}
        nodeRef={drawerOverlayRef}
      >
        <DrawerOverlay ref={drawerOverlayRef} showOverlay={showOverlay} onClick={closeAnyDrawer} />
      </CSSTransition>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        classNames="visible"
        in={visible}
        appear={visible}
        timeout={300}
        onEntered={onEnteredHandler}
        onExit={onExitHandler}
        nodeRef={combinedRefs}
      >
        <DrawerContainer
          position={position}
          isFullscreen={isFullscreen}
          className={className || `drawer__${drawerKey}`}
          ref={combinedRefs}
          aria-hidden={!visible}
          role="dialog"
          aria-modal={visible}
        >
          {children}
        </DrawerContainer>
      </CSSTransition>
    </>
  )
}

export const drawerClassName = 'ss-drawer__container'

export const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  const divEl = usePortalDiv(drawerClassName)

  if (!divEl) return null

  return ReactDOM.createPortal(
    <DrawerBaseContainer {...props}>{children}</DrawerBaseContainer>,
    divEl
  )
}

export const DrawerPortal: React.FC = ({ children }) => {
  const divEl = usePortalDiv(drawerClassName)

  if (!divEl) return null

  return ReactDOM.createPortal(children, divEl)
}
