import { getBrowserStorageKey } from '@config/config.base'
import config from '@config/index'
import { useRedirectToCustomerOrderApp } from '@hooks/useCustomOrder'
import { useResetSS } from '@hooks/useResetSS'
import { debounce } from 'lodash'
import React, { SyntheticEvent, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { useTimer } from '../hooks/useIdle'
import { useLocalStorage } from '../hooks/useLocalStorage'

export type ResetContextValue = {
  userInteractedAfterReset: boolean
  reset: () => void
}

export const defaultResetContext: ResetContextValue = {
  userInteractedAfterReset: false,
  reset: () => {
    // default value for createContext
  },
}

export const ResetContext = React.createContext(defaultResetContext)

export const useResetContext = () => {
  const context = useContext(ResetContext)
  if (context === defaultResetContext) {
    throw new Error('useResetContext must be used within a ResetContextProvider')
  }
  return context
}

export const ResetWrapper = styled.div`
  display: block;
  block-size: 100%;
`

const USER_INTERACTION_EVENT_DEBOUNCE_TIMEOUT_MS = 300

export const ResetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userInteractedAfterReset, setUserInteractedAfterReset] = useLocalStorage(
    [getBrowserStorageKey(), 'has-user-interacted'].join('-'),
    false
  )

  const redirectToCustomerOrderApp = useRedirectToCustomerOrderApp()
  const resetSS = useResetSS()

  const reset = useCallback(() => {
    setUserInteractedAfterReset(false)
    resetSS()
    redirectToCustomerOrderApp()
  }, [setUserInteractedAfterReset, redirectToCustomerOrderApp, resetSS])

  const resetTimer = useTimer(reset, config.resetAppTimeOut)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userInteraction = useCallback(
    debounce(() => {
      setUserInteractedAfterReset(true)
      resetTimer()
    }, USER_INTERACTION_EVENT_DEBOUNCE_TIMEOUT_MS),
    [resetTimer, setUserInteractedAfterReset]
  )

  const handleUserInteraction = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      if ((e.target as HTMLDivElement)?.hasAttribute('data-reset')) return
      userInteraction()
    },
    [userInteraction]
  )

  const contextValue = useMemo(
    () => ({
      userInteractedAfterReset,
      reset,
    }),
    [reset, userInteractedAfterReset]
  )

  return (
    <ResetContext.Provider value={contextValue}>
      <ResetWrapper
        onClick={handleUserInteraction}
        onMouseMove={handleUserInteraction}
        onTouchMove={handleUserInteraction}
        onTouchEnd={handleUserInteraction}
        onKeyUp={handleUserInteraction}
      >
        {children}
      </ResetWrapper>
    </ResetContext.Provider>
  )
}
