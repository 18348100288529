import { Device } from '@abstractTypes/utils'
import { Heading4, Text } from '@components/core/Typography'
import { altNavigation, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const PerfectFrameModalWrapper = styled.div`
  position: fixed;
  inset-inline-end: ${pxToRem(88)};
  inset-block-end: ${pxToRem(300)};
  padding-block: ${pxToRem(40)} ${pxToRem(48)};
  padding-inline: ${pxToRem(40)} ${pxToRem(48)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  inline-size: ${pxToRem(505)};
  block-size: auto;
  background-color: ${({ theme }) => theme.colors.highlight03};
  z-index: 2;

  ${tablet(`
    inline-size: ${clampValues(339, 452)};
    inset-block-end: ${clampValues(198, 264)};
    inset-inline-end: ${clampValues(54, 72)};
    padding-block: ${clampValues(30, 40)} ${clampValues(36, 48)};
    padding-inline:  ${clampValues(30, 40)};
    `)}

  ${landscape(
    `
    inset-block-end: ${pxToRem(118)};
    inset-inline-end: ${pxToRem(112)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${clampValuesLandscape(339, 452)};
    inset-block-end: ${clampValuesLandscape(70, 124)};
    inset-inline-end: ${clampValuesLandscape(72, 96)};
  `
  )}
  ${zoomPortrait(
    [125, 150, 175, 200],
    `
    inline-size: ${pxToRem(660)};
  `
  )}

  ${zoomPortrait(
    [200],
    `
      inline-size: ${pxToRem(860)};
    `
  )}
  ${altNavigation(
    `
    inset-block-end: ${pxToRem(18)};
  `
  )}
`

export const ModalTitle = styled(Heading4)`
  margin-block-end: ${pxToRem(8)};
  ${tablet(
    `
    margin-block-end: ${clampValues(6, 8)};
  `
  )}
`

export const TextPerfectFrame = styled(Text)`
  margin-block-end: ${pxToRem(32)};
  ${tablet(
    `
    margin-block-end: ${clampValues(24, 32)};
  `
  )}
`
export const CloseBtn = styled.button`
  position: absolute;
  padding: 0.25rem;
  inset-block-start: ${pxToRem(16)};
  inset-inline-end: ${pxToRem(16)};
`
