import { BarcodeModalInfo } from '@components/BarCodeScanModal/types'

export const SET_BARCODE_MODAL_INFO = 'barcode/SET_BARCODE_MODAL_INFO'
export const RESET_BARCODE_MODAL_INFO = 'barcode/RESET_BARCODE_MODAL_INFO'
export const SET_BARCODE_MODAL_INITIAL_QUERY = 'barcode/SET_BARCODE_MODAL_INITIAL_QUERY'

export interface SetBarcodeModalInfo {
  type: typeof SET_BARCODE_MODAL_INFO
  payload: BarcodeModalInfo
}

export interface ResetBarcodeModalInfo {
  type: typeof RESET_BARCODE_MODAL_INFO
}

export interface SetBarcodeModalInitialQuery {
  type: typeof SET_BARCODE_MODAL_INITIAL_QUERY
  payload: string
}

export const setBarcodeModalInfo = (info: BarcodeModalInfo): SetBarcodeModalInfo => ({
  type: SET_BARCODE_MODAL_INFO,
  payload: info,
})

export const resetBarcodeModalInfo = (): ResetBarcodeModalInfo => ({
  type: RESET_BARCODE_MODAL_INFO,
})

export const setBarcodeModalInitialQuery = (initialQuery: string): SetBarcodeModalInitialQuery => ({
  type: SET_BARCODE_MODAL_INITIAL_QUERY,
  payload: initialQuery,
})

export type BarcodeActionTypes =
  | SetBarcodeModalInfo
  | ResetBarcodeModalInfo
  | SetBarcodeModalInitialQuery
