import {
  VKContextInterface,
  VKeyboardProviderProps,
  VKeyboardSettings,
} from '@abstractTypes/keyboard'
import { KeyboardedInput } from '@components/TouchKeyboard'
import { VKeyboard } from '@components/TouchKeyboard/VKeyboard'
import { isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

export const VKContext = React.createContext<VKContextInterface | undefined>(undefined)

const defaultSettings = {
  hideKeyboardLabel: 'Close',
}

export const VKeyboardProvider: React.FC<VKeyboardProviderProps> = function (props) {
  const [show, setShow] = useState(false)
  const [srcElement, setSrcElement] = useState<HTMLInputElement | null>(null)
  const [settings, setSettings] = useState<VKeyboardSettings>({
    ...defaultSettings,
    ...props.defaultSettings,
  })

  const keyboardRef = useRef<HTMLDivElement>(null)

  const showKeyboard = React.useCallback(
    (showKeyboard: boolean, newSettings = {}) => {
      const keyboardSettings = { ...defaultSettings, ...props.defaultSettings, ...newSettings }
      if (showKeyboard !== show) {
        setShow(showKeyboard)
      }
      if (!isEqual(keyboardSettings, settings)) {
        setSettings(keyboardSettings)
      }
    },
    [setShow, show, settings, props.defaultSettings]
  )

  const setInput = (targetElement: HTMLInputElement) => {
    setSrcElement(targetElement)
  }

  useEffect(() => {
    if (props.forceKeybordHide) {
      showKeyboard(false)
    }
  }, [props.forceKeybordHide, showKeyboard])

  return (
    <VKContext.Provider value={{ ...settings, showKeyboard, keyboardRef, setInput }}>
      <VKeyboard settings={props.defaultSettings} disabled={props.disabled}>
        {props.children}
      </VKeyboard>
      <KeyboardedInput
        show={show}
        ref={keyboardRef}
        inputNode={srcElement}
        bounds={props.bounds}
        submitClicked={() => {
          // TODO: find the cause of click action after closing on the element under keyboard
          setTimeout(() => {
            setShow(false)
          }, 10)
        }}
        {...settings}
      />
    </VKContext.Provider>
  )
}
