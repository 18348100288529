import { IBlobData, IProductAttribute } from '@abstractTypes/oneConfigurator'
import { BooleanResolver } from '@hooks/useQueryParams'

export const getOCHierarchy = (ocCountry: string, ocCountryFallback = 'US') => {
  const MAPPING: Record<string, string> = {
    AT: 'B2C.RB.EMEA.AT',
    BE: 'B2C.RB.EMEA.BE',
    CH: 'B2C.RB.EMEA.CH',
    DE: 'B2C.RB.EMEA.DE',
    DK: 'B2C.RB.EMEA.DK',
    ES: 'B2C.RB.EMEA.ES',
    FI: 'B2C.RB.EMEA.FI',
    FR: 'B2C.RB.EMEA.FR',
    GB: 'B2C.RB.EMEA.GB',
    IE: 'B2C.RB.EMEA.IE',
    IT: 'B2C.RB.EMEA.IT',
    NL: 'B2C.RB.EMEA.NL',
    NO: 'B2C.RB.EMEA.NO',
    PL: 'B2C.RB.EMEA.PL',
    PT: 'B2C.RB.EMEA.PT',
    SE: 'B2C.RB.EMEA.SE',
    TR: 'B2C.RB.EMEA.TR',
    US: 'B2C.RB.NA.US',
    CA: 'B2C.RB.NA.CA',
    MX: 'B2C.RB.CSA.MX',
    BR: 'B2C.RB.CSA.BR',
    AU: 'B2C.RB.APAC.AU',
  }

  return MAPPING[ocCountry.toUpperCase()] || MAPPING[ocCountryFallback.toUpperCase()]
}

const hybrisLanguageCountryMatrixForRB = [
  { region: 'at', defaultLanguage: 'de', languages: ['de'] },
  { region: 'be', defaultLanguage: 'fr', languages: ['fr, nl'] },
  { region: 'ch', defaultLanguage: 'de', languages: ['de', 'fr', 'en', 'it'] },
  { region: 'de', defaultLanguage: 'de', languages: ['de'] },
  { region: 'dk', defaultLanguage: 'en', languages: ['en'] },
  { region: 'es', defaultLanguage: 'es', languages: ['es'] },
  { region: 'fi', defaultLanguage: 'en', languages: ['en'] },
  { region: 'fr', defaultLanguage: 'fr', languages: ['fr'] },
  { region: 'gb', defaultLanguage: 'en', languages: ['en'] },
  { region: 'ie', defaultLanguage: 'en', languages: ['en'] },
  { region: 'it', defaultLanguage: 'it', languages: ['it'] },
  { region: 'nl', defaultLanguage: 'en', languages: ['en'] },
  { region: 'no', defaultLanguage: 'en', languages: ['en'] },
  { region: 'pl', defaultLanguage: 'pl', languages: ['pl'] },
  { region: 'pt', defaultLanguage: 'en', languages: ['pt'] },
  { region: 'se', defaultLanguage: 'en', languages: ['en'] },
  { region: 'tr', defaultLanguage: 'tr', languages: ['tr'] },
  { region: 'mx', defaultLanguage: 'es', languages: ['es'] },
  { region: 'us', defaultLanguage: 'en', languages: ['en'] },
  { region: 'ca', defaultLanguage: 'en', languages: ['en', 'fr'] },
  { region: 'br', defaultLanguage: 'pt', languages: ['pt'] },
  { region: 'au', defaultLanguage: 'en', languages: ['en'] },
  { region: 'kr', defaultLanguage: 'ko', languages: ['ko'] },
  { region: 'cn', defaultLanguage: 'zh', languages: ['zh'] },
  { region: 'hk', defaultLanguage: 'en', languages: ['en', 'zh'] },
  { region: 'jp', defaultLanguage: 'ja', languages: ['ja'] },
  { region: 'sa', defaultLanguage: 'ar', languages: ['ar'] },
]

export const getSupportedHybrisLanguageCountry = (language: string, country: string): string => {
  const targetCountry = country.toLowerCase()
  const selectedRegion = hybrisLanguageCountryMatrixForRB.find(
    region => region.region === targetCountry
  )
  if (!selectedRegion) return 'en_US'
  const targetLanguage = language.toLowerCase()
  const selectedLanguage =
    selectedRegion.languages.find(regionLanguage => regionLanguage === targetLanguage) ||
    selectedRegion.defaultLanguage
  return `${selectedLanguage}_${selectedRegion.region.toUpperCase()}`
}

export const getFormattedLangCode = (langCode: string): string => {
  const params = langCode.split('-')
  if (params.length === 1) return params[0]

  return `${params[0].toLocaleLowerCase()}_${params[1].toUpperCase()}`
}

export const getOCLang = (langCode: string, country: string): string => {
  const lang = langCode.split('-')[0] || 'en'
  return getSupportedHybrisLanguageCountry(lang, country)
}

export const getOCLangFromSelectedLang = (langCode: string): string => {
  const [locale, country] = langCode.split('-')
  return getSupportedHybrisLanguageCountry(locale, country)
}

/**
 *
 * @param {IBlobData} data data come from one configurator addToCard callback
 * @return {IProductAttribute} partialProduct products built bases on data attributes.
 */
export const getLocalizedConfigurationFromBlob = (data: IBlobData): IProductAttribute => {
  const conf = data.localized_configuration
  const { size, polarized } = conf
  let lensColor,
    lensColor2,
    frameColor,
    jawColor,
    templeLongColor,
    stemsColor,
    iconColor,
    iconColor2,
    templeTipsColor,
    frameFrontColor,
    clipsColor

  if ('ocp_lens_ref' in conf) {
    lensColor = conf.ocp_lens_ref
    lensColor2 = conf.ocp_lens_ref_2
    frameColor = conf.ocp_frame_ref
    jawColor = conf.ocp_jaw
    templeLongColor = conf.ocp_temple_long_ref
    stemsColor = conf.ocp_stems
    iconColor = conf.ocp_icon
    iconColor2 = conf.ocp_icon_2
    clipsColor = conf.ocp_clip
  } else {
    lensColor = conf.lenses_sku
    frameColor = conf.frame_sku
    templeTipsColor = conf.temple_tips_sku
    frameFrontColor = conf.metal_details_render
  }

  const product: IProductAttribute = {
    lensColor,
    lensColor2,
    frameColor,
    jawColor,
    templeLongColor,
    stemsColor,
    iconColor,
    iconColor2,
    clipsColor,
    size,
    polarized: BooleanResolver(polarized),
    templeTipsColor,
    frameFrontColor,
  }

  return product
}
