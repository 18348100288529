import { decreaseZoomLevel, increaseZoomLevel } from '@actions/accessibility'
import { zoomLevels } from '@constants/accessibility'
import { getLangFromUrl } from '@libs/url'
import { WindowDimensions } from '@providers/navigationProvider'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useMediaQuery = (query: string) => {
  const [queryMatch, setQueryMatch] = useState<boolean | null>(null)

  useEffect(() => {
    const handleQueryListener = debounce(() => {
      const updatedMatches = window.matchMedia(query)
      setQueryMatch(updatedMatches.matches)
    }, 250)

    handleQueryListener()
    window.addEventListener('resize', handleQueryListener)

    return () => {
      window.removeEventListener('resize', handleQueryListener)
    }
  }, [query])

  return queryMatch
}

export const useZoomLevel = () => {
  const zoomLevelIndex = useSelector(s => s.accessibility.zoomLevelIndex) ?? 0
  const dispatch = useDispatch()

  const increase = useCallback(() => {
    dispatch(increaseZoomLevel)
  }, [dispatch])

  const decrease = useCallback(() => {
    dispatch(decreaseZoomLevel)
  }, [dispatch])

  return {
    zoomLevel: zoomLevels[zoomLevelIndex],
    increase,
    decrease,
  }
}

export const useWindowResizeListener = () => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    height: 0,
    width: 0,
  })

  useEffect(() => {
    const handleWindowResize = debounce(() => {
      const width = window.innerWidth
      const height = window.innerHeight
      setWindowDimensions({
        height,
        width,
      })
    }, 250)

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return windowDimensions
}

export const useLanguageDirection = () => {
  const rtlLanguages = ['ar']
  const lang = getLangFromUrl()
  return rtlLanguages.includes(lang.split('-')[0])
}
