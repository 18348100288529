import { Dispatch } from 'react'

export const SET_INITIALISED = 'rtr/SET_INITIALISED'

export interface SetRTRAction {
  type: typeof SET_INITIALISED
  payload: {
    initialised: boolean
  }
}

export const setInitialised = (initialised: boolean) => (dispatch: Dispatch<SetRTRAction>) => {
  dispatch({
    type: SET_INITIALISED,
    payload: {
      initialised,
    },
  })
}

export type RTRActionTypes = SetRTRAction
