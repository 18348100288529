import { AppConfigOverride } from '@abstractTypes/envs'

const getDevEnvVariables = (): AppConfigOverride => {
  const devEnvVariables = {
    enableServiceWorker: false,
    fsaEnv: 'dev',
    component: { showQRCodeDebugInfo: true },
  }

  return devEnvVariables
}

export default getDevEnvVariables
