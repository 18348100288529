import { CustomerOrderType } from '@abstractTypes/common'
import { CustomerOrderActionTypes, SET_CUSTOMER_ORDER } from '@actions/customerOrder'

export interface CustomerOrderState {
  salesOrderId: string | undefined
  orderType: CustomerOrderType
  email: string | undefined
}

const initialState: CustomerOrderState = {
  salesOrderId: undefined,
  orderType: null,
  email: undefined,
}

export default (state = initialState, action: CustomerOrderActionTypes) => {
  const { payload } = action

  switch (action.type) {
    case SET_CUSTOMER_ORDER: {
      return {
        salesOrderId: payload.salesOrderId,
        orderType: payload.orderType,
        email: payload.email,
      }
    }

    default:
      return state
  }
}
