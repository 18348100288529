import { ApolloProvider } from '@apollo/client'
import config from '@config/index'
import CourtesyChecker from '@containers/CourtesyChecker'
import { getTheme } from '@theme/utils'
import { ConnectedRouter } from 'connected-react-router'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import AppWithTranslations from './AppWithTranslations'
import { getClient } from './apolloClient'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store, { history } from './store'
import UtilityRoutes from './utilityRoutes'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <ThemeProvider theme={getTheme(config.shopperSlug)}>
        <Provider store={store}>
          <ApolloProvider client={getClient(store)}>
            <ConnectedRouter history={history}>
              <UtilityRoutes>
                <CourtesyChecker>
                  <AppWithTranslations />
                </CourtesyChecker>
              </UtilityRoutes>
            </ConnectedRouter>
          </ApolloProvider>
        </Provider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

config.enableServiceWorker
  ? serviceWorkerRegistration.register()
  : serviceWorkerRegistration.unregister()
