import ConditionalRender from '@components/ConditionalRender'
import { AccessibilityIcon } from '@components/core/Icons'
import SSTrans from '@components/core/Trans'
import { Heading4, Text } from '@components/core/Typography'
import { useDeviceType } from '@hooks/useDeviceType'
import { useModalHandler } from '@hooks/useModalHandler'
import { useTranslation } from 'react-i18next'
import { ADASection } from '../sections/ADASection'
import { ScreenReaderSection } from '../sections/ScreenReaderSection'
import { VKSection } from '../sections/VKSection'
import { ZoomSection } from '../sections/ZoomSection'
import {
  AccessibilityModalContainer,
  AccessibilityModalContent,
  AccessibilityModalHeader,
  AccessibilityModalSectionContainer,
  AccessibilityModalStyled,
  TitleIcon,
} from './styles'

const AccessibilityModal = () => {
  const { isVisible, closeAnyModal } = useModalHandler('accessibility')
  const { isTowerPortrait, isTowerLandscape, isIpad, isIpadlandscape } = useDeviceType()
  const { t } = useTranslation()

  return (
    <AccessibilityModalStyled
      title={
        <AccessibilityModalHeader aria-label={t('AccessibilityLabel.modalTitle')}>
          <TitleIcon>
            <AccessibilityIcon height="100%" width="100%" />
          </TitleIcon>
          <Heading4 weight="bold">{t('AccessibilityLabel.modalTitle')}</Heading4>
        </AccessibilityModalHeader>
      }
      visible={isVisible}
      onCancel={closeAnyModal}
      className="accessibility"
      centered={false}
      dataCalls="0"
      dataAttr="Ada_Close-Button"
      dataDescription="Ada_Close-Button"
    >
      <AccessibilityModalContainer>
        <AccessibilityModalContent id="accessibility-content">
          {isIpad || isIpadlandscape ? (
            <Text>
              <SSTrans
                components={{
                  bold: <b />,
                }}
              >
                {t('AccessibilityLabel.modalSubTitleScreenReader')}
              </SSTrans>
            </Text>
          ) : (
            <Text aria-label={t('AccessibilityLabel.modalSubTitle')}>
              {t('AccessibilityLabel.modalSubTitle')}
            </Text>
          )}
          <AccessibilityModalSectionContainer>
            <ConditionalRender
              condition={isTowerLandscape || isTowerPortrait}
              render={() => (
                <>
                  <ZoomSection />
                  <VKSection />
                  <ADASection />
                </>
              )}
            />
            <ConditionalRender
              condition={isIpad || isIpadlandscape}
              render={() => <ScreenReaderSection />}
            />
          </AccessibilityModalSectionContainer>
        </AccessibilityModalContent>
      </AccessibilityModalContainer>
    </AccessibilityModalStyled>
  )
}

export default AccessibilityModal
