import { FilterFacet } from '@abstractTypes/filter'
import { FrameAdvisorSurvey } from '@abstractTypes/frameAdvisor'
import {
  BarcodeProducts,
  ContentV2Electronics,
  ContentV2PlaylistItem,
  ContentV2PrizmItem,
  ContentV2Video,
  EditorialComponentList,
  FaSuggestionsData,
  Image,
  Mutation,
  MutationAvailabilityArgs,
  MutationCheckoutArgs,
  MutationCreateVmHashArgs,
  MutationCustomerOrderCheckoutArgs,
  MutationFrameAdvisorFsaConsentArgs,
  MutationFrameAdvisorFsaEmailArgs,
  MutationFsaCreateUserArgs,
  MutationFsaUpdateProfilePropertyArgs,
  MutationSessionHandoffStateSaverArgs,
  ProductRelation,
  Query,
  QueryAboutThisStyleArgs,
  QueryBarcodeProductsArgs,
  QueryCartRecommendedAccessoriesArgs,
  QueryDiscountBannerArgs,
  QueryEditorialBrandPlpContentArgs,
  QueryFitSizeArgs,
  QueryFrameAdvisorFsaTokenArgs,
  QueryFrameAdvisorFsaUserProfileArgs,
  QueryFrameAdvisorFsaVideoIdArgs,
  QueryFrameAdvisorOnboardingArgs,
  QueryFrameAdvisorProfilePictureArgs,
  QueryInspiredProductsArgs,
  QueryMatchesWithArgs,
  QueryNoResultsArgs,
  QueryNuanceTabContentArgs,
  QueryPrivacyPolicyArgs,
  QueryProductsArgs,
  QuerySessionHandoffStateArgs,
  QueryStoreArgs,
  Store,
} from '@abstractTypes/graphqlTypes'
import { OneCatalogInfo } from '@abstractTypes/oneCatalogInfo'
import { StandardProduct } from '@abstractTypes/product'
import { StockItem } from '@abstractTypes/realTimeCheck'
import {
  ErrorPolicy,
  QueryFunctionOptions,
  QueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { QueryControls } from '@apollo/client/react/hoc'
import { getBrowserLocalStoragePersistentStateKey } from '@config/config.base'
import config from '@config/index'
import { defaultExcludeFilters } from '@hooks/formatPayloadForSearchApi'
import { useCustomerOrder } from '@hooks/useCustomOrder'
import useEnrichExcludeFilters from '@hooks/useEnrichExcludeFilters'
import {
  DEFAULT_LANG,
  DEFAULT_STORE,
  DEFAULT_STORETYPE,
  useStoreIndentity,
} from '@hooks/useStoreIdentity'
import { usePageChecker } from '@libs/utils'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Location } from 'history'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getClient } from './apolloClient'
import ABOUT_THIS_STYLE_QUERY from './graphql/AboutThisStyle.graphql'
import BARCODE_PRODUCTS_QUERY from './graphql/BarcodeProducts.graphql'
import CART_RECOMMENDED_ACCESSORIES_QUERY from './graphql/CartRecommendedAccessories.graphql'
import DISCOUNT_BANNER_QUERY from './graphql/DiscountBanner.graphql'
import EDITORIAL_BRAND_PLP_CONTENT_QUERY from './graphql/EditorialBrandPLPContent.graphql'
import EDITORIAL_COMPONENT_LIST_QUERY from './graphql/EditorialComponentList.graphql'
import FIT_SIZE_QUERY from './graphql/FitSize.graphql'
import FRAME_ADVISOR_COLOR_PALETTES from './graphql/FrameAdvisorColorPalettes.graphql'
import FRAME_ADVISOR_FSA_TOKEN from './graphql/FrameAdvisorFsaToken.graphql'
import FRAME_ADVISOR_FSA_USER_PROFILE from './graphql/FrameAdvisorFsaUserProfile.graphql'
import FRAME_ADVISOR_FSA_VIDEOID from './graphql/FrameAdvisorFsaVideoId.graphql'
import FRAME_ADVISOR_ONBOARDING from './graphql/FrameAdvisorOnboarding.graphql'
import FRAME_ADVISOR_SUGGESTION_QUERY from './graphql/FrameAdvisorProducts.graphql'
import FRAME_ADVISOR_PROFILE_PICTURE from './graphql/FrameAdvisorProfilePicture.graphql'
import GREEN_SHIPMENT_BANNER_QUERY from './graphql/GreenShipmentBanner.graphql'
import GREEN_SHIPMENT_INFO_QUERY from './graphql/GreenShipmentInfo.graphql'
import INSPIRED_PRODUCTS from './graphql/InspiredProducts.graphql'
import MATCHES_WITH_QUERY from './graphql/MatchesWith.graphql'
import NO_RESULTS_QUERY from './graphql/NoResults.graphql'
import NUANCE_TAB_CONTENT from './graphql/NuanceTabContent.graphql'
import PAGE_QUERY from './graphql/Page.graphql'
import PRIVACY_POLICY_QUERY from './graphql/PrivacyPolicy.graphql'
import PRODUCTS_QUERY from './graphql/Products.graphql'
import SEARCH_SUGGESTIONS_QUERY from './graphql/SearchSuggestions.graphql'
import SESSION_HANDOFF_STATE from './graphql/SessionHandoffState.graphql'
import SESSION_HANDOFF_TOKEN from './graphql/SessionHandoffToken.graphql'
import STORE_QUERY from './graphql/Store.graphql'
import TRANSITION_EXPERIENCES from './graphql/TransitionExperiences.graphql'
import TRANSLATIONS_QUERY from './graphql/Translations.graphql'
import CHECK_AVAILABILITY_CART_QUERY from './graphql/mutations/checkAvailabilityCart.graphql'
import CHECK_AVAILABILITY_PDP_QUERY from './graphql/mutations/checkAvailabilityPDP.graphql'
import CHECKOUT_QUERY from './graphql/mutations/checkout.graphql'
import CREATEVMHASH_QUERY from './graphql/mutations/createVMHash.graphql'
import CUSTOMER_ORDER_CHECKOUT_QUERY from './graphql/mutations/customerOrderCheckout.graphql'
import FRAME_ADVISOR_FSA_CONSENT from './graphql/mutations/frameAdvisorFsaConsent.graphql'
import FRAME_ADVISOR_FSA_EMAIL from './graphql/mutations/frameAdvisorFsaEmail.graphql'
import FSA_CREATE_USER_PROFILE from './graphql/mutations/fsaCreateUser.graphql'
import FSA_UPDATE_PROFILE_PROPERTY from './graphql/mutations/fsaUpdateProfileProperty.graphql'
import SESSION_HANDOFF_STATE_SAVER from './graphql/mutations/sessionHandoffStateSaver.graphql'
import { SessionState } from './reducers/core'
import { AppState } from './store'
const apolloClient = getClient()

export interface HocGraphqlProps {
  location: Location
  state: AppState
  session: SessionState
}

export type PageTypes =
  | 'PageHome'
  | 'PagePDP'
  | 'PageFrameAdvisorPlaylist'
  | 'PageFrameAdvisorSurvey'
  | 'PageSupernova'
  | 'PagePrizm'
  | 'PageNuance'

export interface StoreDataQueryResult {
  store: Store
}

export interface PageElementLink {
  url: string
  label: string
}

export interface NoResultsDataQueryResult {
  noResults: Query['noResults']
}
export interface PageElement {
  id: string
  title: string
  link: PageElementLink
  image: Image
  __typename?: string
}

export interface PageDataQueryResult extends QueryControls {
  page: {
    content: PageElement[]
  }
}

export interface ProductDataQueryResult {
  products: {
    __typename?: string
    items: StandardProduct[]
    numRows: number
    filters: FilterFacet[]
    matchedRules: string[]
    upcSameModel: string[]
    upcSameSize: string[]
    oneCatalogInfo?: OneCatalogInfo
  }
}

export interface SearchSuggestionsDataQueryResult {
  searchSuggestions: Query['searchSuggestions']
}

export interface PDPDataQueryResult {
  page: {
    product: {
      __typename?: string
    } & StandardProduct
    alternatives: StandardProduct[]
    relationship: Maybe<ProductRelation>
  }
}
// export interface ProductsDataQueryResult extends QueryControls {}

export interface FrameAdvisorPlaylistQueryResult {
  page: {
    id: string
    __typename: string
    playlist: {
      title: string
      playlist: ContentV2PlaylistItem[]
    }
  }
}

export interface FrameAdvisorSurveyQueryResult {
  page: {
    id: string
    __typename: string
    survey: {
      survey: FrameAdvisorSurvey
    }
    videoBg: ContentV2Video[]
  }
}

export interface StoreQueryProps {
  storeData: StoreDataQueryResult & QueryControls
}

export interface PageQueryProps {
  pageData: PageDataQueryResult
}
export interface ProductsQueryProps {
  productsData: ProductDataQueryResult & QueryControls
}

export interface ElectronicsDataQueryResult {
  page: {
    __typename?: string
    id: string
    content: ContentV2Electronics[]
  }
}

export interface PrizmQueryResult {
  page: {
    id: string
    __typename: string
    prizmPlaylist: { playlist: ContentV2PrizmItem[] }
  }
}

export interface InspiredDataQueryResult {
  inspiredProducts: Query['inspiredProducts']
}

interface AboutThisStyleResultData {
  aboutThisStyle: Query['aboutThisStyle']
}

interface MatchesWithResultData {
  matchesWith: Query['matchesWith']
}
interface RecommendedAccessoriesResult {
  cartRecommendedAccessories: Query['cartRecommendedAccessories']
}

interface DiscountBannerResultData {
  discountBanner: Query['discountBanner']
}

interface PrivacyPolicyResultData {
  privacyPolicy: Query['privacyPolicy']
}

interface GreenShipmentBannerResultData {
  greenShipment: Query['greenShipment']
}

interface GreenShipmentInfoResultData {
  greenShipmentInfo: Query['greenShipmentInfo']
}

interface EditorialBrandPLPContentResultData {
  editorialBrandPLPContent?: Query['editorialBrandPLPContent']
}

interface EditorialComponentListResultData {
  editorialComponentList: EditorialComponentList
}

interface FitSizeContentResultData {
  fitSize: Query['fitSize']
}

interface TransitionExperienceResultData {
  transitionExperiences: Query['transitionExperiences']
}

interface TranslationsResultData {
  translations: string
}

interface FrameAdvisorOnboardingResultData {
  frameAdvisorOnboarding: Query['frameAdvisorOnboarding']
}

interface FrameAdvisorFsaUserProfileData {
  frameAdvisorFsaUserProfile: Query['frameAdvisorFsaUserProfile']
}

interface FrameAdvisorFsaCreateUserProfile {
  fsaCreateUser: Mutation['fsaCreateUser']
}
interface SessionHandoffStateSaver {
  sessionHandoffStateSaver: Mutation['sessionHandoffStateSaver']
}

interface SessionHandoffState {
  sessionHandoffState: Query['sessionHandoffState']
}

export interface FrameAdvisorSaveUserPlaylist {
  saveUserPlaylist: Mutation['saveUserPlaylist']
}

interface NuanceTabContentData {
  nuanceTabContent: Query['nuanceTabContent']
}

export const useStoreData = () => {
  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)
  const { isCompletePairOrderType } = useCustomerOrder()
  const { isCustomerOrderErrorPage } = usePageChecker()
  const sessionToken = useSelector(state => state.session.token)
  const customerOrderType = useSelector(s => s.customerOrder.orderType)
  const isCompletePair = customerOrderType === 'COMPLETE_PAIR'

  const shouldDisableCustomizers =
    !isCustomerOrderErrorPage && isCustomerOrder && isCompletePairOrderType

  const { pathname } = window.location
  const urlparts = pathname.split('/')
  const [, storeType, storeId, storeLang] = urlparts

  const vars: QueryStoreArgs = {
    url: `${storeType || DEFAULT_STORETYPE}/${storeId || DEFAULT_STORE}/${
      storeLang || DEFAULT_LANG
    }`,
    isCompletePair,
    disabledCustomizers: shouldDisableCustomizers ? ['rbconfigurator', 'ooconfigurator'] : [],
    productSubdivision: config.default.productSubdivision,
  }

  return useQuery<StoreDataQueryResult, QueryStoreArgs>(STORE_QUERY, {
    variables: vars,
    context: {
      headers: {
        'dw-session-id': sessionToken,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useSearchSuggestionsData = <T>(filters: T, excludeFilters = defaultExcludeFilters) => {
  const { basePath } = useStoreIndentity()
  const enrichedExcludeFilters = useEnrichExcludeFilters(excludeFilters)

  const productPageUrl = `${basePath}/products`
  return useQuery<SearchSuggestionsDataQueryResult>(SEARCH_SUGGESTIONS_QUERY, {
    variables: {
      url: productPageUrl,
      filters: {
        ...filters,
        excludeFilters: enrichedExcludeFilters,
      },
      count: config.searchSuggestionsCount,
    },
    fetchPolicy: 'network-only',
  })
}

export const useSearchData = (data: QueryProductsArgs['data']) => {
  const { basePath } = useStoreIndentity()

  return useQuery<ProductDataQueryResult, QueryProductsArgs>(PRODUCTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: basePath,
      data,
      pictureConfig: config.pictureConfig,
    },

    fetchPolicy: 'network-only',
  })
}

export const useNoResultsData = (data: QueryNoResultsArgs['data']) => {
  const { basePath } = useStoreIndentity()

  return useQuery<NoResultsDataQueryResult, QueryNoResultsArgs>(NO_RESULTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: basePath,
      data,
      pictureConfig: config.pictureConfig,
    },

    fetchPolicy: 'network-only',
  })
}

export const useProductData = (data: QueryProductsArgs['data']) => {
  const { basePath } = useStoreIndentity()

  return useQuery<ProductDataQueryResult, QueryProductsArgs>(PRODUCTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: basePath,
      pictureConfig: config.pictureConfig,
      data,
    },

    fetchPolicy: 'network-only',
  })
}

export const useBarcodeProducts = (data: QueryBarcodeProductsArgs['data']) => {
  const { basePath } = useStoreIndentity()
  return useQuery<BarcodeProductsResData, QueryBarcodeProductsArgs>(BARCODE_PRODUCTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: basePath,
      pictureConfig: config.pictureConfig,
      data,
    },
    fetchPolicy: 'network-only',
  })
}

interface CheckoutMutationResultData {
  checkout: Mutation['checkout']
}

interface CheckoutCustomerOrderMutationResultData {
  customerOrderCheckout: Mutation['customerOrderCheckout']
}

export interface BarcodeProductsResData {
  barcodeProducts: BarcodeProducts
}

interface AvailabilityMutationResultData {
  availability: {
    stockItems: StockItem[]
  }
}

export interface FrameAdvisorSuggestions {
  products: StandardProduct[]
  numFound: number
  filters: FilterFacet[]
  __typename: string
}

interface FrameAdvisorSuggestionMutationResultData {
  frameAdvisorSuggestions: FrameAdvisorSuggestions
}

interface FrameAdvisorFsaToken {
  frameAdvisorFsaToken: Query['frameAdvisorFsaToken']
}
interface SessionHandoffToken {
  sessionHandoffToken: Query['sessionHandoffToken']
}

interface FrameAdvisorFsaVideoId {
  frameAdvisorFsaVideoId: Query['frameAdvisorFsaVideoId']
}

interface ColorPalettesResultData {
  frameAdvisorColorPalettes?: Query['frameAdvisorColorPalettes']
}
interface ProfilePictureResultData {
  frameAdvisorProfilePicture?: Query['frameAdvisorProfilePicture']
}

interface FsaUpdateProfilePropertyData {
  fsaUpdateProfileProperty: Mutation['fsaUpdateProfileProperty']
}

export const useDoCheckout = () => {
  return useMutation<CheckoutMutationResultData, MutationCheckoutArgs>(CHECKOUT_QUERY, {})
}

export const useDoCustomerOrderCheckout = () => {
  return useMutation<CheckoutCustomerOrderMutationResultData, MutationCustomerOrderCheckoutArgs>(
    CUSTOMER_ORDER_CHECKOUT_QUERY,
    {}
  )
}

const checkAvailability = (
  mutationName: TypedDocumentNode<AvailabilityMutationResultData>,
  { data }: MutationAvailabilityArgs,
  timeout = 0
) => {
  const abortController = new AbortController()

  return apolloClient.mutate<AvailabilityMutationResultData>({
    mutation: mutationName,
    variables: {
      data,
    },
    context: {
      headers: {
        'store-id': data.storeId,
      },
      timeout,
      fetchOptions: { controller: abortController },
    },
  })
}

export const checkAvailabilityPDP = (
  checkAvailabilityPayload: MutationAvailabilityArgs,
  timeout = 0
) => {
  return checkAvailability(CHECK_AVAILABILITY_PDP_QUERY, checkAvailabilityPayload, timeout)
}

export const checkAvailabilityCart = (
  checkAvailabilityPayload: MutationAvailabilityArgs,
  timeout = 0
) => {
  return checkAvailability(CHECK_AVAILABILITY_CART_QUERY, checkAvailabilityPayload, timeout)
}

export const useVMHash = () => {
  return useMutation<{ createVMHash: Mutation['createVMHash'] }, MutationCreateVmHashArgs>(
    CREATEVMHASH_QUERY
  )
}

const usePageQueryOptions = <T>({
  forcePathname,
  pageType,
  tags,
  forceSearch,
  errorPolicy = 'none',
  skip,
}: {
  pageType: string
  forcePathname?: string | undefined
  tags?: Record<string, Maybe<string>>
  forceSearch?: boolean
  errorPolicy?: ErrorPolicy
  skip?: boolean
}): Omit<QueryHookOptions<T>, 'query'> => {
  const { location } = useHistory()
  const customerOrderType = useSelector(s => s.customerOrder.orderType)
  const { pathname, search } = location
  const isCompletePair = customerOrderType === 'COMPLETE_PAIR'
  const excludeFilters = useEnrichExcludeFilters(defaultExcludeFilters)
  return {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: forcePathname ? forcePathname : pathname,
      type: pageType,
      search: forceSearch ? '' : search,
      pictureConfig: config.pictureConfig,
      disabledCustomizers: isCompletePair ? ['rbconfigurator', 'ooconfigurator'] : [],
      isCompletePair,
      tags,
      productSubdivision: config.default.productSubdivision,
      excludeFilters,
    },

    fetchPolicy: 'cache-first',
    errorPolicy,
    skip,
  }
}
export const usePageData = <T>(
  pageType: PageTypes,
  options?: {
    pathname?: string
    withSearch?: boolean
    errorPolicy?: ErrorPolicy
    tags?: Record<string, Maybe<string>>
    skip?: boolean
  }
) => {
  const { pathname, withSearch = false, errorPolicy, tags, skip } = options ?? {}
  const queryOptions = usePageQueryOptions<T>({
    forcePathname: pathname,
    pageType,
    tags,
    forceSearch: withSearch,
    errorPolicy,
    skip,
  })
  return useQuery<T>(PAGE_QUERY, queryOptions)
}

export const useLazyPageData = <T>(pageType: PageTypes) => {
  const queryOptions = usePageQueryOptions<T>({ pageType })
  return useLazyQuery<T>(PAGE_QUERY, queryOptions)
}

export const useFrameAdvisorSuggestion = (
  data: FaSuggestionsData,
  tags?: Record<string, Maybe<string>>
) => {
  const { basePath } = useStoreIndentity()

  return useQuery<FrameAdvisorSuggestionMutationResultData>(FRAME_ADVISOR_SUGGESTION_QUERY, {
    variables: {
      url: basePath,
      suggestions: data,
      pictureConfig: config.pictureConfig,
      tags,
    },
    notifyOnNetworkStatusChange: true,

    fetchPolicy: 'cache-first',
  })
}

export const useFrameAdvisorFsaToken = () => {
  const { basePath } = useStoreIndentity()
  const options = useMemo(
    () =>
      ({
        variables: {
          url: basePath,
        },
        fetchPolicy: 'network-only',
      }) as const,
    [basePath]
  )

  const queryResult = useQuery<FrameAdvisorFsaToken, QueryFrameAdvisorFsaTokenArgs>(
    FRAME_ADVISOR_FSA_TOKEN,
    options
  )
  return queryResult
}

export const useFrameAdvisorFsaVideoId = (fsaToken: string) => {
  return useLazyQuery<FrameAdvisorFsaVideoId, QueryFrameAdvisorFsaVideoIdArgs>(
    FRAME_ADVISOR_FSA_VIDEOID,
    {
      variables: {
        token: fsaToken,
      },

      pollInterval: 2000,
      fetchPolicy: 'network-only',
    }
  )
}

export const chanelBrandId = 'ch'

export const useFrameAdvisorFsaConsent = () => {
  return useMutation<
    { frameAdvisorFsaConsent: Mutation['frameAdvisorFsaConsent'] },
    MutationFrameAdvisorFsaConsentArgs
  >(FRAME_ADVISOR_FSA_CONSENT, {})
}

export const useInspiredData = (modelCode?: string) => {
  return useQuery<InspiredDataQueryResult, QueryInspiredProductsArgs>(INSPIRED_PRODUCTS, {
    variables: {
      search: modelCode ?? '',
    },
    fetchPolicy: 'cache-first',
    skip: !modelCode,
  })
}

export const useFrameAdvisorFsaEmail = () => {
  return useMutation<
    { frameAdvisorFsaEmail: Mutation['frameAdvisorFsaEmail'] },
    MutationFrameAdvisorFsaEmailArgs
  >(FRAME_ADVISOR_FSA_EMAIL, {})
}

export const useDiscountBanner = () => {
  const { basePath } = useStoreIndentity()

  return useQuery<DiscountBannerResultData, QueryDiscountBannerArgs>(DISCOUNT_BANNER_QUERY, {
    variables: {
      url: basePath,
    },

    fetchPolicy: 'cache-first',
  })
}

export const usePrivacyPolicy = (url?: string) => {
  const { location } = useHistory()
  const { pathname } = location
  return useLazyQuery<PrivacyPolicyResultData, QueryPrivacyPolicyArgs>(PRIVACY_POLICY_QUERY, {
    variables: {
      url: url || pathname,
    },

    fetchPolicy: 'cache-first',
  })
}

export const useGreenShipment = () => {
  const { basePath } = useStoreIndentity()

  return useQuery<GreenShipmentBannerResultData>(GREEN_SHIPMENT_BANNER_QUERY, {
    variables: {
      url: basePath,
    },

    fetchPolicy: 'cache-first',
  })
}

export const useGreenShipmentInfo = () => {
  const { basePath } = useStoreIndentity()

  return useLazyQuery<GreenShipmentInfoResultData>(GREEN_SHIPMENT_INFO_QUERY, {
    variables: {
      url: basePath,
    },

    fetchPolicy: 'cache-first',
  })
}

export const useAboutThisStyle = (brand: string, model: string) => {
  const { basePath } = useStoreIndentity()

  return useQuery<AboutThisStyleResultData, QueryAboutThisStyleArgs>(ABOUT_THIS_STYLE_QUERY, {
    variables: {
      url: basePath,
      brand,
      model,
    },

    fetchPolicy: 'cache-first',
  })
}

type useMatchesWithArgs = {
  moco: string
  compatibleFor: string
}

export const useMatchesWith = ({ moco, compatibleFor }: useMatchesWithArgs) => {
  const { basePath, storeId, langCode } = useStoreIndentity()
  const excludeFilters = useEnrichExcludeFilters()

  const queryResult = useQuery<MatchesWithResultData, QueryMatchesWithArgs>(MATCHES_WITH_QUERY, {
    variables: {
      url: basePath,
      data: {
        store: storeId,
        lang: langCode,
        rows: config.itemsPerPage,
        compatibleFor,
        moco,
        excludeFilters,
      },
      pictureConfig: config.pictureConfig,
    },
    fetchPolicy: 'network-only',
  })
  return queryResult
}

export const useEditorialBrandPLPContent = (
  brand: string,
  options: QueryFunctionOptions<
    EditorialBrandPLPContentResultData,
    QueryEditorialBrandPlpContentArgs
  >
) => {
  const { location } = useHistory()

  const { pathname } = location

  return useQuery<EditorialBrandPLPContentResultData, QueryEditorialBrandPlpContentArgs>(
    EDITORIAL_BRAND_PLP_CONTENT_QUERY,
    {
      variables: {
        url: pathname,
        brand,
        productSubdivision: config.default.productSubdivision,
      },
      fetchPolicy: 'cache-first',
      ...options,
    }
  )
}

export const useCartRecommendedAccessories = (products: StandardProduct[]) => {
  const { basePath, storeId, langCode } = useStoreIndentity()

  const queryResult = useQuery<RecommendedAccessoriesResult, QueryCartRecommendedAccessoriesArgs>(
    CART_RECOMMENDED_ACCESSORIES_QUERY,
    {
      variables: {
        url: basePath,
        data: {
          filters: {
            store: storeId,
            lang: langCode,
          },
          rows: config.itemsPerPage,
          cartProducts: products.map(prod => ({
            upc: prod.UPC,
            brand: prod.brand.id,
            model: prod.model,
            moco: prod.moco,
            productType: prod.productType ?? prod.product_hierarchy1,
          })),
        },
        pictureConfig: config.pictureConfig,
      },
      fetchPolicy: 'network-only',
    }
  )
  return queryResult
}

export const useFitSizeContent = () => {
  const { location } = useHistory()

  const { pathname } = location

  return useQuery<FitSizeContentResultData, QueryFitSizeArgs>(FIT_SIZE_QUERY, {
    variables: {
      url: pathname,
    },

    fetchPolicy: 'cache-first',
  })
}

export const useTransitionExperience = () => {
  const { location } = useHistory()

  const { pathname } = location

  return useQuery<TransitionExperienceResultData>(TRANSITION_EXPERIENCES, {
    variables: {
      url: pathname,
    },
    fetchPolicy: 'cache-first',
  })
}

export const useEditorialComponentList = (
  contentId: string,
  options?: Partial<QueryHookOptions<EditorialComponentListResultData>>
) => {
  const { basePath } = useStoreIndentity()

  return useQuery<EditorialComponentListResultData>(EDITORIAL_COMPONENT_LIST_QUERY, {
    variables: {
      url: basePath,
      contentId,
      pictureConfig: config.pictureConfig,
      productSubdivision: config.default.productSubdivision,
    },

    fetchPolicy: 'cache-first',
    ...options,
  })
}

export const getTranslations = (lang?: string) => {
  return apolloClient.query<TranslationsResultData>({
    query: TRANSLATIONS_QUERY,
    variables: {
      lang,
      ns: config.shopperSlug,
      region: config.shopperRegion,
    },
    fetchPolicy: 'cache-first',
  })
}

export const useFrameAdvisorOnboarding = () => {
  const { basePath } = useStoreIndentity()
  return useQuery<FrameAdvisorOnboardingResultData, QueryFrameAdvisorOnboardingArgs>(
    FRAME_ADVISOR_ONBOARDING,
    {
      variables: {
        url: basePath,
      },
    }
  )
}

export const useFrameAdvisorFsaUserProfile = (userId: string) => {
  return useQuery<FrameAdvisorFsaUserProfileData, QueryFrameAdvisorFsaUserProfileArgs>(
    FRAME_ADVISOR_FSA_USER_PROFILE,
    {
      variables: {
        userId: userId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first',
      skip: !userId,
    }
  )
}

export const useFaColorPalettes = () => {
  return useQuery<ColorPalettesResultData>(FRAME_ADVISOR_COLOR_PALETTES, {})
}

export const useFrameAdvisorFsaCreateUserProfile = () => {
  return useMutation<FrameAdvisorFsaCreateUserProfile, MutationFsaCreateUserArgs>(
    FSA_CREATE_USER_PROFILE
  )
}

export const useLazyFaProfilePictureQuery = (userPictureUUID: string) => {
  return useLazyQuery<ProfilePictureResultData, QueryFrameAdvisorProfilePictureArgs>(
    FRAME_ADVISOR_PROFILE_PICTURE,
    {
      variables: {
        userPictureUUID,
      },
    }
  )
}

export const useFsaUpdateProfileProperty = () => {
  return useMutation<FsaUpdateProfilePropertyData, MutationFsaUpdateProfilePropertyArgs>(
    FSA_UPDATE_PROFILE_PROPERTY
  )
}

export const useSessionHandoffToken = () => {
  const options = useMemo(
    () =>
      ({
        fetchPolicy: 'network-only', // To make sure we alway retrieve a new and fresh uuid everytime
      }) as const,
    []
  )

  const queryResult = useQuery<SessionHandoffToken>(SESSION_HANDOFF_TOKEN, options)
  return queryResult
}
export const useSessionHandoffStatusChecker = () => {
  const options = useMemo(
    () =>
      ({
        fetchPolicy: 'network-only', // To make sure we alway retrieve a new and fresh uuid everytime
      }) as const,
    []
  )

  return useLazyQuery<SessionHandoffState, QuerySessionHandoffStateArgs>(
    SESSION_HANDOFF_STATE,
    options
  )
}
export const useSessionHandoffStateSaver = () => {
  const flows = useSelector(s => s.flows)
  const state = localStorage.getItem(getBrowserLocalStoragePersistentStateKey()) || ''
  const currentLocation = useLocation()
  const stateToSave = { ...JSON.parse(state), currentLocation, flows }

  return useMutation<SessionHandoffStateSaver, MutationSessionHandoffStateSaverArgs>(
    SESSION_HANDOFF_STATE_SAVER,
    {
      variables: {
        state: JSON.stringify(stateToSave),
      } as MutationSessionHandoffStateSaverArgs,
    }
  )
}
export const useSessionHandoffStateRetriever = (token?: string) => {
  const options = useMemo(
    () =>
      ({
        variables: {
          token,
        },
        fetchPolicy: 'network-only', // To make sure we alway retrieve a new and fresh uuid everytime
      }) as const,
    [token]
  )

  const queryResult = useQuery<SessionHandoffState>(SESSION_HANDOFF_STATE, options)
  return queryResult
}

export const useNuanceTabContent = (
  data: Omit<QueryNuanceTabContentArgs, 'url'>,
  options?: QueryFunctionOptions<NuanceTabContentData, QueryNuanceTabContentArgs>
) => {
  const { basePath } = useStoreIndentity()

  return useQuery<NuanceTabContentData, QueryNuanceTabContentArgs>(NUANCE_TAB_CONTENT, {
    variables: {
      url: basePath,
      ...data,
    },
    fetchPolicy: 'cache-first',
    ...options,
  })
}
