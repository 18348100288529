import AccessibilityModal from '@components/Accessibility/AccessibilityModal'
import {
  AccessibilityIconContainer,
  AccessibilityWrapper,
} from '@components/Accessibility/sections/styles'
import { AccessibilityIcon } from '@components/core/Icons'
import { useModalHandler } from '@hooks/useModalHandler'
import { useStoreContext } from '@hooks/useStoreContext'
import { pushToDataTrack } from '@providers/analyticsProvider'
import { useTranslation } from 'react-i18next'

const AccessibilityToggle = () => {
  const { setVisibleModal } = useModalHandler('accessibility')
  const { adaWcagEnabled } = useStoreContext()
  const { t } = useTranslation()

  return adaWcagEnabled ? (
    <>
      <AccessibilityWrapper
        data-analytics_available_call="0"
        aria-label={t('ARIA.accessibilityMenuIcon.label')}
        onClick={() => {
          pushToDataTrack({
            id: 'Click',
            data_element_id: 'Ada_OpenModal',
            data_description: '',
          })
          setVisibleModal('accessibility')
        }}
        data-element-id="Ada_OpenModal"
      >
        <AccessibilityIconContainer>
          <AccessibilityIcon height="100%" width="100%" />
        </AccessibilityIconContainer>
      </AccessibilityWrapper>
      <AccessibilityModal />
    </>
  ) : null
}

export default AccessibilityToggle
