import { useCallback } from 'react'
import { PriceFormatter } from '@abstractTypes/price'
import { useStoreContext } from './useStoreContext'

export const usePriceFormatter = (): PriceFormatter => {
  const store = useStoreContext()

  return useCallback(
    (price: number, fractionDigits = 2) => {
      const {
        currency: { id: storeCurrency, symbol },
        defaultLangCode,
      } = store

      const options: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: storeCurrency,
        minimumFractionDigits: currenciesWithoutPrecision.includes(storeCurrency)
          ? 0
          : fractionDigits,
        currencyDisplay: 'symbol',
      }

      const defaultLangCodeLatinEncoded = `${defaultLangCode}-u-nu-latn`

      //Using defualtLangCode as locale since it reflect the countryId
      const formattedPrice = Intl.NumberFormat(defaultLangCodeLatinEncoded, options)
        .formatToParts(price)
        .map(({ type, value }) => {
          switch (type) {
            case 'currency':
              // We always overwrite the currency symbol with the one provided by the BE
              // This is purely functional for the Australian dollar which would otherwise be formatted as "A$"
              return symbol ?? value
            case 'group':
              return currenciesCommaThousandSeparator.includes(storeCurrency) ? ',' : value
            case 'decimal':
              return currenciesPeriodDecimalSeparator.includes(storeCurrency) ? '.' : value
            default:
              return value
          }
        })
        .join('')

      return formattedPrice
    },
    [store]
  )
}

const currenciesCommaThousandSeparator = ['MXN']
const currenciesPeriodDecimalSeparator = ['MXN']
const currenciesWithoutPrecision = ['JPY', 'HUF']
