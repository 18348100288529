import ConditionalRender from '@components/ConditionalRender'
import { Toggle } from '@components/core/Toggle/Toggle'
import { Heading5 } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BadgeChipStyled,
  FirstColumn,
  IconContainer,
  InfoText,
  OptionBox,
  RadioInputStyled,
  SecondColumn,
} from './styles'
import { OptionToggleProps, SwitchTypes } from './types'

const OptionToggle: FC<OptionToggleProps> = ({ option, handleToggle, activeOption, index }) => {
  const { t } = useTranslation()
  const { id, icon, title, text, isChecked, onChange, type, disabled } = option
  const [checked, setChecked] = useState(isChecked || false)
  const { zoomLevel } = useNavigationContext()

  const handleClick = () => {
    if (disabled) return false
    handleToggle(index, !checked)
    setChecked(!checked)
    onChange?.(id, !checked)
  }

  useEffect(() => {
    if (activeOption !== null && id !== activeOption && checked) {
      setChecked(false)
      handleToggle(index, false)
      onChange?.(id, false)
    } else if (activeOption === null && checked) {
      handleToggle(index, checked)
    }
  }, [activeOption, checked, handleToggle, id, index, onChange])

  const getSwitchType = () => {
    switch (type) {
      case SwitchTypes.radio:
        return (
          <RadioInputStyled
            type="radio"
            name={`${id}_radio`}
            checked={activeOption === id}
            id={`${id}_radio`}
            readOnly={true}
          />
        )
      case SwitchTypes.toggle:
        return <Toggle checked={activeOption === id} />
    }
  }

  const switchType = useMemo(() => {
    return getSwitchType()
  }, [checked, activeOption])

  return (
    <OptionBox
      key={id}
      onClick={handleClick}
      checked={activeOption === id}
      disabled={disabled}
      tabIndex={0}
    >
      <FirstColumn>
        {switchType}
        <ConditionalRender
          condition={icon && zoomLevel < 200}
          render={() => <IconContainer>{icon}</IconContainer>}
        />
      </FirstColumn>
      <SecondColumn>
        <ConditionalRender
          condition={type === SwitchTypes.radio && disabled}
          render={() => (
            <ul>
              <BadgeChipStyled disabled={true}>
                {t('Prescription.notAvailableBadge')}
              </BadgeChipStyled>
            </ul>
          )}
        />

        <Heading5 weight="bold">{title}</Heading5>
        <ConditionalRender condition={text} render={text => <InfoText>{text}</InfoText>} />
      </SecondColumn>
    </OptionBox>
  )
}

export default OptionToggle
