import { ModalBaseProps } from '@abstractTypes/modal'
import { useAriaFocusTrap } from '@hooks/useAriaFocusTrap'
import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { Mask, Modal, ModalBaseWrapper, ModalBody, ModalContent, Root } from './styles'

export const ModalBase: React.FC<ModalBaseProps> = ({
  className = 'modal-base',
  width,
  children,
  centered,
  visible,
  mask,
  style,
  closable,
  onCancel,
  destroyOnClose,
  aria,
  onEntered,
}) => {
  const [isMounted, setIsMounted] = useState(false)
  const rootModal = useRef<HTMLDivElement>(document.createElement('div'))
  const modalId = useRef(`modal-root-${uniqueId()}`)
  rootModal.current.id = modalId.current

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isTransitionEnd, setIsTransitionEnd] = useState(false)
  const { setRef } = useAriaFocusTrap(!!visible && isMounted && isTransitionEnd)

  const onExitHandler = useCallback(() => {
    setIsTransitionEnd(false)
  }, [])
  const onEnteredHandler = useCallback(() => {
    onEntered && onEntered()
    setIsTransitionEnd(true)
  }, [])

  useEffect(() => {
    const existingRootModal = document.getElementById(modalId.current)
    if (visible && rootModal.current && !existingRootModal) {
      document.body.appendChild(rootModal.current)
      setIsMounted(true)
    }
  }, [visible])

  useEffect(() => {
    const destroyOnUnmount = () => {
      const existingRootModal = document.getElementById(modalId.current)
      if (existingRootModal) {
        // Prevent error while hot-reload. Could happend root modal not injected in the DOM
        try {
          document.body.removeChild(rootModal.current)
        } catch (e) {
          return
        }
      }
    }
    return () => {
      if (destroyOnClose) {
        destroyOnUnmount()
        setIsMounted(false)
      }
    }
  }, [destroyOnClose])

  const onWrapperClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!closable) return
    if (wrapperRef.current === e.target) {
      onCancel?.(e)
    }
  }

  return createPortal(
    <Root className="root">
      <CSSTransition
        unmountOnExit={destroyOnClose}
        classNames="css-modal"
        in={visible}
        timeout={200}
        onEntered={onEnteredHandler}
        onExit={onExitHandler}
      >
        <>
          {mask && <Mask className="mask" />}
          <ModalBaseWrapper
            className="modal-wrap"
            centered={centered}
            role="dialog"
            tabIndex={-1}
            ref={wrapperRef}
            onClick={onWrapperClick}
          >
            <Modal
              className={`${className} ${centered && 'centered'}`}
              role="document"
              style={{ ...style, inlineSize: width || 'auto' }}
              aria-labelledby={aria?.labelledby}
              aria-modal={visible}
              aria-describedby={aria?.describedby}
              ref={setRef}
            >
              <ModalContent className="modal-content">
                <ModalBody className="modal-body">{children}</ModalBody>
              </ModalContent>
            </Modal>
          </ModalBaseWrapper>
        </>
      </CSSTransition>
    </Root>,
    rootModal.current
  )
}
