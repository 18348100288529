import { AppConfigOverride, BannerRegion } from '@abstractTypes/envs'

const getSghEnvVariables = (region: BannerRegion) => {
  const sghEnvVariables: AppConfigOverride = {
    shopperSlug: 'sgh',
    shopperName: 'SUNGLASSHUT',
    storeID: '3024',
    storeLang: 'en-US',
    toggleFeature: {
      availableInStore: false,
    },
    showExtraPdpDetails: region === 'anz',
    showStoreAvailableFilter: true,
    vmStore: 'sgh',
    vmStyle: 'sgh',
  }

  return sghEnvVariables
}

export default getSghEnvVariables
