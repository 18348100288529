import { ElectronicsTypes } from '@abstractTypes/electronics'
import { useAnalyticsEvent } from '@hooks/analyticsHooks'
import { PAGES, PAGE_SECTION_1, PAGE_SECTION_2, PAGE_TYPES } from '@libs/analytics'

export const useSendVirtualPageView = (electronicsType: ElectronicsTypes) =>
  useAnalyticsEvent({
    id: 'VirtualPage-View',
    Page_Type: PAGE_TYPES[PAGES.electronics],
    Page_Section1: PAGE_SECTION_1[PAGES.electronics],
    Page_Section2: PAGE_SECTION_2[PAGES[electronicsType]],
  })
