import { Heading1 } from '@components/core/Typography'
import styled from 'styled-components'

export const ErrorWidget = styled.div`
  margin-block-start: 2em;
  padding-block: 3em;
  padding-inline: 1em;
  inline-size: 90%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.outline};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
`

export const ErrorWidgetTitle = styled(Heading1)`
  margin-block-start: 0.5em;
  word-break: break-word;
`

export const ErrorWidgetLabels = styled.div`
  margin-block-end: 1em;
  text-align: center;
`

export const ErrorWidgetDetails = styled.div`
  text-align: end;

  a {
    display: inline-block;

    &::after {
      display: inline-block;
      inline-size: 0.9091rem;
    }
  }
`

export const ErrorWidgetMessages = styled.div`
  padding: 1em;
  block-size: auto;
  visibility: visible;
  opacity: 1;
  text-align: start;
  min-block-size: 4.5455rem;
`
export const ErrorWidgetList = styled.ul`
  margin: 0;
  padding: 0;
`

export const ErrorWidgetMessage = styled.li`
  margin: 0;
  list-style: none;

  ul {
    padding-inline-start: 1em;
    border-inline-start: 1px solid ${({ theme }) => theme.colors.outline};
  }
`
