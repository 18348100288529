import { IconProps } from '@abstractTypes/icons'
export const CaretRightThinIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M1.56901 0.43107L0.626341 1.37374L15.4883 16.2357L0.626343 31.0977L1.56901 32.0404L17.3737 16.2357L1.56901 0.43107Z"
        fill="#333333"
      />
    </svg>
  )
}
