import { BannerInfo, BannerRegion, ShopperSlug } from '@abstractTypes/envs'
import { ORIGIN_SUBDNS } from 'src/variables/default/const'
import getDeploymentEnvFromDns from './getDeploymentEnvFromDns'

const getBannerInfoByDns = (): BannerInfo => {
  const [, dns] = origin.replace(ORIGIN_SUBDNS, '').split('.')

  let shopperSlug: ShopperSlug
  let region: BannerRegion

  switch (dns) {
    case 'opsm-dev':
    case 'opsm-stage':
    case 'opsm-test':
    case 'opsm-uat':
    case 'opsm': {
      shopperSlug = 'opsm'
      region = 'emea'
      break
    }

    case 'fcemea-dev':
    case 'fcemea-stage':
    case 'fcemea-test':
    case 'fcemea-uat':
    case 'fcemea': {
      shopperSlug = 'sgh'
      region = 'emea'
      break
    }

    case 'fcanz-dev':
    case 'fcanz-stage':
    case 'fcanz-test':
    case 'fcanz-uat':
    case 'fcanz': {
      shopperSlug = 'sgh'
      region = 'anz'
      break
    }

    case 'fc-dev':
    case 'fc-stage':
    case 'fc-test':
    case 'fc-uat':
    case 'fc-us': {
      shopperSlug = 'sgh'
      region = 'na'
      break
    }

    case 'fcsea-dev':
    case 'fcsea-stage':
    case 'fcsea-test':
    case 'fcsea-uat':
    case 'fcsea': {
      shopperSlug = 'sgh'
      region = 'sea'
      break
    }

    case 'fcbr-dev':
    case 'fcbr-stage':
    case 'fcbr-test':
    case 'fcbr-uat':
    case 'fcbr-us': {
      shopperSlug = 'sgh'
      region = 'na'
      break
    }

    case 'dcemea-dev':
    case 'dcemea-stage':
    case 'dcemea-test':
    case 'dcemea-uat':
    case 'dcemea': {
      shopperSlug = 'dc'
      region = 'emea'
      break
    }

    case 'outfitters-dev':
    case 'outfitters-stage':
    case 'outfitters-test':
    case 'outfitters-uat':
    case 'outfitters-us': {
      shopperSlug = 'sghof'
      region = 'na'
      break
    }

    case 'svemea-dev':
    case 'svemea-stage':
    case 'svemea-test':
    case 'svemea-uat':
    case 'svemea': {
      shopperSlug = 'salmoiraghi'
      region = 'emea'
      break
    }

    case 'lc-dev':
    case 'lc-stage':
    case 'lc-test':
    case 'lc-uat':
    case 'lc-us': {
      shopperSlug = 'lc'
      region = 'na'
      break
    }

    case 'oc-dev':
    case 'oc-stage':
    case 'oc-test':
    case 'oc-uat':
    case 'oc-na': {
      shopperSlug = 'oc'
      region = 'na'
      break
    }

    case 'al-dev':
    case 'al-stage':
    case 'al-test':
    case 'al-uat':
    case 'al-us': {
      shopperSlug = 'al'
      region = 'na'
      break
    }

    case 'pv-dev':
    case 'pv-stage':
    case 'pv-test':
    case 'pv-uat':
    case 'pv-us': {
      shopperSlug = 'pv'
      region = 'na'
      break
    }

    case 'rbemea-dev':
    case 'rbemea-test':
    case 'rbemea-stage':
    case 'rbemea-uat':
    case 'rbemea': {
      shopperSlug = 'rayban'
      region = 'emea'
      break
    }

    case 'rb-dev':
    case 'rb-test':
    case 'rb-uat':
    case 'rb-stage':
    case 'rb-us': {
      shopperSlug = 'rayban'
      region = 'na'
      break
    }

    case 'rbsea-dev':
    case 'rbsea-test':
    case 'rbsea-stage':
    case 'rbsea-uat':
    case 'rbsea': {
      shopperSlug = 'rayban'
      region = 'sea'
      break
    }

    case 'rbbr-dev':
    case 'rbbr-stage':
    case 'rbbr-test':
    case 'rbbr-uat':
    case 'rbbr-us': {
      shopperSlug = 'rayban'
      region = 'na'
      break
    }

    case 'rbanz-dev':
    case 'rbanz-stage':
    case 'rbanz-test':
    case 'rbanz-uat':
    case 'rbanz': {
      shopperSlug = 'rayban'
      region = 'anz'
      break
    }

    case 'gviemea-dev':
    case 'gviemea-stage':
    case 'gviemea-test':
    case 'gviemea-uat':
    case 'gviemea': {
      shopperSlug = 'gvi'
      region = 'emea'
      break
    }

    case 'groptemea-dev':
    case 'groptemea-stage':
    case 'groptemea-test':
    case 'groptemea-uat':
    case 'groptemea': {
      shopperSlug = 'go'
      region = 'emea'
      break
    }

    case 'solemea-dev':
    case 'solemea-stage':
    case 'solemea-test':
    case 'solemea-uat':
    case 'solemea': {
      shopperSlug = 'sol'
      region = 'emea'
      break
    }

    case 'sol-dev':
    case 'sol-stage':
    case 'sol-test':
    case 'sol-uat':
    case 'sol-us': {
      shopperSlug = 'sol'
      region = 'na'
      break
    }

    case 'gvfe-dev':
    case 'gvfe-stage':
    case 'gvfe-test':
    case 'gvfe-uat':
    case 'gvfe-us': {
      shopperSlug = 'gvife'
      region = 'na'
      break
    }

    case 'lpanz-dev':
    case 'lpanz-stage':
    case 'lpanz-test':
    case 'lpanz-uat':
    case 'lpanz': {
      shopperSlug = 'lp'
      region = 'anz'
      break
    }

    case 'to-dev':
    case 'to-stage':
    case 'to-test':
    case 'to-uat':
    case 'to': {
      shopperSlug = 'to'
      region = 'na'
      break
    }

    case 'oo-dev':
    case 'oo-stage':
    case 'oo-test':
    case 'oo-uat':
    case 'oo-na': {
      shopperSlug = 'oo'
      region = 'na'
      break
    }

    case 'ooemea-dev':
    case 'ooemea-stage':
    case 'ooemea-test':
    case 'ooemea-uat':
    case 'oo-emea': {
      shopperSlug = 'oo'
      region = 'emea'
      break
    }

    case 'ooanz-dev':
    case 'ooanz-stage':
    case 'ooanz-test':
    case 'ooanz-uat':
    case 'oo-anz': {
      shopperSlug = 'oo'
      region = 'anz'
      break
    }

    case 'oosea-dev':
    case 'oosea-stage':
    case 'oosea-test':
    case 'oosea-uat':
    case 'oosea': {
      shopperSlug = 'oo'
      region = 'sea'
      break
    }

    case 'sphsea-dev':
    case 'sphsea-stage':
    case 'sphsea-test':
    case 'sphsea-uat':
    case 'sphsea': {
      shopperSlug = 'sh'
      region = 'sea'
      break
    }

    case 'tv-dev':
    case 'tv-stage':
    case 'tv-test':
    case 'tv-uat':
    case 'tv-us': {
      shopperSlug = 'tv'
      region = 'na'
      break
    }

    case 'multioptemea-dev':
    case 'multioptemea-stage':
    case 'multioptemea-test':
    case 'multioptemea-uat':
    case 'multioptemea': {
      shopperSlug = 'mo'
      region = 'emea'
      break
    }

    default: {
      shopperSlug = 'sgh'
      region = 'na'
      break
    }
  }

  return {
    shopperSlug,
    region,
    deploymentEnv: getDeploymentEnvFromDns(dns),
  }
}

export default getBannerInfoByDns
