import { Device } from '@abstractTypes/utils'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const FrameAdvisorHeaderContainer = styled('div')<{ isAlternativeNavigation: boolean }>`
  z-index: 2;
  position: fixed;
  inset-inline-start: 0;
  inline-size: 100vw;
  block-size: ${pxToRem(540)};
  inset-block-start: ${({ isAlternativeNavigation }) => (isAlternativeNavigation ? 'unset' : '0')};
  background-color: ${({ theme }) => theme.colors.primaryLight};
  ${tablet(`
    block-size: ${clampValues(360, 496)};
  `)}

  ${landscape(`
    block-size: ${pxToRem(252)};
    padding-block-start: ${pxToRem(32)};
  `)}
  ${landscape(
    Device.tablet,
    `
    block-size: ${clampValuesLandscape(152, 200)};
    padding-block-start: 0;
  `
  )}
`
