import { MutationCustomerOrderCheckoutArgs } from '@abstractTypes/graphqlTypes'
import { useCart } from '@hooks/useCart'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { formatCartDataForPayload } from '@libs/checkout'
import { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useCustomerOrder } from './useCustomOrder'

export const useCustomerOrderCheckoutData = () => {
  const { storeId, storeType, langCode } = useStoreIndentity()
  const email = useSelector(s => s.customerOrder.email)
  const { items } = useCart()
  const salesOrderId = useSelector(s => s.customerOrder.salesOrderId)
  const engravingDetails = useSelector(state => state.engraving, shallowEqual)

  const { isCompletePairOrderType } = useCustomerOrder()

  const getCustomerOrderCheckoutData = useCallback(() => {
    const customerOrderCheckoutData: MutationCustomerOrderCheckoutArgs['data'] = {
      storeId,
      storeType,
      langCode: langCode,
      salesOrderId,
      email,
      items: formatCartDataForPayload(items, engravingDetails, isCompletePairOrderType),
    }

    return customerOrderCheckoutData
  }, [
    storeId,
    storeType,
    salesOrderId,
    items,
    engravingDetails,
    langCode,
    isCompletePairOrderType,
    email,
  ])

  return getCustomerOrderCheckoutData
}
