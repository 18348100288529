import { useSendAnalyticsEvent } from '@hooks/analyticsHooks'
import { useEffect } from 'react'

export const useSendVirtualPageView = (
  shouldSendEvent: boolean,
  hasPrescriptionStock: boolean,
  hasNonPrescriptionStock: boolean
) => {
  const getPrescriptionModalImpression = (
    hasPrescriptionStock: boolean,
    hasNonPrescriptionStock: boolean
  ) => {
    if (hasPrescriptionStock && hasNonPrescriptionStock) return 'AddToCartMode:ALL'
    if (hasPrescriptionStock) return 'AddToCartMode:PrescriptionOnly'
    return 'AddToCartMode:FrameOnly'
  }

  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: getPrescriptionModalImpression(hasPrescriptionStock, hasNonPrescriptionStock),
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [sendImpressionEvent, shouldSendEvent])
}
