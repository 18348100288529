import { IconProps } from '@abstractTypes/icons'


export const FaceScanAppConnectedDotIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4455 4.97777H27.5956C28.4252 4.97777 29.0336 5.64147 29.0336 6.41579V30.0879C29.0336 30.8622 28.3699 31.5259 27.5956 31.5259H11.4455C10.6712 31.5259 10.0074 30.8622 10.0074 30.0879V6.41579C10.0074 5.64147 10.6158 4.97777 11.4455 4.97777ZM27.5956 6.08391H11.4455C11.2243 6.08391 11.1136 6.24984 11.1136 6.41577V26.161H27.9275V6.41577C27.9275 6.24984 27.7615 6.08391 27.5956 6.08391ZM27.5956 30.4197H11.4455C11.2796 30.4197 11.1136 30.2538 11.1136 30.0879V27.2672H27.9828V30.0879C27.9275 30.2538 27.8169 30.4197 27.5956 30.4197ZM19.1887 28.0968H20.3502C20.6267 28.0968 20.9032 28.318 20.9032 28.6498C20.9032 28.9817 20.682 29.2029 20.3502 29.2029H19.1887C18.8568 29.2029 18.6356 28.9817 18.6356 28.6498C18.6356 28.318 18.8568 28.0968 19.1887 28.0968Z"
        fill={fill}
      />
      <path
        d="M21.5364 13.8938C19.9699 12.3671 18.0982 11.56 15.9028 11.524C15.757 11.5216 15.6487 11.5724 15.6077 11.7164C15.57 11.8489 15.6295 11.9464 15.745 12.0135C15.7902 12.0403 15.8389 12.0395 15.8886 12.0401C17.4936 12.0599 18.9641 12.5182 20.2532 13.4696C22.2503 14.9437 23.2988 16.9373 23.4047 19.4227C23.4069 19.472 23.4069 19.5216 23.4093 19.5711C23.4175 19.7448 23.52 19.8525 23.6721 19.8483C23.8241 19.8441 23.924 19.7346 23.9248 19.5612C23.9266 19.2454 23.9068 18.9298 23.8655 18.6167C23.6306 16.84 22.8931 15.3046 21.6527 14.0105C21.614 13.9701 21.575 13.9323 21.5364 13.8938Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.666667"
      />
      <path
        d="M20.099 15.3505C18.9578 14.2492 17.5996 13.6502 16.0054 13.5965C15.9213 13.5894 15.8366 13.6 15.7569 13.6276C15.6372 13.6742 15.5605 13.7405 15.5811 13.8802C15.5966 14.0139 15.7102 14.1146 15.8448 14.1139C16.399 14.1059 16.9367 14.2025 17.4653 14.3615C19.7244 15.0414 21.3224 17.1672 21.3469 19.5258C21.3491 19.733 21.4446 19.8508 21.6097 19.8504C21.7747 19.85 21.8738 19.7266 21.8672 19.5086C21.8252 17.8919 21.2269 16.5119 20.099 15.3505Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.666667"
      />
      <path
        d="M18.6524 16.8122C17.895 16.0807 16.9941 15.6849 15.9378 15.6508C15.7978 15.6462 15.6795 15.6761 15.617 15.8118C15.5308 15.9958 15.6725 16.1682 15.9091 16.1714C16.4775 16.1804 17.0181 16.3119 17.5092 16.598C18.6394 17.2559 19.2386 18.2427 19.2926 19.5529C19.2986 19.6984 19.3505 19.8039 19.4938 19.8436C19.621 19.8794 19.7143 19.8201 19.7811 19.7134C19.8142 19.6606 19.8144 19.6001 19.8136 19.541C19.7837 18.4796 19.3877 17.5738 18.6524 16.8122Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.666667"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8192 18.8972C17.958 19.2113 17.7068 19.5264 17.3635 19.5264H16.7027C16.3345 19.5264 16.0361 19.2279 16.0361 18.8597V18.3485C16.0361 17.9278 16.4243 17.6183 16.8004 17.8068C16.9963 17.905 17.1982 18.0337 17.3635 18.199C17.5672 18.4027 17.7153 18.662 17.8192 18.8972Z"
        fill={fill}
      />
      <ellipse cx="28.7294" cy="5.97332" rx="3.73333" ry="3.73333" fill="#D0021B" />
    </svg>
  )
}
