/* stylelint-disable */

import config from '@config/index'
import { css } from 'styled-components'

// TODO: use a lowercase naming convention for the font file names, when we'll be done with the migration of all the other banners under master
const Amiri = `${config.assets.URL}/fonts/amiri/amiri-v13-latin-regular.woff2`
const Barlow = `${config.assets.URL}/fonts/barlow/barlow-regular.woff2`
const BarlowBold = `${config.assets.URL}/fonts/barlow/barlow-bold.woff2`
const BarlowMedium = `${config.assets.URL}/fonts/barlow/barlow-medium.woff2`
const BarlowRegular = `${config.assets.URL}/fonts/barlow/barlow-regular.woff2`
const Righteous = `${config.assets.URL}/fonts/engraving/righteous/Righteous-Regular.woff2`
const ArtelyInks = `${config.assets.URL}/fonts/engraving/artely-inks/artely-inks.woff2`
const Oswald = `${config.assets.URL}/fonts/oswald/Oswald-VariableFont_wght.ttf`
const GaramondItalic = `${config.assets.URL}/fonts/engraving/garamond-italic/garamond-italic.ttf`
const ScoutCondRegular = `${config.assets.URL}/fonts/scout_cond/scout-cond-regular.woff`
const ScoutCondLight = `${config.assets.URL}/fonts/scout_cond/scout-cond-light.woff`
const Helvetica = `${config.assets.URL}/fonts/helvetica/Helvetica.woff2`
const HelveticaBold = `${config.assets.URL}/fonts/helvetica/Helvetica-Bold.woff2`
const HelveticaNeue = `${config.assets.URL}/fonts/helveticaneue/HelveticaNeue.woff2`
const HelveticaNeueLight = `${config.assets.URL}/fonts/helveticaneue/helveticaneue-light.woff2`
const HelveticaNeueMedium = `${config.assets.URL}/fonts/helveticaneue/helveticaneue-medium.woff2`
const HelveticaNeueBold = `${config.assets.URL}/fonts/helveticaneue/helveticaneue-bold.woff2`
const Lato = `${config.assets.URL}/fonts/engraving/lato-regular/lato-regular.woff2`
const MoreproCondbook = `${config.assets.URL}/fonts/more_pro_condensed/morepro-condbook.woff`
const MoreproCondbook_2 = `${config.assets.URL}/fonts/more_pro_condensed/morepro-condbook.woff2`
const Montserrat = `${config.assets.URL}/fonts/montserrat/montserrat-regular.ttf`
const MontserratMedium = `${config.assets.URL}/fonts/montserrat/montserrat-medium.ttf`
const MontserratSemibold = `${config.assets.URL}/fonts/montserrat/montserrat-semibold.ttf`
const MontserratBold = `${config.assets.URL}/fonts/montserrat/montserrat-bold.ttf`
const Nunito = `${config.assets.URL}/fonts/nunito/regular/Nunito-regular.woff2`
const NunitoSemibold = `${config.assets.URL}/fonts/nunito/semibold/Nunito-semibold.woff2`
const NunitoBold = `${config.assets.URL}/fonts/nunito/bold/Nunito-bold.woff2`
const SofiaPro = `${config.assets.URL}/fonts/sofia_pro/SofiaProRegular.woff`
const SofiaProBold = `${config.assets.URL}/fonts/sofia_pro/SofiaProBold.woff`
const AvenirNext = `${config.assets.URL}/fonts/avenir/regular/AvenirNext-Regular.ttf`
const AvenirLight = `${config.assets.URL}/fonts/avenir/light/Avenir-Light.woff`
const AvenirHeavy = `${config.assets.URL}/fonts/avenir/heavy/Avenir-Heavy.woff`
const AvenirBold = `${config.assets.URL}/fonts/avenir/avenir-bold.woff`
const AvenirDemiBold = `${config.assets.URL}/fonts/avenir/avenir-demi-bold.woff`
const Poppins = `${config.assets.URL}/fonts/poppins/poppins-300.ttf`
const PoppinsBold = `${config.assets.URL}/fonts/poppins/poppins-600.ttf`
const DMSans = `${config.assets.URL}/fonts/dm_sans/DMSans-Regular.ttf`
const DMSansMedium = `${config.assets.URL}/fonts/dm_sans/DMSans-Medium.ttf`
const DMSansBold = `${config.assets.URL}/fonts/dm_sans/DMSans-Bold.ttf`
const Touvlo = `${config.assets.URL}/fonts/touvlo/Touvlo-Regular.otf`
const TouvloMedium = `${config.assets.URL}/fonts/touvlo/Touvlo-Medium.otf`
const TouvloBold = `${config.assets.URL}/fonts/touvlo/Touvlo-Bold.otf`
const Garet = `${config.assets.URL}/fonts/garet/Garet-Book.otf`
const GareHeavy = `${config.assets.URL}/fonts/garet/Garet-Heavy.otf`
const TTHoves = `${config.assets.URL}/fonts/tt-hoves/regular.otf`
const TTHovesBlack = `${config.assets.URL}/fonts/tt-hoves/black.otf`
const TTHovesBold = `${config.assets.URL}/fonts/tt-hoves/bold.otf`
const TTHovesLight = `${config.assets.URL}/fonts/tt-hoves/light.otf`
const TTHovesThin = `${config.assets.URL}/fonts/tt-hoves/thin.otf`
const TTHovesMedium = `${config.assets.URL}/fonts/tt-hoves/medium.otf`
const TTHovesItalic = `${config.assets.URL}/fonts/tt-hoves/italic.otf`
const TTHovesBlackItalic = `${config.assets.URL}/fonts/tt-hoves/blackItalic.otf`
const TTHovesBoldItalic = `${config.assets.URL}/fonts/tt-hoves/boldItalic.otf`
const TTHovesLightItalic = `${config.assets.URL}/fonts/tt-hoves/lightItalic.otf`
const TTHovesThinItalic = `${config.assets.URL}/fonts/tt-hoves/thinItalic.otf`
const TTHovesMediumItalic = `${config.assets.URL}/fonts/tt-hoves/mediumItalic.otf`

export const LatoFontFamily = 'Lato'
export const RighteousFontFamily = 'Righteous'
export const ArtelyInksFontFamily = 'ArtelyInks'
export const GaramondItalicFontFamily = 'GaramondItalic'

const commonFontProps = css`
  font-display: swap;
  font-style: normal;
`

export const fonts = css`
  @import url('https://p.typekit.net/p.css?s=1&k=tnj7uqf&ht=tk&f=28977.28991&a=89793109&app=typekit&e=css');
  @import url('https://p.typekit.net/p.css?s=1&k=fbx4gfb&ht=tk&f=5554.5555.5556.5557&a=78729331&app=typekit&e=css');
  @import url('https://p.typekit.net/p.css?s=1&k=nia4yop&ht=tk&f=2005.2006.2009.2010.9938.9939.39002.39004.17398.17399.17400.17401.22618.22619&a=78729331&app=typekit&e=css');

  /* Termina */
  @font-face {
    ${commonFontProps}
    font-family: 'Termina';
    src:
      url('https://use.typekit.net/af/1d76ab/00000000000000007735b21c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/1d76ab/00000000000000007735b21c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/1d76ab/00000000000000007735b21c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-weight: 400;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'Termina';
    src:
      url('https://use.typekit.net/af/574117/00000000000000007735b222/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/574117/00000000000000007735b222/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/574117/00000000000000007735b222/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('opentype');
    font-weight: 700;
  }

  /* MinervaModern */
  @font-face {
    ${commonFontProps}
    font-family: 'MinervaModern';
    src:
      url('https://use.typekit.net/af/3d9e8b/000000000000000077359a48/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/3d9e8b/000000000000000077359a48/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/3d9e8b/000000000000000077359a48/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'MinervaModern';
    src:
      url('https://use.typekit.net/af/0de237/000000000000000077359a4c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/0de237/000000000000000077359a4c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/0de237/000000000000000077359a4c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('opentype');
    font-weight: 700;
    font-stretch: normal;
  }

  /* Museo Sans */
  @font-face {
    ${commonFontProps}
    font-family: 'MuseoSans';
    src:
      url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('woff'),
      url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('opentype');
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'MuseoSans';
    src:
      url('https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('opentype');
    font-weight: 700;
    font-stretch: normal;
  }

  /* MorePro-CondBook-regular - latin */
  @font-face {
    ${commonFontProps}
    font-family: 'MorePro-CondBook';
    src:
      url(${MoreproCondbook_2}) format('woff2'),
      url(${MoreproCondbook}) format('woff');
    font-weight: 400;
  }
  /* ScoutCond-regular - latin */
  @font-face {
    ${commonFontProps}
    font-family: 'ScoutCond';
    src: url(${ScoutCondRegular}) format('woff');
    font-weight: 400;
  }
  /* ScoutCond-light - latin */
  @font-face {
    ${commonFontProps}
    font-family: 'ScoutCond';
    src: url(${ScoutCondLight}) format('woff');
    font-weight: 300;
  }
  /* Garamond-italic */
  @font-face {
    ${commonFontProps}
    font-family: ${GaramondItalicFontFamily};
    src: url(${GaramondItalic}) format('truetype');
    font-weight: 300;
  }
  /* Righteous */
  @font-face {
    ${commonFontProps}
    font-family: ${RighteousFontFamily};
    src: url(${Righteous}) format('woff2');
    font-weight: 300;
  }
  /* Artely-inks */
  @font-face {
    ${commonFontProps}
    font-family: ${ArtelyInksFontFamily};
    src: url(${ArtelyInks}) format('woff2');
    font-weight: 300;
  }

  /* Oswald */
  @font-face {
    ${commonFontProps}
    font-family: 'Oswald';
    src: url(${Oswald}) format('truetype');

    font-weight: 400;
  }

  /* HelveticaNeue */
  @font-face {
    ${commonFontProps}
    font-family: 'HelveticaNeue';
    src: url(${HelveticaNeue}) format('woff2');

    font-weight: 400;
  }
  /* HelveticaNeue-Light */
  @font-face {
    ${commonFontProps}
    font-family: 'HelveticaNeue';
    src: url(${HelveticaNeueLight}) format('woff2');
    font-weight: 300;
  }
  /* HelveticaNeue-Medium */
  @font-face {
    ${commonFontProps}
    font-family: 'HelveticaNeue';
    src: url(${HelveticaNeueMedium}) format('woff2');
    font-weight: 500;
  }
  /* HelveticaNeue-Bold */
  @font-face {
    ${commonFontProps}
    font-family: 'HelveticaNeue';
    src: url(${HelveticaNeueBold}) format('woff2');
    font-weight: 700;
  }
  /* Montserrat-regular */
  @font-face {
    ${commonFontProps}
    font-family: 'Montserrat';
    src: url(${Montserrat}) format('woff2');
    font-weight: 400;
  }
  /* Montserrat-medium */
  @font-face {
    ${commonFontProps}
    font-family: 'Montserrat';
    src: url(${MontserratMedium}) format('woff2');
    font-weight: 500;
  }
  /* Montserrat-semibold */
  @font-face {
    ${commonFontProps}
    font-family: 'Montserrat';
    src: url(${MontserratSemibold}) format('woff2');
    font-weight: 600;
  }
  /* Montserrat-bold */
  @font-face {
    ${commonFontProps}
    font-family: 'Montserrat';
    src: url(${MontserratBold}) format('woff2');
    font-weight: 700;
  }
  /* Lato */
  @font-face {
    ${commonFontProps}
    font-family: ${LatoFontFamily};
    src: url(${Lato}) format('woff2');
    font-weight: 400;
  }
  /* Amiri */
  @font-face {
    ${commonFontProps}
    font-family: 'Amiri';
    src: url(${Amiri}) format('woff2');
    font-weight: 400;
  }
  @font-face {
    ${commonFontProps}
    font-family: 'Amiri';
    src: url(${Amiri}) format('woff2');
    font-weight: 500;
  }
  @font-face {
    ${commonFontProps}
    font-family: 'Amiri';
    src: url(${Amiri}) format('woff2');
    font-weight: 700;
  }
  /* Barlow */
  @font-face {
    ${commonFontProps}
    font-family: 'Barlow';
    src: url(${Barlow}) format('woff2');
    font-weight: 400;
  }
  @font-face {
    ${commonFontProps}
    font-family: 'Barlow';
    src: url(${BarlowBold}) format('woff2');
    font-weight: 700;
  }
  @font-face {
    ${commonFontProps}
    font-family: 'Barlow';
    src: url(${BarlowMedium}) format('woff2');
    font-weight: 500;
  }
  @font-face {
    ${commonFontProps}
    font-family: 'Barlow';
    src: url(${BarlowRegular}) format('woff2');
    font-weight: 500;
  }

  /* Nunito */
  @font-face {
    ${commonFontProps}
    font-family: 'Nunito';
    src: url(${Nunito}) format('woff2');
    font-weight: 400;
  }
  @font-face {
    ${commonFontProps}
    font-family: 'Nunito';
    src: url(${NunitoSemibold}) format('woff2');
    font-weight: 600;
  }
  @font-face {
    ${commonFontProps}
    font-family: 'Nunito';
    src: url(${NunitoBold}) format('woff2');
    font-weight: 700;
  }

  /* SofiaPro */
  @font-face {
    ${commonFontProps}
    font-family: 'SofiaPro';
    src: url(${SofiaPro}) format('woff');
    font-weight: 400;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'SofiaPro';
    src: url(${SofiaProBold}) format('woff');
    font-weight: 700;
    font-stretch: normal;
  }

  /* Helvetica */
  @font-face {
    ${commonFontProps}
    font-family: 'Helvetica';
    src: url(${Helvetica}) format('woff2');
    font-weight: 400;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'Helvetica';
    src: url(${HelveticaBold}) format('woff2');
    font-weight: 700;
  }
  /* Avenir Next */

  @font-face {
    font-family: 'Avenir Next';
    src: url(${AvenirNext}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'Avenir Next';
    src: url(${AvenirLight}) format('woff');
    font-weight: 300;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'Avenir Next';
    src: url(${AvenirBold}) format('woff');
    font-weight: 700;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'Avenir Next';
    src: url(${AvenirDemiBold}) format('woff');
    font-weight: 600;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'Avenir Next';
    src: url(${AvenirHeavy}) format('woff');
    font-weight: 900;
  }

  /* Poppins */
  @font-face {
    ${commonFontProps}
    font-family: 'Poppins';
    src: url(${Poppins}) format('woff');
    font-weight: 300;
  }

  @font-face {
    ${commonFontProps}
    font-family: 'Poppins';
    src: url(${PoppinsBold}) format('woff');
    font-weight: 600;
  }

  /* DM Sans */
  @font-face {
    ${commonFontProps}
    font-family: 'DM Sans';
    src: url(${DMSans}) format('woff');
    font-weight: 400;
  }

  /* DM Sans */
  @font-face {
    ${commonFontProps}
    font-family: 'DM Sans';
    src: url(${DMSansMedium}) format('woff');
    font-weight: 500;
  }

  /* DM Sans */
  @font-face {
    ${commonFontProps}
    font-family: 'DM Sans';
    src: url(${DMSansBold}) format('woff');
    font-weight: 700;
  }

  /* Touvlo */
  @font-face {
    ${commonFontProps}
    font-family: 'Touvlo';
    src: url(${Touvlo}) format('woff');
    font-weight: 400;
  }

  /* Touvlo */
  @font-face {
    ${commonFontProps}
    font-family: 'Touvlo';
    src: url(${TouvloMedium}) format('woff');
    font-weight: 500;
  }

  /* Touvlo */
  @font-face {
    ${commonFontProps}
    font-family: 'Touvlo';
    src: url(${TouvloBold}) format('woff');
    font-weight: 700;
  }

  /* Garet */
  @font-face {
    ${commonFontProps}
    font-family: 'Garet';
    src: url(${Garet}) format('woff');
    font-weight: 300;
  }

  /* Garet */
  @font-face {
    ${commonFontProps}
    font-family: 'Garet';
    src: url(${GareHeavy}) format('woff');
    font-weight: 850;
  }

  // #region TTHoves
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHoves}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesThin}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesThinItalic}) format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesMediumItalic}) format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesBoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'TTHoves';
    src: url(${TTHovesBlackItalic}) format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  // #endregion
`
