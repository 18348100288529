import { setCustomerOrder } from '@actions/customerOrder'
import { setBarcodeFlow, setCustomerOrderFlow, setFrameAdvisorFlow } from '@actions/flows'
import { setHasChangedPage } from '@actions/ui'
import config from '@config/index'
import { useCustomerOrder } from '@hooks/useCustomOrder'
import { usePrescriptionEnabled } from '@hooks/usePrescriptionEnabled'
import { BooleanResolver, useQueryParams } from '@hooks/useQueryParams'
import { useStoreContext } from '@hooks/useStoreContext'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { getCustomerOrderInfo } from '@libs/url'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'

const BackBtnActions = () => {
  const hasChangedPage = useSelector(s => s.ui.hasChangedPage)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!hasChangedPage) {
      dispatch(setHasChangedPage)
    }
  }, [dispatch, hasChangedPage])

  return <></>
}

const CustomerOrderActions = () => {
  const location = useLocation()
  const salesOrderId = useSelector(s => s.customerOrder.salesOrderId)
  const orderType = useSelector(s => s.customerOrder.orderType)
  const {
    salesOrderId: salesOrderIdFromUrl,
    upc,
    orderType: orderTypeFromUrl,
    email,
  } = getCustomerOrderInfo(location)
  const dispatch = useDispatch()
  const { basePath } = useStoreIndentity()
  const isNewParams = salesOrderIdFromUrl !== salesOrderId || orderTypeFromUrl !== orderType

  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)
  const { isCompletePairOrderType } = useCustomerOrder()
  const prescriptionEnabled = usePrescriptionEnabled()
  const isCustomerOrderErrorPageEnabled = config.toggleFeature.customerOrderError
  const shouldRedirectToCustomerOrderErrorPage =
    isCustomerOrderErrorPageEnabled &&
    isCustomerOrder &&
    isCompletePairOrderType &&
    !prescriptionEnabled

  useEffect(() => {
    if (salesOrderIdFromUrl && isNewParams) {
      dispatch(setCustomerOrder(salesOrderIdFromUrl, orderTypeFromUrl, email))
    }
  }, [dispatch, salesOrderIdFromUrl, isNewParams, orderTypeFromUrl, email])

  if (shouldRedirectToCustomerOrderErrorPage) {
    return <Redirect to={`${basePath}/error-customer-order`} />
  }

  return upc ? <Redirect to={`${basePath}?q=${upc}&fromCustomerOrder=true`} /> : <></>
}

const NavigationActions = () => {
  const dispatch = useDispatch()
  const store = useStoreContext()
  const queryParams = useQueryParams({
    fromCustomerOrder: BooleanResolver,
    comesFromBarcode: BooleanResolver,
    fromFrameAdvisor: BooleanResolver,
  })

  // TODO: as soon as we initilize the redux flow state, we have to remove the query param form the url,
  // this way we don't need to exclude the query param from the url while composing the plp payloads in useSanitizedFiltersFromUrl
  useEffect(() => {
    dispatch(setCustomerOrderFlow(!!queryParams.fromCustomerOrder))
    dispatch(setBarcodeFlow(!!queryParams.comesFromBarcode))
    dispatch(setFrameAdvisorFlow(!!store.frameAdvisorEnabled && !!queryParams.fromFrameAdvisor))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])
  return <></>
}

export const AppRouteActions = () => {
  const { basePath } = useStoreIndentity()
  return (
    <>
      <Route path={`${basePath}/.+`}>
        <BackBtnActions />
      </Route>
      <Route path={`${basePath}`}>
        <CustomerOrderActions />
      </Route>
      <Route path={`${basePath}`}>
        <NavigationActions />
      </Route>
    </>
  )
}
