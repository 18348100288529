import { Device } from '@abstractTypes/utils'
import { Heading4 } from '@components/core/Typography'
import { Tabs } from '@components/Tabs'
import { ScrollableTabContent } from '@components/Tabs/ScrollableTabContent'
import { ScrollablePaneContainer, TabPane } from '@components/Tabs/styles'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const DetailListContainer = styled.div`
  position: relative;
  padding-block: 0 ${pxToRem(37)};
  padding-inline: 0;
  margin-block-start: ${pxToRem(3)};

  ${landscape(
    Device.tablet,
    `
    margin-block-start: 2.5rem;
  `
  )}
  ${zoomLandscape(
    [200],
    `
    padding-block-start: ${pxToRem(160)};
  `
  )}
`

export const NuanceDetailListContainer = styled(DetailListContainer)`
  padding-inline: 0;
  inline-size: 100%;
`

export const TabsStyled = styled(Tabs)`
  ${ScrollablePaneContainer} {
    display: flex;
    justify-content: center;
    min-block-size: auto;
    overflow: visible;
    padding-block-start: ${pxToRem(150)};
    padding-block-end: ${pxToRem(22)};
    ${tablet(`padding-block-start: ${clampValues(120, 150)};`)}
  }

  ${TabPane} {
    opacity: 1;
  }
`
interface TabHeaderProps {
  isActive: boolean
}
export const TabHeader = styled(Heading4).attrs<TabHeaderProps>(({ isActive }) => ({
  weight: isActive ? 'bold' : false,
}))<TabHeaderProps>``

export const TabContainer = styled.div`
  display: inline-block;
  transition: height 0.3s;
  padding: 0;
  inline-size: 100%;
  block-size: 100%;
  vertical-align: middle;
`

export const DetailsContent = styled(ScrollableTabContent)`
  white-space: normal;
  block-size: calc(100% - ${pxToRem(70)});
  margin-block: ${pxToRem(56)} 0;
  margin-inline: ${pxToRem(113)} ${pxToRem(44)};
  padding-inline-end: ${pxToRem(60)};
  ${zoomPortrait(
    [150, 175, 200],
    `
    margin-block: ${pxToRem(40)} 0 ;
    margin-inline: ${pxToRem(113)} ${pxToRem(44)};
  `
  )}

  ${tablet(`
    margin-block: ${pxToRem(24)} ${pxToRem(20)} ;
    margin-inline:  ${pxToRem(96)} ${clampValues(24, 32)};
    block-size: calc(100% - ${pxToRem(44)});
    padding-inline-end: ${clampValues(42, 60)};
  `)}
  ${landscape(`
    margin-block: ${pxToRem(54)} ${pxToRem(20)};
    margin-inline: ${pxToRem(400)} ${pxToRem(112)};
    padding-inline-end: ${pxToRem(287)};
    block-size: calc(100% - ${pxToRem(74)});
  `)}
  ${landscape(
    Device.tablet,
    `
    margin-block: ${pxToRem(24)}  ${pxToRem(20)};
    margin-inline: ${clampValuesLandscape(220, 296)} ${clampValuesLandscape(22, 30)};
    block-size: calc(100% - ${pxToRem(44)});
    padding-inline-end: ${clampValuesLandscape(154, 205)};
  `
  )}
  ${altNavigation(`
    margin-block-start: ${pxToRem(48)};
  `)}
  ${altNavigationZoom(
    [150, 175, 200],
    `
      margin-block-start: ${pxToRem(40)};
  `
  )}
`

export const DetailsPanelContainer = styled.div`
  position: relative;
  block-size: 100%;
  inline-size: 100%;
`

export const PackagingTitle = styled(Heading4)`
  text-align: start;
  text-transform: uppercase;
  margin-block: ${pxToRem(32)} ${pxToRem(16)};
  margin-inline: 0;

  ${tablet(`
    margin-block: ${clampValues(12, 16)}  ${clampValues(16, 20)};
  `)};
`
