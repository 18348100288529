import { StandardProduct } from '@abstractTypes/product'
import { addAvailability, addItemToCart } from '@actions/cart'
import { Heading5 } from '@components/core/Typography'
import { ColorSwatch } from '@components/FrameAdvisor/ColorElements/ColorSwatch'
import AddToCart from '@components/pdp/AddToCart'
import { Price } from '@components/Price'
import { useCheckProductInStock } from '@components/PurchaseConfigure/hooks/useCheckProductInStock'
import { useSendAnalyticsAddToCartEvent } from '@hooks/analyticsHooks'
import { useActions } from '@hooks/useActions'
import { useCart } from '@hooks/useCart'
import { useIsCurrentProductInStock } from '@hooks/useIsCurrentProductInStock'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { formatProductForCart, notNull } from '@libs/formatters'
import { getProductImageGallery } from '@libs/image'
import { dataDescriptionForProduct } from '@libs/utils'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MerchCardColorsContainer,
  MerchCardColorSwitchTitle,
  MerchCardContentWrapper,
  MerchCardCTAWrapper,
  MerchCardDescription,
  MerchCardImageWrapper,
  MerchCardInfoContainer,
  MerchCardPrice,
  MerchCardTextContainer,
  MerchCardWrapper,
  MerchColorSwatch,
  MerchSwatchesWrapper,
} from './styles'

interface MerchCardProps {
  product: StandardProduct
}

const MerchCardComponent: React.FC<MerchCardProps> = ({ product }) => {
  const { t } = useTranslation()
  const { storeId } = useStoreIndentity()
  const { sendAddToCartEvent } = useSendAnalyticsAddToCartEvent({})
  const { items } = useCart()

  const variants = useMemo(() => [product, ...(product.variants || [])].filter(notNull), [product])

  const [selectedVariant, setSelectedVariant] = useState<StandardProduct>(variants[0])

  const { loading: isCheckAvailabilityLoading, ...stockData } =
    useCheckProductInStock(selectedVariant)
  const imageSource = getProductImageGallery(selectedVariant.thumbnails, 797, false)[0]
  const hasStock = useIsCurrentProductInStock(stockData.stockItems || [], selectedVariant.UPC)

  const actions = useActions({
    addToCart: addItemToCart,
    addAvailability,
  })

  const handleColorClick = useCallback(
    color => {
      setSelectedVariant(color)
    },
    [setSelectedVariant]
  )

  const isItemInCartAlready = items.find(item => item.product.UPC === selectedVariant.UPC)

  const handleAddToCart = useCallback(
    (addPrescription = false, addMixMatch = false) => {
      const cartItem = formatProductForCart(selectedVariant, addPrescription, addMixMatch)
      actions.addToCart(cartItem, storeId || '', 1, false)
      actions.addAvailability(
        stockData.realStockItems?.length ? stockData.realStockItems : stockData.stockItems || []
      )
      sendAddToCartEvent(cartItem)
    },
    [
      selectedVariant,
      actions,
      storeId,
      stockData.realStockItems,
      stockData.stockItems,
      sendAddToCartEvent,
    ]
  )

  return (
    <MerchCardWrapper>
      <MerchCardImageWrapper>
        <div>
          <img src={imageSource} />
        </div>
      </MerchCardImageWrapper>
      <MerchCardContentWrapper>
        <MerchCardPrice>
          <Heading5 weight="bold">
            <Price price={selectedVariant.price.current} />
          </Heading5>
        </MerchCardPrice>
        <MerchCardInfoContainer>
          <MerchCardTextContainer>
            <Heading5 weight="bold">{selectedVariant.name}</Heading5>
            <MerchCardDescription>
              {selectedVariant.name.includes('Pad') ? '' : selectedVariant.description}
            </MerchCardDescription>
          </MerchCardTextContainer>
          <MerchCardColorsContainer>
            <MerchCardColorSwitchTitle>
              <span>{t('Core.color')} </span>
              {selectedVariant.lensColorLabel ?? selectedVariant.frameColorLabel}
            </MerchCardColorSwitchTitle>
            <MerchSwatchesWrapper>
              {variants.map((item: StandardProduct, i) => (
                <MerchColorSwatch
                  key={i}
                  onClick={() => handleColorClick(item)}
                  active={selectedVariant.UPC === item.UPC}
                >
                  <ColorSwatch background={item.lensColor ?? item.frameColor} />
                </MerchColorSwatch>
              ))}
            </MerchSwatchesWrapper>
          </MerchCardColorsContainer>
        </MerchCardInfoContainer>
        <MerchCardCTAWrapper>
          <AddToCart
            product={selectedVariant}
            handleClickToCart={handleAddToCart}
            analyticsDataDescription={dataDescriptionForProduct(selectedVariant)}
            loading={isCheckAvailabilityLoading}
            stockItems={stockData.stockItems}
            hasStock={hasStock}
            customLabel={isItemInCartAlready ? t('ProductView.added') : t('ProductView.add')}
            disabled={!!isItemInCartAlready}
          />
        </MerchCardCTAWrapper>
      </MerchCardContentWrapper>
    </MerchCardWrapper>
  )
}

export default MerchCardComponent
