const colors = {
  transparent: ' transparent',
  black: '#1f1f24',
  transparenBlack: '#1f1f2460',
  dark: '#222',
  grey: '#e2e2e5',
  'medium-grey': '#474747',
  'light-dark-grey': '#666',
  'mid-grey': '#8c8c8d',
  'light-mid-grey': '#dadada',
  lightGrey: '#f3f3f3',
  white: '#fff',
  magenta: '#cf0a2c',
  error: '#f5222d',
  red: '#ff0d00',
  'light-yellow': '#f9f2e7',
  pink: '#f146e2',
  green: '#11b683',
  gold: '#d8af58', // " engravin",
  silver: '#c0c0c0', // " engravin",
  rose: '#ffe4e1', // " engravin",
  'color-primary': '#4f4f4f',
  'color-primary-dark': '#333',
  'color-secondary': '#d83b56',
  'color-accent': '#cf132d',
  'placeholder-color': 'rgb(117, 117, 117)',
}

const sizes = {
  tiny: '0.6364rem', //14px
  smallest: '0.727rem', //16px
  small: '0.818rem', //18px
  medium: '1rem', //22px
  large: '1.091rem', //24px
  largest: '1.455rem', //32px
  huge: '1.8182rem', //40px
}

const fonts = {
  primary: 'Roboto,Helvetica,Arial,sans-serif',
  secondary: 'Amiri,Helvetica,Arial,sans-serif',
  oakleyPrimary: 'ScoutCond,Helvetica,Arial,sans-serif',
  oakleySecondary: '"MorePro-CondBook",Helvetica,Arial,sans-serif',
  sizes,
}

// TODO: can we get rid of all these properties and just use the theme color props
// in the related styled components?
export default {
  checkout: {
    error_color: colors.error,
    input_border_color: colors.grey,
    input_color: colors.dark,
    input_error_color: colors.error,

    accordion_header_color: colors.dark,
    accordion_border_color: colors.grey,

    select_placeholder: colors['placeholder-color'],

    checkbox_bg_color: 'transparent',
    checkbox_checked_bg_color: colors.grey,
    checkbox_checked_border_color: colors.grey,

    separator: colors.lightGrey,
    displayRequiredStarBefore: 'none',
  },
  colors,
  fonts,
}
