import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ListItemButtonProps } from './types'

const ListItemButtonContainer = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  inline-size: 100%;
  text-align: start;
`

export const ListItemButton: React.FC<ListItemButtonProps> = ({
  className,
  navigateTo,
  onClick,
  children,
  dataAttrs,
  dataCalls,
  ariaAttrs,
}) => {
  const { id: dataId, description: dataDescription, test: dataTest } = dataAttrs || {}
  const { label: ariaLabel, description: ariaDescription } = ariaAttrs || {}

  const handleClick = () => {
    navigateTo && history.push(navigateTo)
    return onClick && onClick()
  }

  const history = useHistory()
  return (
    <ListItemButtonContainer
      className={className}
      onClick={handleClick}
      data-element-id={dataId}
      data-test={dataId || dataTest}
      data-description={dataDescription}
      data-analytics_available_call={dataCalls}
      aria-label={ariaLabel}
      aria-description={ariaDescription}
    >
      {children}
    </ListItemButtonContainer>
  )
}
