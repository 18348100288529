import { IconProps } from '@abstractTypes/icons'

export const GlassShape7Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 0.650391H28C26.895 0.650391 26 1.54539 26 2.65039V3.61439C24.711 3.17039 23.288 3.17039 22 3.61439V2.65039C22 1.54539 21.105 0.650391 20 0.650391H2C0.895 0.650391 0 1.54539 0 2.65039V11.8504C0 12.9554 0.895 13.8504 2 13.8504H20C21.105 13.8504 22 12.9554 22 11.8504V5.79939C23.223 5.10039 24.777 5.10039 26 5.80039V11.8504C26 12.9554 26.895 13.8504 28 13.8504H46C47.105 13.8504 48 12.9554 48 11.8504V2.65039C48 1.54539 47.105 0.650391 46 0.650391ZM20 11.8504H2V2.65039H20V11.8504ZM28 11.8504H46V2.65039H28V11.8504Z"
        fill="#333333"
      />
    </svg>
  )
}
