import { useSendAnalyticsEvent } from '@hooks/analyticsHooks'
import { useEffect } from 'react'

export const useSendBarcodeCustomOnlyAnalyticsEvent = (shouldSendEvent: boolean) => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: 'Barcode:Standard:NOUpc:CustomOnly',
  })

  const sendCloseModalEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeNoResultStd_Close',
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])

  return { sendCloseModalEvent }
}
