import { IconProps } from '@abstractTypes/icons'

export const GeometricalIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.113 1C42.756 0.381 42.096 0 41.381 0H32.143C31.428 0 30.768 0.381 30.411 1L28.368 4.539C27.138 3.553 25.606 3 24 3C22.394 3 20.862 3.553 19.632 4.539L17.588 1C17.231 0.381 16.57 0 15.856 0H6.61899C5.90399 0 5.24399 0.381 4.88699 1L0.267994 9C-0.0890059 9.619 -0.0890059 10.381 0.267994 11L4.88699 19C5.24399 19.619 5.90499 20 6.61899 20H15.857C16.572 20 17.232 19.619 17.589 19L22.208 11C22.565 10.381 22.565 9.619 22.208 9L20.648 6.298C21.561 5.469 22.749 5 24 5C25.25 5 26.438 5.469 27.353 6.298L25.793 9C25.436 9.619 25.436 10.381 25.793 11L30.412 19C30.769 19.619 31.43 20 32.144 20H41.382C42.097 20 42.757 19.619 43.114 19L47.733 11C48.089 10.381 48.089 9.619 47.732 9L43.113 1ZM15.856 18H6.61899L1.99999 10L6.61899 2H15.857L20.476 10L15.856 18ZM32.143 18H41.381L46 10L41.381 2H32.143L27.524 10L32.143 18Z"
        fill={fill}
      />
    </svg>
  )
}
