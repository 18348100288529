import { ContentV2Video } from '@abstractTypes/graphqlTypes'
import { Device } from '@abstractTypes/utils'
import { CloseIcon } from '@components/core/Icons'
import { Modal } from '@components/core/Modal'
import { ModalContent } from '@components/core/Modal/styles'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { altNavigation, landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import React from 'react'
import styled from 'styled-components'

interface SeeInVideoModal {
  onClose: () => void
  visible: boolean
  className?: string
  video: ContentV2Video
  animation?: boolean
}

const ModalStyled = styled(Modal)<{ animation?: boolean }>`
  ${({ animation }) => (animation ? '' : 'animation: none !important;')}

  ${ModalContent} {
    block-size: 100vh;
    inline-size: 100vw;
    background: ${({ theme }) => theme.electronics.colors.secondary};
    ${altNavigation(`
      block-size: 44vh;
    `)}
  }
`

export const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(10)};
  background-color: ${({ theme }) => theme.electronics.colors.primaryLight};
  z-index: 1;
  inset-inline-end: 0;
  inset-block-start: ${pxToRem(260)};
  ${tablet(
    `
      inset-block-start: ${clampValues(184.5, 246)};
      inline-size: ${clampValues(46.5, 62)};
      block-size: ${clampValues(46.5, 62)};
      > svg {
        inline-size: ${clampValues(18, 24)};
        block-size: ${clampValues(18, 24)};
      }
  `
  )}
  ${landscape(`
    inset-block-start: ${pxToRem(253)};
  `)}
  ${landscape(
    Device.tablet,
    `
    inset-block-start: ${clampValuesLandscape(150, 201)};
    inline-size: ${clampValuesLandscape(46.5, 62)};
    block-size: ${clampValuesLandscape(46.5, 62)};
    > svg {
      inline-size: ${clampValuesLandscape(18, 24)};
      block-size: ${clampValuesLandscape(18, 24)};
    }
  `
  )}
`

export const VideoStyled = styled.video`
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  min-inline-size: 100%;
  min-block-size: 100%;
  inline-size: auto;
  block-size: 100%;
  transform: translate(-50%, -50%);
`

export const SeeInVideoModal: React.FC<SeeInVideoModal> = ({
  visible,
  onClose,
  video,
  animation,
}) => {
  const { zoomLevel } = useNavigationContext()

  return (
    <ModalStyled
      visible={visible}
      showCloseButton={false}
      onCancel={onClose}
      animation={animation}
      showHeader={false}
    >
      <CloseButton onClick={onClose}>
        <CloseIcon height={pxToRem(38, zoomLevel / 100)} width={pxToRem(38, zoomLevel / 100)} />
      </CloseButton>
      <VideoStyled muted loop autoPlay preload="auto" src={video.src} />
    </ModalStyled>
  )
}
