import { BestMatchWrapper, BestMatchBadge } from './styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultTheme } from 'styled-components'
import { BestMatchesIcon } from '@components/core/Icons'
import { pxToRem } from '@libs/styled'
import { Text } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useThemeContext } from '@hooks/useThemeContext'
import { BadgeBestMatchProps } from './types'

export const BadgeBestMatch: React.FC<BadgeBestMatchProps> = ({
  className,
  isCapitalized = false,
}) => {
  const { t } = useTranslation()
  const { zoomLevel } = useNavigationContext()
  const theme: DefaultTheme = useThemeContext()
  return (
    <BestMatchWrapper className={className}>
      <BestMatchBadge isCapitalized={isCapitalized}>
        <BestMatchesIcon
          width={pxToRem(16, zoomLevel / 100)}
          height={pxToRem(16, zoomLevel / 100)}
          fill={theme.colors.primaryLight}
          stroke="none"
        />
        <Text color="secondary">{t('FrameAdvisor.bestMatch')}</Text>
      </BestMatchBadge>
    </BestMatchWrapper>
  )
}
