import { usePriceFormatter } from '@hooks/usePriceFormatter'

type FormattedPriceProps = {
  price: number
  fractionDigits?: number
}

export const Price: React.FC<FormattedPriceProps> = ({ price, fractionDigits }) => {
  const formatPrice = usePriceFormatter()
  return <>{formatPrice(price, fractionDigits)}</>
}
