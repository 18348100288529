import { FrameAdvisorTag } from '@abstractTypes/frameAdvisor'
import { updateSurvey } from '@actions/frameAdvisor'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export const useSaveOptionSelection = (tags: FrameAdvisorTag[]) => {
  const dispatch = useDispatch()
  return useCallback(
    () => tags.forEach(tag => dispatch(updateSurvey(tag.suggestedKey, tag.suggestedTag))),
    [dispatch, tags]
  )
}
