import React from 'react'
import Header from '../Header'
import { HeaderProps } from './types'

const HeaderContent: React.FC<HeaderProps> = ({
  children,
  logoColor,
  showFrameAdvisorProfile,
  resetNavigationOnClick = false,
  logoUrl,
}) => {
  return (
    <Header
      logoColor={logoColor}
      showFrameAdvisorProfile={showFrameAdvisorProfile}
      resetNavigationOnClick={resetNavigationOnClick}
      logoUrl={logoUrl}
    >
      {children && children}
    </Header>
  )
}

export default HeaderContent
