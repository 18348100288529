import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const PreviousPrice = styled.div`
  text-decoration: line-through;
`

export const CurrentPrice = styled.div<{ isFirst: boolean }>`
  order: ${({ isFirst }) => (isFirst ? '-1' : '1')};
`

export const DiscountedPriceContainer = styled.div`
  display: inline-flex;

  > * + * {
    margin-inline-end: ${pxToRem(8)};
  }
`
