import {
  Brand,
  CmsAnalyticsData,
  ContentV2Image,
  ContentV2Video,
  LoveHatesData as GrapphqlLoveHatesData,
  Maybe,
} from '@abstractTypes/graphqlTypes'
import { DataAttrs, StandardProduct } from '@abstractTypes/product'
import { QRCodeVariant } from '@components/QRcode/types'
import React from 'react'
import { DefaultTheme } from 'styled-components'

export type PictureModeType = 'product' | 'dressedOn'

export type LoveHatesData = GrapphqlLoveHatesData & {
  playListId?: string
}

export interface FrameAdvisorTag {
  suggestedTag: string
  suggestedKey: string
}

export type UndoData = {
  moco: string
  name: string
  model: string
  brand: Brand
  UPC: string
}

export interface FaceappConnectorProps {
  title?: React.ReactNode
  subTitle?: React.ReactNode
  theme?: DefaultTheme
  generateBase64Qrcode: (value: string, size: number) => string
  videoIdError: boolean
  qrCodeLabels: Record<string, string>
  frameAdvisorFsaTokenData: {
    token?: string
    postURL?: string
  }
  frameAdvisorFsaTokenRefetch: () => void
  qrCodeSize?: number
  className?: string
  showQRCode: boolean
}

export interface FaceappConnectorPropsV2 extends FaceappConnectorProps {
  handleCloseFsa: () => void
  qrCodeVariant?: QRCodeVariant
  withAssistanceLabel?: boolean
  qrCodeSize?: number
}

export interface LoveHateThumbsProps {
  product: StandardProduct
  playlistId: string
  dataAttrs?: DataAttrs
  ThumbUpIcon?: React.FC
  ThumbDownIcon?: React.FC
  RemoveIcon?: React.FC
  ThumbUpLikedIcon?: React.FC
  onHateClick?: (product: StandardProduct, playlistId: string) => void
  onLoveClick?: (product: StandardProduct, playlistId: string) => void
  hateAction?: (product: StandardProduct, playlistId: string) => void
  loveAction?: (product: StandardProduct, playlistId: string) => void
  sendAddToWishlistEvent?: (product: StandardProduct) => void
  saveForUndo?: (product: StandardProduct) => void
  index?: number
  isItemLiked?: boolean
  handleRemove?: (product: StandardProduct) => void
  className?: string
  showHateButton?: boolean
  disableUndo?: boolean
}

type AdditionalQueryStringKeys = 'fromFrameAdvisor'
export type AdditionalQueryStringAttributes = Record<AdditionalQueryStringKeys, unknown>

export interface CMSAnalyticsPlaylistTags {
  playlistTitleID: string
  playlistTitleDescription: string
}

export type ContentV2Playlist = {
  id: string
  title: string
  description: string
  image: ContentV2Image
  carousel: ContentV2Image[]
  elevationMoco: string[]
  exclusionMoco: string[]
  tags: string[]
  productType: string[]
  video: ContentV2Video
  font: string
  analyticsData: CMSAnalyticsPlaylistTags
}

export interface PlayListSlideProps {
  title: string
  font: string
  description: string
  linkTo: string
  prevPlay: () => void
  nextPlay: () => void
  onClick: () => void
}

export interface FrameAdvisorTag {
  suggestedTag: string
  suggestedKey: string
}
export interface FrameAdvisorOption {
  id: FaPlaylistType
  title: string
  tags: FrameAdvisorTag[]
  nextStep: Maybe<string>
  analyticsData?: Maybe<CmsAnalyticsData>
  assetKey?: string
}

export interface FrameAdvisorStep {
  id: string
  slug: string
  title: string
  options: FrameAdvisorOption[]
  key?: string
}

export type FrameAdvisorSurvey = FrameAdvisorStep[]

export enum MoodEnum {
  harmony = 'harmony',
  contrast = 'contrast',
}

export interface FAFeatureSlide {
  title?: string
  icon?: JSX.Element
  backgroundColor?: string
  isSmall?: boolean
}

export enum SkinUndertone {
  cool = 'COOL',
  warm = 'WARM',
}

export enum HairColor {
  bald = 'bald',
  black = 'black',
  blonde = 'blonde',
  brown = 'brown',
  gray = 'gray',
  other = 'other',
  red = 'red',
}

export enum EyeColor {
  blue = 'blue',
  green = 'green',
  brown = 'brown',
  other = 'other',
}

export enum FrameAdvisorTip {
  likeTip = 'likeTip',
  faceAndSkinTip = 'faceAndSkinTip',
  vmTip = 'vmTip',
  bestMatchTip = 'bestMatchTip',
  proTip = 'proTip',
  fitTip = 'fitTip',
  frameShapeTip = 'frameShapeTip',
  sizeTip = 'sizeTip',
  playlistTip = 'playlistTip',
}

export type AdvisorSuggestionKey =
  | 'eyeColor'
  | 'frameBridgePosition'
  | 'frameColor'
  | 'frameColorPalette'
  | 'frameDecoration'
  | 'frameRim'
  | 'frameShape'
  | 'frameShapeLift'
  | 'frameTempleFitting'
  | 'frameTempleThickness'
  | 'glassGender'
  | 'hairColorTone'
  | 'lensColorTreatment'
  | 'lensSize'
  | 'noseProminence'
  | 'skinUndertones'

export type AdvisorSuggestion = Record<AdvisorSuggestionKey, string[]>

export enum FaPlaylistType {
  OPTICAL = 'EYEGLASSES',
  SUN = 'SUNGLASSES',
}

export enum Mood {
  HARMONY = 'HARMONY',
  CONTRAST = 'CONTRAST',
}

export interface UserSearchInformation {
  gender?: string
  age?: string
  skinUndertones?: SkinUndertone
  faceShape?: string
  faceLength?: string
  hairColor?: HairColor
  eyeColor?: EyeColor
  eyebrowsThickness?: string
  eyeMood?: string
  sunMood?: string
  darkCircles?: string
}

export interface FrameAdvisorUserProfile {
  uuid: string
  pictureUuid: string
  profilePictureUrl?: string
  videoUuid?: string
  playlistId?: string
  userInformation?: {
    faceBreadth?: number
    glassesType?: string
  }
  userSearchInformation?: UserSearchInformation
  advisorInformation?: Record<FaPlaylistType, Record<Mood, AdvisorSuggestion>>
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export type ColorPalettes = Record<string, string[]>
