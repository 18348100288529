import { CartMerchRecommenderAccordionProps } from '@components/CartMerchRecommender/CartMerchRecommenderAccordion/types'
import { CaretLeftIcon, CaretRightIcon } from '@components/core/Icons'
import { NukaCarousel } from '@components/NukaCarousel'
import { useDeviceType } from '@hooks/useDeviceType'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import { pxToRem } from '@libs/styled'
import { usePageChecker } from '@libs/utils'
import { ScrollMode } from 'nuka-carousel'
import { FC, useState } from 'react'
import styled from 'styled-components'
import MerchCardComponent from '../MerchCardComponent'
import { CartMerchProgressBar, MerchNextBtn, MerchPrevBtn } from '../styles'

export const CartMerchStyledCarousel = styled(NukaCarousel)<{
  isCartPage: boolean
  showLastSlideGradient: boolean
  showFirstSlideGradient: boolean
}>`
  overflow: visible;
  ${({ isCartPage, showLastSlideGradient }) =>
    isCartPage &&
    `mask-image: 
    ${showLastSlideGradient && 'linear-gradient(to left, transparent 0%, black 20% 80%)'}}`};
  ${({ isCartPage, showFirstSlideGradient }) =>
    isCartPage &&
    `mask-image: 
    ${showFirstSlideGradient && 'linear-gradient(to right, transparent 0%, black 20% 80%)'}}`};
`

export const CartMerchCarousel: FC<CartMerchRecommenderAccordionProps> = ({
  products,
  className,
}) => {
  const rtl = useLanguageDirection()
  const { isCartPage } = usePageChecker()
  const { isTowerLandscape, isIpadlandscape } = useDeviceType()
  const { zoomLevel, isAlternativeNavigation } = useNavigationContext()

  const handleSlidesToShowAmount = () => {
    switch (true) {
      case isAlternativeNavigation:
        return 1.6
      case isTowerLandscape:
        return zoomLevel >= 150 ? 1.6 : 3.1
      case isIpadlandscape:
        return 2.19
      case zoomLevel === 150:
        return 1.1
      default:
        return 1.6
    }
  }

  const initialIndexRtl = isCartPage
    ? products.length - handleSlidesToShowAmount()
    : products.length - 1
  const [slideIndex, setSlideIndex] = useState(rtl ? initialIndexRtl : 0)

  const showNextBtn = isCartPage
    ? slideIndex < products.length - handleSlidesToShowAmount()
    : slideIndex !== products.length - 1

  const showProgressBar = isCartPage
    ? products.length > handleSlidesToShowAmount()
    : products.length > 1

  return (
    <CartMerchStyledCarousel
      isCartPage={isCartPage}
      showLastSlideGradient={slideIndex < products.length - handleSlidesToShowAmount()}
      showFirstSlideGradient={
        slideIndex !== 0 && slideIndex >= products.length - handleSlidesToShowAmount()
      }
      beforeSlide={(_, targetIndex) => setSlideIndex(targetIndex)}
      slideIndex={slideIndex}
      withoutBullets={false}
      cellSpacing={24}
      slidesToShow={isCartPage ? handleSlidesToShowAmount() : 1}
      slidesToScroll={slideIndex - Math.floor(slideIndex) || 1}
      scrollMode={isCartPage ? ScrollMode.remainder : ScrollMode.page}
      renderCenterCenterControls={({ currentSlide, previousSlide, nextSlide }) => {
        return (
          <>
            {currentSlide ? (
              <MerchPrevBtn active variant="light" onClick={previousSlide}>
                <CaretLeftIcon
                  width={pxToRem(9, zoomLevel / 100)}
                  height={pxToRem(16, zoomLevel / 100)}
                />
              </MerchPrevBtn>
            ) : null}

            {showNextBtn ? (
              <MerchNextBtn active variant="light" onClick={nextSlide} className={className}>
                <CaretRightIcon
                  width={pxToRem(9, zoomLevel / 100)}
                  height={pxToRem(16, zoomLevel / 100)}
                />
              </MerchNextBtn>
            ) : null}
          </>
        )
      }}
      renderBottomCenterControls={({ currentSlide, goToSlide }) => {
        if (showProgressBar) {
          return (
            <CartMerchProgressBar
              currentSlide={
                rtl ? products.length - 1 - Math.ceil(currentSlide) : Math.ceil(currentSlide)
              }
              disableAnimation
              isProgressPaused
              onProgressClick={goToSlide}
              playlist={products}
            />
          )
        }
        return null
      }}
    >
      {products.map(item => (
        <MerchCardComponent product={item} key={item.UPC} />
      ))}
    </CartMerchStyledCarousel>
  )
}
