import config from '@config/index'
import ServicePage from '@containers/Service'
import { DEFAULT_STORETYPE } from '@hooks/useStoreIdentity'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {} from './config/config.base'
const ConfigOverride = React.lazy(() => import('@components/EnvironmentInfo/ConfigOverride'))

interface UtilityRoutesProps {
  children: React.ReactNode
}

const UtilityRoutes: React.FC<UtilityRoutesProps> = ({ children }) => (
  <Switch>
    <Route exact path="/service" component={ServicePage} />
    <Route
      exact
      path="/config-override"
      render={() => (
        <ConfigOverride
          config={config}
          defaultConfig={config}
          storeType={DEFAULT_STORETYPE}
          processInfo={process.env}
        />
      )}
    />
    <Route exact={false} path="/" render={() => children} />
  </Switch>
)

export default UtilityRoutes
