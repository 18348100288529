import React from 'react'
import { ElectronicsHeaderStyled } from './styles'

export const ElectronicsHeader: React.FC<{ className?: string; isSticky?: boolean }> = ({
  className,
  children,
  isSticky,
}) => {
  return (
    <ElectronicsHeaderStyled className={className} isSticky={isSticky}>
      {children}
    </ElectronicsHeaderStyled>
  )
}
