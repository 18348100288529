import { IconProps } from '@abstractTypes/icons'

export const DigitalCouvetteIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      viewBox="0 0 24 16"
      width={width}
      height={height}
      stroke={stroke}
      className={className}
    >
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth={0.75}
        d="m13.16 8.663.349.165.154-.354c.579-1.33 2.104-2.099 4.837-2.099 1.79 0 3.072.332 3.898.933.805.585 1.227 1.462 1.227 2.692 0 1.618-.5 2.89-1.368 3.757-.867.867-2.14 1.368-3.757 1.368A5.131 5.131 0 0 1 13.375 10c0-.221.02-.432.048-.64l.035-.245-.212-.128a2.442 2.442 0 0 0-2.492 0l-.212.128.035.245c.029.208.048.419.048.64 0 2.825-2.3 5.125-5.125 5.125-1.618 0-2.89-.5-3.757-1.368C.876 12.89.375 11.617.375 10c0-1.23.422-2.107 1.227-2.692.827-.601 2.109-.933 3.898-.933 2.733 0 4.258.769 4.837 2.099l.154.355.35-.166a2.751 2.751 0 0 1 2.318 0ZM23 1.375h.375v-1h.25v2.25h-.25v-1H.625v1h-.25V.375h.25v1H23ZM.625 10c0 .705.112 1.916.787 2.96.693 1.074 1.943 1.915 4.088 1.915A4.88 4.88 0 0 0 10.375 10c0-.738-.134-1.619-.876-2.3C8.771 7.03 7.53 6.625 5.5 6.625c-2.03 0-3.27.406-4 1.075C.76 8.381.626 9.262.626 10Zm13 0a4.88 4.88 0 0 0 4.875 4.875c2.145 0 3.395-.841 4.088-1.914.675-1.045.787-2.256.787-2.961 0-.738-.134-1.619-.876-2.3-.729-.67-1.968-1.075-3.999-1.075-2.03 0-3.27.406-4 1.075-.74.681-.875 1.562-.875 2.3Z"
      />
    </svg>
  )
}
