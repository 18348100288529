import { Device } from '@abstractTypes/utils'
import { VMMVClassName } from '@components/VirtualMirror/constants'
import { landscape, tablet, transformTranslateX, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { FOCUS_SELECTOR } from '@libs/utils'
import styled, { css } from 'styled-components'

interface DrawerContainerProps {
  position: 'right' | 'left'
  isFullscreen: boolean
}

export const DrawerContainer = styled.div<DrawerContainerProps>`
  z-index: 4;
  position: fixed;
  inset-block: 0;
  inset-inline-start: ${({ position }) => (position === 'left' ? '0' : '100vw')};
  min-block-size: 100%;
  block-size: auto;
  display: flex;
  align-items: flex-start;
  inline-size: ${({ isFullscreen }) => (isFullscreen ? '100vw' : pxToRem(648))};
  background-color: ${({ theme }) => theme.colors.primaryLight};
  transition: transform 300ms ease-in-out;

  &,
  &.visible-enter {
    ${({ position }) =>
      transformTranslateX(position === 'right' ? '0' : { ltr: '-100%', rtl: '100%' })}
  }

  &.visible-enter-active,
  &.visible-enter-done {
    ${({ position }) =>
      transformTranslateX(position === 'right' ? { ltr: '-100%', rtl: '100%' } : '0')}
  }

  &[aria-hidden='true'] {
    ${FOCUS_SELECTOR} {
      visibility: hidden;
    }
  }
  ${({ isFullscreen }) => !isFullscreen && `${tablet(`inline-size: ${clampValues(486, 648)};`)}`}
  ${({ isFullscreen }) =>
    !isFullscreen &&
    `${landscape(Device.tablet, `inline-size: ${clampValuesLandscape(486, 648)};`)}`}
  ${({ isFullscreen }) =>
    !isFullscreen &&
    css`
      ${zoomPortrait([150, 175], `inline-size: ${pxToRem(972)};`)}
      ${zoomPortrait([200], `inline-size: ${pxToRem(1080)};`)}
    ${zoomLandscape([200], `inline-size: ${pxToRem(1296)};`)}
    `}

  .${VMMVClassName} & {
    z-index: 1002;
  }
`
export const DrawerOverlay = styled.div<{ showOverlay: boolean }>`
  z-index: 1;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100vw;
  block-size: 100%;
  min-block-size: 100%;
  overflow: hidden;
  background: rgb(0 0 0 / 25%);
  transition: 0.3s opacity ease-in-out;

  .${VMMVClassName} & {
    z-index: 1002;
  }

  &,
  &.visible-enter {
    z-index: ${({ showOverlay }) => (showOverlay ? 0 : -1)};
    opacity: ${({ showOverlay }) => (showOverlay ? 1 : 0)};
    inline-size: ${({ showOverlay }) => (showOverlay ? '100%' : 0)};
    block-size: ${({ showOverlay }) => (showOverlay ? '100%' : 0)};
    min-block-size: ${({ showOverlay }) => (showOverlay ? '100%' : 'none')};
  }

  &.visible-enter-active,
  &.visible-enter-done {
    opacity: 1;
  }
`
