import { IconProps } from '@abstractTypes/icons'

export const RoundCheckmarkIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#FFF',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={'none'}
    >
      <g clipPath="url(#clip0_4236_1793)">
        <path
          d="M18.8333 22.6425L14.1783 17.9883L11.8217 20.345L18.8333 27.3575L30.0117 16.1783L27.655 13.8216L18.8333 22.6425Z"
          fill="white"
        />
        <path
          d="M20.5 -4.57764e-05C9.47167 -4.57764e-05 0.5 8.97162 0.5 20C0.5 31.0283 9.47167 40 20.5 40C31.5283 40 40.5 31.0283 40.5 20C40.5 8.97162 31.5283 -4.57764e-05 20.5 -4.57764e-05ZM20.5 36.6666C11.31 36.6666 3.83333 29.19 3.83333 20C3.83333 10.81 11.31 3.33329 20.5 3.33329C29.69 3.33329 37.1667 10.81 37.1667 20C37.1667 29.19 29.69 36.6666 20.5 36.6666Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4236_1793">
          <rect width={width} height={height} fill={fill} transform="translate(0.5 -4.57764e-05)" />
        </clipPath>
      </defs>
    </svg>
  )
}
