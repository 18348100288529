import { IconProps } from '@abstractTypes/icons'
export const CategoryCyclingIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M38 20a9.92 9.92 0 0 0-3.55.663l-2.551-5.101a.989.989 0 0 0-.479-.469 1.079 1.079 0 0 0-.439-.092H17.618l-2-4H20a1 1 0 0 0 0-2h-8a1 1 0 0 0 0 2h1.382l2.5 5-2.331 4.663A9.91 9.91 0 0 0 10 20C4.486 20 0 24.486 0 30s4.486 10 10 10c5.177 0 9.446-3.954 9.949-9H24c.379 0 .725-.214.895-.553L31 18.236l1.661 3.323C29.865 23.334 28 26.45 28 30c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10zM10 38c-4.411 0-8-3.589-8-8s3.589-8 8-8c.93 0 1.82.168 2.651.461l-3.546 7.092A1 1 0 0 0 10 31h7.931c-.495 3.94-3.859 7-7.931 7zm4.442-14.648A8.007 8.007 0 0 1 17.93 29h-6.312l2.824-5.648zM23.382 29H19.95c-.312-3.132-2.08-5.835-4.611-7.441L17.618 17h11.764l-6 12zM38 38c-4.411 0-8-3.589-8-8a7.995 7.995 0 0 1 3.558-6.648l3.548 7.096a1 1 0 1 0 1.788-.895l-3.546-7.092A7.963 7.963 0 0 1 38 22c4.411 0 8 3.589 8 8s-3.589 8-8 8zM29 11h7.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5a1 1 0 0 0 0 2c1.93 0 3.5-1.57 3.5-3.5S38.43 9 36.5 9H29a1 1 0 0 0 0 2z"
        fill={fill}
      />
    </svg>
  )
}
