import { Device } from '@abstractTypes/utils'
import { Skeleton } from '@components/Image'
import { landscape } from '@libs/media'
import { clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const ImageWrapper = styled.div`
  margin-block: 0;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  padding-block: 0;
  text-align: start;

  img {
    object-fit: contain;
  }

  ${Skeleton} {
    aspect-ratio: 4 / 3;
    block-size: unset;
    inline-size: unset;
    min-block-size: unset;
    min-inline-size: unset;
  }
`

export const ImagesScrollableContainer = styled.div`
  inline-size: 100%;
  white-space: nowrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${pxToRem(24)};
  ${landscape(
    Device.tablet,
    `
    grid-template-columns: 1fr;
    padding-inline-end: ${clampValuesLandscape(50, 66)};
    padding-inline-start: ${clampValuesLandscape(64, 80)};
    row-gap: ${pxToRem(24)};
  `
  )}
`
