import { Device } from '@abstractTypes/utils'
import { NuanceHeading4 } from '@components/core/Typography/NuanceTypography'
import { altNavigation, altNavigationZoom, landscape, tablet, tabletPortrait } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import styled from 'styled-components'
import {
  animationDuration,
  landscapeTabletMiniSliderWidth,
  landscapeTabletSliderWidth,
  landscapeTowerSliderWidth,
} from '../styleConsts'

export const NuanceSlide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-inline-size: 100vw;
  overflow: hidden;
  block-size: 100%;

  ${landscape(`
    inline-size: unset;
    min-inline-size: unset;
    aspect-ratio: 1;
    flex-shrink: 0;
  `)}
  ${altNavigation(`
    min-inline-size: auto;
  `)}

  img,
  video {
    z-index: -100;
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    inline-size: 100%;
    block-size: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
`

export const NuanceSliderContainer = styled.div<{ isTabsExpanded: boolean }>`
  inline-size: 100%;
  transition: width ${animationDuration} linear;
  position: relative;
  overflow: hidden;
  z-index: 1;
  flex: 1;
  ${({ isTabsExpanded }) =>
    tabletPortrait(`
      flex: none;
      max-block-size: ${isTabsExpanded ? clampValues(390, 477) : clampValues(682, 950)};
      block-size: ${isTabsExpanded ? clampValues(390, 477) : clampValues(682, 950)};
    `)}
  ${landscape(`
    inline-size: ${landscapeTowerSliderWidth};
    ${tablet(`
      inline-size: ${landscapeTabletSliderWidth};
    `)}
    ${tablet(
      Device.ipad11,
      `
      inline-size: ${landscapeTabletMiniSliderWidth};
    `
    )}
    ${tablet(
      Device.ipadMini,
      `
      inline-size: ${landscapeTabletMiniSliderWidth};
    `
    )}
  `)}
  ${altNavigation(`
    position: absolute;
    inline-size: ${pxToRem(529)};
    block-size: ${pxToRem(525)};
    inset-block-start: ${pxToRem(136)};
    inset-inline-start: 0;
  `)}
  ${altNavigationZoom(
    [100, 110, 125, 150, 175, 200],
    `
      inset-inline-start: ${pxToRem(276)};
  `
  )}
`

export const LoadingScreen = styled.div`
  position: absolute;
  inset-block-start: 0;
  inline-size: 100%;
  block-size: 100%;
  background: linear-gradient(0deg, #0977ff 0%, #0977ff 100%),
    linear-gradient(180deg, #170708 20.55%, #15060700 32.58%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NuanceTitle = styled(NuanceHeading4)`
  position: absolute;
  inset-block-start: ${pxToRem(197)};
  padding-inline: ${pxToRem(120)};
  text-align: center;
  z-index: 1;

  ${landscape(
    `
    padding-inline: ${pxToRem(100)};
  `
  )}

  ${tablet(
    `
    inset-block-start: ${clampValues(150, 173)};
    padding-inline: ${clampValues(60, 120)};
  `
  )}

  ${altNavigation(
    `
    inset-block-start: ${pxToRem(32)};
    padding-inline: ${pxToRem(70)};
  `
  )}

  ${altNavigationZoom(
    [150, 175, 200],
    `
    padding-inline: ${pxToRem(0)};
  `
  )}
`
