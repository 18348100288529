import { DefaultCheckoutThemeInterface } from '@abstractTypes/checkout'
import { ModFunction } from '@abstractTypes/styled-components'
import { DeepPartial, Device } from '@abstractTypes/utils'
import { DefaultTheme, SeparatedTheme, ThemeColors, ThemeProps, css } from 'styled-components'
import getChromiumVersion from './getChromiumVersion'
import { DeviceMap } from './media'

const PXTOREM_RATIO = 16

export const theme =
  (themePath: keyof DefaultCheckoutThemeInterface, mod?: ModFunction) =>
  ({ theme }: ThemeProps<DefaultTheme>) => {
    const value = theme.checkout[themePath]
    return mod ? mod(value) : value
  }

export const pxToRem = (pxValue: number, zoomRatio = 1) => {
  //This ratio is the one used as a base font for the body to calculate all the rem sizes to use
  return `${((pxValue / PXTOREM_RATIO) * zoomRatio).toFixed(5)}rem`
}

export const checkColor = (
  color: string,
  theme: DefaultTheme,
  separatedThemeName?: SeparatedTheme
) => {
  const colorsObject = separatedThemeName ? theme[separatedThemeName].colors : theme.colors
  const isColorFromTheme = Object.prototype.hasOwnProperty.call(colorsObject, color) || false
  const textColor = isColorFromTheme ? colorsObject[color as keyof DeepPartial<ThemeColors>] : color
  return textColor
}

type MapDevicePx = { [key in Device]?: number }

const enumKeys = <I extends object, O extends keyof I>(obj: I): O[] => {
  return Object.keys(obj) as O[]
}

export const pxByDevice = (mapDevicePx: MapDevicePx) => {
  for (const device of enumKeys(mapDevicePx)) {
    if (window.matchMedia(DeviceMap[device]).matches) {
      return mapDevicePx[device]
    }
  }
  return mapDevicePx.tower
}

export const clampBuilder = (
  minWidthPx: number,
  maxWidthPx: number,
  minPxSize: number,
  maxPxSize: number,
  zoomRatio: number
) => {
  const minWidth = minWidthPx / PXTOREM_RATIO
  const maxWidth = maxWidthPx / PXTOREM_RATIO

  const minRemWidth = (minPxSize / PXTOREM_RATIO) * zoomRatio
  const maxRemWidth = (maxPxSize / PXTOREM_RATIO) * zoomRatio

  const slope = (maxRemWidth - minRemWidth) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minRemWidth

  const calculatedPreferredValue = `${yAxisIntersection.toFixed(4)}rem + ${(slope * 100).toFixed(
    4
  )}vw`

  return `clamp(${Math.min(minRemWidth, maxRemWidth)}rem, ${calculatedPreferredValue}, ${Math.max(
    minRemWidth,
    maxRemWidth
  )}rem)`
}

export const clampValues = (min: number, max: number, zoomRatio = 1) => {
  return clampBuilder(768, 1024, min, max, zoomRatio)
}

export const clampValuesLandscape = (min: number, max: number, zoomRatio = 1) => {
  return clampBuilder(1024, 1366, min, max, zoomRatio)
}

export const withLineClamp = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

export const lineClamp = (rows: number) => css`
  line-clamp: ${rows};
  -webkit-line-clamp: ${rows};
`

export const withoutLineClamp = css`
  overflow: visible;
  text-overflow: clip;
  line-clamp: unset;
  -webkit-line-clamp: unset;
  display: block;
`

export const flexGapPolyfill = (gap: string, direction?: 'column' | 'row') => {
  let margin = 'margin: 0'

  const isGapSupported = getChromiumVersion() > 84 // 84 is the minumum version where gap was introduced for chrome
  if (!isGapSupported) {
    switch (direction) {
      case 'column':
        margin = `margin-block: 0 ${gap}; margin-inline: 0`
        break
      case 'row':
        margin = `margin-block: 0; margin-inline:${gap} 0`
        break
      default:
        margin = `margin: ${gap}`
        break
    }
    return `
      & > * {
        ${margin}
        &:last-child {
          margin: unset;
        }
      }
    `
  }
  switch (direction) {
    case 'row':
      return `column-gap: ${gap};`
    case 'column':
      return `row-gap: ${gap};`
    default:
      return `gap: ${gap};`
  }
}
