import { IconProps } from '@abstractTypes/icons'

export const BronzeMetalsIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <circle cx={'50%'} cy={'50%'} r={'50%'} fill="url(#bronzeMetal)" />
      <defs>
        <linearGradient
          id="bronzeMetal"
          x1={47.85}
          y1={52.563}
          x2={5.8}
          y2={3.263}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.047} stopColor="#BC8045" />
          <stop offset={0.435} stopColor="#CD7F32" stopOpacity={0.692} />
          <stop offset={0.646} stopColor="#CD7F32" stopOpacity={0.4} />
          <stop offset={0.795} stopColor="#CD7F32" stopOpacity={0.7} />
          <stop offset={1} stopColor="#F39C1B" />
        </linearGradient>
      </defs>
    </svg>
  )
}
