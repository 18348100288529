import { Device } from '@abstractTypes/utils'
import AvailableInStoreSwitch from '@components/AvailableInStoreSwitch'
import { List, ListItem } from '@components/core/List'
import { Heading3, Heading4 } from '@components/core/Typography'
import { altNavigation, altNavigationZoom, landscape, tablet } from '@libs/media'
import { clampValues, flexGapPolyfill, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

export const MenuContainer = styled.div<{ isTopLevel: boolean }>`
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  block-size: 100%;
  ${landscape(`
    padding-block-start: 0;
    margin-block-start: 0;
  `)}
  ${({ isTopLevel }) =>
    isTopLevel
      ? css`
          ${altNavigation(`
          overflow-y: scroll;
          `)}
        `
      : css`
          margin-block-start: ${pxToRem(335)};
          ${landscape('margin-block-start: unset;')};
          ${tablet('margin-block-start: unset;')}
          ${altNavigation('margin-block-start: unset')}
        `}
`

export const HeadMenuList = styled(List)`
  padding-block: ${pxToRem(29)} ${pxToRem(17)};
  padding-inline: ${pxToRem(32)} ${pxToRem(35)};
  background-color: ${({ theme }) => theme.colors.background};
  ${tablet(`
    padding-inline-end: ${clampValues(26, 36)};
  `)}
`

export const HeadMenuItem = styled(ListItem)`
  padding-block-end: ${pxToRem(40)};
  ${tablet(`
    padding-block-end: ${clampValues(32, 45)};
  `)}
  ${altNavigationZoom(
    [150, 175],
    `
    padding-block-end: ${pxToRem(72)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    padding-block-end: ${pxToRem(100)};
  `
  )}
`

export const HeadMenuBorderItem = styled(ListItem)`
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  padding-block: ${pxToRem(16)};
  padding-inline: ${pxToRem(24)};
  border-radius: ${pxToRem(16)};
  margin-block: ${pxToRem(-10)} ${pxToRem(20)};
  margin-inline: 0;
`

export const MenuTitle = styled(Heading3)`
  margin-block: ${pxToRem(64)} 0;
  padding-block: 0;
  padding-inline: ${pxToRem(35)};
  ${tablet(`
    margin-block-start: ${pxToRem(32)};
    padding-inline:  ${pxToRem(35)} ;
  `)}
`

export const MenuList = styled(List)<{ isTopLevel: boolean }>`
  padding-inline-start: ${pxToRem(96)};
  ${({ isTopLevel }) =>
    tablet(`
  padding-inline-start: ${isTopLevel ? pxToRem(96) : clampValues(54, 72)};
  `)}
`

export const MenuItem = styled(ListItem)`
  padding-block-start: ${pxToRem(72)};

  &:first-of-type {
    padding-block-start: ${pxToRem(37)};
  }

  &:last-of-type {
    margin-block-end: ${pxToRem(400)};
  }
  ${landscape(`&:last-of-type {
    margin-block-end: ${pxToRem(80)};
  }`)}
  ${tablet(`
    padding-block-start: ${pxToRem(48)};

    &:first-of-type {
      padding-block-start: ${pxToRem(32)};
    }

    &:last-of-type {
      margin-block-end: ${pxToRem(80)}
    }
  `)}
`

export const DrawerBackArrowContainer = styled.button`
  block-size: 100%;
`

export const MenuPanelHeader = styled(ListItem)<{ isTopLevel?: boolean }>`
  ${flexGapPolyfill(pxToRem(20), 'row')}
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${({ isTopLevel: topLevel }) =>
    `
  padding-block: ${topLevel ? pxToRem(350) : pxToRem(35)}  ${pxToRem(24)};
  padding-inline: ${pxToRem(35)};
    `}
  ${({ isTopLevel }) =>
    tablet(`
    padding-block-start: ${isTopLevel ? pxToRem(84) : pxToRem(35)};
    padding-block-end: ${pxToRem(24)};
  `)}
  ${landscape(`
    padding-block-start: ${pxToRem(96)};
  `)}
  ${({ isTopLevel }) =>
    landscape(
      Device.tablet,
      `
    padding-block-start: ${isTopLevel ? pxToRem(84) : pxToRem(35)};
    padding-block-end: ${pxToRem(21)};
  `
    )}
  ${({ isTopLevel: topLevel }) =>
    topLevel &&
    css`
      ${altNavigationZoom(
        [100, 110, 125],
        `
      padding-block-start: ${pxToRem(112)};
    `
      )}
      ${altNavigationZoom(
        [150, 175],
        `
      padding-block-start: ${pxToRem(168)};
      padding-block-end: ${pxToRem(100)};
    `
      )}
      ${altNavigationZoom(
        [200],
        `
        padding-block-start: ${pxToRem(224)};
        padding-block-end: ${pxToRem(112)};
    `
      )}
    `}
  background-color: ${({ theme, isTopLevel: topLevel }) =>
    !topLevel ? theme.colors.primaryLight : theme.colors.background};
`

export const ScrollingContent = styled.div<{ isTopLevel: boolean }>`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ${({ isTopLevel }) =>
    isTopLevel &&
    css`
      ${altNavigation(`
        overflow: visible;
      `)}
    `}
`

export const CloseButton = styled.button<{ isTopLevel?: boolean }>`
  display: flex;
  border-radius: 50%;
  ${({ isTopLevel, theme }) =>
    isTopLevel &&
    css`
      ${altNavigation(`
        position: fixed;
        z-index: 1;
        inset-block-start: ${pxToRem(16)};
        inset-inline-end: ${pxToRem(16)};
        background: ${theme.colors.background};
      `)}
      ${tablet(`
        position: absolute;
        inset-block-start: ${clampValues(32, 42)};
        inset-inline-end: ${clampValues(36, 36)};
      `)}
    `}
  ${altNavigationZoom(
    [150, 175],
    `
    padding: ${pxToRem(24)};
`
  )}
  ${altNavigationZoom(
    [200],
    `
    padding: ${pxToRem(32)};
`
  )}
`

export const CustomerOrderResetButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  inset-block-end: 0;
  border-block-start: 1px solid ${({ theme }) => theme.colors.outline};
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.primaryLight};
`

export const BackArrowButton = styled.button`
  display: flex;
  margin-block: 0;
  margin-inline: ${pxToRem(14)};
`

export const BackCustomerOrder = styled(Heading4)`
  line-height: 2.25rem;
`

export const SwitcherWithLabelMainMenu = styled(AvailableInStoreSwitch)`
  ${altNavigationZoom(
    [150, 175],
    `
    margin-block-start: ${pxToRem(72)};
`
  )}
  ${altNavigationZoom(
    [200],
    `
    margin-block-start: ${pxToRem(100)};
`
  )}
`
