import { FitTipIcon } from '@components/core/Icons'
import { Text } from '@components/core/Typography'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import { TipCardTitle, TipImageWrapper } from '../styles'

const translationKey = 'FrameAdvisor.tips.fitTip'

const iconWidth = isIPadView() ? clampValues(200, 269) : pxToRem(269)
const iconHeight = isIPadView() ? clampValues(68, 91) : pxToRem(91)

export const FitTipContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <TipCardTitle>{t(`${translationKey}.title`)}</TipCardTitle>
      <TipImageWrapper>
        <FitTipIcon width={iconWidth} height={iconHeight} />
      </TipImageWrapper>
      <Text>{t(`${translationKey}.text`)}</Text>
    </>
  )
}
