import { Device } from '@abstractTypes/utils'
import { SecondaryButton } from '@components/core/Button'
import { Heading3, Heading5 } from '@components/core/Typography'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { History } from 'history'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const EmptyCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  block-size: 60%;
  margin-block-start: ${pxToRem(190)};
  ${tablet(`
    margin-block-start: ${clampValues(209, 289)};
  `)}
  ${landscape(`
    margin-block-start: ${pxToRem(63)};
  `)};
  ${landscape(
    Device.tablet,
    `
    margin-block-start: ${clampValuesLandscape(108, 161)};
  `
  )};
`
const EmptyTitle = styled(Heading3)`
  margin-block-end: 1rem;
`

const EmptyCartButtonWrapper = styled.div`
  padding-block-start: 2rem;
  display: flex;
  inline-size: 100%;
  justify-content: center;

  button {
    margin-block: 0;
    margin-inline: ${pxToRem(25)};
    ${tablet(`
      margin-block: 0;
      margin-inline: ${clampValues(15, 25)};
      &:first-child {
        margin-inline-start: 0;
      }
      &:last-child {
        margin-inline-end: 0;
      }
    `)}
  }
`

interface EmptyCartProps {
  shopMenUrl: string
  shopWomenUrl: string
  history: History
  className?: string
}

export const EmptyCart: React.FC<EmptyCartProps> = ({
  className,
  history,
  shopMenUrl,
  shopWomenUrl,
}) => {
  const { t } = useTranslation()
  return (
    <EmptyCartWrapper className={className}>
      <EmptyTitle>{t('Cart.emptyTitle')}</EmptyTitle>
      <Heading5>{t('Cart.continueShopping')}</Heading5>
      <EmptyCartButtonWrapper>
        <SecondaryButton
          onClick={() => {
            history.push(shopMenUrl)
          }}
        >
          {t('Cart.shopAllMen')}
        </SecondaryButton>
        <SecondaryButton
          onClick={() => {
            history.push(shopWomenUrl)
          }}
        >
          {t('Cart.shopAllWomen')}
        </SecondaryButton>
      </EmptyCartButtonWrapper>
    </EmptyCartWrapper>
  )
}
