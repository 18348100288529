import { toggleScreenReaderState } from '@actions/accessibility'
import NotificationBox from '@components/notificationBox'
import { useActions } from '@hooks/useActions'
import useKeyboardCheck from '@hooks/useKeyboardCheck'
import useNotifications, { SendNotificationAction } from '@hooks/useNotifications'
import React, { useContext, useMemo } from 'react'

type AccessibilityKeyboardContext = {
  focusVisible: boolean
  userInteractingWithKeyboard: boolean
}

type AccessibilityContextType = AccessibilityKeyboardContext & {
  triggerKeyDownAction?: (e: KeyboardEvent) => void
  sendNotification?: SendNotificationAction
}

export const initialAccessibilityKeyboardContext = {
  focusVisible: false,
  userInteractingWithKeyboard: false,
}

const initialAccessibilityContext: AccessibilityContextType = {
  ...initialAccessibilityKeyboardContext,
  triggerKeyDownAction: () => null,
  sendNotification: () => null,
}

const accessibilityContext = React.createContext<AccessibilityContextType>(
  initialAccessibilityContext
)

const AccessibilityContextProvider = accessibilityContext.Provider
interface AccessibilityProviderProps {
  children: React.ReactNode
  enableKeyboardCheck?: boolean
  enableFocusVisible?: boolean
  enableNotificationBox?: boolean
}

export const AccessibilityProvider: React.FC<AccessibilityProviderProps> = ({
  children,
  enableKeyboardCheck = true,
  enableFocusVisible = true,
  enableNotificationBox = true,
}) => {
  const { onKioskToggleChange } = useActions({
    onKioskToggleChange: toggleScreenReaderState,
  })
  const { keyboardCheckState, onKeyDown } = useKeyboardCheck(
    enableKeyboardCheck,
    enableFocusVisible
  )

  const { politeNotifications, assertiveNotifications, sendNotification } = useNotifications()
  window.toggleScreenReader = (isActive: boolean) => onKioskToggleChange(isActive)

  const accessibilityValue = useMemo<AccessibilityContextType>(
    () => ({
      ...keyboardCheckState,
      triggerKeyDownAction: onKeyDown,
      sendNotification,
    }),
    [keyboardCheckState, onKeyDown, sendNotification]
  )

  return (
    <AccessibilityContextProvider value={accessibilityValue}>
      {children}
      {enableNotificationBox && (
        <NotificationBox
          politeNotifications={politeNotifications}
          assertiveNotifications={assertiveNotifications}
        />
      )}
    </AccessibilityContextProvider>
  )
}

export const useAccessibilityContext = () => {
  const context = useContext(accessibilityContext)
  if (context === initialAccessibilityContext) {
    throw new Error('useAccessibilityContext must be used within a AccessibilityProvider')
  }
  return context
}
