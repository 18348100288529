import { IconProps } from '@abstractTypes/icons'

export const FaceShapeSquareManIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61845)">
        <path
          d="M44.6053 22.415C43.9243 21.62 42.7783 21.25 41.0003 21.25V17.25C41.0003 12.895 39.3403 8.93 36.6383 5.919C38.0963 4.469 39.0003 2.464 39.0003 0.25H21.0003C18.5703 0.25 16.5443 1.994 16.0963 4.296C11.0053 4.755 7.0003 9.041 7.0003 14.25V18.25V21.25C5.2223 21.25 4.0763 21.62 3.3953 22.415C2.4883 23.472 2.7103 24.924 2.9263 26.328L3.0103 26.89C3.3133 29.025 3.7723 32.25 7.0003 32.25V37.25C7.0003 43.315 11.9353 48.25 18.0003 48.25H30.0003C36.0653 48.25 41.0003 43.315 41.0003 37.25V32.25C44.2283 32.25 44.6873 29.025 44.9903 26.891L45.0743 26.329C45.2903 24.924 45.5133 23.472 44.6053 22.415ZM30.0003 46.25H18.0003C13.0383 46.25 9.0003 42.213 9.0003 37.25V18.022C12.6833 17.871 15.3613 16.895 17.0073 15.095C17.7093 14.327 18.2453 13.427 18.5723 12.363C20.0073 13.74 22.7533 15.084 28.0003 15.235C28.3243 15.244 28.6563 15.25 29.0003 15.25C33.0473 15.25 36.1253 15.9 37.7863 17.042C38.3793 17.45 38.7913 17.921 39.0003 18.448V37.25C39.0003 42.213 34.9623 46.25 30.0003 46.25ZM36.6503 2.25C35.8243 4.577 33.6073 6.25 31.0003 6.25V8.25C32.2093 8.25 33.3513 7.973 34.3803 7.491C34.6093 7.384 34.8313 7.267 35.0483 7.139C37.0923 9.371 38.4713 12.213 38.8693 15.365C36.2063 13.583 31.8453 13.25 29.0003 13.25C28.6463 13.25 28.3303 13.234 28.0003 13.224C19.3073 12.954 19.0013 9.267 19.0003 9.25H17.0003C17.0003 11.146 16.5953 13.44 14.1253 14.824C12.9493 15.483 11.2993 15.931 9.0003 16.033V14.343V14.25C9.0003 9.839 12.5893 6.25 17.0003 6.25H18.0003V5.25C18.0003 3.596 19.3463 2.25 21.0003 2.25H36.6503ZM4.9903 26.609L4.9023 26.024C4.7483 25.022 4.6033 24.077 4.9123 23.716C5.0623 23.543 5.5323 23.25 7.0003 23.25V30.25C5.8433 30.25 5.3923 29.434 4.9903 26.609ZM43.0983 26.024L43.0103 26.609C42.6083 29.434 42.1573 30.25 41.0003 30.25V23.25C42.4683 23.25 42.9383 23.543 43.0883 23.717C43.3963 24.077 43.2513 25.022 43.0983 26.024Z"
          fill="#333333"
        />
        <path d="M26.0114 36.25H21.9844V38.25H26.0114V36.25Z" fill="#333333" />
        <path
          d="M24.4324 31.2496H21.9844V33.2496H26.4324V23.3066H24.4324V31.2496Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61845">
          <rect width={48} height={48} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
