import { Heading4 } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import React, { useEffect, useRef, useState } from 'react'
import {
  AccordionSingleStyled,
  ScrollableContent,
  ScrollableContentContainer,
  ScrollablePaneContainer,
  TabPane,
  TabsContainer,
} from './styles'
import { HandleTabClick, TabsProps } from './types'
interface DataTabAttr {
  id?: string
  heading?: string
  dataDesc?: string
}

export interface SingleTabProps {
  id: string
  tabName?: string
  tabContent?: React.ReactNode | ((isActive: boolean) => React.ReactNode)
  dataAttr?: DataTabAttr
  analytics?: string
}

const TabsWithoutContent: React.FC<TabsProps> = ({
  defaultActiveKey = 0,
  activeKey,
  onChange,
  onTabClick,
  isTabDisabled,
  children,
  className,
  scrollLogicalPosition,
  withFocus = true,
  renderContent,
  setRef,
}) => {
  const tabsListRef = useRef<HTMLDivElement | null>(null)

  const [activeTab, setActiveTab] = useState(activeKey || defaultActiveKey)
  const { landscapeNavigation } = useNavigationContext()

  useEffect(() => {
    activeKey !== null && activeKey !== undefined && setActiveTab(activeKey)
  }, [activeKey])

  useEffect(() => {
    onChange && onChange(activeTab)
  }, [activeTab, onChange])

  useEffect(() => {
    if (tabsListRef?.current && withFocus) {
      const activetabElement = tabsListRef.current.children[activeTab]
      activetabElement &&
        activetabElement.scrollIntoView({
          inline: 'center',
          ...(scrollLogicalPosition && { block: scrollLogicalPosition }),
        })
    }
  }, [activeTab, landscapeNavigation, scrollLogicalPosition, withFocus])

  const onTabChange = (tabIndex: number, id: string) => {
    if (tabIndex !== activeTab) {
      setActiveTab(tabIndex)
      onTabClick && onTabClick(tabIndex, id)
    }
  }

  const handleTabClick = (id: string, index: number) => {
    if (isTabDisabled && isTabDisabled(id, index)) {
      return
    }
    onTabChange(index, id)
  }
  return (
    <TabsContainer className={className} ref={setRef}>
      <ScrollablePaneContainer ref={tabsListRef} role="tablist">
        {renderContent(activeTab, handleTabClick)}
      </ScrollablePaneContainer>
      <ScrollableContentContainer>
        <ScrollableContent className={className} currentTabIndex={activeTab}>
          {children}
        </ScrollableContent>
      </ScrollableContentContainer>
    </TabsContainer>
  )
}

interface TabListProps {
  activeTab: number
  tabs: SingleTabProps[]
  dataCalls?: string
  handleTabClick: HandleTabClick
}

const TabList: React.FC<TabListProps> = ({ activeTab, tabs, dataCalls, handleTabClick }) => {
  return (
    <>
      {tabs.map((tab, index) => {
        const isActive = activeTab === index
        return (
          <TabPane
            disabled={isActive}
            onClick={() => handleTabClick(tab.id, index)}
            key={tab.id}
            isActive={isActive}
            weight={isActive ? 'bold' : 'normal'}
            data-element-id={tab.dataAttr?.id}
            data-description={tab.dataAttr?.dataDesc}
            data-test={tab.dataAttr?.id}
            {...(dataCalls && { 'data-analytics_available_call': dataCalls })}
            className={isActive ? 'active' : ''}
            id={`tab-pane-${tab.id}`}
            role="tab"
            aria-selected={isActive}
            aria-labelledby={isActive ? `tab-content-${tab.id}` : ''}
          >
            {typeof tab.tabContent === 'function' ? (
              tab.tabContent(activeTab === index)
            ) : (
              <Heading4
                {...(activeTab === index && { weight: 'bold' })}
                data-test={tab.dataAttr?.heading}
              >
                {tab.tabContent || tab.tabName}
              </Heading4>
            )}
          </TabPane>
        )
      })}
    </>
  )
}

export const Tabs: React.FC<Omit<TabsProps, 'renderContent'>> = props => {
  const { tabs, dataCalls } = props
  const content = (activeTab: number, handleTabClick: HandleTabClick) => (
    <TabList
      tabs={tabs}
      dataCalls={dataCalls}
      activeTab={activeTab}
      handleTabClick={handleTabClick}
    />
  )
  return (
    <TabsWithoutContent
      {...props}
      renderContent={(activeTab: number, handleTabClick: HandleTabClick) =>
        content(activeTab, handleTabClick)
      }
    />
  )
}

export const TabsWithAccordion: React.FC<Omit<TabsProps, 'renderContent'>> = props => {
  const { tabs, dataCalls } = props
  const content = (activeTab: number, handleTabClick: HandleTabClick) => (
    <AccordionSingleStyled title={tabs[activeTab]?.tabContent || tabs[activeTab]?.tabName}>
      <TabList
        tabs={tabs}
        dataCalls={dataCalls}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      />
    </AccordionSingleStyled>
  )
  return (
    <TabsWithoutContent
      {...props}
      renderContent={(activeTab: number, handleTabClick: HandleTabClick) =>
        content(activeTab, handleTabClick)
      }
    />
  )
}
