import { Product } from '@abstractTypes/product'
import { addItemToCart } from '@actions/cart'
import { removeItemFromMySelection } from '@actions/frameAdvisor'
import { PrimaryButton } from '@components/core/Button/index'
import { CustomerOrder } from '@components/pdp/CustomerOrder'
import usePurchaseOptions from '@components/PurchaseConfigure/hooks/usePurchaseOptions'
import useShowPurchaseModal from '@components/PurchaseConfigure/hooks/useShowPurchaseModal'
import PurchaseConfigureModal from '@components/PurchaseConfigure/PurchaseConfigureModal'
import { CustomerOrderModalWrapper } from '@components/Wishlist/styles'
import { useSendAnalyticsAddToCartEvent } from '@hooks/analyticsHooks'
import { useActions } from '@hooks/useActions'
import { useCart } from '@hooks/useCart'
import { useIsCurrentProductInStock } from '@hooks/useIsCurrentProductInStock'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { formatProductForCart } from '@libs/formatters'
import { dataDescriptionForProduct } from '@libs/utils'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

interface ProductAddBuyButtonProps {
  product: Product
  index?: number
}

export const ProductAddBuyButton: React.FC<ProductAddBuyButtonProps> = ({ product, index }) => {
  const { t } = useTranslation()
  const { availability: stockItems, updatingAvailability } = useCart()
  const [isPrescriptionModalVisible, togglePrescriptionModalVisible] = useState(false)
  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)
  const { isAfa } = product
  const { storeId } = useStoreIndentity()
  const { addToCart, removeItemFromWishlist } = useActions({
    addToCart: addItemToCart,
    removeItemFromWishlist: removeItemFromMySelection,
  })
  const { sendAddToCartEvent } = useSendAnalyticsAddToCartEvent({})

  const showPurchaseConfigureModal = useShowPurchaseModal(product)
  const { showPrescriptionSwitch, showMixMatchSwitch } = usePurchaseOptions(product)

  const hasNonPrescriptionStock = useIsCurrentProductInStock(
    stockItems ? stockItems.filter(item => !item.prescriptionApplied) : [],
    product.UPC
  )
  const hasPrescriptionStock = useIsCurrentProductInStock(
    stockItems ? stockItems.filter(item => item.prescriptionApplied) : [],
    product.UPC
  )

  const handleClickToCart = useCallback(
    (addPrescription = false, addMixMatch = false) => {
      addToCart(formatProductForCart(product, addPrescription, addMixMatch), storeId || '')
      sendAddToCartEvent(product)
      removeItemFromWishlist(product)
    },
    [addToCart, product, storeId, sendAddToCartEvent, removeItemFromWishlist]
  )

  const handleTogglePrescriptionModalVisible = () => {
    togglePrescriptionModalVisible((v: boolean) => !v)
  }

  const analyticsDataDescription = dataDescriptionForProduct(product)

  const onAddToCartClick = () => {
    if (showPurchaseConfigureModal) {
      handleTogglePrescriptionModalVisible()
    } else {
      handleClickToCart()
    }
  }

  const addToCartBtn = () => (
    <PrimaryButton
      disabled={updatingAvailability}
      dataAttrs={{
        id: `Tiles_${index}-AddCart`,
        description: analyticsDataDescription,
      }}
      onClick={onAddToCartClick}
      ariaAttrs={{
        label: t('Core.addToBag'),
        description: t('Core.addToBag'),
      }}
    >
      {t('Core.addToBag')}
    </PrimaryButton>
  )

  if (!isAfa && isCustomerOrder) {
    return (
      <CustomerOrderModalWrapper>
        <CustomerOrder product={product} />
      </CustomerOrderModalWrapper>
    )
  }

  return (
    <>
      {addToCartBtn()}
      <PurchaseConfigureModal
        visible={isPrescriptionModalVisible}
        showPrescriptionSwitch={showPrescriptionSwitch}
        showMixMatchSwitch={showMixMatchSwitch}
        hasNonPrescriptionStock={hasNonPrescriptionStock}
        hasPrescriptionStock={product.roxable && hasPrescriptionStock}
        onSubmit={handleClickToCart}
        onClose={() => handleTogglePrescriptionModalVisible()}
        product={product}
      />
    </>
  )
}
