import { IconProps } from '@abstractTypes/icons'

export const RightIcon: React.FC<IconProps> = ({ width = '1rem', height = '1rem' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 43 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M39.5284 19.3934L22.9384 35.7367L24.2207 37L43 18.5L24.2207 2.17296e-06L22.9384 1.2633L39.5284 17.6066L-2.11927e-06 17.6066L-2.27548e-06 19.3934L39.5284 19.3934Z"
        fill="white"
      />
    </svg>
  )
}
