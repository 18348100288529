import { IconProps } from '@abstractTypes/icons'

export const FlagIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61728)">
        <path
          d="M30.6693 2.854C30.5553 2.854 30.4393 2.86867 30.3233 2.9L25.038 4.30933C23.864 4.62267 22.6587 4.77933 21.4533 4.77933C20.32 4.77933 19.1867 4.64067 18.0787 4.364L13.9213 3.32467C12.8133 3.04733 11.68 2.90933 10.5467 2.90933C9.34133 2.90933 8.136 3.066 6.962 3.37933L1.33333 4.88V0.25H0V5.23533V24.7133V32.25H1.33333V24.358L6.962 22.8567C8.136 22.5433 9.34133 22.3867 10.5467 22.3867C11.68 22.3867 12.8133 22.5253 13.9213 22.802L18.0787 23.8413C19.1867 24.1187 20.32 24.2567 21.4533 24.2567C22.6587 24.2567 23.864 24.1 25.038 23.7867L31.0107 22.194C31.594 22.0393 32 21.5107 32 20.9067V4.18867C32 3.43267 31.382 2.854 30.6693 2.854ZM30.6667 20.9067L24.694 22.4993C23.6373 22.7813 22.5467 22.924 21.4527 22.924C20.4253 22.924 19.3987 22.7973 18.4013 22.5487L14.244 21.5093C13.036 21.2073 11.792 21.054 10.546 21.054C9.22 21.054 7.89867 21.2273 6.61733 21.5687L1.33333 22.978V6.26L7.306 4.66733C8.36267 4.38533 9.45333 4.24267 10.548 4.24267C11.5753 4.24267 12.602 4.36933 13.5987 4.618L17.756 5.65733C18.964 5.95933 20.2087 6.11267 21.454 6.11267C22.78 6.11267 24.1013 5.93933 25.3827 5.598L30.6667 4.18867V20.9067Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61728">
          <rect width={32} height={32} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
