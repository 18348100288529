import { IconProps } from '@abstractTypes/icons'

export const GlassShape4Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.01 0.25H44.97C42.06 0.25 30.05 1.66 26.81 6.98C25.95 6.52 25 6.25 24 6.25C23 6.25 22.03 6.5 21.17 6.96C17.92 1.66 5.94 0.25 3.03 0.25H2.99C0.76 0.26 0 2.13 0 3.93V13.15C0 17.7 1.98 19.44 7.99 19.44C14.01 19.44 22 17.32 22 9.8C22 9.48 21.96 9.16 21.91 8.86C22.53 8.47 23.25 8.25 24 8.25C24.75 8.25 25.47 8.46 26.09 8.85C26.04 9.16 26 9.47 26 9.8C26 17.32 33.99 19.44 40.01 19.44C46.02 19.44 48 17.7 48 13.15V3.93C48 2.13 47.24 0.26 45.01 0.25ZM16.31 15.68C13.44 17.21 9.87 17.44 7.99 17.44C2.28 17.44 2 15.89 2 13.15V3.93C2 2.28 2.682 2.251 2.988 2.25C4.536 2.254 9.057 2.695 13.15 4.05C16.28 5.09 20 6.92 20 9.8C20 12.43 18.79 14.35 16.31 15.68ZM40.01 17.44C45.72 17.44 46 15.89 46 13.15H46.001V3.93C46.001 2.277 45.316 2.25 45.011 2.25C43.465 2.253 38.946 2.695 34.85 4.05C31.72 5.09 28 6.92 28 9.8C28 12.43 29.21 14.35 31.69 15.68C34.56 17.21 38.13 17.44 40.01 17.44Z"
        fill="#333333"
      />
    </svg>
  )
}
