import { Device } from '@abstractTypes/utils'
import { ProductItem } from '@components/FrameAdvisor/FrameAdvisorProductCard/styles'
import { landscape, zoomLandscape, zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const FAProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-row-gap: ${pxToRem(24)};
  grid-column-gap: ${pxToRem(24)};
  grid-auto-flow: dense;

  ${zoomPortrait(
    [150, 175, 200],
    `
    grid-template-columns: repeat(1, 1fr);
  `
  )}

  ${landscape(
    Device.tower,
    `
    grid-template-columns: repeat(3, minmax(0, 1fr));
`
  )}

  ${zoomLandscape(
    [150, 175],
    `
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `
  )}

  ${zoomLandscape(
    [200],
    `
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
    `
  )}

  ${ProductItem} {
    ${zoomLandscape(
      [150, 175],
      `
      inline-size: 100%;
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: 100%;
    `
    )}
    ${zoomLandscape(
      [200],
      `
      inline-size: ${pxToRem(848)};
    `
    )}
  }
`
