import ConditionalRender from '@components/ConditionalRender'
import { BackIcon, CloseIcon } from '@components/core/Icons'
import { ListItem, ListItemAction } from '@components/core/List'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PanelHeaderBackButton, PanelHeaderCloseButton } from './styles'
import { PanelHeaderProps } from './types'

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  children,
  onClose,
  dataCalls = '0',
  closeIcon,
  showBackButton = false,
  onBack,
  backIcon,
  dataAttr,
}) => {
  const { zoomLevel } = useNavigationContext()
  const iconSize = isIPadView() ? clampValues(20, 27) : pxToRem(34, zoomLevel / 100)
  const { t } = useTranslation()

  return (
    <ListItem>
      {children}{' '}
      <ListItemAction>
        <ConditionalRender
          condition={showBackButton && !!onBack}
          render={() => (
            <PanelHeaderBackButton
              onClick={onBack}
              data-analytics_available_call={dataCalls}
              aria-label={t('ARIA.backIcon.label')}
            >
              {backIcon || <BackIcon height={iconSize} width={iconSize} />}
            </PanelHeaderBackButton>
          )}
        />

        <PanelHeaderCloseButton
          onClick={onClose}
          data-analytics_available_call={dataCalls}
          data-test={dataAttr?.test}
          aria-label={t('ARIA.closeIcon.label')}
        >
          {closeIcon || <CloseIcon height={iconSize} width={iconSize} />}
        </PanelHeaderCloseButton>
      </ListItemAction>
    </ListItem>
  )
}
