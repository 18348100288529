import { SupernovaHomeContentProps } from '@components/Contents/ElectronicsContent'
import ErrorWidget from '@components/ErrorWidget'
import { FloatingContainer } from '@components/FloatingActions/styles'
import useFormatSupernovaQueryPayload from '@hooks/formatPayloadForSearchApi/useFormatSupernovaQueryPayload'
import { useFullscreenLoader } from '@hooks/useFullscreenLoader'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useStoreContext } from '@hooks/useStoreContext'
import { parseGrapqhQLError } from '@libs/errors'
import { landscape } from '@libs/media'
import { clampValuesLandscape } from '@libs/styled'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ElectronicsDataQueryResult, usePageData, useProductData } from '../data'
import BaseContainer from './BaseContainer'

const SupernovaContainer = styled(BaseContainer)`
  overscroll-behavior: none;
  ${FloatingContainer} {
    ${landscape(`
      inset-block-start: ${clampValuesLandscape(190, 240)}
    `)}
  }
`

const SupernovaProductsWrapper: React.FC = () => {
  const store = useStoreContext()
  const { isAlternativeNavigation, zoomLevel } = useNavigationContext()
  const queryPayload = useFormatSupernovaQueryPayload()

  const {
    data: productData,
    loading: productDataLoading,
    error: productDataError,
  } = useProductData(queryPayload)
  const {
    data: pageData,
    loading: pageDataLoading,
    error: pageDataError,
  } = usePageData<ElectronicsDataQueryResult>('PageSupernova')

  useFullscreenLoader('loading-SupernovaHome', [pageDataLoading, productDataLoading])

  const { defaultModel } = useParams<{ defaultModel: string }>()

  const error = pageDataError || productDataError

  if (error) {
    return <ErrorWidget {...parseGrapqhQLError(error)} withWrapper />
  }

  if (!productData || !pageData || !store.supernovaEnabled) {
    return null
  }

  const models = pageData.page.content
  const products = productData.products.items

  return (
    <SupernovaContainer
      showHeader={false}
      preventAccessibilityToggleResize
      withPadding={false}
      showPrivacyPolicyTooltip={false}
      content={
        <SupernovaHomeContentProps
          models={models}
          products={products}
          defaultModel={defaultModel}
          purchaseEnabled={store.supernovaPurchaseEnabled}
        />
      }
      disclaimerColor="inactive"
      floatingDisclaimer
      preventScroll={isAlternativeNavigation && zoomLevel < 150}
    />
  )
}

const SupernovaHome: React.FC = () => {
  return <SupernovaProductsWrapper />
}

export default SupernovaHome
