import { ActiveFilters } from '@abstractTypes/analytics'
import { FiltersState, PreselectedFilters, TogglableProductTypeValues } from '@abstractTypes/filter'
import { updatePreselectedFilters } from '@actions/ui'
import { PRODUCT_TYPE } from '@constants/product'
import { executeOnce, uniqueId } from '@libs/utils'
import { UIState } from '@reducers/ui'
import { differenceWith, isEqual } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useActions } from './useActions'
import { useActiveLocationFilters } from './useFilters'
import { usePredefinedFiltersProductCount } from './usePredifinedFiltersProductCount'

export const usePreselectedFilters = (filters: FiltersState): PreselectedFilters | undefined => {
  // Preselected filters are preselected by the cms, from hero banner or menu option (ticket SS-8442)
  const { shouldUpdatePreselectedFilters, preselectedFilters }: UIState = useSelector(s => s.ui)
  const history = useHistory()
  const actions = useActions({
    updatePreselectedFilters,
  })
  const hash = useRef<string | undefined>(history.action !== 'POP' ? uniqueId() : undefined)
  const userFilters = useUserFilters()

  const predefinedFiltersCount = usePredefinedFiltersProductCount(filters)

  useEffect(() => {
    if (shouldUpdatePreselectedFilters) {
      hash.current = uniqueId()
    }
  }, [shouldUpdatePreselectedFilters])

  useEffect(() => {
    if (hash.current && userFilters) {
      if (userFilters.areSamePreselectedFilters) {
        executeOnce(
          (
            filters: ActiveFilters[],
            productTypeCounts: Record<TogglableProductTypeValues, number>
          ) => {
            actions.updatePreselectedFilters({
              filters,
              productTypeCounts,
            })
          },
          hash.current
        )(preselectedFilters?.filters ?? [], predefinedFiltersCount)
      } else {
        executeOnce(
          (
            filters: ActiveFilters[],
            productTypeCounts: Record<TogglableProductTypeValues, number>
          ) => {
            actions.updatePreselectedFilters({
              filters,
              productTypeCounts,
            })
          },
          hash.current
        )(userFilters.filters ?? [], predefinedFiltersCount)
      }
    }
  }, [userFilters, actions, predefinedFiltersCount, preselectedFilters?.filters])

  return preselectedFilters
}

export const useUserFilters = () => {
  const { preselectedFilters }: UIState = useSelector(s => s.ui)
  const activeFilters = useActiveLocationFilters() as ActiveFilters[] | undefined
  const filteredActiveFilters = useMemo(
    () => activeFilters?.filter(f => !['storeAvailable', 'roxable', PRODUCT_TYPE].includes(f.kind)),
    [activeFilters]
  )

  return useMemo(() => {
    if (!filteredActiveFilters) {
      return
    }

    const userFilters = differenceWith(
      filteredActiveFilters,
      preselectedFilters?.filters ?? [],
      isEqual
    )

    return {
      areSamePreselectedFilters: !!preselectedFilters?.filters && userFilters.length === 0,
      filters: userFilters,
    }
  }, [filteredActiveFilters, preselectedFilters?.filters])
}
