import { IconProps } from '@abstractTypes/icons'
export const XxlSizeIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 95 34"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M1.65789 0.441895H8.66089L16.2749 12.2859L24.2179 0.441895H30.7979L19.7059 16.6099L31.5969 33.9999H24.4529L16.0869 21.1689L7.57989 33.9999H0.858887L12.7499 16.6099L1.65789 0.441895Z"
        fill={fill}
      />
      <path
        d="M35.1344 0.441895H42.1374L49.7514 12.2859L57.6945 0.441895H64.2744L53.1824 16.6099L65.0734 33.9999H57.9294L49.5634 21.1689L41.0564 33.9999H34.3354L46.2264 16.6099L35.1344 0.441895Z"
        fill={fill}
      />
      <path d="M71.525 0.441895H77.4V28.9239H94.461V33.9999H71.525V0.441895Z" fill={fill} />
    </svg>
  )
}
