import StandardProductCard from '@components/CardProduct/StandardProductCard'
import { ProductGrid } from '@components/core/Layout/styles'
import { useProductInfoConfigPlp } from '@hooks/tiles'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useStoreContext } from '@hooks/useStoreContext'
import { useParams } from 'react-router-dom'
import { ProductListProps } from './types'

const ProductList: React.FC<ProductListProps> = ({ items, dataAttrs }) => {
  const getProductInfoConfig = useProductInfoConfigPlp()
  const params = useParams<{ upc?: string }>()
  const { closeAnyDrawer } = useDrawerHandler()
  const store = useStoreContext()

  const handleClick = (upc: string) => {
    // Hide search drawer while searching the same upc from pdp page
    if (upc !== params?.upc) return
    closeAnyDrawer()
  }

  return (
    <ProductGrid>
      {items.map((item, index) => {
        const currentDataAttrs = {
          link: dataAttrs?.link && `${dataAttrs?.link}_${index}-Img`,
          price: dataAttrs?.price && `${dataAttrs?.price}-${index}-Img`,
          productName: dataAttrs?.productName && `${dataAttrs?.productName}-${index}_ProductName`,
          wishlist: `Tiles_${index}-FavAdd`,
        }

        const productInfoConfig = getProductInfoConfig(item.brand.id)
        return (
          <div key={item.UPC} onClick={() => handleClick(item.UPC)}>
            <StandardProductCard
              product={item}
              dataAttrs={currentDataAttrs}
              showCustomizableIcon
              showRTRIcon
              showVTOIcon
              showEngravingIcon={!!store.engravingEnabled && item.engravable && !item.customizable}
              showRange
              productInfoConfig={productInfoConfig}
            />
          </div>
        )
      })}
    </ProductGrid>
  )
}

export const PlpProductList: React.FC<ProductListProps> = ({ items }) => {
  const dataAttrs = {
    link: 'Tiles',
    price: 'PLP_price-mocobox',
    productName: 'PLP_mocobox',
  }

  return <ProductList items={items} dataAttrs={dataAttrs} />
}

export const SearchProductList: React.FC<ProductListProps> = ({ items }) => {
  const dataAttrs = {
    link: 'MainNav_SearchTiles_',
  }

  return <ProductList items={items} dataAttrs={dataAttrs} />
}
