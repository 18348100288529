import { resetStore } from '@actions/store'
import { cleanupOneConfiguratorRecipes } from '@libs/custom'
import { cleanupVMMVData } from '@libs/virtualMirror'
import { resetLocalStorageState } from '@localStorage'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'
import { useActions } from './useActions'
import { useStoreContext } from './useStoreContext'
import { useStoreIndentity } from './useStoreIdentity'

export const useResetSS = () => {
  const actions = useActions({
    resetStore,
  })
  const store = useStoreContext()
  const { storeType, storeId, langCode } = useStoreIndentity()
  const history = useHistory()

  const defaultLang = store.defaultLangCode || langCode
  const basePath = `/${storeType}/${storeId}/${defaultLang}`

  const resetSS = useCallback(() => {
    actions.resetStore()
    cleanupVMMVData()
    serviceWorkerRegistration.unregister()
    cleanupOneConfiguratorRecipes()
    resetLocalStorageState()
    history.push(basePath)
    window.location.reload() // force reload without cache to load new assets
    // This is used to trigger an event on the electron kiosk app to refresh the web page from the browser wrapper as well
    if (window.api || window.ipc) {
      window.api?.invoke('webAppReset')
      window.ipc?.send('action', 'webAppReset')
    }
  }, [actions, basePath, history])

  return resetSS
}
