import React from 'react'

export interface ListProviderProps {
  className?: string
  as?: keyof JSX.IntrinsicElements
  children: React.ReactNode
}

export interface ListContextData {
  parentElement?: keyof JSX.IntrinsicElements
}

export const ListContext = React.createContext<ListContextData | undefined>(undefined)

export const ListProvider: React.FC<ListProviderProps> = ({ children, as = 'ul' }) => {
  return <ListContext.Provider value={{ parentElement: as }}>{children}</ListContext.Provider>
}
