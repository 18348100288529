import { Product } from '@abstractTypes/product'
import {
  ADD_ITEM_TO_WISHLIST,
  HIDE_TOOLTIP,
  REMOVE_ITEM_FROM_WISHLIST,
  TEMP_TOGGLE_ITEM_TO_WISHLIST,
  TOGGLE_ALL_TEMP_ITEMS_TO_WISHLIST,
  TOGGLE_ITEM_TO_WISHLIST,
  WishlistActionTypes,
} from '@actions/wishlist'

export interface WishlistState {
  items: Product[]
  tempItems: Product[]
  tooltipIsVisible: boolean
}

const initialState: WishlistState = {
  items: [],
  tempItems: [],
  tooltipIsVisible: true,
}

export default (state = initialState, action: WishlistActionTypes): WishlistState => {
  switch (action.type) {
    case TOGGLE_ITEM_TO_WISHLIST: {
      const { product } = action.payload

      // Remove product if present...
      const newItems = state.items.filter(item => item.UPC !== product.UPC)

      // If no item was removed, add the product
      if (newItems.length === state.items.length) {
        newItems.push({ ...product })
      }

      return {
        ...state,
        items: newItems,
      }
    }

    case TEMP_TOGGLE_ITEM_TO_WISHLIST: {
      const { product } = action.payload

      // Remove product if present...
      const newItems = state.tempItems.filter(item => item.UPC !== product.UPC)

      // If no item was removed, add the product
      if (newItems.length === state.tempItems.length) {
        newItems.push({ ...product })
      }

      return {
        ...state,
        tempItems: newItems,
      }
    }

    case TOGGLE_ALL_TEMP_ITEMS_TO_WISHLIST: {
      const items = [...state.items]
      state.tempItems.forEach(prod => {
        const index = items.findIndex(item => item.UPC === prod.UPC)
        if (index > -1) {
          items.splice(index, 1)
        } else {
          items.push({ ...prod })
        }
      })

      return {
        ...state,
        items,
        tempItems: [],
      }
    }

    case REMOVE_ITEM_FROM_WISHLIST: {
      const { product } = action.payload

      // Remove product if present...
      const newItems = state.items.filter(item => item.UPC !== product.UPC)

      return {
        ...state,
        items: newItems,
      }
    }

    case ADD_ITEM_TO_WISHLIST: {
      const { product } = action.payload
      const isAlreadyWishlisted = state.items.some(item => item.UPC === product.UPC)

      if (isAlreadyWishlisted) {
        return state
      }

      return {
        ...state,
        items: [...state.items, product],
      }
    }

    case HIDE_TOOLTIP:
      return {
        ...state,
        tooltipIsVisible: false,
      }

    default:
      return state
  }
}
