import config from '@config/index'
import { useEffect, useState } from 'react'

export const useCourtesy = (): boolean | undefined => {
  const [courtesyActive, setCourtesyActive] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const checkCourtesy = async () => {
      try {
        await fetch(config.courtesyUrl).then(response => {
          setCourtesyActive(response.status === 418)
        })
      } catch (e) {
        setCourtesyActive(false)
      }
    }

    if (config.toggleFeature.courtesy) {
      checkCourtesy()
      const courtesyCheckInterval = setInterval(checkCourtesy, config.courtesyInterval)
      return () => {
        clearInterval(courtesyCheckInterval)
      }
    } else {
      setCourtesyActive(false)
    }
  }, [])

  return courtesyActive
}
