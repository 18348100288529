import { IconProps } from '@abstractTypes/icons'

export const FaceShapeRectangleWomanIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61820)">
        <path
          d="M47.707 36.543C45.736 34.572 45.001 26.117 45 21.25C45 9.67 35.58 0.25 24 0.25C12.42 0.25 3 9.67 3 21.25C2.999 26.117 2.264 34.572 0.293 36.543L0 36.836V37.25C0 43.315 4.935 48.25 11 48.25C13.418 48.25 15.648 47.457 17.465 46.13C17.913 46.208 18.373 46.25 18.841 46.25H29.26C29.698 46.25 30.127 46.21 30.548 46.141C32.364 47.461 34.589 48.25 37 48.25C43.065 48.25 48 43.315 48 37.25V36.836L47.707 36.543ZM18.842 44.25C15.941 44.25 13.458 42.178 12.939 39.324L9.457 20.17C14.98 19.565 18.986 15.618 20.421 11.993C22.472 14.589 27.412 18.472 39.026 20.021L35.145 39.426C34.586 42.222 32.112 44.25 29.261 44.25H18.842ZM37 46.25C35.566 46.25 34.214 45.904 33.01 45.305C35.072 44.205 36.625 42.228 37.106 39.819L41.198 19.359L40.334 18.17C23.145 16.163 20.987 9.067 20.97 9.007L19 9.25C19 12.776 14.486 18.108 8.25 18.248L7.289 19.429L10.971 39.681C11.418 42.14 12.961 44.157 15.038 45.282C13.822 45.896 12.453 46.25 11 46.25C6.161 46.25 2.202 42.412 2.007 37.62C4.914 33.984 5 22.579 5 21.25C5 10.773 13.523 2.25 24 2.25C34.477 2.25 43 10.773 43 21.25C43 22.579 43.086 33.984 45.993 37.62C45.798 42.412 41.839 46.25 37 46.25Z"
          fill="#333333"
        />
        <path
          d="M26.4324 23.3066H24.4324V31.2496H21.9844V33.2496H26.4324V23.3066Z"
          fill="#333333"
        />
        <path
          d="M27.3115 37.178L23.9995 36.208L20.6875 37.178L21.8785 38.37C22.4445 38.938 23.1975 39.25 23.9995 39.25C24.8015 39.25 25.5545 38.938 26.1215 38.371L27.3115 37.178Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61820">
          <rect width={48} height={48} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
