import { ContentEditorialComponent } from '@abstractTypes/graphqlTypes'
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  from,
  InMemoryCache,
  TypePolicy,
} from '@apollo/client'
import config from '@config/index'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import ApolloLinkTimeout from './apolloClientTimeout'
import possibleTypes from './fragmentTypes.json'
import { AppStore } from './store'

// https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
const EDITORIAL_LP_TYPE_POLICIES: Partial<
  Record<Required<ContentEditorialComponent>['__typename'], TypePolicy>
> = {
  ContentEditorialBoard: {
    keyFields: ['id', 'layout'],
  },
  ContentEditorialProductTile: {
    keyFields: ['id', 'layout'],
  },
  ContentEditorialLensColor: {
    keyFields: ['id', 'layout'],
  },
  ContentEditorialChooseLensTransition: {
    keyFields: ['id', 'layout'],
  },
  ContentEditorialSwitchRtr: {
    keyFields: ['id', 'layout'],
  },
  ContentEditorialBanner: {
    keyFields: ['id', 'slides'],
  },
}

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    ...EDITORIAL_LP_TYPE_POLICIES,
  },
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Store':
        return `${defaultDataIdFromObject(responseObject)}-${(
          responseObject.disabledCustomizers as string[]
        )?.join('--')}`
      case 'NuanceTabContent':
        return `${defaultDataIdFromObject(responseObject)}-${responseObject.nuanceType}`
      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
})

// TODO: I passed store as an argument because we cannot use the useSelector hook
// do we have any more elegant alternative to this?
export const getClient = (store?: AppStore) => {
  /**
   * The abort controller is passed on the fetch options that are provided to the fetch API when it is executed.
   * https://www.apollographql.com/docs/react/networking/advanced-http-networking/#constructor-options
   *
   * The fetch can receive an AbortSignal so that it can cancel the requests when the request is no longer
   * needed - https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#aborting_a_fetch
   *
   * The GraphQL client would throw errors when the fetch needs to be cancelled for any reason, like
   * unmounting/mounting component, re-running queries (useEffect), etc...
   */

  const abortController = new AbortController()
  const timeoutLink = new ApolloLinkTimeout()

  const httpLink = createHttpLink({
    uri: operation => `${config.middleware.URL}?name=${operation.operationName}`,
    fetchOptions: { signal: abortController.signal },
  })

  const timeoutHttpLink = timeoutLink.concat(httpLink)

  const activityMiddleware = new ApolloLink((operation, forward) => {
    if (store) {
      const reduxStore = store.getState()
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'dw-session-id': reduxStore.session.token,
        },
      }))
    }

    return forward(operation)
  })

  const client = new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    link: from([activityMiddleware, timeoutHttpLink]),
    connectToDevTools: true,
    cache,
  })
  return client
}
