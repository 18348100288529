import { IconProps } from '@abstractTypes/icons'

import styled from 'styled-components'
import CustomizeImage from '../../../assets/imgs/customizeIcon.png'

const CustomizeIconImage = styled.img<{ width?: string; height?: string }>`
  inline-size: ${({ width }) => (width ? width : '1rem')} !important;
  block-size: ${({ height }) => (height ? height : '1rem')} !important;
`

export const CustomizeIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
}: IconProps) => {
  return (
    // This svg icon was using tags not supported on several devices. I had to change it with a PNG to ensure the style is uniform across all the devices (event older ones)
    <CustomizeIconImage src={CustomizeImage} width={width} height={height} />
  )
}
