import { Dispatch } from 'redux'

export const TOGGLE_ALTERNATIVE_NAVIGATION = 'accessibility/TOGGLE_ALTERNATIVE_NAVIGATION'
export const TOGGLE_VIRTUAL_KEYBOARD = 'accessibility/TOGGLE_VIRTUAL_KEYBOARD'
export const TOGGLE_SCREEN_READER_STATE = 'accessibility/TOGGLE_SCREEN_READER_STATE'
export const TOGGLE_CAROUSELS_AUTOPLAY = 'accessibility/TOGGLE_CAROUSELS_AUTOPLAY'
export const INCREASE_ZOOM_LEVEL = 'accessibility/INCREASE_ZOOM_LEVEL'
export const DECREASE_ZOOM_LEVEL = 'accessibility/DECREASE_ZOOM_LEVEL'

export interface ToggleAlternativeNavigationAction {
  type: typeof TOGGLE_ALTERNATIVE_NAVIGATION
}

export interface ToggleVirtualKeyboardAction {
  type: typeof TOGGLE_VIRTUAL_KEYBOARD
}
export interface ToggleCarouselsAutoplayAction {
  type: typeof TOGGLE_CAROUSELS_AUTOPLAY
}
export interface ToggleScreenReaderStateAction {
  type: typeof TOGGLE_SCREEN_READER_STATE
  payload: {
    isActive: boolean
  }
}

export interface IncreaseZoomLevel {
  type: typeof INCREASE_ZOOM_LEVEL
}

export interface DecreaseZoomLevel {
  type: typeof DECREASE_ZOOM_LEVEL
}

export const toggleVirtualKeyboard: ToggleVirtualKeyboardAction = {
  type: TOGGLE_VIRTUAL_KEYBOARD,
}

export const toggleAlternativeNavigation: ToggleAlternativeNavigationAction = {
  type: TOGGLE_ALTERNATIVE_NAVIGATION,
}

export const toggleScreenReaderState =
  (isActive: boolean) => (dispatch: Dispatch<ToggleScreenReaderStateAction>) => {
    dispatch({
      type: TOGGLE_SCREEN_READER_STATE,
      payload: {
        isActive,
      },
    })
  }

export const increaseZoomLevel: IncreaseZoomLevel = {
  type: INCREASE_ZOOM_LEVEL,
}
export const toggleCarouselsAutoplay: ToggleCarouselsAutoplayAction = {
  type: TOGGLE_CAROUSELS_AUTOPLAY,
}

export const decreaseZoomLevel: DecreaseZoomLevel = {
  type: DECREASE_ZOOM_LEVEL,
}

export type AccessibilityActionTypes =
  | ToggleAlternativeNavigationAction
  | ToggleVirtualKeyboardAction
  | ToggleScreenReaderStateAction
  | ToggleCarouselsAutoplayAction
  | DecreaseZoomLevel
  | IncreaseZoomLevel
