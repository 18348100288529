import { ReactNode } from 'react'
import { ButtonStyled, Toggler } from './styles'

export interface TogglableItem<T> {
  value: T
  label: string
  dataElementId?: string
  dataCalls?: string
  icon?: ReactNode
  onChange: (v: T) => void
}

interface ToggleItemsProps<T> {
  itemA: TogglableItem<T>
  itemB: TogglableItem<T>
  currentValue: T
}

const ToggleItems = <T,>({ itemA, itemB, currentValue }: ToggleItemsProps<T>) => {
  const renderButton = (item: TogglableItem<T>, index: number) => {
    return (
      <ButtonStyled
        key={index}
        disabled={currentValue === item.value}
        data-element-id={item.dataElementId || 'Top_Toggle'}
        data-analytics_available_call={item.dataCalls}
        data-test={item.dataElementId || 'Top_Toggle'}
        data-description={item.label}
        onClick={() => item.onChange(item.value)}
        active={currentValue === item.value}
        aria-label={item.label}
        aria-description={item.label}
      >
        {item.icon} {item.label}
      </ButtonStyled>
    )
  }

  return <Toggler>{[itemA, itemB].map(renderButton)}</Toggler>
}

export default ToggleItems
