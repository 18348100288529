import { Product } from '@abstractTypes/product'
import { useMixMatchEnabled } from '@hooks/useMixMatchEnabled'
import { usePrescriptionEnabled } from '@hooks/usePrescriptionEnabled'
import { useSupernovaEnabled } from '@hooks/useSupernovaEnabled'

const useShowMixMatchSwitch = (product: Product) => {
  const isSupernovaEnabled = useSupernovaEnabled()
  const isMixMatchEnabled = useMixMatchEnabled()
  const isPrescriptionEnabled = usePrescriptionEnabled()

  return Boolean(
    isSupernovaEnabled &&
      isMixMatchEnabled &&
      product.isSupernova &&
      product.roxable &&
      isPrescriptionEnabled
  )
}

export default useShowMixMatchSwitch
