import { PlaylistTipIcon } from '@components/core/Icons'
import { Text } from '@components/core/Typography'
import { tablet } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TipCardTitle, TipImageWrapper } from '../styles'

const PlaylistTipText = styled(Text)`
  margin-block-end: ${pxToRem(43)};
  ${tablet(`
    margin-block-end: ${clampValues(18, 43)};
    `)}
`

const translationKey = 'FrameAdvisor.tips.playlistTip'

const iconWidth = isIPadView() ? clampValues(177, 269) : pxToRem(269)
const iconHeight = isIPadView() ? clampValues(67, 100) : pxToRem(100)

export const PlaylistTipContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <TipCardTitle>{t(`${translationKey}.title`)}</TipCardTitle>
      <PlaylistTipText>{t(`${translationKey}.text`)}</PlaylistTipText>
      <TipImageWrapper>
        <PlaylistTipIcon width={iconWidth} height={iconHeight} />
      </TipImageWrapper>
    </>
  )
}
