import { IconProps } from '@abstractTypes/icons'


export const FaceScanAppSwitchGlasses: React.FC<IconProps> = ({
  width = '100%',
  height = '60',
}: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 100 52">
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <rect width="98.864" height="50" fill="#1B1B1B" rx="25"></rect>
        <rect
          width="50"
          height="50"
          fill="#FFF"
          stroke="#979797"
          strokeWidth="1.136"
          rx="25"
        ></rect>
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#FFF"
          strokeWidth="0.847"
          d="M62.698 18C59.003 18 56 21.139 56 25s3.003 7 6.698 7c3.694 0 6.697-3.139 6.697-7 0-.619-.088-1.215-.232-1.786.813-.396 1.842-.547 2.837-.547 1.001 0 2.024.158 2.837.559A7.248 7.248 0 0074.605 25c0 3.861 3.003 7 6.697 7C84.997 32 88 28.861 88 25s-3.003-7-6.698-7c-2.835 0-5.257 1.855-6.232 4.46-.94-.438-2.036-.571-3.07-.571-1.036 0-2.129.144-3.07.583C67.96 19.86 65.538 18 62.698 18zm0 .778c3.292 0 5.953 2.78 5.953 6.222 0 3.441-2.66 6.222-5.953 6.222S56.744 28.441 56.744 25c0-3.441 2.661-6.222 5.954-6.222zm18.604 0c3.293 0 5.954 2.78 5.954 6.222 0 3.441-2.661 6.222-5.954 6.222-3.292 0-5.953-2.781-5.953-6.222 0-3.441 2.66-6.222 5.953-6.222z"
        ></path>
      </g>
    </svg>
  )
}
