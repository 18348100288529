import { AppConfigOverride, BannerRegion, ShopperSlugWithFallback } from '@abstractTypes/envs'
import getShEnvVariables from '@smartshoppers/sh/env'
import getTvEnvVariables from '@smartshoppers/tv/env'
import getAlEnvVariables from '../smartshoppers/al/env'
import getDcEnvVariables from '../smartshoppers/dc/env'
import getGviEnvVariables from '../smartshoppers/gvi/env'
import getGvifeEnvVariables from '../smartshoppers/gvife/env'
import getLcEnvVariables from '../smartshoppers/lc/env'
import getLpEnvVariables from '../smartshoppers/lp/env'
import getOcEnvVariables from '../smartshoppers/oc/env'
import getOoEnvVariables from '../smartshoppers/oo/env'
import getOpsmEnvVariables from '../smartshoppers/opsm/env'
import getPvEnvVariables from '../smartshoppers/pv/env'
import getRbEnvVariables from '../smartshoppers/rb/env'
import getSghEnvVariables from '../smartshoppers/sgh/env'
import getSghofEnvVariables from '../smartshoppers/sghof/env'
import getSolEnvVariables from '../smartshoppers/sol/env'
import getSvEnvVariables from '../smartshoppers/sv/env'
import getToEnvVariables from '../smartshoppers/to/env'
import getMasterEnvVariables from './banners/master'
import getGoEnvVariables from '@smartshoppers/go/env'
import getMoEnvVariables from '@smartshoppers/mo/env'

const getBannerEnvVariable = (shopperSlug: ShopperSlugWithFallback, region: BannerRegion) => {
  const bannerEnvVariablesMap: {
    [key in ShopperSlugWithFallback]: (region?: BannerRegion) => AppConfigOverride
  } = {
    lc: getLcEnvVariables,
    al: getAlEnvVariables,
    dc: getDcEnvVariables,
    go: getGoEnvVariables,
    gvi: getGviEnvVariables,
    gvife: getGvifeEnvVariables,
    lp: getLpEnvVariables,
    oc: getOcEnvVariables,
    oakley: getOoEnvVariables,
    opsm: getOpsmEnvVariables,
    pv: getPvEnvVariables,
    rayban: () => getRbEnvVariables(region),
    sgh: () => getSghEnvVariables(region),
    sghof: getSghofEnvVariables,
    sol: getSolEnvVariables,
    salmoiraghi: getSvEnvVariables,
    to: getToEnvVariables,
    sh: getShEnvVariables,
    oo: getOoEnvVariables,
    tv: getTvEnvVariables,
    mo: getMoEnvVariables,
    master: getMasterEnvVariables,
  }

  const bannerEnvVariable = bannerEnvVariablesMap[shopperSlug]
  return bannerEnvVariable ? bannerEnvVariable(region) : {}
}

export default getBannerEnvVariable
