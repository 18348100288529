import { NuanceAudioLogo } from '@components/core/Icons'
import { useThemeContext } from '@hooks/useThemeContext'
import React from 'react'
import { LogoStyled, NuanceHeaderStyled, PrivacyPolicyTooltipStyled } from './styles'

type NuanceHeaderProps = {
  isSticky?: boolean
  showPrivacyPolicy?: boolean
}

export const NuanceHeader: React.FC<NuanceHeaderProps> = ({ isSticky, showPrivacyPolicy }) => {
  const theme = useThemeContext()

  const color = theme.nuance.colors.primaryDark

  return (
    <NuanceHeaderStyled isSticky={isSticky}>
      {showPrivacyPolicy && <PrivacyPolicyTooltipStyled stroke={color} />}
      <LogoStyled>
        <NuanceAudioLogo fill={color} />
      </LogoStyled>
    </NuanceHeaderStyled>
  )
}
