import { IconProps } from '@abstractTypes/icons'

export const AdjustableNavigationIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_23339_119092)">
        <path
          d="M26.6663 10.8796L24.7863 8.99956L17.333 16.4396V0.212891H14.6663V16.4396L7.22634 8.98622L5.33301 10.8796L15.9997 21.5462L26.6663 10.8796Z"
          fill="#333333"
        />
        <rect y="24.3203" width="32" height="7.68" fill="#333333" />
      </g>
      <defs>
        <clipPath id="clip0_23339_119092">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
