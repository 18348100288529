import { Heading4, Text } from '@components/core/Typography'
import config from '@config/index'
import { useFrameAdvisorFsaToken } from '@data'
import { useThemeContext } from '@hooks/useThemeContext'
import QRCodeStyling, { Options } from 'qr-code-styling'
import React, { useEffect, useMemo, useRef } from 'react'
import { QRCodeDebugWrapper, StyledQRCode } from './style'
import { DebugInfoProps, QRCodeProps } from './types'

const copyTextToClipboard = (text: string) => {
  if (!window.navigator.clipboard) {
    return
  }
  navigator.clipboard.writeText(text)
}

const QRCodeDebugDetails: React.FC<DebugInfoProps> = ({ postURL }) => {
  const { data } = useFrameAdvisorFsaToken()
  if (!data?.frameAdvisorFsaToken.frameAdvisorFsaToken.token || !postURL) {
    return null
  }

  return (
    <QRCodeDebugWrapper>
      <Heading4 weight="bold">Debug information</Heading4>
      <span>
        <Text
          onTouchStart={() =>
            copyTextToClipboard(data?.frameAdvisorFsaToken.frameAdvisorFsaToken.token || '')
          }
          onDoubleClick={() =>
            copyTextToClipboard(data?.frameAdvisorFsaToken.frameAdvisorFsaToken.token || '')
          }
        >
          Token (click to copy) - {data?.frameAdvisorFsaToken.frameAdvisorFsaToken.token}
        </Text>
      </span>
      <hr />
      <span>
        <Text
          onTouchStart={() => copyTextToClipboard(postURL || '')}
          onDoubleClick={() => copyTextToClipboard(postURL || '')}
        >
          Post url (click to copy) - {postURL}
        </Text>
      </span>
    </QRCodeDebugWrapper>
  )
}

export const QRCode = ({ url, size = 200, className, variant = 'square' }: QRCodeProps) => {
  const showQRCodeDebugInfo = config.component.showQRCodeDebugInfo
  const ref = useRef<HTMLDivElement | null>(null)
  const theme = useThemeContext()

  const qrCode = useMemo(() => {
    const squareVariant: Partial<Options> = {
      qrOptions: {
        mode: 'Byte',
        errorCorrectionLevel: 'L',
      },
      dotsOptions: {
        type: 'square',
        color: '#000000',
      },
      cornersDotOptions: {
        type: 'square',
        color: '#000000',
      },
      cornersSquareOptions: {
        type: 'square',
        color: '#000000',
      },
    }
    const roundVariant: Partial<Options> = {
      qrOptions: {
        mode: 'Byte',
        errorCorrectionLevel: 'M',
      },
      dotsOptions: {
        type: 'dots',
        color: theme.colors.primaryDark,
      },
      cornersDotOptions: {
        type: 'dot',
        color: theme.colors.primaryDark,
      },
      cornersSquareOptions: {
        type: 'dot',
        color: theme.colors.primaryDark,
      },
    }

    // get qood quality qrcode resolution
    return new QRCodeStyling({
      width: size,
      height: size,
      type: 'svg',
      ...(variant === 'square' ? squareVariant : roundVariant),
    })
  }, [size, theme.colors.primaryDark, variant])

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current)
    }
  }, [qrCode])

  useEffect(() => {
    qrCode.update({
      data: url,
    })
  }, [url, qrCode])

  return (
    <>
      <StyledQRCode data-url={url} data-test="img-qr" className={className} size={size} url={url}>
        <div ref={ref} />
      </StyledQRCode>
      {showQRCodeDebugInfo && <QRCodeDebugDetails postURL={url} />}
    </>
  )
}
