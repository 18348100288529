import { KeyboardProps } from '@abstractTypes/keyboard'
import config from '@config/index'
import { pxToRem } from '@libs/styled'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Draggable, { DraggableEventHandler } from 'react-draggable'
import ReactSimpleKeyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import reactTriggerChange from 'react-trigger-change'
import styled, { css } from 'styled-components'
import { BackspaceIcon, CapsLockIcon, DraggableIcon, LanguageIcon, ShiftIcon } from './icons'
import {
  ALT_LANG_BUTTON,
  DRAG_BUTTON,
  EMAIL_BUTTONS,
  SYMBOLS_BUTTON,
  domains,
  emailFieldNames,
  generateKeyboardLayout,
  getButtonIcon,
  getLangLayoutName,
  getSymbolsButtonLabel,
} from './utils'

const keyboardTheme = css`
  .hg-theme-default {
    background-color: ${({ theme }) => theme.touchkeyboard.backgroundColor};
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 50%);
    padding: 0;

    .hg-row {
      margin: 0 !important;
    }

    .hg-button.hg-activeButton {
      background-color: ${({ theme }) => theme.touchkeyboard.button.activeBackgroundColor};
      border-color: ${({ theme }) => theme.touchkeyboard.button.activeBackgroundColor};
      color: ${({ theme }) => theme.touchkeyboard.button.activeColor};
    }

    .hg-button.hg-standardBtn,
    .hg-button.hg-functionBtn {
      inline-size: ${({ theme }) => pxToRem(theme.touchkeyboard.button.width)};
      align-items: center;
      block-size: ${({ theme }) => pxToRem(theme.touchkeyboard.button.height)};
      border: 1px solid ${({ theme }) => theme.touchkeyboard.button.borderColor};
      border-radius: ${({ theme }) => pxToRem(theme.touchkeyboard.button.borderRadius)};
      font-family: ${({ theme }) => theme.touchkeyboard.fontFamily};
      font-size: ${({ theme }) => pxToRem(theme.touchkeyboard.fontSize)};
      font-weight: ${({ theme }) => theme.touchkeyboard.fontWeight};
      color: ${({ theme }) => theme.touchkeyboard.button.color};
      background-color: ${({ theme }) => theme.touchkeyboard.button.backgroundColor};
      margin: 0 !important;

      &:focus {
        outline: none;
      }
    }

    .hg-button.keyboard-drag-icon {
      font-size: 0;
    }

    .hg-button.keyboard-lang-icon-active {
      background-color: #005192 !important;
      border-color: #005192;
      color: #fff;
    }

    .hg-button.hg-functionBtn.hg-button-space {
      flex-grow: 1;
    }

    .hg-button.hg-functionBtn.hg-button-enter {
      background-color: ${({ theme }) => theme.touchkeyboard.button.submitBackgroundColor};
    }

    .keyboard-small-button {
      flex-grow: 0;
    }
  }
`

const KeyboardWrapper = styled.div<{ opacity?: number; isNumeric?: boolean }>`
  opacity: ${({ opacity = 1 }) => opacity};
  inline-size: ${({ isNumeric }) => (isNumeric ? pxToRem(360) : pxToRem(700))};
  z-index: 10000;
  position: fixed;
  inset-block-end: 0;
  inset-inline-start: ${({ isNumeric }) => (isNumeric ? 'calc(50% - 180px)' : 'calc(50% - 350px)')};
  margin-block: 0;
  margin-inline: auto;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 50%);
  ${keyboardTheme}
`

export interface HTMLKeyboardElement extends HTMLElement {
  setInput: (value: string) => void
  setCaretPosition: (position: number | null) => void
  getCaretPosition: () => number
}

export const Keyboard: React.FC<KeyboardProps> = ({
  inputNode,
  isDraggable = true,
  storePosition: kbStorePosition = () => {
    return
  },
  coords,
  bounds,
  className,
  hideKeyboardLabel,
  submitClicked,
  lang = 'us',
  altLang = 'en',
  isFirstLetterUppercase = true,
}) => {
  const langName = getLangLayoutName(lang)
  const altLangName = getLangLayoutName(altLang)
  const isEmail = emailFieldNames.includes(inputNode?.name || '')
  const isNumeric = inputNode?.type === 'number'
  const emailSuggestionsEnabled = isEmail && config.toggleFeature.emailSuggestions

  const KeyboardRef = useRef<HTMLKeyboardElement>()
  const [layoutName, setLayoutName] = useState<string>('default')
  const [keyboardLang, setKeyboardLang] = useState(langName)
  const [uppercase, setUppercase] = useState(0)
  const nodeRef = useRef(null)

  // to sync input and keyboard
  useEffect(() => {
    if (inputNode && KeyboardRef.current) {
      KeyboardRef.current.setInput(inputNode.value)
      const initialCaretPosition = inputNode.selectionStart
      KeyboardRef.current.setCaretPosition(initialCaretPosition)
      const uppercaseValue =
        isFirstLetterUppercase && inputNode.value.trim().length === 0 && !isEmail ? 1 : 0
      setUppercase(uppercaseValue)
      setLayoutName(uppercaseValue === 0 ? 'default' : 'shift')
    }
  }, [inputNode, isEmail, isFirstLetterUppercase])

  const storePosition: DraggableEventHandler = (_, data) => {
    kbStorePosition(data.x, data.y)
  }

  const onChange = (stringKey: string) => {
    if (!inputNode) return
    const caretPosition = KeyboardRef.current?.getCaretPosition() || 0
    inputNode.value = stringKey
    reactTriggerChange(inputNode)
    inputNode.setSelectionRange(caretPosition, caretPosition)
    handleShiftState(false)
    inputNode.dispatchEvent(new CustomEvent('input'))
  }

  const handleShiftState = (isShiftClick = true) => {
    //0 - the state is lowercase
    //1 - the state is in uppercase with Shift option
    //2 - the state is in Caps Lock
    let uppercaseState = uppercase
    if (isShiftClick) {
      // on shift we change it
      uppercaseState = uppercase === 2 ? 0 : uppercase + 1
    } else {
      // for letter type we set to 0 for 1 uppercase state
      uppercaseState = uppercase === 1 ? 0 : uppercase
    }
    if (uppercaseState !== uppercase) {
      setUppercase(uppercaseState)
      setLayoutName(uppercaseState === 0 ? 'default' : 'shift')
    }
  }

  const onEmailChange = useCallback(
    (emailButton: string) => {
      if (!inputNode) return
      const selectedSuggestion = domains[emailButton]
      const newValue = `${inputNode.value}@${selectedSuggestion}`
      inputNode.value = newValue
      reactTriggerChange(inputNode)
      const caretPossition = newValue.length
      KeyboardRef.current?.setInput(newValue)
      KeyboardRef.current?.setCaretPosition(caretPossition)
      inputNode.setSelectionRange(caretPossition, caretPossition)
      inputNode.dispatchEvent(new CustomEvent('input'))
    },
    [inputNode]
  )

  const onEnter = () => {
    if (!inputNode) return
    inputNode.blur()
    reactTriggerChange(inputNode)
    inputNode.dispatchEvent(new CustomEvent('input'))
    submitClicked('')
  }

  const onKeyPress = (button: string) => {
    switch (button) {
      case '{enter}':
        onEnter()
        break
      case '{shift}':
        handleShiftState()
        break
      case SYMBOLS_BUTTON:
        setLayoutName(layoutName === 'symbols' ? 'default' : 'symbols')
        break
      case ALT_LANG_BUTTON:
        setKeyboardLang(
          keyboardLang === getLangLayoutName(lang)
            ? getLangLayoutName(altLang)
            : getLangLayoutName(lang)
        )
        break
      case EMAIL_BUTTONS.GMAIL_BUTTON:
      case EMAIL_BUTTONS.YAHOO_BUTTON:
      case EMAIL_BUTTONS.HOTMAIL_BUTTON:
      case EMAIL_BUTTONS.OUTLOOK_BUTTON:
        onEmailChange(button)
        break
    }
  }

  const symbolsKeyValue = useMemo(() => {
    const symbolsValue = getSymbolsButtonLabel(keyboardLang)

    if (layoutName !== 'symbols') return symbolsValue.symbols

    return symbolsValue.letters
  }, [keyboardLang, layoutName])

  const getEmailsButtonsDisplay = useCallback(() => {
    return Object.keys(domains).reduce((list, key) => {
      return { ...list, [key]: `...@${domains[key]}` }
    }, {})
  }, [])

  const layout = useMemo(() => {
    const withAltLang = langName !== altLangName
    return generateKeyboardLayout({
      langName: keyboardLang,
      withAltLang,
      withEmailSuggestions: emailSuggestionsEnabled,
      isEmail,
      isNumeric: isNumeric,
    })
  }, [altLangName, emailSuggestionsEnabled, isEmail, keyboardLang, langName, isNumeric])

  return (
    <Draggable
      disabled={!isDraggable}
      defaultPosition={coords}
      position={coords}
      handle=".keyboard-drag-icon"
      bounds={bounds || 'body'}
      onStop={storePosition}
      nodeRef={nodeRef}
    >
      <KeyboardWrapper
        className={`${className} keyboard keyboard-wrapper`}
        data-analytics_available_call="0"
        isNumeric={isNumeric}
        ref={nodeRef}
        dir="ltr"
      >
        <ReactSimpleKeyboard
          keyboardRef={r => (KeyboardRef.current = r)}
          onChange={onChange}
          onKeyReleased={onKeyPress}
          preventMouseDownDefault
          preventMouseUpDefault
          inputName={inputNode?.name}
          layout={layout.layout}
          layoutCandidates={layout.layoutCandidates}
          layoutName={layoutName}
          buttonTheme={[
            {
              class: isNumeric ? 'keyboard-drag-icon' : 'keyboard-drag-icon keyboard-small-button',
              buttons: DRAG_BUTTON,
            },
            {
              class: altLangName === keyboardLang ? 'keyboard-lang-icon-active' : '',
              buttons: ALT_LANG_BUTTON,
            },
            {
              class: 'keyboard-small-button',
              buttons: '. {enter}',
            },
          ]}
          mergeDisplay
          display={{
            [DRAG_BUTTON]: getButtonIcon(<DraggableIcon />),
            [ALT_LANG_BUTTON]: getButtonIcon(<LanguageIcon />),
            [SYMBOLS_BUTTON]: symbolsKeyValue,
            '{enter}': hideKeyboardLabel || '',
            '{shift}': getButtonIcon(uppercase === 2 ? <CapsLockIcon /> : <ShiftIcon />),
            '{bksp}': getButtonIcon(<BackspaceIcon />),
            ...getEmailsButtonsDisplay(),
          }}
        />
      </KeyboardWrapper>
    </Draggable>
  )
}
