import { AutofillStoreIdentityParams } from '@components/AutoFillStoreIdentityParams'
import Loading from '@components/Loading'
import { SearchDrawer } from '@components/SearchDrawer'
import { unmountVMWidgetApp } from '@components/VirtualMirror/utils'
import { LoadingPage } from '@components/core/LoadingPage'
import config from '@config/index'
import VersionController from '@containers/VersionController'
import { useDisablePinchZoom } from '@hooks/GesturesHandler'
import { useAnalyticsNewSession } from '@hooks/analyticsHooks'
import { useCarouselsAutoplayState } from '@hooks/useCarouselsAutoplayState'
import { useConfigOverrideExpiration } from '@hooks/useConfigOverrideExpiration'
import { useStoreContext } from '@hooks/useStoreContext'
import { DEFAULT_LANG, DEFAULT_STORE, DEFAULT_STORETYPE } from '@hooks/useStoreIdentity'
import { useVKeyboardCoords } from '@hooks/useVKeyboardCoords'
import { useWCAGKeyboardShortcuts } from '@hooks/useWCAGKeyboardShortcuts'
import { setReturnUrl } from '@libs/custom'
import { mapAnalyticsShopperSlugs } from '@libs/formatters'
import { getKeyboardLayout, isVKeyboardAvailable } from '@libs/utils'
import { VKeyboardProvider } from '@providers/VKeyboardProvider'
import { AccessibilityProvider } from '@providers/accessibilityProvider'
import { AnalyticsProvider } from '@providers/analyticsProvider'
import { NavigationProvider } from '@providers/navigationProvider'
import { ResetProvider } from '@providers/resetProvider'
import { ScrollRestorationProvider } from '@providers/scrollRestorationProvider'
import { StoreProvider } from '@providers/storeProvider'
import { Location } from 'history'
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { GlobalHotKeys } from 'react-hotkeys'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Route,
  RouteComponentProps,
  Switch,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { OfflineWarning } from './components/OfflineWarning'
import { useApplicationOnlineStatus } from './hooks/useOnlineStatus'
import { Routes } from './routes'
import { GlobalStyle } from './styles/index'

const Base: React.FC<RouteComponentProps> = props => {
  const store = useStoreContext()
  const { isOffline } = useApplicationOnlineStatus()

  useAnalyticsNewSession(store)

  if (isOffline) return <OfflineWarning />

  return (
    <Suspense fallback={<Loading />}>
      <Routes {...props} />
    </Suspense>
  )
}

const BaseWrapper: React.FC<RouteComponentProps> = props => {
  const listLoaderIds = useSelector(s => s.ui.listLoaderIds)
  const showLoader = listLoaderIds.length > 0
  const { tealiumAnalytics, toggleFeature } = config
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname) {
      unmountVMWidgetApp()
    }
  }, [pathname])

  const analyticsConfig = useMemo(
    () => ({
      ...tealiumAnalytics,
      shopperSlug: mapAnalyticsShopperSlugs(tealiumAnalytics.shopperSlug),
      isEnabled: toggleFeature.analytics,
    }),
    [tealiumAnalytics, toggleFeature.analytics]
  )

  return (
    <StoreProvider>
      <MetaTags />
      <AnalyticsProvider analyticsConfig={analyticsConfig}>
        <ResetProvider>
          <LoadingPage loading={showLoader}>
            <Base {...props} />
            <SearchDrawer />
          </LoadingPage>
        </ResetProvider>
      </AnalyticsProvider>
    </StoreProvider>
  )
}

const AutofillStoreIdentityParamsWrapper: React.FC<RouteComponentProps> = props => {
  return (
    <StoreProvider>
      <AutofillStoreIdentityParams
        {...props}
        defaultId={DEFAULT_STORE}
        defaultType={DEFAULT_STORETYPE}
        defaultLang={DEFAULT_LANG}
      />
    </StoreProvider>
  )
}

const MetaTags: React.FC = () => {
  const store = useStoreContext()
  return (
    <Helmet>
      <meta name="description" content={`Smart Shopper - ${store.name}`} />
      <link rel="preconnect" href="https://p.typekit.net" />
    </Helmet>
  )
}

const App: React.FC = () => {
  const history = useHistory()
  const accessibilityAlternativeNavigation = useSelector(s => s.accessibility.alternativeNavigation)
  const carouselAutoplayState = useCarouselsAutoplayState()
  const accessibilityVkEnabled = useSelector(s => s.accessibility.vkEnabled)
  const { t } = useTranslation()
  const [vkEnabled, setVkEnabled] = useState(true)
  const [blockScreenReaderAutoplay, setBlockScreenReaderAutoplay] = useState(false)
  const [alternativeNavigation, setAlternativeNavigation] = useState<boolean>(false)

  useDisablePinchZoom(true)
  useConfigOverrideExpiration()

  useEffect(() => {
    const setReturnUrlForCustom = (location: Location) => {
      if (
        !matchPath(location.pathname, {
          path: '/:store_type/:store_id/:lang/custom/',
        })
      ) {
        setReturnUrl(`${location.pathname}${location.search}`)
      }
    }

    return history.listen(location => {
      setReturnUrlForCustom(location)
    })
  }, [history])

  const { wcagKeyboardShortcuts, wcagKeyboardShortcutHandlers } = useWCAGKeyboardShortcuts()

  useEffect(() => {
    setVkEnabled(accessibilityVkEnabled)
    setAlternativeNavigation(accessibilityAlternativeNavigation)
    setBlockScreenReaderAutoplay(carouselAutoplayState)
  }, [accessibilityAlternativeNavigation, carouselAutoplayState, accessibilityVkEnabled])

  const defaultKeyboard = getKeyboardLayout() || undefined
  const coords = useVKeyboardCoords()

  return (
    <AccessibilityProvider>
      <GlobalHotKeys keyMap={wcagKeyboardShortcuts} handlers={wcagKeyboardShortcutHandlers} />
      <VKeyboardProvider
        disabled={!isVKeyboardAvailable() || !vkEnabled}
        defaultSettings={{
          lang: defaultKeyboard,
          hideKeyboardLabel: t('TouchKeyboard.close'),
          coords,
        }}
        aria-label={t('TouchKeyboard.close')}
      >
        <NavigationProvider isAlternativeNavigation={alternativeNavigation}>
          <ScrollRestorationProvider>
            <GlobalStyle blockScreenReaderAutoplay={blockScreenReaderAutoplay} />
            <VersionController>
              <Switch>
                <Route
                  path="/:store_type/:store_id/:lang"
                  render={props => <BaseWrapper {...props} />}
                />
                <Route
                  path="/:store_type/:store_id"
                  render={props => <AutofillStoreIdentityParamsWrapper {...props} />}
                />
                <Route
                  path="/:store_type/"
                  render={props => <AutofillStoreIdentityParamsWrapper {...props} />}
                />
                <Route
                  path="/"
                  render={props => <AutofillStoreIdentityParamsWrapper {...props} />}
                />
              </Switch>
            </VersionController>
          </ScrollRestorationProvider>
        </NavigationProvider>
      </VKeyboardProvider>
    </AccessibilityProvider>
  )
}

export default App
