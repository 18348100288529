import { getCustomizableBrand, isOOBrand, isRBBrand } from '@libs/custom'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useStoreContext } from './useStoreContext'

export const useCustomizableEnabled = () => {
  const store = useStoreContext()
  const customerOrderType = useSelector(s => s.customerOrder.orderType)

  return useCallback(
    (originalBrandCode: string) => {
      if (customerOrderType && customerOrderType === 'COMPLETE_PAIR') {
        return false
      }
      const brand = getCustomizableBrand(originalBrandCode)
      if (isRBBrand(brand)) return Boolean(store.rbCustomEnabled)
      if (isOOBrand(brand)) return Boolean(store.ooCustomEnabled)
      return false
    },
    [customerOrderType, store.ooCustomEnabled, store.rbCustomEnabled]
  )
}
