import { ActiveFilters } from '@abstractTypes/analytics'
import { CustomerOrderInfo, CustomerOrderType } from '@abstractTypes/common'
import { AppConfig } from '@abstractTypes/envs'
import { resolveQueryParams } from '@hooks/useQueryParams'
import { Location as LocationHistory } from 'history'
import qs from 'qs'
import { LocaleCode } from './utils'

export const getPLPSortFromLocation = (location: LocationHistory): ActiveFilters[] | undefined => {
  const { sort } = resolveQueryParams(
    {
      sort: (value, kind) => [{ kind, value: String(value) }],
    },
    location.search
  )

  return sort?.length ? sort : undefined
}

export const getFiltersFromUrl = (location: LocationHistory) => {
  const { search } = location
  if (search) {
    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true })
    if (parsedSearch) {
      return Object.keys(parsedSearch)
        .filter(name => ['q', 'sort'].indexOf(name) === -1)
        .map(kind => ({
          kind,
          value: parsedSearch[kind],
        }))
    }
  }
  return undefined
}

export const getVMUrl = (config: AppConfig, hashVM: string) => {
  return `${config.vmURL}?hash=${encodeURIComponent(hashVM)}`
}

const isCustomerOrderType = (value?: unknown): value is CustomerOrderType =>
  ['COMPLETE_PAIR', 'FRAME'].includes(String(value))

export const getCustomerOrderInfo = (location: LocationHistory): CustomerOrderInfo => {
  return resolveQueryParams<CustomerOrderInfo>(
    {
      salesOrderId: String,
      upc: String,
      orderType: value => (isCustomerOrderType(value) ? value : null),
      email: String,
    },
    location.search
  )
}

export const getQueryParamsOnlyDefined = (
  paramsObj: {
    [key: string]: unknown
  } = {}
): string => {
  return paramsObj
    ? Object.keys(paramsObj)
        .filter(key => !!paramsObj[key])
        .map(key => key + '=' + paramsObj[key])
        .join('&')
    : ''
}

/** It returns the current language form the URL */
export const getLangFromUrl = () => {
  const urlparts = window.location.pathname.match(/\/([a-zA-Z-]*)/g)
  const langAttr = urlparts ? urlparts[2] && urlparts[2].replace('/', '') : undefined
  if (langAttr && LocaleCode.getLanguageCode(langAttr)) {
    return langAttr
  }
  return 'en-US'
}

export const getSearchValueFromQs = (location: LocationHistory): string => {
  const { q, query } = resolveQueryParams(
    {
      q: String,
      query: String,
    },
    location.search
  )
  const parsedQuery = q || query || ''

  // Replace `+` with " " (white spaces)
  return parsedQuery.replace(/\+/g, ' ')
}

export const getSiteOrigin = () => window.location.origin

/** It returns the site absolute path */
export const getSiteAbsolutePath = (basepath?: string): string => {
  const lang = getLangFromUrl()

  let path = basepath
  if (!path) {
    const [_, storeType, storeId] = window.location.pathname.split('/')
    path = [_, storeType, storeId].join('/')
  }

  if (lang) {
    return [path, lang].join('/')
  }
  return path
}

/** It returns the UPC from the PDP URL */
export const getUPCFromPDPUrl = () => {
  const urlParts = window.location.pathname.split('/')
  const upcAttr = urlParts[urlParts.length - 1]
  if (upcAttr) {
    return upcAttr
  }
  return 'No UPC found'
}
