import { ProductSubdivisions } from '@abstractTypes/filter'
import { QueryNoResultsArgs } from '@abstractTypes/graphqlTypes'
import { StandardProduct } from '@abstractTypes/product'
import ConditionalRender from '@components/ConditionalRender'
import Loading from '@components/Loading'
import { SearchProductList } from '@components/ProductsList'
import SimilarStylesProducts from '@components/SearchDrawer/SimilarStylesProducts'
import { SecondaryButton } from '@components/core/Button/index'
import { useNoResultsData } from '@data'
import useFormatPlpNoResultsQueryPayload from '@hooks/formatPayloadForSearchApi/useFormatPlpNoResultsQueryPayload'
import useFormatSearchNoResultsQueryPayload from '@hooks/formatPayloadForSearchApi/useFormatSearchNoResultsQueryPayload'
import { useNavigationFlowHandler } from '@hooks/useNavigationFlowHandler'
import { usePreviousNonNullish } from '@hooks/usePreviousNonNullish'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { isProductTypeSubdivision } from '@libs/productSubdivision'
import { useAccessibilityContext } from '@providers/accessibilityProvider'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ContinueShoppingBtn, NoDataContainer, Switcher, Title } from './styles'
import { NoDataSuggestionListProps } from './types'

export const NoDataSuggestionList: React.FC<NoDataSuggestionListProps> = ({ items, title }) => {
  return (
    <div>
      <Title weight="bold">{title}</Title>
      <SearchProductList items={items} />
    </div>
  )
}

interface NoDataContentProps {
  selectedTab?: ProductSubdivisions
  queryParamsData: QueryNoResultsArgs['data']
  showContinueShoppingButton?: boolean
  query?: string
}

const NoDataContent: React.FC<NoDataContentProps> = ({
  selectedTab,
  queryParamsData,
  showContinueShoppingButton = false,
  query,
}) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const history = useHistory()
  const { barcodeFlow } = useNavigationFlowHandler()

  const { sendNotification } = useAccessibilityContext()

  useEffect(() => {
    sendNotification && sendNotification(t('ProductsListPage.noResults'), 'assertive')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: noResultsData, loading: noResultsDataLoading } = useNoResultsData(queryParamsData)
  const { similarStylesProducts } = useSelector(s => s.barcode)

  const prevData = usePreviousNonNullish(noResultsData)
  const existingNoResultsData = !isEmpty(noResultsData) && noResultsData ? noResultsData : prevData

  const hasNoResults = useMemo(
    () => existingNoResultsData?.noResults?.noResults?.some(carousel => carousel?.products?.length),
    [existingNoResultsData?.noResults?.noResults]
  )

  const showToggle = useMemo(() => selectedTab && hasNoResults, [selectedTab, hasNoResults])

  if (noResultsDataLoading) {
    return <Loading />
  }

  const renderSwitcher = () => {
    return <Switcher productSubdivision={selectedTab} />
  }

  return (
    <NoDataContainer>
      {hasNoResults ? (
        <>
          {showToggle && renderSwitcher()}
          {barcodeFlow && similarStylesProducts ? (
            <SimilarStylesProducts
              products={similarStylesProducts as StandardProduct[]}
              query={query || ''}
              renderSwitcher={renderSwitcher()}
            />
          ) : (
            existingNoResultsData?.noResults?.noResults?.map((carousel, i) => {
              return (
                carousel?.products &&
                carousel.products.length >= 1 && (
                  <NoDataSuggestionList
                    key={i}
                    items={carousel.products as StandardProduct[]}
                    title={t(`NoData.${carousel.id.toLowerCase()}`)}
                    aria-label={t(`NoData.${carousel.id.toLowerCase()}`)}
                  />
                )
              )
            })
          )}
        </>
      ) : (
        // TODO: Do we even enter in here to show this button?
        <ConditionalRender
          condition={showContinueShoppingButton}
          render={() => (
            <ContinueShoppingBtn>
              <SecondaryButton
                onClick={() => {
                  history.push(`${basePath}/products`)
                }}
                ariaAttrs={{
                  label: t('NoData.continueShoppingButton'),
                  description: t('NoData.continueShoppingButton'),
                }}
              >
                {t('NoData.continueShoppingButton')}
              </SecondaryButton>
            </ContinueShoppingBtn>
          )}
        />
      )}
    </NoDataContainer>
  )
}

export const PlpNoDataContent: React.FC = () => {
  const queryParamsData = useFormatPlpNoResultsQueryPayload()
  return (
    <NoDataContent
      queryParamsData={queryParamsData}
      showContinueShoppingButton={isProductTypeSubdivision()}
    />
  )
}

interface SearchNoDataContentProps {
  selectedTab: ProductSubdivisions
  query: string
}

export const SearchNoDataContent: React.FC<SearchNoDataContentProps> = ({ selectedTab, query }) => {
  const queryParamsData = useFormatSearchNoResultsQueryPayload(selectedTab)
  return <NoDataContent selectedTab={selectedTab} query={query} queryParamsData={queryParamsData} />
}
