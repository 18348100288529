import { useCombinedRefs } from '@hooks/useCombinedRefs'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import { isEmpty } from 'lodash'
import Carousel, { CarouselProps } from 'nuka-carousel'
import React, { useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { NukaCarouselProps } from './types'

const GlobalNukaCarouselCss = createGlobalStyle<{ rtl: boolean }>`
  .slider-container {
    block-size: 100%;
    inline-size: 100%;
    min-block-size: inherit;

    & .slider-frame {
      block-size: 100% !important;
      min-block-size: inherit;
    }
  }

  .slider-list {
    block-size: 100%;
    min-block-size: inherit;
    flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
  }

  .hidden {
    display: none;
  }
`

const CarouselContainer = styled.div`
  inline-size: 100%;
  display: inline;
`

const defaultCarouselProps: CarouselProps = {
  speed: 500,
  dragThreshold: 0.2,
  slidesToScroll: 1,
  slidesToShow: 1,
}

export const NukaCarousel: React.FC<Omit<CarouselProps, 'innerRef'> & NukaCarouselProps> = ({
  children,
  autoplay = false,
  animationTime = 5500,
  loop,
  withoutBullets = true,
  withoutButtons = true,
  invisibleSlidesFocusable = false,
  slideIndex,
  ...props
}) => {
  const rtlDirection = useLanguageDirection()
  const { defaultControlsConfig, innerRef, ...carouselProps } = props
  const carouselRef = useRef<HTMLDivElement>(null)
  const combinedRef = useCombinedRefs(carouselRef, innerRef || null)

  useEffect(() => {
    if (!rtlDirection && !invisibleSlidesFocusable) return
    const slides = innerRef?.current?.firstElementChild?.children
    if (!slides || isEmpty(slides)) return
    Array.from(slides).forEach((child: Element) => child.removeAttribute('inert'))
  }, [invisibleSlidesFocusable, innerRef, slideIndex, rtlDirection])

  // direction MUST be static on ltr!!
  return (
    <CarouselContainer dir="ltr">
      <GlobalNukaCarouselCss rtl={rtlDirection} />
      <Carousel
        slideIndex={slideIndex}
        // renderAnnounceSlideMessage={() => ''} added so that Sreen Reader does not announce "Slide n of N"
        renderAnnounceSlideMessage={() => ''}
        innerRef={combinedRef as React.MutableRefObject<HTMLDivElement>}
        autoplay={autoplay}
        autoplayReverse={props.autoplayReverse ?? rtlDirection}
        autoplayInterval={animationTime}
        wrapAround={loop}
        withoutControls={withoutBullets && withoutButtons}
        defaultControlsConfig={{
          prevButtonClassName: withoutButtons ? 'hidden' : '',
          nextButtonClassName: withoutButtons ? 'hidden' : '',
          pagingDotsContainerClassName: withoutBullets ? 'hidden' : '',
          ...(defaultControlsConfig ? { ...defaultControlsConfig } : {}),
        }}
        {...defaultCarouselProps}
        {...carouselProps}
      >
        {children}
      </Carousel>
    </CarouselContainer>
  )
}
