import { AnalyticsContext, defaultAnalyticsContext } from '@providers/analyticsProvider'
import { useContext } from 'react'

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)
  if (context === defaultAnalyticsContext) {
    throw new Error('useAnalyticsContext must be used within a AnalyticsContextProvider')
  }
  return context
}
