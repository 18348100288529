import {
  AdvisorSuggestion,
  EyeColor,
  FaPlaylistType,
  FrameAdvisorUserProfile,
  HairColor,
  LoveHatesData,
  Mood,
  SkinUndertone,
  UndoData,
} from '@abstractTypes/frameAdvisor'
import { ContentV2PlaylistItem } from '@abstractTypes/graphqlTypes'
import { StandardProduct } from '@abstractTypes/product'
import { toggleFrameAdvisorMySelectionAction } from '@actions/ui'
import { addItemToWishlist, removeItemFromWishlist } from '@actions/wishlist'
import { formatProductForUndo } from '@libs/formatters'
import { ThunkAction } from '../store'

export const UPDATE_SURVEY = 'frameAdvisor/UPDATE_SURVEY'
export interface UpdateSurvey {
  type: typeof UPDATE_SURVEY
  payload: {
    key: string
    tag: string
  }
}

export const SET_LAST_SURVEY_FACE_STEP = 'frameAdvisor/SET_LAST_SURVEY_FACE_STEP '
export interface SetLastSurveyFaceStep {
  type: typeof SET_LAST_SURVEY_FACE_STEP
  payload: string
}

export const RESET_SURVEY = 'frameAdvisor/RESET_SURVEY'
export interface ResetSurvey {
  type: typeof RESET_SURVEY
}

export const SET_COMPLETE_SURVEY = 'frameAdvisor/COMPLETE_SURVEY'
export interface SetCompleteSurvey {
  type: typeof SET_COMPLETE_SURVEY
  payload: boolean
}

export const ADD_LOVE_HATE_MOCO = 'frameAdvisor/ADD_LOVE_HATE_MOCO'

export const SAVE_MOCO_FOR_UNDO = 'frameAdvisor/SAVE_MOCO_FOR_UNDO'
export const REMOVE_MOCO_FROM_UNDO = 'frameAdvisor/REMOVE_MOCO_FROM_UNDO'
export const UNDO_HATE_ITEM = 'frameAdvisor/UNDO_HATE_ITEM'

export interface AddLoveHateMoco {
  type: typeof ADD_LOVE_HATE_MOCO
  payload: LoveHatesData
}

export interface SaveMocoForUndo {
  type: typeof SAVE_MOCO_FOR_UNDO
  payload: UndoData
}

export interface RemoveMocoFromUndo {
  type: typeof REMOVE_MOCO_FROM_UNDO
  payload: UndoData
}

export interface UndoHateItem {
  type: typeof UNDO_HATE_ITEM
  payload: UndoData
}

export const REMOVE_LOVE_HATE_MOCO = 'frameAdvisor/REMOVE_LOVE_HATE_MOCO'
export interface RemoveLoveHateMoco {
  type: typeof REMOVE_LOVE_HATE_MOCO
  payload: {
    moco: string
  }
}

export const RESET_LOVE_HATE = 'frameAdvisor/RESET_LOVE_HATE'
export interface ResetLoveHate {
  type: typeof RESET_LOVE_HATE
}

export const UPDATE_VISITED = 'frameAdvisor/UPDATE_VISITED'
export interface UpdateVisited {
  type: typeof UPDATE_VISITED
  payload: {
    visited: boolean
  }
}

export const ADD_TO_MYSELECTION = 'frameAdvisor/ADD_TO_MYSELECTION'
export interface AddToMySelection {
  type: typeof ADD_TO_MYSELECTION
  payload: {
    product: StandardProduct
  }
}

export const REMOVE_FROM_MYSELECTION = 'frameAdvisor/REMOVE_FROM_MYSELECTION'
export interface RemoveFromMySelection {
  type: typeof REMOVE_FROM_MYSELECTION
  payload: {
    moco: string
  }
}

export const SAVE_FSA_VIDEOID = 'frameAdvisor/SAVE_FSA_VIDEOID'
export interface SaveFsaVideoId {
  type: typeof SAVE_FSA_VIDEOID
  payload: {
    videoId: string
  }
}

export const SAVE_FSA_TOKENS = 'frameAdvisor/SAVE_FSA_TOKENS'
export interface SaveFsaTokens {
  type: typeof SAVE_FSA_TOKENS
  payload: {
    fsaAccessToken: string
    fsaRefreshToken: string | null
  }
}

export const SAVE_FSA_INFO = 'frameAdvisor/SAVE_FSA_INFO'

export interface SaveFsaInfo {
  type: typeof SAVE_FSA_INFO
  payload: {
    uuid: string
    profilePictureUrl?: string
    pictureUuid: string
    videoUuid?: string
    playlistId?: string
    userInformation?: {
      faceBreadth?: number
      glassesType?: string
    }
    userSearchInformation?: {
      gender?: string
      age?: string
      skinUndertones?: SkinUndertone
      faceShape?: string
      faceLength?: string
      hairColor?: HairColor
      eyeColor?: EyeColor
      eyebrowsThickness?: string
      eyeMood?: string
      sunMood?: string
      darkCircles?: string
    }
    advisorInformation?: Record<FaPlaylistType, Record<Mood, AdvisorSuggestion>>
  }
}

export const SET_SENTENCE_MAP = 'frameAdvisor/SET_SENTENCE_MAP'
export interface SetSentenceMap {
  type: typeof SET_SENTENCE_MAP
  payload: {
    sentenceMap: Record<string, number>
  }
}

export const RESET_FSA = 'frameAdvisor/RESET_FSA'
export interface ResetFsa {
  type: typeof RESET_FSA
}

export const TOGGLE_DRESSED_ON = 'frameAdvisor/TOGGLE_DRESSED_ON'
export interface ToggleDressedOn {
  type: typeof TOGGLE_DRESSED_ON
}

export const TOGGLE_WISHLIST_DRESSED_ON = 'frameAdvisor/TOGGLE_WISHLIST_DRESSED_ON'

export interface ToggleWishlistDressedOn {
  type: typeof TOGGLE_WISHLIST_DRESSED_ON
}

export const SELECT_FRAME_ADVISOR_PLAYLIST = 'frameAdvisor/SELECT_FRAME_ADVISOR_PLAYLIST'
export interface SelectFrameAdvisorPlaylist {
  type: typeof SELECT_FRAME_ADVISOR_PLAYLIST
  payload: {
    playlist: ContentV2PlaylistItem
  }
}

export const SELECT_FRAME_ADVISOR_SHADES = 'frameAdvisor/SELECT_FRAME_ADVISOR_SHADES'

export const SET_SELECTED_OPTION_ID = 'frameAdvisor/SET_SELECTED_OPTION_ID'

export interface SetSelectedOptionId {
  type: typeof SET_SELECTED_OPTION_ID
  payload: {
    id: string
    key: string
  }
}
export interface SelectFrameAdvisorShades {
  type: typeof SELECT_FRAME_ADVISOR_SHADES
  payload: {
    shadesFor: string
  }
}

export const SET_FRAME_ADVISOR_MYSELECTION_VISITED =
  'frameAdvisor/SET_FRAME_ADVISOR_MYSELECTION_VISITED'
export interface SetFrameAdvisorMySelectionVisited {
  type: typeof SET_FRAME_ADVISOR_MYSELECTION_VISITED
}

export const setFrameAdvisorMyselectionVisited = (): SetFrameAdvisorMySelectionVisited => ({
  type: SET_FRAME_ADVISOR_MYSELECTION_VISITED,
})

export const updateSurvey = (key: string, tag: string): UpdateSurvey => ({
  type: UPDATE_SURVEY,
  payload: {
    key,
    tag,
  },
})

export const setLastSurveyFaceStep = (slug: string): SetLastSurveyFaceStep => ({
  type: SET_LAST_SURVEY_FACE_STEP,
  payload: slug,
})

export const resetSurvey = (): ResetSurvey => ({
  type: RESET_SURVEY,
})

export const setCompleteSurvey = (isComplete: boolean): SetCompleteSurvey => ({
  type: SET_COMPLETE_SURVEY,
  payload: isComplete,
})

export const toggleDressedOn = (): ToggleDressedOn => {
  return {
    type: TOGGLE_DRESSED_ON,
  }
}

export const loveMoco = (moco: string, playListId?: string): AddLoveHateMoco => ({
  type: ADD_LOVE_HATE_MOCO,
  payload: {
    status: '0',
    moco,
    timestamp: new Date().toISOString(),
    playListId,
  },
})

export const hateMoco = (moco: string, playListId?: string): AddLoveHateMoco => ({
  type: ADD_LOVE_HATE_MOCO,
  payload: {
    status: '1',
    moco,
    timestamp: new Date().toISOString(),
    playListId,
  },
})

export const saveMocoForUndo = (product: UndoData): SaveMocoForUndo => ({
  type: SAVE_MOCO_FOR_UNDO,
  payload: formatProductForUndo(product),
})

export const removeMocoFromUndo = (product: UndoData): RemoveMocoFromUndo => ({
  type: REMOVE_MOCO_FROM_UNDO,
  payload: formatProductForUndo(product),
})

export const undoHate = (product: UndoData): UndoHateItem => ({
  type: UNDO_HATE_ITEM,
  payload: formatProductForUndo(product),
})

export const removeMoco = (moco: string): RemoveLoveHateMoco => ({
  type: REMOVE_LOVE_HATE_MOCO,
  payload: {
    moco,
  },
})

export const resetLoveHate = (): ResetLoveHate => ({
  type: RESET_LOVE_HATE,
})

export const updateVisited = (visited: boolean): UpdateVisited => ({
  type: UPDATE_VISITED,
  payload: {
    visited,
  },
})

export const addToMySelection = (product: StandardProduct): AddToMySelection => {
  return {
    type: ADD_TO_MYSELECTION,
    payload: {
      product: product,
    },
  }
}

export const removeFromMySelection = (moco: string): RemoveFromMySelection => {
  return {
    type: REMOVE_FROM_MYSELECTION,
    payload: {
      moco,
    },
  }
}

export const saveFsaVideoId = (videoId: string): SaveFsaVideoId => {
  return {
    type: SAVE_FSA_VIDEOID,
    payload: {
      videoId,
    },
  }
}

export const saveFsaTokens = (
  fsaAccessToken: string,
  fsaRefreshToken: string | null
): SaveFsaTokens => {
  return {
    type: SAVE_FSA_TOKENS,
    payload: {
      fsaAccessToken,
      fsaRefreshToken,
    },
  }
}

export const saveFsaInfo = (fsaInfo: FrameAdvisorUserProfile): SaveFsaInfo => {
  return {
    type: SAVE_FSA_INFO,
    payload: {
      uuid: fsaInfo.uuid || '',
      profilePictureUrl: fsaInfo.profilePictureUrl,
      pictureUuid: fsaInfo.pictureUuid,
      videoUuid: fsaInfo.videoUuid,
      playlistId: fsaInfo.playlistId,
      userInformation: {
        faceBreadth: fsaInfo.userInformation?.faceBreadth,
        glassesType: fsaInfo.userInformation?.glassesType,
      },
      userSearchInformation: {
        gender: fsaInfo.userSearchInformation?.gender,
        age: fsaInfo.userSearchInformation?.age,
        skinUndertones: fsaInfo.userSearchInformation?.skinUndertones,
        faceShape: fsaInfo.userSearchInformation?.faceShape,
        faceLength: fsaInfo.userSearchInformation?.faceLength,
        hairColor: fsaInfo.userSearchInformation?.hairColor,
        eyeColor: fsaInfo.userSearchInformation?.eyeColor,
        eyebrowsThickness: fsaInfo.userSearchInformation?.eyebrowsThickness,
        eyeMood: fsaInfo.userSearchInformation?.eyeMood,
        sunMood: fsaInfo.userSearchInformation?.sunMood,
        darkCircles: fsaInfo.userSearchInformation?.darkCircles,
      },
      advisorInformation: fsaInfo.advisorInformation,
    },
  }
}

export const SAVE_FSA_USERID = 'frameAdvisor/SAVE_FSA_USER'
export interface SaveFsaUserId {
  type: typeof SAVE_FSA_USERID
  payload: {
    userId: string
  }
}

export const resetFsa = (): ResetFsa => {
  return {
    type: RESET_FSA,
  }
}

export const loveItem =
  (product: StandardProduct, playListId?: string): ThunkAction =>
  dispatch => {
    dispatch(loveMoco(product.moco, playListId))
    dispatch(addItemToWishlist(product))
    return dispatch(addToMySelection(product))
  }

export const hateItem =
  (product: StandardProduct, playListId?: string): ThunkAction =>
  dispatch => {
    dispatch(hateMoco(product.moco, playListId))
    dispatch(removeItemFromWishlist(product))
    return dispatch(removeFromMySelection(product.moco))
  }

export const saveToUndoableList =
  (product: UndoData): ThunkAction =>
  dispatch => {
    return dispatch(saveMocoForUndo(product))
  }

export const removeFromUndoableList =
  (product: UndoData): ThunkAction =>
  dispatch => {
    return dispatch(removeMocoFromUndo(product))
  }

export const undoHatedItem =
  (product: UndoData): ThunkAction =>
  dispatch => {
    return dispatch(undoHate(product))
  }

export const removeItemFromMySelection =
  (product: StandardProduct): ThunkAction =>
  dispatch => {
    dispatch(removeMoco(product.moco))
    dispatch(removeItemFromWishlist(product))
    return dispatch(removeFromMySelection(product.moco))
  }

export const removeItemFromFASelection =
  (product: StandardProduct): ThunkAction =>
  dispatch => {
    dispatch(removeMoco(product.moco))
    return dispatch(removeFromMySelection(product.moco))
  }

export const selectFrameAdvisorPlaylist = (
  playlist: ContentV2PlaylistItem
): SelectFrameAdvisorPlaylist => {
  return {
    type: SELECT_FRAME_ADVISOR_PLAYLIST,
    payload: {
      playlist,
    },
  }
}

export const selectFrameAdvisorShades = (shadesFor: string): SelectFrameAdvisorShades => {
  return {
    type: SELECT_FRAME_ADVISOR_SHADES,
    payload: {
      shadesFor,
    },
  }
}

export const setSelectedOptionId = (id: string, key: string): SetSelectedOptionId => {
  return {
    type: SET_SELECTED_OPTION_ID,
    payload: {
      id,
      key,
    },
  }
}

export const toggleFrameAdvisorMySelectionSetVisited = (): ThunkAction => dispatch => {
  dispatch(toggleFrameAdvisorMySelectionAction)
  dispatch(setFrameAdvisorMyselectionVisited())
}

export const setSentenceMap = (sentenceMap: Record<string, number>): SetSentenceMap => ({
  type: SET_SENTENCE_MAP,
  payload: {
    sentenceMap,
  },
})

export const saveFsaUserId = (userId: string): SaveFsaUserId => {
  return {
    type: SAVE_FSA_USERID,
    payload: {
      userId,
    },
  }
}

export type FrameAdvisorActionTypes =
  | UpdateSurvey
  | ResetSurvey
  | SetCompleteSurvey
  | AddLoveHateMoco
  | RemoveLoveHateMoco
  | ResetLoveHate
  | UpdateVisited
  | AddToMySelection
  | RemoveFromMySelection
  | SaveFsaVideoId
  | SaveFsaTokens
  | SaveFsaInfo
  | ResetFsa
  | ToggleDressedOn
  | ToggleWishlistDressedOn
  | SelectFrameAdvisorPlaylist
  | SelectFrameAdvisorShades
  | SetFrameAdvisorMySelectionVisited
  | SaveMocoForUndo
  | RemoveMocoFromUndo
  | UndoHateItem
  | SetSelectedOptionId
  | SetLastSurveyFaceStep
  | SetSentenceMap
  | SaveFsaUserId
