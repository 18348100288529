import { NuanceHeading5 } from '@components/core/Typography/NuanceTypography'
import { Maybe } from 'graphql/jsutils/Maybe'
import React from 'react'
import { DetailRowContainer, NuanceValue } from './styles'

export const NuanceDetailsRow: React.FC<{
  label: string
  value?: Maybe<string>
}> = ({ label, value }) => {
  return (
    <DetailRowContainer>
      <NuanceHeading5 weight="bold">{label}</NuanceHeading5>
      <NuanceValue>{value || '-'}</NuanceValue>
    </DetailRowContainer>
  )
}

export default NuanceDetailsRow
