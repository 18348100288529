import { Cart, CartItem, CartState } from '@abstractTypes/cart'
import { EngravingSelectedOptions, EngravingSelectedOptionsList } from '@abstractTypes/engraving'
import { Product } from '@abstractTypes/product'
import { uniqueId } from '@libs/utils'
import { isNuanceProduct } from './nuance'

/**
 * Check if cart has some custom product in it
 *
 * @param {object} cart - object of shape cart (libs/cart)
 * @return {boolean} true if cart has at least one custom product
 */
export const hasCustomItemInCart = (cart: CartState) => {
  return cart.items.some(p => Object.prototype.hasOwnProperty.call(p.product, 'customItem'))
}

/**
 * Check if cart has some standard product in it
 *
 * @param {object} cart - object of shape cart (libs/cart)
 * @return {boolean} true if cart has at least one standard product
 */
export const hasStandardItemInCart = (cart: CartState) => {
  return cart.items.some(p => !Object.prototype.hasOwnProperty.call(p.product, 'customItem'))
}

/**
 * Check if cart is mixed of normal products and custom products (they have customItem obj).
 *
 * @param {object} cart - object of shape cart (libs/cart)
 * @return {boolean} true if cart is with 2 or more items with mixed products
 */
export const isMixedCart = (cart: CartState) => {
  if (cart.items.length < 2) return false

  return hasCustomItemInCart(cart) && hasStandardItemInCart(cart)
}

/**
 * Check if cart is mixed of normal products and electronics products.
 *
 * @param {object} items - object of items in cart
 * @return {boolean} true if cart is with 2 or more items with mixed products
 */
export const isMixedCartElectronics = (items: CartItem[]) => {
  if (items.length < 2) return false

  const electronicsProducts = items.filter(item => item.product.isSupernova)

  return electronicsProducts.length !== 0 && electronicsProducts.length !== items.length
}

export const isMixedCartNuance = (items: CartItem[]) => {
  if (items.length < 2) return false
  const nuanceProducts = items.filter(item => isNuanceProduct(item.product))
  return nuanceProducts.length !== 0 && nuanceProducts.length !== items.length
}

export const isMixedCartNuanceElectronics = (items: CartItem[]) => {
  if (items.length < 2) return false
  const nuanceProducts = items.filter(item => isNuanceProduct(item.product))
  const electronicsProducts = items.filter(item => item.product.isSupernova)

  return (
    nuanceProducts.length !== 0 &&
    nuanceProducts.length !== items.length &&
    electronicsProducts.length !== 0 &&
    electronicsProducts.length !== items.length
  )
}

export const cartSummaryValues = (cartItems: CartItem[]) => {
  return {
    numberItems: cartItems.reduce((tot, el) => (tot += el.quantity), 0),
    subtot: cartItems.reduce((tot, el) => {
      const priceValue = el.product.price.current
      return (tot += el.quantity * priceValue)
    }, 0.0),
  }
}

const generateCartId = (upc: string, idx = uniqueId()) => `cart-${idx}--${upc}`

export const createCartItemId = (
  product: Product,
  engravingItems: EngravingSelectedOptionsList
): string => {
  const upc = product.UPC
  const hasEngraving = engravingItems[upc]
  const hasCustomization = !!product.customItem
  const hasCompletePair = !!product.completePair
  const hasMixMatch = !!product.mixMatch

  if (hasEngraving || hasCustomization) {
    return generateCartId(upc)
  }

  if (hasCompletePair) {
    return generateCartId(upc, 'complete-pair')
  }

  if (hasMixMatch) {
    return generateCartId(upc, 'mix-match')
  }

  return generateCartId(upc, '0')
}

/**
 * Retrieve remaing items number.
 */
export const stock = (cart: Cart, product: Product): number => {
  const inCart = cart.items.filter(item => item.product.UPC === product.UPC)
  const quantity = inCart.reduce((tot, el) => (tot += el.quantity), 0)
  const stock = (product.availability && product.availability.ecommStock) || 0
  return stock - quantity
}

export const addEngravingPriceToProduct = (
  item: CartItem,
  engravedItem: EngravingSelectedOptions
): CartItem => {
  let cartItem = { ...item }
  if (engravedItem && cartItem.product.engravingConstraints) {
    cartItem = {
      ...cartItem,
      product: {
        ...cartItem.product,
        price: {
          ...cartItem.product.price,
          id: (cartItem.product.price && cartItem.product.price.id) || '',
          current: cartItem.product.price.current + cartItem.product.engravingConstraints.price,
        },
      },
    }
  }
  return cartItem
}
