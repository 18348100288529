import { AppConfigOverride } from '@abstractTypes/envs'

const getLocalEnvVariables = (): AppConfigOverride => {
  const localEnvVariables = {
    resetAppTimeOut: 0,
    errorWidgetTimeOut: 0,
  }

  return localEnvVariables
}

export default getLocalEnvVariables
