import { KeyMap } from 'react-hotkeys'
import { useActions } from './useActions'
import {
  navigateToFavourites,
  navigateToPlpAndOpenFilters,
  openSearch,
  zoomIn,
  zoomOut,
} from '@actions/keyboard'
import { useHistory } from 'react-router-dom'
import { History } from 'history'

const keyMap: KeyMap = {
  OPEN_SEARCH: {
    name: 'modalSearch',
    description: 'F3',
    sequence: 'F3',
    group: 'shortcut',
    action: 'keydown',
  },
  NAVIGATE_TO_PLP_AND_OPEN_FILTERS: {
    name: 'modalFilters',
    description: 'F4',
    sequence: 'F4',
    group: 'shortcut',
    action: 'keydown',
  },
  GO_TO_FAVORITES: {
    name: 'modalFavorites',
    description: 'F5',
    sequence: 'F5',
    group: 'shortcut',
    action: 'keydown',
  },
  ZOOM_IN: {
    name: 'modalZoomIn',
    description: 'F6',
    sequence: 'F6',
    group: 'zoom',
    action: 'keydown',
  },
  ZOOM_OUT: {
    name: 'modalZoomOut',
    description: 'F7',
    sequence: 'F7',
    group: 'zoom',
    action: 'keydown',
  },
  SCREENREADER_ACTIVATION: {
    name: 'modalScreenReaderActivation',
    description: 'F10 + enter',
    sequence: '',
    group: 'reader',
    action: 'keydown',
  },
  SCREENREADER_DEACTIVATION: {
    name: 'modalScreenReaderDeactivation',
    description: 'Caps Lock + Q + enter',
    sequence: '',
    group: 'reader',
    action: 'keydown',
  },
}

export const useWCAGKeyboardShortcuts = () => {
  const history: History = useHistory()
  const actions = useActions({
    openSearch,
    navigateToPlpAndOpenFilters,
    navigateToFavourites,
    zoomIn,
    zoomOut,
  })

  return {
    wcagKeyboardShortcuts: keyMap,
    wcagKeyboardShortcutHandlers: {
      OPEN_SEARCH: () => {
        actions.openSearch()
      },
      NAVIGATE_TO_PLP_AND_OPEN_FILTERS: () => {
        actions.navigateToPlpAndOpenFilters(history)
      },
      GO_TO_FAVORITES: () => {
        actions.navigateToFavourites(history)
      },
      ZOOM_IN: () => {
        actions.zoomIn()
      },
      ZOOM_OUT: () => {
        actions.zoomOut()
      },
    },
  }
}
