import { IconProps } from '@abstractTypes/icons'

// Used only in stories
export const RotateDeviceIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      className={className}
    >
      <path
        fill={fill}
        d="M153.87 332.34H53.98c-4.36 0-4.52-.16-4.52-4.5V195.15c0-4.06.07-4.14 4-4.14h200.02c3.07 0 3.46.35 3.46 3.45v91.45c0 14.33-.05 28.66 0 43 0 2.46-.83 3.47-3.29 3.47h-99.69c-.26-.04-.51-.07-.09-.04Z"
      />
      <path
        fill={fill}
        d="M31.98 292.36c.75 5.22.22 10.46.31 15.69.16 8.64.14 17.28.19 25.93-1.71-.46-1.26-1.93-1.28-3-.09-5.23 0-10.47 0-15.71a5.26 5.26 0 0 0-.68-3.31 6.19 6.19 0 0 0-.77 3.76v15.21c0 1.13.2 2.36-.82 3.25-.72-1.23-.51-2.59-.51-3.9 0-45.48.1-91-.1-136.45a20.885 20.885 0 0 1 6.122-15.142 20.889 20.889 0 0 1 15.148-6.108c34.9.09 69.8 0 104.71 0h64.73c12.4 0 24.81-.05 37.21 0 9 .06 15.61 4.06 19.52 12.26a17.463 17.463 0 0 1 1.6 7.52v138.71c0 10.21-9 19.21-19.21 19.29-17.25.13-34.49 0-51.74.06-1.52 0-3.08-.16-3-2.08.08-1.92 1.7-1.91 3.17-1.91h50c9.91 0 16.89-6.83 16.9-16.72 0-45.4 0-90.81.09-136.21a16.918 16.918 0 0 0-10.536-15.754 16.908 16.908 0 0 0-6.544-1.246c-57.48.073-114.964.073-172.45 0H49.51c-10.14 0-17.19 6.91-17.2 17 0 26.5.06 53 0 79.48 0 6.31.35 12.63-.33 18.93-1.89-.82-1.24-2.48-1.08-3.8.298-2.579.385-5.177.26-7.77a2.995 2.995 0 0 0-1.44 2.88 175.308 175.308 0 0 0 .55 21.13 2.692 2.692 0 0 0 .93 2c.05-3.76 0-7.3 0-10.84.03-1.01-.22-2.24.78-3.15Z"
      />
      <path
        fill={fill}
        d="M122.443 71.933a99.528 99.528 0 0 0-19.12-.009c-19.268 2.364-33.817 12.152-44.379 28.195-7.468 11.333-11.83 23.944-15.007 37.037-.425 1.585-.132 4.355-2.914 3.651-2.502-.671-1.23-2.908-.876-4.459 3.548-15.096 8.7-29.535 18.06-42.131 14.474-19.478 34.138-27.802 58.111-26.741a85.997 85.997 0 0 1 23.684 4.337c.547.779 1.461.692 2.224.897 1.516.406 1.673 1.483 1.344 2.71-.329 1.227-1.284.93-2.253.68-4.308-1.154-8.505-2.683-12.924-3.463a39.558 39.558 0 0 0-5.95-.704Z"
      />
      <path
        fill={fill}
        d="M28.85 334.15c0-6.32.026-12.653.08-19a8.232 8.232 0 0 1 .4-3.19c.23-.59 0-1.61.79-1.65.79-.04 1.43.82 1.68 1.62a7.24 7.24 0 0 1 .12 2v17.47c0 .87-.35 1.86.55 2.56 2.09 6.37 6.06 10.8 12.84 12.21a21.16 21.16 0 0 0 4.22.31h154.79c1.28 0 2.7.06 2.75 1.78.05 1.72-1.15 2.15-2.69 2.15-15.72 0-141.06.09-156.79-.07-9.24-.03-17.93-7.76-18.74-16.19Z"
      />
      <path
        fill={fill}
        d="M122.442 71.933c.391-.455.874-.325 1.368-.276a70.47 70.47 0 0 1 16.484 3.858c.831.295 2.171 1.4 2.757-.13.586-1.531-.236-2.518-1.904-2.706a1.222 1.222 0 0 1-1.116-.858c2.045.093 3.773 1.373 5.901 1.582.027-1.992-1.529-3.102-2.224-4.603-.695-1.501-1.647-2.895-2.457-4.354a1.892 1.892 0 0 1 .62-2.816 1.815 1.815 0 0 1 1.566-.273 1.82 1.82 0 0 1 1.184 1.062c2.723 4.674 5.47 9.334 8.12 14.05a2.14 2.14 0 0 1 .252 1.82 2.143 2.143 0 0 1-1.247 1.35l-13.626 7.852c-1.151.664-2.281.786-2.975-.487-.695-1.273-.123-2.248 1.029-2.913 2.743-1.584 5.522-3.107 8.241-4.718.505-.3 1.435-.485 1.281-1.303-.153-.817-.988-.792-1.611-.98a144.236 144.236 0 0 0-21.643-5.157Z"
      />
      <path
        fill={fill}
        d="M31.98 292.36v16a4.007 4.007 0 0 1-2.51-3.24c-.71-7.62-.58-15.27-.54-22.91 0-1.76 1.64-2.3 2.69-3.36a35.108 35.108 0 0 1 0 8.87c-.26 1.42-.67 2.93.33 4.29a.269.269 0 0 1 .03.35Z"
      />
      <path
        fill={fill}
        d="M43.51 261.77c0 1.34.05 2.67 0 4a1.619 1.619 0 0 1-1.8 1.66c-1 0-1.83-.5-1.86-1.61-.06-2.74-.06-5.487 0-8.24a1.79 1.79 0 0 1 2-1.84c1.16 0 1.73.88 1.74 2-.07 1.37-.08 2.7-.08 4.03Z"
      />
      <path
        fill={fill}
        d="M49.98 275.18c.58.31.73.38.86.47 1.69 1.16-.75 3.68.78 4.08 2.76.73 1.08 2.53 1.26 3.69.184 2.151.217 4.313.1 6.47a2.08 2.08 0 0 1-1.23 2.21c-1.11.5-1.77.38-1.77-1v-15.92Z"
      />
      <path
        fill={fill}
        d="M318.571 195.05v-99.2c0-4.43 0-4.43-4.4-4.43h-132.68c-4.08 0-4.1 0-4.1 4v78.4a1.692 1.692 0 0 0 1.81 1.68 1.622 1.622 0 0 0 1.85-1.6c.057-.749.074-1.5.05-2.25 0-20.65 0-52.75-.06-73.4 0-2.41.77-2.85 3-2.84 38.807.06 77.62.06 116.44 0 4.07 0 8.14.07 12.21 0 1.68-.05 2.11.56 2.11 2.15-.066 30.4-.11 60.8-.13 91.2 0 14.83.09 29.65.1 44.47v58.44c0 3.18 0 3.18-3.3 3.18h-34.44c-1.5 0-3.14.05-3.14 1.94s1.55 2.07 3.1 2.07h38.19c2.94 0 3.38-.48 3.38-3.29.007-33.527.01-67.033.01-100.52Z"
      />
      <path
        fill={fill}
        d="M332.911 276.94v22.22c0 9.87-7 16.84-16.8 16.86h-38.44a9.603 9.603 0 0 0-2.46.24 1.773 1.773 0 0 0-1.31 1.9 1.724 1.724 0 0 0 1.55 1.69c.662.096 1.332.126 2 .09h39.44a20.05 20.05 0 0 0 19.95-19.82c.08-15.65 0-31.3 0-46.94 0-.9.17-1.86-.55-2.61a1.873 1.873 0 0 0-2.05-.55c-1.14.39-1.32 1.38-1.32 2.43v17.48l-.01 7.01Z"
      />
      <path
        fill={fill}
        d="M247.641 70.95h-68.25a20.001 20.001 0 0 0-20.36 20.16c-.07 23 0 57.91 0 80.9a6.798 6.798 0 0 0 .19 2 1.778 1.778 0 0 0 1.82 1.41 1.857 1.857 0 0 0 1.89-1.66c.088-.743.112-1.493.07-2.24V90.85c0-8.93 7.62-16.05 16.63-16 19.81.12 39.63 0 59.45.05h77.2c9.6 0 16.57 6.86 16.6 16.48.05 16.82 0 151.58 0 168.4v2.5a1.696 1.696 0 0 0 1.044 1.65c.214.089.444.133.676.13a1.84 1.84 0 0 0 2.06-1.45c.143-.734.19-1.484.14-2.23 0-16.74-.2-151.42.07-168.15a21.007 21.007 0 0 0-6.122-15.237 21 21 0 0 0-15.218-6.173c-22.58.42-45.24.13-67.89.13Z"
      />
    </svg>
  )
}
