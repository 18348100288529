import { Device } from '@abstractTypes/utils'
import { ArrowButton } from '@components/Prizm/PrizmPlaylistContent/styles'
import { Progress } from '@components/Progress'
import { ProgressBar } from '@components/ProgressBar'
import { ScrollableContainer } from '@components/Tabs/styles'
import { Label } from '@components/core/Typography'
import { altNavigation, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem, withLineClamp } from '@libs/styled'
import styled, { css } from 'styled-components'

export const SingleImageContainer = styled.div<{ isEditorial?: boolean }>`
  position: relative;
  block-size: 100%;

  margin-block: 0;
  margin-inline: auto;
  ${tablet(`
    inline-size: ${clampValues(560, 748)};
  `)}
  ${({ isEditorial }) => `
    ${landscape(`
      inline-size: ${isEditorial ? pxToRem(1050) : '100%'};
    `)}
  `}  ${landscape(
    Device.tablet,
    `
      inline-size: 100%;
    `
  )}

  img {
    inline-size: 100%;
    block-size: 100%;
    background-size: contain;
    object-fit: contain;
  }
`

export const MultipleImagesContainer = styled.div<{ isAfa?: boolean }>`
  position: relative;
  block-size: 100%;
  img {
    inline-size: 100%;
    block-size: 100%;
    pointer-events: none;
    background-size: contain;
    object-fit: contain;
  }
`

export const MainImagesCarousel = styled.div<{ isEditorial?: boolean }>`
  position: relative;
  block-size: 100%;
  min-block-size: 100%;
  inline-size: 100%;
  margin-block: 0;
  margin-inline: auto;
  ${tablet(`
    inline-size: ${clampValues(560, 748)};
  `)}
  ${({ isEditorial }) => `
    ${landscape(`
      inline-size: ${isEditorial ? pxToRem(1050) : '100%'};
    `)}
  `}
  ${landscape(
    Device.tablet,
    `
    inline-size: 100%;
  `
  )}
`

export const ImageWrapper = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: relative;
  inline-size: 100%;
  block-size: 100%;

  img {
    inline-size: 100%;
    block-size: 100%;
  }
`

export const OverImageLayerContainer = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;

  img {
    display: block;
  }

  p {
    word-break: break-all;
  }
`

export const MessageLabel = styled(Label)`
  ${withLineClamp};
  line-clamp: 4;
  -webkit-line-clamp: 4;
  ${tablet(Device.ipadMini, 'display: block')}
`

export const MessageBox = styled.div`
  white-space: normal;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  padding: ${pxToRem(20)};
  border-radius: ${pxToRem(12)};
`

export const MessageBoxZoomed = styled(MessageBox)`
  position: absolute;
  inset-block-end: ${pxToRem(80)};
  z-index: 1;

  ${zoomPortrait(
    [200],
    `
    inset-block-end: 0;
  `
  )}
`

export const MessageBoxCloseBtn = styled.button`
  position: absolute;
  inset-block-start: ${pxToRem(5)};
  inset-inline-end: ${pxToRem(5)};
`

export const StyledScrollableContainer = styled(ScrollableContainer)`
  margin-block-start: 0.91rem;
  ${tablet(`
    margin-block-start: 0.5rem;
  `)}
`

export const ThumbImage = styled.div<{ isActive: boolean }>`
  position: relative;
  margin-inline-end: 1.85vw;
  display: inline-block;
  inline-size: calc((100% - 5.55vw) / 4);
  block-size: 5.18rem;
  border: 3px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.primaryDark : 'transparent')};
  ${tablet(`
    block-size: 3.636rem;
  `)}
  ${landscape(`
    block-size: 9.41rem;
  `)}
  ${landscape(
    Device.tablet,
    `
    block-size: 5rem;
  `
  )}

  :last-child {
    margin-inline-end: 0;
  }

  img {
    inset-block-start: 0 !important;
    inline-size: 100%;
  }
`

export const PDPProgressBar = styled(ProgressBar)`
  flex-grow: 1;
  z-index: auto;
  ${Progress} {
    block-size: ${pxToRem(4)};
    background-color: ${({ theme }) => theme.colors.primaryDark};
    opacity: 0.2;
    margin: 0;
    background-image: none;
    border-radius: 0;
    &.active {
      opacity: 1;
    }
  }
`

export const ProgressActionsContainer = styled.div`
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  display: flex;
  align-items: center;
  margin: auto;
  padding-block-end: ${pxToRem(4)};
  inline-size: ${pxToRem(575)};
  ${tablet(
    `
    inline-size: ${clampValues(400, 575)};
  `
  )}
`

export const MultiImagesActions = styled.div`
  margin-inline-start: ${pxToRem(24)};

  > button {
    position: static;
    margin-block-start: ${pxToRem(-3)};

    ${zoomPortrait(
      [150, 175],
      `
      inline-size: ${pxToRem(84)};
      block-size: ${pxToRem(84)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: ${pxToRem(112)};
      block-size: ${pxToRem(112)};
    `
    )}
  }
`

const position1 = css`
  inline-size: ${pxToRem(340)};
  inset-block-end: 50%;
  inset-inline-end: 20%;

  ${landscape(`
    inline-size: ${pxToRem(347)};
    inset-block-end: 55%;
  `)}

  ${altNavigation(`
      inset-inline-end: 25%;
  `)}
`

const position2 = css`
  inline-size: ${pxToRem(318)};
  inset-block-start: 55%;
  inset-inline-start: 60%;
  ${landscape(`
      inline-size: ${pxToRem(396)};
  `)}

  ${tablet(`
    inline-size: ${clampValues(232, 318)};
  `)}
  ${tablet(
    Device.ipadMini,
    `
    flex-direction: row;
  `
  )}

  ${landscape(
    Device.tablet,
    `
      inline-size: ${clampValuesLandscape(244, 298)};
    `
  )}
    ${altNavigation(`
      inset-inline-start: 55%;
  `)}
`

const position3 = css`
  inline-size: ${pxToRem(320)};
  inset-block-start: 45%;
  inset-inline-end: 65%;
  ${landscape(`
      inline-size: ${pxToRem(378)};
  `)}

  ${tablet(`
    inline-size: ${clampValues(252, 323)};
  `)}

  ${landscape(
    Device.tablet,
    `
      inline-size: ${clampValuesLandscape(209, 280)};
    `
  )}
`

export const MessageTooltipBox = styled.div<{ position: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;

  ${({ position }) => {
    switch (position) {
      case 1:
        return position1
      case 2:
        return position2
      case 3:
        return position3
    }
  }}
`

const position1Button = css`
  order: 1;
  align-self: flex-end;
  margin-block-start: ${pxToRem(8)};
`

const position2Button = css`
  order: -1;
  align-self: flex-start;
  margin-block-end: ${pxToRem(8)};
  ${tablet(
    Device.ipadMini,
    `
    margin-block-end: 0;
    margin-inline-end: ${pxToRem(8)};
  `
  )}
`

const position3Button = css`
  order: -1;
  align-self: flex-end;
  margin-block-end: ${pxToRem(8)};
`

export const MessageCloseButton = styled.button<{ position: number }>`
  background-color: ${({ theme }) => theme.colors.highlight04};
  border-radius: 50%;
  inline-size: ${pxToRem(36)};
  block-size: ${pxToRem(36)};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ position }) => {
    switch (position) {
      case 1:
        return position1Button
      case 2:
        return position2Button
      case 3:
        return position3Button
    }
  }}
`

export const MessageOpenButton = styled.button<{ position: number; selected?: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.primaryLight : theme.colors.highlight04};
  border-radius: 50%;
  inline-size: ${pxToRem(26)};
  block-size: ${pxToRem(26)};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: ${({ selected, theme }) =>
    selected ? `${pxToRem(9)} solid ${theme.colors.highlight04}` : `${pxToRem(9)} solid white`};
  /* to count outline in element size */
  margin: ${pxToRem(6)};
  position: relative;

  ${zoomPortrait(
    [150, 175, 200],
    `
    inline-size: ${pxToRem(50)};
    block-size: ${pxToRem(50)};
    border-inline-size: ${pxToRem(15)};
  `
  )}

  /* we simulate a outline to prevent Safari issues */
  ::before {
    content: '';
    position: absolute;
    inset-block-start: ${pxToRem(-15)};
    inset-inline-end: ${pxToRem(-15)};
    inset-block-end: ${pxToRem(-15)};
    inset-inline-start: ${pxToRem(-15)};
    border: ${pxToRem(1)} solid white;
    border-radius: 50%;
  }

  transform: scale(1);
  animation: pulse 2s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  ${({ position }) => {
    switch (position) {
      case 1:
        return position1Button
      case 2:
        return position2Button
      case 3:
        return position3Button
    }
  }};
`

export const ArrowButtonStyled = styled(ArrowButton)<{ direction: 'prev' | 'next' }>`
  inset-inline-start: ${({ direction }) => (direction === 'prev' ? 0 : 'unset')};
  inset-inline-end: ${({ direction }) => (direction === 'next' ? 0 : 'unset')};
  position: absolute;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 50%;
  inline-size: ${pxToRem(68)};
  block-size: ${pxToRem(68)};
  margin: 0;
  inset-block-start: 50%;
  transform: translateY(-50%);
  ${zoomPortrait(
    [200],
    `
      inline-size: ${pxToRem(80)};
      block-size: ${pxToRem(80)};
    `
  )}
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  &:focus {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  }
  ${landscape(Device.tower, 'margin: 0;')}
`
