import { Device } from '@abstractTypes/utils'
import { ProfileIcon } from '@components/core/Icons'
import { Image } from '@components/Image'
import { altNavigation, landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

// fa profile
const mediumSize = css`
  inline-size: ${pxToRem(173)};
  block-size: ${pxToRem(173)};
  ${landscape(`
    inline-size: ${pxToRem(152)};
    block-size: ${pxToRem(152)};
  `)}
  ${tablet(`
    inline-size: ${clampValues(90, 110)};
    block-size: ${clampValues(90, 110)};
  `)}
  ${altNavigation(`
    inline-size: ${pxToRem(111)};
    block-size: ${pxToRem(111)};
  `)}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${clampValuesLandscape(90, 110)};
    block-size: ${clampValuesLandscape(90, 110)};
  `
  )}
`
// fa plp
const smallSize = css`
  inline-size: ${pxToRem(98)};
  block-size: ${pxToRem(98)};
  ${tablet(`
  inline-size: ${clampValues(63, 78)};
  block-size: ${clampValues(63, 78)};
`)}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${clampValuesLandscape(59, 78)};
    block-size: ${clampValuesLandscape(59, 78)};
  `
  )}
`

export const ProfileAvatarImage = styled(Image)<{ size: 'small' | 'medium' }>`
  border-radius: 50%;
  ${({ size }) => {
    switch (size) {
      case 'medium':
        return mediumSize
      case 'small':
        return smallSize
    }
  }}
`

export const ProfileAvatar: React.FC<{ src?: string; size?: 'small' | 'medium' }> = ({
  src,
  size = 'medium',
}) => {
  return (
    <ProfileAvatarImage
      src={src}
      alt=""
      fallbackImage={<ProfileIcon width="44%" height="46%" />}
      size={size}
    />
  )
}
