import {
  PreviewContainer,
  PreviewStyled,
} from '@components/CardProductBase/ProductCard/ProductPreview/ProductPreview.style'

export const ProductPreview: React.FC<{
  backgroundColor?: string
  isSquared: boolean
  isDressedOnView?: boolean
}> = ({ backgroundColor, children, isSquared, isDressedOnView = false }) => {
  return (
    <PreviewContainer isSquared={isSquared} isDressedOnView={isDressedOnView}>
      <PreviewStyled backgroundColor={backgroundColor}>{children}</PreviewStyled>
    </PreviewContainer>
  )
}
