import { IconProps } from '@abstractTypes/icons'
export const ProTipIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <g clipPath="url(#clip0_3992_97223)">
        <path
          d="M11.8364 31.0157L15.1999 31.9835C16.5754 27.2025 20.3484 18.9565 30.1589 17.455L29.6287 13.9952C21.0554 15.306 14.5717 21.5097 11.8364 31.0157Z"
          fill="#333333"
        />
        <path
          d="M42.5646 3.50049C36.7109 3.50049 30.7241 3.91699 24.9421 4.69399C-5.63913 9.40849 -6.63488 58.8547 13.9854 75.4027C18.3954 78.908 23.5211 80.5005 28.9619 80.5005C36.2471 80.5005 44.0976 77.6462 51.5719 72.7165C51.5701 72.622 51.5579 72.531 51.5579 72.4382C51.5579 71.656 51.5684 70.3207 52.2369 67.9775C44.3811 73.77 36.1859 77.0005 28.9619 77.0005C24.0899 77.0005 19.7849 75.541 16.1746 72.6745C5.60637 64.1922 0.718625 44.8792 5.04987 28.7075C6.86637 21.9262 11.9344 10.2415 25.4094 8.16249C31.1459 7.39249 36.9174 7.00049 42.5646 7.00049C59.9071 7.00049 73.2369 10.742 78.2209 17.0105C80.3699 19.7125 80.9911 22.8922 80.1161 26.7265C78.3924 33.2872 75.6641 39.7062 72.1099 45.7035C73.2876 45.9205 74.4146 46.3125 75.4419 46.8917C78.9366 40.8892 81.7331 34.3705 83.5164 27.563C87.5064 10.2835 66.1651 3.50049 42.5646 3.50049Z"
          fill="#333333"
        />
        <path
          d="M70.1204 52.5005C68.8656 52.5005 67.6126 53.1042 66.8566 54.31C63.4371 59.77 58.5581 68.2872 58.5581 72.4365C58.5581 78.8222 63.7346 84.0005 70.1221 84.0005C76.5079 84.0005 81.6861 78.824 81.6861 72.4365C81.6861 68.2872 76.8071 59.77 73.3876 54.31C72.6281 53.1042 71.3734 52.5005 70.1204 52.5005ZM70.1204 80.5005V56.0005C70.3129 56.0005 70.3863 56.1177 70.4178 56.1685C76.1438 65.3105 78.1826 70.5657 78.1826 72.4365C78.1826 76.8832 74.5654 80.5005 70.1204 80.5005Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_3992_97223">
          <rect width={84} height={84} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
