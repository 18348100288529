import { AppConfig } from '@abstractTypes/envs'
import { Maybe, ProductThumbnail, ProductThumbnailGroup } from '@abstractTypes/graphqlTypes'
import { ImageOptions, ProductBaseInfo } from '@abstractTypes/image'
import config from '@config/index'
import productCard from '@theme/productCard'
import { isRBBrand } from './custom'

/**
 * Takes in input a string and formats it in order that it can be used into a url.
 */
const formatUrlElement = (el: string): string => {
  const urlRegexp = /( |\/|%20)/g
  const subst = '_'
  return el.replace(urlRegexp, subst)
}

export const getImage = (
  baseURL: string,
  { brand, materialCode, moco }: ProductBaseInfo,
  imgWidth: number,
  angle = '',
  options: ImageOptions = {}
) => {
  let extra = Object.keys(options)
    .map(k => `${k}=${options[k]}`)
    .join('&')
  if (extra) {
    extra = `&${extra}`
  }

  const angle_slug = angle ? `_${angle}` : ''
  return `${baseURL}/${brand.toUpperCase()}/${formatUrlElement(materialCode)}/${formatUrlElement(
    moco
  )}${angle_slug}.png?imwidth=${imgWidth}${extra}`
}

export const getCaseThumbnailUrlForCustom = (
  recipeId: Maybe<number> | string,
  brand: string,
  scale = 5,
  backgroundColor = productCard.backgroundColor
): string => {
  const url = 'https://cdn-prod.fluidconfigure.com/imagecomposer'
  const caseType = isRBBrand(brand) ? 'CC' : 'Microbag'
  const format: 'jpg' | 'png' = 'png'

  let thumbnailUrl = `${url}/recipe/${Number(recipeId)}/image/${caseType}.${format}?scale=.${scale}`
  if (backgroundColor) {
    const postCompose = `[{"background":"${backgroundColor}"}]`
    thumbnailUrl = `${thumbnailUrl}&postCompose=${encodeURIComponent(postCompose)}`
  }
  return thumbnailUrl
}

export const getImageFullUrl = (productImageUrl: string, imgWidth: number) => {
  const baseUrl = config.productImageUrl
  return `${baseUrl}${productImageUrl}?imwidth=${imgWidth}`
}

export const getProductMainImage = (
  thumbnails: ProductThumbnailGroup,
  imgWidth: number,
  showShadImg: boolean
) => {
  const productThumbnail = getProductThumbnail(thumbnails, showShadImg)
  return getImageFullUrl(productThumbnail.main, imgWidth)
}

export const getProductImageGallery = (
  thumbnails: ProductThumbnailGroup,
  imgWidth: number,
  showShadImg: boolean
) => {
  const productThumbnail = getProductThumbnail(thumbnails, showShadImg)
  return productThumbnail.gallery.map(img => getImageFullUrl(img || '', imgWidth))
}

export const getProductThumbnail = (
  thumbnails: ProductThumbnailGroup,
  showShadImg: boolean
): ProductThumbnail => (showShadImg && thumbnails.shad ? thumbnails.shad : thumbnails.standard)

export const getDressedOnImage = (
  config: AppConfig,
  videoUUID: string,
  UPC: string,
  size = '480f'
) => {
  const url = `${config.dressedOnImageService}s/${size}/v/${videoUUID}/u/${UPC}/pi/7/np/0`

  return new Promise<string>(resolve => {
    const img = new Image()
    img.src = url
    img.onload = () => resolve(url)
    img.onerror = () => resolve('')
  })
}
