import { useMemo } from 'react'
import { useStoreContext } from './useStoreContext'

export const useRTRVisibility = () => {
  const store = useStoreContext()
  const isRTREnabled = store.rtrEnabled

  return useMemo(() => {
    return {
      isRTREnabled: isRTREnabled,
    }
  }, [isRTREnabled])
}
