import { Device } from '@abstractTypes/utils'
import { FrameAdvisorStepContent } from '@components/FrameAdvisor/Survey/FrameAdvisorStepContent'
import { Progress } from '@components/Progress'
import { altNavigation, landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const FrameAdvisorProgressContainer = styled.div`
  margin-block: 0;
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  inline-size: ${pxToRem(488)};
`

export const ProgressStyled = styled(Progress)<{ passed: boolean }>`
  margin-block: 0;
  margin-inline: ${pxToRem(7)};
  inline-size: ${pxToRem(100)};
  block-size: ${pxToRem(4)};
  opacity: ${({ passed }) => (passed ? '1' : '.2')};
`

export const FrameAdvisorSurveyContainer = styled.div`
  padding-block-start: ${pxToRem(245)};
  block-size: 100%;
  display: flex;
  flex-direction: column;

  ${tablet(`padding-block-start: ${clampValues(150, 245)}`)};
  ${landscape(Device.tablet, `padding-block-start: ${clampValuesLandscape(150, 245)}`)};
  padding-block-start: ${pxToRem(158)};
  padding-block-end: ${pxToRem(50)};
`

export const FrameAdvisorSurveyWrapper = styled.div<{ isAlternativeNavigation?: boolean }>`
  padding-block-start: ${pxToRem(380)};
  padding-block-end: ${pxToRem(961)};
  position: relative;
  inline-size: 100%;
  block-size: 100%;
  overflow: auto;
  text-align: center;
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 91%, rgba(0, 0, 0, 0) 100%);
  ${landscape(`
    padding-block-end: ${pxToRem(119)};
    padding-block-start: ${pxToRem(152)};
  `)};
  ${tablet(`
    padding-block-end: ${clampValues(354, 561)};
    padding-block-start: ${pxToRem(152)};
  `)};
  ${landscape(
    Device.tablet,
    `
    padding-block-end: ${pxToRem(219)};
    padding-block-start: ${pxToRem(65)};
  `
  )};
  ${altNavigation(`
    padding-block-end: ${pxToRem(150)};
    padding-block-start: ${pxToRem(120)};
  `)};
`

export const FrameAdvisorCurrentStepContent = styled(FrameAdvisorStepContent)`
  @keyframes slide-in {
    100% {
      opacity: 1;
    }
  }
  opacity: 0;
  animation: slide-in 1s forwards;
`
