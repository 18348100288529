import { ProductTypeValues } from '@abstractTypes/filter'
import { ContentV2ElectronicsTab, Scalars } from '@abstractTypes/graphqlTypes'
import { StandardProduct } from '@abstractTypes/product'

type Tabs = Array<ContentV2ElectronicsTab>

interface GetTabsProps {
  tabs: Tabs
  sortTabsEnabled?: boolean
  customTabs?: string[]
}

const getSortedTabs = (tabs: Tabs, sortTabsEnabled = false): Tabs => {
  if (!sortTabsEnabled) return tabs

  const defaultTab = tabs.find(tab => tab.isDefaultTab)
  if (!defaultTab) return tabs

  const notDefaultTabs = tabs.filter(tab => !tab.isDefaultTab)
  return [defaultTab, ...notDefaultTabs]
}

export const getElectronicsTabs = ({ tabs, sortTabsEnabled, customTabs }: GetTabsProps) => {
  if (!tabs.length) return tabs
  const sortedTabs = getSortedTabs(tabs, sortTabsEnabled)

  if (!customTabs?.length) return sortedTabs.filter(tab => Boolean(tab.content?.length))

  return sortedTabs.filter(tab => {
    const isCustomTab = !tab.content?.length
    return isCustomTab ? customTabs.includes(tab.type) : true
  })
}

export const isElectronicsProduct = (product: StandardProduct) => {
  const electronics: Scalars['ContentV2ProductType']['output'] = 'ELECTRONICS'
  return product.productType === electronics
}

export const isNuanceProductType = (product: StandardProduct) => {
  return product.productType === ProductTypeValues.NUANCE
}
