import { IconProps } from '@abstractTypes/icons'
export const XlSizeIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 61 34.5"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M 1.1344 0.4419 H 8.1374 L 15.7514 12.2859 L 23.6945 0.4419 H 30.2744 L 19.1824 16.6099 L 31.0734 33.9999 H 23.9294 L 15.5634 21.1689 L 7.0564 33.9999 H 0.3354 L 12.2264 16.6099 L 1.1344 0.4419 Z "
        fill={fill}
      />
      <path d="M37.525 0.4419H43.4V28.9239 H60.461 V 33.9999 H 37.525 V 0.4419Z" fill={fill} />
    </svg>
  )
}
