import { IconProps } from '@abstractTypes/icons'


export const EngravingBlackIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#333',
}: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 29">
      <path
        fill={fill}
        d="M7.887 28.683a.635.635 0 00.436-.308l6.985-11.746a.635.635 0 00.05-.556l-2.63-6.676a.635.635 0 10-1.18.466l2.51 6.389-5.645 9.494V17.81a2.863 2.863 0 002.222-2.778 2.867 2.867 0 00-2.857-2.857 2.867 2.867 0 00-2.857 2.857c0 1.352.955 2.486 2.222 2.778v7.936l-5.645-9.494L4.851 7.73h8.324c1.044 0 1.904-.86 1.904-1.905V1.381a.635.635 0 00-.635-.635H1.111a.635.635 0 00-.635.635v4.444c0 1.045.86 1.905 1.905 1.905h1.091L.198 16.073a.635.635 0 00.05.556l6.984 11.746a.635.635 0 00.655.308zm-.11-12.064a1.578 1.578 0 01-1.587-1.587 1.58 1.58 0 011.588-1.588c.884 0 1.587.704 1.587 1.588 0 .884-.703 1.587-1.587 1.587zM13.176 6.46H2.38a.62.62 0 01-.635-.635v-3.81H13.81v3.81a.62.62 0 01-.635.635z"
      ></path>
    </svg>
  )
}
