import { RTRActionTypes, SET_INITIALISED } from '@actions/rtr'

export interface RTRState {
  initialised: boolean
}

const initialState = {
  initialised: false,
}

export default (state = initialState, action: RTRActionTypes): RTRState => {
  switch (action.type) {
    case SET_INITIALISED: {
      const { initialised } = action.payload
      return {
        ...state,
        initialised,
      }
    }
    default:
      return state
  }
}
