import { ImportedLangLayout, LangLayout } from '@abstractTypes/keyboard'
import ReactDOMServer from 'react-dom/server'
import ChineseLayout from 'simple-keyboard-layouts/build/layouts/chinese'
import ArabicLayout from './layouts/ArabicLayout'
import CyrillicLayout from './layouts/CyrillicLayout'
import FrenchLayout from './layouts/FrenchLayout'
import GermanLayout from './layouts/GermanLayout'
import LatinLayout from './layouts/LatinLayout'
import NumericLayout from './layouts/NumericLayout'
import PortugueseLayout from './layouts/PortugueseLayout'
import SpanishLayout from './layouts/SpanishLayout'
import SymbolsLayout from './layouts/SymbolsLayout'

export const emailFieldNames = ['email', 'emailVerification']

const langLayoutMap: LangLayout = {
  ar: ArabicLayout,
  de: GermanLayout,
  fr: FrenchLayout,
  ru: CyrillicLayout,
  es: SpanishLayout,
  pt: PortugueseLayout,
  default: LatinLayout,
  symbols: SymbolsLayout,
}

const importedLangLayouts: ImportedLangLayout = {
  zh: ChineseLayout,
}

export const { DRAG_BUTTON, ALT_LANG_BUTTON, SYMBOLS_BUTTON } = {
  DRAG_BUTTON: '{drag}',
  ALT_LANG_BUTTON: '{secondary}',
  SYMBOLS_BUTTON: '{symbols}',
}

export const EMAIL_BUTTONS = {
  GMAIL_BUTTON: '{gmail}',
  OUTLOOK_BUTTON: '{outlook}',
  YAHOO_BUTTON: '{yahoo}',
  HOTMAIL_BUTTON: '{hotmail}',
}

export const domains = {
  [EMAIL_BUTTONS.GMAIL_BUTTON]: 'gmail.com',
  [EMAIL_BUTTONS.OUTLOOK_BUTTON]: 'outlook.com',
  [EMAIL_BUTTONS.YAHOO_BUTTON]: 'yahoo.com',
  [EMAIL_BUTTONS.HOTMAIL_BUTTON]: 'hotmail.com',
}
// could be '{gmail} {outlook} {yahoo} {hotmail}'
// '1 2 3 4 5 6 7 8 9 0 {bksp}',
// 'q w e r t y u i o p',
// 'a s d f g h j k l',
// '{shift} z x c v b n m {symbols}',
// '{dragg} {secondary} {space} {enter}'
const numbersRow = '1 2 3 4 5 6 7 8 9 0 {bksp}'
const generateLayout = ({
  rows,
  isUppercase = false,
  withAltLang = true,
  withEmailSuggestions = false,
  isEmail = false,
}: {
  rows: LangLayout[string]
  isUppercase?: boolean
  withAltLang?: boolean
  withEmailSuggestions?: boolean
  isEmail?: boolean
}) => {
  const [row1, row2, row3] = isUppercase
    ? rows.map(r => r.join(' ').toUpperCase())
    : rows.map(r => r.join(' '))

  const row4Buttons = [
    DRAG_BUTTON,
    withAltLang ? ALT_LANG_BUTTON : null,
    isEmail ? '@' : null,
    '{space}',
    isEmail ? '.' : null,
    '{enter}',
  ]
    .filter(Boolean)
    .join(' ')

  const basicLayout = [numbersRow, row1, row2, `{shift} ${row3} ${SYMBOLS_BUTTON}`, row4Buttons]

  if (withEmailSuggestions) {
    basicLayout.unshift(Object.values(EMAIL_BUTTONS).join(' '))
  }

  return basicLayout
}

const generateNumericLayouts = (rows: string[][]) => {
  const [row1, row2, row3, row4] = rows.map(r => r.join(' '))
  return [row1, row2, row3, row4]
}

export const getLangLayoutName = (lang: string) =>
  lang in langLayoutMap || lang in importedLangLayouts ? lang : 'default'

export const generateKeyboardLayout = ({
  langName,
  withAltLang,
  withEmailSuggestions,
  isEmail,
  isNumeric = false,
}: {
  langName: string
  withAltLang: boolean
  withEmailSuggestions: boolean
  isEmail: boolean
  isNumeric: boolean
}) => {
  const languageRows = langLayoutMap[langName] || langLayoutMap.default
  const symbolsRows = langLayoutMap.symbols
  const importedLangLayout = importedLangLayouts[langName] || {}

  const generateLayoutType = (rows: LangLayout[string], uppercase: boolean, isNumeric = false) =>
    isNumeric
      ? generateNumericLayouts(NumericLayout)
      : generateLayout({ rows, isUppercase: uppercase, withAltLang, withEmailSuggestions, isEmail })

  return {
    layout: {
      default: generateLayoutType(languageRows, false, isNumeric),
      shift: generateLayoutType(languageRows, true, isNumeric),
      symbols: generateLayoutType(symbolsRows, false, isNumeric),
    },
    layoutCandidates: importedLangLayout.layoutCandidates || {},
  }
}

export const getSymbolsButtonLabel = (lang: string) => {
  let symbolsValue

  switch (lang) {
    case 'ru':
      symbolsValue = 'Абв'
      break
    case 'ar':
      symbolsValue = 'ﺃﺏﺝ'
      break
    default:
      symbolsValue = 'Abc'
      break
  }

  return {
    symbols: '.?!&',
    letters: symbolsValue,
  }
}

export const getButtonIcon = (IconElement: JSX.Element) => {
  return ReactDOMServer.renderToString(IconElement)
}
