import { NuanceContent } from '@components/NuanceComponents'
import React from 'react'
import { useSendVirtualPageView } from './analytics'

const NuanceHomeContent: React.FC<{ onTabChange: () => void }> = ({ onTabChange }) => {
  useSendVirtualPageView()

  return (
    <NuanceContent
      isLandingPage
      analytics={{
        dataElementId: 'Nuance_ShopNow',
        dataCalls: '0',
        dataDescription: 'Nuance_ShopNow',
      }}
      onTabChange={onTabChange}
    />
  )
}

export default NuanceHomeContent
