import {
  FilterFacet,
  ProductHierarchies,
  ProductSubdivisions,
  ProductSubdivisionsList,
  ProductTypeValues,
} from '@abstractTypes/filter'
import config from '@config/config.base'
import { useStoreContext } from '@hooks/useStoreContext'
import { equals } from '@libs/caseInsensitive'
import { useCallback, useMemo } from 'react'
import {
  useFiltersState,
  useGetSortedProductTypeFilters,
  useProductTypeDefault,
} from './useFilters'
import { useProductSubdivisionDefault } from './useProductSubdivision'

const useProductTypesByFacet = (plpFacets: FilterFacet[]) => {
  const filters = useFiltersState(plpFacets)
  const productTypes = useMemo(
    () => filters.productType?.options?.map(option => option.value as ProductTypeValues) ?? [],
    [filters]
  )

  return productTypes
}

const useProductHierarchiesByFacet = (plpFacets: FilterFacet[]) => {
  const filters = useFiltersState(plpFacets)
  const hierarchies = useMemo(
    () =>
      filters.product_hierarchy1?.options?.map(option => option.value as ProductHierarchies) ?? [],
    [filters]
  )

  return hierarchies
}

const useGetInitialProductTypeTab = () => {
  const productTypeDefault = useProductTypeDefault()
  const getSortedProductTypeFilters = useGetSortedProductTypeFilters()

  return useCallback(
    (productTypes: ProductTypeValues[], selectedTab?: ProductTypeValues) => {
      const tabNames = getSortedProductTypeFilters(productTypes)
      const defaultTab: ProductTypeValues | undefined = selectedTab || productTypeDefault
      const initialTab = defaultTab && tabNames.includes(defaultTab) ? defaultTab : tabNames[0]

      return { initialTab, tabNames }
    },
    [getSortedProductTypeFilters, productTypeDefault]
  )
}

const useGetInitialProductHierarchyTab = () => {
  return useCallback((hierarchies: ProductHierarchies[], selectedTab?: ProductHierarchies) => {
    const initialTab =
      selectedTab && hierarchies.includes(selectedTab) ? selectedTab : hierarchies[0]
    return { initialTab, tabNames: hierarchies }
  }, [])
}

export const useProductSubdivisionsByFacets = (plpFacets: FilterFacet[]) => {
  const productTypesByFacet = useProductTypesByFacet(plpFacets)
  const productHierarchiesByFacet = useProductHierarchiesByFacet(plpFacets)

  switch (config.default.productSubdivision) {
    case 'productHierarchy':
      return productHierarchiesByFacet
    case 'productType':
    default:
      return productTypesByFacet
  }
}

export const useGetInitialProductSubdivisionTab = () => {
  const getInitialProductTypeTab = useGetInitialProductTypeTab()
  const getInitialProductHierarchyTab = useGetInitialProductHierarchyTab()

  const getInitialProductSubdivisionTab = useCallback(
    (productSubdivisions: ProductSubdivisionsList, selectedTab?: ProductSubdivisions) => {
      switch (config.default.productSubdivision) {
        case 'productHierarchy':
          return getInitialProductHierarchyTab(
            productSubdivisions as ProductHierarchies[],
            selectedTab as ProductHierarchies
          )
        case 'productType':
        default:
          return getInitialProductTypeTab(
            productSubdivisions as ProductTypeValues[],
            selectedTab as ProductTypeValues
          )
      }
    },
    [getInitialProductHierarchyTab, getInitialProductTypeTab]
  )

  return getInitialProductSubdivisionTab
}

const useSubdivisionSearchTabOrder = () => {
  const { subdivisionSearchTabOrder } = useStoreContext()
  return subdivisionSearchTabOrder.map(x => x.toLowerCase())
}

/**
 * Helper for the sorting and filtering of the search tabs.
 * The sorting is defined from Magento within {subdivisionSearchTabOrder},
 * and the filtering is based on the {plpFacets} from the Catalog API.
 * Finally, the default product subdivision (taken from REACT_APP_DEFAULT_PRODUCT_TYPE) is placed ahead.
 * @param plpFacets
 */
export const useGetSubdivisionsSearchTabsOrder = (plpFacets: FilterFacet[]) => {
  // Magento tabs order
  const subdivisionSearchTabOrder = useSubdivisionSearchTabOrder()

  const defaultProductSubdivision: ProductSubdivisions = useProductSubdivisionDefault()

  // Facets from catalog
  const productSubdivisions = useProductSubdivisionsByFacets(plpFacets)
  const getTabsFromFacets = useGetInitialProductSubdivisionTab()

  // Visibility from catalog
  const { tabNames: catalogFacets } = getTabsFromFacets(productSubdivisions)

  const sortedCatalogTabs = catalogFacets.sort((a, b) => {
    const indexA = subdivisionSearchTabOrder.indexOf(a.toLowerCase())
    const indexB = subdivisionSearchTabOrder.indexOf(b.toLowerCase())
    if (indexA > -1 && indexB > -1) {
      return indexA - indexB
    }
    if (indexA === -1) {
      return 1
    }
    if (indexB === -1) {
      return -1
    }
    return 0
  })

  // Final sorting by default product subdivision
  const processedTabs = sortedCatalogTabs.sort((a, b) => {
    if (equals(a, defaultProductSubdivision)) {
      return -1
    }
    if (equals(b, defaultProductSubdivision)) {
      return 1
    }

    return 0
  })

  return {
    tabNames: processedTabs,
    initialTab: processedTabs?.[0] ?? (defaultProductSubdivision as ProductSubdivisions),
  }
}
