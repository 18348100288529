import { Device } from '@abstractTypes/utils'
import { Text } from '@components/core/Typography'
import { landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import React from 'react'
import styled from 'styled-components'
import { FrameAdvisorModalTileProps } from './types'

const FrameAdvisorModalTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.inactive};
  border-radius: ${pxToRem(15)};
  padding-block: ${pxToRem(38)} ${pxToRem(49)};
  padding-inline: ${pxToRem(41)};
  inline-size: ${pxToRem(337)};

  :first-child {
    ${zoomPortrait(
      [150, 175],
      `
      margin-inline-end:  ${pxToRem(36)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      margin-block-end:  ${pxToRem(48)};
    `
    )}
    ${zoomLandscape(
      [200],
      `
      margin-block-end:  0;
      margin-inline-end:  ${pxToRem(48)};
    `
    )}
  }
  ${tablet(`
    inline-size: ${clampValues(224, 300)};
    padding: ${clampValues(18, 25)};
  `)}
  ${landscape(
    Device.tablet,
    `
    padding: ${clampValuesLandscape(18, 25)};
    inline-size: ${clampValuesLandscape(224, 300)};
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(485)};
    min-block-size: ${pxToRem(505)};
    padding: ${pxToRem(48)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(650)};
    min-block-size: ${pxToRem(670)};
    padding: ${pxToRem(64)};
  `
  )}
`

const IconContainer = styled.div`
  text-align: center;

  > svg {
    inline-size: ${pxToRem(58)};
    block-size: ${pxToRem(58)};
    ${zoomPortrait(
      [150, 175],
      `
      inline-size: ${pxToRem(87)};
      block-size: ${pxToRem(87)};
  
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: ${pxToRem(116)};
      block-size: ${pxToRem(116)};
    `
    )}
  }
`

const Title = styled(Text)`
  margin-block-start: ${pxToRem(40)};
  ${tablet(`
    margin-block-start: ${clampValues(15, 40)};
  `)}
  ${landscape(
    Device.tablet,
    `
    margin-block-start: ${clampValuesLandscape(15, 40)};
  `
  )};
`

const Description = styled(Text)`
  margin-block-start: ${pxToRem(16)};
  ${tablet(`
    margin-block-start: ${clampValues(12, 16)};
  `)}
  ${landscape(
    Device.tablet,
    `
    margin-block-start: ${clampValuesLandscape(12, 16)};
  `
  )};
`

export const FrameAdvisorModalTile: React.FC<FrameAdvisorModalTileProps> = ({
  icon,
  title,
  description,
  className,
}) => {
  return (
    <FrameAdvisorModalTileContainer className={className}>
      <IconContainer>{icon}</IconContainer>
      <Title weight="bold">{title}</Title>
      <Description>{description}</Description>
    </FrameAdvisorModalTileContainer>
  )
}
