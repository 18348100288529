import { IconProps } from '@abstractTypes/icons'
export const FitTipIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 270 92"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <g clipPath="url(#clip0_3992_97215)">
        <path
          d="M133.104 9.31865V27.4921H136.896V9.31865L145.035 17.4575L147.715 14.7768L135 2.0614L122.285 14.7768L124.965 17.4575L133.104 9.31865Z"
          fill="black"
        />
        <path
          d="M136.896 83.5823V65.4088H133.104V83.5823L124.965 75.4435L122.285 78.1242L135 90.8395L147.715 78.1242L145.035 75.4435L136.896 83.5823Z"
          fill="black"
        />
        <path
          d="M142.166 55.6623C142.394 55.6623 142.64 55.6243 142.868 55.5315C143.835 55.1523 144.309 54.0337 143.929 53.0669C143.531 52.0981 142.432 51.6241 141.465 52.0033C140.479 52.4014 140.005 53.501 140.403 54.4679C140.688 55.2281 141.408 55.6623 142.166 55.6623Z"
          fill="black"
        />
        <path
          d="M176.481 41.1212C176.841 41.1212 177.22 41.0075 177.542 40.7989C178.415 40.2112 178.642 39.0358 178.073 38.1637C177.486 37.2916 176.31 37.0641 175.438 37.6329C174.566 38.2225 174.32 39.3979 174.907 40.27C175.267 40.8198 175.874 41.1212 176.481 41.1212Z"
          fill="black"
        />
        <path
          d="M142.583 46.3936C142.546 45.3319 141.673 44.5167 140.631 44.5546C139.569 44.5925 138.754 45.4627 138.792 46.5263C138.83 47.55 139.664 48.3463 140.688 48.3463C140.707 48.3463 140.726 48.3463 140.763 48.3463C141.806 48.3065 142.621 47.4363 142.583 46.3936Z"
          fill="black"
        />
        <path
          d="M178.017 52.0432C177.031 51.7 175.95 52.2498 175.628 53.2376C175.287 54.2234 175.818 55.304 176.803 55.6453C177.012 55.7021 177.22 55.7401 177.41 55.7401C178.206 55.7401 178.946 55.2471 179.211 54.4509C179.552 53.4651 179.021 52.3844 178.017 52.0432Z"
          fill="black"
        />
        <path
          d="M159.911 63.5319C158.868 63.513 157.996 64.3471 157.996 65.4088C157.977 66.4515 158.812 67.3046 159.854 67.3236C159.873 67.3236 159.873 67.3236 159.892 67.3236C160.916 67.3236 161.769 66.4894 161.788 65.4467C161.788 64.404 160.954 63.5509 159.911 63.5319Z"
          fill="black"
        />
        <path
          d="M153.238 62.2427C152.271 61.8635 151.171 62.3375 150.792 63.3044C150.413 64.2921 150.887 65.3917 151.854 65.769C152.081 65.8656 152.328 65.9017 152.555 65.9017C153.314 65.9017 154.034 65.4467 154.319 64.6883C154.698 63.7196 154.224 62.6219 153.238 62.2427Z"
          fill="black"
        />
        <path
          d="M144.915 58.6577C144.195 59.399 144.214 60.5934 144.953 61.3309C145.314 61.6911 145.806 61.8807 146.28 61.8807C146.773 61.8807 147.266 61.674 147.626 61.3119C148.366 60.5536 148.347 59.3592 147.607 58.6198C146.849 57.8824 145.655 57.9013 144.915 58.6577Z"
          fill="black"
        />
        <path
          d="M147.74 33.0639C146.754 33.4431 146.281 34.5427 146.66 35.5096C146.944 36.2679 147.664 36.7248 148.423 36.7248C148.65 36.7248 148.878 36.685 149.105 36.6091V36.5921C150.072 36.2129 150.565 35.1133 150.186 34.1465C149.807 33.1606 148.707 32.6867 147.74 33.0639Z"
          fill="black"
        />
        <path
          d="M155.608 31.4355C154.546 31.5113 153.769 32.4213 153.864 33.4621C153.94 34.4669 154.774 35.2063 155.741 35.2063C155.798 35.2063 155.854 35.2063 155.892 35.2063C156.935 35.1323 157.712 34.2204 157.636 33.1607C157.561 32.1161 156.651 31.3407 155.608 31.4355Z"
          fill="black"
        />
        <path
          d="M163.4 31.4145C162.357 31.3406 161.447 32.116 161.352 33.1606C161.276 34.2033 162.054 35.1114 163.096 35.2062C163.153 35.2062 163.21 35.2062 163.248 35.2062C164.234 35.2062 165.068 34.4498 165.144 33.462C165.22 32.4212 164.442 31.4904 163.4 31.4145Z"
          fill="black"
        />
        <path
          d="M178.415 44.4598C177.353 44.4598 176.519 45.3111 176.519 46.3557C176.519 46.3936 176.519 46.4315 176.519 46.4694C176.519 46.4884 176.519 46.4884 176.519 46.5092C176.557 47.5311 177.391 48.3482 178.415 48.3482C178.434 48.3482 178.453 48.3482 178.472 48.3482C179.533 48.3084 180.349 47.4382 180.311 46.3955C180.311 46.3765 180.311 46.3765 180.311 46.3765C180.311 46.3765 180.311 46.3576 180.311 46.3367C180.311 45.294 179.458 44.4598 178.415 44.4598Z"
          fill="black"
        />
        <path
          d="M172.348 35.4736C172.727 34.4859 172.234 33.3863 171.248 33.028C170.281 32.6488 169.182 33.1417 168.822 34.1276C168.443 35.0944 168.935 36.194 169.921 36.5732C170.13 36.649 170.357 36.6869 170.585 36.6869C171.343 36.6869 172.064 36.2301 172.348 35.4736Z"
          fill="black"
        />
        <path
          d="M166.585 62.4134C165.599 62.7717 165.106 63.8542 165.466 64.84C165.751 65.5965 166.471 66.0704 167.248 66.0704C167.457 66.0704 167.684 66.0325 167.912 65.9567C168.879 65.5965 169.391 64.495 169.012 63.5111C168.651 62.5252 167.552 62.0342 166.585 62.4134Z"
          fill="black"
        />
        <path
          d="M172.178 58.8492C171.438 59.5886 171.438 60.783 172.178 61.5224C172.557 61.8807 173.031 62.0721 173.524 62.0721C174.017 62.0721 174.49 61.8807 174.87 61.5224C175.609 60.7811 175.609 59.5696 174.851 58.8303C174.111 58.0909 172.917 58.108 172.178 58.8492Z"
          fill="black"
        />
        <path
          d="M123.72 61.8636C124.194 61.8636 124.687 61.6929 125.047 61.3327C125.805 60.5934 125.805 59.399 125.085 58.6407C124.345 57.9013 123.151 57.8842 122.393 58.6217C121.653 59.3611 121.634 60.5554 122.374 61.2948C122.734 61.674 123.227 61.8636 123.72 61.8636Z"
          fill="black"
        />
        <path
          d="M95.1498 58.8492C94.3914 59.5885 94.3914 60.7829 95.1308 61.5223C95.51 61.8996 95.9839 62.0721 96.4768 62.0721C96.9698 62.0721 97.4437 61.8996 97.8229 61.5412C98.5623 60.7829 98.5623 59.5885 97.8229 58.8492C97.0835 58.1079 95.8891 58.1079 95.1498 58.8492Z"
          fill="black"
        />
        <path
          d="M91.965 52.0621C90.9791 52.3844 90.4483 53.4651 90.7896 54.4698C91.055 55.2471 91.7943 55.7401 92.5906 55.7401C92.7802 55.7401 92.9887 55.7211 93.1973 55.6453C94.1831 55.304 94.7139 54.2423 94.3727 53.2376C94.0504 52.2517 92.9698 51.7209 91.965 52.0621Z"
          fill="black"
        />
        <path
          d="M92.4199 40.8938C92.7422 41.1024 93.1024 41.1971 93.4626 41.1971C94.0693 41.1971 94.676 40.8938 95.0551 40.3459C95.6239 39.4719 95.3585 38.2984 94.4864 37.7278C93.6143 37.1401 92.4389 37.4055 91.8701 38.2776C91.3014 39.1496 91.5479 40.327 92.4199 40.8938Z"
          fill="black"
        />
        <path
          d="M128.535 52.0053C127.568 51.6261 126.469 52.1 126.071 53.0688C125.691 54.0357 126.165 55.1353 127.132 55.5334C127.36 55.6282 127.606 55.6642 127.834 55.6642C128.592 55.6642 129.312 55.2111 129.597 54.4698C129.995 53.503 129.521 52.3844 128.535 52.0053Z"
          fill="black"
        />
        <path
          d="M91.5856 48.3653C92.5904 48.3653 93.4246 47.569 93.4814 46.5453C93.5194 45.5026 92.7231 44.6115 91.6804 44.5736C90.6187 44.5167 89.7467 45.3319 89.6898 46.3746C89.6519 47.4174 90.4481 48.3084 91.4908 48.3463C91.5287 48.3653 91.5477 48.3653 91.5856 48.3653Z"
          fill="black"
        />
        <path
          d="M131.208 46.5263C131.208 46.5073 131.208 46.5073 131.208 46.5073C131.208 46.4675 131.208 46.4106 131.208 46.3746C131.208 46.3557 131.208 46.3557 131.208 46.3348C131.208 45.2921 130.355 44.439 129.294 44.439C128.251 44.4598 127.417 45.3111 127.417 46.3557C127.417 46.3746 127.417 46.3746 127.417 46.3746C127.379 47.4363 128.194 48.3084 129.256 48.3463C129.275 48.3463 129.294 48.3463 129.313 48.3463C130.336 48.3463 131.171 47.5292 131.208 46.5263Z"
          fill="black"
        />
        <path
          d="M106.487 31.4355C105.444 31.5113 104.667 32.4213 104.743 33.4621C104.838 34.4669 105.653 35.2063 106.639 35.2063C106.696 35.2063 106.734 35.2063 106.79 35.2063C107.833 35.1323 108.61 34.2034 108.535 33.1796C108.44 32.1161 107.53 31.3407 106.487 31.4355Z"
          fill="black"
        />
        <path
          d="M103.415 62.4133C102.449 62.0341 101.349 62.546 100.989 63.5129C100.629 64.4987 101.122 65.5983 102.107 65.9585C102.316 66.0343 102.543 66.0723 102.752 66.0723C103.529 66.0723 104.25 65.5983 104.534 64.8419C104.894 63.8712 104.401 62.7716 103.415 62.4133Z"
          fill="black"
        />
        <path
          d="M101.084 34.1464C100.705 33.1796 99.6049 32.6866 98.6381 33.0658C97.6522 33.445 97.1593 34.5446 97.5385 35.5114C97.8418 36.2698 98.5622 36.7267 99.3206 36.7267C99.5481 36.7267 99.7756 36.6869 100.003 36.611V36.5921C100.97 36.2129 101.463 35.1323 101.084 34.1464Z"
          fill="black"
        />
        <path
          d="M123.246 35.4547C123.625 34.4859 123.132 33.3863 122.147 33.028C122.147 33.009 122.147 33.009 122.147 33.009C121.18 32.6488 120.08 33.1398 119.701 34.1276C119.341 35.0945 119.834 36.194 120.801 36.5732C121.028 36.649 121.256 36.687 121.483 36.687C122.241 36.687 122.962 36.213 123.246 35.4547Z"
          fill="black"
        />
        <path
          d="M110.089 63.5319V63.5509C109.046 63.5509 108.212 64.423 108.231 65.4676C108.25 66.5103 109.103 67.3426 110.146 67.3236C111.188 67.3046 112.041 66.4515 112.022 65.4088C112.003 64.3661 111.15 63.513 110.089 63.5319Z"
          fill="black"
        />
        <path
          d="M114.298 31.4145C113.236 31.3406 112.326 32.116 112.25 33.1606C112.174 34.2033 112.952 35.1114 113.994 35.1873C114.051 35.2062 114.108 35.2062 114.146 35.2062C115.132 35.2062 115.966 34.4498 116.042 33.462C116.118 32.4023 115.321 31.4904 114.298 31.4145Z"
          fill="black"
        />
        <path
          d="M116.762 62.2427C115.776 62.6219 115.303 63.7196 115.682 64.7073C115.985 65.4467 116.686 65.9017 117.445 65.9017C117.672 65.9017 117.919 65.8637 118.146 65.769C119.113 65.3917 119.606 64.2921 119.208 63.3044C118.829 62.3375 117.729 61.8635 116.762 62.2427Z"
          fill="black"
        />
        <path
          d="M135 35.0754C131.578 35.0754 128.239 36.2717 125.6 38.4405C124.791 39.106 124.675 40.2985 125.339 41.108C126.004 41.9194 127.199 42.035 128.006 41.3696C131.934 38.141 138.066 38.141 141.994 41.3696C142.346 41.6597 142.773 41.8019 143.196 41.8019C143.744 41.8019 144.286 41.5668 144.661 41.1099C145.327 40.3004 145.209 39.106 144.4 38.4424C141.761 36.2717 138.422 35.0754 135 35.0754Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip1_3992_97215)">
        <path
          d="M60.7174 43.761C60.9668 43.761 61.2293 43.6822 61.4524 43.5379C62.0561 43.131 62.2136 42.3172 61.8068 41.7135C61.413 41.1097 60.5993 40.9522 59.9955 41.346C59.3918 41.7542 59.2211 42.5679 59.628 43.1717C59.8774 43.5523 60.2974 43.761 60.7174 43.761Z"
          fill="black"
        />
        <path
          d="M38.8645 55.9017C38.3657 56.4149 38.3657 57.2418 38.8907 57.7523C39.1401 58.0017 39.4814 58.133 39.8095 58.133C40.1507 58.133 40.492 57.9899 40.7545 57.7392C41.2532 57.2142 41.2401 56.3873 40.7282 55.8755C40.2032 55.3649 39.3764 55.378 38.8645 55.9017Z"
          fill="black"
        />
        <path
          d="M46.2671 37.0555C45.5321 37.108 44.994 37.738 45.0596 38.4585C45.1121 39.1542 45.6896 39.666 46.3721 39.666C46.3984 39.666 46.4377 39.666 46.464 39.666C47.1859 39.6149 47.724 38.9717 47.6715 38.263C47.619 37.5411 46.989 36.9899 46.2671 37.0555Z"
          fill="black"
        />
        <path
          d="M40.8199 38.1829C40.1374 38.4454 39.8092 39.2067 40.0717 39.876C40.2686 40.401 40.7674 40.7174 41.2924 40.7174C41.4499 40.7174 41.6074 40.6898 41.7649 40.6255C42.4342 40.363 42.7755 39.6149 42.513 38.9324C42.2505 38.263 41.4892 37.9217 40.8199 38.1829Z"
          fill="black"
        />
        <path
          d="M49.2463 59.2761C48.5244 59.263 47.9206 59.8405 47.9206 60.5755C47.9075 61.2974 48.485 61.888 49.2069 61.9011C49.22 61.9011 49.22 61.9011 49.2331 61.9011C49.9419 61.9011 50.5325 61.3236 50.5325 60.6017C50.5456 59.8799 49.9681 59.2892 49.2463 59.2761Z"
          fill="black"
        />
        <path
          d="M36.4756 51.2961C35.7931 51.5717 35.465 52.333 35.7406 53.0023C35.9375 53.5155 36.4363 53.8292 36.9613 53.8292C37.1188 53.8292 37.2894 53.803 37.4469 53.7387C38.1163 53.4762 38.4444 52.7018 38.1819 52.0324C37.9063 51.3604 37.145 51.0323 36.4756 51.2961Z"
          fill="black"
        />
        <path
          d="M35.9768 48.7629C36.7118 48.7354 37.2762 48.1329 37.2499 47.4111C37.2237 46.6892 36.6199 46.1117 35.898 46.1379C35.163 46.1642 34.5987 46.7666 34.6249 47.5029C34.6512 48.2117 35.2287 48.7629 35.9374 48.7629C35.9505 48.7629 35.9637 48.7629 35.9768 48.7629Z"
          fill="black"
        />
        <path
          d="M51.6613 37.0411C50.9394 36.9899 50.3094 37.5267 50.2569 38.2499C50.1913 38.9718 50.7294 39.6005 51.4644 39.6661C51.4906 39.6661 51.53 39.6661 51.5563 39.6661C52.2388 39.6661 52.8163 39.1424 52.8688 38.4586C52.9213 37.738 52.3831 37.0936 51.6613 37.0411Z"
          fill="black"
        />
        <path
          d="M44.6393 58.3837C43.9568 58.1212 43.1956 58.4493 42.9331 59.1187C42.6706 59.8025 42.9987 60.5637 43.6812 60.8249C43.8256 60.8918 43.9962 60.9168 44.1537 60.9168C44.6787 60.9168 45.1643 60.6018 45.3743 60.0768C45.6368 59.4061 45.3087 58.6462 44.6393 58.3837Z"
          fill="black"
        />
        <path
          d="M62.043 46.0724C61.3211 46.0724 60.7437 46.6617 60.7437 47.3849C60.7437 47.4111 60.7437 47.4374 60.7437 47.4505C60.7437 47.4636 60.7437 47.4768 60.7437 47.4899C60.7699 48.1973 61.3474 48.763 62.0562 48.763C62.0693 48.763 62.0824 48.763 62.0955 48.763C62.8174 48.7354 63.3949 48.133 63.3686 47.4111C63.3686 47.398 63.3686 47.3849 63.3686 47.3718C63.3686 46.6499 62.778 46.0724 62.043 46.0724Z"
          fill="black"
        />
        <path
          d="M53.8661 58.5018C53.1836 58.7498 52.8424 59.4993 53.0917 60.1818C53.2886 60.7055 53.7874 61.0336 54.3255 61.0336C54.4699 61.0336 54.6274 61.0073 54.7849 60.9548C55.4542 60.6923 55.8086 59.9429 55.5461 59.2617C55.2967 58.5792 54.5486 58.2393 53.8661 58.5018Z"
          fill="black"
        />
        <path
          d="M57.7382 56.0342C57.2264 56.5461 57.2264 57.373 57.7514 57.8848C58.0007 58.1329 58.3289 58.2655 58.6701 58.2655C59.0114 58.2655 59.3395 58.1329 59.602 57.8848C60.1139 57.3598 60.1139 56.533 59.602 56.0211C59.077 55.5092 58.2501 55.521 57.7382 56.0342Z"
          fill="black"
        />
        <path
          d="M61.7806 51.3224C61.0981 51.0848 60.35 51.4654 60.1268 52.1492C59.8906 52.8317 60.2581 53.5798 60.9406 53.8161C61.085 53.8555 61.2293 53.8817 61.3606 53.8817C61.9118 53.8817 62.4237 53.5405 62.6075 52.9892C62.8437 52.3067 62.4762 51.5586 61.7806 51.3224Z"
          fill="black"
        />
        <path
          d="M57.8564 39.8511C58.1189 39.1673 57.7777 38.406 57.0952 38.158C56.4258 37.8955 55.6645 38.2367 55.4152 38.9192C55.1527 39.5886 55.4939 40.3498 56.1764 40.6123C56.3208 40.6648 56.4783 40.6911 56.6358 40.6911C57.1608 40.6911 57.6595 40.3748 57.8564 39.8511Z"
          fill="black"
        />
        <path
          d="M3.87309 52.1492C3.64997 51.4654 2.90184 51.0992 2.20622 51.3355C1.52372 51.5586 1.15622 52.3067 1.39247 53.0023C1.39247 52.9892 1.39247 52.9892 1.39247 52.9892C1.57622 53.5405 2.08809 53.8817 2.63934 53.8948C2.77059 53.8948 2.91497 53.8673 3.05934 53.8148C3.74184 53.5785 4.10934 52.8435 3.87309 52.161V52.1492Z"
          fill="black"
        />
        <path
          d="M8.51944 38.9324C8.25694 38.263 7.49569 37.9217 6.82632 38.1842C6.14382 38.4467 5.80257 39.208 6.06507 39.8774C6.27507 40.4024 6.76069 40.7187 7.29882 40.7187C7.45632 40.7187 7.61382 40.6911 7.77132 40.6386C8.44069 40.363 8.78194 39.6149 8.51944 38.9324Z"
          fill="black"
        />
        <path
          d="M4.41103 56.0342C3.88603 56.5461 3.88603 57.3729 4.39791 57.8848C4.66041 58.146 4.98853 58.2786 5.32978 58.2786C5.67103 58.2786 5.99916 58.146 6.26166 57.8848C6.77353 57.3847 6.77353 56.5447 6.26166 56.0342C5.74978 55.521 4.92291 55.521 4.41103 56.0342Z"
          fill="black"
        />
        <path
          d="M1.94377 48.763C2.6394 48.763 3.2169 48.2249 3.25627 47.5161C3.28252 46.7942 2.73127 46.1774 2.0094 46.1511C1.2744 46.1117 0.657521 46.6761 0.631271 47.398C0.605021 48.1199 1.15627 48.7367 1.87815 48.763C1.9044 48.763 1.91752 48.763 1.94377 48.763Z"
          fill="black"
        />
        <path
          d="M10.1336 58.5017C9.46418 58.2392 8.70293 58.5936 8.45356 59.2761C8.20418 59.9455 8.54543 60.7067 9.22793 60.9561C9.37231 61.0086 9.52981 61.0348 9.67418 61.0348C10.2123 61.0348 10.7111 60.7198 10.9079 60.183C11.1573 59.511 10.8161 58.7498 10.1336 58.5017Z"
          fill="black"
        />
        <path
          d="M17.6676 37.0411C16.9457 36.9899 16.3026 37.5267 16.2501 38.2499C16.1976 38.9718 16.7357 39.6005 17.4576 39.6661C17.497 39.6661 17.5364 39.6661 17.5626 39.6661C18.2451 39.6661 18.8226 39.1424 18.8751 38.4586C18.9276 37.7249 18.3895 37.108 17.6676 37.0411Z"
          fill="black"
        />
        <path
          d="M2.52131 43.6035C2.74443 43.7479 2.99381 43.8135 3.24318 43.8135C3.66318 43.8135 4.08318 43.6035 4.34568 43.2242H4.33256C4.73943 42.6191 4.56881 41.7922 3.95193 41.4116C3.34818 41.0047 2.53443 41.1885 2.14068 41.7922C1.74693 42.396 1.91756 43.2111 2.52131 43.6035Z"
          fill="black"
        />
        <path
          d="M14.7538 59.2761C14.0319 59.2892 13.4544 59.893 13.4675 60.6162C13.4806 61.338 14.0713 61.9142 14.7931 61.9011C15.515 61.9011 16.1056 61.2973 16.0925 60.5755C16.0794 59.8536 15.4888 59.263 14.7538 59.2761Z"
          fill="black"
        />
        <path
          d="M12.2599 37.0555C11.538 37.108 10.9999 37.738 11.0524 38.4585C11.118 39.1542 11.6824 39.666 12.3649 39.666C12.4042 39.666 12.4305 39.666 12.4699 39.666C13.1917 39.6149 13.7299 38.9835 13.6774 38.2499C13.6249 37.5267 12.9817 36.9899 12.2599 37.0555Z"
          fill="black"
        />
        <path
          d="M19.3736 58.3837C18.6911 58.6462 18.3629 59.4061 18.6254 60.0899C18.8354 60.6018 19.3211 60.9168 19.8461 60.9168C20.0167 60.9168 20.1742 60.8905 20.3317 60.8249C21.0011 60.5637 21.3423 59.8025 21.0667 59.1187C20.8042 58.4493 20.0429 58.1212 19.3736 58.3837Z"
          fill="black"
        />
        <path
          d="M23.8627 39.838C24.1252 39.1673 23.7839 38.4061 23.1014 38.158C23.1014 38.1449 23.1014 38.1449 23.1014 38.1449C22.4189 37.8955 21.6708 38.2354 21.4083 38.9192C21.1589 39.5886 21.5002 40.3499 22.1695 40.6124C22.327 40.6649 22.4845 40.6911 22.642 40.6911C23.167 40.6911 23.6658 40.363 23.8627 39.838Z"
          fill="black"
        />
        <path
          d="M28.0493 46.0724C27.3274 46.0724 26.7499 46.6617 26.7499 47.3849C26.7499 47.398 26.7499 47.398 26.7499 47.4111C26.7237 48.133 27.288 48.7368 28.023 48.763C28.0362 48.763 28.0493 48.763 28.0624 48.763C28.7712 48.763 29.3487 48.2118 29.3749 47.4899C29.3749 47.4623 29.3749 47.4374 29.3749 47.398C29.3749 47.3849 29.3749 47.3849 29.3749 47.3705C29.3749 46.6499 28.7843 46.058 28.0493 46.0724Z"
          fill="black"
        />
        <path
          d="M27.5242 51.296C26.8549 51.0204 26.0936 51.3617 25.818 52.0324C25.5555 52.7017 25.8836 53.463 26.553 53.7386C26.7105 53.8042 26.8811 53.8292 27.0386 53.8292C27.5636 53.8292 28.0624 53.5155 28.2592 53.0023C28.5349 52.3329 28.2067 51.5585 27.5242 51.296Z"
          fill="black"
        />
        <path
          d="M23.2719 55.8767C22.76 56.3754 22.7469 57.2154 23.2588 57.7273C23.5081 57.9898 23.8494 58.121 24.1906 58.121C24.5319 58.121 24.86 58.0029 25.1094 57.7535C25.6344 57.2417 25.6344 56.4148 25.1356 55.8898C24.6238 55.3779 23.7969 55.3648 23.2719 55.8767Z"
          fill="black"
        />
        <path
          d="M30.6877 21.7439V34.3255H33.3127V21.7439L38.9473 27.3784L40.8031 25.5225L32.0002 16.7196L23.1973 25.5225L25.0531 27.3784L30.6877 21.7439Z"
          fill="black"
        />
        <path
          d="M27.158 43.9329C29.8775 41.6978 34.1221 41.6978 36.8416 43.9329C37.0857 44.1338 37.3811 44.2322 37.6737 44.2322C38.0531 44.2322 38.4284 44.0694 38.6883 43.7531C39.149 43.1927 39.0676 42.3658 38.5072 41.9064C36.6802 40.4036 34.3689 39.5754 31.9998 39.5754C29.6307 39.5754 27.3194 40.4036 25.4924 41.9051C24.932 42.3658 24.8519 43.1914 25.3113 43.7518C25.772 44.3149 26.5989 44.3936 27.158 43.9329Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip2_3992_97215)">
        <path
          d="M263.738 51.0342C263.226 51.5461 263.226 52.373 263.751 52.8848C264.001 53.1473 264.342 53.2655 264.67 53.2655C265.011 53.2655 265.353 53.1342 265.602 52.8848C266.114 52.373 266.114 51.533 265.602 51.0211C265.077 50.5092 264.25 50.5223 263.738 51.0342Z"
          fill="black"
        />
        <path
          d="M259.866 53.5017C259.184 53.7511 258.842 54.4992 259.092 55.1817C259.289 55.7067 259.787 56.0349 260.325 56.0349C260.483 56.0349 260.627 56.0086 260.785 55.9561C261.467 55.7067 261.809 54.9455 261.546 54.263C261.297 53.5936 260.549 53.2392 259.866 53.5017Z"
          fill="black"
        />
        <path
          d="M263.856 34.8511C264.119 34.1686 263.777 33.4073 263.095 33.158C262.426 32.8955 261.664 33.2367 261.402 33.9192C261.152 34.5886 261.494 35.3498 262.176 35.6123C262.321 35.6648 262.478 35.6911 262.636 35.6911C263.161 35.6911 263.659 35.3761 263.856 34.8511Z"
          fill="black"
        />
        <path
          d="M242.515 38.6049C242.738 38.7493 242.987 38.8149 243.237 38.8149C243.657 38.8149 244.077 38.6049 244.326 38.2243C244.733 37.6205 244.562 36.8068 243.946 36.413C243.342 36.0061 242.528 36.1768 242.134 36.7936C241.741 37.3974 241.911 38.2111 242.515 38.6049Z"
          fill="black"
        />
        <path
          d="M268.056 41.0724C267.321 41.0724 266.744 41.663 266.744 42.3849C266.744 42.398 266.744 42.398 266.744 42.398C266.744 42.4374 266.744 42.4636 266.744 42.4899C266.77 43.1986 267.347 43.763 268.056 43.763C268.069 43.763 268.082 43.763 268.096 43.763C268.831 43.7367 269.395 43.133 269.369 42.4111V42.398C269.369 42.398 269.369 42.3849 269.369 42.3717C269.369 41.6499 268.778 41.0592 268.056 41.0724Z"
          fill="black"
        />
        <path
          d="M267.78 46.3224C267.098 46.0993 266.35 46.4668 266.127 47.1493C265.89 47.8318 266.258 48.5799 266.954 48.8162C267.085 48.8555 267.229 48.8818 267.36 48.8818C267.912 48.8818 268.424 48.5405 268.607 47.9893C268.844 47.3068 268.476 46.5587 267.78 46.3224Z"
          fill="black"
        />
        <path
          d="M266.717 38.7623C266.967 38.7623 267.216 38.6836 267.439 38.5392C268.056 38.1323 268.214 37.3186 267.807 36.7148C267.413 36.1111 266.599 35.9536 265.996 36.3473C265.392 36.7542 265.221 37.568 265.628 38.1717C265.877 38.5523 266.297 38.7623 266.717 38.7623Z"
          fill="black"
        />
        <path
          d="M250.639 53.3837C249.957 53.1212 249.196 53.4493 248.933 54.1187C248.671 54.8012 248.999 55.5624 249.668 55.8249C249.681 55.8249 249.681 55.8249 249.681 55.8249C249.839 55.8905 249.996 55.9168 250.154 55.9168C250.679 55.9168 251.178 55.6018 251.374 55.0899C251.637 54.4074 251.309 53.6462 250.639 53.3837Z"
          fill="black"
        />
        <path
          d="M252.254 32.0555C251.532 32.108 250.994 32.738 251.06 33.4598C251.112 34.1555 251.676 34.6673 252.359 34.6673C252.398 34.6673 252.425 34.6673 252.464 34.6673C253.186 34.6148 253.724 33.9848 253.671 33.263C253.619 32.528 252.976 31.9898 252.254 32.0555Z"
          fill="black"
        />
        <path
          d="M246.82 33.1842C246.137 33.4467 245.809 34.208 246.059 34.8774C246.269 35.4024 246.767 35.7174 247.292 35.7174C247.45 35.7174 247.608 35.6911 247.765 35.6255C248.434 35.3761 248.776 34.6149 248.513 33.9324C248.251 33.263 247.489 32.9217 246.82 33.1842Z"
          fill="black"
        />
        <path
          d="M255.246 54.2761C254.524 54.2761 253.921 54.8536 253.921 55.5755C253.908 56.2974 254.485 56.888 255.207 56.9011C255.22 56.9011 255.22 56.9011 255.233 56.9011C255.942 56.9011 256.533 56.3236 256.546 55.6149C256.546 54.8799 255.968 54.2892 255.246 54.2761Z"
          fill="black"
        />
        <path
          d="M244.877 50.903C244.366 51.4148 244.379 52.2548 244.891 52.7536C245.153 53.003 245.481 53.1342 245.809 53.1342C246.151 53.1342 246.492 53.003 246.754 52.7405C247.253 52.2155 247.253 51.3886 246.728 50.8767C246.216 50.378 245.376 50.378 244.877 50.903Z"
          fill="black"
        />
        <path
          d="M257.661 32.0424C256.939 31.9899 256.296 32.528 256.244 33.2499C256.191 33.9717 256.729 34.6017 257.451 34.6674C257.491 34.6674 257.53 34.6674 257.556 34.6674C258.239 34.6674 258.816 34.1424 258.869 33.4599C258.921 32.7249 258.383 32.0949 257.661 32.0424Z"
          fill="black"
        />
        <path
          d="M210.411 51.0342C209.886 51.546 209.886 52.3729 210.398 52.8848C210.66 53.1473 210.989 53.2785 211.33 53.2785C211.671 53.2785 211.999 53.1473 212.262 52.8979C212.774 52.386 212.774 51.546 212.262 51.0342C211.75 50.5223 210.923 50.5223 210.411 51.0342Z"
          fill="black"
        />
        <path
          d="M208.219 46.3355C207.524 46.5717 207.156 47.3067 207.392 48.0023C207.576 48.5405 208.088 48.8948 208.639 48.8948C208.77 48.8948 208.915 48.8686 209.059 48.8161C209.742 48.593 210.109 47.8448 209.873 47.1623C209.65 46.4667 208.902 46.0992 208.219 46.3355Z"
          fill="black"
        />
        <path
          d="M207.944 43.7761C208.639 43.7761 209.217 43.2249 209.256 42.5161C209.283 41.7942 208.731 41.1774 208.009 41.1511C207.274 41.1117 206.658 41.6761 206.631 42.398C206.605 43.1199 207.156 43.7367 207.878 43.7761C207.904 43.7761 207.918 43.7761 207.944 43.7761Z"
          fill="black"
        />
        <path
          d="M223.654 32.0424C222.933 31.9899 222.303 32.528 222.25 33.2499C222.198 33.9717 222.736 34.6017 223.458 34.6674C223.497 34.6674 223.523 34.6674 223.563 34.6674C224.245 34.6674 224.823 34.1424 224.875 33.4599C224.928 32.7249 224.376 32.0949 223.654 32.0424Z"
          fill="black"
        />
        <path
          d="M220.754 54.2761C220.032 54.2892 219.454 54.893 219.468 55.6149C219.481 56.3368 220.071 56.9142 220.793 56.9011C221.515 56.9011 222.106 56.2974 222.093 55.5755C222.079 54.8536 221.489 54.2761 220.754 54.2761Z"
          fill="black"
        />
        <path
          d="M216.134 53.5018C215.464 53.2524 214.703 53.5937 214.454 54.2761C214.204 54.9455 214.545 55.7068 215.228 55.9562C215.372 56.0087 215.53 56.0349 215.687 56.0349C216.212 56.0349 216.711 55.7199 216.908 55.1818C217.17 54.5124 216.816 53.7512 216.134 53.5018Z"
          fill="black"
        />
        <path
          d="M218.26 32.0555C217.538 32.108 217 32.738 217.052 33.4599C217.118 34.1555 217.682 34.6674 218.365 34.6674C218.404 34.6674 218.43 34.6674 218.47 34.6674C219.192 34.6149 219.73 33.9849 219.677 33.263C219.612 32.5411 218.982 31.9899 218.26 32.0555Z"
          fill="black"
        />
        <path
          d="M214.519 33.9324C214.257 33.263 213.496 32.9217 212.826 33.1842C212.144 33.4467 211.803 34.208 212.065 34.8774C212.275 35.4024 212.774 35.7174 213.299 35.7174C213.456 35.7174 213.614 35.6911 213.771 35.6386C214.441 35.3761 214.782 34.6149 214.519 33.9324Z"
          fill="black"
        />
        <path
          d="M230.191 53.121C230.532 53.121 230.86 53.0029 231.109 52.7535C231.634 52.2417 231.634 51.4148 231.136 50.8898C230.624 50.3779 229.797 50.3648 229.272 50.8767C228.76 51.3885 228.747 52.2154 229.259 52.7273C229.508 52.9898 229.849 53.121 230.191 53.121Z"
          fill="black"
        />
        <path
          d="M225.374 53.3837C224.691 53.6462 224.363 54.4074 224.625 55.0899C224.835 55.6018 225.321 55.9168 225.846 55.9168C226.017 55.9168 226.174 55.8905 226.332 55.8249C227.001 55.5624 227.342 54.8012 227.08 54.1187C226.804 53.4493 226.043 53.1212 225.374 53.3837Z"
          fill="black"
        />
        <path
          d="M232.724 38.7493C232.973 38.7493 233.222 38.6836 233.446 38.5393C234.062 38.1324 234.22 37.3186 233.813 36.7149C233.406 36.1111 232.606 35.9405 232.002 36.3474C231.398 36.7543 231.227 37.568 231.634 38.1718C231.884 38.5524 232.304 38.7493 232.724 38.7493Z"
          fill="black"
        />
        <path
          d="M208.521 38.6048C208.744 38.7492 208.994 38.8148 209.23 38.8148C209.663 38.8148 210.083 38.6048 210.333 38.2242C210.739 37.6205 210.556 36.8067 209.952 36.413C209.348 36.0061 208.534 36.1898 208.141 36.7936C207.747 37.3973 207.918 38.2111 208.521 38.6048Z"
          fill="black"
        />
        <path
          d="M229.863 34.838C230.125 34.1686 229.784 33.4073 229.101 33.1448C228.419 32.8955 227.671 33.2367 227.408 33.9192C227.159 34.5886 227.5 35.3498 228.17 35.6123C228.327 35.6648 228.485 35.6911 228.642 35.6911C229.167 35.6911 229.666 35.363 229.863 34.838Z"
          fill="black"
        />
        <path
          d="M239.313 68.1571V55.5754H236.688V68.1571L231.053 62.5225L229.197 64.3784L238 73.1813L246.803 64.3784L244.947 62.5225L239.313 68.1571Z"
          fill="black"
        />
        <path
          d="M238 42.4504C235.63 42.4504 233.32 43.2773 231.491 44.7801C230.932 45.2408 230.851 46.0677 231.311 46.6281C231.772 47.1873 232.6 47.2686 233.159 46.8079C235.876 44.5728 240.125 44.5728 242.842 46.8079C243.086 47.0088 243.381 47.1072 243.675 47.1072C244.055 47.1072 244.43 46.9431 244.69 46.6281C245.15 46.0677 245.069 45.2408 244.51 44.7801C242.68 43.2773 240.37 42.4504 238 42.4504Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3992_97215">
          <rect width={91} height={91} fill="white" transform="translate(89.5 0.950439)" />
        </clipPath>
        <clipPath id="clip1_3992_97215">
          <rect width={63} height={63} fill="white" transform="translate(0.5 15.9504)" />
        </clipPath>
        <clipPath id="clip2_3992_97215">
          <rect width={63} height={63} fill="white" transform="translate(206.5 10.9504)" />
        </clipPath>
      </defs>
    </svg>
  )
}
