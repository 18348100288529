import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export const handleBodyScrollLock = (
  targetElement: Element | null,
  visible: boolean,
  wrapClassName: string,
  callback: (el: Element) => void
): void => {
  if (!targetElement) {
    const requestID = window.requestAnimationFrame(() => {
      if (document.getElementsByClassName(wrapClassName)[0]) {
        const newTarget = document.getElementsByClassName(wrapClassName)[0]
        visible ? disableBodyScroll(newTarget) : enableBodyScroll(newTarget)
        callback(newTarget)
      }
      window.cancelAnimationFrame(requestID)
    })
  } else {
    visible ? disableBodyScroll(targetElement) : enableBodyScroll(targetElement)
  }
}
