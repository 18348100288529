import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getSolEnvVariables = () => {
  const solEnvVariables: AppConfigOverride = {
    shopperSlug: 'sol',
    shopperName: 'SOLARIS',
    storeLang: 'it-IT',
    storeID: '14',
    toggleFeature: {
      availableInStore: false,
      customerOrderError: true,
    },
    showStoreAvailableFilter: true,
    default: {
      productType: ProductTypeValues.SUN,
      faPlaylistType: FaPlaylistType.SUN,
    },
    vmStore: 'sol',
    vmStyle: 'sol',
  }

  return solEnvVariables
}

export default getSolEnvVariables
