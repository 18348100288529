import { IconProps } from '@abstractTypes/icons'


export const ScanIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 46"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 1H1V17" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M29 1H45V17" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M29 45L45 45L45 29" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M17 45L1 45L0.999999 29" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M6.5 23H39.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
