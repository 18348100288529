import { getBrowserSessionStorageConfigOverrideKey } from '@config/config.base'
const SESSION_EXPIRATION_INTERVAL = 3600000 // 1 hour

const getSessionStorageItem = (): string | null => {
  return sessionStorage.getItem(getBrowserSessionStorageConfigOverrideKey())
}

export const setConfigSession = <T>(configState: T): void => {
  sessionStorage.setItem(
    getBrowserSessionStorageConfigOverrideKey(),
    JSON.stringify({
      config: configState,
      setDate: new Date().getTime(),
    })
  )
}

export const getConfigFromSessionStorage = <T>(): T | null => {
  const configFromSessionStorage: string | null = getSessionStorageItem()
  return configFromSessionStorage ? JSON.parse(configFromSessionStorage).config : null
}

export const getDateFromSessionStorage = <T>(): T | null => {
  const configFromSessionStorage: string | null = getSessionStorageItem()
  return configFromSessionStorage ? JSON.parse(configFromSessionStorage).setDate : null
}

const removeOverrideFromSessionStorage = (): void => {
  sessionStorage.removeItem(getBrowserSessionStorageConfigOverrideKey())
  window.location.reload()
}

export const checkOverrideConfigInSession = (): void => {
  const expirationDateFromStorage: string | null = getDateFromSessionStorage()
  const expirationDate =
    expirationDateFromStorage &&
    new Date(expirationDateFromStorage).getTime() + SESSION_EXPIRATION_INTERVAL

  if (expirationDate && expirationDate < Date.now()) {
    removeOverrideFromSessionStorage()
  }
}
