import { PreselectedFilters } from '@abstractTypes/filter'
import { ToastType } from '@abstractTypes/toast'
import { DrawerKey, ModalKey } from '@abstractTypes/utils'
export const RESET_LOADER_LIST = 'ui/RESET_LOADER_LIST'
export const TOGGLE_LANGUAGE_SELECTOR = 'ui/TOGGLE_LANGUAGE_SELECTOR'
export const TOGGLE_FRAME_ADVISOR_MYSELECTION = 'ui/TOGGLE_FRAME_ADVISOR_MYSELECTION'
export const SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE = 'ui/SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE'
export const SET_VISIBLE_DRAWER = 'ui/SET_VISIBLE_DRAWER'
export const CLOSE_VISIBLE_DRAWER = 'ui/CLOSE_VISIBLE_DRAWER'
export const SET_VISIBLE_MODAL = 'ui/SET_VISIBLE_MODAL'
export const SET_HAS_CHANGED_PAGE = 'ui/SET_HAS_CHANGED_PAGE'
export const UPDATE_PRESELECTED_FILTERS = 'ui/UPDATE_PRESELECTED_FILTERS'
export const CLEAR_PRESELECTED_FILTERS = 'ui/CLEAR_PRESELECTED_FILTERS'
export const TOGGLE_ACCESSIBILITY_MODAL = 'ui/TOGGLE_ACCESSIBILITY_MODAL'
export const CLOSE_ACCESSIBILITY_MODAL = 'ui/CLOSE_ACCESSIBILITY_MODAL'
export const SET_STRIPE_BANNER_VISIBLE = 'ui/SET_STRIPE_BANNER_VISIBLE'
export const SET_FILTERS_COUNT = 'ui/SET_FILTERS_COUNT'
export const ADD_TOAST = 'ui/ADD_TOAST'
export const DELETE_TOAST = 'ui/DELETE_TOAST'
export const SET_ARE_PDP_TABS_ON_TOP = 'ui/SET_ARE_PDP_TABS_ON_TOP'
export const SET_IS_DEFAULT_PLP = 'ui/SET_IS_DEFAULT_PLP'
export const ADD_LOADER_ID = 'ui/ADD_LOADER_ID'
export const DELETE_LOADER_ID = 'ui/DELETE_LOADER_ID'

export interface ResetLoaderAction {
  type: typeof RESET_LOADER_LIST
}

export interface ToggleAccessibilityModalAction {
  type: typeof TOGGLE_ACCESSIBILITY_MODAL
}

export interface CloseAccessibilityModalAction {
  type: typeof CLOSE_ACCESSIBILITY_MODAL
}

export interface ToggleLanguageSelectorAction {
  type: typeof TOGGLE_LANGUAGE_SELECTOR
}

export interface CustomLogoHeroSlideActiveAction {
  type: typeof SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE
  payload: {
    isActive: boolean
  }
}
export interface SetVisibleDrawerAction {
  type: typeof SET_VISIBLE_DRAWER
  payload: {
    drawerKey: DrawerKey
  }
}
export interface CloseVisibleDrawerAction {
  type: typeof CLOSE_VISIBLE_DRAWER
  payload: {
    drawerKey: DrawerKey
  }
}
export interface SetVisibleModalAction {
  type: typeof SET_VISIBLE_MODAL
  payload: {
    modalKey: ModalKey
  }
}

export interface ToggleFrameAdvisorMySelectionAction {
  type: typeof TOGGLE_FRAME_ADVISOR_MYSELECTION
}

export interface SetHasChangedPageAction {
  type: typeof SET_HAS_CHANGED_PAGE
}

export interface UpdatePreselectedFilters {
  type: typeof UPDATE_PRESELECTED_FILTERS
  payload: {
    filters: PreselectedFilters
  }
}

export interface ClearPreselectedFilters {
  type: typeof CLEAR_PRESELECTED_FILTERS
}

export const resetLoaderList = (): ResetLoaderAction => ({
  type: RESET_LOADER_LIST,
})

export const toggleLanguageSelectorList: ToggleLanguageSelectorAction = {
  type: TOGGLE_LANGUAGE_SELECTOR,
}

export const toggleFrameAdvisorMySelectionAction: ToggleFrameAdvisorMySelectionAction = {
  type: TOGGLE_FRAME_ADVISOR_MYSELECTION,
}

export const setCustomLogoHeroSlideStateAction = (
  isActive: boolean
): CustomLogoHeroSlideActiveAction => ({
  type: SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE,
  payload: {
    isActive,
  },
})
export const setVisibleDrawer = (drawerKey: DrawerKey): SetVisibleDrawerAction => ({
  type: SET_VISIBLE_DRAWER,
  payload: {
    drawerKey,
  },
})

export const closeVisibleDrawer = (drawerKey: DrawerKey): CloseVisibleDrawerAction => ({
  type: CLOSE_VISIBLE_DRAWER,
  payload: {
    drawerKey,
  },
})

export const setVisibleModal = (modalKey: ModalKey): SetVisibleModalAction => ({
  type: SET_VISIBLE_MODAL,
  payload: {
    modalKey,
  },
})

export const setHasChangedPage: SetHasChangedPageAction = {
  type: SET_HAS_CHANGED_PAGE,
}

export interface SetStripeBannerVisible {
  type: typeof SET_STRIPE_BANNER_VISIBLE
  payload: {
    isVisible: boolean
  }
}
export interface SetFiltersCount {
  type: typeof SET_FILTERS_COUNT
  payload: number
}

export const updatePreselectedFilters = (
  filters: PreselectedFilters
): UpdatePreselectedFilters => ({
  type: UPDATE_PRESELECTED_FILTERS,
  payload: {
    filters,
  },
})

export const clearPreselectedFilters = (): ClearPreselectedFilters => ({
  type: CLEAR_PRESELECTED_FILTERS,
})

export const setStripeBannerVisible = (visibility: boolean): SetStripeBannerVisible => {
  return {
    type: SET_STRIPE_BANNER_VISIBLE,
    payload: {
      isVisible: visibility,
    },
  }
}

export const setFiltersCount = (filtersCount: number): SetFiltersCount => {
  return {
    type: SET_FILTERS_COUNT,
    payload: filtersCount,
  }
}

interface AddToastAction {
  type: typeof ADD_TOAST
  payload: {
    message: string
    duration?: number
    type?: ToastType
  }
}

export const addToast = (message: string, duration?: number, type?: ToastType): AddToastAction => ({
  type: ADD_TOAST,
  payload: {
    message,
    duration,
    type,
  },
})

interface DeleteToastAction {
  type: typeof DELETE_TOAST
  payload: string
}

export const deleteToast = (id: string): DeleteToastAction => ({
  type: DELETE_TOAST,
  payload: id,
})

export interface SetArePdpTabsOnTopAction {
  type: typeof SET_ARE_PDP_TABS_ON_TOP
  payload: boolean
}

export const setArePdpTabsOnTop = (value: boolean): SetArePdpTabsOnTopAction => ({
  type: SET_ARE_PDP_TABS_ON_TOP,
  payload: value,
})

export interface SetIsDefaultPlp {
  type: typeof SET_IS_DEFAULT_PLP
  payload: {
    isDefaultPlp: boolean
  }
}

export const setIsDefaultPlp = (isDefaultPlp: boolean): SetIsDefaultPlp => ({
  type: SET_IS_DEFAULT_PLP,
  payload: { isDefaultPlp },
})

export interface AddLoaderIdAction {
  type: typeof ADD_LOADER_ID
  payload: {
    id: string
  }
}

export const addLoaderId = (id: string): AddLoaderIdAction => ({
  type: ADD_LOADER_ID,
  payload: {
    id,
  },
})

export interface DeleteLoaderIdAction {
  type: typeof DELETE_LOADER_ID
  payload: string
}

export const deleteLoaderId = (id: string): DeleteLoaderIdAction => ({
  type: DELETE_LOADER_ID,
  payload: id,
})

export type UIActionTypes =
  | ResetLoaderAction
  | ToggleLanguageSelectorAction
  | ToggleFrameAdvisorMySelectionAction
  | SetHasChangedPageAction
  | UpdatePreselectedFilters
  | ClearPreselectedFilters
  | ToggleAccessibilityModalAction
  | CustomLogoHeroSlideActiveAction
  | SetVisibleDrawerAction
  | CloseVisibleDrawerAction
  | SetVisibleModalAction
  | SetStripeBannerVisible
  | SetFiltersCount
  | AddToastAction
  | DeleteToastAction
  | SetArePdpTabsOnTopAction
  | SetIsDefaultPlp
  | AddLoaderIdAction
  | DeleteLoaderIdAction
