import { IconProps } from '@abstractTypes/icons'

export const GlassShape8Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 0.650391C32.757 0.650391 29.081 2.09439 27.247 4.20539C26.285 3.58539 25.165 3.25039 24 3.25039C22.827 3.25039 21.713 3.58739 20.755 4.20739C18.921 2.09539 15.245 0.650391 11 0.650391C4.925 0.650391 0 3.60539 0 7.25039C0 10.8954 4.925 13.8504 11 13.8504C17.075 13.8504 22 10.8954 22 7.25039C22 6.79739 21.923 6.35439 21.778 5.92739C22.43 5.48939 23.194 5.25039 24 5.25039C24.801 5.25039 25.567 5.49239 26.221 5.93139C26.077 6.35739 26 6.79839 26 7.25039C26 10.8954 30.925 13.8504 37 13.8504C43.075 13.8504 48 10.8954 48 7.25039C48 3.60539 43.075 0.650391 37 0.650391ZM11 11.8504C5.696 11.8504 2 9.42639 2 7.25039C2 5.07439 5.696 2.65039 11 2.65039C16.304 2.65039 20 5.07439 20 7.25039C20 9.42639 16.304 11.8504 11 11.8504ZM28 7.25039C28 9.42639 31.696 11.8504 37 11.8504C42.304 11.8504 46 9.42639 46 7.25039C46 5.07439 42.304 2.65039 37 2.65039C31.696 2.65039 28 5.07439 28 7.25039Z"
        fill="#333333"
      />
    </svg>
  )
}
