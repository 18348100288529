import { LinkProps as ReactLinkProps } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
export type LinkProps = ReactLinkProps

export const Link: React.FC<LinkProps> = ({ to, children, ...props }) => {
  return (
    <HashLink {...props} to={to}>
      {children}
    </HashLink>
  )
}
