import { SET_VERSION, VersionActionTypes } from '@actions/version'

export interface VersionState {
  version: number
}

const initialState = {
  version: 0,
}

export default (state = initialState, action: VersionActionTypes): VersionState => {
  switch (action.type) {
    case SET_VERSION: {
      const { version } = action.payload
      return {
        ...state,
        version,
      }
    }
    default:
      return state
  }
}
