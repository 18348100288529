import { FormItem } from '@components/core/FormItem'
import { InputStyled } from './styles'
import { FormInputProps } from './types'

export const FormInput: React.FC<FormInputProps> = ({
  name,
  value,
  placeholder,
  type,
  label,
  fieldDescription,
  required,
  hasFeedback,
  validateStatus,
  help,
  className,

  onChange = () => {},

  onBlur = () => {},
}) => (
  <FormItem
    label={label}
    required={required}
    hasFeedback={hasFeedback}
    validateStatus={validateStatus}
    extra={fieldDescription}
    help={help}
    className={className}
  >
    <InputStyled
      data-test={`input-${name}`}
      name={name}
      onChange={newValue => onChange(newValue)}
      onBlur={newValue => onBlur(newValue)}
      placeholder={placeholder}
      type={type}
      value={value}
      aria-label={label}
    />
  </FormItem>
)
