import { Device } from '@abstractTypes/utils'
import PrivacyPolicyTooltip from '@components/PrivacyPolicyTooltip'
import { PrivacyPolicyButton } from '@components/PrivacyPolicyTooltip/styles'
import { landscape, tablet } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const PrivacyPolicyTooltipStyled = styled(PrivacyPolicyTooltip)`
  inset-block-start: unset;
  min-block-size: ${pxToRem(112)};
  align-self: center;
  ${landscape(`
    min-block-size: ${pxToRem(150)};
  `)}
  ${tablet(`
    inset-block-start: unset;
      min-block-size: ${pxToRem(100)};
  `)}
  ${landscape(
    Device.tablet,
    `
    inset-block-start: unset;
    min-block-size: ${pxToRem(100)};
  `
  )}

  ${PrivacyPolicyButton} {
    margin-block-start: 0;
  }
`
