import { Device, LogoColor } from '@abstractTypes/utils'
import AccessibilityToggle from '@components/Accessibility/AccessibilityToggle'
import Header from '@components/Contents/HeaderContent'
import { Disclaimer } from '@components/Disclaimer'
import { DrawerContainer } from '@components/Drawer/styles'
import ErrorWidget from '@components/ErrorWidget'
import { FloatingActions } from '@components/FloatingActions'
import { MenuDrawer } from '@components/MenuDrawer'
import { MiniCartDrawer } from '@components/MiniCartDrawer'
import PrivacyPolicyTooltip from '@components/PrivacyPolicyTooltip'
import { ScrollToTopButton } from '@components/ScrollTopButton'
import DiscountBanner from '@components/StripeBanner/DiscountBanner'
import GreenShipmentBanner from '@components/StripeBanner/GreenShipmentBanner'
import { ToastNotification } from '@components/core/Toast'
import config from '@config/index'
import { ADA_SCREEN_HEIGHT, SCROLL_RESTORATION_ID, TOP_ANCHOR_ID } from '@constants/accessibility'
import { useSimulateFirstInteraction } from '@hooks/analytics/useSimulateFirstInteraction'
import { useConfigOverrideExpiration } from '@hooks/useConfigOverrideExpiration'
import { useDeviceType } from '@hooks/useDeviceType'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useNavigationFlowHandler } from '@hooks/useNavigationFlowHandler'
import { usePageChangeListener } from '@hooks/usePageChangeListener'
import { useReloadLangOnHistory } from '@hooks/useReloadLangOnHistory'
import { useScrollBackToTop } from '@hooks/useScrollBackToTop'
import { useStoreContext } from '@hooks/useStoreContext'
import { Log } from '@libs/Log'
import { altNavigation, landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { getSiteAbsolutePath } from '@libs/url'
import { usePageChecker } from '@libs/utils'
import { useAccessibilityContext } from '@providers/accessibilityProvider'
import { useResetContext } from '@providers/resetProvider'
import { baseContainerBottomPadding, baseContainerPadding } from '@styles/common'
import classnames from 'classnames'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { useEffect, useMemo, useState } from 'react'
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DeepPartial } from 'redux'
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components'
import { KioskSettingsOpener } from '../components/KioskAppClickCounterSection'

const AccessibilityManager = () => {
  const { focusVisible } = useAccessibilityContext()
  useEffect(() => {
    document.body.classList.toggle('focus-visible', focusVisible)
    document.body.classList.toggle('focus-hidden', !focusVisible)
  }, [focusVisible])
  return null
}

const GlobalContainerCss = createGlobalStyle<{ isAlternativeNavigation?: boolean }>`
  #root,
  .vk-panel,
  .base-layout {
    block-size: 100%;
  }

  main.content {
    ${tablet('block-size: auto')}
    ${landscape('block-size: auto')}
  }

  .vk-panel {
    background: ${({ theme }) => theme.colors.primaryLight};
  ${({ theme }) =>
    altNavigation(`
      background-image: linear-gradient(0deg, ${theme.colors.primaryLight} ${ADA_SCREEN_HEIGHT}, ${theme.colors.primaryDark} ${ADA_SCREEN_HEIGHT});
    `)};
  }

  ${DrawerContainer} {
    ${({ isAlternativeNavigation }) =>
      isAlternativeNavigation &&
      `
        inset-block-start: 56vh;
        overflow: hidden;
    `};
    min-block-size: ${({ isAlternativeNavigation }) => isAlternativeNavigation && 'unset'};
  }
`

const ContainerStyled = styled.div<{
  isAlternativeNavigation?: boolean
  preventAccessibilityToggleResize?: boolean
  preventScroll?: boolean
}>`
  position: relative;
  background: ${({ theme }) => theme.colors.primaryLight};
  display: flex;
  flex-direction: column;
  ${({ preventScroll }) => preventScroll && 'overflow: hidden;'}
  ${({ preventScroll }) =>
    tablet(`
    flex: 1;
    overflow: ${preventScroll ? 'hidden' : 'scroll'};
  `)}
  ${({ preventScroll }) =>
    landscape(`
    flex: 1;
    overflow: ${preventScroll ? 'hidden' : 'scroll'};
  `)}
  ${({ preventScroll }) =>
    altNavigation(`
    flex: 1;
    overflow: ${preventScroll ? 'hidden' : 'scroll'};
  `)}
  ${altNavigation(`
    inset-block-start: ${pxToRem(1076)};
    block-size: ${ADA_SCREEN_HEIGHT};
  `)}
`

interface ErrorBoundaryComponentProps extends WithTranslation {
  actionUrl: string
}

interface ErrorBoundaryComponentState {
  error: Error | null
}

// TODO: evaluate in remodernizing this component by using functional components and hooks

class ErrorBoundaryComponent extends React.Component<
  ErrorBoundaryComponentProps,
  ErrorBoundaryComponentState
> {
  constructor(props: ErrorBoundaryComponentProps) {
    super(props)
    this.state = {
      error: null,
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ error })
    Log.error(error, 'ReactError')
    Log.error(info.toString(), 'ReactError')
  }

  render() {
    if (this.state.error) {
      let extraProps = {}
      if (this.props.actionUrl) {
        extraProps = {
          actionLabel: this.props.t('Core.goToHomePage'),
          action: () => {
            window.location.href = this.props.actionUrl
          },
        }
      }
      return <ErrorWidget type="500" errors={[this.state.error]} {...extraProps} withWrapper />
    }
    return this.props.children
  }
}

const ErrorBoundary = withTranslation('translations')(ErrorBoundaryComponent)
interface BaseContainerProps {
  content: JSX.Element
  header?: JSX.Element
  headerLogoUrl?: string
  className?: string
  showBackButton?: boolean
  showFilterButton?: boolean
  preventAccessibilityToggleResize?: boolean
  renderFilterDrawer?: JSX.Element
  logoColor?: LogoColor
  showDisclaimer?: boolean
  showPrivacyPolicyTooltip?: boolean
  showHeader?: boolean
  showMenu?: boolean
  showDisclaimerShadow?: boolean
  floatingDisclaimer?: boolean
  floatingHeader?: boolean
  withPadding?: boolean
  showDiscount?: boolean
  showGreenShipment?: boolean
  disclaimerColor?: Maybe<keyof DeepPartial<DefaultTheme['colors']>>
  privacyPolicyIconColor?: string
  hasDynamicContentHeight?: boolean
  preventScroll?: boolean
  withHeight?: boolean
  fixedPrivacyPolicyIcon?: boolean
  showFrameAdvisorProfile?: boolean
  showBannerOnScroll?: boolean
  resetNavigationOnLogoClick?: boolean
  showBackToTopBtn?: boolean
  kioskAppSettingsOpener?: boolean
  forceContainerRef?: boolean
  onScroll?: (event: React.UIEvent<HTMLElement>) => void
}

export interface ChildContainerProps {
  withPadding: boolean
  showStripeBanner: boolean
  withHeight?: boolean
}

export const ChildContainer = styled.div<ChildContainerProps>`
  min-block-size: ${({ showStripeBanner }) =>
    showStripeBanner ? `calc(100% - ${pxToRem(63)})` : '100%'};
  inline-size: 100vw;
  ${({ showStripeBanner, withHeight }) =>
    !showStripeBanner && withHeight ? 'block-size: 100%' : ''};
  ${({ withPadding }) => withPadding && `${baseContainerPadding} ${baseContainerBottomPadding}`}
`

export const Main = styled.main<{
  hasDynamicContentHeight?: boolean
  preventScroll?: boolean
}>`
  position: relative;
  z-index: 0;
  flex: 1;
  overflow: ${({ preventScroll }) => (preventScroll ? 'hidden' : 'auto')};
  ${({ hasDynamicContentHeight }) =>
    tablet(`
    block-size: auto;
    overflow: unset;
    ${hasDynamicContentHeight ? 'display: table' : ''};
`)}
  ${({ hasDynamicContentHeight }) =>
    landscape(`
    block-size: auto;
    overflow: unset;
    ${hasDynamicContentHeight ? 'display: table' : ''};
`)}
  ${({ hasDynamicContentHeight }) =>
    altNavigation(`
    block-size: auto;
    overflow: unset;
    ${hasDynamicContentHeight ? 'display: table' : ''};
`)}
`

export const HeaderWrapper = styled.header<{
  floatingHeader: boolean
  showStripeBanner?: boolean
  isHidden?: boolean
}>`
  position: ${({ floatingHeader }) => (floatingHeader ? 'absolute' : 'relative')};
  z-index: ${({ floatingHeader }) => (floatingHeader ? 1 : 0)};
  flex-shrink: 0;
  inline-size: 100%;
  block-size: ${({ floatingHeader, showStripeBanner }) =>
    floatingHeader ? 0 : showStripeBanner ? pxToRem(170) : pxToRem(134)};
  ${({ floatingHeader }) => (floatingHeader ? 0 : `${landscape(`block-size: ${pxToRem(160)}`)}`)};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  ${tablet(`block-size: ${clampValues(90, 160)}`)}
  ${landscape(Device.tablet, `block-size: ${clampValuesLandscape(80, 115)}`)}
`

const FirstInteractionScreen = styled.div`
  z-index: 200;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
`

const BaseContainer = React.forwardRef(
  (
    {
      header,
      headerLogoUrl,
      showHeader = true,
      content,
      className,
      showMenu = true,
      showBackButton = true,
      showFilterButton = true,
      preventAccessibilityToggleResize = false,
      renderFilterDrawer,
      showDisclaimer = false,
      showPrivacyPolicyTooltip = true,
      showDisclaimerShadow = false,
      floatingDisclaimer = false,
      disclaimerColor,
      floatingHeader = true,
      withPadding = true,
      showDiscount = false,
      showGreenShipment = false,
      hasDynamicContentHeight = false,
      logoColor,
      privacyPolicyIconColor,
      preventScroll = false,
      fixedPrivacyPolicyIcon = false,
      withHeight = true,
      showFrameAdvisorProfile,
      showBannerOnScroll,
      resetNavigationOnLogoClick = false,
      showBackToTopBtn = false,
      kioskAppSettingsOpener = false,
      forceContainerRef,
      onScroll,
    }: BaseContainerProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { i18n } = useTranslation()
    const { setVisibleDrawer } = useDrawerHandler()
    const { barcodeFlow, customerOrderFlow } = useNavigationFlowHandler()
    const headerComponent = showHeader
      ? header || (
          <Header
            resetNavigationOnClick={resetNavigationOnLogoClick}
            showFrameAdvisorProfile={showFrameAdvisorProfile}
            logoColor={logoColor}
            logoUrl={headerLogoUrl}
          />
        )
      : null
    const mainClassName = `content${showHeader ? '' : 'no-header'}`
    const { isAlternativeNavigation } = useNavigationContext()
    const { isTowerPortrait } = useDeviceType()
    const store = useStoreContext()
    const { isHomePage } = usePageChecker()
    const { userInteractedAfterReset } = useResetContext()

    const [clientX, setClientX] = useState<number | undefined>()
    const [clientY, setClientY] = useState<number | undefined>()

    const handleFirstInteractionScreenClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setClientX(e.clientX)
      setClientY(e.clientY)
    }

    const showDiscountBanner = showDiscount && config.toggleFeature.discountBanner
    const isGreenShipmentAvailable = !!store.shippingMethods.find(method => method.code === 'GREEN')
    const showGreenShipmentBanner = showGreenShipment && isGreenShipmentAvailable

    const stripeBannerIsVisible = useSelector(state => state.ui.stripeBannerIsVisible)
    const arePdpTabsOnTop = useSelector(state => state.ui.arePdpTabsOnTop)
    const showStripeBanner = showDiscountBanner || showGreenShipmentBanner
    const { isScrolled, scrollBackToTop, setRef } = useScrollBackToTop()

    usePageChangeListener()
    useConfigOverrideExpiration()
    useReloadLangOnHistory()
    useSimulateFirstInteraction(clientX, clientY)

    useEffect(() => {
      if ((barcodeFlow && isHomePage) || customerOrderFlow) {
        setVisibleDrawer('search')
      }
    }, [barcodeFlow, customerOrderFlow, isHomePage, setVisibleDrawer])

    // Toggle class for selected language
    useEffect(() => {
      const currentLang = i18n.language.split('-')[0]
      const langFixPrefix = 'lang-fix-'
      if (!new RegExp(`${langFixPrefix}${currentLang}`).test(document.body.className)) {
        document.body.className = document.body.className.replace(
          new RegExp(`${langFixPrefix}[a-z]{2}`),
          ''
        )
        document.body.classList.add(`${langFixPrefix}${currentLang}`)
      }
    }, [i18n.language])

    const renderStripeBanner = () => {
      // greenshipment has the priority over the discount banner
      if (showGreenShipmentBanner) {
        return <GreenShipmentBanner />
      }

      if (showDiscount) {
        return <DiscountBanner isSticky={showBannerOnScroll} />
      }
    }

    const isContainerRef = useMemo(() => {
      return forceContainerRef ?? !isTowerPortrait
    }, [isTowerPortrait, forceContainerRef])

    return (
      <>
        <GlobalContainerCss isAlternativeNavigation={isAlternativeNavigation} />
        <ErrorBoundary actionUrl={getSiteAbsolutePath()}>
          <AccessibilityManager />
          <ContainerStyled
            preventScroll={preventScroll}
            className={classnames('base-layout', className)}
            isAlternativeNavigation={isAlternativeNavigation}
            preventAccessibilityToggleResize={preventAccessibilityToggleResize}
            id={SCROLL_RESTORATION_ID}
            onScroll={event => {
              if (onScroll) onScroll(event)
            }}
            {...(isContainerRef && { ref })}
          >
            {/* anchor for the BackToTop CTA for all devices and orientations except Tower Portrait */}
            {!isTowerPortrait && showBackToTopBtn && <div id={TOP_ANCHOR_ID} ref={setRef} />}
            {headerComponent && (
              <HeaderWrapper
                floatingHeader={floatingHeader}
                showStripeBanner={showStripeBanner && stripeBannerIsVisible}
                isHidden={arePdpTabsOnTop}
              >
                {headerComponent}
                {/* This component is needed to open the settings page on the kiosk app. It is visible only for tower and it will trigger a kiosk app specific event only if window contains .api  */}
                {kioskAppSettingsOpener && <KioskSettingsOpener />}
              </HeaderWrapper>
            )}
            <Main
              preventScroll={preventScroll}
              className={mainClassName}
              id="main"
              hasDynamicContentHeight={hasDynamicContentHeight}
              onScroll={event => {
                if (onScroll) onScroll(event)
              }}
              {...(!isContainerRef && { ref })}
            >
              {/* anchor for the BackToTop CTA for Tower Portrait */}
              {isTowerPortrait && showBackToTopBtn && <div id={TOP_ANCHOR_ID} ref={setRef} />}
              {renderStripeBanner()}
              <div id="portal" className="portal" />
              <ChildContainer
                showStripeBanner={showStripeBanner}
                withPadding={withPadding}
                withHeight={withHeight}
              >
                {content}
              </ChildContainer>
              {isScrolled && <ScrollToTopButton onClick={scrollBackToTop} />}
            </Main>
            {showDisclaimer && (
              <Disclaimer
                withShadow={showDisclaimerShadow}
                floatingDisclaimer={floatingDisclaimer}
                disclaimerColor={disclaimerColor}
              />
            )}
            {showPrivacyPolicyTooltip && (
              <PrivacyPolicyTooltip
                stroke={privacyPolicyIconColor}
                isFixed={fixedPrivacyPolicyIcon}
              />
            )}
            {isHomePage && !userInteractedAfterReset && (
              <FirstInteractionScreen onClick={handleFirstInteractionScreenClick} />
            )}
            {showMenu && (
              <FloatingActions
                showBackButton={showBackButton}
                showFilterButton={showFilterButton}
              />
            )}
            <AccessibilityToggle />
            <MenuDrawer />
            {renderFilterDrawer}
            <MiniCartDrawer />
            <ToastNotification />
          </ContainerStyled>
        </ErrorBoundary>
      </>
    )
  }
)
BaseContainer.displayName = 'BaseContainer'

export default BaseContainer
