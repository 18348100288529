import { Heading5 } from '@components/core/Typography'
import { Maybe } from 'graphql/jsutils/Maybe'
import React from 'react'
import { DetailRowContainer, DetailRowContainerFull, Value } from './styles'

export const DetailsRow: React.FC<{
  label: string
  value?: Maybe<string>
  full?: boolean
}> = ({ label, value, full }) => {
  const Container = full ? DetailRowContainerFull : DetailRowContainer

  return value ? (
    <Container>
      <Heading5 weight="bold">{label}</Heading5>
      <Value>{value}</Value>
    </Container>
  ) : null
}

export default DetailsRow
