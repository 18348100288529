import { IconProps } from '@abstractTypes/icons'

const MinimizesColorDistortion: React.FC<IconProps> = ({ width = '1rem', height = '1rem' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 76 74"
      fill="none"
    >
      <path
        d="M40.8404 1.62492C40.2289 0.633626 39.1657 0.041626 38.0001 0.041626C36.8345 0.041626 35.7713 0.632084 35.1598 1.62338C28.5479 12.3426 13.1667 38.4461 13.1667 49.3333C13.1667 62.9339 24.3076 74 38.0001 74C51.6926 74 62.8334 62.9339 62.8334 49.3333C62.8334 38.4461 47.4523 12.3426 40.8404 1.62492ZM38.0001 70.9167V3.12496C38.1258 3.12496 38.177 3.20821 38.1941 3.23442C48.6055 20.1095 59.7292 41.0808 59.7292 49.3333C59.7292 61.235 49.9822 70.9167 38.0001 70.9167Z"
        fill="#222222"
      />
    </svg>
  )
}

export default MinimizesColorDistortion
