import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const goTheme: ThemeOverride = {
  colors: {
    primaryDark: '#000000',
    primaryDarkPressed: '#4C4C4C',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#FBFBFB',
    inactive: '#C1C1C2',
    outline: '#8E8F93',
    background: '#D9D9D9',
    highlight01: '#5F5F5F',
    highlight01Pressed: '#3A3939',
    highlight02: '#000000',
    highlight02Pressed: '#8E8F93',
    highlight03: '#C3C800',
    highlight04: '#504BD6',
    functional01: '#E8583F',
    functional02: '#5DB363',
    functional03: '#FFBC57',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 85,
        tablet: 68,
        tabletMini: 60,
      },
      lineHeight: {
        tower: 109,
        tablet: 86,
        tabletMini: 67,
      },
    },
    heading1: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 60,
        tablet: 56,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 68,
        tablet: 62,
        tabletMini: 60,
      },
    },
    heading2: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 40,
        tablet: 40,
        tabletMini: 34,
      },
      lineHeight: {
        tower: 48,
        tablet: 48,
        tabletMini: 46,
      },
    },
    heading3: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 34,
        tablet: 34,
        tabletMini: 28,
      },
      lineHeight: {
        tower: 44,
        tablet: 44,
        tabletMini: 36,
      },
    },
    heading4: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 36,
        tablet: 36,
        tabletMini: 30,
      },
    },
    heading5: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 26,
        tablet: 26,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 24,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 24,
        tablet: 24,
        tabletMini: 18,
      },
    },
    cta: {
      fontFamily: 'Poppins',
      weight: {
        regular: 600,
        bold: 600,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 26,
      },
    },
    link: {
      fontFamily: 'Poppins',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 30,
        tablet: 30,
        tabletMini: 26,
      },
    },
    caption: {
      fontFamily: 'Poppins',
      weight: {
        regular: 600,
      },
      fontSize: {
        tower: 13,
        tablet: 13,
        tabletMini: 11,
      },
      lineHeight: {
        tower: 16,
        tablet: 16,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'rounded',
      uppercase: true,
    },
    rangeSlider: {
      background: '#8E8F93', //outline
      foreground: '#000000', //primaryDark
      thumb: '#D9D9D9', //background
      thumbBorder: '#C1C1C2', //inactive
    },
  },
}
