import { Device } from '@abstractTypes/utils'
import { PrimaryButton, SecondaryButton } from '@components/core/Button'
import { Modal } from '@components/core/Modal'
import { Heading5 } from '@components/core/Typography'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { flexGapPolyfill, pxToRem } from '@libs/styled'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalBody, ModalContent, ModalFooter, ModalWrapper } from '../Modal/styles'
import { ResetNavigationModalProps } from './types'

const ModalResetNavigation = styled(Modal)`
  ${altNavigation(`
    inset-block-start: ${pxToRem(154)};
  `)}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    inset-block-start: 0;
    block-size: 100%;
  `
  )}

  ${ModalContent} {
    ${altNavigationZoom(
      [150, 175, 200],
      `
      block-size: 100%;
    `
    )}
  }

  ${ModalBody} {
    ${altNavigationZoom(
      [150, 175, 200],
      `
      inline-size: 100%;
    `
    )}
  }

  ${ModalWrapper} {
    ${altNavigationZoom(
      [150, 175, 200],
      `
      block-size: fit-content;
      margin-block: auto ;
      margin-inline:  0;
    `
    )}
  }

  ${ModalFooter} {
    ${tablet(`
      padding-block: ${pxToRem(48)} 0;
      padding-inline: 0;
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-block: ${pxToRem(48)} 0;
      padding-inline: 0;
    `
    )}
    ${zoomPortrait(
      [150, 175, 200],
      `
      flex-direction: column;
      ${flexGapPolyfill(pxToRem(16), 'column')};
      padding-block: ${pxToRem(48)} 0;
      padding-inline: ${pxToRem(135)};
    `
    )}
    ${zoomLandscape(
      [150, 175],
      `
      flex-direction: row;
      ${flexGapPolyfill(pxToRem(24), 'row')};
      padding-block: ${pxToRem(48)} 0;
      padding-inline:  0 ;
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      padding-block: ${pxToRem(48)} 0;
      padding-inline:  ${pxToRem(200)} ;
    `
    )}
  }
`

export const ResetNavigationModal: React.FC<ResetNavigationModalProps> = ({
  visible,
  onCancel,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <ModalResetNavigation
      visible={visible}
      onCancel={onCancel}
      showHeader={false}
      dataCalls="0"
      footerContent={[
        <SecondaryButton
          key="cancel"
          className="cancel"
          onClick={onCancel}
          dataCalls="0"
          ariaAttrs={{
            label: t('Reset.ctaCancel'),
            description: t('Reset.ctaCancel'),
          }}
          fullWidth
        >
          {t('Reset.ctaCancel')}
        </SecondaryButton>,

        <PrimaryButton
          key="confirm"
          dataAttrs={{
            id: 'MainNav_MenuResetYes',
            reset: true,
          }}
          onClick={onClick}
          ariaAttrs={{
            label: t('Reset.ctaConfirm'),
            description: t('Reset.ctaConfirm'),
          }}
          fullWidth
        >
          {t('Reset.ctaConfirm')}
        </PrimaryButton>,
      ]}
    >
      <Heading5 aria-label={t('Reset.content')} aria-description={t('Reset.content')}>
        {t('Reset.content')}
      </Heading5>
    </ModalResetNavigation>
  )
}
