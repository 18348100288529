import {
  BarCodeScanModalDescription,
  BarCodeScanModalSubTitle,
  BarCodeScanModalTitle,
  BarCodeScanModalWrapper,
  ImageModalProductImageContainer,
  LogoContainer,
  RayBanCustomLogo,
} from '@components/BarCodeScanModal/styles'
import ConditionalRender from '@components/ConditionalRender'
import { ContentImage } from '@components/Image'
import { Logo } from '@components/Logo'
import { OakleyCustomIcon } from '@components/core/Icons'
import { isOOBrand } from '@libs/custom'
import { getProductImageGallery } from '@libs/image'
import React from 'react'
import { BarcodeScanModalBaseProps } from './types'
import { BarcodeDebugFlag } from './BarcodeDebugFlag'

export const BarcodeScanModalBase: React.FC<BarcodeScanModalBaseProps> = ({
  visible,
  formattedBrand,
  customizable,
  thumbnails,
  onCancel,
  title,
  subtitle,
  description,
  isCapitalizedTitle = false,
  footerContent,
  titleWeight,
  showCloseButton = true,
}) => {
  const isOakley = isOOBrand(formattedBrand)

  const renderCustomLogo = () => (
    <>{isOakley ? <OakleyCustomIcon fill={'#d0021b'} /> : <RayBanCustomLogo />}</>
  )

  const renderLogo = () => (
    <LogoContainer>{customizable ? renderCustomLogo() : <Logo />}</LogoContainer>
  )

  const imageSource = getProductImageGallery(thumbnails, 797, false)[0]
  return (
    <BarCodeScanModalWrapper
      visible={visible}
      onCancel={onCancel}
      showCloseButton={showCloseButton}
      title={renderLogo()}
      aria={{ labelledby: title }}
      footerContent={footerContent}
      {...(showCloseButton && { dataCalls: '0' })}
    >
      <div>
        {<BarcodeDebugFlag />}
        <ConditionalRender
          condition={subtitle}
          render={subtitle => <BarCodeScanModalSubTitle>{subtitle}</BarCodeScanModalSubTitle>}
        />

        <BarCodeScanModalTitle
          aria-label={title}
          aria-description={title}
          isCapitalized={isCapitalizedTitle}
          weight={titleWeight}
          isReducedMargin={!!subtitle}
        >
          {title}
        </BarCodeScanModalTitle>
        <ImageModalProductImageContainer>
          <ContentImage imageSource={imageSource} />
        </ImageModalProductImageContainer>
        <ConditionalRender
          condition={!!description}
          render={() => (
            <BarCodeScanModalDescription aria-label={description} aria-description={description}>
              {description}
            </BarCodeScanModalDescription>
          )}
        />
      </div>
    </BarCodeScanModalWrapper>
  )
}
