import { ProductInfoConfig, ProductInfoConfigScenario } from '@abstractTypes/tiles'
import config from '@config/config.base'
import { chanelBrandId } from '@data'
import { useThemeContext } from '@hooks/useThemeContext'
import { isOOBrand, isRBBrand } from '@libs/custom'
import { useCallback, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

const getProductInfoConfigByScenario = (
  productInfoConfigScenario: ProductInfoConfigScenario,
  brandCode: string,
  theme: DefaultTheme
): ProductInfoConfig => {
  let cb

  if (config.isMonoBrand) {
    if (isRBBrand(brandCode)) {
      cb = productInfoConfigScenario.monobrand?.rb
    } else if (isOOBrand(brandCode)) {
      cb = productInfoConfigScenario.monobrand?.oo
    }
  } else {
    switch (brandCode) {
      case chanelBrandId:
        cb = productInfoConfigScenario.multibrand?.ch
        break
      default:
        cb = productInfoConfigScenario.multibrand?.default
        break
    }
  }

  if (!cb) {
    cb = productInfoConfigScenario.default
  }

  return cb(theme)
}

export const useProductInfoConfig = (productInfoConfigScenario: ProductInfoConfigScenario) => {
  const theme = useThemeContext()
  const getProductInfoConfigByScenarioCb = useCallback(
    (brandCode: string): ProductInfoConfig => {
      return getProductInfoConfigByScenario(productInfoConfigScenario, brandCode, theme)
    },
    [productInfoConfigScenario, theme]
  )

  return useMemo(
    () => ({
      getProductInfoConfig: getProductInfoConfigByScenarioCb,
    }),
    [getProductInfoConfigByScenarioCb]
  )
}
