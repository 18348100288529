import { EngravingReduxState, Side } from '@abstractTypes/engraving'
import {
  ADD_ENGRAVING,
  DELETE_ENGRAVING,
  EDIT_ENGRAVING,
  EngravingAction,
} from '@actions/engraving'

const initialState: EngravingReduxState = {
  items: {},
  engravingConstraints: {},
}

/**
 * Check whether there is almost an engraving text or not.
 *
 * @param {array} sides - The array of sides
 * @return {(boolean)}
 */
const checkSidesValues = (sides: Side[]) => sides.find(e => e.text.trim())

export default (state: EngravingReduxState = initialState, action: EngravingAction) => {
  switch (action.type) {
    case ADD_ENGRAVING: {
      const { payload } = action

      const items = { ...state.items }
      if (checkSidesValues(payload.details.sides)) {
        items[payload.itemId] = { ...payload.details }
      }
      return {
        ...state,
        items,
        engravingConstraints: {
          ...state.engravingConstraints,
          [payload.itemId]: payload.engravingConstraints,
        },
      }
    }

    case EDIT_ENGRAVING: {
      const { payload } = action

      const { [payload.itemId]: value, ...items } = state.items

      if (checkSidesValues(payload.details.sides)) {
        items[payload.itemId] = { ...payload.details }
      }

      return {
        ...state,
        items,
      }
    }

    case DELETE_ENGRAVING: {
      const { payload } = action

      const { [payload.itemId || 0]: value, ...items } = state.items

      return {
        ...state,
        items,
      }
    }

    default:
      return state
  }
}
