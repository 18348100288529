import { Brand, Menu } from '@abstractTypes/graphqlTypes'
import { MenuItem } from '@components/MainMenu/MenuContent'
import { convertToCamelCaseAndStripSpecials } from '@libs/formatters'

export function createMenuContent(menu: Menu, brands: Brand[], baseUrl: string): MenuItem[] {
  const menuGroup = menu.items.map<MenuItem>(menuItem => {
    if (menuItem?.submenu) {
      switch (menuItem.submenu.__typename) {
        case 'Menu': {
          return {
            label: menuItem.text || '',
            items: createMenuContent(menuItem.submenu, brands, baseUrl),
            analyticsData: menuItem.analyticsData,
            iconId: menuItem.iconId,
          }
        }
        default:
          return {
            label: menuItem.text || '',
            items: brands.map(({ name, url }) => ({
              label: name,
              href: url,
              analyticsData: {
                dataElementId: 'MainNavMenu',
                dataDescription:
                  (`${menuItem.analyticsData?.dataDescription}` || '') +
                  convertToCamelCaseAndStripSpecials(name, true),
              },
            })),
            analyticsData: menuItem.analyticsData,
            iconId: menuItem.iconId,
          }
      }
    }

    return {
      label: menuItem?.text || '',
      href: menuItem?.link && menuItem.link.url,
      analyticsData: menuItem?.analyticsData || menuItem?.link?.analyticsData,
      iconId: menuItem?.link?.icon,
    }
  })

  return menuGroup
}
