import React from 'react'
import { Checkbox, CheckboxDescription, CheckboxLabel, CheckboxWithLabelWrapper } from './styles'

export type CheckboxWithLabelProps = React.ComponentProps<typeof Checkbox> & {
  elementKey: string
  className?: string
  dataAttr?: { test?: string }
  color?: string
  description?: React.ReactElement | string
}

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  className,
  children,
  disabled,
  elementKey,
  dataAttr,
  color,
  description,
  ...props
}) => {
  return (
    <>
      <CheckboxWithLabelWrapper
        className={className}
        disabled={disabled}
        data-test={dataAttr?.test}
        tabIndex={0}
      >
        <Checkbox
          name="checkbox"
          disabled={disabled}
          {...props}
          id={`${elementKey}-checkbox`}
          color={color}
          tabIndex={-1}
        />
        <CheckboxLabel
          as={'label'}
          htmlFor={`${elementKey}-checkbox`}
          aria-hidden="true"
          aria-disabled="true"
          disabled={disabled}
        >
          {children}
        </CheckboxLabel>
      </CheckboxWithLabelWrapper>
      {description && (
        <>
          {typeof description === 'string' ? (
            <CheckboxDescription disabled={disabled}>{description}</CheckboxDescription>
          ) : (
            description
          )}
        </>
      )}
    </>
  )
}
