import { initialAccessibilityKeyboardContext } from '@providers/accessibilityProvider'
import { useCallback, useEffect, useState } from 'react'

const pointerEvents: (keyof DocumentEventMap)[] = [
  'mousemove',
  'mousedown',
  'pointermove',
  'pointerdown',
  'touchmove',
  'touchstart',
]

const focusNavigationKeys = ['Tab', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight']

const useKeyboardCheck = (enableKeyboardCheck: boolean, enableFocusVisible: boolean) => {
  const [keyboardCheckState, setKeyboardCheckState] = useState(initialAccessibilityKeyboardContext)

  const onPointerMove = useCallback(() => {
    if (keyboardCheckState.userInteractingWithKeyboard) {
      setKeyboardCheckState({
        ...keyboardCheckState,
        focusVisible: false,
        userInteractingWithKeyboard: false,
      })
    }
  }, [keyboardCheckState, setKeyboardCheckState])

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!focusNavigationKeys.includes(e.key)) {
        return
      }

      if (!keyboardCheckState.userInteractingWithKeyboard) {
        setKeyboardCheckState({
          ...keyboardCheckState,
          focusVisible: enableFocusVisible,
          userInteractingWithKeyboard: true,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keyboardCheckState, setKeyboardCheckState]
  )

  useEffect(() => {
    if (enableKeyboardCheck) {
      pointerEvents.forEach(eventType => document.addEventListener(eventType, onPointerMove))
      document.addEventListener('keydown', onKeyDown)
    }
    return () => {
      pointerEvents.forEach(eventType => document.removeEventListener(eventType, onPointerMove))
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [enableKeyboardCheck, onPointerMove, onKeyDown])

  return {
    keyboardCheckState,
    onKeyDown,
  }
}

export default useKeyboardCheck
