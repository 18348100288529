import { NuanceTheme } from 'styled-components'

const nuanceFontFamily = 'TTHoves'

const colors: NuanceTheme['colors'] = {
  primaryDark: '#202027',
  primaryDarkPressed: '#404040',
  primaryLight: '#FFFFFF',
  primaryLightPressed: '#F6F6F6',
  inactive: '#C1C1C1',
  outline: '#E8E8E8',
  background: '#F6F6F6',
  highlight01: '#2d2a26',
  highlight01Pressed: '#47423C',
  highlight02: '#B58B34',
  highlight02Pressed: '#A88335',
  highlight03: '#2d2a26',
  highlight04: '#B58B34',
  functional01: '#DE2C2C',
  functional02: '#4DA145',
  functional03: '#1465B4',
  secondary: '#3D50FA',
}

const fonts: NuanceTheme['fonts'] = {
  heading0: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 85,
      tablet: 68,
      tabletMini: 48,
    },
    lineHeight: {
      tower: 109,
      tablet: 86,
      tabletMini: 68,
    },
  },
  heading1: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 45,
      tablet: 42,
      tabletMini: 36,
    },
    lineHeight: {
      tower: 50,
      tablet: 44,
      tabletMini: 44,
    },
  },
  heading2: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 40,
      tablet: 36,
      tabletMini: 32,
    },
    lineHeight: {
      tower: 44,
      tablet: 44,
      tabletMini: 38,
    },
  },
  heading3: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
      bold: 600,
    },
    fontSize: {
      tower: 35,
      tablet: 36,
      tabletMini: 30,
    },
    lineHeight: {
      tower: 48,
      tablet: 44,
      tabletMini: 40,
    },
  },
  heading4: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
      bold: 600,
    },
    fontSize: {
      tower: 24,
      tablet: 28,
      tabletMini: 30,
    },
    lineHeight: {
      tower: 34,
      tablet: 38,
      tabletMini: 40,
    },
  },
  heading5: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
      bold: 600,
    },
    fontSize: {
      tower: 22,
      tablet: 22,
      tabletMini: 18,
    },
    lineHeight: {
      tower: 33,
      tablet: 28,
      tabletMini: 28,
    },
  },
  body: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
      bold: 600,
    },
    fontSize: {
      tower: 20,
      tablet: 16,
      tabletMini: 16,
    },
    lineHeight: {
      tower: 26,
      tablet: 22,
      tabletMini: 22,
    },
  },
  label: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 16,
      tablet: 14,
      tabletMini: 14,
    },
    lineHeight: {
      tower: 22,
      tablet: 20,
      tabletMini: 18,
    },
  },
  cta: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
      bold: 500,
    },
    fontSize: {
      tower: 18,
      tablet: 16,
      tabletMini: 16,
    },
    lineHeight: {
      tower: 22,
      tablet: 20,
      tabletMini: 20,
    },
  },
  caption: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 600,
    },
    fontSize: {
      tower: 14,
      tablet: 12,
      tabletMini: 12,
    },
    lineHeight: {
      tower: 16,
      tablet: 14,
      tabletMini: 14,
    },
  },
  link: {
    fontFamily: nuanceFontFamily,
    weight: {
      regular: 400,
    },
    fontSize: {
      tower: 18,
      tablet: 16,
      tabletMini: 16,
    },
    lineHeight: {
      tower: 34,
      tablet: 34,
      tabletMini: 34,
    },
  },
}

export default {
  colors,
  fonts,
}
