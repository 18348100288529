import { CmsAnalyticsData } from '@abstractTypes/graphqlTypes'
import { clearPreselectedFilters } from '@actions/ui'
import config from '@config/index'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useIsDefaultPlp } from '@hooks/useFilters'
import { Maybe } from 'graphql/jsutils/Maybe'
import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import MenuListWrapper from '../MenuListWrapper'
import { MenuItem, MenuList, MenuTitle } from '../styles'
import { MenuListItem } from './MenuListItem'
import { MenuGroupProps } from './types'

export interface MenuItem {
  label: string
  href?: Maybe<string>
  items?: MenuItem[]
  analyticsData?: Maybe<CmsAnalyticsData>
  iconId?: Maybe<string>
}

export const MenuGroup: React.FC<MenuGroupProps> = ({ menu, onSelectMenu, isTopLevel }) => {
  const dispatch = useDispatch()
  const { closeAnyDrawer } = useDrawerHandler()
  const { setIsDefaultPlp } = useIsDefaultPlp()

  const onSelect = useCallback(() => {
    dispatch(clearPreselectedFilters())
    setIsDefaultPlp(false)
    closeAnyDrawer()
  }, [closeAnyDrawer, dispatch])
  const isListMenuStyle = config.menuStyleVariant === 'LIST'

  return menu ? (
    <div>
      {isTopLevel && <MenuTitle>{menu.label}</MenuTitle>}
      {menu.items && (
        <MenuList isTopLevel={isTopLevel}>
          {menu.items.map((item, index) => {
            const isLastLevel = !isTopLevel && !isEmpty(item.items)

            const hasLink = !!item.href
            const hasSubmenu = item.items && item.items.length > 0

            if (!hasLink && !hasSubmenu) {
              return null
            }

            const isHierarchySecondLevel =
              !isTopLevel && config.default.productSubdivision === 'productHierarchy'

            return (
              <MenuItem key={`${index}-${item.label}`}>
                {isListMenuStyle && isLastLevel ? (
                  <MenuListWrapper
                    menu={menu}
                    item={item}
                    onSelectMenu={onSelectMenu}
                    onSelect={onSelect}
                  />
                ) : (
                  <MenuListItem
                    menu={menu}
                    item={item}
                    onSelect={onSelect}
                    onSelectMenu={onSelectMenu}
                    isHierarchySecondLevel={isHierarchySecondLevel}
                  />
                )}
              </MenuItem>
            )
          })}
        </MenuList>
      )}
    </div>
  ) : null
}

interface MenuContentProps {
  menu: MenuItem
  onSelect: (menu: MenuItem) => void
  isTopLevel: boolean
}

export const MenuContent: React.FC<MenuContentProps> = ({ menu, onSelect, isTopLevel }) => {
  return (
    <MenuGroup
      menu={menu}
      onSelectMenu={(menu: MenuItem) => {
        onSelect(menu)
      }}
      isTopLevel={isTopLevel}
    />
  )
}
