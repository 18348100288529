import { OrderActionTypes, SET_ORDER } from '@actions/order'

export interface OrderState {
  orderNumber: string | undefined
  posReceiptId: string | undefined
}

const initialState: OrderState = {
  orderNumber: undefined,
  posReceiptId: undefined,
}

export default (state = initialState, action: OrderActionTypes) => {
  const { payload } = action

  switch (action.type) {
    case SET_ORDER: {
      return {
        orderNumber: payload.orderNumber,
        posReceiptId: payload.posReceiptId,
      }
    }

    default:
      return state
  }
}
