import { ActiveFilters } from '@abstractTypes/analytics'
import { ProductTypeValues } from '@abstractTypes/filter'
import ToggleItems, { TogglableItem } from '@components/ToggleItems'
import { PRODUCT_TYPE } from '@constants/product'
import { useApplyFilters } from '@hooks/useFilters'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ProductTypeTogglerProps {
  className?: string
  currentValue: ProductTypeValues
  preselectedFilters?: ActiveFilters[]
  dataElementId?: string
  onChange?: (value?: ProductTypeValues) => void
}

export const ProductTypeToggler: React.FC<ProductTypeTogglerProps> = ({
  currentValue,
  preselectedFilters = [],
  onChange,
}) => {
  const { t } = useTranslation()

  const toggleSunProduct = useApplyFilters([
    ...preselectedFilters,
    { kind: PRODUCT_TYPE, value: ProductTypeValues.SUN },
  ])
  const toogleOpticalProduct = useApplyFilters([
    ...preselectedFilters,
    { kind: PRODUCT_TYPE, value: ProductTypeValues.OPTICAL },
  ])

  const sunItem: TogglableItem<ProductTypeValues> = {
    onChange: value => {
      onChange && onChange(value)
      toggleSunProduct()
    },
    value: ProductTypeValues.SUN,
    label: t('Core.sunglasses'),
  }
  const opticalItem: TogglableItem<ProductTypeValues> = {
    onChange: value => {
      onChange && onChange(value)
      toogleOpticalProduct()
    },
    value: ProductTypeValues.OPTICAL,
    label: t('Core.eyeglasses'),
  }

  return (
    <ToggleItems<ProductTypeValues>
      itemA={sunItem}
      itemB={opticalItem}
      currentValue={currentValue}
    />
  )
}
