import {
  FaPlaylistType,
  FrameAdvisorUserProfile,
  LoveHatesData,
  UndoData,
} from '@abstractTypes/frameAdvisor'
import { ContentV2PlaylistItem } from '@abstractTypes/graphqlTypes'
import { StandardProduct } from '@abstractTypes/product'
import config from '@config/config.base'
import {
  ADD_LOVE_HATE_MOCO,
  ADD_TO_MYSELECTION,
  FrameAdvisorActionTypes,
  REMOVE_FROM_MYSELECTION,
  REMOVE_LOVE_HATE_MOCO,
  REMOVE_MOCO_FROM_UNDO,
  RESET_FSA,
  RESET_LOVE_HATE,
  RESET_SURVEY,
  SAVE_FSA_INFO,
  SAVE_FSA_TOKENS,
  SAVE_FSA_USERID,
  SAVE_FSA_VIDEOID,
  SAVE_MOCO_FOR_UNDO,
  SELECT_FRAME_ADVISOR_PLAYLIST,
  SELECT_FRAME_ADVISOR_SHADES,
  SET_COMPLETE_SURVEY,
  SET_FRAME_ADVISOR_MYSELECTION_VISITED,
  SET_LAST_SURVEY_FACE_STEP,
  SET_SELECTED_OPTION_ID,
  SET_SENTENCE_MAP,
  TOGGLE_DRESSED_ON,
  TOGGLE_WISHLIST_DRESSED_ON,
  UNDO_HATE_ITEM,
  UPDATE_SURVEY,
  UPDATE_VISITED,
} from '../actions/frameAdvisor'

export interface Survey {
  playlistType: FaPlaylistType
  gender?: string
  face_shape?: string
  face_length?: string
}
export interface FrameAdvisorState {
  isFrameAdvisorVisited: boolean
  isDressedOnEnabled: boolean
  survey: Survey
  isSurveyComplete: boolean
  loveHate: LoveHatesData[]
  mySelection: StandardProduct[]
  fsaVideoId: string | null
  fsaUserId: string | null
  fsaInfo: FrameAdvisorUserProfile | null
  fsaAccessToken: string | null
  fsaRefreshToken: string | null
  selectedPlaylist: ContentV2PlaylistItem | null
  shadesFor: string | null
  mySelectionVisited: boolean
  isWishlistDressedOnEnabled: boolean
  undoableList: UndoData[]
  selectedOptionIds: Record<string, string>
  lastFaceStepSlug: string
  sentenceMap: Record<string, number>
}

const initialState: FrameAdvisorState = {
  isFrameAdvisorVisited: false,
  survey: {
    playlistType: config.default.faPlaylistType,
    gender: undefined,
    face_shape: undefined,
    face_length: undefined,
  },
  isSurveyComplete: false,
  loveHate: [],
  mySelection: [],
  fsaVideoId: null,
  fsaUserId: null,
  fsaInfo: null,
  fsaAccessToken: null,
  fsaRefreshToken: null,
  isDressedOnEnabled: false,
  selectedPlaylist: null,
  shadesFor: null,
  mySelectionVisited: false,
  isWishlistDressedOnEnabled: false,
  undoableList: [],
  selectedOptionIds: {},
  lastFaceStepSlug: '',
  sentenceMap: {},
}

export default (state = initialState, action: FrameAdvisorActionTypes): FrameAdvisorState => {
  switch (action.type) {
    case UPDATE_SURVEY: {
      return {
        ...state,
        survey: {
          ...state.survey,
          [action.payload.key]: action.payload.tag,
        },
      }
    }

    case SET_LAST_SURVEY_FACE_STEP: {
      return {
        ...state,
        lastFaceStepSlug: action.payload,
      }
    }

    case SET_COMPLETE_SURVEY: {
      return {
        ...state,
        isSurveyComplete: action.payload,
      }
    }

    case RESET_SURVEY: {
      return {
        ...state,
        survey: initialState.survey,
        isSurveyComplete: initialState.isSurveyComplete,
      }
    }

    case ADD_LOVE_HATE_MOCO: {
      const newLoveHate = state.loveHate.filter(lh => lh.moco !== action.payload.moco)
      newLoveHate.push(action.payload)
      return {
        ...state,
        loveHate: newLoveHate,
      }
    }

    case REMOVE_LOVE_HATE_MOCO: {
      const newLoveHate = state.loveHate.filter(lh => lh.moco !== action.payload.moco)
      return {
        ...state,
        loveHate: newLoveHate,
      }
    }

    case SAVE_MOCO_FOR_UNDO: {
      const newUndoableList = state.undoableList.filter(
        (lh: UndoData) => lh.moco !== action.payload.moco
      )
      newUndoableList.push(action.payload)
      return {
        ...state,
        undoableList: newUndoableList,
      }
    }

    case REMOVE_MOCO_FROM_UNDO: {
      const newUndoableList = state.undoableList.filter(
        (lh: UndoData) => lh.moco !== action.payload.moco
      )
      return {
        ...state,
        undoableList: newUndoableList,
      }
    }

    case UNDO_HATE_ITEM: {
      const newUndoableList = state.undoableList.filter(
        (lh: UndoData) => lh.moco !== action.payload.moco
      )
      const newLoveHateList = state.loveHate.filter(lh => lh.moco !== action.payload.moco)
      return {
        ...state,
        undoableList: newUndoableList,
        loveHate: newLoveHateList,
      }
    }

    case RESET_LOVE_HATE: {
      return {
        ...state,
        loveHate: initialState.loveHate,
      }
    }

    case UPDATE_VISITED: {
      return {
        ...state,
        isFrameAdvisorVisited: action.payload.visited,
      }
    }

    case ADD_TO_MYSELECTION: {
      const newMySelection = state.mySelection.filter(i => i.moco !== action.payload.product.moco)
      newMySelection.push(action.payload.product)
      return {
        ...state,
        mySelection: newMySelection,
      }
    }

    case REMOVE_FROM_MYSELECTION: {
      const newMySelection = state.mySelection.filter(i => i.moco !== action.payload.moco)
      return {
        ...state,
        mySelection: newMySelection,
      }
    }

    case SAVE_FSA_VIDEOID: {
      return {
        ...state,
        fsaVideoId: action.payload.videoId,
      }
    }

    case SAVE_FSA_TOKENS: {
      return {
        ...state,
        fsaAccessToken: action.payload.fsaAccessToken,
        fsaRefreshToken: action.payload.fsaRefreshToken,
      }
    }

    case SAVE_FSA_INFO: {
      return {
        ...state,
        fsaInfo: { ...action.payload },
      }
    }

    case RESET_FSA: {
      return {
        ...state,
        fsaVideoId: null,
        fsaUserId: null,
        fsaAccessToken: null,
      }
    }

    case TOGGLE_DRESSED_ON: {
      return {
        ...state,
        isDressedOnEnabled: !state.isDressedOnEnabled,
      }
    }

    case TOGGLE_WISHLIST_DRESSED_ON: {
      return {
        ...state,
        isWishlistDressedOnEnabled: !state.isWishlistDressedOnEnabled,
      }
    }

    case SELECT_FRAME_ADVISOR_PLAYLIST: {
      return {
        ...state,
        selectedPlaylist: action.payload.playlist,
      }
    }

    case SELECT_FRAME_ADVISOR_SHADES: {
      return {
        ...state,
        shadesFor: action.payload.shadesFor,
      }
    }
    case SET_FRAME_ADVISOR_MYSELECTION_VISITED: {
      return {
        ...state,
        mySelectionVisited: true,
      }
    }
    case SET_SELECTED_OPTION_ID: {
      const { key, id } = action.payload
      return {
        ...state,
        selectedOptionIds: {
          ...state.selectedOptionIds,
          [key]: id,
        },
      }
    }
    case SET_SENTENCE_MAP: {
      return {
        ...state,
        sentenceMap: action.payload.sentenceMap,
      }
    }
    case SAVE_FSA_USERID: {
      return {
        ...state,
        fsaUserId: action.payload.userId,
      }
    }
    default:
      return state
  }
}
