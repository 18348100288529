import { AppConfigOverride } from '@abstractTypes/envs'

const getGviEnvVariables = () => {
  const gviEnvVariables: AppConfigOverride = {
    shopperSlug: 'gvi',
    shopperName: 'GRAND VISION ITALIA',
    storeID: '14',
    storeLang: 'it-IT',
    toggleFeature: { customerOrderError: true },
    vmStore: 'gvi',
    vmStyle: 'gvi',
  }

  return gviEnvVariables
}

export default getGviEnvVariables
