import { Product } from '@abstractTypes/product'
import { useStoreContext } from '@hooks/useStoreContext'
import { usePrescriptionEnabled } from '@hooks/usePrescriptionEnabled'

const useShowPurchaseModal = (product: Product) => {
  const { pdpRealtimeCheckEnabled, eyeglassesEnabled } = useStoreContext()
  const disabledInStore = !pdpRealtimeCheckEnabled && !eyeglassesEnabled
  const isPrescriptionEnabled = usePrescriptionEnabled()

  if (disabledInStore) return false
  return Boolean(isPrescriptionEnabled && product.roxable)
}

export default useShowPurchaseModal
