import { StandardProduct } from '@abstractTypes/product'
import { CartMerchRecommenderAccordion } from '@components/CartMerchRecommender/CartMerchRecommenderAccordion'
import { CartMerchRecommenderList } from '@components/CartMerchRecommender/CartMerchRecommenderList'
import {
  CartMerchRecommenderProps,
  CartMerchRecommenderViewProps,
} from '@components/CartMerchRecommender/types'
import { useCartRecommendedAccessories } from '@data'
import { useDeviceType } from '@hooks/useDeviceType'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { isIPadView, usePageChecker } from '@libs/utils'
import { FC, useMemo } from 'react'

const CartMerchRecommender: FC<CartMerchRecommenderProps> = ({ cartItems }) => {
  const matchesWithData = useCartRecommendedAccessories(cartItems.map(c => c.product))

  const products = matchesWithData.data?.cartRecommendedAccessories?.products
  const showRecommender = products && products.length > 0

  return showRecommender ? (
    <CartMerchRecommenderContent products={products as StandardProduct[]} />
  ) : null
}

const CartMerchRecommenderContent: FC<CartMerchRecommenderViewProps> = ({ products }) => {
  const { zoomLevel, isAlternativeNavigation } = useNavigationContext()
  const { isTowerPortrait } = useDeviceType()
  const { isCartPage } = usePageChecker()

  const isAccordion = useMemo(() => {
    if (isIPadView()) return true
    if (zoomLevel < 150 && !(isAlternativeNavigation && isCartPage)) return true
    if (zoomLevel < 200 && isTowerPortrait) return true
    return false
  }, [isAlternativeNavigation, isCartPage, isTowerPortrait, zoomLevel])

  const showRecommender = products.length > 0

  return showRecommender ? (
    <>
      {isAccordion ? (
        <CartMerchRecommenderAccordion products={products} />
      ) : (
        <CartMerchRecommenderList products={products} />
      )}
    </>
  ) : null
}

export default CartMerchRecommender
