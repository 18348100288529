import { IconProps } from '@abstractTypes/icons'

export const PillowIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 0C30.768 0 27.896 0.00500011 26.716 2.66C25.027 1.817 22.973 1.816 21.283 2.659C20.104 0.00499988 17.231 0 11 0C2 0 0 0 0 8C0 16 2 16 11 16C20 16 22 16 22 8C22 6.678 21.938 5.592 21.806 4.671C23.118 3.811 24.882 3.811 26.194 4.671C26.062 5.592 26 6.678 26 8C26 16 28 16 37 16C46 16 48 16 48 8C48 0 46 0 37 0ZM18.827 13.297C17.684 14 14.88 14 11 14C7.12 14 4.316 14 3.173 13.297C2.564 12.922 2 12.021 2 8C2 3.979 2.564 3.078 3.173 2.703C4.316 2 7.12 2 11 2C14.88 2 17.684 2 18.827 2.703C19.436 3.078 20 3.979 20 8C20 12.021 19.436 12.922 18.827 13.297ZM37 14C40.88 14 43.684 14 44.827 13.297C45.436 12.922 46 12.021 46 8C46 3.979 45.436 3.078 44.827 2.703C43.684 2 40.88 2 37 2C33.12 2 30.316 2 29.173 2.703C28.564 3.078 28 3.979 28 8C28 12.021 28.564 12.922 29.173 13.297C30.316 14 33.12 14 37 14Z"
        fill={fill}
      />
    </svg>
  )
}
