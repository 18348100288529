export enum ShippingMethodCode {
  GREEN = 'GREEN',
  EXPEDITED = 'EXPEDITED',
  EXPRESS = 'EXPRESS',
  STANDARD = 'STANDARD',
  POBOX = 'POBOX',
  MILITARY = 'MILITARY',
}

export enum ShippingDestinations {
  store = 'store',
  home = 'home',
}
