import { Device } from '@abstractTypes/utils'
import { altNavigationZoom, landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

export const ProductGrid = styled.div<{ isDressedOn?: boolean }>`
  display: grid;
  grid-gap: ${pxToRem(48)} ${pxToRem(24)};
  grid-template-columns: repeat(
    ${({ theme }) => theme.productGridColumns.tower},
    minmax(${pxToRem(303)}, 1fr)
  );
  inline-size: 100%;

  ${({ theme, isDressedOn }) => css`
    ${landscape(`
      grid-template-columns: ${
        isDressedOn
          ? `repeat(${theme.productGridColumns.towerLandscapeDressedOn}, minmax(0, 1fr))`
          : `repeat(${theme.productGridColumns.towerLandscape}, minmax(0, 1fr))`
      };
      grid-gap: 0 ${pxToRem(24)};
    `)}
    ${tablet(
      `
      grid-template-columns: repeat(${theme.productGridColumns.ipad}, minmax(0, 1fr));
      grid-gap: 0 ${clampValues(18, 24)};
    `
    )}
    ${landscape(
      Device.tablet,
      `
      grid-template-columns: repeat(${theme.productGridColumns.ipadLandscape}, minmax(0, 1fr));
      grid-gap: ${clampValuesLandscape(30, 40)} ${clampValuesLandscape(18, 24)};
    `
    )}
    ${zoomPortrait(
      [150, 175, 200],
      `
      grid-template-columns: repeat(${theme.productGridColumns.towerZoom}, minmax(0, 1fr));
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      grid-template-columns: ${
        isDressedOn
          ? `repeat(${theme.productGridColumns.towerLandscape}, minmax(0, 1fr))`
          : `repeat(${theme.productGridColumns.towerLandscapeZoom}, minmax(0, 1fr))`
      };
      grid-gap: 0 ${pxToRem(24)};
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      grid-template-columns: ${
        isDressedOn
          ? `repeat(${theme.productGridColumns.altNavZoomDressedOn}, minmax(0, 1fr))`
          : `repeat(${theme.productGridColumns.altNavZoom}, minmax(0, 1fr))`
      };
    `
    )}
  `}
`
