import { isHTMLElement } from '@libs/utils'
import { useCallback, useRef } from 'react'

export const useAriaFocusCbWithRestore = () => {
  const prevFocusElementRef = useRef<HTMLElement | null>(null)
  const refInternal = useRef<HTMLElement | null>(null)
  const setRef = useCallback((node: HTMLElement | null) => {
    if (!node) return
    refInternal.current = node
  }, [])

  const focus = useCallback(() => {
    const target = refInternal.current
    if (!target) return
    const prevFocusElement = document.activeElement
    if (isHTMLElement(prevFocusElement)) {
      prevFocusElementRef.current = prevFocusElement
    }
    target.focus({ preventScroll: true })
  }, [])

  const restoreFocus = useCallback(() => {
    const prevFocusElement = prevFocusElementRef.current
    if (!prevFocusElement) return
    prevFocusElement.focus({ preventScroll: true })
  }, [])

  return { focus, restoreFocus, setRef }
}
