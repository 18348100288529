import { IconProps } from '@abstractTypes/icons'

export const AccessibilityIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4483 19.7223L37.1361 21.2779L37.1412 33.6016L43.1064 53.5128C43.4209 54.77 42.6655 56.0279 41.4218 56.3385C40.1787 56.6491 38.9074 55.9644 38.5942 54.7071L32.4833 36.5826H30.6023L24.9812 55.0832C24.5107 56.2904 23.1663 56.8455 21.9604 56.3751C20.7661 55.9092 20.037 54.5499 20.5074 53.3428L25.671 33.8037V21.2779L14.3279 19.7344C13.1644 19.6408 12.3731 18.6159 12.4688 17.4517C12.5541 16.2786 13.613 15.4077 14.7765 15.5014L28.5564 16.688H34.5999L49.2743 15.4783C50.4411 15.4315 51.4294 16.3383 51.4679 17.5133C51.5154 18.68 50.6143 19.6741 49.4483 19.7223ZM31.5157 6.35547C33.8081 6.35547 35.6641 8.2108 35.6641 10.4884C35.6641 12.7808 33.8081 14.6361 31.5157 14.6361C29.2394 14.6361 27.3841 12.7801 27.3828 10.4884C27.3828 8.2108 29.2381 6.35547 31.5157 6.35547ZM32.0001 0C14.326 0 0 14.3266 0 32.0001C0 49.6728 14.326 64.0001 32.0001 64.0001C49.6735 64.0001 64.0002 49.6728 64.0002 32.0001C64.0002 14.3266 49.6729 0 32.0001 0Z"
        fill="black"
      />
    </svg>
  )
}
