import { SkinUndertone1Icon, SkinUndertone2Icon, SkinUndertone3Icon } from '@components/core/Icons'
import { Text } from '@components/core/Typography'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TipCardTitle, TipImageWrapper } from '../styles'

const SkinImageWrapper = styled(TipImageWrapper)`
  justify-content: space-evenly;
`

const SkinTipText = styled(Text)`
  inline-size: ${pxToRem(285)};
  max-inline-size: 100%;
`

const translationKey = 'FrameAdvisor.tips.faceAndSkinTip'
const iconSize = isIPadView() ? clampValues(53, 78) : pxToRem(78)

export const FaceAndSkinTipContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <TipCardTitle>{t(`${translationKey}.title`)}</TipCardTitle>
      <SkinImageWrapper>
        <SkinUndertone1Icon width={iconSize} height={iconSize} />
        <SkinUndertone2Icon width={iconSize} height={iconSize} />
        <SkinUndertone3Icon width={iconSize} height={iconSize} />
      </SkinImageWrapper>
      <SkinTipText>{t(`${translationKey}.text`)}</SkinTipText>
    </>
  )
}
