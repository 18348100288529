import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getGvifeEnvVariables = () => {
  const gvifeEnvVariables: AppConfigOverride = {
    shopperSlug: 'gvife',
    storeLang: 'en-US',
    shopperName: 'FOR EYES',
    storeID: '14',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    toggleFeature: {
      customerOrderError: true,
    },
    vmStore: 'gvife',
    vmStyle: 'gvife',
  }

  return gvifeEnvVariables
}

export default getGvifeEnvVariables
