import { AppConfigOverride } from '@abstractTypes/envs'

const getUatEnvVariables = (): AppConfigOverride => {
  const uatTestVariables = {
    fsaEnv: 'uat',
  }

  return uatTestVariables
}

export default getUatEnvVariables
