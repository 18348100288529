import { AppConfig, ShopperSlug, ShopperSlugWithFallback } from '@abstractTypes/envs'
import getBannerInfoByDns from '@libs/envs/getBannerInfoByDns'
import getCourtesySlugByBannerInfo from '@libs/envs/getCourtesySlugByBannerInfo'
import getBffSlugByBannerInfo from '@libs/envs/getServerSlugByBannerInfo'
import isLocalhost from '@libs/envs/isLocalhost'
import { merge } from 'lodash'
import getBannerEnvVariable from '..'
import getDeploymentEnvVariables from '../envs'
import { getBaseLocalhostEnvVariables, getBaseRemoteEnvVariables } from '../envs/base'

export const getEnvVariablesForBanner = () => {
  const shopperSlugFromProcessEnv = process.env.REACT_APP_SHOPPER_SLUG as ShopperSlug | undefined
  const shopperSlug: ShopperSlugWithFallback = shopperSlugFromProcessEnv || 'master'

  if (isLocalhost()) {
    const baseEnvVariables = getBaseLocalhostEnvVariables()
    const bannerEnvVariable = getBannerEnvVariable(shopperSlug, 'na')
    const deploymentEnvVariables = getDeploymentEnvVariables('local')
    const variables = merge(baseEnvVariables, bannerEnvVariable, deploymentEnvVariables())
    return variables
  }

  const bannerInfo = getBannerInfoByDns()
  const bffSlug = getBffSlugByBannerInfo(bannerInfo)
  const courtesySlug = getCourtesySlugByBannerInfo(bannerInfo)

  const baseEnvVariables = getBaseRemoteEnvVariables(bannerInfo.region, bffSlug, courtesySlug)
  const bannerEnvVariable = getBannerEnvVariable(bannerInfo.shopperSlug, bannerInfo.region)
  const deploymentEnvVariables = getDeploymentEnvVariables(bannerInfo.deploymentEnv)

  const envVariablesForBanner: AppConfig = merge(
    baseEnvVariables,
    bannerEnvVariable,
    deploymentEnvVariables()
  )
  return envVariablesForBanner
}
