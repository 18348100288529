import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const toTheme: ThemeOverride = {
  colors: {
    primaryDark: '#333333',
    primaryDarkPressed: '#54565A',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#F6F6F6',
    inactive: '#767676',
    outline: '#CED4D9',
    background: '#F6F6F6',
    highlight01: '#CC0000',
    highlight01Pressed: '#A50101',
    highlight02: '#489F2D',
    highlight02Pressed: '#C9E8BE',
    highlight03: '#489F2D',
    highlight04: '#CC0000',
    functional01: '#AB5820',
    functional02: '#489F2D',
    functional03: '#333333',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 85,
        tablet: 68,
        tabletMini: 60,
      },
      lineHeight: {
        tower: 109,
        tablet: 86,
        tabletMini: 67,
      },
    },
    heading1: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 60,
        tablet: 56,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 68,
        tablet: 62,
        tabletMini: 60,
      },
    },
    heading2: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 40,
        tablet: 40,
        tabletMini: 34,
      },
      lineHeight: {
        tower: 48,
        tablet: 48,
        tabletMini: 46,
      },
    },
    heading3: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 34,
        tablet: 34,
        tabletMini: 28,
      },
      lineHeight: {
        tower: 44,
        tablet: 44,
        tabletMini: 36,
      },
    },
    heading4: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 300,
        bold: 700,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 36,
        tablet: 36,
        tabletMini: 30,
      },
    },
    heading5: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 30,
        tablet: 26,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 24,
        tablet: 24,
        tabletMini: 18,
      },
    },
    cta: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    link: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 30,
        tablet: 30,
        tabletMini: 26,
      },
    },
    caption: {
      fontFamily: 'HelveticaNeue',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 13,
        tablet: 11,
        tabletMini: 11,
      },
      lineHeight: {
        tower: 16,
        tablet: 14,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'rounded',
      borderRadius: 64,
      uppercase: true,
    },
    rangeSlider: {
      background: '#767676', //inactive
      foreground: '#489F2D', //highlight02
      thumb: '#F6F6F6', //background
      thumbBorder: '#767676', //inactive
    },
  },
}
