import { SecondaryButton } from '@components/core/Button'
import { CheckboxWithLabel } from '@components/core/Checkbox'
import { Col } from '@components/core/Grid'
import { InputTypography, Label } from '@components/core/Typography'
import { StickyTitle } from '@components/plp/PLPTitle'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const SecondaryButtonStyled = styled(SecondaryButton)`
  padding-block: ${pxToRem(5)};
  padding-inline: ${pxToRem(30)};
`

export const DownloadButton = styled.a`
  padding-block: ${pxToRem(12)};
  padding-inline: ${pxToRem(20)};
  font-size: ${pxToRem(16)};
  background: ${({ theme }) => theme.colors.primaryDark};
  color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: ${pxToRem(8)};
  margin-inline-end: ${pxToRem(16)};
  &:active,
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.primaryDarkPressed};
    color: ${({ theme }) => theme.colors.primaryLightPressed};
  }
`

export const ConfigOverrideHeader = styled.div`
  background: ${({ theme }) => theme.colors.primaryLightPressed};
  inline-size: 100vw;
  min-inline-size: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 0;
  padding-inline: 1rem;
`

export const OverrideForm = styled.form`
  block-size: 100vh;
  overflow: scroll;

  ${StickyTitle} {
    padding-block-end: 1rem;
    padding-block-start: 0;
  }

  ${Col} {
    padding-block-end: ${pxToRem(8)};
  }

  ${Label} {
    font-weight: bold;
    font-size: ${pxToRem(20)};
  }

  ${InputTypography} {
    inline-size: 100%;
    border: 1px solid ${({ theme }) => theme.colors.primaryDark};
    border-radius: ${pxToRem(5)};
    font-size: ${pxToRem(18)};
    block-size: ${pxToRem(42)};
  }
`

export const FormWrapper = styled.div`
  padding-block: 0;
  padding-inline: 1rem;
`

export const Checkbox = styled(CheckboxWithLabel)`
  label {
    block-size: ${pxToRem(24)};
  }
`
