import { IconProps } from '@abstractTypes/icons'

export const FaceShapeRoundWomanIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61840)">
        <path
          d="M47.707 36.543C45.736 34.572 45.001 26.117 45 21.25C45 9.671 35.58 0.25 24 0.25C12.42 0.25 3 9.671 3 21.25C2.999 26.117 2.264 34.572 0.293 36.543L0 36.836V37.25C0 43.315 4.935 48.25 11 48.25C13.882 48.25 16.501 47.127 18.465 45.306C20.203 45.907 22.06 46.25 24 46.25C25.94 46.25 27.797 45.907 29.535 45.306C31.499 47.127 34.118 48.25 37 48.25C43.065 48.25 48 43.315 48 37.25V36.836L47.707 36.543ZM9 29.25V20.214C14.746 19.792 18.918 15.788 20.405 12.06C22.412 14.536 27.276 18.12 39 20.1V29.25C39 37.521 32.271 44.25 24 44.25C15.729 44.25 9 37.521 9 29.25ZM37 46.25C34.974 46.25 33.108 45.569 31.602 44.434C37.165 41.637 41 35.89 41 29.25V19.25L40.156 18.263C22.377 15.455 20.999 9.151 20.99 9.108L19 9.25C19 12.79 14.276 18.25 8 18.25L7 19.25V29.25C7 35.89 10.835 41.637 16.398 44.434C14.892 45.569 13.026 46.25 11 46.25C6.161 46.25 2.202 42.412 2.007 37.62C4.914 33.984 5 22.579 5 21.25C5 10.773 13.523 2.25 24 2.25C34.477 2.25 43 10.773 43 21.25C43 22.579 43.086 33.984 45.993 37.62C45.798 42.412 41.839 46.25 37 46.25Z"
          fill="#333333"
        />
        <path
          d="M26.4324 23.3066H24.4324V31.2496H21.9844V33.2496H26.4324V23.3066Z"
          fill="#333333"
        />
        <path
          d="M21.8775 38.371C22.4445 38.938 23.1975 39.25 23.9995 39.25C24.8015 39.25 25.5545 38.938 26.1215 38.371L27.3125 37.178L23.9995 36.208L20.6875 37.178L21.8775 38.371Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61840">
          <rect width={48} height={48} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
