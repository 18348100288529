import { BOM, Custom, OOCustomRecipe, RBCustomRecipe } from '@abstractTypes/customRecipe'
import { UndoData } from '@abstractTypes/frameAdvisor'
import { Store } from '@abstractTypes/graphqlTypes'
import {
  CustomOOProduct,
  CustomProduct,
  CustomRBProduct,
  StandardProduct,
} from '@abstractTypes/product'
import config from '@config/index'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { formatPageType } from '@libs/analytics'
import {
  getEngravingFromOneConfAttributes,
  getEngravingFromOneConfAttributesForOO,
  getThumbnailUrlForCustom,
} from '@libs/custom'
import { getLocalizedConfigurationFromBlob } from '@libs/oneConfigurator'
import { capitalize, get } from 'lodash'
import { useCallback } from 'react'

/**
 * Returns string with each word starts the first character to upper case
 * * @param {string}
 * * @returns {string}
 */
export const startCase = (str: string): string => {
  const separator = ' '
  return str
    .split(separator)
    .map(word => capitalize(word))
    .join(separator)
}

export const getPageType = (): string => {
  const [, , , , pageType, subPageType, extraPageData] = window.location.pathname.split('/')
  return formatPageType(pageType, subPageType, extraPageData)
}

export const stripSpecialCharacters = (
  stringToFormat: string,
  spacialCharactersRegex = /[\s$]/g
) => {
  const formattedString = stringToFormat.replace(spacialCharactersRegex, '')
  return formattedString
}

/**
 * Returns an object with the data required for the UNDO feature on Frame advisor
 * @returns {UndoData} UndoData object
 */
export const formatProductForUndo = (product: UndoData) => ({
  moco: product.moco,
  name: product.name,
  model: product.model,
  UPC: product.UPC,
  brand: product.brand,
})

// https://luxotticaretail.atlassian.net/wiki/spaces/ONECONF/pages/347209775/Technical+Specifications#TechnicalSpecifications-HowtogetBOMandPricingfromrecipe
const getPriceFromCustom = (custom: Custom) => (custom.pricing ? custom.pricing.finalPrice : 0)

export const formatCustomProduct = (
  recipe: OOCustomRecipe | RBCustomRecipe,
  store: Store
): Partial<CustomProduct> & { customItem: RBCustomRecipe | OOCustomRecipe } => {
  const { custom } = recipe
  const name: BOM['modelMetadata']['label'] = get(custom, 'bom.modelMetadata.label')
  const recipeId: BOM['recipeId'] = get(custom, 'bom.recipeId')
  const model: BOM['modelCode'] = get(custom, 'bom.modelCode')
  const currencyId = store.currency.id
  const formattedPrice = {
    id: `price-${model}`,
    current: getPriceFromCustom(custom),
    currency: currencyId,
  }

  return {
    recipeId,
    isCustomizable: true,
    name,
    model,
    moco: model,
    price: formattedPrice,
    customItem: recipe,
    availability: {
      id: `availability-${recipe.id}`,
      available: true,
      stock: 0,
      ecommStock: 1,
    },
    UPC: recipeId,
    vto: false,
    rtr: false,
    image360: false,
  }
}

export const formatCustomOOProduct = (recipe: OOCustomRecipe, store: Store): CustomOOProduct => {
  const originalBrandCode = 'OO'
  const brand = {
    id: 'oo',
    name: 'Oakley',
    url: `${store.url}/oo`,
    logo: {
      src: `${config.assets.URL}/brands/oakley.svg`,
      altText: 'Oakley Logo',
    },
  }

  const { custom, localized_configuration } = recipe
  const recipeId: BOM['recipeId'] = get(custom, 'bom.recipeId')
  const model: BOM['modelCode'] = get(custom, 'bom.modelCode')
  const greenShippingAvailable: BOM['greenShippingAvailable'] =
    get(custom, 'bom.greenShippingAvailable') ?? true
  // BBB: patch, if we don't get the greenShippingAvailable info from the configuratore we set it on true

  const customitemEngraving = getEngravingFromOneConfAttributesForOO(localized_configuration)

  const {
    lensColor,
    lensColor2,
    iconColor,
    iconColor2,
    frameColor,
    polarized = false,
    size,
  } = getLocalizedConfigurationFromBlob(recipe)

  return {
    ...formatCustomProduct(recipe, store),
    url: `custom/oo/${model}/${recipeId}`,
    type: 'OCP',
    customitemEngraving,
    originalBrandCode,
    brand,
    greenShippingAvailable,
    frameColorLabel: frameColor,
    lensColorLabel: lensColor,
    lensColor2,
    iconColorLabel: iconColor,
    iconColor2,
    polarized,
    image: getThumbnailUrlForCustom(recipeId, originalBrandCode),
    sizeFull: size,
  } as CustomOOProduct
}

export const formatCustomRBProduct = (recipe: RBCustomRecipe, store: Store): CustomRBProduct => {
  const originalBrandCode = 'RB'
  const brand = {
    id: 'rb',
    name: 'Ray-Ban',
    url: `${store.url}/rb`,
    logo: {
      src: `${config.assets.URL}/brands/ray-ban.svg`,
      altText: 'Ray-Ban Logo',
    },
  }

  const { custom, localized_configuration } = recipe
  const recipeId: BOM['recipeId'] = get(custom, 'bom.recipeId')
  const model: BOM['modelCode'] = get(custom, 'bom.modelCode')
  const greenShippingAvailable: BOM['greenShippingAvailable'] =
    get(custom, 'bom.greenShippingAvailable') ?? true
  // BBB: patch, if we don't get the greenShippingAvailable info from the configuratore we set it on true

  const customitemEngraving = getEngravingFromOneConfAttributes(localized_configuration)

  const {
    lensColor,
    frameColor,
    polarized = false,
    size,
  } = getLocalizedConfigurationFromBlob(recipe)
  return {
    ...formatCustomProduct(recipe, store),
    url: `custom/rb/${model}/${recipeId}`,
    customitemEngraving,
    originalBrandCode,
    brand,
    greenShippingAvailable,
    lensColorLabel: lensColor,
    frameColorLabel: frameColor,
    polarized,
    image: getThumbnailUrlForCustom(recipeId, originalBrandCode),
    sizeFull: size,
  } as CustomRBProduct
}

export const getLocaleCompareByProperty = <P extends PropertyKey>(property: P) => {
  return <T extends { [key in P]?: string | number | undefined | null }>(a: T, b: T) => {
    const av = a[property]
    const bv = b[property]
    if (typeof av === 'string' && typeof bv === 'string') {
      return av.localeCompare(bv)
    }
    if (typeof av === 'number' && typeof bv === 'number') {
      return av - bv
    }
    return 0
  }
}

export const notNull = <T>(x: T | null): x is T => x !== null

export const convertToCamelCaseAndStripSpecials = (str: string, firstUpperCase = false) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
      return index === 0 && !firstUpperCase ? match.toLowerCase() : match.toUpperCase()
    })
    .replace(/[^0-9a-zA-Z]+/, '')
}

export const mapAnalyticsShopperSlugs = (shopperSlug: string) => {
  switch (shopperSlug.toLowerCase()) {
    case 'salmoiraghi':
      return 'SV'
    case 'sghof':
      return 'SGH'
    case 'gvife':
      return 'FE'
    case 'gvi':
      return 'GV'
    case 'rayban':
      return 'RB'
    case 'oakley':
      return 'OO'
    default:
      return shopperSlug.toUpperCase()
  }
}

export const formatProductForCart = (
  product: StandardProduct,
  addPrescription = false,
  addMixMatch = false
) => {
  const disableGreenShipmentForCompletePair = true

  const cartItem = { ...product, completePair: addPrescription, mixMatch: addMixMatch }

  if (disableGreenShipmentForCompletePair && cartItem.completePair) {
    cartItem.greenShippingAvailable = false
  }

  return cartItem
}

// TODO: quick&dirty fix, to be removed and to be fixed on the BFF
export const useGetProductUrl = () => {
  const { basePath } = useStoreIndentity()

  return useCallback(
    (productUrl: string) => {
      const urlSeparator = '/'
      const formatedUrl = [basePath, productUrl].join(urlSeparator)
      return formatedUrl
    },
    [basePath]
  )
}
