import { Device } from '@abstractTypes/utils'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const SearchTabsWrapper = styled.div`
  max-inline-size: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
`

export const SearchFilterTab = styled.button<{ isActive?: boolean }>`
  margin-block-end: ${pxToRem(28)};
  margin-inline-end: ${pxToRem(32)};
  text-align: center;
  text-transform: capitalize;
  ${({ isActive }) => (isActive ? 'border-block-end: 2px solid' : null)};
  min-block-size: ${pxToRem(36)};
  ${({ theme, isActive }) => (isActive ? theme.colors.primaryDark : 'transparent')};
  ${tablet(
    `
    margin-inline-end: ${clampValues(24, 32)};
    margin-block-end: ${clampValues(21, 28)};
  `
  )}
  ${landscape(Device.tablet, `margin-block-end: ${clampValuesLandscape(21, 28)};`)}
`
