import { DrawerContainer } from '@components/Drawer/styles'
import { createGlobalStyle } from 'styled-components'
import { fonts } from './fonts'

export const GlobalStyle = createGlobalStyle`
  ${fonts}

  * {
    box-sizing: border-box;
    
    &:focus {
      box-shadow: ${props =>
        props.carouselAutoplayState
          ? `inset 0 0 0 3px ${props.theme.colors.functional01 || ''}`
          : 'none'};
      outline: 0;
    }
  }

  html, body {
    inline-size: 100%;
    block-size: 100%;
  }

  html {
    background: ${({ theme }) => theme.colors.primaryLight};
    color: ${({ theme }) => theme.colors.primaryDark};
    font-size: 16px;
    
  }

  .alt-navigation {
    ${DrawerContainer} {
      margin-block-start: 56vh;
      min-block-size: initial;
    }
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  body {
    overflow: hidden;
    -webkit-touch-callout: none !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  img, video {
    user-select: none !important;
  }
`
