import { IconProps } from '@abstractTypes/icons'

const ReducesEyeStrain: React.FC<IconProps> = ({ width = '1rem', height = '1rem' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 75 74"
      fill="none"
    >
      <g clipPath="url(#clip0_3198_44369)">
        <path
          d="M74.4101 35.5863C67.5359 22.1923 54.337 12.9808 39.0521 12.3904V0H35.9479V12.3904C20.663 12.9808 7.46408 22.1923 0.589906 35.5863C0.136698 36.4681 0.136698 37.5334 0.589906 38.4152C7.46408 51.8077 20.663 61.0192 35.9479 61.6096V74H39.0521V61.6096C54.337 61.0192 67.5359 51.8077 74.4101 38.4137C74.8633 37.5319 74.8633 36.4681 74.4101 35.5863ZM35.9479 49.227C29.8327 48.4638 25.0833 43.2777 25.0833 37C25.0833 30.7223 29.8327 25.5362 35.9479 24.773V49.227ZM39.0521 24.773C45.1673 25.5362 49.9167 30.7223 49.9167 37C49.9167 43.2777 45.1673 48.4638 39.0521 49.227V24.773ZM3.35572 36.9861C9.93345 24.1672 22.3222 16.0503 35.9479 15.4722V21.6604C28.1161 22.4359 21.9792 29.0188 21.9792 36.9985C21.9792 44.9781 28.1161 51.561 35.9479 52.3365V58.5247C22.3222 57.9497 9.93345 49.8313 3.35572 36.9861ZM39.0521 58.5278V52.3396C46.8839 51.5641 53.0208 44.9812 53.0208 37.0015C53.0208 29.0219 46.8839 22.439 39.0521 21.6635V15.4752C52.6778 16.0534 65.0666 24.1718 71.6443 37.017C65.0666 49.8328 52.6778 57.9497 39.0521 58.5278Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_3198_44369">
          <rect width="74.5" height="74" fill="white" transform="translate(0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ReducesEyeStrain
