import { resetSurvey as resetSurveyAction } from '@actions/frameAdvisor'
import { toggleLanguageSelectorList } from '@actions/ui'
import { AccordionSingle } from '@components/core/Accordion'
import { InlineWrapper } from '@components/core/Accordion/styles'
import { Heading4 } from '@components/core/Typography'
import { useAction } from '@hooks/useActions'
import { useClickOutside } from '@hooks/useClickOutside'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  LanguageSelectorList,
  LanguageSelectorListItem,
  LanguageSelectorWrapper,
  SelectedLanguage,
} from './styles'
import { LanguageSelectorProps } from './types'

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ languages }) => {
  const { t, i18n } = useTranslation()
  const wrapper = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const isLanguageListVisible = useSelector(({ ui }) => {
    return ui.languageSelectorIsVisible
  })
  const { langCode, storeType, storeId } = useStoreIndentity()
  const toggleLanguageSelector = useAction(toggleLanguageSelectorList)
  const resetSurvey = useAction(resetSurveyAction())
  const currentLang = langCode.split('-')[0]

  useClickOutside(wrapper, toggleLanguageSelector, isLanguageListVisible)

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    resetSurvey()
    history.push(`/${storeType}/${storeId}/${lang}`)
  }

  const getWeight = (lang: string) => (langCode == lang ? 'bold' : undefined)

  return (
    <LanguageSelectorWrapper ref={wrapper}>
      <AccordionSingle
        aria-label={t('LanguageSel.langSelector')}
        aria-description={t('LanguageSel.langSelector')}
        title={
          <InlineWrapper>
            <Heading4>
              {t('LanguageSel.langSelector')}
              {':'}
            </Heading4>
            <SelectedLanguage
              weight="bold"
              aria-label={`${t('LanguageSel.langSelector')}.${currentLang}`}
            >
              {currentLang.toUpperCase()}
            </SelectedLanguage>
          </InlineWrapper>
        }
      >
        <LanguageSelectorList>
          {languages.map((lang, i) => {
            return (
              <li key={i} tabIndex={0}>
                <LanguageSelectorListItem
                  data-element-id="MainNav_MenuLang"
                  data-test="MainNav_MenuLang"
                  onClick={() => changeLanguage(lang)}
                  weight={getWeight(lang)}
                  aria-label={t(`LanguageSel.${lang}`)}
                  aria-description={t(`LanguageSel.${lang}`)}
                >
                  {t(`LanguageSel.${lang}`)}
                </LanguageSelectorListItem>
              </li>
            )
          })}
        </LanguageSelectorList>
      </AccordionSingle>
    </LanguageSelectorWrapper>
  )
}
