import { AccordionStateProps } from '@abstractTypes/accordion'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const AccordionLayoutWrapper = styled.div`
  inline-size: 100%;
`

export const AccordionContent = styled.div<AccordionStateProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

export const AccordionTitle = styled.button`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  inline-size: 100%;
`
export const AccordionSubtitle = styled.span`
  margin-inline: auto ${pxToRem(32)};
`

export const AccordionIcon = styled.span<AccordionStateProps>`
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
  transition: transform 0.5s;
  display: flex;
`

export const InlineWrapper = styled.div`
  display: flex;
  align-items: center;
`
