import { IconProps } from '@abstractTypes/icons'

export const SearchIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9_1495)">
        <path
          d="M31.8047 30.862L23.21 22.2673C25.3513 19.902 26.6667 16.7753 26.6667 13.3333C26.6667 5.96933 20.6973 0 13.3333 0C5.96934 0 0 5.96933 0 13.3333C0 20.6973 5.96934 26.6667 13.3333 26.6667C16.7753 26.6667 19.902 25.3513 22.2673 23.21L30.862 31.8047L31.8047 30.862ZM13.3333 25.3333C6.71667 25.3333 1.33333 19.95 1.33333 13.3333C1.33333 6.71667 6.71667 1.33333 13.3333 1.33333C19.95 1.33333 25.3333 6.71667 25.3333 13.3333C25.3333 19.95 19.95 25.3333 13.3333 25.3333Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_9_1495">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
