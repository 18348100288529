import { FormErrors } from '@abstractTypes/checkout'
import { LocaleLanguage, PageChecker } from '@abstractTypes/common'
import { ProductTypeValues, SizeTypeValues } from '@abstractTypes/filter'
import { Brand, ContentV2Link } from '@abstractTypes/graphqlTypes'
import { ProductTileShape, StandardProduct } from '@abstractTypes/product'
import { StockItem } from '@abstractTypes/realTimeCheck'
import config from '@config/index'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { getLangFromUrl } from '@libs/url'
import { GraphQLError } from 'graphql'
import { TFunction } from 'i18next'
import CountryCode from 'iso-3166-1-alpha-2'
import LanguageCode from 'iso-639-1-zh'
import { debounce } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'
import Sniffr from 'sniffr'
import { chanelBrandId } from '../data'

/** Check whether the vKeyboard should be used or not
 * Vkeyboard is available only on desktop devices
 * and can be enable/disable by a configuration
 */
export const isVKeyboardAvailable = () => {
  if (isIPadView()) {
    return false
  }
  return config.toggleFeature.virtualKeyboard
}

/**
 * Returns string to camelCase without special characters
 * * @param {string}
 * * @param {boolean}
 * * @returns {string}
 */
export const uniqueId = () => Math.random().toString(36).substring(2, 15) + Date.now().toString(36)

/**
 * Check is input value is UPC
 * @param upc
 * @returns {boolean}
 */
export const isUPC = (upc: string): boolean => {
  const match = upc.match(/^[0-9]{8,13}$/)
  return !!match
}

/**
 * Returns opposite product type value (sun or optical)
 * @param productType
 * @returns {string}
 */
export const getOppositeProductType = (productType: ProductTypeValues): string => {
  if (productType === ProductTypeValues.OPTICAL) return ProductTypeValues.SUN
  return ProductTypeValues.OPTICAL
}

export const getProductNameFromPathname = () => {
  const [, product] = window.location.pathname.match(/\/product\/(.+?)\//) || []
  return product ? product.replace(/-/g, '') : ''
}

export const getProductModelFromPathname = () => {
  const [, product] = window.location.pathname.match(/\/product\/(.+?)\//) || []
  return product ? product.split('-')[0]?.toUpperCase() : ''
}

export const getCustomModelFromPathname = () => {
  const [pathname] = window.location.pathname.match(/\/custom\/(.+)/) || []
  const [, , , model] = pathname ? pathname.split('/') : []
  return model ? model : ''
}

export const getAnalyticsScriptSrc = (analyticsProfile: string, sessionId: string) => {
  return `//tags.tiqcdn.com/utag/luxottica/${analyticsProfile}/${getEnv()}/utag.js?v=${sessionId}`
}

export const dataDescriptionForProduct = (product: {
  brand: {
    name: string
  }
  name: string
  UPC: string
}) => `${product.brand.name}_${product.name}_${product.UPC}`

const getEnv = (): string => {
  const hostname = window.location.hostname
  const qaEnvSlugs = ['-dev', '-test', '-uat', '-stage', 'localhost', 'test-', 'qa-', 'beta-']
  const isQaEnv = qaEnvSlugs.some(slug => hostname.includes(slug))
  return isQaEnv ? 'qa' : 'prod'
}

export const isGraphQLError = (e: GraphQLError | Error): e is GraphQLError => 'extensions' in e

const REG = /^([a-z]{2})-([A-Z]{2})$/

export class LocaleCode {
  /* language iso-639-1 */
  static getLanguageCode(code: string): string {
    const match = code.match(REG)
    if (!match || match.length < 1) return ''
    return match[1]
  }

  static getLanguageName(code: string): string {
    const languageCode = LocaleCode.getLanguageCode(code)
    return LanguageCode.getName(languageCode)
  }

  static getLanguageNativeName(code: string): string {
    const languageCode = LocaleCode.getLanguageCode(code)
    return LanguageCode.getNativeName(languageCode)
  }

  static getLanguageZhName(code: string): string {
    const languageCode = LocaleCode.getLanguageCode(code)
    return LanguageCode.getZhName(languageCode)
  }

  static validateLanguageCode(code: string): string {
    const languageCode = LocaleCode.getLanguageCode(code)
    return LanguageCode.validate(languageCode)
  }

  static getLanguages(codes: string[]): LocaleLanguage[] {
    const list: LocaleLanguage[] = []
    for (let i = 0; i < codes.length; i++) {
      list.push({
        code: codes[i],
        name: LocaleCode.getLanguageName(codes[i]),
        nativeName: LocaleCode.getLanguageNativeName(codes[i]),
        zhName: LocaleCode.getLanguageZhName(codes[i]),
      })
    }
    return list
  }

  /* country iso-3166-1-alpha-2 */
  static getCountryCode(code: string): string {
    const match = code.match(REG)
    if (!match || match.length < 2) return ''
    return match[2]
  }
  static getCountryName(code: string): string {
    const countryCode = LocaleCode.getCountryCode(code)
    return CountryCode.getCountry(countryCode)
  }
  static validateCountryCode(code: string): boolean {
    code = LocaleCode.getCountryCode(code)
    if (CountryCode.getCodes().indexOf(code) === -1) {
      return false
    } else {
      return true
    }
  }

  /* validate */
  static validate(code: string): boolean {
    const match = code.match(REG)
    if (
      match &&
      match.length === 3 &&
      LocaleCode.validateLanguageCode(code) &&
      LocaleCode.validateCountryCode(code)
    ) {
      return true
    } else {
      return false
    }
  }
}

const pipe: PipeI =
  (...fns) =>
  x =>
    fns.reduce((v, f) => f(v), x)

export const useRefValue = <T>(value: T): React.MutableRefObject<T> => {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  })
  return ref
}

type FormatCodeI = (code: string) => string
type PipeI = (...fns: FormatCodeI[]) => FormatCodeI

const formatOOProductCode: FormatCodeI = (code: string): string => {
  return code.replace(/^(W|w)[G0]/gi, '')
}

const formatDefaultProductCode: FormatCodeI = (code: string): string => {
  return code[0] === '0' ? code.substring(1) : code
}

export const formatProductCode: FormatCodeI = (code: string): string => {
  return pipe(formatOOProductCode, formatDefaultProductCode)(code)
}

const getIosVersion = () => {
  const sniffr = new Sniffr()
  const sys = sniffr.sniff(navigator.userAgent)
  const isIpad = sys.os.name === 'ios'
  const version: number[] = sys.os.version
  return isIpad ? version : [0]
}

const compareSemanticVersion = (a: string, b: string) => {
  let diff = 0
  const regExStrip0 = /(\.0+)+$/
  const deviceVersion = a.replace(regExStrip0, '').split('.')
  const minVersion = b.replace(regExStrip0, '').split('.')
  const k = Math.min(deviceVersion.length, minVersion.length)
  for (let i = 0; i < k; ++i) {
    diff = parseInt(deviceVersion[i], 10) - parseInt(minVersion[i], 10)
    if (diff) {
      return diff
    }
  }
  return deviceVersion.length - minVersion.length
}

export const isIosVersionFrom = (version: string) => {
  const iOSVersion = getIosVersion().join('.')
  return compareSemanticVersion(iOSVersion, version) >= 0
}

/** Extract error message and translates it */
export const translateErrorMessage = (
  t: TFunction,
  name: keyof FormErrors,
  errors: FormErrors | undefined
) => {
  if (errors && errors[name]) {
    return t(errors[name])
  }
  return undefined
}

export const isFuzzyMatch = (s1: string, s2: string): boolean => {
  return (
    s1
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') ===
    s2
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  )
}

export const isIPadView = (): boolean => {
  const UA = window.navigator.userAgent.toLocaleLowerCase()
  // ipad is found where the option request desktop site is disabled
  // macintosh is found by default because request desktop site is enabled
  return UA.indexOf('ipad') !== -1 || UA.indexOf('macintosh') !== -1
}

export const getDataElementId = (link: ContentV2Link | undefined | null): string | undefined => {
  const dataElementId = link?.analyticsData?.dataElementId
  return dataElementId || undefined
}

export const calculateDurationForProgress = (duration = 0): string => {
  return `${duration / 1000}s`
}

export const toArray = <T>(arrayOrValue: T | T[] | undefined) => {
  return arrayOrValue ? ([] as T[]).concat(arrayOrValue) : []
}

export const usePageChecker = () => {
  const { basePath } = useStoreIndentity()
  return {
    isHomePage: !!useRouteMatch({
      path: basePath,
      exact: true,
    }),
    isCartPage: !!useRouteMatch({
      path: `${basePath}/cart`,
      exact: true,
    }),
    isCheckoutPage: !!useRouteMatch({
      path: `${basePath}/cart/checkout`,
      exact: true,
    }),
    isCustomerOrderErrorPage: !!useRouteMatch({
      path: `${basePath}/error-customer-order`,
      exact: true,
    }),
    isThankyouPage: !!useRouteMatch({
      path: `${basePath}/cart/checkout/thank-you`,
      exact: true,
    }),
    isElectronicsPage: !!useRouteMatch({
      path: `${basePath}/supernova-home`,
      exact: false,
    }),
    isPLPPage: !!useRouteMatch({
      path: [
        `${basePath}/products`,
        `${basePath}/products/:contenId`,
        `${basePath}/frame-advisor/playlist/:playlistId`,
      ],
      exact: true,
    }),
    isFrameAdvisorPage: !!useRouteMatch({
      path: `${basePath}/frame-advisor`,
      exact: false,
    }),
    isFrameAdvisorPlaylistPage: !!useRouteMatch({
      path: `${basePath}/frame-advisor/playlist/`,
      exact: true,
    }),
    isPrizmPage: !!useRouteMatch({
      path: `${basePath}/prizm`,
      exact: true,
    }),
    isPDP: !!useRouteMatch({
      path: `${basePath}/product/:model/:moco/:upc`,
      exact: true,
    }),
    isNuancePage: !!useRouteMatch({
      path: `${basePath}/nuance-home`,
      exact: true,
    }),
  } as PageChecker
}

export const getKeyboardLayout = (): string | void => {
  const lang = getLangFromUrl()
  return lang.split('-')[0]
}

/**
 * The returned function is executed only once and the result is
 * * @param {string}
 * * @returns {string}
 */
const executedList: Record<string, unknown> = {}
export function executeOnce<Args extends unknown[], Return>(
  cb: (...args: Args) => Return,
  id: string
) {
  return (...args: Args): Return => {
    const hasBeenExecuted = id in executedList
    if (hasBeenExecuted) return executedList[id] as Return

    const returnedValue = cb(...args)
    executedList[id] = returnedValue
    return returnedValue
  }
}

export const formatChildAgeRangeValue = (range: string) =>
  range
    .split('-')
    .map(n => n.padStart(2, '0'))
    .join('-')

export const isEmailValid = (email: string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export const isChanelBrand = (brand: Brand) => brand.id === chanelBrandId

export const isAvailableInStore = (availableInStore: boolean) => (product: StandardProduct) =>
  availableInStore ? Number(product.availability?.stock) > 0 : true

export const removeSpaces = (string: string) => string.replace(/ /g, '')

export const createMouseEvents = (mouseEventTitles: string[]) =>
  mouseEventTitles.map(
    eventTitle =>
      new MouseEvent(eventTitle, {
        view: window,
        bubbles: true,
        cancelable: true,
      })
  )

export const simulateEvents = (el: Element, events: Event[]) => {
  events.forEach(event => el.dispatchEvent(event))
}

export const valueToTuple = <T>(value: T) => [value, value]

export const isCurrentProductInStock = (
  stockItems: StockItem[],
  upc: string,
  shouldAllVariantsBeAvailable = false
) => {
  const filteredProducts = stockItems.filter(item => item.upc === upc)
  if (shouldAllVariantsBeAvailable) {
    return Boolean(filteredProducts.length && filteredProducts.every(item => !!item.qty))
  }
  return filteredProducts.some(item => !!item.qty)
}

export const isSquaredProductTile = () => config.productTileShape === ProductTileShape.SQUARED

export const isHTMLElement = (elem: Element | null): elem is HTMLElement =>
  elem instanceof HTMLElement && elem !== null

export const FOCUS_SELECTOR =
  'a:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), button:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'

const AUTOFOCUS_DELAY = 500
export const setAutofocusWithDelay = debounce((element: HTMLElement) => {
  element.focus({ preventScroll: true })
}, AUTOFOCUS_DELAY)

const SIZE_ORDER_MAP = [
  SizeTypeValues.XXS,
  SizeTypeValues.XS,
  SizeTypeValues.S,
  SizeTypeValues.M,
  SizeTypeValues.L,
  SizeTypeValues.XL,
  SizeTypeValues.XXL,
] as string[]
export const sizeComparator = (a?: string, b?: string) => {
  if (a && b) {
    const aNum = parseInt(a)
    const bNum = parseInt(b)
    const isNumA = !isNaN(aNum)
    const isNumB = !isNaN(bNum)
    if (isNumA && isNumB) return aNum - bNum
    if (isNumA) return -1
    if (isNumB) return 1
    return SIZE_ORDER_MAP.indexOf(a) - SIZE_ORDER_MAP.indexOf(b)
  }
  return 0
}
