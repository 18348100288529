import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const lpTheme: ThemeOverride = {
  colors: {
    primaryDark: '#1C354E',
    primaryDarkPressed: '#A3BBEE',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#F6F6F6',
    inactive: '#ADB7C2',
    outline: '#E4E5ED',
    background: '#F4F6F9',
    highlight01: '#4877DC',
    highlight01Pressed: '#A3BBEE',
    highlight02: '#77CFD9',
    highlight02Pressed: '#BBE7EC',
    highlight03: '#4877DC',
    highlight04: '#77CFD9',
    functional01: '#EC4220',
    functional02: '#4EC06E',
    functional03: '#F1C62B',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 85,
        tablet: 68,
        tabletMini: 60,
      },
      lineHeight: {
        tower: 109,
        tablet: 86,
        tabletMini: 67,
      },
    },
    heading1: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 60,
        tablet: 56,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 68,
        tablet: 62,
        tabletMini: 60,
      },
    },
    heading2: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 40,
        tablet: 40,
        tabletMini: 34,
      },
      lineHeight: {
        tower: 48,
        tablet: 48,
        tabletMini: 46,
      },
    },
    heading3: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 34,
        tablet: 34,
        tabletMini: 28,
      },
      lineHeight: {
        tower: 44,
        tablet: 44,
        tabletMini: 36,
      },
    },
    heading4: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 36,
        tablet: 36,
        tabletMini: 30,
      },
    },
    heading5: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 26,
        tablet: 26,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 24,
        tablet: 24,
        tabletMini: 18,
      },
    },
    cta: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 500,
        bold: 500,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 26,
      },
    },
    link: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 30,
        tablet: 30,
        tabletMini: 26,
      },
    },
    caption: {
      fontFamily: 'DM Sans',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 13,
        tablet: 13,
        tabletMini: 11,
      },
      lineHeight: {
        tower: 16,
        tablet: 16,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'rounded',
      uppercase: true,
      borderRadius: 8,
    },
    rangeSlider: {
      background: '#ADB7C2',
      foreground: '#77CFD9',
      thumb: '#FFFFFF',
      thumbBorder: '#E4E5ED',
    },
  },
}
