import { Device } from '@abstractTypes/utils'
import { AccordionLayout } from '@components/core/Accordion/AccordionLayout'
import { AccordionContent, AccordionTitle } from '@components/core/Accordion/styles'
import { PrimaryButton } from '@components/core/Button'
import { CheckboxWithLabelWrapper } from '@components/core/Checkbox/styles'
import { ListItem } from '@components/core/List'
import { InputTypography } from '@components/core/Typography'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  transformTranslateX,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const FilterWrapper = styled.div`
  padding-block: ${pxToRem(234)} 0;
  padding-inline: ${pxToRem(32)};
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  block-size: 100%;
  ${landscape(`
    padding-block-start: ${pxToRem(174)};
  `)}
  ${tablet(`
    padding-block-start: ${pxToRem(146)};
  `)}
  ${altNavigation(`
    padding-block-start: ${pxToRem(128)};
    block-size: ${pxToRem(700)};
    display: block;
    overflow: auto;
  `)}
  ${zoomPortrait(
    [175],
    `
      padding-block: ${pxToRem(351)} 0;
      padding-inline:  ${pxToRem(48)} ;
      block-size: ${pxToRem(1720)}
      display: block;
      overflow: auto;
    `
  )}
  ${zoomLandscape(
    [125],
    `
    block-size: ${pxToRem(930)}
  `
  )}
  ${zoomLandscape(
    [150, 175],
    `
    padding-block: ${pxToRem(261)}  0;
    padding-inline:  ${pxToRem(48)} ;
    block-size: ${pxToRem(880)}
    display: block;
    overflow: auto;
  `
  )}
  ${altNavigationZoom(
    [125, 150, 175],
    `
    padding-block: ${pxToRem(192)}  0;
    padding-inline:  ${pxToRem(48)};
    block-size: ${pxToRem(676)};
    display: block;
    overflow: auto;
  `
  )}
  ${zoomPortrait(
    [200],
    `
    padding-block: ${pxToRem(468)}  0;
    padding-inline:  ${pxToRem(64)};
    block-size: ${pxToRem(1720)}
    display: block;
    overflow: auto;
  `
  )}
  ${zoomLandscape(
    [200],
    `
    padding-block: ${pxToRem(236)}  ${pxToRem(0)};
    padding-inline:  ${pxToRem(64)} ;
    block-size: ${pxToRem(870)}
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    padding-block: ${pxToRem(168)}  0;
    padding-inline:  ${pxToRem(64)};
    block-size: ${pxToRem(686)}
    display: block;
    overflow: auto;
  `
  )}
`

export const FilterHeader = styled.div`
  margin-block-end: ${pxToRem(16)};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  ${tablet(`
    margin-block-end: ${pxToRem(32)};
  `)};
  ${zoomPortrait(
    [150, 175, 200],
    `
    margin-block-end: ${pxToRem(24)};
    `
  )}
`

export const FilterRecapButton = styled(PrimaryButton)`
  padding-block: ${pxToRem(16)};
  padding-inline: ${pxToRem(32)};
  inline-size: ${pxToRem(472)};
  margin: auto;

  ${tablet(`inline-size: ${clampValues(354, 472)};`)}
  ${altNavigation(`
    margin-inline-start: ${pxToRem(56)};
    position: absolute;
    inset-block-end: ${pxToRem(56)};
    z-index: 1;
    inset-inline-start: 50%;
    ${transformTranslateX('-50%')}
  `)}
  ${zoomPortrait(
    [150],
    `
    inline-size: ${pxToRem(708)};
    `
  )}
  ${zoomPortrait(
    [175],
    `
    position: absolute;
    inline-size: ${pxToRem(868)};
    inset-block-end: ${pxToRem(57)};
    z-index: 1;
    `
  )}
  ${zoomLandscape(
    [150, 175],
    `
    inline-size: ${pxToRem(868)};
    margin-inline-start: ${pxToRem(10)};
    position: absolute;
    inset-block-end: ${pxToRem(57)};
    z-index: 1;
    `
  )}
  ${zoomPortrait(
    [200],
    `
    position: absolute;
    inset-block-end: ${pxToRem(57)};
    inline-size: ${pxToRem(944)};
    z-index: 1;
    `
  )}
  ${zoomLandscape(
    [200],
    `
    margin-inline-start: ${pxToRem(112)};
    `
  )}
  ${altNavigationZoom(
    [125, 150, 175, 200],
    `
    margin-inline-start: 0
    `
  )}
  ${landscape(Device.tablet, `inline-size: ${clampValuesLandscape(354, 472)};`)}
`

export const FilterRecap = styled(ListItem)`
  padding-block: ${pxToRem(12)};
  padding-inline: 0;
  margin: ${pxToRem(30)} 0 ${pxToRem(16)};
  ${tablet(`
    margin: ${pxToRem(52)} 0 ${pxToRem(34)};
  `)}
  ${zoomPortrait(
    [150, 175],
    `
    padding-block: ${pxToRem(16)};
    margin: ${pxToRem(72)} 0 ${pxToRem(24)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
    padding-block: ${pxToRem(24)};
    margin: ${pxToRem(96)} 0 ${pxToRem(32)};
    `
  )}
`

export const CloseFilterButton = styled.button`
  display: flex;
  position: absolute;
  background: ${({ theme }) => theme.colors.primaryLight};
  padding: ${pxToRem(16)};
  z-index: 1;
  inset-block-start: ${pxToRem(156)};
  inset-inline-end: ${pxToRem(16)};
  ${tablet(`
    inset-block-start: ${pxToRem(42)};
  `)}
  ${landscape(`
    inset-block-start: ${pxToRem(100)};
  `)};
  ${landscape(
    Device.tablet,
    `
    inset-block-start: ${clampValuesLandscape(55, 103)};
  `
  )};
  ${zoomPortrait(
    [150, 175],
    `
    inset-block-start: ${pxToRem(234)};
    inset-inline-end: ${pxToRem(30)};
    `
  )}
  ${zoomLandscape(
    [150, 175],
    `
    inset-block-start: ${pxToRem(100)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
    inset-block-start: ${pxToRem(100)};
    inset-inline-end: ${pxToRem(42)};
    `
  )}
  ${altNavigation(`
    inset-block-start: ${pxToRem(40)};
  `)}
`

export const CheckboxListItem = styled(ListItem)`
  margin-block-end: ${pxToRem(45)};
  text-transform: capitalize;
  display: block;
  ${zoomPortrait(
    [150, 175],
    `
    margin-block-end: ${pxToRem(67.5)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
    margin-block-end: ${pxToRem(90)};
    `
  )}
`

export const FilterAccordionStyled = styled(AccordionLayout)`
  inline-size: 100%;
  padding-block: ${pxToRem(22)};
  padding-inline: 0;
  margin-block-end: ${pxToRem(24)};
  ${landscape(`
    margin-block-end: ${pxToRem(16)};
  `)};
  ${tablet(`
    padding-block: ${clampValues(16, 24)};
    margin-block-end: ${clampValues(6, 8)};
  `)}
  ${altNavigation(`
    padding-block: ${pxToRem(25)};
    margin-block-end: ${pxToRem(8)};
  `)};
  ${zoomPortrait(
    [150, 175],
    `
    padding-block: ${pxToRem(33)} ;
    margin-block-end: ${pxToRem(36)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
    padding-block: ${pxToRem(44)};
    margin-block-end: ${pxToRem(48)};
    `
  )}
  ${zoomLandscape(
    [150, 175],
    `
    margin-block-end: ${pxToRem(24)};
    `
  )}
  ${zoomLandscape(
    [200],
    `
    margin-block-end: ${pxToRem(32)};
    `
  )}
  ${altNavigationZoom(
    [150, 175],
    `
    padding-block: ${pxToRem(37)};
    margin-block-end: ${pxToRem(12)};
    `
  )}
  ${altNavigationZoom(
    [200],
    `
    padding-block: ${pxToRem(50)};
    margin-block-end: ${pxToRem(16)};
    `
  )}

  ${AccordionContent} {
    padding-block: ${pxToRem(50)} 0;
    padding-inline: ${pxToRem(40)} 0;
    ${tablet(`
      padding-block: ${clampValues(24, 32)} 0;
      padding-inline: ${clampValues(32, 38)} 0;
    `)}
  }

  ${AccordionTitle} {
    h4 {
      font-weight: normal;
    }
  }

  ${CheckboxWithLabelWrapper} {
    inline-size: 100%;
    overflow: hidden;
    word-break: break-all;
    text-wrap: wrap;

    label: before {
      inset-block-start: 0;
      transform: none;
    }
  }
`

export const FilterCountWrapper = styled.span`
  display: inline-block;
  margin-inline-start: ${pxToRem(7)};
  margin-inline-end: auto;
  color: ${({ theme }) => theme.colors.inactive};
`

export const FilterScrollableAreaContainer = styled.div`
  flex: 1 1 auto;
  min-block-size: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-block-end: ${pxToRem(48)};
  ${altNavigation(`
    overflow-y: hidden;
    padding-block-end: 0;
  `)}
  ${zoomLandscape(
    [150, 175],
    `
    overflow-y: hidden;
    padding-block-end: 0;
    `
  )}
  ${zoomPortrait(
    [200],
    `
    overflow-y: hidden;
    padding-block-end: 0;
    `
  )}
`

export const FilterRangeStyled = styled.div<{ width: string }>`
  padding-block: 0.5em;
  padding-inline: 0;
  inline-size: ${({ width }) => width};
  font-size: 1.091rem;
  color: ${({ theme }) => theme.colors.inactive};
  text-transform: capitalize;
`

export const SliderRangeLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FilterContainerMargin = styled.div`
  margin-block-end: ${pxToRem(42)};
  ${tablet(`
    margin-block-end: ${pxToRem(48)};
  `)};

  &:last-child {
    margin-block-end: 0;
  }
`

export const FilterContainerPadding = styled.div`
  padding-block: ${pxToRem(11)};
  padding-inline: 0;
`

export const RadioInput = styled.input`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  margin: 0;
  font: inherit;
  color: currentColor;
  inline-size: ${pxToRem(24)};
  block-size: ${pxToRem(24)};
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: 50%;
  margin-inline-end: ${pxToRem(24)};
  display: grid;
  place-content: center;
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(36)};
    block-size: ${pxToRem(36)};
    margin-inline-end: ${pxToRem(36)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(48)};
    block-size: ${pxToRem(48)};
    margin-inline-end: ${pxToRem(48)};
    `
  )}

  &:before {
    content: '';
    inline-size: ${pxToRem(18)};
    block-size: ${pxToRem(18)};
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: ${({ theme }) => `inset ${pxToRem(18)} ${pxToRem(18)} ${theme.colors.primaryDark}`};
    ${({ theme }) =>
      zoomPortrait(
        [150, 175],
        `
        inline-size: ${pxToRem(27)};
        block-size: ${pxToRem(27)};
        box-shadow: inset ${pxToRem(27)} ${pxToRem(27)} ${theme.colors.primaryDark};
    `
      )}
    ${({ theme }) =>
      zoomPortrait(
        [200],
        `
        inline-size: ${pxToRem(36)};
        block-size: ${pxToRem(36)};
        box-shadow: inset ${pxToRem(36)} ${pxToRem(36)} ${theme.colors.primaryDark};
    `
      )}
  }

  &:checked::before {
    transform: scale(1);
  }
`

export const SortByItemContainer = styled(ListItem)`
  &:not(:last-child) {
    margin-block-end: ${pxToRem(48)};
  }
  ${zoomPortrait(
    [150, 175],
    `
    &:not(:last-child) {
      margin-block-end: ${pxToRem(72)};
    }
  `
  )}
  ${zoomPortrait(
    [200],
    `
    &:not(:last-child) {
      margin-block-end: ${pxToRem(96)};
    }
  `
  )}
`

export const RangeThumbStyled = styled.div`
  block-size: ${pxToRem(32)};
  inline-size: ${pxToRem(32)};
  border: 1px solid ${({ theme }) => theme.components.rangeSlider.thumbBorder};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.components.rangeSlider.thumb};
`

export const RangeTrackStyled = styled.div`
  block-size: ${pxToRem(36)};
  display: flex;
  inline-size: 100%;
`

export const RangeTrackSelectedStyled = styled.div`
  block-size: ${pxToRem(8)};
  inline-size: 100%;
  border-radius: ${pxToRem(5)};
  align-self: center;
`

export const RangeControlContainer = styled.div`
  margin: ${pxToRem(24)} 0 ${pxToRem(16)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${zoomPortrait(
    [150, 175],
    `
    margin: ${pxToRem(36)} 0 ${pxToRem(24)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    margin: ${pxToRem(48)} 0 ${pxToRem(32)};
  `
  )}
`

export const RangeControlGridItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${pxToRem(16)};
  &:first-child {
    border-inline-end: 1px solid black;
    justify-content: flex-start;
  }
  ${tablet(
    `
    &:first-child {
      padding-inline-end: ${clampValues(8, 16)};
      margin-inline-start: 0;
    }
    margin-inline-start: ${clampValues(8, 16)};
    gap: ${clampValues(8, 16)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    &:first-child {
      padding-inline-end: ${clampValuesLandscape(8, 16)};
      margin-inline-start: 0;
    }
    margin-inline-start: ${clampValuesLandscape(8, 16)};
    gap: ${clampValuesLandscape(8, 16)};
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    gap: ${pxToRem(24)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    gap: ${pxToRem(32)};
  `
  )}
`

export const RangeIconButton = styled.button<{ disabled?: boolean }>`
  inline-size: ${pxToRem(32)};
  block-size: ${pxToRem(32)};
  border-radius: 50%;
  border: 1px solid
    ${({ theme, disabled }) => (disabled ? theme.colors.inactive : theme.colors.primaryDark)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(48)};
    block-size: ${pxToRem(48)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(64)};
    block-size: ${pxToRem(64)};
  `
  )}
`

export const RangeInput = styled(InputTypography)`
  text-align: center;
  inline-size: ${pxToRem(92)};
  block-size: ${pxToRem(46)};
  padding-block: ${pxToRem(10)};
  padding-inline: 0;
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  ${tablet(
    `
    max-inline-size: ${pxToRem(92)};
    inline-size: auto;
    flex: 1;
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(138)};
    height ${pxToRem(69)};
    padding-block: ${pxToRem(15)} ;
    padding-inline:  0;
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(184)};
    height ${pxToRem(92)};
    padding-block: ${pxToRem(20)};
    padding-inline: 0;
  `
  )}
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
