import { EnforcementMessage } from '@abstractTypes/content'
import { OOCustomRecipe, RBCustomRecipe } from '@abstractTypes/customRecipe'
import { CustomItemEngraving, EngravingConstraints } from '@abstractTypes/engraving'
import {
  Badges,
  Brand,
  ContentV2ProductPackaging,
  FrameAdvisorEvidences,
  ProductAvailability,
  ProductPrice,
  ProductThumbnailGroup,
  Scalars,
  TransitionLens,
  TryLenses,
} from '@abstractTypes/graphqlTypes'
import { Maybe } from 'graphql/jsutils/Maybe'

interface BaseProduct {
  brand: Brand
  badges: Badges
  moco: string
  model: string
  name: string
  originalBrandCode: string
  UPC: string
  url: Scalars['URI']['output']
  greenShippingAvailable: boolean
  availability?: ProductAvailability
  frameColorLabel?: string
  image360?: boolean
  lensColorLabel?: string
  polarized?: boolean
  price: ProductPrice
  vto: boolean
  rtr: boolean
  sizeFull?: string
}

// Mandatory and optional field are correct after discussion with Davide
export interface StandardProduct extends BaseProduct {
  id: string
  sku: string
  styleName?: string
  advisoryNotes?: string
  clen?: string
  color?: string
  colorCode: string
  colorLabel?: string
  colorsAvailable?: number
  completePair?: boolean
  customizable?: boolean
  description?: string
  drivingAsnzs?: string
  engravable?: boolean
  engravingConstraints?: EngravingConstraints
  faceShape?: string[]
  fit?: string
  frameAdvisorEvidences?: FrameAdvisorEvidences
  frameColor?: string
  frameMaterial?: string
  frameMaterialLabel?: string
  frameShape?: string[]
  gender?: Scalars['Gender']['output'][]
  frameFitting?: string
  geoFit?: string
  hingeDistance?: number
  isAfa: boolean
  isPrizm?: boolean
  isEditorial: boolean
  lensColor?: string
  lensHeight?: string
  lensMaterial?: string
  lensTreatment?: string[]
  lensTreatmentLabel?: string[]
  numberOfVariants?: number
  packaging?: ContentV2ProductPackaging
  prizmCategory?: string
  productType?: string
  product_hierarchy1?: string
  progressiveFriendly?: boolean
  roxable: boolean
  isSupernova: boolean
  isNuanceFrame: boolean
  isNuanceAccessories: boolean

  mixMatch?: boolean
  size?: string
  sizes?: StandardProduct[]
  sports?: string[]
  thumbnails: ProductThumbnailGroup
  variants?: StandardProduct[]
  isTransition?: boolean
  transitionLens?: Maybe<TransitionLens>
  enforcementBullets?: EnforcementMessage[]
}

export interface StandardProductVM extends StandardProduct {
  isProductNotAvailable?: boolean
}

export interface PDPProduct extends StandardProduct {
  tryLenses?: TryLenses
}

export interface CustomProduct extends BaseProduct {
  recipeId: string
  isCustomizable: boolean
  isMySelection?: boolean
  customitemEngraving?: CustomItemEngraving
  image?: string
  type?: string
}

export interface CustomOOProduct extends CustomProduct {
  customItem: OOCustomRecipe
  lensColor2?: string
  iconColorLabel?: string
  iconColor2?: string
}

export interface CustomRBProduct extends CustomProduct {
  customItem: RBCustomRecipe
}

export interface Product
  extends StandardProduct,
    Partial<Omit<CustomOOProduct, keyof BaseProduct | 'customItem'>>,
    Partial<Omit<CustomRBProduct, keyof BaseProduct | 'customItem'>> {
  customItem?: OOCustomRecipe | RBCustomRecipe
}

export type Size = {
  size: Maybe<string>
  id: string
  UPC: string
  sizeFull: Maybe<string>
  hingeDistance: number
}

export interface DataAttrs {
  description?: string
  link?: string
  wishlist?: string
  removeBtn?: string
  bagBtn?: string
  proceedBtn?: string
  home?: string
  store?: string
  id?: string
  title?: string
  price?: string
  productName?: string
}

export type ElectronicsProduct = StandardProduct

export enum ProductTileShape {
  SQUARED = 'SQUARED',
  STANDARD = 'STANDARD',
}
