import { clearPreselectedFilters } from '@actions/ui'
import AvailableInStoreSwitch from '@components/AvailableInStoreSwitch'
import { SecondaryButton } from '@components/core/Button'
import { Heading5 } from '@components/core/Typography'
import { SearchProductList } from '@components/ProductsList'
import useGetViewAllLink from '@components/SearchDrawer/hooks/useGetViewAllLink'
import { SearchTabs } from '@components/SearchDrawer/SearchTabs'
import { useProductGrid } from '@hooks/useProductGrid'
import { isUPC } from '@libs/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SearchTabsContainer } from '..'
import { SearchProductsContainer } from '../styled'
import { SettingsWrapper, ViewAllWrapper } from './styled'
import { ResultContentProps } from './type'

const ResultsContainer: FC<ResultContentProps> = ({
  tabNames,
  selectedNoResultTab,
  handleChangeTab,
  selectedTab,
  items,
  query,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const getViewAllLink = useGetViewAllLink()
  const { numberOfPreviewItems } = useProductGrid()
  const slicedItems = items.slice(0, numberOfPreviewItems)
  const productCount = !isUPC(query) ? items.length ?? 0 : items.length

  const renderTabs = () => {
    if (!selectedTab && !selectedNoResultTab) return null

    if (selectedTab && tabNames.length) {
      return <SearchTabs tabNames={tabNames} selectedTab={selectedTab} onChange={handleChangeTab} />
    }
  }

  const renderSettings = () => (
    <SettingsWrapper>
      <Heading5
        color="secondaryMedium"
        aria-label={t('SearchResults.productCounter', { count: productCount })}
        aria-description={t('SearchResults.productCounter', { count: productCount })}
      >
        {t('SearchResults.productCounter', { count: productCount })}
      </Heading5>
      <AvailableInStoreSwitch productSubdivision={selectedTab} fromSearchPage />
    </SettingsWrapper>
  )

  return (
    <SearchProductsContainer>
      <SearchTabsContainer>
        {renderTabs()}
        {selectedTab && renderSettings()}
      </SearchTabsContainer>
      <>
        {selectedTab && (
          <div>
            <SearchProductList items={slicedItems} />
            <ViewAllWrapper>
              {productCount > numberOfPreviewItems && (
                <SecondaryButton
                  dataAttrs={{ id: 'Tiles_ViewAll', test: 'Search_View-All' }}
                  onClick={() => {
                    history.push(getViewAllLink(query, selectedTab))
                    dispatch(clearPreselectedFilters())
                  }}
                  ariaAttrs={{
                    label: t('ButtonSearch.viewAll'),
                    description: t('ButtonSearch.viewAll'),
                  }}
                >
                  {t('ButtonSearch.viewAll')}
                </SecondaryButton>
              )}
            </ViewAllWrapper>
          </div>
        )}
      </>
    </SearchProductsContainer>
  )
}

export default ResultsContainer
