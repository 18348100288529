import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getLcEnvVariables = () => {
  const lcEnvVariables: AppConfigOverride = {
    shopperSlug: 'lc',
    shopperName: 'LENSCRAFTERS',
    storeID: '14',
    storeLang: 'en-US',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    vmStore: 'lc',
    vmStyle: 'lc',
  }

  return lcEnvVariables
}

export default getLcEnvVariables
