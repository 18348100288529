import { ReactNode } from 'react'
import { Product } from '@abstractTypes/product'

export enum SwitchTypes {
  radio,
  toggle,
}

export enum ConfigTypes {
  prescription,
  nonPrescription,
  mixMatch,
}

export interface Option {
  id: ConfigTypes
  icon?: ReactNode
  title: string
  text?: string
  isChecked?: boolean
  onChange?: (id: ConfigTypes, checked: boolean) => void
  type: SwitchTypes
  disabled: boolean
  dataAttrs?: {
    id?: string
    description?: string
  }
}

export interface OptionToggleProps {
  option: Option
  handleToggle: (index: number, checked: boolean) => void
  activeOption: ConfigTypes | null
  index: number
}

export interface PurchaseConfigureContentProps {
  options: Option[]
  onSubmit?: (addPrescription: boolean, addMixMatch: boolean) => void
  activeOption: ConfigTypes | null
  setActiveOption: (activeOption: ConfigTypes | null) => void
  product: Product
}

export interface PurchaseConfigureModalProps {
  onSubmit: (addPrescription: boolean, addMixMatch: boolean) => void
  onClose: () => void
  showPrescriptionSwitch: boolean
  showMixMatchSwitch: boolean
  visible: boolean
  hasPrescriptionStock: boolean
  hasNonPrescriptionStock: boolean
  product: Product
}
