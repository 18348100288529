import { ProfileAvatar } from '@components/FrameAdvisor/ProfileAvatar'
import { Logo } from '@components/Logo'
import PrivacyPolicyTooltip from '@components/PrivacyPolicyTooltip'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useFrameAdvisorUserProfile } from '@hooks/useFrameAdvisorUserProfile'
import { useFsaProfilePicture } from '@hooks/useFsaProfilePicture'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useNavigationFlowHandler } from '@hooks/useNavigationFlowHandler'
import { useStoreContext } from '@hooks/useStoreContext'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { usePageChecker } from '@libs/utils'
import { useResetContext } from '@providers/resetProvider'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ProfilePopUp from './ProfilePopUp'
import {
  HeaderContainer,
  ProfileAvatarContainer,
  ProfileAvatarLabel,
  ProfileAvatarWrapper,
} from './styles'
import { HeaderProps } from './types'

const Header: React.FC<HeaderProps> = ({
  logoColor,
  staticPosition,
  className,
  showFrameAdvisorProfile,
  isSticky,
  showPrivacyPolicyTooltip,
  resetNavigationOnClick = false,
  logoUrl,
}) => {
  const { basePath } = useStoreIndentity()
  const { reset } = useResetContext()
  const { isAlternativeNavigation } = useNavigationContext()
  const store = useStoreContext()
  const { setVisibleDrawer } = useDrawerHandler()
  const userProfile = useFrameAdvisorUserProfile()
  const profileImage = useFsaProfilePicture(userProfile?.profilePictureUrl || '')
  const { t } = useTranslation()
  const { isFrameAdvisorPage } = usePageChecker()
  const { frameAdvisorFlow } = useNavigationFlowHandler()
  const frameAdvisorProfileVisible = isFrameAdvisorPage || frameAdvisorFlow
  const history = useHistory()

  const onRedirect = useCallback(() => {
    history.push(basePath)
  }, [history, basePath])

  const showProfileDrawer = useCallback(() => {
    return setVisibleDrawer('faProfileRecap')
  }, [setVisibleDrawer])

  const LogoImageWithWrapper = resetNavigationOnClick ? (
    // This needs to ba a link otherwise default analytics event is not triggering (data-element-id)
    <a
      href="#"
      onClick={() => (resetNavigationOnClick ? reset() : null)}
      data-element-id="MainNav_Logo"
      data-test="MainNav_Logo"
    >
      <Logo logoColor={logoColor} hideLogo={!!store.hideHeaderLogo} logoUrl={logoUrl} />
    </a>
  ) : (
    <a role="button" onClick={onRedirect} data-element-id="MainNav_Logo" data-test="MainNav_Logo">
      <Logo logoColor={logoColor} hideLogo={!!store.hideHeaderLogo} logoUrl={logoUrl} />
    </a>
  )

  return (
    <HeaderContainer
      isAlternativeNavigation={isAlternativeNavigation}
      staticPosition={staticPosition}
      className={className}
      isSticky={isSticky}
    >
      {showPrivacyPolicyTooltip && <PrivacyPolicyTooltip />}
      {LogoImageWithWrapper}
      {showFrameAdvisorProfile && frameAdvisorProfileVisible && (
        <ProfileAvatarWrapper>
          <ProfilePopUp />
          <ProfileAvatarLabel>{t('FrameAdvisor.profile.recapHeaderTitle')}</ProfileAvatarLabel>
          <ProfileAvatarContainer onClick={showProfileDrawer} data-element-id="FAFAB_View">
            <ProfileAvatar src={profileImage} size="small" />
          </ProfileAvatarContainer>
        </ProfileAvatarWrapper>
      )}
    </HeaderContainer>
  )
}

export default Header
