import { Text } from '@components/core/Typography'
import { Notification } from '@hooks/useNotifications'
import React from 'react'
import styled from 'styled-components'

interface NotificationBoxProps {
  politeNotifications?: Notification[]
  assertiveNotifications?: Notification[]
}

const NotificationBoxWrapper = styled.span`
  position: absolute;
  margin: 0;
  padding: 0;
  inline-size: 1px;
  block-size: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
`

const NotificationBox: React.FC<NotificationBoxProps> = ({
  politeNotifications = [],
  assertiveNotifications = [],
}) => {
  return (
    <NotificationBoxWrapper>
      <Text aria-live="polite" aria-relevant="additions">
        {politeNotifications.map(notification => (
          <Text key={notification.id} aria-relevant="additions">
            {notification.message}
          </Text>
        ))}
      </Text>
      <Text aria-live="assertive" aria-atomic="true" aria-relevant="additions">
        {assertiveNotifications.map(notification => (
          <Text key={notification.id} aria-relevant="additions">
            {notification.message}
          </Text>
        ))}
      </Text>
    </NotificationBoxWrapper>
  )
}

export default NotificationBox
