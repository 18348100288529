import styled from 'styled-components'

export const KioskAppClickCounterSection = styled.div`
  block-size: 50px;
  inline-size: 50px;
  position: fixed;
  inset-block-start: 0;
  inset-inline-end: 0;
  z-index: 9999999999;
`
