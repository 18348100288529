import _ from 'lodash'

export function removeNonAlphabetical(text: string): string {
  // Remove non-alphabetical characters
  const pattern = /[^\p{L}]+/gu
  const cleaned = text.replace(pattern, ' ')

  // Split the text into words
  const words = cleaned.split(' ').filter(word => word)

  // Convert to PascalCase using Lodash's _.capitalize
  return words.map(word => _.capitalize(word)).join('')
}
