import {
  AccessibilityModalSection,
  SectionContent,
  SectionIcon,
  SectionTitleRow,
  SectionTitleRowLabel,
} from '@components/Accessibility/sections/styles'
import { MinusIcon, PlusIcon, ZoomIcon } from '@components/core/Icons'
import { Heading4, Text } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useThemeContext } from '@hooks/useThemeContext'
import { pxToRem } from '@libs/styled'
import { useTranslation } from 'react-i18next'
import { ZoomButton, ZoomButtonsWrapper, ZoomText } from './styles'

export const ZoomSection: React.FC = () => {
  const { t } = useTranslation()
  const { zoomLevel, increase, decrease } = useNavigationContext()
  const theme = useThemeContext()

  return (
    <AccessibilityModalSection>
      <SectionTitleRow>
        <SectionTitleRowLabel>
          <SectionIcon>
            <ZoomIcon height={pxToRem(32, zoomLevel / 100)} width={pxToRem(32, zoomLevel / 100)} />
          </SectionIcon>
          <Heading4 weight={'bold'} aria-label={t('AccessibilityLabel.modalZoom')}>
            {t('AccessibilityLabel.modalZoom')}
          </Heading4>
        </SectionTitleRowLabel>
        <ZoomButtonsWrapper>
          <ZoomButton
            disabled={zoomLevel === 100}
            onClick={decrease}
            aria-label={t('AccessibilityLabel.modalZoomOut')}
            data-element-id="AdaModal_Zoom"
            data-description="ZoomOut"
            data-test="AdaModal_Zoom"
          >
            <MinusIcon
              height={pxToRem(16, zoomLevel / 100)}
              width={pxToRem(16, zoomLevel / 100)}
              stroke={zoomLevel === 100 ? theme.colors.inactive : theme.colors.primaryDark}
            />
          </ZoomButton>
          <ZoomText weight="bold">{zoomLevel}%</ZoomText>
          <ZoomButton
            disabled={zoomLevel === 200}
            onClick={increase}
            aria-label={t('AccessibilityLabel.modalZoomIn')}
            data-element-id="AdaModal_Zoom"
            data-description="ZoomIn"
            data-test="AdaModal_Zoom"
          >
            <PlusIcon
              height={pxToRem(16, zoomLevel / 100)}
              width={pxToRem(16, zoomLevel / 100)}
              stroke={zoomLevel === 200 ? theme.colors.inactive : theme.colors.primaryDark}
            />
          </ZoomButton>
        </ZoomButtonsWrapper>
      </SectionTitleRow>
      <SectionContent>
        <Text>{t('AccessibilityLabel.modalZoomDescription')}</Text>
      </SectionContent>
    </AccessibilityModalSection>
  )
}
