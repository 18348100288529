import { IconProps } from '@abstractTypes/icons'
export const FaTempleFittingHighIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M48.8223 65.4882L51.1789 67.8449L61.6673 57.3566V79.9999H65.0006V57.3566L75.4889 67.8449L77.8456 65.4882L63.3339 50.9766L48.8223 65.4882Z"
        fill="black"
      />
      <path
        d="M74.1315 49.9866C74.2015 49.995 74.2699 50 74.3399 50C75.1682 50 75.8865 49.3833 75.9915 48.54L76.2249 46.6666L76.5532 44.0416L77.6582 35.2066L79.5315 20.22L76.2249 19.8066L75.5749 25H25.5465C18.9732 25 12.8482 27.9433 8.74153 33.0766L2.4582 40.93C1.3082 42.3683 1.42153 44.4383 2.72486 45.745C3.41153 46.4316 4.3332 46.8083 5.28486 46.8083C5.50986 46.8083 5.73653 46.7866 5.96153 46.7433C7.13486 46.52 8.1182 45.7333 8.5932 44.6366C9.6532 42.1916 15.4632 30 26.0049 30C26.9249 30 27.6715 29.2533 27.6715 28.3333H75.1582L74.3515 34.7933L73.2465 43.6283L72.8665 46.6666L72.6849 48.1266C72.5699 49.04 73.2182 49.8733 74.1315 49.9866Z"
        fill="black"
      />
    </svg>
  )
}
