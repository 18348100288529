import { Maybe, OneCatalogInfo, Product } from '@abstractTypes/graphqlTypes'
import { resetBarcodeModalInfo, setBarcodeModalInfo } from '@actions/barcode'
import { BarcodeScanScenario } from '@components/BarCodeScanModal/types'
import { convertProductForBarcodeModal } from '@libs/barcode'
import { useGetProductUrl } from '@libs/formatters'
import qs from 'qs'
import { useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { useActions } from './useActions'
import { BooleanResolver, useQueryParams } from './useQueryParams'

const useBarcodeHandleScenario = () => {
  const actions = useActions({
    setBarcodeModalInfo,
    resetBarcodeModalInfo,
  })
  const getProductUrl = useGetProductUrl()
  const queryParams = useQueryParams({
    barcodeDebug: BooleanResolver,
  })
  const debugEnabled = queryParams.barcodeDebug
  return useCallback(
    (
      scenario: BarcodeScanScenario,
      product?: Product | OneCatalogInfo,
      redirectInfo?: {
        pathname?: string
        query?: string
      },
      similarStylesProducts?: Maybe<Product[]>
    ) => {
      let productInfo = undefined
      if (product) {
        let productUrl = ''
        if ('url' in product) {
          productUrl = getProductUrl(product.url)
        }
        productInfo = convertProductForBarcodeModal(product, productUrl)
      }

      // TODO: this condition got in introduced because after saving the barcode info on redux only the modals have
      // the possibility to do the clean up of the saved data (by using the close button)
      // in case of the other scenarios we don't want data to be saved, otherwise the dirty data cause
      // unintended behaviours.

      // Improvement: we have to split this callback into multiple callbacks, and this hook returns 2,3,4 callbacks that
      // BarcodeActions knows how to use (since we have the switch case)
      // we have to split this into multiple callbacks also because for the scenario10 we're calling it this way
      // return redirectToScenario('scenario10', undefined, {...})
      // Beeing forced to use undefined as an argument means this function is doing too many things, and it's a bad
      // design pattern.
      if (
        scenario !== 'scenario2A' &&
        scenario !== 'scenario3A' &&
        scenario !== 'scenario3C' &&
        scenario !== 'scenario9'
      ) {
        actions.setBarcodeModalInfo({ productInfo, scenario, similarStylesProducts })
      } else {
        actions.resetBarcodeModalInfo()
      }

      const comesFromBarcode = 'comesFromBarcode=true'

      const defaultSearch = redirectInfo?.query
        ? `?q=${redirectInfo.query}&${comesFromBarcode}`
        : `?${comesFromBarcode}`

      const search = debugEnabled ? `${defaultSearch}&${qs.stringify(queryParams)}` : defaultSearch
      const pathname = redirectInfo?.pathname || productInfo?.url

      return (
        <Redirect
          to={{
            pathname,
            search,
          }}
        />
      )
    },
    [actions]
  )
}

export default useBarcodeHandleScenario
