import { IconProps } from '@abstractTypes/icons'
export const FaFaceShapeOvalUngenderedIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 46"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M43.3129 15.987C43.1209 7.99504 36.1949 2.55004 29.8409 0.873042C23.8829 -0.699958 18.5629 0.756042 15.6849 4.67004C12.5179 3.02504 9.13595 3.09904 6.27795 4.90604C1.88595 7.68304 -0.442052 13.879 0.341948 20.629C0.357948 20.937 0.785949 28.193 4.70295 31.577C5.58495 32.339 6.57895 32.824 7.65895 33.078C8.07995 34.882 8.69795 36.634 9.52795 38.293C11.9049 43.046 16.6839 46 21.9999 46C27.3159 46 32.0949 43.046 34.4719 38.292C35.3339 36.568 35.9679 34.743 36.3889 32.863L36.4109 32.912C36.7059 32.779 43.6389 29.498 43.3129 15.987ZM21.9999 44C17.4459 44 13.3529 41.47 11.3169 37.397C10.4149 35.593 9.76895 33.673 9.39695 31.689L7.19495 19.948C12.8409 19.451 16.9349 15.496 18.4039 11.81C20.4019 14.273 25.2269 17.835 36.8279 19.821L34.6029 31.69C34.2309 33.673 33.5849 35.594 32.6829 37.398C30.6469 41.47 26.5539 44 21.9999 44ZM36.9339 30.113L38.9829 19.184L38.1559 18.012C20.3769 15.205 18.9989 8.90104 18.9899 8.85804L16.9999 9.00004C16.9999 12.54 12.2759 18 5.99995 18L5.01695 19.184L7.19695 30.81C6.78295 30.617 6.38395 30.384 6.01395 30.065C2.74195 27.242 2.34395 20.588 2.33395 20.46C1.63295 14.406 3.60095 8.96404 7.34695 6.59604C9.82295 5.03204 12.7059 5.12104 15.4699 6.84804L16.8569 6.51504C19.5309 2.05904 25.0529 1.67704 29.3299 2.80704C34.9839 4.30004 41.1459 9.07704 41.3139 16.036C41.5199 24.587 38.6919 28.506 36.9339 30.113Z"
        fill={fill}
      />
      <path d="M24.0129 36H19.9859V38H24.0129V36Z" fill={fill} />
      <path d="M22.4339 31H19.9859V33H24.4339V23.057H22.4339V31Z" fill={fill} />
    </svg>
  )
}
