import { AppConfigOverride } from '@abstractTypes/envs'

const getStageEnvVariables = (): AppConfigOverride => {
  const stageTestVariables = {
    fsaEnv: 'stage',
  }

  return stageTestVariables
}

export default getStageEnvVariables
