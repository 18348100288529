import { altNavigation } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const ElectronicsLogoStyled = styled.div<{ electronicsType?: string }>`
  block-size: ${pxToRem(40)};
  inline-size: 'auto';
  margin-block: 0;
  margin-inline: auto;
  ${altNavigation(`
    position: absolute;
    inset-inline-start: ${pxToRem(108)};
    margin-block-start: 0;
    inline-size: fit-content;
  `)}
`
