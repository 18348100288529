import { ContentV2Electronics } from '@abstractTypes/graphqlTypes'
import { useMemo } from 'react'

export const useAvailableElectronicsProducts = <T extends { model: string }>(
  models: ContentV2Electronics[] = [],
  products: T[] = []
): Record<string, T> =>
  useMemo(() => {
    return models.reduce(
      (acc, model) => ({
        ...acc,
        [model.id]: products.find(product => product.model === model.product),
      }),
      {}
    )
  }, [models, products])
