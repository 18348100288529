// This prop appends a default padding to the top to prevent issue with the notch and the navbar for ios
import { Device } from '@abstractTypes/utils'
import { Drawer } from '@components/Drawer'
import { FloatingButton } from '@components/FloatingActions/styles'
import Header from '@components/Header'
import { default as DiscountBannerUnstyled } from '@components/StripeBanner/DiscountBanner'
import { PrimaryButton } from '@components/core/Button'
import { InputTypography } from '@components/core/Typography'
import { altNavigation, altNavigationZoom, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const SearchDrawerStyled = styled(Drawer)`
  flex-direction: column;
  ${landscape(Device.tablet, 'padding-block-start: env(safe-area-inset-top);')}
`

export const SearchWrapper = styled.div`
  overflow-y: hidden;
  inline-size: 100%;
  block-size: 100vh;
  ${landscape(`
    overflow-y: auto;
  `)}
  ${altNavigation(`
    overflow-y: auto;
  `)}
`

export const SearchFloatingButton = styled(FloatingButton)`
  z-index: 4;
  position: absolute;
  inset-block-start: ${pxToRem(273)};
  inset-inline-end: 0;
  border: 1px solid ${({ theme }) => theme.colors.background};
  ${landscape(`inset-block-start: ${pxToRem(32)}`)}
  ${tablet(`
    inset-block-start: ${clampValues(26, 34)};
  `)}
  ${zoomPortrait(
    [175, 200],
    `
    position: absolute;
    inset-block-start: ${pxToRem(32)};
  `
  )}
  ${altNavigation(
    `
    position: absolute;
    inset-block-start: ${pxToRem(32)};
  `
  )}
`

export const SearchInputContent = styled.div`
  display: flex;
  padding-block-start: ${pxToRem(63)};
  align-items: end;
  max-inline-size: 100%;
  ${tablet(`
    padding-block-start: ${clampValues(32, 42)};
  `)}
  ${landscape(`
    padding-block-start: ${pxToRem(35)};
  `)}
    ${landscape(Device.tablet, `padding-block-start: ${clampValuesLandscape(24, 36)}`)}
  ${zoomPortrait(
    [125, 150],
    `
    padding-block-start: ${pxToRem(32)};
  `
  )}
  ${zoomPortrait(
    [175, 200],
    `
    padding-block-start: ${pxToRem(32)};
  `
  )}
`

export const SearchInputWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  box-sizing: content-box;
  justify-content: center;
  inline-size: ${pxToRem(632)};
  block-size: ${pxToRem(48)};
  margin: auto;
  ${tablet(`
    max-inline-size: ${clampValues(457, 609)};
    margin: 0;
    flex: 1;
  `)}
  ${landscape(`
    inline-size: ${pxToRem(1122)};
  `)}
  ${landscape(Device.tablet, `max-inline-size: ${clampValuesLandscape(707, 943)};`)}
  ${zoomPortrait(
    [125, 150],
    `
    block-size: ${pxToRem(72)};
    inline-size: ${pxToRem(856)};
  `
  )}
  ${zoomPortrait(
    [175, 200],
    `
    block-size: ${pxToRem(96)};
    inline-size: ${pxToRem(856)};
  `
  )}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    inline-size: ${pxToRem(888)};
  `
  )}
`

export const SearchInput = styled(InputTypography)`
  margin: 0;
  flex: 1;
  border: 0;
  outline: 0;
  padding: 0;
  border-block-end: 1px solid ${({ theme }) => theme.colors.outline};
  appearance: none;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.primaryLight};
`

export const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  border-block-end: 1px solid ${({ theme }) => theme.colors.outline};
`

export const BarcodeScannerButton = styled(PrimaryButton)`
  margin-inline-start: ${pxToRem(16)};
  block-size: ${pxToRem(56)};
  ${tablet(`
    padding-block: 0 ;
    padding-inline:  ${pxToRem(16)};
    block-size: ${clampValues(42, 56)};
    margin-inline-start: ${clampValues(16, 20)};
  `)}
  ${landscape(`margin-inline-start: ${pxToRem(24)};`)};
  ${landscape(
    Device.tablet,
    `margin-inline-start: ${clampValuesLandscape(18, 22)};
    padding-block: ${clampValuesLandscape(11, 15)} ;
    padding-inline: ${clampValuesLandscape(24, 32)};
    `
  )};

  svg {
    flex-shrink: 0;
  }
`

export const BarcodeButtonContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  inline-size: 100%;
  max-inline-size: ${pxToRem(300)};
`

export const BarcodeButtonTitle = styled.span`
  margin-inline-start: ${pxToRem(6)};
`

export const SearchHeader = styled(Header)`
  padding-block-end: ${pxToRem(32)};
  ${tablet(`padding-block-end: ${clampValues(32, 40)};`)}
  ${landscape(Device.tablet, `padding-block-end: ${clampValues(12, 16)};`)}
`

export const DiscountBanner = styled(DiscountBannerUnstyled)<{ isSticky?: boolean }>`
  ${({ isSticky }) =>
    isSticky &&
    `
    inset-block-start: ${pxToRem(164)};
    ${altNavigation(`
      inset-block-start: 0;
      z-index: 3;
    `)}
  `}
`

export const SearchContainer = styled.div<{ isSticky?: boolean }>`
  padding-block: 0 ${pxToRem(64)};
  padding-inline: ${pxToRem(112)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  ${tablet(`
  padding-block: 0;
  padding-inline:  ${clampValues(72, 96)};
  `)}
  ${({ isSticky, theme }) =>
    isSticky &&
    `
    position: sticky;
    inset-block-start: ${pxToRem(226)};
    z-index: 3;
    background: ${theme.colors.primaryLight};
    
    ${zoomPortrait(
      [125, 150],
      `
      inset-block-start: ${pxToRem(251)};
    `
    )}
    ${zoomPortrait(
      [175, 200],
      `
      inset-block-start: ${pxToRem(316)};
    `
    )}
  `}
`
