import { useCallback, useRef } from 'react'
import useOnScreen from './useOnScreen'

export const scrollToTop = (element: HTMLElement | null, behavior?: ScrollBehavior) => {
  if (element) {
    element.scrollIntoView({ behavior })
  }
}

export const useScrollBackToTop = () => {
  const refInternal = useRef<HTMLElement | null>(null)

  const { isOnScreen: isNotScrolled, setRef: setRefOnScreen } = useOnScreen(null, 1)

  const setRef = useCallback(
    (node: HTMLElement | null) => {
      setRefOnScreen(node)
      if (!node) return
      refInternal.current = node
    },
    [setRefOnScreen]
  )

  const scrollBackToTop = useCallback(() => {
    scrollToTop(refInternal.current, 'smooth')
  }, [])

  return { setRef, isScrolled: !isNotScrolled, scrollBackToTop }
}
