import { IconProps } from '@abstractTypes/icons'
export const CategoryOakleyCustomIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 48 28"
      stroke={stroke}
      className={className}
    >
      <path
        fill="#fff"
        d="M20.6 23.789l-2.239-.026-.219-.016c-.63-.074-1.208-.343-1.64-.769-.204-.199-.365-.425-.479-.669-.132-.28-.197-.573-.197-.873 0-.644.281-1.238.793-1.674.434-.371.885-.57 1.388-.609l4.55-.017.264-.001-.167.205-1.127 1.137-.037.045-3.004-.005-.023.002c-.639.016-1.089.378-1.089.9.002.401.27.714.714.846 0 0 .034.012.135.023l2.206.011.208.017c.622.071 1.202.344 1.65.781.201.201.363.426.478.67.13.28.195.574.195.872 0 .643-.28 1.24-.794 1.675-.435.373-.885.571-1.388.609l-5.02.016-.265.001.168-.204.926-1.139.036-.045 3.691-.001.022-.003c.65-.015 1.054-.362 1.072-.891.014-.378-.26-.797-.7-.863.002-.001-.059-.005-.108-.005z"
      />
      <path
        fill="#fff"
        d="M42.965 23.37l4.215-4.05c.06-.037.147-.076.224-.076.184 0 .337.123.383.295l-.033 5.34-.02.69s.114 1.155-1.479 1.391l.013-4.775-4.79 4.798.004-4.747-2.57 2.653c-.249.268-.508.52-.823.787-1.078.967-1.746 1.277-2.894 1.277-.143 0-.285-.01-.422-.024l2.08-2.059c.06-.054.115-.11.172-.169l.023-.026-.002.003c.391-.44.632-1.017.632-1.653 0-1.368-1.112-2.479-2.482-2.479s-2.482 1.111-2.479 2.48c0 1.371 1.111 2.482 2.481 2.482h.034l-1.349 1.215c-1.062-.38-1.914-1.204-2.327-2.253-.016-.039-.029-.08-.045-.12-.017-.053-.039-.102-.055-.155-.12-.374-.183-.773-.183-1.184 0-.536.12-1.012.295-1.435.03-.072.064-.144.098-.215l.044-.09c.03-.057.058-.112.09-.164.118-.215.246-.418.375-.6l-1.034.002-.04-.006-2.072.022-.049.003c-.44-.015-.813.327-.838.771 0 0 .004.368.004.563v2.575l-.023 1.167s.03 1.161-1.512 1.415h-.07l.01-5.606v-.084c-.01-.419-.346-.762-.77-.783 0 0-.025-.006-.074-.006l-3.034.014 1.32-1.409 11.265-.002s.485.01 1 .138c1.52.378 2.65 1.732 2.824 3.331l3.155-3.146.056-.055c.073-.08.178-.129.292-.129.217 0 .394.172.401.388l-.02 3.7zM14.807 19.162c-1.526.253-1.495 1.4-1.495 1.4l-.028 1.421v1.804h-.002l.001.032c.001.95-.767 1.718-1.716 1.718S9.85 24.768 9.85 23.82l.001-.033h-.002l-.001-4.618h-.005l-1.568 1.735.001 2.707c-.001.037-.005.078-.005.12 0 .04.005.08.005.118v.116h.006c.122 1.71 1.544 3.06 3.285 3.06 1.798 0 3.269-1.44 3.305-3.23v-.187l-.032-4.446h-.033zM4.518 19.184c-.157 0-.31.008-.464.02-.03 0-.06-.004-.09-.004-2.158 0-3.906 1.75-3.906 3.905 0 2.157 1.748 3.906 3.906 3.904 1.206 0 2.284-.547 3-1.405H4.018l-.03.003c-.012 0-.022 0-.032-.003H3.91v-.002c-1.345-.042-2.425-1.142-2.425-2.497 0-1.357 1.08-2.456 2.424-2.499V20.6l3.05-.002-.008-.008h.511l1.271-1.406H4.518zM24 0C10.889 0 0 3.594 0 8.3c0 4.698 10.889 8.297 24 8.297s24-3.599 24-8.298C48 3.596 37.111 0 24 0zm.057 14.823c-7.697 0-14.624-2.807-14.624-6.591 0-3.784 6.933-6.595 14.624-6.595 7.694 0 14.621 2.811 14.621 6.595s-6.927 6.591-14.621 6.591z"
      />
    </svg>
  )
}
