import { ProductSubdivisions } from '@abstractTypes/filter'
import { useAvailableInStoreProductSubdivision } from '@hooks/useAvailableInStore'
import { useGetInitialProductSubdivisionTab } from '@hooks/useGetInitialProductSubdivisionTab'
import { useNavigationFlowHandler } from '@hooks/useNavigationFlowHandler'
import { useStoreContext } from '@hooks/useStoreContext'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import getNoResultsTabs from '@libs/getNoResultsTabs'
import { landscape } from '@libs/media'
import { isAvailableInStore } from '@libs/utils'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { SearchProductsContentProps } from '../types'
import NoResultContent from './NoResultsContent'
import ResultsContainer from './ResultContent'

export const SearchTabsContainer = styled.div`
  inline-size: 100%;
  position: sticky;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  inset-block-start: -3px;
  ${landscape(`
    position: relative;
    z-index: 1;
  `)}
`

export const SearchProductsContent: React.FC<SearchProductsContentProps> = ({
  items,
  query,
  orderType,
  selectedTab,
  tabNames,
  onTabSelect,
}) => {
  const store = useStoreContext()
  const { langCode } = useStoreIndentity()
  const noResultsTabsSubset = getNoResultsTabs(!!store.eyeglassesEnabled)

  const getInitialProductSubdivisionTab = useGetInitialProductSubdivisionTab()
  const { initialTab: noResultInitialTab, tabNames: noResultTabs } =
    getInitialProductSubdivisionTab(noResultsTabsSubset, selectedTab)

  const [selectedNoResultTab, setSelectedNoResultTab] = useState(noResultInitialTab)

  const { customerOrderFlow, barcodeFlow } = useNavigationFlowHandler()
  const { availableInStore } = useAvailableInStoreProductSubdivision(selectedTab)
  const nItems = items.length
  const filteredByAvailableInStoreItems = items.filter(isAvailableInStore(availableInStore))

  const isOrderTypeMatch = orderType === 'COMPLETE_PAIR' ? items[0]?.roxable : true
  const isRedirectFromCustomerOrder = customerOrderFlow && nItems === 1 && isOrderTypeMatch
  const isNoDataContentShown =
    nItems === 0 || filteredByAvailableInStoreItems.length === 0 || !isOrderTypeMatch || barcodeFlow

  const handleChangeTab = (value: ProductSubdivisions) => {
    setSelectedNoResultTab(value)
    onTabSelect(value)
  }

  if (isRedirectFromCustomerOrder) {
    return <Redirect to={`${langCode}/${items[0].url}`} />
  }

  return (
    <>
      {isNoDataContentShown ? (
        <NoResultContent
          noResultTabs={noResultTabs}
          selectedNoResultTab={selectedNoResultTab}
          noResultsTabsSubset={noResultsTabsSubset}
          handleChangeTab={handleChangeTab}
          selectedTab={selectedTab}
          query={query}
        />
      ) : (
        <ResultsContainer
          tabNames={tabNames}
          selectedNoResultTab={selectedNoResultTab}
          handleChangeTab={handleChangeTab}
          selectedTab={selectedTab}
          items={items}
          query={query}
        />
      )}
    </>
  )
}
