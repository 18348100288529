import { ProgressBarPlaylistItem, ProgressBarProps } from '@abstractTypes/progessBar'
import { Progress } from '@components/Progress'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import classnames from 'classnames'
import React from 'react'
import { ProgressContainer } from './styles'

const DEFAULT_DURATION = 8 // time in seconds
const ANIMATION_TIME = `${DEFAULT_DURATION}s`

export const ProgressBar: React.FC<ProgressBarProps> = ({
  currentSlide,
  playlist,
  animationTime = ANIMATION_TIME,
  className = '',
  isProgressPaused,
  nextPlay,
  disableAnimation = false,
  renderPrevButton,
  renderNextButton,
  onProgressClick,
  dataCalls = {},
  activeColor,
  backgroundColor,
}) => {
  const rtlDirection = useLanguageDirection()

  const isPassedSlide = (index: number) => {
    if (disableAnimation) return currentSlide === index
    return currentSlide >= index
  }
  const getAnimationDuration = (slide: ProgressBarPlaylistItem) => {
    if (disableAnimation) return
    return slide?.duration ? `${slide.duration}s` : animationTime
  }

  return (
    <ProgressContainer className={className} {...dataCalls} dir={rtlDirection ? 'rtl' : 'ltr'}>
      {renderPrevButton && renderPrevButton()}
      {playlist.map((slide, index: number) => (
        <Progress
          isReversed={rtlDirection}
          key={index}
          active={disableAnimation ? false : currentSlide === index}
          passed={isPassedSlide(index)}
          animationTime={getAnimationDuration(slide)}
          isProgressPaused={isProgressPaused || disableAnimation}
          onAnimationEnd={nextPlay}
          {...(onProgressClick ? { onClick: () => onProgressClick(index) } : {})}
          className={classnames({
            active: isPassedSlide(index),
          })}
          activeColor={activeColor}
          backgroundColor={backgroundColor}
        />
      ))}
      {renderNextButton && renderNextButton()}
    </ProgressContainer>
  )
}
