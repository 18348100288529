import { IconProps } from '@abstractTypes/icons'

export const GlassShape1Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 0.75H37C32.338 0.75 28.433 3.945 27.325 8.261C26.352 7.613 25.198 7.25 24 7.25C22.789 7.25 21.643 7.606 20.673 8.255C19.563 3.942 15.66 0.75 11 0.75H2C0.895 0.75 0 1.645 0 2.75V11.75C0 17.273 4.477 21.75 10 21.75H19C20.105 21.75 21 20.855 21 19.75V10.75C21 10.7319 20.9987 10.7143 20.9975 10.6968V10.6968C20.9962 10.6795 20.995 10.6624 20.995 10.645C21.755 9.766 22.826 9.25 24 9.25C25.157 9.25 26.251 9.756 27.007 10.617C27.0065 10.6375 27.0051 10.6576 27.0036 10.6777C27.0018 10.7016 27 10.7255 27 10.75V19.75C27 20.855 27.895 21.75 29 21.75H38C43.523 21.75 48 17.273 48 11.75V2.75C48 1.645 47.105 0.75 46 0.75ZM19 19.75H10C5.589 19.75 2 16.161 2 11.75V2.75H11C15.411 2.75 19 6.339 19 10.75V19.75ZM38 19.75C42.411 19.75 46 16.161 46 11.75V2.75H37C32.589 2.75 29 6.339 29 10.75V19.75H38Z"
        fill="#333333"
      />
    </svg>
  )
}
