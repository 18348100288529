import { Device } from '@abstractTypes/utils'
import { altNavigation, landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'
import {
  animationDuration,
  landscapeTabletSliderWidth,
  landscapeTowerSliderWidth,
} from '../styleConsts'

export const ElectronicsHeaderStyled = styled.div<{ isSticky?: boolean }>`
  z-index: 3;
  position: fixed;
  inset-inline: 0 0;
  inset-block-start: ${pxToRem(77)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: width ${animationDuration} linear;
  ${landscape(`
    background-color: transparent;
    inline-size: ${landscapeTowerSliderWidth};
  `)}
  ${tablet(
    `
    inset-block-start: ${clampValues(48, 66)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    inset-block-start: ${clampValuesLandscape(48, 66)};
    inline-size: ${landscapeTabletSliderWidth};
  `
  )}
  ${({ theme, isSticky }) => `
    ${altNavigation(`
      position: ${isSticky ? 'sticky' : 'relative'};
      inset-block-start: 0;
      min-block-size: ${pxToRem(104)};
      padding-block: ${pxToRem(24)} ;
      padding-inline:  ${pxToRem(48)};
      background-color: ${theme.colors.primaryLight};
    `)}
  `}
`
