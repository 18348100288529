import { IconProps } from '@abstractTypes/icons'


export const FishingIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path
        d="m35.666 1.09-.504-.504A1.99 1.99 0 0 0 33.748 0a1.99 1.99 0 0 0-1.414.586c-11.077 11.077-11.117 29.007-.127 40.138A28.432 28.432 0 0 0 26.994 48h2.26a26.368 26.368 0 0 1 4.492-6h.002l.504.504A26.392 26.392 0 0 1 38.464 48h2.293a28.49 28.49 0 0 0-4.964-6.773c10.99-11.131 10.95-29.06-.127-40.137zm-1.918 38.352C23.425 29.119 23.425 12.323 33.746 2h.002l.504.504c5.001 5.001 7.755 11.649 7.755 18.721 0 7.072-2.754 13.721-7.755 18.721l-.504-.504zM14.707 40.707 19 36.414V39.5c0 3.584-2.916 6.5-6.5 6.5A6.508 6.508 0 0 1 6 39.5V9.899a5 5 0 1 0-2 0V39.5c0 4.687 3.813 8.5 8.5 8.5 4.687 0 8.5-3.813 8.5-8.5v-7.914l-7.707 7.707 1.414 1.414zM2 5c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3z"
        fill={fill}
      />
    </svg>
  )
}
