import {
  DefaultColors,
  DefaultFonts,
  DefaultProductGridColumns,
  DefaultTheme,
} from 'styled-components'
import chanel from './chanel'
import checkout from './checkout'
import electronics from './electronics'
import engraving from './engraving'
import greenShipment from './greenShipment'
import nuance from './nuance'
import prizm from './prizm'
import productCard from './productCard'

export const colors: DefaultColors = {
  primaryDark: '#000000',
  primaryDarkPressed: '#000000',
  primaryLight: '#FFFFFF',
  primaryLightPressed: '#FFFFFF',
  inactive: '#CCCCCC',
  outline: '#4A4A4A',
  background: '#828282',
  highlight01: '#777777',
  highlight01Pressed: '#DC5454',
  highlight02: '#79AF2C',
  highlight02Pressed: '#4F8EA1',
  highlight03: '#79AF2C',
  highlight04: '#79AF2C',
  functional01: '#D0021B',
  functional02: '#01B603',
  functional03: '',
  brandeisBlue: '#0977ff',
}

export const sizes = {
  heading0: 100,
  heading0Tablet: 100,
  heading0TabletMini: 100,
  heading1: 64,
  heading1Tablet: 64,
  heading1TabletMini: 64,
  heading2: 48,
  heading2Tablet: 48,
  heading2TabletMini: 48,
  heading3: 40,
  heading3Tablet: 40,
  heading3TabletMini: 40,
  heading4: 32,
  heading4Tablet: 32,
  heading4TabletMini: 32,
  heading5: 24,
  heading5Tablet: 24,
  heading5TabletMini: 24,
  body: 18,
  bodyTablet: 18,
  bodyTabletMini: 18,
  label: 14,
  labelTablet: 14,
  labelTabletMini: 14,
  cta: 18,
  ctaTablet: 18,
  ctaTabletMini: 18,
  link: 32,
  linkTablet: 32,
  linkTabletMini: 32,
}

export const fonts: DefaultFonts = {
  heading0: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  heading1: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  heading2: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  heading3: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  heading4: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  heading5: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  body: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  label: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  cta: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  link: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  caption: {
    fontFamily: 'HelveticaNeue',
    weight: {
      regular: 400,
      bold: 700,
    },
    fontSize: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
    lineHeight: {
      tower: 64,
      tablet: 64,
      tabletMini: 64,
    },
  },
  // We might need to extend these to match FontObject interface at some point, but not required now
  oakleyPrimary: 'ScoutCond,Helvetica,Arial,sans-serif',
  oakleySecondary: '"MorePro-CondBook",Helvetica,Arial,sans-serif',
  sizes,
}

const touchkeyboardDarkTheme = {
  fontFamily: fonts.body.fontFamily,
  fontWeight: '300',
  fontSize: sizes.body,
  backgroundColor: colors.primaryDark,

  button: {
    color: colors.primaryLight,
    height: 60,
    borderColor: colors.outline,
    borderRadius: 4,
    width: 100,
    backgroundColor: colors.primaryDark,
    activeBackgroundColor: colors.background,
    selectedBackgroundColor: colors.highlight02Pressed,
    submitBackgroundColor: colors.functional01,
    activeColor: colors.primaryLight,
    submitColor: colors.primaryLight,
  },
}

export const productGridColumns: DefaultProductGridColumns = {
  ipad: 2,
  ipadLandscape: 3,
  tower: 2,
  towerLandscape: 3,
  towerLandscapeDressedOn: 4,
  towerLandscapeZoom: 2,
  towerZoom: 1,
  altNavZoom: 1,
  altNavZoomDressedOn: 2,
}

export const defaultTheme: DefaultTheme = {
  fonts,
  components: {
    button: {
      style: 'squared',
      uppercase: false,
    },

    rangeSlider: {
      background: '#CCCCCC',
      foreground: '#777777',
      thumb: '#828282',
      thumbBorder: '#CCCCCC',
    },
  },
  colors,
  checkout: {
    ...checkout.checkout,
    checkbox_checked_bg_color: colors.primaryDark,
    checkbox_checked_border_color: colors.primaryDark,
    accordion_border_color: colors.primaryDark,
    input_border_color: colors.primaryDark,
  },
  radioColors: {
    gold: '#d8af58',
    silver: '#c0c0c0',
    rose: '#ffe4e1',
    roseGold: '#ffe4e1',
  },
  inputFontColor: '#333',
  fontInput: 'HelveticaNeue,sans-serif',
  fontHeaderTitle: 'HelveticaNeue,sans-serif',
  fontActionButton: 'HelveticaNeue,sans-serif',
  fontSwitcherText: 'HelveticaNeue,sans-serif',
  engraving,
  productCard: {
    ...productCard,
    color: colors.primaryDark,
    imageMarginBottom: '0',
    imageMaxWidth: '100%',
    rowGutter: '0rem',
    priceOrder: '3',
    quantityOrder: '2',
    brandNameOrder: '1',
    modelCodeOrder: '6',
    modelNameOrder: '4',
    colorsOrder: '5',
    prescriptionOrder: '7',
  },
  touchkeyboard: touchkeyboardDarkTheme,
  electronics,
  nuance,
  prizm,
  chanel,
  greenShipment,
  productGridColumns,
}
