import { updateVisited } from '@actions/frameAdvisor'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

const FrameAdvisorActions = () => {
  const { isFrameAdvisorVisited } = useSelector(s => s.frameAdvisor)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isFrameAdvisorVisited) {
      dispatch(updateVisited(true))
    }
  }, [dispatch, isFrameAdvisorVisited])

  return <></>
}

export const FrameAdvisorRouteActions = () => {
  const { basePath } = useStoreIndentity()
  const videoId = useSelector(state => state.frameAdvisor.fsaVideoId)
  const user = useSelector(state => state.frameAdvisor.fsaInfo)
  const isUser = !isEmpty(user)
  return (
    <>
      <Route path={`${basePath}/frame-advisor`}>
        <FrameAdvisorActions />
      </Route>
      <Route path={`${basePath}/frame-advisor/(connectfsa|fsa)/:id*`}>
        {isUser && <Redirect to={`${basePath}/frame-advisor/playlist`} />}
      </Route>
      <Route path={`${basePath}/frame-advisor/logoutfsa`}>
        {!videoId && <Redirect to={`${basePath}`} />}
      </Route>
      <Route path={`${basePath}/frame-advisor/survey/:id*`}>
        {videoId && <Redirect to={`${basePath}/frame-advisor/logoutfsa`} />}
      </Route>
    </>
  )
}
