import ConditionalRender from '@components/ConditionalRender'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OptionToggle from './OptionToggle'
import { PurchaseCtaButton } from './styles'
import { ConfigTypes, Option, PurchaseConfigureContentProps } from './types'

const PurchaseConfigureContent: React.FC<PurchaseConfigureContentProps> = ({
  options: initialOptions,
  onSubmit,
  activeOption,
  setActiveOption,
  product,
}) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<Option[]>(initialOptions)

  const handleToggle = (index: number, checked: boolean) => {
    const newOptions = [...options]

    newOptions[index] = {
      ...newOptions[index],
      isChecked: checked,
    }

    setOptions(newOptions)
    setActiveOption(checked ? options[index].id : null)
  }

  const isOptionChecked = (id: ConfigTypes) => {
    return !!options.filter(option => option.id === id && option.isChecked).length
  }

  const getDataAttrId = isOptionChecked(ConfigTypes.nonPrescription)
    ? 'RXModal_AddCart'
    : 'RXModal_AddPrescription'

  const handleSubmit = () => {
    onSubmit &&
      onSubmit(
        isOptionChecked(ConfigTypes.prescription) || isOptionChecked(ConfigTypes.mixMatch),
        isOptionChecked(ConfigTypes.mixMatch)
      )
  }

  return (
    <>
      {options.map((option, index) => {
        return (
          <OptionToggle
            option={option}
            handleToggle={handleToggle}
            activeOption={activeOption}
            index={index}
            key={index}
          />
        )
      })}
      <ConditionalRender
        condition={onSubmit}
        render={() => (
          <PurchaseCtaButton
            disabled={activeOption === null}
            onClick={handleSubmit}
            dataAttrs={{
              id: `${getDataAttrId}`,
              description: `${product.brand.name}:${product.model}:${product.UPC}`,
            }}
          >
            {t('Prescription.addToShoppingBagButton')}
          </PurchaseCtaButton>
        )}
      />
    </>
  )
}

export default PurchaseConfigureContent
