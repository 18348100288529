import { CustomerOrderType } from '@abstractTypes/common'
import { Dispatch } from 'react'

export const SET_CUSTOMER_ORDER = 'order/SET_CUSTOMER_ORDER'

export interface SetCustomerOrderAction {
  type: typeof SET_CUSTOMER_ORDER
  payload: {
    salesOrderId: string
    orderType: CustomerOrderType
    email: string | undefined
  }
}

export const setCustomerOrder =
  (salesOrderId: string, orderType?: CustomerOrderType, email?: string) =>
  (dispatch: Dispatch<SetCustomerOrderAction>) => {
    dispatch({
      type: SET_CUSTOMER_ORDER,
      payload: {
        salesOrderId,
        orderType: orderType || null,
        email,
      },
    })
  }

export type CustomerOrderActionTypes = SetCustomerOrderAction
