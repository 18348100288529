import { DataAttrs } from '@abstractTypes/product'
import { removeItemFromCart } from '@actions/cart'
import CartMerchRecommender from '@components/CartMerchRecommender'
import { MiniCartItems } from '@components/Checkout'
import { GoToBagButton } from '@components/Checkout/styles'
import { CloseIcon } from '@components/core/Icons'
import { PanelHeader } from '@components/core/PanelHeader'
import { PanelHeaderTitle, PanelHeaderTitleCount } from '@components/core/PanelHeader/styles'
import { useActions } from '@hooks/useActions'
import { useCart } from '@hooks/useCart'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { engravedSummaryValues } from '@libs/engraving'
import { isNuanceProduct } from '@libs/nuance'
import { pxToRem } from '@libs/styled'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSendMinicartOverlayOpenEvent, useSendRemoveItemFromCart } from './analytics'
import {
  CartSummaryMiniCard,
  DrawerMiniCartItemsContainer,
  MiniBagCloseButton,
  MiniCartContainer,
  MiniCartEmpty,
  MiniCartPanelHeader,
  MiniCartWrapper,
  MinicartDrawer,
} from './styles'

export const MiniCartDrawerComponent: React.FC = () => {
  const { t } = useTranslation()
  const engravingDetails = useSelector(state => state.engraving)
  const cartItems = useSelector(state => state.cart.items)
  const { basePath } = useStoreIndentity()
  const { zoomLevel } = useNavigationContext()

  const { isVisible } = useDrawerHandler('minicart')

  const actions = useActions({
    removeItemFromCart,
  })

  const { closeAnyDrawer } = useDrawerHandler()

  const { numberItems, subtot, items, itemsWithEngrPrice } = useCart()

  const engravingInfo = engravedSummaryValues(items, engravingDetails)

  const sendRemoveItemFromCart = useSendRemoveItemFromCart()

  const handleClickRemoveFromCart = useCallback(
    (id, openMinicart) => {
      const itemToRemove = items.find(item => item.id === id)
      const product = itemToRemove?.product

      actions.removeItemFromCart(id, openMinicart)
      if (product) {
        sendRemoveItemFromCart(product)
      }
    },
    [actions, items, sendRemoveItemFromCart]
  )

  useSendMinicartOverlayOpenEvent(isVisible)

  const dataAttrs: DataAttrs = {
    link: 'MainNav_BagProd-Img',
    removeBtn: 'MainNav_BagProd-Remove',
    bagBtn: 'MainNav_Bag',
  }

  const nuanceItems = useMemo(
    () => cartItems?.filter(item => isNuanceProduct(item.product)) ?? [],
    [cartItems]
  )

  const showMerchRecommender = nuanceItems.length > 0

  return (
    <MinicartDrawer visible={isVisible}>
      <MiniBagCloseButton
        onClick={closeAnyDrawer}
        data-analytics_available_call={0}
        data-test="Main-nav_ Close-button"
        aria-label={t('ARIA.closeIcon.label')}
      >
        <CloseIcon height={pxToRem(34, zoomLevel / 100)} width={pxToRem(34, zoomLevel / 100)} />
      </MiniBagCloseButton>
      <MiniCartWrapper>
        <MiniCartContainer>
          <MiniCartPanelHeader>
            <PanelHeader
              closeIcon={<></>}
              onClose={closeAnyDrawer}
              dataAttr={{ test: 'Main-nav_ Close-button' }}
            >
              <PanelHeaderTitle aria-label={t('SiderMenu.title')} weight="bold">
                {t('SiderMenu.title')}{' '}
                <PanelHeaderTitleCount>({numberItems})</PanelHeaderTitleCount>
              </PanelHeaderTitle>
            </PanelHeader>

            {numberItems > 0 && (
              <CartSummaryMiniCard
                subtot={subtot}
                tot={subtot + engravingInfo.price}
                hideCheckOutButton
                engravingInfo={engravingInfo}
                hideSubtotalSection
                hideShippingSection
              />
            )}
          </MiniCartPanelHeader>
          {numberItems === 0 && (
            <MiniCartEmpty
              aria-label={t('SiderMenu.empty')}
              aria-description={t('SiderMenu.empty')}
            >
              {t('SiderMenu.empty')}
            </MiniCartEmpty>
          )}
          {numberItems > 0 && (
            <GoToBagButton
              dataAttrs={{
                id: dataAttrs && dataAttrs.bagBtn,
                test: 'button-cart',
              }}
              id="go-to-bag"
              navigateTo={`${basePath}/cart`}
              ariaAttrs={{ label: `${t('SummaryCart.go')} ${subtot + engravingInfo.price}` }}
              fullWidth
            >
              {t('SummaryCart.go')}
            </GoToBagButton>
          )}
        </MiniCartContainer>
        {numberItems > 0 && (
          <>
            <DrawerMiniCartItemsContainer>
              <MiniCartItems
                ref={null}
                items={itemsWithEngrPrice}
                removeItemFromCart={handleClickRemoveFromCart}
                dataAttrs={dataAttrs}
              />
            </DrawerMiniCartItemsContainer>
            {showMerchRecommender && <CartMerchRecommender cartItems={nuanceItems} />}
          </>
        )}
      </MiniCartWrapper>
    </MinicartDrawer>
  )
}

export const MiniCartDrawer = MiniCartDrawerComponent
