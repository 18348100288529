import { IconProps } from '@abstractTypes/icons'

export const WarningIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12.5003 8H11.5003V17H12.5003V8Z" fill={fill} />
      <path d="M12.5003 19H11.5003V20H12.5003V19Z" fill={fill} />
      <path
        d="M23.3888 21.9705L12.9453 1.084C12.7508 0.6945 12.3753 0.5 12.0003 0.5C11.6253 0.5 11.2498 0.6945 11.0553 1.084L0.611833 21.9705C0.260833 22.673 0.771333 23.5 1.55733 23.5H22.4438C23.2293 23.5 23.7403 22.673 23.3888 21.9705ZM22.4918 22.473C22.4753 22.5 22.4568 22.5 22.4433 22.5H1.55733C1.54383 22.5 1.52583 22.5 1.50883 22.473C1.49233 22.446 1.50033 22.4295 1.50633 22.418L11.9493 1.5315C11.9578 1.5155 11.9653 1.5 12.0003 1.5C12.0353 1.5 12.0428 1.5155 12.0513 1.5315L22.4943 22.418C22.5003 22.4295 22.5083 22.446 22.4918 22.473Z"
        fill={fill}
      />
    </svg>
  )
}
