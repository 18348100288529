import { IconProps } from '@abstractTypes/icons'
export const GogglesIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      id="espanso"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      xmlSpace="preserve"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      className={className}
    >
      <style type="text/css">{'\n\t.st0{display:none;fill:none;}\n'}</style>
      <rect className="st0" width={48} height={48} />
      <g>
        <path d="M10,23c0.256,0,0.512-0.098,0.707-0.293l2-2c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-2,2 c-0.391,0.391-0.391,1.023,0,1.414C9.488,22.902,9.744,23,10,23z" />
        <path d="M16.293,19.293l-7,7c-0.391,0.391-0.391,1.023,0,1.414C9.488,27.902,9.744,28,10,28s0.512-0.098,0.707-0.293l7-7 c0.391-0.391,0.391-1.023,0-1.414S16.684,18.902,16.293,19.293z" />
        <path d="M46,18h-2v-0.291c0-1.486-1.065-2.732-2.532-2.963l-5.423-0.856c-7.993-1.263-16.098-1.262-24.09,0l-5.423,0.856 C5.065,14.977,4,16.223,4,17.709V18H2c-1.103,0-2,0.897-2,2v8c0,1.103,0.897,2,2,2h2.118c0.281,1.178,1.08,2.193,2.206,2.712 l6.854,3.163c0.933,0.431,1.926,0.638,2.911,0.638c2.156,0,4.271-0.992,5.622-2.793L22.4,32.8c0.762-1.018,2.437-1.018,3.2,0 l0.69,0.919c1.967,2.623,5.556,3.529,8.533,2.156l6.854-3.163c1.126-0.52,1.925-1.535,2.206-2.712H46c1.103,0,2-0.897,2-2v-8 C48,18.897,47.103,18,46,18z M2,20h2v8H2V20z M40.838,30.896l-6.854,3.164c-2.126,0.981-4.69,0.334-6.095-1.54L27.2,31.6 C26.438,30.583,25.271,30,24,30s-2.438,0.583-3.2,1.6l-0.69,0.919c-1.405,1.874-3.968,2.52-6.095,1.54l-6.854-3.164 C6.456,30.571,6,29.858,6,29.081V18v-0.291c0-0.495,0.355-0.911,0.844-0.988l5.423-0.856C16.16,15.25,20.08,14.943,24,14.943 c3.92,0,7.84,0.308,11.733,0.922l5.423,0.856C41.645,16.798,42,17.214,42,17.709V18v11.081C42,29.858,41.544,30.571,40.838,30.896z  M44,28v-8h2l0.001,8H44z" />
      </g>
    </svg>
  )
}
