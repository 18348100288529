import { useTranslateFilterValue } from '@hooks/useTranslateFilterValue'
import { removeSpaces } from '@libs/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NuanceDetailListContainer } from '../styles'
import NuanceDetailsRow from './NuanceDetailsRow'
import { DetailsProps } from './types'

export const NuanceDetailsList: React.FC<DetailsProps> = ({ product, className = '' }) => {
  const { t } = useTranslation()
  const translateFilterValue = useTranslateFilterValue()
  return (
    <NuanceDetailListContainer className={className}>
      <NuanceDetailsRow label={t('ProductDescription.brand')} value={product.brand.name} />
      <NuanceDetailsRow label={t('ProductDescription.name')} value={product.name} />
      <NuanceDetailsRow label={t('ProductDescription.productCode')} value={product.UPC} />
      <NuanceDetailsRow
        label={t('ProductDescription.modelColor')}
        value={`${product.model} ${product.colorCode}`}
      />

      {product.isNuanceFrame && (
        <>
          <NuanceDetailsRow
            label={t('ProductDescription.frameShape')}
            value={translateFilterValue(product.frameShape, 'frameShape')}
          />
          <NuanceDetailsRow
            label={t('ProductDescription.frameMaterial')}
            value={translateFilterValue(product.frameMaterial, 'frameMaterial')}
          />
          <NuanceDetailsRow
            label={t('ProductDescription.frameColor')}
            value={translateFilterValue(product.frameColorLabel, 'frameColorLabel')}
          />

          <NuanceDetailsRow
            label={t('ProductDescription.lensMaterial')}
            value={translateFilterValue(product.lensMaterial, 'lensMaterial')}
          />
          <NuanceDetailsRow
            label={t('ProductDescription.lensColor')}
            value={translateFilterValue(product.lensColorLabel, 'lensColorLabel')}
          />
          <NuanceDetailsRow
            label={t('ProductDescription.lookBest')}
            value={translateFilterValue(product.faceShape, 'faceShape')}
          />
          <NuanceDetailsRow label={t('ProductDescription.size')} value={product.sizeFull} />
          <NuanceDetailsRow
            label={t('ProductDescription.fit')}
            value={
              product.frameFitting &&
              t(`ProductDescription.frameFittingDescription.${product.frameFitting?.toLowerCase()}`)
            }
          />
          <NuanceDetailsRow
            label={t('ProductDescription.frameFitting')}
            value={
              product.geoFit &&
              t(
                `ProductDescription.geoFitDescription.${removeSpaces(product.geoFit).toLowerCase()}`
              )
            }
          />
        </>
      )}
    </NuanceDetailListContainer>
  )
}

export const Details = NuanceDetailsList
