import CheckoutProductCard from '@components/CardProduct/CheckoutProductCard'
import { useCart } from '@hooks/useCart'
import useIsScrollable from '@hooks/useIsScrollable'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { isIPadView } from '@libs/utils'
import { useSelector } from 'react-redux'
import { CheckoutRecapItemsContainer } from './styles'
import { CheckoutRecapItemsProps } from './types'

export const CheckoutRecapItems = ({ className }: CheckoutRecapItemsProps) => {
  const { ref } = useIsScrollable<HTMLDivElement>()
  const { itemsWithEngrPrice } = useCart()
  const isIPad = isIPadView()
  const { landscapeNavigation } = useNavigationContext()
  const engravingDetailsItems = useSelector(s => s.engraving.items)

  return (
    <CheckoutRecapItemsContainer className={className} ref={ref}>
      {itemsWithEngrPrice.map(e => {
        return (
          <CheckoutProductCard
            cartItem={e}
            key={e.id}
            showRTRIcon
            showVTOIcon
            isCondensed={isIPad && landscapeNavigation}
            engravingItems={engravingDetailsItems}
          />
        )
      })}
    </CheckoutRecapItemsContainer>
  )
}
