import { ImageRatio, DeviceImageRatio } from '@abstractTypes/image'
import { useDeviceType } from './useDeviceType'

export const useImageRatio = (
  forceRatio: DeviceImageRatio = {
    ipad: '_3x4',
    ipadlandscape: '_4x3',
    tower: '_9x16',
    towerLandscape: '_16x9',
  }
): ImageRatio => {
  const { isIpad, isIpadlandscape, isTowerLandscape } = useDeviceType()

  if (isIpad && !isIpadlandscape) {
    return (forceRatio && forceRatio.ipad) || '_3x4'
  }

  if (isIpadlandscape) {
    return (forceRatio && forceRatio.ipadlandscape) || '_4x3'
  }

  if (isTowerLandscape) {
    return (forceRatio && forceRatio.towerLandscape) || '_16x9'
  }

  return (forceRatio && forceRatio.tower) || '_9x16'
}
