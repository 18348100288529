import { CheckoutFormState } from '@abstractTypes/checkout'
import {
  CHANGE_FORM_PANEL,
  SET_FORM_ERRORS,
  SET_CART_TYPE,
  SET_DATA,
  VALIDATE_FIELD,
  CheckoutFormActions,
} from '@actions/checkout'
import { validateField } from '@libs/checkout'

const initialState: CheckoutFormState = {
  activePanels: ['shipping', 'shippingType'],
  data: {
    shipping: 'home',
    shippingType: undefined,
    localName: '',
    localName2: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerification: '',
    phone: '',
    address: '',
    address1: '',
    zip: '',
    city: '',
    state: undefined,
    marketing: '',
    profiling: '',
  },
  errors: {},
  cartType: 'frame-only',
}

export default (state = initialState, action: CheckoutFormActions): CheckoutFormState => {
  switch (action.type) {
    case CHANGE_FORM_PANEL: {
      const { activePanels } = action.payload
      return {
        data: { ...state.data },
        errors: { ...state.errors },
        activePanels,
        cartType: state.cartType,
      }
    }
    case SET_DATA: {
      const { field, data } = action.payload
      const newdata = { ...state.data }
      newdata[field] = data
      return {
        ...state,
        activePanels: state.activePanels,
        errors: { ...state.errors },
        data: newdata,
        cartType: state.cartType,
      }
    }

    case VALIDATE_FIELD: {
      const { field, value, storeData, validators } = action.payload
      // need to check here for actual form data
      const error = validateField(validators, value, state.data, storeData)
      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: error,
        },
      }
    }

    case SET_FORM_ERRORS: {
      const { errors } = action.payload
      return {
        ...state,
        errors: { ...errors },
      }
    }

    case SET_CART_TYPE: {
      const { cartType } = action.payload
      return {
        ...state,
        cartType: cartType,
      }
    }

    default:
      return state
  }
}
