import { EngravingSelectedOptions } from '@abstractTypes/engraving'
import { Product } from '@abstractTypes/product'
import {
  CustomTitle,
  PrescriptionLabel,
  ProductDescriptionItem,
  ProductDescriptionWrapper,
} from '@components/CardProductBase/ProductCard/ProductDescription/styles'
import CartEngravingDetails from '@components/Cart/CartEngravingDetails'
import { Text } from '@components/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ProductDescriptionProps {
  product: Product
  isCondensed?: boolean
  engraving?: EngravingSelectedOptions
}

export const ProductDescription: React.FC<ProductDescriptionProps> = ({
  product,
  isCondensed,
  engraving,
}) => {
  const { t } = useTranslation()
  const { customitemEngraving } = product
  const hasCustomEngraving = !!customitemEngraving?.sides?.length
  const hasCustomCaseEngraving = !!customitemEngraving?.caseEngraving
  const hasTempletips = customitemEngraving?.templetipColor
  const frameColorTranslationKey = product.isAfa
    ? 'ProductDescription.color'
    : 'ProductDescription.frameColor'

  return (
    <ProductDescriptionWrapper isCondensed={isCondensed}>
      <ProductDescriptionItem
        aria-label={t(frameColorTranslationKey)}
        aria-description={t(frameColorTranslationKey)}
      >
        <Text weight="bold">{t(frameColorTranslationKey)}:</Text>
        <Text>{product.frameColorLabel}</Text>
      </ProductDescriptionItem>

      {!product.isAfa && (
        <ProductDescriptionItem
          aria-label={t('ProductDescription.lensColor')}
          aria-description={t('ProductDescription.lensColor')}
        >
          <Text weight="bold">{t('ProductDescription.lensColor')}:</Text>
          <Text>{product.lensColorLabel}</Text>
        </ProductDescriptionItem>
      )}

      {product.lensColor2 && (
        <ProductDescriptionItem
          aria-label={`${t('ProductDescription.lensColor')} 2`}
          aria-description={`${t('ProductDescription.lensColor')} 2`}
        >
          <Text weight="bold">{t('ProductDescription.lensColor')} 2:</Text>
          <Text>{product.lensColor2}</Text>
        </ProductDescriptionItem>
      )}

      {product.iconColorLabel && (
        <ProductDescriptionItem
          aria-label={t('ProductDescription.iconColor')}
          aria-description={t('ProductDescription.iconColor')}
        >
          <Text weight="bold">{t('ProductDescription.iconColor')}:</Text>
          <Text>{product.iconColorLabel}</Text>
        </ProductDescriptionItem>
      )}

      {product.iconColor2 && (
        <ProductDescriptionItem
          aria-label={`${t('ProductDescription.iconColor')} 2`}
          aria-description={`${t('ProductDescription.iconColor')} 2`}
        >
          <Text weight="bold">{t('ProductDescription.iconColor')} 2:</Text>
          <Text>{product.iconColor2}</Text>
        </ProductDescriptionItem>
      )}

      {product.sizeFull && (
        <ProductDescriptionItem
          aria-label={t('ProductDescription.size')}
          aria-description={t('ProductDescription.size')}
        >
          <Text weight="bold">{t('ProductDescription.size')}:</Text>
          <Text>{product.sizeFull}</Text>
        </ProductDescriptionItem>
      )}

      {hasTempletips && (
        <ProductDescriptionItem>
          <Text weight="bold">{t('CustomEngraving.templeTipsColor')}:</Text>
          <Text>{customitemEngraving?.templetipColor}</Text>
        </ProductDescriptionItem>
      )}

      {engraving && (
        <ProductDescriptionItem>
          <Text weight="bold">{t('CustomEngraving.engraved')}:</Text>
          <CartEngravingDetails engravingOption={engraving} />
        </ProductDescriptionItem>
      )}

      {hasCustomEngraving && (
        <ProductDescriptionItem>
          <Text weight="bold">{t('CustomEngraving.engraving')}</Text>
          <div>
            <CustomTitle>
              {[customitemEngraving.font, customitemEngraving.color].filter(t => t).join(' / ')}
            </CustomTitle>
            <div>
              {customitemEngraving.sides.map((side, i) => (
                <Text key={i}>
                  <p>
                    {t(`${side.title}`)} {side.value}
                  </p>
                </Text>
              ))}
            </div>
          </div>
        </ProductDescriptionItem>
      )}

      {customitemEngraving?.caseColor && (
        <ProductDescriptionItem>
          <Text weight="bold">{t('CustomEngraving.case')}:</Text>
          <Text>{customitemEngraving.caseColor}</Text>
        </ProductDescriptionItem>
      )}

      {hasCustomCaseEngraving && (
        <ProductDescriptionItem>
          <Text weight="bold">{t('CustomEngraving.caseEngraving')}</Text>
          <Text>{`${customitemEngraving.caseFont} / ${customitemEngraving.caseEngraving}`}</Text>
        </ProductDescriptionItem>
      )}

      {product.completePair && (
        <PrescriptionLabel
          aria-label={t('Prescription.withPrescription')}
          aria-description={t('Prescription.withPrescription')}
          weight="bold"
        >
          {t('Prescription.withPrescription')}
        </PrescriptionLabel>
      )}
    </ProductDescriptionWrapper>
  )
}
