import { SizeAndFitIllustrationsProps } from '@abstractTypes/icons'

export const BridgeAndNosepadsIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  accent = '#333',
}: SizeAndFitIllustrationsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 375 169"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M193.263 52.4055C188.999 52.4055 189.849 69.8708 185.262 90.4183C183.135 99.9454 172.787 103.36 167.894 103.901"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M45.4082 19.4641C51.6861 24.3864 52.5124 29.8864 53.0953 32.3988C56.0405 45.1011 44.2699 67.395 24.0098 70.5808"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M206.137 24.1303L205.04 48.0314C205.02 48.3932 204.886 48.7393 204.657 49.02C204.428 49.3007 204.116 49.5016 203.765 49.5938L191.142 52.8007C190.608 52.9367 190.113 53.1955 189.697 53.5566C189.28 53.9177 188.954 54.371 188.744 54.8804C187.579 57.6967 186.722 61.4347 186.061 66.4849C183.58 85.3804 183.858 94.8332 174.997 101.042C169.717 104.742 159.825 105.667 140.585 103.522C121.891 101.439 106.783 97.6976 90.494 86.2986C84.2716 81.9508 80.5813 76.9759 78.9366 74.1527C70.6515 59.9032 69.4316 53.3044 62.4897 36.7456C60.4784 31.9489 56.2673 21.6703 48.0232 21.6703C39.9642 21.6703 40.3343 31.3356 39.022 48.052C38.2647 57.6762 32.3747 68.2665 26.8992 70.2434C22.0748 71.9942 14.869 71.9942 10.3872 71.8024L10 67.6909C12.5067 67.9375 15.0315 67.9375 17.5382 67.6909C21.715 67.2798 24.9393 67.1017 27.1596 64.6725C30.1098 61.4484 31.6894 56.6003 32.8715 52.5609C34.2901 47.7094 34.4134 43.2588 34.6019 34.5631C34.78 26.3985 34.4271 22.2288 34.081 19.3336C33.6116 15.3764 33.3581 13.3584 32.5254 11.5459C29.7158 5.37877 22.8046 2.76802 18.6929 1.66821L34.0297 1.80866C39.8174 4.26328 45.8948 5.96962 52.1144 6.8863C68.3524 9.26065 77.5146 4.43999 90.4221 5.33422C103.823 6.26615 124.892 6.20105 193.901 24.9903C194.347 25.111 194.811 25.1516 195.271 25.1102L206.137 24.1303Z"
        fill={fill}
      />
      <path
        d="M34.0726 1.80866C39.8716 4.26328 45.9606 5.96962 52.1923 6.8863C68.4616 9.26065 77.1884 5.33422 90.5739 5.33422C97.9584 5.33422 125.11 6.20105 194.252 24.9903C194.699 25.111 195.164 25.1516 195.626 25.1102L206.515 24.1303L205.417 48.0314C205.397 48.3932 205.262 48.7393 205.033 49.02C204.803 49.3007 204.491 49.5016 204.14 49.5938L191.492 52.8007C190.957 52.9367 190.461 53.1955 190.044 53.5566C189.627 53.9177 189.3 54.371 189.089 54.8804C187.922 57.6967 187.063 61.4347 186.401 66.4849C183.915 85.3804 184.193 94.8332 175.316 101.041C170.025 104.742 160.114 105.667 140.837 103.522C122.107 101.439 106.97 97.6976 90.6494 86.2986C84.415 81.9508 80.7176 76.9759 79.0697 74.1527C70.7686 59.9032 69.5464 53.3044 62.591 36.7456C60.5758 31.9489 56.3566 21.6703 48.0967 21.6703C40.0221 21.6703 40.3929 31.3356 39.078 48.052C38.3193 57.6762 32.4179 68.2665 26.9318 70.2434C22.0981 71.9942 14.8784 71.9942 10.3879 71.8024L10 67.6909C12.5115 67.9375 15.0412 67.9375 17.5527 67.6909C21.7376 67.2798 24.9681 67.1017 27.1927 64.6725C30.1486 61.4484 31.7313 56.6003 32.9157 52.5609C34.337 47.7094 34.4605 43.2588 34.6494 34.5631C34.8279 26.3985 34.4743 22.2288 34.1275 19.3336C33.6572 15.3764 33.4032 13.3584 32.5689 11.5459C29.747 5.37877 22.8225 2.76802 18.7028 1.66821"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M69.4473 23.2512C78.5138 30.734 86.1928 33.2487 90.6013 39.9719C98.1402 51.4509 91.835 56.9116 93.1473 70.7069C94.1008 80.7386 99.35 84.5604 112.234 91.4068"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M172.816 94.5835C171.916 96.0078 170.936 96.9276 169.888 97.2125C142.951 104.526 101.812 95.3934 85.8277 76.9975C78.0968 68.1015 63.7345 35.229 67.9264 18.3775C68.4452 16.2943 70.5549 14.791 73.8534 13.7854L73.874 13.8575C66.9162 45.5356 87.0372 73.2943 93.6033 78.9778C117.885 99.9856 166.078 95.9872 172.816 94.5835Z"
        fill={fill}
      />
      <path
        d="M172.626 94.5904C171.728 96.0128 170.75 96.9313 169.704 97.2158C142.815 104.52 101.75 95.3993 85.7942 77.0283C78.0772 68.1445 63.7407 35.3167 67.925 18.4881C68.4429 16.4077 70.5488 14.9064 73.8414 13.9022C94.2041 7.69174 159.854 20.4143 174.369 31.8413C182.13 37.9524 179.963 82.9886 172.626 94.5904Z"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M172.816 94.706C166.081 96.1065 117.909 100.096 93.6381 79.1357C87.0749 73.4651 66.9628 45.7695 73.9175 14.1633"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M10 67.5518L12.7219 66.2517C12.7219 66.2517 25.9467 66.2517 27.0389 64.5227"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M135.33 52.7847L145.175 43.3186"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M134.952 61.1151L160.321 35.3674"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M155.02 49.3769L165.243 39.1536"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M233.971 10.7551C233.971 10.7551 267.739 22.175 286.493 22.6255C300.332 22.9587 349.817 17.4079 353.414 17.6464C352.959 18.0932 352.842 18.2068 350.286 20.2288C325.709 22.0387 301.843 25.4464 286.372 25.3821C267.201 25.3063 227.879 10.7551 227.879 10.7551H233.971Z"
        stroke={stroke}
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <path
        d="M350.289 25.3863L353.413 21.9179V17.6506"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <mask id="path-15-inside-1_23753_40491" fill={fill}>
        <path d="M363.334 117.116L364.951 114.882L365 110.975L362.993 113.178" />
      </mask>
      <path
        d="M364.951 114.882L365.599 115.351L365.747 115.145L365.751 114.892L364.951 114.882ZM365 110.975L365.8 110.985L365.826 108.88L364.409 110.436L365 110.975ZM363.982 117.585L365.599 115.351L364.303 114.413L362.686 116.647L363.982 117.585ZM365.751 114.892L365.8 110.985L364.2 110.965L364.151 114.871L365.751 114.892ZM364.409 110.436L362.402 112.639L363.585 113.716L365.591 111.514L364.409 110.436Z"
        fill={stroke}
        mask="url(#path-15-inside-1_23753_40491)"
      />
      <path
        d="M364.996 111.123C364.996 111.123 342.084 109.725 327.662 104.019C305.7 95.3371 300.948 42.3802 305.92 35.1065C309.793 29.4269 317.4 27.7874 317.4 27.7874"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M246.205 96.5588C246.205 96.5588 254.244 93.0639 260.779 81.9962C269.609 67.0285 270.696 58.8082 276.247 42.1479C278.693 34.8136 286.621 36.2752 286.621 36.2752"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M303.985 85.6268C305.579 90.134 307.665 94.4515 310.206 98.5006C318.892 111.992 342.391 117.194 363.144 116.922V113.237C339.748 112.48 320.456 106.206 313.891 95.7744C305.916 83.0975 299.479 46.7138 302.368 37.2553C305.742 26.2103 333.266 25.896 333.266 25.896L350.305 25.3849V20.2278"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M300.078 72.2473C300.707 74.9319 301.418 77.643 302.229 80.3313"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M290.604 37.9299C291.463 38.4902 292.194 39.2272 292.747 40.092C294.946 43.708 296.154 52.5569 298.123 62.9582"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M206.515 24.0078L203.432 22.4588H194.868C178.082 18.1561 169.689 15.2302 150.506 11.4438C121.383 5.69564 110.426 4.7315 106.144 4.38728C101.862 4.04306 95.5247 3.52677 86.4468 3.87098C83.8452 3.96963 68.1197 5.59205 59.3844 5.24783C56.4155 5.24783 48.9705 4.45613 42.9414 1.28931"
        stroke={stroke}
        strokeWidth="0.8"
      />
      <path
        d="M253.421 82.6078C241.437 98.9386 227.878 96.3071 227.878 96.3071L226.303 98.3517V100.926C226.303 100.926 241.922 101.195 249.226 94.27C270.373 74.202 269.551 44.471 275.42 39.1511C279.26 35.6675 284.409 35.3646 288.218 36.7467"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M267.689 39.4153C267.92 44.97 266.606 50.4375 265.678 54.1482C265.603 54.4398 265.531 54.7351 265.455 55.0267"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M227.879 10.7551V15.7949C235.228 17.3322 261.601 24.5604 265.679 30.6566C265.834 30.928 265.961 31.2148 266.057 31.5123"
        stroke={stroke}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M247.541 77.8279C247.541 82.1027 251.528 82.682 253.417 82.5912C262.009 82.1747 271.517 58.006 265.455 55.0109C259.646 52.1408 258.173 62.9889 253.872 68.8011C252.232 71.0313 247.568 75.0865 247.541 77.8279Z"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M248.17 80.3766C247.034 78.8621 246.655 76.1094 247.322 74.6175C248.39 72.2396 251.619 69.0894 253.051 67.2492C255.501 64.0989 258.621 55.0228 262.854 54.6555"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M252.491 67.9235C252.14 67.7423 251.829 67.4914 251.578 67.1862C251.326 66.881 251.14 66.5279 251.03 66.1483C250.919 65.7687 250.888 65.3707 250.936 64.9784C250.985 64.5861 251.112 64.2078 251.312 63.8665C251.511 63.5253 251.778 63.2282 252.096 62.9934C252.414 62.7586 252.777 62.591 253.162 62.5008C253.546 62.4107 253.946 62.3998 254.335 62.4689C254.724 62.538 255.095 62.6856 255.425 62.9027"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M253.126 67.2259C252.906 67.1176 252.71 66.965 252.552 66.7777C252.393 66.5905 252.275 66.3725 252.204 66.1375C252.133 65.9025 252.111 65.6554 252.14 65.4116C252.169 65.1679 252.248 64.9327 252.372 64.7208C252.496 64.5089 252.662 64.3247 252.86 64.1798C253.058 64.035 253.284 63.9324 253.524 63.8786C253.763 63.8248 254.011 63.821 254.252 63.8673C254.493 63.9135 254.722 64.009 254.925 64.1476"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M253.809 62.4057C253.809 62.4057 264.649 39.7478 265.872 37.2715C267.095 34.7952 265.929 33.8637 265.929 33.8637C264.63 33.8637 264.335 33.345 264.335 32.6861C264.336 32.3695 264.462 32.0661 264.687 31.8425C264.911 31.6189 265.215 31.4934 265.531 31.4934H269.908C270.225 31.4934 270.529 31.6189 270.753 31.8425C270.977 32.0661 271.104 32.3695 271.105 32.6861C271.107 32.845 271.078 33.0028 271.019 33.1504C270.96 33.298 270.873 33.4324 270.761 33.5458C270.65 33.6592 270.517 33.7494 270.371 33.8111C270.225 33.8728 270.067 33.9049 269.908 33.9054H269.53C269.504 33.9047 269.479 33.9094 269.456 33.9193C269.432 33.9291 269.411 33.9439 269.394 33.9625C269.377 33.9812 269.364 34.0033 269.356 34.0274C269.348 34.0515 269.345 34.077 269.348 34.1023C269.397 34.5869 269.409 35.9387 268.447 37.8887C267.629 39.5433 262.521 50.2021 259.219 57.0972"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M299.309 70.9297C299.899 70.9297 300.377 70.4517 300.377 69.862C300.377 69.2722 299.899 68.7942 299.309 68.7942C298.719 68.7942 298.241 69.2722 298.241 69.862C298.241 70.4517 298.719 70.9297 299.309 70.9297Z"
        fill={fill}
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M295.277 69.017C294.739 67.7195 294.264 66.3968 293.853 65.0489C293.482 63.8372 293.161 62.613 292.891 61.3761C292.63 60.1644 292.535 59.0057 291.892 57.9266C291.35 57.0141 290.298 56.3212 289.241 56.537C288.835 56.6492 288.462 56.858 288.154 57.1455C287.846 57.4329 287.613 57.7905 287.473 58.1879C286.416 60.8043 287.219 64.2461 287.946 66.8436C289.199 71.3116 291.274 75.5107 293.69 79.4675C294.926 81.6027 296.411 83.5842 298.113 85.3706C298.676 85.9756 299.34 86.479 300.074 86.8586C300.816 87.2399 301.677 87.316 302.475 87.0707C304.16 86.4762 304.614 84.4202 303.989 82.9056C303.145 80.914 301.316 79.5357 300.089 77.794C298.782 75.9329 297.616 73.976 296.602 71.9401C296.117 70.9897 295.655 70.0129 295.277 69.017Z"
        fill={fill}
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M289.673 58.7893C289.673 58.7893 292.759 75.6768 301.653 83.8441"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M295.277 69.0175L299.492 66.7911C300.434 66.5374 301.385 66.8214 302.104 68.1694C303.346 70.5472 301.953 71.1985 301.953 71.1985L297.462 73.7013"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M297.215 67.9828C297.215 67.9828 286.886 40.2359 288.332 36.1466C289.586 32.6177 295.663 33.1175 296.223 33.2121C296.783 33.3068 296.802 35.0826 296.079 35.0561C294.39 34.9993 291.445 34.401 290.646 36.9114C289.71 39.8573 299.491 66.7825 299.491 66.7825L297.215 67.9828Z"
        stroke={accent}
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M299.783 96.3977L296.896 101.398L302.67 101.398L299.783 96.3977ZM299.283 99.5114L299.283 101.587L300.283 101.587L300.283 99.5114L299.283 99.5114ZM299.283 103.663L299.283 105.739L300.283 105.739L300.283 103.663L299.283 103.663ZM299.283 107.815L299.283 109.89L300.283 109.89L300.283 107.815L299.283 107.815ZM299.283 111.966L299.283 114.042L300.283 114.042L300.283 111.966L299.283 111.966ZM299.283 116.118L299.283 118.193L300.283 118.193L300.283 116.118L299.283 116.118ZM299.283 120.269L299.283 122.345L300.283 122.345L300.283 120.269L299.283 120.269ZM299.283 124.421L299.283 126.497L300.283 126.497L300.283 124.421L299.283 124.421ZM299.283 128.572L299.283 130.648L300.283 130.648L300.283 128.572L299.283 128.572ZM299.283 132.724L299.283 133.762L300.283 133.762L300.283 132.724L299.283 132.724Z"
        fill={accent}
      />
    </svg>
  )
}
