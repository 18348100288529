import { Device } from '@abstractTypes/utils'
import {
  ScrollableContentContainer,
  ScrollablePaneContainer,
  StyledScrollableTabContent,
  TabContent,
  TabPane,
  TabsContainer,
} from '@components/Tabs/styles'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  tabletPortrait,
  zoomLandscape,
} from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import styled, { css, keyframes } from 'styled-components'
import {
  altNavigationSliderHeight,
  animationDuration,
  landscapeTabletMiniSliderHeight,
  landscapeTabletMiniSliderWidth,
  landscapeTabletSliderHeight,
  landscapeTabletSliderWidth,
} from '../styleConsts'

const scrollHiding = keyframes`
  0% {
    overflow: hidden;
  }

  100% {
    overflow: hidden;
  }
`
export const ProductTabsContainer = styled.div<{
  isExpanded: boolean
  sliderShowed?: boolean
  isTabsListSticky: boolean
}>`
  block-size: ${({ isExpanded }) => (isExpanded ? pxToRem(1398) : pxToRem(639))};
  transition: height ${animationDuration};
  ${tablet(`
      margin-block-end: 0;
      block-size: auto;
  `)}
  ${landscape(`
    block-size: auto;
    margin-block-end: 0;
    justify-content: center;
  `)}
  ${landscape(
    Device.tablet,
    `
      margin-block-end: 0;
      block-size: 100%;
    `
  )}
  ${altNavigation(`
    block-size: ${pxToRem(724)};
    margin-block-end: 0;
  `)}

  ${StyledScrollableTabContent} {
    animation-name: ${({ isExpanded }) => (isExpanded ? scrollHiding : '')};
    animation-duration: ${animationDuration};
    animation-timing-function: linear;
    padding-block: ${pxToRem(40)};
    padding-inline: ${pxToRem(54)} ${pxToRem(44)};

    ${landscape(`
      display: flex;
      flex-direction: column;
      padding-inline: 0 ${pxToRem(54)};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-inline-end: ${pxToRem(16)};
      overflow-x: hidden;
    `
    )}

    ::-webkit-scrollbar-track {
      margin-block: ${pxToRem(40)};
    }

    ${tabletPortrait(
      `
      ::-webkit-scrollbar-track {
      margin-block: ${clampValues(16, 32)};
    }
    `
    )}

    ${landscape(`
      ::-webkit-scrollbar-track {
      margin-block: ${pxToRem(50)};
    }
    `)}

    ${landscape(
      Device.tablet,
      `
        ::-webkit-scrollbar-track {
          margin-block: ${clampValues(24, 40)};
        }
      `
    )}

    ::before,
    ::after {
      content: none;
    }
  }

  ${ScrollableContentContainer} {
    flex: 1;
    ${landscape(`
      transition: right ${animationDuration} linear;
      position: absolute;
      inline-size: ${pxToRem(956)};
      block-size: ${pxToRem(948)};
      inset-block-start: 0;
      inset-inline-end: 0;
      ${tablet(`
        inline-size: calc(100vw - ${landscapeTabletSliderWidth});
        block-size: ${landscapeTabletSliderHeight};
      `)}
      ${tablet(
        Device.ipad11,
        `
        inline-size: calc(100vw - ${landscapeTabletMiniSliderWidth});
        block-size: ${landscapeTabletMiniSliderHeight};
      `
      )}
      ${tablet(
        Device.ipadMini,
        `
        inline-size: calc(100vw - ${landscapeTabletMiniSliderWidth});
        block-size: ${landscapeTabletMiniSliderHeight};
      `
      )}
    `)}
    ${altNavigation(`
      inset-block-start: ${pxToRem(136)};
      position: absolute;
      inset-inline-end: 0;
      inline-size: ${altNavigationSliderHeight};
      block-size: auto;
    `)}
    ${altNavigationZoom(
      [150, 175],
      `
      max-block-size: 100%;
      overflow: scroll;
    `
    )}
    ${({ sliderShowed }) => css`
      ${altNavigationZoom(
        [100, 110, 125, 150, 175, 200],
        `
          inset-block-start: ${sliderShowed ? pxToRem(664) : pxToRem(136)};
          inset-inline-start: 0;
          position: absolute;
          inline-size: 100%;
      `
      )}
      ${zoomLandscape(
        [200],
        `
        inset-inline-start: unset;
      `
      )}
    `}
  }

  ${ScrollablePaneContainer} {
    z-index: 1;
    padding-block: ${pxToRem(42)};
    padding-inline: ${pxToRem(48)};
    gap: ${pxToRem(48)};
    inline-size: 100%;
    max-inline-size: unset;
    margin-inline: 0;
    align-items: center;
    background-color: ${({ theme }) => theme.nuance.colors.primaryDark};
    ${tablet(`
      min-block-size: ${clampValues(48, 64)};
      padding-block: ${pxToRem(38)};
    `)}
    ${landscape(`
      block-size: 100%;
      display: flex;
      max-inline-size: unset;
    `)}
    ${landscape(
      Device.tablet,
      `
      min-block-size: auto;
    `
    )}
    ${landscape(
      Device.tower,
      `
      justify-content: center;
    `
    )}
    ${altNavigation(`
      max-inline-size: 100%;
      inline-size: 100%;
      padding-block: 0 ;
      padding-inline:  ${pxToRem(108)};
      margin: unset;
      position: fixed;
      z-index: 5;
      inset-inline-start: 0;
    `)}
    ${altNavigationZoom(
      [150, 175],
      `
      position: sticky;
      inset-block-start: 0;
    `
    )}
    ${altNavigationZoom(
      [200],
      `
      position: relative;
    `
    )}
  }

  ${TabPane} {
    min-block-size: ${pxToRem(48)};
    margin: 0;
    flex: 0;

    ::after {
      background: ${({ theme }) => theme.colors.primaryLight};
    }
    ${landscape(
      Device.tablet,
      `
      display: flex;
      flex-direction: column;
      justify-content: center;

      ::after {
        margin-block-start: ${pxToRem(43)};
      }
    `
    )}
  }

  ${TabsContainer} {
    ${landscape(`
      justify-content: center;
      max-inline-size: 100%;
      margin: auto;
    `)}
  }
`

export const TabContentStyled = styled(TabContent)`
  margin-block-start: 0;
  block-size: 100%;
  ${landscape(`
    padding-block: 0 ;
    padding-inline-end: ${pxToRem(54)};
    padding-inline-start: ${pxToRem(24)};
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-inline:  ${pxToRem(16)};
  `
  )}
  ${altNavigation(`
    padding-inline:  ${pxToRem(21)};
    margin-block-start: 0;
    block-size: 100%;
    padding-block-end: ${pxToRem(60)};
  `)}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    padding-block-end: ${pxToRem(80)};
  `
  )}
`

export const TabInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${pxToRem(40)};
  justify-content: space-between;
  block-size: 100%;
  ${landscape(`
    margin-block: auto ;
    margin-inline:  0;
  `)}

  &[aria-hidden="true"] {
    display: none;
  }
`
