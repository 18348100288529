import { ProductHierarchies, ProductSubdivisions, ProductTypeValues } from '@abstractTypes/filter'
import {
  AvailableInStore,
  AvailableInStoreSearchPage,
  AvailableInStoreSearchPageTypes,
  AvailableInStoreTypes,
} from '@actions/filters'
import config from '@config/index'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const getStoreAvailable = (
  productSubdivision: ProductSubdivisions,
  availableInStoreSearchPage: AvailableInStoreSearchPage,
  availableInStore: AvailableInStore
) => {
  if (
    availableInStoreSearchPage[productSubdivision as AvailableInStoreSearchPageTypes] ||
    (config.showStoreAvailableFilter &&
      availableInStore[productSubdivision as AvailableInStoreTypes])
  ) {
    return {
      storeAvailable: '1',
    }
  }

  return undefined
}

const useGetProductTypeQueryParam = () => {
  const { availableInStoreSearchPage, availableInStore } = useSelector(s => s.filters)
  return useCallback(
    (productType: ProductTypeValues) => ({
      productType,
      ...getStoreAvailable(productType, availableInStoreSearchPage, availableInStore),
    }),
    [availableInStoreSearchPage]
  )
}

const useGetHierarchyQueryParam = () => {
  const { availableInStoreSearchPage, availableInStore } = useSelector(s => s.filters)
  return useCallback(
    (productHierarchy: ProductHierarchies) => ({
      product_hierarchy1: productHierarchy,
      ...getStoreAvailable(productHierarchy, availableInStoreSearchPage, availableInStore),
    }),
    [availableInStoreSearchPage]
  )
}

const useGetProductSubdivisionQueryParam = () => {
  const getProductTypeQueryParam = useGetProductTypeQueryParam()
  const getHierarchyQueryParam = useGetHierarchyQueryParam()

  return useCallback(
    (productSubdivision: ProductSubdivisions) => {
      switch (config.default.productSubdivision) {
        case 'productHierarchy':
          return getHierarchyQueryParam(productSubdivision as ProductHierarchies)
        case 'productType':
        default:
          return getProductTypeQueryParam(productSubdivision as ProductTypeValues)
      }
    },
    [getHierarchyQueryParam, getProductTypeQueryParam]
  )
}

export default useGetProductSubdivisionQueryParam
