import { KeyboardedInputInnerProps, KeyboardedInputProps } from '@abstractTypes/keyboard'
import ConditionalRender from '@components/ConditionalRender'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Keyboard } from './Keyboard'

const KeyboardedInputInner = ({
  coords = {
    x: -2.4453125,
    y: -335.53125,
  },
  show,
  ...otherProps
}: KeyboardedInputInnerProps) => {
  const [position, setStorePosition] = useState(coords)
  const setStorePositionCb = (x: number, y: number) => setStorePosition({ x, y })

  useEffect(() => {
    setStorePosition({ x: coords.x, y: coords.y })
  }, [coords.x, coords.y])
  return (
    <ConditionalRender
      condition={show}
      render={() => (
        <Keyboard coords={position} storePosition={setStorePositionCb} {...otherProps} />
      )}
    />
  )
}

export const KeyboardedInput = React.forwardRef<HTMLDivElement, KeyboardedInputProps>(
  (props, ref) => (
    <div className={props.containerClassName} ref={ref}>
      <KeyboardedInputInner {...props} />
    </div>
  )
)

KeyboardedInput.displayName = 'KeyboardedInput'
