import { Device } from '@abstractTypes/utils'
import { Modal } from '@components/core/Modal'
import { ModalBody, ModalFooter, ModalHeader, ModalWrapper } from '@components/core/Modal/styles'
import { Heading3, Heading5 } from '@components/core/Typography'
import { SingleImageContainer } from '@components/pdp/Images/styles'
import config from '@config/index'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const BarCodeScanModalWrapper = styled(Modal)`
  padding: 0;

  ${ModalHeader} {
    padding: 0;
    margin: 0;
    ${landscape(
      Device.tablet,
      `
        padding: 0;
    `
    )}
  }

  ${ModalBody} {
    ${tablet(
      `
      padding-block: ${clampValues(24, 44)}  ${clampValues(40, 60)};
      padding-inline: ${clampValues(52, 72)};
      inline-size: ${clampValues(650, 832)};
    `
    )}
    ${landscape(
      Device.tablet,
      `
      padding-block: ${clampValuesLandscape(14, 24)}  ${clampValuesLandscape(40, 60)};
      padding-inline:${clampValuesLandscape(52, 72)};
      inline-size: ${clampValuesLandscape(650, 832)};
    `
    )}
  }

  ${ModalWrapper} {
    ${tablet(
      `
      padding: 0;
    `
    )}
  }

  ${ModalFooter} {
    ${tablet(
      `
      padding-block: ${clampValues(42, 48)} 0;
      padding-inline: 0;
      > * {
      :last-child {
        margin-inline-end: 0;
      }
    }
    `
    )}
    ${landscape(
      Device.tablet,
      `
      padding-block: ${clampValuesLandscape(42, 48)} 0;
      padding-inline: 0;
    `
    )}

    button {
      inline-size: ${pxToRem(261)};
      block-size: ${pxToRem(56)};
    }
  }

  ${SingleImageContainer} {
    inline-size: 100%;
    block-size: fit-content;
    ${landscape(`
      inline-size: 90%;
      margin: auto;
      block-size: fit-content;
    `)}
  }
`

export const BarCodeDebugMessageContainer = styled.div`
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  background-color: red;
  color: white;
  font-weight: bold;
  padding: ${pxToRem(20)};
`

export const BarCodeScanModalDescription = styled(Heading5)`
  text-align: center;
  ${tablet(
    `
      margin-block: ${clampValues(40, 48)} 0;
      margin-inline: 0;
  `
  )}
  ${landscape(
    Device.tablet,
    `
    margin-block: ${clampValuesLandscape(40, 48)} 0;
    margin-inline: 0;
  `
  )}
`
export const BarCodeScanModalTitle = styled(Heading3)<{
  isCapitalized?: boolean
  isReducedMargin?: boolean
}>`
  text-transform: ${({ isCapitalized }) => (isCapitalized ? 'capitalize' : 'none')};
  text-align: center;
  margin-block: ${({ isReducedMargin }) =>
    isReducedMargin ? `${pxToRem(16)} ${pxToRem(48)}` : `${pxToRem(48)}`};
  margin-inline: 0;
  ${({ isReducedMargin }) =>
    tablet(`
      margin-block: ${
        isReducedMargin ? `${clampValues(10, 16)} ${clampValues(40, 48)}` : `${clampValues(40, 48)}`
      } ;
      margin-inline: 0;
    `)}
  ${({ isReducedMargin }) =>
    landscape(
      Device.tablet,
      `
      margin-block: ${
        isReducedMargin ? `${clampValues(10, 16)} ${clampValues(40, 48)}` : `${clampValues(40, 48)}`
      } ;
      margin-inline: 0;;
    `
    )}
`

export const BarCodeScanModalSubTitle = styled(Heading5)`
  text-align: center;
  margin-block: ${pxToRem(48)} 0;
  margin-inline: 0;
  ${tablet(
    `
    margin-block: ${clampValues(40, 48)} 0 ;
    margin-inline:  0;
  `
  )}
  ${landscape(
    Device.tablet,
    `
    margin-block: ${clampValuesLandscape(40, 48)} 0 ;
    margin-inline: 0;
  `
  )}
`

export const ImageModalProductImageContainer = styled.div`
  inline-size: ${pxToRem(545)};
  block-size: ${pxToRem(301)};
  margin-block: 0;
  margin-inline: auto;
  position: relative;
  ${tablet(`
    block-size: ${clampValues(214, 301)};
    inline-size: ${clampValues(388, 545)};
  `)}
  ${landscape(
    Device.tablet,
    `
    block-size: ${clampValuesLandscape(214, 301)};
    inline-size: ${clampValuesLandscape(388, 545)};
  `
  )}
`

export const LogoContainer = styled.div`
  block-size: ${pxToRem(132)};
  inline-size: ${pxToRem(286)};
  ${tablet(`
    block-size: ${clampValues(84, 112)};
    inline-size: ${clampValues(168, 224)};
  `)}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${clampValuesLandscape(168, 224)};
    block-size: ${clampValuesLandscape(84, 112)};
  `
  )}

  img {
    inline-size: 100%;
    block-size: 100%;
    object-fit: contain;
  }
`

export const RayBanCustomLogo = styled.img`
  content: url('${config.assets.URL}/customizeModalIcon.png');
`
