import React from 'react'
import styled from 'styled-components'
import { ListItemActionProps } from './types'

const ListItemActionContainer = styled.div`
  display: flex;
  margin-inline-start: auto;
  align-items: center;
`

export const ListItemAction: React.FC<ListItemActionProps> = ({ children }) => {
  return <ListItemActionContainer>{children}</ListItemActionContainer>
}
