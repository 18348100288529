import styled from 'styled-components'
import { pxToRem } from '../../libs/styled'

export const OfflinePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  block-size: 100vh;
  background: ${({ theme }) => theme.colors.background};
`
export const MessageBox = styled.div`
  background: white;
  border-radius: ${pxToRem(12)};
  box-shadow: 0 0 10px 1px ${({ theme }) => theme.colors.inactive};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${pxToRem(50)};
`

export const MessageBoxHeader = styled.div`
  text-align: start;
  padding-block: ${pxToRem(30)} 0;
  padding-inline: ${pxToRem(30)};
  h3 {
    color: red;
  }
`
export const MessageBoxErrorDetails = styled.div`
  text-align: start;
  background: #ff000030;
  padding: ${pxToRem(40)};
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: ${pxToRem(12)};
  border-end-start-radius: ${pxToRem(12)};
  h5 {
    color: red;
    margin-block-end: ${pxToRem(15)};
  }
  span {
    display: block;
  }
`
