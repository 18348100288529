import { CartItem } from '@abstractTypes/cart'
import {
  CheckoutData,
  CheckoutDataItem,
  CheckoutEngravingData,
  CheckoutFormData,
  CheckoutFormState,
  FormFieldsValidators,
  FormFieldValidator,
  State,
  StoreCountry,
  StoreData,
} from '@abstractTypes/checkout'
import { EngravingReduxState } from '@abstractTypes/engraving'
import { ShippingMethod, Store } from '@abstractTypes/graphqlTypes'
import { Product } from '@abstractTypes/product'
import { ShippingMethodCode } from '@abstractTypes/shipping'
import { formatOcpPayloadField, getThumbnailUrlForCustom } from '@libs/custom'
import { formatEngravingFontForOrderPlacement } from '@libs/engraving'
import { getProductMainImage } from '@libs/image'
import * as emailValidator from 'email-validator'
import { i18n } from 'i18next'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import * as postcodeValidator from 'postcode-validator'

const convertToCountryCode = (countryId: string): CountryCode => {
  return countryId === 'UK' ? 'GB' : (countryId as CountryCode)
}

const isRequiredValidator: FormFieldValidator = {
  validate: v => !!v,
  message: 'CheckoutForm.requiredError',
}

const requiredIfShipping: FormFieldValidator = {
  validate: (v, data) => {
    if (data.shipping !== 'home') {
      return true
    }
    return !!v
  },
  message: 'CheckoutForm.requiredError',
}

const isValidEmail: FormFieldValidator = {
  validate: v => !v || (v.indexOf('@') !== -1 && emailValidator.validate(v)),
  message: 'CheckoutForm.emailError',
}

const isValidPhone: FormFieldValidator = {
  validate: (v, _data, store) => {
    if (v) {
      if (v.indexOf('+') !== -1) {
        return isValidPhoneNumber(v)
      }
      // use the country of the store

      const countryCode: CountryCode = convertToCountryCode(store.countryId)

      return isValidPhoneNumber(v, countryCode)
    }
    return false
  },
  message: 'CheckoutForm.phoneError',
}

const isMatchingEmail: FormFieldValidator = {
  validate: (v, data) => !v || v.toLowerCase() === data.email.toLowerCase(),
  message: 'CheckoutForm.emailVerificationError',
}

const isValidZipCode: FormFieldValidator = {
  validate: (v, data, store) => {
    if (data.shipping === 'home' && v) {
      // see https://abs-tech.atlassian.net/browse/WALL-462
      // ensure postal code contains a space.
      if (store.countryId === 'CA') {
        // get regexp from https://github.com/melwynfurtado/postcode-validator/blob/develop/src/postcode-regexes.ts
        const postalCodeReg =
          /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i
        return postalCodeReg.test(v)
      }

      // see https://abs-tech.atlassian.net/browse/WALL-539
      // validate also UK or GB postal code prefix only.
      if (store.countryId === 'UK' || store.countryId === 'GB') {
        const postalCodeReg =
          /^([A-Z]){1}([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z]){1}([ ])?([0-9][A-z][A-z]){0,1}$/i
        return postalCodeReg.test(v)
      }

      if (store.countryId === 'US' && store.stateId === 'PR') {
        const postalCodeReg = /^00[679]([0-9]{2})(-[0-9]{4})?$/ // Puerto Rico
        if (!postalCodeReg.test(v)) {
          return false
        }
      }
      if (store.countryId === 'US' && store.stateId === 'VI') {
        const postalCodeReg = /^008([0-9]{2})(-[0-9]{4})?$/ // Virgin Islands
        if (!postalCodeReg.test(v)) {
          return false
        }
      }

      if (store.countryId === 'PT') {
        const postalCodeReg = /[1-9][0-9]{3}-[0-9]{3}/ // Portugal
        if (!postalCodeReg.test(v)) {
          return false
        }
      }

      if (store.countryId === 'NL') {
        const postalCodeReg = /^\d{4}\s[A-Z]{2}$/ // Netherlands
        if (!postalCodeReg.test(v)) {
          return false
        }
      }

      if (store.countryId === 'IL') {
        const postalCodeReg = /^[0-9]{7}$/ //Israel
        if (postalCodeReg.test(v)) {
          return true
        } else {
          return false //TODO: to evaluate if 5 digits zipcodes are accepted or not and to update the postcode-validator lib
        }
      }

      return postcodeValidator.validate(v, store.countryId)
    }
    return true // skip validation
  },
  message: 'CheckoutForm.zipCodeError',
}

const isYesOrNo: FormFieldValidator = {
  validate: (v, _data, store) => {
    if (!store.subscriptionsEnabled) {
      return true
    }
    return v === 'y' || v === 'n'
  },
  message: 'CheckoutForm.requiredError',
}

const isRequiredCheckoutCountryPostcode: FormFieldValidator = {
  validate: (v, _data, store) => {
    if (store.requiredCheckoutPostcode) {
      return requiredIfShipping.validate(v, _data, store)
    } else return true
  },
  message: 'CheckoutForm.requiredError',
}

export const fieldsValidator: FormFieldsValidators = {
  firstName: [isRequiredValidator],
  lastName: [isRequiredValidator],
  email: [isRequiredValidator, isValidEmail],
  emailVerification: [isRequiredValidator, isValidEmail, isMatchingEmail],
  phone: [isRequiredValidator, isValidPhone],
  address: [requiredIfShipping],
  zip: [isRequiredCheckoutCountryPostcode, isValidZipCode],
  city: [requiredIfShipping],
  state: [isRequiredCheckoutCountryPostcode],
  marketing: [isYesOrNo],
  profiling: [isYesOrNo],
  localName: [isRequiredValidator],
  localName2: [isRequiredValidator],
}

const isCalifornia = (countryId: string, stateCode: string): boolean =>
  countryId === 'US' && stateCode === 'CA'

export const checkIfCalifornia = (
  val: string | undefined,
  storeCountry: StoreCountry,
  states: State[]
) => {
  const { countryId = '', code = '' } = storeCountry || {}

  if (countryId && code && isCalifornia(countryId, code)) {
    return true
  }

  if (val) {
    const selectedState = states.find(state => state.id === val)

    if (selectedState) {
      const { countryId, code } = selectedState
      return isCalifornia(countryId, code)
    }
  }

  return false
}

export const findPanelsWithErrors = (
  panelsAndFields: Record<string, string[]>,
  errors: string[]
): string[] => {
  if (errors.length > 0) {
    const panels: string[] = []
    Object.keys(panelsAndFields).forEach((panel: string) => {
      if (panelsAndFields[panel].find((field: string) => errors.includes(field))) {
        panels.push(panel)
      }
    })
    return panels
  }
  return []
}

const productImgSrc = (product: Product, showShadImg = false) => {
  const { customItem, recipeId, originalBrandCode, thumbnails } = product

  return customItem && recipeId && getThumbnailUrlForCustom
    ? getThumbnailUrlForCustom(recipeId, originalBrandCode)
    : getProductMainImage(thumbnails, 300, showShadImg)
}

const getEngraving = (
  id: string,
  engravingDetails: EngravingReduxState,
  price: number
): CheckoutEngravingData | null => {
  const engravingItem = engravingDetails.items[id]
  if (engravingItem) {
    const data = {
      price,
      items: engravingItem.sides.map(side => ({
        color: engravingItem.color,
        font: formatEngravingFontForOrderPlacement(engravingItem.font),
        text: side.text,
        side: side.id,
      })),
    }
    return data
  }
  return null
}

export const createShippingCostForCartPayload = (
  shippingMethod: ShippingMethod
): CheckoutDataItem => {
  return {
    UPC: shippingMethod.upc,
    quantity: 1,
    price: shippingMethod.fee,
    imageURL: 'https://fakeURL.com',
    brandCode: '',
    brandName: '',
    shippingType: '',
    productName: 'SHIPPING',
    isSupernova: false,
    isNuanceAccessory: false,
    isNuanceFrame: false,
  }
}

export const formatCartDataForPayload = (
  items: CartItem[],
  engravingDetails: EngravingReduxState,
  isCompletePair = false,
  shippingType = 'DEFAULT'
) =>
  items.map(el => {
    const { product, id, quantity } = el
    const engraving =
      product.engravingConstraints && engravingDetails
        ? getEngraving(id, engravingDetails, product.engravingConstraints.price)
        : null
    const item: CheckoutDataItem = {
      UPC: product.UPC,
      quantity: quantity,
      price: product.price.current,
      imageURL: productImgSrc(product),
      brandCode: product.originalBrandCode,
      brandName: product.brand.name,
      shippingType,
      productName: product.name,
      isSupernova: product.isSupernova,
      isNuanceAccessory: product.isNuanceAccessories,
      isNuanceFrame: product.isNuanceFrame,
    }
    if (engraving) {
      item.engraving = engraving
    }

    if (product.customItem) {
      item.customItem = formatOcpPayloadField(product)
    }

    if (product.completePair) {
      item.complete_pair = product.completePair
    }

    if (product.roxable && isCompletePair) {
      item.complete_pair = true
    }

    if (shippingType === ShippingMethodCode.GREEN && product.greenShippingAvailable) {
      item.shippingType = ShippingMethodCode.GREEN
    }
    return item
  })

export const getCheckoutData = (
  store: Store,
  checkout: CheckoutFormState,
  i18n: i18n,
  items: CartItem[],
  engravingDetails: EngravingReduxState
) => {
  const {
    shipping,
    shippingType,
    firstName,
    lastName,
    email,
    phone,
    address,
    address1,
    zip,
    city,
    state,
    marketing,
    profiling,
  } = checkout.data

  let addressObj = undefined
  if (shipping !== 'store') {
    addressObj = {
      address,
      address1,
      zip,
      city,
      state: state || '',
    }
  }

  const cartDataPayload = formatCartDataForPayload(items, engravingDetails, false, shippingType)
  const selectedShipmentMethod = store.shippingMethods.find(method => method.code === shippingType)

  if (selectedShipmentMethod && selectedShipmentMethod.fee) {
    // this is creating a fake entry in the cart when magento have to consider the cost of the shipment
    const shippingCostPayload = createShippingCostForCartPayload(selectedShipmentMethod)
    cartDataPayload.push(shippingCostPayload)
  }

  const checkoutData: CheckoutData = {
    storeId: store.storeId,
    langCode: i18n.language,
    storeType: store.storeType,
    delivery: shipping,
    items: cartDataPayload,
    customer: {
      firstName,
      lastName,
      email,
      phone,
      marketing: undefined,
      profiling: undefined,
    },
    address: addressObj,
  }

  if (store.subscriptionsEnabled) {
    checkoutData.customer.marketing = marketing
    checkoutData.customer.profiling = profiling
  }

  return { checkoutData }
}

export const validateField = (
  validators: FormFieldValidator[],
  value: string | undefined,
  formData: CheckoutFormData,
  storeData: StoreData
) => {
  let error = ''
  validators.forEach(check => {
    const isValid = check.validate(value, formData, storeData)
    if (!isValid) {
      error = check.message
    }
  })
  return error
}

export const checkIsShippingMethod = (shippingMethods: ShippingMethod[], code: string) =>
  shippingMethods.find(method => method.code === code)

export const filterShippingMethods = (shippingMethods: ShippingMethod[], shipping: string) =>
  shippingMethods.filter(method => {
    if (shipping === 'home') {
      return method.destinationAvailability.home
    }
    return method.destinationAvailability.store
  })
