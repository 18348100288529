import { ERROR_CODES } from '@abstractTypes/errors'
import { ApolloError } from '@apollo/client'

export const parseGrapqhQLError = (error: ApolloError) => {
  const { networkError, graphQLErrors } = error
  if (networkError) {
    return {
      type: 'Network error',
      errors: [networkError],
    }
  }

  const type = graphQLErrors[0].extensions?.type || 'Generic Error'

  return {
    type,
    errors: [...graphQLErrors],
  }
}

export const findPanelsWithErrors = (
  panelsAndFields: { [key: string]: string[] },
  errors: string[]
): string[] => {
  if (errors.length > 0) {
    const panels: string[] = []
    Object.keys(panelsAndFields).forEach(panel => {
      if (panelsAndFields[panel].find(field => errors.includes(field))) {
        panels.push(panel)
      }
    })
    return panels
  }
  return []
}

/**
 * Check if GraphQL error is not a field resolver error to
 * be able to display partial data.
 *
 * @param field The field within the error extensions thrown from GraphQL
 * @param error The error thrown from the `useQuery` operations
 * @param fieldParent The parent type to check the field for. Fields can have the same name on different types
 * @returns If the error is not a field error
 */
export const isNotFieldError = (
  field: string | string[],
  error: ApolloError | undefined,
  fieldParent?: string
): error is ApolloError => {
  if (!error) {
    return false
  }

  const { graphQLErrors } = error
  const fieldArray = Array.isArray(field) ? field : [field]

  return !graphQLErrors.every(graphQLError => {
    const { code, field: fieldError, parent } = graphQLError.extensions
    const isFieldResolverError = code === ERROR_CODES.FIELD_RESOLVER_ERROR
    const isFieldError = fieldArray.includes(fieldError)
    const isParent = fieldParent ? fieldParent === parent : true
    return isFieldResolverError && isFieldError && isParent
  })
}
