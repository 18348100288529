import {
  AccessibilityActionTypes,
  DECREASE_ZOOM_LEVEL,
  INCREASE_ZOOM_LEVEL,
  TOGGLE_ALTERNATIVE_NAVIGATION,
  TOGGLE_CAROUSELS_AUTOPLAY,
  TOGGLE_SCREEN_READER_STATE,
  TOGGLE_VIRTUAL_KEYBOARD,
} from '@actions/accessibility'
import { zoomLevels } from '@constants/accessibility'

export interface AccessibilityState {
  alternativeNavigation: boolean
  preventCarouselAutoplay: boolean
  screenReaderState: boolean
  vkEnabled: boolean
  zoomLevelIndex: number
}

const initialState: AccessibilityState = {
  alternativeNavigation: false,
  preventCarouselAutoplay: false,
  screenReaderState: false,
  vkEnabled: true,
  zoomLevelIndex: 0,
}

export default (state = initialState, action: AccessibilityActionTypes) => {
  switch (action.type) {
    case TOGGLE_VIRTUAL_KEYBOARD:
      return { ...state, vkEnabled: !state.vkEnabled }
    case TOGGLE_ALTERNATIVE_NAVIGATION:
      return { ...state, alternativeNavigation: !state.alternativeNavigation }
    case TOGGLE_SCREEN_READER_STATE:
      return { ...state, screenReaderState: action.payload.isActive }
    case TOGGLE_CAROUSELS_AUTOPLAY:
      return { ...state, preventCarouselAutoplay: !state.preventCarouselAutoplay }
    case INCREASE_ZOOM_LEVEL: {
      return { ...state, zoomLevelIndex: Math.min(state.zoomLevelIndex + 1, zoomLevels.length - 1) }
    }
    case DECREASE_ZOOM_LEVEL: {
      return { ...state, zoomLevelIndex: Math.max(state.zoomLevelIndex - 1, 0) }
    }
    default:
      return state
  }
}
