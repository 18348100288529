import { FrameAdvisorSurveyContainer } from '@components/Contents/FrameAdvisorSurveyContent/styles'
import { PrimaryButton } from '@components/core/Button/index'
import { Heading1, Text } from '@components/core/Typography'
import { landscape, tablet } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

export const Container = styled(FrameAdvisorSurveyContainer)`
  margin-block: auto;
  margin-inline: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  block-size: 100%;
  ${landscape(`
    display: block;
    text-align: center;
  `)}
`

export const InstructionsContainer = styled.div`
  margin-block-start: ${pxToRem(50)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-block-size: 3.909rem;

  svg {
    margin-block-end: ${pxToRem(60)};
    ${landscape(`
      margin-block-end: ${pxToRem(30)};
    `)}
  }

  ${tablet(`
    margin-block-start: 3.905vh;
    margin-block-end: 1.955vh;
    font-size: 2.341vh;

    svg {
      block-size: 7.518vh;
    }
  `)}
`

export const ConnectToTheAppCta = styled(PrimaryButton)`
  margin-block: ${pxToRem(48)} ${pxToRem(40)};
  margin-inline: auto;
  padding-block: ${pxToRem(8)};
  padding-inline: ${pxToRem(32)};

  ${tablet(`
    text-transform: uppercase;
  `)}
`

const commonBorderStyle = css`
  content: '';
  position: absolute;
  inline-size: ${pxToRem(58)};
  block-size: ${pxToRem(58)};
`
const borderLineStyle = '4px solid #fff'
const borderRadius = '45px'

export const BorderContainer = styled.div`
  position: relative;
  margin-block: ${pxToRem(60)} 0;
  margin-inline: auto;
  inline-size: fit-content;

  ${tablet(`
    ::after {
      inline-size: 1.455rem;
      block-size: 1.455rem;
      border-top-right-radius: 0.909rem;
      border-inline-size: 2px;
    }

    ::before {
      inline-size: 1.455rem;
      block-size: 1.455rem;
      border-top-left-radius: 0.909rem;
      border-inline-size: 2px;
    }
  `)}

  ${landscape(`
    margin-block-start: ${pxToRem(30)};
  `)}
  &::before,
  &::after {
    ${commonBorderStyle}
  }

  &::after {
    inset-block-start: 0;
    inset-inline-end: 0;
    border-inline-end: ${borderLineStyle};
    border-block-start: ${borderLineStyle};
    border-top-right-radius: ${borderRadius};
  }

  &::before {
    inset-block-start: 0;
    inset-inline-start: 0;
    border-inline-start: ${borderLineStyle};
    border-block-start: ${borderLineStyle};
    border-top-left-radius: ${borderRadius};
  }
`

export const Border = styled.div`
  position: relative;
  padding: ${pxToRem(26)};

  ${tablet(`
    padding: 0.814rem;

    ::after {
      inline-size: 1.455rem;
      block-size: 1.455rem;
      border-bottom-right-radius: 0.909rem;
      border-inline-size: 2px;
    }

    ::before {
      inline-size: 1.455rem;
      block-size: 1.455rem;
      border-bottom-left-radius: 0.909rem;
      border-inline-size: 2px;
    }
  `)}

  &::before,
  &::after {
    ${commonBorderStyle}
  }

  &::after {
    inset-inline-end: 0;
    inset-block-end: 0;
    border-inline-end: ${borderLineStyle};
    border-block-end: ${borderLineStyle};
    border-bottom-right-radius: ${borderRadius};
  }

  &::before {
    inset-block-end: 0;
    inset-inline-start: 0;
    border-inline-start: ${borderLineStyle};
    border-block-end: ${borderLineStyle};
    border-bottom-left-radius: ${borderRadius};
  }
`

export const FaceAppConnectorTitle = styled(Heading1)`
  margin-block-end: 1.5rem;
  text-align: center;
  text-transform: uppercase;

  ${tablet(`
    margin-block-start: 0;
    text-align: center;
    margin-block-end: 2.44vh;
  `)}
`
export const FaceAppConnectorDescription = styled(Text)`
  margin-block-end: 0;
  max-inline-size: 30rem;
  text-align: center;
  object-fit: contain;
  margin-block: 0;
  margin-inline: auto;

  ${tablet(`
    max-inline-size: 42vw;
  `)}
`

export const BarcodeWrapper = styled.div`
  margin-block: ${pxToRem(80)};
  margin-inline: 0;
  ${landscape(`
    margin-block: ${pxToRem(40)};
  `)}
`

export const TakeQuizButton = styled(PrimaryButton)`
  margin-block-start: ${pxToRem(20)};
  ${landscape(`
      margin-block-start: ${pxToRem(10)};
  `)}
`

export const AssociateText = styled(Text)`
  text-align: center;
  opacity: 0.6;
  margin-block-end: ${pxToRem(100)};
`
