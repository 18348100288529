import { Comparator } from '@abstractTypes/utils'
import { FilterSpacing } from '@components/FiltersDrawer/FilterContainer'
import { ActiveFilters } from './analytics'

export interface FilterInput {
  kind: FilterKind
  value: string
}

export interface FilterFacet extends FilterInput {
  count: number
}

export type FilterOption = FilterFacet & {
  selected: boolean
  translatedLabel?: string
  description?: string
}

export type FilterState = DefaultFilter & {
  options: FilterOption[]
  kind: FilterKind
  translatedLabel?: string
  disabled?: boolean
}

export type FiltersState = {
  [kind in FilterKind]: FilterState
}

export type AppFiltersKind = 'q' | 'sort'

export type RangeFiltersKind = 'minLensHeight' | 'maxLensHeight'

export type MultiRangeFiltersKind = 'minPrice' | 'maxPrice'

export type FiltersFromUrlType = string | string[]

export type FiltersFromUrlKind =
  | FilterKind
  | AppFiltersKind
  | RangeFiltersKind
  | MultiRangeFiltersKind

export type FiltersFromUrl = {
  [kind in FiltersFromUrlKind]?: FiltersFromUrlType
}

// Formatted filters that are applied on url parameters
export type FormattedFilterMultiRangeType = Record<MultiRangeFiltersKind, number>

export type FormattedFiltersType =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | FormattedFilterMultiRangeType[]
  | undefined

export type FormattedFilters = Record<string, FormattedFiltersType>

export const PRODUCT_HIERARCHY_1 = 'product_hierarchy1'
export const PRODUCT_HIERARCHY_2 = 'product_hierarchy2'
export const PRODUCT_HIERARCHY_3 = 'product_hierarchy3'

export enum ProductHierarchies {
  SUNGLASSES = 'sunglasses',
  EYEGLASSES = 'eyeglasses',
  GOGGLES = 'goggles',
  APPAREL = 'apparel',
  ACCESSORIES = 'accessories',
  FOOTWEAR = 'footwear',
  HELMETS = 'helmets',
  NFL = 'nfl',
  REPLACEMENT_LENSES = 'replacement lenses',
  FACE_MASK = 'face mask',
  EYEWEAR_ACCESSORIES = 'eyewear accessories',
  GOGGLES_ACCESSORIES = 'goggles accessories',
}

export enum ProductTypeValues {
  OPTICAL = 'OPTICAL',
  SUN = 'SUN',
  SNOWGOGGLES = 'SNOW GOGGLES',
  ACCESSORIES = 'ACCESSORIES',
  NUANCE = 'ELECTRONICS AUDIO OPTICAL',
}

export type ProductSubdivisions = ProductTypeValues | ProductHierarchies
export type ProductSubdivisionsList = ProductTypeValues[] | ProductHierarchies[]

export const DEFAULT_HIERARCHY = ProductHierarchies.SUNGLASSES

export type TogglableProductTypeValues = ProductTypeValues.OPTICAL | ProductTypeValues.SUN
export const togglableProductTypes: TogglableProductTypeValues[] = [
  ProductTypeValues.SUN,
  ProductTypeValues.OPTICAL,
]

export type FiltersReqKeys = string
export type FiltersReqValues = string[] | boolean | number
export type FiltersReq = Record<FiltersReqKeys, FiltersReqValues>

export enum FilterType {
  Radio = 'radio',
  Checkbox = 'checkbox',
  Color = 'color',
  Range = 'range',
  Brand = 'brand',
  Flag = 'flag',
  Price = 'price',
  ChildAgeRange = 'childAgeRange',
}

export const FILTER_TYPE_CHECKBOX = FilterType.Checkbox

export const FILTER_TYPE_RANGE = FilterType.Range
export const FILTER_TYPE_BRAND = FilterType.Brand
export const FILTER_TYPE_FLAG = FilterType.Flag
export const FILTER_TYPE_PRICE = FilterType.Price
export const FILTER_TYPE_CHILD_AGE_RANGE = FilterType.ChildAgeRange

/**
 * Default filters state. Every filter has following fields:
 * 1) key - key of corresponding category received from API ('kind' field)
 * 2) label - translation key to display in title
 * 3) type - type of filter (checkbox, color, range)
 * 4) options - array of available options.
 *
 * Each filter option has following fields:
 * 1) active - if option is selected
 * 2) count - number of products corresponding the option (received from API)
 * 3) label - translation label
 * 4) value - value of the option received from API ('value' field)
 */
export const DEFAULT_FILTERS = {
  brand: {
    key: 'brand',
    label: 'brand',
    type: FILTER_TYPE_BRAND,
  },
  category: {
    key: 'category',
    label: 'category',
    type: FILTER_TYPE_CHECKBOX,
  },
  childAgeRange: {
    key: 'childAgeRange',
    label: 'childAgeRange',
    type: FILTER_TYPE_CHILD_AGE_RANGE,
  },
  storeAvailable: {
    key: 'storeAvailable',
    label: 'storeAvailable',
    type: FILTER_TYPE_FLAG,
  },
  engravable: {
    key: 'engravable',
    label: 'engravable',
    type: FILTER_TYPE_FLAG,
  },
  certifications: {
    key: 'certifications',
    label: 'certifications',
    type: FILTER_TYPE_CHECKBOX,
  },
  color: {
    key: 'color',
    label: 'color',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameColor: {
    key: 'frameColor',
    label: 'frameColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameMaterial: {
    key: 'frameMaterial',
    label: 'frameMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameShape: {
    key: 'frameShape',
    label: 'frameShape',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameType: {
    key: 'frameType',
    label: 'frameType',
    type: FILTER_TYPE_CHECKBOX,
  },
  gender: {
    key: 'gender',
    label: 'gender',
    type: FILTER_TYPE_CHECKBOX,
  },
  faceShape: {
    key: 'faceShape',
    label: 'faceShape',
    type: FILTER_TYPE_CHECKBOX,
  },
  fit: {
    key: 'fit',
    label: 'fit',
    type: FILTER_TYPE_CHECKBOX,
  },
  isPrizm: {
    key: 'isPrizm',
    label: 'isPrizm',
    type: FILTER_TYPE_FLAG,
  },
  lensColor: {
    key: 'lensColor',
    label: 'lensColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensMaterial: {
    key: 'lensMaterial',
    label: 'lensMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensTreatment: {
    key: 'lensTreatment',
    label: 'lensTreatment',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensTreatmentLabel: {
    key: 'lensTreatmentLabel',
    label: 'lensTechnology',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensTechnology: {
    key: 'lensTechnology',
    label: 'lensTechnology',
    type: FILTER_TYPE_CHECKBOX,
  },
  polarizedLens: {
    key: 'polarizedLens',
    label: 'polarizedLens',
    type: FILTER_TYPE_FLAG,
  },
  isPhotochromic: {
    key: 'isPhotochromic',
    label: 'isPhotochromic',
    type: FILTER_TYPE_FLAG,
  },
  vto: {
    key: 'vto',
    label: 'vto',
    type: FILTER_TYPE_FLAG,
  },
  rtr: {
    key: 'rtr',
    label: 'rtr',
    type: FILTER_TYPE_FLAG,
  },
  roxable: {
    key: 'roxable',
    label: 'roxable',
    type: FILTER_TYPE_FLAG,
  },
  progressiveFriendly: {
    key: 'progressiveFriendly',
    label: 'progressiveFriendly',
    type: FILTER_TYPE_FLAG,
  },
  lensHeight: {
    key: 'lensHeight',
    label: 'lensHeight',
    type: FILTER_TYPE_RANGE,
    minKey: 'minLensHeight',
    maxKey: 'maxLensHeight',
    range: [
      {
        min: 22,
        max: 57,
        optionValues: ['22_57'],
      },
    ],
  },
  modelFamily: {
    key: 'modelFamily',
    label: 'modelFamily',
    type: FILTER_TYPE_CHECKBOX,
  },
  price: {
    key: 'price',
    label: 'price',
    type: FILTER_TYPE_PRICE,
    minKey: 'minPrice',
    maxKey: 'maxPrice',
    range: [],
  },
  productType: {
    key: 'productType',
    label: 'productType',
    type: FILTER_TYPE_CHECKBOX,
  },
  playlistType: {
    key: 'playlistType',
    label: 'playlistType',
    type: FILTER_TYPE_CHECKBOX,
  },
  customizable: {
    key: 'customizable',
    label: 'customizable',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameFitting: {
    key: 'frameFitting',
    label: 'frameFitting',
    type: FILTER_TYPE_CHECKBOX,
  },
  geoFit: {
    key: 'geoFit',
    label: 'geoFit',
    type: FILTER_TYPE_CHECKBOX,
  },
  prizmCategory: {
    key: 'prizmCategory',
    label: 'prizmCategory',
    type: FILTER_TYPE_CHECKBOX,
  },
  salesCategory: {
    key: 'salesCategory',
    label: 'salesCategory',
    type: FILTER_TYPE_CHECKBOX,
  },
  release: {
    key: 'release',
    label: 'release',
    type: FILTER_TYPE_CHECKBOX,
  },
  sports: {
    key: 'sports',
    label: 'sports',
    type: FILTER_TYPE_CHECKBOX,
  },
  technologies: {
    key: 'technologies',
    label: 'technologies',
    type: FILTER_TYPE_CHECKBOX,
  },
  weatherConditions: {
    key: 'weatherConditions',
    label: 'weatherConditions',
    type: FILTER_TYPE_CHECKBOX,
  },
  sizeOrdinal: {
    key: 'sizeOrdinal',
    label: 'size',
    type: FILTER_TYPE_CHECKBOX,
  },
  product_hierarchy1: {
    key: PRODUCT_HIERARCHY_1,
    label: 'product_hierarchy1',
    type: FILTER_TYPE_CHECKBOX,
  },
  product_hierarchy2: {
    key: PRODUCT_HIERARCHY_2,
    label: 'product_hierarchy2',
    type: FILTER_TYPE_CHECKBOX,
  },
  product_hierarchy3: {
    key: PRODUCT_HIERARCHY_3,
    label: 'product_hierarchy3',
    type: FILTER_TYPE_CHECKBOX,
  },
}

export type FilterKind = keyof typeof DEFAULT_FILTERS

export type FilterRange = {
  min: number | string
  max: number | string
  optionValues: readonly string[]
  selected?: boolean
  count?: number
}

export type FilterRangePrice = {
  min: number
  max: number | 'max'
  optionValues: readonly string[]
  selected?: boolean
  count: number
}

export type DefaultFilter = {
  key: string
  label: string
  type: FilterType
  minKey?: RangeFiltersKind
  maxKey?: RangeFiltersKind
  range?: FilterRange[]
}

export type DefaultFilters = {
  [kind in FilterKind]: DefaultFilter
}

/**
 * Default filters state. Every filter has following fields:
 * 1) key - key of corresponding category received from API ('kind' field)
 * 2) label - translation key to display in title
 * 3) type - type of filter (checkbox, color, range)
 * 4) options - array of available options.
 *
 * Each filter option has following fields:
 * 1) active - if option is selected
 * 2) count - number of products corresponding the option (received from API)
 * 3) label - translation label
 * 4) value - value of the option received from API ('value' field)
 */

export const HIERARCHY_GOGGLES_FILTERS: FilterGroup[] = [
  'gender',
  'lensColor',
  'frameColor',
  'lensTreatmentLabel',
  'modelFamily',
  'price',
  'weatherConditions',
  'sizeOrdinal',
]
export const HIERARCHY_APPAREL_FILTERS: FilterGroup[] = [
  'color',
  'gender',
  'price',
  'sports',
  'technologies',
  'sizeOrdinal',
]
export const HIERARCHY_FOOTWEAR_FILTERS: FilterGroup[] = ['color', 'price', 'sizeOrdinal']
export const HIERARCHY_ACCESSORIES_FILTERS: FilterGroup[] = [
  'color',
  'price',
  'sports',
  'sizeOrdinal',
]
export const HIERARCHY_LENSES_FILTERS: FilterGroup[] = [
  'modelFamily',
  'weatherConditions',
  'sizeOrdinal',
]
export const HIERARCHY_HELMETS_FILTERS: FilterGroup[] = ['color', 'price', 'sports', 'sizeOrdinal']
export const HIERARCHY_EYEWEAR_ACCESSORIES_FILTERS: FilterGroup[] = [
  'modelFamily',
  'weatherConditions',
  'color',
  'price',
  'sizeOrdinal',
]

export const HIERARCHY_GOGGLES_ACCESSORIES_FILTERS: FilterGroup[] = [
  'modelFamily',
  'weatherConditions',
  'color',
  'price',
  'sizeOrdinal',
]

export const HIERARCHY_FACE_MASK_FILTERS: FilterGroup[] = [
  'color',
  'price',
  'sports',
  'sizeOrdinal',
]
export interface FilterConfig {
  showIcon?: boolean
  spacing?: FilterSpacing
  withDescription?: boolean
}

export type DrawerFilterGroup = {
  label: string
  filters: FilterKind[]
  filterConfig?: FilterConfig
}

export type FilterGroup = DrawerFilterGroup | FilterKind

export type FilterOptionsComparator = Comparator<FilterOption>

export enum SizeTypeValues {
  XXS = 'XXS',
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

export interface PreselectedFilters {
  filters: ActiveFilters[]
  productTypeCounts: Record<TogglableProductTypeValues, number>
}
