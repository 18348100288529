import { DisclaimerProps } from '@abstractTypes/components'
import SSTrans from '@components/core/Trans'
import { ButtonTypography, Label } from '@components/core/Typography'
import { PrivacyPolicyModal } from '@components/PrivacyPolicyModal'
import React, { useState } from 'react'
import { DisclaimerWrapper } from './DisclaimerWrapper'

export const Disclaimer: React.FC<DisclaimerProps> = ({
  className,
  closeTooltip,
  withShadow = true,
  floatingDisclaimer = true,
  disclaimerColor,
}) => {
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false)

  const openPrivacyModal = () => {
    setPrivacyModalVisible(true)
    if (closeTooltip) {
      closeTooltip()
    }
  }

  const defaultColor = floatingDisclaimer ? 'primaryLight' : 'inactive'
  const textColor = disclaimerColor || defaultColor

  return (
    <DisclaimerWrapper
      className={className}
      id="privacy-policy"
      withShadow={withShadow}
      floatingDisclaimer={floatingDisclaimer}
      disclaimerColor={textColor}
    >
      <Label color="inherit">
        <SSTrans
          i18nKey="Core.disclaimer"
          components={{
            button: (
              <ButtonTypography
                color="inherit"
                textSize="label"
                textSizeTablet="labelTablet"
                textSizeTabletMini="labelTabletMini"
                data-element-id="Footer_Privacy"
                data-test="Footer_Privacy"
                onClick={() => openPrivacyModal()}
              />
            ),
          }}
        />
      </Label>

      <PrivacyPolicyModal
        visible={privacyModalVisible}
        onCancel={() => setPrivacyModalVisible(false)}
      />
    </DisclaimerWrapper>
  )
}
