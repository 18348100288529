import { Device } from '@abstractTypes/utils'
import { CloseIcon } from '@components/core/Icons'
import { Label } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useThemeContext } from '@hooks/useThemeContext'
import { landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ContainerPopUp = styled.div`
  display: flex;
  inline-size: ${pxToRem(248)};
  min-block-size: ${pxToRem(80)};
  padding: ${pxToRem(20)};
  align-items: center;
  justify-content: space-between;
  gap: ${pxToRem(8)};
  border-radius: ${pxToRem(12)};
  opacity: 0.9;
  background: ${({ theme }) => theme.colors.primaryDarkPressed};
  position: absolute;
  inset-inline-end: ${pxToRem(112)};
  inset-block-start: 0;
  ${tablet(`        
        inset-inline-end: ${clampValues(78, 92)};
        inline-size: ${clampValues(186, 248)};
        min-block-size: ${clampValues(66, 80)};
        gap: ${clampValues(6, 8)};
        padding: ${clampValues(15, 20)}};
    `)}
  ${landscape(
    Device.tablet,
    `
        inset-inline-end: ${clampValuesLandscape(74, 92)};
        min-block-size: ${clampValuesLandscape(60, 80)};
        inline-size: ${clampValuesLandscape(200, 248)};
        gap: ${clampValuesLandscape(6, 8)};
        padding: ${clampValuesLandscape(15, 20)}};
    `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(372)};
    min-block-size: ${pxToRem(120)};
    padding: ${pxToRem(30)};
    gap: ${pxToRem(12)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(496)};
    min-block-size: ${pxToRem(160)};
    padding: ${pxToRem(40)};
    gap: ${pxToRem(16)};
  `
  )}
`

const ClosePopUp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProfilePopUp = () => {
  const { t } = useTranslation()
  const theme = useThemeContext()
  const { landscapeNavigation, zoomLevel } = useNavigationContext()
  const isIPad = isIPadView()

  const [showPopUp, setShowPopUp] = useState(false)

  const iconSize = useMemo(() => {
    if (isIPad && landscapeNavigation) {
      return clampValuesLandscape(18, 26)
    }
    if (isIPad) {
      return clampValues(18, 26)
    }
    return pxToRem(26, zoomLevel / 100)
  }, [isIPad, landscapeNavigation, zoomLevel])

  useEffect(() => {
    setTimeout(() => setShowPopUp(true), 1000)
  }, [])

  useEffect(() => {
    if (showPopUp) {
      setTimeout(() => setShowPopUp(false), 6000)
    }
  }, [showPopUp])

  return (
    <>
      {showPopUp && (
        <ContainerPopUp data-analytics_available_call="0">
          <Label color={theme.colors.primaryLight} weight="bold">
            {t('FrameAdvisor.popUpProfile')}
          </Label>

          <ClosePopUp onClick={() => setShowPopUp(false)} data-analytics_available_call="0">
            <CloseIcon
              stroke={theme.colors.primaryLight}
              fill={theme.colors.primaryLight}
              height={iconSize}
              width={iconSize}
            />
          </ClosePopUp>
        </ContainerPopUp>
      )}
    </>
  )
}

export default ProfilePopUp
