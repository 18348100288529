import { Device } from '@abstractTypes/utils'
import { SwitcherWithLableStyled } from '@components/core/Switcher'
import { landscape } from '@libs/media'
import { clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const ViewAllWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-block-size: ${pxToRem(58)};
  margin-block-start: ${pxToRem(40)};
`

export const SettingsWrapper = styled('div')<{ noData?: boolean }>`
  display: flex;
  justify-content: ${props => (props.noData ? 'flex-end' : 'space-between')};
  padding-block-end: ${pxToRem(20)};

  ${SwitcherWithLableStyled} {
    margin: 0;
    inline-size: auto;
  }
  ${landscape(Device.tablet, `margin-block-end: ${clampValuesLandscape(18, 24)};`)}
`
