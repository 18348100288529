import { IconProps } from '@abstractTypes/icons'

export const FaceShapeRectangleIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61796)">
        <path
          d="M37 48.25H11C9.35 48.25 8 46.9 8 45.25V3.25C8 1.6 9.35 0.25 11 0.25H37C38.65 0.25 40 1.6 40 3.25V45.25C40 46.9 38.65 48.25 37 48.25ZM11 2.25C10.45 2.25 10 2.7 10 3.25V45.25C10 45.8 10.45 46.25 11 46.25H37C37.55 46.25 38 45.8 38 45.25V3.25C38 2.7 37.55 2.25 37 2.25H11Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61796">
          <rect width={48} height={48} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
