import { Device } from '@abstractTypes/utils'
import { BaseButton } from '@components/CardProductBase/ProductCard/ProductCard.style'
import { landscape, tablet, zoomPortrait } from '@libs/media'
import { checkColor, clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

const standardToggleButtonSize = css`
  margin-inline-start: 0;
  ${tablet(`
    inline-size: ${clampValues(35, 46)};
    block-size: ${clampValues(35, 46)};
    margin-inline-start:0;
  `)}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${clampValues(35, 46)};
    block-size: ${clampValues(35, 46)};
    margin-inline-start:0;
  `
  )}
`

const largeToggleButtonSize = css`
  inline-size: ${pxToRem(56)};
  block-size: ${pxToRem(56)};
  margin-inline-start: 0;
  ${tablet(
    `
    inline-size: ${clampValues(42, 56)};
    block-size: ${clampValues(42, 56)};
    margin-inline-start:0;
    `
  )}
  ${landscape(
    Device.tablet,
    `
    inline-size: ${clampValuesLandscape(42, 56)};
    block-size: ${clampValuesLandscape(42, 56)};
    margin-inline-start:0;
  `
  )}
`

export const ThumbBtn = styled(BaseButton)<{
  wishlisted?: boolean
  wishlistColor?: string
  size?: string
  isToggleButton?: boolean
}>`
  position: relative;
  padding: 0;
  inline-size: ${pxToRem(48)};
  ${zoomPortrait(
    [150, 175],
    `
  inline-size: ${pxToRem(72)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
  inline-size: ${pxToRem(96)};
  `
  )}
  block-size: ${pxToRem(48)};
  ${zoomPortrait(
    [150, 175],
    `
    block-size: ${pxToRem(72)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    block-size: ${pxToRem(96)};
  `
  )}
  margin-inline-start: ${pxToRem(16)};
  flex: 0 0 auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: ${({ theme, wishlisted, wishlistColor }) =>
    wishlisted
      ? wishlistColor
        ? checkColor(wishlistColor, theme)
        : theme.colors.primaryDark
      : theme.colors.primaryLight};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;

  ${tablet(
    `
    margin-inline-start: ${clampValues(12, 16)};
    inline-size: ${clampValues(42, 48)};
    block-size: ${clampValues(42, 48)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    margin-inline-start: ${clampValuesLandscape(12, 16)};
    inline-size: ${clampValuesLandscape(42, 48)};
    block-size: ${clampValuesLandscape(42, 48)};

  `
  )}

  ${({ isToggleButton, size }) => {
    return isToggleButton
      ? css`
          z-index: 2;
          position: absolute;
          inset-inline-end: ${pxToRem(16)};
          inset-block-end: ${pxToRem(16)};
          box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

          ${size === 'large' ? largeToggleButtonSize : standardToggleButtonSize};
        `
      : ''
  }}
`
