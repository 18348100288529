import { IconProps } from '@abstractTypes/icons'


export const SnowIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path
        d="M38.144 1a8.852 8.852 0 0 0-7.309 3.855 99.285 99.285 0 0 1-25.98 25.979A8.855 8.855 0 0 0 1 38.144C1 43.027 4.973 47 9.856 47a8.852 8.852 0 0 0 7.309-3.855 99.285 99.285 0 0 1 25.98-25.979A8.855 8.855 0 0 0 47 9.856C47 4.973 43.027 1 38.144 1zm-4.952 20.879a2.004 2.004 0 0 1-2.829 0l-2.829-2.829 2.829-2.829 2.829 2.829c.378.378.586.88.586 1.414 0 .534-.208 1.037-.586 1.415zm-7.071-4.243a2.004 2.004 0 0 1 0-2.829c.378-.378.88-.586 1.415-.586s1.036.208 1.414.586l-2.829 2.829zm-4.242 15.556c-.756.756-2.073.756-2.829 0l-2.829-2.829 2.829-2.829 2.829 2.829c.779.781.78 2.05 0 2.829zm-7.071-4.242a1.986 1.986 0 0 1-.586-1.414c0-.534.208-1.037.586-1.415.39-.39.902-.584 1.414-.584a2 2 0 0 1 1.415.584l-2.829 2.829zm.707 13.065A6.853 6.853 0 0 1 9.856 45 6.864 6.864 0 0 1 3 38.144a6.853 6.853 0 0 1 2.985-5.659 102.302 102.302 0 0 0 6.264-4.676 3.957 3.957 0 0 0 1.145 2.554l4.243 4.243c.69.69 1.59 1.08 2.554 1.145a102.302 102.302 0 0 0-4.676 6.264zm8.83-11.151a3.963 3.963 0 0 0-1.052-1.914l-4.243-4.243a3.963 3.963 0 0 0-1.914-1.052 100.723 100.723 0 0 0 6.519-6.519 3.963 3.963 0 0 0 1.052 1.914l4.243 4.243a3.963 3.963 0 0 0 1.914 1.052 100.723 100.723 0 0 0-6.519 6.519zm17.67-15.349a102.302 102.302 0 0 0-6.264 4.676 3.957 3.957 0 0 0-1.145-2.554l-4.243-4.243c-.695-.695-1.629-1.056-2.581-1.111a102.11 102.11 0 0 0 4.703-6.298A6.853 6.853 0 0 1 38.144 3 6.864 6.864 0 0 1 45 9.856a6.853 6.853 0 0 1-2.985 5.659z"
        fill={fill}
      />
    </svg>
  )
}
