import { setBarcodeModalInitialQuery } from '@actions/barcode'
import { setBarcodeFlow } from '@actions/flows'
import Loading from '@components/Loading'
import { useBarcodeProducts } from '@data'
import useFormatSearchProductsQueryPayload from '@hooks/formatPayloadForSearchApi/useFormatSearchProductsQueryPayload'
import useBarcodeHandleScenario from '@hooks/useBarcodeHandleScenario'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { getSearchValueFromQs } from '@libs/url'
import { intersection, isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const BarcodeActions: React.FC = () => {
  const history = useHistory()
  const { basePath } = useStoreIndentity()
  const initialQuery = getSearchValueFromQs(history.location)
  const searchReqData = useFormatSearchProductsQueryPayload(initialQuery)
  const { data: barcodeProducts } = useBarcodeProducts(searchReqData)
  const redirectToScenario = useBarcodeHandleScenario()

  const dispatch = useDispatch()

  useEffect(() => {
    // The NavigationActions fragment can't handle the barcode flow starting here
    dispatch(setBarcodeFlow(true))
    dispatch(setBarcodeModalInitialQuery(initialQuery))
  }, [dispatch, initialQuery])

  if (!barcodeProducts) {
    return <Loading />
  }
  const {
    oneCatalogInfo,
    items,
    upcSameLensColor,
    upcSameFrameColor,
    upcSameSize,
    upcSameModel,
    upcPolarized,
    upcPrizm,
    upcPolarizedRelated,
    upcCustomizable,
  } = barcodeProducts.barcodeProducts

  const baseUpc = intersection(upcSameLensColor, upcSameSize, upcSameFrameColor, upcSameModel)
  const baseAndPolarizedUpc = intersection(baseUpc, upcPolarized)
  const sameSizeAndUpcPolarRelated = intersection(upcSameSize, upcPolarizedRelated)
  const differentCustomizableUpc = intersection(upcCustomizable, upcSameSize, upcSameModel)
  const baseAndPolarPrizmUpc = intersection(baseUpc, upcPrizm, upcPolarizedRelated)
  const baseAndPrizmUpc = intersection(baseUpc, upcPrizm)
  const prizmAndPolarUpc = intersection(upcPrizm, upcPolarized)
  const sameSizeMoco = intersection(upcSameSize, upcSameModel)

  const availableProduct = items?.find(p => p.UPC === initialQuery)
  const customAndAvailableProduct = items?.find(() => upcCustomizable.includes(initialQuery))
  const customAndUnavailableProcuct = oneCatalogInfo?.customizable
  const prizmProduct =
    items?.find(() => prizmAndPolarUpc.includes(initialQuery)) ??
    items?.find(() => upcPrizm.includes(initialQuery))

  const polarizedProduct = items?.find(() => baseAndPolarizedUpc.includes(initialQuery))
  const availablePolarPrizmMoco = items?.find(p => baseAndPolarPrizmUpc.includes(p.UPC))
  const availablePrizmMoco = items?.find(p => baseAndPrizmUpc.includes(p.UPC))

  const availablePolarizedMoco = items?.find(p => sameSizeAndUpcPolarRelated.includes(p.UPC))

  const availableOtherMoco = items?.find(p => differentCustomizableUpc.includes(p.UPC))
  const availableSameSizeMoco = items?.find(p => sameSizeMoco.includes(p.UPC))
  const hasProductNeverExisted = isEmpty(oneCatalogInfo)

  if (availableProduct) {
    if (customAndAvailableProduct) {
      return redirectToScenario('scenario1', customAndAvailableProduct)
    }
    if (prizmProduct) {
      return redirectToScenario('scenario2A', prizmProduct)
    }
    if (availablePolarPrizmMoco) {
      return redirectToScenario('scenario2B', availablePolarPrizmMoco, {
        pathname: availableProduct.url,
      })
    }
    if (availablePrizmMoco) {
      return redirectToScenario('scenario2C', availablePrizmMoco, {
        pathname: availableProduct.url,
      })
    }
    if (polarizedProduct) {
      return redirectToScenario('scenario3A', polarizedProduct)
    } else {
      if (!availablePolarizedMoco) {
        return redirectToScenario('scenario3C', availableProduct)
      } else {
        return redirectToScenario('scenario3B', availablePolarizedMoco, {
          pathname: availableProduct.url,
        })
      }
    }
  } else {
    if (customAndUnavailableProcuct) {
      if (availableOtherMoco) {
        return redirectToScenario('scenario4A', availableOtherMoco)
      } else {
        return redirectToScenario('scenario4B', oneCatalogInfo, {
          pathname: basePath,
          query: initialQuery,
        })
      }
    } else {
      if (availablePolarPrizmMoco) {
        return redirectToScenario('scenario5A', availablePolarPrizmMoco)
      }
      if (availablePrizmMoco) {
        return redirectToScenario('scenario5B', availablePrizmMoco)
      }
      if (availablePolarizedMoco) {
        return redirectToScenario('scenario6', availablePolarizedMoco)
      } else {
        if (availableSameSizeMoco) {
          return redirectToScenario('scenario7', availableSameSizeMoco)
        } else if (hasProductNeverExisted) {
          return redirectToScenario('scenario9', undefined, {
            pathname: basePath,
            query: initialQuery,
          })
        } else {
          return redirectToScenario(
            'scenario8',
            undefined,
            {
              pathname: basePath,
              query: initialQuery,
            },
            items
          )
        }
      }
    }
  }
}
