import { toggleCarouselsAutoplay } from '@actions/accessibility'
import {
  AccessibilitySwitcher,
  SectionContent,
  SectionIcon,
  SectionTitleRow,
  SectionTitleRowLabel,
} from '@components/Accessibility/sections/styles'
import { ScreenReaderIcon } from '@components/core/Icons'
import { Heading4, Text } from '@components/core/Typography'
import { useCarouselsAutoplayState } from '@hooks/useCarouselsAutoplayState'
import { pxToRem } from '@libs/styled'
import { pushToDataTrack } from '@providers/analyticsProvider'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ScreenReaderSectionStyle } from './styles'

export const ScreenReaderSection: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const carouselAutoplayState = useCarouselsAutoplayState()
  const screenReaderState = useSelector(s => s.accessibility.screenReaderState)

  const handleScreenReaderCarousels = useCallback(() => {
    dispatch(toggleCarouselsAutoplay)
    pushToDataTrack({
      id: 'Click',
      data_element_id: 'AdaModal_ScreenReader',
      data_description: screenReaderState ? 'OFF' : 'ON',
    })
  }, [dispatch, screenReaderState])

  return (
    <ScreenReaderSectionStyle>
      <SectionTitleRow>
        <SectionTitleRowLabel>
          <SectionIcon>
            <ScreenReaderIcon height={pxToRem(32)} width={pxToRem(33)} />
          </SectionIcon>
          <Heading4 weight="bold" aria-label={t('AccessibilityLabel.modalScreenReader')}>
            {t('AccessibilityLabel.modalScreenReader')}
          </Heading4>
        </SectionTitleRowLabel>
        <AccessibilitySwitcher
          name="Screen Reader carousel autoplay"
          disabled={screenReaderState}
          aria-label={
            carouselAutoplayState
              ? t('AccessibilityLabel.modalSwitchOn')
              : t('AccessibilityLabel.modalSwitchOff')
          }
          checked={carouselAutoplayState}
          onChange={handleScreenReaderCarousels}
          data-element-id="AdaModal_ScreenReader"
          data-test="AdaModal_ScreenReader"
          data-description={carouselAutoplayState ? 'ON' : 'OFF'}
        />
      </SectionTitleRow>
      <SectionContent>
        <Text>{t('AccessibilityLabel.modalScreenReaderCarouselsSubTitle')}</Text>
      </SectionContent>
    </ScreenReaderSectionStyle>
  )
}
