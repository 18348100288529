import { FiltersState, ProductTypeValues } from '@abstractTypes/filter'
import { PRODUCT_TYPE } from '@constants/product'
import { useMemo } from 'react'

const getProductTypeCount = (filters: FiltersState, productType: ProductTypeValues) =>
  filters[PRODUCT_TYPE].options?.find(o => o.value === productType)?.count || 0

export const usePredefinedFiltersProductCount = (filters: FiltersState) => {
  const productsCount = useMemo(
    () => ({
      [ProductTypeValues.OPTICAL]: getProductTypeCount(filters, ProductTypeValues.OPTICAL),
      [ProductTypeValues.SUN]: getProductTypeCount(filters, ProductTypeValues.SUN),
    }),
    [filters]
  )

  return productsCount
}
