import { EyeColor, HairColor } from '@abstractTypes/frameAdvisor'
import { IconProps } from '@abstractTypes/icons'
import {
  BlackMetalsIcon,
  BronzeMetalsIcon,
  ButterflyIcon,
  CatIcon,
  GeometricalIcon,
  GoldMetalsIcon,
  GunMetalsIcon,
  IrregularIcon,
  OvalIcon,
  PhantosIcon,
  PillowIcon,
  PilotIcon,
  RectangleIcon,
  RoseMetalsIcon,
  RoundIcon,
  SilverMetalsIcon,
  SquareIcon,
  UndertoneBlueIcon,
  UndertoneCoolIcon,
  UndertoneGreenIcon,
  UndertoneWarmIcon,
} from '@components/core/Icons'
import config from '@config/index'
import { pxToRem } from '@libs/styled'
import { ColorImage } from './styles'

export const undertoneIcons = {
  warm: <UndertoneWarmIcon />,
  cool: <UndertoneCoolIcon />,
  gold: <ColorImage src={`${config.assets.URL}/frameAdvisor/gold.png`} />,
  silver: <ColorImage src={`${config.assets.URL}/frameAdvisor/silver.png`} />,
  blue: <UndertoneBlueIcon />,
  green: <UndertoneGreenIcon />,
}

const hairColorImageMap: { [key in HairColor]?: string } = {
  [HairColor.bald]: `${config.assets.URL}/frameAdvisor/hair_color-bald.svg`,
  [HairColor.black]: `${config.assets.URL}/frameAdvisor/hair_color-black.png`,
  [HairColor.blonde]: `${config.assets.URL}/frameAdvisor/hair_color-blonde.png`,
  [HairColor.brown]: `${config.assets.URL}/frameAdvisor/hair_color-brown.png`,
  [HairColor.gray]: `${config.assets.URL}/frameAdvisor/hair_color-gray.png`,
  [HairColor.red]: `${config.assets.URL}/frameAdvisor/hair_color-red.png`,
  [HairColor.other]: `${config.assets.URL}/frameAdvisor/hair_color-other.png`,
}

const eyeColorImageMap: { [key in EyeColor]?: string } = {
  [EyeColor.blue]: `${config.assets.URL}/frameAdvisor/iris_color-blue.png`,
  [EyeColor.green]: `${config.assets.URL}/frameAdvisor/iris_color-green.png`,
  [EyeColor.brown]: `${config.assets.URL}/frameAdvisor/iris_color-brown.png`,
  [EyeColor.other]: `${config.assets.URL}/frameAdvisor/iris_color-other.png`,
}

const lensTreatmentColors = {
  gradient: `${config.assets.URL}/frameAdvisor/gradient.png`,
  mirror: `${config.assets.URL}/frameAdvisor/mirror.png`,
  solid: `${config.assets.URL}/frameAdvisor/solid.png`,
}

export type ColorCategory = 'lense' | 'eye' | 'hair'

export const getColorIcon = (category: ColorCategory, name: string) => {
  let list: { [key: string]: string } = {}
  switch (category) {
    case 'eye':
      list = eyeColorImageMap
      break
    case 'hair':
      list = hairColorImageMap
      break
    case 'lense':
      list = lensTreatmentColors
      break
  }
  const src = list[name]
  return <ColorImage src={src} key={name} />
}

const metalsIconsMap: { [key: string]: React.FC<IconProps> } = {
  black_metals: BlackMetalsIcon,
  bronze_metals: BronzeMetalsIcon,
  gold_metals: GoldMetalsIcon,
  gun_metals: GunMetalsIcon,
  rose_metals: RoseMetalsIcon,
  silver_metals: SilverMetalsIcon,
}

export const getMetalsIcon = (name: string) => {
  const Icon = metalsIconsMap[name]
  return <Icon width="100%" height="100%" />
}

const plasticIconsMap: { [key: string]: string[] } = {
  black_plastics: [
    `${config.assets.URL}/frameAdvisor/black-plastics_1.png`,
    `${config.assets.URL}/frameAdvisor/black-plastics_2.png`,
    `${config.assets.URL}/frameAdvisor/black-plastics_3.png`,
  ],
  tortoise_plastics: [
    `${config.assets.URL}/frameAdvisor/tortoise-plastics_1.png`,
    `${config.assets.URL}/frameAdvisor/tortoise-plastics_2.png`,
    `${config.assets.URL}/frameAdvisor/tortoise-plastics_3.png`,
  ],
}

export const getPlasticsIcons = (name: string) => {
  const srcList = plasticIconsMap[name]
  return srcList.map(src => <ColorImage src={src} key={src} />)
}

const frameShapeIconsMap: Record<string, React.FC<IconProps>> = {
  round: RoundIcon,
  oval: OvalIcon,
  cateye: CatIcon,
  phantos: PhantosIcon,
  pillow: PillowIcon,
  pilot: PilotIcon,
  butterfly: ButterflyIcon,
  rectangle: RectangleIcon,
  square: SquareIcon,
  irregular: IrregularIcon,
  geometrical: GeometricalIcon,
}

export const getFrameShapeIcon = (name: string, color: string) => {
  const Icon = frameShapeIconsMap[name]
  return Icon ? <Icon fill={color} stroke={color} width={pxToRem(48)} height="100%" /> : <div />
}
