import { setCompleteSurvey } from '@actions/frameAdvisor'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export const useTryCompleteSurvey = (nextStep: Maybe<string>) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (!nextStep) {
      dispatch(setCompleteSurvey(true))
    } else {
      dispatch(setCompleteSurvey(false))
    }
  }, [dispatch, nextStep])
}
