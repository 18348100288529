import { IconProps } from '@abstractTypes/icons'

export const IrregularIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 0.5H37C32.338 0.5 28.433 3.695 27.325 8.011C26.352 7.363 25.198 7 24 7C22.789 7 21.643 7.356 20.673 8.005C19.563 3.692 15.66 0.5 11 0.5H2C0.895 0.5 0 1.395 0 2.5V11.5C0 17.023 4.477 21.5 10 21.5H19C20.105 21.5 21 20.605 21 19.5V10.5C21 10.4819 20.9987 10.4643 20.9975 10.4468V10.4468C20.9962 10.4295 20.995 10.4124 20.995 10.395C21.755 9.516 22.826 9 24 9C25.157 9 26.251 9.506 27.007 10.367C27.0065 10.3875 27.0051 10.4076 27.0036 10.4277C27.0018 10.4516 27 10.4755 27 10.5V19.5C27 20.605 27.895 21.5 29 21.5H38C43.523 21.5 48 17.023 48 11.5V2.5C48 1.395 47.105 0.5 46 0.5ZM19 19.5H10C5.589 19.5 2 15.911 2 11.5V2.5H11C15.411 2.5 19 6.089 19 10.5V19.5ZM38 19.5C42.411 19.5 46 15.911 46 11.5V2.5H37C32.589 2.5 29 6.089 29 10.5V19.5H38Z"
        fill={fill}
      />
    </svg>
  )
}
