import NuanceHomeContent from '@components/Contents/NuanceHomeContent'
import { FloatingContainer } from '@components/FloatingActions/styles'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { altNavigation, landscape } from '@libs/media'
import { clampValuesLandscape } from '@libs/styled'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import BaseContainer, { ChildContainer, Main } from './BaseContainer'

const NuanceContainer = styled(BaseContainer)`
  ${FloatingContainer} {
    ${landscape(`
      inset-block-start: ${clampValuesLandscape(190, 240)}
    `)}
  }

  ${Main} {
    ${altNavigation(`
      overflow: hidden;
    `)}
  }

  ${ChildContainer} {
    ${altNavigation(`
      overflow: auto;
    `)}
  }
`

const NuanceHome: React.FC = () => {
  const { isAlternativeNavigation, zoomLevel } = useNavigationContext()
  const ref = useRef<HTMLDivElement>(null)

  const tabChangeHandler = useCallback(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollTop = 0
      }
    }, 0)
  }, [])

  return (
    <NuanceContainer
      ref={ref}
      showHeader={false}
      preventAccessibilityToggleResize
      withPadding={false}
      showPrivacyPolicyTooltip={false}
      content={<NuanceHomeContent onTabChange={tabChangeHandler} />}
      disclaimerColor="inactive"
      floatingDisclaimer
      preventScroll={isAlternativeNavigation && zoomLevel < 150}
    />
  )
}

export default NuanceHome
