import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useStoreIndentity } from './useStoreIdentity'

export const useReloadLangOnHistory = () => {
  const { i18n } = useTranslation()
  const { langCode } = useStoreIndentity()
  const history = useHistory()
  return useMemo(() => {
    if (history.action === 'POP') {
      i18n.changeLanguage(langCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langCode, history])
}
