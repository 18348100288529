import {
  CartType,
  CheckoutFormDataField,
  FormErrors,
  FormFieldValidator,
  FormPanel,
  StoreData,
} from '@abstractTypes/checkout'

export const CHANGE_FORM_PANEL = 'checkout/CHANGE_FORM_PANEL'
export const SET_DATA = 'checkout/SET_DATA'
export const VALIDATE_FIELD = 'checkout/VALIDATE_FIELD'
export const SET_FORM_ERRORS = 'checkout/SET_FORM_ERRORS'
export const SET_CART_TYPE = 'checkout/SET_CART_TYPE'

export interface ChangePanelAction {
  type: typeof CHANGE_FORM_PANEL
  payload: {
    activePanels: FormPanel[]
  }
}
export interface SetDataAction {
  type: typeof SET_DATA
  payload: {
    field: CheckoutFormDataField
    data: string
  }
}

export interface SetCartTypeAction {
  type: typeof SET_CART_TYPE
  payload: {
    cartType: CartType
  }
}

export interface ValidateFieldAction {
  type: typeof VALIDATE_FIELD
  payload: {
    field: CheckoutFormDataField
    value: string | undefined
    validators: FormFieldValidator[]
    storeData: StoreData
  }
}

export interface FormErrorsAction {
  type: typeof SET_FORM_ERRORS
  payload: {
    errors: FormErrors
  }
}

export const changePanel = (activePanels: FormPanel[]): ChangePanelAction => {
  return {
    type: CHANGE_FORM_PANEL,
    payload: {
      activePanels: activePanels,
    },
  }
}

export const setCheckoutData = (field: CheckoutFormDataField, data?: string): SetDataAction => {
  return {
    type: SET_DATA,
    payload: {
      field,
      data: data || '',
    },
  }
}

export const validateField = (
  field: CheckoutFormDataField,
  value: string | undefined,
  validators: FormFieldValidator[],
  storeData: StoreData
): ValidateFieldAction => {
  return {
    type: VALIDATE_FIELD,
    payload: {
      field,
      value,
      storeData,
      validators,
    },
  }
}

export const setFormErrors = (errors: FormErrors): FormErrorsAction => {
  return {
    type: SET_FORM_ERRORS,
    payload: {
      errors,
    },
  }
}

export const setCartType = (cartType: CartType): SetCartTypeAction => {
  return {
    type: SET_CART_TYPE,
    payload: {
      cartType,
    },
  }
}

export type CheckoutFormActions =
  | ChangePanelAction
  | SetDataAction
  | ValidateFieldAction
  | FormErrorsAction
  | SetCartTypeAction
