import { SCROLL_RESTORATION_ID } from '@constants/accessibility'
import { scrollToTop } from '@hooks/useScrollBackToTop'
import { createBrowserHistory } from 'history/'
import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  StoreEnhancer,
  StoreEnhancerStoreCreator,
} from 'redux'
import thunk, { ThunkAction as ThunkActionLib } from 'redux-thunk'
import { StoreActionTypes } from './actions/store'
import { loadState, persistReducers } from './localStorage'
import createRootReducer from './reducers'
import storeReducer from './reducers/store'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

declare module 'react-redux' {
  export interface DefaultRootState extends AppState {}
}

export const history = createBrowserHistory()
history.listen(() => {
  scrollToTop(document.body)
  scrollToTop(document.getElementById(SCROLL_RESTORATION_ID))
})

const appReducer = createRootReducer(history)
const initialState = loadState()

const rootReducer = (state: AppReducerState, action: StoreActionTypes) => {
  state = storeReducer(state, action)
  return appReducer(state, action)
}

const enhancers: StoreEnhancer[] = []
const middleware: Middleware[] = [thunk]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose<StoreEnhancerStoreCreator>(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(rootReducer, initialState, composedEnhancers)

persistReducers(
  store,
  'accessibility',
  'cart',
  'checkout',
  'wishlist',
  'session',
  'engraving',
  'filters',
  'frameAdvisor',
  'customerOrder',
  'order',
  'barcode',
  'version'
)

export default store

export type AppStore = typeof store
export type AppState = ReturnType<typeof appReducer>
export type AppReducerState = Parameters<typeof appReducer>[0]
export type ThunkAction<ReturnType = void> = ThunkActionLib<
  ReturnType,
  AppState,
  unknown,
  AnyAction
>
