import { ElectronicsContent } from '@components/ElectronicsComponents'
import { dataDescriptionForProduct } from '@libs/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSendVirtualPageView } from './analytics'
import { StyledSpinner } from './styles'
import { ElectronicsContentProps, ElectronicsContentWrapperProps } from './types'

export const SupernovaHomeContentProps: React.FC<ElectronicsContentWrapperProps> = props => (
  <ElectronicsHomeContent {...props} electronicsType="supernova" />
)

const ElectronicsHomeContent: React.FC<ElectronicsContentProps> = ({
  models,
  products,
  defaultModel,
  purchaseEnabled,
  electronicsType,
}) => {
  const { t } = useTranslation()

  useSendVirtualPageView(electronicsType)

  return (
    <ElectronicsContent
      isLandingPage
      electronicsType={electronicsType}
      models={models}
      products={products}
      defaultModel={defaultModel}
      shopLinkText={t('Electronics.SlideCTA')}
      seeInActionText={t('Electronics.SeeInActionCTA')}
      aria-label={t('Electronics.SlideCTA')}
      aria-description={t('Electronics.SlideCTA')}
      shopLinkVisible={purchaseEnabled}
      spiner={<StyledSpinner />}
      initialTabId="info"
      analytics={{
        dataElementId: 'Tiles_ShopNow',
        dataCalls: '0',
        dataDescription: selectedProduct => {
          return selectedProduct
            ? dataDescriptionForProduct({
                brand: {
                  name: selectedProduct.brand.name,
                },
                name: selectedProduct.name,
                UPC: selectedProduct.UPC,
              })
            : ''
        },
      }}
    />
  )
}
