import { SecondaryButton } from '@components/core/Button'
import config from '@config/index'
import { useAnalyticsError } from '@hooks/analyticsHooks'
import { useResetSS } from '@hooks/useResetSS'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ErrorWidgetDetails,
  ErrorWidgetLabels,
  ErrorWidgetList,
  ErrorWidgetMessage,
  ErrorWidgetMessages,
  ErrorWidget as ErrorWidgetStyled,
  ErrorWidgetTitle,
} from './styles'
import { ErrorTypes, ErrorwidgetProps, WithWrapperProps } from './types'

const SubErrorswidget: React.FC<{ errors: ErrorTypes[] }> = ({ errors }) => (
  <ErrorWidgetList>
    {errors.map((e, i) => (
      <ErrorWidgetMessage key={`error-${i}`}>{e.message}</ErrorWidgetMessage>
    ))}
  </ErrorWidgetList>
)

const ErrorWidget: React.FC<ErrorwidgetProps> = props => {
  const { t } = useTranslation()
  const { className, errors, type, action, actionLabel } = props
  const reset = useResetSS()

  useAnalyticsError({ errors, type })

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (config.errorWidgetTimeOut > 0) {
      intervalId = setInterval(reset, config.errorWidgetTimeOut)
    }
    return () => clearInterval(intervalId)
  }, [reset])

  return (
    <ErrorWidgetStyled className={className}>
      <ErrorWidgetLabels>
        <ErrorWidgetTitle>{`${type} - something went wrong!`}</ErrorWidgetTitle>{' '}
        {/* see: https://react.i18next.com/components/interpolate */}
        <SecondaryButton
          onClick={() => {
            action ? action() : window.location.reload()
          }}
          ariaAttrs={{
            label: actionLabel || t('Core.reload'),
            description: actionLabel || t('Core.reload'),
          }}
        >
          {actionLabel || t('Core.reload')}
        </SecondaryButton>
      </ErrorWidgetLabels>
      <ErrorWidgetDetails>
        <ErrorWidgetMessages>
          <ErrorWidgetList>
            {errors.map((e, i) => (
              <ErrorWidgetMessage key={`error-${i}`}>
                {e.message}
                {'result' in e && typeof e.result !== 'string' && (
                  <SubErrorswidget errors={e.result.errors} />
                )}
              </ErrorWidgetMessage>
            ))}
          </ErrorWidgetList>
        </ErrorWidgetMessages>
      </ErrorWidgetDetails>
    </ErrorWidgetStyled>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const withWrapper = <T extends unknown>(WrappedComponent: React.ComponentType<T>) => {
  const ErrorWidgetWrapper = (props: T & WithWrapperProps) => {
    const wrapperStyle = {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      paddingBlock: '1em ',
      paddingInline: '0',
      inlineSize: '100%',
    }

    if (props.withWrapper) {
      return (
        <div style={wrapperStyle}>
          <WrappedComponent {...props} />
        </div>
      )
    }
    return <WrappedComponent {...props} />
  }

  return ErrorWidgetWrapper
}

export default withWrapper(ErrorWidget)
