import { ProductSubdivisions } from '@abstractTypes/filter'
import useGetProductSubdivisionQueryParam from '@hooks/useGetProductSubdivisionQueryParam'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import qs from 'qs'
import { useCallback } from 'react'

const useGetViewAllLink = () => {
  const { basePath } = useStoreIndentity()
  const getProductSubdivisionQueryParam = useGetProductSubdivisionQueryParam()

  return useCallback(
    (query: string, productSubdivision: ProductSubdivisions) => {
      const productSubdivisionParam = getProductSubdivisionQueryParam(productSubdivision)
      const queryParams = qs.stringify({
        ...productSubdivisionParam,
        query: query.toLowerCase(),
      })
      return `${basePath}/products?${queryParams}`
    },
    [basePath, getProductSubdivisionQueryParam]
  )
}

export default useGetViewAllLink
