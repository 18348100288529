import { PreselectedFilters } from '@abstractTypes/filter'
import { ToastData } from '@abstractTypes/toast'
import { DrawerKey, ModalKey } from '@abstractTypes/utils'
import {
  ADD_LOADER_ID,
  ADD_TOAST,
  CLEAR_PRESELECTED_FILTERS,
  CLOSE_VISIBLE_DRAWER,
  DELETE_LOADER_ID,
  DELETE_TOAST,
  RESET_LOADER_LIST,
  SET_ARE_PDP_TABS_ON_TOP,
  SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE,
  SET_FILTERS_COUNT,
  SET_HAS_CHANGED_PAGE,
  SET_IS_DEFAULT_PLP,
  SET_STRIPE_BANNER_VISIBLE,
  SET_VISIBLE_DRAWER,
  SET_VISIBLE_MODAL,
  TOGGLE_FRAME_ADVISOR_MYSELECTION,
  TOGGLE_LANGUAGE_SELECTOR,
  UIActionTypes,
  UPDATE_PRESELECTED_FILTERS,
} from '@actions/ui'
import { uniqueId } from 'lodash'

export interface UIState {
  languageSelectorIsVisible: boolean
  frameAdvisorMySelectionIsVisible: boolean
  hasChangedPage: boolean
  frameAdvisorMySelectionIsAnimated: boolean
  stripeBannerIsVisible: boolean

  shouldUpdatePreselectedFilters: boolean
  preselectedFilters?: PreselectedFilters
  isCustomLogoHeroSlideActive: boolean
  arePdpTabsOnTop: boolean
  listLoaderIds: string[]
  filtersCount: number
  toasts: ToastData[]
  visibleDrawerKey: DrawerKey
  visibleModalKey: ModalKey
  isDefaultPlp: boolean
}

const initialState: UIState = {
  visibleDrawerKey: undefined,
  visibleModalKey: undefined,

  languageSelectorIsVisible: false,
  frameAdvisorMySelectionIsVisible: false,
  hasChangedPage: false,
  frameAdvisorMySelectionIsAnimated: false,
  stripeBannerIsVisible: false,
  shouldUpdatePreselectedFilters: false,
  isCustomLogoHeroSlideActive: true,
  arePdpTabsOnTop: false,
  listLoaderIds: [],
  filtersCount: 0,
  toasts: [],
  isDefaultPlp: false,
}

export default (state = initialState, action: UIActionTypes) => {
  switch (action.type) {
    case RESET_LOADER_LIST:
      return { ...state, listLoaderIds: [] }

    case TOGGLE_LANGUAGE_SELECTOR:
      return { ...state, languageSelectorIsVisible: !state.languageSelectorIsVisible }
    case TOGGLE_FRAME_ADVISOR_MYSELECTION:
      return {
        ...state,
        frameAdvisorMySelectionIsVisible: !state.frameAdvisorMySelectionIsVisible,
      }
    case SET_VISIBLE_DRAWER:
      return {
        ...state,
        visibleDrawerKey: action.payload.drawerKey,
      }

    case CLOSE_VISIBLE_DRAWER:
      return {
        ...state,
        visibleDrawerKey:
          action.payload.drawerKey === state.visibleDrawerKey ? undefined : state.visibleDrawerKey,
      }

    case SET_VISIBLE_MODAL:
      return {
        ...state,
        visibleModalKey: action.payload.modalKey,
      }

    case SET_HAS_CHANGED_PAGE:
      return {
        ...state,
        hasChangedPage: true,
      }

    case UPDATE_PRESELECTED_FILTERS: {
      return {
        ...state,
        preselectedFilters: action.payload.filters,
        shouldUpdatePreselectedFilters: false,
      }
    }
    case CLEAR_PRESELECTED_FILTERS: {
      return {
        ...state,
        shouldUpdatePreselectedFilters: true,
        preselectedFilters: undefined,
      }
    }
    case SET_CUSTOM_LOGO_HERO_SLIDE_ACTIVE_STATE: {
      return {
        ...state,
        isCustomLogoHeroSlideActive: action.payload.isActive,
      }
    }
    case SET_STRIPE_BANNER_VISIBLE:
      return { ...state, stripeBannerIsVisible: action.payload.isVisible }

    case SET_FILTERS_COUNT:
      return { ...state, filtersCount: action.payload }
    case ADD_TOAST:
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            id: uniqueId(),
            ...action.payload,
          },
        ],
      }
    case DELETE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter(t => t.id !== action.payload),
      }
    case SET_ARE_PDP_TABS_ON_TOP:
      return {
        ...state,
        arePdpTabsOnTop: action.payload,
      }
    case SET_IS_DEFAULT_PLP: {
      return {
        ...state,
        isDefaultPlp: action.payload.isDefaultPlp,
      }
    }
    case ADD_LOADER_ID: {
      const loaderIdsSet = new Set(state.listLoaderIds)
      loaderIdsSet.add(action.payload.id)

      return {
        ...state,
        listLoaderIds: Array.from(loaderIdsSet),
      }
    }
    case DELETE_LOADER_ID: {
      return {
        ...state,
        listLoaderIds: state.listLoaderIds.filter(id => id !== action.payload),
      }
    }
    default:
      return state
  }
}
