import { ProductInfoConfig, ProductInfoConfigScenario } from '@abstractTypes/tiles'
import { useProductInfoConfig } from './useProductInfoConfig'

const getDefaultProductInfoConfigCheckout = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'brand',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'name',
    },
  ],
})

const getMonoBrandRBProductInfoConfigCheckout = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
  ],
})

const getMonoBrandOOProductInfoConfigCheckout = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'price',
      style: {
        weight: 'bold',
      },
    },
  ],
})

const productInfoConfigByScenarios: ProductInfoConfigScenario = {
  monobrand: {
    rb: getMonoBrandRBProductInfoConfigCheckout,
    oo: getMonoBrandOOProductInfoConfigCheckout,
  },
  multibrand: {
    default: getDefaultProductInfoConfigCheckout,
  },
  default: getDefaultProductInfoConfigCheckout,
}

export const useProductInfoConfigCheckout = () => {
  const { getProductInfoConfig } = useProductInfoConfig(productInfoConfigByScenarios)
  return getProductInfoConfig
}
