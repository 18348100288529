import { NuanceContent } from '@abstractTypes/graphqlTypes'
import { NuanceText } from '@components/core/Typography/NuanceTypography'
import { ContentImage } from '@components/Image'
import React from 'react'
import { ImagesScrollableContainer, ImageWrapper } from './styles'

export interface ImagesContentProps {
  images: NuanceContent['cropImages']
}

export const GlassesImagesContent: React.FC<ImagesContentProps> = ({ images }) => {
  return images?.length ? (
    <ImagesScrollableContainer>
      {images.map((variant, index) => (
        <ImageWrapper key={index}>
          <ContentImage
            key={variant?.id}
            imageSource={variant.cropImage ? variant : variant.src}
            aria-label={variant?.altText}
            aria-description={variant?.altText}
            ratio={{ tower: '_4x3', towerLandscape: '_4x3', ipad: '_4x3', ipadlandscape: '_4x3' }}
          />
          <NuanceText weight="bold">{variant?.altText}</NuanceText>
        </ImageWrapper>
      ))}
    </ImagesScrollableContainer>
  ) : null
}
