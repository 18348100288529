import { Device } from '@abstractTypes/utils'
import {
  ElectronicsButtonTypography,
  ElectronicsHeading1,
  ElectronicsHeading4,
} from '@components/core/Typography/ElectronicsTypography'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  transformTranslateX,
  zoomLandscape,
} from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { scrollbar } from '@styles/common'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { landscapeTabletSliderWidth, landscapeTowerSliderWidth } from '../styleConsts'

export const ElectronicsTogglerAltNavContainer = styled.div`
  inline-size: fit-content;
  margin-inline-start: auto;
`

export const StyledElectronicsContent = styled.div`
  display: flex;
  flex-direction: column;
  block-size: 100%;
  position: relative;
  overscroll-behavior: none;
  ${landscape(`
    justify-content: flex-end;
    block-size: 100vh;
  `)}
  ${landscape(
    Device.tablet,
    `
    block-size: 100%;
  `
  )}
  ${altNavigation(`
    block-size: auto;
    overflow: scroll;
  `)}
`

export const TopContent = styled.div`
  inline-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  inset-block-start: ${pxToRem(143)};
  inset-inline-start: 50%;
  ${transformTranslateX({ ltr: '-50%', rtl: '50%' })}
  transition: width 0.8s linear;
  ${tablet(
    `
    inset-block-start: ${clampValues(130, 180)};
  `
  )}
  ${landscape(`
    inline-size: ${landscapeTowerSliderWidth};
    inset-inline-start: 0;
    transform: translate(0);
  `)}
  ${landscape(
    Device.tablet,
    `
      inset-block-start: ${clampValuesLandscape(104, 143)};
      inline-size: ${landscapeTabletSliderWidth};
  `
  )}
  ${altNavigation(`
    inset-block-start: ${pxToRem(36)};
    inset-inline-end: ${pxToRem(32)};
    inset-inline-start: auto;
    inline-size: auto;
    transform: none;
  `)}
  ${altNavigationZoom(
    [150],
    `
      position: fixed;
      inset-block-start: calc(56vh + ${pxToRem(27)});
      z-index: 3;
  `
  )}
  ${altNavigationZoom(
    [175, 200],
    `
      position: fixed;
      inset-block-start: calc(56vh + ${pxToRem(17)});
      z-index: 3;
  `
  )}
`

export const ScrollableContent = styled.div`
  ${scrollbar};
  padding-block: 0;
  padding-inline: ${pxToRem(20)};
  margin-block: ${pxToRem(72)} ${pxToRem(56)};
  overflow: auto;
  inline-size: 80%;
  ${tablet(
    `
    margin-block: ${clampValues(24, 32)} ${clampValues(40, 56)};
  `
  )}
  ${({ theme }) => css`
    ${zoomLandscape(
      [150],
      `
      max-block-size: ${pxToRem(theme.electronics.fonts.heading1.lineHeight.tower * 3, 1.5)};
    `
    )}
    ${zoomLandscape(
      [175],
      `
      max-block-size: ${pxToRem(theme.electronics.fonts.heading1.lineHeight.tower * 3, 1.75)};
    `
    )}
    ${zoomLandscape(
      [200],
      `
      max-block-size: ${pxToRem(theme.electronics.fonts.heading1.lineHeight.tower * 2, 2)};
    `
    )}
  `}

  & > h1 {
    overflow: hidden;
  }
`

export const SlideTitle = styled(ElectronicsHeading1)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  inline-size: 100%;
`

export const TabTitle = styled(ElectronicsHeading4)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-block: ${pxToRem(56)};
  ${({ theme }) => css`
    ${altNavigation(`
      color: ${theme.electronics.colors.primaryDark};
      padding-inline-end: ${pxToRem(21)};
    `)}
    ${altNavigationZoom(
      [150, 175, 200],
      `
        margin-block-start: 0;
    `
    )}
  `}
`

export const ElectronicsTabsContentContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.electronics.colors.primaryLight};
  ${landscape(`
    position: static;
  `)}
  ${landscape(
    Device.tablet,
    `
    flex-grow: 1;
  `
  )}
`

export const SlideCTA = styled(ElectronicsButtonTypography)<{ outline?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-block-size: ${pxToRem(80)};
  min-inline-size: ${pxToRem(330)};
  inline-size: 100%;
  block-size: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  background-color: ${({ theme, outline }) =>
    outline ? 'transparent' : theme.electronics.colors.primaryDark};
  color: ${({ theme }) => theme.electronics.colors.primaryLight};
  border: 2px solid;
  border-color: ${({ theme, outline }) =>
    outline ? theme.electronics.colors.primaryLight : theme.electronics.colors.primaryDark};
  padding: ${pxToRem(10)};
  ${tablet(
    `
  min-block-size: ${clampValues(58, 80)};
  min-inline-size: ${clampValues(240, 330)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
      min-block-size: ${clampValuesLandscape(58, 80)};
      min-inline-size: ${clampValuesLandscape(180, 247)};
    `
  )};
  ${altNavigation(`
    padding-block: ${pxToRem(16)} ;
    padding-inline:  ${pxToRem(32)};
    inline-size: ${pxToRem(230)};
    max-inline-size: unset;
    min-inline-size: unset;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  `)}
  ${({ theme, outline }) => css`
    ${altNavigationZoom(
      [100, 110, 125, 150, 175, 200],
      `
      inline-size: ${pxToRem(400)};
      background-color: 'transparent' ;
      color: ${
        outline ? theme.electronics.colors.primaryDark : theme.electronics.colors.primaryLight
      };
      border: ${outline && `2px solid ${theme.electronics.colors.primaryDark}`};
    `
    )}
    ${altNavigationZoom(
      [175, 200],
      `
      inline-size: ${pxToRem(500)};
    `
    )}
  `}
`

export const StyledLink = styled(({ to, ...props }) => <Link to={to} {...props} />)`
  flex: 1;
  background-color: ${({ theme }) => theme.electronics.colors.primaryLight};
  ${altNavigation(`
    flex: 0;
  `)}
  ${({ theme }) => css`
    ${altNavigationZoom(
      [100, 110, 125, 150, 175, 200],
      `
      background-color: ${theme.electronics.colors.primaryDark};
    `
    )}
  `}
`

export const SeeOnVideoButton = styled(SlideCTA)`
  margin-inline-end: ${pxToRem(16)};
  ${altNavigationZoom(
    [150, 175, 200],
    `
      margin-inline-end: 0;
    `
  )}
`

export const StyledLinkWrapper = styled.div<{ isTabsExpanded?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: absolute;
  z-index: 2;
  inset-block-end: ${({ isTabsExpanded }) => (isTabsExpanded ? pxToRem(1440) : pxToRem(729))};
  inset-inline-start: 50%;
  ${transformTranslateX({ ltr: '-50%', rtl: '50%' })}
  transition: top 0.8s;

  /* top changed to bottom as relative parent is changed */
  ${landscape(`
    inset-inline-start: 45vh;
    inset-block-end: 16vh;
    inset-block-start: auto;
    max-inline-size: calc(90vh - ${pxToRem(32)});
  `)}
  ${({ isTabsExpanded }) =>
    tablet(`
      inset-block-end: unset;
      inset-block-start: ${
        isTabsExpanded
          ? `calc(50% - ${clampValues(320, 120)})`
          : `calc(50% + ${clampValues(10, 50)})`
      }
      ;

  `)}
  ${landscape(
    Device.tablet,
    `
      inset-inline-start: 43vh;
      max-inline-size: calc(100vw - 50vh);
  `
  )}
  ${altNavigation(`
    position: fixed;
    inset-block-end: ${pxToRem(114)};
    inset-inline-start: ${pxToRem(16)};
    transform: none;
    inline-size: ${pxToRem(497)};
    justify-content: center;
  `)}
  ${({ theme }) => css`
    ${altNavigationZoom(
      [100, 110, 125, 150, 175, 200],
      `
        inline-size: 100%;
        inset-block-end: 0;
        inset-block-start: auto;
        inset-inline-start: 0;
        block-size: fit-content;
        background-color: ${theme.electronics.colors.primaryLight};
        padding: ${pxToRem(10)};
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: ${pxToRem(8)};
    `
    )}
  `}
`
