import { IconProps } from '@abstractTypes/icons'

export const FaFrameShapeCatIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 68 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M54.6903 0.670898C53.0611 0.670898 51.3994 0.799814 49.7787 1.11998C45.2681 2.01106 40.5137 4.3854 38.2598 7.50631C36.9735 6.7569 35.52 6.33331 33.9999 6.33331C32.4699 6.33331 31.0107 6.7399 29.7286 7.48931C27.4705 4.3769 22.7246 2.00965 18.2225 1.1214C16.6018 0.80123 14.9401 0.672314 13.3109 0.672314C6.37772 0.672314 0.0395508 3.01831 0.0395508 3.01831C0.0395508 15.1067 6.72055 23.3305 17.7337 23.3305C28.7469 23.3305 31.9783 16.0247 31.0036 10.3368C30.9895 10.2504 30.9583 10.1654 30.9399 10.079C31.8437 9.49248 32.8921 9.16665 33.9999 9.16665C35.0992 9.16665 36.1575 9.48256 37.0641 10.0662C37.0443 10.1569 37.0131 10.2447 36.9975 10.3354C36.0243 16.0233 39.2543 23.3291 50.2675 23.3291C61.2806 23.3291 67.9616 15.1053 67.9616 3.0169C67.9616 3.0169 61.6235 0.670898 54.6903 0.670898ZM26.5822 17.2771C24.8071 19.3822 21.7457 20.4957 17.7323 20.4957C9.16147 20.4957 3.62655 14.6406 2.94372 5.06682C5.19622 4.42507 9.15155 3.50423 13.3109 3.50423C14.8749 3.50423 16.3426 3.6374 17.6728 3.89948C23.0108 4.95348 27.7382 8.05456 28.21 10.8128C28.6194 13.2084 28.0258 15.5643 26.5822 17.2771ZM50.2675 20.4957C46.2541 20.4957 43.194 19.3822 41.4175 17.2771C39.9725 15.5643 39.379 13.2084 39.7898 10.8128C40.2616 8.05456 44.989 4.95348 50.327 3.89948C51.6572 3.6374 53.1263 3.50423 54.6903 3.50423C58.8284 3.50423 62.7965 4.4279 65.0561 5.06965C64.3718 14.6421 58.8369 20.4957 50.2675 20.4957Z"
        fill={fill}
      />
    </svg>
  )
}
