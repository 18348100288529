import { IconProps } from '@abstractTypes/icons'

export const QrcodeIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61872)">
        <path
          d="M1.33333 2.58398C1.33333 2.21598 1.63267 1.91732 2 1.91732H13.3333V0.583984H2C0.897333 0.583984 0 1.48132 0 2.58398V13.9173H1.33333V2.58398Z"
          fill="#4A4A4A"
        />
        <path
          d="M1.33333 30.5843V19.251H0V30.5843C0 31.687 0.897333 32.5843 2 32.5843H13.3333V31.251H2C1.63267 31.251 1.33333 30.9523 1.33333 30.5843Z"
          fill="#4A4A4A"
        />
        <path
          d="M30.668 30.5843C30.668 30.9523 30.3686 31.251 30.0013 31.251H18.668V32.5843H30.0013C31.104 32.5843 32.0013 31.687 32.0013 30.5843V19.251H30.668V30.5843Z"
          fill="#4A4A4A"
        />
        <path
          d="M30.0013 0.583984H18.668V1.91732H30.0013C30.3686 1.91732 30.668 2.21598 30.668 2.58398V13.9173H32.0013V2.58398C32.0013 1.48132 31.104 0.583984 30.0013 0.583984Z"
          fill="#4A4A4A"
        />
        <path
          d="M13.3333 4.58398H5.33333C4.59667 4.58398 4 5.18065 4 5.91732V13.9173C4 14.654 4.59667 15.2507 5.33333 15.2507H13.3333C14.07 15.2507 14.6667 14.654 14.6667 13.9173V5.91732C14.6667 5.18065 14.07 4.58398 13.3333 4.58398ZM13.3333 13.9173H5.33333V5.91732H13.3333V13.9173Z"
          fill="#4A4A4A"
        />
        <path
          d="M13.3333 17.917H5.33333C4.59667 17.917 4 18.5137 4 19.2503V27.2503C4 27.987 4.59667 28.5837 5.33333 28.5837H13.3333C14.07 28.5837 14.6667 27.987 14.6667 27.2503V19.2503C14.6667 18.5137 14.07 17.917 13.3333 17.917ZM13.3333 27.2503H5.33333V19.2503H13.3333V27.2503Z"
          fill="#4A4A4A"
        />
        <path
          d="M18.6654 15.2507H26.6654C27.402 15.2507 27.9987 14.654 27.9987 13.9173V5.91732C27.9987 5.18065 27.402 4.58398 26.6654 4.58398H18.6654C17.9287 4.58398 17.332 5.18065 17.332 5.91732V13.9173C17.332 14.654 17.9287 15.2507 18.6654 15.2507ZM18.6654 5.91732H26.6654V13.9173H18.6654V5.91732Z"
          fill="#4A4A4A"
        />
        <path
          d="M26.6654 28.5788C27.402 28.5788 27.9987 27.9821 27.9987 27.2454V19.2454C27.9987 18.5088 27.402 17.9121 26.6654 17.9121H18.6654C17.9287 17.9121 17.332 18.5088 17.332 19.2454V27.2454C17.332 27.9821 17.9287 28.5788 18.6654 28.5788H26.6654ZM18.6654 19.2454H26.6654V27.2454H18.6654V19.2454Z"
          fill="#4A4A4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61872">
          <rect width={32} height={32} fill="white" transform="translate(0 0.583984)" />
        </clipPath>
      </defs>
    </svg>
  )
}
