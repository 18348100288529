import { SizeFitTipIcon } from '@components/core/Icons'
import SSTrans from '@components/core/Trans'
import { Text } from '@components/core/Typography'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { MessageBox, TipImageWrapper } from '../styles'

const translationKey = 'FrameAdvisor.tips.sizeTip'

const SizeTipTitle = styled(Text)`
  display: inline;
`
const SizeTipText = styled(Text)`
  inline-size: ${pxToRem(306)};
  max-inline-size: 100%;
`

const iconSize = isIPadView() ? clampValues(74, 100) : pxToRem(100)

export const SizeTipContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <MessageBox>
        <SizeTipTitle>
          <SSTrans
            i18nKey={`${translationKey}.title`}
            components={{
              bold: <SizeTipTitle weight="bold" />,
            }}
          />
        </SizeTipTitle>
      </MessageBox>
      <TipImageWrapper>
        <SizeFitTipIcon width={iconSize} height={iconSize} />
      </TipImageWrapper>
      <SizeTipText>{t(`${translationKey}.text`)}</SizeTipText>
    </>
  )
}
