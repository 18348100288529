import { Text } from '@components/core/Typography'
import styled from 'styled-components'

export const PlayListTitle = styled(Text)<{ fontId: string }>`
  margin-block: 0 4rem;
  margin-inline: auto;
  text-transform: uppercase;
  max-inline-size: 32.1363rem;
`

export const PlayListDescription = styled(Text)`
  margin-block-end: 2.6818rem;
`

export const PlayListSlideContainer = styled.div`
  inline-size: 100%;
`

export const PlayListSlideInfo = styled.div`
  position: relative;
  inline-size: 100%;
`

export const Btn = styled.button`
  position: absolute;
  inset-block-start: 45%;
  inline-size: 2rem;
  block-size: 2rem;
  border-block-start: 4px solid white;
  border-inline-start: 4px solid white;
  cursor: pointer;
`

export const PrevBtn = styled(Btn)`
  inset-inline-start: 2rem;
  transform: rotate(-45deg);
`

export const NextBtn = styled(Btn)`
  inset-inline-end: 2rem;
  transform: rotate(135deg);
`

export const Title = styled(Text)`
  margin-block-end: 2.0454rem;
  text-transform: uppercase;
`
