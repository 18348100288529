import { BarcodeScanModalBase } from '@components/BarCodeScanModal/Modals/BarcodeScanModalBase'
import { BarcodeScanModalWithRedirectProps } from '@components/BarCodeScanModal/types'
import { PrimaryButton, SecondaryButton } from '@components/core/Button'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { getCustomizableBrand, isOOBrand } from '@libs/custom'
import { getCustomProductRoute } from '@libs/routing'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSendBarcodeAvailableScannedCustomAnalyticsEvent } from './analytics'

export const BarcodeScanModalExactUpc = ({
  visible,
  onCancel,
  onRedirect,
  productInfo,
}: BarcodeScanModalWithRedirectProps) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const { originalBrandCode, customizable, thumbnails, model, UPC } = productInfo
  const formattedBrand = getCustomizableBrand(originalBrandCode)
  const isOakley = isOOBrand(formattedBrand)

  const title = t(isOakley ? 'BarCodeScanModal.OOTitle' : 'BarCodeScanModal.RayBanTitle')
  const description = t(
    isOakley ? 'BarCodeScanModal.OakleyDescriptionUpc' : 'BarCodeScanModal.RayBanDescriptionUpc'
  )
  const url = getCustomProductRoute({ basePath, brand: formattedBrand, model, UPC })

  const { sendCloseModalEvent } = useSendBarcodeAvailableScannedCustomAnalyticsEvent(
    visible,
    isOakley
  )

  const handleOnCancel = useCallback(() => {
    sendCloseModalEvent()
    onCancel()
  }, [onCancel, sendCloseModalEvent])

  return (
    <BarcodeScanModalBase
      visible={visible}
      title={title}
      description={description}
      customizable={customizable}
      formattedBrand={formattedBrand}
      thumbnails={thumbnails}
      isCapitalizedTitle={customizable}
      onCancel={handleOnCancel}
      footerContent={[
        <SecondaryButton fullWidth key="cancel" onClick={handleOnCancel}>
          {t('BarCodeScanModal.CancelBtn')}
        </SecondaryButton>,
        <PrimaryButton
          fullWidth
          key="customize"
          onClick={() => onRedirect(url)}
          dataAttrs={{
            id: isOakley ? 'BarcodeResultOOCustom_Customize' : 'BarcodeResultRBCustom_Customize',
          }}
        >
          {t('BarCodeScanModal.ConfirmBtn')}
        </PrimaryButton>,
      ]}
    />
  )
}
