import { altNavigation } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const PageLoading = styled.div<{ fullscreen: boolean; zIndex?: number }>`
  z-index: ${({ zIndex }) => (zIndex !== undefined ? zIndex : 2)};
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  ${({ fullscreen }) => fullscreen && 'min-block-size: 100vmax;'}

  ${altNavigation(`
    inset-block-start: ${pxToRem(1076)};
    min-block-size: auto;
  `)}
`
