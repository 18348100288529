import { IconProps } from '@abstractTypes/icons'

export const BinIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61709)">
        <path
          d="M24 3.25H19.82H16.6665V1.25C16.6665 0.6975 16.219 0.25 15.6665 0.25H8.3335C7.781 0.25 7.3335 0.6975 7.3335 1.25V3.25H4.18H0V4.25H3.1855C3.1855 4.2835 3.1815 4.3155 3.185 4.3495L4.986 22.359C5.0935 23.4325 5.9965 24.25 7.0755 24.25H16.9245C18.0035 24.25 18.9065 23.4325 19.014 22.359L20.815 4.3495C20.8185 4.3155 20.8145 4.2835 20.8145 4.25H24V3.25ZM8.3335 1.25H15.667V3.25H8.3335V1.25ZM18.019 22.2595C17.9625 22.824 17.492 23.25 16.9245 23.25H7.0755C6.508 23.25 6.0375 22.824 5.981 22.2595L4.18 4.25H8.3335H15.667H19.8205L18.019 22.2595Z"
          fill="#333333"
        />
        <path d="M12.5 6.25H11.5V21.25H12.5V6.25Z" fill="#333333" />
        <path
          d="M15.9997 6.21539L15 21.2158L15.9978 21.2823L16.9975 6.28189L15.9997 6.21539Z"
          fill="#333333"
        />
        <path
          d="M7.9978 6.2167L7 6.2832L7.99973 21.2836L8.99753 21.2171L7.9978 6.2167Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61709">
          <rect width={24} height={24} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
