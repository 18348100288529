import { Device } from '@abstractTypes/utils'
import { altNavigation, altNavigationZoom, landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const EmptyWishlistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  inline-size: 100%;
  margin-block-start: ${pxToRem(245)};

  ${tablet(`
    margin-block-start: ${clampValues(239, 320)};
  `)}
  ${landscape(`
    margin-block-start: ${pxToRem(125)};
  `)}
  ${landscape(
    Device.tablet,
    `
    margin-block-start: ${clampValuesLandscape(136, 182)};
  `
  )}
  ${altNavigation(`
    margin-block-start: ${pxToRem(100)};
  `)}
  ${altNavigationZoom(
    [175, 200],
    `
    margin-block-start: 0;
  `
  )}

  h5 {
    margin-block-start: ${pxToRem(8)};
  }
`

export const EmptyWishlistIcon = styled.div`
  margin-block-end: ${pxToRem(42)};
`

export const ProductItem = styled.div`
  position: relative;
`

export const LoveHateBtnContainer = styled.div`
  position: absolute;
  inset-inline-start: 0;
  padding-block-start: 62.4%;
  inline-size: 100%;
`

export const CustomerOrderModalWrapper = styled.div`
  margin-inline-start: auto;
`
