import { useMemo } from 'react'
import { StockItem } from '@abstractTypes/realTimeCheck'
import { isCurrentProductInStock } from '@libs/utils'
import { useStoreContext } from './useStoreContext'

export const useIsCurrentProductInStock = (
  stockItems: StockItem[],
  upc: string,
  shouldAllVariantsBeAvailable?: boolean
): boolean => {
  const store = useStoreContext()

  return useMemo(() => {
    if (!store?.pdpRealtimeCheckEnabled) return true
    return isCurrentProductInStock(stockItems, upc, shouldAllVariantsBeAvailable)
  }, [store, stockItems, upc, shouldAllVariantsBeAvailable])
}
