import { Product } from '@abstractTypes/product'

export const TOGGLE_ITEM_TO_WISHLIST = 'wishlist/TOGGLE_ITEM_TO_WISHLIST'
export const TEMP_TOGGLE_ITEM_TO_WISHLIST = 'wishlist/TEMP_TOGGLE_ITEM_TO_WISHLIST'
export const TOGGLE_ALL_TEMP_ITEMS_TO_WISHLIST = 'wishlist/TOGGLE_ALL_TEMP_ITEMS_TO_WISHLIST'
export const REMOVE_ITEM_FROM_WISHLIST = 'wishlist/REMOVE_ITEM_FROM_WISHLIST'
export const ADD_ITEM_TO_WISHLIST = 'wishlist/ADD_ITEM_TO_WISHLIST'
export const HIDE_TOOLTIP = 'wishlist/HIDE_TOOLTIP'

export interface ToggleItemAction {
  type: typeof TOGGLE_ITEM_TO_WISHLIST | typeof TEMP_TOGGLE_ITEM_TO_WISHLIST
  payload: {
    product: Product
  }
}

export interface ToggleAllTempItemAction {
  type: typeof TOGGLE_ALL_TEMP_ITEMS_TO_WISHLIST
}

export interface HideTooltipAction {
  type: typeof HIDE_TOOLTIP
}

export const toggleItemToWishlist = (product: Product): ToggleItemAction => {
  return {
    type: TOGGLE_ITEM_TO_WISHLIST,
    payload: {
      product,
    },
  }
}

export const tempToggleItemToWishlist = (product: Product): ToggleItemAction => {
  return {
    type: TEMP_TOGGLE_ITEM_TO_WISHLIST,
    payload: {
      product,
    },
  }
}

export const toggleAllTempItemsToWishlist = (): ToggleAllTempItemAction => {
  return {
    type: TOGGLE_ALL_TEMP_ITEMS_TO_WISHLIST,
  }
}

export interface AddItemAction {
  type: typeof ADD_ITEM_TO_WISHLIST
  payload: {
    product: Product
  }
}
export const addItemToWishlist = (product: Product): AddItemAction => {
  return {
    type: ADD_ITEM_TO_WISHLIST,
    payload: {
      product,
    },
  }
}

export interface RemoveItemAction {
  type: typeof REMOVE_ITEM_FROM_WISHLIST
  payload: {
    product: Product
  }
}
export const removeItemFromWishlist = (product: Product): RemoveItemAction => {
  return {
    type: REMOVE_ITEM_FROM_WISHLIST,
    payload: {
      product,
    },
  }
}

export const hideTooltip: HideTooltipAction = {
  type: HIDE_TOOLTIP,
}

export type WishlistActionTypes =
  | ToggleItemAction
  | RemoveItemAction
  | AddItemAction
  | HideTooltipAction
  | ToggleAllTempItemAction
