import { IconProps } from '@abstractTypes/icons'

export const RectangleIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 0.400391H28C26.895 0.400391 26 1.29539 26 2.40039V3.36439C24.711 2.92039 23.288 2.92039 22 3.36439V2.40039C22 1.29539 21.105 0.400391 20 0.400391H2C0.895 0.400391 0 1.29539 0 2.40039V11.6004C0 12.7054 0.895 13.6004 2 13.6004H20C21.105 13.6004 22 12.7054 22 11.6004V5.54939C23.223 4.85039 24.777 4.85039 26 5.55039V11.6004C26 12.7054 26.895 13.6004 28 13.6004H46C47.105 13.6004 48 12.7054 48 11.6004V2.40039C48 1.29539 47.105 0.400391 46 0.400391ZM20 11.6004H2V2.40039H20V11.6004ZM28 11.6004H46V2.40039H28V11.6004Z"
        fill={fill}
      />
    </svg>
  )
}
