import { SUPERNOVA_BRAND } from '@abstractTypes/brand'
import { QueryProductsArgs } from '@abstractTypes/graphqlTypes'
import config from '@config/index'
import useEnrichExcludeFilters from '@hooks/useEnrichExcludeFilters'
import { useStoreContext } from '@hooks/useStoreContext'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useFormatSupernovaQueryPayload = () => {
  const { storeId, langCode } = useStoreContext()
  const customerOrderType = useSelector(s => s.customerOrder.orderType)
  const isCompletePair = customerOrderType === 'COMPLETE_PAIR'

  const enrichedExcludeFilters = useEnrichExcludeFilters()

  const queryPayload = useMemo(() => {
    const data: QueryProductsArgs['data'] = {
      filters: {
        brand: [SUPERNOVA_BRAND],
        isSupernova: true,
        ...(isCompletePair && { roxable: true }),
        store: storeId,
        lang: langCode,
        excludeFilters: enrichedExcludeFilters,
      },
      rows: config.itemsPerPage,
      start: 0,
    }
    return data
  }, [isCompletePair, storeId, enrichedExcludeFilters, langCode])

  return queryPayload
}

export default useFormatSupernovaQueryPayload
