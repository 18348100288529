import { throttle } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

const KEY = 'useScrollRestoration-store'
const LISTENER_INTERVAL = 400

interface ScrollInfo {
  top: number
  scrollHeight: number
}

const noop = (): void => {}

function createKey() {
  return Math.random().toString(36).substring(2, 10)
}

export interface ScrollRestorationContextData {
  onScrollListener: (event: React.UIEvent<HTMLElement>) => void
  connectRef: (ref: HTMLElement | null) => void
}

export const ScrollRestorationContext = React.createContext<
  ScrollRestorationContextData | undefined
>(undefined)

export const ScrollRestorationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { key: locationKey, pathname } = useLocation()
  const tracked = useRef<HTMLElement | null>(null)
  const mainElementResizeObserver = useRef<MutationObserver | null>(null)
  const childrenResizeObservers = useRef<
    {
      observer: ResizeObserver
      node: ChildNode
    }[]
  >([])
  const updateTimer = useRef<NodeJS.Timeout>()
  const cleanUp = useRef(noop)
  const scrollsInfo = useRef<Record<string, ScrollInfo>>({})
  const [prevKey, setPrevKey] = useState<string | undefined>(undefined)
  const [internalKey, setInternalKey] = useState<string | undefined>(undefined)

  const scrollInfo = useMemo(
    () => scrollsInfo.current[internalKey ?? ''],
    [scrollsInfo, internalKey]
  )

  useEffect(() => {
    if (locationKey) {
      setInternalKey(locationKey)
    } else if (pathname) {
      const newKey = createKey()
      setInternalKey(newKey)
      window.history.replaceState(
        {
          key: newKey,
        },
        ''
      )
    } else {
      undefined
    }
  }, [locationKey, pathname])

  const onScrollListener = (event: React.UIEvent<HTMLElement>) => {
    throttle(() => {
      const { scrollTop, scrollHeight } = event.currentTarget
      store({ top: scrollTop, scrollHeight })
    }, LISTENER_INTERVAL)()
  }

  const disconnect = (): void => {
    cleanUp.current()
  }

  const store = ({ top, scrollHeight }: ScrollInfo): void => {
    scrollsInfo.current[internalKey ?? ''] = {
      top: top ?? 0,
      scrollHeight: scrollHeight ?? 0,
    }
    updateTimer.current && clearTimeout(updateTimer.current)
    updateTimer.current = setTimeout(() => {
      sessionStorage.setItem(KEY, JSON.stringify(scrollsInfo.current))
    }, LISTENER_INTERVAL)
  }

  const isChildObserved = useCallback(
    (childNode: ChildNode) =>
      childrenResizeObservers.current.some(({ node }) => childNode.isSameNode(node)),
    []
  )

  const scrollPage = useCallback(() => {
    if (!tracked.current) return
    const shouldScroll = tracked.current.scrollTop <= scrollInfo.top
    if (shouldScroll) {
      tracked.current.scrollTo({ top: scrollInfo.top, behavior: 'smooth' })
    }
  }, [scrollInfo])

  const registerChildren = useCallback(() => {
    if (!tracked.current) return
    tracked.current.childNodes.forEach((child, index) => {
      if (!isChildObserved(child)) {
        const resizeObserver = new ResizeObserver(scrollPage)
        const element = tracked.current?.children.item(index)
        element && resizeObserver.observe(element)
        childrenResizeObservers.current.push({
          observer: resizeObserver,
          node: child,
        })
      }
    })
  }, [isChildObserved, scrollPage])

  const mutationCallback = useCallback(() => {
    if (!tracked.current) return
    scrollPage()
    const shouldDisconnect = tracked.current.scrollHeight >= scrollInfo.scrollHeight
    shouldDisconnect && disconnect()
    if (!shouldDisconnect && tracked.current.hasChildNodes()) {
      registerChildren()
    }
  }, [scrollInfo, scrollPage, registerChildren])

  const performScroll = useCallback(() => {
    if (!scrollInfo || !tracked.current) return
    mainElementResizeObserver.current = new MutationObserver(mutationCallback)
    mainElementResizeObserver.current?.observe(tracked.current, { childList: true, subtree: true })
    cleanUp.current = () => {
      mainElementResizeObserver.current?.disconnect()
      mainElementResizeObserver.current = null
      childrenResizeObservers.current.forEach(({ observer }) => observer.disconnect())
      childrenResizeObservers.current = []
      cleanUp.current = noop
    }
  }, [scrollInfo, mutationCallback])

  useEffect(() => {
    if (internalKey !== prevKey) {
      disconnect()
      setPrevKey(undefined)
    }
  }, [internalKey, prevKey])

  const connect = useCallback(
    (ref: HTMLElement | null): void => {
      tracked.current = ref
      if (tracked.current && internalKey !== prevKey) {
        setPrevKey(internalKey)
        performScroll()
      }
    },
    [internalKey, prevKey, performScroll]
  )

  const connectRef = useCallback(connect, [internalKey, connect])

  useEffect(() => {
    try {
      scrollsInfo.current = JSON.parse(sessionStorage.getItem(KEY) ?? '{}')
    } catch (e) {
      console.error('[useScrollRestoration] Error connecting to sessionStorage')
    }
  }, [])

  useEffect(() => {
    if (tracked.current) {
      connectRef(tracked.current)
    }
  }, [connectRef])

  return (
    <ScrollRestorationContext.Provider
      value={{
        connectRef,
        onScrollListener,
      }}
    >
      {children}
    </ScrollRestorationContext.Provider>
  )
}
