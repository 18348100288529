import { IconProps } from '@abstractTypes/icons'

export const MixAndMatch = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 46"
      fill={fill}
      stroke={stroke}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26413 15.723L9.18613 16.276C9.97213 13.544 12.1281 8.832 17.7341 7.974L17.4311 5.997C12.5321 6.746 8.82713 10.291 7.26413 15.723Z"
        fill="#333333"
      />
      <path
        d="M24.8231 0C21.4781 0 18.0571 0.238 14.7531 0.682C-2.72187 3.376 -3.29087 31.631 8.49213 41.087C11.0121 43.09 13.9411 44 17.0501 44C21.2131 44 25.6991 42.369 29.9701 39.552C29.9691 39.498 29.9621 39.446 29.9621 39.393C29.9621 38.946 29.9681 38.183 30.3501 36.844C25.8611 40.154 21.1781 42 17.0501 42C14.2661 42 11.8061 41.166 9.74313 39.528C3.70413 34.681 0.911131 23.645 3.38613 14.404C4.42413 10.529 7.32013 3.852 15.0201 2.664C18.2981 2.224 21.5961 2 24.8231 2C34.7331 2 42.3501 4.138 45.1981 7.72C46.4261 9.264 46.7811 11.081 46.2811 13.272C45.2961 17.021 43.7371 20.689 41.7061 24.116C42.3791 24.24 43.0231 24.464 43.6101 24.795C45.6071 21.365 47.2051 17.64 48.2241 13.75C50.5041 3.876 38.3091 0 24.8231 0Z"
        fill="#333333"
      />
      <path
        d="M40.5691 28C39.8521 28 39.1361 28.345 38.7041 29.034C36.7501 32.154 33.9621 37.021 33.9621 39.392C33.9621 43.041 36.9201 46 40.5701 46C44.2191 46 47.1781 43.042 47.1781 39.392C47.1781 37.021 44.3901 32.154 42.4361 29.034C42.0021 28.345 41.2851 28 40.5691 28ZM40.5691 44V30C40.6791 30 40.7211 30.067 40.7391 30.096C44.0111 35.32 45.1761 38.323 45.1761 39.392C45.1761 41.933 43.1091 44 40.5691 44Z"
        fill="#333333"
      />
    </svg>
  )
}
