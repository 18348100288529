export enum ToastTypeEnum {
  success,
  error,
  warn,
  info,
}
export type ToastType = keyof typeof ToastTypeEnum
export interface ToastData {
  id: string
  message: string
  duration?: number
  type?: ToastType
}
export type AddToast = (message: string, duration?: number) => void
export type ToastNotificationService = {
  [key in ToastType]: AddToast
}
