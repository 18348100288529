import {
  BarcodeActionTypes,
  RESET_BARCODE_MODAL_INFO,
  SET_BARCODE_MODAL_INFO,
  SET_BARCODE_MODAL_INITIAL_QUERY,
} from '@actions/barcode'
import { BarcodeModalInfo } from '@components/BarCodeScanModal/types'

export interface BarcodeModalInfoState {
  scenario: BarcodeModalInfo['scenario'] | null
  productInfo: BarcodeModalInfo['productInfo'] | null
  similarStylesProducts: BarcodeModalInfo['similarStylesProducts'] | null
  initialQuery: string
}

export const initialState: BarcodeModalInfoState = {
  productInfo: null,
  scenario: null,
  initialQuery: '',
  similarStylesProducts: null,
}

export default (state = initialState, action: BarcodeActionTypes) => {
  switch (action.type) {
    case SET_BARCODE_MODAL_INFO: {
      const { payload } = action
      return {
        ...state,
        scenario: payload.scenario,
        productInfo: payload.productInfo,
        similarStylesProducts: payload.similarStylesProducts,
      }
    }
    case RESET_BARCODE_MODAL_INFO: {
      return initialState
    }
    case SET_BARCODE_MODAL_INITIAL_QUERY: {
      return { ...state, initialQuery: action.payload }
    }

    default:
      return state
  }
}
