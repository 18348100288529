import { clampValues, pxToRem } from '@libs/styled'

export const landscapeTowerSliderWidth = '87vh'
export const landscapeTowerSliderHeight = '90vh'

export const landscapeTabletSliderWidth = '86vh'
export const landscapeTabletSliderHeight = '86vh'

export const landscapeTabletMiniSliderWidth = '83vh'
export const landscapeTabletMiniSliderHeight = '83vh'

export const portraitTowerSliderHeight = pxToRem(1280)
export const portraitTowerSliderHeightReduced = pxToRem(534)

export const altNavigationSliderHeight = pxToRem(529)

export const portraitTabletSliderHeight = clampValues(666, 921)
export const portraitTabletSliderHeightReduced = clampValues(308, 742)

export const animationDurationSeconds = 0.8
export const animationDuration = `${animationDurationSeconds}s`
