import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const opsmTheme: ThemeOverride = {
  colors: {
    primaryDark: '#2D2A26',
    primaryDarkPressed: '#47423C',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#F7F6F4',
    inactive: '#BBBBBB',
    outline: '#E5E5E5',
    background: '#F2F2F2',
    highlight01: '#2D2A26',
    highlight01Pressed: '#47423C',
    highlight02: '#B58B34',
    highlight02Pressed: '#A88335',
    highlight03: '#2d2a26',
    highlight04: '#B58B34',
    functional01: '#e1523e',
    functional02: '#428850',
    functional03: '#3D87CB',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'Termina',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 85,
        tablet: 68,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 109,
        tablet: 86,
        tabletMini: 68,
      },
    },
    heading1: {
      fontFamily: 'Termina',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 45,
        tablet: 42,
        tabletMini: 36,
      },
      lineHeight: {
        tower: 50,
        tablet: 44,
        tabletMini: 44,
      },
    },
    heading2: {
      fontFamily: 'Termina',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 35,
        tablet: 36,
        tabletMini: 30,
      },
      lineHeight: {
        tower: 39,
        tablet: 38,
        tabletMini: 38,
      },
    },
    heading3: {
      fontFamily: 'Barlow',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 38,
        tablet: 36,
        tabletMini: 30,
      },
      lineHeight: {
        tower: 48,
        tablet: 44,
        tabletMini: 40,
      },
    },
    heading4: {
      fontFamily: 'Barlow',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 30,
        tablet: 28,
        tabletMini: 24,
      },
      lineHeight: {
        tower: 40,
        tablet: 38,
        tabletMini: 34,
      },
    },
    heading5: {
      fontFamily: 'Barlow',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 22,
        tablet: 22,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 33,
        tablet: 28,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'Barlow',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 22,
        tabletMini: 22,
      },
    },
    label: {
      fontFamily: 'Barlow',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 16,
        tablet: 14,
        tabletMini: 14,
      },
      lineHeight: {
        tower: 22,
        tablet: 20,
        tabletMini: 20,
      },
    },
    cta: {
      fontFamily: 'Barlow',
      weight: {
        regular: 500,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 20,
        tablet: 20,
        tabletMini: 20,
      },
    },
    link: {
      fontFamily: 'Barlow',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 30,
        tablet: 28,
        tabletMini: 24,
      },
      lineHeight: {
        tower: 34,
        tablet: 34,
        tabletMini: 34,
      },
    },
    caption: {
      fontFamily: 'Barlow',
      weight: {
        regular: 600,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 16,
        tablet: 16,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'rounded',
      borderRadius: 64,
      uppercase: true,
    },
    rangeSlider: {
      background: '#BBBBBB', //inactive
      foreground: '#2D2A26', //highlight01
      thumb: '#F2F2F2', //background
      thumbBorder: '#BBBBBB', //inactive
    },
  },
}
