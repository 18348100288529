import { CardProductContainerStyled } from '@components/CardProductBase'
import { ProductCard, ProductItem } from '@components/FrameAdvisor/FrameAdvisorProductCard/styles'
import { useProductInfoConfigFrameAdv } from '@hooks/tiles'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { landscape, zoomLandscape } from '@libs/media'
import { pxToRem } from '@libs/styled'
import { dataDescriptionForProduct } from '@libs/utils'
import React from 'react'
import styled from 'styled-components'
import { FrameAdvisorProductCardProps } from './types'

const ProductCardStyled = styled(ProductItem)<{ isDressedOnView: boolean }>`
  block-size: max-content;
  ${({ isDressedOnView }) =>
    isDressedOnView &&
    `
    background-color: #fff;
    padding: ${pxToRem(8)};
    position: relative;
  `}
  ${zoomLandscape(
    [150, 175],
    `
    inline-size: 100%;
  `
  )}

  ${ProductCard} {
    ${({ isDressedOnView }) =>
      isDressedOnView &&
      landscape(`
        inline-size: 100%;
      `)}
    ${({ isDressedOnView }) =>
      isDressedOnView &&
      zoomLandscape(
        [150, 175, 200],
        `
        inline-size: 100%;
      `
      )}
  }

  ${CardProductContainerStyled} {
    ${zoomLandscape(
      [150, 175],
      `
      inline-size: 100%;
    `
    )}
  }
`

const FrameAdvisorProductCard: React.FC<FrameAdvisorProductCardProps> = ({
  className,
  dynamicSentence,
  dataAttrs,
  product,
  playlistId,
  index = 0,
  span = 12,
  gridColumnStart,
  isDressedOnView,
  dressedOnImageUrl,
}) => {
  const analyticsDataDescription = dataDescriptionForProduct(product)
  const getProductInfoConfig = useProductInfoConfigFrameAdv()
  const productInfoConfig = getProductInfoConfig(product.brand.id)
  const isBestMatch = product.isExtended
  const isBestMatchProductView = isBestMatch && !isDressedOnView
  const { zoomLevel } = useNavigationContext()

  return (
    <ProductCardStyled
      data-element-id={`Tiles_Tile${index}-Img`}
      data-test={`Tiles_${index}-Img`}
      span={span}
      isBestMatch={isBestMatchProductView}
      gridColumnStart={gridColumnStart}
      isDressedOnView={isDressedOnView}
    >
      <ProductCard
        className={className}
        product={product}
        dataAttrs={{ description: analyticsDataDescription, ...dataAttrs }}
        additionalQueryStringAttributes={{ fromFrameAdvisor: true }}
        dressedOnImageUrl={isDressedOnView ? dressedOnImageUrl : undefined}
        showCustomizableIcon={!isDressedOnView}
        showRTRIcon={!isDressedOnView}
        showVTOIcon={!isDressedOnView}
        showIcons
        showLoveHateBtns
        showAddBuyBtn={isDressedOnView}
        playlistId={playlistId || ''}
        index={index}
        productInfoConfig={productInfoConfig}
        isBestMatch={isBestMatch}
        size={zoomLevel >= 150 ? 'extended' : 'standard'}
        isDressedOnView={isDressedOnView}
        bestMatchInfo={
          dynamicSentence
            ? {
                dynamicSentence,
              }
            : undefined
        }
      />
    </ProductCardStyled>
  )
}

export default FrameAdvisorProductCard
