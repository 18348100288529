import { PRICE_STYLE_RANGE, ProductCardBase } from '@components/CardProductBase'
import { dataDescriptionForProduct } from '@libs/utils'
import { useNavigationContext } from '@hooks/useNavigationContext'
import React from 'react'
import { StandardProductCardProps } from './types'

const StandardProductCard: React.FC<StandardProductCardProps> = ({
  className,
  dataAttrs,
  product,
  showCustomizableIcon,
  showEngravingIcon = false,
  showRange,
  showRTRIcon = false,
  showVTOIcon = false,
  size,
  productInfoConfig,
}) => {
  const analyticsDataDescription = dataDescriptionForProduct(product)

  const { zoomLevel } = useNavigationContext()

  return (
    <ProductCardBase
      className={className}
      product={product}
      priceStyle={showRange ? PRICE_STYLE_RANGE : undefined}
      dataAttrs={{ description: analyticsDataDescription, ...dataAttrs }}
      showCustomizableIcon={showCustomizableIcon}
      showRTRIcon={showRTRIcon}
      showVTOIcon={showVTOIcon}
      showIcons
      showWishlistBtn
      showEngravingIcon={showEngravingIcon}
      size={zoomLevel >= 150 ? 'extended' : size}
      productInfoConfig={productInfoConfig}
    />
  )
}

export default StandardProductCard
