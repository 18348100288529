import { IconProps } from '@abstractTypes/icons'

export const GlassShape9Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 0.25C32.12 0.25 27.977 3.447 26.542 7.855C24.947 7.135 23.054 7.135 21.458 7.856C20.023 3.447 15.88 0.25 11 0.25C4.935 0.25 0 5.185 0 11.25C0 17.315 4.935 22.25 11 22.25C17.065 22.25 22 17.315 22 11.25C22 10.779 21.96 10.317 21.902 9.861C23.165 9.099 24.836 9.098 26.097 9.86C26.04 10.316 26 10.778 26 11.25C26 17.315 30.935 22.25 37 22.25C43.065 22.25 48 17.315 48 11.25C48 5.185 43.065 0.25 37 0.25ZM11 20.25C6.038 20.25 2 16.213 2 11.25C2 6.287 6.038 2.25 11 2.25C15.962 2.25 20 6.287 20 11.25C20 16.213 15.962 20.25 11 20.25ZM28 11.25C28 16.213 32.038 20.25 37 20.25C41.962 20.25 46 16.213 46 11.25C46 6.287 41.962 2.25 37 2.25C32.038 2.25 28 6.287 28 11.25Z"
        fill="#333333"
      />
    </svg>
  )
}
