import { Device } from '@abstractTypes/utils'
import { CardBodyStyled, NameStyled, ProductCardBase } from '@components/CardProductBase'
import { ProductPreviewWrapper } from '@components/CardProductBase/ProductCard'
import { Col } from '@components/core/Grid'
import { Text } from '@components/core/Typography'
import { landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

// background-color has to be hardcoded because on the product image we get the background color from the image provider
export const extendedProductItem = css`
  display: flex;
  column-gap: ${pxToRem(30)};
  background-color: #f6f6f6;
`

export const hideElement = css`
  position: absolute;
  margin: 0;
  padding: 0;
  inline-size: 1px;
  block-size: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  opacity: 0;
`

// the formula on grid-column is to dynamically handle the logic to let the extended
// to occupy the double space
export const ProductItem = styled(Col)<{ isBestMatch?: boolean; gridColumnStart?: number }>`
  ${({ isBestMatch }) => isBestMatch && extendedProductItem}

  ${({ isBestMatch, gridColumnStart }) =>
    isBestMatch &&
    gridColumnStart !== undefined &&
    `
    grid-column: span 2;
  `}

  ${zoomPortrait(
    [150, 175, 200],
    `
      grid-column: auto;
      inline-size: ${pxToRem(848)};
      flex-direction: column;
`
  )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    display: flex;
    align-items: center;
  `
  )}

  &.hidden {
    ${hideElement}
  }
`

export const ProductCard = styled(ProductCardBase)`
  margin: 0;
  flex-grow: 1;
  position: relative;
  ${({ isBestMatch, isDressedOnView }) => isBestMatch && !isDressedOnView && extendedProductCard}
`

export const ExtendedDetailsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${NameStyled} {
    flex-basis: 100%;
  }
`

const extendedProductCard = css`
  inline-size: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  flex-direction: row;
  gap: ${pxToRem(16)};
  padding: ${pxToRem(16)};
  ${landscape(Device.tower, `inline-size: 100%;`)}
  ${zoomPortrait(
    [150, 175, 200],
    `
    flex-direction: column;
  `
  )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    flex-direction: column;
  `
  )}

  ${ProductPreviewWrapper} {
    flex: 1;
  }

  ${CardBodyStyled} {
    flex: 1;
    padding-inline-end: ${pxToRem(16)};
  }
`

export const LoveHateBtnWrapper = styled.div`
  z-index: 2;
  position: absolute;
  inset-inline-end: 0;
  padding: 1rem;
  inset-block-start: 90%;
  inset-inline-start: 50%;
  display: flex;

  ${landscape(`
    padding-block: 1rem ;
    padding-inline:  2rem;
  `)}

  button {
    flex-direction: column;
  }
`

export const RemoveBtnWrapper = styled.div`
  z-index: 2;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  padding: 1.3636rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const LoaderWrapper = styled.div`
  z-index: 2;
  position: absolute;
  inset-block-start: 1rem;
  inset-inline-end: 1rem;
  inset-block-end: 4.5rem;
  inset-inline-start: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
`

export const MessageContainer = styled.div`
  position: relative;
  block-size: 100%;
  display: flex;
  justify-content: space-between;
`

export const MessageTitle = styled(Text)`
  display: flex;

  align-items: center;
  justify-content: center;
  block-size: 100%;
  inline-size: 85%;
  padding-block: ${pxToRem(20)} ${pxToRem(20)};
  padding-inline: ${pxToRem(32)} ${pxToRem(8)};
`

export const MessageCTA = styled(Text)`
  inline-size: auto;
  display: flex;
  padding-block: 0;
  padding-inline: 0.5rem;
  align-items: center;
  justify-content: space-between;
  border-inline-start: 1px solid #fff;
  margin-block: 0;
  margin-inline: auto;
  cursor: pointer;
`
export const IconContainer = styled.div`
  inline-size: ${pxToRem(32)};
  block-size: ${pxToRem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: ${pxToRem(6)};
  margin-inline-start: ${pxToRem(16)};
  ${tablet(`
    inline-size: ${pxToRem(24)};
    block-size: ${pxToRem(24)};
  `)}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(48)};
    block-size: ${pxToRem(48)};
    margin-inline-end: ${pxToRem(9)};
    margin-inline-start: ${pxToRem(24)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(64)};
    block-size: ${pxToRem(64)};
    margin-inline-end: ${pxToRem(12)};
    margin-inline-start: ${pxToRem(32)};
  `
  )}
`

export const BestMatchWrapper = styled.div`
  margin-block: ${pxToRem(20)};
  margin-inline: 0;

  ${tablet(`margin-block: ${clampValues(18, 24)};`)}
  ${landscape(Device.tablet, `margin-block: ${clampValuesLandscape(12, 24)};`)}
`
