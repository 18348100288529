import { LinkTypographyProps, TypographyProps } from '@abstractTypes/typography'
import { generateDefaultFontProperties } from '@libs/typography'
import styled from 'styled-components'

export const NuanceHeading0 = styled.h1<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.heading0, { ...props }, 'nuance')}
  text-transform: uppercase;
`

export const NuanceHeading1 = styled.h1<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.heading1, { ...props }, 'nuance')}
  text-transform: uppercase;
`

export const NuanceHeading2 = styled.h2<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.heading2, { ...props }, 'nuance')}
  text-transform: uppercase;
`

export const NuanceHeading3 = styled.h3<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.heading3, { ...props }, 'nuance')}
  text-transform: capitalize;

  strong {
    font-weight: ${({ theme }) => theme.nuance.fonts.heading3.weight.bold};
  }
`

export const NuanceHeading4 = styled.h4<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.heading4, { ...props }, 'nuance')}
  text-transform: capitalize;

  strong {
    font-weight: ${({ theme }) => theme.nuance.fonts.heading4.weight.bold};
  }
`

export const NuanceHeading5 = styled.h5<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.heading5, { ...props }, 'nuance')}

  strong {
    font-weight: ${({ theme }) => theme.nuance.fonts.heading5.weight.bold};
  }
`

export const NuanceListTypography = styled.li<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.label, { ...props }, 'nuance')}
`

export const NuanceLabel = styled.label<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.label, { ...props }, 'nuance')}
`

export const NuanceText = styled.p<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.body, { ...props }, 'nuance')}

  strong {
    font-weight: ${({ theme }) => theme.nuance.fonts.body.weight.bold};
  }
`

export const NuanceCaption = styled.p<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.caption, { ...props }, 'nuance')}
`

export const NuanceLinkTypography = styled.a<LinkTypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.link, { ...props }, 'nuance')}
  text-decoration: ${({ removeUnderline }) => (removeUnderline ? 'none' : 'underline')};
`

export const NuanceButtonTypography = styled.button<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.nuance.fonts.cta, { ...props }, 'nuance')}

  strong {
    font-weight: ${({ theme }) => theme.nuance.fonts.cta.weight.bold};
  }
`
