import { ProductSubdivisions } from '@abstractTypes/filter'
import { QueryProductsArgs } from '@abstractTypes/graphqlTypes'
import config from '@config/index'
import { useAvailableInStoreProductSubdivision } from '@hooks/useAvailableInStore'
import useEnrichExcludeFilters from '@hooks/useEnrichExcludeFilters'
import { useProductSubdivisionFilters } from '@hooks/useFilters'
import { useStoreContext } from '@hooks/useStoreContext'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useFormatSearchProductsQueryPayload = (
  query: string,
  productSubdivision?: ProductSubdivisions
): QueryProductsArgs['data'] => {
  const { storeId, langCode } = useStoreContext()
  const { availableInStore } = useAvailableInStoreProductSubdivision(productSubdivision, true)
  const customerOrderType = useSelector(s => s.customerOrder.orderType)
  const isCompletePair = customerOrderType === 'COMPLETE_PAIR'
  const enrichedExcludeFilters = useEnrichExcludeFilters()
  const productSubdivisionFilters = useProductSubdivisionFilters(productSubdivision)

  return useMemo(() => {
    const data: QueryProductsArgs['data'] = {
      filters: {
        ...productSubdivisionFilters,
        ...(isCompletePair && { roxable: true }),
        store: storeId,
        lang: langCode,
        excludeFilters: enrichedExcludeFilters,
        query: query.toLowerCase(),
        storeAvailable: availableInStore,
      },
      rows: config.itemsPerPage,
      start: 0,
      splitGroupWhenOnlyOneFound: false,
    }
    return data
  }, [
    isCompletePair,
    productSubdivisionFilters,
    storeId,
    langCode,
    enrichedExcludeFilters,
    query,
    availableInStore,
  ])
}

export default useFormatSearchProductsQueryPayload
