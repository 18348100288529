import { ActiveFilters } from '@abstractTypes/analytics'
import { CustomerOrderType } from '@abstractTypes/common'
import {
  DEFAULT_FILTERS,
  DefaultFilter,
  DefaultFilters,
  FILTER_TYPE_FLAG,
  FilterFacet,
  FilterKind,
  FilterOption,
  FilterRange,
  FilterRangePrice,
  FilterState,
  FiltersFromUrl,
  FiltersFromUrlType,
  FiltersState,
  FormattedFilters,
  MultiRangeFiltersKind,
  ProductHierarchies,
  ProductSubdivisions,
  ProductTypeValues,
  RangeFiltersKind,
} from '@abstractTypes/filter'
import { clearPreselectedFilters, setFiltersCount, setIsDefaultPlp } from '@actions/ui'
import config from '@config/index'
import { PRODUCT_TYPE } from '@constants/product'
import { formatFilterValue, removeFiltersToIgnore } from '@libs/filters'
import { objectKeys } from '@libs/object'
import { getFiltersFromUrl } from '@libs/url'
import { toArray } from '@libs/utils'
import { ActiveFilter } from '@providers/filtersProvider'
import { History, Location } from 'history'
import groupBy from 'lodash/groupBy'
import isUndefined from 'lodash/isUndefined'
import qs from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useActions } from './useActions'
import { useAvailableInStoreProductSubdivision } from './useAvailableInStore'
import {
  useGetProductSubdivisionListFromUrl,
  useGetProductTypeListFromUrlRet,
} from './useGetProductSubdivisionFromUrl'
import { usePrevious } from './usePrevious'
import { useStoreContext } from './useStoreContext'

const isInArrayOrEqual = <T>(arrayOrValue: T | T[], value: T) => {
  if (Array.isArray(arrayOrValue)) {
    return arrayOrValue.includes(value)
  }
  return arrayOrValue === value
}

/**
 * Apply filters to the query parameters of the current url location
 *
 * @param filtersFromUrl The filters to be applied on the URL as query params
 * @param history The router's history object
 */
export const applyFilters = (filtersFromUrl: FiltersFromUrl, history: History) => {
  const newSearch = qs.stringify(filtersFromUrl)
  history.replace({ ...window.location, search: newSearch })
}

export const useFiltersFromUrl = () => {
  const {
    location: { search },
  } = useHistory()
  return useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search])
}

export const useActiveLocationFilters = () => {
  const { location } = useHistory()

  return useMemo(() => getFiltersFromUrl(location) as ActiveFilters[] | undefined, [location])
}

// We need this effect to send to bff only possible query params (defined in graphql Query type on BFF side)
const useSanitizedFiltersFromUrl = () => {
  const queryParamsToExclude = ['q', 'fromCustomerOrder', 'comesFromBarcode', 'playlistType']
  const filtersFromUrl = useFiltersFromUrl()

  return useMemo(
    () => removeFiltersToIgnore(filtersFromUrl, queryParamsToExclude) as FiltersFromUrl,
    [filtersFromUrl]
  )
}

interface ToggleUrlFilterObj {
  kind: keyof FiltersFromUrl
  value: string
  replace?: boolean
}

export const useToggleUrlFilter = (obj1?: ToggleUrlFilterObj) => {
  const history = useHistory()

  return useCallback(
    (obj2?: ToggleUrlFilterObj) => {
      const obj = obj2 || obj1 || undefined
      if (!obj) throw new Error('Improper use of useToggleUrlFilter')

      const { kind, value, replace: replaceArg } = obj
      const replace = replaceArg || false

      const {
        location: { search },
      } = history

      const filtersFromUrl = qs.parse(search, { ignoreQueryPrefix: true }) as FiltersFromUrl
      const valuesFromUrl = toArray(filtersFromUrl[kind])

      if (valuesFromUrl.includes(value)) {
        return applyFilters(
          {
            ...filtersFromUrl,
            [kind]: replace ? undefined : valuesFromUrl.filter(v => v !== value),
          },
          history
        )
      }

      return applyFilters(
        {
          ...filtersFromUrl,
          [kind]: replace ? value : [...valuesFromUrl, value],
        },
        history
      )
    },
    [obj1, history]
  )
}

export const useToggleRange = (
  filterRange: FilterRange | FilterRangePrice,
  minKind: RangeFiltersKind | MultiRangeFiltersKind,
  maxKind: RangeFiltersKind | MultiRangeFiltersKind
) => {
  const minKindValue = filterRange.min.toString()
  const maxKindValue = filterRange.max.toString()

  const history = useHistory()

  return useCallback(() => {
    const {
      location: { search },
    } = history

    const filtersFromUrl = qs.parse(search, { ignoreQueryPrefix: true }) as FiltersFromUrl
    const minKindValueFromUrl = toArray(filtersFromUrl[minKind])
    const maxKindValueFromUrl = toArray(filtersFromUrl[maxKind])

    const isMinKindValueAlreadyUsed = minKindValueFromUrl.includes(minKindValue)
    const isMaxKindValueAlreadyUsed = maxKindValueFromUrl.includes(maxKindValue)

    if (isMinKindValueAlreadyUsed && isMaxKindValueAlreadyUsed) {
      filtersFromUrl[minKind] = undefined
      filtersFromUrl[maxKind] = undefined
    } else {
      filtersFromUrl[minKind] = minKindValue
      filtersFromUrl[maxKind] = maxKindValue
    }

    return applyFilters(filtersFromUrl, history)
  }, [minKind, maxKind, history, maxKindValue, minKindValue])
}

export const useIsDefaultPlp = () => {
  const isDefaultPlp = useSelector(s => s.ui.isDefaultPlp)
  const actions = useActions({ setIsDefaultPlp })

  return {
    isDefaultPlp,
    setIsDefaultPlp: actions.setIsDefaultPlp,
  }
}

export const useClearFilter = (preserveFilters: FilterKind[] = []) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setIsDefaultPlp } = useIsDefaultPlp()

  return useCallback(() => {
    const {
      location: { search },
    } = history

    const filtersFromUrl = qs.parse(search, { ignoreQueryPrefix: true }) as FiltersFromUrl
    setIsDefaultPlp(true)
    dispatch(clearPreselectedFilters())
    const preserveFiltersRecord = preserveFilters.reduce(
      (res, filter) => ({
        ...res,
        [filter]: filtersFromUrl[filter],
      }),
      {}
    )

    const { q } = filtersFromUrl
    applyFilters(
      {
        ...preserveFiltersRecord,
        q,
      },
      history
    )
  }, [history, preserveFilters, setIsDefaultPlp])
}

const getEditorialRange = (
  minValue: string,
  maxValue: string,
  range: FilterRange[]
): FilterRange | undefined => {
  const isEditorialRange = !range.some(
    range => minValue === range.min.toString() && maxValue === range.max.toString()
  )

  const max = parseFloat(maxValue)

  if (isEditorialRange) {
    return {
      min: parseFloat(minValue),
      max: isNaN(max) ? 'max' : max,
      optionValues: [minValue, maxValue],
    }
  }

  return undefined
}

const FILTER_FLAG_TRUE_VALUES = ['1', 'true', 'vero', 'TRUE', 'VERO']

export const getFilterFlagTrueOption = (options: FilterOption[]) => {
  try {
    return options?.find(o => FILTER_FLAG_TRUE_VALUES.includes(o.value))
  } catch {
    return null
  }
}

export const getDisableFilter = ({
  orderType,
  filter,
}: {
  filter: FilterState
  orderType: CustomerOrderType
}) => {
  if (orderType === 'COMPLETE_PAIR') {
    const filtersForDisable: FilterKind[] = ['roxable']
    return filtersForDisable.includes(filter.kind)
  }

  if (filter.type === FILTER_TYPE_FLAG) {
    const trueOption = getFilterFlagTrueOption(filter.options)
    const disabled = trueOption?.count === 0 || !trueOption

    if (filter.kind === 'storeAvailable') {
      // fit4You: for case when filter is predifined with 'true' and empty values
      return trueOption?.selected ? false : disabled
    }
    return disabled
  }
  return false
}

/**
 * Generate a filter range with selected and count option based
 * on the url params and the filter options
 *
 * @param defaultFilter The filter to generate a range for
 * @param enabledFilters The enabled filters from the url
 * @param filterOptions The filter options for this filter
 * @returns The filter range transformed with selected and count
 */
const generateFilterRange = (
  defaultFilter: DefaultFilter,
  enabledFilters: FiltersFromUrl,
  filterOptions: FilterOption[]
): FilterRange[] | undefined => {
  const { minKey, maxKey } = defaultFilter
  let { range } = defaultFilter

  if (isUndefined(range) || isUndefined(minKey) || isUndefined(maxKey)) {
    return
  }

  const minValueArray = toArray(enabledFilters[minKey])
  const maxValueArray = toArray(enabledFilters[maxKey])

  range = minValueArray.reduce<FilterRange[]>((newRange, minValue, index) => {
    const editorialRange = getEditorialRange(minValue, maxValueArray[index], newRange)

    if (editorialRange) {
      return [...newRange, editorialRange]
    }

    return newRange
  }, range)

  return range.map(currentRange => {
    const filterRange = {
      ...currentRange,
      selected:
        minValueArray.includes(currentRange.min.toString()) &&
        maxValueArray.includes(currentRange.max.toString()),
      count: currentRange.optionValues.reduce((totalCount, optionValue) => {
        const option = filterOptions?.find(o => o.value === optionValue)
        const optionCount = option ? option.count : 0
        return totalCount + optionCount
      }, 0),
    }

    return filterRange
  })
}

/**
 * Transform the filters retrieved form the API response into a group following similar
 * interface for ease of usage across the application.
 *
 * @param filtersFromProducts The filter facets retrieved from the API response
 * @returns The filters transformed to be used by the application
 */
export const useFiltersState = (
  filtersFromProducts: FilterFacet[],
  productSubdivision?: ProductSubdivisions
) => {
  const filtersFromUrl = useFiltersFromUrl()
  const filtersFromRedux = useApplicationFilters(productSubdivision)

  const customerOrderType = useSelector(s => s.customerOrder.orderType)

  const memoizedFilterState = useMemo(() => {
    const enabledFilters = {
      ...filtersFromUrl,
      ...filtersFromRedux,
    }

    const filterByKind = groupBy(filtersFromProducts, 'kind')
    const filterWithOptions = {} as FiltersState

    objectKeys(DEFAULT_FILTERS).forEach(filterKind => {
      const defaultFilter = (DEFAULT_FILTERS as DefaultFilters)[filterKind]

      const filters = filterByKind[filterKind]

      const newOptions = filters?.map(filter => ({
        ...filter,
        selected: isInArrayOrEqual(enabledFilters[filterKind], filter.value),
      }))

      const filter = {
        ...defaultFilter,
        kind: filterKind,
        options: newOptions,
        range: generateFilterRange(defaultFilter, enabledFilters, newOptions),
      }

      filterWithOptions[filterKind] = {
        ...filter,
        disabled: getDisableFilter({
          orderType: customerOrderType,
          filter,
        }),
      }
    })

    return filterWithOptions
  }, [filtersFromUrl, filtersFromRedux, filtersFromProducts, customerOrderType])

  return memoizedFilterState
}

export const useApplyFilters = (filters: ActiveFilters[]) => {
  const history = useHistory()
  const searchQuery = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    ?.query as string

  const initialFilter: Record<string, FiltersFromUrlType> = searchQuery
    ? { query: searchQuery }
    : {}

  const filterToApply = filters?.reduce((acc, curr) => {
    acc[curr.kind] = curr.value
    return acc
  }, initialFilter)

  return useCallback(() => {
    filters && applyFilters(filterToApply, history)
  }, [filterToApply, history, filters])
}

export const useSelectedFiltersCount = (filters: FilterState[]) => {
  const dispatch = useDispatch()

  const selectedFiltersCount = useMemo(
    () =>
      filters.reduce((selectedCount, filter) => {
        const selectedOptionsCount = filter?.options?.filter(o => o.selected).length || 0
        const selectedRangeCount = filter?.range?.filter(o => o.selected).length || 0
        return selectedCount + selectedOptionsCount + selectedRangeCount
      }, 0),
    [filters]
  )

  useEffect(() => {
    dispatch(setFiltersCount(selectedFiltersCount))
  }, [dispatch, selectedFiltersCount, filters])

  return selectedFiltersCount
}

export const usePredefinedFilters = () => {
  const customerOrderType = useSelector(s => s.customerOrder.orderType)
  const isPrescriptionAvailable = useFacetValue('roxable')

  const togglePrescriptionAvailable = useToggleUrlFilter({
    kind: 'roxable',
    value: 'true',
  })

  useEffect(() => {
    if (!isPrescriptionAvailable && customerOrderType === 'COMPLETE_PAIR') {
      togglePrescriptionAvailable()
    }
  }, [isPrescriptionAvailable, togglePrescriptionAvailable, customerOrderType])
}

export const useApplicationFilters = (productSubdivision?: ProductSubdivisions) => {
  const { availableInStore } = useAvailableInStoreProductSubdivision(productSubdivision)

  return useMemo(() => {
    const filters: FiltersFromUrl = {}
    if (availableInStore && config.toggleFeature.availableInStore) {
      filters.storeAvailable = [String(Number(availableInStore))]
    }

    return filters
  }, [availableInStore])
}
interface FormatFiltersRes {
  formattedFilters: FormattedFilters
  sort: Record<string, string>
}

export const useFormatUrlFilters = (): FormatFiltersRes => {
  const filters = useSanitizedFiltersFromUrl()

  return useMemo(() => {
    const sort: Record<string, string> = {}

    if (filters.sort) {
      const [sortField, sortOrder] = (filters.sort as string).split(':')
      sort.sortField = sortField
      sort.sortOrder = sortOrder
      delete filters.sort
    }

    const formattedFilters = objectKeys(filters).reduce((newFilters, filterKind) => {
      const filterValue = filters[filterKind]

      if (filterValue) {
        newFilters[filterKind] = formatFilterValue(filterKind, filterValue)
      }

      return newFilters
    }, {} as FormattedFilters)

    return { formattedFilters, sort }
  }, [filters])
}

export const useFiltersDependency = () => {
  const filtersFromUrl = useFiltersFromUrl()
  const togglePrescriptionAvailable = useToggleUrlFilter({ kind: 'roxable', value: 'true' })
  const toggleProgressiveFriendly = useToggleUrlFilter({
    kind: 'progressiveFriendly',
    value: 'true',
  })

  const isProgressiveFriendly = !!filtersFromUrl.progressiveFriendly
  const isPrescriptionAvailable = !!filtersFromUrl.roxable
  const prevRoxableFilterStatus = usePrevious(isPrescriptionAvailable)

  useEffect(() => {
    if (isProgressiveFriendly && !isPrescriptionAvailable && !prevRoxableFilterStatus) {
      togglePrescriptionAvailable()
    }
    if (isProgressiveFriendly && !isPrescriptionAvailable && prevRoxableFilterStatus) {
      toggleProgressiveFriendly()
    }
  }, [
    isProgressiveFriendly,
    isPrescriptionAvailable,
    prevRoxableFilterStatus,
    togglePrescriptionAvailable,
    toggleProgressiveFriendly,
  ])
}

export const useFiltersActive = () => {
  const [selectedFilter, setSelectedFilter] = useState<ActiveFilter | null>(null)

  const onFilterClick = useCallback(
    (data: ActiveFilter) => {
      setSelectedFilter(data.id === selectedFilter?.id ? null : data)
    },
    [selectedFilter?.id]
  )

  useEffect(() => {
    if (selectedFilter?.element) {
      selectedFilter.element.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [selectedFilter])

  const filtersContextValue = useMemo(
    () => ({
      activeFilterAccordion: selectedFilter,
      onClick: onFilterClick,
    }),
    [onFilterClick, selectedFilter]
  )

  return useMemo(
    () => ({
      onFilterClick,
      value: filtersContextValue,
    }),
    [filtersContextValue, onFilterClick]
  )
}

export const useGetSortedProductTypeFilters = () => {
  const productTypeDefault = useProductTypeDefault()

  return useCallback(
    (productTypes: ProductTypeValues[]) => {
      const sortedProductTypes = productTypes.sort((a, b) => {
        if (a === productTypeDefault) return -1
        if (b === productTypeDefault) return 1
        return 0
      })

      return sortedProductTypes
    },
    [productTypeDefault]
  )
}

export const getFacetValue = (
  filterKind: FilterKind,
  location: Location<unknown>
): string[] | undefined => {
  const activeFilters = getFiltersFromUrl(location)
  const filter = activeFilters && activeFilters.find(el => el.kind === filterKind)
  if (!filter) return undefined

  if (Array.isArray(filter.value)) {
    return filter.value as string[]
  }

  return [filter.value as string]
}

export const useFacetValue = (filterKind: FilterKind): string[] | undefined => {
  const location = useLocation()
  return getFacetValue(filterKind, location)
}

export const useProductTypeDefault = () => {
  const store = useStoreContext()
  const productTypeDefault = config.default.productType

  return useMemo(() => {
    if (!store.eyeglassesEnabled && productTypeDefault === ProductTypeValues.OPTICAL) {
      return ProductTypeValues.SUN
    }
    return productTypeDefault
  }, [store.eyeglassesEnabled, productTypeDefault])
}

// TODO: can we try to use useGetProductTypeFromUrl instead of useProductTypeFromUrl?
export const useProductTypeFromUrl = (): ProductTypeValues | undefined => {
  const value = useFacetValue(PRODUCT_TYPE)
  const productTypeValue = value?.length && (value[0] as ProductTypeValues)
  return productTypeValue || undefined
}

export const useProductTypeFromUrlWithFallback = (): ProductTypeValues | undefined => {
  const productTypeDefault = useProductTypeDefault()
  const getProductSubdivisionFromUrl =
    useGetProductSubdivisionListFromUrl() as useGetProductTypeListFromUrlRet
  const { productType: productTypeFromUrl } = getProductSubdivisionFromUrl()

  return productTypeFromUrl || productTypeDefault
}

export const useProductSubdivisionFilters = (
  productSubdivision?: ProductSubdivisions
): FormattedFilters => {
  return useMemo(() => {
    switch (config.default.productSubdivision) {
      case 'productHierarchy':
        return {
          product_hierarchy1: productSubdivision as ProductHierarchies,
        }
      case 'productType':
      default:
        return {
          productType: productSubdivision as ProductTypeValues,
        }
    }
  }, [productSubdivision])
}
