import { NotFoundMessage } from '@components/Contents/NoDataContent/styles'
import { altNavigation, landscape, zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'
import { SearchProductsContainer } from '../styled'

export const NoResultsContainer = styled(SearchProductsContainer)`
  block-size: calc(100% - ${pxToRem(520)});
  ${landscape('margin-block-start: 0')}
`

export const NotFoundMessageStyled = styled(NotFoundMessage)`
  ${zoomPortrait([150, 175, 200], 'margin-block-start: 0')}
  ${altNavigation('margin-block-start: 0')}
`
