import { AccordionSingleProps } from '@abstractTypes/accordion'
import React, { useState } from 'react'
import { AccordionLayout } from './AccordionLayout'

export const AccordionSingle: React.FC<AccordionSingleProps> = ({
  isInitiallyOpen = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen)
  return (
    <AccordionLayout onClick={() => setIsOpen(isOpen => !isOpen)} isOpened={isOpen} {...props} />
  )
}
