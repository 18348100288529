export const UndoIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 33 33" width="100%" height="100%">
      <path
        d="M16 0.062C11.7953 0.062 7.82664 1.68267 4.82464 4.62467C4.37197 5.068 3.94797 5.54 3.55064 6.034V0H2.21731V8.682H10.8993V7.34867H4.21931C4.68464 6.71867 5.19797 6.124 5.75731 5.576C8.50931 2.88 12.1466 1.39533 16 1.39533C24.07 1.39533 30.636 7.96133 30.636 16.0313C30.636 24.1013 24.07 30.6667 16 30.6667C7.92997 30.6667 1.36397 24.1013 1.36397 16.0313H0.0306396C0.0306396 24.8367 7.19464 32 16 32C24.8053 32 31.9693 24.8367 31.9693 16.0313C31.9693 7.226 24.8053 0.062 16 0.062Z"
        transform="translate(-0.48 -0.57)"
        fill="#fff"
      />
    </svg>
  )
}
