import { Device } from '@abstractTypes/utils'
import {
  ScrollableContentContainer,
  ScrollablePaneContainer,
  StyledScrollableTabContent,
  TabContent,
  TabPane,
  TabsContainer,
} from '@components/Tabs/styles'
import config from '@config/index'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { clampValues, clampValuesLandscape, flexGapPolyfill, pxToRem } from '@libs/styled'
import { generateDefaultFontProperties } from '@libs/typography'
import styled, { css, keyframes } from 'styled-components'
import {
  altNavigationSliderHeight,
  animationDuration,
  landscapeTabletSliderHeight,
  landscapeTabletSliderWidth,
  landscapeTowerSliderHeight,
  landscapeTowerSliderWidth,
  portraitTabletSliderHeight,
  portraitTabletSliderHeightReduced,
  portraitTowerSliderHeight,
  portraitTowerSliderHeightReduced,
} from '../styleConsts'
import { ImageWrapper, ImagesScrollableContainer } from './ImagesContent/styles'

const scrollHiding = keyframes`
  0% {
    overflow: hidden;
  }

  100% {
    overflow: hidden;
  }
`
export const ProductTabsContainer = styled.div<{
  isExpanded: boolean
  sliderShowed: boolean
  isTabsListSticky: boolean
}>`
  margin-block-end: ${pxToRem(120)};
  block-size: ${({ isExpanded }) =>
    `calc(100vh - ${
      isExpanded ? portraitTowerSliderHeightReduced : portraitTowerSliderHeight
    } - ${pxToRem(120)})`};
  transition: height ${animationDuration};
  ${({ isExpanded }) =>
    tablet(`
      margin-block-end: 0;
      block-size: calc(100vh - ${
        isExpanded ? portraitTabletSliderHeightReduced : portraitTabletSliderHeight
      });
  `)}
  ${landscape(`
    block-size: calc(100vh - ${landscapeTowerSliderHeight});
    margin-block-end: 0;
    justify-content: center;
  `)}
  ${landscape(
    Device.tablet,
    `
      margin-block-end: 0;
      block-size: 100%;
    `
  )}
  ${altNavigation(`
    block-size: ${pxToRem(724)};
    margin-block-end: 0;
  `)}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    block-size: ${pxToRem(740)};
  `
  )}
    ${altNavigationZoom(
    [200],
    `
    block-size: ${pxToRem(640)};
  `
  )}

  ${StyledScrollableTabContent} {
    animation-name: ${({ isExpanded }) => (isExpanded ? scrollHiding : '')};
    animation-duration: ${animationDuration};
    animation-timing-function: linear;
    overscroll-behavior: none;
    ${landscape(`
      display: flex;
      flex-direction: column;
    `)}
  }

  ${ScrollableContentContainer} {
    inset-inline-end: calc(${landscapeTowerSliderWidth} - 100vw);
    ${landscape(`
      transition: right ${animationDuration} linear;
      position: absolute;
      inline-size: calc(100vw - ${landscapeTowerSliderWidth});
      block-size: ${landscapeTowerSliderHeight};
      inset-block-start: 0;
      inset-inline-end: 0;
      ${tablet(`
        inline-size: calc(100vw - ${landscapeTabletSliderWidth});
        block-size: ${landscapeTabletSliderHeight};
      `)}
    `)}
    ${altNavigation(`
      inset-block-start: ${pxToRem(136)};
      position: absolute;
      inset-inline-end: 0;
      inline-size: ${altNavigationSliderHeight};
      block-size: ${altNavigationSliderHeight};
    `)}
    ${({ sliderShowed }) => css`
      ${altNavigationZoom(
        [100, 110, 125, 150, 175, 200],
        `
          inset-block-start: ${sliderShowed ? pxToRem(664) : pxToRem(136)};
          inset-inline-start: 0;
          position: absolute;
          inline-size: 100%;
          block-size: ${pxToRem(604)};
      `
      )}
      ${altNavigationZoom(
        [150, 175, 200],
        `
          block-size: ${pxToRem(652)};
      `
      )}
      ${altNavigationZoom(
        [200],
        `
          block-size: ${pxToRem(748)};
      `
      )}
      ${zoomLandscape(
        [200],
        `
        inset-inline-start: unset;
      `
      )}
    `}
  }

  ${ScrollablePaneContainer} {
    padding-block: 0;
    padding-inline: 5vw;
    max-inline-size: 100%;
    min-block-size: ${pxToRem(64)};
    margin-block: ${pxToRem(22)};
    margin-inline: 0;
    background-color: ${({ theme }) => theme.electronics.colors.primaryLight};
    ${tablet(`
      min-block-size: ${clampValues(48, 64)};
    `)}
    ${landscape(`
      max-inline-size: 80%;
      block-size: 100%;
      display: flex;
    `)}
    ${landscape(
      Device.tablet,
      `
      min-block-size: auto;
    `
    )}
    ${altNavigation(`
      max-inline-size: 100%;
      inline-size: 100%;
      padding-block: 0 ;
      padding-inline:  ${pxToRem(108)};
      margin: unset;
      position: fixed;
      z-index: 5;
    `)}
    ${zoomPortrait(
      [110, 125, 150],
      `
        min-block-size: ${pxToRem(96)};
        margin-block-start: ${pxToRem(10)};
    `
    )}
    ${zoomPortrait(
      [175, 200],
      `
        min-block-size: ${pxToRem(128)};
        margin-block-start: ${pxToRem(0)};
    `
    )}
    ${({ isTabsListSticky }) => css`
      ${altNavigationZoom(
        [100, 110, 125, 150, 175, 200],
        `
        position: ${isTabsListSticky ? 'sticky' : 'relative'};
        inset-block-start:  0;
        margin-block-start: 0;
        z-index: 2;
      `
      )}
    `}
  }

  ${TabPane} {
    text-transform: uppercase;
    font-weight: bold;

    :not(.active) {
      opacity: 0.6;
    }

    ::after {
      background: ${({ theme }) => theme.electronics.colors.primary};
    }
    ${landscape(
      Device.tablet,
      `
      display: flex;
      flex-direction: column;
      justify-content: center;

      ::after {
        margin-block-start: ${pxToRem(43)};
      }
    `
    )}
  }

  ${TabsContainer} {
    ${landscape(`
      justify-content: center;
      inline-size: fit-content;
      max-inline-size: 100%;
      margin: auto;
    `)}
  }
`

export const TabContentStyled = styled(TabContent)<{ imagesOnTop?: boolean; seeOnVideo: boolean }>`
  ${({ imagesOnTop }) =>
    imagesOnTop &&
    `
    margin-block-start: 1vh;
  `}
  ${({ imagesOnTop }) =>
    tablet(`
      padding-block-end: ${pxToRem(20)};
      margin-block-start: ${imagesOnTop ? '1vh' : '3vh'};
      block-size: calc(100% - ${imagesOnTop ? '1vh' : '3vh'});
  `)}
  ${landscape(`
    padding-block: 0 ;
    padding-inline:  ${pxToRem(32)};
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-inline:  ${clampValuesLandscape(24, 32)};
  `
  )}
  ${altNavigation(`
    padding-inline:  ${pxToRem(21)};
    margin-block-start: 0;
    block-size: 100%;
  `)}
  ${altNavigationZoom(
    [100, 125, 150, 175],
    `
    padding-block-start: ${pxToRem(40)};
    padding-block-end: ${pxToRem(150)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    padding-block-start: ${pxToRem(40)};
    padding-block-end: ${pxToRem(170)};
  `
  )}

  ${ImagesScrollableContainer} {
    ${({ imagesOnTop }) =>
      imagesOnTop &&
      `
      padding-block-end: 1vh;
    `}
  }

  ${ImageWrapper} {
    ${({ imagesOnTop }) =>
      imagesOnTop &&
      `
      inline-size: ${pxToRem(124)};
      block-size: ${pxToRem(124)};
    `}
  }
  ${({ seeOnVideo }) => css`
    ${altNavigationZoom(
      [150, 175, 200],
      `
        padding-block-end: ${seeOnVideo ? pxToRem(220) : pxToRem(150)};
      `
    )}

    ${altNavigationZoom(
      [200],
      `
        padding-block-end: ${seeOnVideo ? pxToRem(172) : pxToRem(24)};
        padding-block-start: ${pxToRem(88)};
      `
    )}
  `}
`

export const TabInnerContent = styled.div<{ flexDirection: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  ${landscape(`
    margin-block: auto ;
    margin-inline:  0;
  `)}
`

const comparisonIconStyle = css`
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  inline-size: ${pxToRem(56)};
  block-size: ${pxToRem(56)};
  margin-block: 0 ${pxToRem(16)};
  margin-inline: 0;
  ${tablet(
    Device.ipadMini,
    `
    inline-size: ${pxToRem(44)};
    block-size: ${pxToRem(44)};
  `
  )}
  ${altNavigation(`
    inline-size: ${pxToRem(44)};
    block-size: ${pxToRem(44)};
  `)}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(72)};
    block-size: ${pxToRem(72)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(132)};
    block-size: ${pxToRem(132)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    inline-size: ${pxToRem(72)};
    block-size: ${pxToRem(72)};
  `
  )}
`

const cmsContentDetailsStyles = css`
  & .cms-content {
    text-transform: none;
    padding-block: 0;
    padding-inline: ${pxToRem(46)};
    ${altNavigation(`

      padding-inline:  0 ${pxToRem(24)};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-inline: 0 ${pxToRem(24)};
      margin-block-start: ${clampValuesLandscape(27, 37)};
    `
    )}
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline: ${pxToRem(62)};

    `
    )}
    ${zoomPortrait(
      [200],
      `
        padding-inline:  ${pxToRem(74)};
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      padding-inline: 0 ${pxToRem(24)};
    `
    )}
  }

  & .cms-content .cms-header {
    text-align: start;
    text-transform: uppercase;
    font-weight: bold;
    margin-block-end: ${pxToRem(40)};
    font-family: ${({ theme }) => theme.electronics.fonts.primary};
    font-size: ${pxToRem(22)};
    ${tablet(`
      margin-block-end: ${clampValues(28, 40)};
      font-size: ${clampValues(20, 27)};
    `)}
    ${zoomPortrait(
      [150, 175],
      `
        font-size: ${pxToRem(33)};
        line-height: normal;
      `
    )}
    ${zoomPortrait(
      [200],
      `
        font-size: ${pxToRem(44)};
        line-height: normal;
      `
    )}
  }

  & .cms-content td {
    vertical-align: top;
    text-align: start;
    padding-block-end: ${pxToRem(12)};
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.electronics.fonts.heading5,
        { color: 'primaryDark' },
        'electronics',
        '.cms-content td'
      )}
  }

  & .cms-content tr {
    :nth-last-child(2) {
      block-size: ${pxToRem(71)};
    }
    ${landscape(
      Device.tablet,
      `
      block-size: unset;
      min-block-size: ${pxToRem(26)};
      margin-block-end: ${pxToRem(14)};
      display: flex;
      :nth-last-child(2) {
        margin-block-end: 0;
      }
    `
    )}
  }

  & .cms-content .cms-leftcol {
    padding-inline-end: ${pxToRem(24)};
    min-inline-size: 50%;
    inline-size: 50%;
    max-inline-size: 50%;
    font-weight: bold;
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline-end: ${pxToRem(40)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      padding-inline-end: ${pxToRem(30)};
    `
    )}
  }

  & .cms-content .cms-leftcol span {
    display: block;
    padding-block-start: ${pxToRem(14)};
    ${landscape(
      Device.tablet,
      `
      padding-block-start: ${clampValuesLandscape(10, 14)};
    `
    )}
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.electronics.fonts.label,
        { color: 'inactive' },
        'electronics',
        '.cms-content .cms-leftcol span'
      )};
  }

  & .cms-content .cms-rightcol {
    color: ${({ theme }) => theme.electronics.colors.inactive};
    padding-inline-start: ${pxToRem(18)};
    ${landscape(
      Device.tablet,
      `
      padding-inline-start: ${clampValuesLandscape(14, 18)};
    `
    )}
  }

  & .cms-content img {
    inline-size: ${pxToRem(142)};
    block-size: ${pxToRem(142)};
    ${tablet(`
      inline-size: ${clampValues(103, 142)};
      block-size: ${clampValues(103, 142)};
    `)}
    ${zoomPortrait(
      [150, 175],
      `
        inline-size: ${pxToRem(213)};
        block-size: ${pxToRem(213)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
        inline-size: ${pxToRem(284)};
        block-size: ${pxToRem(284)};
    `
    )}
  }

  & .cms-content br {
    display: block;
    content: ' ';
    block-size: ${pxToRem(14)};
  }

  & .cms-content .cms-footer {
    position: relative;
    text-align: justify;
    padding-block-start: ${pxToRem(60)};
    ${tablet(`
      padding-block-start: ${clampValues(42, 60)};
    `)};
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.electronics.fonts.label,
        { color: 'inactive' },
        'electronics',
        '.cms-content .cms-footer'
      )};
  }
`

const cmsContentComparisonStyles = css`
  & .cms-content-comparison {
    text-align: center;
    padding-block: 0;
    padding-inline: ${pxToRem(46)};
    ${landscape(`
      padding-inline:  ${pxToRem(16)};
    `)}
    ${tablet(`
      padding-inline:  ${pxToRem(32)};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-inline: 0 ${pxToRem(24)};
    `
    )}
    ${altNavigation(`
      padding-inline: 0 ${pxToRem(24)};
    `)}
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline:  ${pxToRem(62)};
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      padding-inline: 0 ${pxToRem(24)};
    `
    )}
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.electronics.fonts.heading2,
        { color: 'primaryDark' },
        'electronics',
        '.cms-content-comparison'
      )};
  }

  & .cms-content-comparison section:first-child {
    padding-block: ${pxToRem(32)} ${pxToRem(48)};
    padding-inline: 0;
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding-block: ${pxToRem(16)} ${pxToRem(32)};
    `
    )}
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.electronics.fonts.heading5,
        { color: 'primaryDark' },
        'electronics',
        '.cms-content-comparison section:first-child'
      )};
  }

  & .cms-content-comparison table {
    inline-size: 100%;
    border-collapse: inherit;
    margin-block-end: ${pxToRem(80)};
    padding-block: 0;
    padding-inline: ${pxToRem(114)};
    ${landscape(`
      padding-inline:  ${pxToRem(110)};
    `)}
    ${tablet(`
      padding-inline:  ${pxToRem(60)};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding: 0;
    `
    )}
    ${altNavigation(`
      padding: 0;
    `)}
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding: 0;
    `
    )}
  }

  & .cms-content-comparison tbody {
    display: flex;
    flex-direction: column;
    ${flexGapPolyfill(pxToRem(42), 'column')};
    ${zoomPortrait(
      [150, 175, 200],
      `
      ${flexGapPolyfill(pxToRem(64), 'column')};
    `
    )}
  }

  & .cms-content-comparison tr {
    display: flex;
    ${flexGapPolyfill(pxToRem(32), 'row')};
    ${landscape(
      Device.tablet,
      `
      ${flexGapPolyfill(pxToRem(8), 'row')};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      ${flexGapPolyfill(pxToRem(8), 'row')};
    `
    )}
  }

  & .cms-content-comparison th {
    inline-size: 50%;
    padding-block-end: ${pxToRem(22)};
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.electronics.fonts.heading4,
        { color: 'primaryDark', weight: 'bold' },
        'electronics',
        '.cms-content-comparison th'
      )};
  }

  & .cms-content-comparison td {
    display: flex;
    flex-direction: column;
    align-items: center;
    inline-size: 50%;
    vertical-align: middle;
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.electronics.fonts.body,
        { color: 'primaryDark' },
        'electronics',
        '.cms-content-comparison td'
      )};

    sup {
      position: relative;
      inset-block-end: ${pxToRem(6)};
      ${({ theme }) =>
        generateDefaultFontProperties(
          theme,
          theme.electronics.fonts.label,
          { color: 'primaryDark' },
          'electronics',
          '.cms-content-comparison sup'
        )};
    }
  }

  & .cms-content-comparison td div + section {
    font-weight: bold;
    margin-block: 0 ${pxToRem(16)};
    margin-inline: 0;
  }

  & .cms-content-comparison hr {
    inline-size: ${pxToRem(144)};
    background-color: ${({ theme }) => theme.colors.brandeisBlue};
    block-size: 2px;
    border: 0;
    margin-block: 0 ${pxToRem(16)};
    margin-inline: 0;
  }

  [class*='5mp_icon'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/camera-5mp.svg);
    margin-block-end: ${({ theme }) => pxToRem(theme.electronics.fonts.body.lineHeight.tower + 24)};
    ${({ theme }) => css`
      ${tablet(`
        margin-block-end: ${pxToRem(theme.electronics.fonts.body.lineHeight.tablet + 24)};
      `)}
      ${tablet(
        Device.ipadMini,
        `
        margin-block-end: ${pxToRem(theme.electronics.fonts.body.lineHeight.tabletMini + 24)};
      `
      )}
      ${zoomPortrait(
        [150],
        `
        margin-block-end: calc(${pxToRem(
          theme.electronics.fonts.body.lineHeight.tower,
          1.5
        )} + ${pxToRem(4)});
      `
      )}
      ${zoomPortrait(
        [175],
        `
        margin-block-end: calc(${pxToRem(
          theme.electronics.fonts.body.lineHeight.tower,
          1.75
        )} + ${pxToRem(4)});
      `
      )}
      ${zoomPortrait(
        [200],
        `
        margin-block-end: calc(${pxToRem(
          theme.electronics.fonts.body.lineHeight.tower,
          2
        )} + ${pxToRem(4)});
      `
      )}
    `}
  }

  [class*='12mp_icon'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/camera-12mp.svg);
    margin-block: 0 ${pxToRem(8)};
    margin-inline: 0;
  }

  [class*='other_icon4'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/livestream.svg);
    inline-size: ${pxToRem(80)};
    block-size: ${pxToRem(34)};
    ${tablet(
      Device.ipadMini,
      `
      inline-size: ${pxToRem(56)};
      block-size: ${pxToRem(24)};
    `
    )}
    ${zoomPortrait(
      [150, 175],
      `
      inline-size: ${pxToRem(120)};
      block-size: ${pxToRem(50)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: ${pxToRem(160)};
      block-size: ${pxToRem(67)};
    `
    )}
  }

  [class*='other_icon5'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/speakers-standard.svg);
  }

  [class*='other_icon6'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/speakers-custom.svg);
  }

  [class*='other_icon8'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/meta-ai.svg);
  }

  [class*='other_icon9'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/charging-slow.svg);
  }

  [class*='other_icon10'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/charging-fast.svg);
  }

  [class*='other_icon11'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/ram-4gb.svg);
  }

  [class*='other_icon12'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/ram-32gb.svg);
  }

  [class*='other_icon13'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/bluetooth-slow.svg);
  }

  [class*='other_icon14'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics/bluetooth-fast.svg);
  }

  [class*='other_icon16'] {
    ${comparisonIconStyle};
    background-image: url(${config.assets.URL}/electronics//water-resistance.svg);
  }

  & .cms-content-comparison section.cms_footer {
    text-align: start;
    display: block;
    padding-block-end: ${pxToRem(24)};
    ${({ theme }) =>
      generateDefaultFontProperties(
        theme,
        theme.electronics.fonts.label,
        { color: 'primaryDark' },
        'electronics',
        '.cms-content-comparison section.cms_footer'
      )};
  }

  & .cms-content-comparison section.cms_footer:last-child {
    padding-block-end: 0;
  }
`

export const HtmlContent = styled.div`
  ${cmsContentDetailsStyles};
  ${cmsContentComparisonStyles};
`
