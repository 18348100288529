import { useMemo } from 'react'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useNavigationContext } from './useNavigationContext'

export const useResponsiveValue = (value: number, tabletMinSize: number, tabletMaxSize: number) => {
  const isIPad = isIPadView()
  const { landscapeNavigation } = useNavigationContext()

  return useMemo(
    () =>
      !isIPad
        ? pxToRem(value)
        : landscapeNavigation
        ? clampValuesLandscape(tabletMinSize, tabletMaxSize)
        : clampValues(tabletMinSize, tabletMaxSize),
    [value, tabletMinSize, tabletMaxSize, isIPad, landscapeNavigation]
  )
}
