import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getToEnvVariables = () => {
  const toEnvVariables: AppConfigOverride = {
    shopperSlug: 'to',
    shopperName: 'TARGET OPTICAL',
    storeLang: 'en-US',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    toggleFeature: {
      availableInStore: true,
    },
    vmStore: 'to',
    vmStyle: 'to',
  }

  return toEnvVariables
}

export default getToEnvVariables
