import { OneCatalogInfo, Product } from '@abstractTypes/graphqlTypes'
import { ProductInfoForModals } from '@components/BarCodeScanModal/types'

export const convertProductForBarcodeModal = (
  product: Product | OneCatalogInfo,
  url: string
): ProductInfoForModals | undefined => {
  const { originalBrandCode, thumbnails, customizable, model, UPC } = product
  return {
    originalBrandCode,
    thumbnails,
    customizable: customizable || false,
    model,
    UPC,
    url,
  }
}
