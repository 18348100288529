import { IconProps } from '@abstractTypes/icons'

export const FaceShapeRoundIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        d="M24 44.25C12.97 44.25 4 35.28 4 24.25C4 13.22 12.97 4.25 24 4.25C35.03 4.25 44 13.22 44 24.25C44 35.28 35.03 44.25 24 44.25ZM24 6.25C14.07 6.25 6 14.33 6 24.25C6 34.17 14.07 42.25 24 42.25C33.93 42.25 42 34.17 42 24.25C42 14.33 33.92 6.25 24 6.25Z"
        fill={stroke}
      />
    </svg>
  )
}
