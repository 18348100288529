import { ProductInfoConfig, ProductInfoConfigScenario } from '@abstractTypes/tiles'
import { DefaultTheme } from 'styled-components'
import { useProductInfoConfig } from './useProductInfoConfig'

const chanelProductInfoConfigShoppingBag = (theme: DefaultTheme): ProductInfoConfig => ({
  style: {
    background: theme.chanel.colors.background,
  },
  modules: [
    {
      id: 'brand',
      style: {
        weight: 'bold',
        transform: 'uppercase',
      },
    },
    {
      id: 'quantity',
    },
    {
      id: 'name',
    },
    {
      id: 'price',
    },
  ],
})

const defaultProductInfoConfigShoppingBag = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'brand',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'quantity',
    },
    {
      id: 'name',
    },
    {
      id: 'price',
    },
  ],
})

const monoBrandRBProductInfoConfigShoppingBag = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'quantity',
    },
    {
      id: 'price',
    },
  ],
})

const monoBrandOOProductInfoConfigShoppingBag = (): ProductInfoConfig => ({
  modules: [
    {
      id: 'name',
      style: {
        weight: 'bold',
      },
    },
    {
      id: 'quantity',
    },
    {
      id: 'price',
    },
  ],
})

const productInfoConfigByScenarios: ProductInfoConfigScenario = {
  monobrand: {
    rb: monoBrandRBProductInfoConfigShoppingBag,
    oo: monoBrandOOProductInfoConfigShoppingBag,
  },
  multibrand: {
    ch: chanelProductInfoConfigShoppingBag,
    default: defaultProductInfoConfigShoppingBag,
  },
  default: defaultProductInfoConfigShoppingBag,
}

export const useProductInfoConfigShoppingBag = () => {
  const { getProductInfoConfig } = useProductInfoConfig(productInfoConfigByScenarios)
  return getProductInfoConfig
}
