import styled from 'styled-components'

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  inline-size: 50%;
  max-inline-size: 100%;
  position: relative;
`
