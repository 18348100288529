import { IconProps } from '@abstractTypes/icons'


export const CheckedIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#333',
}: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 33">
      <path
        d="M11.9581 21.2438L0.174805 9.46925L1.11747 8.52729L11.9588 19.3612L30.8621 0.511963L31.8048 1.45526L11.9581 21.2438Z"
        fill={fill}
      />
    </svg>
  )
}
