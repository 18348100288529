import { IconProps } from '@abstractTypes/icons'


export const SurfIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <g clipPath="url(#a)" fill={fill}>
        <path d="M41.889 40.208c-.886-.316-1.732-.736-2.627-1.181-.96-.477-1.952-.969-3.013-1.332a13.09 13.09 0 0 0-5.576-.62C33.575 32.764 35.29 27.588 35.29 22c0-9.063-4.455-17.081-11.289-22-6.834 4.919-11.289 12.937-11.289 22 0 7.096 2.738 13.546 7.204 18.373a8.894 8.894 0 0 1-3.6-.704c-.896-.382-1.741-.911-2.634-1.473-1.416-.888-2.879-1.806-4.626-2.146-1.822-.358-3.862-.037-5.897.922-1.146.54-2.188 1.23-3.157 1.933V41.4c1.21-.931 2.555-1.934 4.009-2.619 1.149-.541 2.895-1.112 4.663-.769 1.382.269 2.627 1.05 3.946 1.877.92.577 1.872 1.175 2.914 1.618a10.892 10.892 0 0 0 7.181.469c1.171-.327 2.251-.841 3.294-1.337.836-.398 1.626-.773 2.448-1.055a11.11 11.11 0 0 1 7.146.002c.937.32 1.828.763 2.771 1.23.908.451 1.847.917 2.844 1.272.672.24 1.543.399 2.5.399 1.367-.001 2.903-.338 4.284-1.213v-2.605c-1.859 2.024-4.691 2.046-6.113 1.539zM24 2.517a25.207 25.207 0 0 1 8.115 11.954L16.182 30.404A24.942 24.942 0 0 1 14.711 22c0-7.556 3.447-14.734 9.289-19.483zm1.147 36.317c-.954.454-1.859.879-2.801 1.162a25.232 25.232 0 0 1-5.35-7.578l15.717-15.717c.376 1.727.577 3.501.577 5.298 0 5.768-2.014 11.312-5.565 15.727-.9.315-1.753.716-2.578 1.108z" />
        <path d="M42.059 45.717c-.887-.316-1.733-.737-2.629-1.182-.959-.477-1.951-.969-3.012-1.331a13.112 13.112 0 0 0-8.439-.001c-.932.317-1.811.736-2.661 1.141-1.012.481-1.967.936-2.973 1.217a8.903 8.903 0 0 1-5.859-.382c-.896-.382-1.741-.912-2.635-1.473-1.415-.889-2.879-1.808-4.626-2.146-1.824-.353-3.862-.034-5.896.922-1.213.571-2.313 1.31-3.329 2.056v2.503c1.253-.966 2.657-2.032 4.181-2.75 1.148-.542 2.892-1.111 4.663-.769 1.382.269 2.627 1.05 3.945 1.877.92.578 1.873 1.176 2.915 1.619a10.904 10.904 0 0 0 7.18.468c1.172-.326 2.251-.84 3.294-1.337.836-.397 1.626-.773 2.448-1.054a11.11 11.11 0 0 1 7.146.001c.936.32 1.827.762 2.77 1.229.909.451 1.848.918 2.846 1.273.672.24 1.544.399 2.502.399 1.31 0 2.776-.306 4.111-1.101v-2.54c-1.858 1.846-4.563 1.851-5.942 1.361z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={fill} d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
