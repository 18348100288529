import { IconProps } from '@abstractTypes/icons'


export const RayBanStories: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = 'white',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 552 73"
    fill={fill}
  >
    <path d="M110.186 1.06982C110.115 1.06982 110.047 1.098 109.997 1.14816C109.947 1.19831 109.919 1.26634 109.919 1.33727V28.8838C109.919 36.1047 107.244 38.7791 101.628 38.7791C96.0116 38.7791 93.3372 36.1047 93.3372 28.8838V1.33727C93.3372 1.26634 93.309 1.19831 93.2589 1.14816C93.2087 1.098 93.1407 1.06982 93.0698 1.06982H83.7093C83.6384 1.06982 83.5704 1.098 83.5202 1.14816C83.47 1.19831 83.4419 1.26634 83.4419 1.33727V28.6163C83.4419 40.1163 87.186 46.0001 96.5465 47.3373V70.6047C96.5465 70.6756 96.5747 70.7437 96.6248 70.7938C96.675 70.844 96.743 70.8721 96.814 70.8721H106.174C106.245 70.8721 106.313 70.844 106.364 70.7938C106.414 70.7437 106.442 70.6756 106.442 70.6047V47.3373C115.535 46.0001 119.547 40.1163 119.547 28.6163V1.33727C119.547 1.06982 119.279 1.06982 119.547 1.06982H110.186Z" />
    <path d="M132.919 29.9535H124.093C123.826 29.9535 123.558 29.9535 123.558 30.2209V38.5116C123.702 38.6569 123.89 38.7508 124.093 38.7791H132.919C132.954 38.7791 132.989 38.7722 133.021 38.7587C133.053 38.7453 133.083 38.7256 133.108 38.7007C133.133 38.6759 133.152 38.6464 133.166 38.614C133.179 38.5815 133.186 38.5468 133.186 38.5116V30.2209C133.186 30.15 133.158 30.082 133.108 30.0318C133.058 29.9817 132.99 29.9535 132.919 29.9535Z" />
    <path d="M67.9302 1.06982H58.0349C57.7674 1.06982 57.5 1.06982 57.5 1.33727L41.7209 70.6047C41.4535 70.8721 41.7209 71.1396 41.9884 71.1396H51.0814C51.3488 71.1396 51.6163 71.1396 51.6163 70.8721L54.8256 55.0931H70.6047L73.814 70.8721C73.814 71.1396 74.0814 71.1396 74.3488 71.1396H83.4419C83.7093 71.1396 83.7093 71.1396 84.2442 70.6047L68.4651 1.33727C68.4651 1.06982 68.1977 1.06982 67.9302 1.06982ZM56.4302 45.7326L62.3139 17.9187L68.1977 45.7326H56.4302Z" />
    <path d="M539.698 32.6279L533.547 29.686C526.593 26.4767 524.988 24.6047 524.988 20.0581V16.8488C524.988 10.9651 527.128 8.82558 532.744 8.82558C538.36 8.82558 540.5 10.9651 540.5 16.8488V23.8023C540.5 23.8733 540.528 23.9413 540.578 23.9914C540.629 24.0416 540.697 24.0698 540.767 24.0698H550.128C550.199 24.0698 550.267 24.0416 550.317 23.9914C550.367 23.9413 550.395 23.8733 550.395 23.8023V17.1163C550.395 4.81395 545.314 0 532.744 0C520.709 0 514.826 4.81395 514.826 17.1163V21.9302C514.826 29.4186 518.035 33.6977 526.326 37.4419L532.744 40.3837C540.5 43.8605 541.837 46 541.837 51.0814V55.093C541.837 60.9767 539.43 63.1163 533.547 63.1163C527.663 63.1163 525.256 61.2442 525.256 55.093V48.407C525.112 48.2617 524.924 48.1678 524.721 48.1395H515.628C515.361 48.1395 515.093 48.1395 515.093 48.407V54.8256C515.093 67.3953 520.977 72.2093 533.547 72.2093C546.116 72.2093 552 67.3953 552 54.8256V49.7442C552 41.4535 549.058 36.907 539.698 32.6279Z" />
    <path d="M28.3488 35.5698C33.1628 32.8954 35.8372 29.4187 35.8372 21.3954V19.7908C35.8372 6.6861 30.2209 1.06982 16.8488 1.06982H0.267442C0.196512 1.06982 0.128487 1.098 0.0783319 1.14816C0.0281768 1.19831 0 1.26634 0 1.33727L0 70.8721C0 70.9431 0.0281768 71.0111 0.0783319 71.0613C0.128487 71.1114 0.196512 71.1396 0.267442 71.1396H9.62791C9.69884 71.1396 9.76686 71.1114 9.81702 71.0613C9.86717 71.0111 9.89535 70.9431 9.89535 70.8721V40.3838H16.8488C21.1279 40.3838 22.4651 42.5233 23.2674 47.6047L26.4767 70.8721C26.7442 71.1396 26.7442 71.1396 27.0116 71.1396H36.6395C36.907 71.1396 36.907 71.1396 36.907 70.8721L33.1628 47.3373C32.093 41.721 31.2907 36.907 28.3488 35.5698ZM25.9419 22.1977C25.9419 28.0815 23.2674 31.0233 17.6512 31.0233H9.89535V10.1628H17.6512C23.2674 10.1628 25.9419 13.1047 25.9419 18.9884V22.1977Z" />
    <path d="M509.744 1.06982H479.791C479.523 1.06982 479.256 1.06982 479.256 1.33727V70.3373C479.4 70.4825 479.588 70.5764 479.791 70.6047H509.744C509.815 70.6047 509.883 70.5765 509.933 70.5264C509.983 70.4762 510.012 70.4082 510.012 70.3373V61.7791C510.012 61.7082 509.983 61.6402 509.933 61.59C509.883 61.5399 509.815 61.5117 509.744 61.5117H489.419V39.8489H506.802C507.07 39.8489 507.337 39.8489 507.337 39.5815V31.2908C507.193 31.1455 507.005 31.0516 506.802 31.0233H489.419V10.1628H509.744C509.815 10.1628 509.883 10.1347 509.933 10.0845C509.983 10.0344 510.012 9.96634 510.012 9.8954V1.33727C510.012 1.06982 510.012 1.06982 509.744 1.06982Z" />
    <path d="M313.174 32.6279L307.023 29.686C300.07 26.4767 298.465 24.6047 298.465 20.0581V16.8488C298.465 10.9651 300.605 8.82558 306.221 8.82558C311.837 8.82558 313.977 10.9651 313.977 16.8488V23.8023C313.977 23.8733 314.005 23.9413 314.055 23.9914C314.105 24.0416 314.173 24.0698 314.244 24.0698H323.605C323.676 24.0698 323.744 24.0416 323.794 23.9914C323.844 23.9413 323.872 23.8733 323.872 23.8023V17.1163C323.872 4.81395 318.791 0 306.221 0C294.186 0 288.302 4.81395 288.302 17.1163V21.9302C288.302 29.4186 291.512 33.6977 299.802 37.4419L306.221 40.3837C313.977 43.8605 315.314 46 315.314 51.0814V55.093C315.314 60.9767 312.907 63.1163 307.023 63.1163C301.14 63.1163 298.733 61.2442 298.733 55.093V48.407C298.588 48.2617 298.4 48.1678 298.198 48.1395H289.105C288.837 48.1395 288.57 48.1395 288.57 48.407V54.8256C288.57 67.3953 294.454 72.2093 307.023 72.2093C319.593 72.2093 325.477 67.3953 325.477 54.8256V49.7442C325.477 41.4535 322.535 36.907 313.174 32.6279Z" />
    <path d="M253.535 1.06982C253.267 1.06982 253 1.06982 253 1.33727V46.2675H252.733L235.081 1.33727C234.814 1.06982 234.814 1.06982 234.547 1.06982H225.721C225.453 1.06982 225.186 1.06982 225.186 1.33727V70.6047C225.33 70.7499 225.518 70.8439 225.721 70.8721H234.279C234.35 70.8721 234.418 70.844 234.468 70.7938C234.518 70.7437 234.547 70.6756 234.547 70.6047V25.6745H234.814L252.465 70.6047C252.733 70.8721 252.733 70.8721 253 70.8721H261.826C261.897 70.8721 261.965 70.844 262.015 70.7938C262.065 70.7437 262.093 70.6756 262.093 70.6047V1.33727C262.093 1.06982 261.826 1.06982 262.093 1.06982H253.535Z" />
    <path d="M388.593 0C376.291 0 370.14 6.15116 370.14 18.7209V53.4884C370.14 66.0581 376.291 72.2093 388.593 72.2093C400.895 72.2093 407.047 66.0581 407.047 53.4884V18.7209C407.047 6.15116 400.895 0 388.593 0ZM397.151 53.7558C397.151 60.1744 394.209 63.1163 388.593 63.1163C382.977 63.1163 380.035 60.1744 380.035 53.7558V18.186C380.035 11.7674 382.977 8.82558 388.593 8.82558C394.209 8.82558 397.151 11.7674 397.151 18.186V53.7558Z" />
    <path d="M366.128 1.06982H328.954C328.686 1.06982 328.419 1.06982 328.419 1.33727V9.8954C328.563 10.0406 328.751 10.1346 328.954 10.1628H342.593V70.6047C342.593 70.6756 342.621 70.7437 342.671 70.7938C342.722 70.844 342.79 70.8721 342.86 70.8721H352.221C352.292 70.8721 352.36 70.844 352.41 70.7938C352.46 70.7437 352.488 70.6756 352.488 70.6047V10.1628H366.128C366.199 10.1628 366.267 10.1347 366.317 10.0845C366.367 10.0344 366.395 9.96634 366.395 9.8954V1.33727C366.395 1.06982 366.395 1.06982 366.128 1.06982Z" />
    <path d="M443.954 35.5698C448.767 32.8954 451.442 29.4187 451.442 21.3954V19.7908C451.442 6.6861 445.826 1.06982 432.453 1.06982H415.872C415.605 1.06982 415.337 1.06982 415.337 1.33727V70.8721C415.481 71.0174 415.669 71.1113 415.872 71.1396H424.965C425.233 71.1396 425.5 71.1396 425.5 70.8721V40.3838H432.453C436.733 40.3838 438.07 42.5233 438.872 47.6047L442.081 70.8721C442.081 71.1396 442.081 71.1396 442.349 71.1396H451.977C452.244 71.1396 452.244 71.1396 452.512 70.8721L448.767 47.3373C447.698 41.721 446.895 36.907 443.954 35.5698ZM441.012 22.1977C441.012 28.0815 438.337 31.0233 432.721 31.0233H425.233V10.1628H432.721C438.337 10.1628 441.012 13.1047 441.012 18.9884V22.1977Z" />
    <path d="M204.326 1.06982H194.43C194.163 1.06982 193.895 1.06982 193.895 1.33727L178.116 70.6047C177.849 70.8721 178.116 71.1396 178.384 71.1396H187.477C187.744 71.1396 188.012 71.1396 188.012 70.8721L191.221 55.0931H207L210.209 70.8721C210.209 71.1396 210.477 71.1396 210.744 71.1396H219.837C220.105 71.1396 220.372 71.1396 220.64 70.6047L204.86 1.33727C204.86 1.06982 204.593 1.06982 204.326 1.06982ZM192.826 45.7326L198.709 17.9187L204.593 45.7326H192.826Z" />
    <path d="M166.884 33.6977C171.43 30.7559 173.302 27.5466 173.302 20.5931V19.5233C173.302 6.41866 167.151 1.06982 153.244 1.06982H138C137.733 1.06982 137.465 1.06982 137.465 1.33727V70.8721C137.609 71.0174 137.797 71.1113 138 71.1396H155.384C168.221 71.1396 174.64 65.7908 174.64 51.3489V49.7442C174.64 41.4535 172.767 36.3722 166.884 33.6977ZM147.628 10.1628H154.314C160.198 10.1628 163.14 12.5698 163.14 18.721V21.3954C163.14 27.5466 160.465 29.9535 154.314 29.9535H147.628V10.1628ZM164.744 52.4187C164.744 59.6396 162.07 62.0466 154.581 62.0466H147.628V39.314H154.581C162.07 39.314 164.744 41.721 164.744 48.9419V52.4187Z" />
    <path d="M469.093 1.06982H460C459.733 1.06982 459.465 1.06982 459.465 1.33727V70.6047C459.609 70.7499 459.797 70.8439 460 70.8721H469.093C469.36 70.8721 469.628 70.8721 469.628 70.6047V1.33727C469.628 1.06982 469.628 1.06982 469.093 1.06982Z" />
  </svg>
)
