import { AppConfigOverride } from '@abstractTypes/envs'

const getOcEnvVariables = () => {
  const ocEnvVariables: AppConfigOverride = {
    shopperSlug: 'oc',
    shopperName: 'ÓTICAS CAROL',
    storeID: '14',
    storeLang: 'en-US',
    vmStore: 'oc',
    vmStyle: 'oc',
  }

  return ocEnvVariables
}

export default getOcEnvVariables
