import { Device } from '@abstractTypes/utils'
import { landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

export interface ColorBadgeProps {
  backgroundColor?: string | null
  backgroundImage?: string
  isSmall?: boolean
}

export const ColorBadge = styled.div<ColorBadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: ${({ isSmall }) => (isSmall ? `${pxToRem(58)}` : `${pxToRem(80)}`)};
  block-size: ${({ isSmall }) => (isSmall ? `${pxToRem(58)}` : `${pxToRem(80)}`)};
  border-radius: 50%;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.primaryLight};
  overflow: hidden;
  ${({ isSmall }) => css`
    ${tablet(`
      inline-size: ${isSmall ? clampValues(48, 58) : clampValues(70, 80)};
      block-size: ${isSmall ? clampValues(48, 58) : clampValues(70, 80)};
    `)}
    ${landscape(
      Device.tablet,
      `
        inline-size: ${isSmall ? clampValuesLandscape(48, 58) : clampValuesLandscape(70, 80)};
        block-size: ${isSmall ? clampValuesLandscape(48, 58) : clampValuesLandscape(70, 80)};
      `
    )}
    ${zoomPortrait(
      [150, 175],
      `
        inline-size: ${isSmall ? `${pxToRem(84)}` : `${pxToRem(120)}`};
        block-size: ${isSmall ? `${pxToRem(84)}` : `${pxToRem(120)}`};
    `
    )}
    ${zoomPortrait(
      [200],
      `
        inline-size: ${isSmall ? `${pxToRem(112)}` : `${pxToRem(160)}`};
        block-size: ${isSmall ? `${pxToRem(112)}` : `${pxToRem(160)}`};
    `
    )}
  `}
  & > svg {
    stroke: none;
  }
`
