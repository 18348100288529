import { tablet, zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import React from 'react'
import styled from 'styled-components'
import { ColorSwatchProps } from './types'

export const ColorSwatchWrapper = styled.div<{
  background?: string
}>`
  background: ${({ background }) => background};
  flex-shrink: 0;
  inline-size: ${pxToRem(82)};
  block-size: ${pxToRem(82)};
  outline: ${pxToRem(1)} solid ${({ theme }) => theme.colors.outline};
  outline-offset: ${pxToRem(3)};
  border: ${pxToRem(2)} solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: 50%;
  overflow: hidden;
  ${tablet(`
    inline-size: ${pxToRem(68)};
    block-size: ${pxToRem(68)};
  `)}
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(123)};
    block-size: ${pxToRem(123)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(164)};
    block-size: ${pxToRem(164)};
    `
  )}
`

export const ColorSwatch: React.FC<ColorSwatchProps> = ({ background, children }) => {
  return <ColorSwatchWrapper background={background}>{children}</ColorSwatchWrapper>
}
