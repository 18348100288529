import React, { FC } from 'react'
import { IconProps } from '@abstractTypes/icons'
import {
  AccessoriesIcon,
  ApparelIcon,
  BaseballIcon,
  CyclingIcon,
  EyewearIcon,
  FishingIcon,
  FootwearIcon,
  GolfIcon,
  HelmetsIcon,
  Icon6S,
  MountainAndTrailIcon,
  NflIcon,
  OakleyCustomIcon,
  PrizmIcon,
  SnowIcon,
  SportIcon,
  SurfIcon,
  TargetAndHuntingIcon,
  TrainingIcon,
} from '@components/core/Icons/Menu'
import EliminatesGlareIcon from '@components/core/Icons/EliminatesGlareIcon'
import ReducesEyeStrain from '@components/core/Icons/ReducesEyeStrain'
import IncreasesVisualClarity from '@components/core/Icons/IncreasesVisualClarity'
import MinimizesColorDistortion from '@components/core/Icons/MinimizesColorDistortion'
import {
  AntistaticIcon,
  CategoryAccessoriesIcon,
  CategoryApparelIcon,
  CategoryBaseballIcon,
  CategoryCyclingIcon,
  CategoryEyewearIcon,
  CategoryFishingIcon,
  CategoryFootwearIcon,
  CategoryGolfIcon,
  CategoryHelmetsIcon,
  CategoryMountainandtrailIcon,
  CategoryNflIcon,
  CategoryOakleyCustomIcon,
  CategoryPrizmIcon,
  CategorySnowIcon,
  CategorySportIcon,
  CategorySurfIcon,
  CategoryTargetandhuntingIcon,
  CategoryTrainingIcon,
  HydrophobicIcon,
  InnerglarereductionIcon,
  OleophobicIcon,
  OuterglarereductionIcon,
} from '@components/core/Icons'

export enum Icons {
  id_icon_6S = 'id_icon_6S',
  id_icon_eyewear = 'id_icon_eyewear',
  id_icon_apparel = 'id_icon_apparel',
  id_icon_accessories = 'id_icon_accessories',
  id_icon_footwear = 'id_icon_footwear',
  id_icon_helmets = 'id_icon_helmets',
  id_icon_sport = 'id_icon_sport',
  id_icon_prizm = 'id_icon_prizm',
  id_icon_oakleycustom = 'id_icon_oakleycustom',
  id_icon_training = 'id_icon_training',
  id_icon_golf = 'id_icon_golf',
  id_icon_surf = 'id_icon_surf',
  id_icon_baseball = 'id_icon_baseball',
  id_icon_cycling = 'id_icon_cycling',
  id_icon_fishing = 'id_icon_fishing',
  id_icon_mountainandtrail = 'id_icon_mountainandtrail',
  id_icon_nfl = 'id_icon_nfl',
  id_icon_snow = 'id_icon_snow',
  id_icon_targetandhunting = 'id_icon_targetandhunting',
  id_icon_SunIcon = 'id_icon_SunIcon',
  id_icon_EyeIcon = 'id_icon_EyeIcon',
  id_icon_ClarityIcon = 'id_icon_ClarityIcon',
  id_icon_ColorIcon = 'id_icon_ColorIcon',
  id_icon_hydrophobic = 'id_icon_hydrophobic',
  id_icon_oleophobic = 'id_icon_oleophobic',
  id_icon_antistatic = 'id_icon_antistatic',
  id_icon_inner_glare_reduction = 'id_icon_inner_glare_reduction',
  id_icon_outer_glare_reduction = 'id_icon_outer_glare_reduction',
}

export type CMSIconKey = keyof typeof Icons

export const iconMap: Record<CMSIconKey, React.FC<IconProps>> = {
  [Icons.id_icon_6S]: Icon6S,
  [Icons.id_icon_eyewear]: EyewearIcon,
  [Icons.id_icon_apparel]: ApparelIcon,
  [Icons.id_icon_accessories]: AccessoriesIcon,
  [Icons.id_icon_footwear]: FootwearIcon,
  [Icons.id_icon_helmets]: HelmetsIcon,
  [Icons.id_icon_sport]: SportIcon,
  [Icons.id_icon_prizm]: PrizmIcon,
  [Icons.id_icon_oakleycustom]: OakleyCustomIcon,
  [Icons.id_icon_training]: TrainingIcon,
  [Icons.id_icon_golf]: GolfIcon,
  [Icons.id_icon_surf]: SurfIcon,
  [Icons.id_icon_baseball]: BaseballIcon,
  [Icons.id_icon_cycling]: CyclingIcon,
  [Icons.id_icon_fishing]: FishingIcon,
  [Icons.id_icon_mountainandtrail]: MountainAndTrailIcon,
  [Icons.id_icon_nfl]: NflIcon,
  [Icons.id_icon_snow]: SnowIcon,
  [Icons.id_icon_targetandhunting]: TargetAndHuntingIcon,
  [Icons.id_icon_SunIcon]: EliminatesGlareIcon,
  [Icons.id_icon_EyeIcon]: ReducesEyeStrain,
  [Icons.id_icon_ClarityIcon]: IncreasesVisualClarity,
  [Icons.id_icon_ColorIcon]: MinimizesColorDistortion,
  [Icons.id_icon_hydrophobic]: HydrophobicIcon,
  [Icons.id_icon_oleophobic]: OleophobicIcon,
  [Icons.id_icon_antistatic]: AntistaticIcon,
  [Icons.id_icon_inner_glare_reduction]: InnerglarereductionIcon,
  [Icons.id_icon_outer_glare_reduction]: OuterglarereductionIcon,
}

const categoryIconMap: Record<CMSIconKey, React.FC<IconProps>> = {
  ...iconMap,
  [Icons.id_icon_eyewear]: CategoryEyewearIcon,
  [Icons.id_icon_apparel]: CategoryApparelIcon,
  [Icons.id_icon_accessories]: CategoryAccessoriesIcon,
  [Icons.id_icon_footwear]: CategoryFootwearIcon,
  [Icons.id_icon_helmets]: CategoryHelmetsIcon,
  [Icons.id_icon_sport]: CategorySportIcon,
  [Icons.id_icon_prizm]: CategoryPrizmIcon,
  [Icons.id_icon_oakleycustom]: CategoryOakleyCustomIcon,
  [Icons.id_icon_training]: CategoryTrainingIcon,
  [Icons.id_icon_golf]: CategoryGolfIcon,
  [Icons.id_icon_surf]: CategorySurfIcon,
  [Icons.id_icon_baseball]: CategoryBaseballIcon,
  [Icons.id_icon_cycling]: CategoryCyclingIcon,
  [Icons.id_icon_fishing]: CategoryFishingIcon,
  [Icons.id_icon_mountainandtrail]: CategoryMountainandtrailIcon,
  [Icons.id_icon_nfl]: CategoryNflIcon,
  [Icons.id_icon_snow]: CategorySnowIcon,
  [Icons.id_icon_targetandhunting]: CategoryTargetandhuntingIcon,
}

export const CMSIcon: FC<
  {
    iconKey: CMSIconKey
    isCategoryIcon?: boolean
  } & IconProps
> = ({ iconKey, isCategoryIcon = false, ...iconProps }) => {
  const Icon = !isCategoryIcon ? iconMap[iconKey] : categoryIconMap[iconKey]

  return <Icon {...iconProps} />
}
