import { CaretDownThinIcon } from '@components/core/Icons'
import { Text } from '@components/core/Typography'
import { useClickOutside } from '@hooks/useClickOutside'
import { useThemeContext } from '@hooks/useThemeContext'
import { pxToRem } from '@libs/styled'
import React, { useEffect, useRef, useState } from 'react'
import {
  OptionSlyled,
  SelectDropdown,
  SelectInnerStyled,
  SelectStyled,
  SelectWrapperStyled,
} from './styles'
import { SelectProps } from './types'

export const Select: React.FC<SelectProps> = ({
  className = '',
  placeholder = 'Select',
  defaultValue,
  onSelect,
  forceVisible,
  children,
  value: valueProp,
}) => {
  const theme = useThemeContext()
  const [value, setValue] = useState<string | number>(valueProp || defaultValue || '')
  const [displayValue, setDisplayValue] = useState<string | number>(placeholder || '')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (typeof forceVisible !== 'undefined') {
      setIsOpen(forceVisible)
    }
  }, [forceVisible])

  useEffect(() => {
    setValue(valueProp || '')
  }, [valueProp])

  const innerRef = useRef<HTMLDivElement | null>(null)
  useClickOutside(innerRef, () => setIsOpen(false))

  const onSetValue = (val: string | number) => {
    setValue(val)
    onSelect && onSelect(val)
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const props = { onSetValue, defaultValue: value, setDisplayValue }
      return React.cloneElement(child, props)
    }
    return child
  })

  return (
    <SelectStyled className={className} onClick={() => setIsOpen(o => !o)}>
      <SelectInnerStyled
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={isOpen}
        tabIndex={0}
        ref={innerRef}
      >
        <SelectWrapperStyled>
          <Text color="secondaryMedium">{displayValue}</Text>
          <CaretDownThinIcon
            width={pxToRem(12)}
            height={pxToRem(14)}
            stroke={theme.colors.primaryDark}
          />
        </SelectWrapperStyled>
        <SelectDropdown style={{ inlineSize: innerRef.current?.offsetWidth }} visible={isOpen}>
          {childrenWithProps}
        </SelectDropdown>
      </SelectInnerStyled>
    </SelectStyled>
  )
}

interface SelectOptionProps {
  onSetValue?: (val: string | number) => void
  setDisplayValue?: (val: string | number) => void
  value: string | number
  defaultValue?: string | number
}

export const SelectOption: React.FC<SelectOptionProps> = ({
  onSetValue,
  setDisplayValue,
  defaultValue,
  value,
  children,
  ...rest
}) => {
  function findLastChildTextNode(children: React.ReactNode): string {
    if ((children as React.ReactElement)?.props) {
      return findLastChildTextNode((children as React.ReactElement)?.props.children)
    }
    return children as string
  }

  const displayValue = findLastChildTextNode(children)

  useEffect(() => {
    if (!defaultValue) return
    if (defaultValue === value) {
      setDisplayValue?.(displayValue)
    }
  }, [setDisplayValue, defaultValue, value, displayValue])

  return (
    <OptionSlyled onClick={() => onSetValue?.(value)} {...rest}>
      {children}
    </OptionSlyled>
  )
}
