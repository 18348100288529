import { isArray, isEmpty, mergeWith, omitBy, uniq } from 'lodash'

// Input arg 1
// {
//   type: ['opt'],
//   frameColor: 'red'
// }

// Input arg 2
// {
//   type: ['sun']
// }

// Output
// {
//   type: ['opt', 'sun'],
//   frameColor: 'red'
// }
const concatArray = (objValue: unknown, srcValue: unknown) => {
  const isObjValueEmpty = isEmpty(objValue)
  const isSrcValueEmpty = isEmpty(srcValue)
  if (isObjValueEmpty && isSrcValueEmpty) return
  if (isArray(objValue) && !isSrcValueEmpty) {
    return uniq(objValue.concat(srcValue))
  }
  if (isArray(srcValue) && !isObjValueEmpty) {
    return uniq([objValue].concat(srcValue))
  }

  return
}

const deepMerge = <T extends Record<string, unknown>>(...arr: T[]): Partial<T> =>
  omitBy(mergeWith({}, ...arr, concatArray), isEmpty)

export default deepMerge
