import { DeploymentEnv } from '@abstractTypes/envs'

const getDeploymentEnvFromDns = (dns: string): DeploymentEnv => {
  const [, env] = dns.split('-')
  const partialDeploymentEnvList = ['dev', 'test', 'uat', 'stage']

  if (env) {
    if (partialDeploymentEnvList.includes(env)) return env as DeploymentEnv
  }

  return 'prod'
}

export default getDeploymentEnvFromDns
