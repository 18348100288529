import { BadgeChip } from '@components/CardProductBase/BadgeChips'
import { RadioInput } from '@components/FiltersDrawer/filters.style'
import { PrimaryButton } from '@components/core/Button'
import { Modal } from '@components/core/Modal'
import { ModalBody, ModalBodyWrapper, ModalContent } from '@components/core/Modal/styles'
import { Text } from '@components/core/Typography'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const PurchaseConfigureModalStyled = styled(Modal)`
  h3 {
    font-weight: 400;
  }
  ${altNavigation(`
    inset-block-start: ${pxToRem(80)};
  `)}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    inset-block-start: 0;
    block-size: 100%;
  `
  )}

  ${ModalContent} {
    ${altNavigationZoom(
      [150, 175, 200],
      `
      block-size: 100%;
    `
    )}
  }

  ${ModalBody} {
    padding-block: ${pxToRem(80)};
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding-block: ${pxToRem(80)};
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      inline-size: ${pxToRem(1090)};
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      inline-size: 100%;
    `
    )}
  }

  ${ModalBodyWrapper} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 0;
    padding-inline: ${pxToRem(103)};
    ${tablet(`
      padding: 0;
    `)}
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline:  ${pxToRem(74)};
      
    `
    )}
    ${zoomPortrait(
      [200],
      `
      padding: 0;
    `
    )}
    ${altNavigationZoom(
      [150, 175],
      `
      padding: 0;
    `
    )}
  }
`

export const IconContainer = styled.span`
  margin-inline-start: ${pxToRem(24)};
  ${zoomPortrait(
    [150, 175, 200],
    `
      margin-inline-start: 0;
    `
  )}
`
export const InfoText = styled(Text)`
  display: flex;
  flex-direction: column;
  text-align: start;
`

export const FirstColumn = styled.div`
  display: flex;
  padding-inline-end: 2rem;
  justify-content: flex-start;
  align-items: center;

  .prescription-container & {
    flex-basis: 5%;
    margin-inline-end: ${pxToRem(16)};
  }
`

export const SecondColumn = styled.div`
  display: flex;
  inline-size: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;

  ${zoomPortrait(
    [150, 175, 200],
    `
    max-inline-size: unset;
    `
  )}

  .prescription-container & {
    flex-basis: 95%;
  }

  > h5 {
    text-align: start;
  }
`

export const OptionBox = styled.div<{ checked?: boolean; disabled?: boolean }>`
  border: 2px solid
    ${({ checked, theme }) => (checked ? theme.colors.highlight02 : theme.colors.outline)};
  border-color: ${({ disabled, theme }) => (disabled ? theme.colors.inactive : 'none')};
  display: flex;
  justify-content: space-around;
  align-items: center;
  inline-size: fit-content;
  padding-block: ${pxToRem(16)};
  padding-inline: ${pxToRem(24)};
  margin-block-end: ${pxToRem(32)};
  inline-size: 100%;

  ${zoomPortrait(
    [200],
    `
    margin-block-end: ${pxToRem(48)};
  `
  )}

  .prescription-container & {
    border: none;
    inline-size: 100%;
    block-size: auto;
    padding-block: ${pxToRem(6)};
    padding-inline: 0;
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    border-color:${theme.colors.inactive}
    h5,p {
      color: ${theme.colors.inactive}
    }
    input[type='radio'] {
      border-color: ${theme.colors.inactive}
    }
    svg > path {
      fill: ${theme.colors.inactive}
    }
  `}
`

export const RadioInputStyled = styled(RadioInput)`
  margin: 0;
  ${zoomPortrait(
    [200],
    `
    margin-inline-start: ${pxToRem(24)};
  `
  )}
`

export const PurchaseCtaButton = styled(PrimaryButton)`
  text-transform: none;
  margin-block-start: ${pxToRem(20)};
  ${landscape(`
    margin-block-start: ${pxToRem(32)};
  `)}
  ${tablet(`
    margin-block-start: ${pxToRem(32)};
  `)}
`

export const BadgeChipStyled = styled(BadgeChip)<{ disabled?: boolean }>`
  display: flex;
  inline-size: fit-content;
  padding-block: ${pxToRem(4)};
  padding-inline: ${pxToRem(12)};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.inactive : 'none')};
  border-color: ${({ disabled, theme }) => (disabled ? theme.colors.inactive : 'none')};
  margin: 0;
`
