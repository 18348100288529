import { PurchaseConfigureModalStyled } from '@components/PurchaseConfigure/styles'
import { PilotIcon, WithPrescriptionIcon } from '@components/core/Icons'
import { MixAndMatch } from '@components/core/Icons/MixAndMatch'
import { Heading3 } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useThemeContext } from '@hooks/useThemeContext'
import { pxToRem } from '@libs/styled'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PurchaseConfigureContent from '../PurchaseConfigureContent'
import { ConfigTypes, Option, PurchaseConfigureModalProps, SwitchTypes } from '../types'
import { useSendVirtualPageView } from './analytics'

const PurchaseConfigureModal: React.FC<PurchaseConfigureModalProps> = ({
  showPrescriptionSwitch,
  showMixMatchSwitch,
  onSubmit,
  onClose,
  visible,
  hasPrescriptionStock,
  hasNonPrescriptionStock,
  product,
}) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState<string>()
  const [activeOption, setActiveOption] = useState<ConfigTypes | null>(null)

  const tag = 'PurchaseConfigure'
  const translations = {
    title: `${tag}.title`,
    addPrescriptionTitle: `${tag}.addPrescriptionTitle`,
    addPrescriptionDescription: `${tag}.addPrescriptionDescription`,
    addMixMatchTitle: `${tag}.addMixMatchTitle`,
    addMixMatchDescription: `${tag}.addMixMatchDescription`,
  }

  const { zoomLevel } = useNavigationContext()
  const theme = useThemeContext()

  const iconProps = useMemo(
    () => ({
      width: pxToRem(48, zoomLevel / 100),
      height: pxToRem(48, zoomLevel / 100),
      fill: theme.colors.primaryDark,
      stroke: 'none',
    }),
    [theme.colors.primaryDark, zoomLevel]
  )

  useEffect(() => {
    if (showMixMatchSwitch) {
      setTitle(t(translations.title))
    } else if (showPrescriptionSwitch) {
      setTitle(t('Prescription.modalTitle'))
    }
  }, [
    hasNonPrescriptionStock,
    hasPrescriptionStock,
    showMixMatchSwitch,
    showPrescriptionSwitch,
    t,
    translations.title,
  ])

  useEffect(() => {
    setActiveOption(null)
  }, [visible])

  const optionsConfig = useMemo(() => {
    const tempConfig: Option[] = []
    tempConfig.push({
      id: ConfigTypes.nonPrescription,
      icon: <PilotIcon {...iconProps} />,
      title: t('Prescription.modalNonPrescription'),
      text: t('Prescription.modalNonPrescriptionText'),
      type: SwitchTypes.radio,
      disabled: !hasNonPrescriptionStock,
      dataAttrs: { id: 'RXModal_AddCart' },
    })
    if (showMixMatchSwitch) {
      setTitle(t(translations.title))
      tempConfig.push({
        id: ConfigTypes.mixMatch,
        icon: <MixAndMatch {...iconProps} />,
        title: t(translations.addMixMatchTitle),
        text: t(translations.addMixMatchDescription),
        type: SwitchTypes.radio,
        disabled: !hasPrescriptionStock,
        dataAttrs: { id: 'RXModal_AddPrescription' },
      })
    } else if (showPrescriptionSwitch) {
      setTitle(t('Prescription.modalTitle'))
      tempConfig.push({
        id: ConfigTypes.prescription,
        icon: <WithPrescriptionIcon {...iconProps} />,
        title: t('Prescription.modalWithPrescription'),
        text: t('Prescription.modalWithPrescriptionText'),
        type: SwitchTypes.radio,
        disabled: !hasPrescriptionStock,
        dataAttrs: { id: 'RXModal_AddPrescription' },
      })
    }
    return tempConfig
  }, [
    hasNonPrescriptionStock,
    hasPrescriptionStock,
    iconProps,
    showMixMatchSwitch,
    showPrescriptionSwitch,
    t,
    translations.addMixMatchDescription,
    translations.addMixMatchTitle,
    translations.title,
  ])

  useSendVirtualPageView(visible, hasPrescriptionStock, hasNonPrescriptionStock)

  return (
    <PurchaseConfigureModalStyled
      centered
      visible={visible}
      onCancel={onClose}
      title={<Heading3>{title}</Heading3>}
    >
      <PurchaseConfigureContent
        options={optionsConfig}
        onSubmit={onSubmit}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        product={product}
      />
    </PurchaseConfigureModalStyled>
  )
}

export default PurchaseConfigureModal
