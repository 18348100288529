import {
  CatIcon,
  OvalIcon,
  PilotIcon,
  RectangleIcon,
  RoundIcon,
  SquareIcon,
} from '@components/core/Icons'
import { Text } from '@components/core/Typography'
import { useThemeContext } from '@hooks/useThemeContext'
import { tablet } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { MessageBox, TipImageWrapper } from '../styles'

const FrameShapeIconContainer = styled.div`
  inline-size: 30%;
  padding: ${pxToRem(20)};
  ${tablet(`
    padding: ${clampValues(15, 20)};
  `)}
`

const translationKey = 'FrameAdvisor.tips.frameShapeTip'

const frameIcons = [RectangleIcon, SquareIcon, RoundIcon, CatIcon, PilotIcon, OvalIcon]

const iconWidth = isIPadView() ? clampValues(36, 48) : pxToRem(48)
const iconHeight = isIPadView() ? clampValues(16, 22) : pxToRem(22)

export const FrameShapeTipContent: React.FC = () => {
  const { t } = useTranslation()
  const theme = useThemeContext()

  return (
    <>
      <MessageBox>
        <Text>{t(`${translationKey}.title`)}</Text>
      </MessageBox>
      <TipImageWrapper>
        {frameIcons.map((Icon, i) => (
          <FrameShapeIconContainer key={i}>
            <Icon
              width={iconWidth}
              height={iconHeight}
              fill={theme.colors.primaryDark}
              stroke="none"
            />
          </FrameShapeIconContainer>
        ))}
      </TipImageWrapper>
      <Text>{t(`${translationKey}.text`)}</Text>
    </>
  )
}
