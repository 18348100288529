import { DeviceImageRatio } from '@abstractTypes/image'
import { ContentImage } from '@components/Image'
import { NukaCarousel } from '@components/NukaCarousel'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import React, { useMemo } from 'react'
import NuanceVideoSlide from './NuanceVideoSlide'
import { LoadingScreen, NuanceSlide, NuanceSliderContainer, NuanceTitle } from './styles'
import { NuanceSliderProps } from './types'

const NuanceSlider: React.FC<NuanceSliderProps> = ({
  tabs = [],
  spiner,
  isTabsExpanded,
  updating = false,
  slideIndex,
  onSwipe,
}) => {
  const rtlDirection = useLanguageDirection()
  const imageRatio: DeviceImageRatio = useMemo(
    () =>
      isTabsExpanded
        ? { tower: '_16x9', towerLandscape: '_1x1', ipad: '_16x9', ipadlandscape: '_1x1' }
        : { tower: '_3x4', towerLandscape: '_1x1', ipad: '_1x1', ipadlandscape: '_1x1' },
    [isTabsExpanded]
  )

  const slides = tabs.map((tab, index) => {
    const video = tab?.hero?.video
    const image = tab?.hero?.image
    return (
      <NuanceSlide key={tab.id}>
        <NuanceTitle color="primaryDark" weight="bold">
          {tab.title}
        </NuanceTitle>
        {video ? (
          <NuanceVideoSlide
            videoList={[video]}
            poster={image}
            index={rtlDirection ? tabs.length - 1 - index : index}
            slideIndex={slideIndex}
          />
        ) : (
          <ContentImage
            imageSource={image}
            aria-description={image?.altText || ''}
            aria-label={image?.altText || ''}
            ratio={imageRatio}
          />
        )}
      </NuanceSlide>
    )
  })

  return (
    <NuanceSliderContainer isTabsExpanded={isTabsExpanded}>
      <NukaCarousel
        slideIndex={slideIndex}
        loop={false}
        disableEdgeSwiping={true}
        autoplayReverse={false}
        beforeSlide={(_i: any, n: number) => onSwipe(n)}
      >
        {slides}
      </NukaCarousel>
      {updating && <LoadingScreen>{spiner}</LoadingScreen>}
    </NuanceSliderContainer>
  )
}

export default NuanceSlider
