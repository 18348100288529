import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ElectronicsVideoSlideProps } from './types'

const ElectronicsVideoSlide: React.FC<ElectronicsVideoSlideProps> = ({
  videoList,
  poster,
  index,
  slideIndex,
  setVideoLoaded,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [currentVideoIndex, setCurrentVideo] = useState(0)

  useEffect(() => {
    if (videoRef.current) {
      if (index === slideIndex) {
        videoRef.current.currentTime = 0
        const playPromise = videoRef.current?.play()
        if (playPromise !== undefined) {
          playPromise

            .then(() => {
              // Automatic playback started
            })
            .catch(() => {
              // Auto-play was prevented
            })
        }
        setCurrentVideo(0)
      } else {
        videoRef.current?.pause()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideIndex])

  useEffect(() => {
    const videoElement = videoRef.current
    if (Boolean(videoList.length) && videoElement) {
      videoElement.defaultMuted = true
      const setNewVideo = () => {
        setCurrentVideo(nowPlayed => {
          return nowPlayed === videoList.length - 1 ? 0 : nowPlayed + 1
        })
        const playPromise = videoElement?.play()

        if (playPromise !== undefined) {
          playPromise

            .then(() => {
              // Automatic playback started
            })
            .catch(() => {
              // Auto-play was prevented
            })
        }
      }
      videoElement?.addEventListener('ended', setNewVideo)
      const dataLoaded = () => {
        setVideoLoaded(index)
      }
      videoElement?.addEventListener('loadeddata', dataLoaded)
      return () => {
        videoElement?.removeEventListener('ended', setNewVideo)
        videoElement?.removeEventListener('loadeddata', dataLoaded)
      }
    }

    return () => null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoList])

  const videoElem = useMemo(() => {
    return (
      <video
        ref={videoRef}
        muted
        loop={videoList.length === 0}
        autoPlay={false}
        preload="auto"
        poster={poster?.src}
      >
        <source src={videoList[currentVideoIndex]?.src} type={videoList[currentVideoIndex]?.type} />
      </video>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef])

  return videoElem
}

export default ElectronicsVideoSlide
