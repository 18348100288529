import { IconProps } from '@abstractTypes/icons'
export const FaFaceShapeSquareWomanIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M47.707 36.293C45.736 34.322 45.001 25.867 45 21C45 9.42 35.58 0 24 0C12.42 0 3 9.42 3 21C2.999 25.867 2.264 34.322 0.293 36.293L0 36.586V37C0 43.065 4.935 48 11 48C13.365 48 15.553 47.242 17.349 45.967C17.565 45.98 17.78 46 18 46H30C30.22 46 30.435 45.98 30.651 45.967C32.447 47.242 34.635 48 37 48C43.065 48 48 43.065 48 37V36.586L47.707 36.293ZM18 44C13.038 44 9 39.962 9 35V19.963C14.746 19.542 18.918 15.538 20.405 11.809C22.412 14.284 27.276 17.869 39 19.85V35C39 39.962 34.962 44 30 44H18ZM37 46C35.825 46 34.705 45.767 33.674 45.355C37.936 43.838 41 39.775 41 35V19L40.156 18.012C22.377 15.205 20.999 8.901 20.99 8.858L19 9C19 12.54 14.276 18 8 18L7 19V35C7 39.775 10.064 43.838 14.326 45.355C13.295 45.767 12.175 46 11 46C6.161 46 2.202 42.162 2.007 37.37C4.914 33.734 5 22.329 5 21C5 10.523 13.523 2 24 2C34.477 2 43 10.523 43 21C43 22.329 43.086 33.734 45.993 37.37C45.798 42.162 41.839 46 37 46Z"
        fill={fill}
      />
      <path d="M26.434 23.057H24.434V31H21.986V33H26.434V23.057Z" fill={fill} />
      <path
        d="M27.312 36.928L24 35.958L20.688 36.928L21.879 38.12C22.445 38.688 23.198 39 24 39C24.802 39 25.555 38.688 26.122 38.121L27.312 36.928Z"
        fill={fill}
      />
    </svg>
  )
}
