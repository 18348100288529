import { getSiteAbsolutePath, getSiteOrigin } from '@libs/url'
import { isIPadView } from '@libs/utils'
import { get } from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export interface UseBarcode {
  assetsLogoSrc: string
  timeout: number
}

declare global {
  interface Window {
    webkit?: {
      messageHandlers?: {
        iosClickListener?: {
          postMessage: (args: unknown) => void
        }
      }
    }
  }
}

export const isBarcodeScannerAvailable = () =>
  isIPadView() && !!window.webkit && get(window, 'webkit.messageHandlers.iosClickListener')

export const useBarcodeScanner = ({ timeout, assetsLogoSrc }: UseBarcode) => {
  const { t } = useTranslation()
  const basePath = getSiteAbsolutePath()
  const origin = getSiteOrigin()

  return useCallback(() => {
    const iosClickListener = get(window, 'webkit.messageHandlers.iosClickListener')
    iosClickListener &&
      iosClickListener.postMessage({
        action: 'open_scanner',
        logo: assetsLogoSrc,
        topLabel: t('Barcode.topLabel'),
        bottomLabel: t('Barcode.bottomLabel'),
        returnUrl: `${origin}${basePath}/scan-barcode`,
        timeout: timeout.toString(),
      })
  }, [t, timeout, origin, basePath, assetsLogoSrc])
}
