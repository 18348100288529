import { Text } from '@components/core/Typography'
import { zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const ZoomButtonsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  justify-content: end;
`

export const ZoomButton = styled.button<{ disabled?: boolean }>`
  inline-size: ${pxToRem(48)};
  block-size: ${pxToRem(48)};
  border-radius: 50%;
  border: 1px solid
    ${({ theme, disabled }) => (disabled ? theme.colors.inactive : theme.colors.primaryDark)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(72)};
    block-size: ${pxToRem(72)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(96)};
    block-size: ${pxToRem(96)};
  `
  )}
`

export const ZoomText = styled(Text)`
  text-align: center;
  margin-block: 0;
  margin-inline: ${pxToRem(32)};
  ${zoomPortrait(
    [150, 175],
    `
    margin-block: 0;
    margin-inline: ${pxToRem(48)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    margin-block: 0
    margin-inline: ${pxToRem(64)};
  `
  )}
`
