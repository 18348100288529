import ConditionalRender from '@components/ConditionalRender'
import { zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import { FC } from 'react'
import styled from 'styled-components'
import { ToggleProps } from './types'

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: ${pxToRem(10)};
  cursor: pointer;
  pointer-events: none;
`

const Switch = styled.div`
  position: relative;
  inline-size: ${pxToRem(39)};
  block-size: ${pxToRem(24)};
  background: #b3b3b3;
  border-radius: ${pxToRem(32)};
  padding: ${pxToRem(4)};
  transition: 300ms all;
  ${zoomPortrait(
    [150, 175],
    `
    inline-size: ${pxToRem(58)};
    block-size: ${pxToRem(36)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    inline-size: ${pxToRem(78)};
    block-size: ${pxToRem(48)};
  `
  )}

  &::before {
    transition: 300ms all;
    content: '';
    position: absolute;
    inline-size: ${pxToRem(20)};
    block-size: ${pxToRem(20)};
    border-radius: ${pxToRem(35)};
    inset-block-start: 50%;
    inset-inline-start: ${pxToRem(2)};
    background: white;
    transform: translate(0, -50%);
    ${zoomPortrait(
      [150, 175],
      `
      inline-size: ${pxToRem(30)};
      block-size: ${pxToRem(30)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inline-size: ${pxToRem(40)};
      block-size: ${pxToRem(40)};
    `
    )}
  }
`

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${({ theme }) => theme.colors.highlight02};

    &::before {
      transform: translate(16px, -50%);

      [dir='rtl'] & {
        transform: translate(-16px, -50%);
      }

      ${zoomPortrait(
        [150, 175],
        `
        transform: translate(${pxToRem(24)}, -50%);
      `
      )}
      ${zoomPortrait(
        [200],
        `
        transform: translate(${pxToRem(32)}, -50%);
      `
      )}
    }
  }
`

export const Toggle: FC<ToggleProps> = ({ label, checked }) => {
  return (
    <Label>
      <ConditionalRender condition={label} render={label => <span>{label}</span>} />

      <Input checked={checked} readOnly={true} type="checkbox" />
      <Switch />
    </Label>
  )
}
