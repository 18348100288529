import { CartMerchRecommenderAccordionProps } from '@components/CartMerchRecommender/CartMerchRecommenderAccordion/types'
import MerchCardComponent from '@components/CartMerchRecommender/MerchCardComponent'
import {
  MerchAccordionCartPageStyled,
  MerchAccordionStyled,
} from '@components/CartMerchRecommender/styles'
import { MerchAccordionProps } from '@components/CartMerchRecommender/types'
import { CaretUpThinIcon } from '@components/core/Icons'
import { useDeviceType } from '@hooks/useDeviceType'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { pxToRem } from '@libs/styled'
import { usePageChecker } from '@libs/utils'
import { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CartMerchCarousel } from '../CartMerchCarousel'
import { MerchCardContainer } from '../MerchCardComponent/styles'

const MerchAccordion: React.FC<MerchAccordionProps> = ({ children, label }) => {
  const { landscapeNavigation, zoomLevel } = useNavigationContext()
  const [isOpened, setIsOpened] = useState(!landscapeNavigation)
  const targetRef = useRef<HTMLElement | null>(null)
  const { isCartPage } = usePageChecker()

  const handleAccordionClick = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      setIsOpened(!isOpened)
      targetRef.current = ev.target as HTMLElement
    },
    [isOpened]
  )

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        targetRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 300)
    }
  }, [isOpened])

  const AccordionStyled = useMemo(
    () => (isCartPage ? MerchAccordionCartPageStyled : MerchAccordionStyled),
    [isCartPage]
  )

  return (
    <AccordionStyled
      useFocusTrap
      isOpened={isOpened}
      renderAccordionIcon={() => (
        <CaretUpThinIcon
          height={pxToRem(32, zoomLevel / 100)}
          width={pxToRem(32, zoomLevel / 100)}
        />
      )}
      title={label}
      onTitleClick={handleAccordionClick}
    >
      {children}
    </AccordionStyled>
  )
}

export const CartMerchRecommenderAccordion: FC<CartMerchRecommenderAccordionProps> = ({
  products,
}) => {
  const { t } = useTranslation()
  const { isTowerLandscape } = useDeviceType()
  const productAmount = products.length
  const { isCartPage } = usePageChecker()
  const showInline = isCartPage && isTowerLandscape && productAmount <= 3

  return (
    <MerchAccordion label={t('NewMerch.title')}>
      {showInline ? (
        <MerchCardContainer>
          {products.map((item, i) => (
            <MerchCardComponent key={i} product={item} />
          ))}
        </MerchCardContainer>
      ) : (
        <CartMerchCarousel products={products} />
      )}
    </MerchAccordion>
  )
}
