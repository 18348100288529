import { VmIcon } from '@components/core/Icons'
import { Heading5, Text } from '@components/core/Typography'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import { MessageBox, TipImageWrapper } from '../styles'

const translationKey = 'FrameAdvisor.tips.vmTip'

const iconSize = isIPadView() ? clampValues(74, 100) : pxToRem(100)

export const VMTipContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <MessageBox>
        <Text>{t(`${translationKey}.title`)}</Text>
      </MessageBox>
      <TipImageWrapper>
        <VmIcon width={iconSize} height={iconSize} />
      </TipImageWrapper>
      <Heading5 weight="bold">{t(`${translationKey}.text`)}</Heading5>
    </>
  )
}
