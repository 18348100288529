import { Device } from '@abstractTypes/utils'
import { Tabs, TabsWithAccordion } from '@components/Tabs'
import {
  AccordionSingleStyled,
  ScrollableContentContainer,
  ScrollablePaneContainer,
  TabPane,
} from '@components/Tabs/styles'
import { AccordionContent, AccordionTitle } from '@components/core/Accordion/styles'
import { altNavigation, landscape, tablet, zoomLandscape } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

const tabsListFixedStyles = css`
  position: fixed;
  inset-inline-start: 0;
  inset-inline-end: 0;
  max-inline-size: none;
  padding-block: ${pxToRem(32)};
  padding-inline: 0;
  ${altNavigation(`
    inset-block-start: 56vh;
  `)}
`

export const TabsWithAccordionStyled = styled(TabsWithAccordion)<{
  isEditorial?: boolean
  hasLightBackground?: boolean
  isTabsListFixed?: boolean
}>`
  block-size: fit-content;

  ${landscape(`
    block-size: 100%;
  `)}

  ${AccordionSingleStyled} {
    position: relative;
    background-color: ${({ theme, isEditorial, hasLightBackground }) =>
      isEditorial || hasLightBackground ? theme.colors.primaryLight : theme.colors.background};

    ${AccordionTitle} {
      border-block-end: none;
      h4 {
        border-block-end: ${({ theme }) => `3px solid ${theme.colors.primaryDark}`};
      }
    }

    ${AccordionContent} {
      background-color: ${({ theme }) => theme.colors.primaryLight};
      box-shadow: ${({ theme }) =>
        `${pxToRem(-1)} ${pxToRem(5)} ${pxToRem(19)} ${pxToRem(-5)} ${theme.colors.primaryDark}`};
      inline-size: auto;
      margin-block-start: ${pxToRem(16)};
      padding-block: 0;
      padding-inline: ${pxToRem(24)};
      border-start-start-radius: 0;
      border-start-end-radius: 0;
      border-end-end-radius: ${pxToRem(32)};
      border-end-start-radius: ${pxToRem(32)};

      > button {
        inline-size: auto;
        text-align: start;
        margin-block: ${pxToRem(12)};
        margin-inline: 0;
        h4 {
          display: inline-block;
        }
      }
    }
  }

  ${ScrollablePaneContainer} {
    position: static;
    inset-block-start: 0;
    display: flex;
    justify-content: center;
    min-block-size: 0;
    overflow: visible;
    opacity: 1;
    background-color: ${({ theme, isEditorial, hasLightBackground }) =>
      isEditorial || hasLightBackground ? theme.colors.primaryLight : theme.colors.background};
    z-index: 2;
    inline-size: 100%;
    padding-block-start: ${({ isEditorial }) => (isEditorial ? pxToRem(9) : 0)};
    ${({ isEditorial }) => `
      ${landscape(`
        padding-block-start: ${isEditorial ? pxToRem(9) : pxToRem(44)};
        max-inline-size: 100%
      `)}
    `}
    ${({ isTabsListFixed }) => isTabsListFixed && tabsListFixedStyles}
  }

  ${TabPane} {
    :last-child {
      padding-inline-end: 0;
    }

    &:after {
      margin-block-start: 0;
    }
  }
`

export const TabsStyled = styled(Tabs)<{
  isEditorial: boolean
  hasLightBackground: boolean
  isTabsListFixed?: boolean
}>`
  block-size: fit-content;

  ${landscape(`
    block-size: 100%;
  `)}

  ${ScrollableContentContainer} {
    ${landscape(`
      position: relative;
      inline-size: ${pxToRem(1123)};
      inset-inline-start: ${pxToRem(-300)};
    `)}
    ${landscape(
      Device.tablet,
      `
      inline-size: ${clampValuesLandscape(620, 830)};
      inset-inline-start: -15vw;
    `
    )}
    ${zoomLandscape(
      [150, 175],
      `
      inset-inline-start: 0;
    `
    )}
  }

  ${ScrollablePaneContainer} {
    position: static;
    inset-block-start: 0;
    display: flex;
    justify-content: center;
    min-block-size: 0;
    overflow: visible;
    opacity: 1;
    background-color: ${({ theme, isEditorial, hasLightBackground }) =>
      isEditorial || hasLightBackground ? theme.colors.primaryLight : theme.colors.background};
    z-index: 2;
    inline-size: 100%;
    padding-block-start: ${({ isEditorial }) => (isEditorial ? pxToRem(9) : 0)};
    ${tablet(`
      padding-block-start: ${clampValues(20, 48)};
    `)}
    ${({ isEditorial }) => `
      ${landscape(`
        padding-block-start: ${isEditorial ? pxToRem(9) : pxToRem(44)};
        max-inline-size: 100%
      `)}
      ${landscape(
        Device.tablet,
        `
          padding-block-start: ${isEditorial ? pxToRem(10) : clampValuesLandscape(38, 38)};
          padding-block-end: 0;
      `
      )}
    `}
    ${({ isTabsListFixed }) => isTabsListFixed && tabsListFixedStyles}
  }

  ${TabPane} {
    :last-child {
      padding-inline-end: 0;
    }

    &:after {
      margin-block-start: 0;
    }
  }
`
