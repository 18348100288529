import { useActions } from '@hooks/useActions'
import { addToast } from '@actions/ui'
import { useMemo } from 'react'
import { ToastNotificationService, ToastTypeEnum, ToastType } from '@abstractTypes/toast'

export const useToastNotification = (): ToastNotificationService => {
  const actions = useActions({ addToast })
  return useMemo(() => {
    return (Object.keys(ToastTypeEnum) as Array<ToastType>).reduce(
      (acc, toastType) => ({
        ...acc,
        [toastType]: (message: string, duration?: number) =>
          actions.addToast(message, duration, toastType),
      }),
      {} as ToastNotificationService
    )
  }, [actions])
}
