import { CartItem } from '@abstractTypes/cart'
import { addEngravingPriceToProduct, cartSummaryValues } from '@libs/cart'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useCart = () => {
  const items = useSelector(s => s.cart.items)
  const availability = useSelector(s => s.cart.availability)
  const updatingAvailability = useSelector(s => s.cart.updatingAvailability)
  const engravingItems = useSelector(s => s.engraving.items)
  const cartItemOverlayId = useSelector(s => s.cart.cartItemOverlayId)

  const itemsWithEngrPrice = useMemo(() => {
    return items.map(item => {
      const engravedItem = engravingItems[item.id]
      if (engravedItem) {
        return addEngravingPriceToProduct(item, engravedItem)
      }
      return item
    })
  }, [items, engravingItems]) as CartItem[]

  const { numberItems, subtot } = cartSummaryValues(items)

  return {
    numberItems,
    subtot,
    items,
    itemsWithEngrPrice,
    availability,
    updatingAvailability,
    cartItemOverlayId,
  }
}
