import { ContentImage } from '@components/Image'
import styled from 'styled-components'

export const StyledImg = styled(ContentImage)`
  max-block-size: 100%;
  max-inline-size: ${({ theme }) => theme.productCard.imageMaxWidth};
  margin-block: 0 ${({ theme }) => theme.productCard.imageMarginBottom};
  margin-inline: auto;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
  object-fit: contain;
`
export const StyledCustomImg = styled(StyledImg)`
  inset-block-start: 12%;
  block-size: 76%;
`
