import {
  REFRESH_TOKEN,
  SAVE_ANALYTICS_GOOGLE_SENDED,
  SAVE_ANALYTICS_TEALIUM_SENDED,
  SessionActionTypes,
} from '@actions/core'
import { v4 as uuid } from 'uuid'

export interface SessionState {
  token: string
  hasSentGoogleAnalyticsNewSession: boolean
  hasSentTealiumNewSession: boolean
}

export const sessionReducer = (
  state: SessionState = {
    token: uuid(),
    hasSentGoogleAnalyticsNewSession: false,
    hasSentTealiumNewSession: false,
  },
  action: SessionActionTypes
): SessionState => {
  switch (action.type) {
    case REFRESH_TOKEN:
      return {
        token: uuid(),
        hasSentGoogleAnalyticsNewSession: false,
        hasSentTealiumNewSession: false,
      }

    case SAVE_ANALYTICS_GOOGLE_SENDED:
      return {
        ...state,
        hasSentGoogleAnalyticsNewSession: true,
      }

    case SAVE_ANALYTICS_TEALIUM_SENDED:
      return {
        ...state,
        hasSentTealiumNewSession: true,
      }

    default:
      return state
  }
}
