import { IconProps } from '@abstractTypes/icons'

export const VirtualKeyboardIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 42 30"
      stroke={stroke}
    >
      <path
        fill="#000"
        d="M37.667.417H4.334C2.042.417.188 2.292.188 4.583L.167 25.417c0 2.291 1.875 4.166 4.167 4.166h33.333c2.292 0 4.167-1.875 4.167-4.166V4.583c0-2.291-1.875-4.166-4.167-4.166zm-18.75 6.25h4.167v4.166h-4.167V6.667zm0 6.25h4.167v4.166h-4.167v-4.166zm-6.25-6.25h4.167v4.166h-4.167V6.667zm0 6.25h4.167v4.166h-4.167v-4.166zm-2.083 4.166H6.417v-4.166h4.167v4.166zm0-6.25H6.417V6.667h4.167v4.166zm18.75 14.584H12.667V21.25h16.667v4.167zm0-8.334h-4.167v-4.166h4.167v4.166zm0-6.25h-4.167V6.667h4.167v4.166zm6.25 6.25h-4.167v-4.166h4.167v4.166zm0-6.25h-4.167V6.667h4.167v4.166z"
      />
    </svg>
  )
}
