import { Device } from '@abstractTypes/utils'
import { Heading5, Text } from '@components/core/Typography'
import { ColorSwatchWrapper } from '@components/FrameAdvisor/ColorElements/ColorSwatch'
import { altNavigation, altNavigationZoom, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'
import { MerchNextBtn } from '../styles'

export const MerchCardWrapper = styled.div`
  padding: ${pxToRem(24)};
  margin-inline: 0;
  border-radius: ${pxToRem(15)};
  border: ${pxToRem(1)} solid #333;
  background: #f2f2f2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  flex: 1;
  gap: ${pxToRem(24)};
  block-size: 100%;
  ${tablet(`
    padding-block-start: 0;
    padding: ${clampValues(18, 24)};
    gap: ${clampValues(18, 24)};
    grid-template-columns: auto 1fr;
  `)}
  ${landscape(
    Device.tablet,
    `
     padding: ${clampValuesLandscape(18, 24)};
     gap: ${clampValuesLandscape(18, 24)};
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
     padding: ${pxToRem(36)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
     padding-block: ${pxToRem(48)};
     padding-inline: ${pxToRem(24)};
  `
  )}
`

export const MerchCardImageWrapper = styled.div`
  inline-size: 100%;
  ${tablet(`
    inline-size: ${clampValues(170, 185)}};
  `)}

  img {
    inline-size: 100%;
    block-size: 100%;
  }
`

export const MerchCardPrice = styled.div`
  align-self: flex-end;
`

export const MerchCardContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${pxToRem(16)};
  align-items: flex-start;
  block-size: 100%;
  ${tablet(`
     gap: ${pxToRem(12)};
  `)}
`

export const MerchColorSwatch = styled.button<{ active: boolean }>`
  ${({ active, theme }) =>
    active &&
    `
    padding: ${pxToRem(2)};
    border: ${pxToRem(1)} solid ${theme.colors.primaryDark};
    border-radius: 50%;
  `}

  ${ColorSwatchWrapper} {
    inline-size: ${pxToRem(20)};
    block-size: ${pxToRem(20)};
    outline: ${pxToRem(1)} solid ${({ theme }) => theme.colors.primaryDark};
    outline-offset: ${pxToRem(1)};
    ${tablet(`
     inline-size: ${pxToRem(15)};
      block-size: ${pxToRem(15)};
  `)}
    ${zoomPortrait(
      [110, 125, 150, 175, 200],
      `
        inline-size: ${pxToRem(20)};
        block-size: ${pxToRem(20)};
    `
    )}
  }
`

export const MerchCardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${pxToRem(32)};
  ${tablet(`
     gap: ${pxToRem(24)};
  `)}
`

export const MerchCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${pxToRem(16)};
  ${tablet(`
     gap: ${pxToRem(12)};
  `)}
`

export const MerchCardColorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
  ${tablet(`
     gap: ${pxToRem(12)};
  `)}
`

export const MerchSwatchesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${pxToRem(32)};
`

export const MerchCardDescription = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

export const MerchCardColorSwitchTitle = styled(Text)`
  span {
    font-weight: bold;
  }
`

export const MerchCardCTAWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  inline-size: fit-content;
  align-self: flex-end;

  button {
    :disabled {
      background-color: ${({ theme }) => theme.colors.highlight01};
    }
  }
`

export const MerchCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pxToRem(24)};
  ${MerchCardWrapper} {
    align-self: stretch;
    block-size: auto;
  }
`

export const MerchNoAccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-inline: ${pxToRem(32)};
  padding-block: ${pxToRem(60)} ${pxToRem(48)};
  gap: ${pxToRem(48)};
  background-color: #f2f2f2;
  ${Heading5} {
    margin-block: 0 ${pxToRem(60)};
  }
  ${altNavigation(`
    ${Heading5} {
      margin-block: 0;
    }
  `)}
  ${altNavigationZoom(
    [100, 125],
    `
    padding-block: ${pxToRem(32)} ${pxToRem(68)};
    padding-inline: ${pxToRem(95)} 0;
    gap: ${pxToRem(24)};
    ${Heading5} {
      margin-block: 0;
      padding-inline: ${pxToRem(12)};
    }
    ${MerchNextBtn} {
      inset-inline-end: ${pxToRem(32)};
    }
  `
  )}
  ${altNavigationZoom(
    [150, 175],
    `
    padding-block: ${pxToRem(48)};
    padding-inline: ${pxToRem(95)};
    gap: ${pxToRem(48)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    padding-block: ${pxToRem(64)};
    padding-inline: ${pxToRem(118)};
    gap: ${pxToRem(48)};
  `
  )}
`
