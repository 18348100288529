import { SearchNoDataContent } from '@components/Contents/NoDataContent'
import { SearchTabs } from '@components/SearchDrawer/SearchTabs'
import { useNavigationFlowHandler } from '@hooks/useNavigationFlowHandler'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchTabsContainer } from '..'
import { NoResultsContainer, NotFoundMessageStyled } from './styled'
import { NoResultContentProps } from './type'

const NoResultContent: FC<NoResultContentProps> = ({
  noResultTabs,
  selectedNoResultTab,
  noResultsTabsSubset,
  handleChangeTab,
  selectedTab,
  query,
}) => {
  const { t } = useTranslation()
  const { barcodeFlow } = useNavigationFlowHandler()

  const notFoundMessage = barcodeFlow
    ? t('ProductsListPage.noBarcodeResults')
    : t('ProductsListPage.noResults')

  const renderTabs = () => {
    if ((!selectedTab && !selectedNoResultTab) || barcodeFlow) return null

    if (selectedNoResultTab && noResultsTabsSubset.length && noResultsTabsSubset.length > 1) {
      return (
        <SearchTabs
          tabNames={noResultTabs}
          selectedTab={selectedNoResultTab}
          onChange={handleChangeTab}
        />
      )
    }
  }

  return (
    <NoResultsContainer>
      <NotFoundMessageStyled aria-label={notFoundMessage}>{notFoundMessage}</NotFoundMessageStyled>
      <SearchTabsContainer>{renderTabs()}</SearchTabsContainer>
      <SearchNoDataContent selectedTab={selectedNoResultTab} query={query} />
    </NoResultsContainer>
  )
}

export default NoResultContent
