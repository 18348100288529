import { Device } from '@abstractTypes/utils'
import { AccordionSingle } from '@components/core/Accordion'
import { AccordionContent, AccordionIcon, AccordionTitle } from '@components/core/Accordion/styles'
import { landscape, zoomLandscape } from '@libs/media'
import { clampValuesLandscape, pxToRem } from '@libs/styled'
import { scrollbar } from '@styles/common'
import styled from 'styled-components'

export const ScrollableContainer = styled.div`
  overflow: scroll hidden;
  white-space: nowrap;
  inline-size: 100%;
`

export const ScrollableContentContainer = styled(ScrollableContainer)`
  overflow-x: hidden;
  block-size: 100%;
`

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  inline-size: 100%;
  block-size: 100%;
  font-size: 1.455rem;

  ${ScrollableContentContainer} {
    ${landscape(`
      position: relative;
      inline-size: ${pxToRem(1396)};
    `)}
    ${landscape(
      Device.tablet,
      `
      inline-size: ${clampValuesLandscape(1024, 1366)};
    `
    )}
    ${zoomLandscape(
      [200],
      `
      inset-inline-start: 0;
    `
    )}
  }
`

export const ScrollablePaneContainer = styled(ScrollableContainer)`
  display: flex;
  -webkit-overflow-scrolling: touch;
  min-block-size: ${pxToRem(110)};
  max-inline-size: 90%;
  inline-size: unset;
  ${landscape(`
    max-inline-size: 60%;
  `)}

  > * {
    margin-inline-end: ${pxToRem(32)};

    :last-child {
      margin-inline-end: 0;
    }
  }
`

export const ScrollableContent = styled.div<{ currentTabIndex: number }>`
  inline-size: 100%;
  margin-inline-start: ${({ currentTabIndex }) => (currentTabIndex ? `-${currentTabIndex}00%` : 0)};
  transition: margin-inline-start 0.5s;
  block-size: 100%;
`

export const TabPane = styled.button<{
  isActive?: boolean
  weight?: string
}>`
  block-size: 100%;
  position: relative;
  font-weight: ${({ weight = 'normal' }) => weight};

  ::after {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    background-color: ${({ theme }) => theme.colors.primaryDark};
    block-size: 3px;
    inline-size: 100%;
    position: absolute;
    inset-inline-start: 0;
    margin-block-start: 0.3rem;
  }
`

export const TabContent = styled.div`
  display: inline-block;
  inline-size: 100%;
  text-align: center;
  white-space: initial;
  vertical-align: top;
  block-size: calc(100% - 4.11vh);
  padding-block: 0;
  padding-inline: 5vw;
  position: relative;
  margin-block-start: 4.11vh;
`

export const StyledScrollableTabContent = styled.div<{
  topContent?: number
  bottomContent?: number
}>`
  block-size: 100%;
  overflow: auto;
  overscroll-behavior: none;
  ${scrollbar};

  ::before,
  ::after {
    content: '';
    inset-inline-start: 0;
    inline-size: calc(100% - 5vw - 5px);
    transition: height 0.3s;
    position: absolute;
  }

  ::before {
    inset-block-start: 0;
    block-size: ${({ topContent }) => `${topContent || 0}px`};
    background: linear-gradient(to bottom, #fff, rgb(255 255 255 / 0%));
  }

  ::after {
    inset-block-end: 0;
    block-size: ${({ bottomContent }) => `${bottomContent || 0}px`};
    background: linear-gradient(to top, #fff, rgb(255 255 255 / 0%));
  }
`

export const AccordionSingleStyled = styled(AccordionSingle)<{ isOpened?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: ${({ theme }) => `${theme.colors.background}`};

  ${AccordionTitle} {
    inline-size: auto;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-block-start: 0;
    border-block-end: ${({ theme }) => `3px solid ${theme.colors.primaryDark}`};
  }

  ${AccordionIcon} {
    margin-inline-start: ${pxToRem(12)};
  }

  ${AccordionContent} {
    position: absolute;
    inset-block-start: 99%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: ${pxToRem(10)};
    padding-inline: 0;
    inline-size: 100%;
    background: ${({ theme }) => `${theme.colors.background}`};
  }

  ${TabPane} {
    display: block;
    margin-block: 0;
    margin-inline: auto;
  }
`
