import { ProductPriceType } from '@abstractTypes/price'
import { DataAttrs, Product } from '@abstractTypes/product'
import { FontWeight, TypographyProps } from '@abstractTypes/typography'
import { Text } from '@components/core/Typography'
import { usePriceFormatter } from '@hooks/usePriceFormatter'
import { getHighestPrice, getLowestPrice, getTotalPrice } from '@libs/price'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PriceStyled } from '../ProductCard.style'

export const PRICE_STYLE_RANGE = 'range'
export const PRICE_STYLE_FROM = 'from'
export const PRICE_STYLE_UPTO = 'upto'
export const PRICE_STYLE_TOTAL = 'total'

interface ProductPriceProps {
  product: Product
  priceStyle?: ProductPriceType
  quantity?: number
  dataAttr?: DataAttrs
  weight?: FontWeight
  renderPriceAs?: React.FC<TypographyProps>
  engravingPrice?: number
}

export const ProductPrice = ({
  dataAttr,
  product,
  priceStyle = 'default',
  quantity = 1,
  weight,
  renderPriceAs = Text,
  engravingPrice = 0,
}: ProductPriceProps) => {
  const { price: dataTest } = dataAttr || {}
  const { t } = useTranslation()
  const formatPrice = usePriceFormatter()

  const formattedPrice = useMemo(() => {
    switch (priceStyle) {
      case PRICE_STYLE_FROM: {
        return t('CardProduct.priceFrom', {
          price: formatPrice(getLowestPrice(product)),
        })
      }
      case PRICE_STYLE_UPTO: {
        return t('CardProduct.priceUpTo', {
          price: formatPrice(getHighestPrice(product)),
        })
      }
      case PRICE_STYLE_RANGE: {
        const min = formatPrice(getLowestPrice(product))
        const max = formatPrice(getHighestPrice(product))
        if (min !== max) {
          return `${min} - ${max}`
        }
        return min
      }
      case PRICE_STYLE_TOTAL: {
        return formatPrice(getTotalPrice(product, quantity))
      }
      default: {
        return formatPrice(product.price.current + engravingPrice)
      }
    }
  }, [priceStyle, t, formatPrice, product, quantity, engravingPrice])

  return (
    <PriceStyled as={renderPriceAs} weight={weight} data-test={dataTest ?? 'text-price'}>
      {formattedPrice}
    </PriceStyled>
  )
}
