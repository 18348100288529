import {
  FILTER_TYPE_BRAND,
  FILTER_TYPE_FLAG,
  FILTER_TYPE_PRICE,
  FilterKind,
  FilterState,
  FiltersFromUrl,
} from '@abstractTypes/filter'
import { Store } from '@abstractTypes/graphqlTypes'
import { startCase } from '@libs/formatters'
import { getSearchValueFromQs } from '@libs/url'
import { Location } from 'history'
import { TFunction } from 'i18next'

interface TitleObject {
  titles: string[]
  link?: string
}

interface HierachyTitles {
  firstLevelOption: string
  secondLevelOption?: string
  link?: string
}

type FilterHeirarchiesKind = Extract<
  FilterKind,
  'product_hierarchy1' | 'product_hierarchy2' | 'product_hierarchy3'
>
type FilterCategoriesKind = Extract<
  FilterKind,
  'sports' | 'category' | 'gender' | 'prizmCategory' | 'salesCategory'
>

const disallowedCustomTitles = [
  'frameMaterial',
  'frameColor',
  'frameType',
  'frameShape',
  'lensColor',
  'lensTreatment',
  'faceShape',
  'price',
  'roxable',
  'progressiveFriendly',
  'storeAvailable',
]

const removeDisallowCustomTitleFilters = (filters: FilterState[]) => {
  return filters?.filter(filter => {
    return !disallowedCustomTitles.includes(filter.key)
  })
}

const getFilterValueAsArray = (value?: string[] | string): string[] => {
  return Array.isArray(value) ? value : value ? [value] : []
}

const getHierarchyRanking = (search: string): FilterHeirarchiesKind[] => {
  const ph1Index = search.indexOf('product_hierarchy1')
  const ph2Index = search.indexOf('product_hierarchy2')
  const ph3Index = search.indexOf('product_hierarchy3')

  const hierarchyIndexMap: { [k in FilterHeirarchiesKind]: number } = {
    product_hierarchy1: ph1Index,
    product_hierarchy2: ph2Index,
    product_hierarchy3: ph3Index,
  }

  return (Object.keys(hierarchyIndexMap) as FilterHeirarchiesKind[])
    .sort((key1, key2) => hierarchyIndexMap[key1] - hierarchyIndexMap[key2])
    .filter(key => hierarchyIndexMap[key] !== -1)
}

export const getBrandNameFromStore = (store: Store | undefined, brandCode: string) => {
  const brand = store && store.brands.find(b => b.id === brandCode)
  return brand ? brand.name : brandCode
}

export const getFilterOptionTitle = (
  filters: FilterState[],
  store: Store | undefined,
  t: TFunction
) => {
  filters = removeDisallowCustomTitleFilters(filters)
  if (filters.length !== 1) return
  const filter = filters[0]

  switch (filter.type) {
    case FILTER_TYPE_BRAND: {
      const selectedOptions = filter.options?.filter(option => option.selected === true) || []
      if (selectedOptions.length !== 1) return
      return getBrandNameFromStore(store, selectedOptions[0].value)
    }
    case FILTER_TYPE_PRICE: {
      const selectedRange = filter.range?.filter(range => range.selected === true) || []
      if (selectedRange.length !== 1) return
      return `$ ${selectedRange[0].min} - ${selectedRange[0].max}`
    }
    case FILTER_TYPE_FLAG: {
      const selectedOptions = filter.options?.filter(option => option.selected === true) || []
      if (selectedOptions.length !== 1) return
      return t(`PlpFilters.${filter.key}`)
    }
    default: {
      const selectedOptions = filter.options?.filter(option => option.selected === true) || []
      if (selectedOptions.length !== 1) return
      const option = selectedOptions[0]
      return t(`PlpFilters${option.kind}.${option.value.toLowerCase()}`, {
        defaultValue: option.value,
      })
    }
  }
}

export const getTitle = (
  filters: FilterState[],
  store: Store | undefined,
  location: Location,
  t: TFunction
) => {
  const search = getSearchValueFromQs(location)
  if (search) return t('PlpFilters.titleSearch', { search })
  const filter = getFilterOptionTitle(filters, store, t)

  if (filter) {
    return t('PlpFilters.titleFilter', { filter: startCase(filter) })
  }
  // Shop all as default
  return t('PlpFilters.titleSearchall')
}

export const getPriorityFilterList = (
  search: string
): (FilterCategoriesKind | FilterHeirarchiesKind)[] => {
  const priorityHierarchy = getHierarchyRanking(search)

  return ['sports', 'category', 'gender', 'prizmCategory', 'salesCategory', ...priorityHierarchy]
}

export const getHierarchyTitles = (
  urlFilters: FiltersFromUrl,
  location: Location,
  plpBaseLink: string,
  t: TFunction
): HierachyTitles => {
  const category = getFilterValueAsArray(urlFilters.category)
  const salesCategory = getFilterValueAsArray(urlFilters.salesCategory)
  const gender = getFilterValueAsArray(urlFilters.gender)
  const prizmCategory = getFilterValueAsArray(urlFilters.prizmCategory)
  const ph1 = getFilterValueAsArray(urlFilters.product_hierarchy1)
  const ph2 = getFilterValueAsArray(urlFilters.product_hierarchy2)
  const ph3 = getFilterValueAsArray(urlFilters.product_hierarchy3)
  const sports = getFilterValueAsArray(urlFilters.sports)

  const priorityMap: { [k in FilterKind]?: TitleObject | undefined } = {
    sports: sports.length ? { titles: [t('PLPTitle.Sport'), sports[0]] } : undefined,
    category: category.length
      ? {
          titles: [ph1[0], t(`PlpFilterscategory.${category}`)],
          link: `${plpBaseLink}product_hierarchy1=${ph1[0]}`,
        }
      : undefined,
    gender: gender.length
      ? {
          titles: [t('PLPTitle.shopAll'), t(`PlpFilters.${gender[0]}`)],
        }
      : undefined,
    prizmCategory: prizmCategory.length
      ? {
          titles: [t('PLPTitle.shopAll'), t(`PlpFilterscategory.${prizmCategory}`)],
        }
      : undefined,
    salesCategory: salesCategory.length
      ? {
          titles: [t('PLPTitle.shopAll'), t(`PlpFilterscategory.${salesCategory[0]}`)],
        }
      : undefined,
    product_hierarchy1: ph1.length
      ? {
          titles: [t('PLPTitle.shopAll'), ph1[0]],
          link: `${plpBaseLink}product_hierarchy1=${ph1[0]}`,
        }
      : undefined,
    product_hierarchy2: ph2.length
      ? {
          titles: [ph1[0], ph2[0]],
          link: `${plpBaseLink}product_hierarchy1=${ph1[0]}`,
        }
      : undefined,
    product_hierarchy3: ph3.length
      ? ph2.length
        ? {
            titles: [ph2[0], ph3[0]],
            link: `${plpBaseLink}product_hierarchy2=${ph2[0]}`,
          }
        : {
            titles: [ph1[0], ph3[0]],
            link: `${plpBaseLink}product_hierarchy1=${ph1[0]}`,
          }
      : undefined,
  }

  const priorities = getPriorityFilterList(location.search)

  const prioritizedFilter = priorities.find(f => !!priorityMap[f])
  const titleObject = prioritizedFilter
    ? (priorityMap[prioritizedFilter] as TitleObject)
    : {
        titles: [t('PLPTitle.shopAll'), ph1[0]], // this is the default case because ph1 is always defined
      }

  const [firstLevelOption, secondLevelOption] = titleObject.titles

  return {
    firstLevelOption,
    secondLevelOption,
    link: titleObject.link,
  }
}
