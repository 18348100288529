import { ElectronicsListTypography } from '@components/core/Typography/ElectronicsTypography'
import React from 'react'
import { ArticleContainer, ArticleText, ArticleTitle } from './styles'
import { ArticleContentProps } from './types'

export const ArticleContent: React.FC<ArticleContentProps> = ({ article, id, tabIndex }) => {
  const bulletList: string[] = (article.text || '').split('\n')
  const text = (article.text || '').trim().replace(/\./g, '') // Remove new lines and '.'
  return (
    <ArticleContainer id={id} tabIndex={tabIndex}>
      <ArticleTitle>{article.title}</ArticleTitle>
      {text && (
        <ArticleText>
          {bulletList.length > 1 ? (
            <ul>
              {bulletList.filter(Boolean).map((listInfo, i) => {
                const hasBullet = !listInfo.startsWith('*')
                return (
                  <ElectronicsListTypography key={i} hasBullet={hasBullet}>
                    {listInfo}
                  </ElectronicsListTypography>
                )
              })}
            </ul>
          ) : (
            article.text
          )}
        </ArticleText>
      )}
    </ArticleContainer>
  )
}
