import { ContentImage } from '@components/Image'
import React from 'react'
import { ColorsTabContainer, ImageWrapper } from './styles'
import { ImagesContentProps } from './types'

export const ImagesContent: React.FC<ImagesContentProps> = ({ images }) => {
  return images?.length > 0 ? (
    <ColorsTabContainer>
      {images.map(variant => (
        <ImageWrapper key={variant.id}>
          <ContentImage
            key={variant.id}
            imageSource={variant}
            aria-label={variant.altText}
            aria-description={variant.altText}
            ratio={{ tower: '_4x3', towerLandscape: '_4x3', ipad: '_4x3', ipadlandscape: '_4x3' }}
          />
        </ImageWrapper>
      ))}
    </ColorsTabContainer>
  ) : null
}
