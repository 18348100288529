import { Text } from '@components/core/Typography'
import { pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

export const ProductDescriptionItem = styled(Text)`
  display: grid;
  column-gap: ${pxToRem(6)};
  grid-template-columns: 3fr 3fr;
  inline-size: 100%;
`

const ellipsisText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ProductDescriptionWrapper = styled.div<{ isCondensed?: boolean }>`
  inline-size: 100%;

  ${({ isCondensed }) => css`
    ${ProductDescriptionItem} {
      margin-block-end: ${pxToRem(isCondensed ? 0 : 6)};
    }
    ${Text} {
      ${isCondensed && ellipsisText}
    }
  `}
`

export const PrescriptionLabel = styled(Text)`
  &:first-letter {
    text-transform: uppercase;
  }
`

export const CustomTitle = styled(Text).attrs({ size: 'smallest' })`
  text-decoration: underline;
`
