import { ProductHierarchies, ProductTypeValues } from '@abstractTypes/filter'
import config from '@config/config.base'

const getNoResultsTabs = (eyeglassesEnabled: boolean) => {
  const noResultProductTypeTabs = [ProductTypeValues.SUN].concat(
    eyeglassesEnabled ? [ProductTypeValues.OPTICAL] : []
  )
  const noResultProductHierarchyTabs = [ProductHierarchies.SUNGLASSES].concat(
    eyeglassesEnabled ? [ProductHierarchies.EYEGLASSES] : []
  )

  switch (config.default.productSubdivision) {
    case 'productHierarchy':
      return noResultProductHierarchyTabs
    case 'productType':
    default:
      return noResultProductTypeTabs
  }
}

export default getNoResultsTabs
