import { IconProps } from '@abstractTypes/icons'

export const CaretUpThinIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        d="M31.8046 23.4453L16 7.64062L0.195312 23.4453L1.13798 24.388L16 9.52596L30.862 24.388L31.8046 23.4453Z"
        fill="#333333"
      />
    </svg>
  )
}
