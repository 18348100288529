import React from 'react'
import { useThemeContext } from '@hooks/useThemeContext'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { PrivacyPolicyTooltipStyled } from './styles'

export const ElectronicsPrivacyPolicyTooltip: React.FC = () => {
  const { isAlternativeNavigation } = useNavigationContext()
  const theme = useThemeContext()
  return (
    <PrivacyPolicyTooltipStyled
      stroke={isAlternativeNavigation ? theme.colors.primaryDark : theme.colors.primaryLight}
    />
  )
}
