import { pxToRem } from '@libs/styled'
import styled, { keyframes } from 'styled-components'

export const slideIn = keyframes`
  from {
    transform: translateY(${pxToRem(30)});
    opacity: 0;
  }

  to {
    transform: translateY(${pxToRem(30)});
    opacity: 1;
  }
`

export const ToastNotificationWrapper = styled.div`
  position: fixed;
  inset-block-start: ${pxToRem(50)};
  inset-inline-end: 50%;
  transform: translateX(50%);
  z-index: 1010;
  display: flex;
  flex-direction: column;
`

export const ToastNotificationContent = styled.div`
  background: ${({ theme }) => theme.colors.primaryLight};
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  border-radius: ${pxToRem(4)};
  color: ${({ theme }) => theme.colors.functional01};
  inline-size: 100%;
  margin-block-end: ${pxToRem(10)};
  display: flex;
  justify-content: center;
  padding-block: ${pxToRem(16)};
  padding-inline: ${pxToRem(16)};
  animation: ${slideIn} 0.2s;
  transition: all 0.3s ease;

  svg {
    margin-inline-end: ${pxToRem(10)};
  }
`
