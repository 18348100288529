import { togglableProductTypes, TogglableProductTypeValues } from '@abstractTypes/filter'
import { Device } from '@abstractTypes/utils'
import { ProductTypeTogglerWrapper } from '@components/core/ProductTypeToggler/ProductTypeTogglerWrapper'
import { Heading3 } from '@components/core/Typography'
import { useProductTypeFromUrl } from '@hooks/useFilters'
import { useStoreContext } from '@hooks/useStoreContext'
import { altNavigationZoom, landscape, tablet, zoomLandscape, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { baseContainerPadding } from '@styles/common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { PLPTitleProps } from './types'
import { getTitle } from './utils'

const filtersToRemoveFromPLPHeader = ['productType']

const HeadingWrapper = styled.div`
  display: flex;
  ${zoomPortrait(
    [150, 175, 200],
    `
    flex-direction: column;
    align-items: flex-start;
  `
  )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    flex-direction: row;
    align-items: center;
  `
  )}
  justify-content: space-between;
  align-items: center;
  inline-size: 100%;
  ${baseContainerPadding}

  h3 {
    max-inline-size: ${pxToRem(480)};
    ${landscape(`max-inline-size: ${pxToRem(1310)}`)}
    ${tablet(`max-inline-size: ${clampValues(285, 480)}`)}
    ${landscape(Device.tablet, `max-inline-size: ${clampValuesLandscape(540, 800)}`)}
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding-block-end: ${pxToRem(36)};
      max-inline-size: 100%;
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      padding-block-end: 0;
    `
    )}
  }

  /* If only H3 (title) is present, then extend to 100% max width */
  h3:only-child {
    max-inline-size: 100%;
  }
`

export const StickyTitle = styled.div<{
  showStripeBanner?: boolean
  isSticky?: boolean
  heightStripeBanner?: number
}>`
  padding-block: ${pxToRem(44)};
  padding-inline: 0;
  background: ${({ theme }) => theme.colors.primaryLight};
  position: ${({ isSticky }) => (!isSticky ? 'relative' : 'sticky')};
  box-shadow: -1px -2px white;
  inset-block-start: ${({ showStripeBanner, isSticky, heightStripeBanner }) =>
    showStripeBanner && isSticky ? pxToRem(heightStripeBanner ?? 62) : '0'};
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ showStripeBanner, isSticky, heightStripeBanner }) => css`
    ${landscape(`
      inset-block-start: ${showStripeBanner && isSticky ? pxToRem(heightStripeBanner ?? 62) : 0};
    `)}
    ${tablet(`
      padding-block: ${pxToRem(31)} ${pxToRem(20)};
      
      inset-block-start: ${showStripeBanner && isSticky ? pxToRem(heightStripeBanner ?? 62) : 0};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-block: ${clampValuesLandscape(16, 34)};
    `
    )}
    ${zoomPortrait(
      [150],
      `
      padding-block: ${pxToRem(60)} ;
      inset-block-start: ${showStripeBanner && isSticky ? pxToRem(heightStripeBanner ?? 88) : 0};
    `
    )}
    ${zoomPortrait(
      [175],
      `
      padding-block: ${pxToRem(60)} ;
      inset-block-start: ${showStripeBanner && isSticky ? pxToRem(heightStripeBanner ?? 140) : 0};
    `
    )}
    ${zoomPortrait(
      [175, 200],
      `
      padding-block: ${pxToRem(60)} ;
      inset-block-start: ${showStripeBanner && isSticky ? pxToRem(heightStripeBanner ?? 170) : 0};
    `
    )}
    ${zoomLandscape(
      [175, 200],
      `
      padding-block: ${pxToRem(60)} ;
    `
    )}
    ${altNavigationZoom(
      [175, 200],
      `
      padding-block: ${pxToRem(60)} ;
    `
    )}
  `}
`

export const PLPTitle: React.FC<PLPTitleProps> = ({ filters, className, hasItems }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const store = useStoreContext()
  const enabledFilters = useMemo(
    () =>
      Object.values(filters).filter(
        filter =>
          filter.options?.some(option => option.selected) ||
          filter.range?.some(range => range.selected)
      ),
    [filters]
  )
  const filteredEnabledFilters = enabledFilters.filter(
    filter => !filtersToRemoveFromPLPHeader.includes(filter.key)
  )

  const productTypeValue = useProductTypeFromUrl()

  // TODO: do we really need this TogglableProductTypeValues type?
  const isGlassesFilterEnabled = togglableProductTypes.includes(
    productTypeValue as TogglableProductTypeValues
  )
  const showProductTypeToggle = isGlassesFilterEnabled && store.eyeglassesEnabled

  return (
    <HeadingWrapper className={className}>
      <Heading3>{getTitle(filteredEnabledFilters, store, location, t)}</Heading3>
      {showProductTypeToggle && (
        <ProductTypeTogglerWrapper
          currentValue={productTypeValue as TogglableProductTypeValues}
          hasItems={hasItems}
          filters={filters}
        />
      )}
    </HeadingWrapper>
  )
}
