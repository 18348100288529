export const REFRESH_TOKEN = 'session/REFRESH_TOKEN'
export const SAVE_ANALYTICS_GOOGLE_SENDED = 'session/SAVE_ANALYTICS_GOOGLE_SENDED'
export const SAVE_ANALYTICS_TEALIUM_SENDED = 'session/SAVE_ANALYTICS_TEALIUM_SENDED'

export interface RefreshSessionAction {
  type: typeof REFRESH_TOKEN
}

export interface SaveAnalyticsGoogleSendedAction {
  type: typeof SAVE_ANALYTICS_GOOGLE_SENDED
}

export interface SaveAnalyticsTealiumSendedAction {
  type: typeof SAVE_ANALYTICS_TEALIUM_SENDED
}

export const saveAnalyticsTealiumSended = (): SaveAnalyticsTealiumSendedAction => ({
  type: SAVE_ANALYTICS_TEALIUM_SENDED,
})

export type SessionActionTypes =
  | RefreshSessionAction
  | SaveAnalyticsGoogleSendedAction
  | SaveAnalyticsTealiumSendedAction
