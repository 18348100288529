import { IconProps } from '@abstractTypes/icons'

export const EngravingIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#a)">
        <path
          fill={fill}
          d="M16.122 31.986a.757.757 0 0 0 .5-.358l8-13.453a.745.745 0 0 0 0-.63L21.66 9.905a.702.702 0 0 0-.572-.458.716.716 0 0 0-.687.271.702.702 0 0 0-.086.73l2.862 7.313-6.454 10.863v-9.088a3.263 3.263 0 1 0-1.431 0v9.088L8.809 17.746l3.835-9.732h9.531a2.175 2.175 0 0 0 2.176-2.175V.716A.716.716 0 0 0 23.635 0H8.365a.716.716 0 0 0-.73.716V5.81A2.19 2.19 0 0 0 9.81 8.014h1.26l-3.75 9.532a.701.701 0 0 0 0 .63l8 13.452a.73.73 0 0 0 .802.358Zm-.13-13.81a1.817 1.817 0 1 1 0-3.634 1.817 1.817 0 0 1 0 3.633Zm6.183-11.593H9.81a.701.701 0 0 1-.715-.73V1.56h13.81v4.25a.717.717 0 0 1-.73.773Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h17.445v32H0z" transform="translate(7.277)" />
        </clipPath>
      </defs>
    </svg>
  )
}
