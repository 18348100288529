import { CheckboxWithLabel, CheckboxWithLabelProps } from '@components/core/Checkbox'
import { CheckoutCheckboxWrapper } from './Panels/styles'

export const CheckoutCheckbox: React.FC<CheckboxWithLabelProps> = ({
  className,
  checked,
  children,
  ...props
}) => {
  return (
    <CheckoutCheckboxWrapper className={className} checked={checked}>
      <CheckboxWithLabel {...props} checked={checked}>
        {children}
      </CheckboxWithLabel>
    </CheckoutCheckboxWrapper>
  )
}
