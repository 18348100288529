import {
  CheckedIcon,
  CustomizeIcon,
  EngravingIcon,
  ThumbUpIcon,
  TrashIcon,
  VmIcon,
} from '@components/core/Icons'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { IconContainer } from './icons.styles'
import React from 'react'
import { RtrIcon } from '@components/core/Icons/RtrIcon'
import { useThemeContext } from '@hooks/useThemeContext'

interface StyledIconWrapperProps {
  render: (zoomLevel: number) => React.ReactNode
}

const StyledIconWrapper: React.FC<StyledIconWrapperProps> = ({ render }) => {
  const { zoomLevel } = useNavigationContext()

  return <IconContainer>{render(zoomLevel)}</IconContainer>
}

const StyledWishlistedIcon = (size: IconSize = 'standard') => {
  const theme = useThemeContext()
  const sizes =
    size === 'large'
      ? isIPadView()
        ? clampValues(18, 22)
        : pxToRem(22)
      : isIPadView()
      ? clampValues(14, 20)
      : pxToRem(20)
  return (
    <ThumbUpIcon
      stroke={theme.colors.primaryLight}
      fill={theme.colors.primaryLight}
      height={sizes}
      width={sizes}
    />
  )
}

const StyledNotWishlistedIcon = (size: IconSize = 'standard') => {
  const theme = useThemeContext()
  const sizes =
    size === 'large'
      ? isIPadView()
        ? clampValues(18, 22)
        : pxToRem(22)
      : isIPadView()
      ? clampValues(14, 20)
      : pxToRem(20)
  return <ThumbUpIcon stroke={theme.colors.primaryDark} height={sizes} width={sizes} />
}

const StyledCustomizeIcon: React.FC<{ zoomLevel: number }> = ({ zoomLevel }) => (
  <CustomizeIcon height={pxToRem(32, zoomLevel / 100)} width={pxToRem(32, zoomLevel / 100)} />
)

const StyledEngravingIcon: React.FC<{ zoomLevel: number }> = ({ zoomLevel }) => (
  <EngravingIcon height={pxToRem(32, zoomLevel / 100)} width={pxToRem(32, zoomLevel / 100)} />
)

const StyledRtrIcon: React.FC<{ zoomLevel: number }> = ({ zoomLevel }) => (
  <RtrIcon height={pxToRem(32, zoomLevel / 100)} width={pxToRem(32, zoomLevel / 100)} />
)

const StyledCheckedIcon: React.FC<{ zoomLevel: number }> = ({ zoomLevel }) => (
  <CheckedIcon width={isIPadView() ? clampValues(24, 32) : pxToRem(32, zoomLevel / 100)} />
)

const StyledTrashIcon: React.FC<{ zoomLevel: number }> = ({ zoomLevel }) => (
  <TrashIcon width={pxToRem(24, zoomLevel / 100)} height={pxToRem(24, zoomLevel / 100)} />
)

const StyledVtoIcon: React.FC<{ zoomLevel: number }> = ({ zoomLevel }) => (
  <IconContainer>
    <VmIcon height={pxToRem(32, zoomLevel / 100)} width={pxToRem(32, zoomLevel / 100)} />
  </IconContainer>
)

type IconSize = 'large' | 'standard'

type IconTypes =
  | 'customIcon'
  | 'wishlistedIcon'
  | 'notWishlistedIcon'
  | 'engravingIcon'
  | 'chekedIcon'
  | 'trashIcon'
  | 'rtrIcon'
  | 'vtoIcon'

type Icon = {
  icon: () => JSX.Element
  largeIcon?: () => JSX.Element
  order: number
}

export type IconSet = Record<IconTypes, Icon>

const defaultIconSet: IconSet = {
  customIcon: {
    icon: () => (
      <StyledIconWrapper render={zoomLevel => <StyledCustomizeIcon zoomLevel={zoomLevel} />} />
    ),
    order: 2,
  },
  engravingIcon: {
    icon: () => (
      <StyledIconWrapper render={zoomLevel => <StyledEngravingIcon zoomLevel={zoomLevel} />} />
    ),
    order: 3,
  },
  wishlistedIcon: {
    icon: StyledWishlistedIcon,
    largeIcon: () => StyledWishlistedIcon('large'),
    order: -1,
  },
  notWishlistedIcon: {
    icon: StyledNotWishlistedIcon,
    largeIcon: () => StyledNotWishlistedIcon('large'),
    order: -1,
  },
  chekedIcon: {
    icon: () => (
      <StyledIconWrapper render={zoomLevel => <StyledCheckedIcon zoomLevel={zoomLevel} />} />
    ),
    order: 1,
  },
  trashIcon: {
    icon: () => (
      <StyledIconWrapper render={zoomLevel => <StyledTrashIcon zoomLevel={zoomLevel} />} />
    ),
    order: 1,
  },
  rtrIcon: {
    icon: () => <StyledIconWrapper render={zoomLevel => <StyledRtrIcon zoomLevel={zoomLevel} />} />,
    order: 1,
  },
  vtoIcon: {
    icon: () => <StyledIconWrapper render={zoomLevel => <StyledVtoIcon zoomLevel={zoomLevel} />} />,
    order: 1,
  },
}

export const getProductCardIcons = (shopperSlug?: string) => {
  switch (shopperSlug) {
    default:
      return defaultIconSet
  }
}
