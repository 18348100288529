import { ElectronicsProduct } from '@abstractTypes/product'
import { Device } from '@abstractTypes/utils'
import { Heading5 } from '@components/core/Typography'
import { DetailsList } from '@components/pdp/DetailsPanel/Details'
import { DetailRowContainer } from '@components/pdp/DetailsPanel/Details/styles'
import { landscape, tablet, zoomLandscape } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

const DetailsListStyled = styled(DetailsList)`
  padding-inline-end: ${pxToRem(54)};
  ${zoomLandscape(
    [200],
    `
      padding-block-start: 0;
  `
  )}

  ${DetailRowContainer} {
    text-align: start;
    margin-block-end: 0;

    ${Heading5} {
      font-family: ${({ theme }) => theme.electronics.fonts.secondary};
      font-size: ${pxToRem(24)};
      color: ${({ theme }) => theme.electronics.colors.primaryDark};
      line-height: ${pxToRem(36)};
      ${tablet(`
        font-size: ${clampValues(16, 24)};
      `)}
      ${landscape(
        Device.tablet,
        `
        font-size: ${clampValuesLandscape(16, 24)};
      `
      )};

      :last-child {
        color: ${({ theme }) => theme.electronics.colors.inactive};
      }
    }
  }
`

export const DetailsContent: React.FC<{ product: ElectronicsProduct }> = ({ product }) => {
  return <DetailsListStyled product={product} />
}
