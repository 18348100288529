import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const rbTheme: ThemeOverride = {
  colors: {
    primaryDark: '#1F1F24',
    primaryDarkPressed: '#79797C',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#FFFFFF',
    inactive: '#666666',
    outline: '#E2E2E5',
    background: '#F2F2F2',
    highlight01: '#E80C00',
    highlight01Pressed: 'rgba(232, 12, 0, 0.6)',
    highlight02: '#1F1F24',
    highlight02Pressed: 'rgba(31, 31, 36, 0.6)',
    highlight03: '#1F1F24',
    highlight04: '#E80C00',
    functional01: '#B50900',
    functional02: '#1C8846',
    functional03: '#8F7041',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'Oswald',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 100,
        tablet: 75,
        tabletMini: 57,
      },
      lineHeight: {
        tower: 115,
        tablet: 80,
        tabletMini: 72,
      },
    },
    heading1: {
      fontFamily: 'Oswald',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 60,
        tablet: 56,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 62,
        tablet: 62,
        tabletMini: 60,
      },
    },
    heading2: {
      fontFamily: 'Oswald',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 44,
        tablet: 38,
        tabletMini: 34,
      },
      lineHeight: {
        tower: 48,
        tablet: 48,
        tabletMini: 46,
      },
    },
    heading3: {
      fontFamily: 'Oswald',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 34,
        tablet: 32,
        tabletMini: 24,
      },
      lineHeight: {
        tower: 48,
        tablet: 46,
        tabletMini: 38,
      },
    },
    heading4: {
      fontFamily: 'Oswald',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 32,
        tablet: 32,
        tabletMini: 31,
      },
    },
    heading5: {
      fontFamily: 'Lato',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 30,
        tablet: 30,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'Lato',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 24,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'Lato',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 14,
      },
      lineHeight: {
        tower: 20,
        tablet: 20,
        tabletMini: 20,
      },
    },
    cta: {
      fontFamily: 'Oswald',
      weight: {
        regular: 500,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 26,
      },
    },
    link: {
      fontFamily: 'Lato',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 32,
        tablet: 32,
        tabletMini: 31,
      },
    },
    caption: {
      fontFamily: 'Lato',
      weight: {
        regular: 700,
      },
      fontSize: {
        tower: 13,
        tablet: 12,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 16,
        tablet: 13,
        tabletMini: 13,
      },
    },
  },
  components: {
    button: {
      style: 'squared',
      uppercase: true,
    },
    rangeSlider: {
      background: '#666666', //inactive
      foreground: '#1F1F24', //highlight02
      thumb: '#F2F2F2', //background
      thumbBorder: '#666666', //inactive
    },
  },
  productGridColumns: {
    towerLandscape: 4,
  },
}
