import { Device } from '@abstractTypes/utils'
import { FrameAdvisorFaceShapeIcon } from '@components/FrameAdvisor/Survey/FrameAdvisorFaceShapeIcon'
import { FrameAdvisorModalTile } from '@components/FrameAdvisor/Survey/FrameAdvisorModalTile'
import { FluidIcon } from '@components/core/FluidIcon'
import { FaceShapesIcon } from '@components/core/Icons'
import { Modal } from '@components/core/Modal'
import {
  CloseIconContainer,
  ModalBody,
  ModalBodyWrapper,
  ModalContent,
  ModalWrapper,
} from '@components/core/Modal/styles'
import { ButtonTypography, Heading2 } from '@components/core/Typography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FaceShapeModalProps } from './types'

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;

  & > * {
    margin: ${pxToRem(12)};
  }
  ${tablet(
    `
    & > * {
    margin: ${clampValues(9, 12)};
  }
  `
  )}
  ${landscape(
    Device.tablet,
    `
    & > * {
    margin: ${clampValues(9, 12)};
  }
  `
  )}
`

export const ImageWrapper = styled.div`
  inline-size: 77%;
  block-size: 0;
  padding-block-end: 77%;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
`

export const StyledModal = styled(Modal)`
  ${altNavigation(`
    inset-block-start: ${pxToRem(44)};
  `)}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    inset-block-start: 0;
  `
  )}

  ${ModalContent} {
    border-radius: ${pxToRem(16)};
    ${tablet(Device.ipadMini, `border-radius: ${pxToRem(12)}`)}
  }

  ${ModalBodyWrapper} {
    block-size: unset;
  }

  ${ModalBody} {
    padding-block: ${pxToRem(88)};
    padding-inline: 0;
    inline-size: ${pxToRem(856)};
    min-block-size: ${pxToRem(1053)};
    ${landscape(`
        padding-block: ${pxToRem(88)} ;
        padding-inline:  ${pxToRem(75)};
        block-size: ${pxToRem(839)};
        inline-size: unset;
        min-block-size: unset;
        min-inline-size: ${pxToRem(1074)};
    `)};
    ${tablet(`
        padding-block: ${clampValues(84, 112)} ${clampValues(77, 102)};
        padding-inline:  0;
        inline-size: ${clampValues(624, 832)};
        min-block-size: ${clampValues(752, 1002)};
    `)}
    ${landscape(
      Device.tablet,
      `
        padding-block: ${clampValuesLandscape(84, 112)} ;
        padding-inline:  ${clampValuesLandscape(34, 42)};
        inline-size: unset;
        block-size: ${clampValuesLandscape(635, 848)};
        min-inline-size: ${clampValuesLandscape(772, 1031)};
        min-block-size: unset;
    `
    )}
    ${altNavigation(`
      padding-block: ${pxToRem(80)} ${pxToRem(40)};
      padding-inline:  0 ;
      block-size: ${pxToRem(720)};
      min-block-size: unset;
    `)};
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding-block: ${pxToRem(132)} ;
      padding-inline:  0;
      inline-size: ${pxToRem(1056)};
      block-size: ${pxToRem(1580)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      padding-block: ${pxToRem(176)} ;
      padding-inline:  0;
      block-size: ${pxToRem(1776)};
    `
    )}
    ${zoomLandscape(
      [150, 175],
      `
      padding-block: ${pxToRem(78)} ;
      padding-inline:  0;
      inline-size: ${pxToRem(1610)};
      block-size: ${pxToRem(970)};
    `
    )}
    ${zoomLandscape(
      [200],
      `
      inline-size: ${pxToRem(1500)}
      block-size: ${pxToRem(996)};
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      padding-block: ${pxToRem(55)} 0;
      padding-inline:  0 ;
      inline-size: ${pxToRem(1080)};
      block-size: ${pxToRem(845)};
    `
    )}
    ${altNavigationZoom(
      [200],
      `
      padding-block: ${pxToRem(112)} ;
      padding-inline:  0;
    `
    )}
  }

  ${ModalWrapper} {
    padding: 0;
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding: 0;
    `
    )}
  }

  ${CloseIconContainer} {
    ${zoomPortrait(
      [150, 175, 200],
      `
      inset-block-start: ${pxToRem(72)};
      inset-inline-end: ${pxToRem(60)};
      padding: ${pxToRem(5)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      inset-block-start: ${pxToRem(64)};
      inset-inline-end: ${pxToRem(31)};
    `
    )}
    ${zoomLandscape(
      [150, 175],
      `
      inset-block-start: ${pxToRem(48)};
      inset-inline-end: ${pxToRem(48)};
    `
    )}
    ${zoomLandscape(
      [200],
      `
      inset-block-start: ${pxToRem(60)};
      inset-inline-end: ${pxToRem(160)};
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      inset-block-start: ${pxToRem(32)};
      inset-inline-end: ${pxToRem(32)};
    `
    )}
  }
`

export const ModalCTA = styled.div`
  margin-block-start: ${pxToRem(32)};
  opacity: 0.7;
  color: ${({ theme }) => theme.colors.primaryLight};
  ${tablet(`
    margin-block-start: ${pxToRem(24)};
  `)}
`

export const ModalLink = styled(ButtonTypography)`
  text-decoration: underline;
`

export const FaceShapesModal: React.FC<FaceShapeModalProps> = ({ options }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const gender = useSelector(state => state.frameAdvisor.survey.gender)
  const { zoomLevel } = useNavigationContext()

  return (
    <>
      <ModalCTA>
        <ModalLink
          onClick={() => setVisible(true)}
          data-analytics_available_call="0"
          aria-label={`${t('FaceShapes.notSure')}`}
          data-element-id="FrameAdv_FaceExample"
          data-test="FrameAdv_FaceExample"
          color="primaryLight"
        >
          {t('FaceShapes.notSure')}
        </ModalLink>
      </ModalCTA>
      <StyledModal
        visible={visible}
        onCancel={() => setVisible(false)}
        dataCalls="0"
        title={
          <div>
            <FluidIcon
              icon={FaceShapesIcon}
              size={(48 * zoomLevel) / 100}
              tabletMinSize={36}
              tabletMaxSize={48}
            />
            <Heading2 aria-label={t('FaceShapes.title')}>{t('FaceShapes.title')}</Heading2>
          </div>
        }
      >
        <OptionsWrapper>
          {options.map(option => (
            <FrameAdvisorModalTile
              key={option.id}
              icon={
                <FrameAdvisorFaceShapeIcon gender={gender as string} iconKey={option.assetKey} />
              }
              title={option.title}
              description={t(`FaceShapes.${option.assetKey}`)}
            />
          ))}
        </OptionsWrapper>
      </StyledModal>
    </>
  )
}
