import { LoveHateThumbsProps } from '@abstractTypes/frameAdvisor'
import {
  hateItem,
  loveItem,
  removeFromUndoableList,
  removeItemFromMySelection,
  saveToUndoableList,
} from '@actions/frameAdvisor'
import { ThumbUpIcon } from '@components/core/Icons'
import { ThumbDownIcon as ThumbDownIconDefault } from '@components/core/Icons/ThumbDown'
import { ThumbBtn } from '@components/core/ThumbBtn/style'
import { useSendAnalyticsProductsEvent } from '@hooks/analyticsHooks'
import { useActions } from '@hooks/useActions'
import { useThemeContext } from '@hooks/useThemeContext'
import { pxToRem } from '@libs/styled'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { LoveHateBtnContainer } from './styles'
import { useTranslation } from 'react-i18next'
import { EVENTS_ID } from '@libs/analytics'

export const LoveHateActionsBtns: React.FC<LoveHateThumbsProps> = ({
  product,
  dataAttrs,
  playlistId,
  ThumbDownIcon = ThumbDownIconDefault,
  ThumbUpLikedIcon = ThumbUpIcon,
  index = 0,
  className = '',
  onHateClick,
}) => {
  const theme = useThemeContext()
  const selectedItems = useSelector(state => state.frameAdvisor.mySelection)
  const isItemLiked = selectedItems.some(item => item.UPC === product.UPC)
  const sendAddToWishlistEvent = useSendAnalyticsProductsEvent({
    id: EVENTS_ID.event,
    Events_ProdFavAdd: '1',
  })
  const { zoomLevel } = useNavigationContext()

  const actions = useActions({
    loveItem,
    hateItem,
    removeItemFromMySelection,
    saveToUndoableList,
    removeFromUndoableList,
  })

  const handleLoveClick = useCallback(() => {
    sendAddToWishlistEvent && sendAddToWishlistEvent(product)
    if (isItemLiked) {
      actions.removeItemFromMySelection(product)
    } else {
      actions.loveItem(product, playlistId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemLiked])

  const handleHateClick = useCallback(() => {
    const onClick = onHateClick || actions.hateItem
    onClick(product, playlistId)
    actions.saveToUndoableList(product)
  }, [onHateClick, actions, product, playlistId])

  const { t } = useTranslation()

  const renderLikeHateButtons = useCallback(() => {
    return (
      <>
        <ThumbBtn
          onClick={handleHateClick}
          data-element-id={`Tiles_Tile${index}_ThumbDown`}
          data-test={`Tiles_Tile${index}_ThumbDown`}
          data-description={dataAttrs && dataAttrs.description}
          aria-label={t('ARIA.dislikeIcon.label')}
        >
          <ThumbDownIcon
            stroke={theme.colors.functional01}
            fill={theme.colors.functional01}
            height={pxToRem(18, zoomLevel / 100)}
            width={pxToRem(19, zoomLevel / 100)}
          />
        </ThumbBtn>

        <ThumbBtn
          onClick={handleLoveClick}
          data-element-id={`Tiles_Tile${index}_ThumbUp`}
          data-test={`Tiles_Tile${index}_ThumbUp`}
          data-description={dataAttrs && dataAttrs.description}
          wishlisted={isItemLiked}
          aria-label={t('ARIA.likeIcon.label')}
        >
          <ThumbUpLikedIcon
            stroke={isItemLiked ? theme.colors.primaryLight : theme.colors.functional02}
            fill={isItemLiked ? theme.colors.primaryLight : theme.colors.functional02}
            height={pxToRem(18, zoomLevel / 100)}
            width={pxToRem(19, zoomLevel / 100)}
          />
        </ThumbBtn>
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAttrs, handleHateClick, handleLoveClick /*,isItemLiked*/])

  return (
    <LoveHateBtnContainer className={className}>{renderLikeHateButtons()}</LoveHateBtnContainer>
  )
}
