import { IconProps } from '@abstractTypes/icons'

export const FaceShapeRectangularIcon: React.FC<IconProps & { cut?: boolean }> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  className = '',
  cut = false,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 ${cut ? 24 : 0} 32 48`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 48H3C1.35 48 0 46.65 0 45V3C0 1.35 1.35 0 3 0H29C30.65 0 32 1.35 32 3V45C32 46.65 30.65 48 29 48ZM3 2C2.45 2 2 2.45 2 3V45C2 45.55 2.45 46 3 46H29C29.55 46 30 45.55 30 45V3C30 2.45 29.55 2 29 2H3Z"
        fill={fill}
      />
    </svg>
  )
}
