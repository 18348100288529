import { FluidIcon } from '@components/core/FluidIcon'
import * as Icons from '@components/core/Icons'
import React from 'react'
import { FrameAdvisorFaceShapeIconProps } from './types'

type IconName = 'Rectangle' | 'Square' | 'Heart' | 'Diamond' | 'Round' | 'Oval'
type IconGender = 'Man' | 'Woman' | 'Ungendered'

const iconNameMap: Record<string, IconName> = {
  oval_round: 'Oval',
  oval_angled: 'Diamond',
  round: 'Round',
  square: 'Square',
  triangle: 'Heart',
  rectangle: 'Rectangle',
}

const iconGenderMap: Record<string, IconGender> = {
  male: 'Man',
  female: 'Woman',
  skip: 'Ungendered',
}

export const FrameAdvisorFaceShapeIcon: React.FC<FrameAdvisorFaceShapeIconProps> = ({
  gender,
  iconKey,
  size = 58,
  tabletMinSize = 43,
  tabletMaxSize = 58,
}) => {
  if (!iconKey) return null
  const iconName = iconNameMap[iconKey]
  const iconGender = iconGenderMap[gender.toLowerCase()]
  const iconComponent = Icons[`FaFaceShape${iconName}${iconGender}Icon`]
  return iconComponent ? (
    <FluidIcon
      icon={iconComponent}
      size={size}
      tabletMinSize={tabletMinSize}
      tabletMaxSize={tabletMaxSize}
    />
  ) : null
}
