import { IconComponent, IconProps } from '@abstractTypes/icons'
import { useResponsiveValue } from '@hooks/useResponsiveValue'
import { valueToTuple } from '@libs/utils'
import { isNumber } from 'lodash'
import { useMemo } from 'react'

type IconSize =
  | number
  | {
      width: number
      height: number
    }

type FluidIconProps = Omit<IconProps, 'width' | 'height'> & {
  icon: IconComponent
} & {
  size: IconSize
  tabletMinSize: IconSize
  tabletMaxSize: IconSize
}

const useWidthAndHeight = (size: IconSize) =>
  useMemo(() => (isNumber(size) ? valueToTuple(size) : [size.width, size.height]), [size])

export const FluidIcon: React.FC<FluidIconProps> = ({
  icon,
  size,
  tabletMinSize,
  tabletMaxSize,
  ...iconProps
}) => {
  const [width, height] = useWidthAndHeight(size)
  const [tabletMinWidth, tabletMinHeight] = useWidthAndHeight(tabletMinSize)
  const [tabletMaxWidth, tabletMaxHeight] = useWidthAndHeight(tabletMaxSize)

  const iconWidth = useResponsiveValue(width, tabletMinWidth, tabletMaxWidth)
  const iconHeight = useResponsiveValue(height, tabletMinHeight, tabletMaxHeight)
  const IconComponent = icon

  return <IconComponent width={iconWidth} height={iconHeight} {...iconProps} />
}
