import { IconProps } from '@abstractTypes/icons'
export const FaFaceShapeRoundWomanIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M47.707 36.293C45.736 34.322 45.001 25.867 45 21C45 9.421 35.58 0 24 0C12.42 0 3 9.421 3 21C2.999 25.867 2.264 34.322 0.293 36.293L0 36.586V37C0 43.065 4.935 48 11 48C13.882 48 16.501 46.877 18.465 45.056C20.203 45.657 22.06 46 24 46C25.94 46 27.797 45.657 29.535 45.056C31.499 46.877 34.118 48 37 48C43.065 48 48 43.065 48 37V36.586L47.707 36.293ZM9 29V19.964C14.746 19.542 18.918 15.538 20.405 11.81C22.412 14.286 27.276 17.87 39 19.85V29C39 37.271 32.271 44 24 44C15.729 44 9 37.271 9 29ZM37 46C34.974 46 33.108 45.319 31.602 44.184C37.165 41.387 41 35.64 41 29V19L40.156 18.013C22.377 15.205 20.999 8.901 20.99 8.858L19 9C19 12.54 14.276 18 8 18L7 19V29C7 35.64 10.835 41.387 16.398 44.184C14.892 45.319 13.026 46 11 46C6.161 46 2.202 42.162 2.007 37.37C4.914 33.734 5 22.329 5 21C5 10.523 13.523 2 24 2C34.477 2 43 10.523 43 21C43 22.329 43.086 33.734 45.993 37.37C45.798 42.162 41.839 46 37 46Z"
        fill={fill}
      />
      <path d="M26.434 23.057H24.434V31H21.986V33H26.434V23.057Z" fill={fill} />
      <path
        d="M21.878 38.121C22.445 38.688 23.198 39 24 39C24.802 39 25.555 38.688 26.122 38.121L27.313 36.928L24 35.958L20.688 36.928L21.878 38.121Z"
        fill={fill}
      />
    </svg>
  )
}
