import { EngravingSelectedOptions } from '@abstractTypes/engraving'
import { Text } from '@components/core/Typography'
import { pxToRem } from '@libs/styled'
import { radioFonts } from '@theme/engraving'
import React from 'react'
import styled from 'styled-components'

export const EngavingText = styled(Text)`
  margin-block-end: ${pxToRem(5)};
  text-transform: capitalize;
`

export const EngravingSides = styled(Text)`
  grid-column-start: 2;
  text-align: start;
`

interface CartEngravingDetailsProps {
  engravingOption: EngravingSelectedOptions
}

const CartEngravingDetailsV2: React.FC<CartEngravingDetailsProps> = ({ engravingOption }) =>
  engravingOption ? (
    <>
      <EngavingText>
        {[radioFonts[engravingOption.font].fontName, engravingOption.color].join(' / ')}
      </EngavingText>
      <EngravingSides>
        {engravingOption.sides
          .map(side => side.text && side.text)
          .filter(Boolean)
          .join(' / ')}
      </EngravingSides>
    </>
  ) : null

export default CartEngravingDetailsV2
