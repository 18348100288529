import { Device } from '@abstractTypes/utils'
import { PrimaryButton } from '@components/core/Button'
import { landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const FrameAdvisorBottomCTAContainer = styled.div`
  margin-block: ${pxToRem(60)};
  margin-inline: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  inline-size: 100%;

  ${landscape(Device.tower, `margin-block-end: ${pxToRem(116)}`)}
  ${tablet(`
  margin-block: ${clampValues(10, 60)} ${pxToRem(92)};
  margin-inline:  0 ;
  `)}
  ${landscape(Device.tablet, `margin-block-end: ${pxToRem(104)};`)}
  ${zoomPortrait([150, 175], `margin-block-end: ${pxToRem(168)}`)}
  ${zoomPortrait([200], `margin-block-end: ${pxToRem(192)}`)}
`

export const LoadMoreButton = styled(PrimaryButton)`
  min-inline-size: ${pxToRem(237)};
  ${tablet(`min-inline-size: ${pxToRem(218)};`)};
`
