import { InfoIcon } from '@components/core/Icons'
import { useClickOutside } from '@hooks/useClickOutside'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useThemeContext } from '@hooks/useThemeContext'
import { pxToRem } from '@libs/styled'
import React, { useRef, useState } from 'react'
import { Disclaimer } from '../Disclaimer'
import {
  PrivacyPolicyButton,
  PrivacyPolicyTooltipContainer,
  PrivacyPolicyTooltipWrapper,
} from './styles'
import { PrivacyPolicyTooltipProps } from './types'
import { useAriaFocusTrap } from '@hooks/useAriaFocusTrap'
import { useTranslation } from 'react-i18next'

const PrivacyPolicyTooltip: React.FC<PrivacyPolicyTooltipProps> = ({
  stroke,
  className,
  isFixed = false,
}) => {
  const theme = useThemeContext()
  stroke = stroke || theme.colors.primaryDark

  const tooltipRef = useRef<HTMLDivElement>(null)
  const [tooltipVisible, setPrivacyTooltipVisible] = useState(false)

  const { zoomLevel } = useNavigationContext()

  useClickOutside(tooltipRef, () => setPrivacyTooltipVisible(false))

  const { t } = useTranslation()

  const { setRef } = useAriaFocusTrap(tooltipVisible)

  return (
    <PrivacyPolicyTooltipContainer ref={tooltipRef} className={className} isFixed={isFixed}>
      <PrivacyPolicyButton
        onClick={() => setPrivacyTooltipVisible(s => !s)}
        aria-label={t('Core.privacyPolicyLabel')}
      >
        <InfoIcon
          stroke={stroke}
          width={pxToRem(32, zoomLevel / 100)}
          height={pxToRem(32, zoomLevel / 100)}
        />
      </PrivacyPolicyButton>
      <PrivacyPolicyTooltipWrapper visible={tooltipVisible} ref={setRef}>
        <Disclaimer
          floatingDisclaimer={false}
          withShadow={false}
          closeTooltip={() => setPrivacyTooltipVisible(false)}
          disclaimerColor="primaryDark"
        />
      </PrivacyPolicyTooltipWrapper>
    </PrivacyPolicyTooltipContainer>
  )
}

export default PrivacyPolicyTooltip
