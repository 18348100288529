import { IconProps } from '@abstractTypes/icons'


const EliminatesGlareIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#222222',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 75 74"
      fill="none"
    >
      <g clipPath="url(#clip0_3198_44360)">
        <path
          d="M37.25 15.4167C25.2679 15.4167 15.5208 25.0983 15.5208 37C15.5208 48.9017 25.2679 58.5833 37.25 58.5833C49.2321 58.5833 58.9792 48.9017 58.9792 37C58.9792 25.0983 49.2321 15.4167 37.25 15.4167ZM37.25 55.5C26.9799 55.5 18.625 47.2012 18.625 37C18.625 26.7988 26.9799 18.5 37.25 18.5C47.5201 18.5 55.875 26.7988 55.875 37C55.875 47.2012 47.5201 55.5 37.25 55.5Z"
          fill={fill}
        />
        <path d="M38.8021 0H35.6979V9.25H38.8021V0Z" fill={fill} />
        <path
          d="M62.4918 9.74787L55.907 16.2885L58.1019 18.4688L64.6868 11.9281L62.4918 9.74787Z"
          fill={fill}
        />
        <path d="M74.5 35.4583H65.1875V38.5417H74.5V35.4583Z" fill={fill} />
        <path
          d="M58.1009 55.5324L55.906 57.7126L62.4908 64.2533L64.6858 62.073L58.1009 55.5324Z"
          fill={fill}
        />
        <path d="M38.8021 64.75H35.6979V74H38.8021V64.75Z" fill={fill} />
        <path
          d="M16.397 55.5312L9.81211 62.0719L12.0071 64.2521L18.5919 57.7115L16.397 55.5312Z"
          fill={fill}
        />
        <path d="M9.3125 35.4583H0V38.5417H9.3125V35.4583Z" fill={fill} />
        <path
          d="M12.0082 9.74673L9.81327 11.9269L16.3981 18.4676L18.5931 16.2874L12.0082 9.74673Z"
          fill={fill}
        />
      </g>
      {/* <defs>
        <clipPath id="clip0_3198_44360">
          <rect  width={width} height={height} fill="white" />
        </clipPath>
      </defs> */}
    </svg>
  )
}

export default EliminatesGlareIcon
