import { Heading5 } from '@components/core/Typography'
import { altNavigationZoom, tablet, zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

export const BannerWrapper = styled.div<{ asPlaceholder: boolean; isSticky: boolean | undefined }>`
  position: ${({ isSticky }) => (!isSticky ? 'relative' : 'sticky')};
  z-index: 2;
  inset-block-start: 0;
  block-size: ${pxToRem(63)};
  inline-size: 100%;
  text-align: center;
  inset-block-end: ${pxToRem(53)};
  background-color: ${({ theme }) => theme.colors.primaryLight};
  &:before {
    content: '';
    background: ${({ theme }) => theme.colors.primaryLight};
    position: absolute;
    inset-block-start: -2px;
    block-size: 2px;
    inline-size: 100%;
    inset-inline-start: 0;
  }

  ${({ asPlaceholder }) =>
    !asPlaceholder &&
    css`
      // This is a small trick to prevent a tiny overlap between the discount and the sticky title
      border-block-end: 2px solid ${({ theme }) => theme.colors.primaryLight};
      -webkit-box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 1);
      -moz-box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 1);
      box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 1);
      // The small trick for the overlap ends here
    `}

  ${tablet('position: sticky; inset-block-start: -1px;')}
  ${zoomPortrait(
    [125, 150, 175, 200],
    `
    block-size: auto;
  `
  )}
`

export const BannerText = styled(Heading5)`
  margin-inline-start: ${pxToRem(16)};
`

export const BannerContent = styled.div<{ bgColor: string }>`
  min-block-size: 100%;
  max-block-size: 100%;
  inline-size: 100%;
  background-color: ${({ bgColor }) => bgColor};
  padding-block: ${pxToRem(18)};
  padding-inline: ${pxToRem(24)};
  ${zoomPortrait(
    [150, 175, 200],
    `
    padding-block: ${pxToRem(24)} ;
    padding-inline:  ${pxToRem(124)};
  `
  )}
  ${altNavigationZoom(
    [100, 110, 125],
    `
    padding-block: ${pxToRem(24)} ;
    padding-inline:  ${pxToRem(84)};
  `
  )}
  ${altNavigationZoom(
    [150, 175],
    `
    padding-block: ${pxToRem(24)} ;
    padding-inline:  ${pxToRem(96)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    padding-block: ${pxToRem(24)};
    padding-inline:  ${pxToRem(108)};
  `
  )}
  display: flex;
  justify-content: center;
  align-items: center;
`
