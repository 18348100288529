import { CustomItemEngraving, CustomSide } from '@abstractTypes/engraving'
import { Maybe } from '@abstractTypes/graphqlTypes'
import {
  OOLocalizedConfiguration,
  OOThumbnailSide,
  RBLocalizedConfiguration,
  RBThumbnailSide,
} from '@abstractTypes/oneConfigurator'
import { Product } from '@abstractTypes/product'
import { getBrowserLocalStorageCustomReturnUrlKey } from '@config/config.base'
import { getSiteAbsolutePath } from '@libs/url'
import productCard from '@theme/productCard'
import { get } from 'lodash'

const PRODUCT_RETURN_URL = getBrowserLocalStorageCustomReturnUrlKey()

export const getFrameEngravingInfo = (
  localizedConfiguration: RBLocalizedConfiguration
): CustomSide[] => {
  const frameEngravingStandard = [
    { title: 'ir', value: 'internal_right' },
    { title: 'il', value: 'internal_left' },
    { title: 'el', value: 'external_left' },
    { title: 'er', value: 'external_right' },
  ]
  const frameEngravingPremium = [
    { title: 'ir', value: 'art_engraving_internal_right' },
    { title: 'il', value: 'art_engraving_internal_left' },
    { title: 'el', value: 'art_engraving_external_left' },
    { title: 'er', value: 'art_engraving_external_right' },
  ]

  const frameEngraving =
    localizedConfiguration.engraving_type === 'Premium'
      ? frameEngravingPremium
      : frameEngravingStandard

  const sides = frameEngraving.reduce((sidesMap: { title: string; value: string }[], sideObj) => {
    const key = sideObj.value as keyof RBLocalizedConfiguration
    const value = localizedConfiguration[key] as string
    if (value?.length) {
      sidesMap.push({ title: `Engraving.${sideObj.title}`, value })
    }
    return sidesMap
  }, [])

  return sides
}

export const getEngravingFromOneConfAttributes = (
  localizedConfiguration: RBLocalizedConfiguration
): CustomItemEngraving => {
  const {
    standard_engraving_font_render_color,
    choose_font_color_engraving,
    choose_your_font_engraving,
    engraving_type,
    case_engraving_line_1,
    case_engraving_line_2,
    case_sku,
    case_category,
    choose_your_font_case_engraving,
    temple_tips_sku,
  } = localizedConfiguration

  const sides = getFrameEngravingInfo(localizedConfiguration)
  const caseEngraving = [case_engraving_line_1, case_engraving_line_2].filter(Boolean).join(', ')
  const caseType = `Engraving.CaseEngraving.${(case_category || 'standard').toLowerCase()}`
  const color =
    typeof choose_font_color_engraving === 'string'
      ? choose_font_color_engraving
      : standard_engraving_font_render_color

  return {
    color,
    font: choose_your_font_engraving,
    sides,
    frameEngravingType: engraving_type,
    templetipColor: temple_tips_sku,
    caseType,
    caseEngraving,
    caseFont: choose_your_font_case_engraving,
    caseColor: case_sku,
  }
}

export const getEngravingFromOneConfAttributesForOO = (
  localizedConfiguration: OOLocalizedConfiguration
): CustomItemEngraving => {
  const { font, ocp_etching_text, text_etch_color, ocp_microbag, etch_or_logo } =
    localizedConfiguration

  return {
    caseType: 'Engraving.CaseEngraving.standard',
    caseColor: ocp_microbag,
    sides: ocp_etching_text
      ? [{ title: 'Engraving.lens', value: ocp_etching_text.toString() }]
      : [],
    color: text_etch_color || '',
    font: font ? font.toString() : '',
    logo: etch_or_logo ? etch_or_logo.toString() : '',
  }
}

export const setReturnUrl = (prevPage: string): void => {
  const storeBaseUrl = getSiteAbsolutePath()
  const partialUrl = prevPage.replace(storeBaseUrl.replace(/\/$/, ''), '') || '/'
  window.localStorage.setItem(PRODUCT_RETURN_URL, partialUrl)
}

export const getReturnUrl = (storeUrl: string): string => {
  const partialUrl = window.localStorage.getItem(PRODUCT_RETURN_URL) || '/'
  return storeUrl.replace(/\/$/, '') + partialUrl
}

export const cleanupOneConfiguratorRecipes = (): void => {
  // rayRecipe and oakRecipe
  Object.keys(localStorage)
    .filter(k => k.startsWith('rayRecipe') || k.startsWith('oakRecipe'))
    .map(k => {
      localStorage.removeItem(k)
    })
}

export const RBMasterBrand = 'rb'
export const OOMasterBrand = 'oo'

const subBrandsRB = [RBMasterBrand, 'rx', 'ry', 'rj']
const subBrandsOO = [OOMasterBrand, 'ox', 'oy', 'oj']

export const getCustomizableBrand = (brand: string): string => {
  const formattedBrand = brand.toLowerCase()
  if (subBrandsRB.includes(formattedBrand)) return RBMasterBrand.toLowerCase()
  if (subBrandsOO.includes(formattedBrand)) return OOMasterBrand.toLowerCase()
  return formattedBrand
}

export const isRBBrand = (brand: string) => subBrandsRB.includes(brand.toLowerCase())
export const isOOBrand = (brand: string) => subBrandsOO.includes(brand.toLowerCase())

export const getThumbnailUrlForCustom = (
  recipeId: Maybe<number> | string,
  brand: string,
  scale = 5,
  backgroundColor = productCard.backgroundColor
): string => {
  const url = 'https://cdn-prod.fluidconfigure.com/imagecomposer'
  const side = isRBBrand(brand) ? RBThumbnailSide.FrontLeft : OOThumbnailSide.Left
  const format: 'jpg' | 'png' = 'png'

  let thumbnailUrl = `${url}/recipe/${Number(recipeId)}/image/${side}.${format}?scale=.${scale}`
  if (backgroundColor) {
    const postCompose = `[{"background":"${backgroundColor}"}]`
    thumbnailUrl = `${thumbnailUrl}&postCompose=${encodeURIComponent(postCompose)}`
  }
  return thumbnailUrl
}

export const formatOcpPayloadField = (product: Product) => {
  // Bom is defined when we use OneConfigurator

  const bom = get(product, 'customItem.custom.bom')
  if (!bom) return product.customItem

  return {
    custom: {
      bom,
    },
  }
}
