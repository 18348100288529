import { Device } from '@abstractTypes/utils'
import { ElectronicsHeading4 } from '@components/core/Typography/ElectronicsTypography'
import { altNavigation, landscape, tablet } from '@libs/media'
import { clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const ArticleContainer = styled.div`
  max-inline-size: 74.815vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-end: 2rem;
  ${tablet(`
    margin-block-end: 1rem;
  `)}
  ${landscape(`
    margin-block-end: 2rem;
    padding-block: 0 ;
    padding-inline:  ${pxToRem(36)};
  `)}
  ${altNavigation(`
    padding-inline-end: ${pxToRem(21)};
  `)}
`

export const ArticleTitle = styled(ElectronicsHeading4)`
  margin-block-start: 1.36rem;

  :first-child {
    margin-block-start: 0;
  }
`

export const ArticleText = styled.div`
  padding-block-start: ${pxToRem(30)};

  ul {
    margin-block-end: 0;
  }
  ${tablet(`
    padding-block-start: ${pxToRem(13)};
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-block-start: ${clampValuesLandscape(64, 88)};
  `
  )}
`
