import styled from 'styled-components'

export const ColorImage = styled.div<{
  src?: string
}>`
  inline-size: 100%;
  block-size: 100%;
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`
