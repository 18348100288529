import { ButtonProps } from '@abstractTypes/components'
import { Device } from '@abstractTypes/utils'
import { ButtonTypography } from '@components/core/Typography'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const DefaultButtonStyle = styled(ButtonTypography)<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: solid 2px transparent;
  margin: 0;
  ${({ theme }) =>
    theme.components.button.style === 'rounded'
      ? `border-radius: ${pxToRem(theme.components.button.borderRadius || 64)}`
      : null};
  padding-block: ${pxToRem(14)};
  padding-inline: ${pxToRem(30)};
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  ${({ theme, textUppercase }) =>
    theme.components.button.uppercase || textUppercase
      ? 'text-transform: uppercase'
      : 'text-tranform: none'};
  color: inherit;
  inline-size: ${({ fullWidth }) => (fullWidth ? '100%' : null)};
  ${({ disablePointerEvents }) => disablePointerEvents && 'pointer-events: none;'}

  &:disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.8;
    > svg {
      stroke: currentcolor;
    }
  }
  ${tablet(
    `
    padding-block: ${clampValues(12, 14)} ;
    padding-inline:  ${pxToRem(30)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    padding-block: ${clampValuesLandscape(12, 16)} ;
    padding-inline:  ${pxToRem(30)};
  `
  )}
`

export const PrimaryButtonDark = styled(DefaultButtonStyle)`
  background-color: ${({ theme }) => theme.colors.highlight01};
  color: ${({ theme }) => theme.colors.primaryLight};
  border: 1px solid transparent;
  ${({ theme, active }) =>
    active &&
    `
    background-color: ${theme.colors.highlight01Pressed};
    color: ${theme.colors.primaryLight};
    `}
  > svg {
    opacity: 0.8;
    stroke: currentcolor;
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.highlight01Pressed};
    color: ${({ theme }) => theme.colors.primaryLight};
  }

  &:disabled,
  &[aria-disabled='true'] {
    background-color: ${({ theme }) => theme.colors.inactive};
    color: ${({ theme }) => theme.colors.primaryLight};
  }
`
export const PrimaryButtonLight = styled(DefaultButtonStyle)`
  background-color: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.highlight01};
  border: 1px solid transparent;
  ${({ theme, active }) =>
    active &&
    `
    background-color: ${theme.colors.primaryLightPressed};
    color: ${theme.colors.highlight01};
    border: 2px solid ${theme.colors.highlight01};
    `};

  &:active {
    background-color: ${({ theme }) => theme.colors.primaryLightPressed};
    color: ${({ theme }) => theme.colors.highlight01};
    border: 2px solid ${({ theme }) => theme.colors.highlight01};
  }

  &:disabled,
  &[aria-disabled='true'] {
    background-color: ${({ theme }) => theme.colors.primaryLight};
    color: ${({ theme }) => theme.colors.inactive};
  }
`

export const SecondaryButtonDark = styled(DefaultButtonStyle)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.highlight01};
  border: 1px solid ${({ theme }) => theme.colors.highlight01};
  ${({ theme, active }) =>
    active
      ? theme.components.button.style === 'rounded'
        ? `
          color: ${theme.colors.primaryDarkPressed};
          border: 1px solid ${theme.colors.primaryDarkPressed};
      `
        : `
          border: 1px solid transparent;
          border-block-end: 1px solid ${theme.colors.primaryDark};
          color: ${theme.colors.primaryLight};
          background-color: ${theme.colors.primaryDark};
    `
      : null}

  ${({ theme }) =>
    theme.components.button.style === 'rounded'
      ? `&:active {
        color: ${theme.colors.primaryDarkPressed};
        border: 1px solid ${theme.colors.primaryDarkPressed};
	  }`
      : `&:active {
		border: 1px solid transparent;
		border-block-end: 1px solid ${theme.colors.primaryDark};
	}`}

  &:disabled,
  &[aria-disabled="true"] {
    color: ${({ theme }) => theme.colors.inactive};
    border: 1px solid ${({ theme }) => theme.colors.inactive};
  }
`
export const SecondaryButtonLight = styled(DefaultButtonStyle)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primaryLight};
  border: 1px solid ${({ theme }) => theme.colors.primaryLight};
  > svg {
    stroke: currentcolor;
  }

  ${({ theme, active }) =>
    active
      ? theme.components.button.style === 'rounded'
        ? `
        color: ${theme.colors.primaryLightPressed};
        border: 1px solid ${theme.colors.primaryLightPressed};
        background-color: ${theme.colors.highlight01Pressed};
      `
        : `
        border: 1px solid transparent;
        border-block-end: 1px solid ${theme.colors.primaryLight};
        color: ${theme.colors.primaryDark};
        background-color: ${theme.colors.primaryLight};
        `
      : null}

  ${({ theme }) =>
    theme.components.button.style === 'rounded'
      ? `&:active {
        color: ${theme.colors.primaryLightPressed};
        border: 1px solid ${theme.colors.primaryLightPressed};
	  }`
      : `&:active {
		border: 1px solid transparent;
		border-block-end: 1px solid ${theme.colors.primaryLight};
	}`}

  &:disabled,
  &[aria-disabled="true"] {
    color: ${({ theme }) => theme.colors.inactive};
    border: 1px solid ${({ theme }) => theme.colors.inactive};
  }
`
