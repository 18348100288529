import { Device } from '@abstractTypes/utils'
import { Label, LinkTypography } from '@components/core/Typography'
import { PrivacyPolicyModal } from '@components/PrivacyPolicyModal'
import { tablet } from '@libs/media'
import { handleBodyScrollLock } from '@libs/scrollLock'
import { pxToRem } from '@libs/styled'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PrivacyPolicyModalLinkProps } from './types'

const modalID = `link-to-modal-${Math.floor(Math.random() * 0xffff)}`

const LinkTypographyStyled = styled(LinkTypography)`
  font-size: ${({ theme }) => pxToRem(theme.fonts.label.fontSize.tower)};
  line-height: ${({ theme }) => pxToRem(theme.fonts.label.lineHeight.tower)};

  /* // TODO remove font styling */
  ${({ theme }) => `
    ${tablet(`
      font-size:  ${pxToRem(theme.fonts.label.fontSize.tablet)};
      line-height: ${pxToRem(theme.fonts.label.lineHeight.tablet)};
    `)}

    ${tablet(
      Device.ipadMini,
      `
        font-size:  ${pxToRem(theme.fonts.label.fontSize.tabletMini)};
        line-height: ${pxToRem(theme.fonts.label.lineHeight.tabletMini)};
      `
    )}
  `}
`

export const PrivacyPolicyModalLink: React.FC<PrivacyPolicyModalLinkProps> = ({
  linkText,
  linkClickableText,
  className,
}) => {
  const { t } = useTranslation()
  const targetElement = useRef<Element | null>(null)
  const [isOpened, setModalOpened] = useState(false)

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  useEffect(() => {
    if (document.body.clientWidth <= 1024) {
      handleBodyScrollLock(
        targetElement.current,
        isOpened,
        modalID,
        (newTarget: Element) => (targetElement.current = newTarget)
      )
    }
  }, [isOpened])

  const toggleModal = () => {
    setModalOpened(opened => !opened)
  }

  return (
    <>
      <Label className={`link-to-modal ${className} `} color="inactive">
        {`${t(linkText)} `}
        <LinkTypographyStyled
          className="link-to-modal__clickable-text"
          onClick={toggleModal}
          textSize="label"
          textSizeTablet="label"
          textSizeTabletMini="label"
          tabIndex={0}
        >
          {t(linkClickableText)}
        </LinkTypographyStyled>
      </Label>
      <PrivacyPolicyModal visible={isOpened} onCancel={toggleModal} />
    </>
  )
}
