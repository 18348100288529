import { ContentNuance, ContentNuanceTabs, PageNuance } from '@abstractTypes/graphqlTypes'
import { NuancePage, NuanceTab } from '@abstractTypes/nuance'
import { StandardProduct } from '@abstractTypes/product'
import { QueryResult } from '@apollo/client'
import { useNuanceTabContent, usePageData } from '@data'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from './usePrevious'

interface HookResutl extends Pick<QueryResult<NuancePage>, 'data' | 'error' | 'loading'> {
  setTabIdSelected: (value: string) => void
}

export const useNuance = (product?: StandardProduct): HookResutl => {
  const { data: pageData, loading, error } = usePageData<{ page: PageNuance }>('PageNuance')
  const [tabIdSelected, setTabIdSelected] = useState<string>()
  const {
    data: nuanceTabContent,
    loading: loadingTabContent,
    error: errorTabContent,
  } = useNuanceTabContent(
    {
      id: tabIdSelected ?? '',
      isNuanceFrame: product?.isNuanceFrame,
      isNuanceAccessories: product?.isNuanceAccessories,
    },
    { skip: !tabIdSelected }
  )
  const [tabs, setTabs] = useState<NuancePage['tabs']>()
  const previousTabs = usePrevious(tabs)

  useEffect(() => {
    const contentNuance = (pageData?.page.content?.[0] as ContentNuance | undefined)?.contentNuance
    const tabContent = nuanceTabContent?.nuanceTabContent
    if (contentNuance || tabs) {
      const pageDataTabs = (tabs ?? contentNuance) as NuancePage['tabs'] | ContentNuanceTabs[]

      const formattedTabs: NuancePage['tabs'] = pageDataTabs.map(tab => {
        const isTabDowloaded = tab.id === tabContent?.id
        return {
          id: tab.id,
          label: tab.label,
          name: tab.name,
          hasShortMedia: tab.hasShortMedia,
          title: isTabDowloaded ? tabContent?.title : (tab as NuanceTab).title,
          data: isTabDowloaded ? tabContent.data : (tab as NuanceTab).data,
          cta: isTabDowloaded ? tabContent?.cta : (tab as NuanceTab).cta,
          hero: isTabDowloaded ? tabContent?.hero : (tab as NuanceTab).hero,
        }
      })

      !isEqual(previousTabs, formattedTabs) && setTabs(formattedTabs)
    }
  }, [nuanceTabContent?.nuanceTabContent, pageData, previousTabs, tabs])

  return useMemo(
    () => ({
      data: tabs ? { tabs } : undefined,
      loading: loading || loadingTabContent,
      error: error || errorTabContent,
      setTabIdSelected,
    }),
    [tabs, loading, loadingTabContent, error, errorTabContent]
  )
}
