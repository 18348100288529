import { CMSIcon, CMSIconKey } from '@components/core/Icons/CMSIcon'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { useMemo } from 'react'
import styled from 'styled-components'

interface IconContainerProps {
  iconId?: Maybe<CMSIconKey>
}

const ContainerIcon = styled.div`
  margin-inline-end: ${pxToRem(25)};
`

export const IconMenu: React.FC<IconContainerProps> = ({ iconId }) => {
  const isIpad = isIPadView()
  const { zoomLevel, landscapeNavigation, isAlternativeNavigation } = useNavigationContext()

  const iconSize = useMemo(() => {
    if (isIpad && landscapeNavigation) {
      return clampValuesLandscape(36, 50)
    }
    if (isIpad) {
      return clampValues(36, 50)
    }
    if (isAlternativeNavigation) {
      return pxToRem(50, zoomLevel / 100)
    }
    return pxToRem(50, zoomLevel / 100)
  }, [isIpad, landscapeNavigation, isAlternativeNavigation, zoomLevel])

  if (!iconId) return null

  return (
    <ContainerIcon>
      <CMSIcon iconKey={iconId} width={iconSize} height={iconSize} fill="#333" />
    </ContainerIcon>
  )
}
