import { IconProps } from '@abstractTypes/icons'

export const FaceShapeSquaredIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37 40H3C1.35 40 0 38.65 0 37V3C0 1.35 1.35 0 3 0H37C38.65 0 40 1.35 40 3V37C40 38.65 38.65 40 37 40ZM3 2C2.45 2 2 2.45 2 3V37C2 37.55 2.45 38 3 38H37C37.55 38 38 37.55 38 37V3C38 2.45 37.55 2 37 2H3Z"
        fill={fill}
      />
    </svg>
  )
}
