import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getDcEnvVariables = () => {
  const dcEnvVariables: AppConfigOverride = {
    shopperSlug: 'dc',
    shopperName: 'DAVID CLULOW',
    storeID: '8087',
    storeLang: 'en-GB',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    vmStore: 'dc',
    vmStyle: 'dc',
  }

  return dcEnvVariables
}

export default getDcEnvVariables
