import { NuanceTab } from '@abstractTypes/nuance'
import { removeNonAlphabetical } from '@libs/arabic'
import { pushToDataTrack } from '@providers/analyticsProvider'

export const usePushToDataTrack = () => (selectedTab: NuanceTab | null | undefined) => {
  const tabNameOrLabel = selectedTab?.name || selectedTab?.label || ''
  const elementIdFormatted = `MoreInfo_${removeNonAlphabetical(tabNameOrLabel)}`

  pushToDataTrack({
    id: 'Click',
    data_element_id: elementIdFormatted,
  })
}
