import { Device } from '@abstractTypes/utils'
import { Modal } from '@components/core/Modal'
import { altNavigation, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'
import { ModalBody, ModalBodyWrapper, ModalContent, ModalHeader, ModalWrapper } from './styles'

export const TextModalStyled = styled(Modal)`
  padding-block-end: 0;

  ${ModalContent} {
    block-size: ${pxToRem(1408)};
    ${altNavigation(`
      block-size: ${pxToRem(688)}
    `)}
    ${landscape(`    
      block-size: ${pxToRem(936)};
    `)}
    ${tablet(`    
      block-size: ${clampValues(950, 1238)};
    `)}
    ${landscape(
      Device.tablet,
      `
      block-size: ${clampValuesLandscape(708, 896)};    
    `
    )};
  }

  ${ModalBody} {
    padding-block: ${pxToRem(28)};
    ${landscape(`    
      padding-block: ${pxToRem(36)};
    `)}
    ${tablet(`    
      padding-block: ${clampValues(24, 32)};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-block: ${clampValuesLandscape(28, 32)};
    `
    )}
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding-block: ${pxToRem(28)};
    `
    )}
  }

  ${ModalWrapper} {
    padding-block: 0;
    padding-inline: ${pxToRem(33)};
    ${tablet(`    
      padding-inline: ${clampValues(3, 11)};
    `)}
    ${zoomPortrait(
      [150, 175],
      `
      padding-inline:  ${pxToRem(17)};
    `
    )}
    ${zoomPortrait(
      [200],
      `
      padding-inline:  ${pxToRem(9)};
    `
    )}
  }

  ${ModalBodyWrapper} {
    padding-block-end: ${pxToRem(30)};
    overflow: scroll;
  }

  ${ModalHeader} {
    padding-block: 0 ${pxToRem(27)};
    padding-inline: ${pxToRem(33)};
    ${tablet(`    
      padding-block: 0 ${pxToRem(27)};
      padding-inline:  ${clampValues(3, 11)} ;
    `)}
    ${zoomPortrait(
      [150, 175],
      `
      padding-block: 0 ${pxToRem(27)};
      padding-inline:  ${pxToRem(17)} ;
    `
    )}
    ${zoomPortrait(
      [200],
      `
      padding-block: 0 ${pxToRem(27)};
      padding-inline:  ${pxToRem(9)} ;
    `
    )}
  }
`
