import { BestMatchTipIcon } from '@components/core/Icons'
import { Text } from '@components/core/Typography'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import { TipCardTitle, TipImageWrapper } from '../styles'

const translationKey = 'FrameAdvisor.tips.bestMatchTip'

const iconWidth = isIPadView() ? '100%' : pxToRem(325)
const iconHeight = isIPadView() ? clampValues(36, 48) : pxToRem(48)

export const BestMatchTipContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <TipCardTitle>{t(`${translationKey}.title`)}</TipCardTitle>
      <TipImageWrapper>
        <BestMatchTipIcon width={iconWidth} height={iconHeight} />
      </TipImageWrapper>
      <Text weight="bold">{t(`${translationKey}.text`)}</Text>
    </>
  )
}
