import { Device } from '@abstractTypes/utils'
import { ButtonTypography, Label } from '@components/core/Typography'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const SuggestionButton = styled(ButtonTypography)`
  margin-inline-end: ${pxToRem(43)};
  padding-block: ${pxToRem(8)};
  padding-inline: ${pxToRem(24)};
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: ${pxToRem(100)};
  min-inline-size: ${pxToRem(112)};
  ${tablet(
    `
    min-inline-size: ${clampValues(84, 112)};
    padding-block: ${clampValues(6, 8)};
    padding-inline:  ${clampValues(18, 24)};
    margin-inline-end: ${clampValues(32, 42)};
  `
  )}
`

export const SuggestionsContainer = styled.div`
  margin-block-start: ${pxToRem(32)};
  display: flex;
  justify-content: center;
  ${landscape(`
      margin-block-start: ${pxToRem(40)};
  `)}

  ${landscape(
    Device.tablet,
    `
        margin-block-start: ${clampValuesLandscape(34, 38)};
    `
  )}

  ${Label} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;
    overflow: hidden;
  }
`
