import { addLoaderId, deleteLoaderId } from '@actions/ui'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from './useActions'

// This is meant to be used in those scenario when we have
// totally block the user interaction on the whole page
export const useFullscreenLoader = (id: string, deps: boolean[]) => {
  const listLoaderIds = useSelector(s => s.ui.listLoaderIds)
  const actions = useActions({ addLoaderId, deleteLoaderId })

  useEffect(() => {
    const hasAtLeastOneLoadingDep = Boolean(deps.find(Boolean))

    if (hasAtLeastOneLoadingDep) {
      if (!listLoaderIds.includes(id)) {
        actions.addLoaderId(id)
      }
    } else {
      if (listLoaderIds.length && listLoaderIds.includes(id)) {
        actions.deleteLoaderId(id)
      }
    }
  }, [actions, deps, id, listLoaderIds])
}
