import { IconProps } from '@abstractTypes/icons'

export const CaretDownBoldIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.39047 0.835786C0.609425 1.61683 0.609425 2.88316 1.39047 3.66421L15.3905 17.6642C16.1715 18.4453 17.4379 18.4453 18.2189 17.6642L32.2189 3.66421C33 2.88317 33 1.61684 32.2189 0.835788C31.4379 0.0547392 30.1715 0.0547391 29.3905 0.835788L16.8047 13.4216L4.2189 0.835787C3.43785 0.054738 2.17152 0.0547379 1.39047 0.835786Z"
        fill="#333333"
      />
    </svg>
  )
}
