import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const pvTheme: ThemeOverride = {
  colors: {
    primaryDark: '#51534A',
    primaryDarkPressed: '#31322C',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#FFFFFF',
    inactive: '#A8A7A5',
    outline: '#CCCAC6',
    background: '#F2F1ED',
    highlight01: '#5D9732',
    highlight01Pressed: '#51802D',
    highlight02: '#5D9732',
    highlight02Pressed: '#51802D',
    highlight03: '#51534A',
    highlight04: '#51802D',
    functional01: '#D32113',
    functional02: '#558A2D',
    functional03: '#FFB750',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 600,
      },
      fontSize: {
        tower: 100,
        tablet: 75,
        tabletMini: 57,
      },
      lineHeight: {
        tower: 115,
        tablet: 80,
        tabletMini: 72,
      },
    },
    heading1: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 600,
      },
      fontSize: {
        tower: 64,
        tablet: 56,
        tabletMini: 48,
      },
      lineHeight: {
        tower: 68,
        tablet: 62,
        tabletMini: 60,
      },
    },
    heading2: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 600,
      },
      fontSize: {
        tower: 48,
        tablet: 40,
        tabletMini: 34,
      },
      lineHeight: {
        tower: 48,
        tablet: 48,
        tabletMini: 46,
      },
    },
    heading3: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 300,
      },
      fontSize: {
        tower: 36,
        tablet: 34,
        tabletMini: 28,
      },
      lineHeight: {
        tower: 48,
        tablet: 44,
        tabletMini: 38,
      },
    },
    heading4: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 300,
        bold: 600,
      },
      fontSize: {
        tower: 26,
        tablet: 26,
        tabletMini: 22,
      },
      lineHeight: {
        tower: 36,
        tablet: 32,
        tabletMini: 30,
      },
    },
    heading5: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 300,
        bold: 600,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 26,
        tablet: 30,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 300,
        bold: 600,
      },
      fontSize: {
        tower: 17,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 27,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 14,
      },
      lineHeight: {
        tower: 24,
        tablet: 20,
        tabletMini: 20,
      },
    },
    cta: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 400,
        bold: 600,
      },
      fontSize: {
        tower: 16,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 26,
        tabletMini: 26,
      },
    },
    link: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 26,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 36,
        tablet: 32,
        tabletMini: 30,
      },
    },
    caption: {
      fontFamily: 'MuseoSans',
      weight: {
        regular: 600,
      },
      fontSize: {
        tower: 13,
        tablet: 13,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 16,
        tablet: 16,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'squared',
      uppercase: true,
    },
    rangeSlider: {
      background: '#A8A7A5', //inactive
      foreground: '#5D9732', //highlight01
      thumb: '#F2F1ED', //background
      thumbBorder: '#A8A7A5', //inactive
    },
  },
}
