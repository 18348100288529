import { CartState } from '@abstractTypes/cart'
import {
  ADD_AVAILABILITY,
  ADD_ITEM,
  CartActions,
  CLEAR_CART_ITEM_OVERLAY,
  REMOVE_ITEM,
  SET_ITEM_QUANTITY,
  UPDATE_AVAILABILITY,
  UPDATE_ITEM,
} from '@actions/cart'

const initialState: CartState = {
  items: [],
  availability: undefined,
  updatingAvailability: false,
  cartItemOverlayId: '',
}

export const cartReducer = (state = initialState, action: CartActions): CartState => {
  switch (action.type) {
    case ADD_ITEM: {
      const { payload } = action

      const newState: CartState = {
        items: state.items.map(el => ({
          id: el.id,
          product: { ...el.product },
          quantity: el.quantity,
          storeId: el.storeId,
          session: el.session,
        })),
        availability: state.availability || [],
        updatingAvailability: state.updatingAvailability ?? false,
        cartItemOverlayId: payload.id,
      }

      const item = newState.items.findIndex(v => v.id === payload.id)

      if (item === -1) {
        newState.items.push(payload)
      } else {
        const currentItem = newState.items[item].product
        const payloadProduct = payload.product

        if (currentItem.completePair === payloadProduct.completePair) {
          newState.items[item].quantity += 1
        } else {
          newState.items.push({ ...payload, id: `${payload.id}-${newState.items.length + 1}` })
        }
      }
      return newState
    }

    case REMOVE_ITEM: {
      const { payload } = action

      const index = state.items.map(e => e.id).indexOf(payload.id)
      const isTheOnlyUpc =
        state.items.filter(cartItem => cartItem.product.UPC === state.items[index]?.product.UPC)
          .length === 1
      const deletedItem = state.items.splice(index, 1)

      let updatedAvailability = [...(state.availability || [])]
      if (isTheOnlyUpc) {
        updatedAvailability = [...(state.availability || [])].filter(
          i => i.upc !== deletedItem[0].product.UPC
        )
      }
      if (payload.openMinicart) {
        return {
          ...state,
          items: [...state.items],
          availability: updatedAvailability,
        }
      } else {
        return {
          ...state,
          items: [...state.items],
          availability: updatedAvailability,
        }
      }
    }
    case SET_ITEM_QUANTITY: {
      const { payload } = action

      const index = state.items.map(e => e.id).indexOf(payload.id)
      state.items[index].quantity = payload.quantity
      return {
        ...state,
        items: [...state.items],
      }
    }

    case UPDATE_ITEM: {
      const { payload } = action
      const index = state.items.findIndex(item => item.id === payload.id)

      if (index === -1) {
        return state
      }

      state.items[index].product = payload.product
      return {
        ...state,
        items: [...state.items],
      }
    }
    case ADD_AVAILABILITY: {
      const { payload } = action
      const availabilityWithoutOldData = [...(state.availability || [])].filter(
        item =>
          !payload.find(
            i => i.upc === item.upc && i.prescriptionApplied === item.prescriptionApplied
          )
      )
      return {
        ...state,
        availability: [...availabilityWithoutOldData, ...payload],
      }
    }

    case CLEAR_CART_ITEM_OVERLAY: {
      return {
        ...state,
        cartItemOverlayId: '',
      }
    }

    case UPDATE_AVAILABILITY: {
      const { payload } = action
      return {
        ...state,
        updatingAvailability: payload,
      }
    }
    default:
      return state
  }
}

export default cartReducer
