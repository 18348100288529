import { ProductSubdivisions } from '@abstractTypes/filter'
import BarCodeScanModal from '@components/BarCodeScanModal'
import ConditionalRender from '@components/ConditionalRender'
import ErrorWidget from '@components/ErrorWidget'
import Loading from '@components/Loading'
import { useSearchData } from '@data'
import { useSendSearchAnalyticsEvent } from '@hooks/analytics/useSearchAnalyticsData'
import useFormatSearchProductsQueryPayload from '@hooks/formatPayloadForSearchApi/useFormatSearchProductsQueryPayload'
import { useGetSubdivisionsSearchTabsOrder } from '@hooks/useGetInitialProductSubdivisionTab'
import { useNavigationFlowHandler } from '@hooks/useNavigationFlowHandler'
import { usePreviousNonNullish } from '@hooks/usePreviousNonNullish'
import { parseGrapqhQLError } from '@libs/errors'
import { usePageChecker } from '@libs/utils'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SearchProductsContent } from './SearchProductsContent'
import { SearchProductsProps, SearchProductsWrapperProps } from './types'

const SearchProducts: React.FC<SearchProductsProps> = ({
  plpFacets,
  query,
  items,
  selectedTab,
  onTabSelect,
  fromSuggestions,
  previousQuery,
}) => {
  const { barcodeFlow } = useNavigationFlowHandler()
  const orderType = useSelector(s => s.customerOrder.orderType)
  const { isHomePage } = usePageChecker()
  const { tabNames, initialTab } = useGetSubdivisionsSearchTabsOrder(plpFacets)

  // we had to use selectedTab in the shouldSendEvent because the first call to the serach api is only supposed
  // to retrieve the tabs, the second api call is for the actual product data. We don't want the analytics to
  // get triggered for the first call.
  const shouldSendEvent = Boolean(selectedTab && items.length)
  useSendSearchAnalyticsEvent(fromSuggestions, query, items, shouldSendEvent, previousQuery)

  useEffect(() => {
    if (!selectedTab) {
      onTabSelect(initialTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SearchProductsContent
        items={items}
        query={query}
        orderType={orderType}
        tabNames={tabNames}
        onTabSelect={onTabSelect}
        selectedTab={selectedTab}
      />
      <ConditionalRender
        condition={isHomePage && barcodeFlow}
        render={() => <BarCodeScanModal />}
      />
    </>
  )
}

const SearchProductsWrapper: React.FC<SearchProductsWrapperProps> = ({
  query,
  fromSuggestions,
  previousQuery,
}) => {
  const [selectedTab, setSelectedTab] = useState<ProductSubdivisions | undefined>()

  useEffect(() => {
    if (query !== previousQuery) {
      setSelectedTab(undefined)
    }
  }, [query, previousQuery])

  const searchReqData = useFormatSearchProductsQueryPayload(query, selectedTab)

  const {
    data: searchData,
    loading: searchLoading,
    error: searchError,
  } = useSearchData(searchReqData)

  const prevData = usePreviousNonNullish(searchData)

  if (searchError) {
    return <ErrorWidget {...parseGrapqhQLError(searchError)} withWrapper />
  }

  if (searchLoading) {
    return <Loading />
  }

  const existingSearchData = !isEmpty(searchData) && searchData ? searchData : prevData

  if (!existingSearchData) {
    return <Loading /> // TODO: is it right to return loading if we don't have existingSearchData??
  }

  const filtersFromProducts = existingSearchData?.products.filters ?? []
  return (
    <SearchProducts
      query={query}
      plpFacets={filtersFromProducts}
      items={existingSearchData.products.items}
      onTabSelect={setSelectedTab}
      selectedTab={selectedTab}
      fromSuggestions={fromSuggestions}
      previousQuery={previousQuery}
    />
  )
}

export default SearchProductsWrapper
