import { IconProps } from '@abstractTypes/icons'

export const ZoomIcon: React.FC<IconProps> = ({
  width = '2rem',
  height = '2rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_42867_52819)">
        <path
          d="M28.667 5.33398V9.33398H22.0003V25.334H18.0003V9.33398H11.3337V5.33398H28.667ZM3.33366 12.0007H15.3337V16.0007H11.3337V25.334H7.33366V16.0007H3.33366V12.0007Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_42867_52819">
          <rect width={width} height={height} fill="white" transform="matrix(-1 0 0 1 32 0)" />
        </clipPath>
      </defs>
    </svg>
  )
}
