import { ThumbUpIcon } from '@components/core/Icons'
import { Heading5 } from '@components/core/Typography'
import { useThemeContext } from '@hooks/useThemeContext'
import { tablet } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TipCardTitle } from '../styles'

const LikeTipText = styled(Heading5)`
  inline-size: ${pxToRem(283)};
  max-inline-size: 100%;
`

const LikeIconContainer = styled.div`
  margin-block-end: ${pxToRem(16)};
  inline-size: ${pxToRem(84)};
  block-size: ${pxToRem(84)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  ${tablet(`
    inline-size: ${clampValues(63, 84)};
    block-size: ${clampValues(63, 84)};
  `)}
`
const translationKey = 'FrameAdvisor.tips.likeTip'

const iconWidth = isIPadView() ? clampValues(25, 34) : pxToRem(34)
const iconHeight = isIPadView() ? clampValues(23, 31) : pxToRem(31)

export const LikeTipContent: React.FC = () => {
  const { t } = useTranslation()
  const theme = useThemeContext()

  return (
    <>
      <TipCardTitle>{t(`${translationKey}.title`)}</TipCardTitle>
      <LikeIconContainer>
        <ThumbUpIcon
          stroke={theme.colors.primaryLight}
          fill={theme.colors.primaryLight}
          width={iconWidth}
          height={iconHeight}
        />
      </LikeIconContainer>
      <LikeTipText>{t(`${translationKey}.text`)}</LikeTipText>
    </>
  )
}
