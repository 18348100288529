import { VKContextInterface } from '@abstractTypes/keyboard'
import { VKContext } from '@providers/VKeyboardProvider'
import { useContext } from 'react'

export const useVKContext = (): VKContextInterface => {
  const context = useContext(VKContext)
  if (!context) {
    throw new Error('useVKContext must be used within a VKeyboardProvider')
  }
  return context
}
