import { ContentV2Video } from '@abstractTypes/graphqlTypes'
import { CloseIcon, LeftIcon, RightIcon } from '@components/core/Icons'
import { CloseButton } from '@components/ElectronicsComponents'
import { NukaCarousel } from '@components/NukaCarousel'
import { NuanceNextBtn, NuancePrevBtn } from '@components/Slider/styles'
import { useDeviceType } from '@hooks/useDeviceType'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import { useThemeContext } from '@hooks/useThemeContext'
import { pxToRem } from '@libs/styled'
import React, { useRef, useState } from 'react'
import { ModalStyled } from './styles'
import VideoModalSlide from './VideoModalSlide'

interface VideoModalProps {
  onClose: () => void
  visible: boolean
  className?: string
  videos: ContentV2Video[]
  animation?: boolean
  showPlayAnimation?: boolean
  setShowPlayAnimation: (value: boolean) => void
}

export const VideoModal: React.FC<VideoModalProps> = ({
  visible,
  showPlayAnimation = true,
  setShowPlayAnimation,
  onClose,
  videos,
  animation,
}) => {
  const rtlDirection = useLanguageDirection()
  const { isIpad } = useDeviceType()
  const [slideIndex, setSlideIndex] = useState(rtlDirection ? videos.length - 1 : 0)
  const carouselRef = useRef<HTMLDivElement | null>(null)
  const { zoomLevel } = useNavigationContext()
  const theme = useThemeContext()

  const pauseAllVideos = () => {
    if (carouselRef?.current) {
      const videos = carouselRef.current.querySelectorAll(
        '.slide video'
      ) as NodeListOf<HTMLVideoElement>
      videos.forEach(video => {
        video.muted = true
        video.pause()
      })
    }
  }

  return (
    <ModalStyled
      visible={visible}
      showCloseButton={false}
      onCancel={onClose}
      animation={animation}
      showHeader={false}
    >
      <CloseButton onClick={onClose}>
        <CloseIcon height={pxToRem(38, zoomLevel / 100)} width={pxToRem(38, zoomLevel / 100)} />
      </CloseButton>
      <NukaCarousel
        innerRef={carouselRef}
        beforeSlide={(_, targetIndex) => {
          setSlideIndex(targetIndex)
          setShowPlayAnimation(false)
          pauseAllVideos()
        }}
        slideIndex={slideIndex}
        withoutControls={false}
        loop
        renderBottomCenterControls={null}
        renderCenterLeftControls={({ previousSlide }) => (
          <NuancePrevBtn onClick={previousSlide}>
            <LeftIcon
              stroke={theme.colors.primaryLight}
              fill={theme.colors.primaryLight}
              width={pxToRem(43)}
              height={pxToRem(37)}
            />
          </NuancePrevBtn>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <NuanceNextBtn onClick={nextSlide}>
            <RightIcon
              stroke={theme.colors.primaryLight}
              fill={theme.colors.primaryLight}
              width={pxToRem(43)}
              height={pxToRem(37)}
            />
          </NuanceNextBtn>
        )}
      >
        {videos.map((video, index) => {
          return (
            <VideoModalSlide
              key={index}
              video={video}
              autoplay={!isIpad}
              index={rtlDirection ? videos.length - 1 - index : index}
              slideIndex={slideIndex}
              showPlayAnimation={showPlayAnimation}
            />
          )
        })}
      </NukaCarousel>
    </ModalStyled>
  )
}
