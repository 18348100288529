import { ListContext } from '@providers/listProvider'
import { useContext } from 'react'

export const useListContext = () => {
  const context = useContext(ListContext)
  if (!context) {
    throw new Error('useListContext must be used within a ListContextProvider')
  }
  return context
}
