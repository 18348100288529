import { StandardProduct } from '@abstractTypes/product'
import config from '@config/index'
import { getProductMainImage } from '@libs/image'
import { importScript } from '@libs/importScript'
import { formatProductCode } from '@libs/utils'
import type { Product, ProductPrice, Store, VMWidgetApp } from 'vmmv'

declare global {
  interface Window {
    vmmv: typeof import('vmmv')
    widgetApp?: VMWidgetApp
  }
}

export const importVMMVScript = async () => {
  await importScript('vmmv', config.vmJS)
  const vmmv = window.vmmv || exports?.vmmv
  return vmmv
}

const getProductName = (model: string, colorCode: string): string =>
  `${formatProductCode(model)} ${colorCode}`.trim()

export const formatProductToVMProduct = (
  prescriptionEnabled: boolean,
  product: StandardProduct
): Product => {
  return {
    brand: {
      name: product.brand.name,
      logoUrl: product.brand.logo.src,
    },
    code: product.model,
    colorCode: product.colorCode || '',
    name: getProductName(product.model, product.colorCode),
    thumbnailUrl: getProductMainImage(product.thumbnails, 300, false),
    upc: product.UPC,
    frameColorLabel: product.frameColorLabel || product.frameColor || '',
    lensColorLabel: product.lensColorLabel || product.lensColor || '',
    size: `${product.size}` || '',
    styleName: product.name,
    isCustom: product.customizable || false,
    category: product.productType || undefined,
    isTransition: product.transitionLens?.isEvolve
      ? true
      : prescriptionEnabled
      ? product.isTransition
      : false,
    transitionLens: product.transitionLens || undefined,
  }
}

export const formatProductToVMProductPrice = (
  product: StandardProduct
): ProductPrice | undefined => {
  const {
    UPC: upc,
    price: { currency, current, previous },
  } = product

  if (!currency) {
    return
  }

  return {
    upc,
    currency,
    current,
    previous: previous ? previous : undefined,
  }
}

export interface VirtualMirrorProducts {
  products: Product[]
  prices: ProductPrice[]
}

export const formatVMProductsWithPrices = (
  prescriptionEnabled: boolean,
  products: StandardProduct[]
): VirtualMirrorProducts => {
  return products.reduce<VirtualMirrorProducts>(
    (productsWithPrices, product) => {
      const vmProduct = formatProductToVMProduct(prescriptionEnabled, product)
      const vmProductPrice = formatProductToVMProductPrice(product)

      productsWithPrices.products.push(vmProduct)
      vmProductPrice && productsWithPrices.prices.push(vmProductPrice)

      return productsWithPrices
    },
    { products: [], prices: [] }
  )
}

export const VMStoreMap: Record<string, Store['type']> = {
  sgh: 'sunglasshut',
  opsm: 'opsm',
  to: 'to',
  salmoiraghi: 'sv',
  rb: 'rb',
  oo: 'oo',
  lc: 'lc',
}

export const getVmStore = (): Store['type'] => {
  return VMStoreMap[config.shopperSlug] || 'sunglasshut'
}

export const unmountVMWidgetApp = async () => {
  if (window.widgetApp) {
    await (window.widgetApp.unmount() as unknown as Promise<boolean>)
    window.widgetApp = undefined
  }
}
