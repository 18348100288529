import { ProductHierarchies, ProductTypeValues } from '@abstractTypes/filter'
import { Switcher, SwitcherWithLabel } from '@components/core/Switcher'
import { useAvailableInStoreProductSubdivision } from '@hooks/useAvailableInStore'
import { useTranslation } from 'react-i18next'

export type AvailableInStoreSwitchProps = React.ComponentProps<typeof Switcher> & {
  productSubdivision: ProductTypeValues | ProductHierarchies | undefined
  dataAttr?: {
    test: string
  }
  fromSearchPage?: boolean
}

const AvailableInStoreSwitch = (props: AvailableInStoreSwitchProps) => {
  const { t } = useTranslation()
  const {
    productSubdivision,
    label = t('PlpFilters.storeAvailable'),
    fromSearchPage,
    ...rest
  } = props
  const { availableInStore, toggleAvailableInStore } = useAvailableInStoreProductSubdivision(
    productSubdivision,
    fromSearchPage
  )

  return (
    <SwitcherWithLabel
      checked={availableInStore}
      onChange={toggleAvailableInStore}
      label={label}
      aria-label={label}
      aria-description={label}
      {...rest}
    />
  )
}

export default AvailableInStoreSwitch
