import { Device } from '@abstractTypes/utils'
import { CardProductContainerStyled, PreviewContainer } from '@components/CardProductBase'
import { ProductCardBase } from '@components/CardProductBase/ProductCard'
import { ProductDescription as CheckOutProductDescription } from '@components/CardProductBase/ProductCard/ProductDescription'
import { CartImage } from '@components/Cart'
import { useProductInfoConfigCheckout } from '@hooks/tiles'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { dataDescriptionForProduct, isSquaredProductTile } from '@libs/utils'
import React from 'react'
import styled from 'styled-components'
import { CheckoutProductCardProps } from './types'

const CheckoutProductCardWrapper = styled.div`
  margin-block-end: ${pxToRem(32)};
  ${landscape(
    Device.tablet,
    `
      margin-block-end: ${pxToRem(18)};
    `
  )}
  ${CardProductContainerStyled} {
    max-inline-size: 100%;
  }

  ${PreviewContainer} {
    ${tablet(
      `
    padding-block-start: ${isSquaredProductTile() ? '100%' : clampValues(200, 192)};

  `
    )}
    ${landscape(
      Device.tablet,
      `
  padding-block-start: ${isSquaredProductTile() ? '100%' : clampValuesLandscape(144, 192)};
  `
    )}
  }
`

const CartImageCheckout = styled(CartImage)`
  border-inline-end: none;
  ${landscape(`
    border-inline-end: none;
  `)}
`

const CheckoutProductCard: React.FC<CheckoutProductCardProps> = ({
  className,
  dataAttrs,
  cartItem,
  showRTRIcon,
  showVTOIcon,
  isCondensed,
  engravingItems,
}) => {
  const { product } = cartItem
  const analyticsDataDescription = dataDescriptionForProduct(product)
  const getProductInfoConfig = useProductInfoConfigCheckout()
  const productInfoConfig = getProductInfoConfig(product.brand.id)

  const { zoomLevel } = useNavigationContext()

  return (
    <CheckoutProductCardWrapper>
      <ProductCardBase
        className={className}
        product={product}
        dataAttrs={{ description: analyticsDataDescription, ...dataAttrs }}
        showCustomizableIcon
        showRTRIcon={showRTRIcon}
        showVTOIcon={showVTOIcon}
        showIcons
        size={zoomLevel >= 150 ? 'massive' : 'standard'}
        asLink={false}
        customImage={<CartImageCheckout product={product} />}
        productInfoConfig={productInfoConfig}
      />
      <CheckOutProductDescription
        product={product}
        isCondensed={isCondensed}
        engraving={engravingItems[cartItem.id]}
      />
    </CheckoutProductCardWrapper>
  )
}

export default CheckoutProductCard
