import { IconProps } from '@abstractTypes/icons'
export const WithPrescriptionIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M38.6078 13.717C38.8078 13.104 38.9968 12.487 39.1618 11.863C41.0328 3.82204 31.0258 0.665039 19.9588 0.665039C17.2138 0.665039 14.4058 0.859039 11.6958 1.22104C-2.6442 3.41504 -3.1112 26.426 6.5578 34.128C7.3948 34.788 8.2898 35.296 9.2248 35.674C10.1678 39.909 12.1828 43.749 15.1698 46.128C17.2378 47.759 19.6408 48.5 22.1918 48.5C32.0828 48.5 44.2078 37.37 47.7728 23.863C49.0388 18.423 44.8658 15.221 38.6078 13.717ZM7.8038 32.564C2.9758 28.719 0.733801 19.929 2.6988 12.554C3.5218 9.46604 5.8238 4.14304 11.9608 3.20404C14.6358 2.84704 17.3268 2.66604 19.9598 2.66604C28.0068 2.66604 34.1598 4.36404 36.4178 7.20904C37.3358 8.36504 37.5988 9.73104 37.2208 11.383C37.0498 12.029 36.8508 12.671 36.6358 13.309C34.1348 12.872 31.3878 12.666 28.5718 12.666C25.8268 12.666 23.0188 12.86 20.3088 13.222C11.2308 14.61 7.7188 24.337 8.8098 33.244C8.4638 33.039 8.1258 32.817 7.8038 32.564ZM8.9878 34.463C8.9898 34.472 8.9918 34.482 8.9928 34.491C8.9908 34.482 8.9888 34.472 8.9878 34.463ZM45.8318 23.383C42.6238 35.486 31.3638 46.5 22.1918 46.5C19.9878 46.5 18.0418 45.846 16.4148 44.564C11.5868 40.719 9.3448 31.929 11.3098 24.554C12.1328 21.466 14.4348 16.143 20.5708 15.204C23.2458 14.847 25.9368 14.666 28.5698 14.666C36.6168 14.666 42.7698 16.364 45.0278 19.209C45.9468 20.365 46.2098 21.73 45.8318 23.383Z"
        fill={fill}
      />
    </svg>
  )
}
