import { AnalitycsEventFunction, AnalyticsEventPayload, ErrorData } from '@abstractTypes/analytics'
import { CartItem as CartItemType } from '@abstractTypes/cart'
import { ProductTypeValues } from '@abstractTypes/filter'
import { Store } from '@abstractTypes/graphqlTypes'
import { Product } from '@abstractTypes/product'
import { saveAnalyticsTealiumSended } from '@actions/core'
import { EVENTS_ID, formatCartItems, formatProducts } from '@libs/analytics'
import { executeOnce, useRefValue } from '@libs/utils'
import { trackAnalyticsError } from '@providers/analyticsProvider'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAnalyticsContext } from './useAnalyticsContext'

const defaultDeps: unknown[] = []

export const pageSection2 = (productType: string | undefined): string | undefined => {
  switch (productType) {
    case ProductTypeValues.OPTICAL:
      return 'Eyeglasses'
    case ProductTypeValues.SUN:
      return 'Sunglasses'
    case ProductTypeValues.SNOWGOGGLES:
      return 'GOGGLES'
    default:
      return productType
  }
}

/** Provide Common Data in sendEvent Callback Payload
 * @example
 * const sendTealiumNewSession = useSendAnalyticsEvent({ id: 'Start-Session' })
 * sendTealiumNewSession()
 */
export const useSendAnalyticsEvent = <T extends unknown[]>(
  payload: AnalyticsEventPayload | AnalitycsEventFunction<T>,
  addCommonData = false,
  isEventWithCatalogProducts = false
) => {
  const { sendAnalyticsEvent } = useAnalyticsContext()

  const payloadRef = useRefValue(payload)

  return useCallback(
    (...args: T) => {
      const currentPayload = payloadRef.current
      if (typeof currentPayload === 'function') {
        return sendAnalyticsEvent(
          currentPayload(...args),
          addCommonData,
          isEventWithCatalogProducts
        )
      }

      const addTrafficCidParam =
        history.state?.state?.fromOldSession && currentPayload.id === 'VirtualPage-View'
      const payload = addTrafficCidParam
        ? { ...currentPayload, Traffic_Cid: 'IT-XQO_SS' }
        : currentPayload
      sendAnalyticsEvent(payload, addCommonData, isEventWithCatalogProducts)
    },
    [payloadRef, sendAnalyticsEvent, addCommonData, isEventWithCatalogProducts]
  )
}

/** Provide Formatted Cart Items in Data Layer Effect
 * @example
 * useAnalyticsEvent({
 *   id: 'VirtualPage-View'
 * })
 */
export const useAnalyticsEvent = (
  payload: AnalyticsEventPayload,
  deps = defaultDeps,
  addCommonData = true
) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent(payload, addCommonData)

  useEffect(() => {
    sendAnalyticsEvent()
    // we can guarantee that all the dependency are satisfied as deps are additional dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalyticsEvent, ...deps])
}

/** Send Analytics error from callback */
export const useSendAnalyticsError = (error: ErrorData) => {
  const errorRef = useRefValue(error)

  return useCallback(() => {
    trackAnalyticsError(errorRef.current)
  }, [errorRef])
}

/** Send Analytics error as Effect */
export const useAnalyticsError = (error: ErrorData) => {
  const sendError = useSendAnalyticsError(error)
  useEffect(() => {
    sendError()
  }, [sendError])
}

/** Provide Formatted Products in sendEvent Callback Payload
 * @example
 * const sendRemoveItemsFromCart = useSendAnalyticsProductsEvent({
 *  id: 'Prods-Delete',
 * })
 * sendRemoveItemsFromCart(product)
 */
export const useSendAnalyticsProductsEvent = (
  payload: AnalyticsEventPayload,
  addCommonData = false
) => {
  const mySelection = useSelector(state => state.frameAdvisor.mySelection)
  return useSendAnalyticsEvent((products: Product | Product[]) => {
    const productList = Array.isArray(products) ? products : [products]
    return {
      Products: formatProducts(productList, mySelection),
      ...payload,
    }
  }, addCommonData)
}

/** Hook to send analytics addToCart event */
export const useSendAnalyticsAddToCartEvent = (
  payload: AnalyticsEventPayload,
  addCommonData = false
) => {
  const mySelection = useSelector(state => state.frameAdvisor.mySelection)
  const sendAddToCartEvent = useSendAnalyticsEvent((products: Product | Product[]) => {
    const productList = Array.isArray(products) ? products : [products]
    const prods = formatProducts(productList, mySelection)

    Object.keys(prods).forEach((key: string) => {
      delete prods[key]['Vm_IsUpcSupported']
      delete prods[key]['Rtr_IsUpcSupported']
      delete prods[key]['Conf_IsUpcSupported']
    })

    return {
      Products: prods,
      id: EVENTS_ID.event,
      Events_CartAdd: '1',
      ...payload,
    }
  }, addCommonData)

  const sendVMAddToCartEvent = useSendAnalyticsEvent(() => {
    return {
      id: 'Click',
      data_element_id: 'VirtualMirror_AddCart',
    }
  }, addCommonData)

  const sendVMAddToCartRxModalEvent = useSendAnalyticsEvent(() => {
    return {
      id: 'Click',
      data_element_id: 'VirtualMirror_AddCart_RXModal',
    }
  }, addCommonData)

  return {
    sendAddToCartEvent,
    sendVMAddToCartEvent,
    sendVMAddToCartRxModalEvent,
  }
}

/** Provide Formatted Cart Items in Data Layer Effect
 * @example
 * useAnalyticsCartEvent({
 *   id: 'VirtualPage-View'
 * }, items)
 */
export const useAnalyticsCartEvent = (
  payload: AnalyticsEventPayload,
  items: CartItemType[],
  deps = defaultDeps,
  addCommonData = false
) => {
  const mySelection = useSelector(s => s.frameAdvisor.mySelection)
  const engravingDetailsItems = useSelector(s => s.engraving.items)

  const sendAnalyticsEvent = useSendAnalyticsEvent(
    {
      Products: formatCartItems(items, mySelection, engravingDetailsItems),
      ...payload,
    },
    addCommonData
  )

  useEffect(() => {
    sendAnalyticsEvent()
    // we can guarantee that all the dependency are satisfied as deps are additional dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalyticsEvent, ...deps])
}

export const useAnalyticsNewSession = (store: Maybe<Store>) => {
  const dispatch = useDispatch()
  const hasSentTealiumNewSession = useSelector(s => s.session.hasSentTealiumNewSession)
  const sendTealiumNewSession = useSendAnalyticsEvent(
    {
      id: 'VirtualPage-View',
      Session_Status: 'Off',
      Events_SessionStart: '1',
    },
    true
  )

  useEffect(() => {
    if (store) {
      executeOnce(() => {
        if (!hasSentTealiumNewSession) {
          sendTealiumNewSession()
          dispatch(saveAnalyticsTealiumSended())
        }
      }, 'start-session-tealium')()
    }
    // we need to wait for store as events before store are dropped
  }, [store, sendTealiumNewSession, hasSentTealiumNewSession, dispatch])
}
