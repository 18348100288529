import { altNavigation, altNavigationZoom, landscape, tablet } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'
import {
  animationDuration,
  landscapeTabletSliderHeight,
  landscapeTabletSliderWidth,
  landscapeTowerSliderWidth,
  portraitTabletSliderHeight,
  portraitTabletSliderHeightReduced,
  portraitTowerSliderHeight,
  portraitTowerSliderHeightReduced,
} from '../styleConsts'

export const ElectronicsSlide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-inline-size: 100vw;
  overflow: hidden;
  block-size: 100%;

  /* removed white border between slides in landscape see => SS-29974 */
  inline-size: calc(100% + 1px);
  ${landscape(`
    min-inline-size: 100%;
    flex-shrink: 0;
  `)}
  ${altNavigation(`
    min-inline-size: auto;
  `)}

  img,
  video {
    z-index: -100;
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    inline-size: 100%;
    block-size: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
`

interface ElectronicsSliderContainerProps {
  isTabsExpanded: boolean
}

export const ElectronicsSliderContainer = styled.div<ElectronicsSliderContainerProps>`
  inline-size: 100%;
  transition: width ${animationDuration} linear;
  position: relative;
  overflow: hidden;
  z-index: 1;

  /* height needs for old versions */
  block-size: ${({ isTabsExpanded }) => {
    return isTabsExpanded ? portraitTowerSliderHeightReduced : portraitTowerSliderHeight
  }};
  ${({ isTabsExpanded }) =>
    tablet(
      `block-size: ${
        isTabsExpanded ? portraitTabletSliderHeightReduced : portraitTabletSliderHeight
      }`
    )}
  ${landscape(`
    block-size: 100%;
    inline-size: ${landscapeTowerSliderWidth};
    ${tablet(`
      inline-size: ${landscapeTabletSliderWidth};
      block-size: ${landscapeTabletSliderHeight};
      flex-grow: 0;
    `)}
  `)}
  ${altNavigation(`
    position: absolute;
    inline-size: ${pxToRem(529)};
    block-size: ${pxToRem(525)};
    inset-block-start: ${pxToRem(136)};
    inset-inline-start: 0;
  `)}
  ${altNavigationZoom(
    [100, 110, 125, 150, 175, 200],
    `
      inset-inline-start: ${pxToRem(276)};
  `
  )}
`

export const LoadingScreen = styled.div`
  position: absolute;
  inset-block-start: 0;
  inline-size: 100%;
  block-size: 100%;
  background-color: ${({ theme }) => theme.electronics.colors.secondary};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
