import { IconProps } from '@abstractTypes/icons'
export const FaFaceShapeSquareUngenderedIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 46"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M43.3129 15.987C43.1209 7.99504 36.1949 2.55004 29.8409 0.873042C23.8829 -0.699958 18.5629 0.756042 15.6849 4.67004C12.5179 3.02604 9.13595 3.09904 6.27795 4.90604C1.88595 7.68304 -0.442052 13.879 0.341948 20.629C0.357948 20.937 0.785949 28.193 4.70295 31.577C4.79795 31.659 4.90295 31.716 4.99995 31.792V35C4.99995 41.065 9.93495 46 15.9999 46H27.9999C34.0649 46 38.9999 41.065 38.9999 35V30.844C40.8479 29.171 43.5349 25.154 43.3129 15.987ZM27.9999 44H15.9999C11.0379 44 6.99995 39.962 6.99995 35V19.963C12.7459 19.542 16.9179 15.538 18.4049 11.809C20.4119 14.284 25.2759 17.869 36.9999 19.85V35C36.9999 39.962 32.9619 44 27.9999 44ZM38.1559 18.012C20.3769 15.205 18.9989 8.90104 18.9899 8.85804L16.9999 9.00004C16.9999 12.54 12.2759 18 5.99995 18L4.99995 19V28.951C2.65895 25.794 2.34195 20.573 2.33395 20.459C1.63295 14.406 3.60095 8.96404 7.34695 6.59604C9.82295 5.03204 12.7059 5.12004 15.4699 6.84804L16.8569 6.51504C19.5309 2.05904 25.0529 1.67704 29.3299 2.80704C34.9839 4.30004 41.1459 9.07704 41.3139 16.036C41.4619 22.199 40.2329 25.726 38.9999 27.704V19L38.1559 18.012Z"
        fill={fill}
      />
      <path d="M24.0129 36H19.9859V38H24.0129V36Z" fill={fill} />
      <path d="M22.4339 31H19.9859V33H24.4339V23.057H22.4339V31Z" fill={fill} />
    </svg>
  )
}
