import { IconProps } from '@abstractTypes/icons'
export const CategoryEyewearIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 48 48"
      stroke={stroke}
      className={className}
    >
      <path
        fill="#fff"
        d="M47.092 19.775c-.356-1.345-1.395-2.423-2.71-2.814-13.221-3.93-27.543-3.93-40.764 0-1.315.391-2.354 1.469-2.71 2.814C.306 22.047 0 24.394 0 26.75c0 .925.05 1.869.147 2.806.158 1.507 1.185 2.815 2.615 3.331 4.448 1.606 9.294 2.024 13.978 1.197 1.889-.334 3.407-1.663 3.962-3.467L22.739 24h2.523l2.036 6.617c.555 1.804 2.073 3.132 3.962 3.467 1.562.276 3.157.416 4.74.416 3.165 0 6.273-.542 9.238-1.613 1.43-.516 2.457-1.824 2.615-3.332.097-.936.147-1.88.147-2.805 0-2.356-.306-4.703-.908-6.975zm-26.265 3.637l-2.036 6.617c-.333 1.083-1.253 1.882-2.399 2.085-4.339.766-8.831.378-12.951-1.108-.713-.258-1.226-.909-1.304-1.659C2.046 28.479 2 27.605 2 26.75c0-2.183.283-4.357.842-6.462.179-.676.695-1.216 1.347-1.41 6.105-1.815 12.452-2.757 18.812-2.847V22h-.261c-.885 0-1.653.567-1.913 1.412zm25.036 5.935c-.079.75-.591 1.401-1.304 1.66-4.121 1.486-8.612 1.874-12.951 1.107-1.146-.203-2.066-1.002-2.399-2.085l-2.036-6.618c-.26-.844-1.028-1.411-1.912-1.411H25v-5.969c6.359.09 12.707 1.032 18.812 2.847.652.194 1.168.734 1.347 1.41.558 2.105.841 4.279.841 6.462 0 .855-.046 1.729-.137 2.597z"
      />
    </svg>
  )
}
