import { BarcodeScanModalBase } from '@components/BarCodeScanModal/Modals/BarcodeScanModalBase'
import { BarcodeScanModalProps } from '@components/BarCodeScanModal/types'
import { PrimaryButton } from '@components/core/Button'
import { getCustomizableBrand } from '@libs/custom'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSendUnavailablePRIZMAnalyticsEvent } from './analytics'

export const BarcodeScanModalUnavailablePRIZMMoco = ({
  visible,
  onCancel,
  productInfo,
}: BarcodeScanModalProps) => {
  const { t } = useTranslation()
  const { originalBrandCode, thumbnails, customizable } = productInfo
  const formattedBrand = getCustomizableBrand(originalBrandCode)

  const subtitle = t('BarCodeScanModal.StandardSameModelSubtitle')
  const title = t('BarCodeScanModal.FindOutPRIZMVersion')
  const { sendDiscoverEvent } = useSendUnavailablePRIZMAnalyticsEvent(visible)

  const handleOnCancel = useCallback(() => {
    sendDiscoverEvent()
    onCancel()
  }, [onCancel, sendDiscoverEvent])

  return (
    <BarcodeScanModalBase
      visible={visible}
      title={title}
      titleWeight="bold"
      subtitle={subtitle}
      description={''}
      customizable={customizable}
      formattedBrand={formattedBrand}
      thumbnails={thumbnails}
      onCancel={handleOnCancel}
      footerContent={[
        <PrimaryButton key="cancel" onClick={handleOnCancel}>
          {t('BarCodeScanModal.DiscoverPRIZM')}
        </PrimaryButton>,
      ]}
    />
  )
}
