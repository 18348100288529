import { Device } from '@abstractTypes/utils'
import {
  floatingButtonTabletLandscape,
  floatingButtonTabletPortrait,
} from '@components/FloatingActions/styles'
import { LabelStyled, Switcher, SwitcherWithLabel } from '@components/core/Switcher'
import { Heading4, Text } from '@components/core/Typography'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { DefaultTheme } from 'styled-components'
import { kebabCase } from 'lodash'

const MAXIMUM_NUMBER_OF_TABLET_MENU_ITEMS = 5

export type DeviceInfoType =
  | 'default'
  | 'tabletPortrait'
  | 'tabletLandscape'
  | 'towerLandscape'
  | 'zoom150'
  | 'zoom200'
  | 'zoomLandscape150'
  | 'zoomLandscape200'
export type DeviceInfoValue = {
  inlineSize: string
  blockSize: string
  insetBlockEnd: string
  insetBlockStart?: string
}

export type AccessibilityButtonInfoMapType = Record<DeviceInfoType, Partial<DeviceInfoValue>> &
  Record<'default', DeviceInfoValue>

export const accessibilityButtonInfoMap: AccessibilityButtonInfoMapType = {
  default: {
    inlineSize: pxToRem(64),
    blockSize: pxToRem(64),
    insetBlockEnd: pxToRem(756),
  },
  towerLandscape: {
    insetBlockEnd: pxToRem(240),
  },
  tabletPortrait: {
    insetBlockStart: `calc(${clampValues(
      184.5,
      246
    )} + ${MAXIMUM_NUMBER_OF_TABLET_MENU_ITEMS} * ${floatingButtonTabletPortrait} + ${pxToRem(
      16
    )})`,
    inlineSize: clampValues(40, 44),
    blockSize: clampValues(40, 44),
  },
  tabletLandscape: {
    insetBlockStart: `calc(${clampValuesLandscape(
      150,
      201
    )} + ${MAXIMUM_NUMBER_OF_TABLET_MENU_ITEMS} * ${floatingButtonTabletLandscape} + ${pxToRem(
      16
    )})`,
    blockSize: clampValues(40, 44),
  },
  zoom150: {
    inlineSize: pxToRem(78),
    blockSize: pxToRem(78),
  },
  zoom200: {
    inlineSize: pxToRem(104),
    blockSize: pxToRem(104),
  },
  zoomLandscape150: {
    insetBlockEnd: pxToRem(120),
  },
  zoomLandscape200: {
    insetBlockEnd: pxToRem(62),
  },
}

const getAccessibilityButtonInfo = (device: DeviceInfoType) =>
  Object.entries(accessibilityButtonInfoMap[device])
    .map(([key, value]) => `${kebabCase(key)}:${value}`)
    .join(';')

export const AccessibilityWrapper = styled.button`
  position: fixed;
  inset-inline-start: ${pxToRem(12)};
  z-index: 2;
  ${getAccessibilityButtonInfo('default')}

  ${tablet(`
    ${getAccessibilityButtonInfo('tabletPortrait')}
  `)}

  ${zoomPortrait(
    [150, 175],
    `
    ${getAccessibilityButtonInfo('zoom150')}
  `
  )};
  ${zoomPortrait(
    [200],
    `
    inset-inline-start: 0;
    ${getAccessibilityButtonInfo('zoom200')}
    `
  )};
  ${landscape(
    Device.tower,
    `
    ${getAccessibilityButtonInfo('towerLandscape')}
  `
  )};

  ${zoomLandscape(
    [150, 175],
    `
    ${getAccessibilityButtonInfo('zoomLandscape150')}
  `
  )};
  ${zoomLandscape(
    [200],
    `
    ${getAccessibilityButtonInfo('zoomLandscape200')}
  `
  )};
  ${landscape(
    Device.tablet,
    `
      ${getAccessibilityButtonInfo('tabletLandscape')}
    `
  )}
  ${altNavigation(`
    inline-size: ${pxToRem(44)};
    block-size: ${pxToRem(44)};
    inset-block-end: ${pxToRem(200)};
  `)};
  ${altNavigationZoom(
    [150, 175],
    `
    inline-size: ${pxToRem(66)};
    block-size: ${pxToRem(66)};
    inset-block-end: ${pxToRem(140)};
    inset-inline-start: ${pxToRem(5)};
  `
  )}
  ${altNavigationZoom(
    [200],
    `
    inline-size: ${pxToRem(88)};
    block-size: ${pxToRem(88)};
    inset-block-end: ${pxToRem(140)};
  `
  )}
`

export const AccessibilityIconContainer = styled.div`
  background-color: #fff; // not from theme as ada icon is black and white
  box-shadow: 0 0 2px #000; // not from theme as ada icon is black and white
  border-radius: 50%;
  block-size: 100%;
  inline-size: 100%;
`

const getGridItemStyles = (theme: DefaultTheme) => `
  &:nth-child(odd) {
    border-block-end: ${pxToRem(1)} solid ${theme.colors.inactive};
    border-inline-end: ${pxToRem(1)} solid ${theme.colors.inactive};
    padding-inline-start: ${pxToRem(0)};
    ${landscape(`
      border-block-end: none;
    `)}
  }
  &:nth-child(even) {
    border-block-end: ${pxToRem(1)} solid ${theme.colors.inactive};
    padding-inline-end: ${pxToRem(0)};
    ${landscape(`
      border-block-end: none;
    `)}
    
  }
  &:nth-child(1), &:nth-child(2) {
    padding-block-start: ${pxToRem(0)};
  };
  &:nth-child(3) {
    border-block-end: none;
    padding-block-end: ${pxToRem(0)};
}`

export const AccessibilityModalSection = styled.div`
  padding: ${pxToRem(24)};
  ${({ theme }) => getGridItemStyles(theme)}
  ${({ theme }) =>
    zoomPortrait(
      [150, 175, 200],
      `
    padding-vertical: ${pxToRem(32)};
    padding-inline: ${pxToRem(0)};
    border-inline-end: none;
    border-block-end: 1px solid ${theme.colors.inactive};
    &:nth-child(1), &:nth-child(2) {
      padding-block-start: ${pxToRem(32)};
    };
    &:last-child {
      border-block-end: none;
    }
  `
    )}
  ${({ theme }) =>
    zoomLandscape(
      [150, 175, 200],
      `
        ${getGridItemStyles(theme)}
        &:nth-child(1), &:nth-child(2) {
          border-block-end: none;
          padding-block-start: ${pxToRem(0)};
        };
        &:nth-child(odd) {
          padding-inline-end: ${pxToRem(22)};
        };
        &:nth-child(even) {
          padding-inline-start: ${pxToRem(22)};
        };
      `
    )}
  ${zoomLandscape(
    [200],
    `
      &:nth-child(odd) {
        padding-inline-end: ${pxToRem(38)};
      };
      &:nth-child(even) {
        padding-inline-start: ${pxToRem(38)};
      };
    `
  )}
`

export const SectionGrid = styled.div`
  margin-block-end: ${pxToRem(20)};
  display: flex;
  justify-content: space-between;
  ${zoomPortrait(
    [150, 175, 200],
    `
    display: inline;
  `
  )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    display: flex;
  `
  )}
`

export const SectionTitleRow = styled.div`
  margin-block-end: ${pxToRem(16)};
  display: flex;
  justify-content: flex-start;
  min-block-size: ${pxToRem(48)};
  ${zoomPortrait(
    [150, 175, 200],
    `
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: ${pxToRem(24)};
    `
  )}
  ${zoomLandscape(
    [150, 175],
    `
      justify-content: flex-start;
      flex-direction: row;
      margin-block-end: ${pxToRem(48)}};
    `
  )};
  ${zoomLandscape(
    [200],
    `
      justify-content: flex-start;
      flex-direction: row;
      margin-block-end: ${pxToRem(48)}};
    `
  )};
`

export const SectionTitleRowLabel = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const SectionIcon = styled.div`
  margin-inline-end: ${pxToRem(16)};
  display: flex;
  align-items: center;
`

export const SectionContent = styled.div`
  ${Text} {
    text-align: start;
  }
`

export const SectionSubTitleRow = styled.div`
  margin-block-end: ${pxToRem(9)};
  margin-block-start: ${pxToRem(9)};
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primaryDark};
`

export const AccessibilityGridHeading = styled(Heading4)`
  text-align: start;
`

export const AccessibilitySwitcher = styled(SwitcherWithLabel)<{ checked?: boolean }>`
  margin-block-start: 0;
  padding: 0;
  flex: 1;
  justify-content: end;
  align-items: baseline;
  ${({ checked }) =>
    zoomPortrait(
      [150, 175],
      `
    ${Switcher} {
      inset-inline-end:  ${checked ? pxToRem(1) : pxToRem(71)};
    }
  `
    )}
  ${({ checked }) =>
    zoomPortrait(
      [200],
      `
    ${Switcher} {
      inset-inline-end:  ${checked ? pxToRem(1) : pxToRem(93)};
    }
  `
    )}
  ${zoomPortrait(
    [150, 175],
    `
    ${LabelStyled} {
      inline-size: ${pxToRem(120)};
      block-size: ${pxToRem(52.5)};
    }
    ${Switcher} {
      inline-size: ${pxToRem(46.5)};
      block-size: ${pxToRem(46.5)};
    }
  `
  )}
  ${zoomPortrait(
    [200],
    `
    ${LabelStyled} {
      inline-size: ${pxToRem(158)};
      block-size: ${pxToRem(70)};
    }
    ${Switcher} {
      inset-block-start: 3px;
      inline-size: ${pxToRem(62)};
      block-size: ${pxToRem(62)};
    }
  `
  )}
`
