import { ProductForAnalytics } from '@abstractTypes/analytics'
import { StandardProduct } from '@abstractTypes/product'
import { useIsExactUPC } from '@hooks/analytics/useIsExactUPC'
import { useSendAnalyticsEvent } from '@hooks/analyticsHooks'
import { useNavigationFlowHandler } from '@hooks/useNavigationFlowHandler'
import { EVENTS_ID } from '@libs/analytics'
import { getUPCFromPDPUrl } from '@libs/url'
import { usePageChecker } from '@libs/utils'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

export enum SearchResultTypeView {
  serb = 'SERB', // Search Engine Results Box
  serp = 'SERP', // Search Engine Results Page
  noSerp = 'NO-SERP', // No Search Engine Results Page
}

const useSearchAnalyticsData = (
  fromSuggestions: boolean,
  query: string,
  items: StandardProduct[],
  previousQuery?: string
) => {
  const { barcodeFlow, customerOrderFlow } = useNavigationFlowHandler()
  const orderType = useSelector(s => s.customerOrder.orderType)
  const salesOrderId = useSelector(s => s.customerOrder.salesOrderId)

  const { isPDP } = usePageChecker()
  const exactUpc = useIsExactUPC()
  const redirectUPC = getUPCFromPDPUrl()

  const searchType = barcodeFlow ? 'barcode' : fromSuggestions ? 'suggested' : 'text'
  const barcodeNoResult = barcodeFlow && !exactUpc && isPDP
  const searchResultItemQnt = items.length ?? 0
  const suggestedSearch = searchType === 'suggested' ? query : ''
  const barcodeSearch = barcodeNoResult ? redirectUPC : query

  const analyticsData: { [key: string]: ProductForAnalytics | string | undefined } = useMemo(() => {
    return {
      id: EVENTS_ID.event,
      Events_SearchRun: '1',
      Search_Keyword: searchType === 'suggested' ? previousQuery : query,
      Search_KeyActual:
        searchType === 'barcode' ? (exactUpc ? barcodeSearch : redirectUPC) : suggestedSearch,
      Search_Type: searchType,
      Search_View: SearchResultTypeView.noSerp,
      ...(!barcodeFlow && {
        Search_ResultItemsQnt: searchResultItemQnt.toString(),
      }),
      ...(customerOrderFlow && {
        Tags_AdobeAnalytics_TrafficSourceCid_ThisHit: `LX-XCO_${orderType}_${salesOrderId}`,
      }),
    }
  }, [
    barcodeFlow,
    barcodeSearch,
    customerOrderFlow,
    exactUpc,
    orderType,
    previousQuery,
    query,
    redirectUPC,
    salesOrderId,
    searchResultItemQnt,
    searchType,
    suggestedSearch,
  ])

  return analyticsData
}

export const useSendSearchAnalyticsEvent = (
  fromSuggestions: boolean,
  query: string,
  items: StandardProduct[],
  shouldSendEvent: boolean,
  previousQuery?: string
) => {
  const analyticsData = useSearchAnalyticsData(fromSuggestions, query, items, previousQuery)

  const sendAnalyticsEvent = useSendAnalyticsEvent(analyticsData)

  useEffect(() => {
    if (shouldSendEvent) {
      sendAnalyticsEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
