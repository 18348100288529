import { Device } from '@abstractTypes/utils'
import { PrimaryButton } from '@components/core/Button/index'
import { ProgressBar } from '@components/ProgressBar'
import { ProgressContainer } from '@components/ProgressBar/styles'
import { landscape, tablet } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const SliderContainer = styled.div<{ isLensFilterOn?: boolean }>`
  inline-size: 100vw;
  block-size: 100vh;
  color: ${({ theme }) => theme.colors.primaryLight};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  user-select: none;

  ${ProgressContainer} {
    visibility: ${({ isLensFilterOn }) => (isLensFilterOn ? 'hidden' : 'visible')};
  }
`
export const PlayListSlide = styled.div`
  inline-size: 100%;
  position: relative;
  z-index: 1;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ArrowButtonContent = styled.div`
  padding: 0.5rem;
  inline-size: 0.708rem;
  block-size: 0.708rem;
  border-block-start: 4px solid ${({ theme }) => theme.colors.primaryLight};
  border-inline-start: 4px solid ${({ theme }) => theme.colors.primaryLight};
  cursor: pointer;
`

export const ArrowButton = styled.button`
  z-index: 100;
  position: absolute;
  inset-block-start: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 5rem;
  block-size: 5rem;
  ${landscape(`
    margin-block: 0 ;
    margin-inline:  6rem;
  `)}
  // TODO how to use clamp with percentage?
  ${tablet(
    Device.ipadMini,
    `
    inset-block-start: 50%;
  `
  )}
`

export const PrevButton = styled(ArrowButton)`
  inset-inline-start: 0;
  transform: rotate(-45deg);
`

export const NextButton = styled(ArrowButton)`
  inset-inline-end: 0;
  transform: rotate(135deg);
`

export const PrizmButton = styled(PrimaryButton)<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 1;
  display: inline-block;
`

export const StyledImage = styled.img<{ isVisible?: boolean }>`
  position: absolute;
  z-index: -100;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  inline-size: 100%;
  block-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  transition: opacity 0.3s;
`

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
  inline-size: 100%;
  block-size: 100%;
`

export const PrizmProgressBar = styled(ProgressBar)`
  position: absolute;
  inset-inline-start: 50%;
  transform: translate(-50%);
  inline-size: 45vw;
  inset-block-end: ${pxToRem(30)};
  ${tablet(`
    inset-block-end: ${clampValues(20, 27)}
  `)}

  ${landscape(`
    inline-size: 37.73vw;
  `)}
`
