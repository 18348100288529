import { IconProps } from '@abstractTypes/icons'


export const GolfIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path
        d="m46.128 38.249-.25-.433a2.982 2.982 0 0 0-1.822-1.398 2.983 2.983 0 0 0-2.277.3l-6.928 4-9.697-16.796L38.386 1.006l-1.732-1L24 21.922 11.347.006l-1.732 1 13.231 22.916-9.697 16.796-6.928-4a2.996 2.996 0 0 0-4.098 1.098l-.25.433a6.51 6.51 0 0 0 2.379 8.88 6.464 6.464 0 0 0 3.239.869 6.5 6.5 0 0 0 5.361-2.833l.029.017.248-.43.002-.002 1.75-3.031L24 25.922l9.12 15.795 1.75 3.031.002.002.248.43.029-.017a6.5 6.5 0 0 0 5.361 2.833c1.102 0 2.218-.28 3.239-.869a6.506 6.506 0 0 0 2.379-8.878zM5.251 45.396a4.506 4.506 0 0 1-1.647-6.147l.25-.433a.997.997 0 0 1 1.365-.366l6.928 4-.751 1.301a4.503 4.503 0 0 1-6.145 1.645zm37.498 0a4.506 4.506 0 0 1-6.146-1.646l-.751-1.301 6.928-4a.993.993 0 0 1 1.365.366l.25.433a4.505 4.505 0 0 1-1.646 6.148z"
        fill={fill}
      />
      <path
        d="M24 38a5 5 0 1 0 .001 10.001A5 5 0 0 0 24 38zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"
        fill={fill}
      />
    </svg>
  )
}
