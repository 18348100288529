export const SET_FRAME_ADVISOR_FLOW = 'ui/SET_FRAME_ADVISOR_FLOW'
export const SET_BARCODE_FLOW = 'ui/SET_BARCODE_FLOW'
export const SET_CUSTOMER_ORDER_FLOW = 'ui/SET_CUSTOMER_ORDER_FLOW'

export interface SetFrameAdvisorFlowAction {
  type: typeof SET_FRAME_ADVISOR_FLOW
  payload: {
    show: boolean
  }
}
export interface SetBarcodeFlowAction {
  type: typeof SET_BARCODE_FLOW
  payload: {
    show: boolean
  }
}
export interface SetCustomerOrderFlowAction {
  type: typeof SET_CUSTOMER_ORDER_FLOW
  payload: {
    show: boolean
  }
}

export const setFrameAdvisorFlow = (show: boolean): SetFrameAdvisorFlowAction => ({
  type: SET_FRAME_ADVISOR_FLOW,
  payload: {
    show,
  },
})
export const setBarcodeFlow = (show: boolean): SetBarcodeFlowAction => ({
  type: SET_BARCODE_FLOW,
  payload: {
    show,
  },
})
export const setCustomerOrderFlow = (show: boolean): SetCustomerOrderFlowAction => ({
  type: SET_CUSTOMER_ORDER_FLOW,
  payload: {
    show,
  },
})

export type FlowsActionTypes =
  | SetCustomerOrderFlowAction
  | SetBarcodeFlowAction
  | SetFrameAdvisorFlowAction
