import { ProductHierarchies, ProductTypeValues } from '@abstractTypes/filter'
import ConditionalRender from '@components/ConditionalRender'
import { LanguageSelector } from '@components/LanguageSelector/'
import { AccordionSingle } from '@components/core/Accordion'
import { InlineWrapper } from '@components/core/Accordion/styles'
import {
  ArrowLeftIcon,
  BarcodeIcon,
  CloseIcon,
  FrameAdvisorMenuIcon,
  HandoffIcon,
  HomeIcon,
  RefreshIcon,
  StoreIcon,
  ThumbUpIcon,
} from '@components/core/Icons'
import { DigitalCouvetteIcon } from '@components/core/Icons/DigitalCouvette'
import { ListItemButton, ListItemIcon } from '@components/core/List'
import { ResetNavigationModal } from '@components/core/Modals/ResetNavigationModal'
import { Heading3, Heading4, Heading5 } from '@components/core/Typography'
import config from '@config/index'
import { isBarcodeScannerAvailable, useBarcodeScanner } from '@hooks/useBarcodeScanner'
import { isDigitalCouvetteAvailable, useDigitalCouvette } from '@hooks/useDigitalCouvette'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useModalHandler } from '@hooks/useModalHandler'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { isQRCodeScannerAvailable, useQRCodeScanner } from '@hooks/useQRCodeScanner'
import { useStoreContext } from '@hooks/useStoreContext'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { useThemeContext } from '@hooks/useThemeContext'
import { formatBrandBaseIntoBrand } from '@libs/brand'
import { createMenuContent } from '@libs/menu'
import { isProductTypeSubdivision } from '@libs/productSubdivision'
import { pxToRem } from '@libs/styled'
import { isIPadView, usePageChecker } from '@libs/utils'
import { useResetContext } from '@providers/resetProvider'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MenuContent, MenuItem } from './MenuContent'
import {
  BackArrowButton,
  BackCustomerOrder,
  CloseButton,
  CustomerOrderResetButtonContainer,
  DrawerBackArrowContainer,
  HeadMenuBorderItem,
  HeadMenuItem,
  HeadMenuList,
  MenuContainer,
  MenuPanelHeader,
  ScrollingContent,
  SwitcherWithLabelMainMenu,
} from './styles'

const useFrameAdvisorNavigationHandler = () => {
  const faState = useSelector(s => s.frameAdvisor)
  const { basePath } = useStoreIndentity()
  const surveyIsCompleted = faState.isSurveyComplete
  const fsaIsConnected = faState.fsaInfo

  if (surveyIsCompleted && !fsaIsConnected) return `${basePath}/frame-advisor/survey`
  if (fsaIsConnected) return `${basePath}/frame-advisor/playlist`

  return `${basePath}/frame-advisor/onboarding`
}

export const MainMenu: React.FC = () => {
  const theme = useThemeContext()
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const store = useStoreContext()
  const FAPath = useFrameAdvisorNavigationHandler()

  const openBarcodeScanner = useBarcodeScanner({
    timeout: config.resetAppTimeOut,
    assetsLogoSrc: `${config.assets.URL}/logos/${config.shopperSlug}/logo-for-barcode-page.png`,
  })

  const openQRCodeScanner = useQRCodeScanner({
    timeout: config.resetAppTimeOut,
    assetsLogoSrc: `${config.assets.URL}/logos/${config.shopperSlug}/logo-for-barcode-page.png`,
    topLabel: 'HandsOff.topLabel',
    bottomLabel: 'HandsOff.bottomLabel',
  })
  const openDigitalCouvette = useDigitalCouvette()
  const scrollingContentRef = useRef<HTMLDivElement>(null)
  const isBarcodeScannerEnabled = store.barcodeReaderEnabled

  const isSessionTransferAvailable = isIPadView()
    ? config.toggleFeature.handoff && isQRCodeScannerAvailable()
    : config.toggleFeature.handoff

  const availableStoreLanguages = store.languages || []

  const { reset } = useResetContext()

  const brands = (store.brands || []).map(brandBase =>
    formatBrandBaseIntoBrand(brandBase, basePath)
  )

  const menuContent = store.menu ? createMenuContent(store.menu, brands, basePath) : []
  const { closeAnyDrawer } = useDrawerHandler()

  const [menuIsCreated, setMenuIsCreated] = useState(false)
  const [currentMenuGroup, setCurrentMenuGroup] = useState(menuContent[0])
  const [historyMenuGroup, setHistoryMenuGroup] = useState<MenuItem[]>([menuContent[0]])
  const [isResetModalVisible, setIsResetModalVisible] = useState(false)
  const languageSelectorIsVisible = useSelector(({ ui }) => ui.languageSelectorIsVisible)

  const { zoomLevel } = useNavigationContext()
  const { setVisibleModal } = useModalHandler('transferSessionModal')

  if (!menuIsCreated && menuContent.length > 0) {
    setMenuIsCreated(true)
    setCurrentMenuGroup(menuContent[0])
    setHistoryMenuGroup([menuContent[0]])
  }

  const goBackMenu = useCallback(() => {
    historyMenuGroup.pop()
    const menuItem = historyMenuGroup[historyMenuGroup.length - 1]
    setCurrentMenuGroup(menuItem)
    setHistoryMenuGroup([...historyMenuGroup])
  }, [historyMenuGroup])

  const openMenu = useCallback(
    (menu: MenuItem) => {
      setHistoryMenuGroup([...historyMenuGroup, menu])
      setCurrentMenuGroup(menu)
    },
    [historyMenuGroup]
  )

  const resetNavigation = () => {
    reset()
    setIsResetModalVisible(false)
  }

  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)

  const history = useHistory()

  const isIpad = isIPadView()

  const { isHomePage } = usePageChecker()

  const iconSize = useMemo(() => pxToRem(isIpad ? 24 : 32, zoomLevel / 100), [isIpad, zoomLevel])

  const isTopLevel = historyMenuGroup.length === 1

  useEffect(() => {
    if (scrollingContentRef.current && historyMenuGroup.length > 1) {
      scrollingContentRef.current.scrollTo({ top: 0 })
    }
  }, [historyMenuGroup])

  const productSubdivisionEyeglasses = isProductTypeSubdivision()
    ? ProductTypeValues.OPTICAL
    : ProductHierarchies.EYEGLASSES
  const productSubdivisionSunglasses = isProductTypeSubdivision()
    ? ProductTypeValues.SUN
    : ProductHierarchies.SUNGLASSES

  const CustomerOrderResetButton: React.FC = () => {
    return (
      <CustomerOrderResetButtonContainer>
        <BackArrowButton type="button" onClick={() => setIsResetModalVisible(true)}>
          <ArrowLeftIcon width={pxToRem(32)} height={pxToRem(32)} />
        </BackArrowButton>
        <BackCustomerOrder
          data-element-id="MainNav_MenuReset"
          data-test="MainNav_MenuReset"
          onClick={() => setIsResetModalVisible(true)}
          aria-label={t('CustomerOrder.backToOrder')}
          aria-description={t('CustomerOrder.backToOrder')}
        >
          {t('CustomerOrder.backToOrder')}
        </BackCustomerOrder>
      </CustomerOrderResetButtonContainer>
    )
  }

  return (
    <>
      <MenuContainer isTopLevel={isTopLevel}>
        <MenuPanelHeader isTopLevel={isTopLevel}>
          {historyMenuGroup.length > 1 ? (
            <>
              <DrawerBackArrowContainer
                onClick={goBackMenu}
                data-analytics_available_call="0"
                aria-label={t('ARIA.backIcon.label')}
              >
                <ArrowLeftIcon
                  width={pxToRem(isIpad ? 32 : 40, zoomLevel / 100)}
                  height={pxToRem(isIpad ? 28 : 35, zoomLevel / 100)}
                />
              </DrawerBackArrowContainer>
              <Heading3>{historyMenuGroup[historyMenuGroup.length - 1].label}</Heading3>
            </>
          ) : (
            <LanguageSelector languages={availableStoreLanguages} />
          )}
          <CloseButton
            type="button"
            onClick={closeAnyDrawer}
            data-analytics_available_call="0"
            data-test="Main-nav_ Close-button"
            aria-label={t('ARIA.closeIcon.label')}
            isTopLevel={isTopLevel}
          >
            <CloseIcon
              height={pxToRem(isIpad ? 27 : 34, zoomLevel / 100)}
              width={pxToRem(isIpad ? 27 : 34, zoomLevel / 100)}
              stroke={theme.colors.primaryDark}
              fill={theme.colors.primaryDark}
            />
          </CloseButton>
        </MenuPanelHeader>

        {!languageSelectorIsVisible && (
          <ScrollingContent ref={scrollingContentRef} isTopLevel={isTopLevel}>
            {historyMenuGroup.length < 2 && (
              <HeadMenuList>
                <HeadMenuItem>
                  {config.toggleFeature.availableInStore && (
                    <AccordionSingle
                      isInitiallyOpen
                      aria-label={t('PlpFilters.storeAvailable')}
                      aria-description={t('PlpFilters.storeAvailable')}
                      title={
                        <InlineWrapper>
                          <ListItemIcon>
                            <StoreIcon
                              height={iconSize}
                              width={iconSize}
                              fill={theme.colors.primaryDark}
                            />
                          </ListItemIcon>
                          <Heading4>{t('PlpFilters.storeAvailable')}</Heading4>
                        </InlineWrapper>
                      }
                    >
                      <>
                        {store.eyeglassesEnabled && (
                          <SwitcherWithLabelMainMenu
                            productSubdivision={productSubdivisionEyeglasses}
                            aria-description={t('Core.eyeglasses')}
                            aria-label={t('Core.eyeglasses')}
                            label={<Heading5>{t('Core.eyeglasses')}</Heading5>}
                            dataAttr={{ test: 'Eyeglasses_Available-In-Store_Menu-Toggle' }}
                          />
                        )}

                        <SwitcherWithLabelMainMenu
                          productSubdivision={productSubdivisionSunglasses}
                          aria-description={t('Core.sunglasses')}
                          aria-label={t('Core.sunglasses')}
                          label={<Heading5>{t('Core.sunglasses')}</Heading5>}
                          dataAttr={{ test: 'Sunglasses-In-Store_Menu-Toggle' }}
                        />
                      </>
                    </AccordionSingle>
                  )}
                </HeadMenuItem>
                {!isCustomerOrder && (
                  <HeadMenuItem>
                    <ListItemButton
                      dataAttrs={{
                        id: 'MainNav_MenuReset',
                      }}
                      ariaAttrs={{
                        label: t('Menu.reset'),
                        description: t('Menu.reset'),
                      }}
                      onClick={() => setIsResetModalVisible(true)}
                    >
                      <ListItemIcon>
                        <RefreshIcon
                          height={iconSize}
                          width={iconSize}
                          fill={theme.colors.primaryDark}
                        />
                      </ListItemIcon>
                      <Heading4>{t('Menu.reset')}</Heading4>
                    </ListItemButton>
                  </HeadMenuItem>
                )}
                {!isHomePage && (
                  <HeadMenuItem>
                    <ListItemButton
                      dataAttrs={{ id: 'MainNav_MenuHomePage' }}
                      ariaAttrs={{
                        label: t('Menu.home'),
                        description: t('Menu.home'),
                      }}
                      onClick={() => history.push(basePath)}
                    >
                      <ListItemIcon>
                        <HomeIcon
                          height={iconSize}
                          width={iconSize}
                          fill={theme.colors.primaryDark}
                        />
                      </ListItemIcon>
                      <Heading4>{t('Menu.home')}</Heading4>
                    </ListItemButton>
                  </HeadMenuItem>
                )}
                <HeadMenuItem>
                  <ListItemButton
                    dataAttrs={{ id: 'MainNav_MenuWishlist' }}
                    ariaAttrs={{
                      label: t('Menu.wishlist'),
                      description: t('Menu.wishlist'),
                    }}
                    onClick={() => history.push(basePath + '/wishlist')}
                  >
                    <ListItemIcon>
                      <ThumbUpIcon
                        height={iconSize}
                        width={iconSize}
                        fill={theme.colors.primaryDark}
                      />
                    </ListItemIcon>
                    <Heading4>{t('Menu.wishlist')}</Heading4>
                  </ListItemButton>
                </HeadMenuItem>
                {store.frameAdvisorEnabled && (
                  <HeadMenuItem>
                    <>
                      <ListItemButton
                        navigateTo={FAPath}
                        dataAttrs={{ id: 'MainNav_MenuFrameFind' }}
                      >
                        <ListItemIcon>
                          <FrameAdvisorMenuIcon
                            height={iconSize}
                            width={iconSize}
                            fill={theme.colors.primaryDark}
                          />
                        </ListItemIcon>
                        <Heading4
                          aria-label={t('Menu.fsaMenuItem')}
                          aria-description={t('Menu.fsaMenuItem')}
                        >
                          {t('Menu.fsaMenuItem')}
                        </Heading4>
                      </ListItemButton>
                    </>
                  </HeadMenuItem>
                )}
                {/* TODO we need to
                  1 - Open the qrcode reader from SS kiosk app (already talked with gianp about it
                  2 - Ask for an icon, text and analytics tracking
                  3 - This should only be available, like for qrcodereader, if the plugin for ss is present and we are on ipad
                */}
                {isBarcodeScannerAvailable() && isBarcodeScannerEnabled && (
                  <HeadMenuItem>
                    <ListItemButton
                      dataAttrs={{ id: 'MainNav_MenuBarcode' }}
                      ariaAttrs={{
                        label: t('Menu.barcode'),
                        description: t('Menu.barcode'),
                      }}
                      onClick={openBarcodeScanner}
                    >
                      <ListItemIcon>
                        <BarcodeIcon
                          height={pxToRem(isIpad ? 24 : 32, zoomLevel / 100)}
                          width={pxToRem(isIpad ? 24 : 32, zoomLevel / 100)}
                          fill={theme.colors.primaryDark}
                        />
                      </ListItemIcon>
                      <Heading4>{t('Menu.barcode')}</Heading4>
                    </ListItemButton>
                  </HeadMenuItem>
                )}
                <ConditionalRender
                  condition={isDigitalCouvetteAvailable()}
                  render={() => (
                    <HeadMenuItem>
                      <ListItemButton
                        dataAttrs={{ id: 'MainNav_MenuDigitalCouvette' }}
                        ariaAttrs={{
                          label: t('Menu.digitalCouvette'),
                          description: t('Menu.digitalCouvette'),
                        }}
                        onClick={() => openDigitalCouvette()}
                      >
                        <ListItemIcon>
                          <DigitalCouvetteIcon
                            height={pxToRem(isIpad ? 24 : 32, zoomLevel / 100)}
                            width={pxToRem(isIpad ? 24 : 32, zoomLevel / 100)}
                            fill={theme.colors.primaryDark}
                          />
                        </ListItemIcon>
                        <Heading4>{t('Menu.digitalCouvette')}</Heading4>
                      </ListItemButton>
                    </HeadMenuItem>
                  )}
                />
                <ConditionalRender
                  condition={isSessionTransferAvailable}
                  render={() => (
                    <HeadMenuBorderItem>
                      <ListItemButton
                        dataAttrs={{ id: 'MainNav_MenuSessionTransfer' }}
                        ariaAttrs={{
                          label: isIPadView()
                            ? t('Menu.sessionTransferTablet')
                            : t('Menu.sessionTransferTower'),
                          description: isIPadView()
                            ? t('Menu.sessionTransferTablet')
                            : t('Menu.sessionTransferTower'),
                        }}
                        onClick={() => {
                          isIPadView()
                            ? openQRCodeScanner()
                            : setVisibleModal('transferSessionModal')
                        }}
                      >
                        <ListItemIcon>
                          <HandoffIcon
                            height={pxToRem(isIpad ? 24 : 32, zoomLevel / 100)}
                            width={pxToRem(isIpad ? 24 : 32, zoomLevel / 100)}
                            fill={theme.colors.primaryDark}
                          />
                        </ListItemIcon>
                        <Heading4>
                          {isIPadView()
                            ? t('Menu.sessionTransferTablet')
                            : t('Menu.sessionTransferTower')}
                        </Heading4>
                      </ListItemButton>
                    </HeadMenuBorderItem>
                  )}
                />
              </HeadMenuList>
            )}

            <MenuContent menu={currentMenuGroup} onSelect={openMenu} isTopLevel={isTopLevel} />
            {isCustomerOrder && <CustomerOrderResetButton />}
          </ScrollingContent>
        )}
      </MenuContainer>
      <ResetNavigationModal
        visible={isResetModalVisible}
        onCancel={() => {
          setIsResetModalVisible(false)
        }}
        onClick={resetNavigation}
      />
    </>
  )
}
