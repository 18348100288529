import {
  ContentV2ElectronicsTab,
  ContentV2ElectronicsTabContent,
  ContentV2Image,
  ElectronicsContentV2Article,
  Maybe,
} from '@abstractTypes/graphqlTypes'
import { ElectronicsProduct } from '@abstractTypes/product'
import { Tabs } from '@components/Tabs'
import { ScrollableTabContent } from '@components/Tabs/ScrollableTabContent'
import { ElectronicsHeading4 } from '@components/core/Typography/ElectronicsTypography'
import config from '@config/index'
import { useMixMatchEnabled } from '@hooks/useMixMatchEnabled'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { equals } from '@libs/caseInsensitive'
import { useCallback } from 'react'
import { TabTitle } from '../ElectronicsContent/styles'
import { ArticleContent } from './ArticleContent'
import { DetailsContent } from './CustomTabs/DetailsContent'
import { LensesWrapper } from './CustomTabs/LensesContent'
import { ImagesContent } from './ImagesContent'
import {
  ProductTabsContainer as ElectronicsProductTabsContainer,
  HtmlContent,
  TabContentStyled,
  TabInnerContent,
} from './styles'
import { ElectronicsTabsProps } from './types'

const metaViewIcon = `${config.assets.URL}/electronics/meta-view.svg`

const renderTabContent = (
  tabType = '',
  product: ElectronicsProduct,
  lensesContent: ElectronicsContentV2Article,
  lensesColorsContent?: ElectronicsContentV2Article
) => {
  switch (tabType) {
    case 'details':
      return <DetailsContent product={product} />
    case 'lenses':
      return (
        <LensesWrapper lensesContent={lensesContent} lensesColorsContent={lensesColorsContent} />
      )
    default:
      return null
  }
}

const renderCMSTabContent = (content: Maybe<ContentV2ElectronicsTabContent>, tabID?: string) => {
  switch (content?.__typename) {
    case 'ElectronicsContentV2Article':
      return (
        <div id={`tab-content-${tabID}`} key={`tab-content-${tabID}`}>
          <ArticleContent key={content.id} article={content} />
        </div>
      )
    case 'ContentV2HTML':
      return (
        <div id={`tab-content-${tabID}`} key={`tab-content-${tabID}`}>
          <HtmlContent
            key={content.id}
            dangerouslySetInnerHTML={{ __html: content?.data?.replace(/\\n/g, '<br/>') || '' }}
          />
        </div>
      )
    default:
      return null
  }
}

export enum ImagesPlacement {
  top = 'top',
  bottom = 'bottom',
}

const getImagesPlacement = (tabType: string): ImagesPlacement => {
  if (tabType === 'app') return ImagesPlacement.top
  return ImagesPlacement.bottom
}

const getImagesContent = (tab: ContentV2ElectronicsTab): ContentV2Image[] => {
  // for the meta view tab, the image does not return from the CMS
  if (tab.type === 'app') {
    return [
      {
        altText: '',
        id: 'cmpicture-metaview',
        src: metaViewIcon,
        __typename: 'ContentV2Image',
        cropImage: {
          _1x1: metaViewIcon,
          _3x4: metaViewIcon,
          _4x3: metaViewIcon,
          _9x16: metaViewIcon,
          _16x9: metaViewIcon,
        },
      },
    ]
  }

  return (tab.content || []).filter((c): c is ContentV2Image => c?.__typename === 'ContentV2Image')
}

export const ProductTabs: React.FC<ElectronicsTabsProps> = ({
  tabs,
  product,
  isTabsExpanded,
  tabIndex,
  tabChangeHandler,
  sliderShowed,
  isTabsListSticky,
  seeOnVideo,
}) => {
  const { isAlternativeNavigation } = useNavigationContext()
  const showExtraLensesDetails = useMixMatchEnabled()

  const renderContent = useCallback(
    (tab: ContentV2ElectronicsTab) => {
      const tabContent = tab.content ? tab.content : []
      const tabContent0 = tabContent[0] as ElectronicsContentV2Article
      const tabContent1 = tabContent[1] as ElectronicsContentV2Article | undefined

      if (equals(tab.type, 'details') || (equals(tab.type, 'lenses') && showExtraLensesDetails))
        return renderTabContent(tab.type, product, tabContent0, tabContent1)

      return tabContent.map((content, i) => {
        const id = `${content?.id}-${i}`

        return renderCMSTabContent(content, id)
      })
    },
    [product, showExtraLensesDetails]
  )

  return (
    <ElectronicsProductTabsContainer
      isExpanded={isTabsExpanded}
      sliderShowed={sliderShowed}
      isTabsListSticky={isTabsListSticky}
    >
      <Tabs
        defaultActiveKey={0}
        activeKey={tabIndex}
        onTabClick={tabChangeHandler}
        tabs={tabs.map(t => ({
          id: t.id || '',
          analytics: t.analyticsData || '',
          tabContent: () => (
            <ElectronicsHeading4 weight="bold">{t.tabName || ''}</ElectronicsHeading4>
          ),
        }))}
        dataCalls={'0'}
      >
        {tabs.map((tab, i) => {
          const isActiveTab = i === tabIndex
          return (
            <TabContentStyled
              key={`${tab.id}-${i}-${tab.title}`}
              imagesOnTop={getImagesPlacement(tab.type) === ImagesPlacement.top}
              seeOnVideo={seeOnVideo}
            >
              <ScrollableTabContent>
                <TabInnerContent
                  aria-disabled={!isActiveTab}
                  aria-hidden={!isActiveTab}
                  tabIndex={-1}
                  aria-live="polite"
                  id={`tab-content-${tab.id}`}
                  flexDirection={
                    getImagesPlacement(tab.type) === ImagesPlacement.top
                      ? 'column-reverse'
                      : 'column'
                  }
                >
                  {!isTabsExpanded && isAlternativeNavigation && (
                    <TabTitle aria-description={tab.title || ''}>{tab.title}</TabTitle>
                  )}
                  <h6 aria-label={tab.tabName || ''}></h6>
                  {renderContent(tab)}
                  <ImagesContent images={getImagesContent(tab)} />
                </TabInnerContent>
              </ScrollableTabContent>
            </TabContentStyled>
          )
        })}
      </Tabs>
    </ElectronicsProductTabsContainer>
  )
}
