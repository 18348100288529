import { IconProps } from '@abstractTypes/icons'

export const FaceShapeOvalWomanIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61830)">
        <path
          d="M47.707 36.543C45.736 34.572 45.001 26.117 45 21.25C45 9.67 35.58 0.25 24 0.25C12.42 0.25 3 9.67 3 21.25C2.999 26.117 2.264 34.572 0.293 36.543L0 36.836V37.25C0 43.315 4.935 48.25 11 48.25C13.954 48.25 16.634 47.074 18.612 45.172C20.285 45.869 22.107 46.25 24 46.25C25.893 46.25 27.715 45.869 29.388 45.172C31.366 47.074 34.046 48.25 37 48.25C43.065 48.25 48 43.315 48 37.25V36.836L47.707 36.543ZM24 44.25C19.447 44.25 15.353 41.72 13.317 37.647C12.415 35.842 11.769 33.921 11.397 31.939L9.195 20.198C14.841 19.701 18.935 15.746 20.404 12.06C22.402 14.523 27.227 18.085 38.828 20.071L36.602 31.94C36.23 33.922 35.584 35.843 34.682 37.648C32.647 41.72 28.553 44.25 24 44.25ZM37 46.25C34.836 46.25 32.848 45.482 31.294 44.204C33.47 42.87 35.275 40.935 36.472 38.542C37.457 36.57 38.163 34.474 38.569 32.309L40.983 19.434L40.156 18.262C22.377 15.455 20.999 9.151 20.99 9.108L19 9.25C19 12.79 14.276 18.25 8 18.25L7.017 19.434L9.431 32.309C9.837 34.474 10.542 36.571 11.528 38.542C12.725 40.935 14.53 42.871 16.706 44.204C15.152 45.482 13.164 46.25 11 46.25C6.161 46.25 2.202 42.412 2.007 37.62C4.914 33.984 5 22.579 5 21.25C5 10.773 13.523 2.25 24 2.25C34.477 2.25 43 10.773 43 21.25C43 22.579 43.086 33.984 45.993 37.62C45.798 42.412 41.839 46.25 37 46.25Z"
          fill="#333333"
        />
        <path
          d="M26.4324 23.3066H24.4324V31.2496H21.9844V33.2496H26.4324V23.3066Z"
          fill="#333333"
        />
        <path
          d="M27.3115 37.178L23.9995 36.208L20.6875 37.178L21.8785 38.371C22.4445 38.938 23.1975 39.25 23.9995 39.25C24.8015 39.25 25.5545 38.938 26.1215 38.371L27.3115 37.178Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61830">
          <rect width={48} height={48} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
