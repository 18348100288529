import { IconProps } from '@abstractTypes/icons'
export const CategoryTargetandhuntingIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M24 0C10.767 0 0 10.767 0 24s10.767 24 24 24 24-10.767 24-24S37.233 0 24 0zm1 45.975V42h-2v3.975C11.663 45.465 2.535 36.337 2.025 25H6v-2H2.025C2.535 11.663 11.663 2.535 23 2.025V6h2V2.025c11.337.51 20.465 9.638 20.975 20.975H42v2h3.975C45.465 36.337 36.337 45.465 25 45.975z"
        fill={fill}
      />
      <path
        d="M25 13.051V11h-2v2.051c-5.268.478-9.472 4.681-9.949 9.949H11v2h2.051c.478 5.268 4.681 9.472 9.949 9.949V37h2v-2.051c5.268-.478 9.472-4.681 9.949-9.949H37v-2h-2.051c-.477-5.268-4.681-9.472-9.949-9.949zm0 19.89V31h-2v1.941A9.013 9.013 0 0 1 15.059 25H17v-2h-1.941A9.013 9.013 0 0 1 23 15.059V17h2v-1.941A9.013 9.013 0 0 1 32.941 23H31v2h1.941A9.013 9.013 0 0 1 25 32.941z"
        fill={fill}
      />
      <path d="M24 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill={fill} />
    </svg>
  )
}
