import { IconProps } from '@abstractTypes/icons'


export const MountainAndTrailIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path
        d="M46.569 46 34.082 25h-2.165l-1.93 3.245L21 9.769V8h9l.832-1.555L29.202 4l1.63-2.445L30 0H19v9.769L1.374 46H0v2h48v-2h-1.431zm-8.558-10.48c-.703.452-1.7.377-2.304-.227a3.832 3.832 0 0 0-5.414 0c-.604.604-1.601.68-2.304.227L33 27.093l5.011 8.427zM25.334 23.252l-.041.041c-.69.69-1.896.69-2.586 0a3.832 3.832 0 0 0-5.414 0c-.69.69-1.896.69-2.586 0l-.042-.042L20 12.286l5.334 10.966zM21 2h7.131l-.963 1.445v1.109L28.131 6H21V2zm-7.232 23.095c1.495 1.079 3.594.957 4.939-.388a1.83 1.83 0 0 1 2.586 0A3.814 3.814 0 0 0 24 25.827c.786 0 1.566-.251 2.231-.731l2.533 5.207-5.624 9.459 1.719 1.022 2.109-3.547a3.828 3.828 0 0 0 4.738-.53 1.83 1.83 0 0 1 2.586 0 3.814 3.814 0 0 0 4.738.53L44.242 46H3.599l10.169-20.905z"
        fill={fill}
      />
    </svg>
  )
}
