import { TypographyProps } from '@abstractTypes/typography'
import { generateDefaultFontProperties } from '@libs/typography'
import styled from 'styled-components'

export const ElectronicsHeading1 = styled.h1<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(
      theme,
      theme.electronics.fonts.heading1,
      { ...props, color: props.color || 'primaryLight' },
      'electronics'
    )}
  text-transform: uppercase;
`

export const ElectronicsHeading4 = styled.h4<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(
      theme,
      theme.electronics.fonts.heading4,
      { ...props },
      'electronics'
    )}
  text-transform: uppercase;
`

export const ElectronicsText = styled.p<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(
      theme,
      theme.electronics.fonts.bodyLenses,
      { ...props },
      'electronics'
    )}
`

export const ElectronicsListTypography = styled.li<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(
      theme,
      theme.electronics.fonts.heading5,
      { ...props },
      'electronics'
    )}
`

export const ElectronicsButtonTypography = styled.button<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.electronics.fonts.cta, { ...props }, 'electronics')}
  text-transform: uppercase;
`
