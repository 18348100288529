import { PlayButton, StopButton } from '@components/core/Icons'
import { VideoModalSlideTabletProps } from '@components/NuanceComponents/NuanceSlider/types'
import { useDeviceType } from '@hooks/useDeviceType'
import { useLanguageDirection } from '@hooks/useNavigationUtils'
import { useThemeContext } from '@hooks/useThemeContext'
import { pxToRem } from '@libs/styled'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ControlButton, Title, VideoModalSlideStyled, VideoStyled } from './styles'

const VideoModalSlide: React.FC<VideoModalSlideTabletProps> = ({
  video,
  poster,
  index,
  slideIndex,
  autoplay,
  showPlayAnimation = true,
}) => {
  const rtlDirection = useLanguageDirection()
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const { isIpad } = useDeviceType()
  const [isPlaying, setIsPlaying] = useState(false)
  const [hasPlayed, setHasPlayed] = useState(false)
  const theme = useThemeContext()

  const handlePlay = useCallback(() => {
    if (videoRef?.current) {
      videoRef.current.muted = !isIpad
      videoRef.current.play()
      setHasPlayed(true)
      setIsPlaying(true)
    }
  }, [isIpad])

  const handlePause = useCallback(() => {
    if (videoRef?.current) {
      videoRef.current.muted = true
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }, [])

  const onTogglePlay = useCallback(() => {
    if (videoRef?.current) {
      if (isPlaying) {
        handlePause()
      } else {
        handlePlay()
      }
    }
  }, [isPlaying, handlePause, handlePlay])

  useEffect(() => {
    if (index === slideIndex) {
      if (autoplay) {
        handlePlay()
      } else {
        handlePause()
      }
    }
  }, [autoplay, index, slideIndex, handlePlay, handlePause])

  return (
    <VideoModalSlideStyled dir={rtlDirection ? 'rtl' : 'ltr'}>
      <Title color={theme.colors.primaryLight}>{video?.title}</Title>
      <ControlButton
        onClick={onTogglePlay}
        playAnimation={isIpad && !isPlaying && showPlayAnimation && !hasPlayed}
      >
        {isPlaying ? (
          <StopButton width={pxToRem(60)} height={pxToRem(60)} />
        ) : (
          <PlayButton width={pxToRem(60)} height={pxToRem(60)} />
        )}
      </ControlButton>
      <VideoStyled
        ref={videoRef}
        loop
        muted
        autoPlay={false}
        preload="auto"
        poster={poster?.src}
        onClick={onTogglePlay}
      >
        <source src={video?.src} type={video?.type} />
      </VideoStyled>
    </VideoModalSlideStyled>
  )
}

export default VideoModalSlide
