import { IconProps } from '@abstractTypes/icons'
export const GreenDiscoverIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.793 11.5L12.6465 2.35348L13.3535 1.64648L23.707 12L13.3535 22.3535L12.6465 21.6465L21.793 12.5H2.47955e-05V11.5H21.793Z"
        fill={fill}
      />
    </svg>
  )
}
