import React from 'react'
import { Loading } from '@components/Loading'
import { PageLoading } from './styles'

export const LoadingPage: React.FC<{ loading: boolean; fullscreen?: boolean; zIndex?: number }> = ({
  children,
  loading,
  fullscreen = true,
  zIndex,
}) => {
  return (
    <>
      {children}
      {loading && (
        <PageLoading fullscreen={fullscreen} zIndex={zIndex}>
          <Loading />
        </PageLoading>
      )}
    </>
  )
}
