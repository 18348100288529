import { zoomPortrait } from '@libs/media'
import styled from 'styled-components'
import { ModuleInfoContent } from '../ProductCard.style'

export const ColorsStyled = styled(ModuleInfoContent)`
  ${zoomPortrait(
    [150, 175, 200],
    `
    block-size: auto;
    `
  )}
`

export const ColorsStyledLabel = styled(ModuleInfoContent)``
