import { IconProps } from '@abstractTypes/icons'

export const PlusIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61708)">
        <path
          d="M18 8.875H9.375V0.25H8.625V8.875H0V9.625H8.625V18.25H9.375V9.625H18V8.875Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61708">
          <rect width={18} height={18} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
