import { IconProps } from '@abstractTypes/icons'


export const RtrIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 28"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.381 15.8261C25.0392 15.8261 24.7619 16.0982 24.7619 16.4348V20.2294L21.4531 18.2773C21.1597 18.1044 20.7796 18.197 20.6038 18.4861C20.428 18.7746 20.5227 19.1483 20.8161 19.3212L24.0958 21.2563L20.675 22.9381C20.3691 23.0884 20.2453 23.4543 20.3982 23.755C20.5066 23.9686 20.7251 24.0916 20.9529 24.0916C21.0458 24.0916 21.1405 24.0709 21.229 24.0277L25.6583 21.8497C25.662 21.8479 25.6651 21.8449 25.6688 21.843C25.7035 21.8254 25.7344 21.8017 25.7654 21.7779C25.7778 21.7682 25.7926 21.7603 25.8044 21.7499C25.8304 21.7262 25.8521 21.6963 25.8737 21.6671C25.8855 21.6519 25.8997 21.6385 25.9102 21.6221C25.9109 21.6209 25.9121 21.6203 25.9127 21.619C25.9152 21.6148 25.9152 21.6105 25.9177 21.6063C25.9511 21.549 25.9721 21.4857 25.9851 21.42C25.9889 21.3993 25.992 21.3804 25.9938 21.3597C25.9938 21.3397 26 21.3226 26 21.3043V16.4348C26 16.0982 25.7227 15.8261 25.381 15.8261Z"
        fill="#333333"
      />
      <path
        d="M25.9969 6.67313C25.9963 6.65609 25.992 6.63965 25.9895 6.62261C25.982 6.56417 25.9672 6.50878 25.9437 6.45583C25.9387 6.44548 25.94 6.43391 25.935 6.42357C25.9319 6.41687 25.9257 6.41322 25.922 6.40713C25.8873 6.34443 25.844 6.28783 25.7895 6.24035C25.7796 6.23183 25.7679 6.22635 25.7573 6.21843C25.7258 6.19409 25.6948 6.16913 25.6583 6.15087L21.229 3.97296C20.922 3.82383 20.5511 3.94496 20.3982 4.24504C20.2453 4.54574 20.3691 4.91156 20.675 5.06191L23.9968 6.69565L20.675 8.32939C20.3691 8.47974 20.2453 8.84556 20.3982 9.14626C20.5066 9.35991 20.7251 9.48287 20.9529 9.48287C21.0458 9.48287 21.1405 9.46217 21.229 9.41896L24.7619 7.68052V11.5652C24.7619 11.9018 25.0392 12.1739 25.381 12.1739C25.7227 12.1739 26 11.9018 26 11.5652V6.69565C26 6.68774 25.9969 6.68104 25.9969 6.67313Z"
        fill="#333333"
      />
      <path
        d="M8.84743 3.33139L12.381 1.59357V5.47826C12.381 5.81487 12.6583 6.08696 13 6.08696C13.3417 6.08696 13.619 5.81487 13.619 5.47826V1.59357L17.1526 3.33078C17.2417 3.374 17.3358 3.3947 17.4287 3.3947C17.6559 3.3947 17.8744 3.27174 17.9833 3.05809C18.1362 2.75739 18.0124 2.39157 17.7066 2.24122L13.2773 0.0633043C13.2718 0.0608695 13.2656 0.062087 13.26 0.0590435C13.1801 0.0237391 13.0935 0 13 0C12.9065 0 12.8199 0.0237391 12.74 0.0602609C12.7344 0.0626956 12.7282 0.0614782 12.7227 0.0645217L8.29338 2.24243C7.98757 2.39278 7.86376 2.75861 8.01667 3.0593C8.16957 3.35939 8.54038 3.48052 8.84743 3.33139Z"
        fill="#333333"
      />
      <path
        d="M5.60238 4.24565C5.44948 3.94556 5.07805 3.82443 4.77162 3.97356L0.342333 6.15148C0.305809 6.16974 0.274857 6.19469 0.243286 6.21843C0.232762 6.22635 0.221 6.23183 0.210476 6.24035C0.156 6.28783 0.112667 6.34443 0.078 6.40713C0.0742857 6.41322 0.0680953 6.41687 0.065 6.42357C0.0594286 6.43391 0.0606667 6.44548 0.0563334 6.45583C0.0328096 6.50878 0.0179524 6.56417 0.0105238 6.62261C0.00866664 6.63965 0.00371436 6.65609 0.00309531 6.67313C0.00309531 6.68104 0 6.68774 0 6.69565V11.5652C0 11.9018 0.277333 12.1739 0.619048 12.1739C0.960762 12.1739 1.2381 11.9018 1.2381 11.5652V7.68052L4.77162 9.41774C4.86076 9.46096 4.95486 9.48165 5.04771 9.48165C5.2749 9.48165 5.49343 9.3587 5.60238 9.14504C5.75529 8.84435 5.63148 8.47852 5.32567 8.32817L2.00324 6.69565L5.32505 5.06191C5.63148 4.91156 5.75529 4.54635 5.60238 4.24565Z"
        fill="#333333"
      />
      <path
        d="M5.32567 22.9381L1.90481 21.2556L5.18452 19.3206C5.47795 19.1477 5.57267 18.774 5.39686 18.4855C5.22105 18.1963 4.84095 18.1044 4.54752 18.2767L1.2381 20.2294V16.4348C1.2381 16.0982 0.960762 15.8261 0.619048 15.8261C0.277333 15.8261 0 16.0982 0 16.4348V21.3043C0 21.3226 0.00619053 21.3397 0.00742862 21.3579C0.00928577 21.3786 0.012381 21.3975 0.0160953 21.4182C0.0290953 21.4839 0.0501429 21.5472 0.0835714 21.6044C0.0860476 21.6087 0.0854285 21.6136 0.0885237 21.6172C0.0891428 21.6184 0.090381 21.619 0.0910001 21.6203C0.101524 21.6367 0.115762 21.6501 0.128143 21.6659C0.14981 21.6945 0.171476 21.7243 0.196857 21.7481C0.208619 21.759 0.223476 21.7663 0.235857 21.7767C0.26681 21.8004 0.297143 21.8242 0.33181 21.8418C0.335524 21.8437 0.338 21.8467 0.342333 21.8485L4.77162 24.0264C4.86076 24.0697 4.95486 24.0903 5.04771 24.0903C5.2749 24.0903 5.49343 23.9674 5.60238 23.7537C5.75529 23.4536 5.63148 23.0884 5.32567 22.9381Z"
        fill="#333333"
      />
      <path
        d="M17.1526 24.6686L13.619 26.4064V22.5217C13.619 22.1851 13.3417 21.913 13 21.913C12.6583 21.913 12.381 22.1851 12.381 22.5217V26.4064L8.84743 24.6692C8.54038 24.5201 8.16957 24.6412 8.01667 24.9413C7.86376 25.242 7.98757 25.6078 8.29338 25.7582L12.7227 27.9361C12.7282 27.9385 12.7344 27.9379 12.74 27.9403C12.8199 27.9763 12.9065 28 13 28C13.0935 28 13.1801 27.9763 13.2594 27.9397C13.265 27.9373 13.2711 27.9385 13.2767 27.9355L17.706 25.7576C18.0118 25.6072 18.1356 25.2414 17.9827 24.9407C17.8304 24.6406 17.459 24.5195 17.1526 24.6686Z"
        fill="#333333"
      />
      <path
        d="M8.29338 11.1489L12.381 13.1588V17.6522C12.381 17.9888 12.6583 18.2609 13 18.2609C13.3417 18.2609 13.619 17.9888 13.619 17.6522V13.1588L17.7066 11.1489C18.0124 10.9985 18.1362 10.6327 17.9833 10.332C17.8304 10.0319 17.459 9.91078 17.1526 10.0599L13 12.1021L8.84743 10.0605C8.54038 9.91139 8.16957 10.0325 8.01667 10.3326C7.86376 10.6333 7.98757 10.9985 8.29338 11.1489Z"
        fill="#333333"
      />
    </svg>
  )
}
