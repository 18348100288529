import { IconProps } from '@abstractTypes/icons'


export const TrashIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#333',
}: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M24 3H19.82H16.6665V1C16.6665 0.4475 16.219 0 15.6665 0H8.3335C7.781 0 7.3335 0.4475 7.3335 1V3H4.18H0V4H3.1855C3.1855 4.0335 3.1815 4.0655 3.185 4.0995L4.986 22.109C5.0935 23.1825 5.9965 24 7.0755 24H16.9245C18.0035 24 18.9065 23.1825 19.014 22.109L20.815 4.0995C20.8185 4.0655 20.8145 4.0335 20.8145 4H24V3ZM8.3335 1H15.667V3H8.3335V1ZM18.019 22.0095C17.9625 22.574 17.492 23 16.9245 23H7.0755C6.508 23 6.0375 22.574 5.981 22.0095L4.18 4H8.3335H15.667H19.8205L18.019 22.0095Z"
        fill={fill}
      />
      <path d="M12.5 6H11.5V21H12.5V6Z" fill={fill} />
      <path
        d="M16.0008 5.96588L15.0011 20.9663L15.9989 21.0328L16.9986 6.03238L16.0008 5.96588Z"
        fill={fill}
      />
      <path
        d="M7.9989 5.96719L7.0011 6.03369L8.00083 21.0341L8.99863 20.9676L7.9989 5.96719Z"
        fill={fill}
      />
    </svg>
  )
}
