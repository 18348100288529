import { createMouseEvents, executeOnce, simulateEvents, usePageChecker } from '@libs/utils'
import { useResetContext } from '@providers/resetProvider'
import { useEffect } from 'react'

const mouseEvents = ['mousedown', 'mousemove', 'click']

export const useSimulateFirstInteraction = (clientX?: number, clientY?: number) => {
  const { userInteractedAfterReset } = useResetContext()
  const { isHomePage } = usePageChecker()

  useEffect(() => {
    if (clientX && clientY && userInteractedAfterReset && isHomePage) {
      executeOnce(() => {
        // get  the topmost element
        const element = document.elementFromPoint(clientX, clientY)
        if (element) {
          setTimeout(() => {
            simulateEvents(element, createMouseEvents(mouseEvents))
          }, 0)
        }
      }, 'homepage-first-interaction')()
    }
  }, [clientX, clientY, userInteractedAfterReset, isHomePage])
}
