import styled from 'styled-components'
import { ButtonTypography } from '../Typography'

export const ClearButton = styled(ButtonTypography)`
  padding: 0;
  inline-size: auto;
  overflow: visible;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: normal;
  text-align: inherit;
  background: transparent;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
`
