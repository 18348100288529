import { isHTMLElement } from '@libs/utils'
import { useCallback, useRef } from 'react'

export const useCustomKeydownHandler = (
  cb: (e: KeyboardEvent, targetElement: HTMLElement) => void,
  block?: boolean
) => {
  const refInternal = useRef<HTMLElement | null>(null)
  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (block) return
      if (refInternal.current) {
        refInternal.current.removeEventListener('keydown', handleKeyDown)
      }
      if (!node) return
      node.addEventListener('keydown', handleKeyDown)
      refInternal.current = node
    },
    [cb, block]
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const targetElement = refInternal.current
      if (!isHTMLElement(targetElement)) return
      cb(e, targetElement)
    },
    [cb]
  )

  return { setRef }
}
