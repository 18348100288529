/*global google*/
import { State, StoreCountry } from '@abstractTypes/checkout'
import { ShippingMethodCode } from '@abstractTypes/shipping'
import ConditionalRender from '@components/ConditionalRender'
import { FormInput } from '@components/core/FormInput'
import { FormItem } from '@components/core/FormItem'
import { Select, SelectOption } from '@components/core/Select'
import { Label, Text } from '@components/core/Typography'
import { Log } from '@libs/Log'
import { getLangFromUrl } from '@libs/url'
import { isFuzzyMatch, translateErrorMessage } from '@libs/utils'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import PlacesAutocomplete from 'react-places-autocomplete'
import { FormInputSuggester } from '../FormInputSuggester'
import { ShippingAddressPanelProps, ShippingAddressPanelState } from './types'

const SPECIAL_STATES: string[] = ['PR', 'VI']

// TODO: evaluate in modernizing this component by using functional components and hooks
class ShippingAddressPanel extends React.Component<
  ShippingAddressPanelProps,
  ShippingAddressPanelState
> {
  // static defaultProps: ShippingAddressPanelProps = {
  //   checkStateOnSelect: () => {},
  // }

  state = {
    googleMapsAPILoaded: false,
  }

  geocoder: google.maps.Geocoder | undefined

  onScriptLoad = () => {
    this.geocoder = new window.google.maps.Geocoder()
    this.setState({ googleMapsAPILoaded: true })
  }

  isSpecialUsState() {
    const countryId = this.props.countryId || this.props.states[0].countryId
    return countryId === 'US' && SPECIAL_STATES.includes(this.props.code)
  }

  componentDidMount = () => {
    const { code, states } = this.props
    if (SPECIAL_STATES.includes(code)) {
      const specialState: State | undefined = states.find(s => s.code === code)
      specialState && this.props.setData('state', specialState.id)
    }

    if (!window.google) {
      const { config } = this.props
      const lang = getLangFromUrl()
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = `https://maps.googleapis.com/maps/api/js?key=${
        config.googleMapsApiKey
      }&libraries=places&language=${
        lang
          ? config.googleApiSupportedLangs.indexOf(lang) !== -1
            ? lang
            : lang.split('-')[0]
          : 'en'
      }`
      const x = document.getElementsByTagName('script')[0]
      x && x.parentNode && x.parentNode.insertBefore(s, x)
      s.addEventListener('load', () => this.onScriptLoad())
    } else {
      this.onScriptLoad()
    }
  }

  handleSelect = (address: string) => {
    this.geocoder &&
      this.geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          Log.trace(results ? results.toString() : '')
          if (results && results.length > 0) {
            const addressComponents = ([] as google.maps.GeocoderAddressComponent[]).concat(
              ...results.map<google.maps.GeocoderAddressComponent[]>(
                item => item.address_components
              )
            )

            this.setAddressByGeocode(addressComponents)
            this.setZipByGeocode(addressComponents)
            this.setCityByGeocode(addressComponents)
            this.setStateByGeocode(addressComponents)
            this.props.code &&
              this.props.countryId === 'US' &&
              this.checkIfCaliforniaByGeoCode(addressComponents)
          }
        } else {
          Log.error(status.toString())
        }
      })
  }

  handleZipChange = (zip: string) => {
    const { errors } = this.props
    const invalidZipCode = errors && errors['zip'] !== undefined
    const countryId = this.props.countryId || this.props.states[0].countryId
    const code = this.props.code || this.props.states[0].code

    if (zip && zip.length > 3 && this.geocoder && !invalidZipCode) {
      this.geocoder.geocode(
        {
          componentRestrictions: {
            country: this.isSpecialUsState() ? code : countryId,
            postalCode: zip,
          },
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            Log.trace(results ? results.toString() : '')
            if (results && results.length > 0) {
              const addressComponents = results[0].address_components
              this.setCityByGeocode(addressComponents)
            }
          } else {
            Log.error(status.toString())
          }
        }
      )
    }
  }

  setAddressByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const streetNumber = addressComponents.find(
      comp => comp.types.findIndex(type => type === 'street_number') !== -1
    )
    const route = addressComponents.find(
      comp => comp.types.findIndex(type => type === 'route') !== -1
    )
    const premise = addressComponents.find(
      comp => comp.types.findIndex(type => type === 'premise') !== -1
    )
    let address
    if (streetNumber && route) {
      address = `${streetNumber.long_name} ${route.long_name}`
    } else if (route) {
      address = route.long_name
    } else if (premise) {
      address = premise.long_name
    }
    if (address) {
      this.props.setData('address', address)
    }
  }

  setZipByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const zip = addressComponents.find(
      comp => comp.types.findIndex(type => type === 'postal_code') !== -1
    )
    if (zip) {
      this.props.setData('zip', zip.long_name)
    }
  }

  setCityByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const cityFallback1 = addressComponents.find(
      comp => comp.types.findIndex(type => type === 'postal_town') !== -1 // UK or GB
    )

    const cityFallback2 = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'sublocality') !== -1 &&
        comp.types.findIndex(type => type === 'political') !== -1 &&
        comp.types.findIndex(type => type === 'sublocality_level_1') !== -1
    )

    const cityFallback3 = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'sublocality') !== -1 &&
        comp.types.findIndex(type => type === 'political') !== -1
    )

    const cityFallback4 = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'locality') !== -1 &&
        comp.types.findIndex(type => type === 'political') !== -1
    )

    const cityFallback5 = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'administrative_area_level_3') !== -1 &&
        comp.types.findIndex(type => type === 'political') !== -1
    )

    const city = cityFallback1 || cityFallback2 || cityFallback3 || cityFallback4 || cityFallback5

    if (city) {
      this.props.setData('city', city.long_name)
    }
  }

  setStateByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const country = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'country') !== -1 &&
        comp.types.findIndex(type => type === 'political') !== -1
    )
    let state: google.maps.GeocoderAddressComponent | undefined
    let stateObj: State | undefined

    if (country) {
      state = addressComponents.find(
        comp =>
          comp.types.findIndex(type => type === 'administrative_area_level_1') !== -1 &&
          comp.types.findIndex(type => type === 'political') !== -1
      )
      stateObj = this.props.states.find(st =>
        state
          ? isFuzzyMatch(state.short_name, st.code) || isFuzzyMatch(state.short_name, st.name)
          : false
      )
      if (!stateObj) {
        state = addressComponents.find(
          comp =>
            comp.types.findIndex(type => type === 'administrative_area_level_2') !== -1 &&
            comp.types.findIndex(type => type === 'political') !== -1
        )
        stateObj = this.props.states.find(st =>
          state
            ? isFuzzyMatch(state.short_name, st.code) || isFuzzyMatch(state.short_name, st.name)
            : false
        )
      }
    }

    if (stateObj) {
      this.props.setData('state', stateObj.id)
    }
  }

  checkIfCaliforniaByGeoCode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const state = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'administrative_area_level_1') !== -1 &&
        comp.types.findIndex(type => type === 'political') !== -1
    )

    const country = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'political') !== -1 &&
        comp.types.findIndex(type => type === 'country') !== -1
    )

    if (state && country) {
      const { code, countryId } = this.props

      const storeInfo: StoreCountry = {
        code,
        countryId,
      }

      const stateObj = this.props.states.find(st => st.code === state.short_name)
      stateObj &&
        this.props.checkStateOnSelect &&
        this.props.checkStateOnSelect(stateObj.id, storeInfo, this.props.states)
    }
  }

  render() {
    const {
      t,
      data,
      setData,
      errors,
      states,
      addressGeocode,
      countryId,
      code,
      checkStateOnSelect,
      hidePostcode,
      shippingMethods,
      requiredCountryPostcode,
    } = this.props

    const storeInfo = {
      code,
      countryId,
    }

    let availableStates: State[] = []
    if (countryId === 'US') {
      availableStates = SPECIAL_STATES.includes(code)
        ? states.filter(p => p.code === code)
        : states.filter(p => !SPECIAL_STATES.includes(p.code))
    } else {
      availableStates = [...states]
    }

    if (!this.state.googleMapsAPILoaded) return null

    const shippingCodes = shippingMethods.map(method => method.code)
    const addressDescription =
      shippingCodes.includes(ShippingMethodCode.MILITARY) &&
      shippingCodes.includes(ShippingMethodCode.POBOX)
        ? ''
        : t('CheckoutForm.addressDescription')

    const addressInput = addressGeocode ? (
      <PlacesAutocomplete
        value={(data && data['address']) || ''}
        onChange={v => {
          setData('address', v)
        }}
        onSelect={v => {
          this.handleSelect(v)
        }}
        searchOptions={{
          componentRestrictions: {
            country: this.isSpecialUsState() ? code : countryId,
          },
          types: ['address'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <FormInputSuggester
            name="address"
            value={data && data['address']}
            label={t('CheckoutForm.address')}
            type={'text'}
            required={true}
            placeholder={t('CheckoutForm.addressPlaceholder')}
            hasFeedback={errors && !!errors['address']}
            validateStatus={errors && !!errors['address'] ? 'error' : undefined}
            help={translateErrorMessage(t, 'address', errors)}
            getInputProps={getInputProps}
            suggestions={suggestions}
            getSuggestionItemProps={getSuggestionItemProps}
            loading={loading}
            fieldDescription={addressDescription ? <Label>{addressDescription}</Label> : undefined}
          />
        )}
      </PlacesAutocomplete>
    ) : (
      <FormInput
        name="address"
        value={data && data['address']}
        onChange={v => setData('address', v)}
        label={t('CheckoutForm.address')}
        type="text"
        required={false}
        placeholder={t('CheckoutForm.addressPlaceholder')}
        hasFeedback={errors && !!errors['address']}
        validateStatus={errors && !!errors['address'] ? 'error' : undefined}
        fieldDescription={addressDescription ? <Text>{addressDescription}</Text> : undefined}
        help={translateErrorMessage(t, 'address', errors)}
      />
    )

    return (
      <div role="group" aria-labelledby="shippingPanel">
        {addressInput}
        <FormInput
          name="address1"
          value={data && data['address1']}
          onChange={v => setData('address1', v)}
          label={t('CheckoutForm.address1')}
          type="text"
          required={false}
          placeholder={t('CheckoutForm.address1Placeholder')}
          hasFeedback={errors && !!errors['address1']}
          validateStatus={errors && !!errors['address1'] ? 'error' : undefined}
          help={translateErrorMessage(t, 'address1', errors)}
        />
        <ConditionalRender
          condition={!hidePostcode}
          render={() => (
            <FormInput
              name="zip"
              value={data && data['zip']}
              onChange={v => {
                setData('zip', v)
              }}
              onBlur={v => {
                this.handleZipChange(v)
              }}
              label={t('CheckoutForm.zip')}
              type="text"
              required={requiredCountryPostcode}
              placeholder={t('CheckoutForm.zipPlaceholder')}
              hasFeedback={errors && !!errors['zip']}
              validateStatus={errors && !!errors['zip'] ? 'error' : undefined}
              help={translateErrorMessage(t, 'zip', errors)}
            />
          )}
        />
        <FormInput
          name="city"
          value={data && data['city']}
          onChange={v => setData('city', v)}
          label={t('CheckoutForm.city')}
          type="text"
          required={true}
          placeholder={t('CheckoutForm.cityPlaceholder')}
          hasFeedback={errors && !!errors['city']}
          validateStatus={errors && !!errors['city'] ? 'error' : undefined}
          help={translateErrorMessage(t, 'city', errors)}
        />
        <FormItem
          label={t('CheckoutForm.state')}
          required={requiredCountryPostcode}
          hasFeedback={errors && !!errors.state}
          validateStatus={errors && !!errors.state ? 'error' : undefined}
          help={translateErrorMessage(t, 'state', errors)}
        >
          <Select
            placeholder={t('CheckoutForm.statePlaceholder')}
            onSelect={val => {
              setData('state', val as string)
              code &&
                checkStateOnSelect &&
                checkStateOnSelect(val as string, storeInfo, availableStates)
            }}
            value={data.state}
          >
            {availableStates.map((st, i) => (
              <SelectOption
                key={`${i}-${st.id}`}
                value={st.id}
              >{`${st.name} (${st.code})`}</SelectOption>
            ))}
          </Select>
        </FormItem>
      </div>
    )
  }
}

export default withTranslation('translations')(ShippingAddressPanel)
