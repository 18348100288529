import { Device } from '@abstractTypes/utils'
import { CartSummary } from '@components/Checkout'
import { GoToBagButton, SubtotalSections } from '@components/Checkout/styles'
import { ListItemStyle } from '@components/core/List/ListItem'
import { PanelHeaderTitle, PanelHeaderTitleCount } from '@components/core/PanelHeader/styles'
import { Heading5 } from '@components/core/Typography'
import { Drawer } from '@components/Drawer'
import { ADA_SCREEN_HEIGHT } from '@constants/accessibility'
import {
  altNavigation,
  altNavigationZoom,
  landscape,
  tablet,
  zoomLandscape,
  zoomPortrait,
} from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const MinicartDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  ${zoomPortrait(
    [150, 175],
    `
      inline-size: ${pxToRem(972)};
    `
  )}
  ${zoomPortrait(
    [200],
    `
      inline-size: 100%;
    `
  )}
  ${zoomLandscape(
    [200],
    `
    inline-size: ${pxToRem(1296)};
  `
  )}
`

export const MiniCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  block-size: 100%;
  overflow: auto;
  ${zoomPortrait(
    [150, 175, 200],
    `
    display: block;
    block-size: ${pxToRem(2150)};
  `
  )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    display: block;
    block-size: ${pxToRem(911)};
  `
  )}
${altNavigationZoom(
    [100, 110, 125],
    `
    display: flex;
  flex-direction: column;
    
    ${GoToBagButton} {
      inset-block-start: ${pxToRem(170)};
    }
  `
  )}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    display: block;
    block-size: ${pxToRem(700)};
  `
  )}

  ${GoToBagButton} {
    inset-inline-start: unset;
  }
`

export const MiniCartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  padding-block: 0;
  padding-inline: ${pxToRem(32)};
  align-items: center;
  ${tablet(`
  
  padding-inline:  ${clampValues(24, 32)} ;
   position: static;
  `)}
  ${({ theme }) =>
    altNavigationZoom(
      [150, 175],
      `
  position: fixed;
  z-index: 1;
  background: ${theme.colors.primaryLight}

  `
    )}

  ${({ theme }) =>
    zoomPortrait(
      [200],
      `
      position: fixed;
      z-index: 1;
      background: ${theme.colors.primaryLight}
      `
    )};
`

export const DrawerMiniCartItemsContainer = styled.div`
  block-size: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  inline-size: 100%;
  padding-block-start: ${pxToRem(48)};
  ${tablet(`padding-block-start: ${pxToRem(36)};`)}
  ${zoomPortrait([150, 175], `block-size: ${pxToRem(1340)};`)}
  ${zoomPortrait(
    [200],
    `
    block-size: 100vh;
    padding-block-start: ${pxToRem(640)};
  `
  )}
  ${zoomLandscape(
    [150, 175, 200],
    `
    overflow-y: auto;
  `
  )}
  ${zoomLandscape(
    [200],
    `
    padding-block-start: ${pxToRem(420)};
  `
  )}
  ${altNavigationZoom(
    [100, 110, 125],
    `
    overflow-y: auto;
    block-size: ${ADA_SCREEN_HEIGHT};
    padding-block-start: ${pxToRem(90)};
  `
  )}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    overflow-y: auto;
    block-size: ${ADA_SCREEN_HEIGHT};
    padding-block-start: ${pxToRem(50)};
  `
  )}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    padding-block-start: ${pxToRem(226)};
  `
  )}
`

export const MiniCartPanelHeader = styled.div`
  margin-block-start: ${pxToRem(344)};
  inline-size: 100%;
  ${landscape(`
    margin-block-start: ${pxToRem(96)};
  `)}
  ${tablet(`
    margin-block-start: ${pxToRem(128)};
    position: relative;
  `)}
    ${landscape(
    Device.tablet,
    `
      margin-block-start: ${pxToRem(128)};
      `
  )}
  ${altNavigation(`
    margin-block-start: ${pxToRem(42)};
  `)};
  ${altNavigationZoom(
    [150, 175, 200],
    `
    margin-block-end: ${pxToRem(42)};
  `
  )}
  ${ListItemStyle} {
    margin-block-end: ${pxToRem(48)};

    ${PanelHeaderTitle} {
      display: flex;
      align-items: center;
    }

    ${PanelHeaderTitleCount} {
      margin-inline-start: ${pxToRem(11)};
      ${landscape(`
          margin-inline-start: ${pxToRem(11)};
      `)}
    }
  }
`

export const MiniBagCloseButton = styled.button`
  display: flex;
  align-items: center;
  z-index: 2;
  position: absolute;
  inset-block-start: ${pxToRem(330)};
  inset-inline-end: ${pxToRem(35)};
  padding: ${pxToRem(3)};
  ${tablet(`
    inset-block-start: ${pxToRem(120)};
    inset-inline-end: ${pxToRem(32)}
  `)}
  ${landscape(`
    inset-block-start: ${pxToRem(90)};
    inset-inline-end: ${pxToRem(34)};
  `)}
  ${zoomPortrait(
    [150, 175, 200],
    `
    padding: ${pxToRem(5)};
  `
  )}
  ${({ theme }) =>
    zoomLandscape(
      [150, 175, 200],
      `
      background: ${theme.colors.primaryLight}
      inset-block-start: ${pxToRem(90)};
      inline-size: auto;
      block-size: auto;
      `
    )};
  ${({ theme }) =>
    altNavigationZoom(
      [100, 110, 125, 150, 175, 200],
      `
      background: ${theme.colors.primaryLight}
      inset-block-start: ${pxToRem(42)};
      inline-size: auto;
      block-size: auto;
      `
    )};
`

export const MiniCartEmpty = styled(Heading5)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-block-start: ${pxToRem(112)};
  ${tablet(
    Device.ipadMini,
    `
    margin-block-start: ${pxToRem(72)};
  `
  )}
`

export const CartSummaryMiniCard = styled(CartSummary)`
  ${SubtotalSections} {
    ${zoomPortrait(
      [150, 175, 200],
      `
      border-block-end: none;
      padding: 0;
    `
    )}
  }
`
