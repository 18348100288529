import { useEffect, useState } from 'react'

export const usePortalDiv = (className = '') => {
  const [divEl, setDivEl] = useState<Element>()

  useEffect(() => {
    const portalElement =
      document.querySelector(`.${className}`) ||
      document.body.appendChild(document.createElement('div', {}))
    portalElement.classList.add(className)

    setDivEl(portalElement)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return divEl
}
