import { zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import React from 'react'

import styled from 'styled-components'

const ListItemIconContainer = styled.div`
  min-inline-size: ${pxToRem(48)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${zoomPortrait([125], `min-inline-size: ${pxToRem(60)}`)};
  ${zoomPortrait([150, 175, 200], `min-inline-size: ${pxToRem(84)}`)};
`

export const ListItemIcon: React.FC = ({ children }) => {
  return <ListItemIconContainer>{children}</ListItemIconContainer>
}
