import { StandardProduct } from '@abstractTypes/product'
import { Heading5 } from '@components/core/Typography'
import { NuanceHeading5 } from '@components/core/Typography/NuanceTypography'
import { DetailsList } from '@components/pdp/DetailsPanel/Details'
import { DetailRowContainer } from '@components/pdp/DetailsPanel/Details/styles'
import { zoomLandscape } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

const DetailsListStyled = styled(DetailsList)`
  padding-inline-end: ${pxToRem(54)};
  ${zoomLandscape(
    [200],
    `
      padding-block-start: 0;
  `
  )}

  ${DetailRowContainer} {
    text-align: start;
    margin-block-end: 0;

    ${Heading5} {
      ${NuanceHeading5}
      :last-child {
        color: ${({ theme }) => theme.nuance.colors.inactive};
      }
    }
  }
`

export const DetailsContent: React.FC<{ product: StandardProduct }> = ({ product }) => {
  return <DetailsListStyled product={product} />
}
