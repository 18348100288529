import { IconProps } from '@abstractTypes/icons'
export const FaBridgePositionLowIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 54"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M72.6847 24.9002C72.0347 25.5502 72.0347 26.6002 72.7013 27.2502C73.018 27.5835 73.4513 27.7335 73.868 27.7335C74.3013 27.7335 74.7347 27.5669 75.0513 27.2502C75.7013 26.6002 75.7013 25.5335 75.0513 24.8835C74.3847 24.2335 73.3347 24.2502 72.6847 24.9002Z"
        fill="black"
      />
      <path
        d="M67.7665 28.0342C66.8998 28.3509 66.4665 29.3009 66.7832 30.1676C67.0332 30.8342 67.6665 31.2509 68.3498 31.2509C68.5498 31.2509 68.7332 31.2176 68.9332 31.1509C69.7998 30.8342 70.2332 29.8676 69.8998 29.0009C69.5832 28.1509 68.6332 27.7009 67.7665 28.0342Z"
        fill="black"
      />
      <path
        d="M72.8334 4.35028C73.1668 3.48361 72.7334 2.51695 71.8668 2.20028C71.0168 1.86695 70.0501 2.30028 69.7168 3.16695C69.4001 4.01694 69.8334 4.98361 70.7001 5.31695C70.8834 5.38361 71.0834 5.41694 71.2834 5.41694C71.9501 5.41694 72.5834 5.01694 72.8334 4.35028Z"
        fill="black"
      />
      <path
        d="M45.733 9.11684C46.0163 9.30017 46.333 9.38351 46.6496 9.38351C47.183 9.38351 47.7163 9.11684 48.033 8.63351C48.5496 7.86684 48.333 6.83351 47.5496 6.33351C46.783 5.81684 45.7496 6.03351 45.2496 6.81684C44.7496 7.58351 44.9663 8.61684 45.733 9.11684Z"
        fill="black"
      />
      <path
        d="M78.1667 12.2503C77.2333 12.2503 76.5 13.0003 76.5 13.9169C76.5 13.9336 76.5 13.9336 76.5 13.9336C76.5 13.9836 76.5 14.0169 76.5 14.0503C76.5333 14.9503 77.2667 15.6669 78.1667 15.6669C78.1833 15.6669 78.2 15.6669 78.2167 15.6669C79.15 15.6336 79.8667 14.8669 79.8333 13.9503V13.9336C79.8333 13.9336 79.8333 13.9169 79.8333 13.9003C79.8333 12.9836 79.0833 12.2336 78.1667 12.2503Z"
        fill="black"
      />
      <path
        d="M77.8178 18.9168C76.9511 18.6335 76.0011 19.1001 75.7178 19.9668C75.4178 20.8335 75.8845 21.7835 76.7678 22.0835C76.9345 22.1335 77.1178 22.1668 77.2845 22.1668C77.9845 22.1668 78.6345 21.7335 78.8678 21.0335C79.1678 20.1668 78.7011 19.2168 77.8178 18.9168Z"
        fill="black"
      />
      <path
        d="M76.4677 9.31749C76.7843 9.31749 77.101 9.21749 77.3843 9.03416C78.1677 8.51749 78.3677 7.48416 77.851 6.71749C77.351 5.95082 76.3177 5.75083 75.551 6.25083C74.7843 6.76749 74.5677 7.80082 75.0843 8.56749C75.401 9.05082 75.9343 9.31749 76.4677 9.31749Z"
        fill="black"
      />
      <path
        d="M56.0511 27.8834C55.1844 27.5501 54.2177 27.9667 53.8844 28.8167C53.5511 29.6834 53.9677 30.6501 54.8177 30.9834C54.8344 30.9834 54.8344 30.9834 54.8344 30.9834C55.0344 31.0667 55.2344 31.1001 55.4344 31.1001C56.101 31.1001 56.7344 30.7001 56.9844 30.0501C57.3177 29.1834 56.901 28.2167 56.0511 27.8834Z"
        fill="black"
      />
      <path
        d="M58.0997 0.800824C57.1831 0.867491 56.4997 1.66749 56.5831 2.58416C56.6497 3.46749 57.3664 4.11749 58.2331 4.11749C58.2831 4.11749 58.3164 4.11749 58.3664 4.11749C59.2831 4.05082 59.9664 3.25082 59.8997 2.33416C59.8331 1.40082 59.0164 0.717491 58.0997 0.800824Z"
        fill="black"
      />
      <path
        d="M51.2012 2.23368C50.3345 2.56702 49.9178 3.53368 50.2345 4.38368C50.5012 5.05035 51.1345 5.45035 51.8012 5.45035C52.0012 5.45035 52.2012 5.41701 52.4012 5.33368C53.2512 5.01701 53.6845 4.05035 53.3512 3.18368C53.0178 2.33368 52.0512 1.90035 51.2012 2.23368Z"
        fill="black"
      />
      <path
        d="M61.9004 29.0176C60.9837 29.0176 60.2171 29.7509 60.2171 30.6676C60.2004 31.5842 60.9337 32.3342 61.8504 32.3509C61.8671 32.3509 61.8671 32.3509 61.8837 32.3509C62.7837 32.3509 63.5337 31.6176 63.5504 30.7176C63.5504 29.7842 62.8171 29.0342 61.9004 29.0176Z"
        fill="black"
      />
      <path
        d="M48.7332 24.7337C48.0832 25.3837 48.0998 26.4504 48.7498 27.0837C49.0832 27.4004 49.4998 27.5671 49.9165 27.5671C50.3498 27.5671 50.7832 27.4004 51.1165 27.0671C51.7498 26.4004 51.7498 25.3504 51.0832 24.7004C50.4332 24.0671 49.3665 24.0671 48.7332 24.7337Z"
        fill="black"
      />
      <path
        d="M64.9666 0.783788C64.0499 0.717121 63.2333 1.40045 63.1666 2.31712C63.0999 3.23379 63.7833 4.03379 64.6999 4.11712C64.7499 4.11712 64.7999 4.11712 64.8333 4.11712C65.6999 4.11712 66.4333 3.45045 66.4999 2.58379C66.5666 1.65045 65.8833 0.850454 64.9666 0.783788Z"
        fill="black"
      />
      <path
        d="M4.96695 24.9006C4.30028 25.5506 4.30028 26.6006 4.95028 27.2506C5.28361 27.5839 5.70028 27.7506 6.13361 27.7506C6.56695 27.7506 6.98361 27.5839 7.31695 27.2672C7.96695 26.6172 7.96695 25.5506 7.31695 24.9006C6.66695 24.2506 5.61695 24.2506 4.96695 24.9006Z"
        fill="black"
      />
      <path
        d="M2.18383 18.9336C1.30049 19.2336 0.833827 20.167 1.13383 21.0503C1.36716 21.7336 2.01716 22.1836 2.71716 22.1836C2.88383 22.1836 3.06716 22.1503 3.25049 22.0836C4.11716 21.8003 4.58383 20.8503 4.28383 19.9836C4.00049 19.1003 3.05049 18.6336 2.18383 18.9336Z"
        fill="black"
      />
      <path
        d="M1.83382 15.6835C2.71716 15.6835 3.45049 14.9835 3.50049 14.0835C3.53382 13.1668 2.83382 12.3835 1.91716 12.3501C0.983825 12.3001 0.200491 13.0168 0.167158 13.9335C0.133825 14.8501 0.833825 15.6335 1.75049 15.6835C1.78382 15.6835 1.80049 15.6835 1.83382 15.6835Z"
        fill="black"
      />
      <path
        d="M21.7839 0.783788C20.8673 0.717121 20.0673 1.40045 20.0006 2.31712C19.9339 3.23379 20.6173 4.03379 21.5339 4.11712C21.5839 4.11712 21.6173 4.11712 21.6673 4.11712C22.5339 4.11712 23.2673 3.45045 23.3339 2.58379C23.4006 1.65045 22.7006 0.850454 21.7839 0.783788Z"
        fill="black"
      />
      <path
        d="M18.1004 29.0176C17.1837 29.0342 16.4504 29.8009 16.4671 30.7176C16.4837 31.6342 17.2337 32.3676 18.1504 32.3509C19.0671 32.3509 19.8171 31.5842 19.8004 30.6676C19.7837 29.7509 19.0337 29.0176 18.1004 29.0176Z"
        fill="black"
      />
      <path
        d="M12.2329 28.0341C11.3829 27.7175 10.4162 28.1508 10.0996 29.0175C9.7829 29.8675 10.2162 30.8341 11.0829 31.1508C11.2662 31.2175 11.4662 31.2508 11.6662 31.2508C12.3329 31.2508 12.9662 30.8508 13.2162 30.1675C13.5496 29.3175 13.0996 28.3508 12.2329 28.0341Z"
        fill="black"
      />
      <path
        d="M14.9343 0.800682C14.0176 0.867349 13.3343 1.66735 13.401 2.58402C13.4843 3.46735 14.201 4.11735 15.0676 4.11735C15.1176 4.11735 15.151 4.11735 15.201 4.11735C16.1176 4.05068 16.801 3.25068 16.7343 2.33402C16.651 1.41735 15.851 0.717349 14.9343 0.800682Z"
        fill="black"
      />
      <path
        d="M10.1841 3.18368C9.8508 2.33368 8.88413 1.90035 8.03413 2.23368C7.16747 2.56702 6.73413 3.53368 7.06747 4.38368C7.33413 5.05035 7.96747 5.45035 8.63413 5.45035C8.83413 5.45035 9.03413 5.41701 9.23413 5.35035C10.0841 5.01701 10.5175 4.05035 10.1841 3.18368Z"
        fill="black"
      />
      <path
        d="M30.0845 27.5509C30.5178 27.5509 30.9345 27.4009 31.2511 27.0843C31.9178 26.4343 31.9178 25.3843 31.2845 24.7176C30.6345 24.0676 29.5845 24.0509 28.9178 24.7009C28.2678 25.3509 28.2511 26.4009 28.9011 27.0509C29.2178 27.3843 29.6511 27.5509 30.0845 27.5509Z"
        fill="black"
      />
      <path
        d="M23.967 27.8834C23.1003 28.2167 22.6837 29.1834 23.017 30.0501C23.2837 30.7001 23.9003 31.1001 24.567 31.1001C24.7837 31.1001 24.9837 31.0667 25.1837 30.9834C26.0337 30.6501 26.467 29.6834 26.1337 28.8167C25.7837 27.9667 24.817 27.5501 23.967 27.8834Z"
        fill="black"
      />
      <path
        d="M33.2997 9.30036C33.6164 9.30036 33.933 9.21703 34.2164 9.03369C34.9997 8.51703 35.1997 7.48369 34.683 6.71702C34.1664 5.95036 33.1497 5.73369 32.383 6.25036C31.6164 6.76703 31.3997 7.80036 31.9164 8.56703C32.233 9.05036 32.7664 9.30036 33.2997 9.30036Z"
        fill="black"
      />
      <path
        d="M2.56696 9.11748C2.85029 9.30081 3.16696 9.38415 3.46696 9.38415C4.01696 9.38415 4.55029 9.11748 4.86696 8.63415C5.38363 7.86748 5.15029 6.83415 4.38363 6.33415C3.61696 5.81748 2.58363 6.05082 2.08363 6.81748C1.58362 7.58415 1.80029 8.61748 2.56696 9.11748Z"
        fill="black"
      />
      <path
        d="M29.6676 4.33356C30.0009 3.48356 29.5676 2.51689 28.7009 2.18355C27.8343 1.86689 26.8843 2.30022 26.5509 3.16689C26.2343 4.01689 26.6676 4.98356 27.5176 5.31689C27.7176 5.38356 27.9176 5.41689 28.1176 5.41689C28.7843 5.41689 29.4176 5.00022 29.6676 4.33356Z"
        fill="black"
      />
      <path
        d="M41.6673 46.6437V30.667H38.3339V46.6437L31.1789 39.4887L28.8223 41.8453L40.0006 53.0237L51.1789 41.8453L48.8223 39.4887L41.6673 46.6437Z"
        fill="black"
      />
      <path
        d="M39.9997 14C36.9897 14 34.0564 15.05 31.7347 16.9583C31.0247 17.5433 30.9214 18.5933 31.5064 19.305C32.0914 20.015 33.143 20.1183 33.853 19.5333C37.303 16.695 42.698 16.695 46.148 19.5333C46.458 19.7883 46.833 19.9133 47.2064 19.9133C47.688 19.9133 48.1647 19.705 48.4947 19.305C49.0797 18.5933 48.9764 17.5433 48.2664 16.9583C45.943 15.05 43.0097 14 39.9997 14Z"
        fill="black"
      />
    </svg>
  )
}
