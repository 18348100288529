import { ProductHierarchies, ProductSubdivisions, ProductTypeValues } from '@abstractTypes/filter'
import {
  AvailableInStore,
  AvailableInStoreSearchPageTypes,
  AvailableInStoreTypes,
  ToggleAvailableInStore,
  ToggleAvailableInStoreSearchPage,
  availableInStoreSearchPageProductHierarchies,
  availableInStoreSearchPageProductTypes,
  toggleAvailableInStore,
  toggleAvailableInStoreForced,
  toggleAvailableInStoreSearchPage,
} from '@actions/filters'
import config from '@config/index'
import { useFiltersFromUrl } from '@hooks/useFilters'
import { useStoreContext } from '@hooks/useStoreContext'
import { equals, findParameterCaseInsentitive } from '@libs/caseInsensitive'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from './useActions'

type MergedAvailableInStoreTypes = Partial<
  Record<AvailableInStoreTypes | AvailableInStoreSearchPageTypes, boolean>
>
type ToggleFunction = (
  productSubdivision: ProductSubdivisions,
  forcedValue: boolean
) => ToggleAvailableInStore | ToggleAvailableInStoreSearchPage

const checkAvailableInStoreSearchPageProductSubdivision = (
  productSubdivision?: ProductSubdivisions
): boolean => {
  return (
    (productSubdivision &&
      (availableInStoreSearchPageProductTypes.some(item => equals(item, productSubdivision)) ||
        availableInStoreSearchPageProductHierarchies.some(item =>
          equals(item, productSubdivision)
        ))) ||
    false
  )
}

const useAvailableInStore = (
  productSubdivision?: ProductSubdivisions
): {
  availableInStore: MergedAvailableInStoreTypes
  toggleAvailableInStore: ToggleFunction
} => {
  const isProductSubdivisionSearchPage = useMemo(() => {
    return checkAvailableInStoreSearchPageProductSubdivision(productSubdivision)
  }, [productSubdivision])

  const { availableInStore, availableInStoreSearchPage, isForcedFiltersState, forcedState } =
    useSelector(state => state.filters)

  const actions = useActions({
    toggleAvailableInStore,
    toggleAvailableInStoreForced,
    toggleAvailableInStoreSearchPage,
  })

  const state = useMemo(() => {
    return isProductSubdivisionSearchPage
      ? availableInStoreSearchPage
      : isForcedFiltersState
        ? forcedState.availableInStore
        : availableInStore
  }, [
    availableInStoreSearchPage,
    isForcedFiltersState,
    isProductSubdivisionSearchPage,
    availableInStore,
    forcedState,
  ])

  const toggle = (productSubdivision: ProductSubdivisions, forcedValue: boolean) => {
    return isProductSubdivisionSearchPage
      ? actions.toggleAvailableInStoreSearchPage(
          productSubdivision as AvailableInStoreSearchPageTypes
        )
      : isForcedFiltersState
        ? actions.toggleAvailableInStoreForced(productSubdivision as AvailableInStoreTypes)
        : actions.toggleAvailableInStore(productSubdivision as AvailableInStoreTypes, forcedValue)
  }

  return {
    availableInStore: state,
    toggleAvailableInStore: toggle,
  }
}

const useAvailableInStoreDefault = (): AvailableInStore => {
  const { productSubdivision } = config.default
  const { defaultAvailableInStoreOpt, defaultAvailableInStoreSun } = useStoreContext()

  return productSubdivision === 'productHierarchy'
    ? {
        [ProductHierarchies.SUNGLASSES]: defaultAvailableInStoreSun,
        [ProductHierarchies.EYEGLASSES]: defaultAvailableInStoreOpt,
      }
    : {
        [ProductTypeValues.SUN]: defaultAvailableInStoreSun,
        [ProductTypeValues.OPTICAL]: defaultAvailableInStoreOpt,
      }
}

export const useAvailableInStoreProductSubdivision = (
  productSubdivision?: ProductSubdivisions,
  fromSearchPage = false
) => {
  const filtersFromUrl = useFiltersFromUrl()
  const { availableInStore, toggleAvailableInStore } = useAvailableInStore(productSubdivision)

  const isProductSubdivisionSearchPage = useMemo(() => {
    return checkAvailableInStoreSearchPageProductSubdivision(productSubdivision)
  }, [productSubdivision])

  const availableInStoreDefault = useAvailableInStoreDefault()

  const availableInStoreComputed = useMemo(() => {
    if (!fromSearchPage && isProductSubdivisionSearchPage) {
      return !!filtersFromUrl['storeAvailable']
    }

    // if the first check in undefined we use the defaults from the store
    return (
      findParameterCaseInsentitive(availableInStore, productSubdivision) ??
      findParameterCaseInsentitive(availableInStoreDefault, productSubdivision) ??
      false
    )
  }, [
    filtersFromUrl,
    isProductSubdivisionSearchPage,
    fromSearchPage,
    productSubdivision,
    availableInStore,
    availableInStoreDefault,
  ])

  return {
    availableInStore: availableInStoreComputed,
    toggleAvailableInStore: () =>
      productSubdivision && toggleAvailableInStore(productSubdivision, !availableInStoreComputed),
  }
}
