import { Device } from '@abstractTypes/utils'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const BestMatchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-block: ${pxToRem(24)};
  margin-inline: 0;

  ${tablet(`
  margin-block: ${clampValues(18, 24)};
  margin-inline:  0;
  `)}
  ${landscape(
    Device.tablet,
    `
  margin-block: ${clampValuesLandscape(18, 24)};
  margin-inline: 0;
  `
  )}
`

export const BestMatchBadge = styled.div<{ isCapitalized?: boolean }>`
  background-color: ${({ theme }) => theme.colors.highlight04};
  padding-block: ${pxToRem(5)};
  padding-inline: ${pxToRem(11)};
  min-inline-size: 5.46rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};
  color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: ${pxToRem(48)};
  ${({ isCapitalized }) => isCapitalized && 'text-transform: capitalize;'}

  & svg {
    margin-inline-end: 0.36rem;
  }

  ${tablet(`
    padding-block: ${clampValues(1, 3)};
    padding-inline: ${clampValues(8, 9)};
    font-size: ${clampValues(12, 14)};
    line-height: ${clampValues(18, 20)};
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-block: ${clampValuesLandscape(1, 3)};
    padding-inline: ${clampValuesLandscape(8, 9)};
    font-size: ${clampValues(12, 14)};
    line-height: ${clampValues(18, 20)};
  `
  )}
`
