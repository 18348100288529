import { ButtonProps } from '@abstractTypes/components'
import { useHistory } from 'react-router-dom'
import {
  PrimaryButtonDark,
  PrimaryButtonLight,
  SecondaryButtonDark,
  SecondaryButtonLight,
} from './styles'

const variantMap = {
  primary_dark: PrimaryButtonDark,
  primary_light: PrimaryButtonLight,
  secondary_dark: SecondaryButtonDark,
  secondary_light: SecondaryButtonLight,
}

export const PrimaryButton = ({
  children,
  fullWidth = false,
  disabled = false,
  variant = 'dark',
  startIcon,
  endIcon,
  onClick,
  className,
  navigateTo,
  active,
  disablePointerEvents,
  dataAttrs,
  dataCalls,
  ariaAttrs,
  textUppercase,
  as,
  to,
  tabIndex,
}: ButtonProps) => {
  const {
    id: dataId,
    description: dataDescription,
    reset: dataReset,
    test: dataTest,
  } = dataAttrs || {}
  const { label: ariaLabel, description: ariaDescription, disabled: ariaDisabled } = ariaAttrs || {}
  const history = useHistory()
  const VariantButton = variantMap[`primary_${variant}`]
  const startIconComponent = startIcon && <>{startIcon}</>
  const endIconComponent = endIcon && <>{endIcon}</>

  const handleClick = () => {
    navigateTo && history.push(navigateTo)
    return onClick && onClick()
  }

  return (
    <VariantButton
      active={active}
      onClick={handleClick}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      disablePointerEvents={disablePointerEvents}
      data-element-id={dataId}
      data-description={dataDescription}
      data-analytics_available_call={dataCalls}
      data-reset={dataReset}
      data-test={dataId || dataTest}
      aria-label={ariaLabel}
      aria-description={ariaDescription}
      aria-disabled={ariaDisabled}
      textUppercase={textUppercase}
      as={as}
      to={to}
      tabIndex={tabIndex}
    >
      {startIconComponent}
      {children}
      {endIconComponent}
    </VariantButton>
  )
}

export const SecondaryButton = ({
  children,
  fullWidth = false,
  disabled = false,
  variant = 'dark',
  startIcon,
  endIcon,
  onClick,
  className,
  navigateTo,
  active,
  disablePointerEvents,
  dataAttrs,
  dataCalls,
  ariaAttrs,
  textUppercase,
  as,
  to,
  tabIndex,
}: ButtonProps) => {
  const {
    id: dataId,
    description: dataDescription,
    reset: dataReset,
    test: dataTest,
  } = dataAttrs || {}
  const { label: ariaLabel, description: ariaDescription, disabled: ariaDisabled } = ariaAttrs || {}
  const history = useHistory()
  const VariantButton = variantMap[`secondary_${variant}`]
  const startIconComponent = startIcon && <>{startIcon}</>
  const endIconComponent = endIcon && <>{endIcon}</>

  const handleClick = () => {
    navigateTo && history.push(navigateTo)
    return onClick && onClick()
  }

  return (
    <VariantButton
      active={active}
      onClick={handleClick}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      variant={variant}
      disablePointerEvents={disablePointerEvents}
      data-element-id={dataId}
      data-description={dataDescription}
      data-analytics_available_call={dataCalls}
      data-reset={dataReset}
      data-test={dataTest || dataId}
      aria-label={ariaLabel}
      aria-description={ariaDescription}
      aria-disabled={ariaDisabled}
      textUppercase={textUppercase}
      as={as}
      to={to}
      tabIndex={tabIndex}
    >
      {startIconComponent}
      {children}
      {endIconComponent}
    </VariantButton>
  )
}
