import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const LoveHateBtnContainer = styled.div`
  position: absolute;
  inset-inline-end: ${pxToRem(10)};
  inset-block-end: ${pxToRem(10)};
  display: flex;
  z-index: 3;
`
