import { AppConfigOverride } from '@abstractTypes/envs'

const getTestEnvVariables = (): AppConfigOverride => {
  const devTestVariables = {
    fsaEnv: 'test',
  }

  return devTestVariables
}

export default getTestEnvVariables
