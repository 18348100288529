import { IconProps } from '@abstractTypes/icons'
export const CategoryBaseballIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      stroke={stroke}
      className={className}
    >
      <g clipPath="url(#a)" fill={fill}>
        <path d="M37 30c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm-5 7c0-1.119.383-2.143 1.006-2.977.638.857.994 1.9.994 2.977s-.356 2.12-.994 2.977A4.957 4.957 0 0 1 32 37zm2.5 4.305A6.994 6.994 0 0 0 36 37a6.994 6.994 0 0 0-1.5-4.305A4.946 4.946 0 0 1 37 32c.914 0 1.76.265 2.499.695a6.994 6.994 0 0 0-1.5 4.305c0 1.569.542 3.081 1.5 4.305a4.946 4.946 0 0 1-2.5.695 4.946 4.946 0 0 1-2.498-.695zm6.494-1.328A4.992 4.992 0 0 1 40 37c0-1.077.356-2.12.994-2.977A4.957 4.957 0 0 1 42 37a4.957 4.957 0 0 1-1.006 2.977zM42.169 0a5.834 5.834 0 0 0-4.358 1.957L15.293 27.293 2 40.586l-.293-.293a.999.999 0 1 0-1.414 1.414l6 6a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L7.414 46l13.25-13.252 25.379-22.559A5.835 5.835 0 0 0 48 5.831 5.838 5.838 0 0 0 42.169 0zM16.437 34.149l-1.293-3.88.855-.855L18.586 32l-2.15 2.149zm-4 4-1.293-3.88 2.419-2.419 1.293 3.88-2.42 2.419zm-4 4L7.144 38.27l2.419-2.419 1.293 3.88-2.42 2.418zM3.414 42l2.149-2.149 1.293 3.879-.856.856L3.414 42zm41.3-33.306L20.042 30.628l-2.668-2.668L39.306 3.286A3.834 3.834 0 0 1 42.169 2a3.835 3.835 0 0 1 3.83 3.831 3.834 3.834 0 0 1-1.285 2.863z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={fill} d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
