import { debounce } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { StyledScrollableTabContent } from './styles'
import { ScrollableTabContentProps } from './types'

const SHADOW_HEIGHT = 66
const MIN_DIFFERENCE_IN_HEIGHT = 5

const getShadowHeight = (scrollHeight: number) => {
  return scrollHeight > MIN_DIFFERENCE_IN_HEIGHT ? SHADOW_HEIGHT : 0
}

export const ScrollableTabContent: React.FC<ScrollableTabContentProps> = ({
  children,
  className,
  withShadow = true,
}) => {
  const contentElement = useRef<HTMLDivElement>(null)
  const [topShadowHeight, setTopShadowHeight] = useState(0)
  const [bottomShadowHeight, setBottomShadowHeight] = useState(0)

  useEffect(() => {
    const countScroll = debounce((element: HTMLDivElement) => {
      const scrollTopHeight = element.scrollTop
      const bottomRestHeight = element.scrollHeight - scrollTopHeight - element.clientHeight
      setTopShadowHeight(withShadow ? getShadowHeight(scrollTopHeight) : 0)
      setBottomShadowHeight(withShadow ? getShadowHeight(bottomRestHeight) : 0)
    }, 50)

    const element = contentElement?.current
    let resizeObserver: ResizeObserver
    if (element) {
      resizeObserver = new ResizeObserver(() => {
        setBottomShadowHeight(getShadowHeight(element.scrollHeight - element.clientHeight))
      })
      // to set bottom shadow if content is scrollable
      resizeObserver.observe(element)
      element.addEventListener('scroll', () => countScroll(element))
    }
    return () => {
      element?.removeEventListener('scroll', () => countScroll(element))
      element && resizeObserver?.unobserve(element)
    }
  }, [withShadow])

  return (
    <StyledScrollableTabContent
      topContent={topShadowHeight}
      bottomContent={bottomShadowHeight}
      ref={contentElement}
      className={className}
    >
      {children}
    </StyledScrollableTabContent>
  )
}
