import { Spin } from '@components/Loading'
import { useImageRatio } from '@hooks/useImageRatio'
import React from 'react'
import styled from 'styled-components'
import useImage from 'use-image'
import { ContentImageProps, ImageProps } from './types'

export const Skeleton = styled.div<ImageProps & { withBackground: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  inset: 0;
  overflow: hidden;
  block-size: ${({ height }) => height};
  inline-size: ${({ width }) => width};
  min-block-size: ${({ height }) => height};
  min-inline-size: ${({ width }) => width};
  color: ${({ theme }) => theme.colors.primaryDark};
  ${({ withBackground }) =>
    withBackground &&
    `
    &::before {
      content: ' ';
      position: absolute;
      inset: 0;
      block-size: 100%;
      inline-size: 100%;
      background: linear-gradient(to right, #f6f6f6 25%, #eeeeee 50%, #f6f6f6 100%);
    }
    
  `}
`

export const Image = styled(({ ...props }) => (
  <img loading={props.loading || 'lazy'} {...props} />
))<ImageProps>`
  block-size: ${({ height }) => height};
  inline-size: ${({ width }) => width};
  object-fit: cover;
`

export const ContentImage: React.FC<ContentImageProps> = ({
  imageSource,
  showSkeleton = true,
  nativeLazy = 'lazy',
  className = '',
  ratio,
  width = '100%',
  height = '100%',
  skipLazyLoading = false,
  fallbackImage,
  ...props
}) => {
  const imageRatio = useImageRatio(ratio)
  const src = typeof imageSource === 'string' ? imageSource : imageSource?.cropImage?.[imageRatio]
  const [contentImage, status] = useImage(src ?? '')

  const renderSkeleton = (content?: React.ReactElement) => (
    <Skeleton width={width} height={height} className={className} withBackground={!content}>
      {content}
    </Skeleton>
  )

  if (!src) return skipLazyLoading ? null : renderSkeleton(fallbackImage)

  if (skipLazyLoading) {
    return (
      <Image
        loading={nativeLazy}
        className={className}
        src={src}
        width={width}
        height={height}
        alt=""
        {...props}
      />
    )
  }

  switch (status) {
    case 'loaded':
      return (
        <Image
          className={className}
          src={contentImage?.src}
          loading={nativeLazy}
          width={width}
          height={height}
          alt=""
          {...props}
        />
      )
    case 'loading':
      return showSkeleton ? renderSkeleton(<Spin />) : null
    case 'failed':
      return renderSkeleton(fallbackImage)
    default:
      return null
  }
}
