import { Image } from '@components/Image'
import config from '@config/index'
import { LogoProps } from './types'

export const Logo: React.FC<LogoProps> = ({ logoColor = 'default', hideLogo = false, logoUrl }) => {
  const baseLogoPath = `${config.assets.URL}/logos/${config.shopperSlug}/logo-${logoColor}.svg`
  const logoPath = logoUrl || baseLogoPath

  if (hideLogo) return null

  return <Image src={logoPath} height="100%" alt={`Logo ${config.shopperSlug}`} />
}
