import { Product } from '@abstractTypes/product'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export const useCustomerOrder = () => {
  const [isCustomerOrderModalVisible, toggleCustomerOrderModalVisible] = useState(false)
  const [product, setProduct] = useState<Product>()

  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)

  const handleToggleVisible = () => {
    toggleCustomerOrderModalVisible(v => !v)
  }

  const isCompletePairOrderType = useSelector(s => s.customerOrder.orderType) === 'COMPLETE_PAIR'
  const isFrameOderType = useSelector(s => s.customerOrder.orderType) === 'FRAME'

  return {
    isCustomerOrderModalVisible,
    setProduct,
    handleToggleVisible,
    product,
    isCustomerOrder,
    isCompletePairOrderType,
    isFrameOderType,
  }
}

export const useRedirectToCustomerOrderApp = () => {
  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)
  const redirectToCustomerOrderApp = () => {
    if (isCustomerOrder) {
      window.location.replace('ciao-wrapper://')
    }
  }
  return redirectToCustomerOrderApp
}
