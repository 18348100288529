import { Heading4, Heading5 } from '@components/core/Typography'
import config from '@config/index'
import { usePrivacyPolicy } from '@data'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { altNavigationZoom } from '@libs/media'
import { pxToRem } from '@libs/styled'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ConditionalRender from './ConditionalRender'
import { TextModalStyled } from './core/Modal/textModalStyles'

const PrivacyModalStyled = styled(TextModalStyled)`
  ${altNavigationZoom(
    [100, 110, 125, 150, 175, 200],
    `
    inset-block-start: ${pxToRem(75)};
  `
  )}
  table {
    inline-size: 100%;
  }
`

interface PrivacyPolicyModalProps {
  className?: string
  visible: boolean
  onCancel: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({
  className,
  visible,
  onCancel,
}) => {
  const { basePath } = useStoreIndentity()
  const { t } = useTranslation()
  const [loadData, { data }] = usePrivacyPolicy(basePath)

  useEffect(() => {
    if (visible) {
      loadData()
    }
  }, [loadData, visible])

  return (
    <PrivacyModalStyled
      visible={visible}
      centeredContent={false}
      centeredTitle={false}
      fixedHeader
      onCancel={onCancel}
      centered={true}
      title={
        <Heading4 textSize="heading4">
          {t('Checkout.modalHeaderPolicy', { name: config.shopperName })}
        </Heading4>
      }
    >
      <div id="privacy-policy-content" className={className}>
        <ConditionalRender
          condition={data?.privacyPolicy}
          render={privacyPolicy => <Heading5 dangerouslySetInnerHTML={{ __html: privacyPolicy }} />}
        />
      </div>
    </PrivacyModalStyled>
  )
}
