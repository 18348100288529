import { IconProps } from '@abstractTypes/icons'


export const FaceScanAppConnectedIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      transform="translate(-5 -3)"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11.445 4.978h16.15c.83 0 1.439.663 1.439 1.438v23.672c0 .774-.664 1.438-1.438 1.438h-16.15a1.464 1.464 0 0 1-1.439-1.438V6.416c0-.775.609-1.438 1.438-1.438Zm16.15 1.106h-16.15c-.22 0-.332.166-.332.332V26.16h16.814V6.416a.357.357 0 0 0-.331-.332Zm0 24.336h-16.15a.357.357 0 0 1-.332-.332v-2.82h16.87v2.82c-.056.166-.166.332-.387.332Zm-8.406-2.323h1.161c.277 0 .553.221.553.553 0 .332-.221.553-.553.553h-1.161c-.332 0-.553-.221-.553-.553 0-.332.22-.553.553-.553Z"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth=".667"
        d="M21.537 13.894c-1.567-1.527-3.439-2.334-5.634-2.37-.146-.002-.254.048-.295.192-.038.133.022.23.137.298.045.026.094.026.144.026 1.605.02 3.075.478 4.364 1.43 1.997 1.474 3.046 3.467 3.152 5.953l.004.148c.009.174.111.281.263.277.152-.004.252-.113.253-.287a8.014 8.014 0 0 0-2.272-5.55l-.116-.117Z"
      />
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth=".667"
        d="M20.099 15.35c-1.141-1.1-2.5-1.7-4.094-1.753a.603.603 0 0 0-.248.03c-.12.047-.197.114-.176.253.015.134.129.235.264.234a5.334 5.334 0 0 1 1.62.247c2.26.68 3.857 2.806 3.882 5.165.002.207.098.325.262.325.165 0 .265-.124.258-.342-.042-1.617-.64-2.997-1.768-4.159Z"
      />
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth=".667"
        d="M18.652 16.812c-.757-.731-1.658-1.127-2.714-1.161-.14-.005-.259.025-.321.16-.086.185.055.357.292.36.568.01 1.109.14 1.6.427 1.13.658 1.73 1.645 1.783 2.955.006.145.058.25.202.29.127.036.22-.023.287-.13.033-.052.033-.113.033-.172-.03-1.061-.427-1.967-1.162-2.729Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M17.82 18.897c.138.314-.113.63-.457.63h-.66a.667.667 0 0 1-.667-.667v-.511c0-.421.388-.73.764-.542.196.098.398.227.563.392.204.204.352.463.456.698Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
