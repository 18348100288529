import { IconProps } from '@abstractTypes/icons'

export const FaceShapeSquareIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        d="M41 44.25H7C5.35 44.25 4 42.9 4 41.25V7.25C4 5.6 5.35 4.25 7 4.25H41C42.65 4.25 44 5.6 44 7.25V41.25C44 42.9 42.65 44.25 41 44.25ZM7 6.25C6.45 6.25 6 6.7 6 7.25V41.25C6 41.8 6.45 42.25 7 42.25H41C41.55 42.25 42 41.8 42 41.25V7.25C42 6.7 41.55 6.25 41 6.25H7Z"
        fill={stroke}
      />
    </svg>
  )
}
