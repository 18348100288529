import { IconProps } from '@abstractTypes/icons'

export const SquareIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 0H28C26.895 0 26 0.895 26 2V5.363C24.711 4.919 23.288 4.919 22 5.363V2C22 0.895 21.105 0 20 0H2C0.895 0 0 0.895 0 2V20C0 21.105 0.895 22 2 22H20C21.105 22 22 21.105 22 20V7.549C23.223 6.85 24.777 6.85 26 7.55V20C26 21.105 26.895 22 28 22H46C47.105 22 48 21.105 48 20V2C48 0.895 47.105 0 46 0ZM20 20H2V2H20V20ZM28 20H46V2H28V20Z"
        fill={fill}
      />
    </svg>
  )
}
