import { IconProps } from '@abstractTypes/icons'


export const RefreshIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 24.6667H27.8273C25.068 28.4427 20.7333 30.6667 16 30.6667C7.91267 30.6667 1.33333 24.0873 1.33333 16H0C0 24.822 7.178 32 16 32C21.0313 32 25.6487 29.696 28.6667 25.768V32H30V23.3333H21.3333V24.6667Z"
        fill={fill}
      />
      <path
        d="M16 0C10.9687 0 6.352 2.304 3.33333 6.232V0H2V8.66667H10.6667V7.33333H4.172C6.932 3.55733 11.2667 1.33333 16 1.33333C24.0873 1.33333 30.6667 7.91267 30.6667 16H32C32 7.178 24.822 0 16 0Z"
        fill={fill}
      />
    </svg>
  )
}
