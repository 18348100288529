import { IconProps } from '@abstractTypes/icons'

export const FaceShapeTriangleWomanIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <g clipPath="url(#clip0_1874_61809)">
        <path
          d="M47.707 36.543C45.736 34.572 45.001 26.117 45 21.25C45 9.67 35.58 0.25 24 0.25C12.42 0.25 3 9.67 3 21.25C2.999 26.117 2.264 34.572 0.293 36.543L0 36.836V37.25C0 43.315 4.935 48.25 11 48.25C14.218 48.25 17.109 46.852 19.122 44.642C20.529 45.68 22.219 46.25 24 46.25C25.781 46.25 27.471 45.68 28.878 44.642C30.891 46.852 33.782 48.25 37 48.25C43.065 48.25 48 43.315 48 37.25V36.836L47.707 36.543ZM14.551 37.387C12.834 35.67 11.69 33.5 11.242 31.114L9.195 20.198C14.841 19.701 18.935 15.746 20.404 12.06C22.402 14.523 27.227 18.085 38.828 20.071L36.758 31.113C36.31 33.5 35.166 35.67 33.449 37.387L28.414 42.422C27.235 43.601 25.667 44.25 24 44.25C22.333 44.25 20.765 43.601 19.586 42.422L14.551 37.387ZM37 46.25C34.37 46.25 32.007 45.109 30.36 43.304L34.863 38.801C36.866 36.798 38.201 34.267 38.724 31.482L40.983 19.435L40.156 18.263C22.377 15.455 20.999 9.151 20.99 9.108L19 9.25C19 12.79 14.276 18.25 8 18.25L7.017 19.434L9.276 31.482C9.798 34.267 11.133 36.797 13.137 38.801L17.64 43.304C15.993 45.109 13.63 46.25 11 46.25C6.161 46.25 2.202 42.412 2.007 37.62C4.914 33.984 5 22.579 5 21.25C5 10.773 13.523 2.25 24 2.25C34.477 2.25 43 10.773 43 21.25C43 22.579 43.086 33.984 45.993 37.62C45.798 42.412 41.839 46.25 37 46.25Z"
          fill="#333333"
        />
        <path
          d="M26.4324 23.3066H24.4324V31.2496H21.9844V33.2496H26.4324V23.3066Z"
          fill="#333333"
        />
        <path
          d="M21.8775 38.371C22.4445 38.938 23.1975 39.25 23.9995 39.25C24.8015 39.25 25.5545 38.938 26.1215 38.371L27.3125 37.179L23.9995 36.208L20.6875 37.178L21.8775 38.371Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61809">
          <rect width={48} height={48} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
