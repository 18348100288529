import { IconProps } from '@abstractTypes/icons'

export const AlertTriangleIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12.5 8H11.5V17H12.5V8Z" fill={fill} />
      <path d="M12.5 19H11.5V20H12.5V19Z" fill={fill} />
      <path
        d="M23.3886 21.9705L12.9451 1.084C12.7506 0.6945 12.3751 0.5 12.0001 0.5C11.6251 0.5 11.2496 0.6945 11.0551 1.084L0.611589 21.9705C0.260589 22.673 0.771089 23.5 1.55709 23.5H22.4436C23.2291 23.5 23.7401 22.673 23.3886 21.9705ZM22.4916 22.473C22.4751 22.5 22.4566 22.5 22.4431 22.5H1.55709C1.54359 22.5 1.52559 22.5 1.50859 22.473C1.49209 22.446 1.50009 22.4295 1.50609 22.418L11.9491 1.5315C11.9576 1.5155 11.9651 1.5 12.0001 1.5C12.0351 1.5 12.0426 1.5155 12.0511 1.5315L22.4941 22.418C22.5001 22.4295 22.5081 22.446 22.4916 22.473Z"
        fill={fill}
      />
    </svg>
  )
}
