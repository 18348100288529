import { AppRouteActions } from '@components/AppRouteActions'
import { BarcodeActions } from '@components/AppRouteActions/BarcodeScanActions'
import { FrameAdvisorRouteActions } from '@components/FrameAdvisor'
import NuanceHome from '@containers/NuanceHome'
import SupernovaHome from '@containers/SupernovaHome'
import { useStoreContext } from '@hooks/useStoreContext'
import React, { Fragment } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

const Home = React.lazy(() => import('@containers/Home'))
const ProductsList = React.lazy(() => import('@containers/ProductsList'))
const ProductDetails = React.lazy(() => import('@containers/ProductDetails'))
const Wishlist = React.lazy(() => import('@containers/Wishlist'))
const Brands = React.lazy(() => import('@containers/Brands'))
const Cart = React.lazy(() => import('@containers/Cart'))
const Checkout = React.lazy(() => import('@containers/Checkout'))
const ThankYou = React.lazy(() => import('@containers/ThankYou'))
const FrameAdvisorSurvey = React.lazy(() => import('@containers/FrameAdvisorSurvey'))
const FrameAdvisorFaceappConnect = React.lazy(
  () => import('@containers/FrameAdvisorFaceappConnect')
)
const FrameAdvisorFaceappLogout = React.lazy(() => import('@containers/FrameAdvisorFaceappLogout'))
const FrameAdvisorPlaylist = React.lazy(() => import('@containers/FrameAdvisorPlaylist'))
const FrameAdvisorSuggestionPage = React.lazy(() => import('@containers/FrameAdvisorSuggestion'))
const Prizm = React.lazy(() => import('@containers/Prizm'))
const OoCustomDetails = React.lazy(() => import('@containers/OoCustomDetails'))
const RbCustomDetails = React.lazy(() => import('@containers/RbCustomDetails'))
const PageNotFound = React.lazy(() => import('@containers/PageNotFound'))
const SessionHandoffRetriever = React.lazy(() => import('@containers/SessionHandoffRetriever'))
const EditorialLP = React.lazy(() => import('@containers/EditorialLP'))
const OnBoarding = React.lazy(() => import('@containers/FrameAdvisor/onboarding'))
const ErrorCutomerOrder = React.lazy(() => import('@containers/ErrorCustomerOrder'))

export const Routes: React.FC<RouteComponentProps> = ({ match }) => {
  const { rbCustomEnabled, ooCustomEnabled } = useStoreContext()

  return (
    <Fragment>
      <FrameAdvisorRouteActions />
      <AppRouteActions />
      <Switch>
        <Route exact path={`${match.url}`} component={Home} />
        <Route exact path={`${match.url}/products`} component={ProductsList} />
        <Route exact path={`${match.url}/products/:contentId`} component={ProductsList} />
        <Route exact path={`${match.url}/product/:model/:moco/:upc`} component={ProductDetails} />
        <Route exact path={`${match.url}/wishlist`} component={Wishlist} />
        <Route exact path={`${match.url}/brands`} component={Brands} />
        <Route exact path={`${match.url}/cart`} component={Cart} />
        <Route exact path={`${match.url}/cart/checkout`} component={Checkout} />
        <Route exact path={`${match.url}/cart/checkout/thank-you`} component={ThankYou} />
        <Route
          exact
          path={`${match.url}/custom/oo`}
          render={props =>
            ooCustomEnabled ? <OoCustomDetails {...props} /> : <Redirect to={match.url} />
          }
        />
        <Route
          exact
          path={`${match.url}/custom/rb`}
          render={props =>
            rbCustomEnabled ? <RbCustomDetails {...props} /> : <Redirect to={match.url} />
          }
        />
        <Route
          exact
          path={`${match.url}/custom/oo/:modelCode/:recipeId?`}
          render={props =>
            ooCustomEnabled ? <OoCustomDetails {...props} /> : <Redirect to={match.url} />
          }
        />
        <Route
          exact
          path={`${match.url}/custom/rb/:modelCode/:recipeId?`}
          render={props =>
            rbCustomEnabled ? <RbCustomDetails {...props} /> : <Redirect to={match.url} />
          }
        />
        <Route
          exact
          path={`${match.url}/frame-advisor/survey/:stepId?`}
          component={FrameAdvisorSurvey}
        />
        <Route
          exact
          path={`${match.url}/frame-advisor/fsa/:stepId?`}
          render={props => <FrameAdvisorSurvey fsaConnector={true} {...props} />}
        />
        <Route
          exact
          path={`${match.url}/frame-advisor/connectfsa/:videoId?`}
          component={FrameAdvisorFaceappConnect}
        />
        <Route
          exact
          path={`${match.url}/frame-advisor/logoutfsa`}
          component={FrameAdvisorFaceappLogout}
        />
        <Route exact path={`${match.url}/frame-advisor/onboarding`} component={OnBoarding} />
        <Route
          exact
          path={`${match.url}/frame-advisor/playlist`}
          component={FrameAdvisorPlaylist}
        />
        <Route
          exact
          path={`${match.url}/frame-advisor/playlist/:playlistId`}
          component={FrameAdvisorSuggestionPage}
        />
        <Route exact path={`${match.url}/prizm`} component={Prizm} />
        <Route
          exact
          path={`${match.url}/:brand([a-z0-9]{2})`}
          render={props => (
            <Redirect to={`${match.url}/products?brand=${props.match.params.brand}`} />
          )}
        />
        <Route exact path={`${match.url}/scan-barcode`} component={BarcodeActions} />
        <Route
          exact
          path={`${match.url}/supernova-home/:defaultModel?`}
          component={SupernovaHome}
        />
        <Route exact path={`${match.url}/nuance-home`} component={NuanceHome} />
        <Route exact path={`${match.url}/editorial-lp/:contentId`} component={EditorialLP} />
        <Route exact path={`${match.url}/error-customer-order`} component={ErrorCutomerOrder} />
        <Route exact path={`${match.url}/retrieve-session`} component={SessionHandoffRetriever} />
        <Route component={PageNotFound} />
      </Switch>
    </Fragment>
  )
}
