import { ProductHierarchies, ProductTypeValues } from '@abstractTypes/filter'

export const TOGGLE_AVAILABLE_IN_STORE_SEARCH_PAGE = 'ui/TOGGLE_AVAILABLE_IN_STORE_SEARCH_PAGE'
export const TOGGLE_AVAILABLE_IN_STORE = 'ui/TOGGLE_AVAILABLE_IN_STORE'
export const TOGGLE_AVAILABLE_IN_STORE_FORCED = 'ui/TOGGLE_AVAILABLE_IN_STORE_FORCED'
export const SET_FORCED_FILTERS_STATE = 'ui/SET_FORCED_FILTERS_STATE'

export const availableInStoreProductTypes = [
  ProductTypeValues.SUN,
  ProductTypeValues.OPTICAL,
] as const
export const availableInStoreProductHierarchies = [
  ProductHierarchies.SUNGLASSES,
  ProductHierarchies.EYEGLASSES,
] as const
export type AvailableInStoreProductTypes = (typeof availableInStoreProductTypes)[number]
export type AvailableInStoreProductHierarchies = (typeof availableInStoreProductHierarchies)[number]
export type AvailableInStoreTypes =
  | AvailableInStoreProductTypes
  | AvailableInStoreProductHierarchies
export type AvailableInStore = Partial<Record<AvailableInStoreTypes, boolean>>

export const availableInStoreSearchPageProductTypes = [ProductTypeValues.SNOWGOGGLES] as const
export const availableInStoreSearchPageProductHierarchies = [
  ProductHierarchies.GOGGLES,
  ProductHierarchies.REPLACEMENT_LENSES,
  ProductHierarchies.APPAREL,
  ProductHierarchies.ACCESSORIES,
  ProductHierarchies.NFL,
  ProductHierarchies.HELMETS,
  ProductHierarchies.FOOTWEAR,
  ProductHierarchies.EYEWEAR_ACCESSORIES,
  ProductHierarchies.GOGGLES_ACCESSORIES,
  ProductHierarchies.FACE_MASK,
] as const

export type AvailableInStoreSearchPageProductTypes =
  (typeof availableInStoreSearchPageProductTypes)[number]
export type AvailableInStoreSearchPageProductHierarchies =
  (typeof availableInStoreSearchPageProductHierarchies)[number]
export type AvailableInStoreSearchPageTypes =
  | AvailableInStoreSearchPageProductTypes
  | AvailableInStoreSearchPageProductHierarchies
export type AvailableInStoreSearchPage = Partial<Record<AvailableInStoreSearchPageTypes, boolean>>

export interface ToggleAvailableInStore {
  type: typeof TOGGLE_AVAILABLE_IN_STORE | typeof TOGGLE_AVAILABLE_IN_STORE_FORCED
  payload: {
    productSubdivision: AvailableInStoreTypes
    forcedValue?: boolean
  }
}

export interface ToggleAvailableInStoreSearchPage {
  type: typeof TOGGLE_AVAILABLE_IN_STORE_SEARCH_PAGE
  payload: {
    productSubdivision: AvailableInStoreSearchPageTypes
  }
}

interface ForcedFiltersStatePayload {
  isForcedFiltersState: boolean
  forcedState?: {
    availableInStore: AvailableInStore
  }
}
export interface SetForcedFiltersState {
  type: typeof SET_FORCED_FILTERS_STATE
  payload: ForcedFiltersStatePayload
}

export const toggleAvailableInStoreSearchPage = (
  productSubdivision: AvailableInStoreSearchPageTypes
): ToggleAvailableInStoreSearchPage => ({
  type: TOGGLE_AVAILABLE_IN_STORE_SEARCH_PAGE,
  payload: { productSubdivision },
})

export const toggleAvailableInStore = (
  productSubdivision: AvailableInStoreTypes,
  forcedValue?: boolean
): ToggleAvailableInStore => ({
  type: TOGGLE_AVAILABLE_IN_STORE,
  payload: { productSubdivision, forcedValue },
})

export const toggleAvailableInStoreForced = (
  productSubdivision: AvailableInStoreTypes
): ToggleAvailableInStore => ({
  type: TOGGLE_AVAILABLE_IN_STORE_FORCED,
  payload: { productSubdivision },
})

export const setForcedFiltersState = (
  payload: ForcedFiltersStatePayload
): SetForcedFiltersState => ({
  type: SET_FORCED_FILTERS_STATE,
  payload,
})

export type FiltersActionTypes =
  | ToggleAvailableInStore
  | SetForcedFiltersState
  | ToggleAvailableInStoreSearchPage
