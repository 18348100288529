import { Heading4 } from '@components/core/Typography'
import { MenuListItem } from '../MenuContent/MenuListItem'
import { MenuListWrapperStyled } from './styles'
import MenuListWrapperProps from './types'

export const MenuListWrapper: React.FC<MenuListWrapperProps> = ({
  menu,
  item,
  onSelect,
  onSelectMenu,
}) => {
  return (
    <MenuListWrapperStyled>
      <Heading4>{item.label}</Heading4>
      {item?.items?.map((item, index) => (
        <MenuListItem
          key={`${index}-${item.label}`}
          menu={menu}
          item={item}
          onSelect={onSelect}
          onSelectMenu={onSelectMenu}
        />
      ))}
    </MenuListWrapperStyled>
  )
}

export default MenuListWrapper
