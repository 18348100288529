import { FrameAdvisorSurveyContainer } from '@components/Contents/FrameAdvisorSurveyContent/styles'
import FaceappConnector from '@components/FrameAdvisor/FaceappConnector'
import {
  BarcodeWrapper,
  Border,
  BorderContainer,
} from '@components/FrameAdvisor/FaceappConnector/styles'
import { Modal } from '@components/core/Modal'
import {
  ModalBody,
  ModalBodyWrapper,
  ModalContent,
  ModalWrapper,
} from '@components/core/Modal/styles'
import { Heading4, Text } from '@components/core/Typography'
import { useFrameAdvisorFsaToken } from '@data'
import { useFacescanAppVideoId } from '@hooks/useFacescanAppVideoId'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { altNavigation, altNavigationZoom, zoomLandscape, zoomPortrait } from '@libs/media'
import { pxToRem } from '@libs/styled'
import { generateBase64Qrcode } from '@libs/virtualMirror'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { QrCodeModalProps } from './types'

export const ModalStyled = styled(Modal)`
  ${altNavigation(`
    inset-block-start: ${pxToRem(48)};
    block-size: ${pxToRem(748)};
  `)}
  ${altNavigationZoom(
    [150, 175, 200],
    `
    inset-block-start: 0;
    block-size: 100%;
    inline-size: 100% !important;
  `
  )}

  ${ModalContent} {
    ${altNavigation(`
      block-size: 100%;
    `)}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      block-size: 100%;
    `
    )}
  }

  ${ModalBody} {
    padding-block: ${pxToRem(124)} ${pxToRem(157)};
    text-align: center;
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding-block: ${pxToRem(144)} ${pxToRem(165)};
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      inline-size: ${pxToRem(1320)};
      padding-block: ${pxToRem(153)};
    `
    )}
    ${zoomLandscape(
      [200],
      `
      inline-size: ${pxToRem(1440)};
    `
    )}
    ${altNavigation(`
      padding-block: ${pxToRem(115)};
    `)}
    ${altNavigationZoom(
      [110],
      `
      padding-block: ${pxToRem(84)};
    `
    )}
    ${altNavigationZoom(
      [125],
      `
      padding-block: ${pxToRem(54)};
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      inline-size: 100%;
      padding-block: ${pxToRem(104)} 0;
    `
    )}
  }

  ${ModalWrapper} {
    padding-block: 0;
    padding-inline: ${pxToRem(120)};
    ${altNavigation(`
      padding-inline:  ${pxToRem(96)};
    `)}
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding-inline:  ${pxToRem(110)};
    `
    )}
    ${zoomLandscape(
      [150, 175, 200],
      `
      padding-inline:  ${pxToRem(125)};
    `
    )}
    ${altNavigationZoom(
      [150, 175, 200],
      `
      padding-inline:  ${pxToRem(192)};
    `
    )}
  }

  ${ModalBodyWrapper} {
    ${zoomLandscape(
      [150, 175, 200],
      `
      display: flex;
      align-items: center;
      justify-content: space-around;
    `
    )}
  }

  ${FrameAdvisorSurveyContainer} {
    margin: 0;
    padding: 0;
    ${altNavigationZoom(
      [150, 175, 200],
      `
      block-size: auto;
    `
    )}
  }

  ${BarcodeWrapper} {
    margin: 0;
    padding: 0;
  }

  ${BorderContainer} {
    margin: auto;
    padding: 0;
  }

  ${Border} {
    margin: 0;
    padding: 0;

    ::before,
    ::after {
      display: none;
    }
  }
`

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: ${pxToRem(32)} ${pxToRem(64)};
  ${zoomLandscape(
    [150, 175, 200],
    `
      margin-block-end: 0;
  `
  )}
`

export const TextStyled = styled(Text)`
  margin-inline-start: ${pxToRem(16)};
`

export const ModalHeader = styled.div`
  ${zoomLandscape(
    [150, 175, 200],
    `
      max-inline-size: ${pxToRem(582)};
  `
  )}
`

export const QrCodeModal: React.FC<QrCodeModalProps> = ({ visible, onClose }) => {
  const { t } = useTranslation()
  const { data, refetch } = useFrameAdvisorFsaToken()
  const token = data?.frameAdvisorFsaToken.frameAdvisorFsaToken.token || null
  const { error: videoIdError } = useFacescanAppVideoId(token, visible)
  const { basePath } = useStoreIndentity()
  const history = useHistory()

  const handleCloseFsa = () => {
    history.push(`${basePath}/frame-advisor/survey`)
  }

  return (
    <ModalStyled visible={visible} onCancel={onClose} showHeader={false}>
      <ModalHeader>
        <Heading4 weight="bold">{t('FrameAdvisor.onboarding.qrcodeTitle')}</Heading4>
        <Info>
          <TextStyled weight="bold">{t('FrameAdvisor.onboarding.assistance')}</TextStyled>
        </Info>
      </ModalHeader>
      <FaceappConnector
        qrCodeLabels={{
          qrcodeExpired: t('faceScanApp.qrcodeExpired'),
          refreshQrcode: t('faceScanApp.refreshQrcode'),
        }}
        aria-label={t('faceScanApp.refreshQrcode')}
        generateBase64Qrcode={generateBase64Qrcode}
        videoIdError={!!videoIdError}
        frameAdvisorFsaTokenData={{
          ...data?.frameAdvisorFsaToken.frameAdvisorFsaToken,
        }}
        frameAdvisorFsaTokenRefetch={refetch}
        handleCloseFsa={handleCloseFsa}
        showQRCode
        qrCodeVariant="round"
        withAssistanceLabel={false}
        qrCodeSize={250}
      />
    </ModalStyled>
  )
}
