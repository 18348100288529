import { Drawer } from '@components/Drawer'
import { MainMenu } from '@components/MainMenu'
import { TransferSessionModal } from '@components/core/Modals/TransferSessionModal'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useModalHandler } from '@hooks/useModalHandler'
import React from 'react'

export const MenuDrawer: React.FC = () => {
  const { isVisible } = useDrawerHandler('menu')
  const { isVisible: transferSessionModalIsVisible, closeAnyModal } =
    useModalHandler('transferSessionModal')

  return (
    <>
      <Drawer visible={isVisible}>
        <MainMenu />
      </Drawer>
      <TransferSessionModal visible={transferSessionModalIsVisible} onCancel={closeAnyModal} />
    </>
  )
}
