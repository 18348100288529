import { setStripeBannerVisible } from '@actions/ui'
import { useActions } from '@hooks/useActions'
import { useEffect } from 'react'

const useStripeBannerVisible = (isBannerVisible: boolean) => {
  const actions = useActions({ setStripeBannerVisible })
  useEffect(() => {
    actions.setStripeBannerVisible(isBannerVisible)
  }, [isBannerVisible, actions])
}

export default useStripeBannerVisible
