import { IconProps } from '@abstractTypes/icons'

export const BackIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      strokeWidth={0}
    >
      <g clipPath="url(#clip0_1874_61700)">
        <path
          d="M2.94263 15.5833L15.138 3.38798L14.1953 2.44531L0.390625 16.25L14.1953 30.0546L15.138 29.112L2.94263 16.9166H32V15.5833H2.94263Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61700">
          <rect width={32} height={32} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
