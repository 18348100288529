import { IconProps } from '@abstractTypes/icons'


export const ApparelIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke={stroke}
      viewBox="0 0 47 48"
    >
      <path
        fill={fill}
        d="M46.416 17.515l-2.994-8.983c-.891-2.673-3.019-4.801-5.692-5.692L29.208 0l-1.257.419c-3.059 1.02-6.428 1.02-9.486 0L17.208 0 8.687 2.84c-2.674.891-4.802 3.019-5.693 5.693L0 17.516l7.208 4.118v25.42h32v-25.42l7.208-4.119zM28.584 2.316l.52-.173c-.514 2.79-2.96 4.911-5.896 4.911s-5.382-2.121-5.896-4.911l.52.173c3.467 1.155 7.285 1.155 10.752 0zm8.624 42.738h-28v-4h8v-2h-8v-24h-2v4.277l-4.792-2.738 2.476-7.427c.693-2.08 2.348-3.735 4.428-4.428l6.065-2.021c.767 3.615 3.982 6.337 7.823 6.337s7.056-2.722 7.824-6.338l6.065 2.021c2.08.693 3.735 2.348 4.428 4.428l2.476 7.427-4.793 2.739v-4.277h-2v30z"
      />
    </svg>
  )
}
