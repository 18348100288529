import { IconProps } from '@abstractTypes/icons'
export const SizeFitTipIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 101"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M77.0833 33.8334C65.5125 33.8334 58.2937 37.0896 55.4979 43.5167C52.0667 41.8938 47.9312 41.8938 44.5021 43.5167C41.7062 37.0896 34.4875 33.8334 22.9167 33.8334C7.71042 33.8334 0 39.4396 0 50.5C0 64.6355 8.78125 73.4167 22.9167 73.4167C35.5521 73.4167 45.8333 63.1355 45.8333 50.5C45.8333 49.4875 45.7458 48.5355 45.6167 47.6146C48.2521 46.0167 51.7479 46.0167 54.3833 47.6146C54.2542 48.5355 54.1667 49.4875 54.1667 50.5C54.1667 63.1355 64.4479 73.4167 77.0833 73.4167C91.2188 73.4167 100 64.6355 100 50.5C100 39.4396 92.2896 33.8334 77.0833 33.8334ZM22.9167 69.25C5.99375 69.25 4.16667 56.1375 4.16667 50.5C4.16667 44.6813 6.29792 38 22.9167 38C39.5354 38 41.6667 44.6813 41.6667 50.5C41.6667 60.8375 33.2542 69.25 22.9167 69.25ZM77.0833 69.25C66.7458 69.25 58.3333 60.8375 58.3333 50.5C58.3333 44.6813 60.4646 38 77.0833 38C93.7021 38 95.8333 44.6813 95.8333 50.5C95.8333 56.1375 94.0062 69.25 77.0833 69.25Z"
        fill="#333333"
      />
      <path
        d="M95.8333 8.83337V13H4.16667V8.83337H0V21.3334H4.16667V17.1667H95.8333V21.3334H100V8.83337H95.8333Z"
        fill="#333333"
      />
    </svg>
  )
}
