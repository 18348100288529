import { resetLoaderList } from '@actions/ui'
import { usePrevious } from '@hooks/usePrevious'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useActions } from './useActions'
import { useDrawerHandler } from './useDrawerHandler'
import { useModalHandler } from './useModalHandler'

export const usePageChangeListener = () => {
  const { closeAnyDrawer } = useDrawerHandler()
  const { closeAnyModal } = useModalHandler()
  const history = useHistory()
  const prevLocation = usePrevious(history.location)
  const actions = useActions({ resetLoaderList })

  useEffect(() => {
    return history.listen(location => {
      if (location.pathname !== prevLocation.pathname || location.search === prevLocation.search) {
        closeAnyDrawer()
        closeAnyModal()
        actions.resetLoaderList()
      }
    })
  }, [actions, closeAnyDrawer, closeAnyModal, history, prevLocation])
}
