import { IconProps } from '@abstractTypes/icons'


export const TrainingIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      stroke={stroke}
    >
      <path
        d="M46 23v-3a2 2 0 0 0-2-2h-3v-2a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v7H15v-7a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H4a2 2 0 0 0-2 2v3H0v2h2v3a2 2 0 0 0 2 2h3v2a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-7h18v7a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h3a2 2 0 0 0 2-2v-3h2v-2h-2zM4 28v-8h3v8H4zm9 4H9V16h4v16zm26-12v12h-4V16h4v4zm5 8h-3v-8h3v8z"
        fill={fill}
      />
    </svg>
  )
}
