import { Device } from '@abstractTypes/utils'
import { Toggler, TogglerButton } from '@components/Toggler'
import { AccordionLayoutWrapper } from '@components/core/Accordion/styles'
import { TabsWithAccordionStyled } from '@components/pdp/PDPTabs/styles'
import { altNavigation, landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const ElectronicTabsWithAccordion = styled(TabsWithAccordionStyled)`
  ${AccordionLayoutWrapper} {
    background: ${({ theme }) => theme.electronics.colors.primaryLight};
  }
`

export const ElectronicsToggler = styled(Toggler)`
  ${({ theme }) => `
  border-radius: ${pxToRem(50)};
    ${altNavigation(`
      border: 1px solid ${theme.colors.primaryDark};
    `)}
  `}
  ${TogglerButton} {
    color: ${({ theme }) => theme.electronics.colors.primary};
    font-family: ${({ theme }) => theme.electronics.fonts.primary};

    &.active {
      background-color: ${({ theme }) => theme.electronics.colors.primaryDark};
      color: ${({ theme }) => theme.electronics.colors.primaryLight};
    }
    ${tablet(
      `
      min-inline-size: ${clampValues(138, 190)};
    `
    )}
    ${landscape(
      Device.tablet,
      `
      inline-size: ${clampValuesLandscape(130, 160)};
    `
    )}
    ${altNavigation(`
      min-inline-size: ${pxToRem(128)};
    `)}
  }
`
