import { ListTypography } from '@components/core/Typography'
import { tablet } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const SelectStyled = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: 0;
  inline-size: 100%;
`

export const SelectInnerStyled = styled.div`
  cursor: pointer;
  block-size: ${pxToRem(40)};
  border: ${({ theme }) => `1px solid ${theme.colors.outline}`};
`

export const SelectWrapperStyled = styled.div`
  block-size: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 0;
  margin-inline: ${pxToRem(12)};
  user-select: none;
`

export const SelectDropdown = styled.ul<{ visible?: boolean }>`
  position: absolute;
  z-index: 10;
  visibility: ${({ visible }) => !visible && 'hidden'};
  block-size: ${({ visible }) => (visible ? 'auto' : 0)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  max-block-size: ${pxToRem(250)};
  overflow-y: auto;
  transition: opacity 0.3s ease;
  margin-block-end: 0;
  padding-block: ${pxToRem(4)};
  padding-inline: 0;
  padding-inline-start: 0;
  list-style: none;
  outline: none;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  ${tablet(
    `
    max-block-size: ${pxToRem(120)};
    `
  )}
`

export const OptionSlyled = styled(ListTypography)`
  display: block;
  padding-block: ${pxToRem(5)};
  padding-inline: ${pxToRem(12)};
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: normal;
`
