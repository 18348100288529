import { IconProps } from '@abstractTypes/icons'

export const GlassShape10Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 0.25H28C26.895 0.25 26 1.145 26 2.25V5.613C24.711 5.169 23.288 5.169 22 5.613V2.25C22 1.145 21.105 0.25 20 0.25H2C0.895 0.25 0 1.145 0 2.25V20.25C0 21.355 0.895 22.25 2 22.25H20C21.105 22.25 22 21.355 22 20.25V7.799C23.223 7.1 24.777 7.1 26 7.8V20.25C26 21.355 26.895 22.25 28 22.25H46C47.105 22.25 48 21.355 48 20.25V2.25C48 1.145 47.105 0.25 46 0.25ZM20 20.25H2V2.25H20V20.25ZM28 20.25H46V2.25H28V20.25Z"
        fill="#333333"
      />
    </svg>
  )
}
