import { IconProps } from '@abstractTypes/icons'

export const CaretDownThinIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        d="M31.8046 1.05497L30.862 0.112305L16 14.9743L1.13798 0.112305L0.195312 1.05497L16 16.8596L31.8046 1.05497Z"
        fill={fill}
      />
    </svg>
  )
}
