import { IconProps } from '@abstractTypes/icons'


export const AccessoriesIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 40 48"
      stroke={stroke}
    >
      <path
        fill={fill}
        d="M38 33h-4v-9.556c1.19-.694 2-1.97 2-3.444V7c0-1.103-.897-2-2-2h-8.09c-.479-2.833-2.943-5-5.91-5s-5.431 2.167-5.91 5H6c-1.103 0-2 .897-2 2v13c0 1.474.81 2.75 2 3.444V33H2c-1.103 0-2 .897-2 2v7c0 2.206 1.794 4 4 4h2.556c.694 1.19 1.97 2 3.444 2h20c1.474 0 2.75-.81 3.444-2H36c2.206 0 4-1.794 4-4v-7c0-1.103-.897-2-2-2zm-4-13c0 1.103-.897 2-2 2h-6V7h8v13zM24 7v15h-8V7h8zm-4-5c1.858 0 3.411 1.279 3.858 3h-7.716c.447-1.721 2-3 3.858-3zM6 7h8v15H8c-1.103 0-2-.897-2-2V7zm2 17h6v3.028h-1v2h1V30c0 .553.448 1 1 1s1-.447 1-1v-.972h1v-2h-1V24h8v3h-1v2h1v1c0 .553.448 1 1 1s1-.447 1-1v-1h1v-2h-1v-3h6v9H8v-9zm6 11h12v4c0 .552-.449 1-1 1H15c-.551 0-1-.448-1-1v-4zM4 44c-1.103 0-2-.897-2-2v-7h4v9H4zm26 2H10c-1.103 0-2-.897-2-2v-9h4v4c0 1.654 1.346 3 3 3h10c1.654 0 3-1.346 3-3v-4h4v9c0 1.103-.897 2-2 2zm8-4c0 1.103-.897 2-2 2h-2v-9h4v7z"
      />
    </svg>
  )
}
