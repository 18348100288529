import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const QRCodeDebugWrapper = styled.div`
  margin-block-start: 1em;
  padding: 0.5rem;
  max-inline-size: ${pxToRem(600)};
  box-sizing: content-box;
  white-space: pre-wrap;
`

export const StyledQRCode = styled.div<{ size: number; url: string }>`
  margin-block: 0;
  margin-inline: auto;
  block-size: ${({ size }) => pxToRem(size)};
  inline-size: ${({ size }) => pxToRem(size)};
  background-color: ${({ theme }) => theme.colors.outline};
  animation-name: ${({ url }) => (url ? 'none' : 'color')};
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  border-radius: ${pxToRem(20)};

  canvas {
    inline-size: ${({ size }) => pxToRem(size)};
    block-size: ${({ size }) => pxToRem(size)};
  }

  @keyframes color {
    0% {
      background-color: ${({ theme }) => theme.colors.outline};
    }
    100% {
      background-color: ${({ theme }) => theme.colors.inactive};
    }
  }
`
