import { Device } from '@abstractTypes/utils'
import { landscape, tablet } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { css } from 'styled-components'

export const scrollbar = css`
  ::-webkit-scrollbar {
    display: initial !important;
    inline-size: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.electronics.colors.primary};
    border-radius: 5px;
  }
`

export const hideScrollbar = css`
  ::-webkit-scrollbar {
    display: none !important;
  }
`

export const baseContainerPadding = css`
  padding-block: 0;
  padding-inline: ${pxToRem(112)};
  ${tablet(`padding-inline:  ${clampValues(72, 96)};`)}
  ${landscape(`padding-inline:  ${pxToRem(112)};`)}
  ${landscape(Device.tablet, `padding-inline:  ${clampValuesLandscape(72, 112)};`)}
`

export const baseContainerBottomPadding = css`
  padding-block-end: ${pxToRem(30)};
  ${tablet(`padding-block-end: ${pxToRem(30)};`)}
  ${landscape(`padding-block-end: ${pxToRem(30)};`)}
  ${landscape(Device.tablet, `padding-block-end: ${pxToRem(30)};`)}
`
