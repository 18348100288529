import { Text } from '@components/core/Typography'
import { merge } from 'lodash'
import { Trans } from 'react-i18next'

type TransComponentsTags = 'bold' | 'link' | 'button'
type TransComponentsType = Partial<Record<TransComponentsTags, React.ReactNode>>

export type SSTransProps = React.ComponentProps<typeof Trans> & {
  components?: TransComponentsType
}

const SSTrans = ({ components, ...props }: SSTransProps) => {
  const defaultComponents: TransComponentsType = {
    bold: <Text weight="bold" />,
  }

  return <Trans {...props} components={merge({}, defaultComponents, components)} />
}

export default SSTrans
