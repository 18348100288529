import ConditionalRender from '@components/ConditionalRender'
import { ToolTipTimeout } from '@components/Tooltip/ToolTipTimeout'
import {
  BackIcon,
  FilterIcon,
  HamburgerMenuIcon,
  HandoffIcon,
  HomeIcon,
  SearchIcon,
  ShoppingBagIcon,
} from '@components/core/Icons'
import config from '@config/index'
import { useCart } from '@hooks/useCart'
import { useCustomerOrder } from '@hooks/useCustomOrder'
import { useDrawerHandler } from '@hooks/useDrawerHandler'
import { useModalHandler } from '@hooks/useModalHandler'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import { isIPadView, usePageChecker } from '@libs/utils'
import { default as React, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FloatingButton, FloatingContainer, MenuIconBadge } from './styles'
import { FloatingActionsProps } from './types'

// export const FloatingButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ ...props }) => {
//   return <FloatingButton2 {...props}>{props.children}</FloatingButton2>
// }

export const FloatingActions: React.FC<FloatingActionsProps> = ({
  showBackButton = false,
  showFilterButton: showFilterButtonProp = true,
}) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const { setVisibleDrawer } = useDrawerHandler()
  const history = useHistory()
  const showMenu = () => setVisibleDrawer('menu')
  const showFilters = () => setVisibleDrawer('filters')
  const showSearch = () => setVisibleDrawer('search')
  const filtersCount = useSelector(s => s.ui.filtersCount)
  const { numberItems } = useCart()
  const isSessionTransferAvailable = config.toggleFeature.handoff

  const isFsaConnected = useSelector(({ frameAdvisor }) => frameAdvisor.fsaVideoId)
  const wishItems = useSelector(({ wishlist }) => wishlist.items)
  const { landscapeNavigation, zoomLevel, isAlternativeNavigation } = useNavigationContext()
  const showMenuBadge = isFsaConnected || wishItems.length > 0
  const { isCustomerOrder } = useCustomerOrder()
  const {
    isHomePage,
    isPLPPage,
    isElectronicsPage,
    isPrizmPage,
    isFrameAdvisorPage,
    isCartPage,
    isCheckoutPage,
    isNuancePage,
  } = usePageChecker()
  const showHandoffTooltip = isCartPage || isCheckoutPage
  const isFaPageExceptPLP = isFrameAdvisorPage && !isPLPPage
  const showButtonBack = showBackButton && !isElectronicsPage && !isNuancePage
  const showSearchButton = !isElectronicsPage && !isNuancePage
  const showMenuButton = !isElectronicsPage && !isNuancePage
  const showHomeButton = isElectronicsPage || isNuancePage
  const showShoppingBagButton =
    !isCustomerOrder && !isElectronicsPage && !isPrizmPage && !isFaPageExceptPLP && !isNuancePage
  const showFilterButton = showFilterButtonProp && isPLPPage
  const isIPad = isIPadView()
  const showSessionTransferButton =
    isSessionTransferAvailable && !isIPad && !isElectronicsPage && !isNuancePage
  const iconSize = useMemo(() => {
    if (isIPad && landscapeNavigation) {
      return clampValuesLandscape(20, 24)
    }
    if (isIPad) {
      return clampValues(20, 24)
    }
    if (isAlternativeNavigation) {
      return pxToRem(24, zoomLevel / 100)
    }
    return pxToRem(32, zoomLevel / 100)
  }, [isIPad, landscapeNavigation, isAlternativeNavigation, zoomLevel])

  const { setVisibleModal } = useModalHandler('transferSessionModal')

  const handleTransferClick = useCallback(() => {
    if (isSessionTransferAvailable) {
      setVisibleModal('transferSessionModal')
    }
  }, [isSessionTransferAvailable, setVisibleModal])

  return (
    <FloatingContainer higher={isHomePage}>
      <ConditionalRender
        condition={showButtonBack}
        render={() => (
          <FloatingButton
            data-element-id="MainNav_Back"
            data-test="MainNav_Back"
            aria-label={t('ARIA.backIcon.label')}
            onClick={() => history.goBack()}
          >
            <BackIcon height={iconSize} width={iconSize} />
          </FloatingButton>
        )}
      />

      <ConditionalRender
        condition={showHomeButton}
        render={() => (
          <FloatingButton
            data-element-id="MainNav"
            data-test="MainNav"
            aria-label={t('ARIA.homeIcon.label')}
            onClick={() => history.push(basePath)}
          >
            <HomeIcon height={iconSize} width={iconSize} />
          </FloatingButton>
        )}
      />

      <ConditionalRender
        condition={showSearchButton}
        render={() => (
          <FloatingButton
            data-element-id="MainNav_Search"
            data-test="MainNav_Search"
            aria-label={t('ARIA.searchIcon.label')}
            onClick={showSearch}
          >
            <SearchIcon height={iconSize} width={iconSize} />
          </FloatingButton>
        )}
      />

      <ConditionalRender
        condition={showMenuButton}
        render={() => (
          <FloatingButton
            data-element-id="MainNav_Menu"
            data-test="MainNav_Menu"
            aria-label={t('ARIA.menuIcon.label')}
            onClick={showMenu}
          >
            <HamburgerMenuIcon height={iconSize} width={iconSize} />

            {showMenuBadge && <MenuIconBadge />}
          </FloatingButton>
        )}
      />

      <ConditionalRender
        condition={showShoppingBagButton}
        render={() => (
          <FloatingButton
            data-element-id="MainNav_Bag"
            data-test="MainNav_Bag"
            aria-label={t('ARIA.shoppingBagIcon.label')}
            onClick={() => history.push(`${basePath}/cart`)}
          >
            <ShoppingBagIcon height={iconSize} width={iconSize} />
            {numberItems > 0 ? <MenuIconBadge /> : null}
          </FloatingButton>
        )}
      />

      <ConditionalRender
        condition={showFilterButton}
        render={() => (
          <FloatingButton
            data-element-id="MainNav_Filters"
            data-test="MainNav_Filters"
            aria-label={t('ARIA.filtersIcon.label')}
            onClick={showFilters}
          >
            <FilterIcon height={iconSize} width={iconSize} />
            {filtersCount > 1 && <MenuIconBadge />}
          </FloatingButton>
        )}
      />

      <ConditionalRender
        condition={showSessionTransferButton}
        render={() => (
          <ToolTipTimeout text={t('Menu.sessionTransferTower')} visible={showHandoffTooltip}>
            <FloatingButton
              data-element-id="MainNav_MenuSessionTransfer"
              data-test="MainNav_MenuSessionTransfer"
              aria-label={t('Menu.sessionTransfer')}
              onClick={handleTransferClick}
            >
              <HandoffIcon height={iconSize} width={iconSize} />
            </FloatingButton>
          </ToolTipTimeout>
        )}
      />
    </FloatingContainer>
  )
}
