import { ElectronicsContentV2Article } from '@abstractTypes/graphqlTypes'
import { Device } from '@abstractTypes/utils'
import { ColorBadge } from '@components/core/ColorBadge/styles'
import { LensColorTreatmentIcon } from '@components/core/Icons'
import {
  ElectronicsHeading4,
  ElectronicsText,
} from '@components/core/Typography/ElectronicsTypography'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { landscape, tablet } from '@libs/media'
import { clampValues, pxToRem } from '@libs/styled'
import React from 'react'
import styled from 'styled-components'

const LensesTabWrapper = styled.div`
  max-inline-size: 80%;
  margin-block: 0;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LensesColors = styled.div`
  margin-block: ${pxToRem(64)} ${pxToRem(8)};
  margin-inline: 0;

  & > svg {
    margin-block: 0;
    margin-inline: auto;
  }
`

const LensesTabColorText = styled(ElectronicsText)`
  margin-block-end: ${pxToRem(28)};
  white-space: pre-line;
`

const LensesTabColorsContainer = styled.div``

const ColorBadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  inline-size: ${pxToRem(808)};
  ${tablet(`
      inline-size: ${clampValues(680, 888)};
  `)}
  ${landscape(
    Device.tablet,
    `
    inline-size: unset;
    `
  )};
`

const ColorBadgeElectronics = styled(ColorBadge)`
  margin: ${pxToRem(15)};
  inline-size: ${pxToRem(50)};
  block-size: ${pxToRem(50)};
  background-color: ${({ backgroundColor }) => `#${backgroundColor}`};
  ${tablet(`
      margin: ${clampValues(10, 15)};
  `)}
`

export const LensesWrapper: React.FC<{
  lensesContent: ElectronicsContentV2Article
  lensesColorsContent?: ElectronicsContentV2Article
}> = ({ lensesContent, lensesColorsContent }) => {
  const { zoomLevel } = useNavigationContext()
  return (
    <LensesTabWrapper>
      <LensesTabColorsContainer>
        {!lensesColorsContent && (
          <LensColorTreatmentIcon
            height={pxToRem(48, zoomLevel / 100)}
            width={pxToRem(48, zoomLevel / 100)}
          />
        )}
        <ElectronicsHeading4>{lensesContent.text}</ElectronicsHeading4>
        {!!lensesColorsContent && (
          <LensesColors>
            <LensColorTreatmentIcon
              height={pxToRem(48, zoomLevel / 100)}
              width={pxToRem(48, zoomLevel / 100)}
            />
            <LensesTabColorText>{lensesColorsContent.text}</LensesTabColorText>
            {lensesColorsContent.colors?.length && (
              <ColorBadgeWrapper>
                {lensesColorsContent.colors?.map((colorCode, i) => (
                  <ColorBadgeElectronics
                    key={i}
                    backgroundColor={colorCode}
                    isSmall={true}
                  ></ColorBadgeElectronics>
                ))}
              </ColorBadgeWrapper>
            )}
          </LensesColors>
        )}
      </LensesTabColorsContainer>
    </LensesTabWrapper>
  )
}
