import { Product } from '@abstractTypes/product'

const getPrice = (product: Product, operator: Math['max'] | Math['min']): number => {
  const { variants, price } = product
  const variantPrices = variants ? variants.map(element => element.price.current) : []
  const rangePrices = variantPrices.concat(price.current)
  return operator(...rangePrices)
}

export const getHighestPrice = (product: Product): number => {
  return getPrice(product, Math.max)
}

export const getLowestPrice = (product: Product): number => {
  return getPrice(product, Math.min)
}

export const getTotalPrice = (product: Product, quantity = 1) => product.price.current * quantity
