import {
  FilterGroup,
  FilterKind,
  FilterOption,
  FilterOptionsComparator,
  FiltersFromUrl,
  FiltersFromUrlKind,
  FormattedFilterMultiRangeType,
  FormattedFiltersType,
  MultiRangeFiltersKind,
  RangeFiltersKind,
} from '@abstractTypes/filter'
import { PriceRanges } from '@abstractTypes/graphqlTypes'
import config from '@config/index'
import { PRODUCT_TYPE } from '@constants/product'
import isString from 'lodash/isString'
import { objectKeys } from './object'
import { sizeComparator, toArray } from './utils'
import { ShopperSlugWithFallback } from '@abstractTypes/envs'

export const isTrueFilter = (filterValue: string | undefined): boolean =>
  ['true', 'TRUE', '1'].includes(filterValue ?? '')

export const isTrueFilterArray = (filterValue: string[] | undefined): boolean =>
  !!filterValue?.every(isTrueFilter)

export const isFilterRange = (filterKind: FiltersFromUrlKind): filterKind is RangeFiltersKind =>
  ['minLensHeight', 'maxLensHeight', 'maxPrice', 'minPrice'].includes(filterKind)

const sizeOptionsComparator: FilterOptionsComparator = (a, b) =>
  sizeComparator(a.translatedLabel, b.translatedLabel)

export const defaultOptionsComparator: FilterOptionsComparator = (a, b) => {
  if (a.translatedLabel && b.translatedLabel) {
    return a.translatedLabel.localeCompare(b.translatedLabel)
  }
  return 0
}

const optionFilterMap: { [kind in FilterKind]?: FilterOptionsComparator } = {
  sizeOrdinal: sizeOptionsComparator,
}

export const getFilterOptionsComparator = (filterKind: FilterKind): FilterOptionsComparator => {
  return optionFilterMap[filterKind] || defaultOptionsComparator
}

export const formatFilterValue = <T extends FiltersFromUrlKind>(
  filterKind: T,
  filterValue: FiltersFromUrl[T]
): FormattedFiltersType => {
  if (isFilterRange(filterKind)) {
    return Number(filterValue)
  }

  if (isString(filterValue) && isTrueFilter(filterValue)) {
    return true
  }

  if (Array.isArray(filterValue) && isTrueFilterArray(filterValue)) {
    return true
  }

  return filterValue
}

export const formatMultiRangeFilterValue = <T extends MultiRangeFiltersKind>(
  filterKind: MultiRangeFiltersKind,
  filterValue: FiltersFromUrl[T],
  rangeFilterValue: FormattedFilterMultiRangeType[] = []
): FormattedFilterMultiRangeType[] => {
  const rangeArrayValue = toArray(filterValue).map(Number)

  rangeArrayValue.forEach((value, index) => {
    const rangeFilterValueItem = rangeFilterValue[index] ?? {}
    rangeFilterValueItem[filterKind] = value
    rangeFilterValue[index] = rangeFilterValueItem
  })

  return rangeFilterValue
}

export const createPriceRangesFilterValue = (priceRanges: PriceRanges[]): number[] => {
  const prices = new Set<number>()
  const { min } = priceRanges[0]
  const minPrice = min ? parseInt(min) : 0

  prices.add(minPrice)
  priceRanges.forEach(p => prices.add(parseInt(p.max)))

  return Array.from(prices)
    .filter(x => !isNaN(x))
    .sort((a, b) => a - b)
}

export const isProductTypeSubdivision = () => {
  const productSubdivision = config.default.productSubdivision
  return productSubdivision === PRODUCT_TYPE
}

export const removeFiltersToIgnore = <T extends object>(filters: T, ignoreFilters: (keyof T)[]) => {
  const keys = objectKeys(filters)

  return keys.reduce((acc, filterKey) => {
    if (!ignoreFilters.includes(filterKey)) {
      acc[filterKey] = filters[filterKey]
    }
    return acc
  }, {} as T)
}

export const getSortedHierarchyOptions = (options: FilterOption[]): FilterOption[] => {
  return options.filter(o => o.count > 0).sort((o1, o2) => o2.count - o1.count)
}

export const conditionalFilter = (
  filterValue: FilterKind,
  condition: boolean,
  withFilterExclusionCheck = false
): [FilterKind] | [] => {
  let excludeFilter = false

  if (withFilterExclusionCheck) {
    const filtersExclusionListForBanner: { [key in ShopperSlugWithFallback]?: FilterGroup[] } = {
      rayban: ['sports', 'isPrizm'],
    }
    const filtersExclusionList = filtersExclusionListForBanner[config.shopperSlug]
    const excludeFiltersCheck = (
      filterList: FilterGroup[] | undefined,
      filterToExclude: FilterKind
    ): boolean => !!filterList?.some(() => filterList.includes(filterToExclude))

    excludeFilter = excludeFiltersCheck(filtersExclusionList, filterValue)
  }

  return condition && !excludeFilter ? [filterValue] : []
}
