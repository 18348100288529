import { BarcodeScanModalBase } from '@components/BarCodeScanModal/Modals/BarcodeScanModalBase'
import { BarcodeScanModalWithRedirectProps } from '@components/BarCodeScanModal/types'
import { PrimaryButton } from '@components/core/Button'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { getCustomizableBrand, isOOBrand } from '@libs/custom'
import { getCustomProductRoute } from '@libs/routing'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSendBarcodeCustomOnlyAnalyticsEvent } from './analytics'

export const BarcodeScanModalOnlyCustom = ({
  visible,
  onCancel,
  onRedirect,
  productInfo,
}: BarcodeScanModalWithRedirectProps) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const { originalBrandCode, model, UPC, thumbnails } = productInfo
  const formattedBrand = getCustomizableBrand(originalBrandCode)
  const isOakley = isOOBrand(formattedBrand)

  const subtitle = t(
    isOakley
      ? 'BarCodeScanModal.OakleyOnlyCustomSubtitle'
      : 'BarCodeScanModal.RayBanOnlyCustomSubtitle'
  )
  const title = t(
    isOakley ? 'BarCodeScanModal.OakleyOnlyCustomTitle' : 'BarCodeScanModal.RayBanOnlyCustomTitle'
  )
  const description = t(
    isOakley
      ? 'BarCodeScanModal.OakleyDescriptionOnlyCustom'
      : 'BarCodeScanModal.RayBanDescriptionOnlyCustom'
  )
  const { sendCloseModalEvent } = useSendBarcodeCustomOnlyAnalyticsEvent(visible)

  const handleOnCancel = useCallback(() => {
    sendCloseModalEvent()
    onCancel()
  }, [onCancel, sendCloseModalEvent])

  const url = getCustomProductRoute({ basePath, brand: formattedBrand, model, UPC })

  return (
    <BarcodeScanModalBase
      visible={visible}
      subtitle={subtitle}
      title={title}
      titleWeight="bold"
      description={description}
      customizable={true}
      formattedBrand={formattedBrand}
      thumbnails={thumbnails}
      onCancel={handleOnCancel}
      footerContent={[
        <PrimaryButton
          key="customize"
          fullWidth
          onClick={() => onRedirect(url)}
          dataAttrs={{
            id: 'BarcodeNoResultStd_Customize',
          }}
        >
          {t('BarCodeScanModal.ConfirmBtn')}
        </PrimaryButton>,
      ]}
    />
  )
}
