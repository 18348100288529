import { EnforcementMessage } from '@abstractTypes/content'
import { FilterFacet, ProductTypeValues, TogglableProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType, FrameAdvisorTip } from '@abstractTypes/frameAdvisor'
import { Product, StandardProduct } from '@abstractTypes/product'
import config from '@config/index'

export const BEST_MATCH_COUNT = config.frameAdvisorBestMatchCount
const availableTips = Object.values(FrameAdvisorTip)

interface GetSuggestionsOrderProps {
  products: StandardProduct[]
  tipGrids?: FrameAdvisorTip[]
  columns: number
}

export interface FrameAdvisorProduct extends StandardProduct {
  isExtended: boolean
  enforcementMessages?: EnforcementMessage[]
}

type SuggestionTile = FrameAdvisorProduct | FrameAdvisorTip

export const checkIfTipTile = (
  tile: SuggestionTile,
  tipGrids = availableTips
): tile is FrameAdvisorTip => {
  return tipGrids.includes(tile as FrameAdvisorTip)
}

export const getSuggestionsOrder = ({
  products,
  columns,
  tipGrids = availableTips,
}: GetSuggestionsOrderProps) => {
  const modifiedProducts = products.map(p => {
    const enforcementMessages = p.enforcementBullets
    const isExtended = enforcementMessages?.length === BEST_MATCH_COUNT
    return {
      ...p,
      isExtended,
      enforcementMessages,
    }
  })

  const sortedProducts: SuggestionTile[] = []

  let weightCounter = 0
  let i = 0
  let tipI = 0

  const putTip = () => {
    // put tips in loop
    if (tipI < tipGrids.length) {
      const tipTile = tipGrids[tipI]
      sortedProducts.push(tipTile)
      tipI++
    } else {
      const tipTile = tipGrids[0]
      sortedProducts.push(tipTile)
      tipI = 1
    }
  }

  while (i < modifiedProducts.length) {
    const product = modifiedProducts[i]
    const isExtended = product.enforcementMessages?.length === BEST_MATCH_COUNT
    const weight = isExtended ? 2 : 1
    const totWeight = weightCounter + weight

    if (totWeight === columns) {
      sortedProducts.push(product)
      i++
      weightCounter = 0
    }

    if (totWeight < columns) {
      sortedProducts.push(product)
      weightCounter = weightCounter + weight
      i++
    }

    if (totWeight > columns) {
      putTip()
      weightCounter = 0
    }
  }

  // case when last row is not filled
  if (weightCounter > 0) {
    const tipsToAddNumber = columns - weightCounter
    for (let i = tipsToAddNumber; i > 0; i--) {
      putTip()
    }
  }

  return sortedProducts
}

export const isBestMatch = (product: Product) =>
  product.enforcementBullets?.length === BEST_MATCH_COUNT

const faPlaylistToProductTypeMap = {
  [FaPlaylistType.OPTICAL]: ProductTypeValues.OPTICAL,
  [FaPlaylistType.SUN]: ProductTypeValues.SUN,
}

const faProductToPlaylistTypeMap: Record<TogglableProductTypeValues, FaPlaylistType> = {
  [ProductTypeValues.OPTICAL]: FaPlaylistType.OPTICAL,
  [ProductTypeValues.SUN]: FaPlaylistType.SUN,
}

export const playlistTypeToProductType = (playlistType: FaPlaylistType): ProductTypeValues => {
  return faPlaylistToProductTypeMap[playlistType]
}

// TODO: porting oo - we're converting the productType facet into playlistType because we separated productType and playlistType
// on the FE but the BE is still using the productType naming.
// we have to discuss with the BE team, it'd be nice if they can make the same rename too, this way we can remove all the naming conversions
// we have around.
export const filterProductTypeToFilterPlaylistType = (
  facet: FilterFacet
): FilterFacet | undefined => {
  if (facet.kind !== 'productType') {
    return facet
  }
  if (facet.value === ProductTypeValues.SNOWGOGGLES) {
    return undefined
  }

  return {
    kind: 'playlistType',
    value: faProductToPlaylistTypeMap[facet.value as TogglableProductTypeValues],
    count: facet.count,
  }
}
