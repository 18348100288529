import { GreenShipmentIcon } from '@components/core/Icons/GreenShipment'
import { useGreenShipment } from '@data'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { useStoreContext } from '@hooks/useStoreContext'
import { pxToRem } from '@libs/styled'
import React from 'react'
import StripeBanner from '.'
import { GreenShipmentBannerProps } from './types'

const GreenShipmentBanner: React.FC<GreenShipmentBannerProps> = ({
  className,
  takeSpaceWhenEmpty = false,
  icon,
}) => {
  const { data } = useGreenShipment()
  const store = useStoreContext()
  const { zoomLevel, landscapeNavigation, isAlternativeNavigation } = useNavigationContext()
  const isGreenShipmentAvailable = !!store.shippingMethods.find(method => method.code === 'GREEN')
  const isBannerVisible = !!(data && data.greenShipment) && isGreenShipmentAvailable
  const Icon = icon || (
    <GreenShipmentIcon
      height={pxToRem(30, zoomLevel / 100)}
      width={pxToRem(30, zoomLevel / 100)}
      fill="white"
    />
  )

  return (
    <StripeBanner
      className={className}
      takeSpaceWhenEmpty={takeSpaceWhenEmpty}
      backgroundColor={data?.greenShipment?.backgroundColor}
      title={data?.greenShipment?.teaserTitle || ''}
      icon={Icon}
      isVisible={isBannerVisible}
      isSticky={
        zoomLevel < 150 || (zoomLevel >= 150 && !landscapeNavigation && !isAlternativeNavigation)
      }
    />
  )
}

export default GreenShipmentBanner
