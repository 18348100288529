import { isIPadView } from '@libs/utils'
import { useMemo } from 'react'
import { useNavigationContext } from './useNavigationContext'

export const useDeviceType = () => {
  const isIpad = isIPadView()
  const { landscapeNavigation, isAlternativeNavigation } = useNavigationContext()

  const isTowerPortrait = !isAlternativeNavigation && !landscapeNavigation && !isIpad
  const isIpadlandscape = isIpad && landscapeNavigation
  const isTowerLandscape = !isIpad && (isAlternativeNavigation || landscapeNavigation)

  return useMemo(
    () => ({
      isTowerPortrait,
      isIpad,
      isIpadlandscape,
      isTowerLandscape,
    }),
    [isTowerPortrait, isIpad, isIpadlandscape, isTowerLandscape]
  )
}
