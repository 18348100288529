import { KioskAppClickCounterSection } from './style'

export const KioskSettingsOpener = () => {
  let clickCounter = 0
  return (
    <KioskAppClickCounterSection
      onClick={() => {
        clickCounter++
        if ((window.api || window.ipc) && clickCounter === 3) {
          window.api?.invoke('openKioskAppSettings')
          window.ipc?.send('action', 'openKioskAppSettings')
          clickCounter = 0
        }
      }}
    />
  )
}
