import { Device } from '@abstractTypes/utils'
import { AccordionLayout } from '@components/core/Accordion/AccordionLayout'
import { AccordionTitle } from '@components/core/Accordion/styles'
import { ProgressBar } from '@components/ProgressBar'
import { MultiSlidesWrapper, NavigationBtn, SingleSlideWrapper } from '@components/Slider/styles'
import { landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const MerchAccordionStyled = styled(AccordionLayout)<{ isOpened: boolean }>`
  padding-block: 0 ${({ isOpened }) => (isOpened ? pxToRem(52) : 0)};
  padding-inline: ${pxToRem(32)};
  margin-inline: 0;
  position: ${({ isOpened }) => (isOpened ? `fixed` : `static`)};
  inset-block-end: 0;
  background-color: #f2f2f2;
  border-block-end: ${pxToRem(1)} solid ${({ theme }) => theme.colors.outline};

  h4 {
    font-weight: normal;
  }
  ${({ isOpened }) =>
    tablet(`
     padding-block: 0 ${isOpened ? clampValues(36, 52) : 0};
     padding-inline: ${clampValues(24, 32)};
  `)}
  ${({ isOpened }) =>
    landscape(
      Device.tablet,
      `
     padding-block: 0 ${isOpened ? clampValuesLandscape(36, 52) : 0};
     padding-inline: ${clampValuesLandscape(24, 32)};
  `
    )}
  ${({ isOpened }) =>
    zoomPortrait(
      [150, 175],
      `
     padding-block: 0 ${isOpened ? pxToRem(78) : 0};
     padding-inline: ${pxToRem(48)};
  `
    )}
  ${AccordionTitle} {
    padding-block: ${pxToRem(30)};
    padding-inline-start: ${pxToRem(12)};
    ${tablet(`
     padding-block-start: 0;
     padding-block: ${clampValues(24, 30)};
  `)}
    ${landscape(
      Device.tablet,
      `
     padding-block: ${clampValuesLandscape(24, 30)};
  `
    )}
    ${zoomPortrait(
      [150, 175],
      `
     padding-block: ${pxToRem(45)};
  `
    )}
  }
  ${MultiSlidesWrapper}, ${SingleSlideWrapper} {
    padding-block: 0;
    padding-inline: 0;
    padding-inline-start: 0;
    ${tablet(`
      padding-inline: ${clampValues(72, 96)};
    `)}
    ${zoomPortrait(
      [150, 175, 200],
      `
      padding-inline-start: ${pxToRem(112)};
      `
    )}
  }
`
export const MerchBtn = styled(NavigationBtn)`
  opacity: 1;
  background: ${({ theme }) => theme.colors.primaryLight};
  block-size: ${pxToRem(48)};
  inline-size: ${pxToRem(48)};
  padding: 0;
  ${landscape(
    `
      padding: 0;
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
    block-size: ${pxToRem(72)};
    inline-size: ${pxToRem(72)};
  `
  )}
`

export const MerchPrevBtn = styled(MerchBtn)`
  inset-inline-start: 0;
  inset-block-start: 50%;
  transform: translate(${pxToRem(-12)}, -50%);
  ${zoomPortrait(
    [150, 175],
    `
    transform: translate(${pxToRem(-24)}, -50%);
  `
  )}
`

export const MerchNextBtn = styled(MerchBtn)`
  inset-inline-end: 0;
  inset-block-start: 50%;
  transform: translate(${pxToRem(12)}, -50%);
  ${zoomPortrait(
    [150, 175],
    `
     transform: translate(${pxToRem(24)}, -50%);
  `
  )}
`

export const CartMerchProgressBar = styled(ProgressBar)`
  inset-block-end: -${pxToRem(30)};
  inline-size: 25vw;
  ${tablet(`
     inset-block-end: ${clampValues(-24, -30)}};
  `)}
  ${landscape(
    Device.tablet,
    `
     inset-block-end: ${clampValuesLandscape(-24, -30)}};
  `
  )}
  ${zoomPortrait(
    [150, 175],
    `
     inset-block-end: -${pxToRem(45)};
  `
  )}
`

export const MerchAccordionCartPageStyled = styled(MerchAccordionStyled)`
  z-index: 2;
  padding-block: ${({ isOpened }) => (isOpened ? `0 ${pxToRem(68)}` : pxToRem(18))};
  padding-inline: ${pxToRem(100)} ${pxToRem(32)};
  position: fixed;
  ${({ isOpened }) =>
    tablet(`
     padding-block: ${isOpened ? `0 ${clampValues(50, 68)}` : clampValues(12, 18)};
     padding-inline: ${clampValues(70, 80)} 0;
  `)}
  ${({ isOpened }) =>
    landscape(
      Device.tablet,
      `
     padding-block: ${isOpened ? `0 ${clampValuesLandscape(50, 68)}` : clampValuesLandscape(12, 18)};
     padding-inline: ${clampValuesLandscape(70, 80)} 0;
  `
    )}
  ${({ isOpened }) =>
    zoomPortrait(
      [150, 175],
      `
     padding-block: 0 ${isOpened ? pxToRem(78) : 0};
     padding-inline: ${pxToRem(48)};
  `
    )}
  ${AccordionTitle} {
    padding-block: ${pxToRem(30)};
    padding-inline-start: ${pxToRem(24)};
    ${tablet(`
     padding-block-start: 0;
     padding-block: ${clampValues(24, 30)};
     padding-inline-end: ${clampValuesLandscape(24, 32)};
  `)}
    ${landscape(
      Device.tablet,
      `
     padding-block: ${clampValuesLandscape(24, 30)};
     padding-inline-end: ${clampValuesLandscape(24, 32)};
  `
    )}
    ${zoomPortrait(
      [150, 175],
      `
     padding-block: ${pxToRem(45)};
  `
    )}
  }

  ${MerchNextBtn} {
    ${tablet(`
      inset-inline-end: ${clampValuesLandscape(24, 32)};
    `)}
  }

  ${CartMerchProgressBar} {
    inset-block-end: -${pxToRem(36)};
  }
`
