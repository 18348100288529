import { clearCartItemOverlay } from '@actions/cart'
import { ProductCardBase } from '@components/CardProductBase'
import { PRICE_STYLE_TOTAL } from '@components/CardProductBase/ProductCard/ProductPrice'
import { useProductInfoConfigShoppingBag } from '@hooks/tiles'
import { useActions } from '@hooks/useActions'
import { useCart } from '@hooks/useCart'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { dataDescriptionForProduct } from '@libs/utils'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { MiniCartProductCardProps } from './types'

const MiniCartProductCard: React.FC<MiniCartProductCardProps> = ({
  className,
  dataAttrs,
  cartItem,
  removeItemFromCart,
  showRTRIcon,
  showVTOIcon,
}) => {
  const { product, quantity, id } = cartItem
  const analyticsDataDescription = dataDescriptionForProduct(product)
  const getProductInfoConfig = useProductInfoConfigShoppingBag()
  const productInfoConfig = getProductInfoConfig(product.brand.id)
  const { zoomLevel } = useNavigationContext()
  const size = zoomLevel > 175 ? 'extended' : zoomLevel > 125 ? 'large' : 'standard'
  const { cartItemOverlayId } = useCart()
  const showCartOverlay = cartItemOverlayId === id

  const showAddedToBagOverlay = useMemo(
    () => cartItem.id === cartItemOverlayId,
    [cartItemOverlayId, cartItem]
  )

  const OVERLAY_TIMEOUT = 500

  const actions = useActions({ clearCartItemOverlay })
  const t = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (showAddedToBagOverlay) {
      t.current = setTimeout(() => {
        actions.clearCartItemOverlay()
      }, OVERLAY_TIMEOUT)
    }

    return () => {
      clearTimeout(t.current)
    }
  }, [actions, showAddedToBagOverlay])

  const handleRemoveItemFromCart = useCallback(() => {
    if (!id) return
    removeItemFromCart && id && removeItemFromCart(id, true)
  }, [removeItemFromCart, id])

  return (
    <ProductCardBase
      className={className}
      product={product}
      dataAttrs={{ description: analyticsDataDescription, ...dataAttrs }}
      onTrashIconClick={handleRemoveItemFromCart}
      quantity={quantity}
      priceStyle={PRICE_STYLE_TOTAL}
      showCustomizableIcon
      showRTRIcon={showRTRIcon}
      showVTOIcon={showVTOIcon}
      showOverlay={showCartOverlay}
      showIcons
      showTrashIcon
      size={size}
      productInfoConfig={productInfoConfig}
    />
  )
}

export default MiniCartProductCard
