import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

interface Coords {
  x: number
  y: number
}

type KeyboardPositions =
  | 'defaultkeyboardPosition'
  | 'keyboardPositionWCAG'
  | 'keyboardPositionCheckoutWCAG'

const keyboardPositions = {
  defaultkeyboardPosition: { x: -2.4453125, y: -335.53125 },
  keyboardPositionWCAG: { x: -2.4453125, y: -20 },
  keyboardPositionCheckoutWCAG: { x: -170.4453125, y: -20 },
}
export function useVKeyboardCoords(providedPosition?: KeyboardPositions): Coords {
  const accessibility = useSelector(s => s.accessibility)
  const [coords, setCoords] = useState(keyboardPositions.defaultkeyboardPosition)

  useEffect(() => {
    if (accessibility.alternativeNavigation) {
      if (providedPosition && providedPosition in keyboardPositions) {
        setCoords(keyboardPositions[providedPosition])
      } else setCoords(keyboardPositions.keyboardPositionWCAG)
    } else {
      setCoords(keyboardPositions.defaultkeyboardPosition)
    }
  }, [accessibility])

  return coords
}
