import { AppConfig } from '@abstractTypes/envs'
import { getConfigFromSessionStorage } from '@libs/configOverride'
import { getEnvVariablesForBanner } from 'src/variables/default'

// -----------
// // TODO: we introduced this logic only in case we want to migrate banners one by one, but we'll have to remove it all
// const whitelistedBanners: ShopperSlugWithFallback[] = ['opsm', 'master']
// const whitelistedEnvs: DeploymentEnvWithFallback[] = ['stage', 'local']

// // this is for local env
// let shopperSlug = (process.env.REACT_APP_SHOPPER_SLUG || 'master') as ShopperSlugWithFallback
// let deploymentInfo = 'local' as DeploymentEnvWithFallback
// if (!isLocalhost()) {
//   const bannerInfo = getBannerInfoByDns()
//   shopperSlug = bannerInfo.shopperSlug
//   deploymentInfo = bannerInfo.deploymentEnv
// }

const config = getEnvVariablesForBanner()

export const getBrowserStorageKey = () => `ss-${config.shopperSlug}-master`
export const getBrowserLocalStoragePersistentStateKey = () =>
  `${getBrowserStorageKey()}-persistent-state`

export const getBrowserLocalStorageCustomReturnUrlKey = () =>
  `${getBrowserStorageKey()}-custom-return-url`
export const getBrowserSessionStorageConfigOverrideKey = () =>
  `${getBrowserStorageKey()}-config-override`

const configFromSessionStorage = getConfigFromSessionStorage<AppConfig>()
const appConfig = configFromSessionStorage || config
export default appConfig
