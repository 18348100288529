import { IconProps } from '@abstractTypes/icons'

export const InnerglarereductionIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 43"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M16.26 14.9 8.071 6.719l-.87.87 8.186 8.181H8.61V17h8.882V8.127h-1.232V14.9zM35.356 15.77h-6.779l8.187-8.181-.87-.87-8.188 8.18V8.127h-1.231V17h8.881V15.77zM27.706 28.08l8.187 8.18.871-.87-8.187-8.18h6.78v-1.231h-8.882v8.875h1.231V28.08zM8.61 27.21h6.779l-8.187 8.18.87.87 8.187-8.18v6.774h1.232v-8.875H8.61v1.23z"
        fill={fill}
      />
    </svg>
  )
}
