import { CartMerchRecommenderListProps } from '@components/CartMerchRecommender/CartMerchRecommenderList/types'
import MerchCardComponent from '@components/CartMerchRecommender/MerchCardComponent'
import { MerchNoAccordionContainer } from '@components/CartMerchRecommender/MerchCardComponent/styles'
import { Heading5 } from '@components/core/Typography'
import { useDeviceType } from '@hooks/useDeviceType'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { usePageChecker } from '@libs/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CartMerchCarousel } from '../CartMerchCarousel'

export const CartMerchRecommenderList: FC<CartMerchRecommenderListProps> = ({ products }) => {
  const { t } = useTranslation()
  const { isTowerLandscape } = useDeviceType()
  const { isCartPage } = usePageChecker()
  const { zoomLevel } = useNavigationContext()

  return (
    <MerchNoAccordionContainer>
      <Heading5>{t('NewMerch.title')}</Heading5>
      {isCartPage && isTowerLandscape && zoomLevel < 150 ? (
        <CartMerchCarousel products={products} className="cart-merch-carousel" />
      ) : (
        products.map((item, i) => <MerchCardComponent key={i} product={item} />)
      )}
    </MerchNoAccordionContainer>
  )
}
