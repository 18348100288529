import { resetLoaderList } from '@actions/ui'
import { SessionHandoffContent } from '@components/Contents/SessionHandoffContent'
import { Heading4 } from '@components/core/Typography'
import { useActions } from '@hooks/useActions'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Info, ModalHeader, ModalStyled, TextStyled } from './QrCodeModal'

interface TransferSessionModalProps {
  visible: boolean
  onCancel: () => void
}

export const TransferSessionModal: React.FC<TransferSessionModalProps> = ({
  visible,
  onCancel,
}) => {
  const actions = useActions({ resetLoaderList })
  const { t } = useTranslation()

  useEffect(() => {
    if (!visible) {
      actions.resetLoaderList()
    }
  }, [actions, visible])

  return (
    <ModalStyled
      visible={visible}
      onCancel={onCancel}
      showHeader={false}
      dataCalls="0"
      closable={false}
    >
      <ModalHeader>
        <Heading4 weight="bold">{t('HandsOff.modalTitle')}</Heading4>
        <Info>
          <TextStyled weight="bold">{t('HandsOff.modalSubTitle')}</TextStyled>
        </Info>
      </ModalHeader>
      {/*
            TODO:
            The design of the page is not available yet. We need to implement the proper design when available
            */}
      <SessionHandoffContent onScanCompleted={() => onCancel()} />
    </ModalStyled>
  )
}
