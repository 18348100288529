import { IconProps } from '@abstractTypes/icons'


export const SportIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 48"
      stroke={stroke}
    >
      <path
        fill={fill}
        d="M46.776 0L31.225 22.031c-1.077-.653-2.246-1.164-3.487-1.509L42.224 0h-2.449L25.59 20.097C25.067 20.038 24.538 20 24 20c-1.087 0-2.14.137-3.156.372L35.224 0h-2.449L24 12.432 15.224 0h-2.449l10 14.167-2.275 3.224L8.224 0H5.776l13.5 19.125-1.744 2.471c-.258.135-.508.284-.756.435L1.224 0H0v1.735l15.134 21.44C12.003 25.745 10 29.642 10 34c0 7.72 6.28 14 14 14s14-6.28 14-14c0-4.358-2.003-8.255-5.134-10.825L48 1.735V0h-1.224zM24 46c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12z"
      />
      <path fill={fill} d="M25 27l-1-1h-4v2h3v11h-4v2h10v-2h-4V27z" />
    </svg>
  )
}
