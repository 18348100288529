import { BarcodeScanModalBase } from '@components/BarCodeScanModal/Modals/BarcodeScanModalBase'
import { BarcodeScanModalWithRedirectProps } from '@components/BarCodeScanModal/types'
import { PrimaryButton, SecondaryButton } from '@components/core/Button'
import { useStoreIndentity } from '@hooks/useStoreIdentity'
import { getCustomizableBrand, isOOBrand } from '@libs/custom'
import { getCustomProductRoute } from '@libs/routing'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSendBarcodeCustomMocoAvailableAnalyticsEvent } from './analytics'

export const BarcodeScanModalSameModel = ({
  visible,
  onCancel,
  onRedirect,
  productInfo,
}: BarcodeScanModalWithRedirectProps) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const { originalBrandCode, thumbnails, UPC, customizable, model } = productInfo
  const formattedBrand = getCustomizableBrand(originalBrandCode)
  const isOakley = isOOBrand(formattedBrand)

  const customTitle = isOakley
    ? 'BarCodeScanModal.OakleySameModelTitle'
    : 'BarCodeScanModal.RayBanSameModelTitle'
  const customDescriptionPath = isOakley
    ? 'BarCodeScanModal.OakleyDescriptionSameModel'
    : 'BarCodeScanModal.RayBanDescriptionSameModel'

  const customSubtitle = isOakley
    ? 'BarCodeScanModal.OakleySameModelSubtitle'
    : 'BarCodeScanModal.RayBanSameModelSubtitle'
  const title = t(customTitle)
  const subtitle = t(customSubtitle)
  const description = t(customDescriptionPath)
  const { sendCancelModalEvent } = useSendBarcodeCustomMocoAvailableAnalyticsEvent(
    visible,
    isOakley
  )

  const handleOnCancel = useCallback(() => {
    sendCancelModalEvent()
    onCancel()
  }, [onCancel, sendCancelModalEvent])

  const url = getCustomProductRoute({ basePath, brand: formattedBrand, model, UPC })

  return (
    <BarcodeScanModalBase
      visible={visible}
      subtitle={subtitle}
      title={title}
      titleWeight="bold"
      description={description}
      customizable={customizable}
      formattedBrand={formattedBrand}
      thumbnails={thumbnails}
      onCancel={handleOnCancel}
      footerContent={[
        <SecondaryButton fullWidth key="cancel" onClick={handleOnCancel}>
          {t('BarCodeScanModal.showBtnCustom')}
        </SecondaryButton>,
        <PrimaryButton
          key="customize"
          fullWidth
          dataAttrs={{
            id: isOakley
              ? 'BarcodeNoResultOOCustom_Customize'
              : 'BarcodeNoResultRBCustom_Customize',
          }}
          onClick={() => onRedirect(url)}
        >
          {t('BarCodeScanModal.ConfirmBtn')}
        </PrimaryButton>,
      ]}
    />
  )
}
