import { FiltersState, ProductTypeValues, TogglableProductTypeValues } from '@abstractTypes/filter'
import ConditionalRender from '@components/ConditionalRender'
import { useApplyFilters, useProductTypeDefault } from '@hooks/useFilters'
import { usePreselectedFilters, useUserFilters } from '@hooks/usePreselectedFilters'
import { getOppositeProductType } from '@libs/utils'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { ProductTypeToggler } from '.'

interface ProductTypeTogglerWrapper {
  className?: string
  currentValue: ProductTypeValues
  onChange?: (value: ProductTypeValues) => void
  filters: FiltersState
  hasItems?: boolean
}

export const ProductTypeTogglerWrapper: React.FC<ProductTypeTogglerWrapper> = ({
  currentValue,
  hasItems,
  filters,
}) => {
  const preselectedFilters = usePreselectedFilters(filters)
  const productTypeDefault = useProductTypeDefault()
  const userFilters = useUserFilters()
  const [stopAutotoggle, setStopAutoToggle] = useState(false)

  const hasUserFilters = useMemo(
    () => userFilters?.filters && userFilters?.filters?.length > 0,
    [userFilters]
  )

  const oppositeProductType = getOppositeProductType(
    currentValue || productTypeDefault
  ) as TogglableProductTypeValues
  const hasOppositeItems = useMemo(
    () => Boolean(preselectedFilters?.productTypeCounts[oppositeProductType]),
    [preselectedFilters?.productTypeCounts, oppositeProductType]
  )
  const notHasPreselectedFilters = useMemo(
    () => preselectedFilters !== undefined && isEmpty(preselectedFilters.filters),
    [preselectedFilters]
  )

  const isMultiProductTypeAvailable = useMemo(
    () => !!hasItems && !!hasOppositeItems,
    [hasOppositeItems, hasItems]
  )
  const visible = useMemo(
    () => notHasPreselectedFilters || isMultiProductTypeAvailable,
    [isMultiProductTypeAvailable, notHasPreselectedFilters]
  )
  const autoSwitchProductType = useMemo(
    () => hasOppositeItems && !hasItems && !hasUserFilters,
    [hasOppositeItems, hasItems, hasUserFilters]
  )

  const autoToggleProduct = useApplyFilters([
    ...(preselectedFilters?.filters ?? []),
    { kind: 'productType', value: oppositeProductType },
  ])

  useEffect(() => {
    if (!visible && autoSwitchProductType && !stopAutotoggle) {
      autoToggleProduct()
      setStopAutoToggle(true)
    }
  }, [autoSwitchProductType, visible, stopAutotoggle])

  return (
    <ConditionalRender
      condition={visible}
      render={() => (
        <ProductTypeToggler
          preselectedFilters={preselectedFilters?.filters}
          currentValue={currentValue}
          onChange={() => setStopAutoToggle(true)}
        />
      )}
    />
  )
}
