import { Text } from '@components/core/Typography'
import { tabletPortrait } from '@libs/media'
import { pxToRem, theme } from '@libs/styled'
import styled, { css } from 'styled-components'
import { Col } from '../Grid'

export const FormItemWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-block-end: ${pxToRem(24)};
  vertical-align: top;
  ${tabletPortrait(`
     line-height: normal;
  `)}
`
export const LabelWrapper = styled(Col)`
  padding-block-end: 0;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const LabelStyled = styled.label<{ required?: boolean }>`
  margin-block-end: ${pxToRem(10)};
  text-transform: capitalize;
  ${({ required }) =>
    required &&
    css`
      &::before,
      &::after {
        content: '*';
        margin-inline-end: ${pxToRem(8)};
        font-size: ${pxToRem(20)};
        color: ${({ theme }) => theme.colors.functional01};
        line-height: 1;
      }

      &::before {
        display: ${theme('displayRequiredStarBefore', value => {
          return value === 'none' ? 'inline-block' : 'none'
        })};
      }

      &::after {
        display: ${theme('displayRequiredStarBefore')};
      }
    `}
`

export const HelpStyled = styled(Text)`
  text-align: end;
  color: ${({ theme }) => theme.colors.functional01};
  margin-block: ${pxToRem(-5)} ${pxToRem(2)};
  min-block-size: ${pxToRem(22)};
`

export const ValidateWrapper = styled.div`
  &.has-error {
    color: ${({ theme }) => theme.colors.functional01};
  }
`
