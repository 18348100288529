import { TypographyProps } from '@abstractTypes/typography'
import { Device } from '@abstractTypes/utils'
import { zoomLevels, ZOOM_LEVEL_PREFIX } from '@constants/accessibility'
import { defaultTheme } from '@theme/index'
import { css, DefaultTheme, FontObject, SeparatedTheme, Sizes } from 'styled-components'
import { landscape, tablet } from './media'
import { checkColor, clampValues, clampValuesLandscape, pxToRem } from './styled'

const fontSize = (defaultSize: number, customSize?: keyof Sizes, zoomRatio?: number) => {
  const customFontSize = customSize ? defaultTheme.fonts.sizes[customSize] : undefined
  return pxToRem(customFontSize ? customFontSize : defaultSize, zoomRatio)
}

const getTypograpyStyles = (font: FontObject, props: TypographyProps, zoomRatio = 1) => `
    font-size: ${fontSize(font.fontSize.tower, props.textSize, zoomRatio)};
    line-height: ${pxToRem(font.lineHeight.tower, zoomRatio)};
    ${tablet(`
        font-size: ${clampValues(
          props.textSizeTabletMini
            ? defaultTheme.fonts.sizes[props.textSizeTabletMini]
            : font.fontSize.tabletMini,
          props.textSizeTablet
            ? defaultTheme.fonts.sizes[props.textSizeTablet]
            : font.fontSize.tablet,
          zoomRatio
        )};
        line-height: ${clampValues(font.lineHeight.tabletMini, font.lineHeight.tablet, zoomRatio)};
      `)};
    ${landscape(
      Device.tablet,
      `
        font-size: ${clampValuesLandscape(
          props.textSizeTabletMini
            ? defaultTheme.fonts.sizes[props.textSizeTabletMini]
            : font.fontSize.tabletMini,
          props.textSizeTablet
            ? defaultTheme.fonts.sizes[props.textSizeTablet]
            : font.fontSize.tablet,
          zoomRatio
        )};
        line-height: ${clampValuesLandscape(
          font.lineHeight.tabletMini,
          font.lineHeight.tablet,
          zoomRatio
        )};
      `
    )};
  }
`

const getTypograpyZoomStyles = (font: FontObject, props: TypographyProps, selector?: string) =>
  zoomLevels.map(level => {
    const zoomRatio = level / 100
    return css`
      body.${ZOOM_LEVEL_PREFIX}${level} & ${selector || ''} {
        ${getTypograpyStyles(font, props, zoomRatio)}
      }
    `
  })

// Generate defaul font object used on all typography components
export const generateDefaultFontProperties = (
  theme: DefaultTheme,
  font: FontObject,
  props: TypographyProps,
  separatedThemeName?: SeparatedTheme,
  selector?: string
) => {
  const isBold = props.weight === 'bold'
  return css`
    font-family: ${font.fontFamily}, serif;
    font-weight: ${isBold ? font.weight?.bold || font.weight.regular : font.weight.regular};
    color: ${checkColor(props.color || 'primaryDark', theme, separatedThemeName)};
    margin: 0;
    ${getTypograpyStyles(font, props)}
    ${getTypograpyZoomStyles(font, props, selector)}
    ${props.hasBullet &&
    `
        ::before {
          display: inline;
          content: '· ';
        }
    `}
  `
}
