import { Device } from '@abstractTypes/utils'
import { ZOOM_LEVEL_PREFIX, zoomLevels } from '@constants/accessibility'
import { css } from 'styled-components'

export const DeviceMap: { [key in keyof typeof Device]: string } = {
  [Device.tower]: `screen and (min-device-width: 1080px) and (max-device-height: 1920px) and (orientation: portrait),
screen and (min-device-width: 1920px) and (max-device-height: 1080px) and (orientation: landscape)`,
  [Device.ipadMini]:
    'screen and (min-device-width: 768px) and (max-device-width: 1024px) and (max-device-height: 1024px)',
  [Device.ipad11]:
    'screen and (min-device-width: 834px) and (max-device-width: 1194px) and (max-device-height: 1194px)',
  [Device.tablet]:
    'screen and (min-device-width: 768px) and (max-device-width: 1366px) and (max-device-height: 1366px)',
}

export function landscape(device: Device, css: string): string
export function landscape(css: string): string
export function landscape(cssOrDevice: Device | string, css?: string): string {
  if (Object.values(Device).indexOf(cssOrDevice as Device) > -1) {
    return `
      body.landscape-navigation & {
        @media ${DeviceMap[cssOrDevice as Device]} {${css}}
      }
    `
  } else {
    return `body.landscape-navigation & {${cssOrDevice}}`
  }
}

export function tabletPortrait(css: string): string
export function tabletPortrait(device: Device, css: string): string
export function tabletPortrait(cssOrDevice: Device | string, css?: string): string {
  const mediaQuery =
    cssOrDevice in Device ? DeviceMap[cssOrDevice as Device] : DeviceMap[Device.tablet]

  const cssProperties = css || cssOrDevice

  return `
  @media ${mediaQuery} and (orientation: portrait) {
    ${cssProperties}
  }
`
}

export function tablet(device: string, css: string): string
export function tablet(css: string): string
export function tablet(cssOrDevice: string, css?: string): string {
  const mediaQuery =
    cssOrDevice in Device ? DeviceMap[cssOrDevice as Device] : DeviceMap[Device.tablet]

  const cssProperties = css || cssOrDevice

  return `
    @media ${mediaQuery} {
      ${cssProperties}
    }
  `
}

export function altNavigation(css: string): string {
  return `body.alternative-navigation & {${css}}`
}

export function altNavigationZoom(zoomLevel: (typeof zoomLevels)[number][], cssRules: string) {
  return zoomLevel.map(level => {
    return css`
      body.alternative-navigation.${ZOOM_LEVEL_PREFIX}${level} & {
        ${cssRules}
      }
    `
  })
}

export function zoomPortrait(zoomLevel: (typeof zoomLevels)[number][], cssRules: string) {
  return zoomLevel.map(level => {
    return css`
      body.${ZOOM_LEVEL_PREFIX}${level} & {
        ${cssRules}
      }
    `
  })
}

export function zoomLandscape(zoomLevel: (typeof zoomLevels)[number][], cssRules: string) {
  return zoomLevel.map(level => {
    return css`
      body.landscape-navigation.${ZOOM_LEVEL_PREFIX}${level} & {
        ${cssRules}
      }
    `
  })
}

/**
 HOW TO USE THESE HELPER FUNCTIONS:

 * ? ipadPro was removed as it is defaulting to the tablet media query

  here's an example:

  const NewComponent = styled.div`
    // This is the default style used for tower
    color: red;

    // This is the default style used for tablets (so, by default, all the ipads)
    ${tablet("color: blue")}

    // If we need to overwrite some properties for the smaller ipad mini we can do it by calling the tablet function with the device as first parameter and this will overwrite the default (tablet) properties applied above (if on an ipad mini)
    ${tablet(Device.ipadMini, "color: green")}

    // This landscape is the function to use for landscape Mode and the style present in here will be applied for every device in landscape
    ${landscapeMode("color: lightblue; background: blue")}

    // And again, if we need to overwrite some properties for the ipadMini (when on landscape mode) we can do it by calling the landscape function with the device as first parameter (like above)
    ${landscapeMode(Device.ipadMini, "color: lightgreen; background: green")}
  `;

**/

type DirectionArgs = { ltr: string; rtl: string } | string

const convertDirectionArgs = (arg: DirectionArgs) => {
  let ltr, rtl
  if (typeof arg === 'string') {
    ltr = arg
    rtl = arg
  } else {
    ltr = arg.ltr
    rtl = arg.rtl
  }
  return { ltr, rtl }
}

export const transformTranslateX = (arg: DirectionArgs) => {
  const { ltr, rtl } = convertDirectionArgs(arg)
  return `
    transform: translateX(${ltr});
    [dir='rtl'] & {
      transform: translateX(${rtl});
    }
  `
}

export const insetInlineStart = (arg: DirectionArgs) => {
  const { ltr, rtl } = convertDirectionArgs(arg)
  return `
  inset-inline-start: ${ltr};
    [dir='rtl'] & {
      inset-inline-start: ${rtl};
    }
  `
}
