import { DataAttrs, Product } from '@abstractTypes/product'
import { BadgeBestMatch } from '@components/BadgeBestMatch'
import ConditionalRender from '@components/ConditionalRender'
import { BestMatchIcon } from '@components/core/Icons'
import { LinkTypography } from '@components/core/Typography'
import { useGetProductUrl } from '@libs/formatters'
import { useTranslation } from 'react-i18next'
import { BestMatchText } from './BestMatchText'
import { BestMatchDetails, DiscoverMoreLink } from './styles'

interface BestMatchInfoProps {
  product: Product
  dynamicSentence?: string
  dataAttrs?: DataAttrs
  isDressedOnView?: boolean
}

export const BestMatchInfo: React.FC<BestMatchInfoProps> = ({
  dynamicSentence,
  dataAttrs,
  product,
  isDressedOnView,
}) => {
  const { t } = useTranslation()
  const getProductUrl = useGetProductUrl()
  const url = getProductUrl(product.url)

  return (
    <BestMatchDetails isDressedOnView={isDressedOnView}>
      <BadgeBestMatch>
        <BestMatchIcon />
      </BadgeBestMatch>
      <ConditionalRender
        condition={dynamicSentence}
        render={val => (
          <BestMatchText
            dynamicSentence={val}
            product={product}
            isDressedOnView={isDressedOnView}
          />
        )}
      />

      <ConditionalRender
        condition={!isDressedOnView}
        render={() => (
          <DiscoverMoreLink
            to={`${url}?fromFrameAdvisor=true`}
            aria-labelledby={`productCard_${product?.UPC}`}
            data-element-id={dataAttrs?.link}
            data-test={dataAttrs?.link}
            data-description={dataAttrs?.description}
          >
            <LinkTypography
              textSize="body"
              textSizeTablet="bodyTablet"
              textSizeTabletMini="bodyTabletMini"
            >
              {t('FrameAdvisor.discoverMore')}
            </LinkTypography>
          </DiscoverMoreLink>
        )}
      />
    </BestMatchDetails>
  )
}
