import { IconProps } from '@abstractTypes/icons'

export const LanguagesIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 46 42"
      stroke={stroke}
    >
      <path
        fill="#000"
        d="M24.812 27.396l-5.292-5.23.063-.062c3.625-4.041 6.208-8.687 7.73-13.604h6.103V4.333H18.833V.167h-4.167v4.166H.083V8.48h23.27c-1.395 4.021-3.603 7.834-6.603 11.167-1.938-2.146-3.542-4.5-4.813-6.98H7.771c1.52 3.396 3.604 6.605 6.208 9.5L3.375 32.626l2.958 2.958L16.75 25.167l6.479 6.479 1.583-4.25zm11.73-10.563h-4.167l-9.375 25h4.166l2.334-6.25h9.895l2.355 6.25h4.166l-9.375-25zm-5.459 14.584l3.375-9.021 3.375 9.02h-6.75z"
      />
    </svg>
  )
}
