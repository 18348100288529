import { CurrencyCode, Store } from '@abstractTypes/graphqlTypes'
import ErrorWidget from '@components/ErrorWidget'
import Loading from '@components/Loading'
import { useStoreData } from '@data'
import { parseGrapqhQLError } from '@libs/errors'
import createDebug from 'debug'
import React from 'react'

interface StoreContextData {
  store: Store
}

export const StoreContext = React.createContext<StoreContextData>({
  store: {
    id: '',
    disabledCustomizers: [],
    packagingEnabled: true,
    url: '',
    fsaArea: '',
    langCode: '',
    defaultLangCode: '',
    storeId: '',
    globalStoreId: '',
    storeType: '',
    name: '',
    brands: [],
    languages: [''],
    currency: {
      id: CurrencyCode.Eur,
      label: '',
    },
    solrCore: '',
    vtoEnabled: false,
    vtoIPadEnabled: false,
    vtoMonitorEnabled: false,
    engravingEnabled: false,
    address: '',
    city: '',
    postalCode: '',
    telephone: '',
    countryId: '',
    region: '',
    states: [],
    addressGeocode: false,
    digitalCatalog: false,
    subscriptionsEnabled: false,
    rbCustomEnabled: false,
    ooCustomEnabled: false,
    nuanceEnabled: true,
    nuanceAccessoriesEnabled: true,
    supernovaEnabled: false,
    supernovaPurchaseEnabled: false,
    mixMatchEnabled: false,
    mixMatchPrescriptionEnabled: false,
    rtrEnabled: false,
    brandEditorialList: [],
    brandSegregationList: [],
    storeSegment: '',
    checkoutFields: [],
    editorialContentEnabled: false,
    shippingMethods: [
      {
        code: '',
        translationKey: '',
        estimations: {
          min: '',
          max: '',
        },
        fee: 0,
        upc: '',
        destinationAvailability: {
          home: false,
          store: false,
        },
      },
    ],
    pdpRealtimeCheckEnabled: false,
    requiredCheckoutPostcode: false,
    adaWcagEnabled: false,
    priceRanges: [],
    hideCheckoutOrderNumber: false,
    subdivisionSearchTabOrder: [],
    defaultAvailableInStoreOpt: false,
    defaultAvailableInStoreSun: false,
  },
})

const debugError = createDebug('error:store')

export const StoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data, loading, error } = useStoreData()

  if (error) {
    debugError(error)
    return <ErrorWidget {...parseGrapqhQLError(error)} withWrapper />
  }

  if (!data?.store || loading) return <Loading />

  return <StoreContext.Provider value={{ store: data.store }}>{children}</StoreContext.Provider>
}
