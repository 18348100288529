import { Product } from '@abstractTypes/product'
import { usePrescriptionEnabled } from '@hooks/usePrescriptionEnabled'

export const useShowPrescriptionSwitch = (product: Product) => {
  const isPrescriptionEnabled = usePrescriptionEnabled()
  return Boolean(isPrescriptionEnabled && product.roxable)
}

export const useShowPrescriptionSwitchBasedOnStock = (
  product: Product,
  hasPrescriptionStock: boolean
) => {
  const shouldShowPrescriptionSwitch = useShowPrescriptionSwitch(product)
  return Boolean(shouldShowPrescriptionSwitch && hasPrescriptionStock)
}
