const productCard = {
  border: 'none',
  color: '#4f4f4f',
  backgroundColor: '#F6F6F6',
  priceColor: 'inherit',
  colorsColor: 'inherit',
  iconAlignment: 'left',
  iconsHeight: '1.6rem',
  iconsMarginBottom: '0',
  cardPadding: '0.6rem',
  imageMarginBottom: '0rem',
  imageMaxWidth: '100%',
  rowGutter: '0rem',
  priceOrder: 'initial',
  quantityOrder: 'initial',
  brandNameOrder: 'initial',
  modelCodeOrder: 'initial',
  modelNameOrder: 'initial',
  colorsOrder: 'initial',
  prescriptionOrder: 'initial',
  primaryFont: 'Roboto,Helvetica,Arial,sans-serif',
  secondaryFont: 'Amiri,Helvetica,Arial,sans-serif',
  altPrimaryFont: 'ScoutCond,Helvetica,Arial,sans-serif',
  altSecondaryFont: '"MorePro-CondBook",Helvetica,Arial,sans-serif',
}

export default productCard
