import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getOpsmEnvVariables = () => {
  const opsmEnvVariables: AppConfigOverride = {
    shopperSlug: 'opsm',
    shopperName: 'opsm',
    storeID: 'B270',
    storeLang: 'en-GB',
    default: {
      productType: ProductTypeValues.OPTICAL,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    showExtraPdpDetails: true,
    vmStore: 'opsm',
    vmStyle: 'opsm',
  }

  return opsmEnvVariables
}

export default getOpsmEnvVariables
