import { Device } from '@abstractTypes/utils'
import { Heading5, Text } from '@components/core/Typography'
import { NuanceHeading5 } from '@components/core/Typography/NuanceTypography'
import { landscape } from '@libs/media'
import { pxToRem } from '@libs/styled'
import styled from 'styled-components'

export const Value = styled(Heading5)`
  text-align: start;
`

export const NuanceValue = styled(NuanceHeading5)`
  text-align: start;
`

export const DetailRowContainer = styled(Text)`
  display: grid;
  grid-template-columns: 40% 50%;
  grid-gap: 10%;
  line-height: 1.318rem;
  ${landscape(
    Device.tablet,
    `
    grid-gap: ${pxToRem(30)}
  `
  )}
`

export const DetailRowContainerFull = styled(Text)`
  line-height: 1.318rem;

  strong,
  span {
    display: block;
  }
`
