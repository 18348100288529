import { IconProps } from '@abstractTypes/icons'

export const CaretRightIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.39047 31.6642C2.17152 32.4453 3.43785 32.4453 4.2189 31.6642L18.2189 17.6642C19 16.8832 19 15.6168 18.2189 14.8358L4.2189 0.835787C3.43785 0.0547371 2.17152 0.0547371 1.39047 0.835787C0.609425 1.61683 0.609425 2.88317 1.39047 3.66421L13.9763 16.25L1.39047 28.8358C0.609425 29.6168 0.609425 30.8832 1.39047 31.6642Z"
        fill={fill}
      />
    </svg>
  )
}
