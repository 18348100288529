import {
  ProductInfoConfigStyleAlign,
  ProductInfoConfigStyleTransform,
  ProductInfoConfigStyleView,
} from '@abstractTypes/tiles'
import { Device } from '@abstractTypes/utils'
import { BadgeChip, BadgeChips } from '@components/CardProductBase/BadgeChips'
import { ImageSize, ImageSizeDressedOn } from '@components/CardProductBase/ProductCard/index'
import { Heading4, Text } from '@components/core/Typography'
import { altNavigation, landscape, tablet, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

export const ICON_SIZE = pxToRem(32)
export const IMAGE_RATIO = '75%'
export const DRESSED_ON_IMAGE_RATIO = '133%'

export const ProductOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  inline-size: 100%;
  block-size: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`

export const ProductOverlayContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: ${pxToRem(16)};
`

export const ProductOverlayHeading = styled(Heading4)`
  color: #fff;
`

const cardWidthStandard = css`
  inline-size: ${pxToRem(416)};
  ${landscape('inline-size: unset;')}
  ${tablet(`inline-size: ${clampValues(303, 404)};`)}
  ${landscape(Device.tablet, 'inline-size: unset;')}
  ${altNavigation(`inline-size: ${pxToRem(404)};`)};
`
const cardWidthSmall = css`
  ${cardWidthStandard}
  ${landscape(Device.tablet, 'inline-size: unset;')}
  ${altNavigation(`inline-size: ${pxToRem(270)};`)};
`

const cardWidthLarge = css`
  inline-size: ${pxToRem(624)};
  ${tablet(`inline-size: ${clampValues(387, 518)};`)}
  ${altNavigation(`inline-size: ${pxToRem(606)};`)};
`

const cardWidthHuge = css`
  inline-size: ${pxToRem(856)};
  ${landscape(`inline-size: ${pxToRem(580)};`)}
  ${tablet(`inline-size: ${clampValues(516, 689)};`)}
  ${landscape(Device.tablet, `inline-size: ${clampValuesLandscape(420, 450)};`)}
`

const cardWidthExtended = css`
  inline-size: ${pxToRem(832)};
  ${landscape(`inline-size: ${pxToRem(832)};`)}
  ${tablet(`inline-size: ${clampValues(624, 832)};`)}
  ${landscape(Device.tablet, `inline-size: ${clampValuesLandscape(624, 832)};`)}
  ${altNavigation(`inline-size: ${pxToRem(832)};`)};
`

const cardWidthMassive = css`
  inline-size: ${pxToRem(1120)};
`
const cardWidthDressedOn = css`
  ${landscape(`inline-size: ${pxToRem(406)};`)}
`
const cardWidthDressedOnZoom = css`
  ${landscape(`inline-size: ${pxToRem(507)};`)}
`

const cardWidthMap = {
  small: cardWidthSmall,
  standard: cardWidthStandard,
  large: cardWidthLarge,
  huge: cardWidthHuge,
  extended: cardWidthExtended,
  massive: cardWidthMassive,
}

const cardWidthMapDressedOn = {
  standard: cardWidthDressedOn,
  large: cardWidthDressedOnZoom,
  extended: cardWidthDressedOnZoom,
}

export const CardProductContainerStyled = styled.div<{
  size: ImageSize
  isDressedOn: boolean
}>`
  ${({ size, isDressedOn }) =>
    isDressedOn ? cardWidthMapDressedOn[size as ImageSizeDressedOn] : cardWidthMap[size]};
  ${({ isDressedOn }) =>
    landscape(Device.tablet, `inline-size: ${isDressedOn ? `${pxToRem(280)}` : '100%'}`)}

  flex-shrink: 0;
  padding: 0;
  min-block-size: 20vmin;
  color: ${({ theme }) => theme.productCard.color};
  border: ${({ theme }) => theme.productCard.border};
  display: flex;
  flex-direction: column;
  position: relative;
  isolation: isolate;
`

export const IconsStyled = styled.div`
  inline-size: 100%;
  block-size: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
`

const IconsContainer = styled.div`
  inline-size: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, ${ICON_SIZE});
  grid-gap: ${pxToRem(16)};
  position: absolute;
  padding: ${pxToRem(16)};
  & > * {
    z-index: 2;
  }
  ${zoomPortrait(
    [150, 175],
    `
    grid-gap: ${pxToRem(48)};
    padding: ${pxToRem(24)};
  `
  )}
  ${zoomPortrait(
    [200],
    `
    grid-gap: ${pxToRem(48)};
    padding: ${pxToRem(32)};
  `
  )}
`

export const IconsBottomLeft = styled(IconsContainer)`
  inset-inline-start: 0;
  inset-block-end: 0;
`

export const IconsTopRight = styled(IconsContainer)<{ rtlDirection: boolean }>`
  inset-block-start: 0;
  inset-inline-end: 0;
  direction: ${({ rtlDirection }) => (rtlDirection ? 'ltr' : 'rtl')};
`

export const TrashButton = styled.button<{ order?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  grid-column: ${({ order }) => (order ? order : 'auto')};
  border: none;
  background-color: transparent;
  z-index: 3;
`

export const CheckedIcon = styled.div<{ order?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  grid-column: ${({ order }) => (order ? order : 'auto')};
  z-index: 3;
`

export const IconStyled = styled.img`
  block-size: 100%;
  inline-size: auto;
`

export const CardBodyStyled = styled.div`
  padding-block: ${pxToRem(16)};
  padding-inline: 0;
  inline-size: 100%;
  align-items: stretch;
  ${landscape(
    Device.tablet,
    `
  padding-block: ${clampValuesLandscape(12, 16)};
  padding-inline: 0;
  `
  )}
`

const productInfoGridView = css`
  grid-template-columns: auto min-content;
  grid-template-rows: repeat(2, minmax(0, auto));

  & > :nth-child(even) {
    justify-self: end;
  }
`

export const ProductInfoPDP = styled.div<{ isEditorial?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  padding-block: 0;
  padding-inline: ${pxToRem(112)};
  block-size: ${pxToRem(400)};
  ${({ isEditorial }) =>
    landscape(`
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: ${pxToRem(1259)};
    text-align: start;
    padding-block: 0;
    padding-inline: 0 ${pxToRem(112)};
    align-items: flex-start;
    inline-size: auto;
    block-size: 100%;
    padding-block-start:  ${isEditorial ? pxToRem(73) : pxToRem(73 + 164)};
  `)}
  ${tablet(`
    block-size: ${clampValues(297, 404)};
  `)}
  ${({ isEditorial }) =>
    landscape(
      Device.tablet,
      `
      padding-block-start: ${
        isEditorial ? clampValuesLandscape(41, 32) : clampValuesLandscape(77 + 94, 129 + 114)
      };
      inset-inline-start: ${clampValuesLandscape(670, 894)};
      padding-inline-end: ${pxToRem(20)}
    `
    )}
`

export const ProductCardInfo = styled.div<{
  view?: ProductInfoConfigStyleView
  align?: ProductInfoConfigStyleAlign
}>`
  display: grid;
  color: ${({ theme }) => theme.colors.primaryDark};
  ${({ view }) => view != 'list' && productInfoGridView};
  text-align: ${({ align }) => align || 'start'};
  row-gap: 0.375rem;
`

const highlightDetail = `
  text-transform: uppercase;
`

const ModuleInfoContentStyle = css`
  overflow: hidden;
  white-space: nowrap;
  max-inline-size: 100%;
`

export const ModuleInfoContent = styled(Text)<{ transform?: ProductInfoConfigStyleTransform }>`
  ${ModuleInfoContentStyle}
  text-transform: ${({ transform }) => transform || 'none'};
`

export const PriceStyled = styled.div<{ transform?: ProductInfoConfigStyleTransform }>`
  ${ModuleInfoContentStyle}
  text-transform: ${({ transform }) => transform || 'none'};
`

export const BrandStyled = styled(ModuleInfoContent)``

export const NameStyled = styled(ModuleInfoContent)`
  text-overflow: ellipsis;
`

export const QuantityStyled = styled(ModuleInfoContent)``

export const ModelStyled = styled(ModuleInfoContent)<{ highlight: boolean }>`
  ${({ highlight }) => (highlight ? highlightDetail : '')}
`

export const CardProductLinkStyled = styled.a`
  z-index: 2;
  inline-size: 100%;
  block-size: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: block;
`

export const BaseButton = styled.button`
  appearance: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
`

export const VisuallyHidden = styled(Text)`
  position: absolute;
  margin: 0;
  padding: 0;
  inline-size: 1px;
  block-size: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
`

export const ColorTitle = styled(ModuleInfoContent)`
  text-overflow: ellipsis;
`

export const AddBuyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 3;
  margin-block-start: ${pxToRem(10)};
`

// TODO: the && lets the Chips component style to override the inherited style from BadgeChips
// it's kind of an important, let's check why we this is the only spot where we need this solution
export const Chips = styled(BadgeChips)`
  && {
    position: absolute;
    inset-inline-start: ${pxToRem(16)};
    inset-block-start: ${pxToRem(16)};
    margin: 0;
    z-index: 1;
    ${tablet(`inset-block-start: ${clampValues(10, 16)};`)}
    ${landscape('margin-block-start: 0;')}
    ${landscape(Device.tablet, `inset-block-start: ${clampValuesLandscape(12, 16)};`)}

    & > ${BadgeChip} {
      margin-block: 0 ${pxToRem(8)};
      margin-inline: 0 ${pxToRem(8)};
      padding-block: 0;
      padding-inline: ${pxToRem(12)};
    }
  }
`
