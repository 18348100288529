import { default as React } from 'react'
import { useTranslation } from 'react-i18next'
import { KioskAppClickCounterSection } from '../KioskAppClickCounterSection/style'
import { Heading3, Heading5, Text } from '../core/Typography'
import { MessageBox, MessageBoxErrorDetails, MessageBoxHeader, OfflinePageWrapper } from './styles'

export const OfflineWarning: React.FC = () => {
  const { t } = useTranslation()
  return (
    <OfflinePageWrapper>
      <KioskAppClickCounterSection />
      <MessageBox>
        <MessageBoxHeader>
          <Heading3>{t('offline.title')}</Heading3>
        </MessageBoxHeader>
        <MessageBoxErrorDetails>
          <Heading5>{t('offline.errorHead')}</Heading5>
          <Text>{t('offline.errorMessage')}</Text>
        </MessageBoxErrorDetails>
      </MessageBox>
    </OfflinePageWrapper>
  )
}
