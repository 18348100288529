import { Device } from '@abstractTypes/utils'
import { altNavigation, landscape, tablet, transformTranslateX, zoomPortrait } from '@libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from '@libs/styled'
import styled, { css } from 'styled-components'

const transition = css`
  &,
  &.visible-enter {
    opacity: 0;
  }

  &.visible-enter-active,
  &.visible-enter-done {
    opacity: 1;
    transition: opacity 100ms;
  }
`

const commonMessageStyle = css`
  display: block;
  position: fixed;
  color: #fff;
  background: rgb(0 0 0 / 90%);
  ${transformTranslateX({ ltr: '-50%', rtl: '50%' })}
  z-index: 10;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 30%);
  border-radius: ${pxToRem(74)};
`

export const MessageWrapper = styled.div`
  inset-block-start: ${pxToRem(635)};
  inset-inline-start: 50%;
  inline-size: ${pxToRem(665)};
  block-size: auto;
  font-size: 1.05rem;
  ${commonMessageStyle}
  ${transition}
  ${zoomPortrait(
    [150, 175, 200],
    `
      inline-size: ${pxToRem(818)};
  `
  )}
  ${landscape(
    `
    inset-block-start: ${pxToRem(335)};
  `
  )}
  ${altNavigation(`
      inset-block-start: calc(56vh + ${pxToRem(240)});
  `)}
`

export const IPadMessageWrapper = styled.div`
  inset-block-start: ${pxToRem(390)};
  inset-inline-start: 50%;
  inline-size: ${pxToRem(540)};
  block-size: ${pxToRem(65)};
  ${commonMessageStyle}
  ${transition}
  ${tablet(
    `
    inset-block-start: ${clampValues(400, 535)};
    inline-size: ${clampValues(500, 540)};
  `
  )}
    ${tablet(
    Device.ipadMini,
    `
    block-size: ${pxToRem(54)};

  `
  )}
  ${landscape(
    Device.tablet,
    `
    inset-block-start: ${clampValuesLandscape(150, 215)};
    inline-size: ${clampValuesLandscape(648, 670)};
  `
  )}
`
