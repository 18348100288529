import { OOCustomRecipe, RBCustomRecipe } from '@abstractTypes/customRecipe'
import { History } from 'history'

export type HeightSetter = (height: string) => void

export interface ReactOneConfiguratorInnerProps extends ReactOneConfiguratorProps {
  setFrameHeight?: HeightSetter
  customCss?: string
  containerId?: string
}

export interface ReactOneConfiguratorProps {
  children?: JSX.Element
  fluidJS: string
  containerId?: string
  configureParams: ConfigureParams
  customCss?: string
  initialHeight?: string
  history?: History
}

export interface ConfigureParams {
  UPC?: string
  skipOnMissingUpc?: boolean
  styleSelector?: boolean
  inStore?: boolean
  showCloseCTA?: boolean
  skipBeforeUnload?: boolean
  startWithStyleSelector?: boolean
  addToCartClick?: (recipe: OOCustomRecipe & RBCustomRecipe, callback: () => void) => void
  addToWishesClick?: (recipe: OOCustomRecipe & RBCustomRecipe, callback: () => void) => void
  closeConfigure?: () => void
  currency: string
  endpoint: string
  locale: string
  numberFormat: string
  ocHierarchy: string
  ocId: string
  region: string
  showAfterPay?: () => void
  vendorId?: string
  recipeId?: string
  disabilitiesAid?: boolean
  vmEnv?: string
  arabian: boolean
}

export interface IBlobData {
  localized_configuration: RBLocalizedConfiguration | OOLocalizedConfiguration
}

export interface IProductAttribute {
  lensColor: string | undefined
  lensColor2: string | undefined
  frameColor: string | undefined
  size: string | undefined
  polarized: boolean
  jawColor: string | undefined
  templeLongColor: string | undefined
  stemsColor: string | undefined
  iconColor: string | undefined
  iconColor2: string | undefined
  templeTipsColor: string | undefined
  frameFrontColor: string | undefined
  clipsColor: string | undefined
}

export interface OOLocalizedConfiguration {
  ocp_jaw: string
  ocp_temple_long_ref: string
  ocp_clip: string
  polarized: string
  ocp_stems: string
  ocp_lens_ref: string
  ocp_lens_ref_2?: string
  ocp_icon: string
  ocp_icon_2?: string
  ocp_unobtainium?: string
  background?: string
  accept_terms: string
  text_etch_color?: string
  text_shape?: string
  rotation: string
  alignment: string
  ocp_etch_sp_logo: string
  ocp_etching_font: string
  ocp_etch_nfl_logo: string
  region: string
  ocp_microbag: string
  ocp_customization_upcharge: string
  outlet: string
  ocp_leash_show: string
  leash_select_show: string
  leash_select: string
  ocp_leash: boolean
  etch_or_logo: boolean | string
  ocp_etching_text: boolean | string
  font: boolean | string
  text_etch_size?: boolean
  position: boolean | string
  text_path?: boolean
  text_range?: boolean
  text_stride?: boolean
  etching_selector: boolean
  etching_selector_2: boolean
  size: string
  color?: string
  background_standard?: string
  ocp_wicket_ref?: string
  ocp_frame_ref: string
}

export interface RBLocalizedConfiguration {
  size: string
  frame: string
  frame_sku: string
  lenses: string
  lenses_category: string
  lenses_sku: string
  polarized: string
  temple_tips: string
  temple_tips_sku: string
  render: string
  shadow: string
  lenses_render: string
  frame_render: string
  temple_tips_render: string
  engraving: string
  choose_your_font_engraving: string
  internal_right: boolean | string
  internal_left: boolean | string
  external_left: boolean | string
  external_right: boolean | string
  engraving_type: string
  standard_engraving_font_render_color: string
  special_characters: string
  choose_your_font_selector_engraving: string
  case: string
  case_category: string
  case_sku: string
  choose_your_font_case: string
  case_engraving_line_1: string
  case_engraving_line_2: string
  case_engraving_font_render_color: string
  special_characters_case: string
  choose_your_font_case_engraving: string
  data: string
  case_engraving_data_line_1: string
  case_engraving_data_line_2: string
  standard_engraving_data_external_left: string
  standard_engraving_data_internal_left: string
  standard_engraving_data_internal_right: string
  standard_engraving_data_external_right: string
  art_engraving_data_external_left: string
  art_engraving_data_internal_left: string
  art_engraving_data_internal_right: string
  art_engraving_data_external_right: string
  outlet: string
  prescription_show: string
  lenses_options_show: string
  case_category_show: string
  choose_your_font_case_engraving_show: string
  special_characters_case_show: string
  case_engraving_line_1_show: string
  case_engraving_line_2_show: string
  case_show: string
  premium_packaging_card_show: string
  premium_packaging_envelope_show: string
  premium_packaging_box_small_show: string
  select_language_show: string
  select_language_case_show: string
  customization_upcharge: string
  prescription: boolean
  lenses_options: boolean
  select_language: boolean
  choose_font_color_engraving: boolean | string
  art_engraving_position: boolean | string
  art_engraving_external_left: boolean
  art_engraving_internal_left: boolean | string
  art_engraving_internal_right: boolean | string
  art_engraving_external_right: boolean
  external_left_ugc: boolean
  external_right_ugc: boolean
  internal_left_ugc: boolean
  internal_right_ugc: boolean
  external_left_apac: boolean
  internal_left_apac: boolean
  internal_right_apac: boolean
  external_right_apac: boolean
  select_language_case: boolean
  case_engraving_line_1_ugc: boolean
  case_engraving_line_2_ugc: boolean
  case_engraving_line_1_apac: boolean
  case_engraving_line_2_apac: boolean
  art_engraving_upcharge: boolean | string
  metal_details_render?: string
}

export enum OOThumbnailSide {
  Left = 'Left',
  Right = 'Right',
  Front = 'Front',
  Back = 'Back',
  Microbag = 'Microbag',
}

export enum RBThumbnailSide {
  FrontLeft = 'FFL',
  Frontal = 'FF',
  BackLeft = 'BLLL',
  BackRight = 'BRRR',
  Left = 'LL',
  Right = 'RR',
}

declare global {
  interface Window {
    configureParams?: ConfigureParams
    smartshopperLoadedFluidScript?: string[]
  }
}
