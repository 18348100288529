import { ModalProps } from '@abstractTypes/modal'
import ConditionalRender from '@components/ConditionalRender'
import { CloseIcon } from '@components/core/Icons'
import { useNavigationContext } from '@hooks/useNavigationContext'
import { pxToRem } from '@libs/styled'
import { isIPadView } from '@libs/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBase } from './ModalBase'
import {
  CloseIconContainer,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from './styles'

const CloseIconWrapper: React.FC = () => {
  const { zoomLevel } = useNavigationContext()

  return (
    <CloseIcon
      height={pxToRem(isIPadView() ? 27 : 32, zoomLevel / 100)}
      width={pxToRem(isIPadView() ? 27 : 32, zoomLevel / 100)}
    />
  )
}

export const Modal: React.FC<ModalProps> = ({
  overlayVisible = true,
  visible,
  className,
  children,
  onCancel,
  width,
  dataAttr,
  dataDescription,
  dataCalls = '0',
  renderCloseIcon = <CloseIconWrapper />,
  centered = true, // position of the modal in the page
  centeredContent = true,
  aria,
  showCloseButton = true,
  footerContent = [],
  centeredTitle = true,
  fixedHeader = false,
  showHeader = true,
  title,
  style,
  destroyOnClose = true,
  closable = true,
  onEntered,
}) => {
  const header = showHeader && <ModalHeader centeredTitle={centeredTitle}>{title}</ModalHeader>
  const { t } = useTranslation()

  return (
    <ModalBase
      mask={overlayVisible}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      className={className}
      centered={centered}
      closable={closable}
      width={width || 'auto'}
      aria={aria}
      style={style}
      onEntered={onEntered}
    >
      {fixedHeader && header}
      <ModalWrapper>
        {!fixedHeader && header}
        {showCloseButton && (
          <CloseIconContainer
            data-element-id={dataAttr}
            data-test={dataAttr}
            data-description={dataDescription}
            data-analytics_available_call={dataCalls}
            onClick={onCancel}
            aria-label={t('ARIA.closeIcon.label')}
          >
            {renderCloseIcon}
          </CloseIconContainer>
        )}
        <ModalBodyWrapper centeredContent={centeredContent}>{children}</ModalBodyWrapper>
        <ConditionalRender
          condition={footerContent.length}
          render={() => <ModalFooter>{footerContent}</ModalFooter>}
        />
      </ModalWrapper>
    </ModalBase>
  )
}
