import { AppConfigOverride } from '@abstractTypes/envs'

const getSghofEnvVariables = () => {
  const sghofEnvVariables: AppConfigOverride = {
    shopperSlug: 'sghof',
    shopperName: 'SUNGLASSHUT OUTFITTERS',
    storeID: '12161',
    storeLang: 'en-US',
    toggleFeature: {
      availableInStore: false,
    },
    showStoreAvailableFilter: true,
    vmStore: 'sghof',
    vmStyle: 'sghof',
  }

  return sghofEnvVariables
}

export default getSghofEnvVariables
