import { IconProps } from '@abstractTypes/icons'

export const GlassShape6Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 0.25C30.768 0.25 27.896 0.255 26.716 2.91C25.027 2.067 22.973 2.066 21.283 2.909C20.104 0.255 17.231 0.25 11 0.25C2 0.25 0 0.25 0 8.25C0 16.25 2 16.25 11 16.25C20 16.25 22 16.25 22 8.25C22 6.928 21.938 5.842 21.806 4.921C23.118 4.061 24.882 4.061 26.194 4.921C26.062 5.842 26 6.928 26 8.25C26 16.25 28 16.25 37 16.25C46 16.25 48 16.25 48 8.25C48 0.25 46 0.25 37 0.25ZM18.827 13.547C17.684 14.25 14.88 14.25 11 14.25C7.12 14.25 4.316 14.25 3.173 13.547C2.564 13.172 2 12.271 2 8.25C2 4.229 2.564 3.328 3.173 2.953C4.316 2.25 7.12 2.25 11 2.25C14.88 2.25 17.684 2.25 18.827 2.953C19.436 3.328 20 4.229 20 8.25C20 12.271 19.436 13.172 18.827 13.547ZM37 14.25C40.88 14.25 43.684 14.25 44.827 13.547C45.436 13.172 46 12.271 46 8.25C46 4.229 45.436 3.328 44.827 2.953C43.684 2.25 40.88 2.25 37 2.25C33.12 2.25 30.316 2.25 29.173 2.953C28.564 3.328 28 4.229 28 8.25C28 12.271 28.564 13.172 29.173 13.547C30.316 14.25 33.12 14.25 37 14.25Z"
        fill="#333333"
      />
    </svg>
  )
}
