import React, { useRef } from 'react'
import { VKeyboardProps } from '@abstractTypes/keyboard'
import { useVKContext } from '@hooks/useVKContext'

type VKeyboardEventProp = {
  stopFocusInPropagation: boolean
  stopFocusOutPropagation: boolean
}
type VKeyboardFocusEvent<K> = React.FocusEvent<K> & VKeyboardEventProp
type VKeyboardMouseEvent<K> = React.MouseEvent<K, MouseEvent> & VKeyboardEventProp

const isBlurOrMouseEvent = <T extends Element>(
  e: React.SyntheticEvent<T, unknown>
): e is VKeyboardFocusEvent<T> | VKeyboardMouseEvent<T> => 'blur' === e.type || 'click' === e.type

const AVAILABLE_TYPES = ['text', 'email', 'password', 'tel', 'number', 'search']

export const VKeyboard: React.FC<VKeyboardProps> = ({ children, settings, disabled }) => {
  const ref = useRef(null)

  const { showKeyboard, setInput } = useVKContext()

  const isInputText = React.useCallback(
    (el: HTMLElement | HTMLInputElement): el is HTMLInputElement => {
      if (el && el.tagName === 'INPUT' && 'type' in el) {
        return AVAILABLE_TYPES.includes(el.type.toLocaleLowerCase())
      }
      return false
    },
    []
  )

  const isTextArea = React.useCallback(
    (el: HTMLElement): el is HTMLInputElement => !!el && el.tagName === 'TEXTAREA',
    []
  )

  const handleShowKeyboard = React.useCallback(
    (event: VKeyboardFocusEvent<HTMLElement> | VKeyboardMouseEvent<HTMLElement>) => {
      const { target } = event
      const show =
        !disabled && (isInputText(target as HTMLElement) || isTextArea(target as HTMLElement))
      showKeyboard(show, settings)
    },
    [isInputText, isTextArea, disabled, showKeyboard, settings]
  )

  // we show keyboard if user press on input type text or on keyboard
  const onFocusHandler = React.useCallback(
    (event: VKeyboardFocusEvent<HTMLDivElement>) => {
      event.persist()
      if (event.stopFocusInPropagation) return
      const { target } = event

      if (!(isInputText(target) || isTextArea(target))) return

      setInput(target as HTMLInputElement)
      handleShowKeyboard(event)

      event.stopFocusInPropagation = true
    },
    [handleShowKeyboard, setInput, isInputText, isTextArea]
  )

  // we hide keyboard if user click outside input type text or outside keyboard
  const onBlurOrClickHandler = React.useCallback(
    (event: React.SyntheticEvent<HTMLElement, MouseEvent | FocusEvent>) => {
      event.persist()

      if (!isBlurOrMouseEvent(event) || disabled) return
      if (event.stopFocusOutPropagation) return

      handleShowKeyboard(event)
      event.stopFocusOutPropagation = true
    },
    [disabled, handleShowKeyboard]
  )

  return (
    <div
      className="vk-panel"
      ref={ref}
      onFocus={onFocusHandler}
      onBlur={onBlurOrClickHandler}
      onClick={onBlurOrClickHandler}
    >
      {children}
    </div>
  )
}
