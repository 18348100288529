import { AppConfigOverride } from '@abstractTypes/envs'
import { ProductTypeValues } from '@abstractTypes/filter'
import { FaPlaylistType } from '@abstractTypes/frameAdvisor'

const getMoEnvVariables = () => {
  const moEnvVariables: AppConfigOverride = {
    shopperSlug: 'mo',
    shopperName: 'MULTIOPTICAS',
    storeID: '108',
    storeLang: 'pt-BR',
    default: {
      productType: ProductTypeValues.SUN,
      faPlaylistType: FaPlaylistType.OPTICAL,
    },
    vmStore: 'mo',
    vmStyle: 'mo',
  }

  return moEnvVariables
}

export default getMoEnvVariables
