import { ThemeOverride } from '@abstractTypes/theme'
import { fonts } from '@theme/index'

export const ocTheme: ThemeOverride = {
  colors: {
    primaryDark: '#222222',
    primaryDarkPressed: '#333333',
    primaryLight: '#FFFFFF',
    primaryLightPressed: '#FFFFFF',
    inactive: '#A0A0A0',
    outline: '#D8D8D8',
    background: '#F9F9F9',
    highlight01: '#004785',
    highlight01Pressed: '#003B6F',
    highlight02: '#FFC20E',
    highlight02Pressed: '#FFE17F',
    highlight03: '#004785',
    highlight04: '#004785',
    functional01: '#EA420E',
    functional02: '#77C27E',
    functional03: '#79D1FF',
  },
  fonts: {
    ...fonts,
    heading0: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 100,
        tablet: 75,
        tabletMini: 57,
      },
      lineHeight: {
        tower: 115,
        tablet: 80,
        tabletMini: 72,
      },
    },
    heading1: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 60,
        tablet: 48,
        tabletMini: 40,
      },
      lineHeight: {
        tower: 78,
        tablet: 62,
        tabletMini: 59,
      },
    },
    heading2: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 40,
        tablet: 40,
        tabletMini: 36,
      },
      lineHeight: {
        tower: 52,
        tablet: 52,
        tabletMini: 50,
      },
    },
    heading3: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 32,
        tablet: 32,
        tabletMini: 26,
      },
      lineHeight: {
        tower: 42,
        tablet: 42,
        tabletMini: 34,
      },
    },
    heading4: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 34,
        tablet: 34,
        tabletMini: 31,
      },
    },
    heading5: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 20,
        tablet: 20,
        tabletMini: 18,
      },
      lineHeight: {
        tower: 28,
        tablet: 30,
        tabletMini: 28,
      },
    },
    body: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    label: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 24,
        tablet: 20,
        tabletMini: 18,
      },
    },
    cta: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
        bold: 700,
      },
      fontSize: {
        tower: 18,
        tablet: 16,
        tabletMini: 16,
      },
      lineHeight: {
        tower: 26,
        tablet: 24,
        tabletMini: 24,
      },
    },
    link: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 24,
        tablet: 24,
        tabletMini: 20,
      },
      lineHeight: {
        tower: 32,
        tablet: 34,
        tabletMini: 31,
      },
    },
    caption: {
      fontFamily: 'Helvetica',
      weight: {
        regular: 400,
      },
      fontSize: {
        tower: 14,
        tablet: 14,
        tabletMini: 12,
      },
      lineHeight: {
        tower: 16,
        tablet: 16,
        tabletMini: 14,
      },
    },
  },
  components: {
    button: {
      style: 'squared',
      uppercase: true,
    },
    rangeSlider: {
      background: '#A0A0A0', //inactive
      foreground: '#004785', //highlight01
      thumb: '#F9F9F9', //background
      thumbBorder: '#A0A0A0', //inactive
    },
  },
}
