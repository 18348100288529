import { ProductSubdivisions } from '@abstractTypes/filter'
import { Heading5 } from '@components/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SearchTabsProps } from '../types'
import { usePushToDataTrack } from './analytics'
import { SearchFilterTab, SearchTabsWrapper } from './styled'

export const SearchTabs: React.FC<SearchTabsProps> = ({ tabNames, selectedTab, onChange }) => {
  const { t } = useTranslation()
  const pushToDataTrack = usePushToDataTrack()

  const onProductTypeClick = (p: ProductSubdivisions, i: number) => {
    onChange(p)
    pushToDataTrack(p, i)
  }

  const getWeight = (productType: ProductSubdivisions) =>
    productType === selectedTab ? 'bold' : undefined

  return (
    <SearchTabsWrapper role="tablist">
      {tabNames.length > 1 &&
        tabNames.map((p, index) => {
          const isTabActive = p === selectedTab
          return (
            <SearchFilterTab
              isActive={isTabActive}
              key={p}
              onClick={() => onProductTypeClick(p, index)}
              role="tab"
              aria-selected={isTabActive}
            >
              <Heading5
                color={isTabActive ? 'primaryDark' : 'secondaryMedium'}
                weight={getWeight(p)}
              >
                {t(`PlpFilters.${p.toLowerCase()}`)}
              </Heading5>
            </SearchFilterTab>
          )
        })}
    </SearchTabsWrapper>
  )
}
