import { IconProps } from '@abstractTypes/icons'


export const Icon6S: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 51 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.6045 15.3709C50.8828 7.25449 39.0096 0.359375 25.3237 0.359375C11.6379 0.359375 0.0390625 7.2197 0.0390625 15.3709C0.0390625 23.5222 11.634 30.3825 25.3237 30.3825C31.183 30.3825 36.6404 29.1148 40.9846 27.0432C39.1512 27.412 37.2853 27.5959 35.4152 27.592C27.0746 27.592 20.3147 24.1367 20.3147 19.8891C20.3147 15.6415 27.0746 12.1785 35.4152 12.1785C39.0908 12.1785 42.3721 12.7157 45.0776 13.9641C45.3791 14.1032 46.3453 14.6327 46.5888 15.1236C45.9241 14.8286 45.2196 14.6332 44.4979 14.5438C40.6329 14.1303 38.8473 15.5719 38.3139 16.8048C37.5409 18.5866 38.9207 20.8399 42.3567 21.1529C46.1212 21.493 48.3242 20.3258 49.6151 18.5518C50.2427 17.6081 50.5861 16.5041 50.6045 15.3709Z"
        fill={fill}
      />
    </svg>
  )
}
